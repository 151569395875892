import React, { useEffect, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Button, Tooltip, Typography } from 'antd'

import { AgencyService } from '../../../apis/wl-service'
import TablePage, {
  getActionColumn,
  getColumnFilterProps,
  ITablePage,
  TablePageInstance,
} from '../../../components/wl-table-page'

export default () => {
  const params: { type?: string } = useParams()
  const nav = useNavigate()
  const tableRef = useRef<TablePageInstance>(null)
  const [breadcrumb, setBreadcrumb] = useState<ITablePage['breadcrumb']>([])
  const [title, setTitle] = useState<string>('')

  // 设置面包屑
  useEffect(() => {
    const title = params.type === 'todo' ? '待办事项' : '已办事项'
    setTitle(title)
    setBreadcrumb([
      {
        name: '投资开发',
        url: '/invest/workbench',
      },
      {
        name: '工作台',
        url: '/invest/workbench',
      },
      {
        name: title,
        url: `/invest/agency/${params.type}`,
      },
    ])

    // 设置搜索参数
    tableRef.current?.reload?.({
      type: params.type === 'todo' ? 1 : 2,
    })
  }, [params.type])

  // 判断不同类型跳转不同详情页
  const jumpInfo = (row: any) => {
    // window.localStorage.setItem('agencyInfo', JSON.stringify(row))
    // if (row.agency_type === 3) {
    //   nav(`/invest/agency/conflict/${row.id}/${row.type}`)
    // } else {
    nav(`/invest/agency/${row.data_id}/${row.type}`)
    // }
  }

  // 表格列配置
  const columns = (tableParams: any) => [
    {
      title: '项目名称',
      dataIndex: 'project_name',
      width: 120,
      fixed: 'left' as any,
      ...getColumnFilterProps({
        type: 'input',
        placeholder: '请输入项目名称',
        value: tableParams.project_name,
        onSearch: (value) => {
          tableRef.current?.reload?.({
            project_name: value,
            page: 1,
          })
        },
      }),
      render: (text: string, row: any) =>
        text && (
          <Tooltip title={text}>
            <Typography.Text ellipsis>
              <Button type="link" onClick={jumpInfo.bind(null, row)}>
                {text}
              </Button>
            </Typography.Text>
          </Tooltip>
        ),
    },
    {
      title: '所属功能模块',
      dataIndex: 'module_name',
      width: 120,
      render: (text: string) =>
        text && (
          <Tooltip title={text}>
            <Typography.Text ellipsis>{text}</Typography.Text>
          </Tooltip>
        ),
    },
    {
      title: '待办事项',
      dataIndex: 'agency_name',
      width: 150,
      render: (text: string) =>
        text && (
          <Tooltip title={text}>
            <Typography.Text ellipsis>{text}</Typography.Text>
          </Tooltip>
        ),
    },
    {
      title: '提交人',
      dataIndex: 'commit_name',
      width: 100,
    },
    {
      title: '提交时间',
      dataIndex: 'commit_time',
      width: 140,
      render: (text: string) =>
        text && (
          <Tooltip title={text}>
            <Typography.Text ellipsis>{text}</Typography.Text>
          </Tooltip>
        ),
    },

    getActionColumn([
      {
        key: 'detail',
        label: '详情',
        onClick: (row: any) => {
          jumpInfo(row)
        },
      },
      {
        key: 'delete',
        type: 'delete',
        visible: (row: any) => row.is_commit != 1,
        getter: (row: any) => {
          return AgencyService.remove({
            id: row.id,
          })
        },
        success: (arg: any) => tableRef.current?.reload?.(arg),
      },
    ]),
  ]

  return (
    <TablePage
      ref={tableRef}
      autoLoad={false}
      title={title}
      extra={null}
      breadcrumb={breadcrumb}
      columns={columns}
      rowKey="data_id"
      // getter={(arg: any) => AgencyService.list(arg)}
      getter={() => Promise.resolve()}
    />
  )
}
