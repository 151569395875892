/**
 * project
 * unknown
 *
 * OpenAPI spec version: last
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import ajax, { AjaxPromise, ExtraFetchParams } from '@ajax';
import * as models from '../model/models';

/* tslint:disable:no-unused-variable member-ordering object-literal-shorthand */

/**
  * CreatePowerStationReq :CreatePowerStationReq
  */
export type ParamsBodyi3060PowerStationCreatePost = models.CreatePowerStationReq;
/**
  * @description i3060PowerStationPageGet参数
  * @property `page` 
  * @property `size` 
  */
export interface Paramsi3060PowerStationPageGet {
  // queryParams
  /**
   * 
   */
  page: string;
  /**
   * 
   */
  size: string;
}
/**
  * @description i3060PowerStationShowGet参数
  * @property `powerStationId` 
  */
export interface Paramsi3060PowerStationShowGet {
  // queryParams
  /**
   * 
   */
  powerStationId: string;
}
/**
  * @description i3060ProjectProjectSelectGet参数
  * @property `[keyword]` 项目名称模糊搜索(String)
  */
export interface Paramsi3060ProjectProjectSelectGet {
  // queryParams
  /**
   * 项目名称模糊搜索(String)
   */
  keyword?: string;
}
/**
  * @description powerStationShowGet参数
  * @property `powerStationId` 
  */
export interface ParamspowerStationShowGet {
  // queryParams
  /**
   * 
   */
  powerStationId: string;
}

export class PowerStationControllerApi {
  protected $basePath = '/api'.replace(/\/$/, '');

  public constructor(basePath?: string) {
    if (basePath !== undefined) {
      this.$basePath = basePath.replace(/\/$/, '');
    }
  }

  /**
   * ```   /_**      * 创建电站      *      * @param createReq      * @return 电站id      *_/     @PostMapping(\"/create\")     public Result<Long> create(@RequestBody @Valid CreatePowerStationReq createReq) ```
   * @summary 创建电站
   
   * @param data: ParamsBodyi3060PowerStationCreatePost// CreatePowerStationReq :CreatePowerStationReq
   * @param opt ajax config
   * @returns models.ResultLong
   */
  public i3060PowerStationCreatePost = (
    
    data: ParamsBodyi3060PowerStationCreatePost,opt?: ExtraFetchParams
  ) : AjaxPromise<models.ResultLong>  => {
    const url = this.$basePath + `/i3060/powerStation/create`;
    const p: any = {};
    p.data = data;
    return ajax.ajax({
      ...opt,
      method: 'POST',
      url,
      ...p
    });
  }
  /**
   * ```   /_**      * 电站列表分页      *      * @param req      * @return 电站分页列表      *_/     @GetMapping(\"/page\")     public Result<RPage<PowerStationPageRes>> page(@ModelAttribute @Valid PowerStationPageReq req) ```
   * @summary 电站列表分页
   * @param params Paramsi3060PowerStationPageGet
   
   * @param opt ajax config
   * @returns models.ResultRPagePowerStationPageRes
   */
  public i3060PowerStationPageGet = (
    params: Paramsi3060PowerStationPageGet,
    opt?: ExtraFetchParams
  ) : AjaxPromise<models.ResultRPagePowerStationPageRes>  => {
    const url = this.$basePath + `/i3060/powerStation/page`;
    const p: any = {};
    p.query = {};
    if ('page' in params) p.query.page = params.page;
    ajax.check(params.page, 'page');
    if ('size' in params) p.query.size = params.size;
    ajax.check(params.size, 'size');
    return ajax.ajax({
      ...opt,
      method: 'GET',
      url,
      ...p
    });
  }
  /**
   * ```   /_**      * 电站列表分页      *      * @param req      * @return 电站分页列表      *_/     @RequestMapping(\"/page\")     public Result<RPage<PowerStationPageRes>> page(@ModelAttribute @Valid PowerStationPageReq req) ```
   * @summary 电站详情
   * @param params Paramsi3060PowerStationShowGet
   
   * @param opt ajax config
   * @returns models.I3060PowerStationShow
   */
  public i3060PowerStationShowGet = (
    params: Paramsi3060PowerStationShowGet,
    opt?: ExtraFetchParams
  ) : AjaxPromise<models.I3060PowerStationShow>  => {
    const url = this.$basePath + `/i3060/powerStation/show`;
    const p: any = {};
    p.query = {};
    if ('powerStationId' in params) p.query.powerStationId = params.powerStationId;
    ajax.check(params.powerStationId, 'powerStationId');
    return ajax.ajax({
      ...opt,
      method: 'GET',
      url,
      ...p
    });
  }
  /**
   * ```   /_**      * 项目下拉框      *      * @param keyword 项目名称模糊搜索      * @return      *_/     @GetMapping(\"/project/select\")     public Result<List<ProjectSelectRes>> projectSelect(@RequestParam(value = \"keyword\",required = false) String keyword) ```
   * @summary 项目下拉框
   * @param params Paramsi3060ProjectProjectSelectGet
   
   * @param opt ajax config
   * @returns models.ResultListProjectSelectRes
   */
  public i3060ProjectProjectSelectGet = (
    params: Paramsi3060ProjectProjectSelectGet,
    opt?: ExtraFetchParams
  ) : AjaxPromise<models.ResultListProjectSelectRes>  => {
    const url = this.$basePath + `/i3060/project/projectSelect`;
    const p: any = {};
    p.query = {};
    if ('keyword' in params) p.query.keyword = params.keyword;
    return ajax.ajax({
      ...opt,
      method: 'GET',
      url,
      ...p
    });
  }
  /**
   * ```   /_**      * 电站列表分页      *      * @param req      * @return 电站分页列表      *_/     @RequestMapping(\"/page\")     public Result<RPage<PowerStationPageRes>> page(@ModelAttribute @Valid PowerStationPageReq req) ```
   * @summary 电站详情
   * @param params ParamspowerStationShowGet
   
   * @param opt ajax config
   * @returns models.PowerStationShow
   */
  public powerStationShowGet = (
    params: ParamspowerStationShowGet,
    opt?: ExtraFetchParams
  ) : AjaxPromise<models.PowerStationShow>  => {
    const url = this.$basePath + `/powerStation/show`;
    const p: any = {};
    p.query = {};
    if ('powerStationId' in params) p.query.powerStationId = params.powerStationId;
    ajax.check(params.powerStationId, 'powerStationId');
    return ajax.ajax({
      ...opt,
      method: 'GET',
      url,
      ...p
    });
  }
}

export default new PowerStationControllerApi();