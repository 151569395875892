import ReactECharts from 'echarts-for-react'
import styles from '../index.module.scss'

// 图标颜色
const defaultColors = '#1EC692'


const EquipmentVoltageLine = (props) => {
  const { dataSource, name, assetsTreeName, unit } = props
  let seriesData = []
  dataSource.forEach((item) => {
    seriesData.push([item.ts, Number(item.value)])
  })
  return (
    <div className={styles.equipmentVoltageLineCard}>
      <ReactECharts
        style={{
          width: '100%',
          height: '264px',
        }}
        option={{
          color: defaultColors,
          title: {
            text: name,
            left: 16,
            top: 16,
            textStyle: {
              color: '#262E3F',
              fontSize: 14
            }
          },
          legend: {
            orient: 'vertical',
            right: 16,
            top: 50,
            textStyle: {
              color: '#606266',
              fontSize: 14
            },
            icon: 'rect',
            itemWidth: 14,
            itemHeight: 2,
            itemGap: 20,
            data: [assetsTreeName]
          },
          grid: {
            top: 80,
            right: 30,
            bottom: 16,
            left: 16,
            containLabel: true
          },
          tooltip: {
            trigger: 'axis',
            formatter: (args: any) => {
              let res = `<strong>${args[0].axisValueLabel}</strong>`
              if (args.length > 0) {
                res += args
                  .map(
                    (o: any) =>
                      `<div style='width: 200px;'>${ o.marker}${o.seriesName}<span style='float: right'>${o.value[1]}${unit && unit !== 'null' ? unit : ''}</span></div>`
                  )
                  .join('')
              }
              return res
            },
          },
          xAxis: {
            type: 'time',
            name: '时',
            nameTextStyle: {
              verticalAlign: 'top',
              padding: [8, 0, 0, 0],
              color: '#98A0B3',
            },
            nameGap: 8,
            axisLabel: {
              show: true,
              formatter: '{HH}'
            },
            splitNumber: 10,
            splitLine: {
              show: false
            },
            axisTick: {
              show: false
            },
            axisLine: {
              lineStyle: {
                color: '#98A0B3'
              }
            },
            boundaryGap: false,
          },
          yAxis: {
            type: 'value',
            name: unit && unit !== 'null' ? `单位:${unit}` : '',
            nameTextStyle: {
              fontSize: 12,
              color: '#98A0B3',
              padding: [0, -35, 5, 0],
            },
            splitLine: {
              show: true,
              lineStyle: {
                type: 'dashed',
                color: '#CFD6E8'
              }
            },
            axisTick: {
              show: false
            },
            axisLabel: {
              show: true,
              color: '#262E3F',
              fontSize: 12
            },
            axisLine: {
              show: false
            }
          },
          series: [{
            type: 'line',
            name: assetsTreeName,
            smooth: false,
            symbolSize: 0,
            data: seriesData
        }]
        }}
      />
    </div>
  )
}

export default EquipmentVoltageLine
