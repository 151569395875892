import { ArrowLeftOutlined } from '@ant-design/icons'
import React from 'react'
import { useNavigate } from 'react-router-dom'

import './index.less'

type CardTitleProps = {
  title?: React.ReactNode
  path?: string
  goBack?: Function
}

const CardTitle = React.memo((props: CardTitleProps) => {
  const navigate = useNavigate()
  const { title, path, goBack } = props

  const backFun = () => path && navigate(path)

  return (
    <div className="card-title">
      <div className="card-title-back" onClick={() => (goBack ? goBack() : backFun())}>
        <ArrowLeftOutlined alt="返回" />
      </div>
      <h3>{title ?? null}</h3>
    </div>
  )
})

export default CardTitle
