import { Button, Card, Form, Input, Menu, Modal, Popover, Radio, Space, Table, Tooltip } from 'antd'
import React, { useEffect, useState } from 'react'
import Global, { CustomIcons } from '../../global'
import moment from 'moment'
import styles from '../program/index.module.scss'

import './index.less'
import { ResourceService } from '../../apis/project-service-history'
import { ApplicationModal, BreadcrumbObservable } from '../../application'
import { ResourceContent } from './resource-content'
import { RESOURCE_NAME_EXP } from '../../utils/constant'

const query: any = {
  page: 1,
  size: 20,
  total: 0,

  name: undefined,
  type: 1,
  regionCode: undefined,
  createdName: undefined,

  createdTimeSort: '2',
}

const region = [
  { text: '北京市', value: '110000' },
  { text: '天津市', value: '120000' },
  { text: '河北省', value: '130000' },
  { text: '山西省', value: '140000' },
  { text: '内蒙古自治区', value: '150000' },
  { text: '辽宁省', value: '210000' },
  { text: '吉林省', value: '220000' },
  { text: '黑龙江省', value: '230000' },
  { text: '上海市', value: '310000' },
  { text: '江苏省', value: '320000' },
  { text: '浙江省', value: '330000' },
  { text: '安徽省', value: '340000' },
  { text: '福建省', value: '350000' },
  { text: '江西省', value: '360000' },
  { text: '山东省', value: '370000' },
  { text: '河南省', value: '410000' },
  { text: '湖北省', value: '420000' },
  { text: '湖南省', value: '430000' },
  { text: '广东省', value: '440000' },
  { text: '广西壮族自治区', value: '450000' },
  { text: '海南省', value: '460000' },
  { text: '重庆市', value: '500000' },
  { text: '四川省', value: '510000' },
  { text: '贵州省', value: '520000' },
  { text: '云南省', value: '530000' },
  { text: '西藏自治区', value: '540000' },
  { text: '陕西省', value: '610000' },
  { text: '甘肃省', value: '620000' },
  { text: '青海省', value: '630000' },
  { text: '宁夏回族自治区', value: '640000' },
  { text: '新疆维吾尔自治区', value: '650000' },
  { text: '台湾省', value: '710000' },
  { text: '香港特别行政区', value: '810000' },
  { text: '澳门特别行政区', value: '820000' },
]

const jumpTo = (url: string) => {
  if (url) {
    location.assign(`${url}`)
    Global.changeMenu.forEach((Fn: any) => {
      Fn(url)
    })
  }
}

const ActionMenu = (props: any) => {
  const [renameForm] = Form.useForm()
  const [deleteForm] = Form.useForm()

  const auths = props.currOperationAuth || []
  const hasRenameAuth = auths.includes('rename')
  const hasRemoveAuth = auths.includes('remove')

  return (
    <Menu className="custom-row-menu">
      <Menu.Item
        onClick={() => {
          const url = `#/program/manage`
          jumpTo(`${url}?add=1&resourceId=${props.id}`)
          Global.changeMenu.forEach((Fn: any) => {
            Fn(url)
          })
        }}
      >
        新建项目
      </Menu.Item>
      {hasRenameAuth && (
        <Menu.Item
          onClick={() => {
            renameForm.setFieldsValue({
              rename: props.name,
            })
            Modal.destroyAll()
            Modal.confirm({
              keyboard: false,
              autoFocusButton: null,
              title: '重命名',
              icon: <CustomIcons type="klf-alarm1" />,
              content: (
                <Form form={renameForm}>
                  <Form.Item
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                    }}
                    name="rename"
                    label="请输入资源名称"
                    rules={[
                      {
                        required: true,
                        message: '请输入资源名称',
                      },
                      {
                        pattern: RESOURCE_NAME_EXP,
                        message: '1-50个字符,中文、英文、_、数字和-组成',
                      },
                    ]}
                  >
                    <Input placeholder="请输入"></Input>
                  </Form.Item>
                </Form>
              ),
              width: 500,
              okType: 'primary',
              okText: '确定',
              cancelText: '取消',
              onOk: () => {
                return new Promise((resolve, reject) => {
                  const { rename } = renameForm.getFieldsValue()
                  renameForm
                    .validateFields()
                    .then(() => {
                      ResourceService.rename({
                        root: {
                          id: props.id.toString(),
                          name: rename,
                        },
                      })
                        .then((resp) => {
                          resolve(resp)
                          props.callback?.()
                        })
                        .catch((e) => reject(e))
                    })
                    .catch((e) => {
                      reject(e)
                    })
                })
              },
            })
          }}
        >
          重命名
        </Menu.Item>
      )}
      {hasRemoveAuth && (
        <Menu.Item
          onClick={() => {
            deleteForm.resetFields()
            Modal.destroyAll()
            Modal.confirm({
              keyboard: false,
              title: '删除资源',
              icon: <CustomIcons type="klf-alarm1" />,
              content: (
                <div>
                  <p>确认删除资源？</p>
                </div>
              ),
              width: 500,
              okType: 'primary',
              okText: '确定',
              okButtonProps: {
                danger: true,
              },
              onOk: () => {
                return new Promise((resolve, reject) => {
                  ResourceService.remove({
                    id: props.id.toString(),
                  })
                    .then((resp) => {
                      resolve(resp)
                      props.callback?.()
                    })
                    .catch((e) => reject(e))
                })
              },
              cancelText: '取消',
              cancelButtonProps: {
                danger: true,
              },
            })
          }}
        >
          删除
        </Menu.Item>
      )}
    </Menu>
  )
}

export const ResourceManage = () => {
  const [loading, setLoading] = useState(false)

  const [nameFilter, setNameFilter] = useState('')
  const [creatorFilter, setCreatorFilter] = useState('')

  const [form] = Form.useForm()
  const [dataSource, setDataSource] = useState<any[]>([])

  const loadData = () => {
    setLoading(true)
    ResourceService.list({
      ...query,
    })
      .then((resp) => {
        query.total = resp.data.total
        query.page = resp.data.page
        setDataSource(resp.data.data)
      })
      .catch(() => {})
      .finally(() => {
        setLoading(false)
      })
  }

  useEffect(() => {
    BreadcrumbObservable.next([])
    loadData()

    return () => {
      query.page = 1
      query.size = 20
      query.total = 0
      query.name = undefined
      query.type = 1
      query.regionCode = undefined
      query.createdName = undefined
      query.createdTimeSort = '2'
    }
  }, [])
  const columns = [
    {
      title: '资源名称',
      dataIndex: 'name',
      key: 'name',
      width: 200,
      fixed: 'left' as any,
      onFilterDropdownVisibleChange: (visible: boolean) => {
        if (!visible) {
          setNameFilter(query.name)
        }
      },
      filterIcon: (
        <CustomIcons
          type="klf-search"
          style={{
            color: query.name ? '#1ec692' : '#bfbfbf',
          }}
        />
      ),
      filterDropdown: ({ confirm, visible }: any) => {
        if (!visible) {
          ;(window as any).closeDropdown = null
          return null
        }
        setTimeout(() => {
          ;(window as any).closeDropdown = confirm
        })
        return (
          <div
            style={{
              padding: '.5rem 1rem',
            }}
          >
            <Input
              id="nameFilter"
              value={nameFilter}
              placeholder="请输入资源名称"
              onChange={(dom) => {
                setNameFilter(dom.target.value)
              }}
              onBlur={(dom) => {
                setNameFilter(dom.target.value)
              }}
              onPressEnter={() => {
                query.name = nameFilter
                query.page = 1
                loadData()
                confirm?.()
              }}
            />
            <div
              style={{
                marginTop: '.5rem',
                textAlign: 'right',
              }}
            >
              <Button
                onClick={() => {
                  setNameFilter('')
                }}
              >
                重置
              </Button>
              <Button
                type="primary"
                style={{
                  marginLeft: '.5rem',
                }}
                onClick={() => {
                  query.name = nameFilter
                  query.page = 1
                  loadData()
                  confirm?.()
                }}
              >
                确定
              </Button>
            </div>
          </div>
        )
      },
      render: (res: string, row: any) => (
        <Tooltip title={res} key={`name-${res}`}>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <span
              style={{
                cursor: 'pointer',
                color: '#1ec692',
                wordBreak: 'break-all',
              }}
              onClick={() => {
                ApplicationModal.confirm({
                  keyboard: false,
                  icon: null,
                  title: '资源详情',
                  closable: true,
                  content: <ResourceContent data={row} />,
                  width: 900,
                  okButtonProps: {
                    style: { display: 'none' },
                  },
                  cancelButtonProps: {
                    style: { display: 'none' },
                  },
                })
              }}
            >
              {res}
            </span>
          </div>
        </Tooltip>
      ),
    },
    {
      title: '资源类型',
      dataIndex: 'type',
      key: 'type',
      sorter: false,
      width: 120,
      render: (val: any) => {
        return val === 1 ? '屋顶' : val
      },
      filterMultiple: false,
      filterIcon: (
        <CustomIcons
          type="klf-filter"
          style={{
            color: query.type?.length > 0 ? '#1ec692' : '#bfbfbf',
          }}
        />
      ),
      onFilterDropdownVisibleChange: (visible: boolean) => {
        if (!visible) {
          form.setFieldsValue({ type: 1 })
        }
        if (visible) {
          form.setFieldsValue({ type: 1 })
        }
      },
      filterDropdown: ({ confirm, visible, filters }: any) => {
        if (!visible) {
          ;(window as any).closeDropdown = null
        } else {
          setTimeout(() => {
            ;(window as any).closeDropdown = confirm
          })
        }
        return (
          <div
            className={styles.customFilter}
            style={{
              paddingRight: 0,
            }}
          >
            <Form
              onFinish={() => {
                query.type = form.getFieldValue('type')
                query.page = 1
                loadData()
                confirm()
              }}
              {...{
                labelCol: { span: 24 },
                wrapperCol: { span: 24 },
              }}
              colon={false}
              form={form}
            >
              <Form.Item
                name={'type'}
                className="label-low"
                style={{
                  alignItems: 'center',
                  maxHeight: 300,
                  overflowY: 'auto',
                }}
              >
                <Radio.Group>
                  <Space direction="vertical">
                    {filters.map((filter: any) => (
                      <Radio key={filter.value} value={filter.value}>
                        {filter.text}
                      </Radio>
                    ))}
                  </Space>
                </Radio.Group>
              </Form.Item>
              <Form.Item className="filter-btns">
                <div
                  style={{
                    marginTop: '.5rem',
                    textAlign: 'right',
                  }}
                >
                  <Button
                    onClick={() => {
                      form.setFieldsValue({
                        type: [],
                      })
                    }}
                  >
                    重置
                  </Button>
                  <Button
                    type="primary"
                    style={{
                      marginLeft: '.5rem',
                    }}
                    htmlType="submit"
                  >
                    确定
                  </Button>
                </div>
              </Form.Item>
            </Form>
          </div>
        )
      },
      filters: [
        {
          text: '屋顶',
          value: 1,
        },
      ],
    },
    {
      title: '所属区域',
      dataIndex: 'region',
      key: 'region',
      width: 220,
      render: (val: any) => {
        return val
      },
      sorter: false,
      filterMultiple: false,
      filterIcon: (
        <CustomIcons
          type="klf-filter"
          style={{
            color: query.regionCode?.length > 0 ? '#1ec692' : '#bfbfbf',
          }}
        />
      ),
      onFilterDropdownVisibleChange: (visible: boolean) => {
        if (!visible) {
          form.setFieldsValue({ regionCode: query.regionCode })
        }
      },
      filterDropdown: ({ confirm, visible, filters }: any) => {
        if (!visible) {
          ;(window as any).closeDropdown = null
        } else {
          setTimeout(() => {
            ;(window as any).closeDropdown = confirm
          })
        }
        return (
          <div
            className={styles.customFilter}
            style={{
              paddingRight: 0,
            }}
          >
            <Form
              onFinish={() => {
                query.regionCode = form.getFieldValue('regionCode')
                query.page = 1
                loadData()
                confirm()
              }}
              {...{
                labelCol: { span: 24 },
                wrapperCol: { span: 24 },
              }}
              colon={false}
              form={form}
            >
              <Form.Item
                name={'regionCode'}
                className="label-low"
                style={{
                  alignItems: 'center',
                  maxHeight: 300,
                  overflowY: 'auto',
                }}
              >
                <Radio.Group>
                  <Space direction="vertical">
                    {filters.map((filter: any) => (
                      <Radio key={filter.value} value={filter.value}>
                        {filter.text}
                      </Radio>
                    ))}
                  </Space>
                </Radio.Group>
              </Form.Item>
              <Form.Item className="filter-btns">
                <div
                  style={{
                    marginTop: '.5rem',
                    textAlign: 'right',
                  }}
                >
                  <Button
                    onClick={() => {
                      form.setFieldsValue({
                        regionCode: [],
                      })
                    }}
                  >
                    重置
                  </Button>
                  <Button
                    type="primary"
                    style={{
                      marginLeft: '.5rem',
                    }}
                    htmlType="submit"
                  >
                    确定
                  </Button>
                </div>
              </Form.Item>
            </Form>
          </div>
        )
      },
      filters: region,
    },
    {
      title: '添加时间',
      dataIndex: 'createdTime',
      key: 'createdTime',
      width: 180,
      render: (res: Date) => <div key={`createtime-${res}`}>{moment(res).format('YYYY-MM-DD HH:mm')}</div>,
      sorter: true,
      // sorter: (a: ProgramModel, b: ProgramModel) => a.createTime.getTime?.() - b.createTime.getTime?.(),
    },
    {
      title: '添加人',
      dataIndex: 'createdName',
      key: 'createdName',
      sorter: false,
      width: 180,
      filterIcon: (
        <CustomIcons
          type="klf-search"
          style={{
            color: query.createdName ? '#1ec692' : '#bfbfbf',
          }}
        />
      ),
      onFilterDropdownVisibleChange: (visible: boolean) => {
        if (!visible) {
          setCreatorFilter(query.createdName)
        }
      },
      filterDropdown: ({ confirm, visible }: any) => {
        if (!visible) {
          ;(window as any).closeDropdown = null
          return null
        }
        setTimeout(() => {
          ;(window as any).closeDropdown = confirm
        })
        return (
          <div
            style={{
              padding: '.5rem 1rem',
            }}
          >
            <Input
              placeholder="请输入创建人"
              value={creatorFilter}
              onBlur={(dom) => {
                setCreatorFilter(dom.target.value)
              }}
              onChange={(dom) => {
                setCreatorFilter(dom.target.value)
              }}
              onPressEnter={() => {
                query.createdName = creatorFilter
                query.page = 1
                loadData()
                confirm?.()
              }}
            />
            <div
              style={{
                marginTop: '.5rem',
                textAlign: 'right',
              }}
            >
              <Button
                onClick={() => {
                  setCreatorFilter('')
                }}
              >
                重置
              </Button>
              <Button
                type="primary"
                style={{
                  marginLeft: '.5rem',
                }}
                onClick={() => {
                  query.createdName = creatorFilter
                  query.page = 1
                  loadData()
                  confirm?.()
                }}
              >
                确定
              </Button>
            </div>
          </div>
        )
      },
      render: (res: string) => <div key={`creator-${res}`}>{res}</div>,
    },
    {
      title: '修改时间',
      dataIndex: 'updatedTime',
      key: 'updatedTime',
      width: 180,
      render: (res: Date) => <div key={`updatedtime-${res}`}>{moment(res).format('YYYY-MM-DD HH:mm')}</div>,
      sorter: true,
    },
    {
      title: '修改人',
      dataIndex: 'updatedName',
      key: 'updatedName',
      sorter: true,
      width: 180,
      render: (res: string) => <div key={`updated-${res}`}>{res}</div>,
    },
    {
      title: '操作',
      key: 'action',
      width: 60,
      fixed: 'right' as any,
      render: (text: any, record: any) => {
        const obj = false
        if (obj) {
          return (
            <div key={`action-${record.id}`}>
              {record.name} {text}
            </div>
          )
        }
        return (
          <Popover
            placement="leftTop"
            overlayClassName="menu-popover-overlay"
            key={`action-${record.id}`}
            content={
              <ActionMenu
                id={record.id}
                name={record.name}
                region={record.region}
                currOperationAuth={record.currOperationAuth}
                callback={() => {
                  loadData()
                }}
              />
            }
          >
            <Button
              type="link"
              className="ant-dropdown-link"
              onClick={(e) => e.preventDefault()}
              icon={<CustomIcons type="klf-more" />}
            />
          </Popover>
        )
      },
    },
  ]

  function onChange(pagination: any, filters: any, sorter: any) {
    const { order, field } = sorter
    const { current, pageSize } = pagination
    query.size = pageSize
    query.page = current

    query.createdTimeSort = '2'

    if (order) {
      let operation = 0
      switch (order) {
        case 'ascend':
          operation = 1
          break
        case 'descend':
          operation = 2
          break
      }
      switch (field) {
        case 'createdTime':
          query.createdTimeSort = operation
          break
      }
    }
    loadData()
  }

  return (
    <Card
      title={
        <div
          style={{
            fontWeight: 600,
            fontSize: 20,
          }}
        >
          资源管理
        </div>
      }
      className="no-border-bottom-card"
      bodyStyle={{
        height: 'calc(100vh - 210px)',
        paddingTop: 0,
      }}
    >
      <div className="resource-table">
        <Table
          className={`full-screen-table right-actions ${query.total > query.size ? 'jump-right' : ''}`}
          style={{ flexGrow: 1 }}
          scroll={{ x: 'max-content', y: 'calc(100vh - 360px)' }}
          loading={loading}
          columns={columns}
          onChange={onChange}
          dataSource={dataSource}
          pagination={{
            total: query.total,
            showSizeChanger: true,
            showQuickJumper: true,
            defaultPageSize: 20,
            current: query.page,
            pageSize: query.size,
            pageSizeOptions: ['10', '20', '50'],
            showTotal: (total) => `共 ${total} 条`,
          }}
        />
      </div>
    </Card>
  )
}
