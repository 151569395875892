import { useEffect, useState } from 'react'
import { Card, Typography, Tooltip, Button, Table, Image, Descriptions } from 'antd'
import { FilePdfOutlined, FileExcelOutlined, FileWordOutlined } from '@ant-design/icons'
import { BreadcrumbObservable, SideMenuObservable } from '../../../application'
import { useNavigate, useParams } from 'react-router-dom'
import { LogisticsService } from '../../../apis/engineering-service'
import './index.less'

export default () => {
  const nav = useNavigate()
  const [dataSource, setDataSource] = useState<any>({})
  const paramsData = useParams() // 链接参数

  useEffect(() => {
    SideMenuObservable.next(false)
    BreadcrumbObservable.next([
      {
        name: '工程管理',
        url: '/engineering',
      },
      {
        name: '物资管理',
        url: '/engineering/logistics',
      },
      {
        name: '项目物资详情',
        url: '/engineering/logisticsDetails',
      },
      
    ])
  }, [])

  // 获取数据
  useEffect(() => {
    // 获取详情数据
    LogisticsService.getDetail(paramsData?.id || '')
      .then((res: any) => {
        console.log(res, '====res')
        let list = []
        if (res?.data && res?.data?.cargoList) {
          
          res?.data?.cargoList.forEach((item) => {
            list.push({...item, type: item.name.split('.').length !== 0 ? item.name.split('.')[1] : ''})
          })

        }
        setDataSource({
          ...res.data,
          cargoList: list || {}
        })
      })
      .catch(() => {})
      
  }, [])
  console.log(dataSource, '====dataSource')

  // 运单基础信息
  const columns = [
    {
      title: '运单编号', dataIndex: dataSource?.thirdPartyOrderNo || '-'
    },
    {
      title: '运单状态', dataIndex:dataSource ? ['未发货', '在途中', '已到达'][dataSource?.status] : '-'
    },
    {
      title: '项目名称', dataIndex:dataSource?.projectName || '-'
    },
    {
      title: '项目代码', dataIndex:dataSource?.projectCode || '-'
    },
    {
      title: '订单编号', dataIndex:dataSource?.orderNo || '-'
    },
    {
      title: '项目地址', dataIndex:dataSource?.projectAddress || '-'
    },
    {
      title: '合同名称', dataIndex:dataSource?.contractName || '-'
    },
    {
      title: '合同编号', dataIndex:dataSource?.contractNo || '-'
    },
    {
      title: '承运商名称', dataIndex:dataSource?.commonCarrierName || '-'
    },
    {
      title: '创建时间', dataIndex:dataSource?.createTime || '-'
    },
    {
      title: '发货时间', dataIndex:dataSource?.deliverGoodsTime || '-'
    },
    {
      title: '车牌号', dataIndex:dataSource?.licensePlateNumber || '-'
    },
    {
      title: '到达时间', dataIndex:dataSource?.realityArriveTime || '-'
    },
    {
      title: '', dataIndex: ''
    },
  ]

  // 托运人信息
  const shipperColumns = [
    {
      title: '联系人', dataIndex: dataSource?.shipmentsContact || '-'
    },
    {
      title: '联人系电话', dataIndex: dataSource?.shipmentsTelephone || '-'
    },
    {
      title: '发货地址', dataIndex: dataSource?.shipmentsAddress || '-'
    },
    {
      title: '详细地址', dataIndex: dataSource?.shipmentsDetailAddress || '-'
    },
  ]

  // 收货人信息
  const consigneeColumns = [
    {
      title: '联系人', dataIndex: dataSource?.consigneeContact || '-'
    },
    {
      title: '联人系电话', dataIndex: dataSource?.consigneeTelephone || '-'
    },
    {
      title: '收货地址', dataIndex: dataSource?.consigneeAddress || '-'
    },
    {
      title: '详细地址', dataIndex: dataSource?.consigneeDetailAddress || '-'
    },
  ]

  // 运费明细
  const freightColumns = [
    {
      title: '支付状态', dataIndex: dataSource ? ['未支付', '已支付'][dataSource?.freightStatus] : '-'
    },
    {
      title: '包装费', dataIndex: dataSource?.packPrice || '-'
    },
    {
      title: '运输费', dataIndex: dataSource?.freightPrice || '-'
    },
    {
      title: '仓储费', dataIndex: dataSource?.storagePrice || '-'
    },
    {
      title: '装卸费', dataIndex: dataSource?.loadUnloadPrice || '-'
    },
    {
      title: '', dataIndex: ''
    },
  ]

  // 货物信息
  const DetailsGoods = () => {
    const columns = [
      {
        title: '货物名称',
        dataIndex: 'name',
        render: (text: string) =>
          text && (
            <Tooltip title={text}>
              <Typography.Text ellipsis>{text}</Typography.Text>
            </Tooltip>
          ),
      },
      {
        title: '货物规格',
        dataIndex: 'specification',
        render: (text: string) =>
          text && (
            <Tooltip title={text}>
              <Typography.Text ellipsis>{text}</Typography.Text>
            </Tooltip>
          )
      },
      {
        title: '货物数量',
        dataIndex: 'number',
        render: (text: string) =>
          text && (
            <Tooltip title={text}>
              <Typography.Text ellipsis>{text}</Typography.Text>
            </Tooltip>
          ),
      },
      {
        title: '货物重量',
        dataIndex: 'weight',
        render: (text: string) =>
          text && (
            <Tooltip title={text}>
              <Typography.Text ellipsis>{text}</Typography.Text>
            </Tooltip>
          ),
      }
    ]
    return (
      <Table
        className="right-actions"
        columns={columns}
        dataSource={dataSource?.details || []}
        rowKey={(record) => record?.detailId}
        scroll={{ x: '100%', y: `400px` }}
        style={{ flexGrow: 1 }}
        pagination={false}
      />
    )
  }

  const viewPdf = async (url: string) => {
    const a = document.createElement('a')
    a.target = '_blank'
    a.href = url
    a.click()
  }
  

  return (
    <Card
      className="logistics-details"
      extra={null}
      bodyStyle={{ padding: 0 }}
    >
      <Typography.Title level={4} style={{textAlign: 'center', marginBottom: 40}}>项目运单详情</Typography.Title>
      <div className="basicInfo">
        {
          columns.map((item , index) => (
            <div className="basic-item" key={index}>
              <div className="basic-title">{item.title}</div>
              <div className="basic-dataIndex">{item.dataIndex}</div>
            </div>
          ))
        }
      </div>
      <div className="basic-side">
        <Typography.Title level={5} style={{textAlign: 'left', marginBottom: 10}}>托运人信息：</Typography.Title>
        <div className="basicInfo">
          {
            shipperColumns.map((item , index) => (
              <div className="basic-item" key={index}>
                <div className="basic-title">{item.title}</div>
                <div className="basic-dataIndex">{item.dataIndex}</div>
              </div>
            ))
          }
        </div>
      </div>
      <div className="basic-side">
        <Typography.Title level={5} style={{textAlign: 'left', marginBottom: 10}}>收货人信息：</Typography.Title>
        <div className="basicInfo">
          {
            consigneeColumns.map((item , index) => (
              <div className="basic-item" key={index}>
                <div className="basic-title">{item.title}</div>
                <div className="basic-dataIndex">{item.dataIndex}</div>
              </div>
            ))
          }
        </div>
      </div>
      <div className="basic-side">
        <Typography.Title level={5} style={{textAlign: 'left', marginBottom: 10}}>货物信息：</Typography.Title>
        <DetailsGoods />
      </div>
      <div className="basic-side">
        <Typography.Title level={5} style={{textAlign: 'left', marginBottom: 10}}>运费明细：</Typography.Title>
        <div className="basicInfo">
          {
            freightColumns.map((item , index) => (
              <div className="basic-item" key={index}>
                <div className="basic-title">{item.title}</div>
                <div className="basic-dataIndex">{item.dataIndex}</div>
              </div>
            ))
          }
          <div className="basic-total-item">
            <div className="basic-total-title">运费统计</div>
            <div className="basic-total">
              { dataSource?.packPrice + dataSource?.freightPrice + dataSource?.storagePrice + dataSource?.loadUnloadPrice || 0 }
            </div>
          </div>
        </div>
      </div>
      <div className="basic-side">
        <Typography.Title level={5} style={{textAlign: 'left', marginBottom: 10}}>物流信息：</Typography.Title>
        <div className="basic-logistics-back"></div>
      </div>
      <div className="basic-side">
        <Typography.Title level={5} style={{textAlign: 'left', marginBottom: 10}}>随货清单：</Typography.Title>
        
        <Descriptions title="随货清单：" column={1}>
          {dataSource?.cargoList?.map((item: any, index: number) => {
            if(item.type === 'png' || item.type === 'jpg') {
              return (
                <Image.PreviewGroup>
                  <Image width={100} src={item.url} />
                </Image.PreviewGroup>
              )
            } else {
              return (
                <Descriptions.Item key={index} style={{ cursor: 'pointer' }}>
                  <div onClick={() => viewPdf(item.url)}>
                    {
                      item.type === 'pdf' && <FilePdfOutlined style={{ marginRight: 8, color: '#f8ad90' }} />
                    }
                    {
                      item.type === 'excel' || item.type === 'xlsx' || item.type === 'xls' ? <FileExcelOutlined style={{ marginRight: 8, color: '#1ec692' }} /> : null
                    }
                    {
                      item.type === 'docx' || item.type === 'doc' || item.type === 'word' ? <FileWordOutlined style={{ marginRight: 8, color: 'rgb(50, 116, 246)' }} /> : null
                    }
                    {item.name}
                  </div>
                </Descriptions.Item>
              )
            }
          })}
        </Descriptions>
      </div>
      <div className="basic-side">
        <Typography.Title level={5} style={{textAlign: 'left', marginBottom: 10}}>回执单：</Typography.Title>
        <Image.PreviewGroup>
          {
            dataSource?.receiptList?.map((item, index) => (
              <Image key={index} width={200} src={item.url} />
            ))
          }
        </Image.PreviewGroup>
      </div>
      <div className="basic-remarks">
        <Typography.Title level={5} style={{textAlign: 'left', marginBottom: 10}}>备注：</Typography.Title>
        <div className="remarks-text">
          {dataSource?.remark || '-'}
        </div>
      </div>
      <div className="basic-back">
        <Button onClick={() => {nav(-1)}}>返回</Button>
      </div>
          
    </Card>
  )
}
