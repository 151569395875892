import { Button, Modal } from 'antd'
import React from 'react'
import IconWarning from '../../../../../assets/warning.png'

import './index.less'

export type ModalTitleProps = {
  title?: string
  icon?: string
}

export type ModalFooterProps = {
  confirmLoading?: boolean
  onOk?: () => void
  onCancel?: () => void
}

export type ModalProps = {
  visible: boolean
  loading?: boolean
  title?: string
  children?: React.ReactNode
  onOk?: (values?: any) => void
  onCancel?: () => void
}

export const ModalTitle = React.memo((props: ModalTitleProps) => {
  const { title, icon } = props
  return (
    <h3 className="remove-modal-title">
      {icon && <img src={icon} alt="" />}
      <span>{title}</span>
    </h3>
  )
})

export const ModalFooter = React.memo((props: ModalFooterProps) => {
  const { confirmLoading, onCancel, onOk } = props
  return (
    <div className="remove-modal-footer">
      {onCancel && <Button onClick={onCancel}>取消</Button>}
      <Button loading={confirmLoading} type="primary" onClick={onOk}>
        确定
      </Button>
    </div>
  )
})

const RemoveModal = React.memo((props: ModalProps) => {
  const { visible, title, children, loading, onOk, onCancel } = props
  return (
    <Modal
      className="remove-modal"
      visible={visible}
      closable={false}
      destroyOnClose={true}
      width={480}
      title={<ModalTitle icon={IconWarning} title={title} />}
      confirmLoading={loading}
      footer={<ModalFooter confirmLoading={loading} onCancel={onCancel} onOk={onOk} />}
    >
      <div className="remove-modal-content">{children}</div>
    </Modal>
  )
})

export default RemoveModal
