import React from 'react'
import { Modal } from 'antd'
import { FeeService } from '../../../../apis/wl-service'
import { TablePage, TablePageInstance } from '../../../../components/wl-table-page'

interface LogDialogProps {
  id?: string
  type: 'apply' | 'pay'
  visible: boolean
  visibleChange: (visible: boolean) => void
}

export default ({ id, type, visible, visibleChange }: LogDialogProps) => {
  const close = React.useCallback(() => {
    visibleChange(false)
  }, [])

  return (
    <Modal
      title="查看操作日志"
      centered
      className="cost-manage-dialog"
      destroyOnClose
      forceRender
      visible={visible}
      okText=""
      onCancel={close}
      width={1000}
      footer={null}
    >
      <TableLog id={id} type={type} visible={visible} />
    </Modal>
  )
}

const TableLog = ({ id, type, visible }) => {
  const tableRef = React.useRef<TablePageInstance>()

  React.useEffect(() => {
    visible && tableRef.current?.reload?.({ ...(type === 'apply' ? { id } : { pay_work_id: id }), page: 1 })
  }, [visible])

  const columns = () => {
    return [
      { dataIndex: 'option_name', align: 'center', width: 140, title: '操作人' },
      { dataIndex: 'option_company', align: 'center', width: 260, title: '操作公司' },
      { dataIndex: 'option_time', align: 'center', width: 180, title: '操作时间' },
      { dataIndex: 'tips', align: 'center', width: 140, title: '操作事件' },
      {
        dataIndex: 'json_result',
        align: 'center',
        width: 140,
        title: '操作结果',
        render: (text: any) => {
          try {
            text = JSON.parse(text)
          } catch (e) {
            console.warn(e)
            text = null
          }
          return <div>{text?.msg ?? ''}</div>
        },
      },
    ]
  }

  return (
    <TablePage
      autoLoad={false}
      componentRef={tableRef}
      rowKey={(...args: any[]) => args[1]}
      columns={columns as any}
      getter={(arg: any) =>
        type === 'apply' ? FeeService.applyLogs({ id, ...arg }) : FeeService.payLogs({ pay_work_id: id, ...arg })
      }
    />
  )
}
