import React, { useState } from 'react'
import { Descriptions, Modal, Spin, Statistic, Table, Tag } from 'antd'
import { FeeService } from '../../../../apis/wl-service'
import { moneyFromListStatusOptions } from './constants'

interface ApplyDetailDialogProps {
  id?: string
  visible: boolean
  visibleChange: (visible: boolean) => void
}

export default ({ id, visible, visibleChange }: ApplyDetailDialogProps) => {
  const [values, setValues] = useState<any>({})
  const [loading, setLoading] = useState<boolean>(false)

  React.useEffect(() => {
    if (id && visible) {
      setLoading(true)
      FeeService.applyDetail({ id })
        .then((resp: any) => {
          setValues(resp?.data ?? {})
        })
        .finally(() => {
          setLoading(false)
        })
    }
  }, [id, visible])

  const close = React.useCallback(() => {
    visibleChange(false)
  }, [])

  return (
    <Modal
      title="用款申请-明细"
      centered
      className="cost-manage-dialog"
      visible={visible}
      okText=""
      onCancel={close}
      width={1000}
      footer={null}
    >
      <Spin spinning={loading}>
        <Detail dataSource={values} />
      </Spin>
    </Modal>
  )
}

const Detail = ({ dataSource }) => {
  const list = React.useMemo(() => {
    return [
      { label: '项目名称', value: dataSource?.projectName },
      { label: '项目代码', value: dataSource?.projectCode },
      { label: '用款申请单号', value: dataSource?.apply_for_no },
      { label: '申请用款企业', value: dataSource?.company_name },
      {
        label: '申请金额',
        value: <Statistic title="" value={dataSource?.apply_for_money ?? 0} precision={2} prefix="￥" suffix="元" />,
      },
      { label: '合同号', value: dataSource?.contract_no },
      { label: '用款类型', value: dataSource?.use_money_type_desc },
      { label: '申请用途', value: dataSource?.user_of_fund },
      { label: '合同名称', value: dataSource?.contract_name },
      { label: '用款银行', value: dataSource?.bank_name },
      { label: '企业入金记录', value: <RecordTable dataSource={dataSource?.money_from_list ?? []} /> },
    ]
  }, [dataSource])
  return (
    <div>
      <Descriptions title="" bordered={false} column={{ xxl: 2, xl: 2, lg: 2, md: 2, sm: 2, xs: 1 }}>
        {list.map(({ label, value }: any, idx: number) => {
          return (
            <Descriptions.Item key={idx} label={label}>
              {value}
            </Descriptions.Item>
          )
        })}
      </Descriptions>
    </div>
  )
}

const RecordTable = ({ dataSource }) => {
  const columns = React.useMemo(() => {
    return [
      { dataIndex: 'in_money_amount_str', align: 'center', width: 400, title: '入金记录' },
      {
        dataIndex: 'in_money_amount',
        align: 'center',
        width: 300,
        title: '实际申请金额',
        render: (text: string) =>
          text ? <Statistic title="" value={text ?? 0} precision={2} prefix="￥" suffix="元" /> : null,
      },
      {
        dataIndex: 'status',
        align: 'center',
        width: 200,
        title: '状态',
        render: (text: string) => {
          const { fullName, color } = moneyFromListStatusOptions.find((v: any) => v.id === text?.toString()) ?? {}
          if (fullName) {
            return <Tag color={color}>{fullName}</Tag>
          }
          return null
        },
      },
    ]
  }, [])

  return (
    <Table
      rowKey={(...args: any[]) => args[1]}
      bordered
      className="bordered-table"
      columns={columns as any[]}
      dataSource={dataSource || []}
      // locale={{ emptyText: '暂无数据' }}
      pagination={false}
      size="small"
      summary={(pageData) => {
        let totalAmount = 0

        pageData.forEach(({ in_money_amount }) => {
          totalAmount += +in_money_amount
        })

        if (pageData.length === 0) {
          return null
        }

        return (
          <>
            <Table.Summary.Row className="table-stripe-row">
              <Table.Summary.Cell align="center" index={0}>
                合计
              </Table.Summary.Cell>
              <Table.Summary.Cell align="center" index={1} colSpan={2}>
                {totalAmount}
              </Table.Summary.Cell>
            </Table.Summary.Row>
          </>
        )
      }}
    />
  )
}
