import { Outlet } from 'react-router-dom'
import React from 'react'
import './index.less'


export const EngineeringManage = () => {
  return (
    <div className="no-overflow-content">
      <Outlet />
    </div>
  )
}
