import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Button, Card, Divider, Typography, Space } from 'antd'

import { BreadcrumbObservable, SideMenuObservable } from '../../../../application'
import { PolicyService } from '../../../../apis/wl-service'
import UploadFile from '../../../../components/wl-upload'
import { policyTypeOptions } from '../../constants'

export default () => {
  const params: {
    id?: string
    type?: string
  } = useParams()

  const nav = useNavigate()

  const [loading, setLoading] = useState<boolean>(true)
  const [detail, setDetail] = useState<any>({})

  useEffect(() => {
    SideMenuObservable.next(true)

    PolicyService.info({ id: params.id as string })
      .then((response) => {
        const result = response?.data || {}

        result.append_file = result.append_file ? JSON.parse(result.append_file) : []
        setDetail(result)

        const breadcrumb = [
          {
            name: '政策法规',
            url: getRootUrl(params.type),
          },
          {
            name: result.title,
            url: `/policy/detail/${result.id}/${params.type}`,
          },
        ]
        BreadcrumbObservable.next(breadcrumb)
      })
      .catch((error) => {
        console.warn(error)
      })
      .finally(() => {
        setLoading(false)
      })
  }, [])

  // 获取政策法规url
  const getRootUrl = (type = params.type) => {
    const current = policyTypeOptions.find((item: any) => `${item.id}` === type) ?? policyTypeOptions[0]
    return `/policy/manage?type=${current?.id}`
  }

  return (
    <Card
      className="no-border-bottom-card"
      style={{ height: '100%' }}
      title=""
      bodyStyle={{ paddingTop: 0 }}
      loading={loading}
      extra={
        <Button
          type="primary"
          onClick={() => {
            nav(getRootUrl())
          }}
        >
          返回
        </Button>
      }
    >
      <div style={{ textAlign: 'center' }}>
        <Typography.Title level={3}>{detail.title}</Typography.Title>
        <Space size={30} style={{ display: 'flex', marginBottom: 8, justifyContent: 'center' }}>
          <Typography.Text>作者：{detail.author_name}</Typography.Text>
          <Typography.Text>属地：{detail.policy_dependency}</Typography.Text>
        </Space>
        <Typography.Text>
          原文链接：
          <Typography.Link href={detail.source_text} target="_blank">
            {detail.source_text}
          </Typography.Link>
        </Typography.Text>
      </div>

      <Divider />

      <Typography.Paragraph>
        <p dangerouslySetInnerHTML={{ __html: detail.content }} />
      </Typography.Paragraph>

      {detail.append_file?.length === 0 ? (
        <Typography.Text>附件：暂无附件</Typography.Text>
      ) : (
        <UploadFile value={detail.append_file} disabled />
      )}
    </Card>
  )
}
