import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import classnames from 'classnames'
import { Row, Col, Typography, Space, Spin, SpaceProps } from 'antd'
import styles from './index.module.scss'

import { CustomIcons } from '../../../global'
import { BreadcrumbObservable, SideMenuObservable } from '../../../application'
import { InvestService } from '../../../apis/wl-service'
import PieChart from './components/pie'
import BarChart from './components/signing'
import permissionConfig from './config'
import store from '../project-library-create/components/store'

// 标题
export const MyTitle = (props: any) => {
  return <Typography.Title {...props} level={props.level ?? 4} style={{ marginBottom: 0, ...props.style }} />
}

export default () => {
  const [loading, setLoading] = useState<boolean>(false) // 页面加载状态
  const [permissions, setPermissions] = useState<any>({}) // 权限
  const [dataSource, setDataSource] = useState<DataType>({} as DataType) // 数据源

  // 设置面包屑
  useEffect(() => {
    SideMenuObservable.next(false)
    BreadcrumbObservable.next([
      {
        name: '投资开发',
        url: '/invest/workbench',
      },
      {
        name: '开发工作台',
        url: '/invest/workbench',
      },
    ])
  }, [])

  // 设置权限，根据权限显示不同页面
  useEffect(() => {
    // setPermissions(permissionConfig.given)
    // setPermissions(permissionConfig.partners)
    setPermissions(permissionConfig.developers)
  }, [])

  // 获取数据
  useEffect(() => {
    setLoading(true)

    InvestService.workbench()
      .then((resp: any) => {
        // setDataSource(resp?.data ?? {})
        setDataSource((previous: any) => ({ ...previous, ...(resp?.data ?? {}) }))
      })
      .catch((err: any) => {
        console.error(err)
      })
      .finally(() => {
        setLoading(false)
      })
  }, [])

  const renderEntryMenus = React.useMemo(() => {
    const rows: any[] = []
    let cols: any[] = []
    entryMenus.forEach((item: any, index: number, list: any) => {
      if (permissions?.entry?.includes(item.title)) {
        if (item.span === 5) {
          cols.push(
            <Col key={item.key} flex={1} style={{ maxWidth: '20%' }}>
              <Link to={item.pathname}>
                <Space onClick={() => { store.clearAll() }} direction="vertical" wrap={false} size={16} className={styles.verticalCenter}>
                  <CustomIcons type={item.iconType} style={{ fontSize: 40 }} />
                  <MyTitle level={5}>{item.title}</MyTitle>
                </Space>
              </Link>
            </Col>,
          )
        } else if (item.span === 3) {
          const node = (
            <Col key={item.key} span={8}>
              <Link to={item.pathname}>
                <Space direction="horizontal" wrap={false} size={24} className={styles.horizontalCenter}>
                  <CustomIcons type={item.iconType} style={{ fontSize: 32 }} />
                  <Space direction="vertical">
                    <MyTitle level={5}>{item.title}</MyTitle>
                    <Typography.Text>{item.description?.(dataSource)}</Typography.Text>
                  </Space>
                </Space>
              </Link>
            </Col>
          )
          if (list[index - 1]?.span === 5 && cols.length > 3) {
            rows.push(
              <Row key={`row-auto-${item.key}`} gutter={[8, 8]} style={{ width: '100%' }}>
                {cols}
              </Row>,
            )
            cols = [node]
          } else {
            cols.push(node)
          }
        }
      }
    })
    if (cols.length) {
      rows.push(
        <Row key="row-3" gutter={[8, 8]} style={{ width: '100%' }}>
          {cols}
        </Row>,
      )
    }
    return rows
  }, [permissions, dataSource, entryMenus])

  const collectionData = React.useMemo(() => {
    return [
      {
        name: '收资完成',
        value: dataSource?.collectionData?.finish?.count ?? 0,
        percentage: `${dataSource?.collectionData?.finish?.ratio ?? 0}%`,
        itemStyle: { color: '#58a1ff' },
      },
      {
        name: '收资中',
        value: dataSource?.collectionData?.doing?.count ?? 0,
        percentage: `${dataSource?.collectionData?.doing?.ratio ?? 0}%`,
        itemStyle: { color: '#f6d235' },
      },
      {
        name: '未收资',
        value: dataSource?.collectionData?.unFinish?.count ?? 0,
        percentage: `${dataSource?.collectionData?.unFinish?.ratio ?? 0}%`,
        itemStyle: { color: '#f17b49' },
      },
    ]
  }, [dataSource])

  const recordData = React.useMemo(() => {
    return [
      {
        name: '备案完成',
        value: dataSource?.recordData?.finish?.count ?? 0,
        percentage: `${dataSource?.recordData?.finish?.ratio ?? 0}%`,
        itemStyle: { color: '#58a1ff' },
      },
      {
        name: '备案中',
        value: dataSource?.recordData?.doing?.count ?? 0,
        percentage: `${dataSource?.recordData?.doing?.ratio ?? 0}%`,
        itemStyle: { color: '#67d2a4' },
      },
      {
        name: '未备案',
        value: dataSource?.recordData?.unFinish?.count ?? 0,
        percentage: `${dataSource?.recordData?.unFinish?.ratio ?? 0}%`,
        itemStyle: { color: '#827bff' },
      },
    ]
  }, [dataSource])

  return (
    <div className={classnames('right-actions', styles.container)}>
      <Spin spinning={loading}>
        <Space style={{ width: '100%' }} direction="vertical">
          {renderEntryMenus}
        </Space>
        <Row gutter={[8, 8]}>
          {permissions.collecting && dataSource?.collectionData && (
            <Col span={permissions.record ? 12 : 24}>
              <PieChart title="收资情况" dataSource={collectionData} />
            </Col>
          )}
          {permissions.record && dataSource?.recordData && (
            <Col span={permissions.collecting ? 12 : 24}>
              <PieChart title="项目备案与接入" dataSource={recordData} />
            </Col>
          )}
          <BarChart permissions={permissions} />
        </Row>
      </Spin>
    </div>
  )
}

// 面板卡片数据
const menuSpaceProps: SpaceProps = {
  direction: 'horizontal',
  wrap: true,
  size: [8, 0],
}
export const entryMenus = [
  {
    span: 5,
    title: '合作伙伴签约',
    key: 'partner-signing',
    iconType: 'icon-hetongguanli',
    pathname: '/invest/contract?type=partner', // 合同审批/新建合同
  },
  {
    span: 5,
    title: '在线收资',
    key: 'collect-money-online',
    iconType: 'icon-a-24gf-fileText',
    pathname: '/invest/projectLibrary/true?s=2', // 项目库/新建/收资信息
  },
  {
    span: 5,
    title: '项目方案展示',
    key: 'project-plan-display',
    iconType: 'icon-a-wenjianjiawenjian',
    pathname: '/program/manage', // 项目设计/项目管理列表
  },
  {
    span: 5,
    title: '客户签约',
    key: 'customer-signing',
    iconType: 'icon-hetongguanli1',
    pathname: '/invest/contract?type=customer', // 合同审批/新建合同
  },
  {
    span: 5,
    title: '项目接入与备案',
    key: 'project-filing-and-access',
    iconType: 'icon-11document',
    pathname: '/invest/projectLibrary/true?s=1', // 项目库/新建项目/新建资源
  },
  {
    span: 3,
    title: '资源开发',
    key: 'resourceDevelopment',
    iconType: 'icon-zykf',
    pathname: '/invest/resourceDevelopment',
    description: (data: any) => {
      return (
        <Space {...menuSpaceProps}>
          <MyTitle type="success">{data?.resourceDevelop}</MyTitle>
          (个)项目
        </Space>
      )
    },
  },
  {
    span: 3,
    title: '投资决策',
    key: 'decision',
    iconType: 'icon-peixunjihuaoff',
    pathname: '/invest/decision',
    description: (data: any) => {
      return (
        <Space {...menuSpaceProps}>
          <MyTitle type="success">{data?.decision}</MyTitle>
          (个)项目
        </Space>
      )
    },
  },
  {
    span: 3,
    title: '核准备案',
    key: 'record',
    iconType: 'icon-wenjianjia',
    pathname: '/invest/record',
    description: (data: any) => {
      return (
        <Space {...menuSpaceProps}>
          <MyTitle type="success">{data?.record}</MyTitle>
          (个)项目
        </Space>
      )
    },
  },
  // {
  //   span: 3,
  //   title: '建设',
  //   key: 'construction',
  //   iconType: 'icon-gengduo1',
  //   pathname: '/projectManagement/construction',
  //   description: (data: any) => {
  //     return (
  //       <Space {...menuSpaceProps}>
  //         <MyTitle type="success">{data?.production}</MyTitle>
  //         (个)项目
  //       </Space>
  //     )
  //   },
  // },
  // {
  //   span: 3,
  //   title: '项目造价',
  //   key: 'cost',
  //   iconType: 'icon-channengjihua',
  //   pathname: '/projectManagement/cost',
  //   description: (data: any) => {
  //     return (
  //       <Space {...menuSpaceProps}>
  //         <MyTitle type="success">{data?.cost}</MyTitle>
  //         (个)项目
  //       </Space>
  //     )
  //     // const span = { xs: 24, xl: 12 }
  //     // return (
  //     //   <Row>
  //     //     <Col {...span}>
  //     //       <Space direction="horizontal" wrap={true}>
  //     //         <MyTitle type="success">{data?.cost?.front}</MyTitle>
  //     //         (个)可研估算
  //     //       </Space>
  //     //     </Col>
  //     //     <Col {...span}>
  //     //       <Space direction="horizontal" wrap={true}>
  //     //         <MyTitle type="success">{data?.cost?.middle}</MyTitle>
  //     //         (个)初设概算
  //     //       </Space>
  //     //     </Col>
  //     //     <Col {...span}>
  //     //       <Space direction="horizontal" wrap={true}>
  //     //         <MyTitle type="success">{data?.cost?.rear}</MyTitle>
  //     //         (个)竣工结算
  //     //       </Space>
  //     //     </Col>
  //     //   </Row>
  //     // )
  //   },
  // },
  // {
  //   span: 3,
  //   title: '投资计划',
  //   key: 'investmentPlan',
  //   iconType: 'icon-xiangmu',
  //   pathname: '/projectManagement/investmentPlan',
  //   description: (data: any) => {
  //     return (
  //       <Space {...menuSpaceProps}>
  //         <MyTitle type="success">{data?.investPlan}</MyTitle>
  //         (个)项目
  //       </Space>
  //     )
  //   },
  // },
  // {
  //   span: 3,
  //   title: '计划执行',
  //   key: 'planExecution',
  //   iconType: 'icon-gongzuojihua',
  //   pathname: '/projectManagement/planExecution',
  //   description: (data: any) => {
  //     return (
  //       <Space {...menuSpaceProps}>
  //         <MyTitle type="success">{data?.planExecute}</MyTitle>
  //         (个)项目
  //       </Space>
  //     )
  //   },
  // },
  {
    span: 3,
    title: '待办事项',
    key: 'todo',
    iconType: 'icon-youjian',
    pathname: '/invest/agency/todo',
    description: (data: any) => {
      return (
        <Space {...menuSpaceProps}>
          未办理事项:
          <MyTitle type="success">{data?.unreadMessages}</MyTitle>
          (条)消息
        </Space>
      )
    },
  },
  {
    span: 3,
    title: '已办事项',
    key: 'done',
    iconType: 'icon-yiduxiaoxi',
    pathname: '/invest/agency/done',
    description: (data: any) => {
      return (
        <Space {...menuSpaceProps}>
          已办理事项:
          <MyTitle type="warning">{data?.readMessages}</MyTitle>
          (条)消息
        </Space>
      )
    },
  },
]

type DataType = {
  collectionData: {
    doing: {
      count: number // 个数
      ratio: number // 百分比
    }
    finish: {
      count: number // 个数
      ratio: number // 百分比
    }
    unFinish: {
      count: number // 个数
      ratio: number // 百分比
    }
  }
  recordData: {
    doing: {
      count: number // 个数
      ratio: number // 百分比
    }
    finish: {
      count: number // 个数
      ratio: number // 百分比
    }
    unFinish: {
      count: number // 个数
      ratio: number // 百分比
    }
  }
  cost: number // 项目造价
  decision: number // 投资决策
  investPlan: number // 投资计划
  planExecute: number // 计划执行
  production: number // 项目建设
  record: number // 核准备案
  resourceDevelop: number // 资源开发
  readMessages: number // 已办事项-已读消息
  unreadMessages: number // 待办事项-未读消息
}
