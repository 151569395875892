import { useState, useEffect } from 'react'
import { Modal, Button, Input, Form, Space, Spin } from 'antd'
import { PlusOutlined, SearchOutlined } from '@ant-design/icons'

import ProjectList from './ProjectList'
import styles from '../../index.module.scss'
import { TechnologyService, ProjectServiceHistory } from '../../../../apis/project-service-history'
import Global from '../../../../global'
import _ from 'lodash'

// 添加技术方案
const TechnologyList = (props: any) => {
  const { visible, onCancel } = props
  const [loading, setLoading] = useState(false)
  const [listLoading, setListLoading] = useState(false)
  const [projectList, setProjectList] = useState([])
  const [form] = Form.useForm()

  const searchProject = () => {
    const text = form.getFieldValue('searchText')
    setListLoading(true)
    ProjectServiceHistory.list({
      name: text,
      tpnSort: '0',
      fsnSort: '0',
      createTimeSort: '0',
      updateTimeSort: '0',
      operateNameSort: '0',
      page: '0',
      size: '10000',
    })
      .then((res) => {
        setProjectList(res?.data?.data || [])
      })
      .catch(() => {})
      .finally(() => {
        setListLoading(false)
      })
  }

  const createScheme = () => {
    const text = form.getFieldValue('path')
    setLoading(true)
    TechnologyService.add({ projectId: text })
      .then((res) => {
        const id = res.data
        jumpTo(`#/workspace/${id}/tech`)
      })
      .catch(() => {})
      .finally(() => {
        setLoading(false)
      })
  }

  const jumpTo = (url: string) => {
    if (url) {
      location.assign(`${url}`)
      Global.changeMenu.forEach((Fn: any) => {
        Fn(url)
      })
    }
  }

  useEffect(() => {
    searchProject()
  }, [])

  return (
    <Modal
      className={styles.addModal}
      visible={visible}
      title={<strong>新建技术方案</strong>}
      maskClosable={false}
      onCancel={onCancel}
      keyboard={false}
      footer={false}
      width={640}
    >
      <Form form={form} onFinish={createScheme}>
        <div className="search-label">
          <div>
            <span className="title">技术方案路径</span>
            <span className="valid">*</span>
            <span className="desc">请选择项目，用项目来管理技术方案</span>
          </div>
          <Button
            type="link"
            icon={<PlusOutlined />}
            onClick={() => {
              jumpTo(`#/program/manage?add=1`)
            }}
          >
            新建项目
          </Button>
        </div>
        <Form.Item help="" name="searchText">
          <Input
            size="large"
            type="text"
            placeholder="请输入"
            onChange={_.debounce(searchProject, 300)}
            onPressEnter={searchProject}
            suffix={<SearchOutlined />}
          />
        </Form.Item>
        <Spin spinning={listLoading}>
          <Form.Item
            help=""
            name="path"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <ProjectList dataSource={projectList} />
          </Form.Item>
        </Spin>
        <div className="btns">
          <Space>
            <Button className="actived" onClick={onCancel}>
              取消
            </Button>
            <Button type="primary" htmlType="submit" loading={loading}>
              确定
            </Button>
          </Space>
        </div>
      </Form>
    </Modal>
  )
}

export default TechnologyList
