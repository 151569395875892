import { Button, Col, Form, InputNumber, Row } from 'antd'
import ReactECharts from 'echarts-for-react'
import React from 'react'
import { ProjectWorkbenchService } from '../../../apis/project-service-history'

export const DemandLimit = (props: any) => {
  const [demandLimit, setDemandLimit] = React.useState(props.data)
  const [form] = Form.useForm()
  const [uploading, setUploading] = React.useState(false)
  const [, setVer] = React.useState(0)

  React.useEffect(() => {
    form.setFieldsValue({ ...demandLimit })
    setVer((ver) => ver + 1)
  }, [demandLimit])

  return (
    <div>
      <ReactECharts
        style={{
          width: '100%',
          height: 190,
          marginTop: -16,
        }}
        option={{
          grid: { left: 0, right: 0, top: 30, bottom: 0, containLabel: true },
          tooltip: {
            trigger: 'axis',
            formatter: (argsList: any) => {
              const args = argsList[0]
              return `${args.seriesName}<br />${args.marker}${args.name}月: ${args.value}kW`
            },
          },
          xAxis: {
            type: 'category',
            data: Object.keys(demandLimit),
            axisLabel: {
              formatter: (str: string) => {
                return `${str}月`
              },
            },
            axisTick: {
              show: false,
            },
          },
          yAxis: {
            type: 'value',
            boundaryGap: ['0%', '10%'],
            name: 'kW',
            nameTextStyle: {
              color: 'gray',
              padding: [0, 40, 0, 0],
            },
            splitLine: {
              lineStyle: {
                type: [5, 5],
                dashOffset: 5,
              },
            },
          },
          series: [
            {
              name: '需量',
              type: props.type || 'bar',
              data: Object.values(form.getFieldsValue()),
              barWidth: Math.min(300 / Object.values(form.getFieldsValue()).length, 32),
              itemStyle: {
                color: '#1EC692',
                barBorderRadius: [4, 4, 0, 0],
              },
            },
          ],
        }}
      />
      <div>
        <h2>需量</h2>
        <Form
          onValuesChange={() => {
            setVer((ver) => ver + 1)
          }}
          form={form}
          wrapperCol={{ span: 18 }}
          labelCol={{ span: 6 }}
        >
          <Row>
            {Object.keys(demandLimit).map((o) => (
              <Col
                span={12}
                key={o}
                style={{
                  paddingRight: 10,
                }}
              >
                <Form.Item
                  label={`${o}月`}
                  name={o}
                  style={{
                    alignItems: 'center',
                  }}
                >
                  <InputNumber controls={false} addonAfter="kW" min={0} precision={2} />
                </Form.Item>
              </Col>
            ))}
          </Row>
        </Form>
      </div>
      <div className="ant-modal-confirm-btns">
        <Button
          type="link"
          onClick={() => {
            ProjectWorkbenchService.getDefaultDemand({
              technicalProposalId: props.technicalProposalId,
            }).then((resp) => {
              form.setFieldsValue({ ...resp.data })
              setVer((ver) => ver + 1)
            })
          }}
        >
          重 置
        </Button>
        <Button
          onClick={() => {
            props.onClose?.()
          }}
        >
          取 消
        </Button>
        <Button
          type="primary"
          loading={uploading}
          onClick={() => {
            setUploading(true)
            const valMap = form.getFieldsValue()
            ProjectWorkbenchService.updateDemand({
              technicalProposalId: props.technicalProposalId,
              root: Object.keys(valMap).map((o) => ({
                month: o,
                demand: valMap[o],
              })),
            })
              .then(() => {
                props.onClose?.(true)
              })
              .catch(() => {
                setDemandLimit({ ...props.data })
              })
              .finally(() => {
                setUploading(false)
              })
          }}
        >
          确 定
        </Button>
      </div>
    </div>
  )
}
