import { Modal, message } from 'antd'
import { ResourceService } from '../../../apis/wl-service'

export default ({
  params,
  success,
}: {
  params: { id: string; dataId: string; moduleId: string }
  success: () => void
}) =>
  // Modal.destroyAll()
  Modal.confirm({
    keyboard: false,
    title: '提示',
    type: 'warning',
    content: '您确认要提交该条数据吗,提交后将上报给部门领导,后续修改需要进行修改申请!是否继续?',
    width: 500,
    okType: 'primary',
    okText: '确定',
    okButtonProps: {
      // danger: true,
    },
    onOk: () => {
      ResourceService.commit(params)
        .then((resp: any) => {
          if (resp?.code === 200) {
            message.success(resp?.msg, 1, () => success?.())
          } else if (resp?.msg) {
            message.warning(resp?.msg)
          }
        })
        .catch(() => {})
    },
    cancelText: '取消',
    cancelButtonProps: {
      // danger: true,
    },
  })
