import { Button, Form, Input, message, Modal } from 'antd'
import React, { useContext } from 'react'
import { useNavigate, useParams } from 'react-router'
import { CustomIcons } from '../../../../../global'
import { CurrAuthContext } from '../../config'
import ProjectManageControllerApi from '../../../../../services/api/ProjectManageControllerApi'

import '../index.less'

const SettingsChange = React.memo(() => {
  const { info }: any = useContext(CurrAuthContext)
  const nav = useNavigate()
  const { id } = useParams()
  const [deleteForm] = Form.useForm()
  const deleteItem = () => {
    Modal.confirm({
      keyboard: false,
      title: '删除项目',
      icon: <CustomIcons type="klf-alarm1" />,
      content: (
        <div>
          {/* <p>确认删除项目？该项目下所有资源将无法恢复</p>
          <p>
            请输入此项目的名称 &quot;<strong>{info.name}</strong>&quot; 以确定删除
          </p> */}
          <p>确认删除项目？删除项目后，仅组织管理员可在后台管理的回收站中恢复项目</p>
          <p>
            请输入该项目名称“<strong>{info.name}</strong>”以确认删除
          </p>
          <Form form={deleteForm}>
            <Form.Item
              style={{
                marginBottom: 0,
              }}
              name="projectName"
              required
              rules={[
                {
                  required: true,
                  message: '请输入此项目的名称',
                },
              ]}
            >
              <Input placeholder="请输入"></Input>
            </Form.Item>
          </Form>
        </div>
      ),
      width: 500,
      okType: 'primary',
      okText: '确定',
      okButtonProps: {
        danger: true,
      },
      onOk: () => {
        const { projectName } = deleteForm.getFieldsValue()
        return new Promise((resolve, reject) => {
          if (projectName !== info?.name) {
            message.error('请输入正确的项目名称').then()
            reject()
          } else {
            deleteForm
              .validateFields()
              .then(() => {
                ProjectManageControllerApi.i3060ProjectManageDeleteProjectIdPost(
                  {
                    type: '1',
                    projectId: id,
                  },
                  {
                    type: '1',
                    projectId: id,
                  }
                )
                  .then((resp) => {
                    if (resp.data) {
                      message.success('操作成功!')
                      nav('/program/manage')
                    }
                    resolve(resp)
                  })
                  .catch((e) => reject(e))
              })
              .catch((e) => reject(e))
          }
        })
      },
      cancelText: '取消',
      cancelButtonProps: {
        danger: true,
      },
    })
  }

  return (
    <section className="set-up-change">
      <h3 className="set-up-change-title">删除项目</h3>
      <p className="set-up-change-tips">删除项目会删除项目内的数据。</p>
      <Button danger size="large" onClick={deleteItem}>
        删除
      </Button>
    </section>
  )
})

export default SettingsChange
