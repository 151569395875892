import React from 'react'

import { SubmitType } from '../../../project-entry/project-moudle-detail/common/generatorSubmit'
export type { SubmitType }

export interface IFormRef {
  getName: () => string
  submit: (options: SubmitType) => void
}

export interface IProject {
  mark?: string
  project_id?: string
  project_name?: string
  projectModel: {
    name?: string
    address?: string
    cityCode?: string
    latitude?: string
    longitude?: string
  }
}

export type IAction = {
  type:
    | 'visibleDraft'
    | 'visibleSubmit'
    | 'loadingDraft'
    | 'loadingSubmit'
    | 'setCurrentProject'
    | 'setProjectTypeOptions'
  payload: any
}
export interface IState {
  loadingDraft: boolean
  loadingSubmit: boolean
  visibleDraft: boolean
  visibleSubmit: boolean
  currentProject: {
    order: string[]
    production?: IProject
    cost?: IProject
    investPlan?: IProject
    planExecute?: IProject
  }
}

export const StateContext = React.createContext({}) // 创建并导出context
