import React, { useState, useEffect, useMemo, useCallback } from 'react'
import { useSearchParams } from 'react-router-dom'
import { Form, Input, Select, Row, Col, Modal, message } from 'antd'
import { FormInstance } from 'antd/lib/form'

import { PolicyService } from '../../../apis/wl-service'
import AddressSelector from '../../../components/wl-address'
import Editor from '../../../components/wl-editor'
import UploadFile from '../../../components/wl-upload'
import { policyTypeOptions } from '../constants'

interface IProps {
  disabled?: boolean
  form: FormInstance
  value?: any
}
export const CreateForm: React.FC<IProps> = ({ disabled, value: formData = {}, form }) => {
  const [searchParams] = useSearchParams()
  const [showDependency, setShowDependency] = useState<Boolean>(false) // 是否显示法规属地

  useEffect(() => {
    form.resetFields() // 重置表单数据
    formData.policy_type_id = formData.policy_type_id?.toString() ?? searchParams.get('type')
    formData.policy_dependency = formData.policy_dependency ? formData.policy_dependency?.split(',') : []
    form.setFieldsValue(formData)
    setShowDependency(formData?.policy_type_id === '6')
  }, [formData])

  const layout = useMemo(() => {
    return {
      common: {
        labelCol: { span: 3 },
        wrapperCol: { span: 21 },
      },
      twoColumn: {
        labelCol: { span: 6 },
        wrapperCol: { span: 18 },
      },
    }
  }, [])

  return (
    <Form size="large" autoComplete="off" form={form} layout="horizontal" {...layout.common} initialValues={formData}>
      <Form.Item name="title" label="标题">
        <Input disabled={disabled} allowClear placeholder="请输入" />
      </Form.Item>
      <Row>
        <Col span={12}>
          <Form.Item label="政策类型" name="policy_type_id" {...layout.twoColumn}>
            <Select
              placeholder="请选择"
              disabled
              allowClear
              style={{ minWidth: 100 }}
              onChange={useCallback((id: string) => {
                setShowDependency(id === '6')
              }, [])}
            >
              {policyTypeOptions?.map((item: any) => {
                return <Select.Option key={item.id}>{item.name}</Select.Option>
              })}
            </Select>
          </Form.Item>
        </Col>
        <Col span={12}>
          {showDependency && (
            <Form.Item label="政策属地" name="policy_dependency" {...layout.twoColumn}>
              <AddressSelector disabled={disabled} />
            </Form.Item>
          )}
        </Col>
      </Row>
      <Form.Item label="摘要" name="summary">
        <Input.TextArea autoSize={{ minRows: 4, maxRows: 4 }} disabled={disabled} allowClear placeholder="请输入" />
      </Form.Item>
      <Form.Item label="原文链接" name="source_text">
        <Input disabled={disabled} allowClear placeholder="请输入" />
      </Form.Item>
      <Form.Item label="正文" name="content">
        <Editor disabled={disabled} />
      </Form.Item>
      <Form.Item label="附件" name="append_file">
        <UploadFile disabled={disabled} />
      </Form.Item>
    </Form>
  )
}

interface IModalFormProps {
  visible: boolean
  isDetail?: boolean
  value?: any
  onCancel?: any
  onSuccess?: () => void
}

export default ({ isDetail = false, ...props }: IModalFormProps) => {
  const [form] = Form.useForm()
  const [loading, setLoading] = useState<boolean>(false)

  return (
    <Modal
      visible={props.visible}
      width={1000}
      title={isDetail ? '详情' : !props.value?.id ? '发布' : '编辑'}
      maskClosable={false}
      okButtonProps={{ loading, disabled: isDetail }}
      afterClose={() => {
        form.resetFields()
      }}
      onCancel={props.onCancel}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            setLoading(true)

            values.append_file = Array.isArray(values.append_file)
              ? JSON.stringify(values.append_file)
              : values.append_file

            values.policy_dependency = Array.isArray(values.policy_dependency)
              ? values.policy_dependency.join(',')
              : values.policy_dependency ?? ''

            PolicyService.update({ id: props.value?.id, root: values })
              .then((response) => {
                if (response?.code === 200) {
                  message.success(response.msg, 2, () => {
                    props.onSuccess?.()
                  })
                } else {
                  message.error(response.msg, 2, () => {})
                }
              })
              .catch(() => {})
              .finally(() => {
                setLoading(false)
              })
          })
          .finally(() => {})
      }}
    >
      <CreateForm form={form} disabled={isDetail} value={props.value} />
    </Modal>
  )
}
