import './t-data-screen.less'
import {parseNumber} from "../../../resource-reckon/resource-map";
import {Button, Col, Modal, Row, Tabs, Tooltip} from "antd";
import React, {useEffect, useState} from "react";
import {LargeScreenControllerService} from "../../../../apis/project-service";
import ReactECharts from "echarts-for-react";
import Global, {ChartColorMaps} from "../../../../global";
import moment from "moment";
import * as echarts from "echarts";

// @ts-ignore
import mapboxgl from '!mapbox-gl'
import {TDataScreenModal} from "./t-data-screen-modal";
import {BreadcrumbObservable} from "../../../../application";

export const kxReg = /(?!^)(?=(\d{3})+$)/g;


const contributeConfigurations = [
  {
    icon: 'assets/jtl.svg',
    title: '节碳量',
    unit: 't',
    key: 'co',
  },
  {
    icon: 'assets/dxzs.svg',
    title: '等效种树',
    unit: '棵',
    key: 'tree',
  },
  {
    icon: 'assets/dxcl.svg',
    title: '等效车辆',
    unit: '辆',
    key: 'car',
  },
]

const configurations = [
  {
    icon: 'assets/dqfdgl.svg',
    title: '当前发电功率',
    unit: 'kW',
    key: 'realTimePower',
  },
  {
    icon: 'assets/nfdxss.svg',
    title: '消纳率',
    unit: '%',
    key: 'absorptionRate',
  },
  {
    icon: 'assets/drfdl.svg',
    title: '当日发电量',
    unit: 'kWh',
    key: 'generationElectricity',
  },
  {
    icon: 'assets/ljfdl.svg',
    title: '累计发电量',
    unit: 'kWh',
    key: 'totalGenerationElectricity',
  },
  {
    icon: 'assets/zjrl.svg',
    title: '装机容量',
    unit: 'kW',
    key: 'installedCapacity',
  },
  {
    icon: 'assets/dzsl.svg',
    title: '电站数量',
    unit: '个',
    key: 'powerStationNum',
  },
]

export const TDataScreen = () => {

  useEffect(() => {
    BreadcrumbObservable.next([])
  }, [])

  const [data, setData] = useState({});
  const [capacityData, setCapacityData] = useState<any>();
  const [countData, setCountData] = useState<any>();
  const [monitorData, setMonitorData] = useState<any>();

  const [realTimeData, setRealTimeData] = useState<any>();

  const [rankData, setRankData] = useState<any[]>();

  const loadRealtimeData = () => {
    LargeScreenControllerService.getI3060LargeScreenRealTimePowerChart().then(resp => {
      const xData = [];
      const data = moment().startOf('day');
      for (let i = 0; i < 295; i++) {
        if (i > 288) {
          xData.push('25:00:00');
        } else if (i === 288) {
          xData.push('24:00:00');
        } else {
          xData.push(data.format('HH:mm:ss'));
          data.add(5, 'minutes');
        }
      }
      setRealTimeData({
        dataUpdateTime: resp.data.dataUpdateTime,
        xdata: xData,
        ydata: resp.data.chartRes.map(o => o.ydata),
      })
    })

  }

  const loadData = () => {

    LargeScreenControllerService.getI3060LargeScreenPowerStationRanking().then(resp => {
      setRankData(resp.data);
    })

    loadRealtimeData();

    LargeScreenControllerService.getI3060LargeScreenStatisticsColumn().then(
      (resp) => {
        setData(resp.data);
      }
    )

    LargeScreenControllerService.getI3060LargeScreenHistogram({
      type: '1',
    }).then((resp) => {
      setCapacityData({
        xdata: resp.data.map(o => o.xdata),
        ydata: resp.data.map((o) => o.ydata),
      });
    })

    LargeScreenControllerService.getI3060LargeScreenHistogram({
      type: '2',
    }).then((resp) => {
      setCountData({
        xdata: resp.data.map(o => o.xdata),
        ydata: resp.data.map((o) => o.ydata),
      });
    })

    LargeScreenControllerService.getI3060LargeScreenHistogram({
      type: '3',
    }).then((resp) => {
      setMonitorData({
        xdata: resp.data.map(o => moment(o.xdata).format('YYYY-MM-DD')),
        ydata: resp.data.map((o) => o.ydata),
      });
    })
  }

  useEffect(() => {
    setTimeout(() => {
      loadData();
      getMapData();
    }, 300);

    const itv = setInterval(() => {
      loadData();
      loadRealtimeData();
    }, 5 * 60000);

    return () => {
      clearInterval(itv);
    }
  }, []);

  const getMapData = () => {
    LargeScreenControllerService.getI3060LargeScreenPowerStationDistribute().then(resp => {
      createMapbox(resp.data);
    }).catch(() => {
      createMapbox([]);
    })
  }

  const createMapbox = (data: any[]) => {

    mapboxgl.accessToken = 'pk.eyJ1Ijoib3dvcHMiLCJhIjoiY2wxeHBieXhxMDUxYjNicWZua2R5a3RqayJ9.yjsA1-1sWYHfY7Ea5IeArg'

    const map = new mapboxgl.Map({
      style: 'mapbox://styles/owops/cl2h1u3kx000u15o26t9bmtyh',
      center: [116.418261, 39.921984],
      zoom: 4,
      minZoom: 1,
      maxZoom: 20,
      container: 't-screen-map',
      projection: 'equirectangular' // starting projection
    } as any)

    map.on('load', () => {
      const geojson = {
        'type': 'FeatureCollection',
        'features': [...data].reverse().map((o) => ({
          'type': 'Feature',
          'properties': {
            'description':
              `<div style="width: 300px;">
                    <div style="font-size: 14px; color: #262E3F; margin-bottom: 15px;">${o.name}</div>
                    <div style="font-size: 12px; color: #98A0B3; display: flex; flex-direction: row; align-items: flex-start"><div>地址：</div><div style="width: 250px;">${o.address || ''}</div></div>
                </div>`,
            ...o,
          },
          'geometry': {
            'type': 'Point',
            'coordinates': [parseFloat(o.lng), parseFloat(o.lat)]
          }
        }))
      }

      const source = {
        'type': 'geojson',
        'data': geojson,
      }

      const bound = [ 73.502355, 20.83703, 175.09567, 53.563624 ]
      const spl = 9
      const dx = bound[2] - bound[0]
      const dy = bound[3] - bound[1]
      const dsx = dx / spl
      const dsy = dy / spl
      const lt: [number, number] = [bound[0] - dsx, bound[1] - dsy]
      const rb: [number, number] = [bound[2] + dsx, bound[3] + dsy]
      const min = map.project(lt)
      const max = map.project(rb)
      map.fitScreenCoordinates(min, max, map.getBearing())

      map.addSource('places', source);
      map.loadImage(
        './assets/map-icon.png',
        (error, image) => {
          if (error) throw error;
          map.addImage('custom-marker', image);

          map.addLayer({
            'id': 'places',
            'type': 'symbol',
            'source': 'places',
            'layout': {
              'icon-image': 'custom-marker',
              "icon-size": .6,
              'text-field': ['get', 'title'],
              'text-offset': [0, 1.25],
              'text-anchor': 'top'
            }
          });
        });

      const popup = new mapboxgl.Popup({
        closeButton: false,
        closeOnClick: false,
        offset: 25,
        maxWidth: 300,
      });

      map.on('mouseenter', 'places', (e) => {
        map.getCanvas().style.cursor = 'pointer';

        const coordinates = e.features[0].geometry.coordinates.slice();
        const description = e.features[0].properties.description;

        while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
          coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
        }

        popup.setLngLat(coordinates).setHTML(description).addTo(map);
      });

      map.on('click', 'places', (e) => {
        const data = e.features[0].properties;
        Modal.confirm({
          closable: true,
          className: 'data-screen-modal',
          content: <TDataScreenModal {...data}/>,
          icon: null,
          width: 688,
          okButtonProps: {
            style: {
              display: "none",
            }
          },
          cancelButtonProps: {
            style: {
              display: "none",
            }
          },
        });
      });


      map.on('mouseleave', 'places', () => {
        map.getCanvas().style.cursor = '';
        popup.remove();
      });
    })
  }


  return <div className="tds-main">
    <div className="tds-cards">
      {
        configurations.map(o => (<div key={o.key} className="tds-card">
          <div className="tds-card-icon">
            <img alt="" src={o.icon}/>
          </div>
          <div className="tds-card-body">
            <div className="tds-card-title">{o.title}</div>
            <div className="tds-card-content">
              {parseNumber(data?.[o.key] || 0, {
                radix: 2,
                type: '',
              }).toString().replace(kxReg, ',')}
              <span className="tds-card-unit">{o.unit}</span>
            </div>
          </div>
        </div>))
      }
    </div>
    <div style={{
      display: "flex",
      flexDirection: "column",
      flexGrow: 1,
    }}>
      <Row className="tds-content">
        <Col span={6} className="tds-left-content">
          <div className="tds-stations">
            <div>
              <div className="tds-content-card-title">电站建设</div>
              <div className="tds-content-card-body">
                <Tabs>
                  <Tabs.TabPane tab="装机容量" key="capacity">
                    {
                      capacityData && <ReactECharts
                        style={{
                          width: 'calc(100% - 18px)',
                          height: 150,
                        }}
                        option={{
                          grid: {left: '5px', top: '30px', bottom: 0, right: 5, containLabel: true},
                          tooltip: {
                            trigger: 'axis',
                            formatter: (argsList: any) => {
                              const args = argsList[0]
                              return `${args.marker}${args.name}<br /><div style="text-align: right;">${args.value}kW</div>`
                            },
                          },
                          xAxis: {
                            type: 'category',
                            boundaryGap: true,
                            data: capacityData.xdata,
                          },
                          yAxis: {
                            type: 'value',
                            name: 'kW',
                            nameTextStyle: {
                              color: 'gray',
                            },
                            splitLine: {
                              lineStyle: {
                                type: [5, 5],
                                dashOffset: 5,
                              },
                            },
                          },
                          series: [
                            {
                              name: '月',
                              type: 'bar',
                              smooth: true,
                              showSymbol: false,
                              data: capacityData.ydata,
                              itemStyle: {
                                color: ChartColorMaps[0].line,
                              },
                            },
                          ],
                        }}
                      />
                    }
                  </Tabs.TabPane>
                  <Tabs.TabPane tab="电站数量" key="count">
                    {
                      countData && <ReactECharts
                        style={{
                          width: 'calc(100% - 18px)',
                          height: 150,
                        }}
                        option={{
                          grid: {left: '5px', top: '30px', bottom: 0, right: 5, containLabel: true},
                          tooltip: {
                            trigger: 'axis',
                            formatter: (argsList: any) => {
                              const args = argsList[0]
                              return `${args.marker}${args.name}<br /><div style="text-align: right;">${args.value}个</div>`
                            },
                          },
                          xAxis: {
                            type: 'category',
                            boundaryGap: true,
                            data: countData.xdata,
                          },
                          yAxis: {
                            type: 'value',
                            name: '个',
                            nameTextStyle: {
                              color: 'gray',
                            },
                            splitLine: {
                              lineStyle: {
                                type: [5, 5],
                                dashOffset: 5,
                              },
                            },
                          },
                          series: [
                            {
                              name: '月',
                              type: 'bar',
                              smooth: true,
                              showSymbol: false,
                              data: countData.ydata,
                              itemStyle: {
                                color: ChartColorMaps[0].line,
                              },
                            },
                          ],
                        }}
                      />
                    }
                  </Tabs.TabPane>
                </Tabs>
              </div>
            </div>
          </div>
          <div className="tds-monitor">
            <div>
              <div className="tds-content-card-title">发电量监控</div>
              <div className="tds-content-card-body">
                {
                  monitorData && <ReactECharts
                    style={{
                      width: 'calc(100% - 18px)',
                      height: 150,
                    }}
                    option={{
                      grid: {left: '5px', top: '30px', bottom: 0, right: '5px', containLabel: true},
                      tooltip: {
                        trigger: 'axis',
                        formatter: (argsList: any) => {
                          const args = argsList[0]
                          return `${args.marker}${args.name}<br /><div style="text-align: right;">${args.value}kWh</div>`
                        },
                      },
                      xAxis: {
                        // error when 1442-1573
                        type: 'category',
                        data: monitorData.xdata,
                      },
                      yAxis: {
                        type: 'value',
                        name: 'kWh',
                        nameTextStyle: {
                          color: 'gray',
                        },
                        splitLine: {
                          lineStyle: {
                            type: [5, 5],
                            dashOffset: 5,
                          },
                        },
                      },
                      series: [
                        {
                          name: '月',
                          type: 'bar',
                          smooth: true,
                          showSymbol: false,
                          data: monitorData.ydata,
                          itemStyle: {
                            color: ChartColorMaps[0].line,
                          },
                        },
                      ],
                    }}
                  />
                }
              </div>
            </div>
          </div>
        </Col>
        <Col span={18} className="tds-right-content">
          <div className="tds-map">
            <div>
              <div id="t-screen-map"></div>
              <div className="t-screen-rank">
                <div className="tds-content-card-title">电站排名<Button
                  style={{
                    fontSize: 12,
                  }} type="link"
                  onClick={() => {
                    const url = '#/operation-management/power-station';
                    location.assign(url)
                    Global.changeMenu.forEach((Fn: any) => {
                      Fn(url)
                    })
                  }}
                >查看更多 &gt;</Button></div>
                <div className="t-screen-table">
                  <Row className="t-screen-table-head">
                    <Col span={3}>排名</Col>
                    <Col span={7}>电站名称</Col>
                    <Col span={8}>累计发电量(kWh)</Col>
                    <Col span={6}>装机容量(kW)</Col>
                  </Row>
                  <div className="t-screen-table-body custom-scroll-bar">
                    {
                      rankData?.map(o => (
                        <Row key={o.id}>
                          <Col span={3}>{o.order}</Col>
                          <Col span={7}><Tooltip overlay={o.name}>{o.name}</Tooltip></Col>
                          <Col span={8}>{o.totalGenerationElectricity}</Col>
                          <Col span={6}>{o.installedCapacity}</Col>
                        </Row>
                      ))
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Col>
      </Row>
      <Row className="tds-footer">
        <Col span={6} className="tds-contribute">
          <div>
            <div className="tds-content-card-title">碳排贡献</div>
            <div className="tds-content-card-body">
              {
                contributeConfigurations.map(o => (<div className="tds-contribution-content" key={o.key}>
                  <div className="tds-contribution-title">
                    <img src={o.icon} alt={o.title}/>
                    {o.title}
                  </div>
                  <div className="tds-contribution-number">
                    {parseNumber(data?.[o.key] || 0, {
                      radix: 2,
                      type: '',
                    }).toString().replace(kxReg, ',')}
                    <span className="tds-contribution-unit">{o.unit}</span>
                  </div>
                </div>))
              }
            </div>
          </div>
        </Col>
        <Col span={18} className="tds-charts">
          <div>
            <div className="tds-content-card-title">实时功率曲线<span
              style={{
                float: 'right',
                color: '#767F95',
                fontSize: 14,
              }}>
            <span style={{
              color: '#262E3F',
            }}>更新时间：</span>{realTimeData?.dataUpdateTime ? moment(realTimeData?.dataUpdateTime).format('YYYY-MM-DD HH:mm:ss') : '--'}</span></div>
            <div className="tds-content-card-body" style={{
              flexGrow: 1,
              paddingBottom: 0,
            }}>
              {
                realTimeData && <ReactECharts
                  style={{
                    width: '100%',
                    height: '100%',
                  }}
                  option={{
                    animationDuration: 50,
                    tooltip: {
                      trigger: 'axis',
                      formatter: (args: any) => {
                        let res = `<strong>${args[0].axisValue}</strong>`
                        if (args.length > 0) {
                          res += args
                            .map(
                              (o: any) =>
                                `<div style='width: 200px;'>${o.marker}${o.seriesName}<span style='float: right'>${o.value}kW</span></div>`
                            )
                            .join('')
                        }
                        return res
                      },
                    },
                    grid: {left: '30px', right: '3%', top: '40px', bottom: '30px', containLabel: true},
                    xAxis: {
                      type: 'category',
                      boundaryGap: true,
                      data: realTimeData.xdata,
                      axisTick: {
                        show: false,
                      },
                      axisLabel: {
                        showMaxLabel: true,
                        interval: 11,
                        formatter: (o) => {
                          if (parseInt(o.split(':')[0], 10) > 24) {
                            return '时';
                          }
                          return parseInt(o.split(':')[0], 10);
                        }
                      },
                    },
                    yAxis: {
                      type: 'value',
                      name: '单位：kW',
                      nameTextStyle: {
                        color: 'gray',
                      },
                      splitLine: {
                        lineStyle: {
                          type: [5, 5],
                          dashOffset: 5,
                        },
                      },
                    },
                    // legend: {
                    //   show: true,
                    //   top: 'bottom',
                    //   icon: 'rect',
                    // },
                    series: [{
                      name: '实时功率',
                      type: 'line',
                      // sampling: 'lttb',
                      smooth: true,
                      showSymbol: false,
                      itemStyle: {
                        color: ChartColorMaps[0].line,
                      },
                      areaStyle: {
                        color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                          {
                            offset: 0,
                            color: ChartColorMaps[0].area[0],
                          },
                          {
                            offset: 1,
                            color: ChartColorMaps[0].area[1],
                          },
                        ]),
                      },
                      lineStyle: {
                        width: 2,
                      },
                      data: realTimeData.ydata,
                    }],
                  }}
                />
              }
            </div>
          </div>
        </Col>
      </Row>
    </div>
  </div>
}
