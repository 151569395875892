import { Spin } from 'antd'
import styles from '../index.module.scss'
import surveyIcon1 from '../../../assets/equipment-station/surveyIcon1.png'
import surveyIcon2 from '../../../assets/equipment-station/surveyIcon2.png'
import surveyIcon3 from '../../../assets/equipment-station/surveyIcon3.png'
// 组件属性
export interface IDeviceData {
  deviceData?: any
}
const SurveyCardList = (props: { deviceData: IDeviceData }) => {
  const { deviceData } = props
  const transDict = [
    {
      name: '设备总数',
      count: 0,
      key: 'deviceTotal',
      icon: surveyIcon1,
    },
    {
      name: '当前在线设备数',
      count: 0,
      key: 'deviceOnly',
      icon: surveyIcon2,
    },
    {
      name: '当前设备在线率',
      count: 0,
      key: 'onlyRate',
      icon: surveyIcon3,
    }
  ]

  return (
    <Spin spinning={false}>
      <div className={styles.surveyCard}>
        {transDict.map((item, index) => {
          const device = Object.assign(item, { count: deviceData[item.key] })
          return (
            <div
              key={`equipment_${index}`}
              className="card-item"
            >
              <div className="icon">
                <img src={item.icon} alt="" style={{ height: 26 }} />
              </div>
              <div className="count-wrap">
                <div className="name">{item.name}</div>
                <div className="count">
                  {device.count}<span>{item.key === 'onlyRate' ? '%' : ''}</span>
                </div>
              </div>
            </div>
          )
        })}
      </div>
    </Spin>
  )
}

export default SurveyCardList
