import React, { useEffect, useState, useReducer, useRef } from 'react'
import styles from '../index.module.scss'
import {
  Spin,
  Table,
  InputNumber,
  Input,
  Form,
  Button,
  Space,
  Dropdown,
  Menu,
  Modal,
  message,
  Tooltip,
  // List,
} from 'antd'
import {
  PlusCircleFilled,
  EllipsisOutlined,
  // , ExclamationCircleFilled
} from '@ant-design/icons'
import { FinanceService } from '../../../../apis/project-service-history'
import { BaseReducer } from '../reducer'
import { CustomIcons } from '../../../../global'
import { SubBreadcrumbObservable } from '../../layout'
import { paramsStoreShowError } from '../..'
// import CodeModal from './CodeModal'
import { RESOURCE_NAME_EXP } from '../../../../utils/constant'

const projectTypeList = [
  {
    name: '流动资金',
    code: 'ldzj',
    rows: [
      {
        name: '月数',
        unit: '月',
        field: 'monthNumber',
        type: 'primary',
        valueType: 'int',
      },
      {
        name: '贷款占比',
        unit: '%',
        field: 'loansProportion',
        type: 'primary',
        precision: 1,
      },
      {
        name: '短期贷款率',
        unit: '%',
        field: 'shortPeriodLoansRate',
        type: 'primary',
        precision: 2,
      },
    ],
  },
  {
    name: '长期贷款',
    code: 'cqdk',
    rows: [
      {
        name: '长期贷款占比',
        unit: '%',
        field: 'longTimeLoansProportion',
        type: 'primary',
        precision: 1,
      },
      {
        name: '贷款期限',
        unit: '年',
        field: 'loansDeadline',
        type: 'primary',
        max: 15,
        min: 1,
      },
      {
        name: '长期贷款利率',
        unit: '%',
        field: 'longTimeLoansRate',
        type: 'primary',
        precision: 1,
      },
    ],
  },
  {
    name: '税率',
    code: 'sl',
    rows: [
      {
        name: '所得税率',
        unit: '%',
        field: 'incomeTaxRate',
        type: 'primary',
        precision: 1,
      },
      {
        name: '增值税率',
        unit: '%',
        field: 'addedValueTaxRate',
        type: 'primary',
        precision: 1,
      },
      {
        name: '教育附加税率',
        unit: '%',
        field: 'additionalTaxOfEducationRate',
        type: 'primary',
        precision: 1,
      },
      {
        name: '城市维护建设税率',
        unit: '%',
        field: 'urbanMaintenanceAndConstructionTaxRate',
        type: 'primary',
        precision: 1,
      },
      {
        name: '可抵扣增值税税率',
        unit: '',
        field: 'financeCostId',
        hidden: true,
        type: 'primary',
      },
      {
        name: '设备费用',
        unit: '%',
        field: 'equipmentDeductibleRate',
        type: 'desc',
        precision: 1,
      },
      {
        name: '安装工程费',
        unit: '%',
        field: 'installDeductibleRate',
        type: 'desc',
        precision: 1,
      },
      {
        name: '建筑工程费',
        unit: '%',
        field: 'architectureDeductibleRate',
        type: 'desc',
        precision: 1,
      },
      {
        name: '其他费用',
        unit: '%',
        field: 'otherDeductibleRate',
        type: 'desc',
        precision: 1,
      },
    ],
  },
  {
    name: '贴现',
    code: 'tx',
    rows: [
      {
        name: '贴现率',
        unit: '%',
        field: 'discountRate',
        type: 'primary',
        precision: 1,
      },
    ],
  },
]

// 运营成本参数
const OperatingCost = () => {
  const [loading, setLoading] = useState(false)
  const [confirmLoading, setConfirmLoading] = useState(false)
  const [
    ,
    // saveLoading
    setSaveLoading,
  ] = useState(false)
  const [defaultData, setDefaultData] = useState<any>()
  const [readonly, setReadonly] = useState(true)
  let colRef = useRef<any[]>()
  const inititalState = {
    dataSource: [],
    columns: [
      {
        title: '财务成本名称',
        colSpan: 2,
        dataIndex: 'type',
        width: 36,
        className: 'column-title primary-column',
        render: (value: any, row: any) => {
          const obj = {
            children: <div style={{ width: 20, margin: '0 auto' }}>{value}</div>,
            props: {
              rowSpan: 0,
            },
          }
          if (row.index === 0) {
            obj.props.rowSpan = row.fieldsCount
          }
          return obj
        },
      },
      {
        title: '财务成本名称',
        colSpan: 0,
        dataIndex: 'name',
        width: 178,
        className: 'column-title',
        render: (value: any, row: any) => {
          return (
            <div>
              <span className="row-title" style={row.strong === 'desc' ? { color: '#767F95' } : {}}>
                {value}
              </span>
              &nbsp;
              <span className="unit" style={{ fontWeight: 300 }}>
                {row.unit}
              </span>
            </div>
          )
        },
      },
      {
        title: (
          <div>
            推荐财务成本
            {/* <Dropdown
              key={`action-0`}
              overlay={
                <Menu className="custom-row-menu">
                  <Menu.Item
                    key={`rename0`}
                    onClick={() => {
                      setDefaultData((defaultData: any) => {
                        FinanceService.default1({
                          financeCostId: defaultData.id,
                          root: {
                            setDefault: 1,
                          },
                        })
                          .then(() => {
                            init()
                          })
                          .catch(() => {})
                        return defaultData
                      })
                    }}
                  >
                    设为默认
                  </Menu.Item>
                </Menu>
              }
              destroyPopupOnHide={true}
            >
              <Button type="link" className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
                <EllipsisOutlined color="gray" />
              </Button>
            </Dropdown> */}
          </div>
        ),
        dataIndex: 'default',
        className: 'even',
        width: 168,
        render: (value: any, row: any) => {
          const obj = {
            children: readonly ? value : <Input disabled value={value} />,
            props: {},
          }
          if (!row.field || row.hidden) {
            obj.children = <></>
          }
          return obj
        },
      },
      {
        title: ' ',
        dataIndex: 'empty',
        className: 'empty',
      },
    ],
  }
  const [state, hookDispatch] = useReducer(BaseReducer, inititalState)
  const [backupDataSource, setBackupDataSource] = useState<any>([])
  // const [verifyModalVisible, setVerifyModalVisible] = useState(false)
  const [form] = Form.useForm()

  useEffect(() => {
    SubBreadcrumbObservable.next([
      {
        name: '设置',
        url: '',
      },
      {
        name: '参数库',
        url: '/user-center/user-params?type=fin',
      },
      {
        name: '财务成本参数',
        url: '/user-center/financial-cost',
      },
    ])
  }, [])

  useEffect(() => {
    colRef.current = state.columns
  })

  // 开启编辑功能时，显示添加列
  useEffect(() => {
    if (!readonly) {
      const cols: any[] = colRef.current?.slice() || []
      hookDispatch({
        type: 'updateState',
        payload: {
          columns: [
            ...cols,
            {
              title: ' ',
              dataIndex: 'add',
              fixed: 'right',
              width: 56,
              render: (value: any, row: any, index: any) => {
                const obj = {
                  children: (
                    <>
                      <div className="add-btn" onClick={addColumn}>
                        <div className="btn-wrap">
                          <PlusCircleFilled size={16} />
                          <div>添加</div>
                        </div>
                      </div>
                    </>
                  ),
                  props: {
                    rowSpan: 0,
                  },
                }
                if (index === 0) {
                  obj.props.rowSpan = 25
                }
                return obj
              },
            },
            {
              title: ' ',
              dataIndex: 'empty',
              className: 'empty',
            },
          ],
        },
      })
    }
  }, [readonly])

  // 取消时跳转回主列表
  const onCancel = () => {
    location.assign(`#/user-center/user-params?type=fin`)
  }

  // const save = async (code: any) => {
  //   if (!code) {
  //     message.error('请输入验证码')
  //     return
  //   }
  //   return saveData(code)
  // }

  const saveData = async (code?: any) => {
    const values = form.getFieldsValue()
    const data = values.data?.filter((item: any) => !!item) || []
    const cols = colRef.current?.slice()
    const listData = data.map((item: any, index: number) => {
      return {
        ...item,
        name: item.name ? item.name : cols?.filter((col) => col.text)?.[index].text,
      }
    })
    setSaveLoading(true)
    await FinanceService.createOrEditBatch2({
      code,
      root: listData,
    })
      .then(() => {
        message.success('保存成功')
        onCancel()
      })
      .catch(() => {})
      .finally(() => {
        setSaveLoading(false)
      })
  }

  const showWarnModal = () =>
    // editData: any, delData: any, addData: any
    {
      // const editList = editData.editIds
      // const delList = delData.delIds
      // const editProject = editData.list
      // const delProject = delData.list
      // const editProjectList = editProject?.projectList || []
      // const delProjectList = delProject?.projectList || []
      // const projectList = [...editProjectList, ...delProjectList]
      // const editFinanceList = editProject?.financeLibraryList || []
      // const delFinanceList = delProject?.financeLibraryList || []
      // const financeLibraryList = [...editFinanceList, ...delFinanceList]

      saveData().finally(() => {
        setConfirmLoading(false)
      })
    }

  const getWarnToast = (ids: any) => {
    return FinanceService.warnToast({
      type: 'finance_cost_id',
      ids: ids.join(','),
    })
      .then((res) => {
        return Promise.resolve(res.data)
      })
      .catch(() => {})
  }

  // 获取修改与删除的数据
  const validEditAndDeleteData = () => {
    const values = form.getFieldsValue()
    const dataList = values.data?.filter((item: any) => !!item) || []
    const cols = colRef.current?.slice()
    const listData = dataList.map((item: any, index: number) => {
      return {
        ...item,
        name: item.name ? item.name : cols?.filter((col) => col.text)?.[index].text,
      }
    })
    let delIds: any = []
    let editIds: any = []
    let addData: any = []
    backupDataSource?.forEach?.((item: any) => {
      // 判断是否被删除
      if (!listData?.map((colItem: any) => colItem.financeCostId)?.includes(item.financeCostId)) {
        delIds.push({
          name: item.name,
          id: item.financeCostId,
          isDefault: item.isDefault,
        })
        return
      }
      let findItem = listData.find((colItem: any) => colItem.financeCostId === item.financeCostId)
      for (let key in item) {
        // 数据与初始数据不等并且是页面上展示的数据
        if (item[key] != findItem[key] && findItem[key] !== undefined && key != 'name') {
          editIds.push({
            name: item.name,
            id: item.financeCostId,
          })
          return
        }
        // 如果只是名字不同，直接保存
        if (item.name != findItem.name) {
          addData.push(item)
        }
      }
    })
    listData.forEach((item: any) => {
      if (!item.financeCostId) {
        addData.push(item)
      }
    })

    // 如果没有编辑和删除数据
    if (editIds.length === 0 && delIds.length === 0) {
      // 如果没有添加数据 直接返回
      if (addData.length === 0) {
        onCancel()
        return
      } else {
        // 如果添加了数据  调用保存接口
        setConfirmLoading(true)
        return saveData().finally(() => {
          setConfirmLoading(false)
        })
      }
    }

    Promise.all([
      editIds.length && getWarnToast(editIds.map((o: any) => o.id)),
      delIds.length && getWarnToast(delIds.map((o: any) => o.id)),
    ])
      .then(() => {
        showWarnModal()
        // {
        //   editIds,
        //   list: res[0],
        // },
        // {
        //   delIds,
        //   list: res[1],
        // },
        // addData
      })
      .finally(() => {
        setConfirmLoading(false)
      })
  }

  // 保存数据
  const submit = () => {
    form.validateFields().then(() => {
      setConfirmLoading(true)
      const values = form.getFieldsValue()
      const data = values.data?.filter((item: any) => !!item) || []
      const cols = colRef.current?.slice()
      const listData = data.map((item: any, index: number) => {
        return {
          ...item,
          name: item.name ? item.name : cols?.filter((col) => col.text)?.[index].text,
        }
      })
      let err = []
      // 验证是否重名
      listData.forEach((item: any) => {
        if (listData.filter((data: any) => data.name === item.name).length > 1) {
          err.push('')
        }
      })
      if (err.length) {
        paramsStoreShowError('财务成本名称存在重复')
        setConfirmLoading(false)
        return
      }
      validEditAndDeleteData()
    })
  }

  // const onNameKeyDown = (e: any, record: any, index: number) => {
  //   if (e.code === 'Enter' && e.target.value?.trim()) {
  //     const title = e.target.value?.trim()
  //     const cols = colRef.current?.slice()
  //     const current = cols?.[index + 3]
  //     if (!title) {
  //       return
  //     }
  //     current.title = (
  //       <div className={styles.paramsColTitle}>
  //         <Tooltip title={title}>
  //           <div className="col-title-content">{title}</div>
  //         </Tooltip>
  //         <div className="col-title-ell">
  //           {
  //             <Dropdown
  //               key={`action-${cols?.length || 0}`}
  //               overlay={<ActionMenu id={record.id} index={index} item={record} />}
  //               destroyPopupOnHide={true}
  //             >
  //               <Button type="link" className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
  //                 <EllipsisOutlined color="gray" />
  //               </Button>
  //             </Dropdown>
  //           }
  //         </div>
  //       </div>
  //     )
  //     current.text = `${title}`
  //
  //     cols?.splice(index + 3, 1, {
  //       ...current,
  //     })
  //     console.info(current)
  //     hookDispatch({
  //       type: 'updateState',
  //       payload: {
  //         columns: [...(cols as [])],
  //       },
  //     })
  //   }
  // }

  const onNameBlur = (e: any, record: any, index: number, isAdd = false) => {
    const title = (typeof e === 'string' ? e : e.target.value)?.trim()
    const cols = colRef.current?.slice()
    let current = cols?.[index + 3]
    if (isAdd) {
      current = cols.find((o) => o.index === index)
    }
    if (!title) {
      return
    }
    current.title = (
      <div className={styles.paramsColTitle}>
        <Tooltip title={title}>
          <div className="col-title-content">{title}</div>
        </Tooltip>
        <div className="col-title-ell">
          {
            <Dropdown
              key={`action-${cols?.length || 0}`}
              overlay={<ActionMenu id={record.id} index={index} item={record} />}
              destroyPopupOnHide={true}
            >
              <Button type="link" className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
                <EllipsisOutlined color="gray" />
              </Button>
            </Dropdown>
          }
        </div>
      </div>
    )
    current.text = `${title}`

    if (!isAdd) {
      cols?.splice(index + 3, 1, {
        ...current,
      })
    }
    hookDispatch({
      type: 'updateState',
      payload: {
        columns: [...(cols as [])],
      },
    })
  }
  const [renameForm] = Form.useForm()
  // 重命名与删除
  const ActionMenu = (props: any) => {
    const { item, index } = props
    return (
      <Menu className="custom-row-menu">
        <Menu.Item
          key={`rename${index}`}
          onClick={() => {
            renameForm.setFieldsValue({
              rename: item.name,
            })
            Modal.destroyAll()
            Modal.confirm({
              keyboard: false,
              autoFocusButton: null,
              title: '重命名',
              icon: <CustomIcons type="klf-alarm1" />,
              content: (
                <Form form={renameForm}>
                  <Form.Item
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                    }}
                    name="rename"
                    label="请输入财务参数名称"
                    rules={[
                      {
                        required: true,
                        message: '请输入财务参数名称',
                      },
                      {
                        pattern: RESOURCE_NAME_EXP,
                        message: '1-50个字符,中文、英文、_、数字和-组成',
                      },
                    ]}
                  >
                    <Input placeholder="请输入"></Input>
                  </Form.Item>
                </Form>
              ),
              width: 500,
              okType: 'primary',
              okText: '确定',
              cancelText: '取消',
              onOk: () => {
                return new Promise((resolve, reject) => {
                  const { rename } = renameForm.getFieldsValue()
                  renameForm
                    .validateFields()
                    .then(() => {
                      item.name = rename
                      onNameBlur(rename, item, index, item.index)
                      resolve({})
                    })
                    .catch((e) => {
                      reject(e)
                    })
                })
              },
            })
          }}
        >
          重命名
        </Menu.Item>
        <Menu.Item
          key={`copy${index}`}
          onClick={() => {
            const cols = colRef.current?.slice()

            if ((cols?.length || 0) >= 11) {
              paramsStoreShowError('财务成本参数最多6个')
              return
            }
            const colIndex = cols?.findIndex((col) => col.name === item.name) as number
            const current = cols?.[colIndex]

            cols?.splice(cols.length - 2, 0, {
              ...generateColumn({
                readonly: false,
                data: {
                  ...current,
                  name: `${generateCopyName(current.text || current.name)}`,
                  text: `${generateCopyName(current.text || current.name)}`,
                  financeCostId: undefined,
                  className: cols.length % 2 === 0 ? 'even' : 'odd',
                },
                index: cols.length,
              }),
            })

            hookDispatch({
              type: 'updateState',
              payload: {
                columns: [...(cols as [])],
              },
            })
          }}
        >
          拷贝
        </Menu.Item>
        <Menu.Item
          key={`del${index}`}
          onClick={() => {
            const cols = colRef.current?.slice()
            const colIndex = cols?.findIndex((col) => col.name === item.name) as number

            cols?.splice(colIndex, 1)
            hookDispatch({
              type: 'updateState',
              payload: {
                columns: [...(cols as [])],
              },
            })
          }}
        >
          删除
        </Menu.Item>
      </Menu>
    )
  }

  const generateColumn = ({
    readonly,
    data = {},
    index = 0,
  }: {
    readonly: boolean
    data?: any
    index: number
    id?: number
  }) => {
    const rowData = {
      financeCostId: data?.financeCostId,
    }

    const cols = colRef.current?.slice() || []
    const name = data.name || `财务成本${(cols?.length || 0) - 3}`
    return {
      ...data,
      text: name,
      className: index % 2 === 0 ? 'odd' : 'even',
      width: 140,
      title: (
        <>
          <div className={styles.paramsColTitle}>
            <Tooltip title={name}>
              <div className="col-title-content">
                {name}
                {/* {data.isDefault && (
                  <div className="absolute-top">
                    <CustomIcons className="absolute-top primary-mark" type="klf-bookmark_added_filled_regular" />
                  </div>
                )} */}
              </div>
            </Tooltip>
            {readonly && (
              <div className="col-title-ell">
                {/* <Dropdown
                  key={`action-${cols?.length || 0}`}
                  overlay={
                    <Menu className="custom-row-menu">
                      <Menu.Item
                        disabled={data.isDefault}
                        key={`rename${index}`}
                        onClick={() => {
                          FinanceService.default1({
                            financeCostId: data.financeCostId,
                            root: {
                              setDefault: 1,
                            },
                          })
                            .then(() => {
                              init()
                            })
                            .catch(() => {})
                        }}
                      >
                        设为默认
                      </Menu.Item>
                    </Menu>
                  }
                  destroyPopupOnHide={true}
                >
                  <Button type="link" className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
                    <EllipsisOutlined color="gray" />
                  </Button>
                </Dropdown> */}
              </div>
            )}
            {!readonly && (
              <div className="col-title-ell">
                <Dropdown
                  key={`action-${cols?.length || 0}`}
                  overlay={<ActionMenu id={data?.financeCostId} index={index} item={data} />}
                  destroyPopupOnHide={true}
                >
                  <Button type="link" className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
                    <EllipsisOutlined color="gray" />
                  </Button>
                </Dropdown>
              </div>
            )}
          </div>
        </>
      ),
      dataIndex: `add-${cols.length - 1}`,
      render: (value: any, row: any) => {
        const obj = {
          children: readonly ? (
            !row.hidden && (data[row.field] || data[row.field] === 0) ? (
              data[row.field]
            ) : (
              data.default
            )
          ) : (
            <Form.Item
              hidden={row.hidden}
              name={['data', index, row.field]}
              initialValue={
                row.hidden
                  ? rowData.financeCostId
                  : data[row.field] || data[row.field] == 0
                  ? data[row.field]
                  : row.default
              }
              rules={
                row.hidden
                  ? []
                  : [
                      {
                        required: true,
                        message: '',
                      },
                    ]
              }
            >
              <InputNumber
                min={row.min ? row.min : 0}
                max={row.max ? row.max : row.unit === '%' ? 100 : undefined}
                formatter={(val) => {
                  const arr = new Array(row.precision)
                  arr.fill('\\d')
                  const pat = `^(\\-)*(\\d+)\\.(${row.precision ? arr.join('') : ''}).*$`
                  return row.valueType === 'int'
                    ? '' + Number.parseInt(`${val}`.replace(new RegExp(pat), '$1$2.$3'))
                    : `${val}`.replace(new RegExp(pat), '$1$2.$3')
                }}
                style={{ width: '100%' }}
              />
            </Form.Item>
          ),
          props: {},
        }
        if (!row.field) {
          obj.children = <></>
        }
        return obj
      },
    }
  }

  const generateCopyName = (name: string) => {
    const cols = colRef.current?.slice() || []
    let copyName = `${name}_拷贝7`
    for (let i = 7; i > 0; i--) {
      if (!cols.map((col) => col.name).includes(`${name}_拷贝${i}`)) {
        copyName = `${name}_拷贝${i}`
      }
    }
    return copyName
  }

  const generateName = () => {
    const cols = colRef.current?.slice() || []
    let name = '财务成本7'
    for (let i = 7; i > 0; i--) {
      if (!cols.map((col) => col.name).includes(`财务成本${i}`)) {
        name = `财务成本${i}`
      }
    }
    return name
  }

  // 点击添加添加列
  const addColumn = () => {
    const cols = colRef.current?.slice() || []
    if (cols.length >= 11) {
      paramsStoreShowError('财务成本参数最多6个')
      return
    }
    updateColumns(
      generateColumn({
        readonly: false,
        data: {
          name: generateName(),
          ...defaultData,
          index: colRef.current?.length as number,
        },
        index: colRef.current?.length as number,
      }),
      false
    )
  }

  // 插入列数据
  const updateColumns = (datas: any, readonly: boolean = true) => {
    const cols = colRef.current?.slice() || []
    const data = datas instanceof Array ? datas : [datas]
    // 最后一列添加
    cols?.splice(readonly ? cols.length - 1 : cols.length - 2, 0, ...data)
    hookDispatch({
      type: 'updateState',
      payload: {
        columns: [...(cols as [])],
      },
    })
  }

  const generateDataSource = (data: any) => {
    const dataList: any[] = []
    projectTypeList.forEach((type: any) => {
      type.rows.forEach((row: any, index: number) => {
        dataList.push({
          ...row,
          index: index,
          fieldsCount: type.rows.length,
          default: data[row.field] || 0,
          type: type.name,
          strong: row.type,
          financeCostId: data.financeCostId,
        })
      })
    })
    return dataList
  }

  const init = () => {
    setLoading(true)
    // 获取账号下所有数据

    const length = colRef.current?.length || 0
    colRef.current?.splice(3, length - 4)

    FinanceService.list()
      .then((res) => {
        const accountData = res.data
        setBackupDataSource(res.data)
        // 获取账号下默认参数
        FinanceService.getDefault()
          .then((res) => {
            hookDispatch({
              type: 'updateState',
              payload: {
                dataSource: generateDataSource(res.data),
              },
            })
            setDefaultData(res.data)
            let basedata: any[] = []
            accountData.forEach((item: any, index: number) => {
              basedata.push(generateColumn({ readonly, data: item, index }))
            })
            updateColumns(basedata)
          })
          .catch(() => {})
          .finally(() => {
            setLoading(false)
          })
          .then(() => {})
      })
      .catch(() => {})
      .finally(() => {
        setLoading(false)
      })
  }

  const openEditStatus = () => {
    setReadonly(false)
    const cols = colRef.current?.slice(3, colRef.current.length - 1)
    const colList = cols?.map((col, index) => {
      return generateColumn({ readonly: false, data: col, index })
    })

    const defaultCol = {
      title: '推荐财务成本',
      dataIndex: 'default',
      className: 'even',
      width: 148,
      render: (value: any, row: any) => {
        const obj = {
          children: <Input disabled value={value} />,
          props: {},
        }
        if (!row.field || row.hidden) {
          obj.children = <></>
        }
        return obj
      },
    }
    hookDispatch({
      type: 'updateState',
      payload: {
        columns: [...(colRef.current?.slice(0, 2) as []), defaultCol, ...(colList as [])],
      },
    })
  }

  useEffect(() => {
    init()
  }, [])

  return (
    <div className={styles.projectInvestment}>
      <h2 style={{ fontWeight: 700, display: 'flex', justifyContent: 'space-between' }}>
        财务成本参数
        {readonly && (
          <Button
            className="actived"
            onClick={() => {
              openEditStatus()
            }}
            icon={<CustomIcons type="klf-edit1" style={{ color: 'rgba(28, 184, 135, 1)' }} />}
          >
            编辑
          </Button>
        )}
      </h2>
      <Spin spinning={loading}>
        <Form form={form}>
          {!loading && (
            <Table
              dataSource={state.dataSource}
              columns={state.columns}
              pagination={false}
              scroll={{ x: 'max-content', y: readonly ? 'calc(100vh - 300px)' : 'calc(100vh - 360px)' }}
            ></Table>
          )}
          {!readonly && (
            <div className="footer-btns">
              <Space>
                <Button type="primary" loading={confirmLoading} onClick={submit}>
                  确定
                </Button>
                <Button className="actived" onClick={onCancel}>
                  取消
                </Button>
              </Space>
            </div>
          )}
        </Form>
      </Spin>
      {/* {verifyModalVisible && (
        <CodeModal
          visible={verifyModalVisible}
          onSuccess={save}
          saveLoading={saveLoading}
          onCancel={() => {
            setVerifyModalVisible(false)
          }}
        />
      )} */}
    </div>
  )
}

export default OperatingCost
