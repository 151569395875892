/**
 * project
 * unknown
 *
 * OpenAPI spec version: last
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import ajax, { AjaxPromise, ExtraFetchParams } from '@ajax';
import * as models from '../model/models';

/* tslint:disable:no-unused-variable member-ordering object-literal-shorthand */

/**
  * @description i3060AssetsTreeGetDeviceGet参数
  * @property `thingxTreeId` 
  * @property `thingxPowerStationId` 
  */
export interface Paramsi3060AssetsTreeGetDeviceGet {
  // queryParams
  /**
   * 
   */
  thingxTreeId: string;
  /**
   * 
   */
  thingxPowerStationId: string;
}
/**
  * @description i3060AssetsTreeGetPowerStationGet参数
  * @property `[projectId]` 
  */
export interface Paramsi3060AssetsTreeGetPowerStationGet {
  // queryParams
  /**
   * 
   */
  projectId?: string;
}

export class AssetsTreeApi {
  protected $basePath = '/api'.replace(/\/$/, '');

  public constructor(basePath?: string) {
    if (basePath !== undefined) {
      this.$basePath = basePath.replace(/\/$/, '');
    }
  }

  /**
   * ```     /_**      * 获取节点设备      *_/     @GetMapping(\"/getDevice\")     public Result<GetAssetsTreeDeviceRes> getDevice(@Valid @NotBlank(message = \"请输入资产树id\") String thingxTreeId,                                                     @Valid @NotBlank(message = \"请选择电站节点id\") String thingxPowerStationId) ```
   * @summary 获取节点设备
   * @param params Paramsi3060AssetsTreeGetDeviceGet
   
   * @param opt ajax config
   * @returns models.I3060AssetsTreeGetDevice
   */
  public i3060AssetsTreeGetDeviceGet = (
    params: Paramsi3060AssetsTreeGetDeviceGet,
    opt?: ExtraFetchParams
  ) : AjaxPromise<models.I3060AssetsTreeGetDevice>  => {
    const url = this.$basePath + `/i3060/assetsTree/getDevice`;
    const p: any = {};
    p.query = {};
    if ('thingxTreeId' in params) p.query.thingxTreeId = params.thingxTreeId;
    ajax.check(params.thingxTreeId, 'thingxTreeId');
    if ('thingxPowerStationId' in params) p.query.thingxPowerStationId = params.thingxPowerStationId;
    ajax.check(params.thingxPowerStationId, 'thingxPowerStationId');
    return ajax.ajax({
      ...opt,
      method: 'GET',
      url,
      ...p
    });
  }
  /**
   * ```     /_**      * 获取节点设备      *_/     @GetMapping(\"/getPowerStation\")     public Result<List<GetPowerStationInfoRes>> getPowerStation(String projectId) ```
   * @summary 获取电站节点
   * @param params Paramsi3060AssetsTreeGetPowerStationGet
   
   * @param opt ajax config
   * @returns models.I3060AssetsTreeGetPowerStation
   */
  public i3060AssetsTreeGetPowerStationGet = (
    params: Paramsi3060AssetsTreeGetPowerStationGet,
    opt?: ExtraFetchParams
  ) : AjaxPromise<models.I3060AssetsTreeGetPowerStation>  => {
    const url = this.$basePath + `/i3060/assetsTree/getPowerStation`;
    const p: any = {};
    p.query = {};
    if ('projectId' in params) p.query.projectId = params.projectId;
    return ajax.ajax({
      ...opt,
      method: 'GET',
      url,
      ...p
    });
  }
}

export default new AssetsTreeApi();