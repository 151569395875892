import { useEffect, useState } from 'react'
import { Row, Col } from 'antd'
import { CustomIcons } from '../../../../global'
import { FinanceApi } from '../../../../services'
import AwardSubsidyForm from './awardSubsidyForm'
import { EditInput } from './../index'

const icon: any = {
  projectInvest: 'klf-finance-investment',
  operationCost: 'klf-finance-operate',
  financeCost: 'klf-finance-finance',
  awardSubsidy: 'klf-finance-allowance',
}

const AwardSubsidyCard = (props: any) => {
  const { editType, result, editConfigId, projectId, changeNameCallback } = props
  const [curResult, setCurResult] = useState<any>()
  const [curName, setCurName] = useState('')

  const formatDefaultData = (data: any) => {
    setCurResult({ ...data })
  }

  useEffect(() => {
    if (result) {
      formatDefaultData(result)
    }
  }, [result])

  const { name } = curResult || {}
  useEffect(() => {
    setCurName(name)
  }, [])

  if (!result || !curResult) return <></>
  return (
    <div className="param-config-card">
      <Row>
        <Col className="param-title" span={24}>
          <CustomIcons type={icon[editType]} className="icon" />
          <EditInput
            name={curName || name}
            onConfirm={(str) => {
              const FinanceApiService = new FinanceApi()
              FinanceApiService.i3060FinanceParamListRenameProjectLibraryPost({
                projectId,
                name: str,
                type: '4',
                id: editConfigId,
              })
                .then((res: any) => {
                  if (res.code === 200) {
                    changeNameCallback()
                    setCurName(str)
                  }
                })
                .catch(() => {})
                .finally(() => {})
            }}
          />
        </Col>
      </Row>
      {!!curResult['infos'].length &&
        curResult['infos'].map((item: any, index: number) => {
          return (
            <>
              <Row className="mb18" key={index}>
                <Col span={24} className="name">
                  {item.name}
                </Col>
              </Row>
              <AwardSubsidyForm
                detailList={curResult.infos}
                currentItem={item}
                awardSubsidyId={curResult.awardSubsidyId}
                name={curName || name}
                index={index}
              />
            </>
          )
        })}
    </div>
  )
}
export default AwardSubsidyCard
