import _axios from 'axios'
import { IRequestOptions, IRequestConfig, getConfigs, axios } from './serviceOptions'

export const basePath = '/api/project'

// 公共服务模块 start **************************************************************************************************
export class CommonService {
    // 获取 {{ 项目名称 }} 数据下拉框列表
    static getProjectDataSelector(options: IRequestOptions = {}): Promise<any> {
      return new Promise((resolve, reject) => {
        let url = basePath + `/energy_goods_materials_manage/Query/Project/List`
  
        const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
  
        configs.data = null;
        axios(configs, resolve, reject);
      });
    }
    // 获取 {{ 合同名称、供应商、业务机构 }} 数据下拉框列表
    static getContractData(projectNo: any, options: IRequestOptions = {}): Promise<any> {
        return new Promise((resolve, reject) => {
        let url = basePath + `/energy_goods_materials_manage/Query/Contract/List/${projectNo}`

        const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

        configs.data = null;
        axios(configs, resolve, reject);
        });
    }
  }

// 公共服务模块 end **************************************************************************************************

// 附件管理 start **************************************************************************************************
export class FileService {
  static path = (url?: string) => url || `${_axios.defaults.baseURL ?? location.origin}${url ?? ''}`

  // 删除文件
  static remove(
    params: {
      path: string;
      root?: object;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      const url = `/api/fileserver/energy-file?path=${params.path}`

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      configs.data = params['root'] ?? null;
      axios(configs, resolve, reject);
    });
  }

  // 上传文件/图片
  static upload = (): string => FileService.path() + `/api/fileserver/energy-file/Uploader`

  // 下载文件
  static download = (path: string): void => {
    window.location.href = FileService.path() + `/api/fileserver/energy-file/downLoad?path=${path}`
  }

  // 附件批量下载-zip包
  static zip = (json: string): void => {
    const a: any = document.createElement('a')
    a.download = true
    a.target = '_self'
    a.href = FileService.path() + `/api/fileserver/energy-file/downLoad/batch?json=${json}`
    a.click()
  }

  // 附件批量下载-zip包
  static zipCollection = (id: string): void => {
    if (!id) return

    const a: any = document.createElement('a')
    a.download = true
    a.target = '_self'
    a.href = FileService.path(`/api/fileserver/energy-file/collection/zip?id=${id}`)
    a.click()
  }

  // 预览文件
  static preview(
    params: {
      path: string; // required
      root?: object;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      const url = `/api/fileserver/energy-file/preview?path=${params.path}`

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      configs.data = params['root'] ?? null;
      axios(configs, resolve, reject);
    });
  }
}
// 附件管理 end **************************************************************************************************

// 物资管理模块 start **************************************************************************************************
export class MaterialService {
  // 获取列表
  static list(
    params: {
      type?: number;// 合作方类型 0:投资方采集;1:工程总承包采集
      dateSort?: number; // 时间排序 0 不排序；1 asc；2 desc
      operatorSort?: number; // 制单人排序 0 不排序；1 asc；2 desc
      projectCode?: string;
      page?: number; // 当前页
      pageSize?: number; // 每页条数
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      const url = basePath + `/energy_goods_materials_manage/Query/List`;

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      const { page, dateSort, operatorSort, ...args } = params
      configs.params = Object.assign({
        type: args.type,
        projectCode: args.projectCode,
        dateSort: dateSort ?? 0,
        operatorSort: operatorSort ?? 0,
        currentPage: page ?? 1,
        pageSize: 20,
      }, args)

      configs.data = null;
      axios(configs, resolve, reject);
    });
  }

  // 删除
  static remove(
    params: {
      id: string;
      root?: object;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      const url = basePath + `/energy_goods_materials_manage/Delete/${params.id}`

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      configs.data = params['root'] ?? null;
      axios(configs, resolve, reject);
    });
  }

  // 新建 
  static create(
    params: {
      goodsMaterialsManageId?: string
      root?: object;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/energy_goods_materials_manage/Save/Or/Update'
      if (params['goodsMaterialsManageId']) {
        url = basePath + '/energy_goods_materials_manage/Save/Or/Update' + params['goodsMaterialsManageId']
      }
      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      configs.data = params['root'] ?? null;
      axios(configs, resolve, reject);
    });
  }

  // 导出Excel 物资列表
  static exportAllData(
    params: {
      type?: number;// 合作方类型 0:投资方采集;1:工程总承包采集
    } = {} as any,
  ): any {
    const { ...args } = params

    const paramsUrl = Object.entries(Object.assign({
      type: args.type
    }, args)).reduce((str: string, cur: any[]) => {
      str += `&${cur[0]}=${cur[1]}`
      return str
    }, '')

    const url = basePath + '/energy_goods_materials_manage/Output/List/Excel'
    window.open(FileService.path(url + `?${paramsUrl.substring(1)}`))
  }

  // 物资详情
  static getMaterialDetail(id: any, options: IRequestOptions = {}): Promise<any> {
    return new Promise((resolve, reject) => {
    let url = basePath + `/energy_goods_materials_manage/Query/Info/${id}`

    const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

    configs.data = null;
    axios(configs, resolve, reject);
    });
  }

  // 采购合同查询物资明细
  static getMaterialsList(purchaseContractNo: any, options: IRequestOptions = {}): Promise<any> {
    return new Promise((resolve, reject) => {
    let url = basePath + `/energy_goods_materials_manage/Query/Purchase/Materials/Detail/${purchaseContractNo}`

    const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

    configs.data = null;
    axios(configs, resolve, reject);
    });
  }
}
// 物资管理模块 end **************************************************************************************************

// 物流管理模块 start **************************************************************************************************
export class LogisticsService {
  // 获取列表
  static list(
    params: {
      projectCode?: string;
      page?: number; // 当前页
      pageSize?: number; // 每页条数
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      const url = basePath + `/energy_logistics_manage/Query/List`;

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      const { page, ...args } = params
      configs.params = Object.assign({
        projectCode: args.projectCode,
        currentPage: page ?? 1,
        pageSize: 20,
      }, args)

      configs.data = null;
      axios(configs, resolve, reject);
    });
  }

  // 删除
  static remove(
    params: {
      id: string;
      root?: object;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      const url = basePath + `/energy_logistics_manage/Delete/${params.id}`

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      configs.data = params['root'] ?? null;
      axios(configs, resolve, reject);
    });
  }

  // 运费确认
  static freightConfirm(
    params: {
      id: string;
      root?: object;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      const url = basePath + `/energy_logistics_manage/Freight/Affirm/${params.id}`

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      configs.data = params['root'] ?? null;
      axios(configs, resolve, reject);
    });
  }

  // 新建 
  static create(
    params: {
      manageId?: string
      root?: object;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/energy_logistics_manage/Save/Or/Update'
      if (params['manageId']) {
        url = basePath + '/energy_logistics_manage/Save/Or/Update' + params['manageId']
      }
      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      configs.data = params['root'] ?? null;
      axios(configs, resolve, reject);
    });
  }

  // 上传随货清单
  static uploadFile = (): string => basePath + `/energy_logistics_manage/Upload/File`

  // 查询运单详情
  static getDetail(id: any, options: IRequestOptions = {}): Promise<any> {
    return new Promise((resolve, reject) => {
    let url = basePath + `/energy_logistics_manage/Query/Info/${id}`

    const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

    configs.data = null;
    axios(configs, resolve, reject);
    });
  }
}

// 物流管理模块 end **************************************************************************************************