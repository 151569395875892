import React from 'react'
import { Statistic, Tag, Tooltip, Typography } from 'antd'
import {
  getActionColumn,
  getColumnFilterProps,
  TablePage,
  TablePageInstance,
} from '../../../../components/wl-table-page'
import { FeeService } from '../../../../apis/wl-service'
import LogDialog from './LogDialog'
import PayDetailDialog from './PayDetailDialog'
import { payStatusOptions, payTypeOptions } from './constants'

const ellipsis = (text: string, isStat?: boolean) =>
  text && (
    <Tooltip title={text}>
      <Typography.Text ellipsis>
        {typeof isStat === 'boolean' ? <Statistic title="" value={text} precision={2} prefix="￥" /> : text}
      </Typography.Text>
    </Tooltip>
  )

interface PayTableProps {
  componentRef: React.RefObject<TablePageInstance>
}

export default ({ componentRef }: PayTableProps) => {
  const [logState, setLogState] = React.useState({ visible: false, id: '' })
  const [detailState, setDetailState] = React.useState({ visible: false, id: '' })

  // 费用支付字段
  const columns = (tableParams: any) => [
    {
      title: '序号',
      dataIndex: 'idx',
      width: 80,
      render: (...args) => args[2] + 1,
    },
    {
      title: '支付单号',
      dataIndex: 'pay_work_no',
      width: 180,
      render: ellipsis,
      ...getColumnFilterProps({
        type: 'input',
        placeholder: '请输入支付单号',
        value: tableParams.pay_work_no,
        onSearch: (value) => {
          componentRef.current.reload?.({
            pay_work_no: value,
            page: 1,
          })
        },
      }),
    },
    {
      title: '签署方',
      dataIndex: 'signaor',
      width: 200,
      render: ellipsis,
    },
    {
      title: '合同号',
      dataIndex: 'contract_id',
      width: 200,
      render: ellipsis,
    },
    {
      title: '合同日期',
      dataIndex: 'contract_date',
      width: 180,
      render: ellipsis,
      ...getColumnFilterProps({
        type: 'RangePicker',
        placeholder: '',
        value: [tableParams.contract_start_date, tableParams.contract_end_date],
        onSearch: (value) => {
          componentRef.current.reload?.({
            contract_start_date: value[0],
            contract_end_date: value[1],
            page: 1,
          })
        },
      }),
    },
    {
      title: '付款时间',
      dataIndex: 'create_time',
      width: 180,
      render: ellipsis,
    },
    {
      title: '合同金额（元）',
      dataIndex: 'contract_amount',
      width: 180,
      render: (text: string) => ellipsis(text, true),
    },
    {
      title: '付款金额（元）',
      dataIndex: 'pay_amount',
      width: 180,
      render: (text: string) => ellipsis(text, true),
    },
    {
      title: '付款方',
      dataIndex: 'payer',
      width: 260,
      render: ellipsis,
    },
    {
      title: '支付方式',
      dataIndex: 'is_mulitiple',
      width: 120,
      ...getColumnFilterProps({
        type: 'select',
        options: payTypeOptions,
        placeholder: '请选择支付方式',
        value: tableParams.payType,
        onSearch: (value) => {
          componentRef.current.reload?.({
            payType: value,
            page: 1,
          })
        },
      }),
      render: (text: string) => {
        return text?.toString() === '1' ? '批量支付' : '单笔支付'
      },
    },
    {
      title: '支付状态',
      dataIndex: 'pay_status',
      width: 120,
      ...getColumnFilterProps({
        type: 'select',
        options: payStatusOptions,
        placeholder: '请选择支付状态',
        value: tableParams.status,
        onSearch: (value) => {
          componentRef.current.reload?.({
            status: value,
            page: 1,
          })
        },
      }),
      render: (text: string) => {
        const { color, fullName } = payStatusOptions.find((v) => v.id === text?.toString()) ?? {}

        return <Tag color={color}>{fullName}</Tag>
      },
    },
    {
      title: '项目名称',
      dataIndex: 'projectName',
      width: 260,
      render: ellipsis,
      ...getColumnFilterProps({
        type: 'input',
        placeholder: '请输入项目名称',
        value: tableParams.projectName,
        onSearch: (value) => {
          componentRef.current.reload?.({
            projectName: value,
            page: 1,
          })
        },
      }),
    },
    {
      title: '合同编号',
      dataIndex: 'contract_sn',
      width: 260,
      render: ellipsis,
      ...getColumnFilterProps({
        type: 'input',
        placeholder: '请输入合同编号',
        value: tableParams.contract_sn,
        onSearch: (value) => {
          componentRef.current.reload?.({
            contract_sn: value,
            page: 1,
          })
        },
      }),
    },
    getActionColumn([
      {
        key: 'detail',
        label: '明细',
        onClick: (row: any) => {
          setDetailState({ visible: true, id: row.pay_work_id })
        },
      },
      {
        key: 'log',
        label: '日志',
        onClick: (row: any) => {
          setLogState({ visible: true, id: row.pay_work_id })
        },
      },
    ]),
  ]

  return (
    <>
      <TablePage
        autoLoad={false}
        checkable
        componentRef={componentRef}
        columns={columns}
        rowKey="pay_work_id"
        getter={(arg: any) => FeeService.payList(arg)}
      />
      <LogDialog
        type="pay"
        {...logState}
        visibleChange={(visible: boolean) => setLogState((prevState) => ({ ...prevState, visible }))}
      />
      <PayDetailDialog
        {...detailState}
        visibleChange={(visible: boolean) => setDetailState((prevState) => ({ ...prevState, visible }))}
      />
    </>
  )
}
