import React, { ReactElement, useState } from 'react'
import {
  Button,
  Card,
  Checkbox,
  Col,
  Drawer,
  Input,
  InputNumber,
  Layout,
  List,
  message,
  Modal,
  Popover,
  Row,
  Select,
  Space,
  Spin,
  Tooltip,
} from 'antd'
import { LeftOutlined, PlayCircleOutlined, PlusOutlined, SettingOutlined, UserAddOutlined } from '@ant-design/icons'

import './index.less'
import { PVConfig } from './pv-config'
import { BatteryConfig } from './battery-config'
import { ClickObservable } from '../../../index'
import { useNavigate, useParams } from 'react-router-dom'
import { ProjectWorkbenchService, TechnologyService } from '../../../apis/project-service-history'
import ReactECharts from 'echarts-for-react'
import { CustomIcons, getFixedNumber } from '../../../global'
import { DemandLimit } from './demand-limit'
import { TechResult } from './tech-result'
import { TechResultConfig } from './tech-result-config'
import { Subject } from 'rxjs'
import { NAME_EXP } from '../../../utils/constant'
import { ProjectWorkbenchApi } from '../../../services'
import AddTeamworkerModal from '../../program/manage/components/AddTeamworkerModal'
import HelperJurisdictionControllerApi from '../../../services/api/HelperJurisdictionControllerApi'
// import useAuthData from '../../../utils/authData'

declare const window: any

export const PlayObservable$ = new Subject<{ infos: string[]; animate: boolean }>()

interface ElementInterface {
  icon: ReactElement
  title: string
  key: string
  selected: boolean
  disabled: boolean
}

const types = [
  {
    name: '能源供应',
    indexes: [0, 1],
  },
  {
    name: '能量存储',
    indexes: [2],
  },
  {
    name: '能源转化',
    indexes: [3, 4],
  },
]

const buttons: ElementInterface[] = [
  {
    icon: <CustomIcons type="klf-pv-model" />,
    title: '光伏',
    key: 'pv',
    selected: false,
    disabled: false,
  },
  {
    icon: <CustomIcons type="klf-wind-model" />,
    title: '风机',
    key: 'wind',
    selected: false,
    disabled: true,
  },
  {
    icon: <CustomIcons type="klf-battery-model" />,
    title: '电储能',
    key: 'battery',
    selected: false,
    disabled: false,
  },
  {
    icon: <CustomIcons type="klf-charge-model" />,
    title: '充电桩',
    key: 'charge',
    selected: false,
    disabled: true,
  },
  {
    icon: <CustomIcons type="klf-transformer-model" />,
    title: '变压器',
    key: 'transformer',
    selected: false,
    disabled: true,
  },
]

const getScale = (largeSize = true) => {
  if (largeSize) {
    const wWidth = window.innerWidth - 600
    const wHeight = window.innerHeight - 220
    const width = 800
    const height = 526.66

    const scaleX = wWidth / width
    const scaleY = wHeight / height

    return Math.max(Math.min(scaleX, scaleY), 0.8)
  } else {
    const wWidth = window.innerWidth - 300
    const wHeight = window.innerHeight - 220
    const width = 800
    const height = 526.66

    const scaleX = wWidth / width
    const scaleY = wHeight / height

    return Math.min(Math.min(scaleX, scaleY))
  }
}

const positions: any = {
  pv: {
    x: 0,
    y: 0,
  },
  battery: {
    x: 0,
    y: 0,
  },
}

export const EleSelector = ({
  kKey,
  technologyId,
  eleSelectors,
  callback,
  data,
  viewMode,
}: {
  kKey: string
  technologyId: string
  eleSelectors: any[]
  callback: Function
  data: any
  viewMode?: boolean
}) => {
  const rKey: string = (
    {
      pv_user: 'photovoltaicToLoadReference',
      pv_gw: 'photovoltaicToUserToPowerGridReference',
      pv_battery: 'photovoltaicToStoredEnergyReference',
      gw_battery: 'powerGridToUserToStoredEnergyReference',
      battery_user: 'storedEnergyToLoadReference',
      gw_user: 'powerGridToUserToLoadReference',
    } as { [key: string]: string }
  )[kKey]

  React.useEffect(() => {
    if (data?.topologyInfo) {
      setType(data.topologyInfo[rKey].consultPriceType)
      setDzVal(data.topologyInfo[rKey].unitValuePrice)
      setVal(data.topologyInfo[rKey].adjustCoefficient)
    }
  }, [data])

  const [type, setType] = useState<number>()
  const [val, setVal] = useState<number>()
  const [dzVal, setDzVal] = useState<number>()

  return (
    <>
      <Row key={`prc-${kKey}`} className="inner-config-content">
        <Col span={10}>参考电价</Col>
        <Col span={14}>
          {viewMode && <span className="inner-config-value">{eleSelectors.find((o) => o.value === type)?.name}</span>}
          {!viewMode && (
            <Select
              value={type}
              onChange={(value) => {
                setType(value)
                const root: any = {
                  [rKey]: {
                    consultPriceType: value,
                  },
                }
                ProjectWorkbenchService.updatePhotovoltaicTopology({
                  technologyId: technologyId,
                  root,
                }).then(() => {
                  callback?.(0)
                })
              }}
            >
              {eleSelectors.map((o) => (
                <Select.Option key={o.value} value={o.value}>
                  {o.name}
                </Select.Option>
              ))}
            </Select>
          )}
        </Col>
      </Row>
      {data && type !== -3 && (
        <Row key={`xs-${kKey}`} className="inner-config-content">
          <Col span={10}>调整系数</Col>
          <Col span={14}>
            {viewMode && <span className="inner-config-value">{val}</span>}
            {!viewMode && (
              <InputNumber
                className="border-number-right"
                id={`tzxs-${kKey}`}
                controls={false}
                max={100}
                min={0}
                precision={2}
                value={val}
                onChange={(val) => {
                  setVal(val)
                }}
                onBlur={(dom) => {
                  let val = parseFloat(dom.target.value)
                  if (!val || isNaN(val)) {
                    val = 0
                  }
                  if (val > 100) {
                    val = 100
                  }
                  if (val < 0) {
                    val = 0
                  }
                  ProjectWorkbenchService.updatePhotovoltaicTopology({
                    technologyId,
                    root: {
                      [rKey]: {
                        consultPriceType: type,
                        adjustCoefficient: val.toFixed(2),
                      },
                    },
                  })
                    .then(() => {
                      callback?.(0)
                      setVal(val)
                    })
                    .catch(() => {
                      // ;(document.getElementById(`tzxs-${kKey}`) as HTMLInputElement).value =
                      //   data.topologyInfo.electricityPriceDiscount
                    })
                }}
              />
            )}
          </Col>
        </Row>
      )}
      {type === -3 && (
        <Row key={`dz-${kKey}`} className="inner-config-content">
          <Col span={10}>单值电价</Col>
          <Col span={14}>
            {viewMode && <span className="inner-config-value">{dzVal}元/kWh</span>}
            {!viewMode && (
              <InputNumber
                id={`dzdj-${kKey}`}
                controls={false}
                max={99999}
                min={0}
                precision={4}
                addonAfter="元/kWh"
                value={dzVal}
                onChange={(val) => {
                  setDzVal(val)
                }}
                onBlur={(dom) => {
                  let val = parseFloat(dom.target.value)
                  if (!val || isNaN(val)) {
                    val = 0
                  }
                  if (val > 99999) {
                    val = 99999
                  }
                  if (val < 0) {
                    val = 0
                  }
                  ProjectWorkbenchService.updatePhotovoltaicTopology({
                    technologyId,
                    root: {
                      [rKey]: {
                        consultPriceType: type,
                        unitValuePrice: val.toFixed(4),
                      },
                    },
                  })
                    .then(() => {
                      callback?.(0)
                      setDzVal(val)
                    })
                    .catch(() => {
                      // ;(document.getElementById(`dzdj-${kKey}`) as HTMLInputElement).value =
                      //   data.topologyInfo.electricityPriceDiscount
                    })
                }}
              />
            )}
          </Col>
        </Row>
      )}
    </>
  )
}

export const TechnicalWorkspace = () => {
  const params = useParams()
  const nav = useNavigate()

  const [edit, setEdit] = React.useState(true)
  const [forceEdit, setForceEdit] = React.useState(false)

  const [loading, setLoading] = React.useState(true)
  const [selected, setSelected] = React.useState<string | undefined>()

  const [showAddElements, setShowAddElements] = React.useState(false)

  const [selectedElements, setSelectedElements] = React.useState<ElementInterface[]>([])
  const [topologyEmbed] = React.useState(React.createRef<any>())
  const [editName, setEditName] = React.useState(false)
  const [proposalData, setProposalData] = React.useState<any>()
  const [calcing, setCalcing] = React.useState(false)

  const [svgLoaded, setSvgLoaded] = React.useState(false)

  const [season, setSeason] = React.useState('')

  const [mouseKey, setMouseKey] = React.useState('')

  const [openList, setOpenList] = React.useState<string[]>(['gw_user'])

  const [isVisible, setIsVisible] = useState(false)
  const [authObj, setAuthObj] = useState({})

  const [topology, setTopology] = React.useState<
    {
      start: string
      end: string
      steps: string[]
      fold: boolean
      key: string
      selected: boolean
      disabled?: boolean
      onSelect?: (selected: boolean) => void
      configuration?: ReactElement
      selectedKey?: string[]
    }[]
  >([])

  const [eleSelectorsState, setEleSelectors] = useState<any[]>([])
  React.useEffect(() => {
    const observable = ClickObservable.subscribe((clicked) => {
      if (!clicked) {
        setShowAddElements(false)
      }
    })

    setLoading(true)
    const playObservable = PlayObservable$.subscribe(({ infos, animate }) => {
      infos.forEach((o) => {
        setAnimate(o, animate)
      })
    })

    const service = new ProjectWorkbenchApi()
    service
      .i3060TechnicalWorkbenchGetReferencePriceOptionTechnicalProposalIdGet({
        technicalProposalId: params.id!,
      })
      .then((resp) => {
        setEleSelectors(resp.data!)
      })

    HelperJurisdictionControllerApi.i3060HelperJurisdictionCurrDataAuthDataIdGet({
      dataId: params?.id,
      dataType: '1',
    }).then((resp) => {
      const obj =
        (resp?.data?.length &&
          resp?.data?.reduce((a: any, b: any, i: any) => ({
            ...(i == 1 ? { [a]: a } : a),
            [b]: b,
          }))) ||
        {}
      setAuthObj(obj)
    })

    loadData()
    return () => {
      observable.unsubscribe()
      playObservable.unsubscribe()
    }
  }, [])

  const placeFileItem = (id: string, isPlacefile: boolean) => {
    TechnologyService.lock({
      technicalId: id,
      root: {
        type: isPlacefile ? '1' : '0',
      },
    }).then(() => {
      loadData()
    })
  }

  const [loadedPvData, setLoadedPvData] = useState(false)
  const [loadedBatteryData, setLoadedBatteryData] = useState(false)
  const loadData = (key?: string) => {
    ProjectWorkbenchService.workbench1({
      technologyId: params.id!,
    }).then((resp) => {
      setProposalData(resp.data)
      setLoadedPvData(true)
      setLoadedBatteryData(true)
      if (resp.data.status === 2 || resp.data.status === 4) {
        setEdit(false)
      }
      const selected = []

      if (resp.data.photovoltaicInfo) {
        selected.push(buttons.find((o) => o.key === 'pv')!)
      }
      try {
        changeElementVisible('pv', !!resp.data.photovoltaicInfo)
      } catch (e) {
        // console.log(e)
      }
      if (resp.data.energyStorageInfo) {
        selected.push(buttons.find((o) => o.key === 'battery')!)
      }
      try {
        changeElementVisible('battery', !!resp.data.energyStorageInfo)
      } catch (e) {
        // console.log(e)
      }

      setEditName(false)

      setTopology([
        {
          start: '光伏',
          end: '负荷',
          key: 'pv_user',
          steps: [],
          fold: true,
          selectedKey: ['pv'],
          selected: !!resp.data.topologyInfo.photovoltaicToLoad,
          onSelect: (selected: boolean) => {
            // console.log(selected)
            ProjectWorkbenchService.updatePhotovoltaicTopology({
              technologyId: params.id!,
              root: {
                photovoltaicToLoad: selected,
              },
            }).then(() => {
              if (selected) {
                setOpenList((openList) => {
                  if (!openList.includes('pv_user')) {
                    openList.push('pv_user')
                  }
                  return [...openList]
                })
              }
              loadData()
            })
          },
        },
        {
          start: '光伏',
          end: '电网',
          key: 'pv_gw',
          selectedKey: ['pv'],
          steps: [resp.data.topologyInfo.photovoltaicParallelNetwork === 2 ? '用户变' : '光伏变'],
          fold: true,
          selected: !!resp.data.topologyInfo.photovoltaicToPowerGrid,
          onSelect: (selected: boolean) => {
            ProjectWorkbenchService.updatePhotovoltaicTopology({
              technologyId: params.id!,
              root: {
                photovoltaicToPowerGrid: selected,
              },
            }).then(() => {
              setOpenList((openList) => {
                if (!openList.includes('pv_gw')) {
                  openList.push('pv_gw')
                }
                return [...openList]
              })
              loadData()
            })
          },
          configuration: (
            <div>
              <Row className="inner-config-content">
                <Col span={10}>并网点</Col>
                <Col span={14}>
                  <Select
                    defaultValue={resp.data.topologyInfo.photovoltaicParallelNetwork}
                    onChange={(val) => {
                      ProjectWorkbenchService.updatePhotovoltaicTopology({
                        technologyId: params.id!,
                        root: {
                          photovoltaicParallelNetwork: val,
                        },
                      }).then(() => {
                        loadData()
                      })
                    }}
                  >
                    <Select.Option value={2}>低压</Select.Option>
                    <Select.Option value={1}>高压</Select.Option>
                  </Select>
                </Col>
              </Row>
              {/*REMOVE PRICE*/}
              {/*<Row className="inner-config-content">*/}
              {/*  <Col span={10}>上网电价</Col>*/}
              {/*  <Col span={14}>*/}
              {/*    <InputNumber*/}
              {/*      id="internetElectricityPrice"*/}
              {/*      controls={false}*/}
              {/*      max={9999}*/}
              {/*      min={0}*/}
              {/*      precision={4}*/}
              {/*      addonAfter="元/kWh"*/}
              {/*      defaultValue={resp.data.topologyInfo.internetElectricityPrice}*/}
              {/*      onBlur={(dom) => {*/}
              {/*        let val = parseFloat(dom.target.value)*/}
              {/*        if (!val || isNaN(val)) {*/}
              {/*          val = 0*/}
              {/*        }*/}
              {/*        if (val < 0) {*/}
              {/*          val = 0*/}
              {/*        }*/}
              {/*        if (val > 9999) {*/}
              {/*          val = 9999*/}
              {/*        }*/}
              {/*        ProjectWorkbenchService.updatePhotovoltaicTopology({*/}
              {/*          technologyId: params.id!,*/}
              {/*          root: {*/}
              {/*            internetElectricityPrice: val.toFixed(4),*/}
              {/*          },*/}
              {/*        })*/}
              {/*          .then(() => {*/}
              {/*            loadData()*/}
              {/*          })*/}
              {/*          .catch(() => {*/}
              {/*            ;(document.getElementById('internetElectricityPrice') as HTMLInputElement).value =*/}
              {/*              resp.data.topologyInfo.internetElectricityPrice*/}
              {/*          })*/}
              {/*      }}*/}
              {/*    />*/}
              {/*  </Col>*/}
              {/*</Row>*/}
            </div>
          ),
        },
        {
          start: '光伏',
          end: '储能',
          key: 'pv_battery',
          steps: [],
          fold: true,
          selectedKey: ['pv', 'battery'],
          selected: !!resp.data.topologyInfo.photovoltaicToEnergyStorage,
          onSelect: (selected: boolean) => {
            ProjectWorkbenchService.updateEnergyStorageTopology({
              technologyId: params.id!,
              root: {
                photovoltaicToEnergyStorage: selected,
              },
            }).then(() => {
              setOpenList((openList) => {
                if (!openList.includes('pv_battery')) {
                  openList.push('pv_battery')
                }
                return [...openList]
              })

              loadData()
            })
          },
          // REMOVE LCOE
          // configuration: (
          //   <div>
          //     <Row className="inner-config-content">
          //       <Col span={10}>LCOE</Col>
          //       <Col span={14}>
          //         <InputNumber
          //           id="lcde"
          //           controls={false}
          //           max={9999}
          //           min={0}
          //           precision={4}
          //           addonAfter="元/kWh"
          //           defaultValue={resp.data.topologyInfo.lcde}
          //           onBlur={(dom) => {
          //             let val = parseFloat(dom.target.value)
          //             if (!val || isNaN(val)) {
          //               val = 0
          //             }
          //             if (val < 0) {
          //               val = 0
          //             }
          //             if (val > 9999) {
          //               val = 9999
          //             }
          //             ProjectWorkbenchService.updateEnergyStorageTopology({
          //               technologyId: params.id!,
          //               root: {
          //                 lcde: val.toFixed(4),
          //               },
          //             })
          //               .then(() => {
          //                 loadData()
          //               })
          //               .catch(() => {
          //                 ;(document.getElementById('lcde') as HTMLInputElement).value = resp.data.topologyInfo.lcde
          //               })
          //           }}
          //         />
          //       </Col>
          //     </Row>
          //   </div>
          // ),
        },
        {
          start: '电网',
          end: '储能',
          key: 'gw_battery',
          selectedKey: ['battery'],
          steps: [resp.data.topologyInfo.energyStorageParallelNetwork === 2 ? '用户变' : '储能变'],
          fold: true,
          selected: !!resp.data.topologyInfo.powerGridToEnergyStorage,
          onSelect: (selected: boolean) => {
            ProjectWorkbenchService.updateEnergyStorageTopology({
              technologyId: params.id!,
              root: {
                powerGridToEnergyStorage: selected,
              },
            }).then(() => {
              setOpenList((openList) => {
                if (!openList.includes('gw_battery')) {
                  openList.push('gw_battery')
                }
                return [...openList]
              })

              loadData()
            })
          },
          configuration: (
            <div>
              <Row className="inner-config-content">
                <Col span={10}>接入点</Col>
                <Col span={14}>
                  <Select
                    disabled
                    defaultValue={resp.data.topologyInfo.energyStorageParallelNetwork}
                    onChange={(val) => {
                      ProjectWorkbenchService.updateEnergyStorageTopology({
                        technologyId: params.id!,
                        root: {
                          energyStorageParallelNetwork: val,
                        },
                      }).then(() => {
                        loadData()
                      })
                    }}
                  >
                    <Select.Option value={2}>低压</Select.Option>
                    <Select.Option value={1}>高压</Select.Option>
                  </Select>
                </Col>
              </Row>
              <Row className="inner-config-content">
                <Col span={10}>电费支付</Col>
                <Col span={14}>
                  <Select
                    defaultValue={resp.data.topologyInfo.electricityPaymentType}
                    onChange={(val) => {
                      ProjectWorkbenchService.updateEnergyStorageTopology({
                        technologyId: params.id!,
                        root: {
                          electricityPaymentType: val,
                        },
                      }).then(() => {
                        loadData()
                      })
                    }}
                  >
                    <Select.Option value={1}>由用户支付</Select.Option>
                    <Select.Option value={2}>自行支付</Select.Option>
                  </Select>
                </Col>
              </Row>
            </div>
          ),
        },
        {
          start: '储能',
          end: '负荷',
          key: 'battery_user',
          steps: [],
          fold: true,
          selectedKey: ['battery'],
          selected: !!resp.data.topologyInfo.energyStorageToLoad,
          onSelect: (selected: boolean) => {
            ProjectWorkbenchService.updateEnergyStorageTopology({
              technologyId: params.id!,
              root: {
                energyStorageToLoad: selected,
              },
            }).then(() => {
              setOpenList((openList) => {
                if (!openList.includes('battery_user')) {
                  openList.push('battery_user')
                }
                return [...openList]
              })

              loadData()
            })
          },
        },
        {
          start: '电网',
          end: '负荷',
          key: 'gw_user',
          steps: ['用户变'],
          fold: true,
          selected: true,
          disabled: true,
        },
      ])

      setSelectedElements(selected)
      setLoading(false)
      if (key) {
        setTimeout(() => {
          setSelected(key)
        }, 500)
      }
    })
  }

  // const auth = useAuthData(proposalData?.id) || {}

  // const hasAuth = useMemo(() => {
  //   return auth['technical_proposal']
  // }, [auth])

  const onDelete = (key: string) => {
    if (key === 'battery') {
      return ProjectWorkbenchService.removeEnergyStorage({
        technologyId: params.id!,
      })
    }
    if (key === 'pv') {
      return ProjectWorkbenchService.removePhotovoltaic({
        technologyId: params.id!,
      })
    }
  }

  const onAdd = (key: string) => {
    if (key === 'battery') {
      return ProjectWorkbenchService.addEnergyStorage({
        technologyId: params.id!,
      })
    }
    if (key === 'pv') {
      return ProjectWorkbenchService.addPhotovoltaic({
        technologyId: params.id!,
      })
    }
  }
  const handleElementClick = (key: string) => {
    const idx = selectedElements.findIndex((o) => o.key === key)
    if (idx >= 0) {
      // onDelete(key)?.then(() => {
      //   loadData()
      // })
    } else {
      const item = buttons.find((o) => o.key === key)
      if (item) {
        onAdd(key)?.then(() => {
          loadData(key)
        })
      }
    }
    setSelectedElements([...selectedElements])
  }
  const getCurrentSelected = () => {
    if (selected === 'pv') {
      if (proposalData.photovoltaicInfo) {
        return 'pv'
      }
    }
    if (selected === 'battery') {
      if (proposalData.energyStorageInfo) {
        return 'battery'
      }
    }
    return ''
  }
  const getElementByKey = (key: string) => {
    return topologyEmbed.current.getSVGDocument().getElementById(key)
  }

  const setAnimate = (
    key: string,
    // | 'transformer_pv_pv_fill'
    // | 'gw_transformer_bear_fill'
    // | 'transformer_bear_bear_fill'
    // | 'gw_transformer_battery_fill'
    // | 'transformer_battery_battery_fill'
    // | 'pv_battery_fill'
    // | 'pv_bear_fill'
    // | 'gw_transformer_pv_fill'
    // | 'battery_bear_fill'
    visible: boolean
  ) => {
    ;(window as any).testElement = getElementByKey(key)
    getElementByKey(key).style.opacity = visible ? 1 : 0
  }

  const changeElementVisible = (key: string, visible: boolean) => {
    getElementByKey(key).style.opacity = visible ? 1 : 0
  }

  React.useEffect(() => {
    if (proposalData && svgLoaded) {
      changeDoms()
    }
  }, [svgLoaded, proposalData])

  const cannotCalc = () => {
    return false
  }

  const resetPosition = () => {
    const { top: sTop, left: sLeft } = topologyEmbed.current.getBoundingClientRect()
    const tWidth = 800 * getScale(edit || forceEdit)
    const scale = tWidth / 1640.52
    {
      positions.pv.x = sLeft + 554.1 * scale + (313.2 * scale) / 2 - 159
      positions.pv.y = sTop + 191.06 * scale + (313.2 * scale) / 2 + 50
    }
    {
      positions.battery.x = sLeft + 793.06 * scale + (313.2 * scale) / 2 - 159
      positions.battery.y = sTop + 263.43 * scale + (313.2 * scale) / 2 + 50
    }
  }

  const changeDoms = () => {
    const {
      photovoltaicToEnergyStorage,
      photovoltaicToLoad,
      photovoltaicToPowerGrid,
      powerGridToEnergyStorage,
      energyStorageToLoad,
      photovoltaicParallelNetwork,
    } = proposalData.topologyInfo

    try {
      changeElementVisible('pv_battery', photovoltaicToEnergyStorage)
      changeElementVisible('pv_bear', photovoltaicToLoad)
      changeElementVisible('gw_pv', photovoltaicToPowerGrid && photovoltaicParallelNetwork === 1)
      changeElementVisible('gw_pv_low', photovoltaicToPowerGrid && photovoltaicParallelNetwork === 2)
      changeElementVisible('gw_battery', powerGridToEnergyStorage)
      changeElementVisible('battery_bear', energyStorageToLoad)
    } catch (e) {
      //console.log(e)
    }
  }

  /**
   * 查看光伏发电验证报告
   */
  const showPhotovoltaicInfoRecord = () => {
    // setLoading(true)
    ProjectWorkbenchService.photovoltaicValidReport()
      .then((resp) => {
        window.open(resp.data, '_blank')
      })
      .catch(() => {})
      .finally(() => {
        // setLoading(false)
      })
  }

  return (
    <Layout
      style={{
        height: '100%',
      }}
    >
      <Layout.Header
        style={{
          position: 'fixed',
          zIndex: 1,
          width: '100%',
          backgroundColor: 'white',
          paddingLeft: '1rem',
          paddingRight: '1rem',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          height: 60,
          boxShadow: '0px 2px 14px 0px rgba(190, 194, 204, 0.3)',
        }}
      >
        <Button
          icon={<LeftOutlined />}
          type="text"
          onClick={() => {
            nav(`/program/detail/${proposalData.projectId}`)
          }}
        >
          技术方案
        </Button>
        {proposalData && (
          <div className="hover-outer">
            {!editName && (
              <div>
                {proposalData.technologyName}
                {!proposalData.locked && authObj?.['project_technical_proposal_operation'] && (
                  <Button
                    className="hover-inner"
                    type="text"
                    disabled={!authObj['project_technical_proposal_operation']}
                    icon={<CustomIcons type="klf-edit1" />}
                    onClick={() => {
                      setEditName(true)
                      setTimeout(() => {
                        document.getElementById('technologyNameEditor')?.focus()
                      })
                    }}
                  />
                )}
              </div>
            )}
            {editName && (
              <Input
                id="technologyNameEditor"
                allowClear
                onBlur={(dom) => {
                  if (!NAME_EXP.test(dom.target.value)) {
                    message.error('"1-16个字符,中文、英文、_、数字和-组成"')
                    dom.target?.focus()
                    return
                  }
                  TechnologyService.rename({
                    technicalId: params.id!,
                    root: {
                      name: dom.target.value,
                    },
                  }).then(() => {
                    loadData()
                  })
                }}
                defaultValue={proposalData.technologyName}
              />
            )}
          </div>
        )}
        <Space>
          {(authObj['project_technical_proposal_helper']) && (
            <Tooltip title="添加协作者">
              <Button
                type="link"
                icon={<UserAddOutlined style={{ fontSize: 20 }} />}
                onClick={() => setIsVisible(true)}
              ></Button>
            </Tooltip>
          )}
          {!(edit || forceEdit) && (
            <div>
              {!proposalData.locked && authObj && (
                <Button
                  type="primary"
                  // disabled={!hasAuth}
                  disabled={!authObj['project_technical_proposal_operation']}
                  ghost
                  onClick={() => {
                    if (proposalData.status === 2 || proposalData.status === 4) {
                      Modal.confirm({
                        keyboard: false,
                        title: '修改方案',
                        icon: <CustomIcons type="klf-alarm1" />,
                        content: `确认修改${proposalData.technologyName}？修改后，方案原结果将被清除`,
                        onOk: () => {
                          setForceEdit(true)
                        },
                        cancelButtonProps: {
                          type: 'primary',
                          ghost: true,
                        },
                      })
                    } else {
                      setForceEdit(true)
                    }
                  }}
                >
                  编辑
                </Button>
              )}
              {proposalData?.status === 4 && (
                <Button
                  style={{
                    marginLeft: '.5rem',
                  }}
                  disabled={cannotCalc() || !authObj['project_technical_proposal_operation']}
                  // disabled={cannotCalc() || !hasAuth}
                  loading={calcing}
                  icon={<PlayCircleOutlined />}
                  type="primary"
                  onClick={() => {
                    Modal.confirm({
                      keyboard: false,
                      title: '重新计算',
                      icon: <CustomIcons type="klf-alarm1" />,
                      content: `确认重新计算${proposalData.technologyName}？原计算结果将覆盖`,
                      cancelButtonProps: {
                        type: 'primary',
                        ghost: true,
                      },
                      onOk: () => {
                        setCalcing(true)
                        ProjectWorkbenchService.calcTechnical({
                          technologyId: params.id!,
                        })
                          .then(() => {
                            nav(`/program/detail/${proposalData.projectId}`)
                          })
                          .catch(() => {})
                          .finally(() => {
                            setCalcing(false)
                          })
                      },
                    })
                  }}
                >
                  重新计算
                </Button>
              )}
              {proposalData?.status !== 4 && (
                <Button
                  style={{
                    marginLeft: '.5rem',
                  }}
                  type="primary"
                  disabled={!authObj['project_technical_proposal_operation']}
                  // disabled={!hasAuth}
                  onClick={() => {
                    if (!proposalData.locked) {
                      Modal.confirm({
                        keyboard: false,
                        title: '归档',
                        icon: <CustomIcons type="klf-alarm" />,
                        content: `确认归档${proposalData.technologyName}？归档后，方案将不可修改`,
                        onOk: () => {
                          placeFileItem(proposalData.technologyId, !proposalData.locked)
                        },
                        cancelButtonProps: {
                          type: 'primary',
                          ghost: true,
                        },
                      })
                    } else {
                      Modal.confirm({
                        keyboard: false,
                        title: <h2>解除归档</h2>,
                        content: (
                          <>
                            <p>
                              {proposalData.relationFinanceNameList && '一旦解除归档，以下相关联的方案将全部删除，'}
                              确定解除归档吗
                            </p>
                            {proposalData.relationFinanceNameList && (
                              <List
                                style={{
                                  maxHeight: 300,
                                  overflowY: 'auto',
                                  paddingRight: 5,
                                }}
                                grid={{ gutter: 10, column: 1 }}
                                dataSource={proposalData.relationFinanceNameList}
                                size="small"
                                renderItem={(item: string) => (
                                  <List.Item
                                    style={{
                                      backgroundColor: 'rgba(249, 121, 66, 0.06)',
                                      color: 'rgba(249, 121, 66, 1)',
                                      height: 40,
                                      lineHeight: '40px',
                                    }}
                                  >
                                    {item}
                                  </List.Item>
                                )}
                              />
                            )}
                          </>
                        ),
                        onOk: () => {
                          placeFileItem(proposalData.technologyId, !proposalData.locked)
                        },
                        okButtonProps: {
                          danger: true,
                        },
                        cancelButtonProps: {
                          danger: true,
                        },
                      })
                    }
                  }}
                >
                  {proposalData.locked ? '解除归档' : '归档'}
                </Button>
              )}
            </div>
          )}
          {(edit || forceEdit) && (
            <Button
              // disabled={cannotCalc() || hasAuth}
              disabled={!authObj['project_technical_proposal_operation']}
              loading={calcing}
              icon={<PlayCircleOutlined />}
              type="primary"
              onClick={() => {
                if (proposalData.status === 2 || proposalData.status === 4) {
                  Modal.confirm({
                    keyboard: false,
                    title: '重新计算',
                    icon: <CustomIcons type="klf-alarm1" />,
                    content: `确认重新计算${proposalData.technologyName}？原计算结果将覆盖`,
                    cancelButtonProps: {
                      type: 'primary',
                      ghost: true,
                    },
                    onOk: () => {
                      setCalcing(true)
                      setTimeout(() => {
                        ProjectWorkbenchService.calcTechnical({
                          technologyId: params.id!,
                        })
                          .then(() => {
                            nav(`/program/detail/${proposalData.projectId}`)
                          })
                          .catch(() => {
                          })
                          .finally(() => {
                            setCalcing(false)
                          })
                      }, 500);
                    },
                  })
                } else {
                  setCalcing(true)
                  setTimeout(() => {
                    ProjectWorkbenchService.calcTechnical({
                      technologyId: params.id!,
                    })
                      .then(() => {
                        nav(`/program/detail/${proposalData.projectId}`)
                      })
                      .catch(() => {
                      })
                      .finally(() => {
                        setCalcing(false)
                      })
                  }, 500);
                }
              }}
            >
              开始计算
            </Button>
          )}
        </Space>
      </Layout.Header>
      <Layout.Content className="workspace-layout" style={{ marginTop: 64 }}>
        <Spin
          spinning={loading}
          style={{
            width: '100%',
            height: '100%',
          }}
        >
          {!loading && (
            <div
              className="site-layout-background"
              style={{ height: '100%' }}
              onClick={(event) => {
                // console.log(event.nativeEvent.composedPath())
                if (
                  (event.nativeEvent.composedPath()[1] as any).className.includes?.(
                    'site-drawer-render-in-current-wrapper'
                  )
                ) {
                  setSelected(undefined)
                  changeElementVisible('pv_selected', false)
                  changeElementVisible('battery_selected', false)
                  setMouseKey('')
                }
              }}
            >
              <Row
                style={{
                  height: '100%',
                }}
              >
                {(edit || forceEdit) && (
                  <Col
                    style={{
                      width: 316,
                      display: 'flex',
                      flexDirection: 'column',
                      height: '100%',
                    }}
                  >
                    <Popover
                      placement="rightTop"
                      overlayInnerStyle={{
                        padding: '19px 17px',
                      }}
                      overlayClassName="overlay-inner"
                      content={
                        <div className={showAddElements ? 'popover-outside-close' : ''}>
                          {types.map((o) => (
                            <div
                              key={o.name}
                              style={{
                                marginTop: 9,
                              }}
                            >
                              <span
                                style={{
                                  lineHeight: '22px',
                                }}
                              >
                                {o.name}
                              </span>
                              <Row
                                style={{
                                  marginTop: 9,
                                }}
                              >
                                {o.indexes.map((idx) => {
                                  const button = buttons[idx]
                                  return (
                                    <Col
                                      span={12}
                                      style={{
                                        padding: 0,
                                        paddingRight: 16,
                                      }}
                                      key={button.key}
                                    >
                                      {button.disabled && (
                                        <Button
                                          className="vertical-align"
                                          disabled
                                          style={{ borderRadius: 6, width: 60, height: 60 }}
                                          icon={button.icon}
                                        >
                                          {button.title}
                                        </Button>
                                      )}
                                      {!button.disabled && (
                                        <Button
                                          className={`vertical-align ${
                                            selectedElements.find((o) => o.key === button.key) ? 'icon-selected' : ''
                                          }`}
                                          style={{
                                            backgroundColor: selectedElements.find((o) => o.key === button.key)
                                              ? 'rgba(30, 198, 146, .1)'
                                              : 'white',
                                            color: 'rgba(0, 0, 0, 0.65)',
                                            borderColor: selectedElements.find((o) => o.key === button.key)
                                              ? 'rgb(30, 198, 146)'
                                              : 'rgba(0, 0, 0, 0.65)',
                                            borderRadius: 6,
                                            width: 60,
                                            height: 60,
                                          }}
                                          ghost={!!selectedElements.find((o) => o.key === button.key)}
                                          onClick={() => {
                                            handleElementClick(button.key)
                                          }}
                                          icon={button.icon}
                                        >
                                          {button.title}
                                        </Button>
                                      )}
                                    </Col>
                                  )
                                })}
                              </Row>
                            </div>
                          ))}
                        </div>
                      }
                      visible={showAddElements}
                    >
                      <Card
                        title="要素选择"
                        headStyle={{
                          borderBottom: 'none',
                        }}
                        bodyStyle={{
                          padding: '4px 0 14px 16px',
                        }}
                      >
                        <Row>
                          {selectedElements?.map((o) => (
                            <Col
                              key={o.key}
                              style={{
                                width: 38,
                                height: 38,
                                marginRight: '1rem',
                              }}
                            >
                              <Button
                                className="show-delete-button icon-selected"
                                style={{
                                  width: '100%',
                                  height: '100%',
                                  backgroundColor: selected === o.key ? 'rgba(30, 198, 146, .1)' : 'white',
                                  borderColor: 'rgb(30, 198, 146)',
                                  borderRadius: 6,
                                }}
                                icon={o.icon}
                                onClick={() => {
                                  Modal.confirm({
                                    keyboard: false,
                                    title: '删除确认',
                                    icon: <CustomIcons type="klf-alarm1" />,
                                    content: '确认要删除该要素吗？此操作将删除该要素相关的所有内容',
                                    okButtonProps: {
                                      danger: true,
                                    },
                                    cancelButtonProps: {
                                      danger: true,
                                      ghost: true,
                                    },
                                    onOk: () => {
                                      onDelete(o.key)?.then(() => {
                                        loadData()
                                      })
                                    },
                                  })
                                }}
                              >
                                <div className="delete-icon">
                                  <CustomIcons
                                    type="klf-delete"
                                    style={{
                                      color: 'white',
                                    }}
                                  />
                                </div>
                              </Button>
                            </Col>
                          ))}
                          <Col
                            style={{
                              width: 38,
                              height: 38,
                            }}
                          >
                            <Button
                              style={{
                                width: '100%',
                                height: '100%',
                                borderRadius: 6,
                              }}
                              type={'primary'}
                              icon={<PlusOutlined />}
                              onClick={() => {
                                setShowAddElements(true)
                                setSelected(undefined)
                              }}
                            />
                          </Col>
                        </Row>
                      </Card>
                    </Popover>
                    <div
                      style={{
                        flexGrow: 1,
                        display: 'flex',
                        flexDirection: 'column',
                        overflowY: 'auto',
                        marginTop: '.5rem',
                      }}
                    >
                      <Card
                        style={{
                          marginTop: 0,
                        }}
                        title="策略目标"
                        extra={
                          <Select
                            style={{
                              width: 150,
                            }}
                            defaultValue={proposalData.strategyType}
                          >
                            <Select.Option value={1}>经济最优</Select.Option>
                          </Select>
                        }
                        headStyle={{
                          padding: '0 1rem',
                        }}
                        bodyStyle={{
                          padding: 0,
                        }}
                      />
                      <Card
                        title="需量限制"
                        style={{
                          marginTop: 0,
                        }}
                        extra={
                          <Button
                            type="link"
                            icon={<SettingOutlined />}
                            onClick={() => {
                              const modal = Modal.confirm({
                                keyboard: false,
                                title: <h2>需量限制</h2>,
                                closable: true,
                                icon: null,
                                width: 480,
                                content: (
                                  <DemandLimit
                                    technicalProposalId={params.id!}
                                    data={{ ...proposalData.demandLimit }}
                                    onClose={(reload: boolean = false) => {
                                      modal.destroy()
                                      if (reload) {
                                        loadData()
                                      }
                                    }}
                                  />
                                ),
                                okButtonProps: { style: { display: 'none' } },
                                cancelButtonProps: { style: { display: 'none' } },
                              })
                            }}
                          />
                        }
                        headStyle={{
                          padding: '0 1rem',
                          borderBottom: 'none',
                        }}
                        bodyStyle={{
                          padding: 0,
                        }}
                      >
                        <div
                          style={{
                            padding: '0 1rem 16px 1rem',
                          }}
                        >
                          <div
                            style={{
                              width: '100%',
                              height: 150,
                            }}
                          >
                            <ReactECharts
                              style={{
                                width: '100%',
                                height: '100%',
                              }}
                              option={{
                                grid: { left: 0, right: 0, top: 30, bottom: 0, containLabel: true },
                                tooltip: {
                                  trigger: 'axis',
                                  formatter: (argsList: any) => {
                                    const args = argsList[0]
                                    return `${args.seriesName}<br />${args.marker}${args.name}月: ${args.value}kW`
                                  },
                                },
                                xAxis: {
                                  type: 'category',
                                  data: Object.keys(proposalData.demandLimit),
                                  axisLabel: {
                                    formatter: (str: string) => {
                                      return `${str}月`
                                    },
                                  },
                                  axisTick: {
                                    show: false,
                                  },
                                },
                                yAxis: {
                                  type: 'value',
                                  boundaryGap: ['0%', '10%'],
                                  name: 'kW',
                                  nameTextStyle: {
                                    color: 'gray',
                                    padding: [0, 40, 0, 0],
                                  },
                                  splitLine: {
                                    lineStyle: {
                                      type: [5, 5],
                                      dashOffset: 5,
                                    },
                                  },
                                },
                                series: [
                                  {
                                    name: '需量',
                                    type: 'bar',
                                    data: Object.values(proposalData.demandLimit),
                                    barWidth: Math.min(150 / Object.values(proposalData.demandLimit).length, 18),
                                    itemStyle: {
                                      color: '#1EC692',
                                      barBorderRadius: [4, 4, 0, 0],
                                    },
                                  },
                                ],
                              }}
                            />
                          </div>
                        </div>
                      </Card>
                      <Card
                        title="用户变总容量"
                        style={{
                          marginTop: 0,
                        }}
                        extra={
                          <Input
                            disabled
                            style={{
                              width: 130,
                            }}
                            suffix="kVA"
                            defaultValue={proposalData.userTransformerCapacity}
                          />
                        }
                        headStyle={{
                          padding: '0 1rem',
                        }}
                        bodyStyle={{
                          padding: 0,
                        }}
                      />
                      <Card
                        title={
                          <div
                            style={{
                              padding: '4px 0',
                            }}
                          >
                            拓扑设置
                          </div>
                        }
                        extra={
                          <Row>
                            <Col>
                              <div className="square-point start-point" />
                              起点
                            </Col>
                            <Col>
                              <div className="square-point end-point" />
                              终点
                            </Col>
                          </Row>
                        }
                        headStyle={{
                          borderBottom: 'none',
                        }}
                        bodyStyle={{
                          padding: 0,
                        }}
                        style={{
                          marginTop: 0,
                        }}
                      >
                        <div
                          style={{
                            padding: '0 12px',
                            borderBottom: '1px solid #f0f0f0',
                          }}
                        >
                          <div
                            style={{
                              padding: '.3rem',
                            }}
                          >
                            {topology?.map((o, idx) => {
                              if (
                                o.selectedKey?.filter(
                                  (o: string) => selectedElements.findIndex((oo) => oo.key === o) < 0
                                ) &&
                                o.selectedKey.filter(
                                  (o: string) => selectedElements.findIndex((oo) => oo.key === o) < 0
                                ).length > 0
                              ) {
                                return null
                              }
                              return (
                                <Card
                                  key={o.key}
                                  style={{
                                    marginTop: 0,
                                    marginBottom: '.5rem',
                                    boxShadow: '0px 2px 14px 0px rgba(190, 194, 204, 0.3)',
                                  }}
                                  title={
                                    <Row
                                      style={{
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                        height: 52,
                                      }}
                                    >
                                      <Col>
                                        <Checkbox
                                          disabled={
                                            o.disabled ||
                                            (o.selectedKey?.filter(
                                              (o: string) => selectedElements.findIndex((oo) => oo.key === o) < 0
                                            ) &&
                                              o.selectedKey.filter(
                                                (o: string) => selectedElements.findIndex((oo) => oo.key === o) < 0
                                              ).length > 0)
                                          }
                                          checked={o.selected}
                                          onChange={(val) => {
                                            topology[idx].selected = val.target.checked
                                            setTopology([...topology])
                                            o.onSelect?.(val.target.checked)
                                          }}
                                          style={{
                                            marginRight: '.5rem',
                                          }}
                                        />
                                        <span className="start-label">{o.start}</span>
                                      </Col>
                                      <Col key={`arrow-${idx}`} className="arrow-right-label">
                                        <CustomIcons type="klf-arrow" />
                                      </Col>
                                      {o.steps.map((o, idx) => [
                                        <Col key={idx} className="cross-label">
                                          {o}
                                        </Col>,
                                        <Col key={`arrow-${idx}`} className="arrow-right-label">
                                          <CustomIcons type="klf-arrow" />
                                        </Col>,
                                      ])}
                                      <Col>
                                        <span className="end-label">{o.end} </span>
                                        {/*{!o.configuration && <div style={{ display: 'inline-block', width: 20 }} />}*/}
                                        {o.selected && (
                                          <Button
                                            type="text"
                                            disabled={
                                              o.selectedKey?.filter(
                                                (o: string) => selectedElements.findIndex((oo) => oo.key === o) < 0
                                              ) &&
                                              o.selectedKey.filter(
                                                (o: string) => selectedElements.findIndex((oo) => oo.key === o) < 0
                                              ).length > 0
                                            }
                                            style={{
                                              width: 20,
                                              opacity:
                                                o.selectedKey?.filter(
                                                  (o: string) => selectedElements.findIndex((oo) => oo.key === o) < 0
                                                ) &&
                                                o.selectedKey.filter(
                                                  (o: string) => selectedElements.findIndex((oo) => oo.key === o) < 0
                                                ).length > 0
                                                  ? 0
                                                  : 1,
                                            }}
                                            onClick={() => {
                                              if (openList.includes(topology[idx].key)) {
                                                topology[idx].fold = true
                                              } else {
                                                topology[idx].fold = !o.fold
                                              }
                                              if (!topology[idx].fold) {
                                                if (!openList.includes(topology[idx].key)) {
                                                  openList.push(topology[idx].key)
                                                }
                                              } else {
                                                const listIdx = openList.findIndex((o) => o === topology[idx].key)
                                                if (listIdx >= 0) {
                                                  openList.splice(listIdx, 1)
                                                }
                                              }
                                              setOpenList([...openList])
                                              setTopology([...topology])
                                            }}
                                            icon={
                                              <CustomIcons
                                                type={
                                                  o.fold && !openList.includes(topology[idx].key)
                                                    ? 'klf-arrow-up'
                                                    : 'klf-arrow-down'
                                                }
                                              />
                                            }
                                          />
                                        )}
                                      </Col>
                                    </Row>
                                  }
                                  headStyle={{
                                    minHeight: 48,
                                    padding: '12px 1rem',
                                    borderBottomStyle: 'none',
                                  }}
                                  bodyStyle={{
                                    padding: '0px 12px 8px 12px',
                                    display:
                                      o.selectedKey?.filter(
                                        (o: string) => selectedElements.findIndex((oo) => oo.key === o) < 0
                                      ) &&
                                      o.selectedKey.filter(
                                        (o: string) => selectedElements.findIndex((oo) => oo.key === o) < 0
                                      ).length > 0
                                        ? 'none'
                                        : o.fold && !openList.includes(o.key)
                                        ? 'none'
                                        : 'block',
                                  }}
                                >
                                  <div className="dotted-line" />
                                  <EleSelector
                                    kKey={o.key}
                                    technologyId={params.id!}
                                    eleSelectors={eleSelectorsState}
                                    data={proposalData}
                                    callback={() => {
                                      loadData()
                                    }}
                                  />
                                  {o.configuration}
                                </Card>
                              )
                            })}
                          </div>
                          <Button
                            type="link"
                            style={{
                              width: '100%',
                              marginBottom: '1rem',
                            }}
                            onClick={() => {
                              const allOpened = topology.filter((o) => o.fold === true).length === 0
                              if (allOpened) {
                                setOpenList([])
                                setTopology(topology.map((o) => ({ ...o, fold: true })))
                              } else {
                                setOpenList(topology.map((o) => o.key))
                                setTopology(topology.map((o) => ({ ...o, fold: false })))
                              }
                            }}
                          >
                            {topology.filter((o) => o.fold === true).length === 0 ? '折叠所有配置' : '展开所有配置'}
                          </Button>
                        </div>
                      </Card>
                    </div>
                  </Col>
                )}
                <Col
                  flex="1 1"
                  className="site-drawer-render-in-current-wrapper"
                  style={{
                    height: '100%',
                    padding: 0,
                  }}
                >
                  <div
                    onScroll={() => {
                      setMouseKey('')
                      resetPosition()
                    }}
                    style={{
                      height: '100%',
                      overflowY: 'auto',
                      padding: 48,
                    }}
                  >
                    <div
                      style={{
                        height: edit || forceEdit ? '100%' : '150%',
                        position: 'relative',
                      }}
                    >
                      {proposalData && (
                        <embed
                          style={{
                            pointerEvents: showAddElements ? 'none' : 'all',
                            width: 800 * getScale(edit || forceEdit),
                            height: 526.66 * getScale(edit || forceEdit),
                            marginLeft:
                              edit || forceEdit ? (window.innerWidth - 800 * getScale(edit || forceEdit)) / 2 - 300 : 0,
                            left: edit || forceEdit ? undefined : 'calc(50vw - 140px)',
                            transform: edit || forceEdit ? undefined : 'translateX(-50%)',
                          }}
                          onLoad={() => {
                            changeElementVisible('pv', !!proposalData.photovoltaicInfo)
                            changeElementVisible('battery', !!proposalData.energyStorageInfo)

                            getElementByKey('pv').addEventListener('mouseenter', () => {
                              changeElementVisible('pv_selected', true)
                              changeElementVisible('pv_text', true)
                            })
                            getElementByKey('pv').addEventListener('mouseleave', () => {
                              changeElementVisible('pv_text', false)
                              setSelected((selected) => {
                                if (selected !== 'pv') {
                                  changeElementVisible('pv_selected', false)
                                }
                                return selected
                              })
                              setMouseKey('')
                            })

                            getElementByKey('pv').addEventListener('click', () => {
                              changeElementVisible('pv_selected', true)
                              changeElementVisible('battery_selected', false)
                              setSelected('pv')
                            })

                            getElementByKey('battery').addEventListener('mouseenter', () => {
                              changeElementVisible('battery_selected', true)
                              changeElementVisible('battery_text', true)
                            })
                            getElementByKey('battery').addEventListener('mouseleave', () => {
                              changeElementVisible('battery_text', false)
                              setSelected((selected) => {
                                if (selected !== 'battery') {
                                  changeElementVisible('battery_selected', false)
                                }
                                return selected
                              })

                              setMouseKey('')
                            })
                            getElementByKey('battery').addEventListener('click', () => {
                              changeElementVisible('pv_selected', false)
                              changeElementVisible('battery_selected', true)
                              setSelected('battery')
                            })

                            getElementByKey('gw_focus').addEventListener('mouseenter', () => {
                              changeElementVisible('gw_selected', true)
                              changeElementVisible('gw_text', true)
                            })
                            getElementByKey('gw_focus').addEventListener('mouseleave', () => {
                              changeElementVisible('gw_selected', false)
                              changeElementVisible('gw_text', false)
                            })

                            getElementByKey('bear').addEventListener('mouseenter', () => {
                              changeElementVisible('bear_selected', true)
                              changeElementVisible('bear_text', true)
                            })
                            getElementByKey('bear').addEventListener('mouseleave', () => {
                              changeElementVisible('bear_selected', false)
                              changeElementVisible('bear_text', false)
                            })

                            getElementByKey('gw_pv_transformer_focus').addEventListener('mouseenter', () => {
                              changeElementVisible('gf_transformer_text', true)
                            })
                            getElementByKey('gw_pv_transformer_focus').addEventListener('mouseleave', () => {
                              changeElementVisible('gf_transformer_text', false)
                            })
                            getElementByKey('gw_bear_transformer').addEventListener('mouseenter', () => {
                              changeElementVisible('user_transformer_text', true)
                            })
                            getElementByKey('gw_bear_transformer').addEventListener('mouseleave', () => {
                              changeElementVisible('user_transformer_text', false)
                            })

                            proposalData.photovoltaicInfo &&
                              getElementByKey('pv').addEventListener('mouseenter', () => {
                                if (mouseKey !== 'pv') {
                                  setTimeout(() => {
                                    setMouseKey('pv')
                                  })
                                }
                                // const { screenX: left, screenY: top } = e
                                // if (document.getElementById('custom-event-tooltip')) {
                                //   ;(document.getElementById('custom-event-tooltip') as any).style.left = `${left}px`
                                //   ;(document.getElementById('custom-event-tooltip') as any).style.top = `${top}px`
                                // }
                              })
                            proposalData.energyStorageInfo &&
                              getElementByKey('battery').addEventListener('mouseenter', () => {
                                if (mouseKey !== 'battery') {
                                  setTimeout(() => {
                                    setMouseKey('battery')
                                  })
                                }
                                // const { screenX: left, screenY: top } = e
                                // if (document.getElementById('custom-event-tooltip')) {
                                //   ;(document.getElementById('custom-event-tooltip') as any).style.left = `${left}px`
                                //   ;(document.getElementById('custom-event-tooltip') as any).style.top = `${top}px`
                                // }
                              })

                            setSvgLoaded(true)
                            getElementByKey('bg').addEventListener('click', () => {
                              setSelected(undefined)
                              changeElementVisible('pv_selected', false)
                              changeElementVisible('battery_selected', false)
                              setMouseKey('')
                            })

                            resetPosition()
                          }}
                          ref={topologyEmbed}
                          className={`topology-area ${!(edit || forceEdit) ? 'topology-area-top' : ''}`}
                          src="assets/topology.svg"
                        />
                      )}
                    </div>
                  </div>
                  {selectedElements.findIndex((o) => o.key === 'pv') >= 0 && (
                    <Drawer
                      getContainer={false}
                      mask={false}
                      maskClosable
                      closable={false}
                      destroyOnClose={true}
                      placement="right"
                      onClose={() => setSelected(undefined)}
                      visible={(edit || forceEdit) && selected === 'pv'}
                      className="no-drawer-boxshadow mask-transparent"
                      style={{ position: 'absolute', backgroundColor: 'transparent' }}
                      width={286}
                      bodyStyle={{
                        padding: 0,
                      }}
                    >
                      <PVConfig
                        loading={!loadedPvData}
                        province={proposalData.province}
                        district={proposalData.district}
                        info={proposalData.photovoltaicInfo}
                        geo={[proposalData.lng, proposalData.lat]}
                        onTriggerReload={(clearData: boolean) => {
                          if (clearData) {
                            setLoadedPvData(false)
                          }
                          loadData()
                        }}
                      />
                    </Drawer>
                  )}
                  {selectedElements.findIndex((o) => o.key === 'battery') >= 0 && (
                    <Drawer
                      getContainer={false}
                      mask={false}
                      maskClosable
                      closable={false}
                      destroyOnClose={true}
                      placement="right"
                      onClose={() => setSelected(undefined)}
                      visible={(edit || forceEdit) && selected === 'battery'}
                      className="no-drawer-boxshadow mask-transparent"
                      style={{ position: 'absolute', backgroundColor: 'transparent' }}
                      width={286}
                      bodyStyle={{
                        padding: 0,
                      }}
                    >
                      <BatteryConfig
                        loading={!loadedBatteryData}
                        info={proposalData.energyStorageInfo}
                        onTriggerReload={(clearData: boolean) => {
                          if (clearData) {
                            setLoadedBatteryData(false)
                          }
                          loadData()
                        }}
                      />
                    </Drawer>
                  )}
                  {(proposalData?.status === 2 || proposalData?.status === 4) && !(edit || forceEdit) && (
                    <Drawer
                      getContainer={false}
                      mask={false}
                      closable={false}
                      destroyOnClose={true}
                      placement="bottom"
                      visible={!(edit || forceEdit)}
                      className="no-drawer-boxshadow no-overflow-hidden"
                      style={{ position: 'absolute', backgroundColor: 'transparent', pointerEvents: 'none' }}
                      height="100%"
                      bodyStyle={{
                        padding: 0,
                        width: 'calc(100vw - 350px)',
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'flex-end',
                      }}
                    >
                      <TechResult
                        proposalData={proposalData}
                        currentSelected={getCurrentSelected()}
                        onChangeSeason={(val) => {
                          setSeason(val)
                        }}
                      />
                    </Drawer>
                  )}
                  {(proposalData?.status === 2 || proposalData?.status === 4) && (
                    <Drawer
                      getContainer={false}
                      mask={false}
                      closable={false}
                      destroyOnClose={true}
                      placement="right"
                      visible={!(edit || forceEdit)}
                      className="no-drawer-boxshadow"
                      style={{ position: 'absolute', backgroundColor: 'transparent' }}
                      width={286}
                      bodyStyle={{
                        padding: 0,
                      }}
                    >
                      <TechResultConfig
                        proposalData={proposalData}
                        currentSelected={getCurrentSelected()}
                        season={season}
                        showPhotovoltaicInfoRecord={showPhotovoltaicInfoRecord}
                        eleSelectorsState={eleSelectorsState}
                      />
                    </Drawer>
                  )}
                </Col>
              </Row>
            </div>
          )}
        </Spin>

        {(proposalData?.status === 2 || proposalData?.status === 4) && !(edit || forceEdit) && (
          <div
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              pointerEvents: 'none',
              opacity: !(edit || forceEdit) && mouseKey ? 1 : 0,
            }}
          >
            <div>
              <div
                id="custom-event-tooltip"
                className="ant-popover custom-position ant-popover-placement-bottom"
                style={mouseKey ? { left: positions[mouseKey].x, top: positions[mouseKey].y } : {}}
              >
                <div className="ant-popover-content">
                  <div className="ant-popover-arrow">
                    <span className="ant-popover-arrow-content"></span>
                  </div>
                  <div className="ant-popover-inner" role="tooltip">
                    <div className="ant-popover-inner-content">
                      {mouseKey === 'pv' && (
                        <div
                          className="info-body"
                          style={{
                            paddingLeft: 16,
                            paddingRight: 16,
                            width: 286,
                          }}
                        >
                          <div className="info-title">光伏</div>
                          <Row>
                            {[
                              { key: 'installedCapacity', unit: 'kW', label: '装机容量' },
                              { key: 'dayAvgPowerGeneration', unit: 'kWh', label: '日均发电' },
                              { key: 'useHourByYear', unit: 'h', label: '年利用小时数' },
                              { key: 'annualGenerationHours', unit: 'h', label: '年发电小时数' },
                              { key: 'selfAbsorptionRate', unit: '%', label: '自消纳比' },
                            ].map((oo) => (
                              <Col
                                span={12}
                                key={oo.key}
                                className="info-col"
                                style={{
                                  height: 50,
                                }}
                              >
                                <div className="info-content">
                                  <span>{getFixedNumber(proposalData.photovoltaicInfo?.[oo.key], 2)}</span>
                                  <span className="info-unit">{oo.unit}</span>
                                </div>
                                <div className="info-label">{oo.label}</div>
                              </Col>
                            ))}
                          </Row>
                        </div>
                      )}
                      {mouseKey === 'battery' && (
                        <div
                          className="info-body"
                          style={{
                            paddingLeft: 16,
                            paddingRight: 16,
                            width: 286,
                          }}
                        >
                          <div className="info-title">储能</div>
                          <Row>
                            {[
                              { key: 'batteryCapacity', unit: 'kWh', label: '电池实际容量' },
                              { key: 'pcsPower', unit: 'kW', label: 'PCS总功率' },
                              { key: 'dayAvgDischarge', unit: 'kWh', label: '日均放电' },
                              { key: 'dayAvgChargeDischarge', unit: '次', label: '日均循环' },
                            ].map((oo) => (
                              <Col
                                span={12}
                                key={oo.key}
                                className="info-col"
                                style={{
                                  height: 50,
                                }}
                              >
                                <div className="info-content">
                                  <span>{getFixedNumber(proposalData.energyStorageInfo[oo.key], 2)}</span>
                                  <span className="info-unit">{oo.unit}</span>
                                </div>
                                <div className="info-label">{oo.label}</div>
                              </Col>
                            ))}
                          </Row>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        <AddTeamworkerModal
          {...{
            visible: isVisible,
            onOk: () => {
              setIsVisible(false)
              message.success('操作成功！')
            },
            onCancel: () => setIsVisible(false),
            dataId: params.id,
            projectId: proposalData?.projectId,
          }}
        ></AddTeamworkerModal>
      </Layout.Content>
    </Layout>
  )
}
