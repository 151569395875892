import React, { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Card, Tabs, Tooltip, Typography, Button } from 'antd'
import { PlusOutlined, ExportOutlined } from '@ant-design/icons'
import { BreadcrumbObservable, SideMenuObservable } from '../../../application'
import { TablePage, TablePageInstance, getActionColumn, getColumnFilterProps } from '../../../components/wl-table-page'
import { MaterialService, CommonService } from '../../../apis/engineering-service'
import axios from 'axios'
import { token } from '../../../utils/utils'
import './index.less'

export default () => {
  const nav = useNavigate()
  const investmentRef = useRef<TablePageInstance>(null)
  const contractingRef = useRef<TablePageInstance>(null)
  const [activeTabKey, setActiveTabKey] = useState<string>('investment') // 当前显示的Tab


  // 下拉数据
  const [options, setOptions] = React.useState<{ [name: string]: any }>({
    projectListOptions: [], // 项目名称 下拉数据
  })

  // 获取基础下拉数据
  useEffect(() => {
    // 获取项目名称 下拉数据
    CommonService.getProjectDataSelector()
      .then((res: any) => {
        let list = []
        if (res?.data) {
          res?.data.map((item) => {
            list.push({
              id: item.projectNo,
              label: item.projectName
            })
          })
        }
        setOptions((previous) => ({ ...previous, projectListOptions: list }))
      })
      .catch(() => {})
      
  }, [])

  useEffect(() => {
    SideMenuObservable.next(false)
    BreadcrumbObservable.next([
      {
        name: '工程管理',
        url: '/engineering',
      },
      {
        name: '物资管理',
        url: '/engineering/material',
      }
    ])
  }, [])

  // 获取基础数据
  React.useEffect(() => {
  })

  // 投资方采集 / 工程总承包采集
  const columns = (tableParams: any) => [
    {
      title: '序号',
      dataIndex: 'num',
      width: 60,
      render: (text: string, o: any, index: number) => {
        return (
          <Typography.Text ellipsis>{index + 1}</Typography.Text>
        )
      }
    },
    {
      title: '单据号',
      dataIndex: 'orderNo',
      width: 100,
      render: (text: string, record: any) =>
        text && (
          <Tooltip title={text}>
            <Typography.Text ellipsis style={{color: '#1ec692', cursor: 'pointer'}} onClick={() => {nav(`/engineering/materialDetails/${record.goodsMaterialsManageId}`)}}>{text}</Typography.Text>
          </Tooltip>
        ),
    },
    {
      title: '日期',
      dataIndex: 'choiceTime',
      width: 162,
      sorter: true,
      render: (text: any) => {
        return text && (
          <Tooltip title={text}>
            <Typography.Text ellipsis>{text}</Typography.Text>
          </Tooltip>
        )
      }
    },
    {
      title: '结算单位',
      dataIndex: 'clearingHouse',
      width: 100,
      render: (text: string) =>
        text && (
          <Tooltip title={text}>
            <Typography.Text ellipsis>{text}</Typography.Text>
          </Tooltip>
        )
    },
    {
      title: '项目名称',
      dataIndex: 'projectName',
      width: 100,
      ...getColumnFilterProps({
        type: 'select',
        placeholder: '请输入项目名称',
        value: tableParams.projectName,
        options: options.projectListOptions,
        onSearch: (value) => {
          if (activeTabKey === 'investment') {
            investmentRef.current?.reload?.({
              type: 0,
              projectCode: value
            })
          } else {
            contractingRef.current?.reload?.({
              type: 1,
              projectCode: value
            })
          }
        },
      })
    },
    {
      title: '合同名称',
      dataIndex: 'contractName',
      width: 100,
      render: (text: string) =>
        text && (
          <Tooltip title={text}>
            <Typography.Text ellipsis>{text}</Typography.Text>
          </Tooltip>
        ),
    },
    {
      title: '需求总量',
      dataIndex: 'demandTotal',
      width: 100,
      render: (text: string) =>
        text && (
          <Tooltip title={text}>
            <Typography.Text ellipsis>{text}</Typography.Text>
          </Tooltip>
        ),
    },
    {
      title: '已采购总量',
      dataIndex: 'purchaseTotal',
      width: 120,
      render: (text: string) =>
        text && (
          <Tooltip title={text}>
            <Typography.Text ellipsis>{text}</Typography.Text>
          </Tooltip>
        ),
    },
    {
      title: '审核日期',
      dataIndex: 'dateOfApprovalTime',
      width: 100,
      render: (text: any) => {
        return text && (
          <Tooltip title={text}>
            <Typography.Text ellipsis>{text}</Typography.Text>
          </Tooltip>
        )
      }
    },
    {
      title: '业务机构',
      dataIndex: 'programOffices',
      width: 100,
      render: (text: string) =>
        text && (
          <Tooltip title={text}>
            <Typography.Text ellipsis>{text}</Typography.Text>
          </Tooltip>
        ),
    },
    {
      title: '业务部门',
      dataIndex: 'programDepartment',
      width: 100,
      render: (text: string) =>
        text && (
          <Tooltip title={text}>
            <Typography.Text ellipsis>{text}</Typography.Text>
          </Tooltip>
        ),
    },
    {
      title: '制单人',
      dataIndex: 'operatorName',
      width: 100,
      sorter: true,
      render: (text: string) =>
        text && (
          <Tooltip title={text}>
            <Typography.Text ellipsis>{text}</Typography.Text>
          </Tooltip>
        ),
    },
    getActionColumn([
      {
        key: 'detail',
        label: '查看合同',
        onClick: (row: any) => {
          nav(`/engineering/materialDetails/${row.goodsMaterialsManageId}`)
        },
      },
      {
        key: 'edit',
        label: '编辑',
        onClick: (row: any) => {
          console.log(row)
          nav(`/engineering/materialCreate/${row.type}/${row.goodsMaterialsManageId}`)
        },
      },
      {
        key: 'delete',
        type: 'delete',
        getter: (row: any) => {
          return MaterialService.remove({ id: row.goodsMaterialsManageId })
        },
        success: (arg: any) => {
          if (activeTabKey === 'investment') {
            investmentRef.current?.reload?.({
              ...arg,
              type: 0,
            })
          } else {
            contractingRef.current?.reload?.({
              ...arg,
              type: 1,
            })
          }
        },
      },
    ]),
  ]

  // 导出
  const downloadTemplate = (type: number) => {
    const prefix = axios.defaults.baseURL
    const url = `${prefix || ''}/api/project/energy_goods_materials_manage/Output/List/Excel?type=${type}`
    const authToken: string = token.get() || ''
    fetch(url, {
      method: 'GET',
      headers: {
        Authorization: authToken,
        'Content-Type': 'application/json;charset=UTF-8',
      },
    })
      .then((resp) => {
        
        let fileName = decodeURIComponent(resp.headers.get('content-disposition')?.split(';')[1].split('filename=')[1] || `template-${Date.now()}.xlsx`)
          console.log(fileName, '===resp')
        resp.blob().then((blob) => {
          if ((window.navigator as any).msSaveOrOpenBlob) {
            ;(window.navigator as any).msSaveBlob(blob, fileName)
          } else {
            const a = document.createElement('a')
            document.body.appendChild(a)
            const url = window.URL.createObjectURL(blob)
            a.href = url
            a.download = fileName
            a.target = '_blank'
            a.click()
            a.remove()
            window.URL.revokeObjectURL(url)
          }
        })
      })
      .finally(() => {})
  }

  return (
    <Card
      style={{ height: '100%' }}
      className="no-border-bottom-card"
      extra={null}
      bodyStyle={{ height: 'calc(100vh - 150px)', padding: 0 }}
    >
      <div className="screen-warp">
        <Button
          icon={<ExportOutlined />}
          style={{marginRight: 20}}
          onClick={() => {
            console.log('导出')
            downloadTemplate(activeTabKey === 'investment' ? 0 : 1)
          }}
        >
          导出
        </Button>
        <Button
          type="primary"
          icon={<PlusOutlined />}
          onClick={() => {
            console.log('新建物资')
            nav(`/engineering/materialCreate/${activeTabKey === 'investment' ? 0 : 1}/${'create'}`)
          }}
        >
          新建物资
        </Button>
      </div>
      <Tabs
          className="partner-tabs"
          activeKey={activeTabKey}
          tabBarStyle={{ padding: '0 24px' }}
          onChange={(key: string) => {
            setActiveTabKey(key)
          }}
        >
          <Tabs.TabPane tab="投资方集采" key="investment">
            <TablePage
              componentRef={investmentRef}
              columns={columns}
              isSorter={true}
              sorterChange={(sorter) => {
                console.log(sorter, '===sorter')
                const sortData = {
                  // 审核日期
                  dateSort: sorter.order
                    ? sorter.field === 'choiceTime'
                      ? sorter.order === 'ascend'
                        ? 1
                        : 2
                      : 0
                    : 0,
                    operatorSort: sorter.order
                    ? sorter.field === 'operatorName'
                      ? sorter.order === 'ascend'
                        ? 1
                        : 2
                      : 0
                    : 0,
                }
                investmentRef.current?.reload?.({
                  ...sortData,
                  type: 0
                })
              }}
              rowKey="goodsMaterialsManageId"
              getter={(arg: any) => MaterialService.list({ ...arg, type: 0 })}
            />
          </Tabs.TabPane>
          <Tabs.TabPane tab="工程总承包采集" key="contracting">
            <TablePage
              componentRef={contractingRef}
              columns={columns}
              isSorter={true}
              sorterChange={(sorter) => {
                console.log(sorter, '===sorter')
                const sortData = {
                  // 审核日期
                  dateSort: sorter.order
                    ? sorter.field === 'choiceTime'
                      ? sorter.order === 'ascend'
                        ? 1
                        : 2
                      : 0
                    : 0,
                    operatorSort: sorter.order
                    ? sorter.field === 'operatorName'
                      ? sorter.order === 'ascend'
                        ? 1
                        : 2
                      : 0
                    : 0,
                }
                contractingRef.current?.reload?.({
                  ...sortData,
                  type: 1,
                })
              }}
              rowKey="goodsMaterialsManageId"
              getter={(arg: any) => MaterialService.list({ ...arg, type: 1 })}
            />
          </Tabs.TabPane>
        </Tabs>
    </Card>
  )
}
