import React, { useMemo } from 'react'
import ReactECharts from 'echarts-for-react'
import moment from 'moment'
import { PowerStationDetail } from '../type'
import {ChartColorMaps} from "../../../../global";
import * as echarts from "echarts";

import '../index.less'

type IProps = {
  detail: PowerStationDetail
}

// const DefaultStations = [
//   {
//     name: '电站1',
//     color: '#1EC692',
//   },
//   {
//     name: '电站2',
//     color: '#3274F6',
//   },
//   {
//     name: '电站3',
//     color: '#F97942',
//   },
//   {
//     name: '电站4',
//     color: '#F6B932',
//   },
//   {
//     name: '电站5',
//     color: '#B532F6',
//   },
//   {
//     name: '电站6',
//     color: '#27C3E6',
//   },
// ]

const PowerStationCurve: React.FC<IProps> = React.memo((props) => {
  const { detail } = props
  const { dataUpdateTime, realTimePowerChart } = detail || {}
  const { xAxisData, yAxisData } = useMemo(() => {
    const xAxisData = []
    const yAxisData = []
    
    // const xData = [];
    const data = moment().startOf('day');
    for (let i = 0; i < 295; i++) {
      if (i > 288) {
        xAxisData.push('25:00:00');
      } else if (i === 288) {
        xAxisData.push('24:00:00');
      } else {
        xAxisData.push(data.format('HH:mm:ss'));
        data.add(5, 'minutes');
      }
    }
    // xAxisData.push(xData)
    const list = realTimePowerChart || []
    for (let i = 0; i < list.length; i++) {
      const { ydata } = list[i] || {}
      
      yAxisData.push(ydata)
    }
    if (!xAxisData.length) {
      xAxisData.push(0, 2, 4, 6, 8, 10, 12, 14, 16, 18, 20, 22, 24)
    }
    if (!yAxisData.length) {
      yAxisData.push(0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0)
    }
    return {
      xAxisData,
      yAxisData,
    }
  }, [realTimePowerChart])

  return (
    <div className="power-station-curve">
      <div className="power-station-curve-header">
        <h3 className="power-station-curve-title">实时功率曲线</h3>
        <p className="power-station-curve-extra">
          <span>更新时间：</span>
          <span className="power-station-curve-time">{moment(dataUpdateTime).format('YYYY-MM-DD HH:mm:ss')}</span>
        </p>
      </div>
      <div className="power-station-curve-content">
        {/* <div className="power-station-curve-stations">
          {DefaultStations.map(({ name, color }) => (
            <div key={color} className="power-station-curve-station">
              <span className="power-station-curve-station-color" style={{ backgroundColor: color }} />
              <span className="power-station-curve-station-name">{name}</span>
            </div>
          ))}
        </div> */}
        <ReactECharts
          style={{
            width: '100%',
            height: 196, // 141
          }}
          option={{
            animationDuration: 50,
            tooltip: {
              trigger: 'axis',
              formatter: (args: any) => {
                let res = `<strong>${args[0].axisValue}</strong>`
                if (args.length > 0) {
                  res += args
                    .map(
                      (o: any) =>
                        `<div style='width: 200px;'>${o.marker}${o.seriesName}<span style='float: right'>${o.value}kW</span></div>`
                    )
                    .join('')
                }
                return res
              },
            },
            grid: {left: '0', right: '3%', top: '30px', bottom: '0', containLabel: true},
            xAxis: {
              type: 'category',
              boundaryGap: true,
              data: xAxisData,
              axisTick: {
                show: false,
              },
              axisLabel: {
                showMaxLabel: true,
                interval: 11,
                formatter: (o) => {
                  if (parseInt(o.split(':')[0], 10) > 24) {
                    return '时';
                  }
                  return parseInt(o.split(':')[0], 10);
                }
              },
            },
            yAxis: {
              type: 'value',
              name: '单位：kW',
              nameTextStyle: {
                color: 'gray',
              },
              splitLine: {
                lineStyle: {
                  type: [5, 5],
                  dashOffset: 5,
                },
              },
            },
            // legend: {
            //   show: true,
            //   top: 'bottom',
            //   icon: 'rect',
            // },
            series: [{
              name: '实时功率',
              type: 'line',
              // sampling: 'lttb',
              smooth: true,
              showSymbol: false,
              itemStyle: {
                color: ChartColorMaps[0].line,
              },
              areaStyle: {
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  {
                    offset: 0,
                    color: ChartColorMaps[0].area[0],
                  },
                  {
                    offset: 1,
                    color: ChartColorMaps[0].area[1],
                  },
                ]),
              },
              lineStyle: {
                width: 2,
              },
              data: yAxisData,
            }],
          }}
        />
      </div>
    </div>
  )
})

export default PowerStationCurve
