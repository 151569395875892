import { Spin } from 'antd'
import React, { useEffect, useState } from 'react'
import { ProjectServiceHistory } from '../../../apis/project-service-history'
import Global from '../../../global'

import styles from '../index.module.scss'

const PlanCardList = () => {
  const [loading, setLoading] = useState(false)

  const transDict = [
    {
      name: '资源',
      count: 0,
      key: 'resourceNum',
      color: 'rgb(114, 138, 255)',
      icon: 'rect4.png',
      href: '#/resource/list',
    },
    {
      name: '项目',
      count: 0,
      key: 'projectNum',
      color: 'rgb(30,198,144)',
      icon: 'rect1.png',
      href: '#/program/manage',
    },
    {
      name: '技术方案',
      count: 0,
      key: 'technicalProposalNum',
      color: 'rgb(0,136,249)',
      icon: 'rect2.png',
      href: '#/program/technical',
    },
    {
      name: '财务方案',
      count: 0,
      key: 'financeSchemeNum',
      color: 'rgb(246,140,71)',
      icon: 'rect3.png',
      href: '#/program/financial',
    },
  ]

  const [count, setCount] = useState<any>({
    financeSchemeNum: 0,
    projectNum: 0,
    technicalProposalNum: 0,
  })

  useEffect(() => {
    setLoading(true)
    ProjectServiceHistory.assetCount()
      .then((res) => {
        setCount(res.data || {})
      })
      .catch(() => {})
      .finally(() => {
        setLoading(false)
      })
  }, [])

  const jumpTo = (url: any) => {
    if (url) {
      location.assign(`${url}`)
      Global.changeMenu.forEach((Fn: any) => {
        Fn(url)
      })
    }
  }

  return (
    <Spin spinning={loading}>
      <div className={styles.statisticsCard}>
        {transDict.map((dict) => {
          const item = Object.assign(dict, { count: count[dict.key] })
          return (
            <div
              key={item.key}
              className="card-item"
              onClick={() => {
                jumpTo(dict.href)
              }}
            >
              <div className="count-wrap">
                <div className="count" style={{ color: item.color }}>
                  {item.count > 9999 ? '---' : item.count}
                </div>
                <div className="name">{item.name}</div>
              </div>
              <div className="icon">
                <img src={`./assets/${item.icon}`} alt="" style={{ height: 80 }} />
              </div>
            </div>
          )
        })}
      </div>
    </Spin>
  )
}

export default PlanCardList
