import { Button, Form, Input, Select } from 'antd'
import React from 'react'
import { AddressSelector } from '../../../components/address-selector'
import { GaodeMap, Scene } from '@antv/l7'
import { FormInstance } from 'antd/lib/form'
import { getAMap } from '../../account-detail/components/map-script'
import { ProjectServiceHistory } from '../../../apis/project-service-history'
import _ from 'lodash'

import './index.less'
import { NAME_EXP } from '../../../utils/constant'

// @ts-ignore
import mapboxgl from '!mapbox-gl'

const mbx = true;
let lastMarker;

export const ProgramManageCreator = (props: {
  form: FormInstance
  res?: any
  resourceId?: string
  onClose: (reload?: any) => void
}) => {
  const [map, setMap] = React.useState<any>()
  const [uploading, setUploading] = React.useState(false)
  const [, setVer] = React.useState(0)

  const createAMap = () => {
    getAMap().then((AMap) => {
      const lnglat = [116.418261, 39.921984]
      const zoom = 3
      const _map = new AMap.Map('program-create-map', {
        resizeEnable: true,
        center: lnglat,
        zoom,
        mapStyle: 'amap://styles/05e321edfe9e69b5902e4dc372c36fda',
      })

      new Scene({
        id: 'program-create-map',
        map: new GaodeMap({
          mapInstance: _map,
        }),
      })

      setMap(_map)
    })
  }

  const createMapbox = () => {
    lastMarker = undefined;
    const map = new mapboxgl.Map({
      style: 'mapbox://styles/owops/cl2h1u3kx000u15o26t9bmtyh',
      center: [116.418261, 39.921984],
      zoom: 2,
      minZoom: 1,
      maxZoom: 20,
      container: 'program-create-map',
      interactive: false,
      projection: 'equirectangular' // starting projection
    } as any)

    setMap(map);
  }

  React.useEffect(() => {
    if (map && props.resourceId) {
      if(!mbx) {
        getAMap().then((AMap: any) => {
          const lngLat = new AMap.LngLat(props.res.lng, props.res.lat)
          map.setZoom(16)
          map.setCenter(lngLat)
          map.clearMap()

          const marker = new AMap.Marker({
            position: lngLat,
            title: props.res.address,
          })
          map.add(marker)
        })
      } else {
        map.setZoom(13)
        map.setCenter([props.res.lng, +props.res.lat + 0.003]);
        const popup = new mapboxgl.Popup({
          offset: [0, -40],
          closeButton: false
        });
        new mapboxgl.Marker({offset: [0, -15]})
          .setLngLat([props.res.lng, props.res.lat])
          .addTo(map);
        const txt = props.res.address;

        popup.setLngLat([props.res.lng, props.res.lat])
          .setText(txt)
          .addTo(map);
      }
    }
  }, [map, props.resourceId])

  React.useEffect(() => {
    if (props.res) {
      props.form.setFieldsValue({ ...props.res })
      setVer((ver) => ver + 1)
    }
  }, [props.res])

  const onFinish = (values: any) => {
    console.debug('Success:', values)
  }

  const onFinishFailed = (errorInfo: any) => {
    console.debug('Failed:', errorInfo)
  }

  React.useEffect(() => {
    if(!mbx) {
      createAMap();
    } else {
      createMapbox();
    }
  }, [])

  const [addressList, setAddressList] = React.useState<any[]>([])

  const onFocus = () => {}

  const onSearch = (val: string) => {
    ProjectServiceHistory.inputTips({ cityCode: props.form.getFieldValue('city') || '', address: val }).then((resp: any) => {
      setAddressList(resp.data)
    })
  }

  return (
    <div>
      <div>
        <Form
          size="large"
          name="basic"
          labelCol={{ span: 5 }}
          wrapperCol={{ span: 18, offset: 1 }}
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          form={props.form}
        >
          <Form.Item
            label="项目名称"
            name="name"
            rules={[
              { required: true, message: '请输入项目名称' },
              {
                pattern: NAME_EXP,
                message: '1-16个字符,中文、英文、_、数字和-组成',
              },
            ]}
          >
            <Input size="middle" />
          </Form.Item>

          <Form.Item label="建设期长度" name="buildMonth" rules={[{ required: false, message: '请输入建设期长度' }]}>
            <Input size="middle" disabled suffix="月" defaultValue="12" />
          </Form.Item>

          <Form.Item label="运营期长度" name="maintainMonth" rules={[{ required: false, message: '请输入运营期长度' }]}>
            <Input size="middle" disabled suffix="月" defaultValue="300" />
          </Form.Item>
          {props.resourceId && (
            <Form.Item label="详细地址" rules={[{ required: true, message: '请输入详细地址' }]}>
              <span>{props.res.address}</span>
            </Form.Item>
          )}
          {!props.resourceId && (
            <Form.Item label="详细地址" name="addressObject" rules={[{ required: true, message: '请输入详细地址' }]}>
              <Select
                className="gaode-input small-empty"
                size="middle"
                showSearch
                onFocus={onFocus}
                onChange={(val: string) => {
                  const obj = JSON.parse(val)
                  const arr = obj.location.split(',').map(parseFloat)

                  obj?.adcode && props.form.setFieldsValue({ city: obj.adcode })

                  if(!mbx) {
                    getAMap().then((AMap) => {
                      const lngLat = new AMap.LngLat(arr[0], arr[1])
                      map.setZoom(16)
                      map.setCenter(lngLat)
                      map.clearMap()

                      const marker = new AMap.Marker({
                        position: lngLat,
                        title: obj.name,
                      })

                      map.add(marker)
                    })
                  } else {
                    const popup = new mapboxgl.Popup({
                      offset: [0, -40],
                      closeButton: false
                    });
                    if(lastMarker) {
                      lastMarker.remove();
                    }
                    lastMarker = new mapboxgl.Marker({offset: [0, -15]})
                      .setLngLat([arr[0], arr[1]])
                      .addTo(map);
                    const txt = obj.name;

                    popup.setLngLat([arr[0], arr[1]])
                      .setText(txt)
                      .addTo(map);

                    map.setZoom(15);
                    map.setCenter([arr[0], arr[1]+0.0006]);

                  }
                }}
                onSearch={_.debounce(onSearch, 300)}
                filterOption={() => true}
              >
                {addressList?.map((o: any, idx) => (
                  <Select.Option key={`${o.address}--${idx}`} value={JSON.stringify(o)}>
                    {o.name}
                    <span className="hide-in-input" style={{ fontSize: 10, color: 'lightgrey', marginLeft: '1rem' }}>
                      {o.district}
                    </span>
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          )}
          <Form.Item label="地理位置" name="city">
            <AddressSelector />
          </Form.Item>
        </Form>

        <div id="program-create-map" style={{ width: '100%', height: 188, position: 'relative' }}></div>

        <div className="ant-modal-confirm-btns">
          <Button
            onClick={() => {
              props.onClose()
            }}
          >
            取 消
          </Button>
          <Button
            type="primary"
            loading={uploading}
            onClick={() => {
              props.form.validateFields().then(() => {
                setUploading(true)
                const { name, city, addressObject } = props.form.getFieldsValue()
                const addressObj = addressObject && JSON.parse(addressObject)
                const address = props.resourceId ? props.res.address : addressObj.name
                const location = (addressObj && addressObj.location) || ''
                const [longitude, latitude] = location.split(',')
                // console.log(props.res)
                ProjectServiceHistory.add({
                  root: {
                    name,
                    buildMonth: '12',
                    maintainMonth: '300',
                    cityCode: props.resourceId ? props.res.city : city,
                    address,
                    longitude: props.resourceId ? props.res.lng : longitude,
                    latitude: props.resourceId ? props.res.lat : latitude,
                    resourceId: props.resourceId,
                  },
                })
                  .then((resp) => {
                    props.onClose(resp)
                  })
                  .catch(() => {})
                  .finally(() => {
                    setUploading(false)
                  })
              })
            }}
          >
            确 定
          </Button>
        </div>
      </div>
    </div>
  )
}
