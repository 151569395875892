import React from 'react'
import { Button, Col, Form, Input, Row, Typography } from 'antd'
import UploadPdf from '../../../../components/wl-upload'
import PurchaseContractTable from './purchase-contract-table'
import { FileService } from '../../../../apis/wl-service'

export default ({ layout, setFieldsValue }) => {
  const [dataSource, setDataSource] = React.useState([])

  return (
    <>
      <Row align="middle" style={{ marginBottom: 16 }}>
        <Col {...layout.span24.labelCol}>
          <Typography.Title level={5} style={{ marginBottom: 0 }}>
            <Typography.Text type="danger">*</Typography.Text> 采购合同
          </Typography.Title>
        </Col>
        <Col {...layout.span24.wrapperCol}>
          <Form.Item
            noStyle
            name="stock"
            label={''}
            {...layout.span8}
            rules={[
              {
                validator: (rule: any, value: any[]) => {
                  if (value?.length > 0) {
                    return Promise.resolve()
                  }

                  return Promise.reject('请选择采购合同')
                },
              },
            ]}
          >
            <UploadPdf
              fileType="pdf"
              // maxCount={1}
              extraWrap={false}
              accept=".xls"
              action={FileService.path('/api/project/Energy_contract/stock/upload')}
              onResult={(value: any[]) => {
                const tableData = value.reduce((acc, value) => {
                  acc = acc.concat(value.list)
                  return acc
                }, [])
                setDataSource(tableData)
                setFieldsValue({ energyContractStockEntityList: JSON.stringify(tableData) })
              }}
            >
              <Button type="primary">上传Excel</Button>
            </UploadPdf>
          </Form.Item>
        </Col>
      </Row>

      <Form.Item name="energyContractStockEntityList" hidden>
        <Input.TextArea />
      </Form.Item>

      <PurchaseContractTable dataSource={dataSource} />
    </>
  )
}
