import { Button, Col, Form, InputNumber, Row, Spin } from 'antd'
import React, { useEffect } from 'react'
import { ProjectWorkbenchService } from '../../../../apis/project-service-history'

import '../index.less'

const keyMaps = {
  DC: [{ name: '电池损耗率', key: 'batteryLossRate' }],
  AC: [{ name: '交流侧损耗率', key: 'acLossRate' }],
  TT: [{ name: '校正损耗率', key: 'otherLossRate' }],
}

const toMax4 = (num: number) => {
  return parseFloat(num.toFixed(2))
}

export const BatterySystemEfficiency = (props: {
  technologyId?: string
  viewMode?: boolean
  onClose: (reload?: boolean) => void
  current?: any
  onChange?: Function
}) => {
  const [form] = Form.useForm()
  const [current, setCurrent] = React.useState<any>()
  const [loading, setLoading] = React.useState(true)
  const [uploading, setUploading] = React.useState(false)

  const { viewMode } = props

  const calcTotalRate = () => {
    return toMax4(((100 - calcDcLossRate()) * (100 - calcAcLossRate()) * (100 - calcSystemEfficiency())) / 10000)
  }

  const calcDcLossRate = () => {
    let res = 1
    keyMaps.DC.forEach((o) => {
      const key = o.key
      const val = form.getFieldValue(key)
      res *= 1 - val / 100
    })
    return toMax4((1 - res) * 100)
  }

  const calcAcLossRate = () => {
    let res = 1
    keyMaps.AC.forEach((o) => {
      const key = o.key
      const val = form.getFieldValue(key)
      res *= 1 - val / 100
    })

    return toMax4((1 - res) * 100)
  }
  const calcSystemEfficiency = () => {
    let res = 1
    keyMaps.TT.forEach((o) => {
      const key = o.key
      const val = form.getFieldValue(key)
      res *= 1 - val / 100
    })

    return toMax4((1 - res) * 100)
  }

  useEffect(() => {
    if (props.current) {
      setLoading(false)
      setCurrent(props.current)

      const { batteryLossRate, acLossRate, otherLossRate } = props.current

      form.setFieldsValue({
        batteryLossRate,
        acLossRate,
        otherLossRate,
      })
    }
  }, [props.current])

  React.useEffect(() => {
    if (props.technologyId) {
      ProjectWorkbenchService.getEnergyStorageSystemEfficiency({
        technologyId: props.technologyId,
      })
        .then((resp) => {
          setCurrent(resp.data)

          const { batteryLossRate, acLossRate, otherLossRate } = resp.data

          form.setFieldsValue({
            batteryLossRate,
            acLossRate,
            otherLossRate,
          })
        })
        .finally(() => {
          setLoading(false)
        })
    }
  }, [])

  return (
    <Spin spinning={loading}>
      <div
        style={{
          minHeight: '3rem',
        }}
        className={viewMode ? 'only-view-mode' : ''}
      >
        {current && (
          <Form
            className="system-form"
            labelCol={{ span: 6 }}
            wrapperCol={{ span: 18 }}
            form={form}
            onFieldsChange={() => {
              setCurrent({ ...current })
            }}
          >
            <Row>
              <Col span={24}>
                <h3>
                  DC效率损耗率<span style={{ float: 'right' }}>{calcDcLossRate()}%</span>
                </h3>
              </Col>
              {keyMaps.DC.map((o) => (
                <Col span={24} key={o.key}>
                  <Form.Item label={o.name}>
                    {viewMode && <span>{form.getFieldValue(o.key)}%</span>}
                    {!viewMode && (
                      <Form.Item name={o.key}>
                        <InputNumber
                          style={{
                            width: '100%',
                          }}
                          min={0}
                          max={100}
                          precision={2}
                          addonAfter="%"
                          disabled
                          controls={false}
                        />
                      </Form.Item>
                    )}
                  </Form.Item>
                </Col>
              ))}
            </Row>
            <Row>
              <Col span={24}>
                <h3>
                  AC效率损耗率<span style={{ float: 'right' }}>{calcAcLossRate()}%</span>
                </h3>
              </Col>
              {keyMaps.AC.map((o) => (
                <Col span={24} key={o.key}>
                  <Form.Item label={o.name}>
                    {viewMode && <span>{form.getFieldValue(o.key)}%</span>}
                    {!viewMode && (
                      <Form.Item name={o.key}>
                        <InputNumber
                          style={{
                            width: '100%',
                          }}
                          min={0}
                          max={100}
                          addonAfter="%"
                          precision={2}
                          controls={false}
                        />
                      </Form.Item>
                    )}
                  </Form.Item>
                </Col>
              ))}
            </Row>
            <Row>
              <Col span={24}>
                <h3>
                  校正损耗率<span style={{ float: 'right' }}>{calcSystemEfficiency()}%</span>
                </h3>
              </Col>
              {keyMaps.TT.map((o) => (
                <Col span={24} key={o.key}>
                  <Form.Item label={o.name}>
                    {viewMode && <span>{form.getFieldValue(o.key)}%</span>}
                    {!viewMode && (
                      <Form.Item name={o.key}>
                        <InputNumber
                          style={{
                            width: '100%',
                          }}
                          min={0}
                          max={100}
                          precision={2}
                          addonAfter="%"
                          controls={false}
                        />
                      </Form.Item>
                    )}
                  </Form.Item>
                </Col>
              ))}
            </Row>
          </Form>
        )}
        <div className="total-rate">
          <h3>
            储能系统总效率<span style={{ float: 'right' }}>{calcTotalRate()}%</span>
          </h3>
        </div>
      </div>
      <div className="ant-modal-confirm-btns">
        {!viewMode && (
          <Button
            onClick={() => {
              props.onClose?.()
            }}
          >
            取 消
          </Button>
        )}
        <Button
          type="primary"
          loading={uploading}
          onClick={() => {
            if (viewMode) {
              props.onClose?.()
              return
            }
            setUploading(true)
            const { batteryLossRate, acLossRate, otherLossRate } = form.getFieldsValue()
            if (props.onChange) {
              props.onChange({
                batteryLossRate,
                acLossRate,
                otherLossRate,
                energyStorageEfficiency: calcTotalRate(),
              })
            } else {
              ProjectWorkbenchService.updateEnergyStorageSystemEfficiency({
                technologyId: props.technologyId!,
                root: {
                  batteryLossRate,
                  acLossRate,
                  otherLossRate,
                },
              }).then(() => {
                props.onClose?.(true)
              })
            }
          }}
        >
          确 定
        </Button>
      </div>
    </Spin>
  )
}
