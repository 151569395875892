import React, { useMemo, useRef, useState } from 'react'
import { Link, useSearchParams } from 'react-router-dom'
import { Button, Tooltip, Typography } from 'antd'
import { PlusOutlined } from '@ant-design/icons'

import PolicyManageCreator from './create'
import { PolicyService } from '../../../apis/wl-service'
import TablePage, {
  getActionColumn,
  getColumnFilterProps,
  ITablePage,
  TablePageInstance,
} from '../../../components/wl-table-page'
import { policyTypeOptions } from '../constants'

export default () => {
  const [searchParams] = useSearchParams()
  const tableRef = useRef<TablePageInstance>(null)
  const [breadcrumb, setBreadcrumb] = useState<ITablePage['breadcrumb']>([])
  const [formData, setFormData] = useState<{ visible?: boolean; [name: string]: any }>({}) // 编辑,发布弹窗数据

  // 切换菜单导航
  React.useEffect(() => {
    const type = searchParams.get('type')
    const current: any = policyTypeOptions.find((v: any) => v.id === type) ?? {}
    setBreadcrumb([
      {
        name: '政策法规',
        url: '/policy/manage',
      },
      {
        name: current?.name,
        url: `policy/manage?type=${type}`,
      },
    ])
    tableRef.current?.reload?.({
      policy_type_id: searchParams.get('type'),
      page: 1,
    })
  }, [searchParams.get('type')])

  // 表格列配置
  const columns = (tableParams: any) => {
    const result = [
      {
        title: '标题',
        dataIndex: 'title',
        width: 140,
        // ellipsis: true,
        fixed: 'left' as any,
        ...getColumnFilterProps({
          type: 'input',
          placeholder: '请输入标题',
          value: tableParams.title,
          onSearch: (value) => {
            tableRef.current?.reload?.({
              title: value,
              page: 1,
            })
          },
        }),
        render: (text: string, row: any) =>
          text && (
            <Tooltip title={text}>
              <Typography.Text ellipsis>
                <Link to={`/policy/detail/${row.id}/${row.policy_type_id ?? null}`}>{text}</Link>
              </Typography.Text>
            </Tooltip>
          ),
      },
      {
        title: '政策类型',
        dataIndex: 'policy_type_id',
        width: 100,
        ...getColumnFilterProps({
          placeholder: '请选择政策类型',
          type: 'select',
          options: policyTypeOptions.map((item: any) => ({ key: item.id, label: item.name })),
          value: tableParams.policy_type_id,
          onSearch: (value) => {
            tableRef.current?.reload?.({
              policy_type_id: value ?? '',
              page: 1,
            })
          },
        }),
        render: (text: number) => {
          return policyTypeOptions.find((item: any) => item.id === `${text}`)?.name
        },
      },
      {
        title: '概要',
        dataIndex: 'summary',
        width: 100,
      },
      {
        title: '发布人',
        dataIndex: 'author_name',
        width: 100,
      },
      {
        title: '发布时间',
        dataIndex: 'publish_time',
        width: 120,
      },
      getActionColumn([
        {
          key: 'edit',
          label: '编辑',
          onClick: (row: any) => {
            onCreate(row.id)
          },
        },
        {
          key: 'delete',
          type: 'delete',
          getter: (row: any) => {
            return PolicyService.remove({ id: row.id })
          },
          success: (arg: any) => tableRef.current?.reload?.(arg),
        },
      ]),
    ]

    // 政策法规为地方法规时表格显示法规属地字段
    if (searchParams.get('type') === '6') {
      result.splice(-4, 0, {
        title: '法规属地',
        // dataIndex: 'policy_dependency',
        dataIndex: 'policyDependency',
        width: 100,
        ...getColumnFilterProps({
          placeholder: '请选择法规属地',
          type: 'address_selector',
          value: tableParams.policy_dependency?.length ? tableParams.policy_dependency.split(',') : [],
          onSearch: (value) => {
            tableRef.current?.reload?.({
              policy_dependency: Array.isArray(value) ? (value as any).join(',') : undefined,
              page: 1,
            })
          },
        }),
        render: (text: string) => (
          <Tooltip title={text}>
            <Typography.Text ellipsis>{text}</Typography.Text>
          </Tooltip>
        ),
      } as any)
    }

    return result
  }

  // 页面标题
  const pageTitle = useMemo(() => {
    return policyTypeOptions.find((item: any) => `${item.id}` === searchParams.get('type'))?.name
  }, [searchParams.get('type')])

  // 点击发布，编辑
  const onCreate = async (id?: string) => {
    let response: any = {}
    if (id) {
      try {
        // 获取政策详情
        response = await PolicyService.info({ id })
      } catch (err: any) {
        console.warn(err?.message)
      }
    }
    setFormData({ visible: true, ...(response?.data ?? {}) })
  }

  return (
    <TablePage
      ref={tableRef}
      title={pageTitle}
      extra={
        <Button type="primary" icon={<PlusOutlined />} onClick={() => onCreate()}>
          发布
        </Button>
      }
      breadcrumb={breadcrumb}
      columns={columns}
      rowKey="id"
      autoLoad={false}
      getter={(arg: any) => PolicyService.list(arg)}
    >
      <PolicyManageCreator
        visible={formData.visible ?? false}
        value={formData}
        onCancel={() => {
          setFormData({ visible: false })
        }}
        onSuccess={() => {
          setFormData({ visible: false })
          tableRef.current?.reload?.()
        }}
      />
    </TablePage>
  )
}
