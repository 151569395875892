import React, { useImperativeHandle } from 'react'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import moment from 'moment'
import { isEmpty } from 'lodash'
import {
  Button,
  Card,
  Checkbox,
  Col,
  DatePicker,
  Form,
  FormInstance,
  Input,
  InputNumber,
  message,
  Row,
  Select,
  Space,
  Spin,
  Switch,
  Transfer,
  Typography,
} from 'antd'

import UploadPdf from '../../../components/wl-upload'
import PurchaseContract from './components/purchase-contract'
import { BreadcrumbObservable, SideMenuObservable } from '../../../application'
import { ContractService, FileService } from '../../../apis/wl-service'
import Poll from './poll'

interface RecordType {
  key: string
  title: string
}

export const TransferComponent: React.FC<any> = ({ dataSource, value, onChange }) => {
  const [mockData, setMockData] = React.useState<RecordType[]>([])
  const [targetKeys, setTargetKeys] = React.useState<string[]>([])

  React.useEffect(() => {
    setMockData(
      dataSource?.map((item: any) => {
        return {
          key: item.id,
          title: item.fullName,
        }
      })
    )
    setTargetKeys([])
  }, [dataSource])

  React.useEffect(() => {
    value && setTargetKeys([value])
  }, [value])

  const filterOption = React.useCallback((inputValue: string, option: RecordType) => {
    return option.title.indexOf(inputValue) > -1
  }, [])

  const handleChange = React.useCallback(
    (newTargetKeys: string[]) => {
      if (newTargetKeys.length > 1) {
        message.warn(`最多能选择一个企业进行签约，请重新选择`)
        return
      }
      setTargetKeys(newTargetKeys)
      onChange?.(newTargetKeys[0])
    },
    [setTargetKeys]
  )

  // const handleSearch = (dir: TransferDirection, value: string) => {
  //   console.log('search:', dir, value)
  // }

  return (
    <Transfer
      dataSource={mockData}
      targetKeys={targetKeys}
      listStyle={{
        width: 250,
        height: 300,
      }}
      showSelectAll={false}
      // operations={['加入右侧', '加入左侧']}
      render={(item) => item.title}
      showSearch
      filterOption={filterOption}
      onChange={handleChange}
      // onSearch={handleSearch}
    />
  )
}

export interface ContractFormInstance extends FormInstance {
  reset: () => void // 重置表单数据
  submit: (cb?: () => void) => void // 提交表单
}

export interface ContractFormProps {
  componentRef: any
  type?: any
  source: number // 来源
}

const ContractForm: React.FC<ContractFormProps> = ({ source, type, componentRef }) => {
  const [form] = Form.useForm()
  const [loading, setLoading] = React.useState<boolean>(false) // 数据源加载状态
  const [submiting, setSubmiting] = React.useState<boolean>(false) // 表单提交状态
  const [basicData, setBasicData] = React.useState<any>({}) // 数据源

  useImperativeHandle(componentRef, () => ({
    ...form,
    reset: () => form.resetFields(),
    submit: async (cb?: () => void) => {
      if (submiting) return
      try {
        let values = await form.validateFields()
        values = formater(values, 'params')

        try {
          setSubmiting(true)
          const resp: any = await ContractService.update({ id: '', root: values })
          if (resp?.code === 200) {
            message.success(resp.msg ?? '新建合同成功')
            cb?.()
          } else if (resp?.msg) {
            message.error(resp.msg)
          }
        } catch (err: any) {
          console.warn(err)
        } finally {
          setSubmiting(false)
        }
      } catch (e) {
        console.warn(e)
      }
    },
  }))

  const p = React.useMemo(() => {
    const loadData = () => {
      return ContractService.basicData({ code: source }).then((resp: any) => {
        const data = resp?.data ?? {}
        // 项目名称键值对
        Object.assign(data, {
          projectMap: data?.projectData?.reduce((occ: any, cur: any) => {
            occ[cur.id] = cur
            return occ
          }, {}),
        })
        // 合同模板，子模版
        const loop = (arr: any[]): any => {
          return arr.reduce((occ: any, cur: any) => {
            if (Array.isArray(cur.sdataVos) && cur.sdataVos?.length > 0) {
              Object.assign(occ, loop(cur.sdataVos))
            }
            occ[cur.id] = cur
            return occ
          }, {})
        }
        Object.assign(data, {
          contractMap: loop(data?.contract?.dataVos),
        })

        // 甲方、乙方
        const partyBMap = {}
        Object.assign(data, {
          partyAMap: data?.partyA?.reduce((occ: any, cur: any) => {
            occ[cur.id] = cur.party ?? []
            cur.party?.forEach((curB: any) => {
              partyBMap[curB.id] = curB
            })
            return occ
          }, {}),
          partyBMap,
        })
        setBasicData(data)
      })
    }
    return new Poll(loadData)
  }, [source])

  // 获取基础数据
  React.useEffect(() => {
    if ((basicData?.projectData?.length > 0 && basicData?.partyA?.length > 0) || p.counter >= 5) {
      if (!basicData?.projectData?.length || !basicData?.partyA?.length) {
        message.error('系统繁忙，请稍后再试~')
      }
      setLoading(false)
      p.stop()
    } else {
      setLoading(true)
      p.start()
    }
  }, [basicData?.projectData, basicData?.partyA])

  const getTemplateValue = React.useCallback(
    (v: any) => (v ? basicData?.contractMap?.[v]?.sdataVos?.[0]?.id ?? '' : ''),
    [basicData?.contractMap]
  )

  // 表单赋值
  React.useEffect(() => {
    if (!basicData?.contract) return

    const initialValues = {}
    // 工作台/合作伙伴签约入口
    if (type === 'partner') {
      const contract = basicData?.contract?.dataVos?.find((v: any) => v.fullName.indexOf('伙伴') > -1) ?? {}
      contract?.id &&
        Object.assign(initialValues, {
          contractType: contract.id,
          contractTemplate: getTemplateValue(contract.id),
        })
    }
    // 工作台/客户签约入口
    if (type === 'customer') {
      const contract = basicData?.contract?.dataVos?.find((v: any) => v.fullName.indexOf('客户') > -1) ?? {}
      const contractTemplate = getTemplateValue(contract.id)
      contract?.id &&
        Object.assign(initialValues, {
          contractType: contract.id,
          contractTemplate,
          contractTemplateSon: getTemplateValue(contractTemplate),
        })
    }
    if (!isEmpty(initialValues)) {
      form.setFieldsValue(formater(initialValues, 'component'))
    }
  }, [type, basicData?.contract])

  // 数据转换
  const formater = React.useCallback(
    (values: any, type: 'params' | 'component') => {
      if (type === 'params') {
        // 是否需要发起电子合同签章流程 0需要 1不需要
        values.isInitiate = Number(values.isInitiate)
        // 是否需要签字 0需要 1不需要
        values.isSign = Number(!values.isSign)

        values.contractDate = values.contractDate ? values.contractDate.format('YYYY-MM-DD HH:mm:ss') : ''
        values.partyBName = basicData?.partyBMap?.[values.partyB]?.fullName ?? ''

        if ('energyContractStockEntityList' in values) {
          values.energyContractStockEntityList = values.energyContractStockEntityList
            ? JSON.parse(values.energyContractStockEntityList)
            : []
        }
      }

      if (type === 'component') {
        values.isInitiate = values.isInitiate == 1
        values.isSign = values.isSign == 0
        values.contractDate = values.contractDate ? moment(values.contractDate) : null
      }

      // 转换文件
      ;['contractFile', 'stock'].forEach((k: string) => {
        if (type === 'component') {
          values[k] = values[k] ? JSON.parse(values[k]) : []
        }
        if (type === 'params') {
          values[k] = values[k]?.length ? JSON.stringify(values[k]) : ''
        }
      })

      return values
    },
    [basicData]
  )

  // 布局属性
  const layout = React.useMemo(
    () => ({
      col: { xs: 12, sm: 8 },
      col24: { xs: 24 },
      span8: { labelCol: { xs: 24, sm: 9 }, wrapperCol: { xs: 24, sm: 14 } },
      span24: { labelCol: { xs: 24, sm: 3 }, wrapperCol: { xs: 24, sm: 21 } },
      span24NoLabel: { wrapperCol: { offset: 3, xs: 24, sm: 21 } },
      select: {
        allowClear: true,
        dropdownMatchSelectWidth: true,
        placeholder: '请选择',
        showSearch: true,
        optionFilterProp: 'children',
        filterOption: (input: string, option: any) => {
          return (option!.children as unknown as string).toLowerCase().includes(input.toLowerCase())
        },
      },
    }),
    []
  )

  return (
    <Spin spinning={loading}>
    <Form form={form} name="contract-form" className="wl-form-item" layout="horizontal" labelWrap>
      <Typography.Title level={5}>合同信息</Typography.Title>
      <Form.Item name="stem" initialValue={source} hidden>
        <Input />
      </Form.Item>
      <Row gutter={24}>
        <Col {...layout.col}>
          <Form.Item
            name="projectId"
            label="项目名称"
            {...layout.span8}
            rules={[{ required: true, message: '请填写项目名称' }]}
          >
            <Select
              {...layout.select}
              onChange={(value: any, option: any) => {
                form.setFieldsValue({
                  projectName: option?.children ?? '',
                  projectType: basicData?.projectMap?.[value]?.typeId ?? '',
                })
              }}
            >
              {basicData?.projectData?.map((o: any) => {
                return <Select.Option key={o.id}>{o.fullName}</Select.Option>
              })}
            </Select>
          </Form.Item>
          <Form.Item name="projectName" hidden>
            <Input />
          </Form.Item>
        </Col>
        <Col {...layout.col}>
          <Form.Item
            noStyle
            shouldUpdate={(prevValues, currentValues) => prevValues.projectId !== currentValues.projectId}
          >
            {({ getFieldValue }: FormInstance) => {
              return (
                <Form.Item
                  name="projectType"
                  label="项目类型"
                  {...layout.span8}
                  // rules={[{ required: true, message: '请填写项目类型' }]}
                >
                  <Select {...layout.select} disabled>
                    {((getFieldValue('projectId') && [basicData?.projectMap?.[getFieldValue('projectId')]]) ?? [])?.map(
                      (o: any) => {
                        return <Select.Option key={o.typeId}>{o.typeName}</Select.Option>
                      }
                    )}
                  </Select>
                </Form.Item>
              )
            }}
          </Form.Item>
        </Col>
        <Col {...layout.col}>
          {/* 甲方所属行业 */}
          <Form.Item
            name="industry"
            label="所属行业"
            {...layout.span8}
            rules={[{ required: true, message: '请填写所属行业' }]}
          >
            <Select {...layout.select}>
              {basicData?.industry?.map((o: string) => {
                return <Select.Option key={o}>{o}</Select.Option>
              })}
            </Select>
          </Form.Item>
        </Col>
        <Col {...layout.col}>
          <Form.Item
            name="contractType"
            label="合同类型"
            {...layout.span8}
            rules={[{ required: true, message: '请填写合同类型' }]}
          >
            <Select
              {...layout.select}
              onChange={(value: any) => {
                const contractTemplate = getTemplateValue(value)
                form.setFieldsValue({
                  contractTemplate,
                  contractTemplateSon: getTemplateValue(contractTemplate),
                })
              }}
            >
              {basicData?.contract?.dataVos?.map((o: any) => {
                return <Select.Option key={o.id}>{o.fullName}</Select.Option>
              })}
            </Select>
          </Form.Item>
        </Col>
        <Form.Item
          noStyle
          shouldUpdate={(prevValues, currentValues) => prevValues.contractType !== currentValues.contractType}
        >
          {({ getFieldValue }: FormInstance) => {
            const contractType = getFieldValue('contractType')
            const options = (contractType && basicData?.contractMap?.[contractType]?.sdataVos) ?? []
            return (
              <Col {...layout.col}>
                <Form.Item
                  name="contractTemplate"
                  label="合同模板"
                  {...layout.span8}
                  rules={[{ required: true, message: '请填写合同模板' }]}
                >
                  <Select
                    {...layout.select}
                    onChange={(value: any) => {
                      form.setFieldsValue({
                        contractTemplateSon: getTemplateValue(value),
                      })
                    }}
                  >
                    {options.map((o: any) => {
                      return <Select.Option key={o.id}>{o.fullName}</Select.Option>
                    })}
                  </Select>
                </Form.Item>
              </Col>
            )
          }}
        </Form.Item>
        <Col {...layout.col}>
          <Form.Item
            noStyle
            shouldUpdate={(prevValues, currentValues) => prevValues.contractTemplate !== currentValues.contractTemplate}
          >
            {({ getFieldValue }: FormInstance) => {
              const contractTemplate = getFieldValue('contractTemplate')
              const options = (contractTemplate && basicData?.contractMap?.[contractTemplate]?.sdataVos) ?? []
              return options?.length > 0 ? (
                <Form.Item
                  name="contractTemplateSon"
                  label="合同子模板"
                  {...layout.span8}
                  rules={[{ required: true, message: '请填写合同子模板' }]}
                >
                  <Select {...layout.select}>
                    {options.map((o: any) => {
                      return <Select.Option key={o.id}>{o.fullName}</Select.Option>
                    })}
                  </Select>
                </Form.Item>
              ) : null
            }}
          </Form.Item>
        </Col>
        {/*<Col {...layout.col}>*/}
        {/*  <Form.Item*/}
        {/*    name="contractNumber"*/}
        {/*    label="合同编号"*/}
        {/*    {...layout.span8}*/}
        {/*    // rules={[{ required: true, message: '请填写合同编号' }]}*/}
        {/*  >*/}
        {/*    <Input disabled allowClear placeholder="" />*/}
        {/*  </Form.Item>*/}
        {/*</Col>*/}
        <Col {...layout.col}>
          <Form.Item
            name="contractName"
            label="合同名称"
            {...layout.span8}
            rules={[{ required: true, message: '请填写合同名称' }]}
          >
            <Input allowClear placeholder="请输入" />
          </Form.Item>
        </Col>
        <Col {...layout.col}>
          <Form.Item
            name="contractDate"
            label="合同日期"
            {...layout.span8}
            rules={[{ required: true, message: '请填写合同日期' }]}
          >
            <DatePicker style={{ width: '100%' }} />
          </Form.Item>
        </Col>
        <Col {...layout.col}>
          <Form.Item
            name="partyA"
            label="甲方（用能单位）"
            {...layout.span8}
            rules={[{ required: true, message: '请填写甲方（用能单位）' }]}
          >
            <Select
              {...layout.select}
              onChange={(value: string, option: any) => {
                form.setFieldsValue({ partyAName: option?.children })
              }}
            >
              {basicData?.partyA?.map((o: any) => {
                return <Select.Option key={o.id}>{o.fullName}</Select.Option>
              })}
            </Select>
          </Form.Item>
          <Form.Item name="partyAName" hidden>
            <Input />
          </Form.Item>
        </Col>
        <Col {...layout.col}>
          <Form.Item
            name="contractAccountPeriod"
            label="合同账期"
            {...layout.span8}
            rules={[{ required: true, message: '请填写合同账期' }]}
          >
            <InputNumber
              addonAfter="天"
              min={0}
              placeholder="请输入天数"
              precision={0}
              step={1}
              style={{ width: '100%' }}
            />
          </Form.Item>
        </Col>
        <Col {...layout.col}>
          <Form.Item
            name="contractMoney"
            label="合同金额"
            {...layout.span8}
            rules={[{ required: true, message: '请填写合同金额' }]}
          >
            <InputNumber
              addonAfter="元"
              min={0}
              placeholder="请输入"
              precision={2}
              step={1}
              style={{ width: '100%' }}
            />
          </Form.Item>
        </Col>
        <Col {...layout.col}>
          <Form.Item
            name="partyABank"
            label="甲方银行"
            {...layout.span8}
            rules={[{ required: true, message: '请填写甲方银行' }]}
          >
            <Select {...layout.select}>
              {basicData?.bank?.map((o: string) => {
                return <Select.Option key={o}>{o}</Select.Option>
              })}
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Form.Item
        name="isInitiate"
        valuePropName="checked"
        {...layout.span24NoLabel}
        rules={[{ required: true, message: '请确认是否需要发起电子合同签章流程' }]}
        initialValue={false}
      >
        {/* 是否需要发起电子合同签章流程 0需要 1不需要 */}
        <Checkbox>
          不用发起电子合同签核流程
          <Typography.Text type="danger">
            （勾选后代表已经线下签核完成，只上传合同，记录合同相关信息，不需要进行签核流程）
          </Typography.Text>
        </Checkbox>
      </Form.Item>
      <Form.Item noStyle shouldUpdate={(prevValues, currentValues) => prevValues.partyA !== currentValues.partyA}>
        {({ getFieldValue }: FormInstance) => {
          return (
            <Form.Item
              name="partyB"
              label="乙方"
              {...layout.span24}
              rules={[
                {
                  validator: (rule: any, value: any[]) => {
                    if (value) {
                      return Promise.resolve()
                    }

                    return Promise.reject('请选择乙方')
                  },
                },
              ]}
            >
              <TransferComponent dataSource={basicData?.partyAMap?.[getFieldValue('partyA')] ?? []} />
            </Form.Item>
          )
        }}
      </Form.Item>

      <Row>
        <Col {...layout.col}>
          <Form.Item
            name="partyBBANK"
            label="乙方银行"
            {...layout.span8}
            rules={[{ required: true, message: '请填写乙方银行' }]}
          >
            <Select {...layout.select}>
              {basicData?.bank?.map((o: string) => {
                return <Select.Option key={o}>{o}</Select.Option>
              })}
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Typography.Title level={5}>
        <Typography.Text type="danger">*</Typography.Text> 合同上传
      </Typography.Title>
      <Form.Item
        name="contractFile"
        label="选择合同档案"
        {...layout.span24}
        rules={[
          {
            validator: (rule: any, value: any[]) => {
              if (value?.length > 1) {
                return Promise.reject('最多只能上传一份合同档案哦~')
              }

              if (value?.length > 0) {
                return Promise.resolve()
              }

              return Promise.reject('请选择合同档案')
            },
          },
        ]}
      >
        <UploadPdf
          fileType="pdf"
          extra="支持扩展名：.pdf"
          maxCount={1}
          extraWrap={false}
          action={FileService.path('/api/fileserver/energy-file/Uploader/contract')}
        >
          <Form.Item noStyle shouldUpdate={(preValues, curValue) => preValues.contractFile !== curValue.contractFile}>
            {({ getFieldValue }: FormInstance) => (
              <Space direction="horizontal">
                <Input readOnly value={getFieldValue('contractFile')?.[0]?.name ?? ''} />
                <Button type="primary">浏览</Button>
              </Space>
            )}
          </Form.Item>
        </UploadPdf>
      </Form.Item>

      <Form.Item shouldUpdate={(prevValues, curValue) => prevValues.contractType !== curValue.contractType}>
        {({ getFieldValue, setFieldsValue }) => {
          return getFieldValue('contractType') === 'd3ae8c5bdef2428391a14cd796a5499f' ? (
            <PurchaseContract layout={layout} setFieldsValue={setFieldsValue} />
          ) : null
        }}
      </Form.Item>
      {/* 是否需要签字 0需要 1不需要 */}
      <Form.Item
        name="isSign"
        valuePropName="checked"
        label="是否需要签字"
        {...layout.span24}
        // rules={[{ required: true, message: '请确认是否需要签字' }]}
        initialValue={true}
      >
        <Switch />
      </Form.Item>
    </Form>
    </Spin>
  )
}

export default () => {
  const nav = useNavigate()
  const location = useLocation()
  const [searchParams] = useSearchParams()
  const formRef = React.useRef<ContractFormInstance>()

  // 设置面包屑
  React.useEffect(() => {
    SideMenuObservable.next(false)
    BreadcrumbObservable.next([
      location.state?.engineering
        ? { name: '工程管理', url: `/invest/projectLibrary`, state: location.state }
        : { name: '投资开发', url: '/invest/workbench' },
      { name: '合同审批', url: `/invest/contractApproval`, state: location.state },
      { name: '新建合同', url: `/invest/contract`, state: location.state },
    ])

    return () => {}
  }, [location.state])

  const goBack = React.useCallback(() => {
    nav(-1)
  }, [])

  const cancel = React.useCallback(() => {
    formRef.current?.reset()
    nav(-1)
  }, [])

  return (
    <Card
      className="right-actions"
      title={<Typography.Title level={5}>新建合同</Typography.Title>}
      extra={
        <Space size={12}>
          <Button onClick={goBack}>返回</Button>
          <Button onClick={cancel}>取消</Button>
          <Button
            type="primary"
            onClick={() => {
              formRef.current?.submit(() => nav(-1))
            }}
          >
            提交
          </Button>
        </Space>
      }
      bodyStyle={{ height: `calc(100vh - 210px)`, padding: 0 }}
    >
      <div style={{ padding: '16px 40px', height: '100%', overflow: 'auto' }}>
        <ContractForm
          source={Number(location.state?.engineering ?? false)}
          type={searchParams.get('type')}
          componentRef={formRef}
        />
      </div>
    </Card>
  )
}
