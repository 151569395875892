import {
  FundProjectionScreenOutlined,
  OrderedListOutlined,
  PlusOutlined,
  SettingOutlined,
  UserAddOutlined,
} from '@ant-design/icons'
import { Breadcrumb, Button, Card, Cascader, Col, Image, Row, Space, Spin, Tabs, Tooltip } from 'antd'
import React, { useEffect, useMemo, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'

import TechnologyList from './components/technology-list'
import FinanceList from './components/finance-list'
import { ProjectServiceHistory, TechnologyService } from '../../../../apis/project-service-history'
import { BreadcrumbObservable, SideMenuObservable } from '../../../../application'
import Global from '../../../../global'
import { ReportList } from './components/report-list'
import useAuth from '../../../../utils/auth'
import styles from './index.module.scss'
// import useAuthData from '../../../../utils/authData'

export const ProgramDetail = () => {
  const params: {
    id?: string
    type?: string
  } = useParams()

  const nav = useNavigate()

  const [breadcrumb, setBreadcrumb] = React.useState<{ name: string; url: string }[]>([])
  const [itemInfo, setItemInfo] = useState<any>()
  const [firstLoading, setFirstLoading] = useState(true)
  const [loading, setLoading] = useState(false)
  const [edge, setEdge] = useState(false)
  const [allEdge, setAllEdge] = useState(false)
  const [activeKey, setActiveKey] = useState(
    params.type === 'fin' ? 'finance' : params.type === 'report' ? 'report' : 'technology'
  )

  const [sort, setSort] = useState(['createTimeSort', '1'])

  const init = (showLoading = true, edge = true) => {
    if (showLoading) {
      setLoading(true)
    }
    ProjectServiceHistory.get({ projectId: params.id!.toString() })
      .then((resp) => {
        setItemInfo(resp.data)
        setEdge(edge || resp.data.technicalProposalNum > 0)
        setBreadcrumb([
          {
            name: '项目管理',
            url: '/program/manage',
          },
          {
            name: resp.data.name,
            url: `/program/detail/${resp.data.id}`,
          },
        ])
        BreadcrumbObservable.next([])
      })
      .catch(() => {})
      .finally(() => {
        setLoading(false)
        setFirstLoading(false)
      })
  }

  const auth = useAuth(params.id) || {}
  // const authData = useAuthData(params.id) || {}

  const hasAuth = useMemo(() => {
    let isAuth = false
    if (auth) {
      switch (activeKey) {
        case 'technology':
          // isAuth = !!auth['project_technical_proposal_operation']
          isAuth = !!auth['technical_proposal']
          break
        case 'finance':
          isAuth = !!auth['financial_plan']
          break
        case 'report':
          isAuth = !!auth['programme_report']
          break
      }
    }
    return isAuth
  }, [activeKey, auth])

  useEffect(() => {
    SideMenuObservable.next(true)
    ProjectServiceHistory.infoList({
      projectId: params.id!,
    }).then((resp) => {
      const { countBorder, electricityScheme, loadCurve } = resp.data
      setAllEdge(countBorder && electricityScheme && loadCurve)
      init(true, countBorder && electricityScheme && loadCurve)
    })
  }, [])

  const canCreate = (key: string) => {
    switch (key) {
      case 'finance':
        return true
      case 'technology':
        return itemInfo.technicalProposalNum < Global.role.technicalNum
    }
    return true
  }

  return (
    <div style={{ height: '100%', marginTop: edge ? 10 : 50 }} id="draw-test">
      <div className={styles.headerCss}>
        {breadcrumb.length > 1 && breadcrumb.filter((item) => item.url.indexOf('user-center') === -1).length !== 0 && (
          <Breadcrumb style={{ margin: 0 }}>
            {breadcrumb.map((o) => (
              <Breadcrumb.Item key={o.name}>{(o.url && <Link to={o.url}>{o.name}</Link>) || o.name}</Breadcrumb.Item>
            ))}
          </Breadcrumb>
        )}
        <Space>
          <Tooltip title="添加成员">
            <UserAddOutlined onClick={() => nav(`settings/member`)} />
          </Tooltip>
          <Tooltip title="项目设置">
            <Button
              type="link"
              shape="round"
              icon={<SettingOutlined />}
              style={{
                color: 'rgba(0, 0, 0, 0.65)',
              }}
              onClick={() => nav(`settings/info`)}
            >
              项目设置
            </Button>
          </Tooltip>
        </Space>
      </div>
      <Card
        className="no-border-bottom-card"
        style={{ height: '100%' }}
        title={
          <div
            style={{
              fontSize: 20,
              color: '#262E3F',
              fontWeight: 'bold',
            }}
          >
            项目详情
          </div>
        }
        bodyStyle={{
          paddingTop: 0,
        }}
        extra={
          <Link to={`/program/edge/${params.id}/electricity/catalogue`}>
            <Button type="primary" icon={<OrderedListOutlined />}>
              边界设置
            </Button>
          </Link>
        }
      >
        <Row
          style={{
            marginBottom: '1rem',
          }}
        >
          <Col
            span={7}
            style={{
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
            }}
          >
            <strong>项目地址：</strong>
            <Tooltip title={itemInfo?.address}>
              <span>{itemInfo?.address || ''}</span>
            </Tooltip>
          </Col>
          <Col span={7} offset={1}>
            <strong>建设期长度：</strong>
            <span>{itemInfo?.buildMonth || '--'}个月</span>
          </Col>
          <Col span={7} offset={1}>
            <strong>运营期长度：</strong>
            <span>{itemInfo?.maintainMonth || '--'}个月</span>
          </Col>
        </Row>
        <Spin tip="Loading..." spinning={loading}>
          {!firstLoading && !edge && (
            <div
              style={{
                height: 'calc(100vh - 400px)',
                minHeight: 162,
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <div
                style={{
                  textAlign: 'center',
                }}
              >
                <div>
                  <Image
                    style={{
                      width: 340,
                      height: 340,
                    }}
                    src={'./assets/edge-empty.png'}
                    alt=""
                    preview={false}
                  />
                </div>
                <Link to={`/program/edge/${params.id}/electricity/catalogue`}>
                  <Button
                    type="primary"
                    style={{
                      width: 340,
                    }}
                    onClick={() => {}}
                  >
                    开始边界设置
                  </Button>
                </Link>
              </div>
            </div>
          )}
          {!firstLoading && edge && (
            <Tabs
              className="manage-tabs"
              activeKey={activeKey}
              onChange={(e) => {
                setActiveKey(e)
                let key = ''
                switch (e) {
                  case 'technology':
                    key = 'tech'
                    break
                  case 'finance':
                    key = 'fin'
                    break
                  case 'report':
                    key = 'report'
                    break
                }
                nav(`/program/detail/${params.id}/${key}`)
              }}
              tabBarExtraContent={
                <Space>
                  <Cascader
                    allowClear={false}
                    options={
                      [
                        {
                          value: 'createTimeSort',
                          label: '创建时间',
                          children: [
                            {
                              value: '2',
                              label: '最近创建',
                            },
                            {
                              value: '1',
                              label: '最早创建',
                            },
                          ],
                        },
                        {
                          value: 'updateTimeSort',
                          label: '更新时间',
                          children: [
                            {
                              value: '2',
                              label: '最近更新',
                            },
                            {
                              value: '1',
                              label: '最早更新',
                            },
                          ],
                        },
                        activeKey !== 'report' && {
                          value: 'mark',
                          label: '星标优先',
                        },
                        activeKey !== 'report' && {
                          value: 'lock',
                          label: '归档优先',
                        },
                      ].filter(Boolean) as any
                    }
                    onChange={(val: any) => {
                      setSort(val)
                    }}
                    defaultValue={['createTimeSort', '2']}
                    placeholder=""
                  />
                  {activeKey !== 'report' && (
                    <Tooltip title={'请先完成边界设置'} trigger={allEdge ? 'none' : 'hover'}>
                      <Button
                        disabled={!allEdge || !canCreate(activeKey) || !hasAuth}
                        // disabled={!allEdge || !canCreate(activeKey) || !authData['project_technical_proposal_operation']}
                        type="primary"
                        icon={activeKey === 'technology' ? <PlusOutlined /> : <FundProjectionScreenOutlined />}
                        onClick={() => {
                          if (activeKey === 'technology') {
                            TechnologyService.add({ projectId: params.id! }).then((resp) => {
                              nav(`/workspace/${resp.data}/tech`)
                            })
                          } else {
                            nav(`/workspace/${params.id}/fin`)
                          }
                        }}
                      >
                        {activeKey === 'technology' ? '新建方案' : '进工作台'}
                      </Button>
                    </Tooltip>
                  )}
                </Space>
              }
            >
              <Tabs.TabPane key="technology" tab="技术方案">
                <div className={styles.fullContent}>
                  {activeKey === 'technology' && !!itemInfo && (
                    <TechnologyList
                      hasAuth={hasAuth}
                      cannotCalc={!allEdge}
                      timeSort={sort}
                      item={itemInfo!}
                      create={() => {
                        TechnologyService.add({ projectId: params.id! }).then((resp) => {
                          nav(`/workspace/${resp.data}/tech`)
                        })
                      }}
                      onChange={() => {
                        init(false)
                      }}
                    />
                  )}
                </div>
              </Tabs.TabPane>
              <Tabs.TabPane key="finance" tab="财务方案">
                <div className={styles.fullContent}>
                  {activeKey === 'finance' && !!itemInfo && (
                    <FinanceList
                      hasAuth={hasAuth}
                      cannotCalc={!allEdge}
                      timeSort={sort}
                      create={() => {
                        nav(`/workspace/${params.id}/fin`)
                      }}
                      onChange={() => {
                        init(false)
                      }}
                    />
                  )}
                </div>
              </Tabs.TabPane>
              <Tabs.TabPane key="report" tab="项目报告">
                <div className={styles.fullContent}>
                  {activeKey === 'report' && (
                    <ReportList
                      item={itemInfo!}
                      hasAuth={hasAuth}
                      timeSort={sort}
                      projectId={params.id}
                      switchTab={(key: string) => {
                        setActiveKey(key)
                      }}
                    />
                  )}
                </div>
              </Tabs.TabPane>
            </Tabs>
          )}
        </Spin>
      </Card>
    </div>
  )
}
