import React,{useState} from 'react'
import { Row, Col } from 'antd';
import './index.less'
import styles from './index.module.scss'
import ReactECharts from 'echarts-for-react'
import * as echarts from 'echarts'
import chinaJson from "./china.json"
import province from "./coordinate.json"

//注册地图信息
//@ts-ignore
echarts.registerMap("china",{geoJSON: chinaJson});

export const ManagementCockpit = () => {
  const [mapUnit] = useState([{name:"目标值",value:1405},
    {name:"总容量",value:1505},
    {name:"已投产",value:1505},
    {name:"备案",value:1505}]);

  const [capacityUnit] = useState([{name:"本月新增",value:1405},
  {name:"年度累计",value:1505}]);

  const [enable,setEnable] = useState(0);


  //定义地图opt
  
   var mapData = Object.keys(province).map((item,index)=>{
      let colors = ['#B6CCD9', '#9DECED', '#B6CCD9', '#B6CCD9', '#B6CCD9', '#9DEDED', '#9DEDED', '#B6CCD9', '#B6CCD9', '#2FC25B'];
      let colorsadd = ['#75AFFD', '#75AFFD', '#75AFFD', '#75AFFD', '#75AFFD', '#75AFFD', '#75AFFD', '#75AFFD', '#75AFFD', '#ffffff'];
      let length = Object.keys(province).length;
      let percent = Math.floor(length / colors.length);
      let active = Math.floor(index / percent);
      if (active === colors.length) {
          active = colors.length - 1;
      } 
    return {
      name: item,
      key: Math.floor( Math.random()*100),
      itemStyle: {
        areaColor: colors[active] ,
        textStyle: {
          color:  colorsadd[active] 
           }
        },  
        label: { // 地图上的文字
          normal:{
            show: true,
            textStyle: {
              color: colorsadd[active] 
             },
            },
        }
      }
  });

  var mapOpt =  {
    backgroundColor: '#fff',
    title: {
      left: 10,
      right: 10,
      text: '中国地图',
      textStyle: {
        color: '#fff',
      },
    },
    tooltip: {
      trigger: 'item',
      backgroundColor: 'rgba(0,0,0,0)',
      padding: 0,
      //@ts-ignore
      formatter: function (params) {
        let { data = {},name = '' } = params; // 第一个参数 `params` 是 formatter 需要的数据集
        let { key = 0 } = data;  // 传入的数据值
        // 实际来源地**
        // 最终目的地规模**人
        var content = `<div class="info_box">
          <div class="info_box_marker"></div>
          <div class="info_box_box">
            <span class="name">${name}</span>
            <span class="value">${key}</span>
            <span class="company">人</span>
          </div>
        </div>`;
        return content;
      }
    },
    legend: {
      orient: 'vertical',
      y: 'bottom',
      x: 'right',
      textStyle: {
        color: '#fff',
      },
    },    		  
    series: [
      // 地图
      {
        type: 'map',
        mapType: 'china',
        top: '8%',
		    aspectScale: 0.75, // 长宽比
		    roam: false,
		    zoom: 1.15,
        label: { // 地图上的文字
          show: true,
          textStyle: {
            color: '#75AFFD'
          }
        },
        itemStyle: { //地图多边形图形样式
          emphasis: { // 高亮时区域颜色
            areaColor: '#2FC25B'
          }
        },
        data: mapData
      }
    ],
  };
 
  //板块核准/备案容量表
  var plateTreeOpt = {
    backgroundColor:'#fff',
    title: [
      {
        text:'单位:(MW)',
         textStyle: {'color':'#333333',fontSize: 12},
        top:'3%',
        // right: 'right',
        left:'left'
      },
    ],
    tooltip: {
      trigger: 'axis',
      axisPointer: { // 坐标轴指示器，坐标轴触发有效
        type: 'shadow' // 默认为直线，可选为：'line' | 'shadow'
      }
    },
    grid: {
      left: '2%',
      right: '4%',
      bottom: '4%',
      top:'20%',
      containLabel: true
    },
    legend: {
      data: [],
      // right: 30,
      top:'10%',
      textStyle: {
        color: "#000"
      },
      // itemWidth: 12,
      itemHeight: 10,
      // itemGap: 35
    },
    xAxis: {
      type: 'category',
      data:['1月','2月','3月','4月','5月','6月','7月','8月','9月','10月','11月','12月'],
      axisLine: {
        lineStyle: {
          color: 'black'

        }
      },
      axisLabel: {
        textStyle: {
          fontFamily: 'Microsoft YaHei'
        }
      },
    },

    yAxis: {
      type: 'value',
      // max:'1200',
      axisLine: {
        show: false,
        lineStyle: {
          color: '#000'
        }
      },
      splitLine: {
        show: true,
        lineStyle: {
          color: '#cccccc'
        }
      },
      axisLabel: {}
    },
    "dataZoom": [{
      "show": false,
      "height": 12,
      "zoomOnMouseWheel":false,
      "xAxisIndex": [
        0
      ],
      bottom:'8%',
      "start": 0,
      "end": 100,
      handleIcon: 'path://M306.1,413c0,2.2-1.8,4-4,4h-59.8c-2.2,0-4-1.8-4-4V200.8c0-2.2,1.8-4,4-4h59.8c2.2,0,4,1.8,4,4V413z',
      handleSize: '110%',
      handleStyle:{
        color:"#d3dee5",

      },
      textStyle:{
        color:"#000"},
      borderColor:"#90979c"
    }, {
      "type": "inside",
      "show": false,
      "height": 15,
      "start": 1,
      "end": 35
    }],
    series: [
  {
      name: '地面',
      type: 'bar',
      barWidth: '25%',
      itemStyle: {
        normal: {
          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
            offset: 0,
            color: '#1EC692'
          }]),
          // barBorderRadius: 12,
        },
      },
      data: [8000, 7500, 6500, 5500, 4500,8000, 7500, 6500, 5500, 4500,3000,2000]
    },
{
  name: '屋面',
  type: 'bar',
  barWidth: '25%',
  itemStyle: {
    normal: {
      color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
        offset: 0,
        color: '#75AFFD'
      }]),
    },
  },
  data: [4000, 6500, 7500, 8500, 3500,5000, 6500, 7500, 8500, 6500,5000,8000]
},
    
    ]
  };

  var groundOpt = {
    title: {
              text: '比重',
              // 副标题
              subtext: "100%",
              // 主副标题间距
              itemGap: 6,
              x: 'center',
              y: 'center',
              top: '70',
              // 主标题样式
              textStyle: {
                  fontSize: '12',
                  color: 'rgba(0, 0, 0, 0.45)'
              },
              // 副标题样式
              subtextStyle: {
                  fontSize: '16',
                  fontWeight: '800',
                  color: 'rgba(0, 0, 0, 0.85)'
              }
          },
      tooltip: {
            trigger: 'item',
            // formatter: "{a} <br/>{b} : {c} ({d}%)"
            formatter: "{b} : {c}%"
          },
          series: [
            {
              name: '',
              type: 'pie',
              radius: ['40%', '70%'],
              label: {
        normal:{
          textStyle:{
            color:'#000000'
          },
          formatter: '{name|{b}}\n{time|{c}%}',
          minMargin: 5,
          edgeDistance: 190,
          lineHeight: 15,
          
          rich: {
            time: {
            fontSize: 10,
            color: '#999999'
            },
          },
        },
        
              },
              data: [
                { value: 24.5, name: '地面',itemStyle: {color: "#1EC692"} },
                { value: 75.5, name: '屋面',itemStyle: {color: "#75AFFD"}  }
              ],
      emphasis: {
        itemStyle: {
          shadowBlur: 10,
          shadowOffsetX: 0,
          shadowColor: 'rgba(0, 0, 0, 0.5)'
        }
      },
            }
          ],
  };


  //月度核准/备案容量表
  const monthlyOpt= {
    backgroundColor:'#fff',
    title: [
      {
        text:'单位:(MW)',
         textStyle: {'color':'#333333',fontSize: 12},
        top:'3%',
        left:'left'
      },
    ],
    tooltip: {
      trigger: 'axis',
      axisPointer: { // 坐标轴指示器，坐标轴触发有效
        type: 'shadow' // 默认为直线，可选为：'line' | 'shadow'
      }
    },
    grid: {
      left: '2%',
      right: '4%',
      bottom: '4%',
      top:'20%',
      containLabel: true
    },
    legend: {
      data: [],
      // right: 30,
      top:'10%',
      textStyle: {
        color: "#000"
      },
      // itemWidth: 12,
      itemHeight: 10,
      // itemGap: 35
    },
    xAxis: {
      type: 'category',
      data:['1月','2月','3月','4月','5月','6月','7月','8月','9月','10月','11月','12月'],
      axisLine: {
        lineStyle: {
          color: 'black'
        }
      },
        boundaryGap: false,
    },

    yAxis: {
      type: 'value',
      axisLine: {
        show: false,
        lineStyle: {
          color: '#000'
        }
      },
      splitLine: {
        show: true,
        lineStyle: {
          color: '#cccccc'
        }
      },
      axisLabel: {}
    },
    "dataZoom": [{
      "show": false,
      "height": 12,
      "zoomOnMouseWheel":false,
      "xAxisIndex": [
        0
      ],
      bottom:'8%',
      "start": 0,
      "end": 100,
      handleIcon: 'path://M306.1,413c0,2.2-1.8,4-4,4h-59.8c-2.2,0-4-1.8-4-4V200.8c0-2.2,1.8-4,4-4h59.8c2.2,0,4,1.8,4,4V413z',
      handleSize: '110%',
      handleStyle:{
        color:"#d3dee5",

      },
      textStyle:{
        color:"#000"},
      borderColor:"#90979c"
    }, {
      "type": "inside",
      "show": false,
      "height": 15,
      "start": 1,
      "end": 35
    }],
    series: [
  {
        name: '新增容量',
        type: 'line',
        stack: 'Total',
        smooth: true,
        seriesLayoutBy: 'row',
        emphasis: {
          focus: 'series'
        },
        areaStyle: {
              normal: {
                color: '#D1E9FF' //改变区域颜色
              }
            },
        itemStyle: {
          normal : {  
                                 color:'#1890FF',  
                                 lineStyle:{  
                                     color:'#1890FF'  
                                 }  
                             } 
        },
        data: [120, 152, 181, 234, 250, 280, 300,320, 350, 401, 434, 500, 550, 650],
      
      }
 
    ]
  };

  //各月度核准/备案对比图
  const eachMonthOpt = {
    backgroundColor:'#fff',
    title: [
      {
        text:'单位:(MW)',
         textStyle: {'color':'#333333',fontSize: 12},
        top:'3%',   
        left:'left'
      },
    ],
    tooltip: {
      trigger: 'axis',
      axisPointer: { // 坐标轴指示器，坐标轴触发有效
        type: 'shadow' // 默认为直线，可选为：'line' | 'shadow'
      }
    },
    grid: {
      left: '2%',
      right: '4%',
      bottom: '4%',
      top:'20%',
      containLabel: true
    },
    legend: {
      data: [],
      top:'10%',
      textStyle: {
        color: "#000"
      },
      itemHeight: 10,
    },
    xAxis: {
      type: 'category',
      data:['1月','2月','3月','4月','5月','6月','7月'],
      axisLine: {
        lineStyle: {
          color: 'black'
        }
      },
    },
    yAxis: {
      type: 'value',
      // max:'1200',
      axisLine: {
        show: false,
        lineStyle: {
          color: '#000'
        }
      },
      splitLine: {
        show: true,
        lineStyle: {
          color: '#cccccc'
        }
      },
      axisLabel: {}
    },
    "dataZoom": [{
      "show": false,
      "height": 12,
      "zoomOnMouseWheel":false,
      "xAxisIndex": [
        0
      ],
      bottom:'8%',
      "start": 0,
      "end": 100,
      handleIcon: 'path://M306.1,413c0,2.2-1.8,4-4,4h-59.8c-2.2,0-4-1.8-4-4V200.8c0-2.2,1.8-4,4-4h59.8c2.2,0,4,1.8,4,4V413z',
      handleSize: '110%',
      handleStyle:{
        color:"#d3dee5",
      },
      textStyle:{
        color:"#000"},
      borderColor:"#90979c"
    }, {
      "type": "inside",
      "show": false,
      "height": 15,
      "start": 1,
      "end": 35
    }],
    series: [{
      name: '光伏项目',
      type: 'bar',
      barWidth: '50%',
      itemStyle: {
        normal: {
          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
            offset: 0,
            color: '#1EC692'
          }]),
        },
      },
      data: [8000, 7500, 6500, 5500, 4500,8000, 7500]
    },
    
    ]
  };
   
  //各省核准/备案容量对比图
  const eachProviceOpt = {
    backgroundColor:'#fff',
    title: [
      {
        text:'单位:(MW)',
         textStyle: {'color':'#333333',fontSize: 12},
        // left:'10%',//'5' | '5%'，title 组件离容器左侧的距离
        top:'3%',
        // right: 'right',
        left:'left'
        // padding: [20, 0, 0, '5%']  // 位置
      },
    ],
    tooltip: {
      trigger: 'axis',
      axisPointer: { // 坐标轴指示器，坐标轴触发有效
        type: 'shadow' // 默认为直线，可选为：'line' | 'shadow'
      }
    },
    grid: {
      left: '2%',
      right: '4%',
      bottom: '4%',
      top:'20%',
      containLabel: true
    },
    legend: {
      data: [],
      // right: 30,
      top:'10%',
      textStyle: {
        color: "#000"
      },
      itemHeight: 10,
    },
    xAxis: {
      type: 'category',
      data:['广东省','河南省','吉林省','广西省','福建省','海南省','河北省'],
      axisLine: {
        lineStyle: {
          color: 'black'

        }
      },
      // boundaryGap: false,
  axisLabel: { interval: 0, rotate: 30 }
    },

    yAxis: {
      type: 'value',
      // max:'1200',
      axisLine: {
        show: false,
        lineStyle: {
          color: '#000'
        }
      },
      splitLine: {
        show: true,
        lineStyle: {
          color: '#cccccc'
        }
      },
      axisLabel: {}
    },
    "dataZoom": [{
      "show": false,
      "height": 12,
      "zoomOnMouseWheel":false,
      "xAxisIndex": [
        0
      ],
      bottom:'8%',
      "start": 0,
      "end": 100,
      handleIcon: 'path://M306.1,413c0,2.2-1.8,4-4,4h-59.8c-2.2,0-4-1.8-4-4V200.8c0-2.2,1.8-4,4-4h59.8c2.2,0,4,1.8,4,4V413z',
      handleSize: '110%',
      handleStyle:{
        color:"#d3dee5",

      },
      textStyle:{
        color:"#000"},
      borderColor:"#90979c"
    }, {
      "type": "inside",
      "show": false,
      "height": 15,
      "start": 1,
      "end": 35
    }],
    series: [{
      name: '光伏项目',
      type: 'bar',
      barWidth: '50%',
      itemStyle: {
        normal: {
          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
            offset: 0,
            color: '#399FFE'
          }]),
        },
      },
      data: [8000, 7500, 6500, 5500, 4500,8000, 7500, 6500, 5500, 4500,3000,2000]
    },
    
    ]
  };

  return (
    <div className={styles.main}>
      <Row>
        {/* 左列 */}
        <Col span={16}>
          <div className={styles.mapPanel}>
              <div className={styles.titlePanel}>
                  新能源发展统计月报
              </div>
              <Row>
                <Col span={4}>
                  {
                    mapUnit.map((item,index) => {
                      return <div key={index} className={styles.dataUnit}>
                                <div>{item.name}</div>
                                  <div className={styles.value}>
                                     {item.value}<span className={styles.unit}>kw</span>
                                  </div>
                              </div>
                    })
                  }
                </Col>
                <Col span={20}>
                   <ReactECharts option={mapOpt} style={{width:'100%',height:'450px'}}></ReactECharts>
                </Col>
              </Row>
          </div>
          <div className={styles.capacityPanel}>
                <div className={styles.titlePanel}>
                  板块核准/备案容量表
                </div>
                <Row>
                    <Col span={4}>
                    {
                      capacityUnit.map((item,index) => {
                        return <div key={index} className={styles.dataUnit}>
                                  <div>{item.name}</div>
                                    <div className={styles.value}>
                                      {item.value}<span className={styles.unit}>kw</span>
                                    </div>
                                </div>
                      })
                    }
                    </Col>
                    <Col span={2}>
                      <div className={enable === 0 ? `${styles.filterBtn} ${styles.activeBtn}`:styles.filterBtn} onClick={()=>setEnable(0)}>月</div>
                      <div className={enable === 1 ? `${styles.filterBtn} ${styles.activeBtn}`:styles.filterBtn} onClick={()=>setEnable(1)}>年</div>
                    </Col>
                    <Col span={11}>
                      <ReactECharts option={plateTreeOpt} style={{width:'100%',height:'190px'}}></ReactECharts> 
                    </Col>
                    <Col span={7}>
                      <ReactECharts option={groundOpt} style={{width:'100%',height:'190px'}}></ReactECharts> 
                    </Col>
                </Row>
          </div>
        </Col>
        {/* 右列 */}
        <Col span={8}>
          <div className={styles.chartPanel}>
                <div className={styles.titlePanel}>
                  月度核准/备案容量表
                </div>
                <ReactECharts option={monthlyOpt} style={{width:'100%',height:'190px'}}></ReactECharts> 
          </div>
          <div className={styles.chartPanel}>
                <div className={styles.titlePanel}>
                  各月度核准/备案对比图
                </div> 
                <ReactECharts option={eachMonthOpt} style={{width:'100%',height:'190px'}}></ReactECharts> 
          </div>
          <div className={styles.chartPanel}>
                <div className={styles.titlePanel}>
                  各省核准/备案容量对比图
                </div>
                <ReactECharts option={eachProviceOpt} style={{width:'100%',height:'190px'}}></ReactECharts> 
          </div>
        </Col>
      </Row>
    </div>
  )
}
