import React, { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Button, Card, message, Space, Tabs } from 'antd'
import { ExportOutlined, PlusOutlined } from '@ant-design/icons'

import { BreadcrumbObservable, SideMenuObservable } from '../../../application'
import { TablePageInstance } from '../../../components/wl-table-page'
import { FeeService } from '../../../apis/wl-service'

import ApplyTable from './components/ApplyTable'
import PayTable from './components/PayTable'

import './index.less'

type keyType = 'apply' | 'pay'
export default () => {
  const nav = useNavigate()
  const applyRef = useRef<TablePageInstance>(null)
  const payRef = useRef<TablePageInstance>(null)
  const [activeTabKey, setActiveTabKey] = useState<keyType>('apply') // 当前显示的Tab

  useEffect(() => {
    SideMenuObservable.next(false)
    BreadcrumbObservable.next([
      {
        name: '工程管理',
        url: '/invest/projectLibrary',
        state: { engineering: true },
      },
      {
        name: '费用管理',
        url: '/engineering/cost',
      },
    ])
  }, [])

  useEffect(() => {
    if (activeTabKey === 'apply') {
      applyRef.current?.reload?.()
    } else {
      payRef.current?.reload?.()
    }
  }, [activeTabKey])

  const add = React.useCallback(() => {
    nav('/engineering/cost/-1', { state: { type: activeTabKey } })
  }, [activeTabKey])

  const onExport = React.useCallback(() => {
    let selectedKeys = (activeTabKey === 'apply' ? applyRef : payRef)?.current.getSelectedRowKeys() ?? []

    if (selectedKeys?.length > 0) {
      FeeService.export(
        {
          code: Number(activeTabKey === 'apply'),
          ids: selectedKeys.join(','),
        },
        { responseType: 'blob' }
      )
    } else {
      message.warn('请选择数据再导出哦~')
    }
  }, [activeTabKey])

  return (
    <Card
      style={{ height: '100%' }}
      // title={<div style={{ fontWeight: 600, fontSize: 20 }}>合作方</div>}
      className="no-border-bottom-card"
      extra={null}
      bodyStyle={{ height: 'calc(100vh - 150px)', padding: 0 }}
    >
      <Tabs
        className="engineering-cost-tabs"
        activeKey={activeTabKey}
        tabBarStyle={{ padding: '0 24px' }}
        tabBarExtraContent={
          <Space>
            <Button type="primary" style={{ display: 'none' }} icon={<PlusOutlined />} onClick={add}>
              新增
            </Button>
            <Button icon={<ExportOutlined />} onClick={onExport}>
              导出Excel
            </Button>
          </Space>
        }
        onChange={(key: string) => {
          setActiveTabKey(key as keyType)
        }}
      >
        <Tabs.TabPane tab="费用申请" key="apply">
          <ApplyTable componentRef={applyRef} />
        </Tabs.TabPane>
        <Tabs.TabPane tab="费用支付" key="pay">
          <PayTable componentRef={payRef} />
        </Tabs.TabPane>
      </Tabs>
    </Card>
  )
}
