import React from 'react'
import { Button, Form, FormInstance, Input, message, Modal, Select } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import { debounce } from 'lodash'
// @ts-ignore
import mapboxgl from '!mapbox-gl'
import { AddressSelector } from '../../address-selector'
import { ResourceService } from '../../../apis/wl-service'
import { ProjectService } from '../../../apis/project-service-stash'

import { NAME_EXP } from '../../../utils/constant'

let lastMarker

interface ProjectCreatorProps {
  form: FormInstance
}

export const ProjectCreator = (props: ProjectCreatorProps) => {
  const [map, setMap] = React.useState<any>()
  const [addressList, setAddressList] = React.useState<any[]>([])

  const createMapbox = () => {
    lastMarker = undefined
    const map = new mapboxgl.Map({
      style: 'mapbox://styles/owops/cl2h1u3kx000u15o26t9bmtyh',
      center: [116.418261, 39.921984],
      zoom: 2,
      minZoom: 1,
      maxZoom: 20,
      container: 'program-create-map',
      interactive: false,
      projection: 'equirectangular', // starting projection
    } as any)

    setMap(map)
  }

  React.useEffect(() => {
    createMapbox()
  }, [])

  const onSearch = (val: string) => {
    ProjectService.inputTips({ cityCode: props.form.getFieldValue('city') || '', address: val }).then((resp: any) => {
      setAddressList(resp.data)
    })
  }

  return (
    <div>
      <Form
        autoComplete="off"
        form={props.form}
        labelCol={{ span: 5 }}
        size="large"
        wrapperCol={{ span: 18, offset: 1 }}
      >
        <Form.Item
          label="项目名称"
          name="name"
          rules={[
            { required: true, message: '请输入项目名称' },
            { pattern: NAME_EXP, message: '1-16个字符,中文、英文、_、数字和-组成' },
          ]}
        >
          <Input size="middle" allowClear />
        </Form.Item>

        {/* <Form.Item label="建设期长度" name="buildMonth" rules={[{ required: false, message: '请输入建设期长度' }]}>
          <Input size="middle" disabled suffix="月" defaultValue="12" />
        </Form.Item>

        <Form.Item label="运营期长度" name="maintainMonth" rules={[{ required: false, message: '请输入运营期长度' }]}>
          <Input size="middle" disabled suffix="月" defaultValue="300" />
        </Form.Item> */}

        <Form.Item label="详细地址" name="addressObject" rules={[{ required: true, message: '请输入详细地址' }]}>
          <Select
            className="gaode-input small-empty"
            size="middle"
            showSearch
            onChange={(val: string) => {
              const obj = JSON.parse(val)
              const arr = obj.location.split(',').map(parseFloat)

              obj?.adcode && props.form.setFieldsValue({ city: obj.adcode })

              const popup = new mapboxgl.Popup({
                offset: [0, -40],
                closeButton: false,
              })
              if (lastMarker) {
                lastMarker.remove()
              }
              lastMarker = new mapboxgl.Marker({ offset: [0, -15] }).setLngLat([arr[0], arr[1]]).addTo(map)
              const txt = obj.name

              popup.setLngLat([arr[0], arr[1]]).setText(txt).addTo(map)

              map.setZoom(15)
              map.setCenter([arr[0], arr[1] + 0.0006])
            }}
            onSearch={debounce(onSearch, 300)}
            filterOption={() => true}
          >
            {addressList?.map((o: any, idx) => (
              <Select.Option key={`${o.address}--${idx}`} value={JSON.stringify(o)}>
                {o.name}
                <span className="hide-in-input" style={{ fontSize: 10, color: 'lightgrey', marginLeft: '1rem' }}>
                  {o.district}
                </span>
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label="地理位置" name="city">
          <AddressSelector />
        </Form.Item>
      </Form>

      <div id="program-create-map" style={{ width: '100%', height: 188, position: 'relative' }}></div>
    </div>
  )
}

export interface ProjectCreatorDialogProps {
  disabled?: boolean
  onAdd?: (data: any) => void
}

export default ({ disabled, onAdd }: ProjectCreatorDialogProps) => {
  const [visible, setVisible] = React.useState<boolean>(false)
  const [form] = Form.useForm()

  const close = React.useCallback(() => {
    setVisible(false)
  }, [])

  const handlerOk = React.useCallback(() => {
    form
      .validateFields()
      .then(async (values) => {
        try {
          const resp = await ResourceService.createCheck({ projectName: values.name })
          if (resp?.data === false) {
            message.warn('项目名称重复~')
            return
          }
        } catch (err: any) {
          console.warn(err)
        }
        const { name, city, addressObject } = values
        const addressObj = addressObject && JSON.parse(addressObject)
        const address = addressObj.name ?? ''
        const [longitude, latitude] = addressObj?.location?.split(',') ?? []
        //   projectModel
        close()
        onAdd({
          name,
          // buildMonth: '12',
          // maintainMonth: '300',
          cityCode: city,
          address,
          longitude: longitude,
          latitude: latitude,
        })
      })
      .catch((err: any) => {
        console.warn(err)
      })
  }, [])

  return (
    <>
      <Modal
        cancelText="取消"
        centered
        className="project-creator-dialog"
        okText="确定"
        title="新建项目"
        visible={visible}
        width={480}
        onCancel={close}
        onOk={handlerOk}
      >
        <ProjectCreator form={form} />
      </Modal>
      <Button
        type="text"
        block
        disabled={disabled}
        icon={<PlusOutlined />}
        onClick={(e: React.MouseEvent<HTMLAnchorElement>) => {
          e.preventDefault()
          setVisible(true)
        }}
      >
        新建项目
      </Button>
    </>
  )
}
