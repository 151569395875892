/** Generate by swagger-axios-codegen */
// @ts-nocheck
/* eslint-disable */

import { resolve } from 'dns';
import { reject } from 'lodash';
import { IRequestOptions, IRequestConfig, getConfigs, axios } from './serviceOptions';
export const basePath = '/api';

export interface IList<T> extends Array<T> { }
export interface List<T> extends Array<T> { }
export interface IDictionary<TValue> {
  [key: string]: TValue;
}
export interface Dictionary<TValue> extends IDictionary<TValue> { }

export interface IListResult<T> {
  items?: T[];
}

export class ListResultDto<T> implements IListResult<T> {
  items?: T[];
}

export interface IPagedResult<T> extends IListResult<T> {
  totalCount?: number;
  items?: T[];
}

export class PagedResultDto<T = any> implements IPagedResult<T> {
  totalCount?: number;
  items?: T[];
}

// customer definition
// empty

export class UserService {
  /**
   * 90天，强制修改密码接口
   */
  static updatePasswordByAccount(
    params: {
      /**  */
      root?: object;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/auth/user/updatePasswordByAccount';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['root'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 上传用户头像
   */
  static uploadHeadImg(
    params: {
      /** 图片文件 */
      file?: any;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/auth/user/uploadHeadImg';

      const configs: IRequestConfig = getConfigs('post', 'multipart/form-data', url, options);

      let data = null;
      data = new FormData();
      if (params['file']) {
        if (Object.prototype.toString.call(params['file']) === '[object Array]') {
          for (const item of params['file']) {
            data.append('file', item as any);
          }
        } else {
          data.append('file', params['file'] as any);
        }
      }

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 人机验证验证滑动
   */
  static check(
    params: {
      /**  */
      root?: object;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/auth/captcha/check';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['root'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 修改密码
   */
  static updatePassword(
    params: {
      /**  */
      root?: object;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/auth/user/updatePassword';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['root'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 修改用户基本信息
   */
  static update(
    params: {
      /**  */
      root?: object;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/auth/user/update';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['root'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 发送验证邮箱
   */
  static sendCheckEmail(
    params: {
      /**  */
      root?: object;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/auth/user/sendCheckEmail';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['root'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 更新手机号码
   */
  static updateTelephone(
    params: {
      /**  */
      root?: object;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/auth/user/updateTelephone';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['root'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 更新邮箱
   */
  static updateEmail(
    params: {
      /**  */
      root?: object;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/auth/user/updateEmail';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['root'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 更新邮箱 邮箱验证
   */
  static sendUpdateEmailCode(
    params: {
      /**  */
      root?: object;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/auth/user/sendUpdateEmailCode';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['root'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 查询用户信息
   */
  static info(options: IRequestOptions = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/auth/user/info';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 校验邮箱是否可用
   */
  static checkEmail(
    params: {
      /** 邮箱 */
      email?: string;
      /**  */
      root?: object;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/auth/i3060/checkEmail';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { email: params['email'] };
      let data = params['root'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 检查密码正确性
   */
  static checkPwd(
    params: {
      /**  */
      root?: object;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/auth/user/checkPwd';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['root'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 检查手机号是否存在
   */
  static validTelephone(
    params: {
      /** 手机号 */
      mobile: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/auth/user/validTelephone/{mobile}';
      url = url.replace('{mobile}', params['mobile'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 检查邮箱是否存在
   */
  static validEmail(
    params: {
      /** 邮箱 */
      email: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/auth/user/validEmail/{email}';
      url = url.replace('{email}', params['email'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 注册
   */
  static register(
    params: {
      /**  */
      root?: object;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/auth/i3060/register';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['root'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 注册发送短信
   */
  static sendRegisterCode(
    params: {
      /**  */
      root?: object;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/auth/i3060/sendRegisterCode';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['root'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 登录
   */
  static login(
    params: {
      /**  */
      root?: object;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/auth/i3060/login';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['root'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 登录发送短信
   */
  static sendLoginCode(
    params: {
      /**  */
      root?: object;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/auth/i3060/sendLoginCode';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['root'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 短信验证码登录
   */
  static loginByCode(
    params: {
      /**  */
      root?: object;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/auth/i3060/loginByCode';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['root'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取人机验证图片
   */
  static get(
    params: {
      /**  */
      root?: object;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/auth/captcha/get';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['root'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取账户角色限制
   */
  static roleInfo(options: IRequestOptions = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/auth/user/roleInfo';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 解绑手机号码发送短信
   */
  static sendUnBindPhoneCode(
    params: {
      /**  */
      root?: object;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/auth/user/sendUnBindPhoneCode';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['root'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 重置密码-发送手机号验证码
   */
  static sendMobileValid(
    params: {
      /** 手机号 */
      mobile?: string;
      /** 滑块验证码 */
      captchaVerification?: string;
      /**  */
      root?: object;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/auth/resetPassword/sendMobileValid';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { mobile: params['mobile'], captchaVerification: params['captchaVerification'] };
      let data = params['root'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 重置密码-发送邮箱验证码
   */
  static sendMailValid(
    params: {
      /** 邮箱 */
      mail?: string;
      /** 滑块验证码 */
      captchaVerification?: string;
      /**  */
      root?: object;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/auth/resetPassword/sendMailValid';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { mail: params['mail'], captchaVerification: params['captchaVerification'] };
      let data = params['root'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 重置密码-手机号验证码重置密码
   */
  static updatePwdByMobile(
    params: {
      /** 手机号 */
      mobile?: string;
      /** 验证码 */
      validCode?: string;
      /** 新密码 */
      newPassword?: string;
      /**  */
      root?: object;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/auth/resetPassword/updatePwdByMobile';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { mobile: params['mobile'], validCode: params['validCode'], newPassword: params['newPassword'] };
      let data = params['root'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 重置密码-邮箱验证码重置密码
   */
  static updatePwdByMail(
    params: {
      /** 邮箱 */
      mail?: string;
      /** 验证码 */
      validCode?: string;
      /** 新密码 */
      newPassword?: string;
      /**  */
      root?: object;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/auth/resetPassword/updatePwdByMail';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { mail: params['mail'], validCode: params['validCode'], newPassword: params['newPassword'] };
      let data = params['root'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 验证绑定手机号码发送短信
   */
  static sendBindTelephone(
    params: {
      /**  */
      root?: object;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/auth/user/sendBindTelephone';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['root'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 验证邮箱
   */
  static checkEmail1(
    params: {
      /** 邮箱验证码 */
      code?: string;
      /** 邮箱 */
      email?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/auth/user/checkEmail';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { code: params['code'], email: params['email'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }

  /**
   * 获取用户当前组织，没有设置设置默认组织
   */
  static userTenant(
    params: {
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/auth/tenant/userTenant';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class CommonService {
  /**
   * 发送删除或编辑参数库验证码
   */
  static sendFinanceValidMsg(
    params: {
      /** 滑块验证码 */
      captchaVerification?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/auth/common/sendFinanceValidMsg';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { captchaVerification: params['captchaVerification'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 查询基础配置key
   */
  static showKeys(
    params: {
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/other/auth/showKeys';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 查询省市区接口
   */
  static region(options: IRequestOptions = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/region';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取用电场景分类
   */
  static sceneList(options: IRequestOptions = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/industryModelLoad/sceneList';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 行业数据
   */
  static industryCodeList(options: IRequestOptions = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/industryModelLoad/industryCodeList';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 通过code查询城市边界
   */
  static getBoundary(
    params: {
      /** 城市code */
      code?: string;
      /**  */
      level?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/region/getBoundary';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { code: params['code'], level: params['level'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class ProjectServiceHistory {
  /**
   * 保存自定义电价
   */
  static saveCustom(
    params: {
      /** 项目id */
      projectId: string;
      /**  */
      root?: object;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/borderElectricity/saveCustom/{projectId}';
      url = url.replace('{projectId}', params['projectId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['root'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 删除自定义电价
   */
  static deleteCustom(
    params: {
      /** 自定义电价id */
      customId: string;
      /**  */
      root?: object;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/borderElectricity/deleteCustom/{customId}';
      url = url.replace('{customId}', params['customId'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = params['root'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 天气数据-下载天气数据
   */
  static download(
    params: {
      /** 项目id */
      projectId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/weather/download/{projectId}';
      url = url.replace('{projectId}', params['projectId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 天气数据-展示天气汇总表格
   */
  static showWeatherTable(
    params: {
      /** 项目id */
      projectId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/weather/showWeatherTable/{projectId}';
      url = url.replace('{projectId}', params['projectId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 天气数据-获取全国天气数据
   */
  static getWeatherData(
    params: {
      /** 天气下拉选项返回的value */
      weatherElement?: string;
      /** 时间 */
      timestamp?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/weather/getWeatherData';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { weatherElement: params['weatherElement'], timestamp: params['timestamp'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 天气数据-获取全国天气数据（聚合）
   */
  static getTmyWeather(
    params: {
      /** 天气下拉选项返回的value */
      weatherElement?: string;
      /** 时间 2000-10 */
      freq?: string;
      /** M */
      time?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/weather/getTmyWeather';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { weatherElement: params['weatherElement'], freq: params['freq'], time: params['time'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 天气数据-获取天气验证报告pdf
   */
  static validationReport(options: IRequestOptions = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/weather/validationReport';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 查询标杆燃煤电价
   */
  static queryNormFireCoal(
    params: {
      /** 项目id */
      projectId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/borderElectricity/queryNormFireCoal/{projectId}';
      url = url.replace('{projectId}', params['projectId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 查询自定义电价
   */
  static queryCustom(
    params: {
      /** 项目id */
      projectId: string;
      /** 自定义电价id(为空则查全部) */
      customId?: string;
      /**  */
      root?: object;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/borderElectricity/queryCustom/{projectId}';
      url = url.replace('{projectId}', params['projectId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { customId: params['customId'] };
      let data = params['root'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 查询自定义电价名称和id
   */
  static queryCustomNameIdList(
    params: {
      /** 项目id */
      projectId: string;
      /**  */
      root?: object;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/borderElectricity/queryCustomNameIdList/{projectId}';
      url = url.replace('{projectId}', params['projectId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = params['root'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 计算边界-获取已经设置的配置项
   */
  static infoList(
    params: {
      /** 项目id */
      projectId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/borderSetting/infoList/{projectId}';
      url = url.replace('{projectId}', params['projectId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 负荷曲线-典型负荷数据-添加行业典型数据_copy
   */
  static standard(
    params: {
      /** 项目id */
      projectId: string;
      /**  */
      root?: object;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/load/standard/{projectId}_1648810956820';
      url = url.replace('{projectId}', params['projectId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['root'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 负荷曲线-典型负荷数据-添加行业典型数据_copy
   */
  static standard1(
    params: {
      /** 项目id */
      projectId: string;
      /**  */
      root?: object;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/load/standard/{projectId}_1650381066500';
      url = url.replace('{projectId}', params['projectId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['root'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 负荷曲线-典型负荷数据-添加行业典型数据_copy
   */
  static standard2(
    params: {
      /** 项目id */
      projectId: string;
      /**  */
      root?: object;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/load/standard/{projectId}_1650381068892';
      url = url.replace('{projectId}', params['projectId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['root'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 项目数量
   */
  static count(options: IRequestOptions = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/project/count';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 计算边界-时间范围与精度-获取配置项
   */
  static countBorder(
    params: {
      /** 项目id */
      projectId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/borderSetting/countBorder/{projectId}';
      url = url.replace('{projectId}', params['projectId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 计算边界-时间范围与精度-添加配置项
   */
  static countBorder1(
    params: {
      /** 项目id */
      projectId: string;
      /**  */
      root?: object;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/borderSetting/countBorder/{projectId}';
      url = url.replace('{projectId}', params['projectId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['root'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 计算边界-时间范围与精度-编辑配置项
   */
  static countBorder2(
    params: {
      /** 项目id */
      projectId: string;
      /**  */
      root?: object;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/borderSetting/countBorder/{projectId}';
      url = url.replace('{projectId}', params['projectId'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params['root'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取地区经纬度
   */
  static inputTips(
    params: {
      /**  */
      cityCode?: string;
      /**  */
      address?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/map/inputTips';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { cityCode: params['cityCode'], address: params['address'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 负荷曲线-典型负荷数据-获取项目负荷图表
   */
  static chart(
    params: {
      /** 项目id */
      projectId: string;
      /**  */
      root?: object;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/load/chart/{projectId}';
      url = url.replace('{projectId}', params['projectId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = params['root'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 负荷曲线-典型负荷数据-获取地区标准曲线
   */
  static industryStandardModel(
    params: {
      /** 行业Code */
      industryCode: string;
      /**  */
      root?: object;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/load/industryStandardModel/{industryCode}';
      url = url.replace('{industryCode}', params['industryCode'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = params['root'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 负荷曲线-典型负荷数据-添加行业典型数据
   */
  static standard3(
    params: {
      /** 项目id */
      projectId: string;
      /**  */
      root?: object;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/load/standard/{projectId}';
      url = url.replace('{projectId}', params['projectId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['root'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 负荷曲线-离线负荷数据-下载模版
   */
  static template(
    params: {
      /** 项目id */
      projectId: string;
      /**  */
      root?: object;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/load/template/{projectId}';
      url = url.replace('{projectId}', params['projectId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['root'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 负荷曲线-离线负荷数据-数据诊断与治理
   */
  static upload(
    params: {
      /** 项目id */
      projectId: string;
      /** 模版上传文件 */
      filename?: any;
      /** 开始时间 */
      startTime?: string;
      /** 结束时间 */
      endTime?: string;
      /** 颗粒度 */
      level?: string;
      /** 变压器星系 */
      transformer?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/load/upload/{projectId}';
      url = url.replace('{projectId}', params['projectId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'multipart/form-data', url, options);

      let data = null;
      data = new FormData();
      if (params['filename']) {
        if (Object.prototype.toString.call(params['filename']) === '[object Array]') {
          for (const item of params['filename']) {
            data.append('filename', item as any);
          }
        } else {
          data.append('filename', params['filename'] as any);
        }
      }

      if (params['startTime']) {
        if (Object.prototype.toString.call(params['startTime']) === '[object Array]') {
          for (const item of params['startTime']) {
            data.append('startTime', item as any);
          }
        } else {
          data.append('startTime', params['startTime'] as any);
        }
      }

      if (params['endTime']) {
        if (Object.prototype.toString.call(params['endTime']) === '[object Array]') {
          for (const item of params['endTime']) {
            data.append('endTime', item as any);
          }
        } else {
          data.append('endTime', params['endTime'] as any);
        }
      }

      if (params['level']) {
        if (Object.prototype.toString.call(params['level']) === '[object Array]') {
          for (const item of params['level']) {
            data.append('level', item as any);
          }
        } else {
          data.append('level', params['level'] as any);
        }
      }

      if (params['transformer']) {
        if (Object.prototype.toString.call(params['transformer']) === '[object Array]') {
          for (const item of params['transformer']) {
            data.append('transformer', item as any);
          }
        } else {
          data.append('transformer', params['transformer'] as any);
        }
      }

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 电价方案-目录电价-查询菜单选项
   */
  static option(
    params: {
      /** 项目id */
      projectId: string;
      /** 用电类型：1 工商业；2 大工业 */
      userType?: string;
      /** 电压等级 */
      voltageLevel?: string;
      /** 电价库版本 */
      electricityPrice?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/borderElectricity/option/{projectId}';
      url = url.replace('{projectId}', params['projectId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        userType: params['userType'],
        voltageLevel: params['voltageLevel'],
        electricityPrice: params['electricityPrice']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 电价方案-目录电价-查询
   */
  static catalog(
    params: {
      /** 项目id */
      projectId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/borderElectricity/catalog/{projectId}';
      url = url.replace('{projectId}', params['projectId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 电价方案-目录电价-添加
   */
  static catalog1(
    params: {
      /** 项目id */
      projectId: string;
      /**  */
      root?: object;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/borderElectricity/catalog/{projectId}';
      url = url.replace('{projectId}', params['projectId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['root'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 电价方案-目录电价-编辑
   */
  static catalog2(
    params: {
      /** 项目id */
      projectId: string;
      /**  */
      root?: object;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/borderElectricity/catalog/{projectId}';
      url = url.replace('{projectId}', params['projectId'] + '');

      const configs: IRequestConfig = getConfigs('patch', 'application/json', url, options);

      let data = params['root'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 电价方案-目录电价-查询图表
   */
  static chart1(
    params: {
      /** 项目id */
      projectId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/borderElectricity/chart/{projectId}';
      url = url.replace('{projectId}', params['projectId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 查询自定义电list名称和id
   */
  static queryCustomNameIdList(
    params: {
      /** 项目id */
      projectId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/borderElectricity/queryCustomNameIdList/{projectId}';
      url = url.replace('{projectId}', params['projectId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 查询自定义电价详情
   */
  static queryCustomDetails(
    params: {
      /** 项目id */
      projectId: string;
      customId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/borderElectricity/queryCustom/{projectId}';
      url = url.replace('{projectId}', params['projectId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { customId: params['customId'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 查询标杆燃煤电价
   */
  static queryNormFireCoal(
    params: {
      /** 项目id */
      projectId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/borderElectricity/queryNormFireCoal/{projectId}';
      url = url.replace('{projectId}', params['projectId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 天气数据-获取天气下拉选项
   */
  static getCaseList(options: IRequestOptions = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/weather/getCaseList';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 天气数据-获取天气数据
   */
  static getShow(
    params: {
      /** 项目id */
      projectId: string;
      /** 天气下拉选项返回的value */
      weatherElement?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/weather/getShow/{projectId}';
      url = url.replace('{projectId}', params['projectId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { weatherElement: params['weatherElement'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 项目列表查询
   */
  static list(
    params: {
      /** 项目名 */
      name?: string;
      /** 技术方案数排序 0 不排序 1 asc 2 desc */
      tpnSort?: string;
      /** 财务方案数排序 0 不排序 1 asc 2 desc */
      fsnSort?: string;
      /** 创建时间排序 0 不排序 1 asc 2 desc */
      createTimeSort?: string;
      /** 更新排序 0 不排序 1 asc 2 desc */
      updateTimeSort?: string;
      /** 操作人 0 不排序 1 asc 2 desc */
      operateNameSort?: string;
      /** 默认1 */
      page?: string;
      /** 默认10 最大50 */
      size?: string;
      /** 创建者名字 */
      operateName?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/project/list';

      const configs: IRequestConfig = getConfigs('get', 'multipart/form-data', url, options);
      configs.params = {
        name: params['name'],
        tpnSort: params['tpnSort'],
        fsnSort: params['fsnSort'],
        createTimeSort: params['createTimeSort'],
        updateTimeSort: params['updateTimeSort'],
        operateNameSort: params['operateNameSort'],
        page: params['page'],
        size: params['size'],
        operateName: params['operateName']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 项目列表名称重命名
   */
  static rename(
    params: {
      /** 项目id */
      projectId: any;
      /**  */
      root?: object;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/project/rename/{projectId}';
      url = url.replace('{projectId}', params['projectId'] + '');

      const configs: IRequestConfig = getConfigs('patch', 'application/json', url, options);

      let data = params['root'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 项目列表新增项目
   */
  static add(
    params: {
      /**  */
      root?: object;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/project/add';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['root'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取项目详情
   */
  static get(
    params: {
      /** 项目id */
      projectId: any;
      /**  */
      authorization?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/project/get/{projectId}';
      url = url.replace('{projectId}', params['projectId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Authorization: params['authorization'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 项目列表删除项目
   */
  static delete(
    params: {
      /** 项目id */
      projectId: string;
      /**  */
      authorization?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/project/delete/{projectId}';
      url = url.replace('{projectId}', params['projectId'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'multipart/form-data', url, options);
      configs.params = { Authorization: params['authorization'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 用户概览-卡片统计
   */
  static assetCount(
    params: {
      /**  */
      authorization?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/overview/assetCount';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Authorization: params['authorization'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 用户概览-项目分布
   */
  static projectDistribute(
    params: {
      /**  */
      authorization?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/overview/projectDistribute';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Authorization: params['authorization'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 用户概览-活动日志
   */
  static getActivityLog(options: IRequestOptions = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/operate/getActivityLog';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 用户概览-操作记录
   */
  static getRecentlyLog(
    params: {
      /** PROJECT=项目;TECHNICAL=技术;FINANCE=财务;RESOURCES=资源估算 */
      type: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/operate/getRecentlyLog/{type}';
      url = url.replace('{type}', params['type'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class TechnologyService {
  /**
   * 技术方案列表
   */
  static list(
    params: {
      /** 项目id */
      projectId: string;
      /** 创建时间 0 不排序 1 asc 2 desc */
      createTimeSort?: string;
      /** 更新时间 0 不排序 1 asc 2 desc */
      updateTimeSort?: string;
      /** marked 星标 不传为全部  0:否 1:是 */
      marked?: string;
      /** locked 归档 不传为全部  0:否 1:是 */
      locked?: string;
      /** 装机容量区间 */
      installedCapacityMin?: string;
      /** 装机容量区间 */
      installedCapacityMax?: string;
      /** 日均发电区间 */
      dayAvgPowerGenerationMin?: string;
      /** 日均发电区间 */
      dayAvgPowerGenerationMax?: string;
      /** 年使用小时区间 */
      useHourYearMin?: string;
      /** 年使用小时区间 */
      useHourYearMax?: string;
      /** 自消纳百分比区间 */
      selfAbsorptionRateMin?: string;
      /** 自消纳百分比区间 */
      selfAbsorptionRateMax?: string;
      /** 电池容量区间 */
      batteryCapacityMin?: string;
      /** 电池容量区间 */
      batteryCapacityMax?: string;
      /** pcs功率区间 */
      pcsPowerMin?: string;
      /** pcs功率区间 */
      pcsPowerMax?: string;
      /** 日均放电区间 */
      dayAvgDischargeMin?: string;
      /** 日均放电区间 */
      dayAvgDischargeMax?: string;
      /** 日均充放电区间 */
      dayAvgChargeDischargeMin?: string;
      /** 日均充放电区间 */
      dayAvgChargeDischargeMax?: string;
      /** 默认1 */
      page?: string;
      /** 默认10 最大50 */
      size?: string;
      /** 装机容量 0 不排序 1 asc 2 desc */
      installedCapacitySort?: string;
      /** 日均发电 0 不排序 1 asc 2 desc */
      dayAvgPowerGenerationSort?: string;
      /** 年利用小时数 0 不排序 1 asc 2 desc */
      useHourYearSort?: string;
      /** 自消纳比 0 不排序 1 asc 2 desc */
      selfAbsorptionRateSort?: string;
      /** 电池容量 0 不排序 1 asc 2 desc */
      batteryCapacitySort?: string;
      /** psc总功率 0 不排序 1 asc 2 desc */
      pcsPowerSort?: string;
      /** 日均放电区间 0 不排序 1 asc 2 desc */
      dayAvgDischargeSort?: string;
      /** 日均充放 0 不排序 1 asc 2 desc */
      dayAvgChargeDischargeSort?: string;
      /** 星标 0 不排序 1 asc 2 desc */
      markedSort?: string;
      /** 归档 0 不排序 1 asc 2 desc */
      lockedSort?: string;
      /** 创建人 0 不排序 1 asc 2 desc */
      creatorNameSort?: string;
      /** 年发电小时数 排序0 不排序 1 asc 2 desc */
      annualGenerationHoursSort?: string;
      /** 年发电小时数 区间最小 */
      annualGenerationHoursMin?: string;
      /** 年发电小时数  区间最大 */
      annualGenerationHoursMax?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/technical/list/{projectId}';
      url = url.replace('{projectId}', params['projectId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'multipart/form-data', url, options);
      configs.params = {
        createTimeSort: params['createTimeSort'],
        updateTimeSort: params['updateTimeSort'],
        marked: params['marked'],
        locked: params['locked'],
        installedCapacityMin: params['installedCapacityMin'],
        installedCapacityMax: params['installedCapacityMax'],
        dayAvgPowerGenerationMin: params['dayAvgPowerGenerationMin'],
        dayAvgPowerGenerationMax: params['dayAvgPowerGenerationMax'],
        useHourYearMin: params['useHourYearMin'],
        useHourYearMax: params['useHourYearMax'],
        selfAbsorptionRateMin: params['selfAbsorptionRateMin'],
        selfAbsorptionRateMax: params['selfAbsorptionRateMax'],
        batteryCapacityMin: params['batteryCapacityMin'],
        batteryCapacityMax: params['batteryCapacityMax'],
        pcsPowerMin: params['pcsPowerMin'],
        pcsPowerMax: params['pcsPowerMax'],
        dayAvgDischargeMin: params['dayAvgDischargeMin'],
        dayAvgDischargeMax: params['dayAvgDischargeMax'],
        dayAvgChargeDischargeMin: params['dayAvgChargeDischargeMin'],
        dayAvgChargeDischargeMax: params['dayAvgChargeDischargeMax'],
        page: params['page'],
        size: params['size'],
        installedCapacitySort: params['installedCapacitySort'],
        dayAvgPowerGenerationSort: params['dayAvgPowerGenerationSort'],
        useHourYearSort: params['useHourYearSort'],
        selfAbsorptionRateSort: params['selfAbsorptionRateSort'],
        batteryCapacitySort: params['batteryCapacitySort'],
        pcsPowerSort: params['pcsPowerSort'],
        dayAvgDischargeSort: params['dayAvgDischargeSort'],
        dayAvgChargeDischargeSort: params['dayAvgChargeDischargeSort'],
        markedSort: params['markedSort'],
        lockedSort: params['lockedSort'],
        creatorNameSort: params['creatorNameSort'],
        annualGenerationHoursSort: params['annualGenerationHoursSort'],
        annualGenerationHoursMin: params['annualGenerationHoursMin'],
        annualGenerationHoursMax: params['annualGenerationHoursMax']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 技术方案重命名
   */
  static rename(
    params: {
      /** 技术方案id */
      technicalId: string;
      /**  */
      root?: object;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/technical/rename/{technicalId}';
      url = url.replace('{technicalId}', params['technicalId'] + '');

      const configs: IRequestConfig = getConfigs('patch', 'application/json', url, options);

      let data = params['root'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 删除技术方案
   */
  static technical(
    params: {
      /** 方案id */
      technicalId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/technical/{technicalId}';
      url = url.replace('{technicalId}', params['technicalId'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'multipart/form-data', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取技术方案详情
   */
  static technical1(
    params: {
      /**  */
      technicalId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/technical/{technicalId}';
      url = url.replace('{technicalId}', params['technicalId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 标记技术方案
   */
  static mark(
    params: {
      /** 方案id */
      technicalId: string;
      /**  */
      root?: object;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/technical/mark/{technicalId}';
      url = url.replace('{technicalId}', params['technicalId'] + '');

      const configs: IRequestConfig = getConfigs('patch', 'application/json', url, options);

      let data = params['root'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 技术方案归档
   */
  static lock(
    params: {
      /** 方案id */
      technicalId: string;
      /**  */
      root?: object;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/technical/lock/{technicalId}';
      url = url.replace('{technicalId}', params['technicalId'] + '');

      const configs: IRequestConfig = getConfigs('patch', 'application/json', url, options);

      let data = params['root'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 技术方案复制
   */
  static copy(
    params: {
      /** 方案id */
      technicalId: string;
      /**  */
      root?: object;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/technical/copy/{technicalId}';
      url = url.replace('{technicalId}', params['technicalId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['root'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 跨项目技术方案列表
   */
  static all(
    params: {
      /** 创建时间 0 不排序 1 asc 2 desc */
      createTimeSort?: string;
      /** 更新时间 0 不排序 1 asc 2 desc */
      updateTimeSort?: string;
      /** marked 星标 不传为全部  0:否 1:是 */
      marked?: string;
      /** locked 归档 不传为全部  0:否 1:是 */
      locked?: string;
      /** 默认1 */
      page?: string;
      /** 技术方案名称 */
      name?: string;
      /** 项目名称 */
      projectName?: string;
      /** 创建人行嘛 */
      creatorUserName?: string;
      /** 0:未计算 1:计算中 2:计算完成 3: 计算失败 4:计算失效 多个用逗号隔开 */
      calcStatus?: string;
      /** 分页 */
      size?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/technical/all';

      const configs: IRequestConfig = getConfigs('get', 'multipart/form-data', url, options);
      configs.params = {
        createTimeSort: params['createTimeSort'],
        updateTimeSort: params['updateTimeSort'],
        marked: params['marked'],
        locked: params['locked'],
        page: params['page'],
        name: params['name'],
        projectName: params['projectName'],
        creatorUserName: params['creatorUserName'],
        calcStatus: params['calcStatus'],
        size: params['size']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 新建技术方案工作台
   */
  static add(
    params: {
      /** 项目id */
      projectId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/technical/add/{projectId}';
      url = url.replace('{projectId}', params['projectId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取储能默认参数
   */
  static getDefault(options: IRequestOptions = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/technicalParamLibrary/0/getDefault';

      const configs: IRequestConfig = getConfigs('get', 'multipart/form-data', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取光伏默认参数
   */
  static getDefault1(options: IRequestOptions = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/technicalParamLibrary/1/getDefault';

      const configs: IRequestConfig = getConfigs('get', 'multipart/form-data', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 查询技术参数库的名称和id列表
   */
  static getNameIdList(options: IRequestOptions = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/technicalParamLibrary/getNameIdList';

      const configs: IRequestConfig = getConfigs('get', 'multipart/form-data', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取储能详情
   */
  static getInfo(
    params: {
      /** 参数库id */
      libraryId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/technicalParamLibrary/0/getInfo/{libraryId}';
      url = url.replace('{libraryId}', params['libraryId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'multipart/form-data', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取光伏详情
   */
  static getInfo1(
    params: {
      /** 参数库id */
      libraryId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/technicalParamLibrary/1/getInfo/{libraryId}';
      url = url.replace('{libraryId}', params['libraryId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'multipart/form-data', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取储能列表
   */
  static getList(options: IRequestOptions = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/technicalParamLibrary/0/getList';

      const configs: IRequestConfig = getConfigs('get', 'multipart/form-data', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取光伏列表
   */
  static getList1(options: IRequestOptions = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/technicalParamLibrary/1/getList';

      const configs: IRequestConfig = getConfigs('get', 'multipart/form-data', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 添加或修改储能参数库
   */
  static createOrEditBatch(
    params: {
      /** 短信验证码（不知道要不要） */
      code?: string;
      /**  */
      root?: any | null[];
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/technicalParamLibrary/0/createOrEditBatch';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = { code: params['code'] };
      let data = params['root'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 添加或修改光伏参数库
   */
  static createOrEditBatch1(
    params: {
      /** 短信验证码（不知道要不要） */
      code?: string;
      /**  */
      root?: any | null[];
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/technicalParamLibrary/1/createOrEditBatch';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = { code: params['code'] };
      let data = params['root'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 设置储能默认
   */
  static default(
    params: {
      /** 参数库id */
      libraryId: string;
      /**  */
      root?: object;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/technicalParamLibrary/0/default/{libraryId}';
      url = url.replace('{libraryId}', params['libraryId'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params['root'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 设置光伏默认
   */
  static default1(
    params: {
      /** 参数库id */
      libraryId: string;
      /**  */
      root?: object;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/technicalParamLibrary/1/default/{libraryId}';
      url = url.replace('{libraryId}', params['libraryId'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params['root'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class FinanceService {
  /**
   * 查询警告弹窗
   */
  static warnToast(
    params: {
      /** project_invest_id项目投资id；operation_cost_id运营成本id；finance_cost_id财务成本id；award_subsidy_id激励补贴id； */
      type?: string;
      /** id集合 */
      ids?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/financeLibrary/warnToast';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { type: params['type'], ids: params['ids'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 生成归档财务报告
   */
  static fillWord(
    params: {
      /**  */
      financeId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/word/fillWord/{financeId}';
      url = url.replace('{financeId}', params['financeId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取报告逐年发电量图表
   */
  static getYearYearPower(
    params: {
      /** 财务方案id */
      financeId?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/word/getYearYearPower';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { financeId: params['financeId'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 财务报告典型日发电曲线
   */
  static getActivePowerChart(
    params: {
      /** 财务id */
      financeId?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/word/getActivePowerChart';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { financeId: params['financeId'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 财务方案-参数库-添加或编辑激励补贴
   */
  static createOrUpdateBatch(
    params: {
      /** 短信验证码 */
      code?: string;
      /**  */
      root?: any | null[];
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/financeAwardSubsidy/createOrUpdateBatch';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = { code: params['code'] };
      let data = params['root'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 财务方案-参数库-激励补贴-编辑补贴激励
   */
  static financeAwardSubsidyDetail(
    params: {
      /** 补贴激励id */
      awardSubsidyDetailId: string;
      /**  */
      root?: object;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/financeAwardSubsidyDetail/{awardSubsidyDetailId}';
      url = url.replace('{awardSubsidyDetailId}', params['awardSubsidyDetailId'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params['root'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 财务方案-参数库-激励补贴-删除补贴激励
   */
  static financeAwardSubsidyDetail1(
    params: {
      /** 补贴激励id */
      awardSubsidyDetailId: string;
      /**  */
      root?: object;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/financeAwardSubsidyDetail/{awardSubsidyDetailId}';
      url = url.replace('{awardSubsidyDetailId}', params['awardSubsidyDetailId'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = params['root'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 财务方案-参数库-激励补贴-获取补贴激励详情
   */
  static info(
    params: {
      /** 补贴激励id */
      awardSubsidyDetailId: string;
      /**  */
      root?: object;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/financeAwardSubsidyDetail/info/{awardSubsidyDetailId}';
      url = url.replace('{awardSubsidyDetailId}', params['awardSubsidyDetailId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = params['root'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 财务方案-参数库-激励补贴-设置为默认
   */
  static default(
    params: {
      /** 激励补贴id */
      awardSubsidyId: string;
      /**  */
      root?: object;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/financeAwardSubsidy/default/{awardSubsidyId}';
      url = url.replace('{awardSubsidyId}', params['awardSubsidyId'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params['root'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 财务方案-参数库-获取激励补贴详情
   */
  static info1(
    params: {
      /** 激励补贴id */
      awardSubsidyId: string;
      /**  */
      root?: object;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/financeAwardSubsidy/info/{awardSubsidyId}';
      url = url.replace('{awardSubsidyId}', params['awardSubsidyId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = params['root'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 财务方案-参数库-获取财务成本详情
   */
  static info2(
    params: {
      /** 财务成本id */
      financeCostId: string;
      /**  */
      root?: object;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/financeCost/info/{financeCostId}';
      url = url.replace('{financeCostId}', params['financeCostId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = params['root'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 财务方案-参数库-获取运营成本详情
   */
  static info3(
    params: {
      /** 运营成本id */
      operationCostId: string;
      /**  */
      root?: object;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/financeOperationCost/info/{operationCostId}';
      url = url.replace('{operationCostId}', params['operationCostId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = params['root'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 财务方案-参数库-获取项目投资详情
   */
  static info4(
    params: {
      /** 项目投资id */
      projectInvestId: string;
      /**  */
      root?: object;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/financeProjectInvest/info/{projectInvestId}';
      url = url.replace('{projectInvestId}', params['projectInvestId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = params['root'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 财务方案-参数库-财务成本-获取当前用户下所有
   */
  static list(
    params: {
      /**  */
      root?: object;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/financeCost/list';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = params['root'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 财务方案-参数库-财务成本-设置为默认
   */
  static default1(
    params: {
      /** 财务成本id */
      financeCostId: string;
      /**  */
      root?: object;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/financeCost/default/{financeCostId}';
      url = url.replace('{financeCostId}', params['financeCostId'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params['root'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 财务方案-参数库-运营成本-获取当前用户下所有
   */
  static list1(
    params: {
      /**  */
      root?: object;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/financeOperationCost/list';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = params['root'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 财务方案-参数库-运营成本-设置为默认
   */
  static default2(
    params: {
      /** 运营成本id */
      operationCostId: string;
      /**  */
      root?: object;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/financeOperationCost/default/{operationCostId}';
      url = url.replace('{operationCostId}', params['operationCostId'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params['root'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 财务方案-参数库-项目投资-获取当前用户下所有
   */
  static list2(
    params: {
      /**  */
      root?: object;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/financeProjectInvest/list';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = params['root'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 财务方案-参数库-项目投资-设置为默认
   */
  static default3(
    params: {
      /** 项目投资id */
      projectInvestId: string;
      /**  */
      root?: object;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/financeProjectInvest/default/{projectInvestId}';
      url = url.replace('{projectInvestId}', params['projectInvestId'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params['root'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 财务方案-编辑参数组名称
   */
  static editName(
    params: {
      /** 参数组列表id */
      paramListId: string;
      /**  */
      root?: object;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/financeParamList/editName/{paramListId}';
      url = url.replace('{paramListId}', params['paramListId'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params['root'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 财务方案-获取参数库列表含有默认值
   */
  static getLibraryHasDefault(
    params: {
      /**  */
      root?: object;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/financeParamList/getLibraryHasDefault';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = params['root'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 财务方案word文档-文档图片上传(单个)
   */
  static uploadFile(
    params: {
      /** 财务方案id */
      financeId: string;
      /**  */
      root?: object;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/financeWord/uploadFile/{financeId}';
      url = url.replace('{financeId}', params['financeId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['root'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 财务方案word文档-文档图片上传(批量)
   */
  static uploadFileList(
    params: {
      /** 财务方案id */
      financeId: string;
      /**  */
      root?: any | null[];
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/financeWord/uploadFileList/{financeId}';
      url = url.replace('{financeId}', params['financeId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['root'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 财务管理列表
   */
  static list3(
    params: {
      /** 项目id */
      projectId: string;
      /** 创建时间 0 不排序 1 asc 2 desc */
      createTimeSort?: string;
      /** 更新时间 0 不排序 1 asc 2 desc */
      updateTimeSort?: string;
      /** marked 星标 不传为全部  0:否 1:是 */
      marked?: string;
      /** locked 归档 不传为全部  0:否 1:是 */
      locked?: string;
      /** 资本内部收益率 */
      innerYieldRateMin?: string;
      /** 资本内部收益率 */
      innerYieldRateMax?: string;
      /** 净现值  单位；万 */
      netPresentValueMin?: string;
      /** 净现值  单位；万 */
      netPresentValueMax?: string;
      /** 总投资收益率 */
      returnInvestmentMin?: string;
      /** 总投资收益率 */
      returnInvestmentMax?: string;
      /** 静态投资回收期 年 */
      staticInvestmentPaybackPeriodMin?: string;
      /** 静态投资回收期 年 */
      staticInvestmentPaybackPeriodMax?: string;
      /** 初始投资  单位；万 */
      initialInvestmentMin?: string;
      /** 初始投资  单位；万 */
      initialInvestmentMax?: string;
      /** 总收益  单位；万 */
      totalRevenueMin?: string;
      /** 总收益  单位；万 */
      totalRevenueMax?: string;
      /** 累计现金流 单位；万 */
      cumulativeCashFlowMin?: string;
      /** 累计现金流 单位；万 */
      cumulativeCashFlowMax?: string;
      /** 资本金净利润率 */
      netProfitMarginOfCapitalMin?: string;
      /** 资本金净利润率 */
      netProfitMarginOfCapitalMax?: string;
      /**  */
      page?: string;
      /**  */
      size?: string;
      /** 资本内部收益率 */
      innerYieldRateSort?: string;
      /** 净现值  */
      netPresentValueSort?: string;
      /** 资本金净利润率 */
      returnInvestmentSort?: string;
      /** 总投资收益率 */
      staticInvestmentPaybackPeriodSort?: string;
      /** 静态投资回收期 */
      initialInvestmentSort?: string;
      /** 初始投资 */
      totalRevenueSort?: string;
      /** 总收益 */
      cumulativeCashFlowSort?: string;
      /** 累计现金流 */
      netProfitMarginOfCapitalSort?: string;
      /** 星标 0 不排序 1 asc 2 desc */
      markedSort?: string;
      /** 归档 0 不排序 1 asc 2 desc */
      lockedSort?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/finance/list/{projectId}';
      url = url.replace('{projectId}', params['projectId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'multipart/form-data', url, options);
      configs.params = {
        createTimeSort: params['createTimeSort'],
        updateTimeSort: params['updateTimeSort'],
        marked: params['marked'],
        locked: params['locked'],
        innerYieldRateMin: params['innerYieldRateMin'],
        innerYieldRateMax: params['innerYieldRateMax'],
        netPresentValueMin: params['netPresentValueMin'],
        netPresentValueMax: params['netPresentValueMax'],
        returnInvestmentMin: params['returnInvestmentMin'],
        returnInvestmentMax: params['returnInvestmentMax'],
        staticInvestmentPaybackPeriodMin: params['staticInvestmentPaybackPeriodMin'],
        staticInvestmentPaybackPeriodMax: params['staticInvestmentPaybackPeriodMax'],
        initialInvestmentMin: params['initialInvestmentMin'],
        initialInvestmentMax: params['initialInvestmentMax'],
        totalRevenueMin: params['totalRevenueMin'],
        totalRevenueMax: params['totalRevenueMax'],
        cumulativeCashFlowMin: params['cumulativeCashFlowMin'],
        cumulativeCashFlowMax: params['cumulativeCashFlowMax'],
        netProfitMarginOfCapitalMin: params['netProfitMarginOfCapitalMin'],
        netProfitMarginOfCapitalMax: params['netProfitMarginOfCapitalMax'],
        page: params['page'],
        size: params['size'],
        innerYieldRateSort: params['innerYieldRateSort'],
        netPresentValueSort: params['netPresentValueSort'],
        returnInvestmentSort: params['returnInvestmentSort'],
        staticInvestmentPaybackPeriodSort: params['staticInvestmentPaybackPeriodSort'],
        initialInvestmentSort: params['initialInvestmentSort'],
        totalRevenueSort: params['totalRevenueSort'],
        cumulativeCashFlowSort: params['cumulativeCashFlowSort'],
        netProfitMarginOfCapitalSort: params['netProfitMarginOfCapitalSort'],
        markedSort: params['markedSort'],
        lockedSort: params['lockedSort']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 项目报告-列表
   */
  static list4(
    params: {
      /** 项目id */
      projectId: string;
      /** 文档名称 */
      wordName?: string;
      /** 技术方案名称 */
      technicalName?: string;
      /** 财务方案名称 */
      financeName?: string;
      /** 生成状态：0未生成；1生成中；2已生成；3生成失败 多个用","隔开 */
      status?: string;
      /** 0 不排序 1 asc 2 desc */
      createTimeSort?: string;
      /** 更新时间 0 不排序 1 asc 2 desc */
      updateTimeSort?: string;
      /** 生成时间 0 不排序 1 asc 2 desc */
      generateTimeSort?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/financeWord/list/{projectId}';
      url = url.replace('{projectId}', params['projectId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        wordName: params['wordName'],
        technicalName: params['technicalName'],
        financeName: params['financeName'],
        status: params['status'],
        createTimeSort: params['createTimeSort'],
        updateTimeSort: params['updateTimeSort'],
        generateTimeSort: params['generateTimeSort']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 项目报告-更名
   */
  static rename(
    params: {
      /** 项目文档id */
      wordId: string;
      /**  */
      root?: object;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/financeWord/rename/{wordId}';
      url = url.replace('{wordId}', params['wordId'] + '');

      const configs: IRequestConfig = getConfigs('patch', 'application/json', url, options);

      let data = params['root'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 项目报告-获取报告pdf文件tag
   */
  static getPdfTag(
    params: {
      /**  */
      wordId: string;
      /**  */
      root?: object;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/financeWord/getPdfTag/{wordId}';
      url = url.replace('{wordId}', params['wordId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = params['root'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 项目报告-获取文件内容
   */
  static readWord(
    params: {
      /**  */
      wordId: string;
      /**  */
      root?: object;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/financeWord/readWord/{wordId}';
      url = url.replace('{wordId}', params['wordId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = params['root'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 项目报告-获取文件内容pdf
   */
  static readPdf(
    params: {
      /**  */
      tag: string;
      /**  */
      root?: object;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/financeWord/readPdf/{tag}';
      url = url.replace('{tag}', params['tag'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = params['root'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 财务方案-参数组配置-财务成本-获取默认值
   */
  static getDefault(options: IRequestOptions = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/financeCost/getDefault';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 跨项目财务管理列表
   */
  static all(
    params: {
      /** 创建时间 0 不排序 1 asc 2 desc */
      createTimeSort?: string;
      /** 更新时间 0 不排序 1 asc 2 desc */
      updateTimeSort?: string;
      /** marked 星标 不传为全部  0:否 1:是 */
      marked?: string;
      /** locked 归档 不传为全部  0:否 1:是 */
      locked?: string;
      /**  */
      size?: string;
      /**  */
      page?: string;
      /** 创建人姓名 */
      creatorUserName?: string;
      /** 财务方案名称 */
      name?: string;
      /** 项目名称 */
      projectName?: string;
      /** 0:未计算 1:计算中 2:计算完成 3: 计算失败 4:计算失效 多个用逗号隔开 */
      calcStatus?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/finance/all';

      const configs: IRequestConfig = getConfigs('get', 'multipart/form-data', url, options);
      configs.params = {
        createTimeSort: params['createTimeSort'],
        updateTimeSort: params['updateTimeSort'],
        marked: params['marked'],
        locked: params['locked'],
        size: params['size'],
        page: params['page'],
        creatorUserName: params['creatorUserName'],
        name: params['name'],
        projectName: params['projectName'],
        calcStatus: params['calcStatus']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 修改财务管理方案名称
   */
  static rename1(
    params: {
      /** 方案名称 */
      financeId: string;
      /**  */
      root?: object;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/finance/rename/{financeId}';
      url = url.replace('{financeId}', params['financeId'] + '');

      const configs: IRequestConfig = getConfigs('patch', 'application/json', url, options);

      let data = params['root'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 删除财务方案
   */
  static finance(
    params: {
      /** 方案id */
      financeId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/finance/{financeId}';
      url = url.replace('{financeId}', params['financeId'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'multipart/form-data', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 财务方案标记
   */
  static mark(
    params: {
      /** 方案id */
      financeId: string;
      /**  */
      root?: object;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/finance/mark/{financeId}';
      url = url.replace('{financeId}', params['financeId'] + '');

      const configs: IRequestConfig = getConfigs('patch', 'application/json', url, options);

      let data = params['root'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 财务方案归档
   */
  static lock(
    params: {
      /** 方案id */
      financeId: string;
      /**  */
      root?: object;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/finance/lock/{financeId}';
      url = url.replace('{financeId}', params['financeId'] + '');

      const configs: IRequestConfig = getConfigs('patch', 'application/json', url, options);

      let data = params['root'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 财务方案-方案列表-获取透视分析查询类型
   */
  static getPerspectiveAnalyseQueryType(options: IRequestOptions = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/finance/getPerspectiveAnalyseQueryType';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 财务方案-方案列表-透视分析
   */
  static perspectiveAnalyse(
    params: {
      /** 项目id */
      projectId: string;
      /** 选择查看指标 */
      queryType?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/finance/perspectiveAnalyse/{projectId}';
      url = url.replace('{projectId}', params['projectId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { queryType: params['queryType'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 财务方案-方案列表-计算
   */
  static calculate(
    params: {
      /** 项目id */
      projectId: string;
      /**  */
      root?: object;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/finance/calculate/{projectId}';
      url = url.replace('{projectId}', params['projectId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['root'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 财务方案-方案列表-批量计算
   */
  static batchCalculate(
    params: {
      /**  */
      financeId: string;
      /**  */
      root?: object;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/finance/batchCalculate/{financeId}';
      url = url.replace('{financeId}', params['financeId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['root'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 财务方案-规划运行方案选择-关联技术方案
   */
  static ref(
    params: {
      /** 项目id */
      projectId: string;
      /**  */
      root?: object;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/financeSchemePlan/ref/{projectId}';
      url = url.replace('{projectId}', params['projectId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['root'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 财务方案-选择规划运行方案-查询关联的技术方案
   */
  static queryRef(
    params: {
      /** 项目id */
      projectId: string;
      /** 技术方案id列表 */
      root?: any | null[];
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/financeSchemePlan/queryRef/{projectId}';
      url = url.replace('{projectId}', params['projectId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = params['root'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 财务方案-选择规划运行方案-查询方案列表
   */
  static list5(
    params: {
      /** 项目id */
      projectId: string;
      /** 创建时间 0 不排序 1 asc 2 desc */
      createTimeSort?: string;
      /** 更新时间 0 不排序 1 asc 2 desc */
      updateTimeSort?: string;
      /** 装机容量区间-最小 */
      installedCapacityMin?: string;
      /** 装机容量区间-最大 */
      installedCapacityMax?: string;
      /** 日均发电区间 */
      dayAvgPowerGenerationMin?: string;
      /** 日均发电区间 */
      dayAvgPowerGenerationMax?: string;
      /** 年使用小时区间 */
      useHourYearMin?: string;
      /** 年使用小时区间 */
      useHourYearMax?: string;
      /** 自消纳百分比区间 */
      selfAbsorptionRateMin?: string;
      /** 自消纳百分比区间 */
      selfAbsorptionRateMax?: string;
      /** 电池容量区间 */
      batteryCapacityMin?: string;
      /** 电池容量区间 */
      batteryCapacityMax?: string;
      /** 日均放电区间 */
      dayAvgDischargeMin?: string;
      /** 日均放电区间 */
      dayAvgDischargeMax?: string;
      /** 日均充放电区间 */
      dayAvgChargeDischargeMin?: string;
      /** 日均充放电区间 */
      dayAvgChargeDischargeMax?: string;
      /** 装机容量 0 不排序 1 asc 2 desc */
      installedCapacitySort?: string;
      /** 日均发电 0 不排序 1 asc 2 desc */
      dayAvgPowerGenerationSort?: string;
      /** 年利用小时数 0 不排序 1 asc 2 desc */
      useHourYearSort?: string;
      /** 自消纳比 0 不排序 1 asc 2 desc */
      selfAbsorptionRateSort?: string;
      /** 电池容量 0 不排序 1 asc 2 desc */
      batteryCapacitySort?: string;
      /** psc总功率 0 不排序 1 asc 2 desc */
      pcsPowerSort?: string;
      /** 日均放电区间 0 不排序 1 asc 2 desc */
      dayAvgDischargeSort?: string;
      /** 日均充放 0 不排序 1 asc 2 desc */
      dayAvgChargeDischargeSort?: string;
      /** pcs功率区间 */
      pcsPowerMin?: string;
      /** pcs功率区间 */
      pcsPowerMax?: string;
      /** 年发电小时数 0 不排序 1 asc 2 desc */
      annualGenerationHoursSort?: string;
      /** 年使用小时区间 */
      annualGenerationHoursMin?: string;
      /** 年使用小时区间 */
      annualGenerationHoursMax?: string;
      /** 技术方案id列表 */
      root?: any | null[];
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/financeSchemePlan/list/{projectId}';
      url = url.replace('{projectId}', params['projectId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        createTimeSort: params['createTimeSort'],
        updateTimeSort: params['updateTimeSort'],
        installedCapacityMin: params['installedCapacityMin'],
        installedCapacityMax: params['installedCapacityMax'],
        dayAvgPowerGenerationMin: params['dayAvgPowerGenerationMin'],
        dayAvgPowerGenerationMax: params['dayAvgPowerGenerationMax'],
        useHourYearMin: params['useHourYearMin'],
        useHourYearMax: params['useHourYearMax'],
        selfAbsorptionRateMin: params['selfAbsorptionRateMin'],
        selfAbsorptionRateMax: params['selfAbsorptionRateMax'],
        batteryCapacityMin: params['batteryCapacityMin'],
        batteryCapacityMax: params['batteryCapacityMax'],
        dayAvgDischargeMin: params['dayAvgDischargeMin'],
        dayAvgDischargeMax: params['dayAvgDischargeMax'],
        dayAvgChargeDischargeMin: params['dayAvgChargeDischargeMin'],
        dayAvgChargeDischargeMax: params['dayAvgChargeDischargeMax'],
        installedCapacitySort: params['installedCapacitySort'],
        dayAvgPowerGenerationSort: params['dayAvgPowerGenerationSort'],
        useHourYearSort: params['useHourYearSort'],
        selfAbsorptionRateSort: params['selfAbsorptionRateSort'],
        batteryCapacitySort: params['batteryCapacitySort'],
        pcsPowerSort: params['pcsPowerSort'],
        dayAvgDischargeSort: params['dayAvgDischargeSort'],
        dayAvgChargeDischargeSort: params['dayAvgChargeDischargeSort'],
        pcsPowerMin: params['pcsPowerMin'],
        pcsPowerMax: params['pcsPowerMax'],
        annualGenerationHoursSort: params['annualGenerationHoursSort'],
        annualGenerationHoursMin: params['annualGenerationHoursMin'],
        annualGenerationHoursMax: params['annualGenerationHoursMax']
      };
      let data = params['root'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 财务方案-获取参数库列表
   */
  static getLibrary(
    params: {
      /**  */
      root?: object;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/financeParamList/getLibrary';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = params['root'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 财务方案-创建参数组配置
   */
  static financeParamList(
    params: {
      /** 项目id */
      projectId: string;
      /**  */
      root?: object;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/financeParamList/{projectId}';
      url = url.replace('{projectId}', params['projectId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['root'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 财务方案-删除参数组配置
   */
  static financeParamList1(
    params: {
      /** 参数组列表id */
      paramListId: string;
      /**  */
      root?: object;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/financeParamList/{paramListId}';
      url = url.replace('{paramListId}', params['paramListId'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = params['root'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 财务方案-编辑参数组列表
   */
  static financeParamList2(
    params: {
      /** 参数组列表id */
      paramListId: string;
      /**  */
      root?: object;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/financeParamList/{paramListId}';
      url = url.replace('{paramListId}', params['paramListId'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params['root'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 财务方案-获取参数组列表
   */
  static list6(
    params: {
      /** 项目id */
      projectId: string;
      /**  */
      root?: object;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/financeParamList/list/{projectId}';
      url = url.replace('{projectId}', params['projectId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = params['root'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 财务方案-参数组配置-运营成本-获取默认值
   */
  static getDefault1(options: IRequestOptions = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/financeOperationCost/getDefault';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 财务方案-参数库-项目投资-获取默认值
   */
  static getDefault2(options: IRequestOptions = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/financeProjectInvest/getDefault';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 财务方案-参数库-创建项目投资
   */
  static financeProjectInvest(
    params: {
      /**  */
      root?: object;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/financeProjectInvest';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['root'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 财务方案-参数库-删除项目投资
   */
  static financeProjectInvest1(
    params: {
      /**  */
      projectInvestId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/financeProjectInvest/{projectInvestId}';
      url = url.replace('{projectInvestId}', params['projectInvestId'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'multipart/form-data', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 财务方案-参数库-拷贝项目投资
   */
  static copy(
    params: {
      /** 项目投资id */
      projectInvestId: string;
      /**  */
      root?: object;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/financeProjectInvest/copy/{projectInvestId}';
      url = url.replace('{projectInvestId}', params['projectInvestId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['root'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 财务方案-参数库-批量保存或编辑项目投资
   */
  static createOrEditBatch(
    params: {
      /** 短信验证码 */
      code?: string;
      /**  */
      root?: any | null[];
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/financeProjectInvest/createOrEditBatch';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = { code: params['code'] };
      let data = params['root'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 财务方案-参数库-创建运营成本
   */
  static financeOperationCost(
    params: {
      /**  */
      root?: object;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/financeOperationCost';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['root'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 财务方案-参数库-删除运营成本
   */
  static financeOperationCost1(
    params: {
      /** 运营成本id */
      operationCostId: string;
      /**  */
      root?: object;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/financeOperationCost/{operationCostId}';
      url = url.replace('{operationCostId}', params['operationCostId'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = params['root'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 财务方案-参数库-拷贝运营成本
   */
  static copy1(
    params: {
      /** 运营成本id */
      operationCostId: string;
      /**  */
      root?: object;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/financeOperationCost/copy/{operationCostId}';
      url = url.replace('{operationCostId}', params['operationCostId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['root'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 财务方案-参数库-批量保存或编辑运营成本
   */
  static createOrEditBatch1(
    params: {
      /** 短信验证码 */
      code?: string;
      /**  */
      root?: any | null[];
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/financeOperationCost/createOrEditBatch';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = { code: params['code'] };
      let data = params['root'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 财务方案-参数库-创建财务成本
   */
  static financeCost(
    params: {
      /**  */
      root?: object;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/financeCost';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['root'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 财务方案-参数库-删除财务成本
   */
  static financeCost1(
    params: {
      /** 财务成本id */
      financeCostId: string;
      /**  */
      root?: object;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/financeCost/{financeCostId}';
      url = url.replace('{financeCostId}', params['financeCostId'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = params['root'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 财务方案-参数库-拷贝财务成本
   */
  static copy2(
    params: {
      /** 财务成本id */
      financeCostId: string;
      /**  */
      root?: object;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/financeCost/copy/{financeCostId}';
      url = url.replace('{financeCostId}', params['financeCostId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['root'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 财务方案-参数库-批量保存或编辑财务成本
   */
  static createOrEditBatch2(
    params: {
      /** 短信验证码 */
      code?: string;
      /**  */
      root?: any | null[];
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/financeCost/createOrEditBatch';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = { code: params['code'] };
      let data = params['root'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 财务方案-参数库-创建激励补贴
   */
  static financeAwardSubsidy(
    params: {
      /**  */
      root?: object;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/financeAwardSubsidy';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['root'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 财务方案-参数库-删除激励补贴
   */
  static financeAwardSubsidy1(
    params: {
      /** 激励补贴id */
      awardSubsidyId: string;
      /**  */
      root?: object;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/financeAwardSubsidy/{awardSubsidyId}';
      url = url.replace('{awardSubsidyId}', params['awardSubsidyId'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = params['root'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 财务方案-参数库-编辑激励补贴
   */
  static financeAwardSubsidy2(
    params: {
      /** 激励补贴id */
      awardSubsidyId: string;
      /**  */
      root?: object;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/financeAwardSubsidy/{awardSubsidyId}';
      url = url.replace('{awardSubsidyId}', params['awardSubsidyId'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params['root'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 财务方案-参数库-拷贝激励补贴
   */
  static copy3(
    params: {
      /** 激励补贴id */
      awardSubsidyId: string;
      /**  */
      root?: object;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/financeAwardSubsidy/copy/{awardSubsidyId}';
      url = url.replace('{awardSubsidyId}', params['awardSubsidyId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['root'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 财务方案-参数库-激励补贴-获取补贴激励名称
   */
  static detailName(
    params: {
      /** 激励补贴id */
      awardSubsidyId: string;
      /**  */
      root?: object;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/financeAwardSubsidyDetail/detailName/{awardSubsidyId}';
      url = url.replace('{awardSubsidyId}', params['awardSubsidyId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = params['root'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 财务方案-参数库-激励补贴-创建补贴激励
   */
  static financeAwardSubsidyDetail2(
    params: {
      /** 激励补贴id */
      awardSubsidyId: string;
      /**  */
      root?: object;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/financeAwardSubsidyDetail/{awardSubsidyId}';
      url = url.replace('{awardSubsidyId}', params['awardSubsidyId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['root'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 财务方案-参数库-激励补贴-查询补贴激励列表
   */
  static getDetailList(
    params: {
      /**  */
      root?: object;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/financeAwardSubsidyDetail/getDetailList';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = params['root'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class ProjectWorkbenchService {
  /**
   * 修改储能容量
   */
  static updateEnergyStorageCapacity(
    params: {
      /**  */
      technologyId: string;
      /**  */
      root?: object;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/technical/workbench/updateEnergyStorageCapacity/{technologyId}';
      url = url.replace('{technologyId}', params['technologyId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['root'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 修改储能拓扑
   */
  static updateEnergyStorageTopology(
    params: {
      /**  */
      technologyId: string;
      /**  */
      root?: object;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/technical/workbench/updateEnergyStorageTopology/{technologyId}';
      url = url.replace('{technologyId}', params['technologyId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['root'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 修改储能电池信息
   */
  static updateBattery(
    params: {
      /**  */
      technologyId: string;
      /**  */
      root?: object;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/technical/workbench/updateBattery/{technologyId}';
      url = url.replace('{technologyId}', params['technologyId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['root'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 修改储能系统效率属性
   */
  static updateEnergyStorageSystemEfficiency(
    params: {
      /** 方案id */
      technologyId: string;
      /**  */
      root?: object;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/technical/workbench/updateEnergyStorageSystemEfficiency/{technologyId}';
      url = url.replace('{technologyId}', params['technologyId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['root'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 修改储能运行策略
   */
  static updateRevenueSharing(
    params: {
      /**  */
      technologyId: string;
      /**  */
      root?: object;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/technical/workbench/updateRevenueSharing/{technologyId}';
      url = url.replace('{technologyId}', params['technologyId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['root'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 修改光伏拓扑数据
   */
  static updatePhotovoltaicTopology(
    params: {
      /** 技术方案id */
      technologyId: string;
      /**  */
      root?: object;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/technical/workbench/updatePhotovoltaicTopology/{technologyId}';
      url = url.replace('{technologyId}', params['technologyId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['root'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 修改光伏系统参数
   */
  static updatePhotovoltaicSystemEfficiency(
    params: {
      /**  */
      technologyId: string;
      /**  */
      root?: object;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/technical/workbench/updatePhotovoltaicSystemEfficiency/{technologyId}';
      url = url.replace('{technologyId}', params['technologyId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['root'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 修改地图相关配置
   */
  static updateRoofModule(
    params: {
      /**  */
      technologyId: string;
      /**  */
      root?: object;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/technical/workbench/updateRoofModule/{technologyId}';
      url = url.replace('{technologyId}', params['technologyId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['root'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 修改技术方案名称
   */
  static updateTechnologyName(
    params: {
      /** 技术方案id */
      technologyId: string;
      /**  */
      root?: object;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/technical/workbench/updateTechnologyName/{technologyId}';
      url = url.replace('{technologyId}', params['technologyId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['root'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 修改逆变器
   */
  static updateInverterModule(
    params: {
      /**  */
      technologyId: string;
      /**  */
      root?: object;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/technical/workbench/updateInverterModule/{technologyId}';
      url = url.replace('{technologyId}', params['technologyId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['root'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 修改需量限制
   */
  static updateDemand(
    params: {
      /** 方案id */
      technicalProposalId: string;
      /**  */
      root?: any | null[];
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/technical/workbench/updateDemand/{technicalProposalId}';
      url = url.replace('{technicalProposalId}', params['technicalProposalId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['root'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 切换技术方案参数库方案
   */
  static checkParamLibrary(
    params: {
      /** 技术方案id */
      technicalProposalId: string;
      /**  */
      root?: object;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/technical/workbench/checkParamLibrary/{technicalProposalId}';
      url = url.replace('{technicalProposalId}', params['technicalProposalId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['root'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 增加光伏
   */
  static addPhotovoltaic(
    params: {
      /** 技术方案id add/list 接口有返回 */
      technologyId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/technical/workbench/addPhotovoltaic/{technologyId}';
      url = url.replace('{technologyId}', params['technologyId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 技术方案工作台详情
   */
  static workbench(
    params: {
      /** 技术方案id */
      technicalProposalId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/technical/workbench/{technicalProposalId}';
      url = url.replace('{technicalProposalId}', params['technicalProposalId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 技术方案计算
   */
  static calcTechnical(
    params: {
      /**  */
      technologyId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/technical/workbench/calcTechnical/{technologyId}';
      url = url.replace('{technologyId}', params['technologyId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 新增储能
   */
  static addEnergyStorage(
    params: {
      /** 技术方案id */
      technologyId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/technical/workbench/addEnergyStorage/{technologyId}';
      url = url.replace('{technologyId}', params['technologyId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 更新光伏设置-其他信息-首年功率衰减率/逐年功率衰减率
   */
  static updatePhotovoltaicOtherInfo(
    params: {
      /** 技术方案id */
      technicalProposalId: string;
      /**  */
      root?: object;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/technical/workbench/updatePhotovoltaicOtherInfo/{technicalProposalId}';
      url = url.replace('{technicalProposalId}', params['technicalProposalId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['root'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 查询储能系统效率
   */
  static getEnergyStorageSystemEfficiency(
    params: {
      /**  */
      technologyId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/technical/workbench/getEnergyStorageSystemEfficiency/{technologyId}';
      url = url.replace('{technologyId}', params['technologyId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 查询屋顶信息
   */
  static getRoofModuleInfo(
    params: {
      /**  */
      technologyId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/technical/workbench/getRoofModuleInfo/{technologyId}';
      url = url.replace('{technologyId}', params['technologyId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 根据屋顶id列表查询屋顶坐标
   */
  static getNearbyRoofByIds(
    params: {
      /**  */
      root?: object;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/roof/getNearbyRoofByIds';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['root'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 移除储能
   */
  static removeEnergyStorage(
    params: {
      /**  */
      technologyId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/technical/workbench/removeEnergyStorage/{technologyId}';
      url = url.replace('{technologyId}', params['technologyId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 移除光伏
   */
  static removePhotovoltaic(
    params: {
      /** 技术方案id */
      technologyId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/technical/workbench/removePhotovoltaic/{technologyId}';
      url = url.replace('{technologyId}', params['technologyId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取储能系统优选属性
   */
  static getAutoCheckOpt(
    params: {
      /**  */
      technologyId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/technical/workbench/getAutoCheckOpt/{technologyId}';
      url = url.replace('{technologyId}', params['technologyId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取光伏发电验证报告
   */
  static photovoltaicValidReport(options: IRequestOptions = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/technical/workbench/photovoltaicValidReport';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取光伏模组列表
   */
  static modelList(
    params: {
      /** 型号 */
      pvModuleName?: string;
      /** 品牌 */
      manufacturer?: string;
      /** 电池片类型
       * 多晶
       * 单晶
       * Thin Film */
      cellType?: string;
      /** stc最大功率 区间 */
      stcMin?: string;
      /** stc最大功率 区间 */
      stcMax?: string;
      /** ptc最大功率 区间 */
      ptcMin?: string;
      /** ptc最大功率 区间 */
      ptcMax?: string;
      /** 单面/双面 */
      bifacial?: string;
      /** 开路电压 区间 */
      openVoltageMin?: string;
      /** 开路电压 区间 */
      openVoltageMax?: string;
      /** 1 */
      page?: string;
      /** 100 */
      size?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/workbench/photovoltaic/modelList';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        pvModuleName: params['pvModuleName'],
        manufacturer: params['manufacturer'],
        cellType: params['cellType'],
        stcMin: params['stcMin'],
        stcMax: params['stcMax'],
        ptcMin: params['ptcMin'],
        ptcMax: params['ptcMax'],
        bifacial: params['bifacial'],
        openVoltageMin: params['openVoltageMin'],
        openVoltageMax: params['openVoltageMax'],
        page: params['page'],
        size: params['size']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取光伏系统效率详细参数
   */
  static getPhotovoltaicSystemEfficiency(
    params: {
      /**  */
      technologyId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/technical/workbench/getPhotovoltaicSystemEfficiency/{technologyId}';
      url = url.replace('{technologyId}', params['technologyId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取技术方案详情（编辑）
   */
  static workbench1(
    params: {
      /**  */
      technologyId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/technical/workbench/{technologyId}';
      url = url.replace('{technologyId}', params['technologyId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取拓扑设置-参考电价下拉选项
   */
  static getReferencePriceOption(
    params: {
      /** 技术方案id */
      technicalProposalId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/technical/workbench/getReferencePriceOption/{technicalProposalId}';
      url = url.replace('{technicalProposalId}', params['technicalProposalId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取电池列表
   */
  static batteryList(options: IRequestOptions = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/workbench/energyStorage/batteryList';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取逆变器列表
   */
  static inverterList(
    params: {
      /** 逆变器名字 */
      inverterName?: string;
      /** 品牌 */
      manufacturer?: string;
      /** 最大支流功率区间 */
      maxDcPowerMin?: string;
      /** 最大直流功率区间 */
      maxDcPowerMax?: string;
      /** 最大交流功率区间 */
      maxAcPowerMin?: string;
      /** 最大交流功率区间 */
      maxAcPowerMax?: string;
      /** 1 */
      page?: string;
      /** 10 */
      size?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/workbench/photovoltaic/inverterList';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        inverterName: params['inverterName'],
        manufacturer: params['manufacturer'],
        maxDcPowerMin: params['maxDcPowerMin'],
        maxDcPowerMax: params['maxDcPowerMax'],
        maxAcPowerMin: params['maxAcPowerMin'],
        maxAcPowerMax: params['maxAcPowerMax'],
        page: params['page'],
        size: params['size']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取项目附近屋顶坐标数据
   */
  static getNearbyRoof(
    params: {
      /**  */
      technologyId: string;
      /** 范围 单位 公里 */
      distance?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/technical/workbench/getNearbyRoof/{technologyId}';
      url = url.replace('{technologyId}', params['technologyId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { distance: params['distance'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取默认需量限制
   */
  static getDefaultDemand(
    params: {
      /** 方案id */
      technicalProposalId: string;
      /**  */
      root?: any | null[];
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/technical/workbench/getDefaultDemand/{technicalProposalId}';
      url = url.replace('{technicalProposalId}', params['technicalProposalId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = params['root'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 计算屋顶获取可安装模组
   */
  static calcRoofModuleNum(
    params: {
      /**  */
      technologyId: string;
      /**  */
      root?: object;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/technical/workbench/calcRoofModuleNum/{technologyId}';
      url = url.replace('{technologyId}', params['technologyId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['root'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 设置光伏模组
   */
  static updatePhotovoltaicModule(
    params: {
      /** 技术方案id */
      technologyId: string;
      /**  */
      root?: object;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/technical/workbench/updatePhotovoltaicModule/{technologyId}';
      url = url.replace('{technologyId}', params['technologyId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['root'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class EvaluationService {
  /**
   * 地区筛选栏接口
   */
  static regionCode(options: IRequestOptions = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/evaluation/regionCode';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 屋顶扫描
   */
  static getRoof(
    params: {
      /** 名字 */
      name?: string;
      /** 地址 */
      address?: string;
      /** 地区code */
      regionCode?: string;
      /** 面积 */
      areaMin?: string;
      /**  */
      areaMax?: string;
      /**  */
      areaSort?: string;
      /** 时间 取选项的value 多个逗号隔开  必须选到子项选父项为子项全选 */
      times?: string;
      /**  */
      timeSort?: string;
      /**  */
      yearTotalIrradianceMin?: string;
      /**  */
      yearTotalIrradianceMax?: string;
      /**  */
      yearTotalIrradianceSort?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/evaluation/getRoof';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        name: params['name'],
        address: params['address'],
        regionCode: params['regionCode'],
        areaMin: params['areaMin'],
        areaMax: params['areaMax'],
        areaSort: params['areaSort'],
        times: params['times'],
        timeSort: params['timeSort'],
        yearTotalIrradianceMin: params['yearTotalIrradianceMin'],
        yearTotalIrradianceMax: params['yearTotalIrradianceMax'],
        yearTotalIrradianceSort: params['yearTotalIrradianceSort']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 屋顶扫描时间条件筛选
   */
  static getRoofQueryParam(
    params: {
      /** 地区code */
      regionCode?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/evaluation/getRoofQueryParam';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { regionCode: params['regionCode'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 根据左下右上坐标获取屋顶数据
   */
  static getRoofByGeo(
    params: {
      /** 地区code */
      regionCode?: string;
      /** 左下经度 */
      leftBottomLng?: string;
      /** 左下纬度 */
      leftBottomLat?: string;
      /** 右上纬度 */
      rightTopLng?: string;
      /** 右上经度 */
      rightTopLat?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/evaluation/getRoofByGeo';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        regionCode: params['regionCode'],
        leftBottomLng: params['leftBottomLng'],
        leftBottomLat: params['leftBottomLat'],
        rightTopLng: params['rightTopLng'],
        rightTopLat: params['rightTopLat']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 根据左下右上坐标获取网格屋顶信息
   */
  static getGridData(
    params: {
      /** 级别 0.01 0.02 0.05 0.1 0.2 0.5 1 其中0.01 0.02 0.05 数据都很多 建议用坐标 其他可以直接中国的左下右上 */
      level?: string;
      /** 左下经度 */
      leftBottomLng?: string;
      /** 左下纬度 */
      leftBottomLat?: string;
      /** 右上纬度 */
      rightTopLng?: string;
      /** 右上经度 */
      rightTopLat?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/evaluation/getGridData';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        level: params['level'],
        leftBottomLng: params['leftBottomLng'],
        leftBottomLat: params['leftBottomLat'],
        rightTopLng: params['rightTopLng'],
        rightTopLat: params['rightTopLat']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取全国市、区资源详情（不带条件）
   */
  static regionData(
    params: {
      /** 地区等级，province,city,district 省市区 逗号隔开 可以传单个 也可以不传 默认所有 */
      level?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/evaluation/regionData';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { level: params['level'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取全国市、区资源详情（带条件）
   */
  static list(
    params: {
      /** 名字 */
      label?: string;
      /** 省份code 逗号隔开 */
      adcodes?: string;
      /** 区域面积 最小 */
      regionAreaMin?: string;
      /** 区域面积 最大 */
      regionAreaMax?: string;
      /** 区域面积 排序  0 不排序 1 asc 2 desc */
      regionAreaSort?: string;
      /** 年辐照 */
      yearTotalIrradianceMin?: string;
      /**  */
      yearTotalIrradianceMax?: string;
      /**  */
      yearTotalIrradianceSort?: string;
      /** 屋顶数量 */
      roofCountMin?: string;
      /**  */
      roofCountMax?: string;
      /**  */
      roofCountSort?: string;
      /** 屋顶面积 */
      roofAreaMin?: string;
      /**  */
      roofAreaMax?: string;
      /**  */
      roofAreaSort?: string;
      /** 屋顶面积平均值 */
      avgRoofAreaMin?: string;
      /**  */
      avgRoofAreaMax?: string;
      /**  */
      avgRoofAreaSort?: string;
      /** 覆盖率 */
      roofRatioMin?: string;
      /**  */
      roofRatioMax?: string;
      /**  */
      roofRatioSort?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/evaluation/list';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        label: params['label'],
        adcodes: params['adcodes'],
        regionAreaMin: params['regionAreaMin'],
        regionAreaMax: params['regionAreaMax'],
        regionAreaSort: params['regionAreaSort'],
        yearTotalIrradianceMin: params['yearTotalIrradianceMin'],
        yearTotalIrradianceMax: params['yearTotalIrradianceMax'],
        yearTotalIrradianceSort: params['yearTotalIrradianceSort'],
        roofCountMin: params['roofCountMin'],
        roofCountMax: params['roofCountMax'],
        roofCountSort: params['roofCountSort'],
        roofAreaMin: params['roofAreaMin'],
        roofAreaMax: params['roofAreaMax'],
        roofAreaSort: params['roofAreaSort'],
        avgRoofAreaMin: params['avgRoofAreaMin'],
        avgRoofAreaMax: params['avgRoofAreaMax'],
        avgRoofAreaSort: params['avgRoofAreaSort'],
        roofRatioMin: params['roofRatioMin'],
        roofRatioMax: params['roofRatioMax'],
        roofRatioSort: params['roofRatioSort']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 资源添加
   */
  static add(
    params: {
      /**  */
      root?: any | null[];
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/resource/add';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['root'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class ResourceService {
  /**
   * 资源列表
   */
  static list(
    params: {
      /** 资源名 */
      name?: string;
      /** 类型 1:屋顶 */
      type?: string;
      /** 地区码 */
      regionCode?: string;
      /** 创建时间 0 不排序 1:asc 2:desc */
      createdTimeSort?: string;
      /** 创建者名字 */
      createdName?: string;
      /**  */
      page?: string;
      /**  */
      size?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/resource/list';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        name: params['name'],
        type: params['type'],
        regionCode: params['regionCode'],
        createdTimeSort: params['createdTimeSort'],
        createdName: params['createdName'],
        page: params['page'],
        size: params['size']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 资源重命名
   */
  static rename(
    params: {
      /**  */
      root?: object;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/resource/rename';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['root'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 资源删除
   */
  static remove(
    params: {
      /** 资源id */
      id: string;
      /**  */
      root?: object;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/resource/remove/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['root'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 资源详情
   */
  static info(
    params: {
      /** 资源id */
      id: string;
      /**  */
      root?: any | null[];
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/resource/info/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = params['root'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 资源估算地区筛选条件列表
   */
  static getQueryRegion(options: IRequestOptions = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/resource/getQueryRegion';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 光伏发电小工具_默认值
   */
  static getDefaultCalculate(options: IRequestOptions = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/resource/getDefaultCalculate';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 光伏发电小工具_装机容量、装机面积
   */
  static calculateCapacityOrArea(
    params: {
      /** 装机面积 */
      availableArea?: string;
      /** pvCapacity */
      pvCapacity?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/resource/calculateCapacityOrArea';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { availableArea: params['availableArea'], pvCapacity: params['pvCapacity'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 光伏发电小工具_计算
   */
  static calculatePvTool(
    params: {
      /**  */
      root?: object;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/resource/calculatePvTool';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['root'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 光伏发电小工具_计算年总辐照
   */
  static calculateSolarRadiation(
    params: {
      /** 经度 */
      longitude?: string;
      /** 纬度 */
      latitude?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/resource/calculateSolarRadiation';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { longitude: params['longitude'], latitude: params['latitude'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class AccountControllerService {
  /**
   * 新增用户
   */
  static add(
    params: {
      /** AccountAddReq :AccountAddReq */
      root?: object;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/auth/account/add';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['root'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 查看用户详情
   */
  static detail(
    params: {
      /** (Long) */
      userId?: string;
      /** (Long) */
      tenantId?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/auth/account/detail';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { userId: params['userId'], tenantId: params['tenantId'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 用户接受邀请
   */
  static userAcceptInvite(
    params: {
      /** AcceptInviteReq :AcceptInviteReq */
      root?: object;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/auth/account/userAcceptInvite';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['root'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 移除组织用户
   */
  static delTenantAccount(
    params: {
      /** List<TenantAccountIdReq> */
      root?: any | null[];
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/auth/account/delTenantAccount';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['root'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 组织下的用户
   */
  static info(
    params: {
      /** AccountReq :AccountReq */
      root?: object;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/auth/account/info';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['root'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}


export class PermissionTemplateControllerService {
  /**
   * 修改模板名称或者是否默认
   */
  static update(
    params: {
      /** PermissionTemplateReq :PermissionTemplateReq */
      root?: object;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/auth/permissionTemplate/update';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['root'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 删除模板
   */
  static delete(
    params: {
      /** (Long) */
      id?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/auth/permissionTemplate/delete';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = { id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 新增模板以及模板资源
   */
  static addTemplateAndResources(
    params: {
      /** TemplateResourcesReq :TemplateResourcesReq */
      root?: object;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/auth/permissionTemplate/addTemplateAndResources';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['root'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 新增用户模板
   */
  static add(
    params: {
      /** PermissionTemplateReq :PermissionTemplateReq */
      root?: object;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/auth/permissionTemplate/add';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['root'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 新增编辑模板资源
   */
  static addOrUpdate(
    params: {
      /** List<ResourcesAddOrUpdate> */
      root?: any | null[];
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/auth/permissionTemplate/resources/addOrUpdate';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['root'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 查询所有的模板资源
   */
  static resourcesAll(options: IRequestOptions = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/auth/permissionTemplate/resourcesAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 查询资源
   */
  static resourcesByTemplateIds(
    params: {
      /** Set<Long> */
      root?: any | null[];
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/auth/permissionTemplate/resourcesByTemplateIds';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['root'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 模板资源查看
   */
  static query(
    params: {
      /** (Long) */
      userId?: string;
      /** (Long) */
      templateId?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/auth/permissionTemplate/resources/query';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { userId: params['userId'], templateId: params['templateId'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 用户模板列表
   */
  static list(options: IRequestOptions = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/auth/permissionTemplate/list';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 用户模板列表
   */
  static list1(options: IRequestOptions = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/auth/permissionTemplate/list';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}


export class RoofWordService {
  /**
   * 1.摘要-屋顶分布区间
   */
  static initRoofSectionChart(
    params: {
      /**  */
      adcode?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/roof/initRoofSectionChart';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { adcode: params['adcode'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 2.屋顶资源-资源指标
   */
  static initResourceIndicatorsChart(
    params: {
      /**  */
      adcode?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/roof/initResourceIndicatorsChart';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { adcode: params['adcode'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 2.屋顶资源-资源详情
   */
  static initResourceDetailChart(
    params: {
      /**  */
      adcode?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/roof/initResourceDetailChart';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { adcode: params['adcode'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 屋顶报告word/pdf下载
   */
  static getRoofWord(
    params: {
      /** 地区code */
      adcode?: string;
      /** 0:word下载后缀为.docx 1:pdf下载后缀为.pdf */
      type?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/roof/getRoofWord';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { adcode: params['adcode'], type: params['type'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 未安装屋顶总面积直方图数据
   */
  static queryAvailableAreasData(
    params: {
      /** 地区code */
      adcode?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/roof/queryAvailableAreasData';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { adcode: params['adcode'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 查询行政区域面积直方图面积数据
   */
  static queryDistrictAreaData(
    params: {
      /** 地区code */
      adcode?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/roof/queryDistrictAreaData';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { adcode: params['adcode'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取省市code
   */
  static getRegionCode(options: IRequestOptions = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/roof/getRegionCode';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}
