
// 补贴类型
export const subsidyType = [
  {
    value:0,
    label:"一次性现金补贴"
  },
  {
    value:1,
    label:"电价补贴"
  },
  {
    value:2,
    label:"减免增值税"
  },
  {
    value:3,
    label:"减免所得税"
  },
]

// 微网要素
export const microgridFactor = [
  {
    value:1,
    label:"光伏"
  },
  {
    value:2,
    label:"储能"
  },
]

// 是否免所得税
export const isAvoidIncomeTax = [
  {
    value:0,
    label:"否"
  },
  {
    value:1,
    label:"是"
  },
]

// 补贴对象
export const subsidyObject = [
  {
    value:0,
    label:"消纳电量"
  },
  {
    value:1,
    label:"上网电量"
  },
  {
    value:2,
    label:"全部电量"
  },
]

// 金额单位
export const moneyType = [
  {
    value:0,
    label:"%"
  },
  {
    value:1,
    label:"元"
  },
  {
    value:2,
    label:"元/kWh"
  },
  {
    value:3,
    label:"元/kW"
  },
]