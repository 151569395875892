import React, { useEffect, useState } from 'react'
import ReactECharts from 'echarts-for-react'
import moment from 'moment'

export const LineChart = (props: {
  colors: string[];
  chartInfo: any;
  fsInfo?: any[];
  isEdit?: boolean;
  showSymbol?: boolean;
}) => {
  const { colors, chartInfo } = props

  const [chartData, setChartData] = React.useState<any>({
    time: {},
    charts: {}
  })

  const [fsData, setFsData] = useState([])

  useEffect(() => {
    getFsData()
  }, [props.fsInfo])

  const getFsData = () => {
    if (props.fsInfo && chartData.time && Object.values(chartData.time).length > 0) {
      const times = Object.values(chartData.time)[0] as any[]
      const rs = props.fsInfo.map(o => {
        const res = []
        o.forEach(oo => {
          if (!oo.time) {
            return
          }

          const [start, end] = oo.time?.map(tm => tm?.valueOf())
          if (!start || !end) return
          const price = oo.price
          times.forEach((time, idx) => {
            if (time === '24:00') {
              res[idx] = o[0].price
            } else {
              const mmt = moment(time, 'HH:mm').valueOf()
              if (mmt >= start && mmt <= end) {
                res[idx] = price
              }
            }
          })
        })
        return {
          name: o?.[0]?.name || '',
          value: res
        }
      })
      setFsData(rs)
    }
  }

  React.useEffect(() => {
    const chartInfoTmp = { ...chartInfo }
    if (chartInfoTmp?.time) {
      const key = Object.keys(chartInfoTmp.time)[0]
      if (key) {
        chartInfoTmp.time[key].push('24:00')
      }
    }
    setChartData(chartInfo)
  }, [chartInfo])

  return (
    <div style={{ width: '100%', height: 500 }}>
      {chartData &&
      <ReactECharts
        notMerge={true}
        style={{
          width: '100%',
          height: '100%'
        }}
        option={{
          color: colors,
          title: {
            text: '单位：元/kWh',
            top: 50,
            textStyle: {
              color: '#98A0B3',
              fontSize: '12px',
              fontWeight: 'normal'
            }
          },
          tooltip: {
            trigger: 'axis',
            backgroundColor: 'rgba(38, 46, 63, 0.95)',
            borderWidth: 0,
            formatter: (args: any) => {
              let res = `<strong>${args[0].axisValue}</strong>`
              if (args.length > 0) {
                res += args
                  .map(
                    (o: any) =>
                      (o.value === undefined || o.value === '') ? '' : `<div style='width: 200px; font-size:12px'>${o.marker}${o.seriesName} <span style='float: right'>${o.value}元/kWh</span></div>`
                  )
                  .join('')
              }
              return res
            },
            textStyle: {
              color: '#fff'
            }
          },
          grid: {
            left: 30,
            right: '3%',
            top: 100,
            bottom: 50,
            containLabel: true
          },
          xAxis: {
            type: 'category',
            axisLabel: {
              interval: 6
            },
            boundaryGap: false,
            data: Object.values(chartData.time)[0]
          },
          yAxis: {
            type: 'value',
            splitLine: {
              lineStyle: {
                type: [5, 5],
                dashOffset: 5
              }
            }
          },
          legend: {
            show: true,
            bottom: '0',
            icon: 'roundRect',
            itemWidth: 15,
            itemGap: 30
          },
          series: [
            ...(!props.isEdit && Object.keys(chartData.charts).map((key) => {
              return {
                name: key,
                type: 'line',
                sampling: 'lttb',
                showSymbol: false,
                lineStyle: {
                  width: 1
                },
                data: chartData.charts[key]
              }
            }) || []),
            ...fsData.map(key => {
              return {
                name: key.name,
                type: 'line',
                sampling: 'lttb',
                showSymbol: !!props.showSymbol,
                lineStyle: {
                  width: 1,
                },
                data: key.value
              }
            })
          ]
        }}
      />
      }
    </div>
  )
}
