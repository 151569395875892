import React from 'react'
import { Statistic, Tag, Tooltip, Typography } from 'antd'
import {
  getActionColumn,
  getColumnFilterProps,
  TablePage,
  TablePageInstance,
} from '../../../../components/wl-table-page'
import { FeeService } from '../../../../apis/wl-service'
import LogDialog from './LogDialog'
import ApplyDetailDialog from './ApplyDetailDialog'
import { applyStatusOptions, applyTypeOptions } from './constants'

const ellipsis = (text: string, isStat?: boolean) =>
  text && (
    <Tooltip title={text}>
      <Typography.Text ellipsis>
        {typeof isStat === 'boolean' ? <Statistic title="" value={text} precision={2} prefix="￥" /> : text}
      </Typography.Text>
    </Tooltip>
  )

interface ApplyTableProps {
  componentRef: React.RefObject<TablePageInstance>
}

export default ({ componentRef }: ApplyTableProps) => {
  const [logState, setLogState] = React.useState({ visible: false, id: '' })
  const [detailState, setDetailState] = React.useState({ visible: false, id: '' })

  // 费用申请字段
  const columns = (tableParams: any) => [
    {
      title: '用款申请单号',
      dataIndex: 'apply_for_no',
      width: 180,
      render: ellipsis,
      ...getColumnFilterProps({
        type: 'input',
        placeholder: '请输入用款申请单号',
        value: tableParams.apply_for_no,
        onSearch: (value) => {
          componentRef.current.reload?.({
            apply_for_no: value,
            page: 1,
          })
        },
      }),
    },
    {
      title: '签署方',
      dataIndex: 'signaor',
      width: 200,
      render: ellipsis,
    },
    {
      title: '合同编号',
      dataIndex: 'contract_no',
      width: 260,
      render: ellipsis,
      ...getColumnFilterProps({
        type: 'input',
        placeholder: '请输入合同编号',
        value: tableParams.contract_no,
        onSearch: (value) => {
          componentRef.current.reload?.({
            contract_no: value,
            page: 1,
          })
        },
      }),
    },
    {
      title: '用款申请日期',
      dataIndex: 'create_time',
      width: 180,
      render: ellipsis,
      ...getColumnFilterProps({
        type: 'RangePicker',
        placeholder: '',
        value: [tableParams.create_time, tableParams.create_end_time],
        onSearch: (value) => {
          componentRef.current.reload?.({
            create_time: value[0],
            create_end_time: value[1],
            page: 1,
          })
        },
      }),
    },
    {
      title: '申请人',
      dataIndex: 'apply_for_name',
      width: 140,
      render: ellipsis,
    },
    {
      title: '合同档案',
      dataIndex: 'contract_name',
      width: 150,
      render: ellipsis,
    },
    {
      title: '申请类型',
      dataIndex: 'use_money_type_desc',
      width: 160,
      render: ellipsis,
      ...getColumnFilterProps({
        type: 'select',
        options: applyTypeOptions,
        placeholder: '请选择申请类型',
        value: tableParams.use_amount_type_id,
        onSearch: (value) => {
          componentRef.current.reload?.({
            use_amount_type_id: value,
            page: 1,
          })
        },
      }),
    },
    {
      title: '申请金额',
      dataIndex: 'apply_for_money',
      width: 180,
      render: (text: string) => ellipsis(text, true),
    },
    {
      title: '申请状态',
      dataIndex: 'apply_status',
      width: 100,
      render: (text: string) => {
        const { color, fullName } = applyStatusOptions.find((v) => v.id === text?.toString()) ?? {}

        return <Tag color={color}>{fullName}</Tag>
      },
      ...getColumnFilterProps({
        type: 'select',
        options: applyStatusOptions,
        placeholder: '请选择申请状态',
        value: tableParams.status,
        onSearch: (value) => {
          componentRef.current.reload?.({
            status: value,
            page: 1,
          })
        },
      }),
    },
    {
      title: '项目名称',
      dataIndex: 'projectName',
      width: 260,
      render: ellipsis,
      ...getColumnFilterProps({
        type: 'input',
        placeholder: '请输入项目名称',
        value: tableParams.projectName,
        onSearch: (value) => {
          componentRef.current.reload?.({
            projectName: value,
            page: 1,
          })
        },
      }),
    },
    {
      title: '合同号',
      dataIndex: 'contract_id',
      width: 200,
      render: ellipsis,
    },
    getActionColumn([
      {
        key: 'detail',
        label: '明细',
        onClick: (row: any) => {
          setDetailState({ visible: true, id: row.id })
        },
      },
      {
        key: 'log',
        label: '日志',
        onClick: (row: any) => {
          setLogState({ visible: true, id: row.id })
        },
      },
    ]),
  ]

  return (
    <>
      <TablePage
        autoLoad={false}
        checkable
        componentRef={componentRef}
        columns={columns}
        rowKey="id"
        getter={(arg: any) => FeeService.applyList(arg)}
      />
      <LogDialog
        type="apply"
        {...logState}
        visibleChange={(visible: boolean) => setLogState((prevState) => ({ ...prevState, visible }))}
      />
      <ApplyDetailDialog
        {...detailState}
        visibleChange={(visible: boolean) => setDetailState((prevState) => ({ ...prevState, visible }))}
      />
    </>
  )
}
