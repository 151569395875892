import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Alert, Button, Form, message, Select, Space, Table, Typography } from 'antd'
import UploadFile from '../../../../components/wl-upload'
import { getActionColumn } from '../../../../components/wl-table-page/components/table-action'
import { CollectionConService, ProjectDocumentService, ProjectProposalService } from '../../../../apis/wl-service'
import store from './store'
import { BreadcrumbObservable, SideMenuObservable } from '../../../../application'

export interface MyFormProps {
  componentRef: any
  visibleDraft: (visible: boolean) => void
  visibleSubmit: (visible: boolean) => void
  onNext?: () => void
}

export default ({ componentRef, visibleDraft, visibleSubmit }: MyFormProps) => {
  const unmountRef = React.useRef<boolean>(false)
  const nav = useNavigate()
  const [form] = Form.useForm()
  const [disabled, setDisabled] = React.useState<boolean>(!store.projectId) // 上传是否禁用
  const [projectOptions, setProjectOptions] = React.useState<any>() // 项目列表
  const [dataSource, setDataSource] = React.useState([]) // 表格数据
  const storeKey = React.useMemo(() => store.keyMap.projectProposal, [store]) // 用于本地数据存储的键值

  React.useImperativeHandle(componentRef, () => {
    return {
      getName: () => storeKey,
      submit: () => {},
      // async (options: SubmitType) => {
      // try {
      //   let values: any = await form.validateFields()
      //   values = formatter(values, 'params')
      //   store.submit[storeKey]({
      //     ...options,
      //     values,
      //     success: (data: any) => {
      //       options.success?.(data)
      //       // onNext?.()
      //     },
      //   })
      // } catch (e) {
      //   console.warn('validateFields:', e)
      // }
      // },
    }
  })

  // 设置组件挂载状态
  React.useEffect(() => {
    unmountRef.current = false
    return () => {
      unmountRef.current = true
    }
  }, [])

  React.useEffect(() => {
    // 是否显示保存草稿按钮
    visibleDraft(false)

    // 是否显示确定按钮
    visibleSubmit(false)
  }, [])

  // 监听上传禁用状态
  React.useEffect(() => {
    const fn = () => {
      setDisabled(!store.projectId)
    }
    store.subscribe(fn)
    return () => {
      store.unsubscribe(fn)
    }
  }, [store])

  // 获取项目列表
  React.useEffect(() => {
    const prevProjectOptions = store.getOptions('developList')

    // 未选中项目时
    if (!prevProjectOptions?.length) {
      // 获取项目名称
      CollectionConService.basicData()
        .then((resp: any) => {
          if (resp?.data) {
            const options = {
              ...resp.data,
              developList: resp.data.developList?.map(({ id, name }: any) => {
                return { id, name, fullName: name }
              }),
            }
            store.setOptions(options, true)
            !unmountRef.current && setProjectOptions(options.developList)
          } else {
            !unmountRef.current && setProjectOptions([])
          }
        })
        .catch((e) => {
          console.warn(`获取项目库项目列表失败：`, e)
        })
    } else {
      setProjectOptions(prevProjectOptions)
    }
  }, [])

  // 获取详情
  React.useEffect(() => {
    // 从缓存中恢复数据
    const cacheData = store.getItem(storeKey)
    if (cacheData) {
      form.setFieldsValue(formatter(cacheData, 'component'))
    } else {
      form.resetFields()
    }

    form.setFieldsValue({ projectId: store.projectId })

    if (cacheData?.__tableData) {
      setDataSource(cacheData.__tableData)
    } else {
      // 首次加载表格数据
      loadTableData().then()
    }
  }, [store.projectId])

  // 获取表格数据
  const loadTableData = async () => {
    const setValues = (values: any) => {
      store.setItem(storeKey, { ...store.getItem(storeKey), __tableData: values })
      !unmountRef.current && setDataSource(values)
    }

    if (!store.projectId) {
      setValues([])
      return
    }

    try {
      const resp: any = await ProjectProposalService.list({ projectId: store.projectId })
      if (resp?.code === 200) {
        setValues(resp.data ?? [])
      }
    } catch (e) {
      console.warn(`获取项目建议书列表失败：`, e)
    }
  }

  // type=params作为参数发送到服务端
  // type=component转换数据用于组件渲染
  const formatter = (values: any, type: 'params' | 'component') => {
    values = { ...values }

    // 转换文件
    ;['file'].forEach((k: string) => {
      if (type === 'component') {
        values[k] = values[k] ? JSON.parse(values[k]) : []
      }
      if (type === 'params') {
        values[k] = values[k]?.length ? JSON.stringify(values[k]) : ''
      }
    })

    return values
  }

  const onCreate = React.useCallback(() => {
    ProjectDocumentService.oauth({
      projectId: store.projectId,
      code: 4
    }).then((res: any) => {
      if (res?.data) {
        SideMenuObservable.next(false)
        BreadcrumbObservable.next([])
        nav(`/program/technical`)
      } else {
        message.warn(res?.msg ?? `您暂时还没有新建项目建议书权限哦~`)
      }
    }).catch((err: any) => {
      console.warn('请求下载权限接口失败:', err)
    })
  }, [store.projectId])

  const columns = React.useMemo(() => {
    return [
      {
        dataIndex: '_order',
        title: '序号',
        width: 80,
        fixed: 'left',
        align: 'center',
        render: (...args: any[]) => args[2] + 1,
      },
      { dataIndex: 'name', title: '项目建议书', width: 160, fixed: 'left' },
      { dataIndex: 'projectName', title: '项目名称', width: 140 },
      { dataIndex: 'projectCode', title: '项目代码', width: 100 },
      { dataIndex: 'resourcetypename', title: '项目类型', width: 100 },
      { dataIndex: 'calculationState', title: '计算状态', width: 100 },
      { dataIndex: 'createTime', title: '创建时间', width: 100 },
      { dataIndex: 'createName', title: '创建人', width: 100 },
      getActionColumn([
        {
          key: 'delete',
          type: 'delete',
          getter: (row: any) => {
            return ProjectProposalService.remove({ id: row.id })
          },
          success: () => loadTableData(),
        },
      ]),
    ]
  }, [])

  return (
    <Form
      form={form}
      name="projectProposalForm"
      className="wl-form-item"
      layout="vertical"
      onValuesChange={(changedValues, allValues) => {
        store.onValuesChange(storeKey, formatter(allValues, 'params'))
      }}
    >
      <Form.Item
        label={
          <Space wrap={false} direction="horizontal">
            <Typography.Title level={5} style={{ marginBottom: 0 }}>
              新建项目建议书
            </Typography.Title>
            <Typography.Text type="secondary">（技术方案/财务方案）</Typography.Text>
          </Space>
        }
      >
        {/* // 点击新建项目建议书按钮直接跳转到项目设计里新建技术方案和财务方案,并且生成项目建议书报告 */}
        <Button disabled={!store.projectId} type="primary" ghost onClick={onCreate}>
          新建项目建议书
        </Button>
      </Form.Item>

      <Form.Item
        name="projectId"
        label="项目名称"
        hidden={!!store.projectId && store.getModuleName() !== storeKey}
        rules={[{ required: true, message: '请选择项目' }]}
      >
        <Select
          dropdownMatchSelectWidth={false}
          allowClear
          dropdownClassName="right-actions"
          placeholder="请选择"
          style={{ maxWidth: 300 }}
          showSearch
          optionFilterProp="children"
          filterOption={(input: string, option: any) => {
            return (option!.children as unknown as string).toLowerCase().includes(input.toLowerCase())
          }}
          onChange={(value: string) => {
            // 项目id变化
            store.setProjectId(value ?? '', storeKey)
          }}
        >
          {projectOptions?.map(({ id, name }: any) => {
            return <Select.Option key={id}>{name}</Select.Option>
          })}
        </Select>
      </Form.Item>

      <Form.Item
        name="file"
        label={
          <Space wrap={false} direction="horizontal">
            <Typography.Title level={5} style={{ marginBottom: 0 }}>
              项目建设书上传
            </Typography.Title>
            <Typography.Text type="secondary">（技术方案/财务方案）</Typography.Text>
          </Space>
        }
      >
        {disabled ? (
          <Alert type="warning" message="请先选择项目再上传项目建议书哦~" />
        ) : (
          <UploadFile
            accept=".docx,.doc,.pdf,.jpeg,.jpg"
            extra="支持扩展名：.docx .pdf .jpg"
            extraWrap={false}
            maxCount={1}
            showProgress
            disabled={disabled}
            onChange={async (value) => {
              const fileId = value?.[0]?.fileId ?? ''
              if (!fileId) return

              try {
                const resp: any = await ProjectProposalService.upload({
                  projectId: store.projectId ?? '',
                  root: { fileId, fileName: value?.[0]?.name ?? '' },
                })
                if (resp?.code === 200) {
                  await loadTableData()
                }
              } catch (e) {
                console.warn('项目方案上传失败:', e)
              }
            }}
          />
        )}
      </Form.Item>
      <Form.Item>
        <Table rowKey="id" columns={columns as any} dataSource={dataSource} scroll={{ x: '100%' }} pagination={false} />
      </Form.Item>
    </Form>
  )
}
