import React, { useImperativeHandle } from 'react'
import moment from 'moment'
import { FormInstance, Table, Typography } from 'antd'
import { CustomFormItem } from '../../../../components/wl-form-item'

export interface IProjectInvestTableProps {
  form: FormInstance
  dataSource: any
  editable?: boolean
  t?: string
}

export interface ProjectInvestTableInstance {
  getValues(values: any): any
}

export default React.forwardRef(ProjectInvestTable)

const yesOrNo = [
  { id: '是', fullName: '是' },
  { id: '否', fullName: '否' },
]

// 工程投资表格
function ProjectInvestTable({ form, dataSource: data, t, editable = false }: IProjectInvestTableProps, ref: any) {
  useImperativeHandle(ref, () => ({
    /**
     * 将获取到的数据数据同步给表格
     * 初始化数据
     * 工程投资
     */
    getValues: (values: any) => {
      const projectCostData = values?.projectCostData ?? {}
      const next = {
        // 项目规模(MW)
        __t_0_1: values?.decisionList?.decisionVolume,
        project_module1: projectCostData.project_module1, // t_0_2
        project_module2: projectCostData.project_module2, // t_0_3
        // 总投资是否超额上一阶段
        // __t_1_1: '否', // 默认否
        isexceed2: projectCostData.isexceed2 ?? '否', // 默认否
        isexceed3: projectCostData.isexceed3 ?? '否', // 默认否
        // 工程总投资
        __t_2_1: values?.decisionList?.totalInvest, // 工程总投资(万元)
        totalinvest2: projectCostData.totalinvest2 ?? '',
        totalinvest3: projectCostData.totalinvest3 ?? '',
        // 项目动态投资
        __t_3_1: values?.decisionList?.dynamicInvest,
        dynamic_investment2: projectCostData?.dynamic_investment2, // t_3_2
        dynamic_investment3: projectCostData?.dynamic_investment3, // t_3_3
        // 项目静态投资
        staticInvestment1: projectCostData?.staticInvestment1 ?? '',
        staticInvestment2: projectCostData?.staticInvestment2 ?? '',
        staticInvestment3: projectCostData?.staticInvestment3 ?? '',
        // 流动资金
        working_fund: projectCostData.working_fund, // t_5_1
        // 铺底流动资金
        working_capital_fund: projectCostData.working_capital_fund, // t_6_1
        // 建设利息
        // interest1: projectCostData.interest1 ?? '', // t_7_1
        // interest2: projectCostData.interest2 ?? '', // t_7_2
        // __t_7_3: '',
        // 批复时间
        approval_time1: projectCostData.approval_time1, // t_8_2
        approval_time2: projectCostData.approval_time2, // t_8_3
        // 批复单位
        approval_unit1: projectCostData.approval_unit1 ?? '', // t_9_2
        approval_unit2: projectCostData.approval_unit2 ?? '', // t_9_3
      }
      if (editable) {
        Object.assign(next, {
          approval_time1: next.approval_time1 ? moment(next.approval_time1) : null,
          approval_time2: next.approval_time2 ? moment(next.approval_time2) : null,
        })
      }

      setTimeout(watch.setRow1, 0)
      return next
    },
  }))

  const watch = {
    setRow1: () => {
      const v = form.getFieldsValue(['__t_2_1', 'totalinvest2', 'totalinvest3'])
      if (v.__t_2_1 && v.totalinvest2) {
        form.setFieldsValue({
          isexceed2: v.totalinvest2 > v.__t_2_1 ? '是' : '否',
        })
      }
      if (v.totalinvest2 && v.totalinvest3) {
        form.setFieldsValue({
          isexceed3: v.totalinvest3 > v.totalinvest2 ? '是' : '否',
        })
      }
    },
  }

  const tableData = [
    {
      uid: 0,
      title: '项目规模(MW)',
      front: {
        name: '__t_0_1',
        type: 'number',
        editable,
        value: data.__t_0_1,
        componentProps: {
          //  placeholder: '',
          disabled: true,
        },
      },
      middle: {
        name: 'project_module1',
        type: 'number',
        editable,
        value: data.project_module1,
        componentProps: {
          //  placeholder: '',
          disabled: t !== '2',
        },
      },
      rear: {
        name: 'project_module2',
        type: 'number',
        editable,
        value: data.project_module2,
        componentProps: {
          //  placeholder: '',
          disabled: t !== '3',
        },
      },
    },
    {
      uid: 1,
      title: '总投资是否超额上一阶段',
      front: {
        type: '/',
        // name: '__t_1_1',
        // type: 'select',
        // editable,
        // value: data.__t_1_1,
        // options: yesOrNo,
        // computed: true,
        // componentProps: {
        //   disabled: true,
        // },
      },
      middle: {
        name: 'isexceed2',
        type: 'select',
        editable,
        value: data.isexceed2,
        options: yesOrNo,
        computed: true,
        componentProps: {
          //  placeholder: '',
          disabled: true,
        },
      },
      rear: {
        name: 'isexceed3',
        type: 'select',
        editable,
        value: data.isexceed3,
        options: yesOrNo,
        computed: true,
        componentProps: {
          //  placeholder: '',
          disabled: true,
        },
      },
    },
    {
      uid: 2,
      title: '工程总投资(万元)',
      front: {
        name: '__t_2_1',
        type: 'number',
        editable,
        value: data.__t_2_1,
        componentProps: {
          //  placeholder: '',
          disabled: true,
          onChange: watch.setRow1,
        },
      },
      middle: {
        name: 'totalinvest2',
        type: 'number',
        editable,
        value: data.totalinvest2,
        componentProps: {
          //  placeholder: '',
          //   disabled: true,
          disabled: t !== '2',
          onChange: watch.setRow1,
        },
      },
      rear: {
        name: 'totalinvest3',
        type: 'number',
        editable,
        value: data.totalinvest3,
        componentProps: {
          //  placeholder: '',
          //   disabled: true,
          disabled: t !== '3',
          onChange: watch.setRow1,
        },
      },
    },
    {
      uid: 3,
      title: '项目动态投资(万元)',
      front: {
        name: '__t_3_1',
        type: 'number',
        editable,
        value: data.__t_3_1,
        componentProps: {
          //  placeholder: '',
          disabled: true,
        },
      },
      middle: {
        name: 'dynamic_investment2',
        type: 'number',
        editable,
        value: data.dynamic_investment2,
        componentProps: {
          //  placeholder: '',
          disabled: t !== '2',
        },
      },
      rear: {
        name: 'dynamic_investment3',
        type: 'number',
        editable,
        value: data.dynamic_investment3,
        componentProps: {
          //  placeholder: '',
          disabled: t !== '3',
        },
      },
    },
    {
      uid: 4,
      title: '项目静态投资(万元)',
      front: {
        name: 'staticInvestment1',
        type: 'number',
        editable,
        value: data.staticInvestment1,
        componentProps: {
          //  placeholder: '',
          //   disabled: true,
          disabled: t !== '1',
        },
      },
      middle: {
        name: 'staticInvestment2',
        type: 'number',
        editable,
        value: data.staticInvestment2,
        componentProps: {
          //  placeholder: '',
          //   disabled: true,
          disabled: t !== '2',
        },
      },
      rear: {
        name: 'staticInvestment3',
        type: 'number',
        editable,
        value: data.staticInvestment3,
        componentProps: {
          //  placeholder: '',
          //   disabled: true,
          disabled: t !== '3',
        },
      },
    },
    {
      uid: 5,
      title: '流动资金(万元)',
      front: {
        name: 'working_fund',
        type: 'number',
        editable,
        value: data.working_fund,
        componentProps: {
          //  placeholder: '',
          disabled: t !== '1',
        },
      },
      middle: {
        type: '/',
      },
      rear: {
        type: '/',
      },
    },
    {
      uid: 6,
      title: '铺底流动资金(万元)',
      front: {
        name: 'working_capital_fund',
        type: 'number',
        editable,
        value: data.working_capital_fund,
        componentProps: {
          //  placeholder: '',
          disabled: t !== '1',
        },
      },
      middle: {
        type: '/',
      },
      rear: {
        type: '/',
      },
    },
    // {
    //   uid: 7,
    //   title: '建设利息(万元)',
    //   front: {
    //     name: 'interest1',
    //     type: 'number',
    //     editable,
    //     value: data.interest1,
    //     componentProps: {
    //       disabled: true,
    //     },
    //   },
    //   middle: {
    //     name: 'interest2',
    //     type: 'number',
    //     editable,
    //     value: data.interest2,
    //     componentProps: {
    //       disabled: true,
    //     },
    //   },
    //   rear: {
    //     name: '__t_7_3',
    //     type: 'number',
    //     editable,
    //     value: data.__t_7_3,
    //     componentProps: {
    //       disabled: true,
    //     },
    //   },
    // },
    {
      uid: 8,
      title: '批复时间',
      front: {
        type: '/',
      },
      middle: {
        name: 'approval_time1',
        type: 'datepicker',
        editable,
        value: data.approval_time1,
        componentProps: {
          //  placeholder: '',
          disabled: t !== '2',
          format: 'YYYY-MM-DD',
        },
      },
      rear: {
        name: 'approval_time2',
        type: 'datepicker',
        editable,
        value: data.approval_time2,
        //  placeholder: '',
        componentProps: {
          disabled: t !== '3',
          format: 'YYYY-MM-DD',
        },
      },
    },
    {
      uid: 9,
      title: '批复单位',
      front: {
        type: '/',
      },
      middle: {
        name: 'approval_unit1',
        type: 'input',
        editable,
        value: data.approval_unit1,
        componentProps: {
          //  placeholder: '',
          disabled: t !== '2',
        },
      },
      rear: {
        name: 'approval_unit2',
        type: 'input',
        editable,
        value: data.approval_unit2,
        componentProps: {
          //  placeholder: '',
          disabled: t !== '3',
        },
      },
    },
  ]

  return (
    <Table
      bordered
      className="bordered-table"
      pagination={false}
      rowKey="uid"
      size="small"
      tableLayout="fixed"
      dataSource={tableData}
      columns={[
        {
          dataIndex: 'title',
          title: '',
          align: 'left',
        },
        {
          dataIndex: 'front',
          title: <Typography.Title level={5}>可研估算</Typography.Title>,
          align: 'center',
          render: (text: any, row: any) => {
            if (row?.front?.type === '/') {
              return '/'
            }
            return (
              <CustomFormItem
                noStyle
                label=""
                span={1}
                name={row?.front?.name}
                type={row?.front?.type}
                editable={row?.front?.editable}
                value={row?.front?.value}
                {...(row.front ?? {})}
              />
            )
          },
        },
        {
          dataIndex: 'middle',
          title: <Typography.Title level={5}>初设概算</Typography.Title>,
          align: 'center',
          render: (text: any, row: any) => {
            if (row?.middle?.type === '/') {
              return '/'
            }
            return (
              <CustomFormItem
                noStyle
                label=""
                span={1}
                name={row?.middle?.name}
                type={row?.middle?.type}
                editable={row?.middle?.editable}
                value={row?.middle?.value}
                {...(row.middle ?? {})}
              />
            )
          },
        },
        {
          dataIndex: 'rear',
          title: <Typography.Title level={5}>竣工结算</Typography.Title>,
          align: 'center',
          render: (text: any, row: any) => {
            if (row?.rear?.type === '/') {
              return '/'
            }
            return (
              <CustomFormItem
                noStyle
                label=""
                span={1}
                name={row?.rear?.name}
                type={row?.rear?.type}
                editable={row?.rear?.editable}
                value={row?.rear?.value}
                {...(row.rear ?? {})}
              />
            )
          },
        },
      ]}
    />
  )
}
