import { Button, Select, Spin } from 'antd'
import { ApplicationModal } from '../../application'
import React, { useEffect, useState } from 'react'
import { EyeFilled, LeftOutlined } from '@ant-design/icons'
import { parseNumber, parseSuffixNumber } from './resource-map'
import { pageSize, query, RoofSiftModal } from './roof-sift-modal'
import { EvaluationV2Api } from '../../services'

const parseDom = (str: string | number) => {
  if (typeof str !== 'string') {
    return str
  }
  const strArr = str.split('^')
  if (strArr.length === 2) {
    return (
      <div
        style={{
          display: 'inline-block',
        }}
      >
        <span>{strArr[0]}</span>
        <span className="e-radix">{strArr[1]}</span>
      </div>
    )
  } else {
    return str
  }
}

let page = 1;

export const RoofSift = (props: any) => {
  const [data, setData] = useState<any[]>()
  const [hasMore, setHasMore] = useState(true);

  const loadData = (append = false) => {
    setLoading(true);
    if(!append) {
      page = 1;
    } else {
      page++;
    }
    new EvaluationV2Api().i3060EvaluationGetRoofV2Get({
      ...query,
      areaSort: '2',
      page: page,
      size: pageSize,
      regionCode: (window as any).currentAdcode,
    }).then((resp: any) => {
      let dt = [];
      if(append) {
        dt = [...data, ...resp.data.data]
      } else {
        dt = resp?.data?.data || []
      }
      if(dt.length >= resp.data.total) {
        setHasMore(false)
      } else {
        setHasMore(true)
      }
      setData(dt);
    }).finally(() => {
      setLoading(false)
    })
  }
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    page = 1;
    loadData()
    return () => {
      page = 1;
      const obj: any = {
        name: '',
        regionCode: (window as any).currentAdcode,
        areaMin: undefined,
        areaMax: undefined,
        yearTotalIrradianceMin: undefined,
        yearTotalIrradianceMax: undefined,
        time: undefined,

        areaSort: '0',
        timeSort: '0',
        yearTotalIrradianceSort: '0',
      }

      Object.keys(obj).forEach((key) => {
        query[key] = obj[key]
      })
    }
  }, [])

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        position: 'absolute',
        overflow: 'hidden',
        left: 20,
        top: 20,
        width: 286,
        bottom: 16,
        pointerEvents: 'all',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
        }}
      >
        <Button
          icon={<LeftOutlined />}
          style={{
            width: 35,
          }}
          onClick={() => {
            props.onBack?.()
          }}
        />
        <Select
          style={{
            marginLeft: 3,
            flexGrow: 1,
          }}
          showSearch
          allowClear
          placeholder="请输入地址"
          filterOption={(input, option: any) => {
            return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }}
          onChange={(value) => {
            const dt = JSON.parse(value)
            ;(window as any).moveToRoof(dt.centerPoint, dt)
          }}
        >
          {data?.map((o: any) => {
            return (
              <Select.Option key={o.id} value={JSON.stringify(o)}>
                {o.name}
              </Select.Option>
            )
          })}
        </Select>
      </div>
      <div
        style={{
          flexGrow: 1,
          marginTop: 16,
          paddingLeft: 0,
          paddingRight: 0,
          paddingTop: 4,
          paddingBottom: 16,
          backgroundColor: 'white',
          display: 'flex',
          flexDirection: 'column',
          height: 1,
        }}
      >
        <div
          style={{
            fontSize: 16,
            color: '#262E3F',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            paddingRight: 16,
          }}
        >
          <div
            style={{
              width: 3,
              height: 22,
              background: '#1EC692',
              marginRight: 13,
            }}
          />
          <span
            style={{
              lineHeight: '30px',
              flexGrow: 1,
            }}
          >
            屋顶排名
          </span>
          <Button type="link" icon={<EyeFilled />} />
          <Button
            type="link"
            onClick={() => {
              const modal = ApplicationModal.confirm({
                keyboard: false,
                icon: null,
                title: '更多筛选',
                closable: true,
                content: (
                  <RoofSiftModal
                    onClose={(data: any) => {
                      modal.destroy()
                      if (data) {
                        loadData()
                      }
                    }}
                  />
                ),
                width: 1250,
                okButtonProps: {
                  style: { display: 'none' },
                },
                cancelButtonProps: {
                  style: { display: 'none' },
                },
              })
            }}
          >
            更多筛选
          </Button>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            marginTop: 8,
            boxShadow: 'rgba(0,0,0,0.1) 0px -6px 6px 0px',
            paddingLeft: 16,
            paddingRight: 16,
            paddingTop: 5,
          }}
        >
          <div
            style={{
              fontSize: '14px',
              color: '#262E3F',
              width: 60,
              whiteSpace: 'nowrap',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
              flexGrow: 1,
            }}
          >
            屋顶名称
          </div>
          <span>屋顶面积 ㎡</span>
        </div>
        {data && (
          <div
            style={{
              flexGrow: 1,
              overflowY: 'auto',
              overflowX: 'hidden',
              marginTop: 10,
              height: 'calc(100% - 340px)',
            }}
          >
            {data?.map((o: any, idx: number) => (
              <div
                key={o.name + idx}
                style={{
                  fontSize: 12,
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  paddingLeft: 16,
                  paddingRight: 16,
                  height: 32,
                }}
              >
                <div
                  style={{
                    color: idx === 0 ? '#1CB887' : idx === 1 ? '#F86728' : idx === 2 ? '#3274F6' : '#767F95',
                    minWidth: 40,
                  }}
                >
                  {idx + 1}
                </div>
                <div
                  style={{
                    color: '#262E3F',
                    marginRight: 5,
                    minWidth: 80,
                    flexShrink: 1,
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    ;(window as any).moveToRoof(o.centerPoint, o)
                  }}
                >
                  {o.name}
                </div>
                <div
                  style={{
                    minWidth: 80,
                    textOverflow: 'ellipsis',
                  }}
                >
                  <div
                    style={{
                      background: 'rgba(30, 198, 146, 0.2)',
                      color: '#1CB887',
                      width: (80 * o.area) / Math.max(...data.map((o: any) => o.area)),
                    }}
                  >
                    {parseSuffixNumber(o.area)}
                  </div>
                </div>
              </div>
            ))}
            {
              hasMore && <div
                style={{
                  fontSize: 12,
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  paddingLeft: 16,
                  paddingRight: 16,
                  height: 32,
                  cursor: 'pointer',
                }}
                onClick={() => {
                  loadData(true);
                }}
              >
                <Spin spinning={loading}>加载更多...</Spin>
              </div>
            }
          </div>
        )}
      </div>

      <div
        style={{
          flexGrow: 0,
          flexShrink: 0,
          marginTop: 16,
          backgroundColor: 'white',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          height: 214,
        }}
      >
        <div
          style={{
            fontSize: 16,
            color: '#262E3F',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            flexWrap: 'wrap',
          }}
          className="map-popover roof-content"
        >
          <div className="map-popover-item">
            <div className="map-popover-value">
              {parseDom(
                parseNumber(props.data.regionArea, {
                  type: 'e',
                  radix: 2,
                  div: 1000000,
                })
              )}
              <span className="map-popover-unit">k㎡</span>
            </div>
            <div className="map-popover-name">区域面积</div>
          </div>
          <div className="map-popover-item">
            <div className="map-popover-value">
              {parseNumber(props.data.yearTotalIrradiance, {
                type: '',
                radix: 2,
              })}
              <span className="map-popover-unit">kWh/㎡</span>
            </div>
            <div className="map-popover-name">年总辐照</div>
          </div>
          <div className="map-popover-item">
            <div className="map-popover-value">
              {parseNumber(props.data.roofCount)}
              <span className="map-popover-unit">个</span>
            </div>
            <div className="map-popover-name">屋顶数量</div>
          </div>
          <div className="map-popover-item">
            <div className="map-popover-value">
              {parseNumber(parseSuffixNumber(props.data.roofRatio * 1000, 2))}
              <span className="map-popover-unit">‰</span>
            </div>
            <div className="map-popover-name">屋顶覆盖率</div>
          </div>
          <div className="map-popover-item">
            <div className="map-popover-value">
              {parseNumber(props.data.roofArea, {
                type: '',
                radix: 0,
              })}
              <span className="map-popover-unit">㎡</span>
            </div>
            <div className="map-popover-name">屋顶总面积</div>
          </div>
          <div className="map-popover-item">
            <div className="map-popover-value">
              {parseNumber(props.data.avgRoofArea, {
                type: '',
                radix: 2,
              })}
              <span className="map-popover-unit">㎡</span>
            </div>
            <div className="map-popover-name">屋顶平均面积</div>
          </div>
        </div>
      </div>
    </div>
  )
}
