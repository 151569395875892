import React from "react"
import { Alert, Popover, Button } from 'antd'
import { Link } from "react-router-dom";

import { ProjectServiceHistory } from '../../../../../../apis/project-service-history'

export const KLFAlert = (props: {projectId: string, hasAuth?: boolean}) => {
  const { projectId, hasAuth } = props;
  const [ customNameIdList, setCustomNameIdList] = React.useState<any[]>([])

  const getCustomNameIdList = () => {
    ProjectServiceHistory.queryCustomNameIdList({projectId})
      .then((resp: any)=>{
          const { data=[] } = resp
          setCustomNameIdList(data)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  React.useEffect(()=>{
    getCustomNameIdList()
  },[])

  return (
    <Alert 
      message={
        <div>
          若不符合项目详情，请选择添加 
          { customNameIdList.length >= 6
            ? 
            <Popover content={"最多只能添加 6 条自定义电价"} trigger="hover">
            <Button 
              type='link' 
              style={{ padding: '0' }} 
              disabled
            >
              自定义电价
            </Button>
          </Popover>
          :
          <Link to={`/program/edge/${projectId}/electricity/add`}>
            <Button 
              type='link'
              disabled={!hasAuth}
              style={{ padding: '0' }} 
            >
              自定义电价
            </Button>
          </Link>
        }
        </div>
      }
      style = {{
        width: '685px',
        marginBottom: '20px',
        paddingTop: '2px',
        paddingBottom: '2px',
        lineHeight: '20px',
        color: '#1CB887',
        border: '0',
      }}
    />
  )
}
