import { Button, Form, Input, message } from 'antd'
import { useState } from 'react'
import { Link } from 'react-router-dom'
import { EyeInvisibleOutlined } from '@ant-design/icons'
import Verify from './verify'
import { UserService } from '../../../apis/project-service-history'
import { token } from '../../../utils/utils'
import VerifyCode from './verify-code'
import { sm2 } from 'sm-crypto'
import { SM2CODE } from '../../../utils/constant'
import { PHONE_EXP } from '../../../utils/constant'
import styles from '../index.module.scss'
import { PasswordNotice } from './password-notice'

let fn: any = null
// 忘记密码
function ForgetPassword() {
  const [form] = Form.useForm()
  const [loading, setLoading] = useState(false)
  // 图片滑块验证成功状态
  const [verifyPass, setVerifyPass] = useState('')
  const [visible, setVisible] = useState(false)
  const [showPwd, setShowPwd] = useState(false)
  // 验证手机状态
  const [checkPhone, setCheckUsername] = useState<any>({
    validateStatus: ' ',
    help: '',
  })
  // 验证手机验证码状态
  const [checkCode, setCodePhone] = useState<any>({
    validateStatus: ' ',
    help: '',
  })

  const onModalCancel = () => {
    setVisible(false)
  }

  const onSendCode = async (callback: any) => {
    const username = form.getFieldValue('username')
    if (!username) {
      setCheckUsername({
        validateStatus: 'error',
        help: '请输入手机号',
      })
      return
    } else {
      setCheckUsername({
        validateStatus: '',
        help: ' ',
      })
    }
    await setVisible(true)
    fn = callback
  }

  const validateFields = (values: any) => {
    let errs = []
    if (!values.username) {
      setCheckUsername({
        validateStatus: 'error',
        help: '请输入手机号',
      })
      errs.push(values.username)
    } else {
      setCheckUsername({
        validateStatus: ' ',
        help: ' ',
      })
    }
    if (!values.captchaVerification) {
      setCodePhone({
        validateStatus: 'error',
        help: '请验证手机号',
      })
      errs.push('captchaVerification')
    } else {
      setCodePhone({
        validateStatus: ' ',
        help: ' ',
      })
    }
    if (!values.code) {
      setCodePhone({
        validateStatus: 'error',
        help: '请输入验证码',
      })
      errs.push('code')
    } else {
      setCodePhone({
        validateStatus: ' ',
        help: ' ',
      })
    }

    return errs
  }
  const [showNotice, setShowNotice] = useState(false)

  // 请求验证码
  const getCode = (phone: any, captchaVerification: string) => {
    return UserService.sendMobileValid({
      mobile: phone,
      captchaVerification,
    }).then((res) => {
      if (res.msg === 'success') {
        message.success('验证码已发送,请注意查收短信')
      } else {
        message.error(res.msg)
        return Promise.reject()
      }
    })
  }

  // 判断手机号是否存在
  const validPhone = (e: string) => {
    console.info(e)
  }

  // 重置
  const handleResetPassword = () => {
    const values = form.getFieldsValue(['username', 'captchaVerification', 'code', 'password'])
    validateFields(values)
    form.validateFields().then(() => {
      const validErrors = validateFields(values)
      if (validErrors.length > 0) {
        return
      }
      setLoading(true)
      UserService.updatePwdByMobile({
        mobile: values.username,
        validCode: values.code,
        newPassword: sm2.doEncrypt(values.password, SM2CODE, 0),
        root: {
          ...values,
          captchaVerification: verifyPass,
        },
      })
        .then((res) => {
          if (res.code === 200) {
            token.set(res?.data?.token)
            message.success('重置密码成功')
            location.assign('#/login/index')
          } else {
            message.error(res.msg)
          }
        })
        .catch(() => {})
        .finally(() => {
          setLoading(false)
        })
    })
  }

  const [password, setPassword] = useState('')

  return (
    <div className={styles.forgetPage}>
      <h1 style={{ marginBottom: '3px', fontSize: 36 }}>重置密码</h1>
      <div className="desc-text" style={{ fontSize: 16, marginBottom: 30 }}>
        通过您的手机号重置密码，或
        <Link to="/login/forget-email "> 通过邮箱重置</Link>
      </div>
      <div>
        <Form
          form={form}
          labelAlign="left"
          style={{
            width: '100%',
          }}
        >
          <Form.Item
            name="username"
            validateStatus={checkPhone.validateStatus}
            help={checkPhone.help ? checkPhone.help : undefined}
          >
            <Input
              size="large"
              onBlur={(e) => {
                if (!e.target.value) {
                  setCheckUsername({
                    validateStatus: 'error',
                    help: '手机号必填',
                  })
                } else if (!PHONE_EXP.test(e.target.value)) {
                  setCheckUsername({
                    validateStatus: 'error',
                    help: '格式不正确',
                  })
                } else {
                  setCheckUsername({
                    validateStatus: '  ',
                    help: null,
                  })
                  validPhone(e.target.value)
                }
              }}
              type="phone"
              placeholder="请输入手机号"
            />
          </Form.Item>
          <Form.Item hidden name="captchaVerification">
            <Verify
              mode="modal"
              update={async (e: any) => {
                const phone = form.getFieldValue('username')
                setVerifyPass(e)
                if (fn) {
                  fn(phone, e)
                  setCheckUsername({
                    validateStatus: '  ',
                    help: null,
                  })
                }
              }}
              onModalCancel={onModalCancel}
              modalVisible={visible}
              isVerifyPass={!!verifyPass}
            />
          </Form.Item>
          <Form.Item
            name="code"
            validateStatus={checkCode.validateStatus}
            help={checkCode.help ? checkCode.help : undefined}
          >
            <VerifyCode
              setVisible={setVisible}
              onSendCode={onSendCode}
              disabledTip="请先输入手机号"
              disabled={checkPhone.validateStatus === 'error' || checkPhone.help === ''}
              setValidate={setCheckUsername}
              sendFn={UserService.sendMobileValid}
              field="mobile"
              customRequest={getCode}
              form={form}
              type="login"
            />
          </Form.Item>
          <Form.Item
            name="password"
            rules={[
              {
                required: true,
                message: '密码必填',
              },
              {
                min: 8,
                max: 16,
                message: '密码长度8~16位',
              },
              {
                pattern: /^.*(?=.*\d)(?=.*[A-Z])(?=.*[a-z]).*$/,
                message: '必须包含大写字母小写字母和数字',
              },
              {
                pattern: /((?=[\x21-\x7e]+)[^A-Za-z0-9])/,
                message: '必须包含特殊字符',
              },
            ]}
          >
            <Input
              size="large"
              type={showPwd ? 'text' : 'password'}
              placeholder="请输入新密码"
              onFocus={() => {
                setShowNotice(true)
              }}
              onBlur={() => {
                setShowNotice(false)
              }}
              onChange={(dom) => {
                setPassword(dom.target.value)
                form.setFieldsValue({ password: dom.target.value })
              }}
              value={password}
              suffix={
                <EyeInvisibleOutlined
                  className="show-pwd-icon"
                  onMouseDown={() => {
                    setShowPwd(true)
                  }}
                  onMouseUp={() => {
                    setShowPwd(false)
                  }}
                />
              }
            />
            {showNotice && <PasswordNotice password={password || ''} />}
          </Form.Item>
          <Form.Item>
            <Button
              size="large"
              style={{
                width: '100%',
                height: 42,
                marginTop: '40px',
              }}
              type="primary"
              onClick={handleResetPassword}
              loading={loading}
            >
              重置密码
            </Button>
          </Form.Item>
          <Form.Item>
            <Button
              type="link"
              style={{
                width: '100%',
              }}
              onClick={() => {
                location.assign('#/login/index')
              }}
              loading={loading}
            >
              返回登录
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  )
}

export default ForgetPassword
