import React, {useEffect, useState} from 'react'
import {parseSuffixNumber} from './resource-map'
import {Drawer} from 'antd'
import {BreadcrumbObservable} from '../../application'
import {ResourceMapboxMountain} from './resource-mapbox-mountain'

export const preMap: { [key: string]: { radix?: number; type?: string; div?: number, formatter?: (val: number) => string } } = {
  regionAreaSort: {
    radix: 2,
    type: 'e',
    div: 1000000
  }, //'}>区域面积 k㎡</Select.Option>
  yearTotalIrradianceSort: {
    radix: 2,
    type: 'custom'
  }, //'}>年辐照 kWh/㎡</Select.Option>
  roofCountSort: {
    radix: 0,
    type: 'custom'
  }, //'}>武定数量 个</Select.Option>
  roofAreaSort: {
    radix: 2,
    type: 'custom'
  }, //'}>屋顶面积 ㎡</Select.Option>
  avgRoofAreaSort: {
    radix: 2,
    type: 'custom'
  }, //'}>屋顶面积平均值 ㎡</Select.Option>
  roofRatioSort: {
    formatter: (val: number) => {
      return parseSuffixNumber(val * 1000, 2)
    }
  } //'}>覆盖率 %</Select.Option>
}

export const ResourceMountain = () => {
  const [showSift, setShowSift] = useState(true)
  const [showRoofSift, setShowRoofSift] = useState(false)

  const [hideCluster, setHideCluster] = useState(true)

  const [showTool, setShowTool] = useState(false)

  useEffect(() => {
    BreadcrumbObservable.next([])
  }, [])
  return (
    <div>
      <ResourceMapboxMountain
        hideCluster={hideCluster}
        doHideCluster={() => {
          setHideCluster((cluster) => !cluster)
        }}
        showSift={showSift} showRoofSift={showRoofSift} showSiftHandler={(showSift: boolean) => {
        setShowSift(showSift)
      }} showRoofSiftHandler={(showSift: boolean) => {
        setShowRoofSift(showSift)
      }}
        // onCalc={(val) => {
        //   setArea(val);
        //   setShowTool(true)
        // }}
      />
      <Drawer
        getContainer={false}
        mask={false}
        maskClosable
        closable={false}
        destroyOnClose={true}
        placement='right'
        onClose={() => setShowTool(false)}
        visible={showTool}
        className='no-drawer-boxshadow mask-transparent'
        style={{position: 'absolute', backgroundColor: 'transparent'}}
        width={334}
        bodyStyle={{
          padding: 0
        }}
      />
    </div>
  )
}
