import React, { useState } from 'react'
import { Form, DatePicker, Select } from 'antd'
import { TransformerList } from './transformer-list'
import moment from 'moment'
import { CustomIcons, validate7Days } from '../../../../global'

let lastDateRange: any
export const BearTemplate = (props: {
  config: {
    form: any
  }
}) => {
  const [dates, setDates] = useState<moment.Moment[]>([])

  const onOpenChange = (open: boolean) => {
    if (open) {
      lastDateRange = props.config.form.getFieldValue('timeRange')
      props.config.form.setFieldsValue({ timeRange: [] })
      setDates([])
    } else {
      if (lastDateRange) {
        props.config.form.setFieldsValue({ timeRange: lastDateRange })
      }
    }
  }

  const disabledDate = (current: moment.Moment) => {
    if (!dates || dates.length === 0) {
      return false
    }
    const tooEarly = dates[0] && current.diff(dates[0], 'days') < 7 && dates[0] && current.diff(dates[0], 'days') >= 0
    const tooLate = dates[1] && dates[1].diff(current, 'days') < 7 && dates[1] && dates[1].diff(current, 'days') >= 0
    return tooEarly || tooLate
  }

  return (
    <div>
      <Form wrapperCol={{ span: 24 }} labelCol={{ span: 24 }} form={props.config.form}>
        <Form.Item
          name="timeRange"
          style={{
            marginBottom: '1rem',
          }}
          label="数据时间段"
          help="*请设置7天以上的时间跨度以满足模型计算要求"
          required
          rules={[
            {
              required: true,
              message: '*请设置7天以上的时间跨度以满足模型计算要求',
            },
            { validator: validate7Days, message: '*请设置7天以上的时间跨度以满足模型计算要求' },
          ]}
        >
          <DatePicker.RangePicker
            disabledDate={disabledDate}
            onOpenChange={onOpenChange}
            style={{
              width: '100%',
              marginBottom: '.5rem',
            }}
            suffixIcon={<CustomIcons type="klf-mdatepicker" />}
            placeholder={
              lastDateRange
                ? lastDateRange.map(
                    (o: any, idx: number) => o.format?.('YYYY-MM-DD') || (idx === 0 ? '开始日期' : '结束日期')
                  )
                : ['开始日期', '结束日期']
            }
            onCalendarChange={(val: any) => {
              lastDateRange = undefined
              setDates(val)
            }}
          />
        </Form.Item>
        <Form.Item
          name="level"
          style={{
            marginBottom: '1rem',
          }}
          label="数据时间颗粒度"
          required
          rules={[
            {
              required: true,
              message: '请选择数据时间颗粒度',
            },
          ]}
        >
          <Select placeholder="请选择">
            {[1, 5, 10, 15, 20, 30, 60].map((o) => (
              <Select.Option value={o} key={o}>
                {o}分钟
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          name="transformer"
          label="变压器信息"
          style={{
            marginBottom: '1rem',
          }}
          rules={[
            {
              validator: (rule: any, value: any) => {
                return new Promise((resolve, reject) => {
                  if (!value || value.length === 0) {
                    reject('请添加变压器信息')
                  }
                  for (let i = 0; i < value.length; i++) {
                    if (!/^[\u4E00-\u9FA5A-Za-z0-9_-]{1,12}$/.test(value[i].transformerName)) {
                      reject('变压器名称为1-12个字符,中文、英文、_、数字和-组成')
                    }
                    if (!value[i].maxCapacity) {
                      reject('请选择变压器容量')
                    }
                  }
                  resolve('')
                })
              },
            },
          ]}
          extra="*最多可添加6条变压器信息"
        >
          <TransformerList />
        </Form.Item>
      </Form>
    </div>
  )
}
