import React from 'react'
import { Modal, Form, Input, message } from 'antd'
import { ContractService } from '../../../../apis/wl-service'

export interface ModalProps {
  id: string
  visible: boolean
  title?: string
  visibleChange: (visible: boolean) => void
  success?: () => void
}

export default ({ id, visible, title, visibleChange, success }: ModalProps) => {
  const [form] = Form.useForm()
  const [loading, setLoading] = React.useState<boolean>(false)

  const handleOk = async () => {
    if (loading) {
      return
    }

    try {
      const values: any = await form.validateFields()
      setLoading(true)
      try {
        const resp: any = await ContractService.stateUpdate({ id, type: 2, root: values })
        if (resp?.code === 200) {
          message.success(resp.msg)
          visibleChange(false)
          success?.()
        } else if (resp?.msg) {
          message.error(resp.msg)
        }
      } catch (error) {
        console.warn(error)
      } finally {
        setLoading(false)
      }
    } catch (err) {
      console.warn(err)
    }
  }

  return (
    <Modal
      keyboard={false}
      maskClosable={false}
      title={title ?? '合同作废'}
      visible={visible}
      width={640}
      okButtonProps={{
        loading,
      }}
      onCancel={() => visibleChange(false)}
      onOk={handleOk}
    >
      <Form form={form} layout="vertical" labelWrap>
        <Form.Item
          label="作废原因"
          name="memo"
          rules={[
            {
              required: true,
              message: '请输入作废原因',
            },
          ]}
        >
          <Input.TextArea allowClear showCount maxLength={200} autoSize={{ minRows: 3 }} placeholder="请输入作废原因" />
        </Form.Item>
      </Form>
    </Modal>
  )
}
