import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Space, Table, Tag } from 'antd'
import CustomPagination from '../Pagination'

import '../../index.less'

export type IProps = {
  list: any[]
  total: number
  pageNum: number
  pageSize: number
  onChange?: (pageNum: number, pageSize: number) => void
}

const PowerStationList = React.memo((props: IProps) => {
  const { list, total, pageNum, pageSize, onChange } = props

  const navigate = useNavigate()

  const columns = [
    {
      title: '电站名称',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: '所属项目',
      dataIndex: 'projectName',
      key: 'projectName',
    },
    {
      title: '装机容量(kW)',
      dataIndex: 'installedCapacity',
      key: 'installedCapacity',
    },
    {
      title: '电压等级(kV)',
      dataIndex: 'voltageLevel',
      key: 'voltageLevel',
    },
    {
      title: '并网日期',
      dataIndex: 'gridConnectionDate',
      key: 'gridConnectionDate',
    },
    {
      title: '设备数量',
      dataIndex: 'deviceNum',
      key: 'deviceNum',
      width: 120,
      render: (text: number) => <Tag color="green">{text}</Tag>,
    },
    {
      title: '操作',
      key: 'action',
      width: 64,
      render: (record: any) => {
        const { powerStationId } = record || {}
        return (
          <div className="rights-template-action">
            <Space>
              <div
                className="rights-template-action-set"
                onClick={() => navigate(`/operation-management/power-station/detail/${powerStationId}`)}
              >
                查看
              </div>
            </Space>
          </div>
        )
      },
    },
  ]

  return (
    <section className="power-station">
      <div className="power-station-list" style={{minHeight: 'calc(100vh - 270px)'}}>
        <Table className="rights-template-table" columns={columns} dataSource={list} pagination={false} />
      </div>
      <div className="power-station-footer">
        <CustomPagination
          total={total}
          pageNum={pageNum}
          pageSize={pageSize}
          onChange={(pageNum, pageSize) => onChange?.(pageNum, pageSize)}
        />
      </div>
    </section>
  )
})

export default PowerStationList
