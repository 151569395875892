import { Button, Form, Input, Space, Table } from 'antd'
import React from 'react'
import '../index.less'
import styles from '../../../program/manage/detail/index.module.scss'
import { FinanceService } from '../../../../apis/project-service-history'
import { CustomIcons, getFixedNumber } from '../../../../global'

const cellWidth = 110

const layout = {
  labelCol: { span: 9 },
  wrapperCol: { span: 15 },
}

const formatRes = (res?: string | number, unit?: string, show: boolean = false) => {
  if (!show) {
    return ''
  }
  if (res || res === 0) {
    return (
      <span
        style={{
          whiteSpace: 'nowrap',
        }}
      >
        {`${getFixedNumber(parseFloat(res.toString()), 2)} ${unit}`}
      </span>
    )
  } else {
    return '--'
  }
}
const query: any = {
  page: 1,
  size: 500,
  total: 0,
  pvChange: false,
  energyChange: false,
  marked: undefined,
  locked: undefined,
  installedCapacityMin: undefined,
  installedCapacityMax: undefined,
  dayAvgPowerGenerationMin: undefined,
  dayAvgPowerGenerationMax: undefined,
  useHourYearMin: undefined,
  useHourYearMax: undefined,
  selfAbsorptionRateMin: undefined,
  selfAbsorptionRateMax: undefined,
  annualGenerationHoursMin: undefined,
  annualGenerationHoursMax: undefined,
  batteryCapacityMin: undefined,
  batteryCapacityMax: undefined,
  pcsPowerMin: undefined,
  pcsPowerMax: undefined,
  dayAvgDischargeMin: undefined,
  dayAvgDischargeMax: undefined,
  dayAvgChargeDischargeMin: undefined,
  dayAvgChargeDischargeMax: undefined,

  createTimeSort: '0',
  updateTimeSort: '0',
  installedCapacitySort: '0',
  dayAvgPowerGenerationSort: '0',
  useHourYearSort: '0',
  selfAbsorptionRateSort: '0',
  annualGenerationHoursSort: '0',
  batteryCapacitySort: '0',
  pcsPowerSort: '0',
  dayAvgDischargeSort: '0',
  dayAvgChargeDischargeSort: '0',
}

export const TechPlanList = (props: { projectId: string; onClose: (reload?: boolean) => void; selected: string[] }) => {
  // 2022-07-08新需求：移除多选框、底部按钮；列表只显示已归档方案
  // const [uploading, setUploading] = React.useState(false)
  const [techPlans, setTechPlans] = React.useState<any>([])

  // const [selectedRows, setSelectedRows] = React.useState<React.Key[]>(props.selected || [])

  const [pvForm] = Form.useForm()
  const [energyForm] = Form.useForm()

  const queryList = () => {
    FinanceService.list5({
      projectId: props.projectId,
      ...query,
    }).then((resp) => {
      const data = resp?.data || []
      const plans = data.map((o: any) => ({
        key: o.id,
        ...o,
      }))
      const lockedPlans = plans.filter((item) => item.locked)
      setTechPlans(lockedPlans)
    })
  }

  React.useEffect(() => {
    queryList()
  }, [])

  const energyFilter = (values: any, confirm: Function) => {
    query.energyChange = true
    Object.keys(values).forEach((key) => {
      const val = values[key]
      query[key] = val
    })
    confirm()
  }

  const pvFilter = (values: any, confirm: Function) => {
    query.pvChange = true
    Object.keys(values).forEach((key) => {
      const val = values[key]
      query[key] = val
    })
    confirm()
  }
  // 光伏指标筛选
  const PVFilterDom = ({ confirm }: any) => {
    return (
      <div className={styles.customFilter}>
        <Form
          form={pvForm}
          onFinish={(values) => {
            pvFilter(values, confirm)
          }}
          {...layout}
          colon={false}
        >
          <Form.Item label="装机容量区间">
            <Input.Group compact>
              <Form.Item noStyle name="installedCapacityMin">
                <Input type="number" style={{ width: 101 }} placeholder="最小值" />
              </Form.Item>
              <Input
                className="site-input-split"
                style={{
                  width: 30,
                  borderLeft: 0,
                  borderRight: 0,
                  pointerEvents: 'none',
                }}
                placeholder="-"
                disabled
              />
              <Form.Item noStyle name="installedCapacityMax">
                <Input type="number" style={{ width: 130 }} placeholder="最大值" suffix="kW" />
              </Form.Item>
            </Input.Group>
          </Form.Item>
          <Form.Item label="日均发电区间">
            <Input.Group compact>
              <Form.Item noStyle name="dayAvgPowerGenerationMin">
                <Input type="number" style={{ width: 101 }} placeholder="最小值" />
              </Form.Item>
              <Input
                className="site-input-split"
                style={{
                  width: 30,
                  borderLeft: 0,
                  borderRight: 0,
                  pointerEvents: 'none',
                }}
                placeholder="-"
                disabled
              />
              <Form.Item noStyle name="dayAvgPowerGenerationMax">
                <Input type="number" style={{ width: 130 }} placeholder="最大值" suffix="kWh" />
              </Form.Item>
            </Input.Group>
          </Form.Item>
          <Form.Item label="年利用小时数区间">
            <Input.Group compact>
              <Form.Item noStyle name="useHourYearMin">
                <Input type="number" style={{ width: 101 }} placeholder="最小值" />
              </Form.Item>
              <Input
                className="site-input-split"
                style={{
                  width: 30,
                  borderLeft: 0,
                  borderRight: 0,
                  pointerEvents: 'none',
                }}
                placeholder="-"
                disabled
              />
              <Form.Item noStyle name="useHourYearMax">
                <Input type="number" style={{ width: 130 }} placeholder="最大值" suffix="h" />
              </Form.Item>
            </Input.Group>
          </Form.Item>
          <Form.Item label="自消纳比区间">
            <Input.Group compact>
              <Form.Item noStyle name="selfAbsorptionRateMin">
                <Input type="number" style={{ width: 101 }} placeholder="最小值" />
              </Form.Item>
              <Input
                className="site-input-split"
                style={{
                  width: 30,
                  borderLeft: 0,
                  borderRight: 0,
                  pointerEvents: 'none',
                }}
                placeholder="-"
                disabled
              />
              <Form.Item noStyle name="selfAbsorptionRateMax">
                <Input type="number" style={{ width: 130 }} placeholder="最大值" suffix="%" />
              </Form.Item>
            </Input.Group>
          </Form.Item>
          <Form.Item label="年发电小时数区间">
            <Input.Group compact>
              <Form.Item noStyle name="annualGenerationHoursMin">
                <Input type="number" style={{ width: 101 }} placeholder="最小值" />
              </Form.Item>
              <Input
                className="site-input-split"
                style={{
                  width: 30,
                  borderLeft: 0,
                  borderRight: 0,
                  pointerEvents: 'none',
                }}
                placeholder="-"
                disabled
              />
              <Form.Item noStyle name="annualGenerationHoursMax">
                <Input type="number" style={{ width: 130 }} placeholder="最大值" suffix="h" />
              </Form.Item>
            </Input.Group>
          </Form.Item>
          <Form.Item label=" " className="filter-btns">
            <Space>
              <Button size="small" type="primary" htmlType="submit">
                确定
              </Button>
            </Space>
          </Form.Item>
        </Form>
      </div>
    )
  }

  // 储能指标筛选
  const energyFilterDom = ({ confirm }: any) => {
    return (
      <div className={styles.customFilter}>
        <Form
          form={energyForm}
          onFinish={(values) => {
            energyFilter(values, confirm)
          }}
          {...layout}
          colon={false}
        >
          <Form.Item label="电池容量区间">
            <Input.Group compact>
              <Form.Item noStyle name="batteryCapacityMin">
                <Input type="number" style={{ width: 101 }} placeholder="最小值" />
              </Form.Item>
              <Input
                className="site-input-split"
                style={{
                  width: 30,
                  borderLeft: 0,
                  borderRight: 0,
                  pointerEvents: 'none',
                }}
                placeholder="-"
                disabled
              />
              <Form.Item noStyle name="batteryCapacityMax">
                <Input type="number" style={{ width: 130 }} placeholder="最大值" suffix="kWh" />
              </Form.Item>
            </Input.Group>
          </Form.Item>
          <Form.Item label="PCS功率区间">
            <Input.Group compact>
              <Form.Item noStyle name="pcsPowerMin">
                <Input type="number" style={{ width: 101 }} placeholder="最小值" />
              </Form.Item>
              <Input
                className="site-input-split"
                style={{
                  width: 30,
                  borderLeft: 0,
                  borderRight: 0,
                  pointerEvents: 'none',
                }}
                placeholder="-"
                disabled
              />
              <Form.Item noStyle name="pcsPowerMax">
                <Input type="number" style={{ width: 130 }} placeholder="最大值" suffix="kW" />
              </Form.Item>
            </Input.Group>
          </Form.Item>
          <Form.Item label="日均放电区间">
            <Input.Group compact>
              <Form.Item noStyle name="dayAvgDischargeMin">
                <Input type="number" style={{ width: 101 }} placeholder="最小值" />
              </Form.Item>
              <Input
                className="site-input-split"
                style={{
                  width: 30,
                  borderLeft: 0,
                  borderRight: 0,
                  pointerEvents: 'none',
                }}
                placeholder="-"
                disabled
              />
              <Form.Item noStyle name="dayAvgDischargeMax">
                <Input type="number" style={{ width: 130 }} placeholder="最大值" suffix="kWh" />
              </Form.Item>
            </Input.Group>
          </Form.Item>
          <Form.Item label="日均充放电区间">
            <Input.Group compact>
              <Form.Item noStyle name="dayAvgChargeDischargeMin">
                <Input type="number" style={{ width: 101 }} placeholder="最小值" />
              </Form.Item>
              <Input
                className="site-input-split"
                style={{
                  width: 30,
                  borderLeft: 0,
                  borderRight: 0,
                  pointerEvents: 'none',
                }}
                placeholder="-"
                disabled
              />
              <Form.Item noStyle name="dayAvgChargeDischargeMax">
                <Input type="number" style={{ width: 130 }} placeholder="最大值" suffix="次" />
              </Form.Item>
            </Input.Group>
          </Form.Item>
          <Form.Item label=" " className="filter-btns">
            <Space>
              <Button size="small" type="primary" htmlType="submit">
                确定
              </Button>
            </Space>
          </Form.Item>
        </Form>
      </div>
    )
  }

  const columns = [
    {
      title: '方案名称',
      dataIndex: 'name',
      key: 'name',
      width: 200,
      fixed: 'left' as any,
    },
    {
      title: '光伏指标',
      dataIndex: '',
      key: 'PV_quota',
      filterIcon: <CustomIcons type="klf-filter" />,
      filterDropdown: PVFilterDom,
      onFilterDropdownVisibleChange: (visible: boolean) => {
        if (!visible) {
          if (query.pvChange) {
            query.pvChange = false
            queryList()
          }
        } else {
          const {
            installedCapacityMin,
            installedCapacityMax,
            dayAvgPowerGenerationMin,
            dayAvgPowerGenerationMax,
            useHourYearMin,
            useHourYearMax,
            selfAbsorptionRateMin,
            selfAbsorptionRateMax,
            annualGenerationHoursMin,
            annualGenerationHoursMax,
          } = query

          pvForm.setFieldsValue({
            installedCapacityMin,
            installedCapacityMax,
            dayAvgPowerGenerationMin,
            dayAvgPowerGenerationMax,
            useHourYearMin,
            useHourYearMax,
            selfAbsorptionRateMin,
            selfAbsorptionRateMax,
            annualGenerationHoursMin,
            annualGenerationHoursMax,
          })
        }
      },
      children: [
        {
          title: '装机容量',
          dataIndex: 'installedCapacity',
          key: 'installedCapacity',
          sorter: true,
          width: cellWidth,
          render: (res: string, row: any) => {
            return formatRes(res, 'kW', row.calcStatus === 2)
          },
        },
        {
          title: '日均发电',
          dataIndex: 'dayAvgPowerGeneration',
          key: 'dayAvgPowerGeneration',
          sorter: true,
          width: cellWidth,
          render: (res: string, row: any) => {
            return formatRes(res, 'kWh', row.calcStatus === 2)
          },
        },
        {
          title: '年利用小时数',
          dataIndex: 'useHourYear',
          key: 'useHourYear',
          sorter: true,
          width: cellWidth + 30,
          render: (res: string, row: any) => {
            return formatRes(res, 'h', row.calcStatus === 2)
          },
        },
        {
          title: '自消纳比',
          dataIndex: 'selfAbsorptionRate',
          key: 'selfAbsorptionRate',
          sorter: true,
          width: cellWidth,
          render: (res: string, row: any) => {
            return formatRes(res, '%', row.calcStatus === 2)
          },
        },
        {
          title: '年发电小时数',
          dataIndex: 'annualGenerationHours',
          key: 'annualGenerationHours',
          sorter: true,
          width: cellWidth + 30,
          render: (res: string, row: any) => {
            return formatRes(res, 'h', row.calcStatus === 2)
          },
        },
      ],
    },
    {
      title: '储能指标',
      dataIndex: 'energy_quota',
      key: 'energy_quota',
      filterIcon: <CustomIcons type="klf-filter" />,
      filterDropdown: energyFilterDom,
      onFilterDropdownVisibleChange: (visible: boolean) => {
        if (!visible) {
          if (query.energyChange) {
            query.energyChange = false
            queryList()
          } else {
            const {
              batteryCapacityMin,
              batteryCapacityMax,
              pcsPowerMin,
              pcsPowerMax,
              dayAvgDischargeMin,
              dayAvgDischargeMax,
              dayAvgChargeDischargeMin,
              dayAvgChargeDischargeMax,
            } = query

            energyForm.setFieldsValue({
              batteryCapacityMin,
              batteryCapacityMax,
              pcsPowerMin,
              pcsPowerMax,
              dayAvgDischargeMin,
              dayAvgDischargeMax,
              dayAvgChargeDischargeMin,
              dayAvgChargeDischargeMax,
            })
          }
        }
      },
      children: [
        {
          title: '电池容量',
          dataIndex: 'batteryCapacity',
          key: 'batteryCapacity',
          sorter: true,
          width: cellWidth,
          render: (res: string, row: any) => {
            return formatRes(res, 'kWh', row.calcStatus === 2)
          },
        },
        {
          title: 'PCS总功率',
          dataIndex: 'pcsPower',
          key: 'pcsPower',
          sorter: true,
          width: cellWidth + 15,
          render: (res: string, row: any) => {
            return formatRes(res, 'kW', row.calcStatus === 2)
          },
        },
        {
          title: '日均放电',
          dataIndex: 'dayAvgDischarge',
          key: 'dayAvgDischarge',
          sorter: true,
          width: cellWidth,
          render: (res: string, row: any) => {
            return formatRes(res, 'kWh', row.calcStatus === 2)
          },
        },
        {
          title: '日均循环',
          dataIndex: 'dayAvgChargeDischarge',
          key: 'dayAvgChargeDischarge',
          sorter: true,
          width: cellWidth + 15,
          render: (res: string, row: any) => {
            return formatRes(res, '次', row.calcStatus === 2)
          },
        },
      ],
    },
  ]

  // const rowSelection = {
  //   selectedRowKeys: selectedRows,
  //   onChange: (selectedRowKeys: React.Key[]) => {
  //     const corKeys = selectedRowKeys.filter((key: React.Key) => {
  //       return techPlans.find((o: any) => o.key === key)!.locked
  //     })
  //     if (corKeys.length !== selectedRowKeys.length) {
  //       message.error('请先归档技术方案')
  //     }
  //     setSelectedRows(corKeys)
  //   },
  //   getCheckboxProps: (record: any) => ({
  //     // disabled: record.locked ? false : true, // Column configuration not to be checked
  //     name: record.name,
  //   }),
  // }

  // 过滤  排序
  const changeTable = (pager: any, filter: any, sorter: any) => {
    query.page = pager.current
    query.size = pager.pageSize

    const { order, field } = sorter
    let ope = '0'
    if (order === 'ascend') {
      ope = '1'
    }
    if (order === 'descend') {
      ope = '2'
    }

    Object.keys(query).forEach((key) => {
      if (key.includes('Sort')) {
        query[key] = '0'
      }
    })

    query[`${field}Sort`] = ope

    queryList()
  }
  return (
    <div>
      <Table
        className="small-th"
        scroll={{ x: '100%' }}
        dataSource={techPlans}
        columns={columns}
        // rowSelection={rowSelection}
        pagination={false}
        onChange={changeTable}
      />
      {/* <div className="ant-modal-confirm-btns">
        <Button
          onClick={() => {
            props.onClose?.()
          }}
        >
          取 消
        </Button>
        <Button
          type="primary"
          loading={uploading}
          onClick={() => {
            setUploading(true)
            FinanceService.ref({
              projectId: props.projectId,
              root: { tpIdList: selectedRows },
            })
              .then(() => {
                props.onClose?.(true)
              })
              .catch(() => {})
              .finally(() => setUploading(false))
          }}
        >
          确 定
        </Button>
      </div> */}
    </div>
  )
}
