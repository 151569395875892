import styles from '../index.module.scss'
import React, { useEffect, useState } from 'react'
import { Input, Tree, Checkbox, Row, Col, Tooltip, Typography, DatePicker, Button, Spin } from 'antd'
import {
  CaretDownOutlined,
  DownOutlined,
  UpOutlined,
  SearchOutlined
} from '@ant-design/icons';
import moment from 'moment'
import EquipmentVoltageLine from './equipment-voltage-line'
import treeIcon1 from '../../../assets/equipment-station/treeIcon1.png'
import treeIcon2 from '../../../assets/equipment-station/treeIcon2.png'
import { EquipmentService } from '../../../apis/equipment-service'

const { TreeNode } = Tree

const MeasureSpot = (props) => {
  const { entityId } = props
  const [isQueryMove, setIsQueryMove] = useState<boolean>(true) // 当前显示的Tab
  

  const [treeData, setTreeData] = useState<any>([]) // 设备资产树 数据
  const [selectedKeys, setSelectedKeys] = useState<any>('') // 设备资产树 数据
  const [expandedKeys, setExpandedKeys] = useState<React.Key[]>([])
  const [autoExpandParent, setAutoExpandParent] = useState(false)
  const [pointDiagram, setPointDiagram] = useState<any>([]) // 测点图表详情 数据
  const [lineChartSpinning, setLineChartSpinning] = useState<boolean>(false)
  
  const [queryParameter, setQueryParameter] = useState<any>([]) // 查询条件 数据
  const [identifyList, setIdentifyList] = useState<any>([]) // 多选 查询条件 选中数据
  const time = Date.now()
    // 设置时间
  const [queryTime, setQueryTime] = useState<any>(moment(moment(time).format('YYYY-MM-DD'), 'YYYY-MM-DD')) // 多选 查询条件 选中数据

  useEffect(() => {
    // 测点树 数据
    EquipmentService.getMeasuringTree('')
      .then((res) => {
        if (res?.data) {
          setTreeData(res?.data || [])
          const id = res?.data[0].length !== 0 && res?.data[0]?.children[0].length !== 0 ? res?.data[0]?.children[0].entityId : ''
          
          setAutoExpandParent(true);
          // powerStationId && entityId 详情跳转过来的
          if (entityId) {
            setSelectedKeys(entityId)
            setExpandedKeys([entityId])
          } else {
            
            setSelectedKeys(id)
            setExpandedKeys(res?.data.map((item) => item.entityId))
          }
          
          // 测点数据图表查询选项 数据
          EquipmentService.getMeasuringPointCheckbox(entityId ? entityId : id)
          .then((resp) => {
            console.log(resp, '===测点数据图表查询选项')
            if (resp?.data) {
              setDefaultValueIdentifyList(resp?.data)
              setQueryParameter(resp?.data || [])
            }
          })
          .catch(() => {})
        }
      })
      .catch(() => {})
    
  }, [])

  // 设置默认选中 1-4 个 多选查询条件
  const setDefaultValueIdentifyList = (data: any) => {
    const time = Date.now()
    // 设置时间
    setQueryTime(moment(moment(time).format('YYYY-MM-DD'), 'YYYY-MM-DD'))

    setIdentifyList(data.map((item) => item.identify).slice(0, 4))
  }
  

  // 组装树
  const renderTreeNodes = (data: any) => {
    return (
      data &&
      data.map((item) => {
        if (item.children) {
          return (
            <TreeNode
              title={item.labelName}
              key={item.entityId}
              icon={<img src={treeIcon1} alt="" style={{ height: 20 }} />}
              selectable={item.isClick}

            >
              {renderTreeNodes(item.children)}
            </TreeNode>
          );
        }
        return (
          <TreeNode
            title={item.labelName}
            key={item.entityId}
            selectable={item.isClick}
            icon={<img src={treeIcon2} alt="" style={{ height: 20 }} />}
          />
        );
      })
    )
  }

  const onExpand = (newExpandedKeys: string[]) => {
    setExpandedKeys(newExpandedKeys);
    setAutoExpandParent(false);
  };

  // 点击树 回调
  const handleTree = (selectedKeys: any, e: any) => {
    const { node } = e
    
    if (selectedKeys.length !== 0) {
      setSelectedKeys(selectedKeys[0])
      setPointDiagram([])
      // 测点数据图表查询选项 数据
      if (!node.expanded) {
        EquipmentService.getMeasuringPointCheckbox(selectedKeys ? selectedKeys[0] : '')
          .then((res) => {
            if (res?.data) {
              setQueryParameter(res?.data || [])
              setDefaultValueIdentifyList(res?.data || [])
            }
          })
          .catch(() => {})
      }
    }
  }

  // 模糊查询回调
  const onChange = (e) => {
    const { value } = e.target
    EquipmentService.getMeasuringTree(value)
    .then((res) => {
      console.log(res, '===测点树')
      if (res?.data) {
        setTreeData(res?.data || [])
        setExpandedKeys(res?.data.map((item) => item.entityId))
      }
    })
    .catch(() => {})
  };

  // 多选回调
  const handleCheckbox = (e: any) => {
    setIdentifyList(e || [])
    let list = []
    pointDiagram.map((item) => {
      if (e.indexOf(item.identify) !== -1) {
        list.push(item)
      }
    })
    setPointDiagram(list)
  }

  // 查询 测点数据图表
  const handleQueryChartData = () => {
    setLineChartSpinning(true)
    EquipmentService.getMeasureSpotChartData({
      entityId: selectedKeys, // 节点id
      queryTime: moment(queryTime).format('YYYY-MM-DD'), // 时间
      identifyList: identifyList
    })
    .then((res) => {
      if (res?.data) {
        setLineChartSpinning(false)
        setPointDiagram(res?.data || [])
      }
    })
    .catch(() => {setLineChartSpinning(false)})
  }

  return (
    <div className={styles.measureSpotContainer}>
      <div className={styles.measureSpotLeft}>
        <Input
          size="large"
          type="text"
          className={styles.measureInput}
          placeholder="请搜索电站或设备"
          onChange={onChange}
          suffix={<SearchOutlined />}
        />

        <Tree
          className={styles.measureTree}
          showIcon
          onExpand={onExpand}
          autoExpandParent={autoExpandParent}
          defaultExpandAll={true}
          expandedKeys={expandedKeys}
          selectedKeys={[selectedKeys]}
          onSelect={handleTree}
          switcherIcon={<CaretDownOutlined />}
        >
          { renderTreeNodes(treeData) }
        </Tree>
      </div>
      <div className={styles.measureSpotRight}>
        <div className={styles.queryMove} onClick={() => {setIsQueryMove(!isQueryMove)}}>
          {isQueryMove ? <UpOutlined /> : <DownOutlined />} {isQueryMove ? '收起' : '展开'}
        </div>
        {
          (
            <div className={styles.queryParameterWarp} style={!isQueryMove ? {height: 130} : {}}>
              <Checkbox.Group 
                value={identifyList}
                style={{ width: '100%', minHeight: 10, borderBottom: '1px dashed #CFD6E8' }} 
                onChange={(e) => handleCheckbox(e)}
              >
                <Row style={!isQueryMove ? {height: 40, overflow: 'hidden'} : {}}>
                  {
                    queryParameter.map((item) => (
                      <Col span={6} style={{marginBottom: 20}} key={item.identify}>
                        <Checkbox value={item.identify}>
                          <Tooltip title={item.name}>
                            <Typography.Text ellipsis>{item.name}</Typography.Text>
                          </Tooltip>
                        </Checkbox>
                      </Col>
                    ))
                  }
                </Row>
              </Checkbox.Group>
              <div className={styles.queryDatePicker}>
                <span className={styles.queryDatePickerTitle}>日期</span>
                <DatePicker allowClear={false} format={'YYYY-MM-DD'} value={queryTime} onChange={(e) => {
                  setQueryTime(moment(moment(e).format('YYYY-MM-DD'), 'YYYY-MM-DD'))
                }} />
              </div>
            </div>
          )
        }
        <div className={styles.queryWarp}>
          <Button type="primary" disabled={identifyList?.length === 0} onClick={handleQueryChartData}>查询</Button>
        </div>
        <Spin spinning={lineChartSpinning}>
          <div className={styles.equipmentVoltageLine}>
            {
              pointDiagram.map((item, index) => (
                <EquipmentVoltageLine dataSource={item?.tsValueList || []} name={item.name} key={index} assetsTreeName={item?.assetsTreeName} unit={item.unit} />
              ))
            }
          </div>
        </Spin>
      </div>
    </div>
  )
}

export default MeasureSpot
