// import React from 'react'
import { Navigate } from 'react-router-dom'
import App from './application'
import AccountDetail from './pages/account-detail'
import UserCenterLayout from './pages/user-center/layout'
import UserCenter from './pages/user-center'
import UserParams from './pages/user-center/user-params'
import ProjectInvestment from './pages/user-center/user-params/components/project-investment'
import IncentiveSubsidy from './pages/user-center/user-params/components/incentive-subsidy'
import OperatingCost from './pages/user-center/user-params/components/operating-cost'
import FinancialCost from './pages/user-center/user-params/components/financial-cost'
import TimeZone from './pages/user-center/time-zone'
import Login from './pages/login'
import LoginPage from './pages/login/components/login'
import RegisterPage from './pages/login/components/register'
import ForgetPage from './pages/login/components/forget'
import ForgetEmailPage from './pages/login/components/forget-email'
import { ProgramManage } from './pages/program'
import { Edge } from './pages/program/edge'
import { Bear } from './pages/program/edge/bear'
import { Calc } from './pages/program/edge/calc'
import { Electricity } from './pages/program/edge/electricity'
import { Catalogue } from './pages/program/edge/electricity/catalogue'
import { NormFireCoal } from './pages/program/edge/electricity/norm-fire-coal'
import { AddElectricity } from './pages/program/edge/electricity/add'
import { ElectricityDetail } from './pages/program/edge/electricity/detail'
import { CreateElectricity } from './pages/program/edge/electricity/creat-electricity'
import { Weather } from './pages/program/edge/weather'
import Financial from './pages/program/financial'
import { ProgramManageList } from './pages/program/manage'
import { ProgramDetail } from './pages/program/manage/detail'
import Technical from './pages/program/technical'
import { ResourceReckon } from './pages/resource-reckon'
import { Workspace } from './pages/workspace/workspace'
import { TechnicalWorkspace } from './pages/workspace/technical'
import { FinancialWorkspace } from './pages/workspace/financial'
import { ResourceManage } from './pages/resource-manage'
import { PVParams } from './pages/user-center/user-params/components/pv-params'
import { BatteryParams } from './pages/user-center/user-params/components/battery-params'
import AuthTemplate from './pages/user-center/auth-template'
import AuthTemplateEdit from './pages/user-center/auth-template-edit'
import { GroupProjectDetail } from './pages/program/manage/settings'
import ProjectTemplateEdit from './pages/program/manage/settings/template'
import { GroupProjectRightsTemplate } from './pages/user-center/rights-template'
import { GroupProjectRightsTemplateEdit } from './pages/user-center/rights-template/edit'
import { GroupProjectRightsTemplateView } from './pages/user-center/rights-template/view'
import { ManagementCockpit } from './pages/cockpit-manage'
import { DataSummary } from './pages/data-summary'
import { ProjectEntry } from './pages/project-entry'
import ResourceDevelopmentEntry from './pages/project-entry/resource-development'
import ProjectCommonEntry from './pages/project-entry/project-moudle-detail'
import RecordEntry from './pages/project-entry/record'
import DecisionEntry from './pages/project-entry/decision'
import ConstructionEntry from './pages/project-entry/construction'
import CostEntry from './pages/project-entry/cost'
import InvestmentPlanEntry from './pages/project-entry/investment-plan'
import PlanExecutionEntry from './pages/project-entry/plan-execution'
import { ProjectDetail } from './pages/project-entry/project-detail'
// import { InvestDev } from './pages/invest-dev'
// import { PolicyManage } from './pages/policy'
import EnergyAgencyManage from './pages/invest-dev/energy-agency-manage'
import EnergyAgencyDetail from './pages/invest-dev/energy-agency-detail'
import InvestWorkbench from './pages/invest-dev/workbench'
import InvestProjectDocument from './pages/invest-dev/project-document'
import InvestProjectDocumentPhotos from './pages/invest-dev/project-document/photos'
import InvestProjectLibrary from './pages/invest-dev/project-library'
import InvestProjectLibraryCreate from './pages/invest-dev/project-library-create'
import InvestContractApproval from './pages/invest-dev/contract-approval'
import InvestContractForm from './pages/invest-dev/contract-approval/form'
import InvestContractDetail from './pages/invest-dev/contract-detail'
import InvestPartner from './pages/invest-dev/partner'
import PolicyManageList from './pages/policy/manage'
import PolicyDetail from './pages/policy/manage/detail'
import { WorkOrderHome } from './work-order/home/work-order-home'
import { WorkOrderList } from './work-order/home/list'
import { WorkOrderDetail } from './work-order/home/detail'
import { WorkOrderCreate } from './work-order/home/create'
import PowerStation from './pages/operation-management/power-station'
import PowerStationCreate from './pages/operation-management/power-station/Create'
import PowerStationDetail from './pages/operation-management/power-station/Detail'
import EquipmentStation from './pages/equipment-station'
import { TDataScreen } from './pages/operation-management/power-station/data-screen/t-data-screen'
// 工程管理 菜单
import { EngineeringManage } from './pages/engineering-manage'
import MaterialManage from './pages/engineering-manage/material'
import MaterialCreate from './pages/engineering-manage/material/create'
import MaterialDetails from './pages/engineering-manage/material/details'
import LogisticsManage from './pages/engineering-manage/logistics'
import LogisticsCreate from './pages/engineering-manage/logistics/create'
import LogisticsDetails from './pages/engineering-manage/logistics/details'
import EngineeringProjectLibraryCreate from './pages/engineering-manage/project-library-create'
import EngineeringCostManage from './pages/engineering-manage/cost-manage'
import EngineeringCost from './pages/engineering-manage/cost-form'
import {ResourceMountain} from "./pages/resource-reckon/resource-mountain";

export const routerConfig = [
  {
    path: 'login',
    element: <Login />,
    breadcrumbName: '',
    children: [
      {
        path: '/login',
        element: <Navigate replace to="index" />,
      },
      {
        path: 'index',
        element: <LoginPage />,
      },
      {
        path: 'register',
        element: <RegisterPage />,
      },
      {
        path: 'forget',
        element: <ForgetPage />,
      },
      {
        path: 'forget-email',
        element: <ForgetEmailPage />,
      },
    ],
  },
  {
    path: '/',
    element: <App />,
    breadcrumbName: '',
    children: [
      {
        path: '/',
        element: <Navigate replace to="home" />,
      },
      {
        path: 'home',
        element: <AccountDetail />,
      },
      {
        path: 'operation-management',
        children: [
          // 运维大屏
          {
            path: 't-screen',
            element: <TDataScreen />,
          },
          // 电站情况
          {
            path: 'power-station',
            element: <PowerStation />,
          },
          {
            path: 'power-station/create',
            element: <PowerStationCreate />,
          },
          {
            path: 'power-station/detail/:id',
            element: <PowerStationDetail />,
          },
          {
            path: 'equipment-station/:powerStationId',
            element: <EquipmentStation />,
          },
        ],
      },
      {
        path: 'user-center',
        element: <UserCenterLayout />,
        children: [
          //   用户中心  编辑个人信息
          {
            path: 'account',
            element: <UserCenter />,
          },
          // 用户中心编辑语言与时间
          {
            path: 'time-zone',
            element: <TimeZone />,
          },
          // 用户中心参数库
          {
            path: 'user-params',
            element: <UserParams />,
          },
          // 用户中心项目权限模版
          {
            path: 'auth-template',
            element: <AuthTemplate />,
          },
          {
            path: 'auth-template/edit/:id',
            element: <AuthTemplateEdit />,
          },
          {
            path: 'rights-template',
            element: <GroupProjectRightsTemplate />,
          },
          {
            path: 'rights-template/edit/:templateId',
            element: <GroupProjectRightsTemplateEdit />,
          },
          {
            path: 'rights-template/view/:templateId',
            element: <GroupProjectRightsTemplateView />,
          },
          // 参数库 - 项目投资参数
          {
            path: 'investment',
            element: <ProjectInvestment />,
          },
          // 参数库 - 运营成本参数
          {
            path: 'operating',
            element: <OperatingCost />,
          },
          // 参数库 - 财务成本参数
          {
            path: 'financial-cost',
            element: <FinancialCost />,
          },
          // 参数库 - 激励补贴参数
          {
            path: 'incentive',
            element: <IncentiveSubsidy />,
          },
          // 参数库 - 光伏
          {
            path: 'pv',
            element: <PVParams />,
          },
          // 参数库 - 储能
          {
            path: 'battery',
            element: <BatteryParams />,
          },
        ],
      },
      {
        path: 'resource',
        element: <ProgramManage />,
        children: [
          // {
          //   path: 'map',
          //   element: <ResourceReckon />,
          // },
          {
            path: 'mapbox',
            element: <ResourceReckon />,
          },
          {
            path: 'mountain',
            element: <ResourceMountain />,
          },
          {
            path: 'list',
            element: <ResourceManage />,
          },
        ],
      },
      {
        path: 'program',
        element: <ProgramManage />,
        children: [
          {
            path: 'manage',
            element: <ProgramManageList />,
          },
          {
            path: 'edge/:id',
            element: <Edge />,
            children: [
              {
                path: 'electricity',
                element: <Electricity />,
                children: [
                  {
                    path: 'catalogue',
                    element: <Catalogue />,
                  },
                  {
                    path: 'normFireCoal',
                    element: <NormFireCoal />,
                  },
                  {
                    path: 'add',
                    element: <AddElectricity />,
                  },
                  {
                    path: 'detail/:customId',
                    element: <ElectricityDetail />,
                  },
                  {
                    path: 'custom/:type/:customId',
                    element: <CreateElectricity />,
                  },
                ],
              },
              {
                path: 'bear',
                element: <Bear />,
              },
              {
                path: 'weather',
                element: <Weather />,
              },
              {
                path: 'weather/:type',
                element: <Weather />,
              },
              {
                path: 'calc',
                element: <Calc />,
              },
            ],
          },
          {
            path: 'detail/:id/:type',
            element: <ProgramDetail />,
          },
          {
            path: 'detail/:id/:type/settings/:activeVal',
            element: <GroupProjectDetail />,
          },
          {
            path: 'detail/:id/:type/settings/:activeVal/template/:userId/:isEdit',
            element: <ProjectTemplateEdit />,
          },
          {
            path: 'detail/:id',
            element: <ProgramDetail />,
          },
          {
            path: 'detail/:id/settings/:activeVal',
            element: <GroupProjectDetail />,
          },
          {
            path: 'detail/:id/settings/:activeVal/template/:userId/:isEdit',
            element: <ProjectTemplateEdit />,
          },
          {
            path: 'technical',
            element: <Technical />,
          },
          {
            path: 'financial',
            element: <Financial />,
          },
        ],
      },
      {
        path: 'cockpit',
        element: <ManagementCockpit />,
      },
      {
        path: 'summary',
        element: <DataSummary />,
      },
      {
        path: 'projectManagement',
        element: <ProjectEntry />,
        children: [
          {
            path: 'construction',
            element: <ConstructionEntry />,
          },
          {
            path: 'cost',
            element: <CostEntry />,
          },
          {
            path: 'investmentPlan',
            element: <InvestmentPlanEntry />,
          },
          {
            path: 'planExecution',
            element: <PlanExecutionEntry />,
          },
        ],
      },
      {
        path: 'policy',
        element: <ProgramManage />,
        children: [
          {
            path: 'manage',
            element: <PolicyManageList />,
          },
          {
            path: 'detail/:id/:type',
            element: <PolicyDetail />,
          },
        ],
      },
      {
        path: 'invest',
        element: <ProgramManage />,
        children: [
          {
            path: 'workbench',
            element: <InvestWorkbench />,
          },
          {
            path: 'projectLibrary',
            element: <InvestProjectLibrary />,
          },
          {
            path: 'projectLibrary/:editable',
            element: <InvestProjectLibraryCreate />,
          },
          {
            path: 'projectLibrary/:editable/:id',
            element: <InvestProjectLibraryCreate />,
          },
          {
            path: 'document/:id',
            element: <InvestProjectDocument />,
          },
          {
            path: 'document/photos/:id',
            element: <InvestProjectDocumentPhotos />,
          },
          {
            path: 'projectDetail/:id',
            element: <ProjectDetail />,
          },
          {
            path: 'project/:editable/:id',
            element: <ProjectCommonEntry />,
          },
          {
            path: 'project/:editable',
            element: <ProjectCommonEntry />,
          },
          {
            path: 'resourceDevelopment',
            element: <ResourceDevelopmentEntry />,
          },
          {
            path: 'decision',
            element: <DecisionEntry />,
          },
          {
            path: 'record',
            element: <RecordEntry />,
          },
          {
            path: 'contractApproval',
            element: <InvestContractApproval />,
          },
          {
            path: 'contract',
            element: <InvestContractForm />,
          },
          {
            path: 'contractDetail/:id',
            element: <InvestContractDetail />,
          },
          {
            path: 'partner',
            element: <InvestPartner />,
          },
          {
            path: 'agency/:type',
            element: <EnergyAgencyManage />,
          },
          {
            path: 'agency/:id/:type',
            element: <EnergyAgencyDetail />,
          },
        ],
      },
      // 工程管理
      {
        path: 'engineering',
        element: <EngineeringManage />,
        children: [
          {
            path: 'projectLibrary/:editable',
            element: <EngineeringProjectLibraryCreate />,
          },
          {
            path: 'projectLibrary/:editable/:id',
            element: <EngineeringProjectLibraryCreate />,
          },
          {
            path: 'cost',
            element: <EngineeringCostManage />,
          },
          {
            path: 'cost/:id',
            element: <EngineeringCost />,
          },
          {
            path: 'material',
            element: <MaterialManage />,
          },
          {
            path: 'materialCreate/:type/:materialId',
            element: <MaterialCreate />,
          },
          {
            path: 'materialDetails/:id',
            element: <MaterialDetails />,
          },

          {
            path: 'logistics',
            element: <LogisticsManage />,
          },
          {
            path: 'logisticsCreate/:manageId',
            element: <LogisticsCreate />,
          },
          {
            path: 'logisticsDetails/:id',
            element: <LogisticsDetails />,
          },
        ],
      },
    ],
  },
  {
    path: '/workspace/:id',
    element: <Workspace />,
    children: [
      {
        path: 'tech',
        element: <TechnicalWorkspace />,
      },
      {
        path: 'fin',
        element: <FinancialWorkspace />,
      },
    ],
  },
  {
    path: '/work-order',
    element: <WorkOrderHome />,
    children: [
      {
        path: '',
        element: <Navigate replace to="list" />,
      },
      {
        path: 'list',
        element: <WorkOrderList />,
      },
      {
        path: 'create',
        element: <WorkOrderCreate />,
      },
      {
        path: 'detail/:orderNo',
        element: <WorkOrderDetail />,
      },
    ],
  },
]
