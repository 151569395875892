import React, { useEffect, useState } from 'react'
import { Button, Card, Col, Dropdown, Form, Input, InputNumber, Menu, Radio, Row, Spin } from 'antd'
// import { SettingOutlined } from '@ant-design/icons'
import { PvModelSelect } from './pv-model-select'
import { PvInverterSelect } from './pv-inverter-select'
import { PvDesign } from './pv-design'
import { PvSystemEfficiency } from './pv-system-efficiency'
import ReactECharts from 'echarts-for-react'

import '../index.less'
import { useParams } from 'react-router-dom'
import * as echarts from 'echarts'
import { ChartColorMaps, CustomIcons } from '../../../../global'
import styles from '../../../program/manage/detail/index.module.scss'
import { WorkspaceModal } from '../../workspace'
import { ProjectWorkbenchService, TechnologyService } from '../../../../apis/project-service-history'
import { CaretDownOutlined } from '@ant-design/icons'

export const createRangeFilter = (
  confirm: any,
  key: string,
  name: string,
  unit: string,
  form: any,
  query: any,
  layout: any
) => {
  return (
    <div className={styles.customFilter}>
      <Form
        onFinish={() => {
          query[`${key}Change`] = true
          confirm()
        }}
        {...layout}
        colon={false}
        form={form}
      >
        <Form.Item
          label={name}
          style={{
            alignItems: 'center',
          }}
        >
          <Input.Group compact>
            <Form.Item noStyle name={`${key}Min`}>
              <Input
                type="number"
                style={{ width: 150 }}
                placeholder="最小值"
                onPressEnter={() => {
                  form.submit()
                }}
              />
            </Form.Item>
            <Input
              className="site-input-split"
              style={{
                width: 30,
                borderLeft: 0,
                borderRight: 0,
                pointerEvents: 'none',
              }}
              placeholder="-"
              disabled
            />
            <Form.Item noStyle name={`${key}Max`}>
              <Input
                type="number"
                style={{ width: 180 }}
                placeholder="最大值"
                suffix={unit}
                onPressEnter={() => {
                  form.submit()
                }}
              />
            </Form.Item>
          </Input.Group>
        </Form.Item>
        <Form.Item label=" " className="filter-btns">
          <div
            style={{
              marginTop: '.5rem',
              textAlign: 'right',
            }}
          >
            <Button
              onClick={() => {
                form.setFieldsValue({
                  [`${key}Min`]: undefined,
                  [`${key}Max`]: undefined,
                })
              }}
            >
              重置
            </Button>
            <Button
              type="primary"
              style={{
                marginLeft: '.5rem',
              }}
              htmlType="submit"
            >
              确定
            </Button>
          </div>
        </Form.Item>
      </Form>
    </div>
  )
}

export const PVConfig = (props: any) => {
  const params = useParams()

  const [pvParams, setPvParams] = useState([])
  const { info, geo, onTriggerReload, viewMode } = props
  useEffect(() => {
    TechnologyService.getList1()
      .then((resp) => {
        setPvParams(resp.data)
      })
      .catch(() => {})
  }, [])

  const [loading, setLoading] = useState(true)
  useEffect(() => {
    setLoading(props.loading)
  }, [props.loading])

  if (!info) return null
  return (
    <Spin spinning={loading}>
      <Col
        style={{
          display: 'flex',
          flexDirection: 'column',
        }}
        className="custom-layout pv-config-layout"
      >
        {!viewMode && (
          <Card
            style={{
              textAlign: 'left',
            }}
            title={
              <div>
                光伏设置
                <div
                  style={{
                    position: 'absolute',
                    left: 0,
                    width: 3,
                    height: 22,
                    background: '#1EC692',
                    top: '50%',
                    transform: 'translateY(-50%)',
                  }}
                />
              </div>
            }
            extra={
              <Dropdown
                overlay={
                  <Menu>
                    {pvParams.map((o: any) => {
                      return (
                        <Menu.Item
                          key={o.id}
                          onClick={() => {
                            ProjectWorkbenchService.checkParamLibrary({
                              technicalProposalId: params.id!,
                              root: {
                                paramLibraryId: o.id,
                                type: '1',
                              },
                            })
                              .then(() => {
                                props.onTriggerReload?.(true)
                              })
                              .catch(() => {})
                          }}
                        >
                          {o.name}
                        </Menu.Item>
                      )
                    })}
                  </Menu>
                }
              >
                <Button
                  icon={
                    <div>
                      <CustomIcons
                        type="klf-stack"
                        style={{
                          fontSize: 16,
                        }}
                      />
                      <CaretDownOutlined
                        style={{
                          fontSize: 10,
                        }}
                      />
                    </div>
                  }
                  type="link"
                  ghost
                />
              </Dropdown>
            }
            bodyStyle={{
              display: 'none',
            }}
            headStyle={{
              position: 'relative',
            }}
          />
        )}


        <Card
          title="技术经济"
          style={{
            textAlign: 'left',
            marginTop: 0,
          }}
          headStyle={{
            borderBottom: 'none',
          }}
        >
          <Row
            style={{
              alignItems: 'center',
              marginBottom: '.5rem',
            }}
          >
            <Col
              span={12}
              style={{
                textAlign: 'left',
              }}
            >
              是否有租金
            </Col>
            <Col span={12}>
              <Radio.Group value={info.rental} onChange={(dom) => {
                ProjectWorkbenchService.updatePhotovoltaicOtherInfo({
                  technicalProposalId: params.id!,
                  root: {
                    rental: dom.target.value,
                  },
                })
                  .then(() => {
                    props.onTriggerReload?.()
                  })
              }}>
                <Radio value={true}>是</Radio>
                <Radio value={false}>否</Radio>
              </Radio.Group>
            </Col>
          </Row>
        </Card>

        <Card
          title="关键设备"
          style={{
            textAlign: 'left',
          }}
          headStyle={{
            borderBottom: 'none',
          }}
        >
          <Row
            style={{
              alignItems: 'center',
            }}
          >
            <Col
              flex="1 1"
              style={{
                textAlign: 'left',
              }}
              className="model-select"
            >
              光伏模组
            </Col>
            <Col>
              {!loading && !viewMode && (
                <Button
                  type="primary"
                  ghost
                  onClick={() => {
                    const modal = WorkspaceModal.confirm({
                      keyboard: false,
                      title: '光伏模组',
                      closable: true,
                      content: (
                        <PvModelSelect
                          current={info}
                          technologyId={params.id!}
                          onClose={(reload: boolean = false) => {
                            modal.destroy()
                            if (reload) {
                              onTriggerReload()
                            }
                          }}
                        />
                      ),
                      icon: null,
                      width: 900,
                      okButtonProps: {
                        style: { display: 'none' },
                      },
                      cancelButtonProps: {
                        style: { display: 'none' },
                      },
                    })
                  }}
                >
                  选型
                </Button>
              )}
            </Col>
          </Row>
          <div
            style={{
              width: '100%',
              minHeight: 172,
            }}
          >
            {!loading && !viewMode && (
              <div className="tips">
                {info.photovoltaicModuleManufacturer} {info.photovoltaicModuleName}
              </div>
            )}
            {viewMode && (
              <Button
                type="link"
                style={{
                  textAlign: 'left',
                  padding: 0,
                }}
                onClick={() => {
                  const modal = WorkspaceModal.confirm({
                    keyboard: false,
                    title: '光伏模组',
                    closable: true,
                    content: (
                      <PvModelSelect
                        viewMode
                        current={info}
                        technologyId={params.id!}
                        onClose={(reload: boolean = false) => {
                          modal.destroy()
                          if (reload) {
                            onTriggerReload()
                          }
                        }}
                      />
                    ),
                    icon: null,
                    width: 900,
                    okButtonProps: {
                      style: { display: 'none' },
                    },
                    cancelButtonProps: {
                      style: { display: 'none' },
                    },
                  })
                }}
              >
                {info.photovoltaicModuleManufacturer} {info.photovoltaicModuleName}
              </Button>
            )}
            <ReactECharts
              style={{
                width: 237,
                height: 150,
              }}
              option={{
                grid: { left: '5px', top: '30px', bottom: 0, right: 5, containLabel: true },
                tooltip: {
                  trigger: 'axis',
                  show: false,
                  formatter: (argsList: any) => {
                    const args = argsList[0]
                    return `${args.seriesName}<br />${args.marker}${args.name}月: ${args.value}kW`
                  },
                },
                xAxis: {
                  type: 'value',
                  // boundaryGap: ['0%', '10%'],
                  // name: 'V',
                  min: 0 - Math.max(...info.photovoltaicModuleChart.x) * 0.1,
                  max: Math.max(...info.photovoltaicModuleChart.x) * 1.1,
                  axisLine: {
                    onZero: false,
                  },
                  splitLine: {
                    show: false,
                  },
                  axisTick: {
                    show: false,
                  },
                  axisLabel: {
                    formatter: (val: number) => {
                      if (val < Math.min(...info.photovoltaicModuleChart.x)) return ''
                      if (val > Math.max(...info.photovoltaicModuleChart.x)) return 'V'
                      return val
                    },
                  },
                },
                yAxis: {
                  type: 'value',
                  name: 'A',
                  axisLine: {
                    show: false,
                  },
                  axisTick: {
                    show: false,
                  },
                  nameTextStyle: {
                    color: 'gray',
                    padding: [0, 55, 0, 0],
                  },
                  splitLine: {
                    lineStyle: {
                      type: [5, 5],
                      dashOffset: 5,
                    },
                  },
                },
                series: [
                  {
                    name: '需量',
                    type: 'line',
                    smooth: true,
                    showSymbol: false,
                    data: info.photovoltaicModuleChart.x.map((o: string, idx: number) => [
                      parseFloat(o),
                      info.photovoltaicModuleChart.y[idx],
                    ]),
                    itemStyle: {
                      color: ChartColorMaps[0].line,
                    },
                    areaStyle: {
                      color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                        {
                          offset: 0,
                          color: ChartColorMaps[0].area[0],
                        },
                        {
                          offset: 1,
                          color: ChartColorMaps[0].area[1],
                        },
                      ]),
                    },
                  },
                ],
              }}
            />
          </div>
          <Row
            style={{
              marginTop: '1.5rem',
              alignItems: 'center',
            }}
          >
            <Col
              flex="1 1"
              style={{
                textAlign: 'left',
              }}
              className="model-select"
            >
              逆变器
            </Col>
            <Col>
              {!loading && !viewMode && (
                <Button
                  type="primary"
                  ghost
                  onClick={() => {
                    const modal = WorkspaceModal.confirm({
                      keyboard: false,
                      title: '逆变器',
                      closable: true,
                      content: (
                        <PvInverterSelect
                          current={info}
                          technologyId={params.id!}
                          onClose={(reload: boolean = false) => {
                            modal.destroy()
                            if (reload) {
                              onTriggerReload()
                            }
                          }}
                        />
                      ),
                      icon: null,
                      width: 1200,
                      okButtonProps: {
                        style: { display: 'none' },
                      },
                      cancelButtonProps: {
                        style: { display: 'none' },
                      },
                    })
                  }}
                >
                  选型
                </Button>
              )}
            </Col>
          </Row>
          <div
            style={{
              width: '100%',
              minHeight: 172,
            }}
          >
            {!loading && !viewMode && (
              <div className="tips">
                {info.inverterModuleManufacturer} {info.inverterModuleName}
              </div>
            )}
            {viewMode && (
              <Button
                type="link"
                style={{
                  padding: 0,
                  textAlign: 'left',
                }}
                onClick={() => {
                  const modal = WorkspaceModal.confirm({
                    keyboard: false,
                    title: '逆变器',
                    closable: true,
                    content: (
                      <PvInverterSelect
                        viewMode
                        current={info}
                        technologyId={params.id!}
                        onClose={(reload: boolean = false) => {
                          modal.destroy()
                          if (reload) {
                            onTriggerReload()
                          }
                        }}
                      />
                    ),
                    icon: null,
                    width: 1200,
                    okButtonProps: {
                      style: { display: 'none' },
                    },
                    cancelButtonProps: {
                      style: { display: 'none' },
                    },
                  })
                }}
              >
                {info.inverterModuleManufacturer} {info.inverterModuleName}
              </Button>
            )}
            <ReactECharts
              style={{
                width: 237,
                height: 150,
              }}
              option={{
                grid: { left: '5px', top: '30px', bottom: 0, right: 5, containLabel: true },
                tooltip: {
                  trigger: 'axis',
                  show: false,
                },
                xAxis: {
                  type: 'value',
                  min: -10,
                  max: 110,
                  interval: 10,
                  axisLine: {
                    onZero: false,
                  },
                  splitLine: {
                    show: false,
                  },
                  axisTick: {
                    show: false,
                  },
                  // data: [...(Array(101).keys() as any)].map((o) => `${o}%`),
                  axisLabel: {
                    showMaxLabel: true,
                    formatter: (val: number) => {
                      if (val > 100) return '%'
                      if (val % 20 !== 0) return ''
                      return val
                    },
                  },
                },
                yAxis: {
                  name: '%',
                  type: 'value',
                  nameTextStyle: {
                    color: 'gray',
                    padding: [0, 55, 0, 0],
                  },
                  axisLine: {
                    show: false,
                  },
                  axisTick: {
                    show: false,
                  },
                  splitLine: {
                    lineStyle: {
                      type: [5, 5],
                      dashOffset: 5,
                    },
                  },
                },
                series: [
                  {
                    key: 'mpptMaxVoltageEfficiency',
                    name: 'MPPT最大电压效率',
                  },
                  {
                    key: 'mpptMinVoltageEfficiency',
                    name: 'MPPT最小电压效率',
                  },
                  // {
                  //   key: 'ratedOutputPower',
                  //   name: '额定输出功率',
                  // },
                  {
                    key: 'ratedVoltageEfficiency',
                    name: '额定电压效率',
                  },
                ].map((o, idx) => {
                  return {
                    name: o.name,
                    type: 'line',
                    showSymbol: false,
                    data: info.inverterModuleChart[o.key].map((o: string, idx: number) => [
                      idx,
                      parseFloat((parseFloat(o) * 100).toFixed(6)),
                    ]),
                    itemStyle: {
                      color: ChartColorMaps[idx].line,
                    },
                    areaStyle: {
                      color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                        {
                          offset: 0,
                          color: ChartColorMaps[idx].area[0],
                        },
                        {
                          offset: 1,
                          color: ChartColorMaps[idx].area[1],
                        },
                      ]),
                    },
                  }
                }),
              }}
            />
          </div>
        </Card>
        <Card
          title="其他信息"
          style={{
            textAlign: 'left',
            marginTop: 0,
          }}
          headStyle={{
            borderBottom: 'none',
          }}
        >
          <Row
            style={{
              alignItems: 'center',
              marginBottom: '.5rem',
            }}
          >
            <Col
              span={12}
              style={{
                textAlign: 'left',
              }}
            >
              系统总效率
            </Col>
            <Col span={12}>
              <Button
                type="link"
                style={{
                  width: '100%',
                  textAlign: 'right',
                  padding: 0,
                }}
                onClick={() => {
                  const modal = WorkspaceModal.confirm({
                    keyboard: false,
                    title: '系统总效率',
                    closable: true,
                    content: (
                      <PvSystemEfficiency
                        viewMode={viewMode}
                        technologyId={params.id!}
                        onClose={(reload: boolean = false) => {
                          modal.destroy()
                          if (reload) {
                            onTriggerReload()
                          }
                        }}
                      />
                    ),
                    icon: null,
                    width: 640,
                    okButtonProps: {
                      style: { display: 'none' },
                    },
                    cancelButtonProps: {
                      style: { display: 'none' },
                    },
                  })
                }}
              >
                {`${parseFloat(info.systemEfficiency).toFixed(2)}% >`}
              </Button>
            </Col>
          </Row>
          <Row
            style={{
              alignItems: 'center',
              marginBottom: '.5rem',
            }}
          >
            <Col
              span={12}
              style={{
                textAlign: 'left',
              }}
            >
              系统寿命
            </Col>
            <Col span={12}>
              {viewMode && <span className="align-right-full">{info.systemLife}年</span>}
              {!loading && !viewMode && (
                <Input disabled style={{ width: '100%' }} suffix="年" defaultValue={info.systemLife} />
              )}
            </Col>
          </Row>
          <Row
            style={{
              alignItems: 'center',
              marginBottom: '.5rem',
            }}
          >
            <Col
              span={12}
              style={{
                textAlign: 'left',
              }}
            >
              首年功率衰减率
            </Col>
            <Col
              span={12}
              style={{
                textAlign: 'left',
              }}
            >
              {viewMode && <span className="align-right-full">{info.firstYearPowerAttenuation}%</span>}
              {!loading && !viewMode && (
                <InputNumber
                  id="firstYearPowerAttenuation"
                  style={{ width: '100%' }}
                  defaultValue={info.firstYearPowerAttenuation}
                  size="middle"
                  addonAfter="%"
                  controls={false}
                  precision={2}
                  min={0}
                  max={100}
                  formatter={(val) => {
                    if (val === '') {
                      return '0'
                    }
                    const formattedVal = parseFloat(parseFloat(val).toFixed(2))
                    if (formattedVal < 0) {
                      return '0'
                    }
                    if (formattedVal > 100) {
                      return '100'
                    }
                    return formattedVal.toString()
                  }}
                  onBlur={(dom) => {
                    const firstYearPowerAttenuation = Math.min(Math.max(parseFloat(dom.target.value), 0), 100)
                    ProjectWorkbenchService.updatePhotovoltaicOtherInfo({
                      technicalProposalId: params.id!,
                      root: {
                        firstYearPowerAttenuation: firstYearPowerAttenuation.toString(),
                      },
                    })
                      .then(() => {
                        props.onTriggerReload?.()
                      })
                      .catch(() => {
                        ;(document.getElementById('firstYearPowerAttenuation') as HTMLInputElement).value =
                          firstYearPowerAttenuation.toString()
                      })
                  }}
                />
              )}
            </Col>
          </Row>
          <Row
            style={{
              alignItems: 'center',
            }}
          >
            <Col
              span={12}
              style={{
                textAlign: 'left',
              }}
            >
              逐年功率衰减率
            </Col>
            <Col span={12}>
              {viewMode && <span className="align-right-full">{info.annualPowerAttenuation}%</span>}
              {!loading && !viewMode && (
                <InputNumber
                  id="annualPowerAttenuation"
                  style={{ width: '100%' }}
                  defaultValue={info.annualPowerAttenuation}
                  size="middle"
                  addonAfter="%"
                  controls={false}
                  precision={2}
                  min={0}
                  max={100}
                  formatter={(val) => {
                    if (val === '') {
                      return '0'
                    }
                    const formattedVal = parseFloat(parseFloat(val).toFixed(2))
                    if (formattedVal < 0) {
                      return '0'
                    }
                    return formattedVal.toString()
                  }}
                  onBlur={(dom) => {
                    const annualPowerAttenuation = Math.min(Math.max(parseFloat(dom.target.value), 0), 100)

                    ProjectWorkbenchService.updatePhotovoltaicOtherInfo({
                      technicalProposalId: params.id!,
                      root: {
                        annualPowerAttenuation: annualPowerAttenuation.toString(),
                      },
                    })
                      .then(() => {
                        props.onTriggerReload?.()
                      })
                      .catch(() => {
                        ;(document.getElementById('annualPowerAttenuation') as HTMLInputElement).value =
                          annualPowerAttenuation.toString()
                      })
                  }}
                />
              )}
            </Col>
          </Row>
        </Card>
        <Card
          title="详细设计"
          style={{
            textAlign: 'left',
            marginTop: 0,
          }}
          extra={
            <Button
              type="primary"
              ghost
              onClick={() => {
                const modal = WorkspaceModal.confirm({
                  keyboard: false,
                  title: '详细设计',
                  closable: true,
                  content: (
                    <PvDesign
                      viewMode={viewMode}
                      info={info}
                      province={props.province}
                      district={props.district}
                      technologyId={params.id!}
                      geo={geo}
                      onClose={(reload: boolean = false) => {
                        modal.destroy()
                        if (reload) {
                          // props.
                          onTriggerReload?.()
                        }
                      }}
                    />
                  ),
                  icon: null,
                  width: 900,
                  okButtonProps: {
                    style: { display: 'none' },
                  },
                  cancelButtonProps: {
                    style: { display: 'none' },
                  },
                })
              }}
            >
              {viewMode ? '查看' : '配置'}
            </Button>
          }
          bodyStyle={{
            display: info.selectedArea ? 'block' : 'none',
          }}
          headStyle={{
            borderBottom: 'none',
            paddingBottom: 8,
            marginBottom: 8,
            marginTop: 8,
          }}
        >
          <Row
            style={{
              alignItems: 'center',
              marginBottom: '.5rem',
            }}
          >
            <Col
              span={12}
              style={{
                textAlign: 'left',
              }}
            >
              所选区域面积
            </Col>
            <Col span={12}>
              {viewMode && <span className="align-right-full">{info.selectedArea}㎡</span>}
              {!loading && !viewMode && (
                <Input disabled style={{ width: '100%' }} suffix="㎡" value={info.selectedArea}></Input>
              )}
            </Col>
          </Row>
          <Row
            style={{
              alignItems: 'center',
              marginBottom: '.5rem',
            }}
          >
            <Col
              span={12}
              style={{
                textAlign: 'left',
              }}
            >
              预计模组个数
            </Col>
            <Col span={12}>
              {viewMode && <span className="align-right-full">{info.photovoltaicNum}个</span>}
              {!loading && !viewMode && (
                <Input disabled style={{ width: '100%' }} suffix="个" value={info.photovoltaicNum}></Input>
              )}
            </Col>
          </Row>
          <Row
            style={{
              alignItems: 'center',
            }}
          >
            <Col
              span={12}
              style={{
                textAlign: 'left',
              }}
            >
              预计装机容量
            </Col>
            <Col span={12}>
              {viewMode && <span className="align-right-full">{info.photovoltaicInstallCapacity}kW</span>}
              {!loading && !viewMode && (
                <Input disabled style={{ width: '100%' }} suffix="kW" value={info.photovoltaicInstallCapacity}></Input>
              )}
            </Col>
          </Row>
        </Card>
      </Col>
    </Spin>
  )
}
