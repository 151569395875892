import { useEffect, useState } from 'react'
import { Layout, Menu, Breadcrumb } from 'antd'
import { SettingOutlined } from '@ant-design/icons'
import { Outlet, Link } from 'react-router-dom'
import Global from '../../global'
import styles from './index.module.scss'
import { BehaviorSubject } from 'rxjs'
import { BreadcrumbObservable, SideMenuObservable } from '../../application'

const { Sider, Content } = Layout

const keyMap = ['account', 'time-zone', 'user-params', 'rights-template']
const userParams = ['investment', 'operating', 'financial-cost', 'incentive']
export const SubBreadcrumbObservable = new BehaviorSubject<
  {
    name: string
    url: string
  }[]
>([])

// 嵌套Layout
const UserCenterLayout = () => {
  const [breadcrumb, setBreadcrumb] = useState<{ name: string; url: string }[]>([])
  const [selectedKeys, setSelectKeys] = useState<string[]>(['account'])

  Global.changeMenu.push((e: any) => {
    if (e.lastIndexOf('/') > 2) {
      const paths = e.split('/')
      paths.forEach((path: string) => {
        if (path === 'program') {
          setSelectKeys(['program'])
        }
      })
    } else {
      setSelectKeys(e?.split('#/'))
    }
  })

  useEffect(() => {
    SideMenuObservable.next(true)
    BreadcrumbObservable.next([])
  })

  useEffect(() => {
    const brsbsc = SubBreadcrumbObservable.subscribe((resp) => {
      activeMenu()
      setBreadcrumb(resp)
    })

    return () => {
      brsbsc.unsubscribe()
    }
  }, [])

  const activeMenu = () => {
    // 激活菜单
    keyMap.forEach((o) => {
      if (location.hash.indexOf(o) >= 0) {
        setSelectKeys([o])
      }
    })
    userParams.forEach((o) => {
      if (location.hash.indexOf(o) >= 0) {
        setSelectKeys(['user-params'])
      }
    })
  }

  useEffect(() => {
    activeMenu()
  }, [])

  return (
    <Layout>
      <Sider className={`site-layout-background ${styles.customSubSlider}`} width={216} collapsedWidth="50">
        <div className="menu-title" style={{ paddingRight: '16px' }}>
          <SettingOutlined style={{ marginRight: 8, fontSize: '16px', padding: '0 8px' }} />
          设置
        </div>
        <Menu
          className="klf-custom-menu"
          mode="inline"
          selectedKeys={selectedKeys}
          style={{ height: '100%', borderRight: 0 }}
          onClick={({ key }) => {
            setSelectKeys([key])
          }}
        >
          <Menu.Item key="account">
            <Link to="account">账号信息</Link>
          </Menu.Item>
          <Menu.Item key="time-zone">
            <Link to="time-zone">语言与时间</Link>
          </Menu.Item>
          <Menu.Item key="user-params">
            <Link to="user-params">参数库</Link>
          </Menu.Item>
          <Menu.Item key="rights-template">
            <Link to="rights-template">项目权限模板</Link>
          </Menu.Item>
        </Menu>
      </Sider>
      <Layout className={styles.userLayout}>
        {breadcrumb.length > 1 && breadcrumb.filter((item) => item.url.indexOf('user-center') === -1).length !== 0 && (
          <Breadcrumb style={{ margin: 0, marginBottom: 14 }}>
            {breadcrumb.map((o) => (
              <Breadcrumb.Item key={o.name}>{(o.url && <Link to={o.url}>{o.name}</Link>) || o.name}</Breadcrumb.Item>
            ))}
          </Breadcrumb>
        )}
        <Content
          className="site-layout-background custom-scroll-bar"
          style={{
            minHeight: 230,
            height: 'calc(100vh - 140px)',
            overflowY: 'auto',
            flex: 'none',
            background: 'white',
            borderRadius: '4px',
          }}
        >
          <Outlet />
        </Content>
      </Layout>
    </Layout>
  )
}

export default UserCenterLayout
