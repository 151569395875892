import { Button, Checkbox, Form, Input, Pagination, Space, Table } from 'antd'
import { CustomIcons } from '../../global'
import React, { useEffect, useState } from 'react'
import { parseNumber, parseSuffixNumber } from './resource-map'
import styles from '../program/index.module.scss'
import { FormInstance } from 'antd/lib/form/hooks/useForm'
import { EvaluationV2Api } from '../../services'

export const query: any = {
  label: '',
  adcodes: [],
  regionAreaMin: undefined,
  regionAreaMax: undefined,
  yearTotalIrradianceMin: undefined,
  yearTotalIrradianceMax: undefined,
  roofCountMin: undefined,
  roofCountMax: undefined,
  roofAreaMin: undefined,
  roofAreaMax: undefined,
  avgRoofAreaMin: undefined,
  avgRoofAreaMax: undefined,
  roofRatioMin: undefined,
  roofRatioMax: undefined,

  regionAreaSort: '0',
  yearTotalIrradianceSort: '0',
  roofCountSort: '0',
  roofAreaSort: '0',
  avgRoofAreaSort: '0',
  roofRatioSort: '0',
}

const region = [
  { text: '北京市', value: '110000' },
  { text: '天津市', value: '120000' },
  { text: '河北省', value: '130000' },
  { text: '山西省', value: '140000' },
  { text: '内蒙古自治区', value: '150000' },
  { text: '辽宁省', value: '210000' },
  { text: '吉林省', value: '220000' },
  { text: '黑龙江省', value: '230000' },
  { text: '上海市', value: '310000' },
  { text: '江苏省', value: '320000' },
  { text: '浙江省', value: '330000' },
  { text: '安徽省', value: '340000' },
  { text: '福建省', value: '350000' },
  { text: '江西省', value: '360000' },
  { text: '山东省', value: '370000' },
  { text: '河南省', value: '410000' },
  { text: '湖北省', value: '420000' },
  { text: '湖南省', value: '430000' },
  { text: '广东省', value: '440000' },
  { text: '广西壮族自治区', value: '450000' },
  { text: '海南省', value: '460000' },
  { text: '重庆市', value: '500000' },
  { text: '四川省', value: '510000' },
  { text: '贵州省', value: '520000' },
  { text: '云南省', value: '530000' },
  { text: '西藏自治区', value: '540000' },
  { text: '陕西省', value: '610000' },
  { text: '甘肃省', value: '620000' },
  { text: '青海省', value: '630000' },
  { text: '宁夏回族自治区', value: '640000' },
  { text: '新疆维吾尔自治区', value: '650000' },
  { text: '台湾省', value: '710000' },
  { text: '香港特别行政区', value: '810000' },
  { text: '澳门特别行政区', value: '820000' },
]

export const ResourceSift = (props: { onClose: (data?: any) => void }) => {
  const [nameFilter, setNameFilter] = useState(query.label)
  const [dataSource, setDataSource] = useState([])

  const [form] = Form.useForm()
  const [regionAreaForm] = Form.useForm()
  const [yearTotalIrradianceForm] = Form.useForm()
  const [roofCountForm] = Form.useForm()
  const [roofAreaForm] = Form.useForm()
  const [avgRoofAreaForm] = Form.useForm()
  const [roofRatioForm] = Form.useForm()

  const dropdownArr: {
    [key: string]: {
      name: string
      form: FormInstance
      unit: string
    }
  } = {
    regionArea: {
      name: '区域面积',
      form: regionAreaForm,
      unit: 'k㎡',
    },
    yearTotalIrradiance: {
      name: '年总辐照',
      form: yearTotalIrradianceForm,
      unit: 'kWh/㎡',
    },
    roofCount: {
      name: '屋顶数量',
      form: roofCountForm,
      unit: '个',
    },
    roofArea: {
      name: '屋顶总面积',
      form: roofAreaForm,
      unit: '㎡',
    },
    avgRoofArea: {
      name: '屋顶平均面积',
      form: avgRoofAreaForm,
      unit: '㎡',
    },
    roofRatio: {
      name: '屋顶覆盖率',
      form: roofRatioForm,
      unit: '‰',
    },
  }

  const createRangeFilter = (confirm: any, key: string) => {
    return (
      <div className={styles.customFilter}>
        <Form
          onFinish={() => {
            query[`${key}Change`] = true
            confirm()
          }}
          {...{
            labelCol: { span: 6 },
            wrapperCol: { span: 18 },
          }}
          colon={false}
          form={dropdownArr[key].form}
        >
          <Form.Item
            label={dropdownArr[key].name}
            style={{
              alignItems: 'center',
            }}
          >
            <Input.Group compact>
              <Form.Item noStyle name={`${key}Min`}>
                <Input type="number" style={{ width: 150 }} placeholder="最小值" />
              </Form.Item>
              <Input
                className="site-input-split"
                style={{
                  width: 30,
                  borderLeft: 0,
                  borderRight: 0,
                  pointerEvents: 'none',
                }}
                placeholder="-"
                disabled
              />
              <Form.Item noStyle name={`${key}Max`}>
                <Input type="number" style={{ width: 180 }} placeholder="最大值" suffix={dropdownArr[key].unit} />
              </Form.Item>
            </Input.Group>
          </Form.Item>
          <Form.Item label=" " className="filter-btns">
            <Space>
              <Button
                onClick={() => {
                  dropdownArr[key].form.setFieldsValue({
                    [`${key}Min`]: undefined,
                    [`${key}Max`]: undefined,
                  })
                }}
              >
                重置
              </Button>
              <Button type="primary" htmlType="submit">
                确定
              </Button>
            </Space>
          </Form.Item>
        </Form>
      </div>
    )
  }

  const columns = [
    {
      title: '区域名称',
      dataIndex: 'label',
      key: 'label',
      width: 180,
      fixed: 'left' as any,
      onFilterDropdownVisibleChange: (visible: boolean) => {
        if (!visible) {
          setNameFilter(query.label)
        }
      },
      filterIcon: (
        <CustomIcons
          type="klf-search"
          style={{
            color: query.label ? '#1ec692' : '#bfbfbf',
          }}
        />
      ),
      filterDropdown: ({ confirm, visible }: any) => {
        if (!visible) {
          ;(window as any).closeDropdown = null
          return null
        }
        setTimeout(() => {
          ;(window as any).closeDropdown = confirm
        })
        return (
          <div
            style={{
              padding: '.5rem 1rem',
            }}
          >
            <Input
              id="nameFilter"
              value={nameFilter}
              placeholder="请输入区域名称"
              onChange={(dom) => {
                setNameFilter(dom.target.value)
              }}
              onBlur={(dom) => {
                setNameFilter(dom.target.value)
              }}
              onPressEnter={() => {
                query.label = nameFilter
                loadingData()
                confirm?.()
              }}
            />
            <div
              style={{
                marginTop: '.5rem',
                textAlign: 'right',
              }}
            >
              <Button
                onClick={() => {
                  setNameFilter('')
                }}
              >
                重置
              </Button>
              <Button
                type="primary"
                style={{
                  marginLeft: '.5rem',
                }}
                onClick={() => {
                  query.label = nameFilter
                  loadingData()
                  confirm?.()
                }}
              >
                确定
              </Button>
            </div>
          </div>
        )
      },
      render: (res: string) => res.split('-').slice(-1).join(''),
    },
    {
      title: '所属省份',
      dataIndex: 'province',
      key: 'province',
      sorter: false,
      filterMultiple: true,
      filterIcon: (
        <CustomIcons
          type="klf-filter"
          style={{
            color: query.adcodes?.length > 0 ? '#1ec692' : '#bfbfbf',
          }}
        />
      ),
      onFilterDropdownVisibleChange: (visible: boolean) => {
        if (!visible) {
          form.setFieldsValue({ adcodes: query.adcodes })
        }
      },
      filterDropdown: ({ confirm, visible, filters }: any) => {
        if (!visible) {
          ;(window as any).closeDropdown = null
        } else {
          setTimeout(() => {
            ;(window as any).closeDropdown = confirm
          })
        }
        return (
          <div
            className={styles.customFilter}
            style={{
              paddingRight: 0,
            }}
          >
            <Form
              onFinish={() => {
                query.adcodes = form.getFieldValue('adcodes')
                loadingData(true)
                confirm()
              }}
              {...{
                labelCol: { span: 24 },
                wrapperCol: { span: 24 },
              }}
              colon={false}
              form={form}
            >
              <Form.Item
                name={'adcodes'}
                className="label-low"
                style={{
                  alignItems: 'center',
                  maxHeight: 300,
                  overflowY: 'auto',
                }}
              >
                <Checkbox.Group>
                  <Space direction="vertical">
                    {filters.map((filter: any) => (
                      <Checkbox key={filter.value} value={filter.value}>
                        {filter.text}
                      </Checkbox>
                    ))}
                  </Space>
                </Checkbox.Group>
              </Form.Item>
              <Form.Item className="filter-btns">
                <div
                  style={{
                    marginTop: '.5rem',
                    textAlign: 'right',
                  }}
                >
                  <Button
                    onClick={() => {
                      form.setFieldsValue({
                        adcodes: [],
                      })
                    }}
                  >
                    重置
                  </Button>
                  <Button
                    type="primary"
                    style={{
                      marginLeft: '.5rem',
                    }}
                    htmlType="submit"
                  >
                    确定
                  </Button>
                </div>
              </Form.Item>
            </Form>
          </div>
        )
      },
      filters: region,
    },
    {
      title: '区域面积',
      dataIndex: 'regionArea',
      key: 'regionArea',
      render: (res: string) => `${parseNumber(parseFloat(res) / 1000000, { type: 'e', radix: 2 })}k㎡`,
      sorter: true,
      filterIcon: (
        <CustomIcons
          type="klf-filter"
          style={{
            color: query.regionAreaMin || query.regionAreaMin ? '#1ec692' : '#bfbfbf',
          }}
        />
      ),
      filterDropdown: ({ confirm, visible }: any) => {
        if (!visible) {
          ;(window as any).closeDropdown = null
        } else {
          setTimeout(() => {
            ;(window as any).closeDropdown = confirm
          })
        }
        return createRangeFilter(confirm, 'regionArea')
      },
      onFilterDropdownVisibleChange: (visible: boolean) => {
        const key = 'regionArea'
        if (!visible) {
          if (query[`${key}Change`]) {
            query[`${key}Change`] = false
            query[`${key}Min`] = dropdownArr[key].form.getFieldsValue()[`${key}Min`]
            query[`${key}Max`] = dropdownArr[key].form.getFieldsValue()[`${key}Max`]
            loadingData(true)
          }
        } else {
          dropdownArr[key].form.setFieldsValue({
            [`${key}Max`]: query[`${key}Max`],
            [`${key}Min`]: query[`${key}Min`],
          })
        }
      },
    },
    {
      title: '年总辐照',
      dataIndex: 'yearTotalIrradiance',
      key: 'yearTotalIrradiance',
      render: (res: string) => `${parseNumber(res, { type: 'custom', radix: 2 })}kWh/㎡`,
      sorter: true,
      filterIcon: (
        <CustomIcons
          type="klf-filter"
          style={{
            color: query.yearTotalIrradianceMin || query.yearTotalIrradianceMa ? '#1ec692' : '#bfbfbf',
          }}
        />
      ),
      filterDropdown: ({ confirm, visible }: any) => {
        if (!visible) {
          ;(window as any).closeDropdown = null
        } else {
          setTimeout(() => {
            ;(window as any).closeDropdown = confirm
          })
        }
        return createRangeFilter(confirm, 'yearTotalIrradiance')
      },
      onFilterDropdownVisibleChange: (visible: boolean) => {
        const key = 'yearTotalIrradiance'
        if (!visible) {
          if (query[`${key}Change`]) {
            query[`${key}Change`] = false
            query[`${key}Min`] = dropdownArr[key].form.getFieldsValue()[`${key}Min`]
            query[`${key}Max`] = dropdownArr[key].form.getFieldsValue()[`${key}Max`]
            loadingData(true)
          }
        } else {
          dropdownArr[key].form.setFieldsValue({
            [`${key}Max`]: query[`${key}Max`],
            [`${key}Min`]: query[`${key}Min`],
          })
        }
      },
    },
    {
      title: '屋顶数量',
      dataIndex: 'roofCount',
      key: 'roofCount',
      render: (res: string) => `${parseNumber(res, { type: 'c', radix: 0 })}个`,
      sorter: true,
      filterIcon: (
        <CustomIcons
          type="klf-filter"
          style={{
            color: query.roofCountMax || query.roofCountMin ? '#1ec692' : '#bfbfbf',
          }}
        />
      ),
      filterDropdown: ({ confirm, visible }: any) => {
        if (!visible) {
          ;(window as any).closeDropdown = null
        } else {
          setTimeout(() => {
            ;(window as any).closeDropdown = confirm
          })
        }
        return createRangeFilter(confirm, 'roofCount')
      },
      onFilterDropdownVisibleChange: (visible: boolean) => {
        const key = 'roofCount'
        if (!visible) {
          if (query[`${key}Change`]) {
            query[`${key}Change`] = false
            query[`${key}Min`] = dropdownArr[key].form.getFieldsValue()[`${key}Min`]
            query[`${key}Max`] = dropdownArr[key].form.getFieldsValue()[`${key}Max`]
            loadingData(true)
          }
        } else {
          dropdownArr[key].form.setFieldsValue({
            [`${key}Max`]: query[`${key}Max`],
            [`${key}Min`]: query[`${key}Min`],
          })
        }
      },
    },
    {
      title: '屋顶总面积',
      dataIndex: 'roofArea',
      key: 'roofArea',
      render: (res: string) => `${parseNumber(res, { type: 'c', radix: 2 })}㎡`,
      sorter: true,
      filterIcon: (
        <CustomIcons
          type="klf-filter"
          style={{
            color: query.roofAreaMin || query.roofAreaMax ? '#1ec692' : '#bfbfbf',
          }}
        />
      ),
      filterDropdown: ({ confirm, visible }: any) => {
        if (!visible) {
          ;(window as any).closeDropdown = null
        } else {
          setTimeout(() => {
            ;(window as any).closeDropdown = confirm
          })
        }
        return createRangeFilter(confirm, 'roofArea')
      },
      onFilterDropdownVisibleChange: (visible: boolean) => {
        const key = 'roofArea'
        if (!visible) {
          if (query[`${key}Change`]) {
            query[`${key}Change`] = false
            query[`${key}Min`] = dropdownArr[key].form.getFieldsValue()[`${key}Min`]
            query[`${key}Max`] = dropdownArr[key].form.getFieldsValue()[`${key}Max`]
            loadingData(true)
          }
        } else {
          dropdownArr[key].form.setFieldsValue({
            [`${key}Max`]: query[`${key}Max`],
            [`${key}Min`]: query[`${key}Min`],
          })
        }
      },
    },
    {
      title: '屋顶平均面积',
      dataIndex: 'avgRoofArea',
      key: 'avgRoofArea',
      render: (res: string) => `${parseNumber(res, { type: 'c', radix: 2 })}㎡`,
      sorter: true,
      filterIcon: (
        <CustomIcons
          type="klf-filter"
          style={{
            color: query.avgRoofAreaMax || query.avgRoofAreaMin ? '#1ec692' : '#bfbfbf',
          }}
        />
      ),
      filterDropdown: ({ confirm, visible }: any) => {
        if (!visible) {
          ;(window as any).closeDropdown = null
        } else {
          setTimeout(() => {
            ;(window as any).closeDropdown = confirm
          })
        }
        return createRangeFilter(confirm, 'avgRoofArea')
      },
      onFilterDropdownVisibleChange: (visible: boolean) => {
        const key = 'avgRoofArea'
        if (!visible) {
          if (query[`${key}Change`]) {
            query[`${key}Change`] = false
            query[`${key}Min`] = dropdownArr[key].form.getFieldsValue()[`${key}Min`]
            query[`${key}Max`] = dropdownArr[key].form.getFieldsValue()[`${key}Max`]
            loadingData(true)
          }
        } else {
          dropdownArr[key].form.setFieldsValue({
            [`${key}Max`]: query[`${key}Max`],
            [`${key}Min`]: query[`${key}Min`],
          })
        }
      },
    },
    {
      title: '屋顶覆盖率',
      dataIndex: 'roofRatio',
      key: 'roofRatio',
      render: (res: string) => `${parseSuffixNumber(parseFloat(res) * 1000, 2)}‰`,
      sorter: true,
      filterIcon: (
        <CustomIcons
          type="klf-filter"
          style={{
            color: query.roofRatioMax || query.roofRatioMin ? '#1ec692' : '#bfbfbf',
          }}
        />
      ),
      filterDropdown: ({ confirm, visible }: any) => {
        if (!visible) {
          ;(window as any).closeDropdown = null
        } else {
          setTimeout(() => {
            ;(window as any).closeDropdown = confirm
          })
        }
        return createRangeFilter(confirm, 'roofRatio')
      },
      onFilterDropdownVisibleChange: (visible: boolean) => {
        const key = 'roofRatio'
        if (!visible) {
          if (query[`${key}Change`]) {
            query[`${key}Change`] = false
            query[`${key}Min`] = dropdownArr[key].form.getFieldsValue()[`${key}Min`]
            query[`${key}Max`] = dropdownArr[key].form.getFieldsValue()[`${key}Max`]
            loadingData(true)
          }
        } else {
          dropdownArr[key].form.setFieldsValue({
            [`${key}Max`]: query[`${key}Max`],
            [`${key}Min`]: query[`${key}Min`],
          })
        }
      },
    },
  ]

  useEffect(() => {
    form.setFieldsValue({ ...query })
    loadingData(true)
  }, [])

  const [pager, setPager] = useState<any>({
    current: 1,
    pageSize: 20,
    total: 0,
  })

  const [loading, setLoading] = useState(true)

  const loadingData = (resetPager = false) => {
    setLoading(true)
    if(resetPager) {
      pager.current = 1;
    }
    new EvaluationV2Api().i3060RegionRoofGetRegionRoofSummaryPost({
      ...query,
      adcodes: query.adcodes.join(','),
      page: pager.current,
      size: pager.pageSize,
    })
    .then((rsp: any) => {
      const resp = rsp.data;
      setDataSource(resp.data)
      pager.page = resp.page;
      pager.total = resp.total
      setPager({...pager});
      setLoading(false)
    })
  }

  const changeTable = (pager: any, filter: any, sorter: any) => {
    const { order, field } = sorter
    let ope = '0'
    if (order === 'ascend') {
      ope = '1'
    }
    if (order === 'descend') {
      ope = '2'
    }

    query.regionAreaSort = '0'
    query.yearTotalIrradianceSort = '0'
    query.roofCountSort = '0'
    query.roofAreaSort = '0'
    query.avgRoofAreaSort = '0'
    query.roofRatioSort = '0'
    query[`${field}Sort`] = ope

    loadingData()
  }

  const getCurrentPageData = () => {
    return dataSource;
    // return dataSource.slice((pager.current - 1) * pager.pageSize, pager.current * pager.pageSize)
  }

  return (
    <div>
      <Table
        loading={loading}
        dataSource={getCurrentPageData()}
        columns={columns}
        pagination={false}
        onChange={changeTable}
        scroll={{ x: '1060', y: window.innerHeight - 400 }}
      />
      <div className={styles.paginationWrap}>
        <Pagination
          className="left-pagination"
          size="small"
          total={pager.total}
          showQuickJumper={false}
          pageSizeOptions={['5', '10', '20', '50']}
          showTotal={(total) => {
            return `共 ${total} 条`
          }}
          showSizeChanger
          current={pager.current}
          pageSize={pager.pageSize}
          onChange={(page: number, pageSize?: number) => {
            pager.current = page
            pager.pageSize = pageSize
            setPager({ ...pager })
            setTimeout(() => {
              loadingData();
            })
          }}
          locale={{ items_per_page: '' }}
        />
        <Pagination
          size="small"
          total={pager.total}
          current={pager.current}
          pageSize={pager.pageSize}
          showQuickJumper
          showSizeChanger={false}
          locale={{ jump_to: '前往' }}
          onChange={(page: number, pageSize?: number) => {
            pager.current = page
            pager.pageSize = pageSize
            setPager({ ...pager })
            setTimeout(() => {
              loadingData()
            })
          }}
        />
      </div>

      <div className="ant-modal-confirm-btns">
        <Button
          onClick={() => {
            props.onClose?.()
          }}
        >
          取消
        </Button>
        <Button
          type="primary"
          onClick={() => {
            props.onClose(dataSource)
          }}
        >
          确定
        </Button>
      </div>
    </div>
  )
}
