import React from 'react'
import { Typography, Tooltip } from 'antd'

import { getActionColumn, TablePage, getColumnFilterProps } from '../../../../components/wl-table-page'
import { ProjectPartnerService } from '../../../../apis/wl-service'

export default ({ editRow, componentRef }) => {
  // 个人列表字段
  const personalColumns = (tableParams: any) => [
    {
      title: '姓名',
      dataIndex: 'name',
      width: 100,
      ...getColumnFilterProps({
        type: 'input',
        placeholder: '请输入姓名',
        value: tableParams.name,
        onSearch: (value) => {
          componentRef.current?.reload?.({
            name: value,
            page: 1,
          })
        },
      }),
      render: (text: string) =>
        text && (
          <Tooltip title={text}>
            <Typography.Text ellipsis>{text}</Typography.Text>
          </Tooltip>
        ),
    },
    {
      title: '身份证号码',
      dataIndex: 'code',
      width: 100,
    },
    {
      title: '联系电话',
      dataIndex: 'phone',
      width: 100,
    },
    getActionColumn([
      {
        key: 'edit',
        label: '编辑',
        onClick: (row: any) => {
          editRow(row)
        },
      },
      {
        key: 'delete',
        type: 'delete',
        getter: (row: any) => {
          return ProjectPartnerService.remove({ id: row.id })
        },
        success: (arg: any) => {
          componentRef.current?.reload?.(arg)
        },
      },
    ]),
  ]

  return (
    <TablePage
      autoLoad={false}
      componentRef={componentRef}
      columns={personalColumns}
      rowKey="id"
      getter={(arg: any) => ProjectPartnerService.list({ ...arg, type: 1 })}
    />
  )
}
