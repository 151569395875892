import * as React from 'react';
import { Tooltip } from 'antd';
import moment from 'moment';
import './index.less';

interface IValue {
  name: string;
  idx: number;
  values: moment.Moment[];
}

interface IElectricProps {
  data: IValue[];
  colors: string[];
}

const prefix = 'c-electric';
const monthDays = [
  31,
  29,
  31,
  30,
  31,
  30,
  31,
  31,
  30,
  31,
  30,
  31,
];

const startDate = moment('2000/01/01', "YYYY/MM/DD");

export const Electric = (props: IElectricProps) => {
  const { data, colors } = props;
  const months = new Array(12).fill('');

  const getMonthPosition = (i: number) => {
    const length = monthDays.reduce((sum, o, idx) => {
      if(idx < i) return sum + o;
      return sum;
    }, 0)
    return `${(length / 366) * 100}%`;
  }

  // 根据时间计算长度
  const getTimeRange = (v: moment.Moment[]) => {
    const length = v[1].diff(v[0], 'days') + 1;
    return `${(length / 366) * 100}%`;
  };

  // 计算位置
  const getPosition = (v: moment.Moment[]) => {
    const left = v[0].diff(startDate, 'days');
    console.log(left, 55555);
    return `${(left / 366) * 100}%`;
  };

  const renderTooltip = (v: IValue) => {
    console.log(v);
    return (
      <div className={`${prefix}-tooltip`}>
        <div className={`${prefix}-tooltip-icon`} style={{
          backgroundColor: props.colors[v.idx],
        }}/>
        <div>
          <div className={`${prefix}-tooltip-title`}>{v.name}</div>
          {
            v.values.map((o, idx) => {
              if(!o[0] || !o[1]) {
                return;
              }
              return (
                <div key={idx} className={`${prefix}-tooltip-content`}>
                  {o?.[0].format('MM-DD')} 至 {o[1].format('MM-DD')}
                </div>
              )
            })
          }
        </div>
      </div>
    );
  };

  console.log(data)

  return (
    <div className={prefix}>
      <div className={`${prefix}-timeline`}>
        {/* 时间轴 */}
        {data?.map((v: any, i: number) => {
          return v.values?.map(o => {
            if(!o || !o[0] || !o[1]) return;
            const m = getTimeRange(o);
            const p = getPosition(o);
            console.log(m, p);
            return <Tooltip title={renderTooltip(v)} key={`${i}-${v.idx}-${m}-${p}`}>
              <span
                className={`${prefix}-timeline-item`}
                style={{
                  width: m,
                  left: p,
                  background: colors[v.idx]
                }}
              />
              </Tooltip>
          });
        })}
        {/* 圆点渲染 */}
        {months.map((v, i) => (
          <span
            className={`${prefix}-timeline-circle`}
            key={i}
            style={{
              left: getMonthPosition(i),
            }}
          />
        ))}
      </div>

      {/* 月份渲染 */}
      <div className={`${prefix}-month`}>
        {months.map((v, i) => (
          <span
            className={`${prefix}-month-item`}
            key={i}
            style={{
              left: getMonthPosition(i),
            }}
          >
            {i + 1}月
          </span>
        ))}
      </div>
    </div>
  );
};

