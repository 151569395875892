import { ArrowRightOutlined } from '@ant-design/icons';
import { Select } from 'antd';
import moment from 'moment'
import { useEffect, useState } from 'react';

export const KlfDateRangePicker = (
  props: {
    limit?: [moment.Moment, moment.Moment],
    step?: number,
    value?: [moment.Moment, moment.Moment] | any,
    onChange?: (val: [moment.Moment, moment.Moment]) => void
    disabled?: [boolean, boolean]
  }) => {

  const [options, setOptions] = useState([]);
  const [endOptionsIdx, setEndOptionIdx] = useState(0);

  const getOptions = () => {
    const [start = moment('00:00', 'HH:mm'), end = moment('00:00', 'HH:mm').add(1, 'days')] = props.limit || [];
    const res = [];
    for(let i = start; i.isBefore(end); i.add(props.step || 15, 'minutes')) {
      res.push(i.format('HH:mm'))
    }
    if(end.valueOf() === moment('00:00', 'HH:mm').add(1, 'days').valueOf()) {
      res.push('24:00')
    } else {
      res.push(end.format('HH:mm'));
    }
    setOptions(res);
    getEndOptions(res);
  }

  const getEndOptions = (opt = options) => {
    if(props.value?.length > 0) {
      const start = opt.findIndex(o => o === props.value[0]?.format('HH:mm'));
      setEndOptionIdx(start);
    }
  }

  useEffect(() => {
    getEndOptions();
  }, [props.value])

  useEffect(() => {
    getOptions();
  }, [props.limit])

  const get24hValue = (mmt: moment.Moment) => {
    if(!mmt) return;
    if (moment('00:00', 'HH:mm').add(1, 'days').valueOf() === mmt.valueOf()) {
      return '24:00'
    }
    return mmt.format('HH:mm')
  }

  return <div className={'klf-date-range'} style={{
    border: '1px solid lightgrey',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  }}>
    <Select dropdownClassName={'klf-date-range-selector'} disabled={props.disabled?.[0]} style={{
      width: 65,
    }} bordered={false} showArrow={false} value={get24hValue(props.value?.[0])} onChange={(val) => {
      const mmt = moment(val, 'HH:mm');
      if(props.value[1]?.isBefore(mmt)) {
        props.onChange([mmt, mmt])
      } else {
        props.onChange([mmt, props.value[1]])
      }
    }}>
      {options.map((o, idx) => {
        return <Select.Option key={`start-${idx}`} value={o}>{o}</Select.Option>
      })}
    </Select>
    <ArrowRightOutlined />
    <Select dropdownClassName={'klf-date-range-selector'} disabled={props.disabled?.[1]}  style={{
      width: 65,
    }} bordered={false} showArrow={false} value={get24hValue(props.value?.[1])}  onChange={(val) => {
      props.onChange([props.value[0], moment(val, 'HH:mm')])
    }}>
      {options.map((o, idx) => {
        return <Select.Option disabled={idx < endOptionsIdx? 'disabled' : false} key={`end-${idx}`} value={o}>{o}</Select.Option>
      })}
    </Select>
  </div>
}
