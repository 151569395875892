import React from 'react'
import { Divider, Select, SelectProps } from 'antd'
import ProjectCreator from './new-project'

export interface IProjectSelectorProps extends SelectProps {
  options: any[]
  setOptions: (options: any[]) => void
  setValues: (values: any) => void
  onChange: (value: any, changedValues: any) => void
}

export default React.memo(({ options, setOptions, setValues, ...componentProps }: IProjectSelectorProps) => {
  return (
    <Select
      dropdownMatchSelectWidth={false}
      allowClear
      dropdownClassName="right-actions"
      placeholder={componentProps?.disabled ? '' : '请选择'}
      showSearch
      optionFilterProp="children"
      filterOption={(input: string, option: any) => {
        return (option!.children as unknown as string).toLowerCase().includes(input.toLowerCase())
      }}
      {...(componentProps as SelectProps)}
      onChange={(value) => {
        const current: any = options?.find((v: any) => v.id == value) ?? {}
        const changedValues = {
          mark: value,
          project_id: current.projectId ?? '',
          project_name: current.projectName ?? current.name ?? '',
          projectModel: {
            name: current.name ?? '', // TODO:
            address: current.address ?? '',
            cityCode: current.cityCode ?? '',
            latitude: current.latitude ?? '',
            longitude: current.longitude ?? '',
          },
        }
        setValues(changedValues)
        componentProps?.onChange?.(value, changedValues)
      }}
      dropdownRender={(menu) => (
        <>
          {menu}
          <Divider style={{ margin: '4px 0' }} />
          <ProjectCreator
            disabled={!!componentProps?.disabled}
            onAdd={(info: any) => {
              const mark = `_mark_${options.length}`
              setOptions([{ id: mark, fullName: info.name, ...info }, ...options])
              setValues({ mark, project_id: '', project_name: info.name, projectModel: info })
            }}
          />
        </>
      )}
    >
      {options?.map(({ id, fullName }) => {
        return <Select.Option key={`${id}`}>{fullName}</Select.Option>
      })}
    </Select>
  )
})
