import { useEffect, useState } from 'react'
import styles from '../index.module.scss'
import { Modal, Descriptions, Spin } from 'antd'
import { microgridFactor, subsidyType, moneyType, isAvoidIncomeTax, subsidyObject } from '../incentive-data'
import { getFieldLabel } from '../../../../utils/utils'
import { FinanceService } from '../../../../apis/project-service-history'

// 添加、编辑、查看弹窗
const IncentiveModal = (props: any) => {
  const { currentItem, visible, onCancel, showType } = props
  const [type, setType] = useState(0)
  const [state, setState] = useState([])
  const [item, setItem] = useState<any>({})
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setLoading(true)
    setType(showType)
    if (showType === 0) {
      FinanceService.info({
        awardSubsidyDetailId: currentItem.awardSubsidyDetailId,
      })
        .then((res) => {
          setItem(res?.data)
        })
        .catch(() => {})
        .finally(() => {
          setLoading(false)
        })
    } else {
      FinanceService.info1({
        awardSubsidyId: currentItem.awardSubsidyId,
      })
        .then((res) => {
          setState(res?.data?.infos)
        })
        .catch(() => {})
        .finally(() => {
          setLoading(false)
        })
    }
  }, [])

  // 一次性现金补贴表单
  const subsidyType0 = (item: any) => {
    return (
      <>
        <Descriptions.Item label="金额">
          {item.money}
          {getFieldLabel(moneyType, item.moneyType)}
        </Descriptions.Item>
        <Descriptions.Item label="最大值">{item.moneyMax}元</Descriptions.Item>
        <Descriptions.Item label="发放时间">{item.grantDeadline}年</Descriptions.Item>
        <Descriptions.Item label="是否免所得税">
          {getFieldLabel(isAvoidIncomeTax, item.isAvoidIncomeTax ? 1 : 0)}
        </Descriptions.Item>
      </>
    )
  }

  // 电价补贴
  const subsidyType1 = (item: any) => {
    return (
      <>
        <Descriptions.Item label="补贴对象">{getFieldLabel(subsidyObject, item.subsidyObject)}</Descriptions.Item>
        <Descriptions.Item label="单位补贴">{item.unitSubsidy}¥/kWh</Descriptions.Item>
        <Descriptions.Item label="发放期限">{item.grantDeadline}年</Descriptions.Item>
        <Descriptions.Item label="是否免所得税">
          {getFieldLabel(isAvoidIncomeTax, item.isAvoidIncomeTax ? 1 : 0)}
        </Descriptions.Item>
      </>
    )
  }

  // 减免增值税 / 减免所得税
  const subsidyType2 = (item: any) => {
    return item?.taxReliefMap?.map((row: any) => {
      return (
        <>
          <Descriptions.Item label={item.subsidyType === 2 ? '起止时间' : '补贴对象'}>
            第{row.startYear} - {row.endYear}年
          </Descriptions.Item>
          <Descriptions.Item label="减免税额百分比">{row.rate}%</Descriptions.Item>
        </>
      )
    })
  }

  return (
    <Modal
      className={styles.incentiveAddModal}
      visible={visible}
      title={
        <>
          <span style={{ marginRight: 8 }}>{currentItem.name}</span>
        </>
      }
      width={640}
      keyboard={false}
      maskClosable={false}
      onCancel={onCancel}
      footer={false}
    >
      <Spin spinning={loading}>
        {type === 0 && (
          <>
            <Descriptions title="补贴类型" column={1}>
              <Descriptions.Item label="微网要素">
                {getFieldLabel(microgridFactor, item?.microgridFactor)}
              </Descriptions.Item>
              <Descriptions.Item label="补贴类型">{getFieldLabel(subsidyType, item?.subsidyType)}</Descriptions.Item>
            </Descriptions>

            <Descriptions title="补贴对象" column={2}>
              {item.subsidyType === 0 && subsidyType0(item)}
              {item.subsidyType === 1 && subsidyType1(item)}
              {(item.subsidyType === 2 || item.subsidyType === 3) && subsidyType2(item)}
            </Descriptions>
          </>
        )}
        {type === 1 &&
          state?.map((item: any, index: number) => {
            return (
              <div className={styles.groupItem} key={index}>
                <Descriptions title={item.name} key={item.awardSubsidyId} column={2}>
                  <Descriptions.Item label="微网要素">
                    {getFieldLabel(microgridFactor, item.microgridFactor)}
                  </Descriptions.Item>
                  <Descriptions.Item label="补贴类型">{getFieldLabel(subsidyType, item.subsidyType)}</Descriptions.Item>
                  {item.subsidyType === 0 && subsidyType0(item)}
                  {item.subsidyType === 1 && subsidyType1(item)}
                  {(item.subsidyType === 2 || item.subsidyType === 3) && subsidyType2(item)}
                </Descriptions>
              </div>
            )
          })}
      </Spin>
    </Modal>
  )
}

export default IncentiveModal
