import { Button, Col, Form, Input, Radio, Row, Space, Table } from 'antd'
import React from 'react'
import { ProjectWorkbenchService } from '../../../../apis/project-service-history'
import ReactECharts from 'echarts-for-react'
import { ChartColorMaps, CustomIcons } from '../../../../global'
import * as echarts from 'echarts'
import { createRangeFilter } from './index'
import styles from '../../../program/manage/detail/index.module.scss'

let query: { [key: string]: any } = {
  pvModuleName: undefined,
  manufacturer: undefined,
  cellType: undefined,
  stcMin: undefined,
  stcMax: undefined,
  ptcMin: undefined,
  ptcMax: undefined,
  bifacial: undefined,
  openVoltageMin: undefined,
  openVoltageMax: undefined,
  page: 1,
  size: 5,
  total: 0,
}

const layout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 18 },
}
export const PvModelSelect = (props: any) => {
  const { viewMode } = props

  const [pvModelList, setPvModelList] = React.useState<any[]>()
  const [current, setCurrent] = React.useState<any>()
  const [loading, setLoading] = React.useState(true)
  const [uploading, setUploading] = React.useState(false)

  const [nameFilter, setNameFilter] = React.useState<string | undefined>('')
  const [manufacturer, setManufacturer] = React.useState<string | undefined>('')
  const [form] = Form.useForm()

  const init = () => {
    const { photovoltaicModuleName } = props.current
    ProjectWorkbenchService.modelList({
      pvModuleName: photovoltaicModuleName,
      page: '1',
      size: '100',
    })
      .then((resp) => {
        setCurrent(resp.data.data[0])
      })
      .catch(() => {})
  }

  const loadData = () => {
    setLoading(true)
    const {
      pvModuleName,
      manufacturer,
      cellType,
      stcMin,
      stcMax,
      ptcMin,
      ptcMax,
      bifacial,
      openVoltageMin,
      openVoltageMax,
      page,
      size,
    } = query
    ProjectWorkbenchService.modelList({
      pvModuleName,
      manufacturer,
      cellType,
      stcMin,
      stcMax,
      ptcMin,
      ptcMax,
      bifacial,
      openVoltageMin,
      openVoltageMax,
      page: page.toString(),
      size: size.toString(),
    })
      .then((resp) => {
        query.total = resp.data.total
        query.page = resp.data.page
        setPvModelList(resp.data.data.map((o: any) => ({ key: o.id, ...o })))
      })
      .catch(() => {})
      .finally(() => setLoading(false))
  }

  React.useEffect(() => {
    init()
    if (!viewMode) {
      loadData()
    }
    return () => {
      query = {
        pvModuleName: undefined,
        manufacturer: undefined,
        cellType: undefined,
        stcMin: undefined,
        stcMax: undefined,
        ptcMin: undefined,
        ptcMax: undefined,
        bifacial: undefined,
        openVoltageMin: undefined,
        openVoltageMax: undefined,
        page: 1,
        size: 5,
        total: 0,
      }
    }
  }, [])

  const columns = [
    {
      title: '品牌',
      dataIndex: 'manufacturer',
      key: 'manufacturer',
      onFilterDropdownVisibleChange: (visible: boolean) => {
        if (!visible) {
          setManufacturer(query.manufacturer)
        }
      },
      filterIcon: (
        <CustomIcons
          type="klf-search"
          style={{
            color: query.manufacturer ? '#1ec692' : '#bfbfbf',
          }}
        />
      ),
      filterDropdown: ({ confirm, visible }: any) => {
        if (!visible) {
          ;(window as any).closeDropdown = null
        } else {
          setTimeout(() => {
            ;(window as any).closeDropdown = confirm
          })
        }
        return (
          <div
            style={{
              padding: '.5rem 1rem',
            }}
          >
            <Input
              placeholder="请输入品牌"
              value={manufacturer}
              onChange={(dom) => {
                setManufacturer(dom.target.value)
              }}
              onBlur={(dom) => {
                setManufacturer(dom.target.value)
              }}
              onPressEnter={() => {
                query.manufacturer = manufacturer
                loadData()
                confirm?.()
              }}
            />
            <div
              style={{
                marginTop: '.5rem',
                textAlign: 'right',
              }}
            >
              <Button
                onClick={() => {
                  setManufacturer(undefined)
                }}
              >
                重置
              </Button>
              <Button
                type="primary"
                style={{
                  marginLeft: '.5rem',
                }}
                onClick={() => {
                  query.manufacturer = manufacturer
                  loadData()
                  confirm?.()
                }}
              >
                确定
              </Button>
            </div>
          </div>
        )
      },
    },
    {
      title: '型号',
      dataIndex: 'pvModuleName',
      key: 'pvModuleName',
      onFilterDropdownVisibleChange: (visible: boolean) => {
        if (!visible) {
          setNameFilter(query.pvModuleName)
        }
      },
      filterIcon: (
        <CustomIcons
          type="klf-search"
          style={{
            color: query.pvModuleName ? '#1ec692' : '#bfbfbf',
          }}
        />
      ),
      filterDropdown: ({ confirm, visible }: any) => {
        if (!visible) {
          ;(window as any).closeDropdown = null
        } else {
          setTimeout(() => {
            ;(window as any).closeDropdown = confirm
          })
        }
        return (
          <div
            style={{
              padding: '.5rem 1rem',
            }}
          >
            <Input
              placeholder="请输入型号"
              value={nameFilter}
              onChange={(dom) => {
                setNameFilter(dom.target.value)
              }}
              onBlur={(dom) => {
                setNameFilter(dom.target.value)
              }}
              onPressEnter={() => {
                query.pvModuleName = nameFilter
                loadData()
                confirm?.()
              }}
            />
            <div
              style={{
                marginTop: '.5rem',
                textAlign: 'right',
              }}
            >
              <Button
                onClick={() => {
                  setNameFilter(undefined)
                }}
              >
                重置
              </Button>
              <Button
                type="primary"
                style={{
                  marginLeft: '.5rem',
                }}
                onClick={() => {
                  query.pvModuleName = nameFilter
                  loadData()
                  confirm?.()
                }}
              >
                确定
              </Button>
            </div>
          </div>
        )
      },
    },

    {
      title: '电池片类型',
      dataIndex: 'cellType',
      key: 'cellType',
      filterMultiple: false,
      filterIcon: <CustomIcons type="klf-filter" />,
      onFilterDropdownVisibleChange: (visible: boolean) => {
        if (!visible) {
          form.setFieldsValue({ cellType: query.cellType })
        }
      },
      filterDropdown: ({ confirm, visible, filters }: any) => {
        if (!visible) {
          ;(window as any).closeDropdown = null
        } else {
          setTimeout(() => {
            ;(window as any).closeDropdown = confirm
          })
        }
        return (
          <div className={styles.customFilter}>
            <Form
              onFinish={() => {
                query.cellType = form.getFieldValue('cellType')
                loadData()
                confirm()
              }}
              {...{
                labelCol: { span: 24 },
                wrapperCol: { span: 24 },
              }}
              colon={false}
              form={form}
            >
              <Form.Item
                className="label-low"
                name={'cellType'}
                label={'电池片类型'}
                style={{
                  alignItems: 'center',
                }}
              >
                <Radio.Group>
                  <Space direction="vertical">
                    {filters.map((filter: any) => (
                      <Radio key={filter.value} value={filter.value}>
                        {filter.text}
                      </Radio>
                    ))}
                  </Space>
                </Radio.Group>
              </Form.Item>
              <Form.Item className="filter-btns">
                <div
                  style={{
                    marginTop: '.5rem',
                    textAlign: 'right',
                  }}
                >
                  <Button
                    onClick={() => {
                      form.setFieldsValue({
                        cellType: undefined,
                      })
                    }}
                  >
                    重置
                  </Button>
                  <Button
                    type="primary"
                    style={{
                      marginLeft: '.5rem',
                    }}
                    htmlType="submit"
                  >
                    确定
                  </Button>
                </div>
              </Form.Item>
            </Form>
          </div>
        )
      },
      filters: [
        { text: '多晶', value: '多晶' },
        { text: '单晶', value: '单晶' },
        { text: 'Thin Film', value: 'Thin Film' },
      ],
    },
    {
      title: 'STC最大功率',
      dataIndex: 'stc',
      key: 'stc',
      render: (val: string) => {
        return `${val}Wdc`
      },
      filterDropdown: ({ confirm, visible }: any) => {
        if (!visible) {
          ;(window as any).closeDropdown = null
        } else {
          setTimeout(() => {
            ;(window as any).closeDropdown = confirm
          })
        }
        return createRangeFilter(confirm, 'stc', 'STC最大功率', 'Wdc', form, query, layout)
      },
      filterIcon: (
        <CustomIcons
          type="klf-filter"
          style={{
            color: form.getFieldValue('stcMin') || form.getFieldValue('stcMax') ? '#1ec692' : '#bfbfbf',
          }}
        />
      ),
      onFilterDropdownVisibleChange: (visible: boolean) => {
        const key = 'stc'
        if (!visible) {
          if (query[`${key}Change`]) {
            query[`${key}Change`] = false
            query[`${key}Min`] = form.getFieldsValue()[`${key}Min`]
            query[`${key}Max`] = form.getFieldsValue()[`${key}Max`]
            loadData()
          } else {
            form.setFieldsValue({
              [`${key}Max`]: query[`${key}Max`],
              [`${key}Min`]: query[`${key}Min`],
            })
          }
        } else {
          form.setFieldsValue({
            [`${key}Max`]: query[`${key}Max`],
            [`${key}Min`]: query[`${key}Min`],
          })
        }
      },
    },
    {
      title: 'PTC最大功率',
      dataIndex: 'ptc',
      key: 'ptc',
      render: (val: string) => {
        return `${val}Wdc`
      },
      filterDropdown: ({ confirm, visible }: any) => {
        if (!visible) {
          ;(window as any).closeDropdown = null
        } else {
          setTimeout(() => {
            ;(window as any).closeDropdown = confirm
          })
        }
        return createRangeFilter(confirm, 'ptc', 'PTC最大功率', 'Wdc', form, query, layout)
      },
      filterIcon: (
        <CustomIcons
          type="klf-filter"
          style={{
            color: form.getFieldValue('ptcMin') || form.getFieldValue('ptcMax') ? '#1ec692' : '#bfbfbf',
          }}
        />
      ),
      onFilterDropdownVisibleChange: (visible: boolean) => {
        const key = 'ptc'
        if (!visible) {
          if (query[`${key}Change`]) {
            query[`${key}Change`] = false
            query[`${key}Min`] = form.getFieldsValue()[`${key}Min`]
            query[`${key}Max`] = form.getFieldsValue()[`${key}Max`]
            loadData()
          } else {
            form.setFieldsValue({
              [`${key}Max`]: query[`${key}Max`],
              [`${key}Min`]: query[`${key}Min`],
            })
          }
        } else {
          form.setFieldsValue({
            [`${key}Max`]: query[`${key}Max`],
            [`${key}Min`]: query[`${key}Min`],
          })
        }
      },
    },
    {
      title: '单/双面',
      dataIndex: 'isBifacial',
      key: 'bifacial',
      filterMultiple: false,
      filterIcon: <CustomIcons type="klf-filter" />,
      onFilterDropdownVisibleChange: (visible: boolean) => {
        if (!visible) {
          form.setFieldsValue({ bifacial: query.bifacial })
        }
      },
      filterDropdown: ({ confirm, visible, filters }: any) => {
        if (!visible) {
          ;(window as any).closeDropdown = null
        } else {
          setTimeout(() => {
            ;(window as any).closeDropdown = confirm
          })
        }
        return (
          <div className={styles.customFilter}>
            <Form
              onFinish={() => {
                query.bifacial = form.getFieldValue('bifacial')
                loadData()
                confirm()
              }}
              {...{
                labelCol: { span: 24 },
                wrapperCol: { span: 24 },
              }}
              colon={false}
              form={form}
            >
              <Form.Item
                name={'bifacial'}
                label={'电池片类型'}
                className="label-low"
                style={{
                  alignItems: 'center',
                }}
              >
                <Radio.Group>
                  <Space direction="vertical">
                    {filters.map((filter: any) => (
                      <Radio key={filter.value} value={filter.value}>
                        {filter.text}
                      </Radio>
                    ))}
                  </Space>
                </Radio.Group>
              </Form.Item>
              <Form.Item className="filter-btns">
                <div
                  style={{
                    marginTop: '.5rem',
                    textAlign: 'right',
                  }}
                >
                  <Button
                    onClick={() => {
                      form.setFieldsValue({
                        bifacial: undefined,
                      })
                    }}
                  >
                    重置
                  </Button>
                  <Button
                    type="primary"
                    style={{
                      marginLeft: '.5rem',
                    }}
                    htmlType="submit"
                  >
                    确定
                  </Button>
                </div>
              </Form.Item>
            </Form>
          </div>
        )
      },
      filters: [
        { text: '单面', value: 0 },
        { text: '双面', value: 1 },
      ],
      render: (val: any) => {
        return val ? '双面' : '单面'
      },
    },
    {
      title: '开路电压',
      dataIndex: 'referenceOpenCircuitVoltage',
      key: 'referenceOpenCircuitVoltage',
      render: (val: string) => {
        return `${val}Vdc`
      },
      filterDropdown: ({ confirm, visible }: any) => {
        if (!visible) {
          ;(window as any).closeDropdown = null
        } else {
          setTimeout(() => {
            ;(window as any).closeDropdown = confirm
          })
        }
        return createRangeFilter(confirm, 'openVoltage', '开路电压', 'Vdc', form, query, layout)
      },
      filterIcon: (
        <CustomIcons
          type="klf-filter"
          style={{
            color: form.getFieldValue('openVoltageMin') || form.getFieldValue('openVoltageMax') ? '#1ec692' : '#bfbfbf',
          }}
        />
      ),
      onFilterDropdownVisibleChange: (visible: boolean) => {
        const key = 'openVoltage'
        if (!visible) {
          if (query[`${key}Change`]) {
            query[`${key}Change`] = false
            query[`${key}Min`] = form.getFieldsValue()[`${key}Min`]
            query[`${key}Max`] = form.getFieldsValue()[`${key}Max`]
            loadData()
          } else {
            form.setFieldsValue({
              [`${key}Max`]: query[`${key}Max`],
              [`${key}Min`]: query[`${key}Min`],
            })
          }
        } else {
          form.setFieldsValue({
            [`${key}Max`]: query[`${key}Max`],
            [`${key}Min`]: query[`${key}Min`],
          })
        }
      },
    },
  ]

  return (
    <div style={{width: '100%',}}>
      {!viewMode && (
        <Table
          loading={loading}
          dataSource={pvModelList}
          className="table-minimal"
          columns={columns}
          scroll={{ x: '100%'}}
          style={{
            marginBottom: '1rem',
          }}
          rowSelection={{
            type: 'radio',
            onChange: (val: any) => {
              setCurrent(pvModelList?.find((o) => o.key === val[0]))
            },
          }}
          onChange={(pager: any, filter: any) => {
            const { cellType, bifacial } = filter
            const { current, pageSize } = pager
            if (!cellType) {
              query.cellType = undefined
            } else {
              query.cellType = cellType[0]
            }
            if (!bifacial) {
              query.bifacial = undefined
            } else {
              query.bifacial = bifacial[0]
            }
            query.page = current
            query.size = pageSize
            loadData()
          }}
          pagination={{
            total: query.total,
            showSizeChanger: true,
            showQuickJumper: true,
            pageSize: query.size,
            pageSizeOptions: ['5', '10', '20', '50'],
            showTotal: (total) => `共 ${total} 条`,
          }}
        />
      )}
      {current && (
        <div>
          <div>
            <strong>IV曲线</strong>
          </div>
          <div className="dialog-tips">{current.pvModuleName}</div>
          <Row
            gutter={[16, 8]}
            style={{
              marginBottom: '1rem',
            }}
          >
            <Col span={8}>
              <strong>电池片类型：</strong>
              <span>{current.cellType}</span>
            </Col>
            <Col span={8}>
              <strong>最大功率时电压：</strong>
              <span>{current.referenceMaxPowerPointVoltage}Vdc</span>
            </Col>
            <Col span={8}>
              <strong>短路电流：</strong>
              <span>{current.referenceShortCircuitCurrent}Adc</span>
            </Col>
            <Col span={8}>
              <strong>光伏电池数：</strong>
              <span>{current.numberOfCells}</span>
            </Col>
            <Col span={8}>
              <strong>最大功率时电流：</strong>
              <span>{current.referenceMaxPowerPointCurrent}Adc</span>
            </Col>
            <Col span={8}>
              <strong>开路电压：</strong>
              <span>{current.referenceOpenCircuitVoltage}Vdc</span>
            </Col>
            <Col span={8}>
              <strong>模块面积：</strong>
              <span>{current.moduleAreaInMeters}㎡</span>
            </Col>
            <Col span={8}>
              <strong>最大功率时功率：</strong>
              <span>{current.referenceMaxPowerPointPower}Wdc</span>
            </Col>
          </Row>
          {current && (
            <div
              id="pv-model-current"
              style={{
                height: 250,
              }}
            >
              <ReactECharts
                style={{
                  width: '100%',
                  height: '100%',
                }}
                option={{
                  animationDuration: 50,
                  grid: { left: 20, top: 30, bottom: 0, right: 20, containLabel: true },
                  tooltip: {
                    trigger: 'axis',
                    formatter: (argsList: any) => {
                      const args = argsList[0]
                      return `${args.marker}电压<span style="display:inline-block;width:100px;text-align:right">${args.axisValueLabel}V</span><br />${args.marker}电流<span style="display:inline-block;width:100px;text-align:right">${args.value[1]}A</span>`
                    },
                  },
                  xAxis: {
                    type: 'value',
                    boundaryGap: false,
                    // name: 'V',
                    axisLine: {
                      onZero: false,
                    },
                    splitLine: {
                      show: false,
                    },
                    axisTick: {
                      show: false,
                    },
                    axisLabel: {
                      formatter: (val: number) => {
                        if (val < Math.min(...current.plot.x)) return ''
                        if (val > Math.max(...current.plot.x)) return 'V'
                        return val
                      },
                    },
                  },
                  yAxis: {
                    type: 'value',
                    name: 'A',
                    axisLine: {
                      show: false,
                    },
                    axisTick: {
                      show: false,
                    },
                    nameTextStyle: {
                      color: 'gray',
                      padding: [0, 35, 0, 0],
                    },
                    splitLine: {
                      lineStyle: {
                        type: [5, 5],
                        dashOffset: 5,
                      },
                    },
                  },
                  series: [
                    {
                      name: '需量',
                      type: 'line',
                      smooth: true,
                      showSymbol: false,
                      data: current.plot.x.map((o: string, idx: number) => [parseFloat(o), current.plot.y[idx]]),
                      itemStyle: {
                        color: ChartColorMaps[0].line,
                      },
                      areaStyle: {
                        color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                          {
                            offset: 0,
                            color: ChartColorMaps[0].area[0],
                          },
                          {
                            offset: 1,
                            color: ChartColorMaps[0].area[1],
                          },
                        ]),
                      },
                    },
                  ],
                }}
              />
            </div>
          )}
        </div>
      )}
      <div className="ant-modal-confirm-btns">
        {!viewMode && (
          <Button
            onClick={() => {
              props.onClose?.()
            }}
          >
            取 消
          </Button>
        )}
        <Button
          type="primary"
          loading={uploading}
          onClick={() => {
            if (viewMode) {
              props.onClose?.()
              return
            }
            setUploading(true)
            if (props.onChange) {
              props.onChange(current)
            } else {
              ProjectWorkbenchService.updatePhotovoltaicModule({
                technologyId: props.technologyId,
                root: {
                  photovoltaicModuleId: current.id,
                },
              }).then(() => {
                props.onClose?.(true)
              })
            }
          }}
        >
          确 定
        </Button>
      </div>
    </div>
  )
}
