import { useEffect, useState } from 'react'
import styles from './index.module.scss'
import { useLocation } from 'react-router-dom'
import { Form, Button, Input, message, Row, Col, Modal, notification } from 'antd'
import { ExclamationCircleFilled } from '@ant-design/icons'
import HeadUpload from './components/header-upload'
import HeadUploadBtn from './components/header-upload-btn'
import { UserService } from '../../apis/project-service-history'
import Global from '../../global'
import { sm2 } from 'sm-crypto'
import { SM2CODE } from '../../utils/constant'
import EditEmailModal from './components/edit-email-modal'
import { SideMenuObservable } from '../../application'
import { SubBreadcrumbObservable } from './layout'
import { PasswordNotice } from '../login/components/password-notice'

const formItemLayout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 18 },
}

const disableHistory = () => {
  history.forward()
}

// 参数库公共报错提示
export const paramsStoreShowError = (text: string) => {
  notification.warning({
    icon: <ExclamationCircleFilled style={{ color: '#f97942' }} />,
    message: <strong>警告提示</strong>,
    description: text,
    duration: 3,
  })
}

// 用户中心 修改用户信息用
const UserCenter = () => {
  const [form] = Form.useForm()
  const [saveLoading, setSaveLoading] = useState(false)
  const [visible, setVisible] = useState({
    editName: false,
    editCompany: false,
    editEmail: false,
    editPhone: false,
    editPassword: false,
    bindEmail: false,
  })
  const [pwdValidate, setPwdValidate] = useState<any>({
    validateStatus: undefined,
    help: ' ',
  })
  const location = useLocation()

  // 用户信息
  const [user, setUser] = useState({
    userName: '',
    headUrl: '',
    email: '',
    emailCheck: false,
    companyName: '',
    telephone: '',
    field: '',
  })

  useEffect(() => {
    SubBreadcrumbObservable.next([
      {
        name: '设置',
        url: '',
      },
      {
        name: '账号信息',
        url: '/user-center/account',
      },
    ])
    SideMenuObservable.next(true)
    // 获取通过路径传递过来的user信息
    if (location.state) {
      setUser(location.state as any)
      form.setFieldsValue({
        ...(location.state as any),
      })
    } else {
      setUser(Global.user)
    }
  }, [])

  const updateUser = (user: any) => {
    Global.updateUser.forEach((fn: any) => {
      fn(user)
    })
  }

  // 保存用户信息
  const handleSaveProfile = (params?: any) => {
    form.validateFields().then(() => {
      const values = form.getFieldsValue()
      setSaveLoading(true)
      if (!user.email) {
        return
      } else {
        UserService.update({ root: { ...user, ...params, ...values } })
          .then((res) => {
            if (res.code === 200) {
              Global.user = Object.assign(user, {
                ...values,
              })
              updateUser(
                Object.assign({}, user, {
                  ...values,
                })
              )
              message.success('保存成功')
              setVisible({
                editName: false,
                editCompany: false,
                editEmail: false,
                editPhone: false,
                editPassword: false,
                bindEmail: false,
              })
            }
          })
          .catch(() => {})
          .finally(() => {
            setSaveLoading(false)
          })
      }
    })
  }

  // 修改密码
  const editPassword = () => {
    form
      .validateFields()
      .then(() => {
        const values = form.getFieldsValue()
        if (!values.validNewPassword) {
          setPwdValidate({
            validateStatus: 'error',
            help: '请再次输入密码',
          })
          return
        }
        setSaveLoading(true)
        UserService.updatePassword({
          root: {
            oldPassword: sm2.doEncrypt(values.oldPassword, SM2CODE, 0),
            newPassword: sm2.doEncrypt(values.newPassword, SM2CODE, 0),
            validNewPassword: sm2.doEncrypt(values.validNewPassword, SM2CODE, 0),
          },
        })
          .then((res) => {
            if (res.code === 200) {
              Global.user = Object.assign(user, {
                ...values,
              })
              message.success('保存成功')
              setVisible({
                editName: false,
                editCompany: false,
                editEmail: false,
                editPhone: false,
                editPassword: false,
                bindEmail: false,
              })
              form.resetFields()
            }
          })
          .catch(() => {})
          .finally(() => {
            setSaveLoading(false)
          })
      })
      .catch((e) => {
        console.log(e)
        setSaveLoading(false)
      })
  }
  const [showNotice, setShowNotice] = useState(false)
  const [password, setPassword] = useState('')

  return (
    <div className={`${styles.userCenter}`}>
      <h2 style={{ fontWeight: 700 }}>账号信息</h2>
      <div className="user-center-wrap">
        <Row style={{ marginBottom: 20 }}>
          <Col style={{ width: 65, marginRight: 20 }}>头像</Col>
          <Col style={{ width: 320 }}>
            <HeadUpload user={user} />
          </Col>
          <Col>
            <HeadUploadBtn
              user={user}
              onChange={(e: string) => {
                Global.user = Object.assign(user, {
                  headUrl: e,
                })
                setUser({ ...user, headUrl: e })
                updateUser(
                  Object.assign({}, user, {
                    headUrl: e,
                  })
                )
                handleSaveProfile({ headUrl: e })
              }}
            />
          </Col>
        </Row>
        <Row>
          <Col style={{ width: 65, marginRight: 20 }}>昵称</Col>
          <Col style={{ width: 320 }}>{user.userName}</Col>
          <Col>
            <Button
              onClick={() => {
                form.setFieldsValue({
                  userName: user.userName,
                })
                setVisible({
                  ...visible,
                  editName: true,
                })
              }}
            >
              编辑
            </Button>
          </Col>
        </Row>
        <Row>
          <Col style={{ width: 65, marginRight: 20 }}>公司名</Col>
          <Col style={{ width: 320 }}>
            {!user.companyName ? <span className="desc">未填写</span> : user.companyName}
          </Col>
          <Col>
            <Button
              className={!user.companyName ? 'danger-tips' : ''}
              type="default"
              onClick={() => {
                form.setFieldsValue({
                  companyName: user.companyName,
                })
                setVisible({
                  ...visible,
                  editCompany: true,
                })
              }}
            >
              {!user.companyName ? '立即填写' : '编辑'}
            </Button>
          </Col>
        </Row>
        <Row>
          <Col style={{ width: 65, marginRight: 20 }}>邮箱</Col>
          <Col style={{ width: 320 }}>
            {!user.emailCheck ? (
              <span className="danger-tips">{user.email.replace(/(.{2}).+(.{2}@.+)/g, '$1****$2')}</span>
            ) : (
              user.email.replace(/(.{2}).+(.{2}@.+)/g, '$1****$2')
            )}
          </Col>
          <Col>
            <Button
              className={!user.emailCheck ? 'danger-tips' : ''}
              onClick={() => {
                form.setFieldsValue({
                  email: user.email,
                })
                user.emailCheck
                  ? setVisible({
                      ...visible,
                      editEmail: true,
                    })
                  : setVisible({
                      ...visible,
                      bindEmail: true,
                    })
              }}
            >
              {!user.emailCheck ? '立即验证' : '编辑'}
            </Button>
          </Col>
        </Row>
        <Row>
          <Col style={{ width: 65, marginRight: 20 }}>手机号</Col>
          <Col style={{ width: 320 }}>{user.telephone.replace(/1(\d{2})\d{4}(\d{4})/g, '1$1****$2')}</Col>
          <Col>
            <Button
              onClick={() => {
                form.setFieldsValue({
                  telephone: user.telephone,
                })
                setVisible({
                  ...visible,
                  editPhone: true,
                })
              }}
            >
              编辑
            </Button>
          </Col>
        </Row>
        <Row>
          <Col style={{ width: 65, marginRight: 20 }}>密码</Col>
          <Col style={{ width: 320 }}>*******</Col>
          <Col>
            <Button
              onClick={() => {
                setVisible({
                  ...visible,
                  editPassword: true,
                })
              }}
            >
              编辑
            </Button>
          </Col>
        </Row>

        {/* 修改昵称 */}
        <Modal
          visible={visible.editName}
          title="修改昵称"
          maskClosable={false}
          keyboard={false}
          confirmLoading={saveLoading}
          destroyOnClose
          onOk={() => {
            handleSaveProfile()
          }}
          onCancel={() => {
            setVisible({
              ...visible,
              editName: false,
            })
          }}
        >
          <Form form={form}>
            <Form.Item
              name="userName"
              label="昵称"
              initialValue={user.userName}
              rules={[
                {
                  required: true,
                  message: '请输入用户昵称',
                },
                {
                  pattern: /^[\u4E00-\u9FA5A-Za-z0-9_-]+$/,
                  message: '仅支持中文、英文大小写、数字、-和_',
                },
                {
                  validator: (rule: any, value: any) => {
                    return new Promise((resolve, reject) => {
                      let length = 0
                      for (let i = 0; i < value.length; i++) {
                        if (/[\u4E00-\u9FA5]+/.test(value[i])) {
                          length += 2
                        } else {
                          length += 1
                        }
                      }
                      if (length > 0 && length < 17) {
                        resolve('')
                      } else {
                        reject('昵称长度1~16,中文占2个字符')
                      }
                    })
                  },
                },
              ]}
              getValueFromEvent={(e: any) => {
                return e.target.value?.replace(/\s+/g, '')
              }}
            >
              <Input size="large" placeholder="请输入用户昵称" />
            </Form.Item>
          </Form>
        </Modal>

        {/* 修改公司名 */}
        <Modal
          visible={visible.editCompany}
          title="修改公司名"
          maskClosable={false}
          keyboard={false}
          confirmLoading={saveLoading}
          destroyOnClose
          onOk={() => {
            handleSaveProfile()
          }}
          onCancel={() => {
            setVisible({
              ...visible,
              editCompany: false,
            })
          }}
        >
          <Form form={form}>
            <Form.Item
              name="companyName"
              label="公司名"
              initialValue={user.companyName}
              rules={[
                {
                  required: true,
                  message: '请输入公司名',
                },
                {
                  pattern: /^[\u4E00-\u9FA5A-Za-z0-9_-]+$/,
                  message: '仅支持中文、英文大小写、数字、-和_',
                },
                {
                  validator: (rule: any, value: any) => {
                    return new Promise((resolve, reject) => {
                      let length = 0
                      for (let i = 0; i < value?.length; i++) {
                        if (/[\u4E00-\u9FA5]+/.test(value[i])) {
                          length += 2
                        } else {
                          length += 1
                        }
                      }
                      if (length === 0) {
                        reject('')
                      } else if (length === 1) {
                        reject('公司名太短')
                      } else if (length > 1 && length < 33) {
                        resolve('')
                      } else {
                        reject('公司名长度2~32,中文占2个字符')
                      }
                    })
                  },
                },
              ]}
            >
              <Input size="large" placeholder="请输入公司名" />
            </Form.Item>
          </Form>
        </Modal>

        {/* 修改邮箱手机号 */}
        {(visible.editEmail || visible.bindEmail || visible.editPhone) && (
          <EditEmailModal
            onSuccess={(value: any) => {
              if (visible.editEmail || visible.bindEmail) {
                Global.user = { ...Global.user, emailCheck: true, emailTipVisible: false, email: value || user.email }
                updateUser(Object.assign({}, Global.user))
                window.removeEventListener('popstate', disableHistory)
              } else {
                Global.user = { ...Global.user, telephone: value, emailTipVisible: false }
              }
              setUser(
                visible.editEmail || visible.bindEmail
                  ? {
                      ...user,
                      email: value,
                      emailCheck: true,
                    }
                  : {
                      ...user,
                      telephone: value,
                    }
              )
              setVisible({
                ...visible,
                editEmail: false,
                editPhone: false,
                bindEmail: false,
              })
            }}
            onCancel={() => {
              setVisible({
                ...visible,
                editEmail: false,
                editPhone: false,
                bindEmail: false,
              })
            }}
            setDefault={!user.emailCheck}
            type={visible.editEmail || visible.bindEmail ? 'email' : 'phone'}
            defaultValue={user.email}
            visible={visible.editEmail || visible.editPhone || visible.bindEmail}
          />
        )}

        {/* 修改密码 */}
        {visible.editPassword && (
          <Modal
            visible={visible.editPassword}
            title="修改密码"
            maskClosable={false}
            keyboard={false}
            confirmLoading={saveLoading}
            destroyOnClose
            onOk={() => {
              editPassword()
            }}
            onCancel={() => {
              form.resetFields()
              setPassword('')
              setVisible({
                ...visible,
                editPassword: false,
              })
            }}
          >
            <Form form={form} {...formItemLayout}>
              <Form.Item
                name="oldPassword"
                label="验证密码"
                rules={[
                  {
                    required: true,
                    message: '请输入新密码',
                  },
                ]}
              >
                <Input size="large" type="password" placeholder="请输入当前登录密码" />
              </Form.Item>
              <Form.Item
                name="newPassword"
                label="新密码"
                extra={showNotice && <PasswordNotice password={password || ''} />}
                rules={[
                  {
                    required: true,
                    message: '请输入新密码',
                  },
                  {
                    min: 8,
                    max: 16,
                    message: '密码长度8~16位',
                  },
                  {
                    pattern: /^.*(?=.*\d)(?=.*[A-Z])(?=.*[a-z]).*$/,
                    message: '必须包含大写字母小写字母和数字',
                  },
                  {
                    pattern: /((?=[\x21-\x7e]+)[^A-Za-z0-9])/,
                    message: '必须包含特殊字符',
                  },
                ]}
              >
                <Input
                  onFocus={() => {
                    setShowNotice(true)
                  }}
                  onBlur={() => {
                    setShowNotice(false)
                  }}
                  onChange={(dom) => {
                    setPassword(dom.target.value)
                  }}
                  size="large"
                  type="password"
                  placeholder="请输入新密码"
                />
              </Form.Item>
              <Form.Item
                name="validNewPassword"
                label="确认密码"
                validateStatus={pwdValidate.validateStatus}
                help={pwdValidate.help}
              >
                <Input
                  onChange={(e: any) => {
                    const values = form.getFieldsValue(['newPassword'])
                    if (!e.currentTarget.value) {
                      setPwdValidate({
                        validateStatus: 'error',
                        help: '请再次输入密码',
                      })
                    } else if (e.currentTarget.value !== values.newPassword) {
                      setPwdValidate({
                        validateStatus: 'error',
                        help: '两次密码不一致',
                      })
                    } else {
                      setPwdValidate({
                        validateStatus: undefined,
                        help: '',
                      })
                    }
                  }}
                  size="large"
                  type="password"
                  placeholder="请在此输入密码"
                />
              </Form.Item>
            </Form>
          </Modal>
        )}
      </div>
    </div>
  )
}

export default UserCenter
