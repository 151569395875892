import React, { useEffect, useState } from 'react'
import { Cascader } from 'antd'
import { ProvinceService } from '../../apis/wl-service'
import './index.less'

interface IOption {
  id: string
  fullName: string
  children?: IOption[]
  isLeaf?: boolean
  level?: number
  loading?: boolean
}

export interface IAddressSelectorProps {
  placeholder?: string
  disabled?: boolean
  level?: number
  value?: any
  // onChange?: CascaderProps<any>['onChange']
  onChange?: (value: any) => void
}

const AddressSelector: React.FC<IAddressSelectorProps> = ({ placeholder, value, disabled, level = 2, onChange }) => {
  const [options, setOptions] = useState<IOption[]>([])
  const [rootOptions, setRootOptions] = useState<IOption[]>([]) // 根数据
  const unmountedRef = React.useRef<boolean>(false) // 页面卸载标识

  // 加载根数据
  useEffect(() => {
    ProvinceService.getSelector(-1)
      .then((resp: any) => {
        if (unmountedRef.current) {
          return
        }
        resp = resp?.data?.list ?? []
        setRootOptions(resp)
        setOptions(resp)
      })
      .catch((err: any) => {
        console.warn(err)
      })

    return () => {
      unmountedRef.current = true
    }
  }, [])

  // 计算属地选中值
  React.useLayoutEffect(() => {
    const initData = async () => {
      if (value?.length > 0 && rootOptions.length > 0) {
        let nextOptions = [...rootOptions]
        let targetOption: any = nextOptions
          .map((v: any) => {
            v.level = 0
            return v
          })
          .find((v: any) => v.id === value[0])
        for (let i = 1, length = value.length; i < length; ) {
          const mapFn = (v: any) => {
            v.level = (targetOption.level ?? 0) + 1
            v.isLeaf = v.level >= level
            return v
          }
          const findFn = (v: any) => v.id === value[i]
          const current = targetOption?.children?.map(mapFn)?.find(findFn)
          if (!current) {
            const resp: any = await ProvinceService.getSelector(value[i - 1])
            targetOption.children = resp?.data?.list
            targetOption = targetOption.children.map(mapFn).find(findFn)
          } else {
            targetOption = current
          }
          targetOption.isLeaf = targetOption.level >= level
          i++
        }

        !unmountedRef.current && setOptions(nextOptions)
      }
    }
    initData()
  }, [value, rootOptions, level])

  const loadData = async (selectedOptions: IOption[]) => {
    const targetOption = selectedOptions[selectedOptions.length - 1]
    targetOption.level = targetOption.level ?? 0
    if (targetOption.level >= level) {
      setOptions([...options])
      return false
    }

    targetOption.loading = true

    try {
      // load options lazily
      const resp: any = await ProvinceService.getSelector(targetOption.id ?? -1)
      targetOption.loading = false
      targetOption.children = (resp?.data?.list ?? []).map((v: any) => {
        const currrentLevel = (targetOption.level ?? 0) + 1
        return { ...v, level: currrentLevel, isLeaf: currrentLevel >= level }
      })
      !unmountedRef.current && setOptions([...options])
    } catch (err: any) {
      targetOption.loading = false
      targetOption.children = []
      !unmountedRef.current && setOptions([...options])
      console.warn('查询地址报错: ', err)
    }
  }

  return (
    <Cascader
      allowClear
      multiple={false}
      changeOnSelect={false}
      dropdownClassName="wl-address-dropdown"
      disabled={disabled}
      placement="bottomRight"
      showCheckedStrategy={Cascader.SHOW_CHILD}
      fieldNames={{ label: 'fullName', value: 'id', children: 'children' }}
      options={options}
      placeholder={placeholder}
      loadData={loadData as any}
      value={value}
      onChange={onChange}
    />
  )
}

export default AddressSelector
