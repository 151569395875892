// @ts-ignore
import mapboxgl, {GeoJSONSource} from '!mapbox-gl'
import 'mapbox-gl/dist/mapbox-gl.css'

import React, {useEffect, useState} from 'react'
import {colorArrs, getCityData} from './mapbox-data'
import {message, Slider, Spin} from 'antd'
import bbox from '@turf/bbox'
import {parseNumber, parseSuffixNumber} from './resource-map'
import {EvaluationService} from '../../apis/project-service-history'
import {RasterSource, VectorSource} from 'mapbox-gl'
import {CustomIcons} from '../../global'
import {weatherConfig} from './mbp-config'
import {PauseOutlined, StepBackwardOutlined, StepForwardOutlined} from '@ant-design/icons'
import {EvaluationV2Api} from '../../services'

let ignoreBubble = false

const mbxCityLayer = true


const unitMap: any = {
  GHI: 'W/㎡',
  DNI: 'W/㎡',
  DHI: 'W/㎡',
  TMP: '℃',
  WS: 'm/s',
  PRES: 'kPa',
  RH: '%',
  RAINFALL: 'mm',

  regionArea: 'k㎡',
  roofCount: '个',
  roofRatio: '‰',
  roofArea: '㎡',
  avgRoofArea: '㎡'
}


const minMap: any = {
  GHI: 27.40,
  DNI: 27.99,
  DHI: 14.45,
  TMP: -26.62,
  WS: 1.08,
  PRES: 51342.08,
  RH: 13.09,
  RAINFALL: 0.00
}

const maxMap: any = {
  GHI: 276.7,
  DNI: 253.73,
  DHI: 142.21,
  TMP: 34.31,
  WS: 12.19,
  PRES: 103783.79,
  RH: 100.00,
  RAINFALL: 1.06
}


const provinceBbox = {
  '100000': [73.502355, 3.83703, 135.09567, 53.563624],
  '110000': [115.416855, 39.441461, 117.508134, 41.059233],
  '120000': [116.702128, 38.554815, 118.059217, 40.25179],
  '130000': [113.458721, 36.048394, 119.866915, 42.617629],
  '140000': [110.224329, 34.583264, 114.562939, 40.738366],
  '150000': [97.171967, 37.406246, 126.067939, 53.335367],
  '210000': [118.839424, 38.718924, 125.785621, 43.488571],
  '220000': [121.638323, 40.864614, 131.314285, 46.301935],
  '230000': [121.174279, 43.42301, 135.09567, 53.563624],
  '310000': [120.85262, 30.67779, 122.242919, 31.874625],
  '320000': [116.357106, 30.760149, 121.970943, 35.125012],
  '330000': [118.023019, 27.044745, 122.945311, 31.180867],
  '340000': [114.877479, 29.395624, 119.644266, 34.651695],
  '350000': [115.847634, 23.531158, 120.723299, 28.316203],
  '360000': [113.572778, 24.488947, 118.482141, 30.079839],
  '370000': [114.813671, 34.378534, 122.711101, 38.401667],
  '410000': [110.355026, 31.384369, 116.646412, 36.366508],
  '420000': [108.36247, 29.032624, 116.129963, 33.277542],
  '430000': [108.786641, 24.639321, 114.256035, 30.129091],
  '440000': [109.65079, 20.21418, 117.313712, 25.519616],
  '450000': [104.447754, 20.900972, 112.056399, 26.389041],
  '460000': [108.583473, 3.83703, 117.841863, 20.166159],
  '500000': [105.286411, 28.163658, 110.194854, 32.203442],
  '510000': [97.347424, 26.049272, 108.54241, 34.314704],
  '520000': [103.599576, 24.621194, 109.593349, 29.224317],
  '530000': [97.527699, 21.144353, 106.194221, 29.226134],
  '540000': [78.393301, 26.858273, 99.115193, 36.48355],
  '610000': [105.488224, 31.706994, 111.241569, 39.585793],
  '620000': [92.337825, 32.596184, 108.707503, 42.79343],
  '630000': [89.401766, 31.600933, 103.068637, 39.207695],
  '640000': [104.284273, 35.238766, 107.654309, 39.380676],
  '650000': [73.502355, 34.336312, 96.385334, 49.181411],
  '710000': [119.313606, 21.754124, 124.561574, 25.928982],
  '810000': [113.834898, 22.154044, 114.44239, 22.561917],
  '820000': [113.528181, 22.10915, 113.598862, 22.217043]
}

const layerSources = {
  province: {
    // tiles: 'mapbox://owops.dk3y88fo',
    // layer: 'cn_ot_1-7qds5f',
    tiles: 'mapbox://owops.1ctxskkw',
    layer: 'res_province-bh0173'
  },
  city: {
    // tiles: 'mapbox://owops.duiqod0q',
    // layer: 'cn_ot_2-ab1euo',

    tiles: 'mapbox://owops.ana1jcs4',
    layer: 'res_city-1csyo9'
  },
  district: {
    tiles: 'mapbox://owops.3zbvm0vs',
    layer: 'res_district-5jvyf5'
  }
}

const marks: any = {}
for (let i = 1; i <= 12; i++) {
  marks[i] = `${i}月`
}
const filterSelectors = [
  {
    selectedIcon: 'klf-a-taiyangzhishebeifen14',
    icon: 'klf-a-taiyangzhishebeifen2',
    title: '太阳总辐射',
    key: 'GHI'
  },
  {
    selectedIcon: 'klf-a-taiyangzhishebeifen12',
    icon: 'klf-taiyangzhishe',
    title: '太阳直接辐射',
    key: 'DNI'
  },
  {
    selectedIcon: 'klf-a-taiyangzhishebeifen13',
    icon: 'klf-taiyangzhishebeifen',
    title: '散射辐射',
    key: 'DHI'
  },
  {
    selectedIcon: 'klf-a-taiyangzhishebeifen15',
    icon: 'klf-a-taiyangzhishebeifen3',
    title: '温度',
    key: 'TMP'
  },
  {
    selectedIcon: 'klf-a-taiyangzhishebeifen16',
    icon: 'klf-a-taiyangzhishebeifen4',
    title: '风速',
    key: 'WS'
  },
  {
    selectedIcon: 'klf-a-taiyangzhishebeifen17',
    icon: 'klf-a-taiyangzhishebeifen5',
    title: '气压',
    key: 'PRES'
  },
  {
    selectedIcon: 'klf-a-taiyangzhishebeifen18',
    icon: 'klf-a-taiyangzhishebeifen6',
    title: '相对湿度',
    key: 'RH'
  },
  {
    selectedIcon: 'klf-a-taiyangzhishebeifen19',
    icon: 'klf-a-taiyangzhishebeifen7',
    title: '降水量',
    key: 'RAINFALL'
  }
]


const parseDom = (str: string | number) => {
  if (typeof str !== 'string') {
    return str
  }
  const strArr = str.split('^')
  if (strArr.length === 2) {
    return `<span>${strArr[0]}</span><span class='e-radix'>${strArr[1]}</span>`
  } else {
    return str
  }
}

const changeLayerVisibility = (map: any, id: string, visibility: boolean) => {
  if(map.getLayer(id)) {
    map.setLayoutProperty(id, 'visibility', visibility ? 'visible' : 'none')
  }
}

let tle: any

import md5 from 'crypto-js/md5'

export const getRoofId = (obj: any): string => {
    const {center_x: lng, center_y: lat, area} = obj
    return md5(`${lng.toFixed(6)}${lat.toFixed(6)}${area.toFixed(6)}`).toString()
  }

;(window as any).doAdd = (id: number, name: string) => {
  EvaluationService.add({
    root: [
      {
        regionCode: (window as any).currentAdcode,
        name,
        id
      }
    ]
  }).then(() => {
    message.success('成功添加至资源管理列表').then()
    const doms = document.getElementsByClassName('add-source')
    if (doms) {
      for (let i = 0; i < doms.length; i++) {
        const dom: any = doms[i]
        dom.innerText = '已添加'
        dom.onclick = null
      }
    }
  })
}

const createRoofDom = (obj: any) => {
  //
  //addTime: null
  // added: false
  // area: 42421.2393569384
  // centerPoint: (2) ['121.76625568256631', '31.16650553903011']
  // id: "61a5d5c59029738883660948338ecc99"
  // name: "机场大道156号上海浦东国际机场海关监管第二仓储区"
  // const {id, name} = obj;

  return `<div class='map-popover'>
  <div class='map-popover-label'><span style='overflow: hidden; white-space: nowrap; text-overflow: ellipsis;'>${
    obj.name
  }</span><div class='province-scan-button add-source' onclick="if(!${obj.added}) {doAdd('${obj.id}', '${
    obj.name
  }')}" style='white-space: nowrap; margin-left: 10px;'>${obj.added ? '已添加' : '添加至资源管理'}</div></div>
  <div style='display: flex; flex-direction: column'>
    <div class='map-popover-item' style='display: flex; flex-direction: row;align-items: center; width: 100%;'>
      <div class='map-popover-name'>屋顶面积：</div>
      <div class='map-popover-value' style='margin-left: 10px;'>${parseDom(
    parseNumber(obj.area, {
      type: 'c',
      radix: 2
    })
  )}<span class='map-popover-unit'>㎡</span></div>
    </div>
    <div class='map-popover-item' style='display: flex; flex-direction: row;align-items: flex-start; width: 100%;'>
      <div class='map-popover-name'>地址：</div>
      <div class='map-popover-name' style='margin-left: 10px; white-space: normal; text-align: left; flex-shrink: 1;'>${
    obj.name
  }</div>
    </div>
  </div>
</div>`
}

const getInfoWindow = (obj: any, currentLevel: string, doScan: Function) => {
  ;(window as any).doScan = doScan
  return `<div class='map-popover'>
  <div class='map-popover-label'>${obj.label}<div class='province-scan-button' style='display: ${
    currentLevel === 'district' ? 'block' : 'none'
  }' onclick='window.doScan()'>屋顶扫描</div></div>
  <div style='display: flex; flex-direction: row; flex-wrap: wrap;'>
    <div class='map-popover-item'>
      <div class='map-popover-value'>${parseDom(
    parseNumber(obj.regionArea / 1000000, {
      type: 'e',
      radix: 2
    })
  )}<span class='map-popover-unit'>k㎡</span></div>
      <div class='map-popover-name'>区域面积</div>
    </div>
    <div class='map-popover-item'>
      <div class='map-popover-value'>${parseNumber(
    parseFloat(obj.yearTotalIrradiance), {
      type: '',
      radix: 2,
    }
  )}<span class='map-popover-unit'>kWh/㎡</span></div>
      <div class='map-popover-name'>年总辐照</div>
    </div>
    <div class='map-popover-item'>
      <div class='map-popover-value'>${parseNumber(obj.roofCount)}<span class='map-popover-unit'>个</span></div>
      <div class='map-popover-name'>屋顶数量</div>
    </div>
    <div class='map-popover-item'>
      <div class='map-popover-value'>${parseNumber(
    parseSuffixNumber(obj.roofRatio * 1000, 2)
  )}<span class='map-popover-unit'>‰</span></div>
      <div class='map-popover-name'>屋顶覆盖率</div>
    </div>
    <div class='map-popover-item'>
      <div class='map-popover-value'>${parseNumber(obj.roofArea, {
    type: '',
    radix: 0
  })}<span class='map-popover-unit'>㎡</span></div>
      <div class='map-popover-name'>屋顶总面积</div>
    </div>
    <div class='map-popover-item'>
      <div class='map-popover-value'>${parseNumber(obj.avgRoofArea, {
    type: '',
    radix: 2
  })}<span class='map-popover-unit'>㎡</span></div>
      <div class='map-popover-name'>屋顶平均面积</div>
    </div>
  </div>
</div>`
}

export const ResourceMapbox = (props: any) => {

  const [loading, setLoading] = useState(false)
  const [, setRegionData] = useState([])
  const [map, setMap] = useState<mapboxgl.Map>()
  const [showAction, setShowAction] = useState(false)
  const [layerType, setLayerType] = useState('wx')
  const [showCoverLayer] = useState(false)
  const [showLoca, setShowLoca] = useState(true)

  useEffect(() => {
    if (!map) return
    if (!props.hideCluster) {
      if (props.pointerData && props.srcData) {
        ;(window as any).pointerData = props.pointerData
        ;(window as any).srcData = props.srcData
        const dt = props.pointerData.map(o => {
          const adcode = o.adcode
          const so = props.srcData.find(o => o.adcode == adcode)

          if (so) {
            return {
              ...o,
              lng: so.lng,
              lat: so.lat
            }
          } else {
            return undefined
          }
        }).filter(Boolean)

        // [73.502355, 3.83703, 135.09567, 53.563624]
        const features = dt.map((o: any) => {
          return {
            type: 'Feature',
            properties: {
              point_count: 1
            },
            geometry: {
              type: 'Point',
              coordinates: [o.lng, o.lat]
            }
          }
        })
        const data: any = {
          type: 'FeatureCollection',
          features
        };

        (map.getSource('earthquakes') as GeoJSONSource).setData(data)
        changeLayerVisibility(map, 'unclustered-point', true)
        changeLayerVisibility(map, 'cluster-count', true)
        changeLayerVisibility(map, 'clusters', true)
      }
    } else {
      changeLayerVisibility(map, 'unclustered-point', false)
      changeLayerVisibility(map, 'cluster-count', false)
      changeLayerVisibility(map, 'clusters', false)

    }
  }, [props.hideCluster, props.pointerData, props.srcData, map])

  const [showAll, setShowAll] = useState(false)
  useEffect(() => {
    if (!map) return

    const roadLayers = map.getStyle().layers.filter(function (layer) {
      // filter out the road label layer
      return layer.id.indexOf('roofLayer-') > -1
    })

    roadLayers.forEach(function (layer) {
      changeLayerVisibility(map, layer.id, showAll)
    })

  }, [showAll, map])

  useEffect(() => {
    if (!layerType || !map) {
      return
    }

    const roadLayers = map.getStyle().layers.filter(function (layer) {
      // filter out the road label layer
      return (layer.id.includes('road-') || layer.id.includes('tunnel-') || layer.id.includes('bridge-'))
    })

    switch (layerType) {
      case 'wx':
        changeLayerVisibility(map, 'stLayer', true)
        roadLayers.forEach(function (layer) {
          changeLayerVisibility(map, layer.id, false)
        })
        break
      case 'road':
        changeLayerVisibility(map, 'stLayer', true)
        roadLayers.forEach(function (layer) {
          changeLayerVisibility(map, layer.id, true)
        })
        break
      default:
        changeLayerVisibility(map, 'stLayer', false)
        roadLayers.forEach(function (layer) {
          changeLayerVisibility(map, layer.id, true)
        })
    }
  }, [layerType, map])

  useEffect(() => {
    if (!map) {
      return
    }
    changeLayerVisibility(map, 'weatherLayer', showLoca)
  }, [showLoca, map])

  useEffect(() => {
    new EvaluationV2Api().i3060RegionRoofGetRegionDataGet({}).then((resp: any) => {
      setRegionData(resp.data)
    })
  }, [])

  const changeCityData = (code: string, map: mapboxgl.Map, level = '1') => {
    setLoading((loading) => {
      if (mbxCityLayer) {
        return false
      }
      if (!loading && !ignoreBubble) {
        getCityData(code || '100000', level).then(({data: geojson}: any) => {
          (map.getSource('city-range') as GeoJSONSource).setData(geojson)

          const bound = bbox(geojson)
          const spl = 9
          const dx = bound[2] - bound[0]
          const dy = bound[3] - bound[1]
          const dsx = dx / spl
          const dsy = dy / spl
          const lt: [number, number] = [bound[0] - dsx, bound[1] - dsy]
          const rb: [number, number] = [bound[2] + dsx, bound[3] + dsy]
          const min = map.project(lt)
          const max = map.project(rb)
          map.fitScreenCoordinates(min, max, map.getBearing())

        }).finally(() => {
          setTimeout(() => {
            setLoading(false)
          })
        })
        return true
      }
      return loading
    })
  }

  const addCityLayer = (map: mapboxgl.Map) => {
    setLoading(true)

    getCityData('100000', '1').then(({data: geojson}: any) => {
      map.addSource(
        'city-range',
        {type: 'geojson', data: geojson}
      )

      map.addLayer(
        {
          id: 'city-layer',
          type: 'fill',
          source: 'city-range',
          maxzoom: 10,
          paint: {
            'fill-color': 'transparent',
            'fill-outline-color': '#FF0000'
          }
        }
      )

      map.addLayer(
        {
          id: 'city-cover',
          type: 'fill',
          source: 'city-range',
          minzoom: 1,
          maxzoom: 20,
          paint: {
            'fill-color': '#000',
            'fill-opacity': 0.5
          },
          filter: ['in', 'code', 'x']
        }
      )

      map.addLayer(
        {
          id: 'city-light',
          type: 'fill',
          source: 'city-range',
          layout: {},
          paint: {
            'fill-color': '#088',
            'fill-outline-color': 'rgba(0, 255, 0, 1)',
            'fill-opacity': 0.6
          },
          filter: ['in', 'code', '']
        }
      )


      const popup = new mapboxgl.Popup({
        closeButton: false,
        closeOnClick: true,
        closeOnMove: true
      })

      map.on('mouseleave', 'city-light', () => {
        map.setFilter('city-light', ['in', 'code', ''])
      })

      map.on('mousemove', 'city-layer', (e: any) => {
        const feature = e.features?.[0]
        if (feature) {
          map.setFilter('city-light', ['in', 'code', feature.properties?.code])
          const center = JSON.parse(feature.properties?.center)
          if (center) {
            const [lng, lat] = center
            const lngLat = popup?.getLngLat()
            if (!lngLat || lngLat.lng !== lng || lngLat.lat !== lat || !popup.isOpen()) {

              setRegionData((regionData) => {
                if (!regionData || regionData.length === 0) {
                  return regionData
                }
                popup
                  .setLngLat([lng, lat])
                  .setHTML(getInfoWindow(regionData[feature.properties?.code], feature.properties?.level, () => {
                    (window as any).currentAdcode = feature.properties?.code
                    ignoreBubble = true;
                    (window as any).cancelScan = () => {
                      ignoreBubble = false
                      changeLayerVisibility(map, 'city-layer', true)
                      changeLayerVisibility(map, 'city-light', true)
                      changeLayerVisibility(map, 'weatherLayer', true)
                      // changeLayerVisibility(map, 'stLayer', true)

                      changeLayerVisibility(map, 'roofLayer', false)
                      changeLayerVisibility(map, 'roofLight', false)

                      map.setFilter('city-cover', ['in', 'code', 'x']);
                      (window as any).currentAdcode = ''
                      props.showRoofSiftHandler(false)
                      props.showSiftHandler(true)
                      map.setZoom(9.5)
                    }


                    ;(window as any).tMap = map;
                    map.setFilter('city-cover', ['!in', 'code', feature.properties?.code])

                    changeLayerVisibility(map, 'city-layer', false)
                    changeLayerVisibility(map, 'city-light', false)
                    changeLayerVisibility(map, 'weatherLayer', false)
                    // changeLayerVisibility(map, 'stLayer', false)

                    changeLayerVisibility(map, 'roofLayer', true)
                    changeLayerVisibility(map, 'roofLight', true)

                    props.showRoofSiftHandler(true)
                    props.showSiftHandler(false)


                    popup.remove()
                  }))
                  .setMaxWidth('470px')
                  .addTo(map)
                return regionData
              })
            }
          }
        }
      })

      map.on('click', 'city-layer', (e: any) => {
        // e.originalEvent.stopPropagation();
        const feature = e.features?.[0]
        if (feature?.properties?.level === 'province') {
          ;(map.getSource('roof') as VectorSource).tiles = [
            `https://dev-tile.terraqt.com/tile/xyz/{z}/{x}/{y}.pbf?path=f7a3c99d-4306-404a-ad33-5f1099992871/cor10403/20191201/CN-${feature?.properties.code}/vectortiles`
          ]
        }
        if (feature?.properties?.level === 'province' || feature?.properties?.level === 'city') {
          changeCityData(feature?.properties.code, map)
        } else {
          (window as any).currentCode = feature?.properties.code
          ignoreBubble = true
          setTimeout(() => {
            ignoreBubble = false
          }, 1000)
        }
      })

    }).finally(() => {
      setLoading(false)
    })
  }

  const addSateliteLayer = (map: any) => {
    map.addSource('satelite', {
      type: 'raster',
      maxzoom: 20,
      tiles: ['https://map.i3060.com/static/v1.0.0/klfmbt/{z}/{x}/{y}.png']
    })

    map.addLayer(
      {
        id: 'stLayer',
        type: 'raster',
        maxzoom: 20,
        source: 'satelite',
        'source-layer': 'default',
        paint: {
          'raster-opacity': 1
        }
      },
      'building-underground'
    )
  }
  const addWeatherLayer = (map: mapboxgl.Map) => {
    map.addSource('weather', {
      type: 'raster',
      maxzoom: 1,
      // tiles: ['https://dev-tile.terraqt.com/tile/xyz/{z}/{x}/{y}.png?path=96c447b6-e0b7-4810-b7d4-e70c4aa9b4d5/der1.c/20220201/CN-100000/rastertiles']
      tiles: [weatherConfig['GHI'].urls[1]]
    })
    setWeatherSource(map)
  }

  const addGroupLayer = (map) => {
    const data: any = {
      type: 'FeatureCollection',
      features: []
    }

    map.addSource('earthquakes', {
      type: 'geojson',
      data,
      cluster: true,
      clusterMaxZoom: 20,
      clusterRadius: 50
    })

    map.addLayer({
      id: 'clusters',
      type: 'circle',
      source: 'earthquakes',
      filter: ['has', 'point_count'],
      paint: {
        'circle-color': [
          'step',
          ['get', 'point_count'],
          '#51bbd6',
          2,
          '#51bbd6',
          10,
          '#f1f075',
          20,
          '#f28cb1'
        ],
        'circle-radius': [
          'step',
          ['get', 'point_count'],
          5,
          2,
          20,
          10,//
          30
        ]
      }
    })

    map.addLayer({
      id: 'cluster-count',
      type: 'symbol',
      source: 'earthquakes',
      filter: ['has', 'point_count'],
      layout: {
        'text-field': '{point_count_abbreviated}',
        'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
        'text-size': 12
      }
    })

    map.addLayer({
      id: 'unclustered-point',
      type: 'circle',
      source: 'earthquakes',
      filter: ['!', ['has', 'point_count']],
      paint: {
        'circle-color': '#11b4da',
        'circle-radius': 4,
        'circle-stroke-width': 1,
        'circle-stroke-color': '#fff'
      }
    })
  }

  const moveCenter = (map, adcode = 100000) => {
    const bound = provinceBbox[adcode]
    moveCenterBounds(map, bound)
  }

  const moveCenterBounds = (map, bound: number[]) => {
    const spl = 9
    const dx = bound[2] - bound[0]
    const dy = bound[3] - bound[1]
    const dsx = dx / spl
    const dsy = dy / spl
    const lt: [number, number] = [bound[0] - dsx, bound[1] - dsy]
    const rb: [number, number] = [bound[2] + dsx, bound[3] + dsy]
    const min = map.project(lt)
    const max = map.project(rb)
    map.fitScreenCoordinates(min, max, map.getBearing())
  }

  const createMapbox = () => {
    const map = new mapboxgl.Map({
      style: 'mapbox://styles/owops/cl2h1u3kx000u15o26t9bmtyh',
      center: [120.412002, 36.075867],
      zoom: 3.8,
      minZoom: 1,
      maxZoom: 20,
      container: 'map',
      projection: 'equirectangular' // starting projection
    } as any)


    moveCenter(map)

    map.on('zoomend', () => {
      console.log(map.getZoom())
    })

    map.on('load', () => {
      // const showAll = true || !!localStorage.getItem('showAll') || location.href.includes('http://klf-dev.i3060.com/');
      // const provinceRoofs = Object.keys(provinceBbox).map(o => `https://dev-tile.terraqt.com/tile/xyz/{z}/{x}/{y}.pbf?path=f7a3c99d-4306-404a-ad33-5f1099992871/cor10403/20191201/CN-${o}/vectortiles`)

      Object.keys(provinceBbox).forEach((o) => {
        if (o === '100000') return
        map.addSource(`roof-${o}`, {
          type: 'vector',
          scheme: 'xyz',
          minzoom: 1,
          maxzoom: 13,
          tiles: [`https://dev-tile.terraqt.com/tile/xyz/{z}/{x}/{y}.pbf?path=f7a3c99d-4306-404a-ad33-5f1099992871/cor10403/20191201/CN-${o}/vectortiles`]
        })

        map.addLayer({
          id: `roofLayer-${o}`,
          type: 'fill',
          source: `roof-${o}`,
          'source-layer': 'default',
          layout: {
            visibility: 'none'
          },
          paint: {
            'fill-color': 'rgba(255, 0,0,1)',
            'fill-opacity': 0.6,
            'fill-outline-color': 'rgba(0, 255, 0, 1)'
          }
        })
      })

      map.addSource('roof', {
        type: 'vector',
        scheme: 'xyz',
        minzoom: 1,
        maxzoom: 13,
        // tiles: provinceRoofs
        tiles: ['https://dev-tile.terraqt.com/tile/xyz/{z}/{x}/{y}.pbf?path=f7a3c99d-4306-404a-ad33-5f1099992871/cor10403/20191201/CN-640000/vectortiles']
      })

      map.addLayer({
        id: 'roofLayer',
        type: 'fill',
        source: 'roof',
        'source-layer': 'default',
        layout: {
          visibility: 'none'
        },
        paint: {
          'fill-color': 'rgba(255, 0,0,1)',
          'fill-opacity': 0.6,
          'fill-outline-color': 'rgba(0, 255, 0, 1)'
        }
      })

      map.addLayer({
        id: 'roofLight',
        type: 'fill',
        source: 'roof',
        'source-layer': 'default',
        layout: {
          visibility: 'none'
        },
        paint: {
          'fill-color': '#088',
          'fill-outline-color': 'rgba(0, 255, 0, 1)',
          'fill-opacity': 0.6
        },
        filter: ['in', 'id', '']
      })

      const popup = new mapboxgl.Popup({
        closeButton: true,
        closeOnClick: true,
        closeOnMove: true
      })

      map.on('click', 'roofLayer', (e: any) => {
        const feature = e.features?.[0]

        if (feature?.properties) {
          map.setFilter('roofLight', ['in', 'id', feature.properties?.id])

          const {center_x, center_y} = feature.properties
          const srv = new EvaluationV2Api()
          srv.i3060EvaluationGetRoofInfoGet({
            code: (window as any).currentAdcode || (window as any).currentCode,
            id: getRoofId(feature.properties)
          }).then((resp) => {
            if (resp.data) {
              popup
                .setLngLat([center_x, center_y])
                .setHTML(createRoofDom(resp.data))
                .setMaxWidth('470px')
                .addTo(map)
            }
          })
        }
      })

      map.on('click', 'cities-join', (e: any) => {
        const feature = e.features?.[0]
        if (feature) {
          setTimeout(() => {
            // console.log('cities-join', feature.properties)
          }, 0)
        }
      })

      map.addSource('countries', {
        type: 'vector',
        url: 'mapbox://mapbox.country-boundaries-v1'
      })
      map.addLayer(
        {
          id: 'countries-join',
          type: 'fill',
          source: 'countries',
          'source-layer': 'country_boundaries',
          paint: {
            'fill-color': '#0000FF',
            'fill-opacity': 0
          },
          filter: ['match', ['get', 'worldview'], ['all', 'CN'], true, false]
        },
        'admin-1-boundary-bg'
      )

      map.on('click', 'countries-join', (e: any) => {
        const feature = e.features?.[0]
        if (feature) {
          setTimeout(() => {
            if (!ignoreBubble) {
              changeLayerVisibility(map, 'province-join', true)
              changeLayerVisibility(map, 'province-join-light', true)
              changeLayerVisibility(map, 'district-join', false)
              changeLayerVisibility(map, 'district-join-light', false)
              changeLayerVisibility(map, 'city-join', false)
              changeLayerVisibility(map, 'city-join-light', false)

              moveCenter(map)
            }
          }, 0)
        }
      })

      addSateliteLayer(map)

      addGroupLayer(map)

      addWeatherLayer(map)
      // console.log(addWeatherLayer)
      if (!mbxCityLayer) {
        addCityLayer(map)
      } else {

        map.addSource('province-source', {
          type: 'vector',
          minzoom: 0,
          maxzoom: 20,
          // url: 'mapbox://owops.1ctxskkw', //res_province-bh0173
          url: layerSources.province.tiles //res_province-bh0173
        })
        map.addSource('city-source', {
          type: 'vector',
          minzoom: 0,
          maxzoom: 20,
          // url: 'mapbox://owops.7pi4mpkm', //res_city-5bsexw
          url: layerSources.city.tiles //res_province-bh0173
        })
        map.addSource('district-source', {
          type: 'vector',
          minzoom: 0,
          maxzoom: 20,
          // url: 'mapbox://owops.1dejmjd5', //res_district-caxumt
          url: layerSources.district.tiles //res_province-bh0173
        })

        map.addLayer(
          {
            id: 'province-join',
            type: 'fill',
            source: 'province-source',
            minzoom: 0,
            maxzoom: 20,
            'source-layer': layerSources.province.layer,
            paint: {
              'fill-color': 'rgba(0,0,0,0)',
              'fill-opacity': .6,
              'fill-outline-color': '#FF0000'
            }
            // filter: [
            // '==', 'level', 'province'
            // ]
          }
        )

        map.addLayer(
          {
            id: 'province-join-light',
            type: 'fill',
            source: 'province-source',
            'source-layer': layerSources.province.layer,
            minzoom: 0,
            maxzoom: 20,
            layout: {},
            paint: {
              'fill-color': '#088',
              'fill-outline-color': 'rgba(0, 255, 0, 1)',
              'fill-opacity': 0.6
            },
            filter: ['in', 'adcode', '']
          }
        )


        map.addLayer(
          {
            id: 'city-join',
            type: 'fill',
            source: 'city-source',
            minzoom: 0,
            maxzoom: 20,
            'source-layer': layerSources.city.layer,
            paint: {
              'fill-color': 'rgba(0,0,0,0)',
              'fill-opacity': .6,
              'fill-outline-color': '#FF0000'
            },
            filter: [
              'in', 'pre_adcode', ''
            ]
          }
        )
        map.addLayer(
          {
            id: 'city-join-light',
            type: 'fill',
            source: 'city-source',
            'source-layer': layerSources.city.layer,
            minzoom: 0,
            maxzoom: 20,
            layout: {},
            paint: {
              'fill-color': '#088',
              'fill-outline-color': 'rgba(0, 255, 0, 1)',
              'fill-opacity': 0.6
            },
            filter: ['in', 'adcode', '']
          }
        )

        map.addLayer(
          {
            id: 'district-join',
            type: 'fill',
            source: 'district-source',
            minzoom: 0,
            maxzoom: 20,
            'source-layer': layerSources.district.layer,
            paint: {
              'fill-color': 'rgba(0,0,0,0)',
              'fill-opacity': .6,
              'fill-outline-color': '#FF0000'
            },
            filter: [
              'in', 'pre_adcode', ''
            ]
          }
        )

        map.addLayer(
          {
            id: 'district-join-light',
            type: 'fill',
            source: 'district-source',
            'source-layer': layerSources.district.layer,
            minzoom: 0,
            maxzoom: 20,
            layout: {},
            paint: {
              'fill-color': '#088',
              'fill-outline-color': 'rgba(0, 255, 0, 1)',
              'fill-opacity': 0.6
            },
            filter: ['in', 'adcode', '']
          }
        )


        map.addLayer(
          {
            id: 'district-cover',
            type: 'fill',
            source: 'district-source',
            minzoom: 0,
            maxzoom: 20,
            'source-layer': layerSources.district.layer,
            paint: {
              'fill-color': 'rgba(0, 0, 0, 1)',
              'fill-opacity': .6
              // 'fill-outline-color': '#FF0000',
            },
            filter: ['in', 'adcode', '']
          }
        )

        map.addLayer(
          {
            id: 'district-cover-outline',
            type: 'fill',
            source: 'district-source',
            minzoom: 0,
            maxzoom: 20,
            'source-layer': layerSources.district.layer,
            paint: {
              'fill-color': 'rgba(0, 0, 0, 0)',
              'fill-opacity': 1,
              'fill-outline-color': '#FF0000'
            },
            filter: ['in', 'adcode', '']
          }
        )

        const popup = new mapboxgl.Popup({
          closeButton: false,
          closeOnClick: true,
          closeOnMove: true
        })

        map.on('mouseleave', 'province-join-light', () => {
          map.setFilter('province-join-light', ['in', 'adcode', ''])
        })

        map.on('mousemove', 'province-join', (e: any) => {
          const feature = e.features?.[0]
          if (feature) {
            map.setFilter('province-join-light', ['in', 'adcode', feature.properties?.adcode || ''])
            const center = feature.properties?.center.split(',').map(parseFloat)
            if (center) {
              const [lng, lat] = center
              const lngLat = popup?.getLngLat()
              if (!lngLat || lngLat.lng !== lng || lngLat.lat !== lat || !popup.isOpen()) {

                setRegionData((regionData) => {
                  if (!regionData || regionData.length === 0) {
                    return regionData
                  }
                  popup
                    .setLngLat([lng, lat])
                    .setHTML(getInfoWindow(regionData[feature.properties?.adcode], feature.properties?.level, () => {
                    }))
                    .setMaxWidth('470px')
                    .addTo(map)
                  return regionData
                })
              }
            }
          }
        })


        map.on('mouseleave', 'city-join-light', () => {
          map.setFilter('city-join-light', ['in', 'adcode', ''])
        })

        map.on('mousemove', 'city-join', (e: any) => {
          const feature = e.features?.[0]
          if (feature) {
            map.setFilter('city-join-light', ['in', 'adcode', feature.properties?.adcode || ''])
            const bounds = JSON.parse(feature.properties.bbox) as number[]
            const center = [(bounds[2] + bounds[0]) / 2, (bounds[3] + bounds[1]) / 2]

            if (center) {
              const [lng, lat] = center
              const lngLat = popup?.getLngLat()
              if (!lngLat || lngLat.lng !== lng || lngLat.lat !== lat || !popup.isOpen()) {
                setRegionData((regionData) => {
                  if (!regionData || regionData.length === 0) {
                    return regionData
                  }
                  popup
                    .setLngLat([lng, lat])
                    .setHTML(getInfoWindow(regionData[feature.properties?.adcode], feature.properties?.level, () => {
                      (window as any).currentAdcode = feature.properties?.adcode
                      ignoreBubble = true;
                      (window as any).cancelScan = () => {
                        ignoreBubble = false

                        changeLayerVisibility(map, 'city-join', true)
                        changeLayerVisibility(map, 'city-join-light', true)

                        changeLayerVisibility(map, 'weatherLayer', true)
                        // changeLayerVisibility(map, 'stLayer', true)

                        changeLayerVisibility(map, 'roofLayer', false)
                        changeLayerVisibility(map, 'roofLight', false)

                        map.setFilter('district-cover', ['in', 'adcode', 'x'])
                        map.setFilter('district-cover-outline', ['in', 'adcode', 'x']);

                        (window as any).currentAdcode = ''
                        props.showRoofSiftHandler(false)
                        props.showSiftHandler(true)
                        moveCenterBounds(map, bounds)
                      }

                      map.setFilter('district-cover', ['!in', 'adcode', feature.properties?.adcode])
                      map.setFilter('district-cover-outline', ['in', 'adcode', feature.properties?.adcode])

                      changeLayerVisibility(map, 'city-join', false)
                      changeLayerVisibility(map, 'city-join-light', false)

                      changeLayerVisibility(map, 'weatherLayer', false)
                      // changeLayerVisibility(map, 'stLayer', false)

                      changeLayerVisibility(map, 'roofLayer', true)
                      changeLayerVisibility(map, 'roofLight', true)

                      props.showRoofSiftHandler(true)
                      props.showSiftHandler(false)


                      popup.remove()
                    }))
                    .setMaxWidth('470px')
                    .addTo(map)
                  return regionData
                })
              }
            }
          }
        })

        map.on('mouseleave', 'district-join-light', () => {
          map.setFilter('district-join-light', ['in', 'adcode', ''])
        })

        map.on('mousemove', 'district-join', (e: any) => {
          const feature = e.features?.[0]
          if (feature) {
            map.setFilter('district-join-light', ['in', 'adcode', feature.properties?.adcode || ''])

            const bounds = JSON.parse(feature.properties.bbox) as number[]
            const center = [(bounds[2] + bounds[0]) / 2, (bounds[3] + bounds[1]) / 2]

            if (center) {
              const [lng, lat] = center
              const lngLat = popup?.getLngLat()
              if (!lngLat || lngLat.lng !== lng || lngLat.lat !== lat || !popup.isOpen()) {

                setRegionData((regionData) => {
                  if (!regionData || regionData.length === 0) {
                    return regionData
                  }
                  popup
                    .setLngLat([lng, lat])
                    .setHTML(getInfoWindow(regionData[feature.properties?.adcode], 'district', () => {
                      (window as any).currentAdcode = feature.properties?.adcode
                      ignoreBubble = true;
                      (window as any).cancelScan = () => {
                        ignoreBubble = false

                        changeLayerVisibility(map, 'district-join', true)
                        changeLayerVisibility(map, 'district-join-light', true)

                        changeLayerVisibility(map, 'weatherLayer', true)
                        // changeLayerVisibility(map, 'stLayer', true)

                        changeLayerVisibility(map, 'roofLayer', false)
                        changeLayerVisibility(map, 'roofLight', false)

                        map.setFilter('district-cover', ['in', 'adcode', 'x'])
                        map.setFilter('district-cover-outline', ['in', 'adcode', 'x']);
                        (window as any).currentAdcode = ''
                        props.showRoofSiftHandler(false)
                        props.showSiftHandler(true)

                        moveCenterBounds(map, bounds)
                      }

                      map.setFilter('district-cover', ['!in', 'adcode', feature.properties?.adcode])
                      map.setFilter('district-cover-outline', ['in', 'adcode', feature.properties?.adcode])

                      changeLayerVisibility(map, 'district-join', false)
                      changeLayerVisibility(map, 'district-join-light', false)

                      changeLayerVisibility(map, 'weatherLayer', false)
                      // changeLayerVisibility(map, 'stLayer', false)

                      changeLayerVisibility(map, 'roofLayer', true)
                      changeLayerVisibility(map, 'roofLight', true)

                      props.showRoofSiftHandler(true)
                      props.showSiftHandler(false)


                      popup.remove()
                    }))
                    .setMaxWidth('470px')
                    .addTo(map)
                  return regionData
                })
              }
            }
          }
        })


        map.on('click', 'province-join', (e: any) => {
          ignoreBubble = true
          setTimeout(() => {
            ignoreBubble = false
          }, 1000)

          // e.originalEvent.stopPropagation();
          const feature = e.features?.[0];
          (window as any).currentCode = feature.properties.adcode;
          (map.getSource('roof') as VectorSource).tiles = [
            `https://dev-tile.terraqt.com/tile/xyz/{z}/{x}/{y}.pbf?path=f7a3c99d-4306-404a-ad33-5f1099992871/cor10403/20191201/CN-${feature?.properties.adcode}/vectortiles`
          ]
          map.setFilter('city-join', ['in', 'pre_adcode', feature.properties?.adcode])

          moveCenter(map, feature.properties?.adcode)
          // map.setZoom(6);

          // const center = feature.properties?.center.split(',').map(parseFloat);
          // if (center) {
          // map.setCenter(center);
          // map.fitScreenCoordinates(min, max, map.getBearing())
          // }

          changeLayerVisibility(map, 'province-join', false)
          changeLayerVisibility(map, 'province-join-light', false)

          changeLayerVisibility(map, 'city-join', true)
          changeLayerVisibility(map, 'city-join-light', true)
        })


        ;(window as any).reloadAreaNode = (obj: any) => {

          ;(window as any).doScan = () => {
            const {adcode, parentAdcode} = obj;
            ;(map.getSource('roof') as VectorSource).tiles = [
              `https://dev-tile.terraqt.com/tile/xyz/{z}/{x}/{y}.pbf?path=f7a3c99d-4306-404a-ad33-5f1099992871/cor10403/20191201/CN-${parentAdcode}/vectortiles`
            ]
            ;(window as any).currentAdcode = adcode
            ignoreBubble = true;
            (window as any).cancelScan = () => {
              ignoreBubble = false

              changeLayerVisibility(map, 'district-join', true)
              changeLayerVisibility(map, 'district-join-light', true)

              changeLayerVisibility(map, 'weatherLayer', true)
              // changeLayerVisibility(map, 'stLayer', true)

              changeLayerVisibility(map, 'roofLayer', false)
              changeLayerVisibility(map, 'roofLight', false)

              map.setFilter('district-cover', ['in', 'adcode', 'x'])
              map.setFilter('district-cover-outline', ['in', 'adcode', 'x']);
              (window as any).currentAdcode = ''
              props.showRoofSiftHandler(false)
              props.showSiftHandler(true)

              moveCenterBounds(map, [73.502355, 3.83703, 135.09567, 53.563624])
            }

            map.setFilter('district-cover', ['!in', 'adcode', adcode])
            map.setFilter('district-cover-outline', ['in', 'adcode', adcode])

            changeLayerVisibility(map, 'district-join', false)
            changeLayerVisibility(map, 'district-join-light', false)

            changeLayerVisibility(map, 'weatherLayer', false)
            // changeLayerVisibility(map, 'stLayer', false)

            changeLayerVisibility(map, 'roofLayer', true)
            changeLayerVisibility(map, 'roofLight', true)

            props.showRoofSiftHandler(true)
            props.showSiftHandler(false)


            popup.remove()
          }

          const root = obj.parentAdcode.substring(0, 3)
          const adcode = root === '110' ? '110100' : `${root}000`;

          (window as any).currentCode = adcode;
          (map.getSource('roof') as VectorSource).tiles = [
            `https://dev-tile.terraqt.com/tile/xyz/{z}/{x}/{y}.pbf?path=f7a3c99d-4306-404a-ad33-5f1099992871/cor10403/20191201/CN-${adcode}/vectortiles`
          ]
          const {parentAdcode, lng, lat} = obj
          map.setFilter('district-join', ['in', 'pre_adcode', parentAdcode])

          changeLayerVisibility(map, 'province-join', false)
          changeLayerVisibility(map, 'province-join-light', false)

          changeLayerVisibility(map, 'city-join', false)
          changeLayerVisibility(map, 'city-join-light', false)

          changeLayerVisibility(map, 'district-join', true)
          changeLayerVisibility(map, 'district-join-light', true)

          map.setZoom(obj.zoom || 8)
          map.setCenter([parseFloat(lng), parseFloat(lat)])
        }

        ;(window as any).moveToRoof = (center: any, obj: any) => {
          map.setFilter('roofLight', ['in', 'id', ''])

          const [center_x, center_y] = center
          const srv = new EvaluationV2Api()
          map.setCenter([center_x, center_y].map(parseFloat))
          map.setZoom(16)
          srv.i3060EvaluationGetRoofInfoGet({
            code: (window as any).currentAdcode || (window as any).currentCode,
            id: obj.id
          }).then((resp) => {
            if (resp.data) {
              popup
                .setLngLat([center_x, center_y].map(parseFloat))
                .setHTML(createRoofDom(resp.data))
                .setMaxWidth('470px')
                .addTo(map)
            }
          })
        }

        ;(window as any).showInfoWindow = (adcode) => {
          console.debug(adcode)
        }

        map.on('click', 'city-join', (e: any) => {
          ignoreBubble = true
          setTimeout(() => {
            ignoreBubble = false
          }, 1000)
          // e.originalEvent.stopPropagation();
          const feature = e.features?.[0]
          if (feature.properties.level === 'district') {
            ignoreBubble = true
            // e.originalEvent.stopPropagation();
            // const feature = e.features?.[0];
            // (window as any).currentCode = feature?.properties.adcode;
            setTimeout(() => {
              ignoreBubble = false
            }, 1000)
            return
          }
          map.setFilter('district-join', ['in', 'pre_adcode', feature.properties?.adcode])
          // map.setZoom(8);

          const bound = JSON.parse(feature.properties.bbox)
          const spl = 9
          const dx = bound[2] - bound[0]
          const dy = bound[3] - bound[1]
          const dsx = dx / spl
          const dsy = dy / spl
          const lt: [number, number] = [bound[0] - dsx, bound[1] - dsy]
          const rb: [number, number] = [bound[2] + dsx, bound[3] + dsy]
          const min = map.project(lt)
          const max = map.project(rb)
          map.fitScreenCoordinates(min, max, map.getBearing())

          changeLayerVisibility(map, 'city-join', false)
          changeLayerVisibility(map, 'city-join-light', false)

          changeLayerVisibility(map, 'district-join', true)
          changeLayerVisibility(map, 'district-join-light', true)
        })


        map.on('click', 'district-join', (
          // e: any
        ) => {
          ignoreBubble = true
          // e.originalEvent.stopPropagation();
          // const feature = e.features?.[0];
          // (window as any).currentCode = feature?.properties.adcode;
          // console.log('step1', ignoreBubble)
          setTimeout(() => {
            ignoreBubble = false
          }, 1000)
        })
      }

      setMap(map)

    })
  }
  useEffect(() => {
    setTimeout(() => {
      createMapbox()
    }, 0)
  }, [])

  const [currentSelected, setCurrentSelected] = useState('GHI')
  const [month, setMonth] = useState(1)
  const [sliderMonth, setSliderMonth] = useState(1)


  useEffect(() => {
    if (month > 0) {
      setSliderMonth(month)
    }
  }, [month])

  const setWeatherSource = (map: mapboxgl.Map, key = 'weather') => {
    const source = (map?.getSource(key) as RasterSource)
    if (source) {
      setCurrentSelected((currentSelected) => {
        setMonth((month) => {
          source.tiles = [
            weatherConfig[currentSelected]?.urls[month].replace('pbf', 'png')
          ]

          if (map.getLayer('weatherLayer')) {
            map.removeLayer('weatherLayer')
          }

          map.addLayer({
            id: 'weatherLayer',
            type: 'raster',
            source: 'weather',
            maxzoom: 10,
            paint: {
              'raster-opacity': 0.5
            }
          }, 'admin-1-boundary-bg')

          return month
        })
        return currentSelected
      })
    }
  }

  useEffect(() => {
    // console.log(currentSelected, month, map)
    if (currentSelected && month && map) {
      setWeatherSource(map)
    }
  }, [currentSelected, month, map])
  const [isPlaying, setIsPlaying] = useState(false)


  useEffect(() => {
    if (isPlaying && !tle) {
      tle = setInterval(() => {
        doPlay()
      }, 5000)
    } else {
      clearInterval(tle)
      tle = undefined
    }
  }, [isPlaying])

  const doPlay = () => {
    setMonth((month) => {
      if (month < 11) {
        return month + 1
      } else {
        setIsPlaying(false)
        return month + 1
      }
    })
  }
  return (
    <div style={{
      position: 'absolute',
      left: 0,
      right: 0,
      top: 0,
      bottom: 0
    }}>
      <div
        id='map'
        style={{
          width: '100%',
          height: '100%'
        }}
      />
      {
        props.showSift && <div
          className='weather-custom-radio-group'
          style={{
            position: 'absolute',
            overflowX: 'auto',
            overflowY: 'hidden',
            right: 255,
            bottom: 65,
            left: 20,
            pointerEvents: 'all'
          }}
        >
          {
            filterSelectors.map((o) => (
              <div
                style={{
                  paddingRight: 14,
                  height: 32,
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  borderRadius: 16,
                  backgroundColor:
                    o.key === currentSelected
                      ? 'rgba(30, 198, 146, 1)'
                      : 'rgba(0, 0, 0, 0.6)',
                  color: 'white',
                  marginLeft: 14,
                  pointerEvents: 'all',
                  cursor: 'pointer',
                  whiteSpace: 'nowrap'
                }}
                key={o.key}
                onClick={() => {
                  setCurrentSelected(o.key)
                }}
              >
                <div
                  style={{
                    width: 32,
                    height: 32,
                    borderRadius: 16,
                    backgroundColor:
                      o.key === currentSelected ? 'rgba(28, 184, 135, 1)' : 'white',
                    marginRight: 6,
                    fontSize: 18,
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'center'
                  }}
                >
                  <CustomIcons
                    type={o.key === currentSelected ? o.icon : o.selectedIcon}
                  />
                </div>
                {o.title}
              </div>
            ))
          }
        </div>
      }
      {
        props.showSift && <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            position: 'absolute',
            bottom: 20,
            height: 32,
            left: 20,
            right: 105
          }}
        >
          {/*playButton*/}
          <div
            style={{
              color: 'white',
              fontSize: 16,
              textAlign: 'center',
              borderRadius: 16,
              width: 32,
              height: 32,
              lineHeight: '32px',
              backgroundColor: 'rgba(0, 0, 0, 0.6)',
              pointerEvents: 'all',
              cursor: 'pointer',
              marginRight: 14
            }}
            onClick={() => {
              setMonth((month) => (month >= 12 ? 1 : month))
              setIsPlaying((isPlaying) => !isPlaying)
            }}
          >
            {!isPlaying && <CustomIcons type='klf-play'/>}
            {isPlaying && <PauseOutlined/>}
          </div>
          {/*preButton*/}
          <div
            style={{
              color: 'white',
              fontSize: 22,
              textAlign: 'center',
              borderRadius: 16,
              width: 32,
              height: 32,
              lineHeight: '32px',
              backgroundColor: 'rgba(0, 0, 0, 0.6)',
              pointerEvents: 'all',
              cursor: 'pointer',
              marginRight: 14
            }}
            onClick={() => {
              setIsPlaying(false)
              setMonth((month) => (month <= 1 ? 12 : month - 1))
            }}
          >
            <StepBackwardOutlined/>
          </div>
          <Slider
            style={{
              flexGrow: 1,
              pointerEvents: 'all'
            }}
            step={1}
            min={1}
            max={12}
            marks={marks}
            value={sliderMonth}
            onChange={(val) => {
              setSliderMonth(val)
            }}
            onAfterChange={(val) => {
              setIsPlaying(false)
              setMonth(val)
            }}
          />
          {/*nextButton*/}
          <div
            style={{
              color: 'white',
              fontSize: 22,
              textAlign: 'center',
              borderRadius: 16,
              width: 32,
              height: 32,
              lineHeight: '32px',
              backgroundColor: 'rgba(0, 0, 0, 0.6)',
              pointerEvents: 'all',
              cursor: 'pointer',
              marginLeft: 14
            }}
            onClick={() => {
              setIsPlaying(false)
              setMonth((month) => (month >= 12 ? 1 : month + 1))
            }}
          >
            <StepForwardOutlined/>
          </div>
        </div>
      }
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'flex-end',
          position: 'absolute',
          overflow: 'hidden',
          justifyContent: 'flex-end',
          right: 20,
          bottom: 20
        }}
      >
        {showAction && (
          <div
            style={{
              width: 266,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-end'
            }}
          >
            <div style={{
              display: 'flex',
              flexDirection: 'row'
            }}>
              <div
                style={{
                  backgroundColor: 'white',
                  borderRadius: 4,
                  height: 40,
                  marginBottom: 8,
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'center',
                  width: 134,
                  pointerEvents: 'all',
                  cursor: 'pointer',
                  fontSize: 14,
                  color: showAll ? 'rgba(28, 184, 135, 1)' : 'rgba(38, 46, 63, 1)',
                  marginRight: '.5rem'
                }}
                onClick={() => {
                  setShowAll(!showAll)
                }}
              >
                <span
                  style={{
                    marginRight: 10,
                    fontSize: 16,
                    lineHeight: '16px'
                  }}
                >
                  <CustomIcons type={showAll ? 'klf-a-taiyangzhishebeifen20' : 'klf-a-taiyangzhishebeifen8-copy'}/>
                </span>
                查看全国屋顶
              </div>
              {layerType !== 'custom' && (
                <div
                  style={{
                    backgroundColor: 'white',
                    borderRadius: 4,
                    height: 40,
                    marginBottom: 8,
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: 114,
                    pointerEvents: 'all',
                    cursor: 'pointer',
                    fontSize: 14,
                    color: layerType === 'road' ? 'rgba(28, 184, 135, 1)' : 'rgba(38, 46, 63, 1)'
                  }}
                  onClick={() => {
                    setLayerType((layerType) => {
                      if (layerType === 'wx') {
                        return 'road'
                      } else {
                        return 'wx'
                      }
                    })
                  }}
                >
                  <span
                    style={{
                      marginRight: 10,
                      fontSize: 16,
                      lineHeight: '16px'
                    }}
                  >
                    <CustomIcons type='klf-icon_yixuan-danxuan'/>
                  </span>
                  查看路网
                </div>
              )}
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                backgroundColor: 'white',
                borderRadius: 4,
                height: 40,
                width: showCoverLayer ? 88 : '100%'
              }}
            >
              {!showCoverLayer && (
                <div
                  style={{
                    flexGrow: 1,
                    borderRight: '1px solid #EBEBEB',
                    textAlign: 'center',
                    pointerEvents: 'all',
                    cursor: 'pointer',
                    color: !props.hideCluster ? 'rgba(28, 184, 135, 1)' : 'rgba(38, 46, 63, 1)'
                  }}
                  onClick={() => {
                    props.doHideCluster?.()
                  }}
                >
                    <span
                      style={{
                        marginRight: 10,
                        fontSize: 16
                      }}
                    >
                      <CustomIcons type={!props.hideCluster ? 'klf-a-juxing4' : 'klf-a-juxing5'}/>
                    </span>
                  筛选
                </div>
              )}
              {!showCoverLayer && (
                <div
                  style={{
                    flexGrow: 1,
                    borderRight: '1px solid #EBEBEB',
                    textAlign: 'center',
                    pointerEvents: 'all',
                    cursor: 'pointer',
                    color: showLoca ? 'rgba(28, 184, 135, 1)' : 'rgba(38, 46, 63, 1)'
                  }}
                  onClick={() => {
                    setShowLoca(!showLoca)
                  }}
                >
                    <span
                      style={{
                        marginRight: 10,
                        fontSize: 16
                      }}
                    >
                      <CustomIcons type='klf-navi-resource'/>
                    </span>
                  资源
                </div>
              )}
              <div
                style={{
                  flexGrow: 1,
                  textAlign: 'center',
                  pointerEvents: 'all',
                  cursor: 'pointer',
                  color: layerType !== 'custom' ? 'rgba(28, 184, 135, 1)' : 'rgba(38, 46, 63, 1)'
                }}
                onClick={() => {
                  setLayerType((layerType) => {
                    if (layerType === 'wx' || layerType === 'road') {
                      return 'custom'
                    } else {
                      return 'road'
                    }
                  })
                }}
              >
                  <span
                    style={{
                      marginRight: 10,
                      fontSize: 16
                    }}
                  >
                    <CustomIcons type={layerType !== 'custom' ? 'klf-a-juxing7' : 'klf-a-juxing6'}/>
                  </span>
                卫星
              </div>
            </div>
          </div>
        )}
        <div
          style={{
            marginLeft: 16,
            fontSize: 24
          }}
        >
          <div
            style={{
              width: 24,
              height: 24,
              lineHeight: '24px',
              textAlign: 'center',
              borderRadius: 2,
              pointerEvents: 'all',
              cursor: 'pointer',
              backgroundColor: 'white'
            }}
            onClick={() => {
              map?.zoomIn()
            }}
          >
            <CustomIcons type='klf-a-juxing2'/>
          </div>
          <div
            style={{
              width: 24,
              height: 24,
              lineHeight: '24px',
              textAlign: 'center',
              borderRadius: 2,
              pointerEvents: 'all',
              cursor: 'pointer',
              backgroundColor: 'white',
              marginTop: 1
            }}
            onClick={() => {
              map?.zoomOut()
            }}
          >
            <CustomIcons type='klf-a-juxing3'/>
          </div>
          <div
            style={{
              width: 24,
              height: 24,
              lineHeight: '24px',
              textAlign: 'center',
              borderRadius: 2,
              pointerEvents: 'all',
              cursor: 'pointer',
              backgroundColor: 'white',
              marginTop: 4
            }}
            onClick={() => {
              const bound = [73.502355, 3.83703, 135.09567, 53.563624]
              const spl = 9
              const dx = bound[2] - bound[0]
              const dy = bound[3] - bound[1]
              const dsx = dx / spl
              const dsy = dy / spl
              const lt: [number, number] = [bound[0] - dsx, bound[1] - dsy]
              const rb: [number, number] = [bound[2] + dsx, bound[3] + dsy]
              const min = map.project(lt)
              const max = map.project(rb)
              map.fitScreenCoordinates(min, max, map.getBearing())
            }}
          >
            <CustomIcons type='klf-a-juxing1'/>
          </div>
          <div
            style={{
              width: 24,
              height: 24,
              lineHeight: '24px',
              textAlign: 'center',
              borderRadius: 2,
              pointerEvents: 'all',
              cursor: 'pointer',
              backgroundColor: 'white',
              marginTop: 4
            }}
            onClick={() => {
              setShowAction(!showAction)
            }}
          >
            <CustomIcons type={showAction ? 'klf-a-juxing8' : 'klf-juxing'}/>
          </div>
        </div>
      </div>
      <div
        style={{
          position: 'absolute',
          right: 10,
          top: 60,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-end'
        }}
      >
        {maxMap[currentSelected]}
        {unitMap[currentSelected]}
        {[
          0, 1, 2, 3, 4, 5, 6, 7, 8, 9,
          0, 1, 2, 3, 4, 5, 6, 7, 8, 9,
          0, 1, 2, 3, 4, 5, 6, 7, 8, 9,
          0, 1, 2, 3, 4, 5, 6, 7, 8, 9,
          0, 1, 2, 3, 4, 5, 6, 7, 8, 9
        ].map((o, idx) => {
          return (
            <div
              key={idx}
              style={{
                backgroundColor: colorArrs[currentSelected](1 - idx / 50),
                width: 10,
                height: 3
              }}
            />
          )
        })}
        {minMap[currentSelected]}
        {unitMap[currentSelected]}
      </div>
      {
        loading && <Spin spinning style={{
          width: '100%',
          height: '100%',
          position: 'absolute',
          left: 0,
          top: 0,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          background: 'rgba(0,0,0,0.3)'
        }}/>
      }
    </div>
  )
}
