/**
 * project
 * unknown
 *
 * OpenAPI spec version: last
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import ajax, { AjaxPromise, ExtraFetchParams } from '@ajax';
import * as models from '../model/models';

/* tslint:disable:no-unused-variable member-ordering object-literal-shorthand */

/**
  * @description authInviteCodeToContentCodeGet参数
  * @property `code` 邀请code
  */
export interface ParamsauthInviteCodeToContentCodeGet {
  // pathParams
  /**
   * 邀请code
   */
  code: string;
}

export class InviteControllerApi {
  protected $basePath = '/api'.replace(/\/$/, '');

  public constructor(basePath?: string) {
    if (basePath !== undefined) {
      this.$basePath = basePath.replace(/\/$/, '');
    }
  }

  /**
   * 
   * @summary 邀请code获取详情
   * @param params ParamsauthInviteCodeToContentCodeGet
   
   * @param opt ajax config
   * @returns models.AuthInviteCodeToContentCode
   */
  public authInviteCodeToContentCodeGet = (
    params: ParamsauthInviteCodeToContentCodeGet,
    opt?: ExtraFetchParams
  ) : AjaxPromise<models.AuthInviteCodeToContentCode>  => {
    const {
    code,
} = params;
    const url = this.$basePath + `/auth/invite/codeToContent/${code}`;
    const p: any = {};
    ajax.check(params.code, 'code');
    return ajax.ajax({
      ...opt,
      method: 'GET',
      url,
      ...p
    });
  }
}

export default new InviteControllerApi();