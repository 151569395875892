/**
 * project
 * unknown
 *
 * OpenAPI spec version: last
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import ajax, { AjaxPromise, ExtraFetchParams } from '@ajax';
import * as models from '../model/models';

/* tslint:disable:no-unused-variable member-ordering object-literal-shorthand */

/**
  * PermissionTemplateReq :PermissionTemplateReq
  */
export type ParamsBodyauthPermissionTemplateAddPost = models.PermissionTemplateReq1;
/**
  * TemplateResourcesReq :TemplateResourcesReq
  */
export type ParamsBodyauthPermissionTemplateAddTemplateAndResourcesPost = models.TemplateResourcesReq;
/**
  * @description authPermissionTemplateDeletePost参数
  * @property `id` (Long)
  */
export interface ParamsauthPermissionTemplateDeletePost {
  // queryParams
  /**
   * (Long)
   */
  id: string;
}
/**
  */
export type ParamsBodyauthPermissionTemplateListPost = any;
/**
  */
export type ParamsBodyauthPermissionTemplateResourcesAddOrUpdatePost = models.AuthPermissionTemplateResourcesAddOrUpdateBody;
/**
  * Set<Long>
  */
export type ParamsBodyauthPermissionTemplateResourcesByTemplateIdsPost = Array<number>;
/**
  * @description authPermissionTemplateResourcesQueryGet参数
  * @property `userId` (Long)
  * @property `templateId` (Long)
  */
export interface ParamsauthPermissionTemplateResourcesQueryGet {
  // queryParams
  /**
   * (Long)
   */
  userId: string;
  /**
   * (Long)
   */
  templateId: string;
}
/**
  * @description authPermissionTemplateResourcesQueryTreeGet参数
  * @property `templateId` (Long)
  */
export interface ParamsauthPermissionTemplateResourcesQueryTreeGet {
  // queryParams
  /**
   * (Long)
   */
  templateId: string;
}
/**
  * PermissionTemplateReq :PermissionTemplateReq
  */
export type ParamsBodyauthPermissionTemplateUpdatePost = models.PermissionTemplateReq;

export class PermissionTemplateControllerApi {
  protected $basePath = '/api'.replace(/\/$/, '');

  public constructor(basePath?: string) {
    if (basePath !== undefined) {
      this.$basePath = basePath.replace(/\/$/, '');
    }
  }

  /**
   * ```   /_**      *  新增用户模板      *_/     @PostMapping(\"add\")     public Result<Boolean> addPermissionTemplate(@RequestBody PermissionTemplateReq permissionTemplateReq) ```
   * @summary 新增用户模板
   
   * @param data: ParamsBodyauthPermissionTemplateAddPost// PermissionTemplateReq :PermissionTemplateReq
   * @param opt ajax config
   * @returns models.ResultLong
   */
  public authPermissionTemplateAddPost = (
    
    data: ParamsBodyauthPermissionTemplateAddPost,opt?: ExtraFetchParams
  ) : AjaxPromise<models.ResultLong>  => {
    const url = this.$basePath + `/auth/permissionTemplate/add`;
    const p: any = {};
    p.data = data;
    return ajax.ajax({
      ...opt,
      method: 'POST',
      url,
      ...p
    });
  }
  /**
   * 
   * @summary 新增模板以及模板资源
   
   * @param data: ParamsBodyauthPermissionTemplateAddTemplateAndResourcesPost// TemplateResourcesReq :TemplateResourcesReq
   * @param opt ajax config
   * @returns models.ResultBoolean
   */
  public authPermissionTemplateAddTemplateAndResourcesPost = (
    
    data: ParamsBodyauthPermissionTemplateAddTemplateAndResourcesPost,opt?: ExtraFetchParams
  ) : AjaxPromise<models.ResultBoolean>  => {
    const url = this.$basePath + `/auth/permissionTemplate/addTemplateAndResources`;
    const p: any = {};
    p.data = data;
    return ajax.ajax({
      ...opt,
      method: 'POST',
      url,
      ...p
    });
  }
  /**
   * 
   * @summary 删除模板
   * @param params ParamsauthPermissionTemplateDeletePost
   
   * @param opt ajax config
   * @returns models.ResultBoolean
   */
  public authPermissionTemplateDeletePost = (
    params: ParamsauthPermissionTemplateDeletePost,
    opt?: ExtraFetchParams
  ) : AjaxPromise<models.ResultBoolean>  => {
    const url = this.$basePath + `/auth/permissionTemplate/delete`;
    const p: any = {};
    p.query = {};
    if ('id' in params) p.query.id = params.id;
    ajax.check(params.id, 'id');
    return ajax.ajax({
      ...opt,
      method: 'POST',
      url,
      ...p
    });
  }
  /**
   * 
   * @summary 用户模板列表
   
   
   * @param opt ajax config
   * @returns models.ResultListUserPermissionTemplateRes
   */
  public authPermissionTemplateListGet = (
    
    opt?: ExtraFetchParams
  ) : AjaxPromise<models.ResultListUserPermissionTemplateRes>  => {
    const url = this.$basePath + `/auth/permissionTemplate/list`;
    const p: any = {};
    return ajax.ajax({
      ...opt,
      method: 'GET',
      url,
      ...p
    });
  }
  /**
   * ```   /_**      * 用户模板列表      *_/     @RequestMapping(\"/list\")     public Result<List<UserPermissionTemplateRes>> userPermissionTemplateList() ```
   * @summary 用户模板列表
   
   * @param data: ParamsBodyauthPermissionTemplateListPost
   * @param opt ajax config
   * @returns models.ResultListUserPermissionTemplateRes1
   */
  public authPermissionTemplateListPost = (
    
    data: ParamsBodyauthPermissionTemplateListPost,opt?: ExtraFetchParams
  ) : AjaxPromise<models.ResultListUserPermissionTemplateRes1>  => {
    const url = this.$basePath + `/auth/permissionTemplate/list`;
    const p: any = {};
    p.data = data;
    return ajax.ajax({
      ...opt,
      method: 'POST',
      url,
      ...p
    });
  }
  /**
   * ```   /_**      * 新增编辑模板资源      *_/     @PostMapping(\"resources/addOrUpdate\")     public Result<Boolean> permissionTemplateResourcesAddOrUpdate(@RequestBody ResourcesAddOrUpdate resourcesAddOrUpdates) ```
   * @summary 新增编辑模板资源
   
   * @param data: ParamsBodyauthPermissionTemplateResourcesAddOrUpdatePost
   * @param opt ajax config
   * @returns models.ResultBoolean
   */
  public authPermissionTemplateResourcesAddOrUpdatePost = (
    
    data: ParamsBodyauthPermissionTemplateResourcesAddOrUpdatePost,opt?: ExtraFetchParams
  ) : AjaxPromise<models.ResultBoolean>  => {
    const url = this.$basePath + `/auth/permissionTemplate/resources/addOrUpdate`;
    const p: any = {};
    p.data = data;
    return ajax.ajax({
      ...opt,
      method: 'POST',
      url,
      ...p
    });
  }
  /**
   * ``` /_**  * 查询所有的模板资源  *_/ @GetMapping(\"/resourcesAll\") public Result<List<PermissionResourcesEntity>> permissionResourcesAll()  modular:[项目设计,投资开发] # 所属模块 function_permission:[设置,下载,设计,生成及下载,编辑,录入,填写,上传/关联,更新] # 功能权限 operation_object:[电价方案,负荷曲线,天气数据,时间范围与精度,技术方案,财务方案,方案报告,合同,项目基本信息,项目收资表,项目方案,备案文件] # 操作对象   url_key:[     \"electricity_price_scheme\", # 电价方案     \"load_curve\", # 负荷曲线     \"weather_data\", #  天气数据     \"time_range_accuracy\", # 时间范围与精度     \"technical_proposal\", #  技术方案     \"financial_plan\", #  财务方案     \"programme_report\" #  方案报告     \"project_contract_edit\" # 编辑合同     \"project_info\", # 项目基本信息     \"project_capital\", # 项目收资表     \"project_programme\", # 项目方案     \"project_filing_file\" # 备案文件 ] # 主要的功能key 对应operation_object ```
   * @summary 查询所有的模板资源
   
   
   * @param opt ajax config
   * @returns models.ResultListPermissionResourcesEntity
   */
  public authPermissionTemplateResourcesAllGet = (
    
    opt?: ExtraFetchParams
  ) : AjaxPromise<models.ResultListPermissionResourcesEntity>  => {
    const url = this.$basePath + `/auth/permissionTemplate/resourcesAll`;
    const p: any = {};
    return ajax.ajax({
      ...opt,
      method: 'GET',
      url,
      ...p
    });
  }
  /**
   * ```   /_**      * 查询模版资源树      *_/     @GetMapping(\"/resourcesAllTree\")     public Result<List<PermissionResourcesRes>> permissionResourcesAllTree() ```
   * @summary 查询模版资源树
   
   
   * @param opt ajax config
   * @returns models.ResultListPermissionResourcesRes
   */
  public authPermissionTemplateResourcesAllTreeGet = (
    
    opt?: ExtraFetchParams
  ) : AjaxPromise<models.ResultListPermissionResourcesRes>  => {
    const url = this.$basePath + `/auth/permissionTemplate/resourcesAllTree`;
    const p: any = {};
    return ajax.ajax({
      ...opt,
      method: 'GET',
      url,
      ...p
    });
  }
  /**
   * 
   * @summary 查询资源
   
   * @param data: ParamsBodyauthPermissionTemplateResourcesByTemplateIdsPost// Set<Long>
   * @param opt ajax config
   * @returns models.ResultListTemplateResourcesRes
   */
  public authPermissionTemplateResourcesByTemplateIdsPost = (
    
    data: ParamsBodyauthPermissionTemplateResourcesByTemplateIdsPost,opt?: ExtraFetchParams
  ) : AjaxPromise<models.ResultListTemplateResourcesRes>  => {
    const url = this.$basePath + `/auth/permissionTemplate/resourcesByTemplateIds`;
    const p: any = {};
    p.data = data;
    return ajax.ajax({
      ...opt,
      method: 'POST',
      url,
      ...p
    });
  }
  /**
   * ```   /_**      * 模板资源查看      *_/     @GetMapping(\"/resources/query\")     public Result<List<ResourcesRes>> permissionTemplateResourcesQuery(@RequestParam(\"templateId\") Long  templateId) ```
   * @summary 模板资源查看
   * @param params ParamsauthPermissionTemplateResourcesQueryGet
   
   * @param opt ajax config
   * @returns models.ResultListResourcesRes
   */
  public authPermissionTemplateResourcesQueryGet = (
    params: ParamsauthPermissionTemplateResourcesQueryGet,
    opt?: ExtraFetchParams
  ) : AjaxPromise<models.ResultListResourcesRes>  => {
    const url = this.$basePath + `/auth/permissionTemplate/resources/query`;
    const p: any = {};
    p.query = {};
    if ('userId' in params) p.query.userId = params.userId;
    ajax.check(params.userId, 'userId');
    if ('templateId' in params) p.query.templateId = params.templateId;
    ajax.check(params.templateId, 'templateId');
    return ajax.ajax({
      ...opt,
      method: 'GET',
      url,
      ...p
    });
  }
  /**
   * ```  /_**      * 模板资源查看树      * @param templateId      *_/     @GetMapping(\"/resources/query/tree\")     @TenantChange     public Result<ResourcesTreeRes> permissionTemplateResourcesQueryTree(@RequestParam(\"templateId\") Long  templateId)  ```
   * @summary 模板资源查看树
   * @param params ParamsauthPermissionTemplateResourcesQueryTreeGet
   
   * @param opt ajax config
   * @returns models.AuthPermissionTemplateResourcesQueryTree
   */
  public authPermissionTemplateResourcesQueryTreeGet = (
    params: ParamsauthPermissionTemplateResourcesQueryTreeGet,
    opt?: ExtraFetchParams
  ) : AjaxPromise<models.AuthPermissionTemplateResourcesQueryTree>  => {
    const url = this.$basePath + `/auth/permissionTemplate/resources/query/tree`;
    const p: any = {};
    p.query = {};
    if ('templateId' in params) p.query.templateId = params.templateId;
    ajax.check(params.templateId, 'templateId');
    return ajax.ajax({
      ...opt,
      method: 'GET',
      url,
      ...p
    });
  }
  /**
   * 
   * @summary 修改模板名称或者是否默认
   
   * @param data: ParamsBodyauthPermissionTemplateUpdatePost// PermissionTemplateReq :PermissionTemplateReq
   * @param opt ajax config
   * @returns models.ResultBoolean
   */
  public authPermissionTemplateUpdatePost = (
    
    data: ParamsBodyauthPermissionTemplateUpdatePost,opt?: ExtraFetchParams
  ) : AjaxPromise<models.ResultBoolean>  => {
    const url = this.$basePath + `/auth/permissionTemplate/update`;
    const p: any = {};
    p.data = data;
    return ajax.ajax({
      ...opt,
      method: 'POST',
      url,
      ...p
    });
  }
}

export default new PermissionTemplateControllerApi();