import React from 'react'
import { Typography, Tooltip } from 'antd'

import { getActionColumn, TablePage, getColumnFilterProps } from '../../../../components/wl-table-page'
import { ProjectPartnerService } from '../../../../apis/wl-service'

export default ({ editRow, componentRef }) => {
  const ellipsis = (text: string) =>
    text && (
      <Tooltip title={text}>
        <Typography.Text ellipsis>{text}</Typography.Text>
      </Tooltip>
    )

  // 公司列表字段
  const columns = (tableParams: any) => [
    {
      title: '合作方名称',
      dataIndex: 'name',
      width: 180,
      ...getColumnFilterProps({
        type: 'input',
        placeholder: '请输入合作方名称',
        value: tableParams.name,
        onSearch: (value) => {
          componentRef.current?.reload?.({
            name: value,
            page: 1,
          })
        },
      }),
      render: ellipsis,
    },
    {
      title: '社会统一信用代码',
      dataIndex: 'code',
      width: 180,
      render: ellipsis,
    },
    {
      title: '注册资金(万)',
      dataIndex: 'registeredCapital',
      width: 140,
    },
    {
      title: '企业地址',
      dataIndex: 'address',
      width: 150,
      render: ellipsis,
    },
    {
      title: '经营范围',
      dataIndex: 'propertyJson',
      width: 140,
      render: ellipsis,
    },
    {
      title: '邮箱',
      dataIndex: 'mail',
      width: 180,
      render: ellipsis,
    },
    {
      title: '企业法人',
      dataIndex: 'legalPerson',
      width: 140,
      render: ellipsis,
    },
    {
      title: '企业法人电话',
      dataIndex: 'phone',
      width: 140,
      render: ellipsis,
    },
    {
      title: '相关负责人',
      dataIndex: 'principal',
      width: 140,
      render: ellipsis,
    },
    {
      title: '企业负责人身份证号',
      dataIndex: 'principalCode',
      width: 180,
      render: ellipsis,
    },
    {
      title: '企业负责人联系电话',
      dataIndex: 'principalPhone',
      width: 180,
      render: ellipsis,
    },
    {
      title: '银联负责人',
      dataIndex: 'unionPerson',
      width: 140,
      render: ellipsis,
    },
    {
      title: '银联负责人电话',
      dataIndex: 'unionPersonPhone',
      width: 140,
      render: ellipsis,
    },
    {
      title: '基本户银行卡号',
      dataIndex: 'cardNo',
      width: 140,
      render: ellipsis,
    },
    {
      title: '基本户开户行',
      dataIndex: 'bank',
      width: 140,
      render: ellipsis,
    },
    {
      title: '基本户联行号',
      dataIndex: 'bankNo',
      width: 140,
      render: ellipsis,
    },
    {
      title: '一般户银行卡号',
      dataIndex: 'normalCardNo',
      width: 140,
      render: ellipsis,
    },
    {
      title: '一般户开户行',
      dataIndex: 'normalBank',
      width: 140,
      render: ellipsis,
    },
    {
      title: '一般户联行号',
      dataIndex: 'normalBankNo',
      width: 140,
      render: ellipsis,
    },
    {
      title: '企业状态',
      dataIndex: 'status',
      width: 140,
      render: ellipsis,
    },
    {
      title: '使用状态',
      dataIndex: 'useStatus',
      width: 140,
      render: ellipsis,
    },
    {
      title: '审核原因',
      dataIndex: 'msg',
      width: 200,
      render: ellipsis,
    },
    getActionColumn([
      {
        key: 'edit',
        label: '编辑',
        visible: (row: any) => row.status === '审核驳回',
        onClick: (row: any) => {
          editRow(row)
        },
      },
      {
        key: 'delete',
        type: 'delete',
        getter: (row: any) => {
          return ProjectPartnerService.remove({ id: row.id })
        },
        success: (arg: any) => {
          componentRef.current?.reload(arg)
        },
      },
    ]),
  ]

  return (
    <TablePage
      autoLoad={false}
      componentRef={componentRef}
      columns={columns}
      rowKey="id"
      getter={(arg: any) => ProjectPartnerService.list({ ...arg, type: 0 })}
    />
  )
}
