import React, { useEffect, useState, useImperativeHandle } from 'react'
import { Card, Typography, Space, Button, FormInstance, Form, Row, Col, Select, DatePicker, Input, Tooltip, Modal, message, Table } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import { useNavigate, useParams } from 'react-router-dom'
import moment from 'moment'
import { BreadcrumbObservable, SideMenuObservable } from '../../../application'
import { MaterialService, CommonService } from '../../../apis/engineering-service'
import { PHONE_EXP } from '../../../utils/constant'

import './index.less'
export interface ContractFormInstance extends FormInstance {
  reset: () => void // 重置表单数据
  submit: (cb?: () => void) => void // 提交表单
}

export interface ContractFormProps {
  componentRef: any
  type?: any
  materialId?: any
}

const ContractForm: React.FC<ContractFormProps> = ({ type, materialId, componentRef }) => {
  const [form] = Form.useForm()
  const [submiting, setSubmiting] = React.useState<boolean>(false) // 表单提交状态
  const { TextArea } = Input;

  const [dataSource, setDataSource] = useState<any>({}) // 编辑时 详情数据

  const [addVisible, setAddVisible] = useState(false) // 添加采购合同 弹窗
  const [purchaseForm] = Form.useForm()
  const [addLoading, setAddLoading] = useState(false)

  const [isContract, setIsContract] = useState(true) // 是否选了 项目名称

  const [materialList, setMaterialList] = useState([]) // 弹窗数据

  // 存储提交过的 采购明细
  // 验证手机状态
  const [contracts, setContracts] = useState({}) // 提交采购合同存储的数据
  const [contractList, setContractList] = useState([]) // 采购明细
  const [contractNos, setContractNos] = useState([]) // 选中的合同编号

  const [projectAddress, setProjectAddress] = useState('') // 项目地址
  const [supplierId, setSupplierId] = useState('') // 项目地址

  // 验证手机状态
  const [checkPhone, setCheckPhone] = useState<any>({
    validateStatus: ' ',
    help: '',
  })

  // 下拉数据
  const [options, setOptions] = React.useState<{ [name: string]: any }>({
    projectListOptions: [], // 项目名称 下拉数据
    contractOptions: [], //  合同名称、供应商、业务机构 下拉数据
    purchaseContractNos: [] // 采购合同弹窗 - 合同编号 下拉数据
  })

  // 提交验证
  useImperativeHandle(componentRef, () => ({
    ...form,
    reset: () => form.resetFields(),
    submit: async (cb?: () => void) => {
      if (submiting) return
      if (contractNos.length === 0) {
        message.warning('请添加采购合同')
        return
      }
      try {
        let params = await form.validateFields()

        params.choiceTime = params.choiceTime ? params.choiceTime.format('YYYY-MM-DD HH:mm:ss') : ''
        
        try {
          setSubmiting(true)
          const rootObj = {
            ...params,
            projectAddress,
            supplierId,
            type: type ? Number(type) : type,
            contractNos,
          }
          // 编辑时添加ID
          if (materialId && materialId !== 'create') {
            rootObj['goodsMaterialsManageId'] = materialId
          }
          const resp: any = await MaterialService.create({ root: rootObj })
          if (resp?.code === 200) {
            message.success(materialId && materialId !== 'create' ? '编辑物资成功' : '新增物资成功')
            cb?.()
          } else if (resp?.msg) {
            message.error(resp.msg)
          }
        } catch (err: any) {
          console.warn(err)
        } finally {
          setSubmiting(false)
        }
      } catch (e) {
        console.warn(e)
      }
    },
  }))

  // 获取基础下拉数据
  useEffect(() => {
    // 获取项目名称 下拉数据
    CommonService.getProjectDataSelector()
      .then((res: any) => {
        setOptions((previous) => ({ ...previous, projectListOptions: res?.data || [] }))
      })
      .catch(() => {})
      
    // 编辑 获取详情数据 回显
    if (materialId && materialId !== 'create') {
      // 获取详情数据
      MaterialService.getMaterialDetail(materialId)
        .then((res: any) => {
          if (res?.data) {
            setDataSource(res?.data || {})
            setTimeout(() => {
              form.setFieldsValue(formater(res?.data || {}))
            }, 300)
          }
          
          MaterialService.getMaterialsList(res?.data?.contractNo ||  '')
            .then((res: any) => {
              setContractList(res?.data || [])
              contractNos
            })
            .catch(() => {})
        })
        .catch(() => {})
    }
  }, [])

  // 数据转换
  const formater = React.useCallback((values: any) => {
    values.choiceTime = values.choiceTime ? moment(values.choiceTime, 'YYYY-MM-DD HH:mm:ss') : null
    // 回显 采购合同编号
    if (values.details) {
      setContractNos(values.details.map((item) => item.contractNo))
    }
    if (values.projectAddress) {
      setProjectAddress(values.projectAddress || '')
    }

    if (values.supplierId) {
      setSupplierId(values.supplierId || '')
    }
    
    return values
  }, [dataSource])

  // 布局属性
  const layout = React.useMemo(
    () => ({
      col: { xs: 12, md: 8 },
      col12: { xs: 12, md: 12 },
      col24: { xs: 24 },
      span8: { labelCol: { xs: 24, md: 9 }, wrapperCol: { xs: 24, md: 14 } },
      span24: { labelCol: { xs: 24, md: 3 }, wrapperCol: { xs: 24, md: 21 } },
      span24NoLabel: { wrapperCol: { offset: 3, xs: 24, md: 21 } },
      select: {
        dropdownMatchSelectWidth: true,
        placeholder: '请选择',
        showSearch: false,
        filterOption: (input: string, option: any) => {
          return (option!.children as unknown as string).toLowerCase().includes(input.toLowerCase())
        },
      },
    }),
    []
  )

  // 新增采购合同
  const addPurchaseModel = () => {
    if (form.getFieldValue('contractNo')) {
      setAddVisible(true)
    } else {
      message.info('请选择合同名称')
    }
  }

  // 关闭采购合同
  const cancelPurchaseModel = () => {
    setAddVisible(false)
    // 添加完清空 弹窗数据
    purchaseForm.resetFields() 
    setMaterialList([])
  }

  // 提交 新增采购合同
  const createScheme = () => {
    setAddLoading(true)
    if (purchaseForm.getFieldValue('contractNo')) {
      // 第一次提交采购明细， 直接添加
      if (contracts) {
        let obj = {}
        obj[purchaseForm.getFieldValue('contractNo')] = materialList
        setContracts(obj)
        setAddLoading(false)
        setAddVisible(false)
        cancelPurchaseModel()
        combinationList(obj) // 出里添加过的采购明细
      } else {
        // 多次提交采购明细，数据处理
        const keys = Object.keys(contracts)
        keys.forEach((key) => {
          if (key === purchaseForm.getFieldValue('contractNo')) {
            contracts[key] = materialList
          } else {
            contracts[purchaseForm.getFieldValue('contractNo')] = materialList
          }
        })
        
        setContracts(contracts)
        setAddLoading(false)
        cancelPurchaseModel()
        combinationList(contracts) // 出里添加过的采购明细
      }
      
    } else {
      message.warning('请选择合同编号')
      setAddLoading(false)
    }
  }

  // 物资明细列表 弹窗列表
  const MaterialDetailsMoadl = () => {
    const columns = [
      {
        title: '项次',
        dataIndex: 'itemNumber',
        width: 60,
        render: (text: string) =>
          text && (
            <Tooltip title={text}>
              <Typography.Text ellipsis>{text}</Typography.Text>
            </Tooltip>
          )
      },
      {
        title: '单据编号',
        dataIndex: 'orderNo',
        width: 100,
        render: (text: string) =>
          text && (
            <Tooltip title={text}>
              <Typography.Text ellipsis>{text}</Typography.Text>
            </Tooltip>
          ),
      },
      
      {
        title: '产品编号',
        dataIndex: 'productNo',
        width: 100,
        render: (text: string) =>
          text && (
            <Tooltip title={text}>
              <Typography.Text ellipsis>{text}</Typography.Text>
            </Tooltip>
          )
      },
      {
        title: '产品名称',
        dataIndex: 'productName',
        width: 100,
        render: (text: string) =>
          text && (
            <Tooltip title={text}>
              <Typography.Text ellipsis>{text}</Typography.Text>
            </Tooltip>
          ),
      },
      {
        title: '规格',
        dataIndex: 'specification',
        width: 100,
        render: (text: string) =>
          text && (
            <Tooltip title={text}>
              <Typography.Text ellipsis>{text}</Typography.Text>
            </Tooltip>
          ),
      },
      {
        title: '品牌',
        dataIndex: 'brand',
        width: 100,
        render: (text: string) =>
          text && (
            <Tooltip title={text}>
              <Typography.Text ellipsis>{text}</Typography.Text>
            </Tooltip>
          ),
      },
      {
        title: '型号',
        dataIndex: 'model',
        width: 120,
        render: (text: string) =>
          text && (
            <Tooltip title={text}>
              <Typography.Text ellipsis>{text}</Typography.Text>
            </Tooltip>
          ),
      },
      {
        title: '数量',
        dataIndex: 'number',
        width: 100,
        render: (text: number) =>
          text && (
            <Tooltip title={text}>
              <Typography.Text ellipsis>{text}</Typography.Text>
            </Tooltip>
          ),
      },
      {
        title: '产品单价',
        dataIndex: 'productUnitPrice',
        width: 100,
        render: (text: number) =>
          text && (
            <Tooltip title={text}>
              <Typography.Text ellipsis>{text}</Typography.Text>
            </Tooltip>
          ),
      },
      {
        title: '产品金额',
        dataIndex: 'productMoney',
        width: 100,
        render: (text: number) =>
          text && (
            <Tooltip title={text}>
              <Typography.Text ellipsis>{text}</Typography.Text>
            </Tooltip>
          ),
      },
      {
        title: '数量单位',
        dataIndex: 'numberUnit',
        width: 100,
        render: (text: string) =>
          text && (
            <Tooltip title={text}>
              <Typography.Text ellipsis>{text}</Typography.Text>
            </Tooltip>
          ),
      },
      {
        title: '重量单位',
        dataIndex: 'weightUnit',
        width: 100,
        render: (text: string) =>
          text && (
            <Tooltip title={text}>
              <Typography.Text ellipsis>{text}</Typography.Text>
            </Tooltip>
          ),
      },
      {
        title: '需求总量',
        dataIndex: 'demandTotal',
        width: 100,
        render: (text: number) =>
          text && (
            <Tooltip title={text}>
              <Typography.Text ellipsis>{text}</Typography.Text>
            </Tooltip>
          ),
      },
      {
        title: '已采购总量',
        dataIndex: 'purchaseTotal',
        width: 120,
        render: (text: number) =>
          text && (
            <Tooltip title={text}>
              <Typography.Text ellipsis>{text}</Typography.Text>
            </Tooltip>
          ),
      },
      {
        title: '日期',
        dataIndex: 'addTime',
        width: 180,
        render: (text: any) => {
          return text && moment(text).format('YYYY.MM.DD HH:mm:ss')
        }
      }
    ]
    return (
      <Table
          className="right-actions"
          columns={columns}
          dataSource={materialList}
          rowKey={(record) => record.orderNo}
          scroll={{ x: '100%', y: `400px` }}
          style={{ flexGrow: 1 }}
          pagination={false}
        />
    )
  }

  // 物资明细列表
  const MaterialDetails = () => {
    const columns = [
      {
        title: '项次',
        dataIndex: 'itemNumber',
        width: 60,
        render: (text: string) =>
          text && (
            <Tooltip title={text}>
              <Typography.Text ellipsis>{text}</Typography.Text>
            </Tooltip>
          )
      },
      {
        title: '单据编号',
        dataIndex: 'orderNo',
        width: 100,
        render: (text: string) =>
          text && (
            <Tooltip title={text}>
              <Typography.Text ellipsis>{text}</Typography.Text>
            </Tooltip>
          ),
      },
      
      {
        title: '产品编号',
        dataIndex: 'productNo',
        width: 100,
        render: (text: string) =>
          text && (
            <Tooltip title={text}>
              <Typography.Text ellipsis>{text}</Typography.Text>
            </Tooltip>
          )
      },
      {
        title: '产品名称',
        dataIndex: 'productName',
        width: 100,
        render: (text: string) =>
          text && (
            <Tooltip title={text}>
              <Typography.Text ellipsis>{text}</Typography.Text>
            </Tooltip>
          ),
      },
      {
        title: '规格',
        dataIndex: 'specification',
        width: 100,
        render: (text: string) =>
          text && (
            <Tooltip title={text}>
              <Typography.Text ellipsis>{text}</Typography.Text>
            </Tooltip>
          ),
      },
      {
        title: '品牌',
        dataIndex: 'brand',
        width: 100,
        render: (text: string) =>
          text && (
            <Tooltip title={text}>
              <Typography.Text ellipsis>{text}</Typography.Text>
            </Tooltip>
          ),
      },
      {
        title: '型号',
        dataIndex: 'model',
        width: 120,
        render: (text: string) =>
          text && (
            <Tooltip title={text}>
              <Typography.Text ellipsis>{text}</Typography.Text>
            </Tooltip>
          ),
      },
      {
        title: '数量',
        dataIndex: 'number',
        width: 100,
        render: (text: number) =>
          text && (
            <Tooltip title={text}>
              <Typography.Text ellipsis>{text}</Typography.Text>
            </Tooltip>
          ),
      },
      {
        title: '产品单价',
        dataIndex: 'productUnitPrice',
        width: 100,
        render: (text: number) =>
          text && (
            <Tooltip title={text}>
              <Typography.Text ellipsis>{text}</Typography.Text>
            </Tooltip>
          ),
      },
      {
        title: '产品金额',
        dataIndex: 'productMoney',
        width: 100,
        render: (text: number) =>
          text && (
            <Tooltip title={text}>
              <Typography.Text ellipsis>{text}</Typography.Text>
            </Tooltip>
          ),
      },
      {
        title: '数量单位',
        dataIndex: 'numberUnit',
        width: 100,
        render: (text: string) =>
          text && (
            <Tooltip title={text}>
              <Typography.Text ellipsis>{text}</Typography.Text>
            </Tooltip>
          ),
      },
      {
        title: '重量单位',
        dataIndex: 'weightUnit',
        width: 100,
        render: (text: string) =>
          text && (
            <Tooltip title={text}>
              <Typography.Text ellipsis>{text}</Typography.Text>
            </Tooltip>
          ),
      },
      {
        title: '需求总量',
        dataIndex: 'demandTotal',
        width: 100,
        render: (text: number) =>
          text && (
            <Tooltip title={text}>
              <Typography.Text ellipsis>{text}</Typography.Text>
            </Tooltip>
          ),
      },
      {
        title: '已采购总量',
        dataIndex: 'purchaseTotal',
        width: 120,
        render: (text: number) =>
          text && (
            <Tooltip title={text}>
              <Typography.Text ellipsis>{text}</Typography.Text>
            </Tooltip>
          ),
      },
      {
        title: '日期',
        dataIndex: 'addTime',
        width: 180,
        render: (text: any) => {
          return text && moment(text).format('YYYY.MM.DD HH:mm:ss')
        }
      }
    ]
    return (
      <Table
          className="right-actions"
          columns={columns}
          dataSource={contractList}
          rowKey={(record) => record.orderNo}
          scroll={{ x: '100%', y: `400px` }}
          style={{ flexGrow: 1 }}
          pagination={false}
        />
    )
  }

  // 处理 物资采购明细列表 数据
  const combinationList = (data: any) => {
      const keys = Object.keys(data)
      keys.forEach((key) => {
        data[key].map((g) => {
          contractList.push(g)
        })
      })
      
    setContractList(contractList)
    setContractNos(keys)
  }

  return (
    <Form form={form} name="contract-form" className="wl-form-item" layout="horizontal" labelWrap  style={{marginTop: 30, paddingRight: 50}}>
      <Row gutter={24}>
        <Col {...layout.col}>
          <Form.Item
            name="projectName"
            label="项目名称"
            {...layout.span8}
            rules={[{ required: true, message: '请填写项目名称' }]}
          >
            <Select
              {...layout.select}
              onChange={(value: any) => {
                console.log(value, options?.projectListOptions[value])
                form.setFieldsValue({
                  projectCode: options?.projectListOptions[value].projectNo,
                  projectName: options?.projectListOptions[value].projectName,
                })
                setProjectAddress(options?.projectListOptions[value].projectAddress || '')
                // 获取 合同名称、供应商、业务机构 下拉数据
                if (value) {
                  setIsContract(false)
                } else {
                  setIsContract(true)
                }
                CommonService.getContractData(options?.projectListOptions[value].projectNo || '')
                .then((res: any) => {
                  console.log(res, '获取 合同名称、供应商、业务机构 下拉数据')
                  
                  setOptions((previous) => ({ ...previous, contractOptions: res?.data || [] }))
                })
                .catch(() => {})
              }}
            >
              {options?.projectListOptions?.map((o: any, index: number) => {
                return <Select.Option key={index}>{o.projectName}</Select.Option>
              })}
            </Select>
          </Form.Item>
        </Col>
        <Col {...layout.col}>
          <Form.Item
            name="projectCode"
            label="项目代码"
            {...layout.span8}
          >
            <Input allowClear disabled placeholder="请输入" />
          </Form.Item>
        </Col>
        <Col {...layout.col}>
          <Form.Item
            name="choiceTime"
            label="日期"
            {...layout.span8}
          >
            <DatePicker format="YYYY-MM-DD HH:mm:ss" style={{ width: '100%' }} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col {...layout.col}>
          <Form.Item
            name="orderNo"
            label="单据号"
            {...layout.span8}
          >
            <Input allowClear placeholder="请输入" />
          </Form.Item>
        </Col>
        <Col {...layout.col}>
          <Form.Item
            name="projectNo"
            label="产品编号"
            {...layout.span8}
          >
            <Input allowClear placeholder="请输入" />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col {...layout.col}>
          <Form.Item
            name="contractName"
            label="合同名称"
            {...layout.span8}
            rules={[{ required: true, message: '请填写合同名称' }]}
          >
            <Select
              {...layout.select}
              disabled={isContract}
              onChange={(value: any) => {
                console.log(value)
                form.setFieldsValue({
                  contractNo: options?.contractOptions[value].contractNo ||  '',
                  contractName: options?.contractOptions[value].contractName ||  '',
                })
                setSupplierId(options?.contractOptions[value].supplierId || '')
                setOptions((previous) => ({ ...previous, purchaseContractNos: options?.contractOptions[value].purchaseContractNos || [] }))
              }}
            >
              {options?.contractOptions?.map((o: any, index: number) => {
                return <Select.Option key={index}>{o.contractName}</Select.Option>
              })}
            </Select>
          </Form.Item>
        </Col>
        <Col {...layout.col}>
          <Form.Item
            name="contractNo"
            label="合同编号"
            {...layout.span8}
            rules={[{ required: true, message: '请填写合同编号' }]}
          >
            <Input allowClear disabled placeholder="请输入" />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col {...layout.col}>
          <Form.Item
            name="supplier"
            label="供应商"
            {...layout.span8}
            rules={[{ required: true, message: '请填写供应商' }]}
          >
            <Select
              {...layout.select}
              disabled={isContract}
              onChange={(value: any) => {
                form.setFieldsValue({
                  clearingHouse: value ||  '',
                })
              }}
            >
              {options?.contractOptions?.map(({ supplier, clearingHouse }) => {
                return <Select.Option key={clearingHouse}>{supplier}</Select.Option>
              })}
            </Select>
          </Form.Item>
        </Col>
        <Col {...layout.col}>
          <Form.Item
            name="clearingHouse"
            label="结算单位"
            {...layout.span8}
            rules={[{ required: true, message: '请填写结算单位' }]}
          >
            <Input allowClear disabled placeholder="请输入" />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col {...layout.col}>
          <Form.Item
            name="programOffices"
            label="业务机构"
            {...layout.span8}
            rules={[{ required: true, message: '请填写业务机构' }]}
          >
            <Select
              {...layout.select}
              disabled={isContract}
            >
              {options?.contractOptions?.map(({ programOffices }) => {
                return <Select.Option key={programOffices}>{programOffices}</Select.Option>
              })}
            </Select>
          </Form.Item>
        </Col>
        <Col {...layout.col}>
          <Form.Item
            name="programDepartment"
            label="业务部门"
            {...layout.span8}
          >
            <Input allowClear placeholder="请输入" />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col {...layout.col}>
          <Form.Item
            name="operatorName"
            label="制单人"
            {...layout.span8}
          >
            <Input allowClear placeholder="请输入" />
          </Form.Item>
        </Col>
        <Col {...layout.col}>
          <Form.Item
            name="contactWay"
            label="联系方式"
            {...layout.span8}
            validateStatus={checkPhone.validateStatus}
            help={checkPhone.help ? checkPhone.help : undefined}
          >
            <Input
              allowClear
              onBlur={(e) => {
                if (!PHONE_EXP.test(e.target.value)) {
                  setCheckPhone({
                    validateStatus: 'error',
                    help: '手机号格式不正确',
                  })
                } else {
                  setCheckPhone({
                    validateStatus: '  ',
                    help: null,
                  })
                }
              }}
              type="phone"
              placeholder="请输入联人系电话"
              suffix={<span>+86</span>}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col {...layout.col24}>
          <Form.Item
            name="remark"
            label="备注"
            {...layout.span24}
          >
              <TextArea showCount maxLength={200} style={{ height: 150 }} onChange={
                (e: React.ChangeEvent<HTMLTextAreaElement>) => {
                  console.log('Change:', e.target.value);
                }
              } />
          </Form.Item>
        </Col>
      </Row>
      <div className="material-details-modal">
        <Typography.Title level={5}>物资明细: 
            <Button
              style={{marginLeft: 30}}
              type="primary"
              icon={<PlusOutlined />}
              onClick={() => addPurchaseModel()}
            >
              采购合同
            </Button>
        </Typography.Title>
        <MaterialDetails />

        {
          addVisible && (
            <Modal
              visible={addVisible}
              title={<strong>新增采购合同</strong>}
              footer={false}
              keyboard={false}
              closable={true}
              onCancel={() => cancelPurchaseModel()}
              width={1000}
            >
              <Form form={purchaseForm} name="contract-form" className="wl-form-item" layout="horizontal"
                labelWrap 
                style={{marginTop: 30, paddingRight: 50}}
              >
                <Row gutter={24}>
                  <Col {...layout.col12}>
                    <Form.Item
                      name="contractNo"
                      label="合同编号"
                      {...layout.span8}
                      rules={[{ required: true, message: '请填写业务机构' }]}
                    >
                      <Select
                        {...layout.select}
                        onChange={(value: any) => {
                          console.log(value)
                          purchaseForm.setFieldsValue({
                            time: options?.purchaseContractNos[value].time ? moment(options?.purchaseContractNos[value].time).format('YYYY.MM.DD HH:mm:ss') : '',
                            contractName: options?.purchaseContractNos[value].contractName ||  '',
                            contractNo: options?.purchaseContractNos[value].contractNo ||  '',
                          })
                          MaterialService.getMaterialsList(options?.purchaseContractNos[value].contractNo ||  '')
                            .then((res: any) => {
                              console.log(res, '采购合同查询物资明细')
                              setMaterialList(res?.data || [])
                            })
                            .catch(() => {})
                          
                        }}
                      >
                        {options?.purchaseContractNos?.map((o: any, index: number) => {
                          return <Select.Option key={index}>{o.contractName}</Select.Option>
                        })}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col {...layout.col12}>
                    <Form.Item
                      name="time"
                      label="日期"
                      {...layout.span8}
                    >
                      <Input allowClear disabled />
                    </Form.Item>
                  </Col>
                  <Col {...layout.col12}>
                    <Form.Item
                      name="contractName"
                      label="合同名称"
                      {...layout.span8}
                    >
                      <Input allowClear disabled />
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
              <div style={{ paddingBottom: 30 }}>
                <MaterialDetailsMoadl />
              </div>
              <div style={{ textAlign: 'right' }}>
                <Space>
                  <Button className="actived" onClick={() => cancelPurchaseModel()}>
                    取消
                  </Button>
                  <Button type="primary" onClick={createScheme} loading={addLoading}>
                    提交
                  </Button>
                </Space>
              </div>
            </Modal>
          )
        }
      </div>
    </Form>
  )
}

export default () => {
  const nav = useNavigate()
  const formRef = React.useRef<ContractFormInstance>()
  const paramsData = useParams() // 链接参数
  console.log(paramsData)

  useEffect(() => {
    SideMenuObservable.next(false)
    BreadcrumbObservable.next([
      {
        name: '工程管理',
        url: '/engineering',
      },
      {
        name: '物资管理',
        url: '/engineering/material',
      },
      {
        name: paramsData?.materialId && paramsData?.materialId !== 'create' ? '编辑物资' : '新增物资',
        url: '/engineering/materialCreate',
      },
    ])
  }, [])

  const goBack = React.useCallback(() => {
    formRef.current?.reset()
    nav(-1)
  }, [])

  const cancel = React.useCallback(() => {
    formRef.current?.reset()
    nav(-1)
  }, [])

  return (
    <Card
      className="right-actions"
      title={<Typography.Title level={5}>新增物资</Typography.Title>}
      extra={
        <Space size={12}>
          <Button onClick={goBack}>返回</Button>
          <Button onClick={cancel}>取消</Button>
          <Button
            type="primary"
            onClick={() => {
              formRef.current?.submit(() => nav(-1))
            }}
          >
            提交
          </Button>
        </Space>
      }
      bodyStyle={{ height: `calc(100vh - 210px)`, padding: '0 0 30px 0' }}
    >
      <div style={{ padding: '16px 40px', height: '100%', overflow: 'auto' }}>
        <ContractForm type={paramsData.type} materialId={paramsData.materialId} componentRef={formRef} />
      </div>
    </Card>
  )
}
