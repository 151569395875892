import React, { useMemo, useState } from 'react'
import { Form, Input, Menu, message, Modal } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
// import { CustomIcons } from '../../../global'
import SubMenu from 'antd/lib/menu/SubMenu'
import { UserService } from '../../../apis/project-service-stash'
import Global from '../../../global'
// import Global from '../../../global'
import { useParams } from 'react-router'
import TenantControllerApi from '../../../services/api/TenantControllerApi'
import './index.less'
import { NAME_REG } from '../../../utils/constant'

export const GroupList = React.memo((props: any) => {
  const { inlineCollapsed } = props
  const [active, setActive] = useState<any>(null)
  const [tenantOwn, setTenantOwn] = useState<any>([])
  const navigate = useParams()
  const jumpTo = (url: any) => {
    if (url) {
      location.assign(`${url}`)
      Global.changeMenu.forEach((Fn: any) => {
        Fn(url)
      })
    }
  }
  const updateUser = (user: any) => {
    Global.updateUser.forEach((fn: any) => {
      fn(user)
    })
  }
  const [createLoading, setCreateLoading] = useState(false) // 创建组织加载状态
  const [createVisible, setCreateVisible] = useState(false) // 创建组织弹窗
  const [name, setValue] = React.useState('') // 创建输入组织名称
  const tenantChange = ({ id, isAdministrators }: any) => {
    TenantControllerApi.authTenantChangeGet({ tenantId: id, isAdministrators })
      .then((res: any) => {
        message.success('操作成功！')
        console.log('asd', res)
        if (res?.msg == 'success') {
          console.log('navigate', navigate)
          if (location.hash.includes('home')) location.reload()
          else
            UserService.info()
              .then((res) => {
                Global.user = res?.data || {}
                if (!res?.data?.emailCheck) {
                  Global.emailTipVisible = true
                }
                UserService.roleInfo().then((resp) => {
                  Global.role = resp.data
                  updateUser(Object.assign({}, Global.user))
                  jumpTo('#/home')
                  init()
                  getList()
                })
              })
              .catch(() => {
                Global.user = {}
              })
        }
      })
      .catch(() => {})
  }
  const getList = () => {
    TenantControllerApi.authTenantListGet({})
      .then((res) => {
        setTenantOwn(res?.data || [])
      })
      .catch(() => {})
  }
  const init = () => {
    TenantControllerApi.authTenantUserTenantGet()
      .then((res) => {
        // setActive(res?.data?.tenantId)
        setActive(res?.data)
      })
      .catch(() => {})
  }

  const getColorWithName = (name = '') => {
    const ICON_COLORS = [
      '#1EC692',
      '#3274F6',
      '#F97942',
      '#B532F6',
      '#F6B932',
      '#54A378',
      '#F56C6C',
      '#3EB1B7',
      '#8468B2',
      '#B9A43F',
    ]
    const num = name.split('').reduce((sum, i) => sum + i.charCodeAt(0), 0)
    const idx = Math.floor(num) % 10
    return { color: 'white', bgColor: ICON_COLORS[idx] }
  }

  const handleCancel = () => {
    setCreateVisible(false)
    setCreateLoading(false)
  }

  useMemo(() => {
    getList()
    init()
  }, [])

  return (
    <>
      <Menu
        className="klf-custom-menu"
        inlineCollapsed={inlineCollapsed}
        mode="vertical"
        selectedKeys={['user-group']}
        // openKeys={active}
        style={{
          height: '100%',
          borderRight: 0,
        }}
      >
        <SubMenu
          key="user-group"
          // className={inlineCollapsed ? 'inline-collapsed-setting setting-line' : 'setting-line'}
          style={{
            position: 'absolute',
            bottom: !inlineCollapsed ? '40px' : '80px',
            width: '100%',
            margin: 0,
            background: !inlineCollapsed ? 'white' : 'none',
          }}
          icon={
            <span
              onClick={getList}
              style={{
                padding: '2px',
                width: '30px',
                height: '30px',
                color: getColorWithName(active?.tenantName).color,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                background: getColorWithName(active?.tenantName).bgColor,
                fontSize: '18px',
                fontWeight: 'bolder',
                borderRadius: '8px',
                ...(inlineCollapsed ? { marginTop: '6px', marginLeft: '-8px' } : {}),
              }}
            >
              {active?.tenantName?.charAt(0)?.toUpperCase()}
            </span>
          }
          title={active?.tenantName || '组织列表'}
        >
          {tenantOwn?.length ? (
            tenantOwn?.map((res: any) => (
              <Menu.Item
                className={res?.id == active?.tenantId ? 'ant-menu-item-active' : ''}
                style={{ padding: '0 12px' }}
                key={res?.id}
                onClick={() => tenantChange(res)}
              >
                {res?.name}
              </Menu.Item>
            ))
          ) : (
            <Menu.Item>暂无数据</Menu.Item>
          )}
          <Menu.Item
            key={'org-down-item'}
            onClick={() => {
              setCreateVisible(true)
            }}
          >
            <div className="settings-create">
              <PlusOutlined style={{ fontSize: 20 }} />
              <span style={{ marginLeft: 5 }}>创建新组织</span>
            </div>
          </Menu.Item>
        </SubMenu>
      </Menu>

      {/* 创建弹窗 */}
      <Modal
        wrapClassName="user-modal-warp"
        title="创建组织"
        width={480}
        centered={true}
        maskClosable={false}
        destroyOnClose={true}
        visible={createVisible}
        confirmLoading={createLoading}
        onOk={() => {
          if (name === '') {
            message.warning('请输入组织名称')
            return
          }
          if (!NAME_REG.test(name)) {
            return
          }
          setCreateLoading(true)
          TenantControllerApi.authTenantAddPost({
            name,
          })
            .then((res) => {
              setCreateLoading(false)
              const { code, msg, data } = res || {}
              if (code === 200) {
                setCreateVisible(false)
                message.success('创建成功')
                tenantChange(data)
              } else {
                msg && message.error(msg)
              }
            })
            .catch(() => {
              setCreateLoading(false)
            })
        }}
        onCancel={handleCancel}
      >
        <Form>
          <Form.Item
            name="name"
            label="组织名称"
            rules={[
              { required: true, message: '请输入组织名称' },
              { pattern: NAME_REG, message: '汉字、英文大小写、数字、-和_' },
            ]}
          >
            <Input
              value={name}
              size="large"
              maxLength={32}
              showCount
              placeholder="请输入组织名称"
              onChange={(e) => {
                setValue(e.target.value)
              }}
            />
          </Form.Item>
        </Form>
      </Modal>
    </>
  )
})
