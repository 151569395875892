import React, { useImperativeHandle } from 'react'
import moment from 'moment'
import { cloneDeep } from 'lodash'
import { Form, Input, Typography, Row, Col, FormInstance, Spin } from 'antd'
import { CommonService, ProjectPartnerService, ResourceService, ResponseOptionType } from '../../../../apis/wl-service'
import { CustomFormItem, CustomFormTitle, formItemLayout } from '../../../../components/wl-form-item'
import { investTypeOptions, resourceElectricTypeOptions } from '../../common/constant'
import store, { SubmitType } from '../../../invest-dev/project-library-create/components/store'

export interface IResourceDevFormProps {
  id?: string
  editable?: boolean
}

const yesOrNoOptions: ResponseOptionType[] = [
  { fullName: '是', id: '1' },
  { fullName: '否', id: '0' },
]
export const RefForm = ({ id, editable = false }: IResourceDevFormProps, ref: any) => {
  const unmountRef = React.useRef<boolean>(false)
  const [form] = Form.useForm()
  const storeKey = React.useMemo<string>(() => (editable ? store.keyMap.resourceDev : ''), [store, editable]) // 用于本地数据存储的键值
  const [formData, setFormData] = React.useState<any>({}) // 存储表单数据
  const [loading, setLoading] = React.useState<boolean>(false) // 数据加载状态
  const [options, setOptions] = React.useState<{ [name: string]: any }>({
    resourceTypeOptions: [], // 项目类型
    partnerOptions: [], // 合作方
    project_developerOptions: [{ fullName: '其他', id: '7' }], // 开发主体
    developListOptions: [], // 项目列表
    developListMap: {}, // 项目列表键值对
  }) // 基础选择数据

  useImperativeHandle(ref, () => ({
    getName: () => storeKey,
    // 无草稿时，保存草稿，调用创建post接口 is_draft: 1,
    // 有草稿时，保存草稿，调用更新put接口 is_draft: 1
    // 确定 is_draft: 0
    submit: async (options: SubmitType) => {
      try {
        let values: any = await form.validateFields()
        values = formatter(values, 'params')
        store.submit[storeKey]?.({
          ...options,
          values,
        })
      } catch (err) {
        console.warn('submit form Validate Failed:', err)
      }
    },
  }))

  // 设置组件挂载状态
  React.useEffect(() => {
    unmountRef.current = false
    return () => {
      unmountRef.current = true
    }
  }, [])

  // 获取基础数据
  React.useEffect(() => {
    if (unmountRef.current) return

    // 编辑时从缓存中读取数据
    const prevOptions = editable && store.getOptions()

    // 项目类型
    prevOptions?.resourceTypeOptions
      ? setOptions((previous: any) => ({
          ...previous,
          resourceTypeOptions: prevOptions.resourceTypeOptions,
        }))
      : CommonService.getDictionaryDataSelectorValid('15301742e5ec4e92843192cb76010e08')
          .then((data: any) => {
            const resourceTypeOptions = data?.data?.list ?? []
            editable && store.setOptions({ resourceTypeOptions }, true)
            !unmountRef.current && setOptions((previous: any) => ({ ...previous, resourceTypeOptions }))
          })
          .catch((error: any) => {
            console.warn('项目类型获取失败：', error?.message)
          })

    // 开发主体
    prevOptions?.project_developerOptions
      ? setOptions((previous: any) => ({
          ...previous,
          project_developerOptions: prevOptions.project_developerOptions,
        }))
      : CommonService.getDictionaryData('cbe5eb037ffd4157abe128705205c654')
          .then((data: any) => {
            const project_developerOptions = data?.data?.list ?? []
            editable && store.setOptions({ project_developerOptions }, true)
            !unmountRef.current && setOptions((previous: any) => ({ ...previous, project_developerOptions }))
          })
          .catch((error: any) => {
            console.warn('开发主体获取失败：', error?.message)
          })

    if (editable) {
      // 获取项目名称
      prevOptions?.developListOptions
        ? setOptions((previous: any) => ({
            ...previous,
            developListOptions: prevOptions.developListOptions,
            developListMap: prevOptions.developListMap,
          }))
        : ResourceService.developList()
            .then((resp: any) => {
              const developListMap: any = {}
              const current = {
                developListOptions: (resp?.data ?? []).map((item: any) => {
                  developListMap[item.mark] = item
                  // id 是有资源的项目id
                  // mark 是项目的键值
                  return {
                    id: item.mark,
                    fullName: item.projectName ?? item.project_name,
                    // ...item,
                  }
                }),
                developListMap,
              }
              editable && store.setOptions(current, true)
              !unmountRef.current && setOptions((previous: any) => ({ ...previous, ...current }))
            })
            .catch((e) => {
              console.warn(`获取项目库项目列表失败：`, e)
            })

      // 合作方
      prevOptions?.partnerOptions
        ? setOptions((previous: any) => ({
            ...previous,
            partnerOptions: prevOptions.partnerOptions,
          }))
        : ProjectPartnerService.list()
            .then((data: any) => {
              const partnerOptions = (data?.data?.list ?? []).map(({ id, name }: any) => ({ id, fullName: name }))
              !unmountRef.current && setOptions((previous: any) => ({ ...previous, partnerOptions }))
              editable && store.setOptions({ partnerOptions }, true)
            })
            .catch((error: any) => {
              console.warn('合作方获取失败：', error?.message)
            })
    }
  }, [editable])

  // 监听projectId变化
  React.useEffect(() => {
    const fn = () => {
      detail(store.projectId)
    }
    store.subscribe(fn)
    return () => {
      store.unsubscribe(fn)
    }
  }, [])

  // 根据projectId获取详情
  React.useEffect(() => {
    detail(editable ? store.projectId : id)
  }, [id, editable, store.projectId])

  // type=params作为参数发送到服务端
  // type=component转换数据用于组件渲染
  const formatter = (values: any, type: 'params' | 'component') => {
    values = cloneDeep(values)

    // 转换时间值
    if (editable) {
      ;['resourceyear'].forEach((k: string) => {
        if (type === 'component') {
          values[k] = values[k] ? moment(values[k]) : null
        }
        if (type === 'params') {
          values[k] = values[k] ? moment(values[k]).format('YYYY') : ''
        }
      })
      ;['validity_start', 'validity_end'].forEach((k: string) => {
        if (type === 'component') {
          values[k] = values[k] ? moment(values[k]) : null
        }
        if (type === 'params') {
          values[k] = values[k] ? moment(values[k]).format('YYYY-MM-DD') : ''
        }
      })
    }

    if (type === 'params') {
      values.resourcedependency = Array.isArray(values.resourcedependency) ? values.resourcedependency.join(',') : ''
    }
    if (type === 'component' && editable && !Array.isArray(values.resourcedependency)) {
      values.resourcedependency = values.resourcedependency ? values.resourcedependency.split(',') : []
    }

    // 转换select
    ;['invest_type', 'ydys', 'hjyxpj', 'resource_electric_type', 'is_sign', 'is_scale', 'is_check'].forEach(
      (k: string) => {
        if (type === 'component' && typeof values[k] === 'number') {
          values[k] = values[k].toString()
        }
      }
    )

    // 转换文件
    ;['attachment', 'qt_file'].forEach((k: string) => {
      if (type === 'component') {
        values[k] = values[k] ? JSON.parse(values[k]) : []
      }
      if (type === 'params') {
        values[k] = values[k]?.length ? JSON.stringify(values[k]) : ''
      }
    })

    return values
  }

  // 根据projectId获取详情
  const detail = async (projectId?: string) => {
    if (unmountRef.current) return

    // 设置、存储表单数据
    const setValues = (values: any) => {
      try {
        if (editable) {
          // 设置form 表单中 的项目选中项
          values.projectId = store.projectId ?? ''
          store.setItem(storeKey, values)
        }

        values.project_id = values.projectId
        // 无project_id时，清空表单数据
        if (!values.project_id) {
          const prevValues = form.getFieldsValue()
          const nextValues = {}
          for (let k in prevValues) {
            if (k !== 'mark' && k !== 'project_id' && k !== 'project_name') {
              nextValues[k] = undefined
            } else {
              nextValues[k] = prevValues[k]
            }
          }
          values = nextValues
        }
        //   form.resetFields()
        form.setFieldsValue(formatter(values, 'component'))
        !unmountRef.current && setFormData(values)
      } catch (e: any) {
        console.warn(e)
      }
    }

    const prevDetailData = editable && store.getItem(storeKey)
    if (prevDetailData) {
      setValues(prevDetailData)
      return
    }

    try {
      setLoading(true)

      let resp: any = await (projectId ? ResourceService.info({ id: projectId ?? '' }) : Promise.resolve({}))
      setValues(resp?.data ?? {})
    } catch (e) {
      console.warn(`获取资源详情失败`, e)
    } finally {
      setLoading(false)
    }
  }

  return (
    <Spin spinning={loading}>
      <Form
        name="resourceDevForm"
        form={form}
        labelWrap
        {...formItemLayout.span8}
        autoComplete="off"
        initialValues={formData}
        onValuesChange={(changedValues, allValues) => {
          store.onValuesChange(storeKey, formatter(allValues, 'params'))
        }}
      >
        <Form.Item name="id" hidden>
          <Input />
        </Form.Item>

        <CustomFormTitle>基本信息</CustomFormTitle>
        <Row>
          <CustomFormItem
            label="项目名称"
            name="mark"
            span={3}
            type="select"
            options={options?.developListOptions}
            editable={editable}
            computed={false}
            value={formData.project_name}
            componentProps={{
              disabled:
                !!id || (editable && !!store.projectId && store.getModuleName() && store.getModuleName() !== storeKey), // 编辑状态下，项目名称不可修改
              onChange: (value: any) => {
                // 项目id变化
                const current = options?.developListMap?.[value]
                const projectId = current?.id ?? ''
                store.setProjectId(projectId, storeKey)

                form.setFieldsValue({
                  project_id: projectId,
                  project_name: current?.projectName ?? current?.project_name,
                })
              },
            }}
            rules={[
              {
                required: true,
                message: '请填写项目名称',
              },
            ]}
          />
          <Form.Item name="project_id" hidden>
            <Input />
          </Form.Item>

          <Form.Item hidden name="project_name">
            <Input />
          </Form.Item>

          <CustomFormItem
            label="项目类型"
            name="resourcetype"
            span={3}
            type="select"
            editable={editable}
            computed={true}
            options={options?.resourceTypeOptions}
            value={formData.resourcetype}
            componentProps={{}}
            rules={[
              {
                required: false,
                message: '请选择项目类型',
              },
            ]}
          />

          <CustomFormItem
            label="资源属地"
            name="resourcedependency"
            // dependencies={['resourcetype']}
            span={3}
            type="address_selector"
            editable={editable}
            value={formData.resourcedependencystr}
            componentProps={{
              level: 2,
            }}
            // rules={[
            //   {
            //     validator: async (rule, value) => {
            //       if (!value) {
            //         return Promise.resolve()
            //       }
            //       const { resourcetype } = form.getFieldsValue() ?? formData
            //       if (!resourcetype) {
            //         message.error('请先选择项目类型!')
            //         return false
            //       }
            //       if (value.length === 0) {
            //         return Promise.resolve()
            //       }
            //       try {
            //         const res = await ResourceService.getSameProject({
            //           id: id ?? '0',
            //           resourcetype,
            //           resourcedependency: value.join(','),
            //         })
            //         if (res.data?.length >= 1) {
            //           return Promise.reject('该资源已有集团投资主体，请联系集团')
            //         }
            //       } catch (err) {
            //         console.warn('getSameProject api error: ', err)
            //       }
            //       return Promise.resolve()
            //     },
            //   },
            // ]}
          />

          <CustomFormItem
            label="开发主体"
            name="developer"
            span={3}
            type="select"
            editable={editable}
            computed={true}
            options={options?.project_developerOptions}
            value={formData.developer}
            componentProps={{}}
            rules={[
              {
                required: false,
                message: '请选择开发主体',
              },
            ]}
          />

          <CustomFormItem
            label="合作方"
            name="partnerId"
            type="select"
            span={3}
            editable={editable}
            computed={false}
            options={options?.partnerOptions}
            value={formData.partnerName}
            componentProps={{
              onChange: (value: any, option: any) => {
                form.setFieldsValue({ partnerName: option?.children ?? '' })
              },
            }}
            rules={[
              {
                required: false,
                message: '请选择所合作方',
              },
            ]}
          >
            <Form.Item name="partnerName" hidden={true}>
              <Input />
            </Form.Item>
          </CustomFormItem>

          <CustomFormItem
            label="详细地址"
            name="address"
            span={1}
            type="textarea"
            editable={editable}
            value={formData.address}
            componentProps={{
              showCount: true,
              maxLength: 50,
            }}
          />

          <CustomFormItem
            label="投资模式"
            name="invest_type"
            span={3}
            type="select"
            editable={editable}
            computed={true}
            options={investTypeOptions}
            value={formData.invest_type}
            componentProps={{}}
            rules={[
              {
                required: false,
                message: '请选择投资模式',
              },
            ]}
          />

          <CustomFormItem
            label="是否完成用地预审"
            name="ydys"
            span={3}
            type="select"
            editable={editable}
            computed={true}
            options={yesOrNoOptions}
            value={formData.ydys}
            componentProps={{}}
          />

          <CustomFormItem
            label="是否完成环境影响评价"
            name="hjyxpj"
            span={3}
            type="select"
            editable={editable}
            computed={true}
            options={yesOrNoOptions}
            value={formData.hjyxpj}
            componentProps={{}}
          />

          <CustomFormItem
            label="年份"
            name="resourceyear"
            span={3}
            type="datepicker"
            editable={editable}
            value={formData.resourceyear}
            componentProps={{
              format: 'YYYY',
            }}
            rules={[
              {
                required: false,
                message: '请选择年份',
              },
            ]}
          />

          <CustomFormItem
            label="项目规模(MW)"
            name="project_module"
            span={3}
            type="number"
            editable={editable}
            value={formData.project_module}
            componentProps={{
              min: 0,
              max: 90000,
            }}
            rules={[
              {
                required: false,
                message: '请填写项目规模',
              },
            ]}
          />

          <Form.Item
            noStyle
            shouldUpdate={(prevValues, currentValues) => prevValues.resourcetype !== currentValues.resourcetype}
          >
            {({ getFieldValue }: FormInstance) => {
              return getFieldValue('resourcetype') == 4 ? (
                <CustomFormItem
                  label="光伏类型"
                  name="resource_electric_type"
                  span={3}
                  type="select"
                  editable={editable}
                  computed={true}
                  options={resourceElectricTypeOptions}
                  value={formData.resource_electric_type}
                  componentProps={{}}
                />
              ) : null
            }}
          </Form.Item>

          <CustomFormItem
            label="是否签署协议"
            name="is_sign"
            span={3}
            type="select"
            editable={editable}
            computed={true}
            options={yesOrNoOptions}
            value={formData.is_sign}
            componentProps={{}}
          />

          <Form.Item noStyle shouldUpdate={(prevValues, currentValues) => prevValues.is_sign !== currentValues.is_sign}>
            {({ getFieldValue }: FormInstance) => {
              return getFieldValue('is_sign') === '1' ? (
                <>
                  <CustomFormItem
                    label="协议签署主体"
                    name="agreement_subject"
                    span={3}
                    type="input"
                    editable={editable}
                    value={formData.agreement_subject}
                    componentProps={{}}
                  />

                  <CustomFormItem
                    label="协议有效期起"
                    name="validity_start"
                    span={3}
                    type="datepicker"
                    editable={editable}
                    value={formData.validity_start}
                    componentProps={{
                      format: 'YYYY-MM-DD',
                      disabledDate: (current: any) => {
                        const end = form.getFieldValue('validity_end')
                        return current && end && current > end.endOf('day')
                      },
                    }}
                  />

                  <CustomFormItem
                    label="协议有效期止"
                    name="validity_end"
                    span={3}
                    type="datepicker"
                    editable={editable}
                    value={formData.validity_end}
                    componentProps={{
                      format: 'YYYY-MM-DD',
                      disabledDate: (current: any) => {
                        const start = form.getFieldValue('validity_start')
                        return current && start && current < start.endOf('day')
                      },
                    }}
                  />
                </>
              ) : null
            }}
          </Form.Item>

          <CustomFormItem
            label="是否已纳入地方指标规模"
            name="is_scale"
            span={3}
            type="select"
            editable={editable}
            computed={true}
            options={yesOrNoOptions}
            value={formData.is_scale}
            componentProps={{}}
          />

          <CustomFormItem
            label="是否已开展颠覆性制约因素排查"
            name="is_check"
            span={3}
            type="select"
            editable={editable}
            computed={true}
            options={yesOrNoOptions}
            value={formData.is_check}
            componentProps={{}}
          />

          <Col xs={24}>
            {editable ? (
              <Form.Item label="项目内容" name="xmrn" {...formItemLayout.span24}>
                <Input.TextArea showCount maxLength={200} placeholder="请输入" autoSize={{ minRows: 4 }} />
              </Form.Item>
            ) : (
              <Form.Item {...formItemLayout.span24}>
                <Typography.Title level={5}>项目内容</Typography.Title>
                <Typography.Paragraph>{formData.xmrn || '暂无项目内容'}</Typography.Paragraph>
              </Form.Item>
            )}
          </Col>

          <Col xs={24}>
            {editable ? (
              <Form.Item label="备注" name="memo" {...formItemLayout.span24}>
                <Input.TextArea showCount maxLength={200} placeholder="请输入" autoSize={{ minRows: 4 }} />
              </Form.Item>
            ) : (
              <Form.Item {...formItemLayout.span24}>
                <Typography.Title level={5}>备注</Typography.Title>
                <Typography.Paragraph>{formData.memo || '暂无备注'}</Typography.Paragraph>
              </Form.Item>
            )}
          </Col>

          <CustomFormItem
            label="协议附件"
            name="attachment"
            span={2}
            type="file"
            editable={editable}
            value={formData.attachment}
            componentProps={{}}
          />

          <CustomFormItem
            label="其他附件"
            name="qt_file"
            span={2}
            type="file"
            editable={editable}
            value={formData.qt_file}
            componentProps={{}}
          />
        </Row>
      </Form>
    </Spin>
  )
}

export default React.forwardRef(RefForm)
