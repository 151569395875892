import { Table, Form, Input, Button, Tooltip, Popover, Menu, Modal, Image, List, message } from 'antd'
import React, { useEffect, useState } from 'react'
import { LoadingOutlined } from '@ant-design/icons'

import styles from '../index.module.scss'
import { ProjectWorkbenchService, TechnologyService } from '../../../../../apis/project-service-history'
import { useNavigate } from 'react-router-dom'
import { BreadcrumbObservable, SideMenuObservable } from '../../../../../application'
import { CustomIcons, getFixedNumber } from '../../../../../global'
import { NAME_EXP } from '../../../../../utils/constant'
import ManageMembersModal from '../../components/ManageMembersModal'

interface Iprops {
  item: any
  timeSort: string[]
  hasAuth?: boolean
  create?: () => void
  onChange?: () => void
  cannotCalc?: boolean
}

const layout = {
  labelCol: { span: 9 },
  wrapperCol: { span: 15 },
}

const cellWidth = 90

let query: any = {
  page: 1,
  size: 500,
  total: 0,
  markedSort: '0',
  lockedSort: '0',
  pvChange: false,
  energyChange: false,
  marked: undefined,
  locked: undefined,
  installedCapacityMin: undefined,
  installedCapacityMax: undefined,
  dayAvgPowerGenerationMin: undefined,
  dayAvgPowerGenerationMax: undefined,
  useHourYearMin: undefined,
  useHourYearMax: undefined,
  selfAbsorptionRateMin: undefined,
  selfAbsorptionRateMax: undefined,
  annualGenerationHoursMin: undefined,
  annualGenerationHoursMax: undefined,
  batteryCapacityMin: undefined,
  batteryCapacityMax: undefined,
  pcsPowerMin: undefined,
  pcsPowerMax: undefined,
  dayAvgDischargeMin: undefined,
  dayAvgDischargeMax: undefined,
  dayAvgChargeDischargeMin: undefined,
  dayAvgChargeDischargeMax: undefined,

  createTimeSort: '0',
  updateTimeSort: '0',
  installedCapacitySort: '0',
  dayAvgPowerGenerationSort: '0',
  useHourYearSort: '0',
  selfAbsorptionRateSort: '0',
  annualGenerationHoursSort: '0',
  batteryCapacitySort: '0',
  pcsPowerSort: '0',
  dayAvgDischargeSort: '0',
  dayAvgChargeDischargeSort: '0',
}

const hasQuery = () => {
  const {
    installedCapacityMin,
    installedCapacityMax,
    dayAvgPowerGenerationMin,
    dayAvgPowerGenerationMax,
    useHourYearMin,
    useHourYearMax,
    selfAbsorptionRateMin,
    selfAbsorptionRateMax,
    annualGenerationHoursMin,
    annualGenerationHoursMax,
    batteryCapacityMin,
    batteryCapacityMax,
    pcsPowerMin,
    pcsPowerMax,
    dayAvgDischargeMin,
    dayAvgDischargeMax,
    dayAvgChargeDischargeMin,
    dayAvgChargeDischargeMax,
  } = query

  const obj = {
    installedCapacityMin,
    installedCapacityMax,
    dayAvgPowerGenerationMin,
    dayAvgPowerGenerationMax,
    useHourYearMin,
    useHourYearMax,
    selfAbsorptionRateMin,
    selfAbsorptionRateMax,
    annualGenerationHoursMin,
    annualGenerationHoursMax,
    batteryCapacityMin,
    batteryCapacityMax,
    pcsPowerMin,
    pcsPowerMax,
    dayAvgDischargeMin,
    dayAvgDischargeMax,
    dayAvgChargeDischargeMin,
    dayAvgChargeDischargeMax,
  }

  return Object.values(obj).filter((o) => o !== undefined).length > 0
}

const formatRes = (res?: string | number, unit?: string, show: boolean = false) => {
  if (!show) {
    return ''
  }
  if (res || res === 0) {
    return (
      <span
        style={{
          whiteSpace: 'nowrap',
        }}
      >
        {`${getFixedNumber(parseFloat(res.toString()), 2)} ${unit}`}
      </span>
    )
  } else {
    return '--'
  }
}

const queryArr: number[] = []
let queryInterval: any

// 技术方案列表
const TechnologyList = (props: Iprops) => {
  const { item, hasAuth, onChange } = props
  const nav = useNavigate()

  const [dataSource, setDataSource] = useState<any[]>([])
  const [firstLoading, setFirstLoading] = useState(true)
  const [loading, setLoading] = useState(false)
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [rowObj, setRowObj] = useState({})

  const [pvForm] = Form.useForm()
  const [energyForm] = Form.useForm()

  useEffect(() => {
    query = {
      page: 1,
      size: 500,
      total: 0,
      markedSort: '0',
      lockedSort: '0',
      pvChange: false,
      energyChange: false,
      marked: undefined,
      locked: undefined,
      installedCapacityMin: undefined,
      installedCapacityMax: undefined,
      dayAvgPowerGenerationMin: undefined,
      dayAvgPowerGenerationMax: undefined,
      useHourYearMin: undefined,
      useHourYearMax: undefined,
      selfAbsorptionRateMin: undefined,
      selfAbsorptionRateMax: undefined,
      annualGenerationHoursMin: undefined,
      annualGenerationHoursMax: undefined,
      batteryCapacityMin: undefined,
      batteryCapacityMax: undefined,
      pcsPowerMin: undefined,
      pcsPowerMax: undefined,
      dayAvgDischargeMin: undefined,
      dayAvgDischargeMax: undefined,
      dayAvgChargeDischargeMin: undefined,
      dayAvgChargeDischargeMax: undefined,

      createTimeSort: '0',
      updateTimeSort: '0',
      installedCapacitySort: '0',
      dayAvgPowerGenerationSort: '0',
      useHourYearSort: '0',
      selfAbsorptionRateSort: '0',
      annualGenerationHoursSort: '0',
      batteryCapacitySort: '0',
      pcsPowerSort: '0',
      dayAvgDischargeSort: '0',
      dayAvgChargeDischargeSort: '0',
    }

    if (!queryInterval) {
      queryInterval = setInterval(() => {
        const arr = queryArr.splice(0, queryArr.length)
        arr.forEach((o) => {
          TechnologyService.technical1({
            technicalId: o.toString(),
          }).then((resp) => {
            if (resp.data.calcStatus !== 1) {
              setDataSource((dataSource) => {
                const idx = dataSource.findIndex((data) => data.id === o)
                if (idx >= 0) {
                  dataSource[idx] = resp.data
                }
                return [...dataSource]
              })
            }
            if (resp.data.calcStatus === 1) {
              queryArr.push(o)
            }
          })
        })
      }, 5000)
    }
    return () => {
      clearInterval(queryInterval)
      queryInterval = undefined
      queryArr.length = 0
    }
  }, [])

  useEffect(() => {
    if (dataSource) {
      queryArr.push(...dataSource.filter((o) => o.calcStatus === 1).map((o) => o.id))
    }
  }, [dataSource])

  useEffect(() => {
    query.createTimeSort = '0'
    query.updateTimeSort = '0'
    query.markedSort = '0'
    query.lockedSort = '0'
    if (props.timeSort.length === 2) {
      query[props.timeSort[0]] = props.timeSort[1]
    } else if (props.timeSort.length === 1) {
      if (props.timeSort[0] === 'mark') {
        query.markedSort = '2'
      } else if (props.timeSort[0] === 'lock') {
        query.lockedSort = '2'
      }
    }
    queryList()

    BreadcrumbObservable.next([
      {
        name: '项目管理',
        url: '/program/manage',
      },
      {
        name: item.name,
        url: `/program/detail/${item.id}`,
      },
    ])
  }, [props.timeSort])

  const showModal = (obj: any) => {
    setRowObj(obj)
    setIsModalVisible(true)
  }
  const energyFilter = (values: any, confirm: Function) => {
    query.energyChange = true
    Object.keys(values).forEach((key) => {
      const val = values[key]
      query[key] = val
    })
    confirm()
  }

  const pvFilter = (values: any, confirm: Function) => {
    query.pvChange = true
    Object.keys(values).forEach((key) => {
      const val = values[key]
      query[key] = val
    })
    confirm()
  }

  useEffect(() => {
    SideMenuObservable.next(true)
  }, [])

  // 光伏指标筛选
  const PVFilterDom = ({ confirm, visible }: any) => {
    if (!visible) {
      ;(window as any).closeDropdown = null
      return null
    }
    setTimeout(() => {
      ;(window as any).closeDropdown = confirm
    })
    return (
      <div className={styles.customFilter}>
        <Form
          form={pvForm}
          onFinish={(values) => {
            pvFilter(values, confirm)
          }}
          {...layout}
          colon={false}
        >
          <Form.Item label="装机容量区间">
            <Input.Group compact>
              <Form.Item noStyle name="installedCapacityMin">
                <Input type="number" style={{ width: 101 }} placeholder="最小值" />
              </Form.Item>
              <Input
                className="site-input-split"
                style={{
                  width: 30,
                  borderLeft: 0,
                  borderRight: 0,
                  pointerEvents: 'none',
                }}
                placeholder="-"
                disabled
              />
              <Form.Item noStyle name="installedCapacityMax">
                <Input type="number" style={{ width: 130 }} placeholder="最大值" suffix="kW" />
              </Form.Item>
            </Input.Group>
          </Form.Item>
          <Form.Item label="日均发电区间">
            <Input.Group compact>
              <Form.Item noStyle name="dayAvgPowerGenerationMin">
                <Input type="number" style={{ width: 101 }} placeholder="最小值" />
              </Form.Item>
              <Input
                className="site-input-split"
                style={{
                  width: 30,
                  borderLeft: 0,
                  borderRight: 0,
                  pointerEvents: 'none',
                }}
                placeholder="-"
                disabled
              />
              <Form.Item noStyle name="dayAvgPowerGenerationMax">
                <Input type="number" style={{ width: 130 }} placeholder="最大值" suffix="kWh" />
              </Form.Item>
            </Input.Group>
          </Form.Item>
          <Form.Item label="年利用小时数区间">
            <Input.Group compact>
              <Form.Item noStyle name="useHourYearMin">
                <Input type="number" style={{ width: 101 }} placeholder="最小值" />
              </Form.Item>
              <Input
                className="site-input-split"
                style={{
                  width: 30,
                  borderLeft: 0,
                  borderRight: 0,
                  pointerEvents: 'none',
                }}
                placeholder="-"
                disabled
              />
              <Form.Item noStyle name="useHourYearMax">
                <Input type="number" style={{ width: 130 }} placeholder="最大值" suffix="h" />
              </Form.Item>
            </Input.Group>
          </Form.Item>
          <Form.Item label="自消纳比区间">
            <Input.Group compact>
              <Form.Item noStyle name="selfAbsorptionRateMin">
                <Input type="number" style={{ width: 101 }} placeholder="最小值" />
              </Form.Item>
              <Input
                className="site-input-split"
                style={{
                  width: 30,
                  borderLeft: 0,
                  borderRight: 0,
                  pointerEvents: 'none',
                }}
                placeholder="-"
                disabled
              />
              <Form.Item noStyle name="selfAbsorptionRateMax">
                <Input type="number" style={{ width: 130 }} placeholder="最大值" suffix="%" />
              </Form.Item>
            </Input.Group>
          </Form.Item>
          <Form.Item label="年发电小时数">
            <Input.Group compact>
              <Form.Item noStyle name="annualGenerationHoursMin">
                <Input type="number" style={{ width: 101 }} placeholder="最小值" />
              </Form.Item>
              <Input
                className="site-input-split"
                style={{
                  width: 30,
                  borderLeft: 0,
                  borderRight: 0,
                  pointerEvents: 'none',
                }}
                placeholder="-"
                disabled
              />
              <Form.Item noStyle name="annualGenerationHoursMax">
                <Input type="number" style={{ width: 130 }} placeholder="最大值" suffix="H" />
              </Form.Item>
            </Input.Group>
          </Form.Item>
          <Form.Item label=" " className="filter-btns">
            <div
              style={{
                marginTop: '.5rem',
                textAlign: 'right',
              }}
            >
              <Button
                onClick={() => {
                  pvForm.resetFields()
                }}
              >
                重置
              </Button>
              <Button
                type="primary"
                style={{
                  marginLeft: '.5rem',
                }}
                htmlType="submit"
              >
                确定
              </Button>
            </div>
          </Form.Item>
        </Form>
      </div>
    )
  }

  // 储能指标筛选
  const energyFilterDom = ({ confirm, visible }: any) => {
    if (!visible) {
      ;(window as any).closeDropdown = null
      return null
    }
    setTimeout(() => {
      ;(window as any).closeDropdown = confirm
    })
    return (
      <div className={styles.customFilter}>
        <Form
          form={energyForm}
          onFinish={(values) => {
            energyFilter(values, confirm)
          }}
          {...layout}
          colon={false}
        >
          <Form.Item label="电池容量区间">
            <Input.Group compact>
              <Form.Item noStyle name="batteryCapacityMin">
                <Input type="number" style={{ width: 101 }} placeholder="最小值" />
              </Form.Item>
              <Input
                className="site-input-split"
                style={{
                  width: 30,
                  borderLeft: 0,
                  borderRight: 0,
                  pointerEvents: 'none',
                }}
                placeholder="-"
                disabled
              />
              <Form.Item noStyle name="batteryCapacityMax">
                <Input type="number" style={{ width: 130 }} placeholder="最大值" suffix="kWh" />
              </Form.Item>
            </Input.Group>
          </Form.Item>
          <Form.Item label="PCS功率区间">
            <Input.Group compact>
              <Form.Item noStyle name="pcsPowerMin">
                <Input type="number" style={{ width: 101 }} placeholder="最小值" />
              </Form.Item>
              <Input
                className="site-input-split"
                style={{
                  width: 30,
                  borderLeft: 0,
                  borderRight: 0,
                  pointerEvents: 'none',
                }}
                placeholder="-"
                disabled
              />
              <Form.Item noStyle name="pcsPowerMax">
                <Input type="number" style={{ width: 130 }} placeholder="最大值" suffix="kW" />
              </Form.Item>
            </Input.Group>
          </Form.Item>
          <Form.Item label="日均放电区间">
            <Input.Group compact>
              <Form.Item noStyle name="dayAvgDischargeMin">
                <Input type="number" style={{ width: 101 }} placeholder="最小值" />
              </Form.Item>
              <Input
                className="site-input-split"
                style={{
                  width: 30,
                  borderLeft: 0,
                  borderRight: 0,
                  pointerEvents: 'none',
                }}
                placeholder="-"
                disabled
              />
              <Form.Item noStyle name="dayAvgDischargeMax">
                <Input type="number" style={{ width: 130 }} placeholder="最大值" suffix="kWh" />
              </Form.Item>
            </Input.Group>
          </Form.Item>
          <Form.Item label="日均充放电区间">
            <Input.Group compact>
              <Form.Item noStyle name="dayAvgChargeDischargeMin">
                <Input type="number" style={{ width: 101 }} placeholder="最小值" />
              </Form.Item>
              <Input
                className="site-input-split"
                style={{
                  width: 30,
                  borderLeft: 0,
                  borderRight: 0,
                  pointerEvents: 'none',
                }}
                placeholder="-"
                disabled
              />
              <Form.Item noStyle name="dayAvgChargeDischargeMax">
                <Input type="number" style={{ width: 130 }} placeholder="最大值" suffix="次" />
              </Form.Item>
            </Input.Group>
          </Form.Item>
          <Form.Item label=" " className="filter-btns">
            <div
              style={{
                marginTop: '.5rem',
                textAlign: 'right',
              }}
            >
              <Button
                onClick={() => {
                  energyForm.resetFields()
                }}
              >
                重置
              </Button>
              <Button
                type="primary"
                style={{
                  marginLeft: '.5rem',
                }}
                htmlType="submit"
              >
                确定
              </Button>
            </div>
          </Form.Item>
        </Form>
      </div>
    )
  }

  // 列表操作列
  const listOperaColumn = (text: string, row: any) => {
    const { currOperationAuth } = row
    const currOperationAuthObj =
      (currOperationAuth?.length &&
        currOperationAuth?.reduce((a: any, b: any, i: any) => ({
          ...(i == 1 ? { [a]: a } : a),
          [b]: b,
        }))) ||
      {}

    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
        }}
        className={styles.operaBtns}
        key={`ope-${row.id}`}
      >
        <Popover
          overlayClassName="menu-popover-overlay"
          content={
            <Menu
              mode="inline"
              style={{
                borderRight: 'none',
                paddingTop: '.5rem',
                paddingBottom: '.5rem',
              }}
              selectable={false}
            >
              <Menu.Item
                style={{
                  height: 30,
                  display: 'none',
                }}
                disabled
                onClick={() => {
                  showReport(row)
                }}
              >
                查看报告
              </Menu.Item>
              <Menu.Item
                style={{
                  height: 30,
                }}
                disabled={!currOperationAuthObj['project_technical_proposal_copy']}
                onClick={() => {
                  onCopy(row.id)
                }}
              >
                拷贝
              </Menu.Item>
              <Menu.Item
                style={{
                  height: 30,
                }}
                disabled={!currOperationAuthObj['project_technical_proposal_operation']}
                onClick={() => {
                  Modal.destroyAll()
                  Modal.confirm({
                    keyboard: false,
                    title: '删除方案',
                    icon: <CustomIcons type="klf-alarm1" />,
                    content: (
                      <>
                        <div>
                          确认删除{row.name}？删除后将无法恢复
                          {row.relationFinanceList?.length > 0 && <br />}
                          {row.relationFinanceList?.length > 0 && '下列关联财务方案将被删除'}
                        </div>
                        {row.relationFinanceList?.length > 0 && (
                          <List
                            style={{
                              maxHeight: 300,
                              overflowY: 'auto',
                              paddingRight: 5,
                            }}
                            grid={{ gutter: 10, column: 1 }}
                            dataSource={row.relationFinanceList}
                            size="small"
                            renderItem={(item: any) => (
                              <List.Item
                                style={{
                                  backgroundColor: 'rgba(249, 121, 66, 0.06)',
                                  color: 'rgba(249, 121, 66, 1)',
                                  height: 40,
                                  display: 'flex',
                                  flexDirection: 'row',
                                  justifyContent: 'space-between',
                                  alignItems: 'center',
                                }}
                              >
                                <span>{item.name}</span>
                                {item.locked && (
                                  <span style={{ fontSize: 16 }}>
                                    <CustomIcons type="klf-file-alert" />
                                  </span>
                                )}
                              </List.Item>
                            )}
                          />
                        )}
                      </>
                    ),
                    onOk: () => {
                      onDelete(row.id)
                    },
                    okButtonProps: {
                      danger: true,
                    },
                  })
                }}
              >
                删除
              </Menu.Item>
              <Menu.Item
                style={{
                  height: 30,
                }}
                // disabled={!hasAuth}
                disabled={!currOperationAuthObj['project_technical_proposal_helper']}
                onClick={() => {
                  showModal(row)
                }}
              >
                管理协作者
              </Menu.Item>
            </Menu>
          }
        >
          <Button type="link" icon={<CustomIcons type="klf-more" />} />
        </Popover>
        {row.marked === true ? (
          <Tooltip title="取消星标">
            <Button
              disabled={!currOperationAuthObj['project_technical_proposal_operation']}
              onClick={() => {
                collectionItem(row.id, false)
              }}
              type="link"
              icon={<CustomIcons type="klf-mark" />}
            />
          </Tooltip>
        ) : (
          <Tooltip title="设为星标">
            <Button
              onClick={() => {
                collectionItem(row.id, true)
              }}
              type="link"
              disabled={!currOperationAuthObj['project_technical_proposal_operation']}
              icon={<CustomIcons type="klf-non-mark" />}
            />
          </Tooltip>
        )}
        {row.calcStatus === 2 && (
          <Tooltip title={row.locked ? '解除归档' : '归档'}>
            <Button
              type="link"
              disabled={!currOperationAuthObj['project_technical_proposal_operation']}
              // disabled={!hasAuth}
              onClick={() => {
                if (!row.locked) {
                  Modal.confirm({
                    keyboard: false,
                    title: '归档',
                    icon: <CustomIcons type="klf-alarm" />,
                    content: `确认归档${row.name}？归档后，方案将不可修改`,
                    onOk: () => {
                      placeFileItem(row.id, !row.locked)
                    },
                    cancelButtonProps: {
                      type: 'primary',
                      ghost: true,
                    },
                  })
                } else {
                  Modal.confirm({
                    keyboard: false,
                    title: '解除归档',
                    icon: <CustomIcons type="klf-alarm1" />,
                    content: (
                      <>
                        <p>
                          {`确认解除归档${row.name}？方案解除归档后将可再次编辑`}
                          <br />
                          {row.relationFinanceList?.length > 0 && '下列关联财务方案将被删除'}
                        </p>
                        {row.relationFinanceList?.length > 0 && (
                          <List
                            style={{
                              maxHeight: 300,
                              overflowY: 'auto',
                              paddingRight: 5,
                            }}
                            grid={{ gutter: 10, column: 1 }}
                            dataSource={row.relationFinanceList}
                            size="small"
                            renderItem={(item: any) => (
                              <List.Item
                                style={{
                                  backgroundColor: 'rgba(249, 121, 66, 0.06)',
                                  color: 'rgba(249, 121, 66, 1)',
                                  height: 40,
                                  display: 'flex',
                                  flexDirection: 'row',
                                  justifyContent: 'space-between',
                                  alignItems: 'center',
                                }}
                              >
                                <span>{item.name}</span>
                                {item.locked && (
                                  <span style={{ fontSize: 16 }}>
                                    <CustomIcons type="klf-file-alert" />
                                  </span>
                                )}
                              </List.Item>
                            )}
                          />
                        )}
                      </>
                    ),
                    onOk: () => {
                      placeFileItem(row.id, !row.locked)
                    },
                    okButtonProps: {
                      danger: true,
                    },
                    cancelButtonProps: {
                      danger: true,
                      ghost: true,
                    },
                  })
                }
              }}
              icon={<CustomIcons type={row.locked ? 'klf-file' : 'klf-file-line'} />}
            />
          </Tooltip>
        )}

        {(row.calcStatus === 0 || row.calcStatus === 3 || row.calcStatus === 4) && (
          <Tooltip title={row.calcStatus === 0 ? '一键计算' : '重新计算'}>
            <Button
              type="link"
              // disabled={!hasAuth}
              disabled={!currOperationAuthObj['project_technical_proposal_operation']}
              onClick={() => {
                if (props.cannotCalc) {
                  Modal.confirm({
                    keyboard: false,
                    title: '提示',
                    icon: <CustomIcons type="klf-alarm1" />,
                    content: `请先设置时间范围与精度`,
                    cancelButtonProps: {
                      style: {
                        display: 'none',
                      },
                    },
                  })
                } else {
                  if (row.calcStatus === 2 || row.calcStatus === 4) {
                    Modal.confirm({
                      keyboard: false,
                      title: '重新计算',
                      icon: <CustomIcons type="klf-alarm1" />,
                      content: `确认重新计算${row.name}？原计算结果将覆盖`,
                      onOk: () => {
                        refreshItem(row)
                      },
                      cancelButtonProps: {
                        type: 'primary',
                        ghost: true,
                      },
                    })
                  } else {
                    refreshItem(row)
                  }
                }
              }}
              icon={<CustomIcons type="klf-recalculate1" />}
            />
          </Tooltip>
        )}
      </div>
    )
  }

  // 过滤  排序
  const changeTable = (pager: any, filter: any, sorter: any) => {
    const { order, field } = sorter
    let ope = '0'
    if (order === 'ascend') {
      ope = '1'
    }
    if (order === 'descend') {
      ope = '2'
    }

    query.createTimeSort = '0'
    query.updateTimeSort = '0'
    query.installedCapacitySort = '0'
    query.dayAvgPowerGenerationSort = '0'
    query.useHourYearSort = '0'
    query.selfAbsorptionRateSort = '0'
    query.annualGenerationHoursSort = '0'
    query.batteryCapacitySort = '0'
    query.pcsPowerSort = '0'
    query.dayAvgDischargeSort = '0'
    query.dayAvgChargeDischargeSort = '0'
    console.debug(`${field}Sort`)
    query[`${field}Sort`] = ope

    queryList()
  }

  // 收藏/标星
  const collectionItem = (id: string, is_collection: boolean) => {
    TechnologyService.mark({
      technicalId: id,
      root: {
        type: is_collection ? '1' : '0',
      },
    }).then(() => {
      queryList()
    })
  }

  // 查看报告
  const showReport = (item: any) => {
    alert(JSON.stringify(item))
  }

  const onCopy = (id: string) => {
    TechnologyService.copy({
      technicalId: id,
    })
      .then(() => {
        queryList()
      })
      .catch(() => {})
  }

  const onDelete = (id: string) => {
    TechnologyService.technical({
      technicalId: id,
    })
      .then(() => {
        queryList()
        onChange?.()
      })
      .catch(() => {})
  }

  // 刷新状态
  const refreshItem = (item: any) => {
    ProjectWorkbenchService.calcTechnical({
      technologyId: item.id,
    })
      .then(() => {
        queryList()
      })
      .catch(() => {})
  }

  // 归档/取消归档
  const placeFileItem = (id: string, is_placefile: boolean) => {
    TechnologyService.lock({
      technicalId: id,
      root: {
        type: is_placefile ? '1' : '0',
      },
    }).then(() => {
      queryList()
    })
  }

  // 编辑方案名称
  const editItemName = (item: any, index: number) => {
    const id = `name-${item.id}`
    setTimeout(() => {
      const element = document.getElementById(id)
      element?.focus()
    })
    const list = dataSource.slice()
    list[index] = {
      ...item,
      edit_name: true,
    }
    setDataSource(list)
  }

  const queryList = () => {
    setLoading(true)
    queryArr.length = 0
    console.log(query, 6666)
    const {
      page,
      size,
      createTimeSort,
      updateTimeSort,
      marked,
      locked,
      installedCapacityMin,
      installedCapacityMax,
      dayAvgPowerGenerationMin,
      dayAvgPowerGenerationMax,
      useHourYearMin,
      useHourYearMax,
      selfAbsorptionRateMin,
      selfAbsorptionRateMax,
      annualGenerationHoursMin,
      annualGenerationHoursMax,
      batteryCapacityMin,
      batteryCapacityMax,
      pcsPowerMin,
      pcsPowerMax,
      dayAvgDischargeMin,
      dayAvgDischargeMax,
      dayAvgChargeDischargeMin,
      dayAvgChargeDischargeMax,

      installedCapacitySort,
      dayAvgPowerGenerationSort,
      useHourYearSort,
      selfAbsorptionRateSort,
      annualGenerationHoursSort,
      batteryCapacitySort,
      pcsPowerSort,
      dayAvgDischargeSort,
      dayAvgChargeDischargeSort,
      markedSort,
      lockedSort,
    } = query

    TechnologyService.list({
      projectId: item.id,
      page: page.toString(),
      size: size.toString(),
      createTimeSort,
      updateTimeSort,
      marked,
      locked,
      markedSort,
      lockedSort,
      installedCapacityMin,
      installedCapacityMax,
      dayAvgPowerGenerationMin,
      dayAvgPowerGenerationMax,
      useHourYearMin,
      useHourYearMax,
      selfAbsorptionRateMin,
      selfAbsorptionRateMax,
      annualGenerationHoursMin,
      annualGenerationHoursMax,
      batteryCapacityMin,
      batteryCapacityMax,
      pcsPowerMin,
      pcsPowerMax,
      dayAvgDischargeMin,
      dayAvgDischargeMax,
      dayAvgChargeDischargeMin,
      dayAvgChargeDischargeMax,

      installedCapacitySort,
      dayAvgPowerGenerationSort,
      useHourYearSort,
      selfAbsorptionRateSort,
      annualGenerationHoursSort,
      batteryCapacitySort,
      pcsPowerSort,
      dayAvgDischargeSort,
      dayAvgChargeDischargeSort,
    })
      .then((resp) => {
        const list = resp.data.data
        query.page = resp.data.page
        query.total = resp.data.total
        query.size = resp.data.size
        setDataSource([...list])
      })
      .catch(() => {})
      .finally(() => {
        setLoading(false)
        setFirstLoading(false)
      })
  }

  const hasBatteryValue = () => {
    const {
      batteryCapacityMin,
      batteryCapacityMax,
      pcsPowerMin,
      pcsPowerMax,
      dayAvgDischargeMin,
      dayAvgDischargeMax,
      dayAvgChargeDischargeMin,
      dayAvgChargeDischargeMax,
    } = query
    const obj = {
      batteryCapacityMin,
      batteryCapacityMax,
      pcsPowerMin,
      pcsPowerMax,
      dayAvgDischargeMin,
      dayAvgDischargeMax,
      dayAvgChargeDischargeMin,
      dayAvgChargeDischargeMax,
    }

    return Object.values(obj).filter(Boolean).length > 0
  }

  const hasPvValue = () => {
    const {
      installedCapacityMin,
      installedCapacityMax,
      dayAvgPowerGenerationMin,
      dayAvgPowerGenerationMax,
      useHourYearMin,
      useHourYearMax,
      selfAbsorptionRateMin,
      selfAbsorptionRateMax,
      annualGenerationHoursMin,
      annualGenerationHoursMax,
    } = query

    const obj = {
      installedCapacityMin,
      installedCapacityMax,
      dayAvgPowerGenerationMin,
      dayAvgPowerGenerationMax,
      useHourYearMin,
      useHourYearMax,
      selfAbsorptionRateMin,
      selfAbsorptionRateMax,
      annualGenerationHoursMin,
      annualGenerationHoursMax,
    }

    return Object.values(obj).filter(Boolean).length > 0
  }

  const columns = [
    {
      title: '方案名称',
      dataIndex: 'name',
      key: 'name',
      width: 150,
      fixed: 'left' as any,
      render: (text: string, row: any, index: number) => {
        return (
          <Tooltip title={text} key={`name-${row.id}`}>
            {row.edit_name ? (
              <Input
                allowClear
                id={`name-${row.id}`}
                maxLength={16}
                defaultValue={text}
                onBlur={(val) => {
                  if (!NAME_EXP.test(val.target.value)) {
                    const dom = document.getElementById(`name-${row.id}`)
                    message.error('"1-16个字符,中文、英文、_、数字和-组成"')
                    dom?.focus()
                    return
                  }
                  TechnologyService.rename({
                    technicalId: row.id,
                    root: {
                      name: val.target.value,
                    },
                  })
                    .then(() => {
                      queryList()
                    })
                    .catch(() => {})
                }}
              />
            ) : (
              <div className={`${styles.listName} hover-outer`}>
                <Button
                  type="link"
                  className="name"
                  style={{
                    padding: 0,
                  }}
                  disabled={
                    row.calcStatus === 1 ||
                    !(
                      row?.currOperationAuth?.includes('technical_proposal') ||
                      (!row?.currOperationAuth?.includes('technical_proposal') && row.calcStatus)
                    )
                  }
                  onClick={() => {
                    // if (row.calcStatus !== 1) {
                    nav(`/workspace/${row.id}/tech`)
                    // }
                  }}
                >
                  {text}
                </Button>
                {!row.locked && (
                  <Button
                    className="hover-inner"
                    type="text"
                    disabled={!row?.currOperationAuth?.includes('project_technical_proposal_operation')}
                    icon={<CustomIcons type="klf-edit1" />}
                    onClick={() => {
                      editItemName(row, index)
                    }}
                  />
                )}
              </div>
            )}
          </Tooltip>
        )
      },
    },
    {
      title: '光伏指标',
      dataIndex: '',
      key: 'PV_quota',
      filterIcon: (
        <CustomIcons
          type="klf-filter"
          style={{
            color: hasPvValue() ? '#1ec692' : '#bfbfbf',
          }}
        />
      ),
      filterDropdown: PVFilterDom,
      onFilterDropdownVisibleChange: (visible: boolean) => {
        if (!visible) {
          if (query.pvChange) {
            query.pvChange = false
            queryList()
          }
        } else {
          const {
            installedCapacityMin,
            installedCapacityMax,
            dayAvgPowerGenerationMin,
            dayAvgPowerGenerationMax,
            useHourYearMin,
            useHourYearMax,
            selfAbsorptionRateMin,
            selfAbsorptionRateMax,
            annualGenerationHoursMin,
            annualGenerationHoursMax,
          } = query

          pvForm.setFieldsValue({
            installedCapacityMin,
            installedCapacityMax,
            dayAvgPowerGenerationMin,
            dayAvgPowerGenerationMax,
            useHourYearMin,
            useHourYearMax,
            selfAbsorptionRateMin,
            selfAbsorptionRateMax,
            annualGenerationHoursMin,
            annualGenerationHoursMax,
          })
        }
      },
      children: [
        {
          title: '装机容量',
          dataIndex: 'installedCapacity',
          key: 'installedCapacity',
          sorter: true,
          width: cellWidth,
          render: (res: string, row: any) => {
            switch (row.calcStatus) {
              case 0:
                return '方案未计算'
              case 1:
                return (
                  <span
                    style={{
                      whiteSpace: 'nowrap',
                    }}
                  >
                    方案计算中
                    <LoadingOutlined
                      style={{
                        marginLeft: '1rem',
                      }}
                    />
                  </span>
                )
              case 2:
                return formatRes(res, 'kW', row.calcStatus === 2)
              case 3:
                return <span style={{ color: '#767F95' }}>计算失败</span>
              case 4:
                return <span style={{ color: '#FF574C' }}>结果失效</span>
            }
          },
        },
        {
          title: '日均发电',
          dataIndex: 'dayAvgPowerGeneration',
          key: 'dayAvgPowerGeneration',
          sorter: true,
          width: cellWidth,
          render: (res: string, row: any) => {
            return formatRes(res, 'kWh', row.calcStatus === 2)
          },
        },
        {
          title: '年利用小时数',
          dataIndex: 'useHourYear',
          key: 'useHourYear',
          sorter: true,
          width: cellWidth + 30,
          render: (res: string, row: any) => {
            return formatRes(res, 'h', row.calcStatus === 2)
          },
        },
        {
          title: '自消纳比',
          dataIndex: 'selfAbsorptionRate',
          key: 'selfAbsorptionRate',
          sorter: true,
          width: cellWidth,
          render: (res: string, row: any) => {
            return formatRes(res, '%', row.calcStatus === 2)
          },
        },
        {
          title: '年发电小时数',
          dataIndex: 'annualGenerationHours',
          key: 'annualGenerationHours',
          sorter: true,
          width: cellWidth + 30,
          render: (res: string, row: any) => {
            return formatRes(res, 'h', row.calcStatus === 2)
          },
        },
      ],
    },
    {
      title: '储能指标',
      dataIndex: 'energy_quota',
      key: 'energy_quota',
      filterIcon: (
        <CustomIcons
          type="klf-filter"
          style={{
            color: hasBatteryValue() ? '#1ec692' : '#bfbfbf',
          }}
        />
      ),
      filterDropdown: energyFilterDom,
      onFilterDropdownVisibleChange: (visible: boolean) => {
        if (!visible) {
          if (query.energyChange) {
            query.energyChange = false
            queryList()
          } else {
            const {
              batteryCapacityMin,
              batteryCapacityMax,
              pcsPowerMin,
              pcsPowerMax,
              dayAvgDischargeMin,
              dayAvgDischargeMax,
              dayAvgChargeDischargeMin,
              dayAvgChargeDischargeMax,
            } = query

            energyForm.setFieldsValue({
              batteryCapacityMin,
              batteryCapacityMax,
              pcsPowerMin,
              pcsPowerMax,
              dayAvgDischargeMin,
              dayAvgDischargeMax,
              dayAvgChargeDischargeMin,
              dayAvgChargeDischargeMax,
            })
          }
        }
      },
      children: [
        {
          title: '电池容量',
          dataIndex: 'batteryCapacity',
          key: 'batteryCapacity',
          sorter: true,
          width: cellWidth,
          render: (res: string, row: any) => {
            return formatRes(res, 'kWh', row.calcStatus === 2)
          },
        },
        {
          title: 'PCS总功率',
          dataIndex: 'pcsPower',
          key: 'pcsPower',
          sorter: true,
          width: cellWidth + 15,
          render: (res: string, row: any) => {
            return formatRes(res, 'kW', row.calcStatus === 2)
          },
        },
        {
          title: '日均放电',
          dataIndex: 'dayAvgDischarge',
          key: 'dayAvgDischarge',
          sorter: true,
          width: cellWidth,
          render: (res: string, row: any) => {
            return formatRes(res, 'kWh', row.calcStatus === 2)
          },
        },
        {
          title: '日均循环',
          dataIndex: 'dayAvgChargeDischarge',
          key: 'dayAvgChargeDischarge',
          sorter: true,
          width: cellWidth + 15,
          render: (res: string, row: any) => {
            return formatRes(res, '次', row.calcStatus === 2)
          },
        },
      ],
    },
    {
      title: '',
      dataIndex: '',
      key: '',
    },
    {
      title: '操作',
      dataIndex: '',
      key: 'action',
      width: 120,
      fixed: 'right' as any,
      render: listOperaColumn,
    },
  ]

  // const rowSelection = {
  //   onChange: (selectedRowKeys: React.Key[], selectedRows: any[]) => {
  //     console.debug(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows)
  //   },
  //   getCheckboxProps: (record: any) => ({
  //     disabled: false, // Column configuration not to be checked
  //     name: record.name,
  //   }),
  // }

  return (
    <div style={{ height: '100%', width: '100%' }}>
      {!hasQuery() && !firstLoading && dataSource.length === 0 && (
        <div
          style={{
            height: '100%',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <div
            style={{
              textAlign: 'center',
            }}
          >
            <div>
              <Image
                style={{
                  width: 340,
                  height: 340,
                }}
                src={'./assets/tech-empty.png'}
                alt=""
                preview={false}
              />
            </div>
            <Button
              type="primary"
              disabled={!hasAuth}
              style={{
                width: 340,
              }}
              onClick={() => {
                props.create?.()
              }}
            >
              开始技术设计
            </Button>
          </div>
        </div>
      )}
      {!firstLoading && (hasQuery() || dataSource.length > 0) && (
        <Table
          loading={loading}
          scroll={{ x: 'max-content', y: 'calc(100vh - 450px)' }}
          // rowSelection={rowSelection}
          rowKey="id"
          size="small"
          columns={columns}
          className="right-actions"
          dataSource={dataSource}
          onChange={changeTable}
          pagination={false}
        />
      )}
      <ManageMembersModal
        row={rowObj}
        isModalVisible={isModalVisible}
        setIsModalVisible={setIsModalVisible}
      ></ManageMembersModal>
    </div>
  )
}

export default TechnologyList
