import { useEffect, useState } from 'react'
import styles from '../index.module.scss'
import { Spin, Tooltip } from 'antd'
import { TechnologyService } from '../../../../apis/project-service-history'
import { CustomIcons } from '../../../../global'

const ParamsTypes = [
  {
    title: '光伏',
    icon: <CustomIcons type="klf-finance-investment" />,
    url: 'pv',
  },
  {
    title: '储能',
    icon: <CustomIcons type="klf-finance-operate" />,
    url: 'battery',
  },
]

// 参数库
export const Technical = () => {
  const [loading, setLoading] = useState(false)
  const [dataSource, setDataSource] = useState<any>([])

  const init = () => {
    setLoading(true)
    TechnologyService.getNameIdList()
      .then((res) => {
        setDataSource([res.data.photovoltaic, res.data.energyStorage])
      })
      .catch(() => {})
      .finally(() => {
        setLoading(false)
      })
  }

  const jumpTo = (url: any) => {
    if (url) {
      location.assign(`#/user-center/${url}`)
    }
  }

  useEffect(() => {
    init()
  }, [])

  return (
    <Spin spinning={loading}>
      <div className={styles.financialParams}>
        {ParamsTypes.map((params, index) => {
          // 参数模块数据列表
          const listData = dataSource[index]
          return (
            <div
              key={index}
              className="module-item"
              onClick={() => {
                jumpTo(params?.url)
              }}
            >
              <div className="module-title">
                {params.icon}
                <span className="title">{params.title}</span>
              </div>
              <div className="list">
                {listData?.map((item: any) => {
                  return (
                    <>
                      <div
                        className="item"
                        style={{
                          position: 'relative',
                        }}
                      >
                        {item.name?.length > 10 ? (
                          <Tooltip title={item.name}>{item.name.substring(0, 10)}...</Tooltip>
                        ) : (
                          item.name
                        )}

                        {item.isDefault && (
                          <div
                            className="absolute-top"
                            style={{
                              position: 'absolute',
                              top: -5,
                              right: 10,
                              fontSize: 16,
                            }}
                          >
                            <CustomIcons
                              className="absolute-top primary-mark"
                              type="klf-bookmark_added_filled_regular"
                              style={{
                                color: '#1ec692',
                              }}
                            />
                          </div>
                        )}
                      </div>
                    </>
                  )
                })}
              </div>
            </div>
          )
        })}
      </div>
    </Spin>
  )
}
