import { useState, useEffect } from 'react'
import { Form, Button, Input, Modal, message, Space } from 'antd'
import { UserService } from '../../../apis/project-service-history'
import Verify from '../../login/components/verify'
import { EMAIL_EXP, PHONE_EXP, SM2CODE } from '../../../utils/constant'
import { sm2 } from 'sm-crypto'

const formItemLayout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 18 },
}

let timer: any;
// 修改邮箱& 验证邮箱 &手机号
const EditEmailPhoneModal = (props: any) => {
  const { defaultValue, onSuccess, type = 'email', visible, setDefault } = props
  const [form] = Form.useForm()

  const [statuses, setStatuses] = useState({
    checkLoading: false,
    loading: false,
    isChecked: false,
  })

  const [password, setPassword] = useState('')

  const [validate, setValidate] = useState<any>({
    validateStatus: undefined,
    help: ' ',
    emailValidateStatus: undefined,
    emailHelp: ' ',
  })

  const [state, setState] = useState({
    fieldValue: '',
    captchaVerification: '',
    code: '',
  })
  const [setp, setSetp] = useState(1)
  const [count, setCount] = useState(-1)
  const fieldsTextAndService =
    type === 'email'
      ? {
          field: 'email',
          title: '修改邮箱',
          label: '邮箱地址',
          errorMessage: '请输入邮箱',
          sendVerifyCode: setDefault ? UserService.sendCheckEmail : UserService.sendUpdateEmailCode,
        }
      : {
          field: 'telephone',
          title: '修改手机号',
          label: '手机号码',
          errorMessage: '请输入手机号',
          sendVerifyCode: UserService.sendBindTelephone,
        }

  const onCancel = () => {
    setSetp(1)
    setCount(-1)
    form.setFieldsValue({
      password: '',
      email: '',
      code: '',
      captchaVerification: '',
    })
    props.onCancel()
  }

  useEffect(() => {
    if (count < 0) {
      clearInterval(timer)
    }
  }, [count])

  // 发送验证码
  const sendVerifyCode = () => {
    const values = form.getFieldsValue()
    if (type === 'email' && setDefault) {
      Object.assign(values, {
        email: values.email,
      })
    } else {
      Object.assign(values, {
        password,
      })
    }
    setCount(60)
    timer = setInterval(() => {
      setCount((count: number) => count - 1)
    }, 1000)
    const request = fieldsTextAndService.sendVerifyCode
    request({
      root: {
        ...values,
      },
    })
      .then((res) => {
        if (res.data) {
          message.success('发送成功')
        }
      })
      .catch(() => {
        setCount(-1)
      })
      .finally(() => {
        setStatuses({
          ...statuses,
          loading: false,
        })
      })
  }

  // 验证验证码是否正确
  const handleSave = () => {
    const request =
      type === 'email' ? (setDefault ? UserService.checkEmail1 : UserService.updateEmail) : UserService.updateTelephone
    form.validateFields().then(() => {
      const values = form.getFieldsValue()
      console.info(values)
      setStatuses({
        ...statuses,
        checkLoading: true,
      })
      request(
        setDefault && type === 'email'
          ? {
              ...values,
            }
          : {
              root: {
                ...values,
                password,
              },
            }
      )
        .then((res) => {
          if (res.data) {
            onSuccess && onSuccess(type === 'email' ? values.email || defaultValue : values.telephone)
            message.success('修改成功')
          }
        })
        .catch(() => {})
        .finally(() => {
          setStatuses({
            ...statuses,
            checkLoading: false,
          })
        })
    })
  }

  useEffect(() => {
    form.setFieldsValue({
      password: '',
      email: setDefault ? defaultValue : '',
      captchaVerification: '',
      telephone: '',
      code: '',
    })
    setState({
      ...state,
      captchaVerification: '',
    })
  }, [setp])

  useEffect(() => {
    if (setDefault && type === 'email' && visible) {
      setSetp(2)
    }
  }, [])

  const validPassword = async () => {
    await form.validateFields().then(() => {
      const values = form.getFieldsValue(['password'])
      setPassword(sm2.doEncrypt(values.password, SM2CODE, 0))
      UserService.checkPwd({
        root: {
          password: sm2.doEncrypt(values.password, SM2CODE, 0),
        },
      })
        .then((res) => {
          if (res.data) {
            setSetp(2)
          } else {
            message.error('密码验证错误')
          }
        })
        .catch(() => {})
    })
  }

  return (
    <Modal
      visible={visible}
      onCancel={onCancel}
      keyboard={false}
      maskClosable={false}
      destroyOnClose
      title={setDefault && type === 'email' ? '验证邮箱' : fieldsTextAndService.title}
      footer={
        setp === 1 ? (
          <div style={{ textAlign: 'right' }}>
            <Button type="primary" onClick={validPassword}>
              下一步
            </Button>
          </div>
        ) : (
          <Space>
            <Button onClick={onCancel}>取消</Button>
            <Button type="primary" onClick={handleSave} loading={statuses.checkLoading}>
              保存
            </Button>
          </Space>
        )
      }
    >
      <Form form={form} {...formItemLayout}>
        {setp === 1 ? (
          <>
            <Form.Item
              name="password"
              label="验证密码"
              rules={[
                {
                  required: true,
                  message: '密码必填',
                },
                {
                  min: 8,
                  max: 16,
                  message: '密码长度8~16位',
                },
                {
                  pattern: /^.*(?=.*\d)(?=.*[A-Z])(?=.*[a-z]).*$/,
                  message: '必须包含大写字母小写字母和数字',
                },
              ]}
            >
              <Input size="large" type="password" placeholder="请输入密码" />
            </Form.Item>
          </>
        ) : (
          <>
            <Form.Item
              name={type === 'email' ? 'email' : 'telephone'}
              label={fieldsTextAndService.label}
              validateStatus={validate.emailValidateStatus}
              help={validate.emailHelp}
              initialValue={defaultValue}
            >
              <Input size="large" type={type} placeholder={fieldsTextAndService.errorMessage} />
            </Form.Item>
            <Form.Item
              colon={false}
              label=" "
              validateStatus={validate.validateStatus}
              help={validate.help}
              name="captchaVerification"
            >
              <Verify
                update={(e: string) => {
                  setState({
                    ...state,
                    captchaVerification: e,
                  })
                  e && form.validateFields(['captchaVerification'])
                }}
                isVerifyPass={!!state.captchaVerification}
              />
            </Form.Item>
            <Form.Item
              label="验证码"
              name="code"
              rules={[
                {
                  required: true,
                  message: '请输入验证码',
                },
                {
                  min: 6,
                  message: '',
                },
              ]}
            >
              <Input
                size="large"
                maxLength={6}
                minLength={6}
                placeholder="请输入验证码"
                addonAfter={
                  count >= 0 ? (
                    <span>{count}</span>
                  ) : (
                    <Button
                      type="link"
                      loading={statuses.loading}
                      onClick={() => {
                        const values = form.getFieldsValue()
                        let err = 0
                        let validateInfo = {
                          validateStatus: '',
                          help: ' ',
                          emailValidateStatus: '',
                          emailHelp: ' ',
                        }
                        if (type === 'email' && !setDefault) {
                          if (!values.email) {
                            validateInfo = {
                              ...validateInfo,
                              emailValidateStatus: 'error',
                              emailHelp: '请输入邮箱',
                            }
                            err += 1
                          }
                          if (!EMAIL_EXP.test(values.email) && !validateInfo.emailValidateStatus) {
                            validateInfo = {
                              ...validateInfo,
                              emailValidateStatus: 'error',
                              emailHelp: '请输入正确的邮箱',
                            }
                            err += 1
                          }
                          if (values.email === defaultValue && !validateInfo.emailValidateStatus) {
                            validateInfo = {
                              ...validateInfo,
                              emailValidateStatus: 'error',
                              emailHelp: '不能使用当前已绑定的邮箱',
                            }
                            err += 1
                          }
                        }
                        if (type === 'phone') {
                          if (!values.telephone) {
                            validateInfo = {
                              ...validateInfo,
                              emailValidateStatus: 'error',
                              emailHelp: '请输入手机号',
                            }
                            err += 1
                          }
                          if (!PHONE_EXP.test(values.telephone) && !validateInfo.emailValidateStatus) {
                            validateInfo = {
                              ...validateInfo,
                              emailValidateStatus: 'error',
                              emailHelp: '请输入正确的手机号',
                            }
                            err += 1
                          }
                          if (values.telephone === defaultValue && !validateInfo.emailValidateStatus) {
                            validateInfo = {
                              ...validateInfo,
                              emailValidateStatus: 'error',
                              emailHelp: '不能使用当前已绑定的手机号',
                            }
                            err += 1
                          }
                        }
                        if (!values.captchaVerification) {
                          validateInfo = {
                            ...validateInfo,
                            validateStatus: 'error',
                            help: '请验证',
                          }
                          err += 1
                        }
                        if (err > 0) {
                          setValidate({
                            ...validateInfo,
                          })
                          return
                        } else {
                          setValidate({
                            validateStatus: undefined,
                            help: ' ',
                            emailValidateStatus: undefined,
                            emailHelp: ' ',
                          })
                        }
                        setStatuses({
                          ...statuses,
                          loading: true,
                        })
                        sendVerifyCode()
                      }}
                    >
                      发送验证码
                    </Button>
                  )
                }
              />
            </Form.Item>
          </>
        )}
      </Form>
    </Modal>
  )
}

export default EditEmailPhoneModal
