import React, { useEffect, useState } from 'react'
import { SmileOutlined } from '@ant-design/icons'
import { Drawer, Spin, Timeline } from 'antd'
import { ProjectDocumentService } from '../../../../apis/wl-service'

export interface ILogProps {
  projectId: string
  visible: boolean
  visibleChange: (visible: boolean) => void
}

const App: React.FC<ILogProps> = ({ projectId, visible, visibleChange }) => {
  const [loading, setLoading] = useState<boolean>(false) // 数据加载状态
  const [data, setData] = useState<any[]>([]) // 项目日志数据源

  // 获取项目日志
  useEffect(() => {
    if (!projectId || !visible) return

    setLoading(true)
    ProjectDocumentService.detail({ id: projectId })
      .then((resp: any) => {
        setData(resp?.data ?? [])
      })
      .catch((e) => {
        console.warn(e)
      })
      .finally(() => {
        setLoading(false)
      })

    return () => {}
  }, [projectId, visible])

  // TODO: 对接接口
  console.log('项目日志：', data)
  return (
    <Spin spinning={loading}>
      <Drawer
        className="right-actions"
        title="项目日志(修订历史)"
        placement="right"
        visible={visible}
        onClose={() => {
          visibleChange(false)
        }}
      >
        <Timeline>
          <Timeline.Item color="green">Create a services site 2015-09-01</Timeline.Item>
          <Timeline.Item color="green">Create a services site 2015-09-01</Timeline.Item>
          <Timeline.Item color="red">
            <p>Solve initial network problems 1</p>
            <p>Solve initial network problems 2</p>
            <p>Solve initial network problems 3 2015-09-01</p>
          </Timeline.Item>
          <Timeline.Item>
            <p>Technical testing 1</p>
            <p>Technical testing 2</p>
            <p>Technical testing 3 2015-09-01</p>
          </Timeline.Item>
          <Timeline.Item color="gray">
            <p>Technical testing 1</p>
            <p>Technical testing 2</p>
            <p>Technical testing 3 2015-09-01</p>
          </Timeline.Item>
          <Timeline.Item color="gray">
            <p>Technical testing 1</p>
            <p>Technical testing 2</p>
            <p>Technical testing 3 2015-09-01</p>
          </Timeline.Item>
          <Timeline.Item color="#00CCFF" dot={<SmileOutlined />}>
            <p>Custom color testing</p>
          </Timeline.Item>
        </Timeline>
      </Drawer>
    </Spin>
  )
}

export default App
