import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Space } from 'antd'
import CardTitle from '../components/CardTitle'
import PermissionResource from '../components/PermissionResource'
import PermissionTemplateControllerApi from '../../../../services/api/PermissionTemplateControllerApi'
import Global from '../../../../global'
import { TemplateAuth } from '../type'
import { getCheckedIds } from '../index'
import iconMember from '../../../../assets/member.png'

import '../index.less'

export const GroupProjectRightsTemplateView = React.memo(() => {
  const params = useParams()
  const { templateId } = params

  // 用户基础信息
  const [user, setUser] = useState(Global.user)
  Global.updateUser.push((e: any) => {
    setUser(e)
  })

  const [loading, setLoading] = React.useState<boolean>(false)
  const [authList, setResourceList] = React.useState<TemplateAuth[]>()
  const [checkIdList, setCheckIdList] = React.useState<React.Key[]>([])
  const [templateName, setTemplateName] = useState<string>()

  const fetchById = () => {
    if (loading || !user?.id) return
    setLoading(true)
    PermissionTemplateControllerApi.authPermissionTemplateResourcesQueryTreeGet({
      templateId,
    })
      .then((res) => {
        setLoading(false)
        const { code, data } = res || {}
        if (code === 200) {
          const { templateName, permissionsTreeRes } = data || {}
          const list = permissionsTreeRes || []
          setResourceList(list)
          setTemplateName(templateName || '')
          const checkedIds = getCheckedIds(list)
          setCheckIdList(checkedIds)
        }
      })
      .catch(() => {
        setLoading(false)
      })
  }

  useEffect(() => {
    fetchById()
  }, [])

  return (
    <div className="rights-template">
      <CardTitle title="权限查看" />
      <div className="rights-template-detail">
        <div className="rights-template-detail-header">
          <Space size={12}>
            <img width={32} src={iconMember} alt="" />
            <span className="rights-template-detail-name">{templateName}</span>
          </Space>
        </div>
        <div className="rights-template-detail-content">
          <PermissionResource
            disabled
            checkedKeys={checkIdList}
            list={authList}
            onCheck={(values) => setCheckIdList(values)}
          />
        </div>
      </div>
    </div>
  )
})
