import React, { useEffect, useRef, useState } from 'react'
import { Button, Card, Space, Tabs } from 'antd'
import { PlusOutlined } from '@ant-design/icons'

import { BreadcrumbObservable, SideMenuObservable } from '../../../application'
import { TablePageInstance } from '../../../components/wl-table-page'

import CompanyTable from './components/company-table'
import PersonalTable from './components/personal-table'
import PartnerDlg from './components/dlg'

import './index.less'

export default () => {
  const companyRef = useRef<TablePageInstance>(null)
  const personalRef = useRef<TablePageInstance>(null)
  const [activeTabKey, setActiveTabKey] = useState<string>('company') // 当前显示的Tab
  const [modalProps, setModalProps] = useState<{ visible: boolean; title: string; dataSource: any }>({
    visible: false,
    title: '',
    dataSource: {
      type: '0',
    },
  })

  useEffect(() => {
    SideMenuObservable.next(false)
    BreadcrumbObservable.next([
      {
        name: '投资开发',
        url: '/invest/workbench',
      },
      {
        name: '合作方',
        url: '/invest/partner',
      },
    ])
  }, [])

  useEffect(() => {
    if (activeTabKey === 'company') {
      companyRef.current?.reload?.()
    } else {
      personalRef.current?.reload?.()
    }
  }, [activeTabKey])

  // 编辑表格
  const editRow = (dataSource: any) => {
    setModalProps((previous) => ({
      ...previous,
      title: dataSource.type?.toString() === '0' ? '编辑企业' : '编辑个人',
      visible: true,
      dataSource: {
        ...dataSource,
        type: dataSource.type?.toString(),
      },
    }))
  }

  return (
    <Card
      style={{ height: '100%' }}
      // title={<div style={{ fontWeight: 600, fontSize: 20 }}>合作方</div>}
      className="no-border-bottom-card"
      extra={null}
      bodyStyle={{ height: 'calc(100vh - 150px)', padding: 0 }}
    >
      <PartnerDlg
        {...modalProps}
        onCancel={() => {
          setModalProps((previous) => ({ ...previous, visible: false }))
        }}
        onOk={(type: string) => {
          setModalProps((previous) => ({ ...previous, visible: false }))
          setActiveTabKey(type === '1' ? 'personal' : 'company')
          if (type === '1') {
            personalRef.current?.reload()
          } else {
            companyRef.current?.reload()
          }
        }}
      />
      <Tabs
        className="partner-tabs"
        activeKey={activeTabKey}
        tabBarStyle={{ padding: '0 24px' }}
        tabBarExtraContent={
          <Space>
            <Button
              type="primary"
              icon={<PlusOutlined />}
              onClick={() => {
                setModalProps((previous) => ({
                  ...previous,
                  visible: true,
                  title: '新增合作方',
                  dataSource: {
                    type: activeTabKey === 'company' ? '0' : '1',
                  },
                }))
              }}
            >
              新增合作方
            </Button>
          </Space>
        }
        onChange={(key: string) => {
          setActiveTabKey(key)
        }}
      >
        <Tabs.TabPane tab="企业" key="company">
          <CompanyTable componentRef={companyRef} editRow={editRow} />
        </Tabs.TabPane>
        <Tabs.TabPane tab="个人" key="personal">
          <PersonalTable componentRef={personalRef} editRow={editRow} />
        </Tabs.TabPane>
      </Tabs>
    </Card>
  )
}
