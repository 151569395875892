// import { EvaluationService } from '../../apis/project-service'
// import GeoJSON from 'geojson'
import colorUtil from '@antv/color-util'
import { CommonService, ProjectServiceHistory } from '../../apis/project-service-history'
import GeoJSON  from 'geojson'

export const colorFields = [
  colorUtil.gradient(
    [
      '#FF574C',
      '#FF6B50',
      '#FF8056',
      '#FF945A',
      '#FFA860',
      '#FBBB66',
      '#C6BD86',
      '#91BFA6',
      '#5CC1C6',
      '#27C3E6',
    ].reverse()
  ),
  colorUtil.gradient(
    [
      '#3274F6',
      '#4376EF',
      '#5478E7',
      '#778CC5',
      '#767CD9',
      '#8E85C8',
      '#AA92AE',
      '#C69F95',
      '#E3AD7D',
      '#FFBB64',
    ].reverse()
  ),
  colorUtil.gradient(
    [
      '#1EC692',
      '#37C58D',
      '#50C387',
      '#69C283',
      '#82C17D',
      '#9BC078',
      '#B4BF74',
      '#CDBD6E',
      '#E6BC69',
      '#FFBB64',
    ].reverse()
  ),
]

export const colorArrs: { [key: string]: any } = {
  GHI: colorFields[0],
  DNI: colorFields[0],
  TMP: colorFields[0],
  DHI: colorFields[0],
  SUNRISE: colorFields[0],
  SUNSET: colorFields[0],
  RH: colorFields[1],
  RAINFALL: colorFields[1],
  WS: colorFields[2],
  PRES: colorFields[2],
}

export const minMaxArrs: { [key: string]: any } = {
  GHI: [],
  DNI: [],
  TMP: [-20, 40],
  DHI: [],
  SUNRISE: [],
  SUNSET: [],
  RH: [],
  RAINFALL: [],
  WS: [],
  PRES: [],
}

export const getRoofData = (props: {bound: { rightTopLat: number;
    rightTopLng: number;
    leftBottomLat: number;
    leftBottomLng: number; }}) => {
  console.log(props);
  //   const { bound } = props;
  //   if (bound) {
  //     EvaluationService.getGridData({
  //       level,
  //       ...bound,
  //     })
  //       .then((jsonData: any) => {
  //
  //         roofData = jsonData.data
  //         const srcData = jsonData.data.map((o: any) => ({
  //           lat: parseFloat(o[1]),
  //           lng: parseFloat(o[0]),
  //           value: parseFloat(o[4]),
  //         }))
  //         const max = Math.max(...srcData.map((o: any) => o.value))
  //         const min = Math.min(...srcData.map((o: any) => o.value))
  //         setMin(min)
  //         setMax(max)
  //         const distance = max - min
  //         const data = (GeoJSON as any).parse(srcData, { Point: ['lat', 'lng'] })
  //         const geo = new Loca.GeoJSONSource({
  //           data,
  //         })
  //         const style = {
  //           radius: 55000 * currentRoofLevel,
  //           gap: 0,
  //           altitude: 0,
  //           unit: 'meter',
  //           borderWidth: 0,
  //           opacity: 0.6,
  //           height: 0,
  //           topColor: function (i: number, feature: any) {
  //             let data = feature.coordinates[0].properties
  //             const value = data.value - min
  //
  //             let ci = value / distance
  //             return colorArrs[currentSelected](ci)
  //           },
  //         }
  //         roofPl.setSource(geo)
  //         roofPl.setStyle(style)
  //         loca.requestRender()
  //         callback?.(true)
  //       })
  //       .catch(() => {
  //         setLoading(false)
  //         callback?.(false)
  //       })
  //   }
  // }
}
const range: any = {};
const getData = (code = '100000', level: string = '1') => {
  if(range[code]?.[level]) {
    console.log(range[code][level]);
    return range[code][level];
  }
  return undefined;
}

const putData = (code = '100000', level: string = '1', geojson: any) => {
  if(range[code || '100000']) {
    range[code || '100000'][level] = geojson
  } else {
    range[code || '100000'] = {
      [level]: geojson,
    }
  }
}

export const getCityData = (adcode: string, level='10') => {
  return new Promise((resolve) => {
    if(getData(adcode, level)) {
      resolve({ data: getData(adcode, level) });
      return;
    }
    CommonService.getBoundary({
      code: adcode,
      level,
    }).then(resp => {
      const srcData: any[] = [];
      resp.data.forEach((o: any) => srcData.push(
        ...o.polyline.map((oo: any) => ({
          ...o,
          polyline: [oo],
        })))
      );
      const data = (GeoJSON as any).parse(srcData, { Polygon: 'polyline' })
      putData(adcode, level, data);
      resolve({ data });
    })
  })
}

;(window as any).getCityData = getCityData;

export const getWeatherData = (weatherElement: string, month: string) => {
  return new Promise((resolve) => {
    ProjectServiceHistory.getTmyWeather({
      weatherElement,
      time: `2000-${('0' + month).slice(-2)}`,
      freq: 'M',
    }).then((resp: any) => {
      let min = 99999;
      let max = -99999;
      const srcData = resp.data
        // .filter((o: any, idx: number) => {
        //   return idx % TIMES === 0
        // })
        .map((o: any) => {
          const [lng, lat, value] = o.map(parseFloat);
          min = Math.min(min, value);
          max = Math.max(max, value);
          return {
            polygon: [
              [
                [lng - 0.05, lat - 0.05],
                [lng + 0.05, lat - 0.05],
                [lng + 0.05, lat + 0.05],
                [lng - 0.05, lat + 0.05],
                [lng - 0.05, lat - 0.05],
              ]
            ],
            value,
          }
        })

      const data = (GeoJSON as any).parse(srcData, { Polygon: 'polygon' })
      const step = (max - min) / 20
      const arr = []
      for(let i = 0; i < 20; i++) {
        arr.push([min + i * step, colorArrs[weatherElement](i * 5 / 100) ]);
      }
      resolve({ data, colorArr: arr })
    })
  })
}
