// @ts-ignore
import mapboxgl from '!mapbox-gl'
import React from 'react'
import ReactDOM from 'react-dom'
import { HashRouter as Router, useRoutes } from 'react-router-dom'
import reportWebVitals from './reportWebVitals'
import { routerConfig } from './router'
import { ConfigProvider, message } from 'antd'
import 'moment/locale/zh-cn'
import zh_CN from 'antd/lib/locale-provider/zh_CN'
import en_US from 'antd/lib/locale-provider/en_US'
import axios from 'axios'

import './index.less'
import { token } from './utils/utils'
import { serviceOptions } from './apis/serviceOptions'
import { setTwoToneColor } from '@ant-design/icons'
import { UserService } from './apis/project-service-history'
import { Global } from './global'
import { BehaviorSubject, debounceTime, Subject } from 'rxjs'
import qs from 'qs'

mapboxgl.accessToken = 'pk.eyJ1Ijoib3dvcHMiLCJhIjoiY2wxeHBieXhxMDUxYjNicWZua2R5a3RqayJ9.yjsA1-1sWYHfY7Ea5IeArg'

import 'moment/locale/zh-cn'
import * as moment from 'moment'
moment.locale('zh-cn')

const result = new URLSearchParams(location.search)
const invite = result.get('invite')
if (invite) {
  sessionStorage.setItem('INVITE', invite)
}

// import md5 from 'crypto-js/md5'
// console.log('abcdefg:', md5('abcdefg').toString())
// console.log('11223344', md5('11223344').toString())
// const a = 119.297555812212
// const b = 26.1203969439181
// const c = 327.244490012379
// console.log(md5(a.toFixed(6) + b.toFixed(6) + c.toFixed(6)).toString())

// 初始化网络
const ignoreList = ['restapi.amap.com']

const ignoreResponse = ['/financeWord/uploadFileList', '/word/fillWord']

const orgObservable = new BehaviorSubject('')

orgObservable.pipe(debounceTime(500)).subscribe((msg) => {
  if (msg) {
    message.error(msg)
    setTimeout(() => {
      location.href = `#/home`
      location.reload()
    }, 2000)
  }
})

const projObservable = new BehaviorSubject('')

projObservable.pipe(debounceTime(500)).subscribe((msg) => {
  if (msg) {
    message.error(msg)
    setTimeout(() => {
      location.href = `#/program/manage`
      projObservable.next('')
    }, 2000)
  }
})

setTwoToneColor('#1ec692')

// @ts-ignore
export const ClickObservable = new Subject()

export function init() {
  serviceOptions.axios = axios

  axios.defaults.paramsSerializer = (params: any) => {
    return qs.stringify(params, { arrayFormat: 'brackets' })
  }

  axios.interceptors.request.use((config) => {
    if (config.method?.toUpperCase() === 'GET' && config.headers) {
      config.headers['Content-Type'] = 'application/json'
    }
    if (config.method?.toUpperCase() === 'DELETE' && config.headers) {
      config.headers['Content-Type'] = ''
    }
    if (ignoreList.findIndex((o) => config.url!.includes(o)) >= 0) {
      return config
    }
    if (config?.headers) {
      config.headers['Authorization'] = token.get() || ''
      config.headers['tenantId'] = Global?.user?.tenantId || ''
    }
    return config
  })

  // 初始化拦截器 拦截未登录请求
  axios.interceptors.response.use((response) => {
    if (response.data instanceof Blob) {
      let fileName = window.decodeURI(response.headers['content-disposition'].split('=')[1])
      let link = document.createElement('a')
      link.href = window.URL.createObjectURL(new Blob([response.data], { type: 'application/octet-stream' }))
      link.target = '_blank'
      link.download = fileName
      link.click()
      return response
    }
    if (ignoreList.findIndex((o) => response.config.url!.includes(o)) >= 0) {
      return Promise.resolve(response)
    } else {
      if (ignoreResponse.findIndex((o) => response.config.url!.includes(o)) >= 0) {
        return Promise.resolve(response)
      }
      if (response.data.code === 10005 || response.data.code === 50003 || response.data.code === 50004) {
        return Promise.resolve(response)
      } else if (response.data.code === 50005) {
        if (orgObservable.getValue() !== response?.data?.msg) {
          orgObservable.next(response?.data?.msg || '组织已切换')
        }
      } else if (response.data.code === 10006) {
        if (projObservable.getValue() !== response?.data?.msg) {
          projObservable.next(response?.data?.msg || '该项目不存在')
        }
      } else if (response.data.code === 10002) {
        if (token.get()) {
          message.error('需要登录').then()
        }
        Global.user = {}
        token.clear()
        if (location.href.includes('register')) {
          location.assign('#/login/register')
        } else {
          location.assign('#/login/index')
        }

        return Promise.reject(response)
      } else if (response.data.code !== 200) {
        try {
          if (response.config.baseURL?.includes('mock')) {
            return Promise.resolve(response)
          }
          message.error(response?.data?.msg).then()
          return Promise.reject(response?.data?.msg)
        } catch (e) {
          console.log(e)
        }
      } else {
        return Promise.resolve(response)
      }
    }
  })
}

init()

document.addEventListener(
  'keydown',
  function (e) {
    if (e.code == 'Escape') {
      ;(window as any).closeDropdown?.()
    }
  },
  true
)

if (location.href.includes('localhost')) {
  // axios.defaults.baseURL = 'http://121.229.30.34:31980'
  // axios.defaults.baseURL = 'http://klf-uat.i3060.com'
  axios.defaults.baseURL = 'http://klf-dev.i3060.com'
  // axios.defaults.baseURL = 'http://klf-wh-dev.i3060.com'
  // axios.defaults.baseURL = 'https://service.wongy.cn/api/klf'
}

window.getTranslateStr = (key: string, fallback?: string) => {
  return window.translateMap[key.toUpperCase()] || fallback || `Can't find translate with key: ${key}`
}

let antd_lang = zh_CN

;(function () {
  var lang = navigator.language || (navigator as any).userLanguage
  if (lang !== 'zh-CN') {
    switch (lang) {
      case 'en_US':
        antd_lang = en_US
    }
    const async_script = document.createElement('script')
    async_script.type = 'text/javascript'
    async_script.src = `./assets/translate-${lang}.js`
    const x = document.getElementsByTagName('body')[0]
    x.parentNode?.insertBefore(async_script, x)
  }
})()

const AppRouter = () => {
  const element = useRoutes(routerConfig)
  return element
}

const initDom = () => {
  ReactDOM.render(
    <ConfigProvider locale={antd_lang}>
      <Router>
        <AppRouter />
      </Router>
    </ConfigProvider>,
    document.getElementById('root')
  )
}

UserService.info()
  .then((res) => {
    Global.user = res?.data || {}
    UserService.roleInfo().then((resp) => {
      Global.role = resp.data
      UserService.roleInfo().then((resp) => {
        Global.role = resp.data
        initDom()
      })
    })
  })
  .catch(() => {
    Global.user = {}
    if (location.href.includes('register')) {
      location.assign('#/login/register')
    } else {
      location.assign('#/login/index')
    }
    initDom()
  })
reportWebVitals()

document.addEventListener(
  'click',
  (event: any) => {
    if (document.getElementsByClassName('popover-outside-close').length > 0) {
      const nxt = !!event.path.find((o: HTMLElement) => {
        return o.className?.includes?.('ant-popover-inner')
      })
      ClickObservable.next(nxt)
      if (!nxt) {
        event.stopPropagation()
      }
    }
  },
  {
    passive: true,
    capture: true,
  }
)
