import { Outlet } from 'react-router-dom'
import { useEffect } from 'react'
import { BreadcrumbObservable } from '../../application'

export const ProgramManage = () => {

  useEffect(() => {
    BreadcrumbObservable.next([])
  }, [])

  return (
    <div className="no-overflow-content">
      <Outlet />
    </div>
  )
}
