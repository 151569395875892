import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Card, Space, Button, Modal, message } from 'antd'

import { BreadcrumbObservable, SideMenuObservable } from '../../../application'
import { AgencyService, ResourceService } from '../../../apis/wl-service'
import {
  ResourceDevForm,
  RecordForm,
  InvestDecisionForm,
  ProjectProductionForm,
  ProjectCostForm,
  InvestPlanForm,
  PlanExecuteForm,
} from '../../project-entry/project-moudle-detail/components'

export default () => {
  const params: { id?: string; type?: string } = useParams()
  const nav = useNavigate()
  const [loading, setLoading] = useState<boolean>(false) // 页面加载状态
  const [dataSource, setDataSource] = useState<DataType>({} as DataType) // 详情数据源
  const [agree, setAgree] = useState<boolean>(false)

  useEffect(() => {
    SideMenuObservable.next(false)
    BreadcrumbObservable.next([
      {
        name: '投资开发',
        url: '/invest/workbench',
      },
      {
        name: '工作台',
        url: '/invest/workbench',
      },
      {
        name: params.type === '1' ? '待办事项' : '已办事项',
        url: `/invest/agency/${params.type === '1' ? 'todo' : 'done'}`,
      },
      {
        name: dataSource.project_name,
        url: `/invest/agency/${params.id}/${params.type}`,
      },
    ])
  }, [dataSource.project_name])

  useEffect(() => {
    setLoading(true)
    setAgree(params.type === '2') // 1.待办 2.已办

    AgencyService.info({ id: params.id ?? '' })
      .then((resp: any) => {
        setDataSource(resp?.data || [])
      })
      .catch(() => {})
      .finally(() => {
        setLoading(false)
      })
  }, [params])

  const verifyData = React.useCallback(
    (state: number) => {
      Modal.confirm({
        title: '提示',
        content: state === 1 ? '是否审批通过' : '是否退回修改',
        onOk: () => {
          ResourceService.verifyData({ id: dataSource.id, state })
            .then((resp: any) => {
              if (resp?.code === 200) {
                message.success(state === 1 ? '审核通过' : '退回修改成功')
              } else if (resp?.msg) {
                message.warn(resp.msg)
              }
            })
            .catch((e) => {
              console.warn(e)
            })
        },
        onCancel: () => {},
      })
    },
    [dataSource.id]
  )

  const content = React.useMemo(() => {
    const id = dataSource.id
    if (!id) return
    switch (dataSource?.module_name ?? '') {
      case '资源开发':
        return <ResourceDevForm id={id} editable={false} />
      case '核准备案':
        return <RecordForm id={id} editable={false} />
      case '投资决策':
        return <InvestDecisionForm id={id} editable={false} />
      case '项目建设':
        return <ProjectProductionForm id={id} editable={false} />
      case '项目造价':
        return <ProjectCostForm id={id} editable={false} />
      case '投资计划':
        return <InvestPlanForm id={id} editable={false} />
      case '计划执行':
        return <PlanExecuteForm id={id} editable={false} />
      default:
        return null
    }
  }, [dataSource.id, dataSource?.module_name])

  return (
    <Card
      bodyStyle={{ height: 'calc(100vh - 190px)', paddingTop: 0 }}
      className="no-border-bottom-card"
      loading={loading}
      style={{ height: '100%' }}
      title={<div style={{ fontWeight: 600, fontSize: 20 }}>项目详情</div>}
      extra={
        <Space>
          {agree && (
            <Button type="primary" onClick={() => verifyData(1)}>
              审批通过
            </Button>
          )}
          {agree && <Button onClick={() => verifyData(2)}>退回修改</Button>}
          <Button onClick={() => nav(-1)}>返回</Button>
        </Space>
      }
    >
      {content}
    </Card>
  )
}

export type DataType = {
  agency_name: string
  agency_type: number
  commit_name: string
  commit_organize: string
  commit_time: string
  data_id: string // agency id
  id: string // project id
  module_name: '资源开发' | '核准备案' | '投资决策' | '项目建设' | '项目造价' | '投资计划' | '计划执行'
  project_id: string
  project_name: string
  state: number // 审核状态0.未审核，1.通过，2.拒绝
  type: number
}
