// 政策法规模块 start **************************************************************************************************
// 政策类型
export type IPolicyType = {
  key: string
  id: string
  name: string
}
export const policyTypeOptions: IPolicyType[] = [
  { key: 'national', id: '0', name: '国家政策' },
  { key: 'group', id: '3', name: '集团政策' },
  { key: 'local', id: '6', name: '地方法规' },
  { key: 'standard', id: '9', name: '标准制度' },
  { key: 'news', id: '12', name: '新闻热点' },
]
// 政策法规模块 end **************************************************************************************************
