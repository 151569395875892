import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Dropdown, Form, Input, Menu, message, Modal, Space, Table, Tooltip } from 'antd'
import RemoveModal from '../RemoveModal'
import { ProjectRightsTemplate } from '../../type'
import { PermissionTemplateControllerService } from '../../../../../apis/project-service-history'
import { CustomIcons } from '../../../../../global'
import { NAME_REG } from '../../../../../utils/constant'
import IconMore from '../../../../../assets/more.png'

import './index.less'

enum OperationType {
  'view' = 0,
  'edit' = 1,
}

enum OperationName {
  '查看' = 0,
  '设置' = 1,
}

type UpdateParams = {
  id: number // 模板id
  templateName?: string // 模板名称修改时传
  defaultChecked?: boolean // 默认设置默认传
}

const ProjectList: React.FC<any> = forwardRef((_props, ref) => {
  const [removeVisible, setRemoveVisible] = useState<boolean>(false)
  const [renameVisible, setRenameVisible] = useState<boolean>(false)
  const [shouldCreate, setShouldCreate] = useState<boolean>(false)
  const [list, setList] = useState<ProjectRightsTemplate[]>([])
  const [loading, setLoading] = useState<boolean>(false)
  const [currTemplate, setCurrTemplate] = useState<ProjectRightsTemplate>()

  const [form] = Form.useForm()

  const navigate = useNavigate()

  const fetchList = () => {
    if (loading) return
    setLoading(true)
    PermissionTemplateControllerService.list1()
      .then((res) => {
        setLoading(false)
        const { data } = res || {}
        setList(data || [])
      })
      .catch(() => {
        setLoading(false)
      })
  }

  useImperativeHandle(ref, () => ({
    handleRename
  }))

  const addTemplate = (params: UpdateParams) => {
    if (!params?.id) return
    setLoading(true)
    PermissionTemplateControllerService.add({
      root: {
        ...params,
      },
    })
      .then((res) => {
        const { code, msg } = res || {}
        if (code === 200) {
          setRemoveVisible(false)
          message.success('拷贝成功')
          fetchList()
        } else {
          msg && message.error(msg)
        }
      })
      .catch(() => {
        setLoading(false)
      })
  }

  const updateTemplate = (params: UpdateParams) => {
    if (!params?.id) return
    setLoading(true)
    PermissionTemplateControllerService.update({
      root: {
        ...params,
      },
    })
      .then((res) => {
        const { code, msg } = res || {}
        if (code === 200) {
          setRenameVisible(false)
          handleFormReset()
          message.success('设置成功')
          fetchList()
        } else {
          msg && message.error(msg)
        }
      })
      .catch(() => {
        setLoading(false)
      })
  }

  const deleteById = (id: string) => {
    if (!id) return
    setLoading(true)
    PermissionTemplateControllerService.delete({
      id,
    })
      .then((res) => {
        const { code, msg } = res || {}
        if (code === 200) {
          setRemoveVisible(false)
          message.success('删除成功')
          fetchList()
        } else {
          msg && message.error(msg)
        }
      })
      .catch(() => {
        setLoading(false)
      })
  }

  const handleSet = (record: ProjectRightsTemplate) => {
    const { templateId } = record
    updateTemplate({
      id: templateId,
      defaultChecked: true,
    })
  }

  const handleFormReset = () => {
    form.resetFields(['templateName'])
  }

  const handleRename = (isCreate = false) => {
    setShouldCreate(isCreate)
    if (isCreate) {
      form?.setFieldsValue({templateName: ''})
    }
    setRenameVisible(true)
  }
  const handleRenameCancel = () => {
    setRenameVisible(false)
    handleFormReset()
  }
  const handleRenameOk = () => {
    form.validateFields().then((value) => {
      if (shouldCreate) {
        PermissionTemplateControllerService.addTemplateAndResources({
          root: {resourcesList: [], ...value},
        }).then((res) => {
            const { code, data, msg } = res || {}
            if (code === 200) {
              message.success('添加成功')
              navigate(`/user-center/rights-template/edit/${data}`)
            } else {
              msg && message.error(msg)
            }
          })
      } else {
        updateTemplate({
          id: currTemplate?.templateId,
          ...value,
        })
      }
    })
  }

  const handleCopy = (record: ProjectRightsTemplate) => {
    addTemplate({
      ...record,
      templateName: `${record?.templateName}（拷贝）`,
      id: record?.templateId,
    })
  }

  const handleRemove = () => {
    setRemoveVisible(true)
  }
  const handleRemoveCancel = () => {
    setRemoveVisible(false)
  }
  const handleRemoveOk = () => {
    deleteById(`${currTemplate?.templateId || ''}`)
  }

  const handleMenuClick = (key: string, record: ProjectRightsTemplate) => {
    setCurrTemplate(record)
    switch (key) {
      case '1':
        handleSet(record)
        break
      case '2':
        handleRename()
        break
      case '3':
        handleCopy(record)
        break
      case '4':
        handleRemove()
        break
    }
  }

  const getDropdownMenu = (record: ProjectRightsTemplate) => {
    const { defaultChecked, system } = record
    const items: any[] = []
    if (!system) {
      items.push(
        {
          label: '设置默认',
          key: '1',
          disabled: defaultChecked,
        },
        {
          label: '重命名',
          key: '2',
        },
        {
          label: '拷贝',
          key: '3',
        }
      )
    }
    if (!defaultChecked) {
      items.push({
        label: '删除',
        key: '4',
      })
    }
    return <Menu className="rights-template-menu" onClick={(e) => handleMenuClick(e.key, record)} items={items} />
  }

  const columns = [
    {
      title: '权限模板名称',
      dataIndex: 'templateName',
      key: 'templateName',
      render: (text, record) => {
        const { defaultChecked } = record
        return (
          <Space size={5}>
            <span>{text}</span>
            <Tooltip placement="top" title="默认模板">
              {defaultChecked && <CustomIcons className="primary-mark" type="klf-bookmark_added_filled_regular" />}
            </Tooltip>
          </Space>
        )
      },
    },
    {
      title: '操作',
      key: 'action',
      width: 64,
      render: (record: ProjectRightsTemplate) => {
        const { templateId, system } = record || {}
        const type = system ? 0 : 1
        return (
          <div className="rights-template-action">
            <Space size={12}>
              <div
                className="rights-template-action-set"
                onClick={() => navigate(`/user-center/rights-template/${OperationType[type]}/${templateId}`)}
              >
                {OperationName[type]}权限
              </div>
              {!system ? (
                <Dropdown placement="bottomRight" overlay={() => getDropdownMenu(record)} trigger={['click']}>
                  <img className="rights-template-action-operation" src={IconMore} alt="" />
                </Dropdown>
              ) : null}
            </Space>
          </div>
        )
      },
    },
  ]

  useEffect(() => {
    fetchList()
  }, [])

  useEffect(() => {
    form.setFieldsValue(currTemplate || {})
  }, [currTemplate])

  return (
    <div className="rights-template-list">
      <Table className="rights-template-table" columns={columns} dataSource={list} pagination={false} />
      {renameVisible && (
        <Modal
          className="authModal"
          title={shouldCreate?'新建权限':'重命名'}
          visible={renameVisible}
          destroyOnClose
          onOk={handleRenameOk}
          onCancel={handleRenameCancel}
        >
          <Form
            className="rights-template-form"
            form={form}
            initialValues={{ templateName: currTemplate?.templateName }}
            autoComplete="off"
          >
            <Form.Item
              label="权限名称"
              name="templateName"
              rules={[
                { required: true, message: '请输入模板名称' },
                { pattern: NAME_REG, message: '汉字、英文大小写、数字、-和_' },
              ]}
            >
              <Input placeholder={'请输入模板名称'} showCount maxLength={32} size="large" />
            </Form.Item>
          </Form>
        </Modal>
      )}
      {removeVisible && (
        <RemoveModal visible={removeVisible} title="删除提示" onOk={handleRemoveOk} onCancel={handleRemoveCancel}>
          <p>请确认是否删除{currTemplate?.templateName}模板</p>
        </RemoveModal>
      )}
    </div>
  )
})

export default ProjectList
