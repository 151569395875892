import React, { useState } from 'react'
import { Descriptions, Modal, Spin, Statistic, Table } from 'antd'
import { FeeService } from '../../../../apis/wl-service'

interface PayDetailDialogProps {
  id?: string
  visible: boolean
  visibleChange: (visible: boolean) => void
}

export default ({ id, visible, visibleChange }: PayDetailDialogProps) => {
  const [values, setValues] = useState<any>({})
  const [loading, setLoading] = useState<boolean>(false)

  React.useEffect(() => {
    if (id && visible) {
      setLoading(true)
      FeeService.payDetail({ pay_work_id: id })
        .then((resp: any) => {
          setValues(resp?.data ?? {})
        })
        .finally(() => {
          setLoading(false)
        })
    }
  }, [id, visible])

  const close = React.useCallback(() => {
    visibleChange(false)
  }, [])

  return (
    <Modal
      title="支付作业-明细"
      centered
      className="cost-manage-dialog"
      visible={visible}
      okText=""
      onCancel={close}
      width={1000}
      footer={null}
    >
      <Spin spinning={loading}>
        <Detail dataSource={values} />
      </Spin>
    </Modal>
  )
}

const Detail = ({ dataSource }) => {
  const list = React.useMemo(() => {
    return [
      {
        key: '1',
        title: '',
        column: 2,
        children: [
          { label: '项目名称', value: dataSource?.projectName },
          { label: '项目代码', value: dataSource?.projectCode },
          // { label: '费用类型', value: dataSource?.business_type },
          // { label: '款项', value: dataSource?.use_amount_type },
          { label: '支付单号', value: dataSource?.pay_work_no },
          { label: '合同号', value: dataSource?.contract_sn },
          {
            label: '合同金额',
            span: 2,
            value: (
              <Statistic title="" value={dataSource?.contract_amount ?? 0} precision={2} prefix="￥" suffix="元" />
            ),
          },
          {
            label: '用款申请',
            span: 2,
            value: <WithLoanTable dataSource={dataSource?.union_paywork_include_list ?? []} />,
          },
        ],
      },
      {
        key: '2',
        title: '付款方信息',
        column: 2,
        children: [
          { label: '付款方', value: dataSource?.payer },
          {
            label: '付款金额',
            value: <Statistic title="" value={dataSource?.pay_amount ?? 0} precision={2} prefix="￥" suffix="元" />,
          },
          { label: '支付用途', value: dataSource?.purpose },
          { label: '支付时间', value: dataSource?.business_time },
        ],
      },
      {
        key: '3',
        title: '收款方信息',
        column: 2,
        children: [
          { label: '收款方', value: dataSource?.payee },
          { label: '银联开户行', value: dataSource?.payee_open_bank_name },
          { label: '银联账号', value: dataSource?.payee_open_bank_card_no },
          { label: '联行号', value: dataSource?.payee_open_bank_no },
        ],
      },
    ]
  }, [dataSource])

  return (
    <>
      {list?.map(({ key, title, column, children }) => (
        <Descriptions key={key} title={title} bordered={false} column={column}>
          {children.map(({ label, value, span = 1 }: any, idx: number) => {
            return (
              <Descriptions.Item span={span} key={idx} label={label}>
                {value}
              </Descriptions.Item>
            )
          })}
        </Descriptions>
      ))}
    </>
  )
}

const WithLoanTable = ({ dataSource }) => {
  const columns = React.useMemo(() => {
    return [
      { dataIndex: 'use_amount_no', align: 'center', width: 600, title: '用款申请单号' },
      {
        dataIndex: 'pay_amount',
        align: 'center',
        width: 600,
        title: '实际用款金额',
        render: (text: string) =>
          text ? <Statistic title="" value={text ?? 0} precision={2} prefix="￥" suffix="元" /> : null,
      },
    ]
  }, [])

  return (
    <Table
      rowKey={(...args: any[]) => args[1]}
      bordered
      className="bordered-table"
      columns={columns as any[]}
      dataSource={dataSource || []}
      locale={{ emptyText: '暂无数据' }}
      pagination={false}
      size="small"
      summary={(pageData) => {
        let totalAmount = 0

        pageData.forEach(({ pay_amount }) => {
          totalAmount += +pay_amount
        })

        if (pageData.length === 0) {
          return null
        }

        return (
          <>
            <Table.Summary.Row className="table-stripe-row">
              <Table.Summary.Cell align="center" index={0}>
                合计
              </Table.Summary.Cell>
              <Table.Summary.Cell align="center" index={1} colSpan={1}>
                {totalAmount}
              </Table.Summary.Cell>
            </Table.Summary.Row>
          </>
        )
      }}
    />
  )
}
