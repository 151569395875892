import React from 'react'
import { Table, Tooltip, Typography, Badge } from 'antd'
import CustomPagination from '../Pagination'

import './index.less'

export type IProps = {
  list: any[]
  total: number
  pageNum: number
  pageSize: number
  onChange?: (pageNum: number, pageSize: number) => void
  changeList?: (params: any) => void
}

const PowerStationList = React.memo((props: IProps) => {
  const { list, total, pageNum, pageSize, onChange, changeList } = props

  const columns = [{
    title: '设备名称',
    dataIndex: 'labelName',
    width: 200,
    render: (text: string) =>
      text && (
        <Tooltip title={text}>
          <Typography.Text ellipsis>{text}</Typography.Text>
        </Tooltip>
      )
  }, {
    title: '设备类型',
    dataIndex: 'deviceType',
    width: 150,
    render: (text: string) =>
      text && (
        <Tooltip title={text}>
          <Typography.Text ellipsis>{text}</Typography.Text>
        </Tooltip>
      )
  }, {
    title: '所属场站',
    dataIndex: 'powerStationName',
    width: 100,
    render: (text: string) =>
      text && (
        <Tooltip title={text}>
          <Typography.Text ellipsis>{text}</Typography.Text>
        </Tooltip>
      )
  }, {
    title: '最近一次状态变化',
    dataIndex: 'updateTime',
    width: 180,
    render: (text: string) =>
      text && (
        <Tooltip title={text}>
          <Typography.Text ellipsis>{text}</Typography.Text>
        </Tooltip>
      )
  }, {
    title: '首次上线',
    dataIndex: 'upOnlineTime',
    width: 180,
    render: (text: string) =>
      text && (
        <Tooltip title={text}>
          <Typography.Text ellipsis>{text}</Typography.Text>
        </Tooltip>
      )
  }, {
    title: '状态',
    dataIndex: 'status',
    width: 100,
    render: (text: string) =>
      text && (
        <Badge color={text === '离线' ? '#98A0B3' : '#1ec692'} text={text} />
      )
  }, {
    title: '操作',
    dataIndex: 'operation',
    width: 120,
    render: (text, record: any) => (
      <Typography.Text ellipsis style={{color: '#1CB887', cursor: 'pointer'}} 
      onClick={() => {
        console.log(record, '===record')
        changeList?.(record)
      }}>测点数据查看</Typography.Text>
    )
  },]

  return (
    <section className="equipment-station">
      <div className="equipment-station-list" style={{minHeight: 'calc(100vh - 270px)'}}>
        <Table className="rights-template-table" rowKey="entityId" columns={columns} dataSource={list} pagination={false} />
      </div>
      <div className="equipment-station-footer">
        <CustomPagination
          total={total}
          pageNum={pageNum}
          pageSize={pageSize}
          onChange={(pageNum, pageSize) => onChange?.(pageNum, pageSize)}
        />
      </div>
    </section>
  )
})

export default PowerStationList
