import { useState,useEffect } from 'react'
import { Input, Tooltip } from 'antd'

import { UserService } from '../../../apis/project-service-history'
import { FormInstance } from 'antd/lib/form'
import styles from '../index.module.scss'

interface IProps {
  form: FormInstance
  onChange?: any
  type?: string
  setValidate?: any
  setVisible?: Function
  onSendCode?: Function
  sendFn?: Function
  disabled?: boolean
  disabledTip?: string
}

let timer: any
// 手机表单验证码
function VerifyPhone(props: IProps) {
  const { onChange, form, type, onSendCode, sendFn, disabled, disabledTip = '请先输入手机号' } = props
  // 倒计时
  const [count, setCount] = useState(-1)

  const sendCodeFn = (telephone: any, captchaVerification: any) => {
    if (!telephone || !captchaVerification) return
    const request = sendFn ? sendFn : type === 'login' ? UserService.sendLoginCode : UserService.sendRegisterCode
    setCount(60)
    timer = setInterval(() => {
      setCount((count: number) => count - 1)
    }, 1000)
    request({
      root: {
        telephone,
        captchaVerification,
      },
    })
      .then((res: any) => {
        if (res.code === 200) {
          props.setValidate({
            validateStatus: '  ',
            help: null,
          })
        } else {
          props.setValidate({
            validateStatus: 'error',
            help: res.msg,
          })
        }
      })
      .catch(() => {
        setCount(-1)
      })
  }

  useEffect(() => {
    if (count < 0) {
      clearInterval(timer)
    }
  }, [count])

  // 发送手机验证码
  const sendVerifyCode = async () => {
    onSendCode
      ? onSendCode(sendCodeFn)
      : (() => {
          const telephone = form.getFieldValue('telephone')
          const captchaVerification = form.getFieldValue('captchaVerification')

          if (telephone) {
            sendCodeFn(telephone, captchaVerification)
          } else {
            if (form) form.validateFields(['telephone'])
          }
        })()
  }

  return (
    <Input
      className={styles.verifyPhone}
      size="large"
      disabled={disabled}
      placeholder="请输入验证码"
      onChange={onChange}
      minLength={6}
      maxLength={6}
      suffix={
        count >= 0 ? (
          <span>{count}</span>
        ) : disabled ? (
          <Tooltip title={disabledTip}>发送验证码</Tooltip>
        ) : (
          <div onClick={sendVerifyCode}>
            <a>发送验证码</a>
          </div>
        )
      }
    />
  )
}

export default VerifyPhone
