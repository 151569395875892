import { List, Radio, Spin, Empty } from 'antd'
import React, { useEffect, useState } from 'react'
import { ProjectServiceHistory } from '../../../apis/project-service-history'
import moment from 'moment'
import styles from '../index.module.scss'
import { Link } from 'react-router-dom'
import Global from '../../../global'

type OperaTypes = 'RESOURCES' | 'PROJECT' | 'TECHNICAL' | 'FINANCE'

// 最近操作
const RecentActions = (props: any) => {
  const { data } = props

  const [loading, setLoading] = useState(false)
  const [dataSource, setDataSource] = useState(data || [])
  const [activeKey, setActiveKey] = useState<OperaTypes>('RESOURCES')

  useEffect(() => {
    setLoading(true)
    ProjectServiceHistory.getRecentlyLog({
      type: activeKey,
    })
      .then((res) => {
        setDataSource(res.data || [])
      })
      .catch(() => {})
      .finally(() => {
        setLoading(false)
      })
  }, [activeKey])

  return (
    <Spin spinning={loading}>
      <div
        className={`${styles.recentActions} ${styles.basePadding}`}
        style={{
          position: 'relative',
        }}
      >
        <h1>最近操作</h1>
        <Radio.Group
          size="small"
          value={activeKey}
          onChange={(e) => {
            setActiveKey(e.target.value)
          }}
        >
          <Radio.Button value="RESOURCES">资源</Radio.Button>
          <Radio.Button value="PROJECT">项目</Radio.Button>
          <Radio.Button value="TECHNICAL">技术方案</Radio.Button>
          <Radio.Button value="FINANCE">财务方案</Radio.Button>
        </Radio.Group>
        <div>
          <li className="ant-list-item title">
            <span>名称</span>
            <span>修改时间</span>
          </li>
        </div>
        <List
          size="small"
          className={`${styles.topListContent} custom-scroll-bar`}
          dataSource={dataSource}
          renderItem={(item: any) => (
            <List.Item
              className={item.type}
              extra={<span style={{
                whiteSpace: 'nowrap',
              }}>{item.type ? item.updateTime : moment(item.updateTime).format('YYYY-MM-DD HH:mm')}</span>}
            >
              <Link
                style={{
                  flexShrink: 1,
                  overflow: 'hidden',
                }}
                to={
                  activeKey === 'RESOURCES' ? `/resource/list`
                    : activeKey === 'PROJECT'
                    ? `/program/detail/${item.refId}`
                    : activeKey === 'TECHNICAL'
                    ? `/workspace/${item.refId}/tech`
                    : `/workspace/${item.refId}/fin`
                }
                onClickCapture={() => {
                  Global.changeMenu.forEach((Fn: any) => {
                    Fn(activeKey === 'RESOURCES' ? `resource/list` : activeKey === 'PROJECT' ? `program/manage` : 'home')
                  })
                }}
              >
                <div style={{
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                }}>{item.designation}</div>
              </Link>
            </List.Item>
          )}
        />
        {dataSource.length === 0 && <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
      </div>
    </Spin>
  )
}

export default RecentActions
