import { Button, Select, Spin, Tabs } from 'antd'
import React from 'react'
import ReactECharts from 'echarts-for-react'
import { ProjectServiceHistory } from '../../../../apis/project-service-history'
import { getAMap } from '../../../account-detail/components/map-script'
import { GaodeMap, Scene } from '@antv/l7'
import { useParams } from 'react-router-dom'
import moment from 'moment'
import * as echarts from 'echarts'
import '../index.less'
import { WeatherCharts } from './weather-charts'
import axios from 'axios'
import { token } from '../../../../utils/utils'
import Global, { CustomIcons } from '../../../../global'
import { BarChartOutlined, TableOutlined } from '@ant-design/icons'
const colorFields = [
  ['#FF574C', '#FF6B50', '#FF8056', '#FF945A', '#FFA860', '#FBBB66', '#C6BD86', '#91BFA6', '#5CC1C6', '#27C3E6'],
  ['#FF574C', '#4376EF', '#5478E7', '#778CC5', '#767CD9', '#8E85C8', '#AA92AE', '#C69F95', '#E3AD7D', '#FFBB64'],
  ['#1EC692', '#37C58D', '#50C387', '#69C283', '#82C17D', '#9BC078', '#B4BF74', '#CDBD6E', '#E6BC69', '#FFBB64'],
]

const radius = 0.7

const colorArrs: { [key: string]: string[] } = {
  GHI: colorFields[0],
  DNI: colorFields[0],
  TMP: colorFields[0],
  DHI: colorFields[0],
  SUNRISE: colorFields[0],
  SUNSET: colorFields[0],
  WD: colorFields[0],
  RH: colorFields[1],
  RAINFALL: colorFields[1],
  'SUNRISE,SUNSET': ['#3274F6', '#D65D6E', '#FF574C', '#8468B2'],
  WS: colorFields[2],
  PRES: colorFields[2],
}

const weatherChartTypes: { [key: string]: number } = {
  'SUNRISE,SUNSET': 1,
}

const getWeatherChartType = (key: string) => {
  return weatherChartTypes[key] || 0
}

const formatTimeFloat = (hour: number, zh = false) => {
  const minute = (hour - Math.floor(hour)) * 60
  const second = (minute - Math.floor(minute)) * 60
  const time = moment(`2000-01-01 ${Math.floor(hour)}:${Math.floor(minute)}:${Math.floor(second)}`)
  return time.format(zh ? 'HH小时mm分ss秒' : 'HH:mm:ss')
}

export const Weather = () => {
  const params = useParams()

  const [selected, setSelected] = React.useState('1')
  const [config, setConfig] = React.useState<any[]>()

  // const [map, setMap] = React.useState<any>()
  const [heatMap, setHeatMapData] = React.useState<any>()

  const [loadingHeatMap, setLoadingHeatMap] = React.useState(false)
  const [currentSelectedType, setCurrentSelectedType] = React.useState<string>()
  const [currentSelectedTypeName, setCurrentSelectedTypeName] = React.useState<string>()
  const [currentSelectedUnit, setCurrentSelectedUnit] = React.useState<string>()

  const [position, setPosition] = React.useState<number[]>()

  const [isTable, setIsTable] = React.useState(false)

  const auth = Global.projectAuth || {}

  React.useEffect(() => {
    if (currentSelectedType) {
      loadHeatmapData(currentSelectedType)
    }
  }, [currentSelectedType])

  React.useEffect(() => {
    ProjectServiceHistory.getCaseList()
      .then((resp) => {
        setConfig(resp.data)
        setLoadingHeatMap(true)
        setCurrentSelectedType('TMP')
      })
      .catch(() => {})
    ProjectServiceHistory.get({
      projectId: params.id!,
    }).then((resp) => {
      const { longitude, latitude } = resp.data
      setPosition([parseFloat(longitude), parseFloat(latitude)])
    })
  }, [])

  const doDownload = () => {
    setLoading(true)
    const prefix = axios.defaults.baseURL
    const url = `${prefix || ''}/api/i3060/weather/download/${params.id!}`
    const authToken: string = token.get() || ''
    fetch(url, {
      method: 'GET',
      headers: {
        Authorization: authToken,
        'Content-Type': 'application/json;charset=UTF-8',
      },
    })
      .then((resp) => {
        let fileName =
          resp.headers.get('content-disposition')?.split(';')[1].split('filename=')[1] || `template-${Date.now()}.xlsx`
        fileName = decodeURIComponent(fileName)
        resp.blob().then((blob) => {
          if ((window.navigator as any).msSaveOrOpenBlob) {
            ;(window.navigator as any).msSaveBlob(blob, fileName)
          } else {
            const a = document.createElement('a')
            document.body.appendChild(a)
            const url = window.URL.createObjectURL(blob)
            a.href = url
            a.download = fileName
            a.target = '_blank'
            a.click()
            a.remove()
            window.URL.revokeObjectURL(url)
          }
        })
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const loadHeatmapData = (type: string) => {
    setLoadingHeatMap(true)
    setHeatMapData(undefined)
    ProjectServiceHistory.getShow({
      projectId: params.id!,
      weatherElement: type,
    })
      .then((resp: any) => {
        const res: any = { x: [], y: [], values: [] }
        const vals: any = resp.data && Object.values(resp.data)
        if (vals && vals[0].length === 2) {
          res.x = Object.keys(resp.data)
          res.x.forEach((date: string) => {
            res.values.push(resp.data[date])
          })
        } else {
          Object.keys(resp.data).forEach((o: string) => {
            const time = moment(o)
            const value = resp.data[o]
            const x = time.format('MM-DD')
            const y = time.format('HH:mm:ss')
            let xIdx = res.x.indexOf(x)
            let yIdx = res.y.indexOf(y)
            if (xIdx < 0) {
              res.x.push(x)
              xIdx = res.x.indexOf(x)
            }
            if (yIdx < 0) {
              res.y.push(y)
              yIdx = res.y.indexOf(y)
            }

            res.values.push([xIdx, yIdx, parseFloat(value)])
          })
        }
        setHeatMapData(res)
        config?.forEach((o: any) => {
          if (o.type === type) {
            setCurrentSelectedTypeName(o.weather)
            setCurrentSelectedUnit(o.unit)
          }
        })
      })
      .catch(() => {})
      .finally(() => {
        setLoadingHeatMap(false)
      })
  }

  React.useEffect(() => {
    if (selected === '1') {
      return
    }
    setTimeout(() => {
      getAMap().then((AMap) => {
        const _map = new AMap.Map('project-address', {
          resizeEnable: true,
          center: position,
          zoom: 13,
          mapStyle: 'amap://styles/05e321edfe9e69b5902e4dc372c36fda',
        })

        new Scene({
          id: 'project-address',
          map: new GaodeMap({
            mapInstance: _map,
          }),
        })

        const marker = new AMap.Marker({
          position: position,
        })

        _map.add(marker)

        // setMap(_map)
      })
    })
  }, [selected])

  const [loading, setLoading] = React.useState(false)

  return (
    <Spin spinning={loading}>
      <Tabs
        onChange={(key: string) => setSelected(key)}
        activeKey={selected}
        tabBarExtraContent={
          selected === '1'
            ? config && (
                <Select
                  disabled={loadingHeatMap}
                  style={{
                    width: '15rem',
                  }}
                  onChange={(val: string) => {
                    setLoadingHeatMap(true)
                    setCurrentSelectedType(val)
                  }}
                  value={currentSelectedType}
                >
                  {config?.map((o: any) => (
                    <Select.Option value={o.type} key={o.type}>
                      {o.weather}
                    </Select.Option>
                  ))}
                </Select>
              )
            : selected === '3' && (
                <div>
                  <Button
                    onClick={() => {
                      setIsTable((table) => !table)
                    }}
                  >
                    {isTable ? <BarChartOutlined /> : <TableOutlined />}
                    {isTable ? '图表' : '表格'}
                  </Button>
                  <Button
                    type={'primary'}
                    disabled={!auth.weather_data}
                    style={{
                      marginLeft: '.5rem',
                    }}
                    onClick={() => {
                      doDownload()
                    }}
                  >
                    下载天气数据
                    <CustomIcons type={'klf-download'} />
                  </Button>
                </div>
              )
        }
      >
        <Tabs.TabPane tab="天气展示" key="1">
          {selected === '1' && (
            <div
              className="spin-full"
              style={{
                width: '100%',
                position: 'relative',
                height: 'calc(100vh - 360px)',
                maxHeight: 450,
                minHeight: 300,
              }}
            >
              <Spin
                spinning={loadingHeatMap}
                style={{
                  height: '100%',
                  position: 'relative',
                }}
              >
                <div
                  style={{
                    position: 'absolute',
                    right: 0,
                    top: 0,
                  }}
                >
                  天气来源：<span style={{ color: 'grey' }}>TPDC、ECMWF</span>
                </div>
                {loadingHeatMap && <div style={{ minHeight: 100 }}></div>}
                {!loadingHeatMap && !heatMap && <div>暂无数据</div>}
                {!loadingHeatMap && heatMap && currentSelectedType && getWeatherChartType(currentSelectedType) !== 0 && (
                  <ReactECharts
                    style={{ height: '100%', width: '100%' }}
                    option={{
                      grid: { left: '0px', right: '3%', top: '30px', bottom: '30px', containLabel: true },
                      tooltip: {
                        axisPointer: {
                          type: 'cross',
                          label: {
                            formatter: (args: any) => {
                              if (args.axisDimension === 'x') {
                                return args.value.split('-').slice(1, 3).join('-')
                              }
                              if (args.axisDimension === 'y') {
                                return formatTimeFloat(args.value)
                              }
                              return ''
                            },
                          },
                        },
                        formatter: (args: any) => {
                          const [startTime, endTime] = heatMap.values[args.dataIndex]
                          return `<div style="width: 200px;display: flex;flex-direction: row; justify-content: space-between">${moment(
                            args.name
                          ).format(
                            'MM-DD'
                          )}</div><div style="display: flex;flex-direction: row; justify-content: space-between"><span>${
                            args.marker
                          }日出时间</span>${formatTimeFloat(
                            startTime
                          )}</div><div style="display: flex;flex-direction: row; justify-content: space-between"><span>${
                            args.marker
                          }日落时间</span>${formatTimeFloat(
                            endTime
                          )}</div><div style="display: flex;flex-direction: row; justify-content: space-between"><span>${
                            args.marker
                          }昼长</span>${formatTimeFloat(endTime - startTime, true)}</div>`
                        },
                      },
                      yAxis: {
                        type: 'value',
                        min: 0,
                        max: 24,
                        splitNumber: 24,
                        axisLabel: {
                          formatter: (str: string) => {
                            return `${str}:00:00`
                          },
                        },
                        splitArea: {
                          show: false,
                        },
                      },
                      xAxis: {
                        type: 'category',
                        data: heatMap.x,
                        silent: false,
                        splitLine: {
                          show: false,
                        },
                        splitArea: {
                          show: false,
                        },
                        axisLabel: {
                          formatter: (str: string) => {
                            return `${moment(str).format('MM-DD')}`
                          },
                        },
                      },
                      series: [
                        {
                          name: '日出前',
                          type: 'bar',
                          stack: 'Total',
                          itemStyle: {
                            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                              { offset: 1, color: colorArrs['SUNRISE,SUNSET'][0] },
                              { offset: radius, color: colorArrs['SUNRISE,SUNSET'][0] },
                              { offset: 0, color: colorArrs['SUNRISE,SUNSET'][1] },
                            ]),
                            borderColor: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                              { offset: 1, color: colorArrs['SUNRISE,SUNSET'][0] },
                              { offset: radius, color: colorArrs['SUNRISE,SUNSET'][0] },
                              { offset: 0, color: colorArrs['SUNRISE,SUNSET'][1] },
                            ]),
                          },
                          barGap: '0%',
                          barCategoryGap: '0%',
                          data: heatMap.values.map((o: any) => o[0]),
                        },
                        {
                          name: '白天',
                          type: 'bar',
                          stack: 'Total',
                          itemStyle: {
                            borderColor: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                              { offset: 1, color: colorArrs['SUNRISE,SUNSET'][1] },
                              { offset: 1 - radius, color: colorArrs['SUNRISE,SUNSET'][2] },
                              { offset: radius, color: colorArrs['SUNRISE,SUNSET'][2] },
                              { offset: 0, color: colorArrs['SUNRISE,SUNSET'][3] },
                            ]),
                            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                              { offset: 1, color: colorArrs['SUNRISE,SUNSET'][1] },
                              { offset: 1 - radius, color: colorArrs['SUNRISE,SUNSET'][2] },
                              { offset: radius, color: colorArrs['SUNRISE,SUNSET'][2] },
                              { offset: 0, color: colorArrs['SUNRISE,SUNSET'][3] },
                            ]),
                          },
                          barGap: '0%',
                          barCategoryGap: '0%',
                          data: heatMap.values.map((o: any) => o[1] - o[0]),
                        },
                        {
                          name: '日落',
                          type: 'bar',
                          stack: 'Total',
                          itemStyle: {
                            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                              { offset: 1, color: colorArrs['SUNRISE,SUNSET'][3] },
                              { offset: 1 - radius, color: colorArrs['SUNRISE,SUNSET'][0] },
                              { offset: 0, color: colorArrs['SUNRISE,SUNSET'][0] },
                            ]),
                            borderColor: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                              { offset: 1, color: colorArrs['SUNRISE,SUNSET'][3] },
                              { offset: 1 - radius, color: colorArrs['SUNRISE,SUNSET'][0] },
                              { offset: 0, color: colorArrs['SUNRISE,SUNSET'][0] },
                            ]),
                            barGap: '0%',
                            barCategoryGap: '0%',
                          },
                          data: heatMap.values.map((o: any) => 24 - parseFloat(o[1])),
                        },
                      ],
                    }}
                  />
                )}
                {!loadingHeatMap && heatMap && currentSelectedType && getWeatherChartType(currentSelectedType) === 0 && (
                  <ReactECharts
                    style={{ height: '100%', width: '100%' }}
                    option={{
                      grid: { left: '0px', right: '150px', top: '30px', bottom: '30px', containLabel: true },
                      tooltip: {
                        // trigger: 'axis',
                        // position: 'top',
                        // axisPointer: {
                        //   type: 'cross',
                        //   crossStyle: {
                        //     shadowColor: 'black',
                        //     shadowBlur: 10,
                        //   },
                        // },
                        formatter: (args: any) => {
                          const y = heatMap.y[args.value[1]]
                          return `${args.marker} ${args.seriesName} ${args.name} ${y} : ${args.value[2]} ${currentSelectedUnit}`
                        },
                      },
                      yAxis: {
                        type: 'category',
                        data: heatMap.y,
                      },
                      xAxis: {
                        type: 'category',
                        data: heatMap.x,
                      },
                      visualMap: {
                        type: 'continuous',
                        min: currentSelectedType === 'WD' ? 0 : Math.max(...heatMap.values.map((o: number[]) => o[2])),
                        max:
                          currentSelectedType === 'WD' ? 360 : Math.min(...heatMap.values.map((o: number[]) => o[2])),
                        align: 'left',
                        calculable: true,
                        orient: 'vertical',
                        left: 'right',
                        top: 'center',
                        bottom: '15%',
                        show: true,
                        color:
                          currentSelectedType === 'WD'
                            ? ['#FFFF00', '#FF0000', '#FF00FF', '#0000FF', '#00FFFF', '#00FF00', '#FFFF00'].reverse()
                            : colorArrs[currentSelectedType] || colorFields[0],
                        formatter: (value: number) => {
                          return `${parseFloat(value.toFixed(2))} ${currentSelectedUnit}`
                        },
                      },
                      series: [
                        {
                          name: currentSelectedTypeName,
                          type: 'heatmap',
                          data: heatMap.values,
                          label: {
                            show: false,
                          },
                          large: true,
                        },
                      ],
                    }}
                  />
                )}
              </Spin>
            </div>
          )}
        </Tabs.TabPane>

        <Tabs.TabPane tab="数据汇总" key="3">
          {selected === '3' && (
            <WeatherCharts projectId={params.id!} isTable={isTable} disabledDownload={!auth.weather_data} />
          )}
        </Tabs.TabPane>

        <Tabs.TabPane tab="项目地址" key="2">
          {selected === '2' && (
            <div
              id="project-address"
              style={{ width: '100%', height: 'calc(100vh - 360px)', position: 'relative' }}
            ></div>
          )}
        </Tabs.TabPane>
      </Tabs>
    </Spin>
  )
}
