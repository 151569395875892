import React, { useEffect, useRef, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Button, Space, Tooltip, Typography } from 'antd'
import { EditOutlined, PlusOutlined } from '@ant-design/icons'

import { CommonService, dynamicText, PlanExecuteService, ResponseOptionType } from '../../../apis/wl-service'
import TablePage, { getColumnFilterProps, getActionColumn, TablePageInstance } from '../../../components/wl-table-page'
import ModifyApplyDialog from '../common/modify-apply-dialog'
import commit from '../common/commit'
import useBreadcrumbs from '../common/breadcrumb-hooks'

export default () => {
  const nav = useNavigate()
  const location = useLocation()
  const tableRef = useRef<TablePageInstance>(null)
  const [dataSet, setDataSet] = useState<{
    moduleId: string
    resourceTypeOptions: ResponseOptionType[]
  }>({
    moduleId: '7',
    resourceTypeOptions: [],
  }) // 选择项字段存储
  const [modifyApplyDialog, setModifyApplyDialog] = useState<boolean>(false)
  const [{ breadcrumb, title, editable }] = useBreadcrumbs(dataSet.moduleId)

  // 获取选择器字典
  useEffect(() => {
    CommonService.getDictionaryDataSelectorValid('15301742e5ec4e92843192cb76010e08')
      .then((resp: any) => {
        setDataSet((previous) => ({ ...previous, resourceTypeOptions: resp?.data?.list || [] }))
      })
      .catch(() => {
      })
  }, [])

  const jumpDetail = (row?: any, editable?: boolean) => {
    if (typeof editable === 'boolean') {
      // 跳到当前页面的详情页
      nav(`/invest/project/${editable ?? true}${row?.id ? '/' + row.id : ''}?moduleId=${dataSet.moduleId}`, { state: location.state })
    } else {
      // 跳到项目详情页
      nav(`/invest/projectDetail/${row.project_id}`, { state: location.state })
    }
  }

  // 表格列配置
  const columns = (tableParams: any) => [
    {
      title: '项目名称',
      dataIndex: 'project_name',
      width: 120,
      fixed: 'left' as any,
      ...getColumnFilterProps({
        type: 'input',
        placeholder: '请输入项目名称',
        value: tableParams.project_name,
        onSearch: (value) => {
          tableRef.current?.reload?.({
            project_name: value,
            page: 1,
          })
        },
      }),
      render: (text: string, row: any) =>
        text && (
          <Tooltip title={text}>
            <Typography.Link ellipsis onClick={() => jumpDetail(row)}>
              {text}
            </Typography.Link>
          </Tooltip>
        ),
    },
    {
      title: '项目代码',
      dataIndex: 'project_code',
      width: 100,
      render: (text: string) =>
        text && (
          <Tooltip title={text}>
            <Typography.Text ellipsis>{text}</Typography.Text>
          </Tooltip>
        ),
    },
    {
      title: '项目类型',
      dataIndex: 'project_type',
      width: 100,
      render: (text: any) => {
        return dynamicText(text, dataSet.resourceTypeOptions)
      },
    },
    {
      title: '执行时间',
      dataIndex: 'write_month',
      width: 100,
    },
    getActionColumn([
      {
        key: 'detail',
        label: '详情',
        onClick: (row: any) => {
          jumpDetail(row, false)
        },
      },
      {
        key: 'edit',
        label: '编辑',
        visible: (row: any) => editable && row.is_commit != 1,
        onClick: (row: any) => {
          jumpDetail(row, row.is_commit != 1)
        },
      },
      {
        key: 'commit',
        label: '提交',
        visible: (row: any) => editable && row.is_commit != 1,
        onClick: (row: any) => {
          commit({
            params: {
              moduleId: dataSet.moduleId,
              id: row.project_id,
              dataId: row.id,
            },
            success: () => tableRef.current?.reload?.(),
          })
        },
      },
      {
        key: 'delete',
        type: 'delete',
        visible: (row: any) => editable && row.is_commit != 1,
        getter: (row: any) => {
          return PlanExecuteService.remove({ id: row.id })
        },
        success: (arg: any) => tableRef.current?.reload?.(arg),
      },
    ]),
  ]

  return (
    <TablePage
      ref={tableRef}
      title={title}
      extra={
        !editable ? null : (
          <Space>
            <Button type="primary" icon={<PlusOutlined />} onClick={() => jumpDetail(undefined, true)}>
              新增执行
            </Button>
            <Button
              icon={<EditOutlined />}
              onClick={() => {
                setModifyApplyDialog(true)
              }}
            >
              申请修改
            </Button>
          </Space>
        )
      }
      breadcrumb={breadcrumb}
      columns={columns}
      rowKey="id"
      getter={(arg: any) => PlanExecuteService.list(arg)}
    >
      <ModifyApplyDialog
        moduleId={dataSet.moduleId}
        visible={modifyApplyDialog}
        close={() => setModifyApplyDialog(false)}
        success={() => {
          tableRef.current?.reload?.()
        }}
      />
    </TablePage>
  )
}
