import React, { useEffect, useRef, useState } from 'react'
import { Button, message, Space, Tabs } from 'antd'
import { useNavigate } from 'react-router-dom'

import CardTitle from '../../../user-center/rights-template/components/CardTitle'
import StepBar from '../components/StepBar'
import BasicInfo from './BasicInfo'
import ChannelInfo from './ChannelInfo'
import Complete from './Complete'
import { PowerStationDevice, PowerStationNode, ProjectItem, StationItem } from '../type'
import PowerStationControllerApi from '../../../../services/api/PowerStationControllerApi'
import AssetsTreeApi from '../../../../services/api/AssetsTreeApi'
import {BreadcrumbObservable} from "../../../../application";
import moment from 'moment'

import '../index.less'

const { TabPane } = Tabs

const PowerStation: React.FC = React.memo(() => {
  const [loading, setLoading] = useState<boolean>(false)
  const [currentStep, setStep] = useState<number>(0)
  const [activeKey, setActiveKey] = useState<string>('0')
  const [station, setStation] = useState<StationItem>(null)
  const [projectList, setProjectList] = useState<ProjectItem[]>([])
  const [nodeList, setNodeList] = useState<PowerStationNode[]>([])
  const [stationDevice, setStationDevice] = useState<PowerStationDevice>(null)
  const [thingxAssetTreeId, setThingxAssetTreeId] = useState<any>()

  const step1Ref = useRef(null)
  const step2Ref = useRef(null)

  useEffect(() => {
    BreadcrumbObservable.next([])
  }, [])

  const navigate = useNavigate()

  const goBack = () => {
    navigate(-1)
  }

  const goDetail = () => {
    if (station?.powerStationId) {
      navigate(`/operation-management/power-station/detail/${station?.powerStationId}`)
    }
  }

  const fetchProjectList = () => {
    setLoading(true)
    PowerStationControllerApi.i3060ProjectProjectSelectGet({})
      .then((res) => {
        setLoading(false)
        const { code, data } = res || {}
        if (code === 200) {
          setProjectList(data || [])
        }
      })
      .catch(() => {
        setLoading(false)
      })
  }

  const createStation = (params: StationItem) => {
    setLoading(true)
    PowerStationControllerApi.i3060PowerStationCreatePost(params)
      .then((res) => {
        setLoading(false)
        const { code, msg, data } = res || {}
        console.log('🚀 ~ file: index.tsx ~ line 52 ~ .then ~ data', data)
        if (code === 200) {
          message.success('添加成功')
          setStation({ ...station, powerStationId: data })
          handleStepChange(2)
        } else {
          msg && message.error(msg)
        }
      })
      .catch(() => {
        setLoading(false)
      })
  }

  const fetchNodeList = () => {
    setLoading(true)
    AssetsTreeApi.i3060AssetsTreeGetPowerStationGet({ projectId: `${station.projectId}` })
      .then((res) => {
        setLoading(false)
        const { code, data } = res || {}
        if (code === 200) {
          setNodeList(data || [])
        }
      })
      .catch(() => {
        setLoading(false)
      })
  }

  const fetchDeviceList = (params: any) => {
    setLoading(true)
    AssetsTreeApi.i3060AssetsTreeGetDeviceGet(params)
      .then((res) => {
        setLoading(false)
        const { code, data } = res || {}
        if (code === 200) {
          setStationDevice(data)
        }
      })
      .catch(() => {
        setLoading(false)
      })
  }

  const handleFetchDevice = (value: any) => {
    if (value) {
      const node = nodeList?.find(({ thingxPowerStationId }) => thingxPowerStationId === value)
      setThingxAssetTreeId(node.treeId)
      node && fetchDeviceList({ thingxTreeId: node.treeId, thingxPowerStationId: node.thingxPowerStationId })
    }
  }

  const handleStepChange = (current: number) => {
    setStep(current)
    setActiveKey(`${current}`)
  }

  const handleCancel = () => {
    if (currentStep === 0) {
      goBack()
    } else if (currentStep > 0) {
      handleStepChange(currentStep - 1)
    }
  }

  const handleSubmit = () => {
    switch (currentStep) {
      case 0:
        step1Ref.current?.submit()
        break
      case 1:
        step2Ref.current?.submit()
        break
      default:
        break
    }
  }

  const handleFinish = (step: number, values?: any) => {
    console.log('🚀 ~ file: index.tsx ~ line 61 ~ handleFinish ~ step', step, values)
    const params = { ...station, ...values, thingxAssetTreeId }
    params.gridConnectionDate = moment(params.gridConnectionDate).format('YYYY-MM-DD')
    setStation(params)
    switch (step) {
      case 0:
        handleStepChange(step + 1)
        break
      case 1:
        createStation(params)
        break
    }
  }

  const handleFailed = (step: number, err?: any) => {
    console.log('🚀 ~ file: index.tsx ~ line 66 ~ handleFailed ~ step', step, err)
    handleStepChange(step)
  }

  const handleReset = () => {
    console.log('🚀 ~ file: index.tsx ~ line 107 ~ handleReset ~ handleReset')
    setStep(0)
    setActiveKey('0')
    setStation(null)
    setProjectList([])
    setNodeList([])
    setStationDevice(null)
    step1Ref.current?.reset()
    step2Ref.current?.reset()
    fetchProjectList()
  }

  useEffect(() => {
    fetchProjectList()
  }, [])

  useEffect(() => {
    console.log('🚀 ~ file: index.tsx ~ line 146 ~ useEffect ~ projectId', station?.projectId, currentStep)
    if (currentStep === 1 && station?.projectId) {
      fetchNodeList()
    }
  }, [currentStep, station?.projectId])

  return (
    <section className="power-station-create">
      <div className="power-station-create-header">
        <CardTitle title="电站状况" />
      </div>
      <div className="power-station-create-content">
        <StepBar current={currentStep} />
        <Tabs activeKey={activeKey} renderTabBar={() => null}>
          <TabPane key="0">
            <BasicInfo
              ref={step1Ref}
              projectList={projectList}
              onSubmit={(values) => handleFinish(0, values)}
              onFailed={(err) => handleFailed(0, err)}
            />
          </TabPane>
          <TabPane key="1">
            <ChannelInfo
              ref={step2Ref}
              nodeList={nodeList}
              stationDevice={stationDevice}
              onFetch={handleFetchDevice}
              onSubmit={(values) => handleFinish(1, values)}
              onFailed={(err) => handleFailed(1, err)}
            />
          </TabPane>
          <TabPane key="2">
            <Complete onBack={goBack} onView={goDetail} onCreate={handleReset} />
          </TabPane>
        </Tabs>
      </div>
      {currentStep < 2 && (
        <div className="power-station-create-footer">
          <Space size={10}>
            <Button loading={loading} size="large" onClick={handleCancel}>
              {currentStep > 0 ? '上一步' : '取消'}
            </Button>
            <Button loading={loading} type="primary" size="large" onClick={handleSubmit}>
              {currentStep > 0 ? '确认创建' : '下一步'}
            </Button>
          </Space>
        </div>
      )}
    </section>
  )
})

export default PowerStation
