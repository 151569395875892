import React from 'react'
import * as echarts from 'echarts'
import moment from 'moment'

export type PieDataType = {
  name: string
  value: string | number
  percentage?: number | string
  itemStyle?: React.CSSProperties
}

export type RangeValue = [moment.Moment | null, moment.Moment | null] | null
export type BarConfigType = {
  xAxisType?: 'year' | 'month' | 'week' | 'day' | RangeValue
  axis: string[]
}

export type BarDataType = {
  name: string
  value: Array<number | string>
  color: string
}

export default {
  // 收资情况,备案情况
  pie: (title: string, data: PieDataType[]) => {
    const option = {
      title: {
        text: title,
        left: 'center',
        top: 'center',
        textStyle: {
          color: '#333',
          fontWeight: 'bold',
          fontSize: 14,
        },
      },
      tooltip: {
        trigger: 'item',
        // formatter: "{a} <br/>{b} : {c} ({d}%)"
        formatter: '{b} : {c}项目',
      },
      series: [
        {
          type: 'pie',
          radius: ['35%', '50%'],
          center: ['50%', '50%'],
          label: {
            normal: {
              position: 'outside',
              // alignTo: 'edge', // 标签的对齐方式，文字对齐，文字的边距由 label.edgeDistance 决定。
              textStyle: {
                color: '#000',
              },
              formatter: '{name|{b}}\n{time|{c} 项目}',
              // minMargin: 5,
              edgeDistance: 0,
              lineHeight: 15,

              rich: {
                time: {
                  fontSize: 10,
                  color: 'inherit',
                },
              },
            },
          },
          data,
          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: 'rgba(0, 0, 0, 0.5)',
            },
          },
        },
      ],
    }
    return option
  },

  // 合作伙伴签约, 客户签约
  signing: (data: BarDataType[], config?: BarConfigType) => {
    // const format = 'YYYY/MM/DD'
    let xAxis: string[] = config?.axis ?? []
    // switch (config?.xAxisType) {
    //   case 'year':
    //     for (let i = 0, m = moment().month(); i <= m; i++) {
    //       xAxis.push(`${i + 1}月`)
    //     }
    //     break

    //   case 'month':
    //     for (let i = 1, d = moment().date(); i <= d; i++) {
    //       xAxis.push(moment().date(i).format(format))
    //     }
    //     break

    //   case 'week':
    //     // 星期日为 0、星期六为 6
    //     // eslint-disable-next-line
    //     const wm = new Map([
    //       [7, '周日'],
    //       [1, '周一'],
    //       [2, '周二'],
    //       [3, '周三'],
    //       [4, '周四'],
    //       [5, '周五'],
    //       [6, '周六'],
    //     ])
    //     for (let i = 1, d = moment().day(), end = d === 0 ? 7 : d; i <= end; i++) {
    //       xAxis.push(wm.get(i) as string)
    //     }
    //     break

    //   case 'day':
    //     xAxis = [moment().format(format)]
    //     break

    //   default:
    //     // eslint-disable-next-line
    //     const [start, end] = config?.xAxisType ?? []
    //     if (start && end) {
    //       let current = moment(start)
    //       while (current && end.diff(current, 'days') >= 0) {
    //         xAxis.push(current.format(format))
    //         current = current.add(1, 'days')
    //       }
    //     }
    // }

    let option = {
      title: {
        text: '合同签约状态',
        left: 5, // title 组件离容器左侧的距离
        textAlign: 'left',
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          // 坐标轴指示器，坐标轴触发有效
          type: 'shadow', // 默认为直线，可选为：'line' | 'shadow'
          label: {
            show: true,
          },
        },
      },
      calculable: true,
      legend: {
        show: true,
        itemGap: 10,
        right: '5%',
        icon: 'circle',
      },
      grid: {
        top: '20%',
        left: '1%',
        right: '5%',
        // bottom: 0,
        containLabel: true,
      },
      xAxis: [
        {
          type: 'category',

          data: xAxis,
          axisLabel: {
            // interval: 0,
            // rotate: 40,
            textStyle: {
              fontFamily: 'Microsoft YaHei',
            },
          },
        },
      ],
      yAxis: {
        type: 'value',
        axisLabel: {},
        axisLine: {
          show: false,
        },
        splitLine: {
          show: true,
          lineStyle: {
            color: '#ddd',
            type: 'dashed',
          },
        },
      },
      dataZoom: [
        {
          show: false,
          height: 12,
          zoomOnMouseWheel: false,
          xAxisIndex: [0],
          bottom: '8%',
          start: 0,
          end: 100,
          handleIcon:
            'path://M306.1,413c0,2.2-1.8,4-4,4h-59.8c-2.2,0-4-1.8-4-4V200.8c0-2.2,1.8-4,4-4h59.8c2.2,0,4,1.8,4,4V413z',
          handleSize: '110%',
          handleStyle: {
            color: '#d3dee5',
          },
          textStyle: {
            color: '#000',
          },
          borderColor: '#90979c',
        },
        {
          type: 'inside',
          show: false,
          height: 15,
          start: 1,
          end: 35,
        },
        {
          show: true,
          xAxisIndex: [0, 1],
          type: 'slider',
          top: '85%',
          start: 98,
          end: 100,
        },
        {
          show: false,
          yAxisIndex: 0,
          filterMode: 'empty',
          width: 30,
          height: '80%',
          showDataShadow: false,
          left: '93%',
        },
      ],
      series: data.map((item) => ({
        name: item.name,
        type: 'bar',
        itemStyle: {
          normal: {
            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
              {
                offset: 0,
                color: item.color ?? '#F47A8F',
              },
            ]),
            // barBorderRadius: 12,
          },
        },
        label: {
          show: true, //开启显示
          position: 'top', //在上方显示
          textStyle: {
            //数值样式
            color: 'inherit',
            fontSize: 12,
          },
        },
        data: item.value,
      })),
    }
    return option
  },
}
