import React from 'react'
import { Button, Col, Input, Row, Select } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import { CustomIcons } from '../../../../global'

interface TransformInterface {
  transformerName: string
  maxCapacity: string
}

interface TransformListInterface {
  value?: TransformInterface[]
  onChange?: (obj: any[]) => void
}

export const TransformerList: React.FC<TransformListInterface> = ({ value = [], onChange }) => {
  React.useEffect(() => {}, [value])

  return (
    <div>
      {value.map((o, idx) => (
        <Row
          key={`${o.transformerName}-${idx}`}
          style={{
            marginBottom: '.5rem',
          }}
        >
          <Col
            span={11}
            style={{
              paddingRight: '.25rem',
            }}
          >
            <Input
              placeholder="请输入变压器名称"
              defaultValue={o.transformerName || ''}
              onBlur={(val) => {
                value[idx].transformerName = (val.target as HTMLInputElement).value
                onChange?.([...value])
              }}
            />
          </Col>
          <Col
            span={11}
            style={{
              paddingLeft: '.25rem',
            }}
          >
            <Select
              showSearch
              placeholder="输入或选择变压器容量"
              onChange={(val: number) => {
                value[idx].maxCapacity = val.toString()
                onChange?.([...value])
              }}
              defaultValue={o.maxCapacity ? parseInt(o.maxCapacity, 10) : undefined}
            >
              {[
                50, 80, 100, 125, 160, 200, 250, 315, 400, 500, 630, 800, 1000, 1250, 1600, 2000, 2500, 3150, 4000,
                5000,
              ].map((o) => (
                <Select.Option value={o} key={o}>
                  {o}kVA
                </Select.Option>
              ))}
            </Select>
          </Col>
          <Col
            span={2}
            style={{
              textAlign: 'right',
            }}
          >
            <Button
              type="text"
              icon={<CustomIcons type="klf-delete" />}
              onClick={() => {
                const arr = [...value]
                arr.splice(idx, 1)
                onChange?.(arr)
              }}
            />
          </Col>
        </Row>
      ))}
      {value?.length < 6 && (
        <div>
          <Button
            type="dashed"
            icon={<PlusOutlined />}
            style={{
              width: '100%',
            }}
            onClick={() => {
              onChange?.([
                ...value,
                {
                  transformerName: '',
                  maxCapacity: '',
                },
              ])
            }}
          >
            添加
          </Button>
        </div>
      )}
    </div>
  )
}
