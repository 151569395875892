/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React,{ useEffect, useState } from 'react'
import s from './index.module.scss'
import { Button, Menu, Card, Dropdown, Modal, Input, message, Table, Space } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import { SubBreadcrumbObservable } from './layout'
import PermissionTemplateControllerApi from '../../services/api/PermissionTemplateControllerApi'
import { useNavigate } from 'react-router-dom'
import IconMore from '../../assets/more.png'

const AuthTemplate = () => {

  const [creating, setCreating] = useState(false)
  const [removing, setRemoving] = useState(false)
  const [templateName, setTemplateName] = useState('')
  const [dataSource, setDataSource] = useState([])

  const navigate = useNavigate()

  const handleSet = () => {
    console.log('🚀 ~ file: index.tsx ~ line 80 ~ handleSet')
  }

  const handleRename = () => {
    console.log('🚀 ~ file: index.tsx ~ line 80 ~ handleRename')
  }

  const handleCopy = () => {
    console.log('🚀 ~ file: index.tsx ~ line 80 ~ handleCopy')
  }

  const handleRemove = () => {
    setRemoving(true)
  }
  const handleRemoveOk = () => {
    setRemoving(false)
  }

  const handleMenuClick = (e: any) => {
    switch (e.key) {
      case '1':
        handleSet()
        break
      case '2':
        handleRename()
        break
      case '3':
        handleCopy()
        break
      case '4':
        handleRemove()
        break
    }
  }

  useEffect(() => {
    SubBreadcrumbObservable.next([
      {
        name: '设置',
        url: '',
      },
      {
        name: '项目权限模板',
        url: '/user-center/auth-template',
      },
    ])

    authPermissionTemplateListGet()
  }, [])

  const authPermissionTemplateListGet = () => {
    PermissionTemplateControllerApi.authPermissionTemplateListGet().then((res: any) => {
      console.log('authPermissionTemplateListGet:', res)
      setDataSource(res.data || [])
    })
  }

  const CardTitle = React.memo((props: any) => {
    return (
      <div className={s.authTemplateTitle}>
        <h3 className={s.authTemplateName}>{props.title}</h3>
        <Button icon={<PlusOutlined />} size="large" type="primary" onClick={() => setCreating(true)}>
          创建模板
        </Button>
      </div>
    )
  })

  const menu = (
    <Menu onClick={handleMenuClick}>
      <Menu.Item key={1}>设置默认</Menu.Item>
      <Menu.Item key={2}>重命名</Menu.Item>
      <Menu.Item key={3}>拷贝</Menu.Item>
      <Menu.Item key={4}>删除</Menu.Item>
    </Menu>
  )

  const columns = [
    {
      title: '权限模板名称',
      dataIndex: 'templateName',
      key: 'templateName',
    },
    {
      title: '操作',
      key: 'action',
      width: 64,
      render: (record) => {
        const { templateId } = record || {}
        return (
          <div className={s.authTemplateAction}>
            <Space size={12}>
              <div className={s.authTemplateActionSet} onClick={() => navigate(`/user-center/auth-template/edit/${templateId}`)}>
                设置权限
              </div>
              <Dropdown placement="bottomRight" overlay={menu} trigger={['click']}>
                <img className={s.authTemplateOperation} src={IconMore} alt="" />
              </Dropdown>
            </Space>
          </div>
        )
      },
    },
  ];

  const createTemplate = () => {
    if (!templateName) {
      message.warning("请填写正确的模板名称 !")
      return
    }
    PermissionTemplateControllerApi.authPermissionTemplateAddPost({templateName}).then((res: any) => {
      setCreating(false)
      navigate(`/user-center/auth-template/${res.data}`)
      authPermissionTemplateListGet()
    })
  }

  return (
    <Card className="main-card" title={<CardTitle title="项目权限模板" />}>
      <div className={s.authTemplate}>
        <div className={s.authTemplateDetail}>
          <Table className={s.authTemplateTable} columns={columns} dataSource={dataSource} pagination={false} />
          {/* <div className="rights-template-footer">
              <Pagination total={total} showQuickJumper showSizeChanger showTotal={(total) => `共 ${total} 条`} />
            </div> */}
        </div>
      </div>
      <Modal className="authModal" title="模板名称" visible={creating} onOk={createTemplate} onCancel={() => setCreating(false)}>
        <Input placeholder={'请输入模板名称'} value={templateName} onChange={(e: any) => setTemplateName(e.target.value)} />
      </Modal>
      <Modal className="authModal" title="删除模板" visible={removing} onOk={handleRemoveOk} onCancel={() => setRemoving(false)}>
        <p>请确认是否删除xxx模板</p>
      </Modal>
    </Card>
  )
}

export default AuthTemplate
