import { isEmpty } from 'lodash'
import { dynamicText } from '../../../apis/wl-service'
import {
  // capitalSourceOptions,
  engineering_propertiesOptions,
  investTypeOptions,
  isGetYearTargetOptions,
  is_group_decisionOptions,
  is_self_buildOptions,
} from '../common/constant'

export const generatorFields = (data, options, jumpTo) => {
  return {
    invest: [
      {
        title: '资源开发',
        disabled: !data?.resourceList?.id,
        more: () => {
          jumpTo(data?.resourceList?.id, 1)
        },
        infoList: [
          { name: '项目名称', val: data?.resourceList?.projectName ?? '' },
          { name: '项目代码', val: data?.resourceList?.projectCode ?? '' },
          { name: '项目类型', val: dynamicText(data?.resourceList?.resourcetype, options?.project_typeOptions) },
          { name: '资源属地', val: data?.resourceList?.resourcedependency ?? '' },
          {
            name: '开发主体',
            val: data?.resourceList?.developer
              ? dynamicText(data?.resourceList?.developer, options?.project_developerOptions ?? [])
              : '',
          },
          { name: '投资模式', val: dynamicText(data?.resourceList?.investType?.toString(), investTypeOptions) },
        ],
      },
      {
        title: '投资决策',
        disabled: !data?.decisionList?.id,
        more: () => {
          jumpTo(data?.resourceList?.id, 3)
        },
        infoList: [
          { name: '决策机构', val: data?.decisionList?.decisionOrganization },
          {
            name: '集团决策',
            val: dynamicText(data?.decisionList?.isGroupDecision?.toString(), is_group_decisionOptions),
          },
          { name: '决策时间', val: data?.decisionList?.decisionTime },
          { name: '决策容量(MW)', val: data?.decisionList?.decisionVolume },
          { name: '决策总投资(万元)', val: data?.decisionList?.totalInvest },
          { name: '资本金(万元)', val: data?.decisionList?.capital },
          { name: '决策动态投资(万元)', val: data?.decisionList?.dynamicInvest },
          { name: '流动资金(万元)', val: data?.decisionList?.fluidCapital },
          { name: '资本金内部收益率', val: data?.decisionList?.incomeRate && data?.decisionList?.incomeRate + '%' },
          { name: '资本金比例', val: data?.decisionList?.capitalRate && data?.decisionList?.capitalRate + '%' },
          { name: '决策用地面积(亩)', val: data?.decisionList?.siteArea },
          { name: '上网电价(元/千瓦时)', val: data?.decisionList?.netElectrovalence },
          { name: '送出线路(公里)', val: data?.decisionList?.routeLength },
          { name: '是否自建', val: dynamicText(data?.decisionList?.isSelfBuild?.toString(), is_self_buildOptions) },
          { name: '年利用小时', val: data?.decisionList?.yearUseHour },
          { name: '配套储能(MW)', val: data?.decisionList?.storage },
        ],
      },
      {
        title: '并购信息',
        visible: data?.resourceList?.investType == 2,
        disabled: !data?.decisionList?.id,
        infoList: [
          { name: '项目转让方', val: data?.decisionList?.transferor },
          { name: '企业性质', val: data?.decisionList?.enterpriseProperty },
          { name: '签约时间', val: data?.decisionList?.signTime },
          { name: '股转时间', val: data?.decisionList?.appointedTime },
          { name: '净资产(万元)', val: data?.decisionList?.jassets },
          { name: '前期费用评估(万元)', val: data?.decisionList?.costEvaluate },
          { name: '评估增值(万元)', val: data?.decisionList?.pgIncrement },
          { name: '增值率(%)', val: data?.decisionList?.incrementRate },
        ],
      },
      {
        title: '投资方信息',
        disabled: !data?.decisionList?.id,
        infoList: [
          { name: '投资主体', val: data?.decisionList?.investor },
          { name: '项目负责人', val: data?.decisionList?.projectLeader },
        ],
        tableColumns: [
          {
            dataIndex: 'index',
            title: '序号',
            width: 80,
            align: 'center',
            render: (...args: any[]) => args[2] + 1,
          },
          { dataIndex: 'investName', title: '投资方名称' },
          { dataIndex: 'capitalRate', title: '出资比例' },
        ],
        tableData: data?.investorList,
      },
      {
        title: '备案信息',
        disabled: !data?.recordList?.id,
        more: () => {
          jumpTo(data?.resourceList?.id, 2)
        },
        infoList: [
          { name: '项目备案规模(MW)', val: data?.recordList?.installSize },
          { name: '备案机构', val: data?.recordList?.filingOrganization },
          { name: '备案日期', val: data?.recordList?.filingTime },
          { name: '备案投资主体', val: data?.recordList?.filingInvestMain },
          { name: '备案投资额(万元)', val: data?.recordList?.filingInvest },
          {
            name: '是否获得年度指标',
            val: dynamicText(data?.recordList?.isGetYearTarget?.toString(), isGetYearTargetOptions),
          },
          { name: '备案文号', val: data?.recordList?.filingNumber },
        ],
      },
    ],

    engineering: [
      {
        title: '项目建设信息',
        disabled: !data?.productionList?.id,
        more: () => {
          jumpTo(data?.resourceList?.id, 4)
        },
        infoList: [
          { name: '计划工期(月)', val: data?.productionList?.planWorkMonth },
          { name: '投产日期', val: data?.productionList?.productionRealTime },
          { name: '已投产容量(MW)', val: data?.productionList?.productionCapacity },
        ],
        tableData: data?.productionScaleList,
        tableColumns: [
          {
            dataIndex: 'index',
            title: '序号',
            width: 80,
            align: 'center',
            render: (...args: any[]) => args[2] + 1,
          },
          {
            dataIndex: 'buildT',
            title: '建设规模台数',
          },
          {
            dataIndex: 'buildR',
            title: '建设规模容量',
          },
          {
            dataIndex: 'constructionT',
            title: '施工台数',
          },
          {
            dataIndex: 'constructionR',
            title: '施工容量',
          },
          {
            dataIndex: 'planT',
            title: '计划台数',
          },
          {
            dataIndex: 'planR',
            title: '计划容量',
          },
          {
            dataIndex: 'planTime',
            title: '计划投产时间',
          },
        ],
      },
      {
        title: '项目造价信息',
        disabled: !data?.projectCostList?.id,
        more: () => {
          jumpTo(data?.resourceList?.id, 5)
        },
        infoList: [
          { name: '设计单位', val: data?.projectCostList?.designUnit },
          { name: '建设单位', val: data?.projectCostList?.developUnit },
        ],
        tableVisible: data?.projectCostList || data?.decisionList || !isEmpty(data?.costTargetMap),
        tableData: [
          {
            uid: 0,
            title: '项目规模(MW)',
            front: data?.decisionList?.decisionVolume,
            middle: data?.projectCostList?.projectModule1,
            rear: data?.projectCostList?.projectModule2,
          },
          {
            uid: 1,
            title: '总投资是否超额上一阶段',
            front: '/',
            middle: data?.projectCostList?.isexceed2,
            rear: data?.projectCostList?.isexceed3,
          },
          {
            uid: 2,
            title: '工程总投资(万元)',
            front: data?.decisionList?.totalInvest,
            middle: data?.projectCostList?.totalinvest2,
            rear: data?.projectCostList?.totalinvest3,
          },
          {
            uid: 3,
            title: '项目动态投资(万元)',
            front: data?.decisionList?.dynamicInvest,
            middle: data?.projectCostList?.dynamicInvestment2 ?? '',
            rear: data?.projectCostList?.dynamicInvestment3 ?? '',
          },
          {
            uid: 4,
            title: '项目静态投资(万元)',
            front: data?.projectCostList?.staticInvestment1 ?? '',
            middle: data?.projectCostList?.staticInvestment2 ?? '',
            rear: data?.projectCostList?.staticInvestment3 ?? '',
          },
          {
            uid: 5,
            title: '流动资金(万元)',
            front: data?.projectCostList?.workingFund ?? '',
            middle: '/',
            rear: '/',
          },
          {
            uid: 6,
            title: '铺底流动资金(万元)',
            front: data?.projectCostList?.workingCapitalFund ?? '',
            middle: '/',
            rear: '/',
          },
          // {
          //   uid: 7,
          //   title: '建设利息(万元)',
          //   front: data?.costTargetMap?.jslxky ?? '',
          //   middle: data?.costTargetMap?.jslxcs ?? '',
          //   rear: data?.costTargetMap?.jslxjg ?? '',
          // },
          {
            uid: 8,
            title: '批复时间',
            front: '/',
            middle: data?.projectCostList?.approvalTime1 ?? '',
            rear: data?.projectCostList?.approvalTime2 ?? '',
          },
          {
            uid: 9,
            title: '批复单位',
            front: '/',
            middle: data?.projectCostList?.approvalUnit1 ?? '',
            rear: data?.projectCostList?.approvalUnit2 ?? '',
          },
        ],
        tableColumns: [
          { dataIndex: 'title', title: '', align: 'left' },
          { dataIndex: 'front', title: '可研估算', align: 'center' },
          { dataIndex: 'middle', title: '初设概算', align: 'center' },
          { dataIndex: 'rear', title: '竣工结算', align: 'center' },
        ],
      },
      {
        title: '投资计划信息',
        disabled: !data?.investPlanList?.id,
        more: () => {
          jumpTo(data?.resourceList?.id, 6)
        },
        infoList: [
          {
            name: '工程性质',
            val: dynamicText(data?.investPlanList?.engineeringProperties?.toString(), engineering_propertiesOptions),
          },
          { name: '本期建设规模(MW)', val: data?.investPlanList?.scale },
          { name: '建设起始年份', val: data?.investPlanList?.buildStartYear },
          { name: '建设截至年份', val: data?.investPlanList?.buildEndYear },
        ],
        tableData: data?.investPlanListInfo ?? [],
        tableColumns: [
          {
            dataIndex: 'index',
            title: '序号',
            width: 80,
            align: 'center',
            render: (...args: any[]) => args[2] + 1,
          },
          // {
          //   dataIndex: 'capitalSource',
          //   title: '资金来源',
          //   render: (text: string) => {
          //     return dynamicText(text?.toString(), capitalSourceOptions)
          //   },
          // },
          { dataIndex: 'total_investment', title: '项目总投资' },
          { dataIndex: 'work_quantity', title: '年底累计完成工程量' },
          { dataIndex: 'money_quantity', title: '年底累计资金完成量' },
          // { dataIndex: 'workOriginalPlan', title: '工程原计划' },
          // { dataIndex: 'workAdjustPlan', title: '工程拟调整计划' },
          // { dataIndex: 'moneyOriginalPlan', title: '资金原计划' },
          // { dataIndex: 'moneyAdjustPlan', title: '资金拟调整计划' },
        ],
      },
      {
        title: '投资进度信息',
        disabled: !data?.planExecuteList?.id,
        more: () => {
          jumpTo(data?.resourceList?.id, 7)
        },
        infoList: [
          { name: '建筑工程(万元)', val: data?.planExecuteList?.gcljzgc },
          { name: '安裝工程(万元)', val: data?.planExecuteList?.gclazgc },
          { name: '设备工器具购置(万元)', val: data?.planExecuteList?.gclsbgqjgz },
          { name: '其他费用(万元)', val: data?.planExecuteList?.gclqtfy },
          { name: '本月新增固定资产(万元)', val: data?.planExecuteList?.byxzgdzc },
        ],
      },
    ],
  }
}
