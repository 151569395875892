import React, { useState } from 'react'
import BraftEditor, { ControlType } from 'braft-editor'
import 'braft-editor/dist/index.css'
import './index.less'

const controls: ControlType[] = [
  'undo',
  'redo',
  'separator',
  'font-size',
  'line-height',
  'letter-spacing',
  'separator',
  'text-color',
  'bold',
  'italic',
  'underline',
  'strike-through',
  'separator',
  'superscript',
  'subscript',
  'remove-styles',
  'emoji',
  'separator',
  'text-indent',
  'text-align',
  'separator',
  'headings',
  'list-ul',
  'list-ol',
  'blockquote',
  'code',
  'separator',
  //   'media',
  'link',
  'table',
  //   'split',
  'hr',
  'separator',
  'clear',
  'separator',
  'fullscreen',
]
interface IProps {
  disabled?: boolean
  value?: string
  onChange?: (value: string) => void
}
const App: React.FC<IProps> = ({ disabled, value, onChange }) => {
  const [editorState, setEditorState] = useState<any>(BraftEditor.createEditorState(value))

  return (
    <BraftEditor
      className="wl-editor-in-form"
      value={editorState}
      controls={controls}
      readOnly={disabled}
      onChange={(editorState) => {
        setEditorState(editorState)
        onChange && onChange(editorState.toHTML())
      }}
      //   media={{
      //     accepts: {
      //       image: 'image/jpeg,image/png',
      //       video: 'video/mp4',
      //       audio: 'audio/mpeg,audio/mp3',
      //     },
      //     uploadFn: (upload) => {
      //       console.log(upload)
      //     },

      //     // onChange(...rest) {
      //     //     console.log('onChange---rest', rest)
      //     // }
      //   }}
      style={{ border: '1px solid #d1d1d1', borderRadius: 3, background: '#fff' }}
    />
  )
}

export default App
