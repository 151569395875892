import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { Card, Space, Button, Steps, Typography, Divider, Dropdown, Menu } from 'antd'
import {
  EditOutlined,
  FileTextOutlined,
  // FileProtectOutlined,
  DownloadOutlined,
} from '@ant-design/icons'

import FileDrawer from './components/file-drawer'
import LogDrawer from './components/log-drawer'
import { CommonService, ResourceService, ResponseOptionType } from '../../../apis/wl-service'
import { BreadcrumbObservable, SideMenuObservable } from '../../../application'
import TableComponent from './components/table-component'
import InfoItem from './components/info-item'
import { generatorFields } from './generator'

export const ProjectDetail = () => {
  const nav = useNavigate()
  const params = useParams()
  const location = useLocation()
  const [loading, setLoading] = useState<boolean>(false) // 数据加载状态
  const [data, setData] = useState<any>({}) // 项目信息数据
  const [dataSet, setDataSet] = useState<{
    project_typeOptions: ResponseOptionType[]
    project_developerOptions: ResponseOptionType[]
  }>({
    project_typeOptions: [],
    project_developerOptions: [],
  }) // 数据集
  const [fileDrawerVisible, setFileDrawerVisible] = useState<boolean>(false) // 附件抽屉显隐状态
  const [logDrawerVisible, setLogDrawerVisible] = useState<boolean>(false) // 项目日志抽屉显隐状态

  // 设置面包屑
  useEffect(() => {
    SideMenuObservable.next(false)
    let breadcrumb: any = BreadcrumbObservable.getValue()
    if (breadcrumb.length > 0) {
      window.localStorage.setItem('wl-breadcrumb', JSON.stringify(breadcrumb))
    } else {
      breadcrumb = window.localStorage.getItem('wl-breadcrumb')
      breadcrumb = breadcrumb ? JSON.parse(breadcrumb) : []
    }

    breadcrumb = breadcrumb
      .filter((item: any) => item.name !== '项目详情')
      .concat([
        {
          name: '项目详情',
          url: `/invest/projectDetail/${params.id}}`,
          state: location.state,
        },
      ])
    breadcrumb.length && BreadcrumbObservable.next(breadcrumb)

    return () => {
      // window.localStorage.removeItem('wl-breadcrumb')
    }
  }, [location.state])

  // 获取项目详情
  useEffect(() => {
    if (!params.id) return

    setLoading(true)
    ResourceService.getAllInfo({ projectId: params.id ?? '' })
      .then((resp: any) => {
        setData(resp?.data)
      })
      .catch((e) => {
        console.warn(e)
      })
      .finally(() => {
        setLoading(false)
      })
  }, [])

  // 获取数据集
  useEffect(() => {
    CommonService.getDictionaryDataSelectorValid('15301742e5ec4e92843192cb76010e08').then((resp: any) => {
      setDataSet((previous) => ({ ...previous, project_typeOptions: resp?.data?.list ?? [] }))
    })
    CommonService.getDictionaryData('cbe5eb037ffd4157abe128705205c654')
      .then((data: any) => {
        setDataSet((previous: any) => ({
          ...previous,
          project_developerOptions: data?.data?.list ?? [],
        }))
      })
      .catch((error: any) => {
        console.warn('开发主体获取失败：', error?.message)
      })
  }, [])

  // 跳转到模块详情
  const jumpTo = (id: string, moduleId: number, editable?: boolean, search?: string) => {
    let url = `/invest/project/${editable ?? false}/${id}?moduleId=${moduleId}`
    if (search) {
      url += `&${search}`
    }
    nav(url, { state: location.state })
  }

  const exportExcel = () => {
    try {
      ResourceService.createInformationTable({ id: data?.resourceList?.id })
    } catch (e) {
      console.warn(e)
    }
  }

  const edit = React.useCallback(
    ({ key, keyPath: [subId, moduleId = key] }: any) => {
      if (location.state?.engineering) {
        jumpTo(params.id, +moduleId, true, subId !== moduleId ? `t=${subId}` : '')
      } else {
        nav(`/invest/projectLibrary/true/${params.id}?s=1&t=${key}`, { state: location.state })
      }
    },
    [params.id, location.state]
  )

  const editItems = React.useMemo(() => {
    if (location.state?.engineering) {
      return [
        { key: '4', label: '编辑建设', disabled: false ?? !data?.productionList?.id },
        {
          key: '5',
          label: '编辑造价',
          children: [
            { key: '1', label: '可研预算', disabled: false ?? !data?.projectCostList?.id },
            { key: '2', label: '初设概算', disabled: false ?? !data?.projectCostList?.id },
            { key: '3', label: '竣工结算', disabled: false ?? !data?.projectCostList?.id },
          ],
        }, // 编辑造价
        { key: '6', label: '编辑投资计划', disabled: false ?? !data?.investPlanList?.id },
        { key: '7', label: '编辑投资进度', disabled: false ?? !data?.planExecuteList?.id },
      ]
    }

    return [
      { key: '1', label: '编辑资源开发', disabled: false ?? !data?.resourceList?.id },
      { key: '2', label: '编辑投资决策', disabled: false ?? !data?.decisionList?.id },
      { key: '3', label: '编辑核准备案', disabled: false ?? !data?.recordList?.id },
    ]
  }, [location.state, data])

  const oStep = React.useMemo(() => {
    let configs = [
      { key: 'resourceList', idx: 1, label: '资源开发' },
      { key: 'decisionList', idx: 2, label: '投资决策' },
      { key: 'recordList', idx: 3, label: '核准备案' },
    ]

    if (location.state?.engineering) {
      configs = [
        ...configs,
        { key: 'productionList', idx: 4, label: '建设信息' },
        { key: 'projectCostList', idx: 5, label: '造价' },
        { key: 'investPlanList', idx: 6, label: '投资计划' },
        { key: 'planExecuteList', idx: 7, label: '计划进度' },
      ]
    }

    let current = 0
    for (let i = configs.length - 1; i >= 0; i--) {
      const v = configs[i]
      if (data?.[v.key]) {
        current = v.idx
        break
      }
    }

    return {
      configs,
      current,
    }
  }, [data, location.state])

  //field定义
  const fieldInfo = React.useMemo(() => generatorFields(data, dataSet, jumpTo), [data, dataSet])

  return (
    <Card
      className="right-actions no-border-bottom-card"
      loading={loading}
      title={<Typography.Title level={4}>{data?.resourceList?.projectName}</Typography.Title>}
      bodyStyle={{ height: `calc(100vh - 224px)`, padding: 0 }}
      extra={
        <Space>
          <Dropdown overlay={<Menu items={editItems} onClick={edit} />}>
            <Button type="primary" icon={<EditOutlined />}>
              编辑
            </Button>
          </Dropdown>

          <Button type="primary" icon={<FileTextOutlined />} onClick={() => setFileDrawerVisible(true)}>
            附件
          </Button>
          <Button type="primary" icon={<DownloadOutlined />} onClick={exportExcel}>
            导出项目综合信息报表
          </Button>
          {/* <Button type="primary" icon={<FileProtectOutlined />} onClick={() => setLogDrawerVisible(true)}>
            项目修改记录
          </Button> */}
          <Button onClick={() => nav(-1)}>返回</Button>
        </Space>
      }
    >
      <Space direction="vertical" size={24} style={{ padding: 24, height: '100%', overflow: 'auto' }}>
        <Steps size="small" initial={0} current={Math.max(0, oStep.current - 1)} labelPlacement="vertical">
          {oStep.configs.map((item) => (
            <Steps.Step key={item.key} title={item.label} />
          ))}
        </Steps>

        <InfoItem dataSource={fieldInfo.invest} />

        <Divider style={{ margin: '-24px 0 0' }} />
        <TableComponent type={1} projectId={params.id} dataSource={data?.projectProposal ?? []} />
        <TableComponent type={2} projectId={params.id} dataSource={data?.collection ? [data?.collection] : []} />

        {location.state?.engineering ? <InfoItem dataSource={fieldInfo.engineering} /> : null}
      </Space>

      <FileDrawer
        from={location.state?.engineering ? 'engineering' : 'invest'}
        projectId={params.id ?? ''}
        visible={fileDrawerVisible}
        visibleChange={setFileDrawerVisible}
      />
      <LogDrawer projectId={params.id ?? ''} visible={logDrawerVisible} visibleChange={setLogDrawerVisible} />
    </Card>
  )
}
