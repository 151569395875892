import { useEffect, useState } from 'react'
import styles from '../index.module.scss'
import { Modal, Timeline, Form, Select, Input, Row, Col, InputNumber, Button, message, Popconfirm } from 'antd'
import { CheckCircleFilled, PlusOutlined } from '@ant-design/icons'
import { paramsStoreShowError as showError } from '../../index'
import { CustomIcons } from '../../../../global'
import { NUMBER_EXP } from '../../../../utils/constant'

const FORM_LAYOUT_HALF = {
  labelCol: {
    xs: { span: 8 },
    sm: { span: 8 },
    md: { span: 8 },
    lg: { span: 8 },
    xl: { span: 8 },
    xxl: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 15 },
    sm: { span: 15 },
    md: { span: 15 },
    lg: { span: 15 },
    xl: { span: 15 },
    xxl: { span: 15 },
  },
}
// 添加、编辑、查看弹窗
const IncentiveModal = (props: any) => {
  const { currentItem, visible, type, onCancel, onSuccess, onSave } = props
  const [form] = Form.useForm()
  const [edit, setEdit] = useState(false)
  const [name, setName] = useState('补贴')
  const [item, setItem] = useState<any>({})
  const [subsidyType, setSubsidyType] = useState<any>()
  const [incentiveList, setIncentiveList] = useState<any>([])
  const [rows, setRows] = useState<any>([
    {
      index: 0,
    },
  ])

  const generateName = () => {
    const list = currentItem?.detailList?.slice() || []
    let name = '补贴激励-7'
    for (let i = 7; i > 0; i--) {
      if (!list.map((item: any) => item.name).includes(`补贴激励-${i}`)) {
        name = `补贴激励-${i}`
      }
    }
    return name
  }

  useEffect(() => {
    if (type === 'add' && visible) {
      setName(generateName())
    }
  }, [visible])

  useEffect(() => {
    if (type !== 'add') {
      //   setLoading(true)
      //   FinanceService.info({
      //     awardSubsidyDetailId: currentItem.awardSubsidyDetailId,
      //   })
      //     .then((res) => {
      setItem(currentItem)
      if (currentItem?.taxReliefList) {
        setRows(
          currentItem?.taxReliefList?.map((item: any, index: number) => {
            return {
              index: index,
            }
          })
        )
        setIncentiveList(
          currentItem?.taxReliefList?.map((item: any) => {
            return {
              startYear: item.startYear,
              endYear: item.endYear,
            }
          })
        )
      }
      setName(currentItem.name)
      setSubsidyType(currentItem?.subsidyType)
      form.setFieldsValue(currentItem)
      // })
      // .catch(() => {})
      // .finally(() => {
      //   setLoading(false)
      // })
    }
  }, [])

  //一次性现金补贴表单
  const subsidyType0 = () => {
    const values = form.getFieldsValue(['microgridFactor'])

    return (
      <>
        <Row gutter={[12, 12]}>
          <Col span={12}>
            <Form.Item
              name="money"
              label="金额"
              initialValue={item.money || 0}
              rules={[
                {
                  required: true,
                  message: '',
                },
              ]}
              getValueFromEvent={(e: any) => {
                const values = form.getFieldsValue(['moneyType'])
                let val = e.target.value
                console.info(val)
                if (val < 0) {
                  val = 0
                } else if (val > 100 && values.moneyType === 0) {
                  val = 100
                } else {
                  val = Number.parseFloat(e.target.value.replace(NUMBER_EXP, ''))
                }
                return val
              }}
            >
              <Input
                className="compact"
                placeholder="请输入"
                type="number"
                min={0}
                suffix={
                  <Form.Item
                    name="moneyType"
                    initialValue={
                      item.moneyType || item.moneyType === 0 ? item.moneyType : values.microgridFactor !== 1 ? 2 : 3
                    }
                  >
                    <Select
                      style={{ width: 100 }}
                      onChange={() => {
                        const values = form.getFieldsValue(['money', 'moneyType', 'moneyMax'])
                        if (values.money && values.money > 100 && values.moneyType === 0) {
                          form.setFieldsValue({
                            money: 100,
                          })
                        }
                        if (values.moneyType != 1 && values.moneyMax > 999999999) {
                          form.setFieldsValue({
                            moneyMax: 999999999,
                          })
                        }
                      }}
                    >
                      <Select.Option value={0}>%</Select.Option>
                      <Select.Option value={1}>元</Select.Option>
                      {values.microgridFactor !== 1 && <Select.Option value={2}>元/kWh</Select.Option>}
                      {values.microgridFactor !== 2 && <Select.Option value={3}>元/kW</Select.Option>}
                    </Select>
                  </Form.Item>
                }
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="moneyMax"
              label="最大值"
              initialValue={item.moneyMax || 0}
              rules={[
                {
                  required: true,
                  message: '',
                },
              ]}
              getValueFromEvent={(e: any) => {
                const values = form.getFieldsValue(['moneyType'])
                let val = e.target.value
                if (e.target.value === '') {
                  return ''
                } else if (e.target.value < 0) {
                  return 0
                } else if (values.moneyType != 1) {
                  if (val > 999999999.99) {
                    return 999999999.99
                  } else {
                    const arr = new Array(2)
                    arr.fill('\\d')
                    const pat = `^(\\-)*(\\d+)\\.(${arr.join('')}).*$`
                    return Number(`${val}`.replace(new RegExp(pat), '$1$2.$3'))
                  }
                } else {
                  return Number(`${Number(e.target.value.replace(NUMBER_EXP, ''))}`)
                }
              }}
            >
              <Input suffix="元" type="number" min={0} placeholder="请输入" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[12, 12]}>
          <Col span={12}>
            <Form.Item
              name="grantDeadline"
              label="发放时间"
              initialValue={item.grantDeadline || 0}
              rules={[
                {
                  required: true,
                  message: '',
                },
              ]}
              getValueFromEvent={(e: any) => {
                if (e.target.value === '') {
                  return ''
                } else {
                  return `${Number(e.target.value.replace(NUMBER_EXP, ''))}`
                }
              }}
            >
              <Input suffix="年" type="number" min={0} placeholder="请输入" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="isAvoidIncomeTax"
              label="是否免所得税"
              rules={[
                {
                  required: true,
                  message: '',
                },
              ]}
              initialValue={item.isAvoidIncomeTax ? 1 : 0}
            >
              <Select placeholder="请选择">
                <Select.Option value={0}>否</Select.Option>
                <Select.Option value={1}>是</Select.Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
      </>
    )
  }

  // 电价补贴
  const subsidyType1 = () => {
    return (
      <>
        <Row gutter={[12, 12]}>
          <Col span={12}>
            <Form.Item
              name="subsidyObject"
              label="补贴对象"
              rules={[
                {
                  required: true,
                  message: '',
                },
              ]}
              initialValue={item.subsidyObject}
            >
              <Select placeholder="请选择">
                <Select.Option value={0}>消纳电量</Select.Option>
                <Select.Option value={1}>上网电量</Select.Option>
                <Select.Option value={2}>全部电量</Select.Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="unitSubsidy"
              label="单位补贴"
              initialValue={item.unitSubsidy || 0}
              rules={[
                {
                  required: true,
                  message: '',
                },
              ]}
              getValueFromEvent={(e: any) => {
                if (e.target.value === '') {
                  return ''
                } else {
                  return `${
                    Number(e.target.value.replace(NUMBER_EXP, '')) > 999999
                      ? 999999
                      : Number(e.target.value.replace(NUMBER_EXP, ''))
                  }`
                }
              }}
            >
              <Input suffix="¥/kWh" type="number" min={0} placeholder="请输入" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[12, 12]}>
          <Col span={12}>
            <Form.Item
              name="grantDeadline"
              label="发放期限"
              initialValue={item.grantDeadline || 0}
              rules={[
                {
                  required: true,
                  message: '',
                },
              ]}
              getValueFromEvent={(e: any) => {
                if (e.target.value === '' || e.target.value < 0) {
                  return 0
                } else {
                  return `${Number.parseInt(e.target.value.replace(NUMBER_EXP, ''))}`
                }
              }}
            >
              <Input suffix="年" type="number" min={0} placeholder="请输入" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="isAvoidIncomeTax"
              label="是否免所得税"
              rules={[
                {
                  required: true,
                  message: '',
                },
              ]}
              initialValue={item.isAvoidIncomeTax ? 1 : 0}
            >
              <Select placeholder="请选择">
                <Select.Option value={0}>否</Select.Option>
                <Select.Option value={1}>是</Select.Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
      </>
    )
  }

  // 添加补贴详情
  const addRow = () => {
    const list = rows?.slice() || []
    list.push({
      index: (rows?.length || 1) - 1,
    })
    setRows(list)
  }

  const setIncentiveListData = (index: number, key: string, e: any) => {
    const list = incentiveList.slice()
    list.splice(index, 1, {
      ...list[index],
      [key]: e,
    })
    setIncentiveList(list)
  }

  const getTaxReliefMinMax = (index: number) => {
    const list = incentiveList.slice(0, index + 1)
    const startMin = list?.[index - 1]?.endYear + 1 || 0
    const startMax = list?.[index + 1]?.startYear
    const endMin = list?.[index]?.startYear || list?.[index - 1]?.endYear + 1
    const endMax = list?.[index + 1]?.startYear
    return [startMin, startMax, endMin, endMax]
  }

  // 删除减免行
  const delRow = (row: any, index: number) => {
    const list = rows?.slice() || []
    list.splice(index, 1)
    setRows([...list])
  }

  // 减免增值税 / 减免所得税
  const subsidyType2 = () => {
    return (
      <>
        {rows?.map((row: any, index: any) => {
          const [startMin, startMax, endMin, endMax] = getTaxReliefMinMax(index)
          return (
            <Row gutter={[12, 12]} key={index}>
              <Col span={11}>
                <Form.Item label="起止时间">
                  <div className="years-wrap">
                    <span>第</span>
                    <Form.Item
                      name={['taxReliefList', index, 'startYear']}
                      rules={[
                        {
                          required: true,
                          message: '',
                        },
                      ]}
                      initialValue={item?.['taxReliefMap']?.[index]?.['startYear']}
                    >
                      <InputNumber
                        placeholder="0"
                        min={startMin}
                        max={startMax}
                        onChange={(e) => {
                          setIncentiveListData(index, 'startYear', e)
                        }}
                      />
                    </Form.Item>
                    <span>-</span>
                    <Form.Item
                      name={['taxReliefList', index, 'endYear']}
                      rules={[
                        {
                          required: true,
                          message: '',
                        },
                      ]}
                      initialValue={item?.['taxReliefMap']?.[index]?.['endYear']}
                    >
                      <InputNumber
                        placeholder="0"
                        min={endMin}
                        max={endMax}
                        onChange={(e) => {
                          setIncentiveListData(index, 'endYear', e)
                        }}
                      />
                    </Form.Item>
                    <span>年</span>
                  </div>
                </Form.Item>
              </Col>
              <Col span={11}>
                <Form.Item
                  name={['taxReliefList', index, 'rate']}
                  initialValue={item?.['taxReliefMap']?.[index]?.['rate']}
                  label="减免税额百分比"
                  labelCol={{ span: 12 }}
                  wrapperCol={{ span: 12 }}
                  rules={[
                    {
                      required: true,
                      message: '',
                    },
                  ]}
                  getValueFromEvent={(e: any) => {
                    let val = e.target.value
                    if (val < 0) {
                      val = 0
                    } else if (val > 100) {
                      val = 100
                    } else {
                      const arr = new Array(1)
                      arr.fill('\\d')
                      const pat = `^(\\-)*(\\d+)\\.(${arr.join('')}).*$`
                      const numberValue = Number(e.target.value.replace(NUMBER_EXP, ''))
                      val = `${numberValue}`.replace(new RegExp(pat), '$1$2.$3')
                    }
                    return val
                  }}
                >
                  <Input suffix="%" type="number" min={0} max={100} placeholder="请输入" />
                </Form.Item>
              </Col>
              <Col span={2}>
                <Popconfirm
                  title="确认删除?"
                  onConfirm={() => {
                    delRow(row, index)
                  }}
                >
                  <CustomIcons type="klf-delete" className="custom-del-btn" />
                </Popconfirm>
              </Col>
            </Row>
          )
        })}
        <Row>
          <Col span={24}>
            <Button type="dashed" className="add-row" onClick={addRow}>
              <PlusOutlined />
              <span>添加时段</span>
            </Button>
          </Col>
        </Row>
      </>
    )
  }

  // 头部编辑时回车键保存
  const nameKeyDown = (e: any) => {
    if (e.code === 'Enter' && e.target.value?.trim()) {
      setEdit(false)
    }
  }

  // 保存时验证数据
  const validData = () => {
    const values = form.getFieldsValue()
    return new Promise((resolve, reject) => {
      if (
        currentItem.detailList?.filter(
          (item: any) => item.microgridFactor === values.microgridFactor && item.subsidyType === values.subsidyType
        )?.length
      ) {
        showError('同一激励补贴下的补贴类型只能存在一种')
        reject()
      }
      if (!name.trim()) {
        showError('请输入补贴名称')
        reject()
      }
      if (currentItem.detailList?.filter((item: any) => item.name === name.trim())?.length) {
        showError('补贴名称已存在')
        reject()
      }
      resolve('')
    })
  }

  // 保存数据
  const saveIncentive = async () => {
    validData().then(() => {
      const values = form.getFieldsValue()
      const taxReliefList = form.getFieldValue('taxReliefList')
      const errors = taxReliefList?.filter((item: any, index: number) => {
        return (
          (subsidyType === 2 || subsidyType === 3) &&
          (item.startYear < taxReliefList?.[index - 1]?.endYear || item.endYear < item.startYear)
        )
      })
      if (errors?.length > 0) {
        message.error('请检查补贴数据')
        return
      }
      console.info('save')
      onSave({ awardSubsidyDetailId: currentItem.awardSubsidyDetailId, ...values, name })
    })
  }

  // 新建数据
  const createIncentive = async () => {
    validData().then(() => {
      const values = form.getFieldsValue()
      const taxReliefList = form.getFieldValue('taxReliefList')
      const errors = taxReliefList?.filter((item: any, index: number) => {
        return (
          (subsidyType === 2 || subsidyType === 3) &&
          (item.startYear < taxReliefList?.[index - 1]?.endYear || item.endYear < item.startYear)
        )
      })
      if (errors?.length > 0) {
        message.error('请检查补贴数据')
        return
      }
      onSuccess({ ...values, name })
    })
  }

  return (
    <Modal
      className={styles.incentiveAddModal}
      visible={visible}
      title={
        <>
          {edit && (
            <>
              <Input
                value={name}
                onChange={(e) => {
                  setName(e.target.value)
                }}
                onBlur={(e) => {
                  if (e.target.value?.trim()) {
                    setEdit(false)
                  }
                }}
                onKeyDown={nameKeyDown}
              />
            </>
          )}
          {!edit && (
            <>
              <span style={{ marginRight: 8 }}>{name}</span>
              {(type === 'edit' || type === 'add') && (
                <CustomIcons
                  type="klf-edit1"
                  onClick={() => {
                    setEdit(true)
                  }}
                />
              )}
            </>
          )}
        </>
      }
      width={640}
      keyboard={false}
      maskClosable={false}
      onCancel={onCancel}
      onOk={async () => {
        form.validateFields().then(async () => {
          type === 'edit' ? await saveIncentive() : await createIncentive()
        })
      }}
    >
      <Form form={form} {...FORM_LAYOUT_HALF}>
        <Timeline>
          <Timeline.Item dot={<CheckCircleFilled style={{ color: 'rgba(28, 184, 135, 1)' }} />}>
            <div>选择补贴类型</div>
            <Row gutter={[12, 12]}>
              <Col span={12}>
                <Form.Item
                  name="microgridFactor"
                  label="微网要素"
                  initialValue={item.microgridFactor}
                  rules={[
                    {
                      required: true,
                      message: '',
                    },
                  ]}
                >
                  <Select
                    placeholder="请选择"
                    onChange={() => {
                      setSubsidyType(null)
                      form.setFieldsValue({
                        subsidyType: null,
                      })
                    }}
                  >
                    <Select.Option value={1}>光伏</Select.Option>
                    <Select.Option value={2}>储能</Select.Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[12, 12]}>
              <Col span={12}>
                <Form.Item
                  name="subsidyType"
                  label="补贴类型"
                  initialValue={item.subsidyType}
                  rules={[
                    {
                      required: true,
                      message: '',
                    },
                  ]}
                >
                  <Select
                    placeholder="请选择"
                    onChange={(e) => {
                      setSubsidyType(e)
                      const microgridFactor = form.getFieldValue('microgridFactor')
                      if (e === 0 && microgridFactor == 1) {
                        form.setFieldsValue({
                          moneyType: 3,
                        })
                      }
                      if (e === 0 && microgridFactor == 2) {
                        form.setFieldsValue({
                          moneyType: 2,
                        })
                      }
                    }}
                  >
                    <Select.Option value={0}>一次性现金补贴</Select.Option>
                    <Select.Option value={1}>电价补贴</Select.Option>
                    <Select.Option value={2}>减免增值税</Select.Option>
                    <Select.Option value={3}>减免所得税</Select.Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          </Timeline.Item>
          <Timeline.Item
            dot={
              <CheckCircleFilled
                style={{ color: subsidyType || subsidyType === 0 ? 'rgba(28, 184, 135, 1)' : 'gray' }}
              />
            }
          >
            <>
              <div>填写补贴详情</div>
              {subsidyType === 0 && subsidyType0()}
              {subsidyType === 1 && subsidyType1()}
              {(subsidyType === 2 || subsidyType === 3) && subsidyType2()}
            </>
          </Timeline.Item>
        </Timeline>
      </Form>
    </Modal>
  )
}

export default IncentiveModal
