import React, { useEffect } from 'react'
import { Button, Spin, message} from 'antd'
import { useParams, Link, useNavigate } from 'react-router-dom'
import { CustomIcons } from '../../../../../global'
import { ProjectServiceHistory } from '../../../../../apis/project-service-history'
import { InfoList } from '../../index'

const systemNameIdList:any[] = [
    {
        id: "0",
        name: "目录电价"
    },
    {
        id: "1",
        name: "标杆燃煤电价"
    }
]
export const AddElectricity = () => {
    const navigate = useNavigate()
    const params = useParams()
    const [finished, setFinished] = React.useState(false)
    const [uploading, setUploading] = React.useState(false)
    const [customNameIdList, setCustomNameIdList] = React.useState<{ [key: string]: any }>([])

    useEffect(()=>{
        getCustomNameIdList();
        const subscription = InfoList.subscribe((o: any) => {
          setFinished(o.electricity.finished)
        })
        return () => {
          subscription.unsubscribe()
        }
    },[])
    /**
   * 查询自定义电list名称和id
   */
  const getCustomNameIdList = () => {
    setUploading(true)
    
    ProjectServiceHistory.queryCustomNameIdList({projectId: params.id!})
      .then((resp)=>{
          const { data=[]} = resp;
          setCustomNameIdList(data)
      })
      .catch(() => {
        console.log("catch")
      })
      .finally(() => {
        setUploading(false)
      })
  }

  const goCreatPage = (customId) => {
    if(customNameIdList.length >= 6) {
      message.error('自定义电价最多添加 6 条')
    } else {
      navigate(`/program/edge/${params.id}/electricity/custom/add/${customId}`)
    }
  }
    return (
        <Spin spinning={uploading}>
            <p 
              style={{
                marginTop: '30px',
                marginBottom: 0,
                lineHeight: '20px',
                color: '#767F95'
              }}
            >
              可选择已有电价作为模版或新建空白自定义电
            </p>
            <div style={{
              display: "flex",
              flexWrap: "wrap",
              width: "800px",
              padding: "20px 0",
              margin: "0 -12px",
            }}>
                <Button
                  style={{
                    width: '222px',
                    height: '60px',
                    margin: '0 12px',
                    background: "#FFFFFF #F8F8F8"
                  }}
                  shape="round"
                  type="dashed"
                  icon={<CustomIcons type="klf-add2"  style={{ fontSize: 12 }}/>}
                  onClick={()=>{
                    goCreatPage(-1)
                  }}
                >
                  新建空白自定义电价
                </Button>
              
              {systemNameIdList.map((item: any)=>{
                return (
                  <>
                  {(item.id === '0' && !finished)
                  ? <div key={item.id} className="ant-btn temp-item-btn">
                      <div style={{
                        display: 'flex',
                        flexDirection: "column",
                        textAlign: 'left',
                        lineHeight: '20px',
                      }}>
                        <strong>{item.name}</strong>
                        <p style={{
                          margin: 0
                        }}>请先
                        <span style={{
                          cursor: 'pointer',
                          color: "rgb(30, 198, 146)"
                        }}><Link to={`/program/edge/${params.id}/electricity/catalogue`}>设置目录电价</Link></span></p>
                      </div>
                  </div>
                  : <div key={item.id} className="ant-btn temp-item-btn" onClick={(e)=>{console.log(e)}}>
                      <strong>{item.name}</strong>
                        <Button
                          className=""
                          size="large"
                          type="primary"
                          style={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: "translate(-50%, -50%)",
                          }}
                          onClick={()=>{
                            goCreatPage(item.id)
                          }}
                        >
                          使用该模版
                        </Button>
                    </div>
                  }
                  </>
                )
              })}
            </div>
          </Spin>
    )
}
