import { useState, useEffect } from 'react'
import styles from './index.module.scss'
import { Tabs } from 'antd'
import Financial from './components/financial'
import { SideMenuObservable } from '../../../application'
import { SubBreadcrumbObservable } from '../layout'
import { Technical } from './components/technical'

// 参数库
const ParamsStore = () => {
  const [activeTab, setActiveTab] = useState(location.href.includes('type=fin') ? 'financial' : 'tech')

  useEffect(() => {
    SubBreadcrumbObservable.next([
      {
        name: '设置',
        url: '',
      },
      {
        name: '参数库',
        url: '/user-center/user-params',
      },
    ])
    SideMenuObservable.next(true)
  }, [])

  return (
    <div className={`${styles.userCenter}`}>
      <h2 style={{ fontWeight: 700 }}>参数库</h2>
      <Tabs activeKey={activeTab} onChange={setActiveTab}>
        <Tabs.TabPane key="tech" tab="技术参数库">
          {activeTab === 'tech' && <Technical />}
        </Tabs.TabPane>
        <Tabs.TabPane key="financial" tab="财务参数库">
          {activeTab === 'financial' && <Financial />}
        </Tabs.TabPane>
      </Tabs>
    </div>
  )
}

export default ParamsStore
