import React, { useImperativeHandle } from 'react'
import moment from 'moment'
import { Form, Input, Row, Spin, Table } from 'antd'
import { InvestPlanService } from '../../../../apis/wl-service'
import { CustomFormItem, CustomFormTitle, formItemLayout } from '../../../../components/wl-form-item'
import { engineering_propertiesOptions } from '../../common/constant'
import useProject from '../common/useProject'

export interface IInvestPlanFormProps {
  id?: string
  editable?: boolean
}

export const RefForm = ({ id, editable = false }: IInvestPlanFormProps, ref: any) => {
  // type=params作为参数发送到服务端
  // type=component转换数据用于组件渲染
  const formatter = (values: any, type: 'params' | 'component') => {
    // 转换时间值
    if (editable) {
      ;['plan_year', 'build_start_year', 'build_end_year'].forEach((k: string) => {
        if (type === 'component') {
          values[k] = values[k] ? moment(values[k]) : null
        }
        if (type === 'params') {
          values[k] = values[k] ? moment(values[k]).format('YYYY') : ''
        }
      })
    }

    // 转换文件值
    ;['pt_decision_file', 'xm_decision_file', 'append_file'].forEach((k: string) => {
      if (type === 'component' && !Array.isArray(values[k])) {
        values[k] = values[k] ? JSON.parse(values[k]) : []
      }

      if (type === 'params') {
        values[k] = values[k]?.length ? JSON.stringify(values[k]) : ''
      }
    })

    // 设置select
    ;['engineering_properties'].forEach((k: string) => {
      if (type === 'component' && typeof values[k] === 'number') {
        values[k] = values[k].toString()
      }
    })

    // 重组资金来源、投资计划详情数据
    const infoEntity = [
      'zyczzj',
      'provinceczzj',
      'wstz',
      'countyczzj',
      'qyzyzj',
      'cityczzj',
      'yhdk',
      'qtzj',
      'total_investment',
      'work_quantity',
      'gclncjhsb',
      'gclncjhxd',
      'gclnzjhsb',
      'gclnzjhxd',
      'money_quantity',
      'zjncjhsb',
      'zjncjhxd',
      'zjnzjhsb',
      'zjnzjhxd',
    ].reduce((acc: any, k: string) => {
      if (type === 'component') {
        // 获取资金来源、投资计划详情数据
        values[k] = values?.energy_invest_plan_infoEntityList?.[0]?.[k] ?? ''
      }
      if (type === 'params') {
        acc[k] = values[k]
        delete values[k]
        return acc
      }
    }, {})
    if (type === 'params') {
      values.energy_invest_plan_infoEntityList = [infoEntity]
    }

    return values
  }

  const [{ imperativeHandle, form, formData, loading, projectId, options, getDetails, onValuesChange }] = useProject({
    detailsApi: async (projectId) => {
      return await (projectId ? InvestPlanService.info({ id: projectId }) : InvestPlanService.draft())
    },
    submitOptions: {
      label: '新建投资计划',
      Api: (values: any) =>
        InvestPlanService.update({
          id: values.id ?? '',
          root: values,
        }),
    },
    formatter,
    key: 'investPlan',
    shareable: !id,
    mergeResponse: ({ resourceList }: any) => {
      return {
        mark: resourceList?.mark,
        project_id: resourceList?.id,
        project_name: resourceList?.projectName,
        project_code: resourceList?.projectCode,
        project_type: resourceList?.resourcetype,
      }
    },
  })

  useImperativeHandle(ref, imperativeHandle)

  // 根据projectId获取详情
  React.useEffect(() => {
    getDetails(id ?? projectId).then()
  }, [id, projectId])

  const computed = {
    // 求和
    sum: (v: any) => {
      return Object.keys(v).reduce((acc, k) => {
        acc += +(v[k] ?? 0)
        return acc
      }, 0)
    },
  }

  //计算总投资
  const sumTotalInvestment = () => {
    const v = form.getFieldsValue([
      'zyczzj',
      'provinceczzj',
      'cityczzj',
      'countyczzj',
      'qyzyzj',
      'wstz',
      'yhdk',
      'qtzj',
    ])
    const sum = computed.sum(v)
    form.setFieldsValue({ total_investment: sum })
  }

  return (
    <Spin spinning={loading}>
      <Form
        name="investPlanForm"
        form={form}
        labelWrap
        {...formItemLayout.span8}
        autoComplete="off"
        initialValues={formData}
        onValuesChange={(changedValues, allValues) => {
          onValuesChange(changedValues, allValues)
        }}
      >
        <Form.Item name="id" hidden>
          <Input />
        </Form.Item>

        <CustomFormTitle>基本信息</CustomFormTitle>
        <Row>
          <CustomFormItem
            label="项目名称"
            name="mark"
            type="project_selector"
            span={3}
            editable={editable}
            options={options.projectIdOptions}
            setOptions={options.setProjectIdOptions}
            setValues={form.setFieldsValue}
            value={formData.project_name}
            componentProps={{
              disabled: !!id,
            }}
            rules={[
              {
                required: true,
                message: '请选择项目名称',
              },
            ]}
          />

          <CustomFormItem
            label="计划年份"
            name="plan_year"
            span={3}
            type="datepicker"
            editable={editable}
            value={formData.plan_year}
            componentProps={{
              format: 'YYYY',
            }}
          />
          <CustomFormItem
            label="项目代码"
            name="project_code"
            span={3}
            type="input"
            editable={editable}
            value={formData.project_code}
            componentProps={{
              disabled: true,
            }}
          />

          <CustomFormItem
            label="项目类型"
            name="project_type"
            span={3}
            type="select"
            editable={editable}
            computed={true}
            options={options.projectTypeOptions}
            value={formData.project_type}
            componentProps={{
              disabled: true,
            }}
          />

          <CustomFormItem
            label="工程性质"
            name="engineering_properties"
            span={3}
            type="select"
            editable={editable}
            computed={true}
            options={engineering_propertiesOptions}
            value={formData.engineering_properties}
          />

          <CustomFormItem
            label="建设起始年份"
            name="build_start_year"
            span={3}
            type="datepicker"
            editable={editable}
            value={formData.build_start_year}
            componentProps={{
              format: 'YYYY',
              disabledDate: (current: any) => {
                const end = form.getFieldValue('build_end_year')
                return current && end && current > end.endOf('year')
              },
            }}
          />
          <CustomFormItem
            label="建设截至年份"
            name="build_end_year"
            span={3}
            type="datepicker"
            editable={editable}
            value={formData.build_end_year}
            componentProps={{
              format: 'YYYY',
              disabledDate: (current: any) => {
                const start = form.getFieldValue('build_start_year')
                return current && start && current < start.endOf('year')
              },
            }}
          />

          <CustomFormItem
            label="本期建设规模(MW)"
            name="scale"
            span={3}
            type="number"
            editable={editable}
            value={formData.scale}
            componentProps={{}}
          />

          <CustomFormItem
            label="工程量主要建设内容及进度要求"
            name="work_schedule"
            span={1}
            type="textarea"
            editable={editable}
            value={formData.work_schedule}
            componentProps={{}}
          />
        </Row>

        <CustomFormTitle>资金来源</CustomFormTitle>
        <Row>
          <CustomFormItem
            label="中央财政资金(万元)"
            name="zyczzj"
            span={3}
            type="number"
            editable={editable}
            value={formData.zyczzj}
            componentProps={{
              onChange: sumTotalInvestment,
            }}
          />
          <CustomFormItem
            label="省财政资金(万元)"
            name="provinceczzj"
            span={3}
            type="number"
            editable={editable}
            value={formData.provinceczzj}
            componentProps={{
              onChange: sumTotalInvestment,
            }}
          />

          <CustomFormItem
            label="外商投资(万元)"
            name="wstz"
            span={3}
            type="number"
            editable={editable}
            value={formData.wstz}
            componentProps={{
              onChange: sumTotalInvestment,
            }}
          />
          <CustomFormItem
            label="县财政资金(万元)"
            name="countyczzj"
            span={3}
            type="number"
            editable={editable}
            value={formData.countyczzj}
            componentProps={{
              onChange: sumTotalInvestment,
            }}
          />
          <CustomFormItem
            label="企业自有资金(万元)"
            name="qyzyzj"
            span={3}
            type="number"
            editable={editable}
            value={formData.qyzyzj}
            componentProps={{
              onChange: sumTotalInvestment,
            }}
          />
          <CustomFormItem
            label="市财政资金(万元)"
            name="cityczzj"
            span={3}
            type="number"
            editable={editable}
            value={formData.cityczzj}
            componentProps={{
              onChange: sumTotalInvestment,
            }}
          />
          <CustomFormItem
            label="银行贷款(万元)"
            name="yhdk"
            span={3}
            type="number"
            editable={editable}
            value={formData.yhdk}
            componentProps={{
              onChange: sumTotalInvestment,
            }}
          />
          <CustomFormItem
            label="其他资金(万元)"
            name="qtzj"
            span={3}
            type="number"
            editable={editable}
            value={formData.qtzj}
            componentProps={{
              onChange: sumTotalInvestment,
            }}
          />
        </Row>

        <CustomFormTitle>投资计划详情</CustomFormTitle>
        <Table
          bordered
          className="bordered-table"
          pagination={false}
          rowKey={(...args: any[]) => args[1]}
          size="small"
          tableLayout="fixed"
          dataSource={[formData]}
          columns={[
            {
              dataIndex: 'total_investment',
              title: '项目总投资',
              align: 'center',
              render: (text: any) => {
                return (
                  <CustomFormItem
                    noStyle
                    label=""
                    name="total_investment"
                    span={1}
                    type="number"
                    editable={editable}
                    value={text}
                  />
                )
              },
            },
            {
              dataIndex: 'work_quantity',
              title: '年底累计完成工程量',
              align: 'center',
              render: (text: any) => {
                return (
                  <CustomFormItem
                    noStyle
                    label=""
                    name="work_quantity"
                    span={1}
                    type="number"
                    editable={editable}
                    value={text}
                  />
                )
              },
            },
            {
              dataIndex: 'gclncjhsb',
              title: '工程量年初计划(上报数)',
              align: 'center',
              render: (text: any) => {
                return (
                  <CustomFormItem
                    noStyle
                    label=""
                    name="gclncjhsb"
                    span={1}
                    type="number"
                    editable={editable}
                    value={text}
                  />
                )
              },
            },
            {
              dataIndex: 'gclncjhxd',
              title: '工程量年初计划(下达数)',
              align: 'center',
              render: (text: any) => {
                return (
                  <CustomFormItem
                    noStyle
                    label=""
                    name="gclncjhxd"
                    span={1}
                    type="number"
                    editable={editable}
                    value={text}
                    componentProps={{}}
                  />
                )
              },
            },
            {
              dataIndex: 'gclnzjhsb',
              title: '工程量年终调整计划(上报数)',
              align: 'center',
              render: (text: any) => {
                return (
                  <CustomFormItem
                    noStyle
                    label=""
                    name="gclnzjhsb"
                    span={1}
                    type="number"
                    editable={editable}
                    value={text}
                    componentProps={{}}
                  />
                )
              },
            },
            {
              dataIndex: 'gclnzjhxd',
              title: '工程量年终调整计划(下达数)',
              align: 'center',
              render: (text: any) => {
                return (
                  <CustomFormItem
                    noStyle
                    label=""
                    name="gclnzjhxd"
                    span={1}
                    type="number"
                    editable={editable}
                    value={text}
                    componentProps={{}}
                  />
                )
              },
            },
            {
              dataIndex: 'money_quantity',
              title: '年底累计资金完成量',
              align: 'center',
              render: (text: any) => {
                return (
                  <CustomFormItem
                    noStyle
                    label=""
                    name="money_quantity"
                    span={1}
                    type="number"
                    editable={editable}
                    value={text}
                    componentProps={{}}
                  />
                )
              },
            },
          ]}
        />

        <Form.Item wrapperCol={{ span: 24 }}>
          <Table
            bordered
            className="bordered-table"
            pagination={false}
            rowKey={(...args: any[]) => args[1]}
            size="small"
            tableLayout="fixed"
            dataSource={[formData]}
            columns={[
              {
                dataIndex: 'zjncjhsb',
                title: '资金年初计划(上报数)',
                align: 'center',
                render: (text: any) => {
                  return (
                    <CustomFormItem
                      noStyle
                      label=""
                      name="zjncjhsb"
                      span={1}
                      type="number"
                      editable={editable}
                      value={text}
                    />
                  )
                },
              },
              {
                dataIndex: 'zjncjhxd',
                title: '资金年初计划(下达数)',
                align: 'center',
                render: (text: any) => {
                  return (
                    <CustomFormItem
                      noStyle
                      label=""
                      name="zjncjhxd"
                      span={1}
                      type="number"
                      editable={editable}
                      value={text}
                    />
                  )
                },
              },
              {
                dataIndex: 'zjnzjhsb',
                title: '资金年终调整计划(上报数)',
                align: 'center',
                render: (text: any) => {
                  return (
                    <CustomFormItem
                      noStyle
                      label=""
                      name="zjnzjhsb"
                      span={1}
                      type="number"
                      editable={editable}
                      value={text}
                    />
                  )
                },
              },
              {
                dataIndex: 'zjnzjhxd',
                title: '资金年终调整计划(下达数)',
                align: 'center',
                render: (text: any) => {
                  return (
                    <CustomFormItem
                      noStyle
                      label=""
                      name="zjnzjhxd"
                      span={1}
                      type="number"
                      editable={editable}
                      value={text}
                      componentProps={{}}
                    />
                  )
                },
              },
            ]}
          />
        </Form.Item>

        <CustomFormTitle>备注</CustomFormTitle>
        <Row>
          <CustomFormItem
            label=""
            name="memo"
            span={1}
            type="textarea"
            editable={editable}
            value={formData.memo || '暂无备注'}
            wrapperCol={{ span: 24 }}
          />
        </Row>

        <CustomFormTitle>附件</CustomFormTitle>
        <Row>
          <CustomFormItem
            label="平台公司的投资计划的三重一大决策文件"
            name="pt_decision_file"
            span={2}
            type="file"
            editable={editable}
            value={formData.pt_decision_file}
          />
          <CustomFormItem
            label="项目公司的三重一大决策文件"
            name="xm_decision_file"
            span={2}
            type="file"
            editable={editable}
            value={formData.xm_decision_file}
          />

          <CustomFormItem
            label="其他附件"
            name="append_file"
            span={2}
            type="file"
            editable={editable}
            value={formData.append_file}
          />
        </Row>
      </Form>
    </Spin>
  )
}

export default React.forwardRef(RefForm)
