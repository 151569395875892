import { Button, Form, Input, message } from 'antd'
import { useState } from 'react'
import { Link } from 'react-router-dom'
import { sm2 } from 'sm-crypto'

import Verify from './verify'
import VerifyPhone from './verify-phone'
import ServiceTrimModal from './service-trim-modal'
import { EMAIL_EXP, PHONE_EXP, SM2CODE } from '../../../utils/constant'
import { token } from '../../../utils/utils'
import { UserService } from '../../../apis/project-service-history'
import { useNavigate } from 'react-router-dom'
import { Global } from '../../../global'
import { PasswordNotice } from './password-notice'
import Invite from './Invite'

function Register() {
  const [form] = Form.useForm()
  const navigate = useNavigate()
  // 图片滑块验证成功状态
  const [verifyPass, setVerifyPass] = useState('')
  const [registerInfo, setRegisterInfo] = useState({
    email: '',
    password: '',
    phone: '',
  })
  // 注册请求加载状态
  const [loading, setLoading] = useState(false)
  const [serviceVisible, setServiceVisible] = useState(false)

  // 验证邮箱状态
  const [checkEmail, setCheckEmail] = useState<any>({
    validateStatus: ' ',
    help: '',
  })
  // 验证手机状态
  const [checkPhone, setCheckPhone] = useState<any>({
    validateStatus: ' ',
    help: '',
  })
  const [validloading, setValidloading] = useState(false)
  const [validPass, setValidPass] = useState(false)

  // 注册
  const handleRegister = (values: any) => {
    setLoading(true)
    UserService.register({
      root: {
        ...values,
        password: sm2.doEncrypt(values.password, SM2CODE, 0),
      },
    })
      .then((res) => {
        token.set(res?.data?.token)
        message.success('注册成功')

        UserService.info()
          .then((res) => {
            Global.user = res?.data || {}
            navigate('/login/index')
          })
          .catch(() => {
            Global.user = {}
          })
      })
      .catch(() => {})
      .finally(() => {
        setLoading(false)
      })
  }

  // 验证邮箱是否已存在
  const validEmail = (e: any) => {
    const value = e.target.value
    var emailreg = EMAIL_EXP
    if (!value) {
      setCheckEmail({ validateStatus: 'error', help: '邮箱必填' })
      return
    } else if (!emailreg.test(value)) {
      setCheckEmail({ validateStatus: 'error', help: '邮箱格式错误' })
      return
    }

    UserService.checkEmail({ email: value })
      .then((res) => {
        if (res.data) {
          setCheckEmail({
            validateStatus: 'success',
            help: ' ',
          })
        } else {
          setCheckEmail({
            validateStatus: 'error',
            help: (
              <div>
                邮箱已存在，去<Link to="/login/index">登录</Link>
              </div>
            ),
          })
        }
      })
      .catch(() => {})
  }

  const [showNotice, setShowNotice] = useState(false)

  // 验证手机号是否已存在
  const validPhone = (e: any) => {
    const value = e.target.value
    setValidloading(true)
    UserService.validTelephone({ mobile: value })
      .then((res) => {
        if (res.data) {
          setCheckPhone({
            validateStatus: 'error',
            help: '手机号已被占用',
          })
        } else {
          setCheckPhone({
            validateStatus: '',
            help: null,
          })
        }
      })
      .catch(() => {})
      .finally(() => {
        setValidloading(false)
      })
  }

  const onFinishFailed = (values: any) => {
    console.info(values)
  }

  const showTermsService = () => {
    console.info('显示服务条款')
    setServiceVisible(true)
  }

  return (
    <div>
      <Invite>
        <h1 style={{ marginBottom: '3px', fontSize: 36 }}>注册</h1>
      </Invite>
      <div className="desc-text" style={{ marginBottom: 30 }}>
        已有帐号&nbsp;
        <Link to="/login/index">立即登录</Link>
      </div>
      <div>
        <Form
          form={form}
          labelAlign="left"
          onFinish={handleRegister}
          onFinishFailed={onFinishFailed}
          style={{
            width: '100%',
          }}
        >
          <Form.Item
            name="email"
            validateStatus={checkEmail.validateStatus}
            help={checkEmail.help ? checkEmail.help : undefined}
          >
            <Input
              size="large"
              onBlur={validEmail}
              placeholder="请输入邮箱"
              type="email"
              onChange={(e) => {

                setVerifyPass('');
                form.setFieldsValue({captchaVerification: ''});

                setRegisterInfo({
                  ...registerInfo,
                  email: e.target.value,
                })
              }}
            />
          </Form.Item>
          <Form.Item
            name="password"
            rules={[
              {
                required: true,
                message: '密码必填',
              },
              {
                min: 8,
                max: 16,
                message: '密码长度8~16位',
              },
              {
                pattern: /^.*(?=.*\d)(?=.*[A-Z])(?=.*[a-z]).*$/,
                message: '必须包含大写字母小写字母和数字',
              },
              {
                pattern: /((?=[\x21-\x7e]+)[^A-Za-z0-9])/,
                message: '必须包含特殊字符',
              },
            ]}
          >
            <Input
              size="large"
              type="password"
              placeholder="请输入密码"
              onFocus={() => {
                setShowNotice(true)
              }}
              onBlur={() => {
                setShowNotice(false)
              }}
              onChange={(e) => {
                setVerifyPass('');
                form.setFieldsValue({captchaVerification: ''});
                form.setFieldsValue({ password: e.target.value })
                setRegisterInfo({
                  ...registerInfo,
                  password: e.target.value,
                })
              }}
            />
            {showNotice && <PasswordNotice password={registerInfo.password} />}
          </Form.Item>
          <Form.Item
            name="captchaVerification"
            rules={[
              {
                required: true,
                message: '请进行验证',
              },
            ]}
          >
            <Verify
              update={setVerifyPass}
              disabled={!(registerInfo.email && registerInfo.password)}
              isVerifyPass={!!verifyPass}
            />
          </Form.Item>
          <Form.Item
            name="telephone"
            validateStatus={checkPhone.validateStatus}
            help={checkPhone.help ? checkPhone.help : undefined}
          >
            <Input
              size="large"
              disabled={!verifyPass}
              onBlur={(e) => {
                if (!e.target.value) {
                  setCheckPhone({
                    validateStatus: 'error',
                    help: '手机号必填',
                  })
                } else if (!PHONE_EXP.test(e.target.value)) {
                  setCheckPhone({
                    validateStatus: 'error',
                    help: '手机号格式不正确',
                  })
                } else {
                  if (!validPass && !validloading) {
                    validPhone(e)
                  }
                  setCheckPhone({
                    validateStatus: '  ',
                    help: null,
                  })
                }
              }}
              onChange={(e) => {
                if (e.target.value.length === 11 && !validPass && !validloading) {
                  validPhone(e)
                } else {
                  setValidPass(false)
                }

                setRegisterInfo({
                  ...registerInfo,
                  phone: e.target.value,
                })
              }}
              type="phone"
              placeholder="请输入手机号"
            />
          </Form.Item>
          <Form.Item
            name="code"
            rules={[
              {
                required: true,
                message: '请输入验证码',
              },
              {
                min: 6,
                message: '验证码不正确',
              },
            ]}
          >
            <VerifyPhone
              form={form}
              disabled={checkPhone.validateStatus === 'error' || checkPhone.help === ''}
              setValidate={setCheckPhone}
              type="register"
            />
          </Form.Item>
          <Form.Item>
            <Button
              size="large"
              style={{
                width: '100%',
                height: 42,
              }}
              type="primary"
              htmlType="submit"
              loading={loading}
            >
              注册
            </Button>
          </Form.Item>
        </Form>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          点击”注册“表示已同意并阅读
          <span onClick={showTermsService}>
            <a>《服务条款》</a>
          </span>
        </div>
      </div>
      {serviceVisible && (
        <ServiceTrimModal
          visible={serviceVisible}
          onCancel={() => {
            setServiceVisible(false)
          }}
        />
      )}
    </div>
  )
}

export default Register
