import React, { useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react'
import moment from 'moment'
import { Card, DatePicker, message, Space, Typography } from 'antd'
import ReactECharts, { EChartsInstance } from 'echarts-for-react'
import echartsOption, { BarConfigType, BarDataType, RangeValue } from './echarts-option'
import { InvestService } from '../../../../apis/wl-service'

const __radio = [
  { key: 'day', label: '今日', id: 1 },
  { key: 'week', label: '本周', id: 2 },
  { key: 'month', label: '本月', id: 3 },
  { key: 'year', label: '本年', id: 4 },
]

export interface IBarProps {
  permissions: any
}

const App: React.FC<IBarProps> = ({ permissions }) => {
  const echartsRef = useRef<EChartsInstance>()
  const [tabList, setTabList] = useState<{ key: string; tab: string }[]>([])
  const [activeTabKey, setActiveTabKey] = useState<string>(tabList[0]?.key)
  const [time, setTime] = useState<BarConfigType['xAxisType']>('year')
  const [dates, setDates] = useState<RangeValue>(null)
  const [dataSource, setDataSource] = useState<any>({})

  useLayoutEffect(() => {
    echartsRef.current?.resize()
  }, [])

  const loadData = async (key?: string) => {
    try {
      const postData = {
        type: (key ?? activeTabKey) === 'customer' ? 2 : 1,
      }
      if (Array.isArray(time)) {
        const [start, end] = time
        Object.assign(postData, {
          beginTime: start?.format('YYYY-MM-DD') ?? '',
          endTme: end?.format('YYYY-MM-DD') ?? '',
        })
      } else {
        Object.assign(postData, {
          dateType: __radio.find((v: any) => v.key === time)?.id ?? '',
        })
      }
      const resp: any = await InvestService.lineChart(postData)
      setDataSource(resp?.data ?? {})
      if (resp?.code !== 200 && resp?.msg) {
        message.warn(resp.msg)
      }
    } catch (e) {
      console.warn('获取工作台-签约情况失败：', e)
    }
  }

  useEffect(() => {
    setTabList(() => {
      const list = []
      if (permissions.partnerSigning) {
        list.push({
          key: 'partner',
          tab: '合作伙伴签约',
        })
      }
      if (permissions.customerSigning) {
        list.push({
          key: 'customer',
          tab: '客户签约',
        })
      }
      return list
    })
  }, [permissions])

  // 时间类型变化加载数据
  useEffect(() => {
    loadData()
  }, [time])

  const CardAction = (
    <Space size={16} style={{ float: 'right' }}>
      {__radio.map(({ key, label }) => (
        <Typography.Text
          key={key}
          type={key === time ? 'success' : undefined}
          style={{ cursor: 'pointer' }}
          onClick={() => {
            setTime(key as BarConfigType['xAxisType'])
          }}
        >
          {label}
        </Typography.Text>
      ))}
      <DatePicker.RangePicker
        value={(typeof time === 'string' ? [] : time) as any}
        disabledDate={(current: moment.Moment) => {
          if (!dates) {
            return false
          }
          const tooLate = dates[0] && current.diff(dates[0], 'years') > 0
          const tooEarly = dates[1] && dates[1].diff(current, 'years') > 0
          return !!tooEarly || !!tooLate
        }}
        onCalendarChange={(val) => setDates(val)}
        onChange={(val) => setTime(val)}
      />
    </Space>
  )

  const option = useMemo(() => {
    const barData = [
      {
        name: '未签约',
        // value: [210, 20, 220, 50, 420, 350, 200],
        value: dataSource?.contractWait ?? [],
        color: '#59a1ff',
      },
      {
        name: '签约中',
        // value: [50, 250, 650, 450, 650, 110, 300],
        value: dataSource?.contracting ?? [],
        color: '#6cd4d4',
      },
      {
        name: '已签约',
        // value: [40, 490, 520, 250, 120, 50, 450],
        value: dataSource?.contractOk ?? [],
        color: '#69d4a6',
      },
      {
        name: '已作废',
        // value: [30, 70, 350, 150, 450, 70, 650],
        value: dataSource?.contractVoid ?? [],
        color: '#f9d473',
      },
      {
        name: '已退回',
        // value: [10, 50, 50, 550, 350, 75, 550],
        value: dataSource?.contractBack ?? [],
        color: '#f35350',
      },
      {
        name: '已过期',
        // value: [10, 350, 650, 550, 450, 800, 750, 650, 550, 450, 300, 200],
        value: dataSource?.contractOut ?? [],
        color: '#8153ff',
      },
    ]
    return echartsOption.signing(barData as BarDataType[], {
      // xAxisType: time,
      axis: dataSource?.axis ?? [],
    })
  }, [dataSource])

  const onTabChange = React.useCallback(
    (key: string) => {
      setActiveTabKey(key)
      setTime('year')
      if (time === 'year') {
        loadData(key)
      }
    },
    [time]
  )

  if (tabList.length === 0) {
    return null
  }

  return (
    <Card
      style={{ width: '100%' }}
      tabList={tabList}
      tabBarExtraContent={CardAction}
      activeTabKey={activeTabKey}
      onTabChange={onTabChange}
    >
      <ReactECharts
        ref={echartsRef}
        notMerge={true}
        lazyUpdate={true}
        option={option}
        style={{
          position: 'relative',
          width: '100%',
          height: '320px',
        }}
      />
    </Card>
  )
}

export default App
