import { Button, Tabs } from 'antd'
import { useEffect, useState } from 'react'
import AccountLogin from './account-login'
import PhoneLogin from './phone-login'
// import { Link } from 'react-router-dom'
import styles from '../index.module.scss'
import Invite from './Invite'
// import { showRegister } from '../../../global'

declare var isDemoEnv: boolean

// 登录组件
function Login() {
  const [activeTab, setActiveTab] = useState('account')

  useEffect(() => {})

  return (
    <div style={{ paddingBottom: 50 }}>
      <Invite>
        <h1 style={{ marginBottom: '3px', fontSize: 36 }}>登录</h1>
      </Invite>
      {/*{showRegister && !isDemoEnv && (*/}
      {/*<div className="desc-text">*/}
      {/*没有账号请&nbsp;*/}
      {/*<Link to="/login/register">立即注册</Link>*/}
      {/*</div>*/}
      {/*)}*/}
      {/* <div className={styles.inviteTitle}>
        <span className={styles.inviter}>XXX邀请你加入</span>
        <span className={styles.invited}>XXX项目</span>
      </div> */}
      {/* <div className={styles.inviteTips}>
        <span>请先登录，若没有账号请先</span>
        <Link to="/login/register">注册账户</Link>
      </div> */}
      <Tabs
        activeKey={activeTab}
        onChange={(e) => {
          setActiveTab(e)
        }}
        style={{ overflow: 'visible' }}
        className={styles.loginTabs}
      >
        <Tabs.TabPane tab="账号登录" key="account">
          <AccountLogin />
        </Tabs.TabPane>
        <Tabs.TabPane tab="手机登录" key="phone">
          <PhoneLogin />
        </Tabs.TabPane>
      </Tabs>
      {!isDemoEnv && (
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Button
            type="link"
            onClick={() => {
              setActiveTab(activeTab === 'account' ? 'phone' : 'account')
            }}
            style={{ padding: 0, color: '#1ec692' }}
          >
            {activeTab === 'account' ? '手机登录' : '账号登录'}
          </Button>
          <Button
            type="link"
            style={{ padding: 0, color: '#1ec692' }}
            onClick={() => {
              location.assign('#/login/forget')
            }}
          >
            忘记密码?
          </Button>
        </div>
      )}
    </div>
  )
}

export default Login
