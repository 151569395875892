import { List, Avatar, Spin, Tooltip } from 'antd'
import React, { useEffect, useState } from 'react'
import { ProjectServiceHistory } from '../../../apis/project-service-history'
import Global from '../../../global'

import styles from '../index.module.scss'

const operaType: any = {
  CREATE: '创建',
  RENAME: '重命名',
  DELETE: '删除',
  UPDATE_BORDER: '更改边界',
  ARCHIVE: '归档',
  STAR: '星标',
  INVITE: '将',
}

const objType: any = {
  RESOURCES: '资源',
  PROJECT: '项目',
  TECHNICAL: '技术方案',
  FINANCIAL: '财务方案',
  INVITE_PROJECT: '添加至',
}

// 活动日志
const ActivityLogs = (props: any) => {
  const { data } = props
  const [loading, setLoading] = useState(false)
  const [dataSource, setDataSource] = useState(data || [])

  useEffect(() => {
    setLoading(true)
    ProjectServiceHistory.getActivityLog()
      .then((res) => {
        setDataSource(res.data || [])
      })
      .catch(() => {})
      .finally(() => {
        setLoading(false)
      })
  }, [])

  const jumpTo = (item: any) => {
    const url = item.objType === 'RESOURCES' ? `#/resource/list` : item.objType === 'FINANCE' ? `#/program/detail/${item.objId}/fin` : `#/program/detail/${item.objId}`
    if (url) {
      location.assign(`${url}`)
      Global.changeMenu.forEach((Fn: any) => {
        Fn(url)
      })
    }
  }

  const renderTitle = (item: any, type: string) => {
    let content = <></>
    const name = item.userName?.length > 6 ? `${item.userName.substring(0, 4)}...` : item.userName
    const objName = (
      <>
        <Tooltip title={item.objName}>{item.objName}</Tooltip>
      </>
    )
    switch (type) {
      case 'CREATE':
        content = (
          <div className="log-row">
            {name}
            <span>{operaType[item.optType]}</span>
            <span style={{ marginRight: '16px' }}>{objType[item.objType]}</span>
            {item.objDelete ? (
              <>{objName}</>
            ) : (
              <span
                onClick={() => {
                  jumpTo(item)
                }}
              >
                {<a>{objName}</a>}
              </span>
            )}
          </div>
        )
        break
      case 'DELETE':
        content = (
          <div className="log-row">
            {name}
            <span>{operaType[item.optType]}</span>
            <span>{objType[item.objType]}</span>
            <span style={{ marginLeft: '16px' }}>{objName}</span>
          </div>
        )
        break
      case 'RENAME':
        content = (
          <div className="log-row">
            {name}
            <span>{operaType[item.optType]}</span>
            <span>{objType[item.objType]}</span>
            <span style={{ marginRight: '16px' }}>{` "${item.valMap?.oldName}"`} 为</span>
            {item.objDelete ? (
              <>{item.valMap?.nowName}</>
            ) : (
              <span
                onClick={() => {
                  jumpTo(item)
                }}
              >
                {<a>{item.valMap?.nowName}</a>}
              </span>
            )}
          </div>
        )
        break
      case 'UPDATE_BORDER':
        content = (
          <div className="log-row">
            {name}
            <span style={{ marginRight: '16px' }}>更改项目</span>
            {item.objDelete ? (
              <>{objName}</>
            ) : (
              <span
                onClick={() => {
                  jumpTo(item)
                }}
              >
                {<a>{objName}</a>}
              </span>
            )}
            <span>的</span>
            <span>{item.valMap?.typeName}</span>
          </div>
        )
        break
      case 'ARCHIVE':
        content = (
          <div className="log-row">
            {name}
            {item.valMap?.type === 0 && <span>解除</span>}
            <span>{operaType[item.optType]}</span>
            <span style={{ marginRight: '16px' }}>{objType[item.objType]}</span>
            {item.objDelete ? (
              <>{objName}</>
            ) : (
              <span
                onClick={() => {
                  jumpTo(item)
                }}
              >
                {<a>{objName}</a>}
              </span>
            )}
          </div>
        )
        break
      case 'STAR':
        content = (
          <div className="log-row">
            {name}
            {item.valMap?.type === 0 && <span>解除</span>}
            <span>{operaType[item.optType]}</span>
            <span style={{ marginRight: '16px' }}>{objType[item.objType]}</span>
            {item.objDelete ? (
              <>{objName}</>
            ) : (
              <span
                onClick={() => {
                  jumpTo(item)
                }}
              >
                {<a>{objName}</a>}
              </span>
            )}
          </div>
        )
        break
      case 'INVITE':
        content = (
          <div className="log-row">
            {item.valMap?.inviteUserName}
            <span style={{
              padding: '0 .5rem',
            }}>{operaType[item.optType]}</span>
            {item.valMap?.byInviteUserName}
            <span style={{ marginRight: '16px' }}>{objType[item.objType]}</span>
            <span
              onClick={() => {
                jumpTo(item)
              }}
            >
              {<a>{item.valMap?.projectName}</a>}
            </span>
          </div>
        )
        break
    }
    return content
  }

  return (
    <Spin spinning={loading}>
      <div
        className={`${styles.recentActions} ${styles.activityLogs} ${styles.basePadding} `}
        style={{
          position: 'relative',
          marginBottom: 0,
        }}
      >
        <h1>活动日志</h1>
        <List
          className={`${styles.listContent} custom-scroll-bar`}
          size="small"
          dataSource={[...dataSource]}
          renderItem={(item) => (
            <List.Item className={item.type}>
              <List.Item.Meta
                avatar={
                  <Avatar style={{ backgroundColor: 'rgb(66, 212, 161)' }} src={item.headUrl}>
                    {item.email?.substring?.(0, 2)}
                  </Avatar>
                }
                title={renderTitle(item, item.optType)}
                description={<div style={{ color: 'rgba(0, 0, 0, 0.25)' }}>{item.time}</div>}
              />
            </List.Item>
          )}
        />
      </div>
    </Spin>
  )
}

export default ActivityLogs
