import React, { useState, useEffect, useRef, useImperativeHandle, forwardRef } from 'react'
import { Form, Modal, Checkbox, Space, Typography, message } from 'antd'
import { FormInstance } from 'antd/lib/form'
import type { CheckboxChangeEvent } from 'antd/es/checkbox'
import { ResourceService } from '../../../../apis/wl-service'

// 配置可选字段
export const dataSourceConfig: any = [
  {
    title: '',
    columnList: [
      { value: 'resourceyear', label: '年份' },
      { value: 'project_name', label: '项目名称' },
      { value: 'resourcetype', label: '项目类型' },
      // {value:'resourcemodule', label: '所属板块'},
      { value: 'project_code', label: '项目代码' },
      { value: 'project_module', label: '项目规模' },
      { value: 'resourcedependency', label: '资源属地' },
      { value: 'address', label: '详细地址' },
      { value: 'important_level', label: '重要程度' },
    ],
  },
  {
    title: '资源开发',
    columnList: [
      { value: 'developer', label: '开发主体' },
      { value: 'partnerId', label: '合作方' },
      { value: 'invest_type', label: '投资模式' },
      { value: 'ydys', label: '是否完成用地预审' },
      { value: 'is_scale', label: '是否纳入地方指标' },
      { value: 'is_sign', label: '是否签署协议' },
    ],
  },
  {
    title: '核准备案',
    columnList: [
      { value: 'filing_scale', label: '备案规模' },
      { value: 'filing_invest', label: '备案投资额' },
      { value: 'filing_organization', label: '备案机构' },
      { value: 'filing_time', label: '备案日期' },
      { value: 'filing_number', label: '备案号' },
      { value: 'filing_invest_main', label: '备案投资主体' },
    ],
  },
  {
    title: '投资决策',
    columnList: [
      { value: 'decision_organization', label: '决策机构' },
      { value: 'decision_time', label: '决策时间' },
      { value: 'total_invest', label: '决策总投资' },
      { value: 'capital', label: '资本金' },
      { value: 'dynamic_invest', label: '动态投资' },
      { value: 'fluid_capital', label: '流动资金' },
      { value: 'income_rate', label: '资本金内部收益率' },
      { value: 'capital_rate', label: '资本金比例' },
      { value: 'decision_volume', label: '决策容量' },
      { value: 'install_size', label: '装机规模' },
      { value: 'storage', label: '配套储能(MW)' },
    ],
  },
  {
    title: '建设信息',
    columnList: [
      { value: 'plan_work_month', label: '计划工期' },
      { value: 'production_capacity', label: '已投产容量' },
      { value: 'production_real_time', label: '投产时间' },
      { value: 'plan_start_time', label: '里程碑计划时间' },
      { value: 'real_start_time', label: '实际完成时间' },
      { value: 'main_develop', label: '主要建设内容' },
    ],
  },
  {
    title: '造价信息',
    columnList: [
      { value: 'record_time', label: '备案时间' },
      { value: 'record_cost', label: '备案投资额' },
      { value: 'design_unit', label: '设计单位' },
      { value: 'develop_unit', label: '建设单位' },
    ],
  },
  {
    title: '投资计划',
    columnList: [
      { value: 'engineering_properties', label: '工程性质' },
      { value: 'scale', label: '本期建设规模' },
      { value: 'build_start_year', label: '建设起始年份' },
      { value: 'build_end_year', label: '建设截至年份' },
    ],
  },
]

export interface MyFormInstance extends FormInstance {
  resetForm: () => void
}

export const MyForm = (props: any, ref: any) => {
  const [form] = Form.useForm()
  const [dataSource, setDataSource] = useState([])

  const [checkAll, setCheckAll] = useState<{ indeterminate: boolean; checked: boolean }>({
    indeterminate: false,
    checked: false,
  })

  useImperativeHandle(ref, () => ({
    ...form,
    resetForm: initForm,
  }))

  useEffect(() => {
    initForm()
  }, [dataSource])

  useEffect(() => {
    setDataSource(props.mode === 'engineering' ? dataSourceConfig : dataSourceConfig.slice(0, 4))
  }, [props.mode, dataSourceConfig])

  // 选中所有的值
  const selectedValues = React.useMemo(() => {
    return dataSource.reduce((occ: any[], cur: any, index: number) => {
      if (cur.columnList) {
        occ = occ.concat(
          cur.columnList.map((v: any) => v.value),
          index?.toString(),
        )
      }
      return occ
    }, [])
  }, [dataSource])


  // 初始化选中所有数据
  const initForm = () => {
    form.setFieldsValue({
      selectKey: selectedValues,
    })
    setCheckAll({ checked: true, indeterminate: false })
  }

  const util = React.useMemo(() => {
    return {
      // 是否全选
      isSelectAll: (selectKey: string[]) => {
        const selectKeySet = new Set(selectKey)
        for (let i = 0, len = dataSource.length; i < len; i++) {
          const ki = i.toString()
          if (!selectKeySet.has(ki)) {
            return false
          }
        }
        return true
      },
      // 是否选中父节点
      isSelectParent: (parentValue: string, selectKey: string[]) => {
        const valuesSet = new Set(dataSource[parentValue].columnList.map((v: any) => v.value))
        let counter = 0
        for (let i = 0, len = selectKey.length; i < len; i++) {
          if (valuesSet.has(selectKey[i])) {
            counter++
          }
        }
        return counter === valuesSet.size
      },
    }
  }, [dataSource])

  // 全选
  const handlerCheckAllChange = ({ target: { checked } }: CheckboxChangeEvent) => {
    setCheckAll({
      checked,
      indeterminate: false,
    })
    form.setFieldsValue({ selectKey: checked ? selectedValues : [] })
  }

  // 选中父节点
  const handlerCheckChildrenChange = ({ target: { checked, value } }: CheckboxChangeEvent) => {
    let selectKey: string[] = form.getFieldValue('selectKey')
    const values = dataSource[value].columnList.map((v: any) => v.value).concat(value)
    if (checked) {
      selectKey = selectKey.concat(values, value)
      const checkedAll: boolean = util.isSelectAll(selectKey)
      setCheckAll({ checked: checkedAll, indeterminate: !checkedAll && selectKey.length > 0 })
    } else {
      const valuesSet = new Set(values)
      selectKey = selectKey.filter((v: any) => !valuesSet.has(v))
      setCheckAll({ checked: false, indeterminate: selectKey.length > 0 })
    }
    setTimeout(() => {
      form.setFieldsValue({ selectKey })
    }, 50)
  }

  // 选中子节点
  const handlerCheckItemChange = (index: string, { target: { checked, value } }: CheckboxChangeEvent) => {
    let selectKey: string[] = form.getFieldValue('selectKey')
    if (checked) {
      selectKey = selectKey.concat(value)
      const checkedParent: boolean = util.isSelectParent(index, selectKey)
      if (checkedParent && !selectKey.includes(index)) {
        selectKey = selectKey.concat(index)
      }
      const checkedAll = util.isSelectAll(selectKey)
      setCheckAll({ checked: checkedAll, indeterminate: !checkedAll && selectKey.length > 0 })
    } else {
      selectKey = selectKey.filter((v: any) => v !== value && v !== index)
      setCheckAll({ checked: false, indeterminate: selectKey.length > 0 })
    }
    setTimeout(() => {
      form.setFieldsValue({ selectKey })
    }, 50)
  }

  return (
    <Form size="large" autoComplete="off" form={form} layout="vertical">
      <Typography.Title level={5}>选择项目字段</Typography.Title>
      <Checkbox
        indeterminate={checkAll.indeterminate}
        checked={checkAll.checked}
        style={{ margin: '12px 0' }}
        onChange={handlerCheckAllChange}
      >
        全选
      </Checkbox>
      <Form.Item name="selectKey">
        <Checkbox.Group>
          <Space wrap={false} direction="vertical" size={[30, 12]}>
            {dataSource.map((item: any, index: number) => {
              const k = index.toString()
              return (
                <Space key={k} wrap={false} direction="vertical" size={[30, 12]}>
                  {item.title && (
                    <Checkbox value={k} onChange={handlerCheckChildrenChange}>
                      <Typography.Link strong>{item.title}</Typography.Link>
                    </Checkbox>
                  )}
                  <Space wrap={true} direction="horizontal" size={[30, 12]}>
                    {item.columnList.map((c: any) => {
                      return (
                        <Checkbox key={c.value} value={c.value} onChange={handlerCheckItemChange.bind(null, k)}>
                          {c.label}
                        </Checkbox>
                      )
                    })}
                  </Space>
                </Space>
              )
            })}
          </Space>
        </Checkbox.Group>
      </Form.Item>
    </Form>
  )
}

const MyFormRef: any = forwardRef(MyForm)

interface IModalFormProps {
  mode?: string
  visible: boolean
  visibleChange: (visible: boolean) => void
  getParams?: () => { [name: string]: any }
}

export default ({ ...props }: IModalFormProps) => {
  const [loading, setLoading] = useState<boolean>(false)
  const formRef = useRef<MyFormInstance>({} as MyFormInstance)

  const closeDialog = React.useCallback(() => {
    props.visibleChange?.(false)
  }, [props.visibleChange])

  const handlerOk = async () => {
    if (loading) {
      return
    }

    try {
      const values: any = await formRef.current.validateFields()

      values.selectKey = values.selectKey.filter((v: any) => !/^\d+$/.test(v)).join(',')
      let postData: any = { ...values, ...(props.getParams?.() ?? {}) }
      if (postData.ids?.length === 0) {
        message.error('请先选择项目进行导出项目表格哦~')
        return false
      }

      try {
        setLoading(true)
        postData.ids = postData.ids.join(',')
        ResourceService.exportAllData(postData)
        closeDialog()
        formRef.current.resetForm()
      } catch (err) {
        console.error(err)
      } finally {
        setLoading(false)
      }
    } catch (e) {
      console.error(e)
    }
  }

  return (
    <Modal
      visible={props.visible}
      width={800}
      title="导出数据"
      destroyOnClose={false} // 是否保持原有数据
      maskClosable={false}
      okButtonProps={{ loading }}
      cancelText="取 消"
      okText="导 出"
      onCancel={closeDialog}
      onOk={handlerOk}
    >
      <MyFormRef ref={formRef} mode={props.mode} />
    </Modal>
  )
}
