import { Button, Form, Input, message } from 'antd'
import { useState } from 'react'

import Verify from './verify'
import { UserService } from '../../../apis/project-service-history'
import { token } from '../../../utils/utils'
import { PHONE_EXP } from '../../../utils/constant'
import VerifyCode from './verify-code'
import { Global } from '../../../global'

let fn: any = null
// 手机登录组件
function PhoneLogin() {
  const [form] = Form.useForm()
  const [loading, setLoading] = useState(false)
  // 图片滑块验证成功状态
  const [verifyPass, setVerifyPass] = useState('')
  const [visible, setVisible] = useState(false)
  // 验证手机状态
  const [checkPhone, setCheckPhone] = useState<any>({
    validateStatus: ' ',
    help: '',
  })

  const onModalCancel = () => {
    setVisible(false)
  }

  const onSendCode = async (callback: any) => {
    const phone = form.getFieldValue('telephone')
    if (!phone) {
      setCheckPhone({
        validateStatus: 'error',
        help: '请输入手机号',
      })
      return
    } else if (!PHONE_EXP.test(phone)) {
      setCheckPhone({
        validateStatus: 'error',
        help: '手机号格式不正确',
      })
    } else {
      setCheckPhone({
        validateStatus: '',
        help: ' ',
      })
    }
    await setVisible(true)
    fn = callback
  }

  const validateFields = (values: any) => {
    let errs = []
    if (!values.telephone) {
      setCheckPhone({
        validateStatus: 'error',
        help: '请输入手机号',
      })
      errs.push(values.telephone)
    } else {
      setCheckPhone({
        validateStatus: ' ',
        help: ' ',
      })
    }

    return errs
  }

  // 登录
  const handleLogin = () => {
    if (loading) {
      return
    }
    setLoading(true)
    const values = form.getFieldsValue(['telephone', 'captchaVerification', 'code'])
    const validErrors = validateFields(values)
    if (validErrors.length > 0) {
      setLoading(false)
      return
    }
    UserService.loginByCode({
      root: {
        ...values,
        captchaVerification: verifyPass,
      },
    })
      .then((res) => {
        if (res.code === 200) {
          token.set(res?.data?.token)

          UserService.info()
            .then((res) => {
              Global.user = res?.data || {}
              if (!res?.data?.emailCheck) {
                Global.emailTipVisible = true
              }
              message.success('登录成功')
              UserService.roleInfo().then((resp) => {
                Global.role = resp.data
                location.assign('/')
              })
            })
            .catch(() => {
              Global.user = {}
            })
        } else if (res.code === 10005) {
          message.error(res.msg)
          location.assign('#/login/forget')
        } else {
          message.error(res.msg)
        }
      })
      .catch(() => {
        setLoading(false)
      })
      .finally(() => {})
  }

  return (
    <div>
      <div>
        <Form
          form={form}
          labelAlign="left"
          style={{
            width: '100%',
          }}
        >
          <Form.Item
            name="telephone"
            validateStatus={checkPhone.validateStatus}
            help={checkPhone.help ? checkPhone.help : undefined}
          >
            <Input
              size="large"
              onBlur={(e) => {
                if (!e.target.value) {
                  setCheckPhone({
                    validateStatus: 'error',
                    help: '手机号必填',
                  })
                } else if (!PHONE_EXP.test(e.target.value)) {
                  setCheckPhone({
                    validateStatus: 'error',
                    help: '手机号格式不正确',
                  })
                } else {
                  setCheckPhone({
                    validateStatus: '  ',
                    help: null,
                  })
                }
              }}
              type="phone"
              placeholder="请输入手机号"
            />
          </Form.Item>
          <Form.Item hidden name="captchaVerification">
            <Verify
              mode="modal"
              update={async (e: any) => {
                const phone = form.getFieldValue('telephone')
                setVerifyPass(e)
                if (fn) {
                  fn(phone, e)
                  setCheckPhone({
                    validateStatus: '  ',
                    help: null,
                  })
                }
              }}
              onModalCancel={onModalCancel}
              modalVisible={visible}
              isVerifyPass={!!verifyPass}
            />
          </Form.Item>
          <Form.Item
            name="code"
            rules={[
              {
                required: true,
                message: '请输入验证码',
              },
              {
                min: 6,
                message: '验证码不正确',
              },
            ]}
          >
            <VerifyCode
              field="telephone"
              disabledTip="请先输入手机号"
              disabled={checkPhone.validateStatus === 'error' || checkPhone.help === ''}
              setVisible={setVisible}
              onSendCode={onSendCode}
              setValidate={setCheckPhone}
              form={form}
              type="login"
            />
          </Form.Item>
          <Form.Item>
            <Button
              size="large"
              style={{
                width: '100%',
                height: 42,
                marginTop: '40px',
              }}
              type="primary"
              onClick={handleLogin}
              loading={loading}
            >
              登录
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  )
}

export default PhoneLogin
