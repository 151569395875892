import './application.less'

import {
  CaretDownOutlined,
  LoginOutlined,
  DoubleLeftOutlined,
  DoubleRightOutlined,
  SettingOutlined,
} from '@ant-design/icons'
import {Breadcrumb, Layout, Menu, Dropdown, Space, Modal, Button} from 'antd'
import React, {useEffect, useMemo, useState} from 'react'
import {Outlet, Link, useLocation} from 'react-router-dom'
import ProfilePhoto from './Layout/components/profile-photo'
import Logo from './Layout/components/Logo'

import {token} from './utils/utils'
import locationState from './utils/locationState'
// import { policyTypeOptions } from './pages/policy/constants'
import {CustomIcons, Global} from './global'
import {BehaviorSubject} from 'rxjs'
import {GroupList} from './pages/program/group'
import InviteModal from './pages/login/components/InviteModal'

declare var isDemoEnv: boolean

const {Header, Sider, Content} = Layout
const {SubMenu} = Menu

const showResource = true
export const SideMenuObservable = new BehaviorSubject(false)
export const BreadcrumbObservable = new BehaviorSubject<{
  name: string
  url: string
  state?: any
}[]>([])

// const breadcrumbMap: { [key: string]: { name: string; url: string } } = {
//   program: { name: '项目管理', url: '/program/manage' },
//   detail: { name: '项目详情', url: '' },
//   technical: { name: '技术方案', url: '/program/manage' },
//   financial: { name: '财务方案', url: '/program/manage' },
//   'resource-reckon': { name: '资源估计', url: '/program/manage' },
//   account: { name: '账户概览', url: '/program/manage' },
//   edge: { name: '边界设置', url: '/program/edge' },
// }

const routerLocation = location

const keyMap = [
  'home',
  'resource/mapbox',
  'resource/list',
  'resource/mountain',
  'program',
  'program/technical',
  'program/financial',
  'user-center',
  'policy',
  'invest',
  'invest/workbench',
  'invest/projectLibrary',
  'invest/contractApproval',
  'invest/partner',
  'engineering',
  'engineering/cost',
  'engineering/material',
  'engineering/logistics',
]

export let ApplicationModal: any

export const App = () => {
  const location = useLocation()

  const [breadcrumb, setBreadcrumb] = React.useState<{ name: string; url: string; state?: any }[]>([])

  const [emailTipVisible, setEmailTipVisible] = useState(Global.emailTipVisible)
  const [inlineCollapsed, setInlineCollapsed] = useState(false)
  // const [openKeys, setOpenKeys] = useState([])
  const [modal, modalContext] = Modal.useModal()

  // 用户基础信息
  const [user, setUser] = useState(Global.user)
  Global.updateUser.push((e: any) => {
    setUser(e)
  })

  useMemo(() => {
    setUser(Global.user)
  }, [Global.user])

  useEffect(() => {
    ApplicationModal = modal
    return () => {
      ApplicationModal = undefined
    }
  }, [])

  // 选中的菜单
  const [selectedKeys, setSelectKeys] = useState<string[]>(['home'])
  Global.changeMenu.push((e: any) => {
    setSelectKeys(e?.split('#/'))
  })

  React.useEffect(() => {
    keyMap.forEach((o) => {
      if (
        location.pathname.includes('/program/detail/') ||
        location.pathname.includes('/program/edge/') ||
        location.pathname.includes('/program/manage')
      ) {
        setSelectKeys(['program/manage'])
      } else if (
        location.pathname.includes('/invest/projectLibrary') ||
        location.pathname.includes('/invest/projectDetail') ||
        location.pathname.includes('/invest/project/false') ||
        location.pathname.includes('/invest/project/true') ||
        location.pathname.includes('/invest/document')
      ) {
        if (location.state?.engineering || locationState.getProjectLib()) {
          setSelectKeys(['engineering/projectLibrary'])
        } else {
          setSelectKeys(['invest/projectLibrary'])
        }
      } else if (location.pathname.includes('/engineering/projectLibrary')) {
        setSelectKeys(['engineering/projectLibrary'])
      } else if (
        location.pathname.includes('/invest/contractApproval') ||
        location.pathname.includes('/invest/contract')
      ) {
        if (location.state?.engineering || locationState.getContract()) {
          setSelectKeys(['engineering/contractApproval'])
        } else {
          setSelectKeys(['invest/contractApproval'])
        }
      } else if (location.pathname.includes('/policy/manage')) {
        setSelectKeys([`policy/manage${location.search}`])
      } else if (location.pathname.includes('/policy/detail/')) {
        setSelectKeys([`policy/manage?type=${location.pathname.split('/').pop()}`])
      } else if (location.pathname.includes('/operation-management/t-screen')) {
        setSelectKeys(['operation-management/t-screen'])
      } else if (location.pathname.includes('/operation-management/equipment-station')) {
        setSelectKeys(['operation-management/equipment-station'])
      } else if (location.pathname.includes('/operation-management/power-station')) {
        setSelectKeys(['operation-management/power-station'])
      } else if (location.pathname.indexOf(o) >= 0) {
        setSelectKeys([o])
      }
    })

    const sbsc = SideMenuObservable.subscribe((resp) => {
      setInlineCollapsed(resp)
    })

    const brsbsc = BreadcrumbObservable.subscribe((resp) => {
      setBreadcrumb(resp)
    })

    return () => {
      sbsc.unsubscribe()
      brsbsc.unsubscribe()
    }
  }, [])

  // useEffect(() => {
  //   if (inlineCollapsed) {
  //     setOpenKeys([])
  //   } else {
  //     setOpenKeys(['programBase', 'resource-reckon', 'invest', 'operationManagement', 'engineering'])
  //   }
  // }, [inlineCollapsed])

  // 退出登录
  const confirmLoginOut = () => {
    Modal.confirm({
      keyboard: false,
      title: '退出登录',
      icon: <CustomIcons type="klf-alarm1"/>,
      content: '确定退出?',
      okText: '确定',
      cancelText: '取消',
      onOk: () => {
        token.clear()
        Global.user = {}
        routerLocation.assign('#/login/index')
      },
    })
  }

  const userDropMenu = (
    <Menu>
      {/* <Menu.Item
        key="user-center"
        onClick={() => {
          routerLocation.assign('#/user-center/account')
          setSelectKeys(['user-center'])
        }}
        icon={<CustomIcons type="klf-navi-setting" />}
      >
        设置
      </Menu.Item> */}
      {Global.user.isAdministrators ? (
        <Menu.Item
          key="user-center"
          onClick={() => {
            window.open(`${routerLocation?.origin}/admin`)
          }}
          // style={{ color: '#1ec692' }}
          icon={<SettingOutlined/>}
        >
          管理后台
        </Menu.Item>
      ) : null}
      <Menu.Item
        key="work-order"
        onClick={() => {
          window.open(`./work-order.html`, '_blank')
        }}
        // style={{ color: '#1ec692' }}
        icon={<CustomIcons type="klf-a-bianzu1"/>}
      >
        我的工单
      </Menu.Item>
      <Menu.Item key="logout" onClick={confirmLoginOut} icon={<LoginOutlined/>}>
        退出登录
      </Menu.Item>
    </Menu>
  )

  const renderLayout = () => {
    return (
      <Layout style={{overflow: 'hidden'}}>
        <Header className="header">
          <Logo
            size={isDemoEnv ? 55 : undefined}
            image={isDemoEnv ? './logo-demo.png' : undefined}
            text={
              isDemoEnv ? (
                <img src={`./logo-text-demo.svg`} style={{height: 25}} alt="logo-text"/>
              ) : (
                <img src={`./logo-text.svg`} style={{height: 45}} alt="logo-text"/>
              )
            }
          />
          <Dropdown overlay={userDropMenu}>
            <Space size={5}>
              <ProfilePhoto user={user} url={user.headUrl} name={user.email} size={24} {...user} />
              {user?.userName || ''}
              <CaretDownOutlined/>
            </Space>
          </Dropdown>
        </Header>
        <Layout>
          <Sider width={218} className="site-layout-background" collapsedWidth="50" collapsed={inlineCollapsed}>
            <div
              style={{
                height: '100%',
                backgroundColor: 'white',
                borderRight: '1px solid #EBEBEB',
                borderRightColor: '#EBEBEB',
              }}
            >
              <Menu
                className="klf-custom-menu"
                inlineCollapsed={inlineCollapsed}
                mode="inline"
                selectedKeys={selectedKeys}
                // openKeys={openKeys}
                style={{height: '100%', borderRight: 0}}
                onClick={({key}) => {
                  if (key !== 'collspaned') setSelectKeys([key])
                }}
                // onOpenChange={(e) => {
                //   // setOpenKeys(e)
                // }}
              >
                <Menu.Item
                  key="home"
                  icon={
                    <CustomIcons
                      style={{
                        fontSize: 32,
                      }}
                      type="klf-navi-overview-copy"
                    />
                  }
                >
                  <Link to="home">账户概览</Link>
                </Menu.Item>
                {showResource && (
                  <SubMenu
                    key="resource-reckon"
                    icon={
                      <CustomIcons
                        style={{
                          fontSize: 32,
                        }}
                        type="klf-navi-resource-copy"
                      />
                    }
                    title="资源估算"
                  >
                    {/*<Menu.Item key="resource/mapbox">*/}
                    {/*  <Link to="resource/mapbox">资源探索（新）</Link>*/}
                    {/*</Menu.Item>*/}
                    <Menu.Item key="resource/mapbox">
                      <Link to="resource/mapbox">资源探索</Link>
                    </Menu.Item>
                    <Menu.Item key="resource/list">
                      <Link to="resource/list">资源管理</Link>
                    </Menu.Item>
                    <Menu.Item key="resource/mountain">
                      <Link to="resource/mountain">山地光伏</Link>
                    </Menu.Item>
                  </SubMenu>
                )}
                <SubMenu
                  key="invest"
                  icon={
                    <div
                      className="custom-icons-box"
                      style={{width: 32, height: 32, display: 'flex', alignItems: 'center', justifyContent: 'center'}}
                    >
                      <CustomIcons style={{fontSize: 20}} type="icon-gongzuotai"/>
                    </div>
                  }
                  title="投资开发"
                >
                  <Menu.Item key="invest/workbench">
                    <Link to="invest/workbench">工作台</Link>
                  </Menu.Item>
                  <Menu.Item key="invest/projectLibrary" onClick={() => locationState.setProjectLib(false)}>
                    <Link to="invest/projectLibrary">项目库</Link>
                  </Menu.Item>
                  <Menu.Item key="invest/contractApproval" onClick={() => locationState.setContract(false)}>
                    <Link to="invest/contractApproval">合同审批</Link>
                  </Menu.Item>
                  <Menu.Item key="invest/partner">
                    <Link to="invest/partner">合作方</Link>
                  </Menu.Item>
                </SubMenu>
                <SubMenu
                  key="programBase"
                  icon={
                    <CustomIcons
                      style={{
                        fontSize: 32,
                      }}
                      type="klf-project_design-copy"
                    />
                  }
                  title="项目设计"
                >
                  <Menu.Item key="program/manage">
                    <Link to="program/manage">项目管理</Link>
                  </Menu.Item>
                  <Menu.Item key="program/technical">
                    <Link to="program/technical">技术方案</Link>
                  </Menu.Item>
                  <Menu.Item key="program/financial">
                    <Link to="program/financial">财务方案</Link>
                  </Menu.Item>
                </SubMenu>
                {/* <Menu.Item
                  key="user-group"
                  className={inlineCollapsed ? 'inline-collapsed-setting setting-line' : 'setting-line'}
                  icon={<GroupList></GroupList>}
                  style={{
                    position: 'absolute',
                    bottom: !inlineCollapsed ? '30px' : '80px',
                    margin: 0,
                  }}
                >
                  <div className="setting">组织列表</div>
                </Menu.Item> */}

                <SubMenu
                  key="engineering"
                  icon={
                    <div
                      className="custom-icons-box"
                      style={{width: 32, height: 32, display: 'flex', alignItems: 'center', justifyContent: 'center'}}
                    >
                      <CustomIcons style={{fontSize: 20}} type="icon-gongzuotai"/>
                    </div>
                  }
                  title="工程管理"
                >
                  <Menu.Item key="engineering/projectLibrary" onClick={() => locationState.setProjectLib(true)}>
                    <Link to="/invest/projectLibrary" state={{engineering: true}}>
                      项目库
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="engineering/contractApproval" onClick={() => locationState.setContract(true)}>
                    <Link to="invest/contractApproval" state={{engineering: true}}>
                      合同审批
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="engineering/cost">
                    <Link to="engineering/cost">费用管理</Link>
                  </Menu.Item>
                  <Menu.Item key="engineering/material">
                    <Link to="engineering/material">物资管理</Link>
                  </Menu.Item>
                  <Menu.Item key="engineering/logistics">
                    <Link to="engineering/logistics">物流管理</Link>
                  </Menu.Item>
                </SubMenu>
                <SubMenu
                  key="operationManagement"
                  icon={
                    <CustomIcons
                      style={{
                        fontSize: 32,
                      }}
                      type="klf-navi-overview-copy"
                    />
                  }
                  title="运维管理"
                >
                  <Menu.Item key="operation-management/t-screen">
                    <Link to="operation-management/t-screen">运维大屏</Link>
                  </Menu.Item>
                  <Menu.Item key="operation-management/power-station">
                    <Link to="operation-management/power-station">电站状况</Link>
                  </Menu.Item>
                  <Menu.Item key="operation-management/equipment-station">
                    <Link to={`operation-management/equipment-station/${'default'}`}>设备情况</Link>
                  </Menu.Item>
                </SubMenu>
                {/* <SubMenu
                  key="policyBase"
                  icon={
                    <div
                      style={{ width: 32, height: 32, display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                    >
                      <CustomIcons style={{ fontSize: 20 }} type="icon-zhengcefagui1" />
                    </div>
                  }
                  title="政策法规"
                >
                  {policyTypeOptions.map(({ id, name }) => (
                    <Menu.Item key={`policy/manage?type=${id}`}>
                      <Link to={`policy/manage?type=${id}`}>{name}</Link>
                    </Menu.Item>
                  ))}
                </SubMenu> */}
                <div style={{
                  backgroundColor: 'white',
                  position: "absolute",
                  bottom: 0,
                  right: 1,
                  left: 0,
                  height: 80,
                }}>
                  <GroupList inlineCollapsed={inlineCollapsed}></GroupList>
                  {/* <Menu.Item
                  key="cockpit"
                  icon={
                    <CustomIcons
                      style={{
                        fontSize: 32,
                      }}
                      type="klf-navi-overview-copy"
                    />
                  }
                >
                  <Link to="cockpit">管理驾驶舱</Link>
                </Menu.Item>
                <Menu.Item
                  key="summary"
                  icon={
                    <CustomIcons
                      style={{
                        fontSize: 32,
                      }}
                      type="klf-navi-overview-copy"
                    />
                  }
                >
                  <Link to="summary">数据汇总</Link>
                </Menu.Item>   */}

                  <Menu.Item
                    key="user-center"
                    className={inlineCollapsed ? 'inline-collapsed-setting setting-line' : 'setting-line'}
                    icon={
                      <CustomIcons
                        onClick={() => {
                          routerLocation.assign('#/user-center/account')
                        }}
                        type="klf-navi-setting"
                      />
                    }
                    style={{
                      position: 'absolute',
                      bottom: !inlineCollapsed ? 0 : '40px',
                      margin: 0,
                      [inlineCollapsed ? 'borderTop' : '']: '1px solid rgba(218, 218, 218, 0.65)',
                    }}
                  >
                    <Link to="user-center/account" style={{flex: 1}}>
                      <div className="setting">设置</div>
                    </Link>
                  </Menu.Item>
                  <Menu.Item
                    className={inlineCollapsed ? 'collspaned-menu' : 'collspaned-menu-open'}
                    key="collspaned"
                    icon={
                      inlineCollapsed ? (
                        <DoubleRightOutlined/>
                      ) : (
                        <DoubleLeftOutlined
                          style={{
                            color: selectedKeys.includes('user-center') ? 'white' : 'rgba(0, 0, 0, 0.65)',
                          }}
                        />
                      )
                    }
                    style={{
                      ...{
                        position: 'absolute',
                        bottom: 4,
                        margin: 0,
                        padding: 0,
                        marginLeft: '-2px',
                        zIndex: 10,
                        overflow: 'visible',
                        backgroundColor: 'transparent',
                        width: 50,
                      },
                      ...{
                        [inlineCollapsed ? '' : 'color']: inlineCollapsed ? '' : 'white',
                        [inlineCollapsed ? '' : 'right']: inlineCollapsed ? '' : '0px',
                      },
                    }}
                    onClick={() => {
                      setInlineCollapsed(!inlineCollapsed)
                    }}
                  />
                </div>
              </Menu>
            </div>
          </Sider>
          <Layout
            style={
              location.pathname.indexOf('user-center') < 0
                ? {minWidth: 824, padding: '24px', background: '#F3F4F5'}
                : {minWidth: 824, background: '#F3F4F5'}
            }
          >
            {breadcrumb.length > 1 && breadcrumb.filter((item) => item.url.indexOf('user-center') === -1).length !== 0 && (
              <Breadcrumb style={{margin: 0, marginBottom: 14}}>
                {breadcrumb.map((o) => (
                  <Breadcrumb.Item key={o.name}>
                    {(o.url && (
                        <Link to={o.url} state={o.state ?? {}}>
                          {o.name}
                        </Link>
                      )) ||
                      o.name}
                  </Breadcrumb.Item>
                ))}
              </Breadcrumb>
            )}
            <Content
              className="site-layout-background"
              style={{
                minHeight: 550,
                overflowY: 'auto',
              }}
            >
              <Outlet/>
            </Content>
          </Layout>
        </Layout>
        {modalContext}
      </Layout>
    )
  }

  return (
    <>
      {renderLayout()}

      {!isDemoEnv && (
        <Modal
          visible={emailTipVisible}
          title="提示"
          maskClosable={false}
          footer={false}
          keyboard={false}
          onCancel={() => {
            setEmailTipVisible(false)
            Global.emailTipVisible = false
          }}
        >
          邮箱未验证，请
          <Button
            type="link"
            onClick={() => {
              setEmailTipVisible(false)
              Global.emailTipVisible = false
              setSelectKeys(['user-center'])
              routerLocation.replace('/#/user-center/account')
            }}
          >
            立即验证
          </Button>
        </Modal>
      )}

      <InviteModal/>
    </>
  )
}

export default App
