/* eslint-disable no-case-declarations */
import React from 'react'
import {
  Col,
  DatePicker,
  DatePickerProps,
  Form,
  FormItemProps,
  Input,
  InputNumber,
  InputNumberProps,
  InputProps,
  Radio,
  RadioGroupProps,
  Row,
  Select,
  SelectProps,
  Typography,
} from 'antd'
import { TypographyProps } from 'antd/lib/typography/Typography'
import { TextAreaProps } from 'antd/lib/input/TextArea'
import { dynamicText, ResponseOptionType } from '../../apis/wl-service'
import AddressSelector, { IAddressSelectorProps } from '../wl-address'
import UploadFile, { IUploadProps } from '../wl-upload'
import ProjectSelector, { IProjectSelectorProps } from './project-selector'

import './index.less'

// 表单布局
export const formItemLayout = {
  span8: {
    labelCol: { span: 12 },
    wrapperCol: { span: 12 },
  },
  span12: {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  },
  span24: {
    labelCol: { span: 4 },
    wrapperCol: { span: 20 },
  },
}

export const FormText = (props: TypographyProps) => (
  <Typography.Text {...props} style={{ lineHeight: '32px', marginBottom: 0 }}>
    {props.children ?? <>&nbsp;</>}
  </Typography.Text>
)

export interface ICustomFormItem extends FormItemProps {
  children?: any
  editable: boolean
  value: any
  span?: 1 | 2 | 3
  type?:
    | 'input'
    | 'number'
    | 'textarea'
    | 'radio'
    | 'select'
    | 'project_selector'
    | 'datepicker'
    | 'file'
    | 'address_selector'
  options?: ResponseOptionType[] // type === 'select'时生效,
  setOptions?: (options: any[]) => void
  setValues?: (values: any) => void
  computed?: boolean // type === 'select'时生效,默认为false,直接显示props/value,为true时调用通过计算显示props/value所对应的fullName值
  componentProps?:
    | InputProps
    | InputNumberProps
    | TextAreaProps
    | RadioGroupProps
    | SelectProps
    | DatePickerProps
    | IUploadProps
    | IAddressSelectorProps
    | IProjectSelectorProps
  readOnlyRender?: React.ReactElement
}
export const CustomFormItem: React.FC<ICustomFormItem> = ({
  children,
  editable,
  value,
  computed = false,
  span = 3,
  type = 'input',
  options,
  setOptions,
  setValues,
  componentProps,
  readOnlyRender,
  ...formItemProps
}) => {
  let content
  if (span === 1) {
    formItemProps = { ...formItemLayout.span24, ...formItemProps }
  }

  formItemProps = { className: 'wl-form-item', ...formItemProps }

  switch (type) {
    case 'input':
      content = (
        <Form.Item {...formItemProps}>
          {editable ? (
            <Input
              allowClear
              placeholder={componentProps?.disabled ? '' : '请输入'}
              {...(componentProps as InputProps)}
            />
          ) : (
            <FormText>{readOnlyRender ?? value}</FormText>
          )}
        </Form.Item>
      )
      break

    case 'textarea':
      content = (
        <Form.Item {...formItemProps}>
          {editable ? (
            <Input.TextArea
              autoSize={{ minRows: 3 }}
              allowClear
              placeholder={componentProps?.disabled ? '' : '请输入'}
              {...(componentProps as TextAreaProps)}
            />
          ) : (
            <FormText>{readOnlyRender ?? value}</FormText>
          )}
        </Form.Item>
      )
      break

    case 'number':
      content = (
        <Form.Item {...formItemProps}>
          {editable ? (
            <InputNumber
              min={0}
              style={{ width: '100%' }}
              placeholder={componentProps?.disabled ? '' : '请输入'}
              {...(componentProps as InputNumberProps)}
            />
          ) : (
            <FormText>{readOnlyRender ?? value}</FormText>
          )}
        </Form.Item>
      )
      break

    case 'radio':
      content = (
        <Form.Item {...formItemProps}>
          <Radio.Group {...(componentProps as RadioGroupProps)} disabled={!editable}>
            {options?.map(({ id, fullName }) => {
              return (
                <Radio key={id} value={id}>
                  {fullName}
                </Radio>
              )
            })}
          </Radio.Group>
        </Form.Item>
      )
      break

    case 'select':
      content = (
        <Form.Item {...formItemProps}>
          {editable ? (
            <Select
              dropdownMatchSelectWidth={false}
              allowClear
              dropdownClassName="right-actions"
              placeholder={componentProps?.disabled ? '' : '请选择'}
              showSearch
              optionFilterProp="children"
              filterOption={(input: string, option: any) => {
                return (option?.children as unknown as string).toLowerCase().includes(input.toLowerCase())
              }}
              {...(componentProps as SelectProps)}
            >
              {options?.map(({ id, fullName }) => {
                return <Select.Option key={`${id}`}>{fullName}</Select.Option>
              })}
            </Select>
          ) : (
            <FormText>{readOnlyRender ?? (computed ? dynamicText(value, options ?? []) : value)}</FormText>
          )}
        </Form.Item>
      )
      break

    case 'project_selector':
      const shouldUpdateMark = (prevValues: any, nextValues: any) => prevValues.mark !== nextValues.mark

      content = (
        <>
          <Form.Item {...formItemProps}>
            {editable ? (
              <ProjectSelector
                setValues={setValues}
                options={options}
                setOptions={setOptions}
                {...(componentProps as IProjectSelectorProps)}
              />
            ) : (
              <FormText>{readOnlyRender ?? (computed ? dynamicText(value, options ?? []) : value)}</FormText>
            )}
          </Form.Item>

          <Form.Item shouldUpdate={shouldUpdateMark} name="project_id" hidden={true}>
            <Input />
          </Form.Item>
          <Form.Item shouldUpdate={shouldUpdateMark} name="project_name" hidden={true}>
            <Input />
          </Form.Item>
          <Form.Item shouldUpdate={shouldUpdateMark} name={['projectModel', 'name']} hidden={true}>
            <Input />
          </Form.Item>
          <Form.Item shouldUpdate={shouldUpdateMark} name={['projectModel', 'address']} hidden={true}>
            <Input />
          </Form.Item>
          <Form.Item shouldUpdate={shouldUpdateMark} name={['projectModel', 'cityCode']} hidden={true}>
            <Input />
          </Form.Item>
          <Form.Item shouldUpdate={shouldUpdateMark} name={['projectModel', 'latitude']} hidden={true}>
            <Input />
          </Form.Item>
          <Form.Item shouldUpdate={shouldUpdateMark} name={['projectModel', 'longitude']} hidden={true}>
            <Input />
          </Form.Item>
        </>
      )
      break

    case 'datepicker':
      {
        let inputNode: any
        if (!editable) {
          inputNode = <FormText>{readOnlyRender ?? value}</FormText>
        } else {
          if ((componentProps as DatePickerProps)?.format === 'YYYY') {
            inputNode = (
              <DatePicker.YearPicker
                allowClear
                format="YYYY"
                style={{ width: '100%' }}
                placeholder={componentProps?.disabled ? '' : '请选择'}
                {...(componentProps as DatePickerProps)}
              />
            )
          } else if ((componentProps as DatePickerProps)?.format === 'YYYY-MM') {
            inputNode = (
              <DatePicker.MonthPicker
                allowClear
                format="YYYY-MM"
                style={{ width: '100%' }}
                placeholder={componentProps?.disabled ? '' : '请选择'}
                {...(componentProps as DatePickerProps)}
              />
            )
          } else {
            inputNode = (
              <DatePicker
                allowClear
                format="YYYY-MM-DD"
                style={{ width: '100%' }}
                placeholder={componentProps?.disabled ? '' : '请选择'}
                {...(componentProps as DatePickerProps)}
              />
            )
          }
        }
        content = <Form.Item {...formItemProps}>{inputNode}</Form.Item>
      }
      break

    case 'address_selector':
      content = (
        <Form.Item {...formItemProps}>
          {editable ? (
            <AddressSelector
              placeholder={componentProps?.disabled ? '' : '请选择'}
              {...(componentProps as IAddressSelectorProps)}
            />
          ) : (
            <FormText>{readOnlyRender ?? value}</FormText>
          )}
        </Form.Item>
      )
      break

    case 'file':
      content = (
        <Row gutter={[20, 12]}>
          {!editable && (
            <Col>
              <Typography.Title level={5} style={{ margin: 0, lineHeight: '32px' }}>
                {formItemProps.label}
              </Typography.Title>
            </Col>
          )}
          <Col flex="1">
            <Form.Item {...formItemLayout.span12} {...formItemProps} label={editable ? formItemProps.label : ''}>
              <UploadFile
                maxCount={15}
                maxSize={10}
                disabled={!editable}
                emptyText={<FormText>{`暂无${formItemProps?.label}`}</FormText>}
                {...(componentProps as IUploadProps)}
              />
            </Form.Item>
          </Col>
        </Row>
      )
      break

    default:
      content = null
  }

  return (
    <Col xs={span === 1 ? 24 : 12} md={Math.floor(24 / span)}>
      {content}
      {children}
    </Col>
  )
}

export const CustomFormTitle: React.FC<{ children: any }> = ({ children }) => {
  return (
    // <Form.Item>
    <Typography.Title level={5}>{children}</Typography.Title>
    // </Form.Item>
  )
}
