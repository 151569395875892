import { CheckCircleFilled, CloseCircleFilled } from '@ant-design/icons'
import { Slider } from 'antd'

export const PasswordNotice = (props: { password: string }) => {
  // 密码由数字、字母、特殊符号组成，并且要同时含有数字和大小写字母以及特殊符号，且长度要在8-16位之间
  const checkUppercase = () => {
    const reg = new RegExp('[A-Z]+')
    return reg.test(props.password)
  }
  const checkLowercase = () => {
    const reg = new RegExp('[a-z]+')
    return reg.test(props.password)
  }
  const checkNumbercase = () => {
    const reg = new RegExp('[0-9]+')
    return reg.test(props.password)
  }
  const checkSymbolcase = () => {
    const reg = new RegExp('((?=[\x21-\x7e]+)[^A-Za-z0-9])')
    return reg.test(props.password)
  }
  const checkLengthcase = () => {
    return props.password.length >= 8 && props.password.length <= 16
  }

  const strength = () => {
    console.log(checkLengthcase(), checkUppercase() && checkLowercase() && checkNumbercase(), checkSymbolcase())
    return [checkLengthcase(), checkUppercase() && checkLowercase() && checkNumbercase(), checkSymbolcase()].filter(
      Boolean
    ).length
  }

  return (
    <div
      style={{
        position: 'absolute',
        top: 40,
        left: 0,
        width: 300,
        zIndex: 999,
        padding: '.5rem 1rem',
        lineHeight: '2rem',
        backgroundColor: 'white',
        boxShadow: 'rgba(0,0,0,0.1) 0px 6px 6px 0px',
      }}
    >
      <div>密码必须满足以下条件：</div>
      <div>
        {checkLengthcase() ? (
          <CheckCircleFilled style={{ color: '#1ec692' }} />
        ) : (
          <CloseCircleFilled style={{ color: '#f5222d' }} />
        )}
        <span style={{ marginLeft: '.5rem' }}>8-16个字符</span>
      </div>
      <div>
        {checkLowercase() && checkUppercase() && checkNumbercase() ? (
          <CheckCircleFilled style={{ color: '#1ec692' }} />
        ) : (
          <CloseCircleFilled style={{ color: '#f5222d' }} />
        )}
        <span style={{ marginLeft: '.5rem' }}>必须包含数字和大小写字母</span>
      </div>
      <div>
        {checkSymbolcase() ? (
          <CheckCircleFilled style={{ color: '#1ec692' }} />
        ) : (
          <CloseCircleFilled style={{ color: '#f5222d' }} />
        )}
        <span style={{ marginLeft: '.5rem' }}>必须包含特殊符号</span>
      </div>
      <div>密码强度</div>
      <div className={['low-strength', 'low-strength', 'mid-strength', 'high-strength'][strength()]}>
        <Slider min={0} max={3} step={1} value={strength()} />
      </div>
    </div>
  )
}
