import React, { useImperativeHandle } from 'react'
import moment from 'moment'
import { cloneDeep, set } from 'lodash'
import { Form, Input, Typography, Row, Col, FormInstance, Spin } from 'antd'
import {
  CollectionConService,
  CommonService,
  dynamicText,
  InvestDecisionService,
  ResourceService,
} from '../../../../apis/wl-service'
import {
  investTypeOptions,
  is_dsh_verifyOptions,
  is_dwh_verifyOptions,
  is_group_decisionOptions,
  is_jybz_verifyOptions,
  liability_statementOptions,
  is_self_buildOptions,
} from '../../common/constant'
import { CustomFormItem, CustomFormTitle, formItemLayout } from '../../../../components/wl-form-item'
import DynamicTable from '../common/dynamic-table'
import store, { SubmitType } from '../../../invest-dev/project-library-create/components/store'

export interface IInvestDecisionFormProps {
  id?: string
  editable?: boolean
}

export const RefForm = ({ id, editable = false }: IInvestDecisionFormProps, ref: any) => {
  const unmountRef = React.useRef<boolean>(false)
  const [form] = Form.useForm()
  const storeKey = React.useMemo<string>(() => (editable ? store.keyMap.investDecision : ''), [store, editable]) // 用于本地数据存储的键值
  const [formData, setFormData] = React.useState<any>({}) // 存储表单数据
  const [loading, setLoading] = React.useState<boolean>(false) // 数据加载状态
  const [options, setOptions] = React.useState<{ [name: string]: any[] }>({
    resourceTypeOptions: [], // 项目类型
    developList: [], // 项目（屋顶企业）名称列表（此处的项目全部已新建过资源）
    enterpriseNature: [], // 企业性质
    property: [], // 产权
    collectionTemplate: [], // 收资表模板数据
  }) // 基础选择数据

  useImperativeHandle(ref, () => ({
    getName: () => storeKey,
    submit: async (options: SubmitType) => {
      try {
        let values: any = await form.validateFields()
        values = formatter(values, 'params')
        store.submit[storeKey]({
          ...options,
          values,
        })
      } catch (err) {
        console.warn('submit form Validate Failed:', err)
      }
    },
  }))

  // 设置组件挂载状态
  React.useEffect(() => {
    unmountRef.current = false
    return () => {
      unmountRef.current = true
    }
  }, [])

  // 获取基础数据
  React.useEffect(() => {
    if (unmountRef.current) return

    // 编辑时从缓存中读取数据
    const prevOptions = editable && store.getOptions()

    // 项目类型
    prevOptions?.resourceTypeOptions
      ? setOptions((previous: any) => ({
          ...previous,
          resourceTypeOptions: prevOptions.resourceTypeOptions,
        }))
      : CommonService.getDictionaryDataSelectorValid('15301742e5ec4e92843192cb76010e08')
          .then((data: any) => {
            const resourceTypeOptions = data?.data?.list ?? []
            !unmountRef.current && setOptions((previous: any) => ({ ...previous, resourceTypeOptions }))
            editable && store.setOptions({ resourceTypeOptions }, true)
          })
          .catch((error: any) => {
            console.warn('项目类型获取失败：', error?.message)
          })

    if (editable) {
      // 项目列表 // 使用收资信息中的developList
      prevOptions?.developList
        ? setOptions((previous: any) => ({
            ...previous,
            developList: prevOptions.developList,
            enterpriseNature: prevOptions.enterpriseNature,
            property: prevOptions.property,
            collectionTemplate: prevOptions.collectionTemplate,
          }))
        : CollectionConService.basicData()
            .then((res: any) => {
              const current = res?.data ?? {}
              current.developList = current.developList?.map(({ id, name }: any) => ({ id, name, fullName: name }))
              !unmountRef.current && setOptions((previous: any) => ({ ...previous, ...current }))
              editable && store.setOptions(current, true)
            })
            .catch((e) => {
              console.warn('获取收资信息基础数据失败：', e)
            })
    }
  }, [editable, store.getOptions, store.setOptions])

  // 根据projectId获取详情
  React.useEffect(() => {
    detail(editable ? store.projectId : id)
  }, [id, editable, store.projectId])

  // type=params作为参数发送到服务端
  // type=component转换数据用于组件渲染
  const formatter = (values: any, type: 'params' | 'component') => {
    values = cloneDeep(values)

    // 转换时间值
    if (editable) {
      ;['decision_time', 'approval_time', 'sign_time', 'appointed_time'].forEach((k: string) => {
        if (type === 'component') {
          values[k] = values[k] ? moment(values[k]) : null
        }
        if (type === 'params') {
          values[k] = values[k] ? moment(values[k]).format('YYYY-MM-DD') : ''
        }
      })
    }

    // 转换文件值
    ;[
      'ky_report_file',
      'ky_advice_file',
      'fx_report_file',
      'fl_advice_file',
      'js_report_file',
      'gq_purchase_file',
      'qy_report_file',
      'qy_jybz_file',
      'qt_file',
    ].forEach((k: string) => {
      if (type === 'component') {
        values[k] = values[k] ? JSON.parse(values[k]) : []
      }

      if (type === 'params') {
        values[k] = values[k]?.length ? JSON.stringify(values[k]) : ''
      }
    })

    // 设置select
    ;[
      'is_group_decision',
      'is_dwh_verify',
      'is_jybz_verify',
      'is_dsh_verify',
      'liability_statement',
      'investment_pattern',
      'is_self_build',
    ].forEach((k: string) => {
      if (type === 'component' && typeof values[k] === 'number') {
        values[k] = values[k].toString()
      }
    })

    return values
  }

  // 根据projectId获取详情
  const detail = async (projectId?: string) => {
    if (unmountRef.current) return

    // 设置、存储表单数据
    const setValues = (values: any) => {
      try {
        if (editable) {
          // 设置form 表单中 的项目选中项
          values.project_id = store.projectId ?? ''
          store.setItem(storeKey, values)
        }

        // form.resetFields()
        form.setFieldsValue(formatter(values, 'component'))
        !unmountRef.current && setFormData(values)
      } catch (e: any) {
        console.warn(e)
      }
    }

    const prevDetailData = editable && store.getItem(storeKey)
    if (prevDetailData) {
      setValues(prevDetailData)
      return
    }

    try {
      setLoading(true)

      let resp: any = await (projectId ? InvestDecisionService.info({ projectId }) : InvestDecisionService.draft())
      const requestId = resp?.data?.project_id ?? projectId
      if (requestId) {
        const prevProject = editable && store.getProject()

        let project = prevProject
        if (requestId !== prevProject?.project_id) {
          project = await ResourceService.info({ id: requestId })
          project = project?.data
        }

        set(resp, ['data'], {
          ...resp?.data,
          project_id: project?.id,
          project_name: project?.project_name,
          project_code: project?.project_code,
          project_type: project?.resourcetype,
          project_type_text: dynamicText(project?.resourcetype?.toString(), options?.resourceTypeOptions),
          is_self_build: project?.invest_type,
          investment_pattern: project?.invest_type,
        })
      }

      setValues(resp?.data ?? {})
    } catch (e) {
      console.warn(`获取投资详情失败`, e)
    } finally {
      setLoading(false)
    }
  }

  return (
    <Spin spinning={loading}>
      <Form
        name="decisionForm"
        form={form}
        labelWrap
        {...formItemLayout.span8}
        autoComplete="off"
        initialValues={formData}
        onValuesChange={(changedValues, allValues) => {
          store.onValuesChange(storeKey, formatter(allValues, 'params'))
        }}
      >
        <Form.Item name="id" hidden>
          <Input />
        </Form.Item>

        <CustomFormTitle>基本信息</CustomFormTitle>
        <Row>
          <CustomFormItem
            label="项目名称"
            name="project_id"
            type="select"
            span={3}
            editable={editable}
            computed={false}
            options={options?.developList}
            value={formData.project_name}
            componentProps={{
              disabled: true,
              onChange: (value: any) => {
                // 项目id变化
                store.setProjectId(value ?? '', storeKey)
              },
            }}
            rules={[
              {
                required: false,
                message: '请选择项目名称',
              },
            ]}
          >
            <Form.Item name="project_name" hidden={true}>
              <Input />
            </Form.Item>
          </CustomFormItem>

          <CustomFormItem
            label="项目代码"
            name="project_code"
            span={3}
            type="input"
            componentProps={{ disabled: true }}
            editable={editable}
            value={formData.project_code}
          />
          <CustomFormItem
            label="项目类型"
            name="project_type"
            span={3}
            type="select"
            options={options?.resourceTypeOptions}
            editable={editable}
            computed={true}
            value={formData.project_type}
            componentProps={{
              disabled: true,
              onChange: (value: any, option: any) => {
                form.setFieldsValue({ project_type_text: option.children })
              },
            }}
          >
            <Form.Item name="project_type_text" hidden={true}>
              <Input />
            </Form.Item>
          </CustomFormItem>
        </Row>

        <CustomFormTitle>决策状态</CustomFormTitle>
        <Row>
          <CustomFormItem
            label="决策机构"
            name="decision_organization"
            span={3}
            type="input"
            editable={editable}
            value={formData.decision_organization}
            rules={[
              {
                required: false,
                message: '请填写决策机构',
              },
            ]}
          />
          <CustomFormItem
            label="决策时间"
            name="decision_time"
            span={3}
            type="datepicker"
            editable={editable}
            componentProps={{ format: 'YYYY-MM-DD' }}
            value={formData.decision_time}
            rules={[
              {
                required: false,
                message: '请填写决策时间',
              },
            ]}
          />
          <CustomFormItem
            label="批复时间"
            name="approval_time"
            span={3}
            type="datepicker"
            editable={editable}
            componentProps={{ format: 'YYYY-MM-DD' }}
            value={formData.approval_time}
          />
          <CustomFormItem
            label="是否集团决策"
            name="is_group_decision"
            span={3}
            type="select"
            options={is_group_decisionOptions}
            editable={editable}
            computed={true}
            value={formData.is_group_decision}
            rules={[
              {
                required: false,
                message: '请填写是否集团决策',
              },
            ]}
          />
          <CustomFormItem
            label="党委会是否审议通过"
            name="is_dwh_verify"
            span={3}
            type="select"
            options={is_dwh_verifyOptions}
            editable={editable}
            computed={true}
            value={formData.is_dwh_verify}
          />
          <CustomFormItem
            label="经营班子是否审议通过"
            name="is_jybz_verify"
            span={3}
            type="select"
            options={is_jybz_verifyOptions}
            editable={editable}
            computed={true}
            value={formData.is_jybz_verify}
          />
          <CustomFormItem
            label="董事会是否审议通过"
            name="is_dsh_verify"
            span={3}
            type="select"
            options={is_dsh_verifyOptions}
            editable={editable}
            computed={true}
            value={formData.is_dsh_verify}
          />
          <CustomFormItem
            label="是否签署项目责任书"
            name="liability_statement"
            span={3}
            type="select"
            options={liability_statementOptions}
            editable={editable}
            computed={true}
            value={formData.liability_statement}
          />
        </Row>

        <CustomFormTitle>决策项目信息</CustomFormTitle>
        <Row>
          <CustomFormItem
            label="投资模式"
            name="investment_pattern"
            span={3}
            type="select"
            options={investTypeOptions}
            editable={editable}
            computed={true}
            componentProps={{ disabled: true }}
            value={formData.investment_pattern}
          />
          <CustomFormItem
            label="决策总投资(万元)"
            name="total_invest"
            span={3}
            type="number"
            editable={editable}
            value={formData.total_invest}
            rules={[
              {
                required: false,
                message: '请填写决策总投资',
              },
            ]}
          />
          <CustomFormItem
            label="资本金内部收益率(%)"
            name="income_rate"
            span={3}
            type="number"
            editable={editable}
            value={formData.income_rate}
            rules={[
              {
                required: false,
                message: '请填写资本金内部收益率',
              },
            ]}
          />
          <CustomFormItem
            label="决策容量(MW)"
            name="decision_volume"
            span={3}
            type="number"
            editable={editable}
            value={formData.decision_volume}
            rules={[
              {
                required: false,
                message: '请填写决策容量',
              },
            ]}
          />
          <CustomFormItem
            label="年均利用小时(h)"
            name="year_use_hour"
            span={3}
            type="number"
            editable={editable}
            value={formData.year_use_hour}
            rules={[
              {
                required: false,
                message: '请填写年均利用小时',
              },
            ]}
          />
          <CustomFormItem
            label="上网电价(元/kWh)"
            name="net_electrovalence"
            span={3}
            type="number"
            editable={editable}
            value={formData.net_electrovalence}
          />
          <CustomFormItem
            label="资本金(万元)"
            name="capital"
            span={3}
            type="number"
            editable={editable}
            value={formData.capital}
            rules={[
              {
                required: false,
                message: '请填写资本金',
              },
            ]}
          />
          <CustomFormItem
            label="装机规模(MW)"
            name="install_size"
            span={3}
            type="number"
            editable={editable}
            value={formData.install_size}
            rules={[
              {
                required: false,
                message: '请填写装机规模',
              },
            ]}
          />
          <CustomFormItem
            label="送出线路长度(公里)"
            name="route_length"
            span={3}
            type="number"
            editable={editable}
            value={formData.route_length}
            rules={[
              {
                required: false,
                message: '请填写送出线路长度',
              },
            ]}
          />
          <CustomFormItem
            label="决策动态投资(万元)"
            name="dynamic_invest"
            span={3}
            type="number"
            editable={editable}
            value={formData.dynamic_invest}
            rules={[
              {
                required: false,
                message: '请填写决策动态投资',
              },
            ]}
          />
          <CustomFormItem
            label="是否自建送出"
            name="is_self_build"
            span={3}
            type="select"
            options={is_self_buildOptions}
            editable={editable}
            computed={true}
            value={formData.is_self_build}
          />
          <CustomFormItem
            label="配套储能(MW)"
            name="storage"
            span={3}
            type="number"
            editable={editable}
            value={formData.storage}
            rules={[
              {
                required: false,
                message: '请填写配套储能',
              },
            ]}
          />
          <CustomFormItem
            label="决策用地面积(亩)"
            name="site_area"
            span={3}
            type="number"
            editable={editable}
            value={formData.site_area}
          />{' '}
          <CustomFormItem
            label="流动资金(万元)"
            name="fluid_capital"
            span={3}
            type="number"
            editable={editable}
            value={formData.fluid_capital}
            rules={[
              {
                required: false,
                message: '请填写流动资金',
              },
            ]}
          />
          <CustomFormItem
            label="资本金比例(%)"
            name="capital_rate"
            span={3}
            type="number"
            editable={editable}
            value={formData.capital_rate}
            rules={[
              {
                required: false,
                message: '请填写资本金比例',
              },
            ]}
          />
          <CustomFormItem
            label="建设工期(月)"
            name="work_month"
            span={3}
            type="number"
            editable={editable}
            value={formData.work_month}
          />
        </Row>
        <Form.Item noStyle shouldUpdate={(prevValues, currentValues) => prevValues.is_pz !== currentValues.is_pz}>
          {({ getFieldValue }: FormInstance) =>
            getFieldValue('investment_pattern') == 2 ? (
              <>
                <CustomFormTitle>并购信息</CustomFormTitle>
                <Form.Item>
                  <Typography.Title level={5}></Typography.Title>
                </Form.Item>
                <Row>
                  <CustomFormItem
                    label="项目转让方"
                    name="transferor"
                    span={3}
                    type="input"
                    editable={editable}
                    value={formData.transferor}
                  />
                  <CustomFormItem
                    label="企业性质"
                    name="enterprise_property"
                    span={3}
                    type="input"
                    editable={editable}
                    value={formData.enterprise_property}
                  />
                  <CustomFormItem
                    label="签约时间"
                    name="sign_time"
                    span={3}
                    type="datepicker"
                    editable={editable}
                    componentProps={{ format: 'YYYY-MM-DD' }}
                    value={formData.sign_time}
                  />
                  <CustomFormItem
                    label="前期费用评估(万元)"
                    name="cost_evaluate"
                    span={3}
                    type="number"
                    editable={editable}
                    value={formData.cost_evaluate}
                  />
                  <CustomFormItem
                    label="评估增值(万元)"
                    name="pg_increment"
                    span={3}
                    type="number"
                    editable={editable}
                    value={formData.pg_increment}
                  />
                  <CustomFormItem
                    label="约定转股时间"
                    name="appointed_time"
                    span={3}
                    type="datepicker"
                    editable={editable}
                    componentProps={{ format: 'YYYY-MM-DD' }}
                    value={formData.appointed_time}
                  />
                  <CustomFormItem
                    label="净资产(万元)"
                    name="j_assets"
                    span={3}
                    type="number"
                    editable={editable}
                    value={formData.j_assets}
                  />
                  <CustomFormItem
                    label="增值率(%)"
                    name="increment_rate"
                    span={3}
                    type="number"
                    editable={editable}
                    value={formData.increment_rate}
                  />
                </Row>
              </>
            ) : null
          }
        </Form.Item>

        <CustomFormTitle>投资方信息</CustomFormTitle>
        <Row>
          <CustomFormItem
            label="投资主体"
            name="investor"
            span={3}
            type="input"
            editable={editable}
            value={formData.investor}
            rules={[
              {
                required: false,
                message: '请填写投资主体',
              },
            ]}
          />
          <CustomFormItem
            label="项目负责人"
            name="project_leader"
            span={3}
            type="input"
            editable={editable}
            value={formData.project_leader}
            rules={[
              {
                required: false,
                message: '请填写项目负责人',
              },
            ]}
          />

          <Col xs={24}>
            <Form.Item
              noStyle
              name="energy_invest_decision_investorEntityList"
              labelCol={{ span: 0 }}
              wrapperCol={{ span: 24 }}
            >
              <DynamicTable
                name="energy_invest_decision_investorEntityList"
                editable={editable}
                columns={[
                  {
                    title: '序号',
                    dataIndex: 'index',
                    width: 80,
                    render: (text: any, record: any, index: number) => index + 1,
                  },
                  {
                    title: '投资方',
                    dataIndex: 'investName',
                    editable: true,
                    inputType: 'input',
                  },
                  {
                    title: '出资比例（%）',
                    dataIndex: 'capitalRate',
                    width: '15%',
                    editable: true,
                    inputType: 'number',
                    componentProps: {
                      min: 0,
                    },
                  },
                ]}
              />
            </Form.Item>
          </Col>
        </Row>

        <CustomFormTitle>附件</CustomFormTitle>
        <Row>
          <CustomFormItem
            label="可研报告"
            name="ky_report_file"
            span={2}
            type="file"
            editable={editable}
            value={formData.ky_report_file}
            rules={
              [
                // {
                //   validator: (rule: any, value: any) => {
                //     if (!value || value.length === 0) {
                //       return Promise.reject('需上传可研报告才可提交')
                //     }
                //     return Promise.resolve()
                //   },
                // },
              ]
            }
          />
          <CustomFormItem
            label="可研评审意见"
            name="ky_advice_file"
            span={2}
            type="file"
            editable={editable}
            value={formData.ky_advice_file}
          />

          <CustomFormItem
            label="风险评估报告"
            name="fx_report_file"
            span={2}
            type="file"
            editable={editable}
            value={formData.fx_report_file}
          />
          <CustomFormItem
            label="法律意见书"
            name="fl_advice_file"
            span={2}
            type="file"
            editable={editable}
            value={formData.fl_advice_file}
          />

          <CustomFormItem
            label="技术、财务和法律尽调报告"
            name="js_report_file"
            span={2}
            type="file"
            editable={editable}
            value={formData.js_report_file}
          />
          <CustomFormItem
            label="股权收购协议"
            name="gq_purchase_file"
            span={2}
            type="file"
            editable={editable}
            value={formData.gq_purchase_file}
          />

          <CustomFormItem
            label="二级平台企业议案"
            name="qy_report_file"
            span={2}
            type="file"
            editable={editable}
            value={formData.qy_report_file}
          />
          <CustomFormItem
            label="二级平台企业经营班子决议"
            name="qy_jybz_file"
            span={2}
            type="file"
            editable={editable}
            value={formData.qy_jybz_file}
          />

          <CustomFormItem
            label="其他附件"
            name="qt_file"
            span={2}
            type="file"
            editable={editable}
            value={formData.qt_file}
          />
        </Row>
      </Form>
    </Spin>
  )
}

export default React.forwardRef(RefForm)
