import React, { useEffect, useRef, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { Button, message, Modal, Space, Tooltip, Typography } from 'antd'
import { PlusOutlined } from '@ant-design/icons'

import { ContractService, ProjectDocumentService } from '../../../apis/wl-service'
import TablePage, { getActionColumn, getColumnFilterProps, TablePageInstance } from '../../../components/wl-table-page'
import InvalidDialog from './components/invalid-dialog'
import RevokeDialog from './components/revoke-dialog'
import { contractCodeOptions } from '../constants'

export default () => {
  const nav = useNavigate()
  const location = useLocation()
  const tableRef = useRef<TablePageInstance>(null)
  // const [selectedTags, setSelectedTags] = useState<string[]>([])
  const [contractTypeOptions, setContractTypeOptions] = useState<any[]>([])
  const [invalidInfo, setInvalidInfo] = useState<{ visible: boolean; id: string }>({ visible: false, id: '' })
  const [revokeInfo, setRevokeInfo] = useState<{ visible: boolean; id: string }>({ visible: false, id: '' })
  const [breadcrumb, setBreadcrumb] = useState<any[]>([])

  // 设置面包屑
  useEffect(() => {
    setBreadcrumb([
      location.state?.engineering
        ? { name: '工程管理', url: `/invest/projectLibrary`, state: location.state }
        : { name: '投资开发', url: '/invest/workbench' },
      { name: '合同审批', url: `/invest/contractApproval`, state: location.state },
    ])
  }, [location.state])

  useEffect(() => {
    tableRef.current.reload()
  }, [location.state])

  // 获取合同类型
  useEffect(() => {
    if (location.state?.engineering) {
      ContractService.typeList()
        .then((data: any) => {
          setContractTypeOptions(data?.data?.map(({ id, name }: any) => ({ id, fullName: name })) ?? [])
        })
        .catch((err: any) => {
          console.warn('获取合同类型失败:', err)
        })
    }
  }, [location.state])

  // 刷新表格
  const refresh = React.useCallback(() => {
    tableRef.current?.reload?.({ page: 1 })
  }, [tableRef])

  // 表格列配置
  const columns = (tableParams: any) => [
    {
      title: '合同编号',
      dataIndex: 'contractNumber',
      width: 120,
      fixed: 'left' as any,
      ...getColumnFilterProps({
        type: 'input',
        placeholder: '请输入合同编号',
        value: tableParams.contractNumber,
        onSearch: (value) => {
          tableRef.current?.reload?.({
            contractNumber: value,
            page: 1,
          })
        },
      }),
      render: (text: string) =>
        text && (
          <Tooltip title={text}>
            <Typography.Text ellipsis>{text}</Typography.Text>
          </Tooltip>
        ),
    },
    {
      title: '合同名称',
      dataIndex: 'contractName',
      width: 140,
      fixed: 'left' as any,
      render: (text: string, row: any) =>
        text && (
          <Tooltip title={text}>
            <Typography.Text ellipsis>
              <Link to={`/invest/contractDetail/${row.id}`} state={location.state}>
                {text}
              </Link>
            </Typography.Text>
          </Tooltip>
        ),
    },
    {
      title: '签署方',
      dataIndex: 'party',
      width: 140,
      render: (text: string) =>
        text && (
          <Tooltip title={text}>
            <Typography.Text ellipsis>{text}</Typography.Text>
          </Tooltip>
        ),
    },
    {
      title: '合同类型',
      dataIndex: 'contractTypeName',
      width: 140,
      ...(location.state?.engineering
        ? getColumnFilterProps({
            type: 'select',
            placeholder: '请选择合同类型',
            value: tableParams.contractType,
            options: contractTypeOptions,
            onSearch: (value) => {
              tableRef.current?.reload?.({
                contractType: value,
                page: 1,
              })
            },
          })
        : {}),
    },
    {
      title: '合同状态',
      dataIndex: 'code',
      width: 120,
      render: (text: number) => {
        const current = contractCodeOptions.find((o: any) => o.id == text)
        if (current) {
          return <Typography.Text style={{ color: current.color }}>{current.fullName}</Typography.Text>
        }
        return null
      },
    },
    {
      title: '合同日期',
      dataIndex: 'contractDate',
      width: 120,
      render: (text: string) =>
        text && (
          <Tooltip title={text}>
            <Typography.Text ellipsis>{text}</Typography.Text>
          </Tooltip>
        ),
    },
    {
      title: '创建人',
      dataIndex: 'createName',
      width: 120,
      render: (text: string) =>
        text && (
          <Tooltip title={text}>
            <Typography.Text ellipsis>{text}</Typography.Text>
          </Tooltip>
        ),
    },
    /**
     * 操作按钮：
     * 0待签约：A：提交签约 B：删除
     * 1签约中：A：撤回
     * 3已退回：A：合同下载 B：作废
     * 2已签约：A：合同下载 B：作废
     * 5已作废：A：删除
     * 4已过期：A：删除
     */
    getActionColumn([
      {
        key: 'commit',
        label: '提交签约',
        visible: (row: any) => row.code == 0,
        onClick: (row: any) => {
          Modal.confirm({
            title: '提示',
            content: '确定提交签约？',
            okText: '提交',
            onOk: () => {
              ContractService.stateUpdate({ id: row.id, type: 1 }).then((resp: any) => {
                if (resp?.code === 200) {
                  message.success(resp.msg ?? '提交签约成功')
                  refresh()
                } else if (resp?.msg) {
                  message.error(resp.msg)
                }
              })
            },
          })
        },
      },
      {
        key: 'delete',
        type: 'delete',
        visible: (row: any) => ['0', '3', '4', '5', '8', '11'].includes(row.code?.toString()),
        getter: (row: any) => {
          return ContractService.remove({ id: row.id })
        },
        success: (arg: any) => tableRef.current?.reload?.(arg),
      },
      {
        key: 'download',
        label: '合同下载',
        visible: (row: any) => ['2', '10'].includes(row.code?.toString()),
        onClick: (row: any) => {
          ProjectDocumentService.oauth({
            projectId: row.projectId,
            code: 1,
          })
            .then((res: any) => {
              if (res?.data) {
                if (row.filePath) {
                  window.location.href = row.filePath
                } else {
                  message.warn('暂无可供下载的合同哦~')
                }
              } else {
                message.warn(res?.msg ?? `您无权限下载${row.contractName}哦~`)
              }
            })
            .catch((err: any) => {
              console.warn('请求下载权限接口失败:', err)
            })
        },
      },
      {
        key: 'invalid',
        label: '作废',
        visible: (row: any) => ['2'].includes(row.code?.toString()),
        onClick: (row: any) => {
          setInvalidInfo({ visible: true, id: row.id })
        },
      },
      {
        key: 'revoke',
        label: '撤回',
        visible: (row: any) => ['1', '6', '7'].includes(row.code?.toString()),
        onClick: (row: any) => {
          setRevokeInfo({ visible: true, id: row.id })
        },
      },
    ]),
  ]

  // const tagsData = [
  //   { id: 'all', fullName: '全部' },
  //   { id: 'partners', fullName: '合作伙伴' },
  //   { id: 'owner', fullName: '业主' },
  //   { id: 'given', fullName: '消纳方' },
  //   { id: 'company', fullName: '电网公司' },
  // ]

  return (
    <TablePage
      ref={tableRef}
      title="合同管理"
      extra={
        <Space>
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => {
              nav(`/invest/contract`, { state: location.state })
            }}
          >
            新建合同
          </Button>
        </Space>
      }
      breadcrumb={breadcrumb}
      columns={columns}
      rowKey="id"
      autoLoad={false}
      getter={(arg: any) => ContractService.list({ ...arg, stem: Number(location.state?.engineering ?? false) })}
    >
      <>
        {/* <Space direction="horizontal" style={{ marginBottom: 12 }} size={8}>
          {tagsData.map((tag: any) => {
            const checked = selectedTags.indexOf(tag.id) > -1
            return (
              <Tag
                key={tag.id}
                style={{ cursor: 'pointer' }}
                color={checked ? 'success' : 'default'}
                onClick={() => {
                  const next = checked ? [] : [tag.id]
                  setSelectedTags(next)
                  // TODO 联调确认tag名称及数据来源
                  tableRef.current?.reload?.({ tag: next[0] ?? '', page: 1 })
                }}
              >
                {tag.fullName}
              </Tag>
            )
          })}
        </Space> */}
        <InvalidDialog
          {...invalidInfo}
          success={refresh}
          visibleChange={(visible: boolean) => {
            setInvalidInfo((previous) => ({ ...previous, visible }))
          }}
        />
        <RevokeDialog
          {...revokeInfo}
          success={refresh}
          visibleChange={(visible: boolean) => {
            setRevokeInfo((previous) => ({ ...previous, visible }))
          }}
        />
      </>
    </TablePage>
  )
}
