import { ResponseOptionType } from '../../../apis/wl-service'

// 信息录入模块
export const moduleOptions: any[] = [
  {
    fullName: '资源开发',
    id: '1',
    route: '/entry/resourceDevelopment',
  },
  {
    fullName: '核准备案',
    id: '2',
    route: '/entry/record',
  },
  {
    fullName: '投资决策',
    id: '3',
    route: '/entry/decision',
  },
  {
    fullName: '项目建设',
    id: '4',
    route: '/entry/construction',
  },
  {
    fullName: '项目造价',
    id: '5',
    route: '/entry/cost',
  },
  {
    fullName: '投资计划',
    id: '6',
    route: '/entry/investmentPlan',
  },
  {
    fullName: '计划执行',
    id: '7',
    route: '/entry/planExecution',
  },
]

// --------------------------资源开发-------------------------
// 重要程度 in Table
export const resourceElectricTypeOptions: ResponseOptionType[] = [
  { fullName: '直流', id: '1' },
  { fullName: '交流', id: '2' },
]

// --------------------------核准备案-------------------------
// 重要程度 in Table
export const importantLevelOptions: ResponseOptionType[] = [
  { fullName: '广东省重点建设项目', id: '1' },
  { fullName: '广东省重点预备项目', id: '2' },
  { fullName: '集团重大项目（前期）', id: '3' },
  { fullName: '集团重大项目（建设中）', id: '4' },
  { fullName: '其他', id: '5' },
]

// 重要程度 in Form
export const importantLevelOptions2: ResponseOptionType[] = [
  { fullName: '省重点建设项目', id: '1' },
  { fullName: '省重点预备项目', id: '2' },
  { fullName: '集团重大项目（前期）', id: '3' },
  { fullName: '集团重大项目（建设中）', id: '4' },
  { fullName: '其他', id: '5' },
]

// 投资模式
export const investTypeOptions: ResponseOptionType[] = [
  { fullName: '自建', id: '1' },
  { fullName: '并购', id: '2' },
]

// 电价政策类型
export const policy_typeOptions: ResponseOptionType[] = [
  { fullName: '平价', id: '1' },
  { fullName: '竞价', id: '2' },
  { fullName: '市场化', id: '3' },
  { fullName: '部分市场化', id: '4' },
  { fullName: '其他', id: '5' },
]

// 是否获得年度指标
export const isGetYearTargetOptions: ResponseOptionType[] = [
  { fullName: '否', id: '0' },
  { fullName: '是', id: '1' },
]

// 是否批准
export const is_pzOptions: ResponseOptionType[] = [
  { fullName: '否', id: '0' },
  { fullName: '是', id: '1' },
]

// 批准方式
export const pzTypeOptions: ResponseOptionType[] = [
  { fullName: '审批', id: '0' },
  { fullName: '核准', id: '1' },
  { fullName: '备案', id: '2' },
]

// ------------------------------投资决策------------------------
// 是否集团决策
export const is_group_decisionOptions: ResponseOptionType[] = [
  { fullName: '是', id: '1' },
  { fullName: '否', id: '2' },
]

// 党委会是否审议通过
export const is_dwh_verifyOptions: ResponseOptionType[] = [
  { fullName: '是', id: '1' },
  { fullName: '否', id: '2' },
]

// 经营班子是否审议通过
export const is_jybz_verifyOptions: ResponseOptionType[] = [
  { fullName: '是', id: '1' },
  { fullName: '否', id: '2' },
]

// 董事会是否审议通过
export const is_dsh_verifyOptions: ResponseOptionType[] = [
  { fullName: '是', id: '1' },
  { fullName: '否', id: '2' },
]

// 是否签署项目责任书
export const liability_statementOptions: ResponseOptionType[] = [
  { fullName: '是', id: '1' },
  { fullName: '否', id: '2' },
]

// 是否自建送出
export const is_self_buildOptions: ResponseOptionType[] = [
  { fullName: '是', id: '1' },
  { fullName: '否', id: '2' },
]

export const selfBuildUpload: string[] = [
  '备案证',
  '项目投资议案',
  '项目决议或批复',
  '可研报告',
  '风险评估报告',
  '可研评审意见',
  '法律意见书',
]
export const mergerUpload: string[] = [
  '股权收购协议',
  '技术',
  '财务和法律尽调报告',
  '可研报告及审查意见',
  '风险评估报告',
  '法律意见书',
]

// 省当年进度要求
export const provinceProgressOptions: ResponseOptionType[] = [
  { fullName: '年内投产', id: '1' },
  { fullName: '年内新开工', id: '2' },
  { fullName: '续建', id: '3' },
]

// 集团当年进度要求
export const groupProgressOptions: ResponseOptionType[] = [
  { fullName: '年内部分投产', id: '1' },
  { fullName: '年内整体投产', id: '2' },
  { fullName: '年内新开工', id: '3' },
  { fullName: '续建', id: '4' },
]

// 工程性质
export const engineering_propertiesOptions: ResponseOptionType[] = [
  { fullName: '在建', id: '1' },
  { fullName: '筹建', id: '2' },
  { fullName: '竣工', id: '3' },
]

// 资金来源
export const capitalSourceOptions: ResponseOptionType[] = [
  { fullName: '中央财政资金', id: '1' },
  { fullName: '省财政资金', id: '2' },
  { fullName: '市财政资金', id: '3' },
  { fullName: '县财政资金', id: '4' },
  { fullName: '企业自有资金', id: '5' },
  { fullName: '外商投资', id: '6' },
  { fullName: '银行贷款', id: '7' },
  { fullName: '其他资金', id: '8' },
]
