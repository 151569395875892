import { Cascader } from 'antd'
import React from 'react'
import { CommonService } from '../apis/project-service-history'

const deepMap = (data: any): any[] => {
  const rsp: any = {}
  rsp.value = `${data.code}|||${data.name}`
  rsp.label = data.name
  const chidren = data.children
  if (chidren) {
    rsp.children = chidren.map((o: any) => deepMap(o))
  }
  return rsp
}

export const AddressSelector = (props: { value?: string; onChange?: (selected: string) => void }) => {
  const [options, setOptions] = React.useState<any[]>()
  React.useEffect(() => {
    CommonService.region()
      .then((resp) => {
        resp.data.forEach(o => {
          if (o.children.length === 0) {
            o.children = [{...o}];
          }
          o.children.map(oo => {
            if(oo.children.length === 0) {
              oo.children = [{...oo}];
            }
          })
        })
        setOptions(resp.data.map((o: any) => deepMap(o)))
      })
      .catch(() => {})
  }, [])

  const displayRender = (labels: any, selectedOptions: any) =>
    labels.map((label: any, i: number) => {
      const option = selectedOptions[i]
      if (!option) {
        return <span></span>
      }
      return (
        <span key={option.value}>
          {label} {i === 0 ? ' - ' : i === 1 ? ' - ' : ''}{' '}
        </span>
      )
    })

  const getValueWithCode = (code?: string) => {
    if (!code || !options) {
      return null
    }
    const result = ['', '', '']
    options.forEach((o: any) => {
      o.children.forEach((oo: any) => {
        oo.children.forEach((ooo: any) => {
          if (ooo.value.includes(code)) {
            result[0] = o.value
            result[1] = oo.value
            result[2] = ooo.value
          }
        })
      })
    })
    return result
  }

  if (!options) {
    return null
  }
  return (
    <Cascader
      disabled
      className="form-cascader"
      options={options}
      value={getValueWithCode(props.value) || ['', '', '']}
      displayRender={displayRender}
      onChange={(data: any) => {
        if (data && data.length === 3) {
          props.onChange?.(data[2].split('|||')[0])
        } else {
          props.onChange?.('')
        }
      }}
    />
  )
}
