import React, { useEffect } from 'react'
import { ProjectServiceHistory } from '../../../apis/project-service-history'
import styles from '../index.module.scss'
import { getAMap } from './map-script'

// let rectData = {
//   lngMax: -180,
//   lngMin: 180,
//   latMax: -90,
//   latMin: 90,
// }

// let zoom: any

const ProjectMap = () => {
  //   const gotoProject = (project: any) => {
  //     location.assign(`#/program/detail/${project.projectId}`)
  //   }

  useEffect(() => {
    ProjectServiceHistory.projectDistribute().then((res) => {
      getAMap().then((AMap) => {
        let center: any
        if (res.data.length === 1) {
          center = [res.data[0].longitude, res.data[0].latitude]
        }
        const Amap = new AMap.Map('project-address', {
          resizeEnable: true,
          zoom: 4,
          center,
          mapStyle: 'amap://styles/05e321edfe9e69b5902e4dc372c36fda',
        })

        // 自动缩放到一图展示所有项目
        // res.data.forEach((item: any) => {
        //   rectData.lngMax = Math.max(rectData.lngMax, item.longitude)
        //   rectData.lngMin = Math.min(rectData.lngMin, item.longitude)
        //   rectData.latMax = Math.max(rectData.latMax, item.latitude)
        //   rectData.latMin = Math.min(rectData.latMin, item.latitude)

        //   center = [(rectData.lngMax + rectData.lngMin) / 2, (rectData.latMax + rectData.latMin) / 2]
        //   const markersDistance = [
        //     AMap.GeometryUtil.distance(
        //       new AMap.LngLat(rectData.lngMax, center[1]),
        //       new AMap.LngLat(rectData.lngMin, center[1])
        //     ),
        //     AMap.GeometryUtil.distance(
        //       new AMap.LngLat(center[0], rectData.latMax),
        //       new AMap.LngLat(center[0], rectData.latMin)
        //     ),
        //   ]

        //   for (let i = 11; i >= 3; i -= 1) {
        //     const viewportSize = AMap.DomUtil.getViewport(document.getElementById('project-address'))
        //     // 通过距离像素比公式将地理长度转为像素尺寸
        //     const getScalePerPixel = (zoom: number) => 0.2531 * Math.pow(2, 19 - zoom)
        //     const markersSize = [markersDistance[0] / getScalePerPixel(i), markersDistance[1] / getScalePerPixel(i)]
        //     // 检查屏幕是否能容纳（四边留有余量）

        //     if (
        //       markersSize[0] &&
        //       markersSize[0] < viewportSize.width - 100 &&
        //       markersSize[1] < viewportSize.height - 20
        //     ) {
        //       zoom = i
        //       break;
        //     }
        //   }
        // })

        let currentMarker: any = null
        let currentItem: any = null
        let infoWindow: any = null
        const generateInfoWindow = (item: any = currentItem, marker: any = currentMarker) => {
          if (!item || !marker) {
            return
          }
          let content = ``
          if (Amap.getZoom() > 10) {
            content = `<div class='map-project-card'>
              <div>项目名称：${item.projectName}</div>
              <div>项目地址：${item.address}</div>
            </div>`
          } else {
            content = `<div class='map-project-name'>${item.projectName}</div>`
          }

          infoWindow = new AMap.InfoWindow({
            content,
            offset: new AMap.Pixel(2, -15),
          })
          infoWindow.open(Amap, marker.getPosition())
        }

        Amap.on('zoomchange', function () {
          generateInfoWindow()
        })

        const markList = res.data.map((item: any) => {
          const icon = new AMap.Icon({
            size: new AMap.Size(24, 24),
            image: './assets/map-icon.png',
            // 设置了 icon 以后，设置 icon 的偏移量，以 icon 的 [center bottom] 为原点
            imageSize: new AMap.Size(20, 20),
            // 图标取图偏移量
            // imageOffset: new AMap.Size(-24, -24),
          })
          const marker = new AMap.Marker({
            position: [item.longitude, item.latitude],
            offset: new AMap.Pixel(-9, -16),
            zIndex: 101,
            icon: icon,
          })

          marker.on('mouseover', function () {
            currentMarker = marker
            currentItem = item
            generateInfoWindow(item, marker)
          })

          marker.on('mouseout', function () {
            infoWindow.close()
          })

          // AMap.event.addListener(marker, 'mouseover', function () {
          //   currentMarker = marker
          //   currentItem = item
          //   console.info(item, Amap.getZoom())
          //   generateInfoWindow(item, marker)
          // })

          // AMap.event.addListener(marker, 'mouseout', function () {
          //   infoWindow.close()
          // })

          return marker
        })

        Amap.add(markList)
      })
    })
  }, [])

  return (
    <div className={`${styles.recentActions} ${styles.projectMap} ${styles.basePadding}`}>
      <h1>项目分布</h1>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          flexGrow: 1,
          alignItems: 'stretch',
          minHeight: '300px',
          height: 'calc(100% - 40px)',
        }}
      >
        <div
          id="project-address"
          style={{
            flexGrow: 1,
          }}
        />
      </div>
    </div>
  )
}

export default ProjectMap
