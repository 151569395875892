/**
 * 电价方案设置：不能编辑设置和自定义电价 yes
 * 负荷曲线设置：不能编辑设置 yes
 * 天气数据设置：不能编辑设置和下载文档 、pdf yes
 * 时间范围与精度：不能编辑设置 yes
 * 技术方案：不能新建、拷贝、删除、归档、重新计算
 * 财务方案：不能下载财务结果（实际上打开是一个pdf，浏览器自带下载pdf功能），不能新建、拷贝、删除、归档、重新计算。不能点击《财务情景配置》
 * 方案报告：只能查看，不能重新生成和下载
*/

import React from "react"
import Global from "../global"
import ProjectAccountControllerApi from "../services/api/ProjectAccountControllerApi"

type Auth = Record<string, boolean>

const DEFAULT_AUTH: Auth = {
  electricity_price_scheme: false,
  load_curve: false,
  weather_data: false,
  time_range_accuracy: false,
  technical_proposal: false,
  financial_plan: false,
  programme_report: false,
}

const useAuth = (projectId: string): Auth => {
  const [auth, setAuth] = React.useState<Auth>(null)

  const fetchAuth = () => {
    if (!projectId) return
    ProjectAccountControllerApi.i3060ProjectAccountOperationAuthGet({
      projectId,
    }).then((res) => {
      const auths = res?.data || []
      const obj = { ...DEFAULT_AUTH }
      Object.keys(obj).forEach((key: string) => {
        obj[key] = auths.includes(key)
      })
      Global.projectAuth = obj;
      setAuth(obj)
    })
  }
  React.useEffect(() => {
    fetchAuth()
    return () => {
      Global.projectAuth = null;
    }
  }, [projectId])
  return auth
}

export default useAuth;