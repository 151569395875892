import React from 'react'
import { Tabs } from 'antd'
import { useParams } from 'react-router-dom'
import { OnlineForm } from './online-form'
import { SubmitType } from './store'
import CollectionForm from './collection-form'

export interface MyFormProps {
  componentRef: any
  visibleDraft: (visible: boolean) => void
  visibleSubmit: (visible: boolean) => void
  onNext: (key: string) => void
}

export default ({ componentRef, visibleDraft, visibleSubmit, onNext }: MyFormProps) => {
  const params = useParams()
  const [type, setType] = React.useState<'online' | 'offline'>('online')
  const formRef = React.useRef<any>()

  React.useImperativeHandle(componentRef, () => {
    return {
      getName: formRef.current.getName,
      submit: async (options: SubmitType) => {
        formRef.current?.submit({
          ...options,
          success: (data: any) => {
            if (options?.isDraft) {
              return
            }

            options?.success?.(data)
            if (type === 'online') {
              onNext('3')
            }
          },
        })
      },
    }
  })

  React.useEffect(() => {
    // 是否显示保存草稿按钮
    visibleDraft(!params.id && type === 'online')

    // 是否显示确定按钮
    visibleSubmit(type === 'online')
  }, [params, type])

  // Tabs
  const tabList = React.useMemo(() => {
    return [
      { key: 'online', label: '在线填写' },
      { key: 'offline', label: '上传收资表' },
    ]
  }, [])

  // 当前tab内容
  const content = React.useMemo(() => {
    switch (type) {
      case 'online':
        return <OnlineForm componentRef={formRef} editable />

      case 'offline':
        return <CollectionForm componentRef={formRef} editable />

      default:
        return null
    }
  }, [type])

  return (
    <Tabs
      type="card"
      activeKey={type}
      onTabClick={(key: string) => {
        setType(key as any)
      }}
    >
      {tabList.map(({ key, label }: any) => (
        <Tabs.TabPane tab={label} key={key}>
          {key === type ? content : null}
        </Tabs.TabPane>
      ))}
    </Tabs>
  )
}
