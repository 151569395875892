import React, {useEffect, useRef} from 'react'
// import { useNavigate } from 'react-router-dom'
import { Button } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import RightsTemplateList from './components/RightsTemplateList'
import { TemplateAuth } from './type'

import './index.less'
import {SubBreadcrumbObservable} from "../layout";
import {SideMenuObservable} from "../../../application";

/**
 * 获取已选成员权限权限ids
 */
export const getCheckedIds = (data: TemplateAuth[]) => {
  const ids = []
  data?.map((item: TemplateAuth) => {
    const { id, chooseFlag, children } = item || {}
    if (Array.isArray(children) && children.length) {
      const list = getCheckedIds(children)
      ids.push(...list)
    } else if (chooseFlag && id) {
      ids.push(id)
    }
  })
  return ids
}

type CardTitleProps = {
  title: React.ReactNode
  onCreate?: () => void
}

const CardTitle = React.memo((props: CardTitleProps) => {
  const { title, onCreate } = props
  return (
    <div className="rights-template-title">
      <h3>{title}</h3>
      <Button icon={<PlusOutlined />} size="large" type="primary" onClick={onCreate}>
        创建模板
      </Button>
    </div>
  )
})

export const GroupProjectRightsTemplate = React.memo(() => {

  const rightsRef: any = useRef()

  useEffect(() => {
    SubBreadcrumbObservable.next([
      {
        name: '设置',
        url: '',
      },
      {
        name: '项目权限模板',
        url: '/user-center/rights-template',
      },
    ])
    SideMenuObservable.next(true)
  }, [])

  // const navigate = useNavigate()
  const handleAdd = () => {
    rightsRef?.current?.handleRename(true)
    // navigate(`/user-center/rights-template/edit/0`)
  }
  return (
    <div className="rights-template">
      <CardTitle title="项目权限模板" onCreate={handleAdd} />
      <RightsTemplateList ref={rightsRef} />
    </div>
  )
})
