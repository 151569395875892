import React, { useEffect, useState } from 'react'
import { render } from 'react-dom'
import { uniqueId } from 'lodash'
import { CloudUploadOutlined } from '@ant-design/icons'
import { Upload, Button, message, Typography, Space } from 'antd'
import type { UploadChangeParam } from 'antd/es/upload'
import type { RcFile, UploadFile, UploadProps } from 'antd/es/upload/interface'
import { FileService } from '../../apis/wl-service'
import { token } from '../../utils/utils'
import './index.less'

export interface IUploadProps {
  action?: string
  accept?: string
  extra?: string
  showProgress?: boolean
  extraWrap?: boolean
  description?: string | React.ReactElement
  disabled?: boolean
  emptyText?: string | React.ReactElement
  maxCount?: number
  maxSize?: number
  value?: UploadFile[]
  onChange?: (list: any[]) => void
  onResult?: (list: any[]) => void
  fileType?: 'pdf' | 'default'
  children?: React.ReactElement
}

interface IFile extends UploadFile {
  fileId?: string
  list?: any
}

const App: React.FC<IUploadProps> = ({
  action,
  accept,
  extra,
  showProgress = false,
  extraWrap = true,
  description,
  disabled,
  emptyText,
  maxCount,
  maxSize,
  value,
  onChange,
  onResult,
  fileType,
  children,
}) => {
  const ref = React.useRef<any>(null)
  const unmountRef = React.useRef<boolean>(false)
  const [fileList, setFileList] = useState<IFile[]>()
  const [showUploadList, setShowUploadList] = useState<boolean>(true)

  React.useEffect(() => {
    unmountRef.current = false
    return () => {
      unmountRef.current = true
    }
  }, [])

  useEffect(() => {
    if (unmountRef.current) return

    if (typeof value === 'string') {
      setFileList(value ? JSON.parse(value) : [])
    } else if (Array.isArray(value)) {
      setFileList((previous) => {
        const fileMap =
          previous?.reduce((occ: any, cur: any) => {
            occ[cur.fileId] = cur
            return occ
          }, {}) ?? {}
        return value.map((v: any) => {
          if (fileMap[v.fileId]) {
            return fileMap[v.fileId]
          }
          return {
            ...v,
            uid: uniqueId(),
            status: 'done',
          }
        })
      })
    }
  }, [value])

  React.useLayoutEffect(() => {
    if (unmountRef.current) return

    if (extra) {
      const oSelect = ref.current?.querySelector('.ant-upload-select')
      if (!oSelect) {
        return
      }
      const doc: any = document.createElement(extraWrap ? 'div' : 'span')
      doc.style.cssText = extraWrap ? `margin: 12px 0` : `margin: 0 12px`
      oSelect.appendChild(doc)
      render(<Typography.Text type="secondary">{extra}</Typography.Text>, doc)
    }
  }, [extra, extraWrap])

  const hiddenShowUploadList = () => {
    if (showProgress) {
      setTimeout(() => {
        if (unmountRef.current) return

        setShowUploadList(false)
      }, 1000)
    }
  }

  React.useEffect(() => {
    if (unmountRef.current) return

    showProgress && setShowUploadList(false)
  }, [showProgress])

  const handleChange: UploadProps['onChange'] = (info: UploadChangeParam<IFile>) => {
    if (unmountRef.current) return

    setFileList(info.fileList)
    const response = info.file.response
    if (response?.code === 200) {
      info.file.fileId = response.data?.fileId ?? response.msg
      if (response?.data) {
        info.file.list = response.data?.list ?? []
      }
      onResult?.(
        info.fileList.map((v: any) => ({ name: v.name, fileId: v.fileId, ...(v.list ? { list: v.list } : {}) }))
      )
      onChange?.(info.fileList.map((v: any) => ({ name: v.name, fileId: v.fileId })))
      hiddenShowUploadList()
    } else if (response?.code) {
      hiddenShowUploadList()
      message.error(response?.msg ?? `保存${info.file.name}失败了 汪:(`)
      setFileList(info.fileList.filter((v: any) => v.status !== 'done'))
    }
  }

  const beforeUpload = (file: RcFile) => {
    if (unmountRef.current) return

    if (showProgress) {
      setShowUploadList(true)
    }
    let canUpload = true
    // 判断上传文件大小限制
    if (maxSize) {
      const isLt2M = file.size / 1024 / 1024 < maxSize
      if (!isLt2M) {
        canUpload = false
        message.error(`上传图片不能大于${maxSize}Ｍ，请重新上传`)
      }
    }

    return canUpload || Upload.LIST_IGNORE
  }

  // 点击下载文件
  const handlePreview = async (file: IFile) => {
    if (!file.fileId) {
      return false
    }
    FileService.download(file.fileId)
  }

  // 删除文件
  // const handleRemove = async (file: any) => {
  //   const resp: any = await FileService.remove({ path: file.fileId })
  //   if (resp?.code === 200) {
  //     return true
  //   }
  //   return false
  // }

  const trigger = React.useMemo(() => {
    if (disabled) {
      return !fileList || fileList?.length === 0 ? emptyText ?? '暂无文件' : null
    }
    if (fileType === 'pdf') {
      return children
    }
    return (
      <Space direction="horizontal">
        <Button icon={<CloudUploadOutlined />}>点击上传</Button>
        {description}
      </Space>
    )
  }, [disabled, fileList, emptyText, fileType, description])

  // React.useEffect(() => {
  //   if (unmountRef.current) return

  //   if (maxCount && (fileList?.length as number) > maxCount) {
  //     setFileList(fileList?.slice(fileList.length - maxCount))
  //   }
  // }, [fileList, maxCount])

  return (
    <span ref={ref}>
      <Upload
        ref={ref}
        accept={accept ?? (fileType === 'pdf' ? '.pdf' : undefined)}
        className="wl-upload"
        action={action ?? FileService.upload()}
        disabled={disabled}
        headers={{ Authorization: token.get() ?? '' }}
        maxCount={maxCount}
        beforeUpload={beforeUpload}
        fileList={fileList as any}
        showUploadList={showUploadList}
        withCredentials={true}
        progress={{
          width: 90,
          strokeColor: {
            '0%': '#108ee9',
            '100%': '#87d068',
          },
          strokeWidth: 3,
          format: (percent) => percent && `${parseFloat(percent.toFixed(2))}%`,
        }}
        // onRemove={handleRemove}
        onChange={handleChange}
        onPreview={handlePreview}
      >
        {trigger}
      </Upload>
    </span>
  )
}

export default App
