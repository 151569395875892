import { Outlet } from 'react-router-dom'
import React from 'react'
import './index.less'


export const ProjectEntry = () => {
  return (
    <div>
      <Outlet />
    </div>
  )
}
