import { IRequestOptions, IRequestConfig, getConfigs, axios } from './serviceOptions'

export const basePath = '/api'


// 设备状况 start **************************************************************************************************
export class EquipmentService {
  // 获取设备状况列表
  static list(
    params: {
      powerStationId: string, // 电站id
      deviceType: string, // 设备类型
      page?: number; // 当前页
      size?: number; // 每页条数
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      const url = basePath + `/i3060/deviceState/getDeviceStateList`;

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      const { page, size, powerStationId, deviceType, ...args } = params
      configs.params = Object.assign({
        powerStationId: powerStationId ?? '',
        deviceType: deviceType ?? '',
        page: page ?? 1,
        size: size ?? 20,
      }, args)

      configs.data = null;
      axios(configs, resolve, reject);
    });
  }

  // 设备情况及类型分布
  static getDeviceTypeDistribution(options: IRequestOptions = {}): Promise<any> {
    return new Promise((resolve, reject) => {
    let url = basePath + `/i3060/deviceState/getDeviceTypeDistribution`

    const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

    configs.data = null;
    axios(configs, resolve, reject);
    });
  }

  // 获取设备类型下拉框 数据
  static getDeviceTypeComboBox(options: IRequestOptions = {}): Promise<any> {
    return new Promise((resolve, reject) => {
    let url = basePath + `/i3060/deviceState/getDeviceTypeComboBox`

    const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

    configs.data = null;
    axios(configs, resolve, reject);
    });
  }

  // 获取电站下拉框 数据
  static getPowerStationComboBox(options: IRequestOptions = {}): Promise<any> {
    return new Promise((resolve, reject) => {
    let url = basePath + `/i3060/deviceState/getPowerStationComboBox`

    const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

    configs.data = null;
    axios(configs, resolve, reject);
    });
  }

  // 测点数据 资产树 详情
  static getMeasuringTree(searchName: string, options: IRequestOptions = {}): Promise<any> {
    return new Promise((resolve, reject) => {
    let url = basePath + `/i3060/deviceState/getMeasuringPoint`

    if (searchName) {
      url = basePath + `/i3060/deviceState/getMeasuringPoint?searchName=${searchName}`
    }

    const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

    configs.data = null;
    axios(configs, resolve, reject);
    });
  }

  // 测点数据图表查询选项
  static getMeasuringPointCheckbox(id: string, options: IRequestOptions = {}): Promise<any> {
    return new Promise((resolve, reject) => {
    let url = basePath + `/i3060/deviceState/getMeasuringPointCheckbox`

    if (id) {
      url = basePath + `/i3060/deviceState/getMeasuringPointCheckbox?entityId=${id}`
    }

    const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

    configs.data = null;
    axios(configs, resolve, reject);
    });
  }

  // 测点数据图表 详情
  static getMeasureSpotChartData(
    params: {
      entityId?: string, // 节点id
      identifyList?: any, // 查询选项
      queryTime?: string; // 时间
    } = {} as any,
  options: IRequestOptions = {}): Promise<any> {
    return new Promise((resolve, reject) => {
    const { entityId, identifyList, queryTime } = params
    let url = basePath + `/i3060/deviceState/getMeasuringPointDiagram?entityId=${entityId}&identifyList=${identifyList}&queryTime=${queryTime}`

    const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

    configs.data = null;
    axios(configs, resolve, reject);
    });
  }
  
}
// 设备状况 end **************************************************************************************************
