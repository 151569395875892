import { useEffect, useState } from 'react'
import { Avatar } from 'antd'
interface IProps {
  user: any
}

// 用户中心 修改用户信息用
const HeadUpload = (props: IProps) => {
  const { user } = props
  const [imageUrl, setImageUrl] = useState('')

  useEffect(() => {
    setImageUrl(user.headUrl)
  }, [user])

  // 上传按钮
  const uploadButton = (
    <Avatar size={80} src={imageUrl} style={{ fontSize: 50, backgroundColor: '#42d4a1' }}>
      {(user?.email || '  ').substring(0, 2)}
    </Avatar>
  )

  return <div>{imageUrl ? <Avatar size={80} src={imageUrl} /> : uploadButton}</div>
}

export default HeadUpload
