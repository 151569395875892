import {Button, Form, Spin} from "antd";
import React, {useEffect, useState} from "react";
import {FormInstance} from "antd/lib/form/hooks/useForm";
import {Config, MFFormItem} from "./mountain-form";
import ReactECharts from "echarts-for-react";
import {getFixedNumber} from "../../../global";
import {parseNumber} from "../resource-map";

const eleConfigs = [
  new Config('firstYearPvPower', '首年发电量', 'kWp', 0, Infinity, 2),
  new Config('firstYearPvHours', '首年发电小时数', 'h', 0, 999999, 2),
  new Config('yearlyPvPower', '年均发电量', 'kWh', 0, Infinity, 2),
  new Config('yearlyPvHours', '年均发电小时数', 'h', 0, 999999, 2),
]

const finConfigs = [
  new Config('irrOne', '总投资内部收益率', '%', 0, 100, 2),
  new Config('irrZero', '资本金内部收益率', '%', 0, 100, 2),
  new Config('npv', '财务净现值', '元', 0, Infinity, 2),
  new Config('returnOnInvestment', '总投资收益率', '%', 0, 100, 2),
  new Config('roe', '资本金净利润率', '%', 0, 100, 2),
  new Config('paybackPeriod', '静态投资回收期', '年', 0, 100, 2),
  new Config('totalRevenue', '总收益', '元', 0, Infinity, 2),
  new Config('ancf', '累计现金流', '元', 0, Infinity, 2),
]

export const MountainFormChange = (props: any) => {

  const form: FormInstance = props.form;

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    form.setFieldsValue(props.data);
    setLoading(false);
  }, [])

  if (loading) {
    return <Spin spinning/>
  }

  const kMap = {
    ele: eleConfigs,
    fin: finConfigs,
  }

  const configs = kMap[props.type];

  return (
    <div className="container-content">
      {
        props.type === 'ele' && <div className="container-title">
          <div>发电量估算</div>
        </div>
      }
      {
        props.type === 'fin' && <div className="container-title">
          <div>财务估算</div>
        </div>
      }
      <Form
        className={'hide-required'}
        form={form}
        style={{
          padding: '.5rem 0rem',
          height: '100%',
          overflowY: 'auto',
          overflowX: 'hidden',
          textAlign: "left"
        }}
        labelAlign='left'
        colon={false}
        wrapperCol={{span: 14}}
        labelCol={{span: 10}}
      >
        {
          configs.map((o: Config) => (o as any) === 'split' ? <div className="split-line"/> : (
            <MFFormItem
              key={o.key}
              keyArr={o.key.split('.')}
              label={o.label}
              unit={o.unit}
              min={o.min}
              max={o.max}
              precision={o.precision}
              hideLabel={o.hideLabel}
            />
          ))
        }
      </Form>
    </div>
  )
}

const resultConfig = {
  fin: [
    {
      key: 'irrOne',
      unit: '%',
      tips: '总投资内部收益率'
    },
    {
      key: 'irrZero',
      unit: '%',
      tips: '资本金内部收益率'
    },
    {
      key: 'npv',
      unit: '万元',
      tips: '财务净现值'
    },
    {
      key: 'returnOnInvestment',
      unit: '%',
      tips: '总投资收益率'
    },
    {
      key: 'roe',
      unit: '%',
      tips: '资本金净利润率'
    },
    {
      key: 'paybackPeriod',
      unit: '年',
      tips: '静态投资回收期'
    },
    {
      key: 'totalRevenue',
      unit: '万元',
      tips: '总收益'
    },
    {
      key: 'ancf',
      unit: '万元',
      tips: '累计现金流'
    }
  ],
  ele: [
    {
      key: 'firstYearPvPower',
      unit: 'kWh',
      tips: '首年发电量'
    },
    {
      key: 'firstYearPvHours',
      unit: 'h',
      tips: '首年发电小时数'
    },
    {
      key: 'yearlyPvPower',
      unit: 'kWh',
      tips: '年均发电量'
    },
    {
      key: 'yearlyPvHours',
      unit: 'h',
      tips: '年均发电小时数'
    }
  ]
}


export const MountainFormResult = (props: any) => {

  const result = props.result;

  return <div>
    <div className="container-content calc-result">
      <div className="container-title">
        <div>发电量估算</div>
        <Button onClick={() => props.onChange?.('ele')} type="primary" ghost>修改</Button>
      </div>
      <div className="calc-result-section">
        {resultConfig.ele.map((o) => (
          <div className='item-content' key={o.key}>
            <div className='item-content-value'>
              {parseNumber(result[o.key], {
                type: 'e',
                radix: 2,
                beginValue: 100000,
              })}
              <span className='item-content-unit'>{o.unit}</span>
            </div>
            <div className='item-content-tips'>{o.tips}</div>
            <div className='v-split-line'/>
          </div>
        ))}
      </div>
      <div className='calc-chart'>
        <div className='calc-chart-title'>首年逐月发电量</div>
        {result?.firstYearPvPowerMonthly?.length > 0 && (
          <ReactECharts
            style={{
              width: '100%',
              height: '180px'
            }}
            option={{
              grid: {left: 10, right: 0, top: 40, bottom: 0, containLabel: true},
              tooltip: {
                show: true,
                trigger: 'axis',
                formatter: (argsList: any[]) => {
                  const args: any = argsList[0]
                  return `${args.name}<br />${args.marker}${parseFloat(args.value.toFixed(2))}kWh`
                }
              },
              xAxis: {
                type: 'category',
                boundaryGap: true,
                data: Object.keys(result.firstYearPvPowerMonthly).map((o) => `${parseInt(o) + 1}`)
                // show: false,
              },
              yAxis: [
                {
                  type: 'value',
                  name: 'kWh',
                  splitLine: {
                    lineStyle: {
                      type: 'dashed'
                    }
                  },
                  axisLabel: {
                    formatter: (val) => {
                      return parseNumber(val, {
                        type: 'e',
                        radix: 2,
                        beginValue: 100000,
                      });
                    }
                  },
                  boundaryGap: false,
                  nameTextStyle: {
                    color: 'gray',
                    padding: [0, 40, 0, 0]
                  },
                },
                {
                  type: 'value',
                  name: `{a|--} 平均值: ${parseNumber(getFixedNumber(
                    result.firstYearPvPowerMonthly.reduce((a: number, b: number) => a + b, 0) /
                    result.firstYearPvPowerMonthly.length,
                    2
                  ), {
                    type: 'e',
                    radix: 2,
                    beginValue: 100000,
                  })}kWh`,
                  nameTextStyle: {
                    color: 'gray',
                    align: 'right',
                    rich: {
                      a: {
                        color: '#1ec692'
                      }
                    }
                  },
                  axisLine: {
                    // show: false,
                  },
                  axisLabel: {
                    // show: false,
                  },
                  axisTick: {
                    // show: false,
                  }
                }
              ],
              series: [
                {
                  name: '',
                  type: 'bar',
                  data: result.firstYearPvPowerMonthly,
                  barWidth: 10,
                  itemStyle: {
                    color: '#1EC692',
                    barBorderRadius: [4, 4, 0, 0]
                  },
                  markLine: {
                    silent: true,
                    symbol: 'none',
                    data: [
                      {
                        yAxis:
                          (result.firstYearPvPowerMonthly as number[]).reduce(
                            (a: number, b: number) => a + b,
                            0
                          ) / result.firstYearPvPowerMonthly.length
                      }
                    ],
                    lineStyle: {
                      normal: {
                        type: 'dashed',
                        color: '#1EC692'
                      }
                    },
                    label: {
                      show: false
                    }
                  }
                }
              ]
            }}
          />
        )}
      </div>
      <div className="container-title" style={{
        marginTop: '1rem',
      }}>
        <div>财务估算</div>
        <Button onClick={() => props.onChange?.('fin')} type="primary" ghost>修改</Button>
      </div>
      <div className="calc-result-section">
        {resultConfig.fin.map((o) => (
          <div className='item-content' key={o.key}>
            <div className='item-content-value'>
              {parseNumber(result[o.key], {
                type: 'e',
                radix: 2,
                beginValue: 100000,
              })}
              <span className='item-content-unit'>{o.unit}</span>
            </div>
            <div className='item-content-tips'>{o.tips}</div>
            <div className='v-split-line'/>
          </div>
        ))}
      </div>
    </div>
  </div>
}
