import { useEffect, useState } from 'react'
import { Row, Col, InputNumber, message } from 'antd'
import { CustomIcons } from '../../../../global'
import { FinanceApi } from '../../../../services'
import { EditInput } from './../index'

const projectTypeList = [
  {
    name: '流动资金',
    code: 'ldzj',
    rows: [
      {
        name: '月数',
        unit: '月',
        field: 'monthNumber',
        type: 'primary',
        valueType: 'int',
      },
      {
        name: '贷款占比',
        unit: '%',
        field: 'loansProportion',
        type: 'primary',
        precision: 1,
      },
      {
        name: '短期贷款率',
        unit: '%',
        field: 'shortPeriodLoansRate',
        type: 'primary',
        precision: 2,
      },
    ],
  },
  {
    name: '长期贷款',
    code: 'cqdk',
    rows: [
      {
        name: '长期贷款占比',
        unit: '%',
        field: 'longTimeLoansProportion',
        type: 'primary',
        precision: 1,
      },
      {
        name: '贷款期限',
        unit: '年',
        field: 'loansDeadline',
        type: 'primary',
        max: 15,
        min: 1,
      },
      {
        name: '长期贷款利率',
        unit: '%',
        field: 'longTimeLoansRate',
        type: 'primary',
        precision: 1,
      },
    ],
  },
  {
    name: '税率',
    code: 'sl',
    rows: [
      {
        name: '所得税率',
        unit: '%',
        field: 'incomeTaxRate',
        type: 'primary',
        precision: 1,
      },
      {
        name: '增值税率',
        unit: '%',
        field: 'addedValueTaxRate',
        type: 'primary',
        precision: 1,
      },
      {
        name: '教育附加税率',
        unit: '%',
        field: 'additionalTaxOfEducationRate',
        type: 'primary',
        precision: 1,
      },
      {
        name: '城市维护建设税率',
        unit: '%',
        field: 'urbanMaintenanceAndConstructionTaxRate',
        type: 'primary',
        precision: 1,
      },
      {
        name: '可抵扣增值税税率',
        unit: '',
        field: 'financeCostId',
        hidden: true,
        type: 'primary',
      },
      {
        name: '设备费用',
        unit: '%',
        field: 'equipmentDeductibleRate',
        type: 'desc',
        precision: 1,
      },
      {
        name: '安装工程费',
        unit: '%',
        field: 'installDeductibleRate',
        type: 'desc',
        precision: 1,
      },
      {
        name: '建筑工程费',
        unit: '%',
        field: 'architectureDeductibleRate',
        type: 'desc',
        precision: 1,
      },
      {
        name: '其他费用',
        unit: '%',
        field: 'otherDeductibleRate',
        type: 'desc',
        precision: 1,
      },
    ],
  },
  {
    name: '贴现',
    code: 'tx',
    rows: [
      {
        name: '贴现率',
        unit: '%',
        field: 'discountRate',
        type: 'primary',
        precision: 1,
      },
    ],
  },
]
const icon: any = {
  projectInvest: 'klf-finance-investment',
  operationCost: 'klf-finance-operate',
  financeCost: 'klf-finance-finance',
  awardSubsidy: 'klf-finance-allowance',
}

const FinanceCostCard = (props: any) => {
  const { editType, result, editConfigId, projectId, changeNameCallback } = props
  const [curResult, setCurResult] = useState<any>()
  const [curName, setCurName] = useState('')

  const formatDefaultData = (data: any) => {
    setCurResult({ ...data })
  }

  const changeNumber = ({ value, name }: { value: number; name: string }) => {
    curResult[name] = value
    formatDefaultData(curResult)
    submitData()
  }

  const submitData = () => {
    let data = { ...curResult, projectInvestId: editConfigId, name: curName || curResult.name }
    const FinanceApiService = new FinanceApi()
    // @ts-ignore
    FinanceApiService.i3060FinanceCostEditPost(data)
      .then(() => {})
      .catch(() => {})
      .finally(() => {})
  }

  useEffect(() => {
    if (result) {
      formatDefaultData(result)
    }
  }, [result])

  const { name } = curResult || {}
  useEffect(() => {
    if (name) {
      setCurName(name)
    }
  }, [])

  if (!result || !curResult) return <></>
  return (
    <div className="param-config-card">
      <Row>
        <Col className="param-title" span={24}>
          <CustomIcons type={icon[editType]} className="icon" />
          <EditInput
            name={curName || name}
            onConfirm={(str) => {
              const FinanceApiService = new FinanceApi()
              FinanceApiService.i3060FinanceParamListRenameProjectLibraryPost({
                projectId,
                name: str,
                type: '3',
                id: editConfigId,
              })
                .then((res: any) => {
                  if (res.code === 200) {
                    changeNameCallback()
                    setCurName(str)
                  }
                })
                .catch(() => {})
                .finally(() => {})
            }}
          />
        </Col>
      </Row>
      {projectTypeList.map((ele) => {
        return (
          <>
            <Row>
              <Col className="config-main-title" span={24}>
                {ele.name}
              </Col>
            </Row>
            {ele['rows'].map((item: any, index) => {
              return !item.field ? (
                <Row className="mb18">
                  <Col span={12} className="name">
                    {item.name}
                  </Col>
                  <Col span={12} className="content"></Col>
                </Row>
              ) : (
                <Row className="mb8 " key={`gf-${index}`}>
                  <Col span={10} className="edit-name name">
                    {item.name}
                  </Col>
                  {!item.hidden && (
                    <Col span={14} className="content">
                      <InputNumber
                        min={item.min ? item.min : 0}
                        max={item.max ? item.max : item.unit === '%' ? 100 : undefined}
                        formatter={(val) => {
                          const arr = new Array(item.precision)
                          arr.fill('\\d')
                          const pat = `^(\\-)*(\\d+)\\.(${item.precision ? arr.join('') : ''}).*$`
                          return item.valueType === 'int'
                            ? '' + Number.parseInt(`${val}`.replace(new RegExp(pat), '$1$2.$3'))
                            : `${val}`.replace(new RegExp(pat), '$1$2.$3')
                        }}
                        style={{ width: '100%' }}
                        addonAfter={item.unit}
                        defaultValue={curResult[item.field]}
                        onBlur={(e) => {
                          let { value } = e.target
                          if (value === '') {
                            message.error('不能为空')
                            return
                          }
                          if (item.unit === '%') {
                            value = Number(value) > 100 ? '100' : value
                          }
                          if (curResult[item.field] == value) return

                          let max = item.max ? item.max : item.unit === '%' ? 100 : undefined
                          let min = item.min ? item.min : 0

                          if (max && value > max) {
                            value = max
                          }
                          if (value < min) {
                            value = min
                          }

                          const arr = new Array(item.precision)
                          arr.fill('\\d')
                          const pat = `^(\\-)*(\\d+)\\.(${item.precision ? arr.join('') : ''}).*$`
                          item.valueType === 'int'
                            ? '' + Number.parseInt(`${value}`.replace(new RegExp(pat), '$1$2.$3'))
                            : `${value}`.replace(new RegExp(pat), '$1$2.$3')
                          changeNumber({
                            value: Number(value),
                            name: item.field,
                          })
                        }}
                      />
                    </Col>
                  )}
                </Row>
              )
            })}
          </>
        )
      })}
    </div>
  )
}
export default FinanceCostCard
