export default {
  developers: {
    entry: [
      '合作伙伴签约',
      '在线收资',
      '项目方案展示',
      '客户签约',
      '项目接入与备案',
      '待办事项',
      '已办事项',
      '资源开发',
      '核准备案',
      '投资决策',
      '建设',
      '项目造价',
      '投资计划',
      '计划执行',
    ],
    collecting: true,
    record: true,
    partnerSigning: true,
    customerSigning: true,
  },
  partners: {
    entry: ['合作伙伴签约', '在线收资', '项目方案展示', '项目接入与备案', '待办事项', '已办事项'],
    collecting: true,
    record: false,
    partnerSigning: true,
    customerSigning: false,
  },
  roofOwner: {
    entry: ['客户签约', '待办事项', '已办事项'],
    collecting: false,
    record: false,
    partnerSigning: false,
    customerSigning: false,
  },
  given: {
    entry: ['客户签约', '待办事项', '已办事项'],
    collecting: false,
    record: false,
    partnerSigning: false,
    customerSigning: false,
  },
}
