import { GaodeMap, Scene } from '@antv/l7'
import { Button, Form, Input, message, Tooltip } from 'antd'
import React, { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { ProjectServiceHistory } from '../../../../../apis/project-service-history'
import { AddressSelector } from '../../../../../components/address-selector'
import { getAMap } from '../../../../../pages/account-detail/components/map-script'
import { NAME_EXP } from '../../../../../utils/constant'
import { CurrAuthContext } from '../../config'

import '../index.less'

interface IProps {
  id?: string
  type?: string
}

const SetUpInfo = React.memo(() => {
  const { roleType, isAdministrators, source, info }: any = useContext(CurrAuthContext)
  console.log('CurrAuthContext', roleType, isAdministrators, source)

  const [form] = Form.useForm()
  const { id }: IProps = useParams()
  const [map, setMap] = useState<any>()

  const onFinish = (values: any) => {
    const { name } = values
    ProjectServiceHistory.rename({
      projectId: id,
      root: {
        name,
      },
    }).then((res: any) => {
      const { code, msg } = res || {}
      if (code === 200) {
        message.success('操作成功!')
      } else {
        msg && message.error(msg)
      }
    })
  }

  const createAMap = () => {
    getAMap().then((AMap) => {
      const lnglat = [116.418261, 39.921984]
      const zoom = 3
      const _map = new AMap.Map('program-create-map', {
        resizeEnable: true,
        center: lnglat,
        zoom,
        mapStyle: 'amap://styles/05e321edfe9e69b5902e4dc372c36fda',
      })

      new Scene({
        id: 'program-create-map',
        map: new GaodeMap({
          mapInstance: _map,
        }),
      })

      setMap(_map)
    })
  }
  useEffect(() => {
    if (map && info) {
      getAMap().then((AMap: any) => {
        const lngLat = new AMap.LngLat(info?.longitude, info?.latitude)
        map.setZoom(16)
        map.setCenter(lngLat)
        map.clearMap()
        const marker = new AMap.Marker({
          position: lngLat,
          title: info.address,
        })
        map.add(marker)
      })
      form.setFieldsValue({ ...info })
    } else {
      createAMap()
    }
  }, [map, info])

  const layout = {
    labelCol: { span: 4 },
    wrapperCol: { span: 20 },
  }
  return (
    <Form
      form={form}
      {...layout}
      className="set-up-info-form"
      style={{
        width: '40%',
        minWidth: '500px',
      }}
      initialValues={{}}
      onFinish={onFinish}
      autoComplete="off"
    >
      <Form.Item
        label="项目名称"
        name="name"
        rules={[
          { required: true, message: '请输入项目名称' },
          {
            pattern: NAME_EXP,
            message: '1-16个字符,中文、英文、_、数字和-组成',
          },
        ]}
      >
        <Input size="large" maxLength={16} />
      </Form.Item>

      <Form.Item label="建设期长度" name="buildMonth">
        <Input size="large" suffix="月" disabled />
      </Form.Item>

      <Form.Item label="运营期长度" name="maintainMonth">
        <Input size="large" suffix="月" disabled />
      </Form.Item>

      <Form.Item label="地理位置" name="city">
        <AddressSelector />
      </Form.Item>
      <Tooltip title={info?.address}>
        <Form.Item label="详细地址" name="address" rules={[{ required: true, message: '请输入详细地址' }]}>
          <Input size="large" disabled />
        </Form.Item>
      </Tooltip>
      <Form.Item noStyle>
        <div id="program-create-map" style={{ width: '100%', height: 188, position: 'relative' }}></div>
      </Form.Item>

      <Form.Item noStyle shouldUpdate={(prevValues, currentValues) => prevValues.name !== currentValues.name}>
        {({ getFieldValue }) => (
          <Button
            type="primary"
            htmlType="submit"
            style={{ marginTop: 20 }}
            disabled={getFieldValue('name') === info?.name}
          >
            保存
          </Button>
        )}
      </Form.Item>
    </Form>
  )
})

export default SetUpInfo
