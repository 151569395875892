import React, { useEffect, useRef, useState } from 'react'
import moment from 'moment'
import { Link, useLocation, useParams } from 'react-router-dom'
import { Button, message, Space, Tooltip, Typography } from 'antd'

import { dynamicText, FileService, ProjectDocumentService, ResourceService } from '../../../apis/wl-service'
import TablePage, { getActionColumn, TablePageInstance } from '../../../components/wl-table-page'
import { investTypeOptions } from '../../project-entry/common/constant'
import { CustomIcons } from '../../../global'

export default () => {
  const params = useParams()
  const location = useLocation()
  const tableRef = useRef<TablePageInstance>(null)
  const [data, setData] = useState<{ projectName: string; investType: string }>({
    projectName: '',
    investType: '',
  }) // 存储页面字段
  const [breadcrumb, setBreadcrumb] = useState<any[]>([])

  // 设置面包屑
  useEffect(() => {
    setBreadcrumb([
      location.state?.engineering
        ? { name: '工程管理', url: `/invest/projectLibrary`, state: location.state }
        : { name: '投资开发', url: '/invest/workbench' },
      { name: '项目库', url: `/invest/projectLibrary`, state: location.state },
      { name: '项目综合文档', url: `/invest/document/${params.id}`, state: location.state },
    ])
  }, [params.id, location.state])

  useEffect(() => {
    ResourceService.info({ id: params.id ?? '' })
      .then((info: any) => {
        setData({
          projectName: info?.data?.project_name ?? '',
          investType: dynamicText(info?.data?.invest_type?.toString(), investTypeOptions),
        })
      })
      .catch(() => {})
      .finally(() => {})
  }, [params.id])

  // 表格列配置
  const columns = () => [
    {
      dataIndex: '_order',
      title: '序号',
      width: 80,
      render: (...args: any[]) => args[2] + 1,
    },
    {
      dataIndex: 'fileName',
      title: '文档名称',
      width: 260,
      // align: 'center',
      render: (text: any) => {
        return (
          text && (
            <Tooltip title={text}>
              <Typography.Text ellipsis>{text}</Typography.Text>
            </Tooltip>
          )
        )
      },
    },
    {
      dataIndex: 'typeName',
      title: '所属功能模块',
      width: 220,
      align: 'center',
    },
    {
      dataIndex: 'createPerson',
      title: '创建人',
      width: 120,
      align: 'center',
    },
    {
      dataIndex: 'createTime',
      title: '创建时间',
      width: 180,
      align: 'center',
      render: (text: any) => {
        text = text && moment(text).format('YYYY-MM-DD HH:mm:ss')
        return (
          text && (
            <Tooltip title={text}>
              <Typography.Text ellipsis>{text}</Typography.Text>
            </Tooltip>
          )
        )
      },
    },
    getActionColumn([
      {
        key: 'download',
        label: '下载',
        onClick: (row: any) => {
          FileService.download(row.fileId)
        },
      },
    ]),
  ]

  const handleDownload = React.useCallback(() => {
    const id = tableRef.current?.getSelectedRowKeys() ?? []
    if (id.length === 0) {
      message.warn('请勾选需要批量下载的内容')
      return
    }
    FileService.zip(id.join(','))
  }, [])

  return (
    <TablePage
      ref={tableRef}
      title={
        <>
          文档查看 — {data.projectName} {data.investType ? `（${data.investType}）` : ''}
        </>
      }
      breadcrumb={breadcrumb}
      checkable
      columns={columns as any}
      extra={
        <Tooltip title="下载项目所有文档，格式打包成一份项目文件夹。">
          <Button type="primary" onClick={handleDownload}>
            批量下载
          </Button>
        </Tooltip>
      }
      footer={
        <Space direction="vertical">
          <Typography.Title level={5}>照片</Typography.Title>
          <Link to={`/invest/document/photos/${params.id}`} state={location.state}>
            <CustomIcons
              type="icon-wenjianjia1"
              style={{
                marginLeft: 36,
                fontSize: 60,
                color: '#f9da67',
              }}
            />
          </Link>
          <Typography.Text>{moment().format('YYYY-MM-DD HH:mm:ss')}</Typography.Text>
        </Space>
      }
      formatJson={(data: any) => {
        return {
          ...(data ?? {}),
          data: {
            list: data?.data || [],
          },
        }
      }}
      getter={(arg: any) => ProjectDocumentService.info({ id: params.id ?? '', ...(arg ?? {}) })}
      pagination={{
        defaultPageSize: 10,
        pageSizeOptions: ['10'],
      }}
      rowKey="fileId"
      scroll={{ x: '100%' }}
    />
  )
}
