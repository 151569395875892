import React, { useLayoutEffect, useMemo, useRef } from 'react'
import { Card, Col, Row, Space, Typography, Divider } from 'antd'
import ReactECharts, { EChartsInstance } from 'echarts-for-react'
import echartsOption, { PieDataType } from './echarts-option'

export interface IPieProps {
  title: string
  dataSource: PieDataType[]
}

const App: React.FC<IPieProps> = ({ title, dataSource }) => {
  const echartsRef = useRef<EChartsInstance>()

  useLayoutEffect(() => {
    echartsRef.current.resize()
  }, [])

  const option = useMemo(() => {
    return echartsOption.pie(title, dataSource)
  }, [title, dataSource])

  return (
    <Card title={title}>
      <Row>
        <Col xs={24} xl={14}>
          <ReactECharts
            ref={echartsRef}
            style={{
              width: '100%',
            }}
            option={option}
          />
        </Col>
        <Col
          xs={24}
          xl={10}
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Space direction="vertical">
            {dataSource?.map((item: any) => {
              return (
                <Space key={item.name} direction="horizontal">
                  <Space direction="horizontal" style={{ minWidth: 100 }}>
                    <Typography.Text style={{ color: item.itemStyle?.color }}> ● </Typography.Text>
                    <Typography.Text>{item.name}</Typography.Text>
                    <Divider type="vertical" orientationMargin={0} />
                  </Space>
                  <Typography.Text type="secondary">{item.percentage}</Typography.Text>
                  <Typography.Text>{item.value} 个项目</Typography.Text>
                </Space>
              )
            })}
          </Space>
        </Col>
      </Row>
    </Card>
  )
}

export default App
