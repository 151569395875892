import { GaodeMap, Scene } from '@antv/l7'
import { Form, Input, Select, Tooltip } from 'antd'
import React, { useEffect, useState, forwardRef, useImperativeHandle } from 'react'
import { ProjectService } from '../../../../../apis/project-service-stash'
import { AddressSelector } from '../../../../../components/address-selector'
import { NAME_EXP } from '../../../../../utils/constant'
import { getAMap } from '../../../../account-detail/components/map-script'
import { ProjectItem, TabPaneProps } from '../../type'
// @ts-ignore
import mapboxgl from '!mapbox-gl'
import { debounce } from 'lodash'

import './index.less'

const mbx = true
let lastMarker

const layout = {
  colon: false,
  labelCol: { span: 5 },
  wrapperCol: { span: 19 },
}

const DefaultParmas = {
  name: null, // 电站名称
  projectId: null, // 项目id
  address: null, // 详细地址
  adcode: null, // 省市区编码
  lat: null, // 经度
  lng: null, // 纬度
}

const BasicInfo: React.FC<TabPaneProps> = React.memo(
  forwardRef((props, ref) => {
    const [form] = Form.useForm()
    const [map, setMap] = useState<any>()
    const [addressList, setAddressList] = React.useState<any[]>([])
    console.log('🚀 ~ file: index.tsx ~ line 14 ~ BasicInfo ~ map', map)

    useImperativeHandle(ref, () => ({
      submit: () => {
        handleSubmit()
      },
      reset: () => {
        form.setFieldsValue({ ...DefaultParmas })
      },
    }))

    const { initialValues, projectList, onSubmit, onFailed } = props

    const handleSubmit = () => {
      form && form.submit()
    }

    // 表单提交
    const onFinish = (values: any) => {
      onSubmit?.(values)
    }

    // 验证失败
    const onFinishFailed = (values: any) => {
      onFailed?.(values)
    }

    const onSearch = debounce((val: string) => {
      ProjectService.inputTips({ cityCode: form.getFieldValue('adcode') || '', address: val }).then((resp: any) => {
        setAddressList(resp.data)
      })
    }, 300)

    const createAMap = () => {
      getAMap().then((AMap) => {
        const lnglat = [116.418261, 39.921984]
        const zoom = 3
        const _map = new AMap.Map('program-create-map', {
          resizeEnable: true,
          center: lnglat,
          zoom,
          mapStyle: 'amap://styles/05e321edfe9e69b5902e4dc372c36fda',
        })

        new Scene({
          id: 'program-create-map',
          map: new GaodeMap({
            mapInstance: _map,
          }),
        })

        setMap(_map)
      })
    }

    const createMapbox = () => {
      lastMarker = undefined
      const map = new mapboxgl.Map({
        style: 'mapbox://styles/owops/cl2h1u3kx000u15o26t9bmtyh',
        center: [116.418261, 39.921984],
        zoom: 2,
        minZoom: 1,
        maxZoom: 20,
        container: 'program-create-map',
        interactive: false,
        projection: 'equirectangular', // starting projection
      } as any)

      setMap(map)
    }

    const projectSelect = (projectId: any) => {
      ProjectService.get({projectId}).then((res: any) => {
        const project = res.data
        form.setFieldsValue({ adcode: project.city, lng: project.longitude, lat: project.latitude, address: project.address, addrName: project.address })
        if (!mbx) {
          getAMap().then((AMap) => {
            const lngLat = new AMap.LngLat(project.longitude, project.latitude)
            map.setZoom(16)
            map.setCenter(lngLat)
            map.clearMap()

            const marker = new AMap.Marker({
              position: lngLat,
              title: project.name,
            })

            map.add(marker)
          })
        } else {
          const popup = new mapboxgl.Popup({
            offset: [0, -40],
            closeButton: false,
          })
          if (lastMarker) {
            lastMarker.remove()
          }
          lastMarker = new mapboxgl.Marker({ offset: [0, -15] }).setLngLat([project.longitude, project.latitude]).addTo(map)
          const txt = project.name

          popup.setLngLat([project.longitude, project.latitude]).setText(txt).addTo(map)

          map.setZoom(15)
          map.setCenter([project.longitude, project.latitude])
        }
      })
    }

    // useEffect(() => {
    //   if (map && props.resourceId) {
    //     if(!mbx) {
    //       getAMap().then((AMap: any) => {
    //         const lngLat = new AMap.LngLat(props.res.lng, props.res.lat)
    //         map.setZoom(16)
    //         map.setCenter(lngLat)
    //         map.clearMap()

    //         const marker = new AMap.Marker({
    //           position: lngLat,
    //           title: props.res.address,
    //         })
    //         map.add(marker)
    //       })
    //     } else {
    //       map.setZoom(13)
    //       map.setCenter([props.res.lng, +props.res.lat + 0.003]);
    //       const popup = new mapboxgl.Popup({
    //         offset: [0, -40],
    //         closeButton: false
    //       });
    //       new mapboxgl.Marker({offset: [0, -15]})
    //         .setLngLat([props.res.lng, props.res.lat])
    //         .addTo(map);
    //       const txt = props.res.address;

    //       popup.setLngLat([props.res.lng, props.res.lat])
    //         .setText(txt)
    //         .addTo(map);
    //     }
    //   }
    // }, [map, props.resourceId])

    useEffect(() => {
      if (!mbx) {
        createAMap()
      } else {
        createMapbox()
      }
    }, [])

    useEffect(() => {
      form.setFieldsValue(initialValues)
    }, [initialValues])

    return (
      <div className="station-basic-info">
        <Form
          form={form}
          {...layout}
          initialValues={{ ...DefaultParmas }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Form.Item
            label="电站名称"
            name="name"
            rules={[
              { required: true, message: '请输入电站名称' },
              {
                pattern: NAME_EXP,
                message: '1-16个字符,中文、英文、_、数字和-组成',
              },
            ]}
          >
            <Input size="large" maxLength={16} />
          </Form.Item>

          <Form.Item label="所属项目" name="projectId" rules={[{ required: true, message: '请选择所属项目' }]}>
            <Select size="large" showSearch onSearch={onSearch} onChange={projectSelect}>
              {projectList?.map(({ projectId, projectName }: ProjectItem) => (
                <Select.Option key={projectId} value={projectId}>
                  {projectName}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Tooltip title={'电站地址'}>
            <Form.Item label="电站地址" name="addrName" rules={[{ required: true, message: '请输入电站地址' }]}>
              <Select
                size="large"
                showSearch
                suffixIcon={null}
                onChange={(val: string) => {
                  const obj = JSON.parse(val)
                  const arr = obj.location.split(',').map(parseFloat)
                  obj?.adcode &&
                    form.setFieldsValue({ adcode: obj.adcode, lng: arr[0], lat: arr[1], address: obj.name, addrName: obj.name })
                  if (!mbx) {
                    getAMap().then((AMap) => {
                      const lngLat = new AMap.LngLat(arr[0], arr[1])
                      map.setZoom(16)
                      map.setCenter(lngLat)
                      map.clearMap()

                      const marker = new AMap.Marker({
                        position: lngLat,
                        title: obj.name,
                      })

                      map.add(marker)
                    })
                  } else {
                    const popup = new mapboxgl.Popup({
                      offset: [0, -40],
                      closeButton: false,
                    })
                    if (lastMarker) {
                      lastMarker.remove()
                    }
                    lastMarker = new mapboxgl.Marker({ offset: [0, -15] }).setLngLat([arr[0], arr[1]]).addTo(map)
                    const txt = obj.name

                    popup.setLngLat([arr[0], arr[1]]).setText(txt).addTo(map)

                    map.setZoom(15)
                    map.setCenter([arr[0], arr[1] + 0.0006])
                  }
                }}
                onSearch={onSearch}
                filterOption={() => true}
              >
                {addressList?.map((o: any, idx) => (
                  <Select.Option key={`${o.address}--${idx}`} value={JSON.stringify(o)}>
                    {o.name}
                    <span className="hide-in-input" style={{ fontSize: 10, color: 'lightgrey', marginLeft: '1rem' }}>
                      {o.district}
                    </span>
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Tooltip>

          <Form.Item label="地理位置" name="adcode" rules={[{ required: true, message: '请选择地理位置' }]}>
            <AddressSelector />
          </Form.Item>

          <Form.Item label="地址" name="address" hidden>
            <Input />
          </Form.Item>

          <Form.Item label="经纬度" style={{ marginBottom: 0 }}>
            <Form.Item
              name="lng"
              rules={[{ required: true, message: '请输入经度' }]}
              style={{ display: 'inline-block', width: 'calc(50% - 5px)' }}
            >
              <Input size="large" placeholder="请输入经度" disabled />
            </Form.Item>
            <Form.Item
              name="lat"
              rules={[{ required: true, message: '请输入纬度' }]}
              style={{ display: 'inline-block', width: 'calc(50% - 5px)', marginLeft: 10 }}
            >
              <Input size="large" placeholder="请输入纬度" disabled />
            </Form.Item>
          </Form.Item>

          <Form.Item noStyle>
            <div id="program-create-map" style={{ width: '100%', height: 188, position: 'relative' }}></div>
          </Form.Item>
        </Form>
      </div>
    )
  })
)

export default BasicInfo
