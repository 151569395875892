import {Form, InputNumber, Spin} from "antd";
import React, {useEffect, useState} from "react";
import {FormInstance} from "antd/lib/form/hooks/useForm";
import {parseNumber} from "../resource-map";

export const MFFormItem = (props: { keyArr: string[]; label: string; unit: string; min: number; max: number; precision: number; hideLabel: boolean, childs?: Config[], fullWidth?: boolean }) => {
  if (props.childs?.length > 0) {
    return (
      <div style={{
        display: "flex",
        alignItems: 'flex-start',
      }}>
        <div className="ant-col ant-col-10 ant-form-item-label ant-form-item-label-left" style={{
          marginBottom: '0.4rem',
        }}>
          <label style={{
            height: 32,
          }} className="ant-form-item-required ant-form-item-no-colon">{props.label}</label>
        </div>
        {props.childs.map(o => (
          <MFFormItem
            key={props.keyArr.join('.') + o.key}
            keyArr={[...props.keyArr, ...o.key.split('.')]}
            label={o.label}
            unit={o.unit}
            min={o.min}
            max={o.max}
            precision={o.precision}
            hideLabel={o.hideLabel}
            fullWidth
          />
        ))}
      </div>
    )
  }

  return (
    <Form.Item
      labelCol={props.fullWidth ? {span: 0} : {span: 10}}
      wrapperCol={props.fullWidth ? {span: 24} : {span: 14}}
      label={props.hideLabel ? '' : props.label}
      name={props.keyArr}
      rules={[
        {
          required: true,
          message: `请输入${props.label}`
        }
      ]}
    >
      <InputNumber
        style={{
          width: '100%'
        }}
        placeholder={`请输入${props.label}`}
        size='middle'
        addonAfter={props.unit}
        controls={false}
        precision={props.precision}
        min={props.min}
        max={props.max}
      />
    </Form.Item>
  )
}

export class Config {
  key: string;
  label: string;
  unit: string;
  min: number;
  max: number;
  precision: number;
  hideLabel: boolean;
  children: Config[];

  constructor(key: string, label: string, unit: string, min?: number, max?: number, precision?: number, children?: Config[], hideLabel?: boolean) {
    this.key = key;
    this.label = label;
    this.unit = unit;
    this.min = min !== undefined ? min : 0;
    this.max = max !== undefined ? max : 999999;
    this.precision = precision !== undefined ? precision : 2;
    this.hideLabel = !!hideLabel;
    this.children = children;
  }
}

const configs = [
  new Config('pvCapacity', '装机容量', 'kWp', 0, Infinity, 2),
  new Config('surfaceTilt', '安装倾角', '°', 0, 360, 2),
  new Config('systemEfficiency', '系统效率', '%', 0, 100, 2),
  new Config('firstYearLoss', '首年衰减率', '%', 0, 100, 2),
  new Config('yearlyLoss', '逐年衰减率', '%', 0, 100, 2),
  'split',
  new Config('techProposalParam.gridPrice', '上网电价', '元/kWh', 0, 999999, 2),
  new Config('capitalCostParam.unitCost', '光伏单位造价', '元/kW', 0, 999999, 2),
  new Config('techProposalParam', '储能容量配比', '%', 0, 100, 2, [
    new Config('batteryCapacityRatio', '储能容量配比', '%', 0, 100, 2, [], true,),
    new Config('batteryCapacityHours', '储能时长', 'h', 0, 999999, 2, [], true),
  ]),
  // new Config('techProposalParam.batteryCapacityHours', '储能时长', 'h', 0, 999999, 2, ),
  new Config('capitalCostParam.batteryUnitCost', '储能单位造价', '元/kWh', 0, 999999, 2),
  new Config('operationCostParam.omCostUnitPrice', '光伏运营成本', '元/kW', 0, 999999, 2),
  new Config('operationCostParam.batteryOmCostUnitPrice', '储能运营成本', '元/kWh', 0, 999999, 2),
  new Config('measurementParam.unitWorkingCapital', '流动资金', '元/kW', 0, 999999, 2),
  new Config('measurementParam.longLoanRate', '长期贷款比例', '%', 0, 100, 2),
  new Config('measurementParam.longLoanInterestRate', '长期贷款利率', '元/kWh', 0, 999999, 2),
  new Config('measurementParam.longLoanYear', '长期贷款年限', '年', 0, 100, 2),
  new Config('measurementParam.addedValueTaxRate', '增值税', '%', 0, 100, 2),
  new Config('measurementParam.incomeTaxRate', '所得税', '%', 0, 100, 2),
  new Config('measurementParam.discountRate', '贴现率', '%', 0, 100, 2),
]

export const MountainForm = (props: any) => {

  const form: FormInstance = props.form;

  console.log(props.center);

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    form.setFieldsValue(props.data);
    setLoading(false);
  }, [])

  if (loading) {
    return <Spin spinning/>
  }

  return (
    <Form
      className={'hide-required'}
      form={form}
      style={{
        padding: '.5rem 1rem',
        height: '100%',
        overflowY: 'auto',
        overflowX: 'hidden',
        textAlign: "left"
      }}
      labelAlign='left'
      colon={false}
      wrapperCol={{span: 14}}
      labelCol={{span: 10}}
    >
      <Form.Item
        label="区域面积"
      >
        <span>{parseNumber(props.area, {
          type: 'e',
          radix: 2,
        })} ㎡</span>
      </Form.Item>
      {
        configs.map((o: Config, idx) => (o as any) === 'split' ? <div key={`sp-${idx}`} className="split-line"/> : (
          <MFFormItem
            key={o.key}
            keyArr={o.key.split('.')}
            label={o.label}
            unit={o.unit}
            min={o.min}
            max={o.max}
            precision={o.precision}
            hideLabel={o.hideLabel}
            childs={o.children || []}
          />
        ))
      }
    </Form>
  )
}
