import React from 'react'
import { useSearchParams } from 'react-router-dom'
import { moduleOptions } from './constant'

export type BreadcrumbType = {
  breadcrumb: any[]
  title: string
  editable: boolean
}

// 设置面包屑
export const useBreadcrumbs = (moduleId: string) => {
  const [searchParams] = useSearchParams()
  const ref = React.useRef<BreadcrumbType>({
    breadcrumb: [],
    title: '',
    editable: false,
  })

  React.useEffect(() => {
    const current = moduleOptions.find((v: any) => v.id === moduleId) ?? {}
    const editable: boolean = searchParams.get('editable') === 'true'
    const title = editable ? `录入${current.fullName}${+moduleId < 6 ? '信息' : ''}` : current.fullName
    ref.current.title = title
    ref.current.editable = editable

    // TODO: 后续补充工程管理面包屑逻辑

    ref.current.breadcrumb = [
      {
        name: '投资开发',
        url: '/invest/workbench',
      },
      {
        name: '工作台',
        url: '/invest/workbench',
      },
      {
        name: title,
        url: current.route,
      },
    ]
  }, [])

  return [ref.current]
}

export default useBreadcrumbs
