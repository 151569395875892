import React, { useRef, useEffect } from 'react'
import { Spin, Card } from 'antd'
import * as echarts from 'echarts'
import { useParams } from 'react-router-dom'
import 'echarts/lib/chart/map'

import { geoJson } from './geoJSON'
import { ProjectServiceHistory } from '../../../../../apis/project-service-history'
import { KLFAlert } from '../components/klf-alert'
import Global from '../../../../../global'
import './index.less'

// test
const mapColors = ['#FFBB64', '#1EC692']
export const NormFireCoal = () => {
  const mapRef = useRef(null)
  const [localPrice, setLocalPrice] = React.useState()
  const [mapData, setMapData] = React.useState([])
  const [uploading, setUploading] = React.useState(true)
  const [updateTime, setUpdateTime] = React.useState()
  const params = useParams()
  const projectId = params.id
  const [min, setMin] = React.useState(0)
  const [max, setMax] = React.useState(0)

  const auth = Global.projectAuth || {}

  useEffect(() => {
    if (mapRef.current) {
      renderChartsData()
    }
  }, [mapRef, mapData])

  useEffect(() => {
    queryNormFireCoal()
  }, [mapRef])

  const renderChartsData = () => {
    const chartDom = mapRef.current
    const myChart = echarts.init(chartDom)

    myChart.showLoading()
    myChart.hideLoading()

    echarts.registerMap('china', geoJson)
    const option = {
      tooltip: {
        trigger: 'item',
        formatter: function (params) {
          const { name, value } = params
          let res = ''
          if (value) {
            res = `<div style="font-size: 14px; color: #262E3F;">${name}</div><div style="font-size: 10px;"><span style="font-size: 14px; color: #1CB887;">${value}</span> 元/kWh</div>`
          } else {
            res = `<div style="font-size: 14px; color: #262E3F;">${name}</div><div style="font-size: 10px;">暂无相关数据</div>`
          }
          return res
        },
        backgroundColor: 'rgba(250,250,250,0.9)',
        borderColor: '#EBEBEB',
      },
      visualMap: {
        show: false,
        right: 70,
        top: 460,
        itemWidth: 12,
        itemHeight: 120,
        min: 0.3,
        max: 0.5,
        inRange: {
          color: mapColors,
        },
        text: [`${max}元/kWh`, `${min}元/kWh`],
        hoverLink: false,
      },
      series: [
        {
          name: 'china map',
          type: 'map',
          map: 'china',
          emphasis: {
            label: {
              show: true,
            },
          },
          label: {
            show: true,
            textBorderColor: '#fff',
            textBorderWidth: 2,
          },
          layoutCenter: ['400px', '430px'],
          layoutSize: 840,
          data: mapData,
        },
      ],
    }
    myChart.setOption(option)
  }

  const queryNormFireCoal = () => {
    ProjectServiceHistory.queryNormFireCoal({ projectId })
      .then((resp) => {
        const { localPrice, regionPrice = [], updateTime } = resp.data
        setLocalPrice(localPrice)
        let data = []
        if (regionPrice.length) {
          regionPrice.map((item) => {
            data.push({ name: item.regionName, value: item.price })
          })
        }
        setMapData(data)
        setUpdateTime(updateTime)

        setMin(resp.data.minPrice)
        setMax(resp.data.maxPrice)
      })
      .catch(() => {})
      .finally(() => {
        setUploading(false)
      })
  }

  return (
    <Spin spinning={uploading}>
      <Card
        className="no-padding-card electricity-info-card"
        title="标杆燃煤电价"
        bordered={false}
        style={{
          marginTop: '14px',
        }}
        headStyle={{
          padding: 0,
          minHeight: '40px',
          lineHeight: '40px',
          fontSize: '18px',
          fontWeight: 'bold',
          color: '#262E3F',
        }}
      ></Card>
      <KLFAlert projectId={projectId} hasAuth={!!auth.electricity_price_scheme} />
      <div
        style={{
          lineHeight: '40px',
        }}
      >
        <strong style={{ fontSize: '16px' }}>当地标杆燃煤电价：</strong>
        <span>{localPrice}元/kWh</span>
      </div>
      <div style={{ display: 'flex', justifyContent: 'space-between', position: 'relative' }}>
        <div ref={mapRef} id="main" />
        <div className="custom-visual-map">
          <div className="max">{max} 元/kWh</div>
          <div
            className="visual-map-box"
            style={{ background: `linear-gradient(0deg, ${mapColors[0]} 0%, ${mapColors[1]} 100%)` }}
          ></div>
          <div className="min">{min} 元/kWh</div>
        </div>
        <div className="update-time-line">
          <span style={{ color: '#262E3F' }}>更新时间：</span>
          {updateTime}
        </div>
      </div>
    </Spin>
  )
}
