import React, { useImperativeHandle, useRef } from 'react'
import moment from 'moment'
import { Form, Row, Spin, Input } from 'antd'
import { CostService } from '../../../../apis/wl-service'
import { CustomFormItem, CustomFormTitle, formItemLayout } from '../../../../components/wl-form-item'
import ProjectInvestTable from './project-invest-table'
import useProject from '../common/useProject'

export interface IProjectProductionFormProps {
  id?: string
  editable?: boolean
  t?: string
}

export const RefForm = ({ id, t, editable = false }: IProjectProductionFormProps, ref: any) => {
  const investTableRef = useRef<any>()
  const [{ imperativeHandle, form, formData, loading, projectId, options, getDetails, onValuesChange }] = useProject({
    key: 'cost',
    shareable: !id,
    submitOptions: {
      label: '新建造价',
      Api: (values: any) =>
        CostService.update({
          id: values.id ?? '',
          root: values,
        }),
    },
    detailsApi: async (projectId: string) => {
      return await (projectId ? CostService.info({ id: projectId }) : CostService.draft())
    },
    formatter: (values: any, type: 'params' | 'component') => {
      // type=params作为参数发送到服务端
      // type=component转换数据用于组件渲染
      // 转换时间值
      if (editable) {
        ;['filing_time', 'decision_time', 'approval_time1', 'approval_time2'].forEach((k: string) => {
          if (type === 'component') {
            values[k] = values[k] ? moment(values[k]) : null
          }
          if (type === 'params') {
            values[k] = values[k] ? moment(values[k]).format('YYYY-MM-DD') : ''
          }
        })
      }

      return values
    },
    mergeResponse: (projectData: any, detailsData: any) => {
      const { resourceList, recordList, decisionList, projectCostList } = projectData
      return {
        ...investTableRef.current?.getValues({ ...projectData, projectCostData: detailsData }),
        id: detailsData?.id ?? '',

        mark: resourceList?.mark,
        project_id: resourceList?.id,
        project_name: resourceList?.projectName,
        project_code: resourceList?.projectCode,
        project_type: resourceList?.resourcetype,
        resourcedependency: resourceList?.resourcedependency,

        filing_time: parseTime(recordList?.filingTime),
        filing_size: recordList?.installSize,
        address: recordList?.address,
        filing_invest: recordList?.filingInvest,

        decision_time: parseTime(decisionList?.decisionTime),

        design_unit: projectCostList?.designUnit,
        develop_unit: projectCostList?.developUnit,
      }
    },
  })

  useImperativeHandle(ref, imperativeHandle)

  // 根据projectId获取详情
  React.useEffect(() => {
    getDetails(id ?? projectId).then()
  }, [id, projectId])

  const parseTime = (value: any) => {
    if (editable) {
      return value ? moment(value) : null
    }
    return value
  }

  return (
    <Spin spinning={loading}>
      <Form
        name="projectCostForm"
        form={form}
        labelWrap
        {...formItemLayout.span8}
        autoComplete="off"
        initialValues={formData}
        onValuesChange={(changedValues, allValues) => {
          onValuesChange(changedValues, allValues)
        }}
      >
        <CustomFormTitle>基本信息</CustomFormTitle>
        <Row>
          <Form.Item hidden name="id">
            <Input />
          </Form.Item>
          <CustomFormItem
            label="项目名称"
            name="mark"
            type="project_selector"
            span={3}
            editable={editable}
            options={options.projectIdOptions}
            setOptions={options.setProjectIdOptions}
            setValues={form.setFieldsValue}
            value={formData.project_name}
            componentProps={{
              disabled: !!id,
            }}
            rules={[
              {
                required: true,
                message: '请选择项目名称',
              },
            ]}
          />

          <CustomFormItem
            label="项目代码"
            name="project_code"
            span={3}
            type="input"
            editable={editable}
            value={formData.project_code}
            componentProps={{
              disabled: true,
            }}
          />

          <CustomFormItem
            label="项目类型"
            name="project_type"
            span={3}
            type="select"
            editable={editable}
            computed={true}
            options={options.projectTypeOptions}
            value={formData.project_type}
            componentProps={{
              disabled: true,
            }}
          />

          <CustomFormItem
            label="资源属地"
            name="resourcedependency"
            span={3}
            type="input"
            editable={editable}
            value={formData.resourcedependency}
            componentProps={{
              disabled: true,
            }}
          />

          <CustomFormItem
            label="备案时间"
            name="filing_time"
            span={3}
            type="datepicker"
            editable={editable}
            value={formData.filing_time ?? null}
            componentProps={{
              format: 'YYYY-MM-DD',
              disabled: true,
            }}
          />

          <CustomFormItem
            label="备案规模(MW)"
            name="filing_size"
            span={3}
            type="number"
            editable={editable}
            value={formData.filing_size}
            componentProps={{
              disabled: true,
            }}
          />

          <CustomFormItem
            label="决策时间"
            name="decision_time"
            span={3}
            type="datepicker"
            editable={editable}
            value={formData.decision_time ?? null}
            componentProps={{
              format: 'YYYY-MM-DD',
              disabled: true,
            }}
          />

          <CustomFormItem
            label="详细地址"
            name="address"
            span={3}
            type="input"
            editable={editable}
            value={formData.address}
            componentProps={{
              disabled: true,
            }}
          />

          <CustomFormItem
            label="备案投资额(万元)"
            name="filing_invest"
            span={3}
            type="number"
            editable={editable}
            value={formData.filing_invest}
            componentProps={{
              disabled: true,
            }}
          />

          <CustomFormItem
            label="设计单位"
            name="design_unit"
            span={3}
            type="input"
            editable={editable}
            value={formData.design_unit}
            componentProps={{}}
          />

          <CustomFormItem
            label="建设单位"
            name="develop_unit"
            span={3}
            type="input"
            editable={editable}
            value={formData.develop_unit}
            componentProps={{}}
          />
        </Row>

        <CustomFormTitle>工程投资</CustomFormTitle>
        <Form.Item wrapperCol={{ span: 24 }}>
          <ProjectInvestTable ref={investTableRef} t={t} editable={editable} dataSource={formData} form={form} />
        </Form.Item>
      </Form>
    </Spin>
  )
}

export default React.forwardRef(RefForm)
