/**
 * project
 * unknown
 *
 * OpenAPI spec version: last
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import ajax, { AjaxPromise, ExtraFetchParams } from '@ajax';
import * as models from '../model/models';

/* tslint:disable:no-unused-variable member-ordering object-literal-shorthand */

/**
  */
export type ParamsBodyauthCaptchaCheckPost = models.AuthCaptchaCheckBody;
/**
  */
export type ParamsBodyauthCaptchaGetPost = models.AuthCaptchaGetBody;
/**
  * @description authI3060CheckEmailGet headers 参数
  * @property `Authorization` Authorization (Only:undefined)
  */
export interface ParamsHeaderauthI3060CheckEmailGet {
  /** Authorization (Only:undefined) */
  'Authorization': string;
}
/**
  * @description authI3060CheckEmailGet参数
  * @property `email` 邮箱
  */
export interface ParamsauthI3060CheckEmailGet {
  // queryParams
  /**
   * 邮箱
   */
  email: string;
}
/**
  */
export type ParamsBodyauthI3060LoginByCodePost = models.AuthI3060LoginByCodeBody;
/**
  */
export type ParamsBodyauthI3060LoginPost = models.AuthI3060LoginBody;
/**
  */
export type ParamsBodyauthI3060RegisterPost = models.AuthI3060RegisterBody;
/**
  */
export type ParamsBodyauthI3060SendLoginCodePost = models.AuthI3060SendLoginCodeBody;
/**
  */
export type ParamsBodyauthI3060SendRegisterCodePost = models.AuthI3060SendRegisterCodeBody;
/**
  * @description authResetPasswordSendMailValidGet headers 参数
  * @property `Authorization` Authorization (Only:undefined)
  */
export interface ParamsHeaderauthResetPasswordSendMailValidGet {
  /** Authorization (Only:undefined) */
  'Authorization': string;
}
/**
  * @description authResetPasswordSendMailValidGet参数
  * @property `mail` 邮箱
  * @property `captchaVerification` 滑块验证码
  */
export interface ParamsauthResetPasswordSendMailValidGet {
  // queryParams
  /**
   * 邮箱
   */
  mail: string;
  /**
   * 滑块验证码
   */
  captchaVerification: string;
}
/**
  * @description authResetPasswordSendMobileValidGet headers 参数
  * @property `Authorization` Authorization (Only:undefined)
  */
export interface ParamsHeaderauthResetPasswordSendMobileValidGet {
  /** Authorization (Only:undefined) */
  'Authorization': string;
}
/**
  * @description authResetPasswordSendMobileValidGet参数
  * @property `mobile` 手机号
  * @property `captchaVerification` 滑块验证码
  */
export interface ParamsauthResetPasswordSendMobileValidGet {
  // queryParams
  /**
   * 手机号
   */
  mobile: string;
  /**
   * 滑块验证码
   */
  captchaVerification: string;
}
/**
  * @description authResetPasswordUpdatePwdByMailGet headers 参数
  * @property `Authorization` Authorization (Only:undefined)
  */
export interface ParamsHeaderauthResetPasswordUpdatePwdByMailGet {
  /** Authorization (Only:undefined) */
  'Authorization': string;
}
/**
  * @description authResetPasswordUpdatePwdByMailGet参数
  * @property `mail` 邮箱
  * @property `validCode` 验证码
  * @property `newPassword` 新密码
  */
export interface ParamsauthResetPasswordUpdatePwdByMailGet {
  // queryParams
  /**
   * 邮箱
   */
  mail: string;
  /**
   * 验证码
   */
  validCode: string;
  /**
   * 新密码
   */
  newPassword: string;
}
/**
  * @description authResetPasswordUpdatePwdByMobileGet headers 参数
  * @property `Authorization` Authorization (Only:undefined)
  */
export interface ParamsHeaderauthResetPasswordUpdatePwdByMobileGet {
  /** Authorization (Only:undefined) */
  'Authorization': string;
}
/**
  * @description authResetPasswordUpdatePwdByMobileGet参数
  * @property `mobile` 手机号
  * @property `validCode` 验证码
  * @property `newPassword` 新密码
  */
export interface ParamsauthResetPasswordUpdatePwdByMobileGet {
  // queryParams
  /**
   * 手机号
   */
  mobile: string;
  /**
   * 验证码
   */
  validCode: string;
  /**
   * 新密码
   */
  newPassword: string;
}
/**
  * @description authUserCheckEmailGet参数
  * @property `code` 邮箱验证码
  * @property `email` 邮箱
  */
export interface ParamsauthUserCheckEmailGet {
  // queryParams
  /**
   * 邮箱验证码
   */
  code: string;
  /**
   * 邮箱
   */
  email: string;
}
/**
  * @description authUserCheckPwdPost headers 参数
  * @property `Authorization` Authorization (Only:)
  */
export interface ParamsHeaderauthUserCheckPwdPost {
  /** Authorization (Only:) */
  'Authorization': string;
}
/**
  * @description authUserCheckPwdPost参数
  */
export interface ParamsauthUserCheckPwdPost {
}
/**
  */
export type ParamsBodyauthUserCheckPwdPost = models.AuthUserCheckPwdBody;
/**
  * @description authUserInfoGet headers 参数
  * @property `Authorization` Authorization (Only:)
  */
export interface ParamsHeaderauthUserInfoGet {
  /** Authorization (Only:) */
  'Authorization': string;
}
/**
  * @description authUserInfoGet参数
  */
export interface ParamsauthUserInfoGet {
}
/**
  * @description authUserSendBindTelephonePost headers 参数
  * @property `Authorization` Authorization (Only:)
  */
export interface ParamsHeaderauthUserSendBindTelephonePost {
  /** Authorization (Only:) */
  'Authorization': string;
}
/**
  * @description authUserSendBindTelephonePost参数
  */
export interface ParamsauthUserSendBindTelephonePost {
}
/**
  */
export type ParamsBodyauthUserSendBindTelephonePost = models.AuthUserSendBindTelephoneBody;
/**
  * @description authUserSendCheckEmailPost headers 参数
  * @property `Authorization` Authorization (Only:undefined)
  */
export interface ParamsHeaderauthUserSendCheckEmailPost {
  /** Authorization (Only:undefined) */
  'Authorization': string;
}
/**
  * @description authUserSendCheckEmailPost参数
  */
export interface ParamsauthUserSendCheckEmailPost {
}
/**
  */
export type ParamsBodyauthUserSendCheckEmailPost = models.AuthUserSendCheckEmailBody;
/**
  * @description authUserSendUnBindPhoneCodePost headers 参数
  * @property `Authorization` Authorization (Only:)
  */
export interface ParamsHeaderauthUserSendUnBindPhoneCodePost {
  /** Authorization (Only:) */
  'Authorization': string;
}
/**
  * @description authUserSendUnBindPhoneCodePost参数
  */
export interface ParamsauthUserSendUnBindPhoneCodePost {
}
/**
  */
export type ParamsBodyauthUserSendUnBindPhoneCodePost = models.AuthUserSendUnBindPhoneCodeBody;
/**
  * @description authUserSendUpdateEmailCodePost headers 参数
  * @property `Authorization` Authorization (Only:undefined)
  */
export interface ParamsHeaderauthUserSendUpdateEmailCodePost {
  /** Authorization (Only:undefined) */
  'Authorization': string;
}
/**
  * @description authUserSendUpdateEmailCodePost参数
  */
export interface ParamsauthUserSendUpdateEmailCodePost {
}
/**
  */
export type ParamsBodyauthUserSendUpdateEmailCodePost = models.AuthUserSendUpdateEmailCodeBody;
/**
  * @description authUserUpdateEmailPost headers 参数
  * @property `Authorization` Authorization (Only:undefined)
  */
export interface ParamsHeaderauthUserUpdateEmailPost {
  /** Authorization (Only:undefined) */
  'Authorization': string;
}
/**
  * @description authUserUpdateEmailPost参数
  */
export interface ParamsauthUserUpdateEmailPost {
}
/**
  */
export type ParamsBodyauthUserUpdateEmailPost = models.AuthUserUpdateEmailBody;
/**
  */
export type ParamsBodyauthUserUpdatePasswordByAccountPost = models.AuthUserUpdatePasswordByAccountBody;
/**
  * @description authUserUpdatePasswordPost headers 参数
  * @property `Authorization` Authorization (Only:)
  */
export interface ParamsHeaderauthUserUpdatePasswordPost {
  /** Authorization (Only:) */
  'Authorization': string;
}
/**
  * @description authUserUpdatePasswordPost参数
  */
export interface ParamsauthUserUpdatePasswordPost {
}
/**
  */
export type ParamsBodyauthUserUpdatePasswordPost = models.AuthUserUpdatePasswordBody;
/**
  * @description authUserUpdatePost headers 参数
  * @property `Authorization` Authorization (Only:)
  */
export interface ParamsHeaderauthUserUpdatePost {
  /** Authorization (Only:) */
  'Authorization': string;
}
/**
  * @description authUserUpdatePost参数
  */
export interface ParamsauthUserUpdatePost {
}
/**
  */
export type ParamsBodyauthUserUpdatePost = models.AuthUserUpdateBody;
/**
  * @description authUserUpdateTelephonePost headers 参数
  * @property `Authorization` Authorization (Only:undefined)
  */
export interface ParamsHeaderauthUserUpdateTelephonePost {
  /** Authorization (Only:undefined) */
  'Authorization': string;
}
/**
  * @description authUserUpdateTelephonePost参数
  */
export interface ParamsauthUserUpdateTelephonePost {
}
/**
  */
export type ParamsBodyauthUserUpdateTelephonePost = models.AuthUserUpdateTelephoneBody;
/**
  * @description authUserUploadHeadImgPost headers 参数
  * @property `Authorization` Authorization (Only:undefined)
  */
export interface ParamsHeaderauthUserUploadHeadImgPost {
  /** Authorization (Only:undefined) */
  'Authorization': string;
  'Content-Type': string;
}
/**
  * @description authUserUploadHeadImgPost参数
  * @property `file` 图片文件
  */
export interface ParamsauthUserUploadHeadImgPost {
  // formParams
  /**
   * 图片文件
   */
  file: any;
}
/**
  * @description authUserValidEmailEmailGet headers 参数
  * @property `Authorization` Authorization (Only:)
  */
export interface ParamsHeaderauthUserValidEmailEmailGet {
  /** Authorization (Only:) */
  'Authorization': string;
}
/**
  * @description authUserValidEmailEmailGet参数
  * @property `email` 邮箱
  */
export interface ParamsauthUserValidEmailEmailGet {
  // pathParams
  /**
   * 邮箱
   */
  email: string;
}
/**
  * @description authUserValidTelephoneMobileGet headers 参数
  * @property `Authorization` Authorization (Only:)
  */
export interface ParamsHeaderauthUserValidTelephoneMobileGet {
  /** Authorization (Only:) */
  'Authorization': string;
}
/**
  * @description authUserValidTelephoneMobileGet参数
  * @property `mobile` 手机号
  */
export interface ParamsauthUserValidTelephoneMobileGet {
  // pathParams
  /**
   * 手机号
   */
  mobile: string;
}

export class UserApi {
  protected $basePath = '/api'.replace(/\/$/, '');

  public constructor(basePath?: string) {
    if (basePath !== undefined) {
      this.$basePath = basePath.replace(/\/$/, '');
    }
  }

  /**
   * 
   * @summary 人机验证验证滑动
   
   * @param data: ParamsBodyauthCaptchaCheckPost
   * @param opt ajax config
   * @returns models.AuthCaptchaCheck
   */
  public authCaptchaCheckPost = (
    
    data: ParamsBodyauthCaptchaCheckPost,opt?: ExtraFetchParams
  ) : AjaxPromise<models.AuthCaptchaCheck>  => {
    const url = this.$basePath + `/auth/captcha/check`;
    const p: any = {};
    p.data = data;
    return ajax.ajax({
      ...opt,
      method: 'POST',
      url,
      ...p
    });
  }
  /**
   * 
   * @summary 获取人机验证图片
   
   * @param data: ParamsBodyauthCaptchaGetPost
   * @param opt ajax config
   * @returns models.AuthCaptchaGet
   */
  public authCaptchaGetPost = (
    
    data: ParamsBodyauthCaptchaGetPost,opt?: ExtraFetchParams
  ) : AjaxPromise<models.AuthCaptchaGet>  => {
    const url = this.$basePath + `/auth/captcha/get`;
    const p: any = {};
    p.data = data;
    return ajax.ajax({
      ...opt,
      method: 'POST',
      url,
      ...p
    });
  }
  /**
   * 
   * @summary 校验邮箱是否可用
   * @param params ParamsauthI3060CheckEmailGet
   
   * @param opt ajax config
   * @returns models.AuthUserUploadHeadImg
   */
  public authI3060CheckEmailGet = (
    params: ParamsauthI3060CheckEmailGet,
    opt?: { headers: ParamsHeaderauthI3060CheckEmailGet } & Omit<ExtraFetchParams, 'headers'> 
  ) : AjaxPromise<models.AuthUserUploadHeadImg>  => {
    const url = this.$basePath + `/auth/i3060/checkEmail`;
    const p: any = {};
    p.query = {};
    if ('email' in params) p.query.email = params.email;
    ajax.check(params.email, 'email');
    return ajax.ajax({
      ...opt,
      method: 'GET',
      url,
      ...p
    });
  }
  /**
   * 
   * @summary 短信验证码登录
   
   * @param data: ParamsBodyauthI3060LoginByCodePost
   * @param opt ajax config
   * @returns models.AuthI3060LoginByCode
   */
  public authI3060LoginByCodePost = (
    
    data: ParamsBodyauthI3060LoginByCodePost,opt?: ExtraFetchParams
  ) : AjaxPromise<models.AuthI3060LoginByCode>  => {
    const url = this.$basePath + `/auth/i3060/loginByCode`;
    const p: any = {};
    p.data = data;
    return ajax.ajax({
      ...opt,
      method: 'POST',
      url,
      ...p
    });
  }
  /**
   * 
   * @summary 登录
   
   * @param data: ParamsBodyauthI3060LoginPost
   * @param opt ajax config
   * @returns models.AuthI3060Register
   */
  public authI3060LoginPost = (
    
    data: ParamsBodyauthI3060LoginPost,opt?: ExtraFetchParams
  ) : AjaxPromise<models.AuthI3060Register>  => {
    const url = this.$basePath + `/auth/i3060/login`;
    const p: any = {};
    p.data = data;
    return ajax.ajax({
      ...opt,
      method: 'POST',
      url,
      ...p
    });
  }
  /**
   * 
   * @summary 注册
   
   * @param data: ParamsBodyauthI3060RegisterPost
   * @param opt ajax config
   * @returns models.AuthI3060Register
   */
  public authI3060RegisterPost = (
    
    data: ParamsBodyauthI3060RegisterPost,opt?: ExtraFetchParams
  ) : AjaxPromise<models.AuthI3060Register>  => {
    const url = this.$basePath + `/auth/i3060/register`;
    const p: any = {};
    p.data = data;
    return ajax.ajax({
      ...opt,
      method: 'POST',
      url,
      ...p
    });
  }
  /**
   * 
   * @summary 登录发送短信
   
   * @param data: ParamsBodyauthI3060SendLoginCodePost
   * @param opt ajax config
   * @returns models.AuthUserUpdatePassword
   */
  public authI3060SendLoginCodePost = (
    
    data: ParamsBodyauthI3060SendLoginCodePost,opt?: ExtraFetchParams
  ) : AjaxPromise<models.AuthUserUpdatePassword>  => {
    const url = this.$basePath + `/auth/i3060/sendLoginCode`;
    const p: any = {};
    p.data = data;
    return ajax.ajax({
      ...opt,
      method: 'POST',
      url,
      ...p
    });
  }
  /**
   * 
   * @summary 注册发送短信
   
   * @param data: ParamsBodyauthI3060SendRegisterCodePost
   * @param opt ajax config
   * @returns models.AuthUserUpdatePassword
   */
  public authI3060SendRegisterCodePost = (
    
    data: ParamsBodyauthI3060SendRegisterCodePost,opt?: ExtraFetchParams
  ) : AjaxPromise<models.AuthUserUpdatePassword>  => {
    const url = this.$basePath + `/auth/i3060/sendRegisterCode`;
    const p: any = {};
    p.data = data;
    return ajax.ajax({
      ...opt,
      method: 'POST',
      url,
      ...p
    });
  }
  /**
   * 
   * @summary 重置密码-发送邮箱验证码
   * @param params ParamsauthResetPasswordSendMailValidGet
   
   * @param opt ajax config
   * @returns models.AuthUserSendCheckEmail
   */
  public authResetPasswordSendMailValidGet = (
    params: ParamsauthResetPasswordSendMailValidGet,
    opt?: { headers: ParamsHeaderauthResetPasswordSendMailValidGet } & Omit<ExtraFetchParams, 'headers'> 
  ) : AjaxPromise<models.AuthUserSendCheckEmail>  => {
    const url = this.$basePath + `/auth/resetPassword/sendMailValid`;
    const p: any = {};
    p.query = {};
    if ('mail' in params) p.query.mail = params.mail;
    ajax.check(params.mail, 'mail');
    if ('captchaVerification' in params) p.query.captchaVerification = params.captchaVerification;
    ajax.check(params.captchaVerification, 'captchaVerification');
    return ajax.ajax({
      ...opt,
      method: 'GET',
      url,
      ...p
    });
  }
  /**
   * 
   * @summary 重置密码-发送手机号验证码
   * @param params ParamsauthResetPasswordSendMobileValidGet
   
   * @param opt ajax config
   * @returns models.AuthUserSendCheckEmail
   */
  public authResetPasswordSendMobileValidGet = (
    params: ParamsauthResetPasswordSendMobileValidGet,
    opt?: { headers: ParamsHeaderauthResetPasswordSendMobileValidGet } & Omit<ExtraFetchParams, 'headers'> 
  ) : AjaxPromise<models.AuthUserSendCheckEmail>  => {
    const url = this.$basePath + `/auth/resetPassword/sendMobileValid`;
    const p: any = {};
    p.query = {};
    if ('mobile' in params) p.query.mobile = params.mobile;
    ajax.check(params.mobile, 'mobile');
    if ('captchaVerification' in params) p.query.captchaVerification = params.captchaVerification;
    ajax.check(params.captchaVerification, 'captchaVerification');
    return ajax.ajax({
      ...opt,
      method: 'GET',
      url,
      ...p
    });
  }
  /**
   * 
   * @summary 重置密码-邮箱验证码重置密码
   * @param params ParamsauthResetPasswordUpdatePwdByMailGet
   
   * @param opt ajax config
   * @returns models.AuthUserSendCheckEmail
   */
  public authResetPasswordUpdatePwdByMailGet = (
    params: ParamsauthResetPasswordUpdatePwdByMailGet,
    opt?: { headers: ParamsHeaderauthResetPasswordUpdatePwdByMailGet } & Omit<ExtraFetchParams, 'headers'> 
  ) : AjaxPromise<models.AuthUserSendCheckEmail>  => {
    const url = this.$basePath + `/auth/resetPassword/updatePwdByMail`;
    const p: any = {};
    p.query = {};
    if ('mail' in params) p.query.mail = params.mail;
    ajax.check(params.mail, 'mail');
    if ('validCode' in params) p.query.validCode = params.validCode;
    ajax.check(params.validCode, 'validCode');
    if ('newPassword' in params) p.query.newPassword = params.newPassword;
    ajax.check(params.newPassword, 'newPassword');
    return ajax.ajax({
      ...opt,
      method: 'GET',
      url,
      ...p
    });
  }
  /**
   * 
   * @summary 重置密码-手机号验证码重置密码
   * @param params ParamsauthResetPasswordUpdatePwdByMobileGet
   
   * @param opt ajax config
   * @returns models.AuthUserSendCheckEmail
   */
  public authResetPasswordUpdatePwdByMobileGet = (
    params: ParamsauthResetPasswordUpdatePwdByMobileGet,
    opt?: { headers: ParamsHeaderauthResetPasswordUpdatePwdByMobileGet } & Omit<ExtraFetchParams, 'headers'> 
  ) : AjaxPromise<models.AuthUserSendCheckEmail>  => {
    const url = this.$basePath + `/auth/resetPassword/updatePwdByMobile`;
    const p: any = {};
    p.query = {};
    if ('mobile' in params) p.query.mobile = params.mobile;
    ajax.check(params.mobile, 'mobile');
    if ('validCode' in params) p.query.validCode = params.validCode;
    ajax.check(params.validCode, 'validCode');
    if ('newPassword' in params) p.query.newPassword = params.newPassword;
    ajax.check(params.newPassword, 'newPassword');
    return ajax.ajax({
      ...opt,
      method: 'GET',
      url,
      ...p
    });
  }
  /**
   * 
   * @summary 验证邮箱
   * @param params ParamsauthUserCheckEmailGet
   
   * @param opt ajax config
   * @returns models.AuthUserCheckEmail
   */
  public authUserCheckEmailGet = (
    params: ParamsauthUserCheckEmailGet,
    opt?: ExtraFetchParams
  ) : AjaxPromise<models.AuthUserCheckEmail>  => {
    const url = this.$basePath + `/auth/user/checkEmail`;
    const p: any = {};
    p.query = {};
    if ('code' in params) p.query.code = params.code;
    ajax.check(params.code, 'code');
    if ('email' in params) p.query.email = params.email;
    ajax.check(params.email, 'email');
    return ajax.ajax({
      ...opt,
      method: 'GET',
      url,
      ...p
    });
  }
  /**
   * 
   * @summary 检查密码正确性
   * @param params ParamsauthUserCheckPwdPost
   * @param data: ParamsBodyauthUserCheckPwdPost
   * @param opt ajax config
   * @returns models.AuthUserCheckPwd
   */
  public authUserCheckPwdPost = (
    params: ParamsauthUserCheckPwdPost,
    data: ParamsBodyauthUserCheckPwdPost,opt?: { headers: ParamsHeaderauthUserCheckPwdPost } & Omit<ExtraFetchParams, 'headers'> 
  ) : AjaxPromise<models.AuthUserCheckPwd>  => {
    const url = this.$basePath + `/auth/user/checkPwd`;
    const p: any = {};
    p.data = data;
    return ajax.ajax({
      ...opt,
      method: 'POST',
      url,
      ...p
    });
  }
  /**
   * 
   * @summary 查询用户信息
   * @param params ParamsauthUserInfoGet
   
   * @param opt ajax config
   * @returns models.AuthUserInfo
   */
  public authUserInfoGet = (
    params: ParamsauthUserInfoGet,
    opt?: { headers: ParamsHeaderauthUserInfoGet } & Omit<ExtraFetchParams, 'headers'> 
  ) : AjaxPromise<models.AuthUserInfo>  => {
    const url = this.$basePath + `/auth/user/info`;
    const p: any = {};
    return ajax.ajax({
      ...opt,
      method: 'GET',
      url,
      ...p
    });
  }
  /**
   * 
   * @summary 获取账户角色限制
   
   
   * @param opt ajax config
   * @returns models.AuthUserRoleInfo
   */
  public authUserRoleInfoGet = (
    
    opt?: ExtraFetchParams
  ) : AjaxPromise<models.AuthUserRoleInfo>  => {
    const url = this.$basePath + `/auth/user/roleInfo`;
    const p: any = {};
    return ajax.ajax({
      ...opt,
      method: 'GET',
      url,
      ...p
    });
  }
  /**
   * 
   * @summary 验证绑定手机号码发送短信
   * @param params ParamsauthUserSendBindTelephonePost
   * @param data: ParamsBodyauthUserSendBindTelephonePost
   * @param opt ajax config
   * @returns models.AuthUserUpdatePassword
   */
  public authUserSendBindTelephonePost = (
    params: ParamsauthUserSendBindTelephonePost,
    data: ParamsBodyauthUserSendBindTelephonePost,opt?: { headers: ParamsHeaderauthUserSendBindTelephonePost } & Omit<ExtraFetchParams, 'headers'> 
  ) : AjaxPromise<models.AuthUserUpdatePassword>  => {
    const url = this.$basePath + `/auth/user/sendBindTelephone`;
    const p: any = {};
    p.data = data;
    return ajax.ajax({
      ...opt,
      method: 'POST',
      url,
      ...p
    });
  }
  /**
   * 
   * @summary 发送验证邮箱
   * @param params ParamsauthUserSendCheckEmailPost
   * @param data: ParamsBodyauthUserSendCheckEmailPost
   * @param opt ajax config
   * @returns models.AuthUserSendCheckEmail
   */
  public authUserSendCheckEmailPost = (
    params: ParamsauthUserSendCheckEmailPost,
    data: ParamsBodyauthUserSendCheckEmailPost,opt?: { headers: ParamsHeaderauthUserSendCheckEmailPost } & Omit<ExtraFetchParams, 'headers'> 
  ) : AjaxPromise<models.AuthUserSendCheckEmail>  => {
    const url = this.$basePath + `/auth/user/sendCheckEmail`;
    const p: any = {};
    p.data = data;
    return ajax.ajax({
      ...opt,
      method: 'POST',
      url,
      ...p
    });
  }
  /**
   * 
   * @summary 解绑手机号码发送短信
   * @param params ParamsauthUserSendUnBindPhoneCodePost
   * @param data: ParamsBodyauthUserSendUnBindPhoneCodePost
   * @param opt ajax config
   * @returns models.AuthUserUpdatePassword
   */
  public authUserSendUnBindPhoneCodePost = (
    params: ParamsauthUserSendUnBindPhoneCodePost,
    data: ParamsBodyauthUserSendUnBindPhoneCodePost,opt?: { headers: ParamsHeaderauthUserSendUnBindPhoneCodePost } & Omit<ExtraFetchParams, 'headers'> 
  ) : AjaxPromise<models.AuthUserUpdatePassword>  => {
    const url = this.$basePath + `/auth/user/sendUnBindPhoneCode`;
    const p: any = {};
    p.data = data;
    return ajax.ajax({
      ...opt,
      method: 'POST',
      url,
      ...p
    });
  }
  /**
   * 
   * @summary 更新邮箱 邮箱验证
   * @param params ParamsauthUserSendUpdateEmailCodePost
   * @param data: ParamsBodyauthUserSendUpdateEmailCodePost
   * @param opt ajax config
   * @returns models.AuthUserUpdatePassword
   */
  public authUserSendUpdateEmailCodePost = (
    params: ParamsauthUserSendUpdateEmailCodePost,
    data: ParamsBodyauthUserSendUpdateEmailCodePost,opt?: { headers: ParamsHeaderauthUserSendUpdateEmailCodePost } & Omit<ExtraFetchParams, 'headers'> 
  ) : AjaxPromise<models.AuthUserUpdatePassword>  => {
    const url = this.$basePath + `/auth/user/sendUpdateEmailCode`;
    const p: any = {};
    p.data = data;
    return ajax.ajax({
      ...opt,
      method: 'POST',
      url,
      ...p
    });
  }
  /**
   * 
   * @summary 更新邮箱
   * @param params ParamsauthUserUpdateEmailPost
   * @param data: ParamsBodyauthUserUpdateEmailPost
   * @param opt ajax config
   * @returns models.AuthUserUpdatePassword
   */
  public authUserUpdateEmailPost = (
    params: ParamsauthUserUpdateEmailPost,
    data: ParamsBodyauthUserUpdateEmailPost,opt?: { headers: ParamsHeaderauthUserUpdateEmailPost } & Omit<ExtraFetchParams, 'headers'> 
  ) : AjaxPromise<models.AuthUserUpdatePassword>  => {
    const url = this.$basePath + `/auth/user/updateEmail`;
    const p: any = {};
    p.data = data;
    return ajax.ajax({
      ...opt,
      method: 'POST',
      url,
      ...p
    });
  }
  /**
   * 当登陆接口返回code == 10005 时候，需要调用这个接口进行修改密码
   * @summary 90天，强制修改密码接口
   
   * @param data: ParamsBodyauthUserUpdatePasswordByAccountPost
   * @param opt ajax config
   * @returns any
   */
  public authUserUpdatePasswordByAccountPost = (
    
    data: ParamsBodyauthUserUpdatePasswordByAccountPost,opt?: ExtraFetchParams
  ) : AjaxPromise<any>  => {
    const url = this.$basePath + `/auth/user/updatePasswordByAccount`;
    const p: any = {};
    p.data = data;
    return ajax.ajax({
      ...opt,
      method: 'POST',
      url,
      ...p
    });
  }
  /**
   * 
   * @summary 修改密码
   * @param params ParamsauthUserUpdatePasswordPost
   * @param data: ParamsBodyauthUserUpdatePasswordPost
   * @param opt ajax config
   * @returns models.AuthUserUpdatePassword
   */
  public authUserUpdatePasswordPost = (
    params: ParamsauthUserUpdatePasswordPost,
    data: ParamsBodyauthUserUpdatePasswordPost,opt?: { headers: ParamsHeaderauthUserUpdatePasswordPost } & Omit<ExtraFetchParams, 'headers'> 
  ) : AjaxPromise<models.AuthUserUpdatePassword>  => {
    const url = this.$basePath + `/auth/user/updatePassword`;
    const p: any = {};
    p.data = data;
    return ajax.ajax({
      ...opt,
      method: 'POST',
      url,
      ...p
    });
  }
  /**
   * 
   * @summary 修改用户基本信息
   * @param params ParamsauthUserUpdatePost
   * @param data: ParamsBodyauthUserUpdatePost
   * @param opt ajax config
   * @returns models.AuthUserUpdate
   */
  public authUserUpdatePost = (
    params: ParamsauthUserUpdatePost,
    data: ParamsBodyauthUserUpdatePost,opt?: { headers: ParamsHeaderauthUserUpdatePost } & Omit<ExtraFetchParams, 'headers'> 
  ) : AjaxPromise<models.AuthUserUpdate>  => {
    const url = this.$basePath + `/auth/user/update`;
    const p: any = {};
    p.data = data;
    return ajax.ajax({
      ...opt,
      method: 'POST',
      url,
      ...p
    });
  }
  /**
   * 
   * @summary 更新手机号码
   * @param params ParamsauthUserUpdateTelephonePost
   * @param data: ParamsBodyauthUserUpdateTelephonePost
   * @param opt ajax config
   * @returns models.AuthUserUpdatePassword
   */
  public authUserUpdateTelephonePost = (
    params: ParamsauthUserUpdateTelephonePost,
    data: ParamsBodyauthUserUpdateTelephonePost,opt?: { headers: ParamsHeaderauthUserUpdateTelephonePost } & Omit<ExtraFetchParams, 'headers'> 
  ) : AjaxPromise<models.AuthUserUpdatePassword>  => {
    const url = this.$basePath + `/auth/user/updateTelephone`;
    const p: any = {};
    p.data = data;
    return ajax.ajax({
      ...opt,
      method: 'POST',
      url,
      ...p
    });
  }
  /**
   * 
   * @summary 上传用户头像
   * @param params ParamsauthUserUploadHeadImgPost
   
   * @param opt ajax config
   * @returns models.AuthUserUploadHeadImg
   */
  public authUserUploadHeadImgPost = (
    params: ParamsauthUserUploadHeadImgPost,
    opt?: { headers: ParamsHeaderauthUserUploadHeadImgPost } & Omit<ExtraFetchParams, 'headers'> 
  ) : AjaxPromise<models.AuthUserUploadHeadImg>  => {
    const url = this.$basePath + `/auth/user/uploadHeadImg`;
    const p: any = {};
    p.form = new FormData();
    // 上传文件
    opt = {
      ...opt,
      headers: {
        ...(opt && opt.headers),
        'Content-Type': 'multipart/form-data'
      }
    };
    // p.form = new FormData();
    if ('file' in params) p.form.append('file', params.file);
    ajax.check(params.file, 'file');
    return ajax.ajax({
      ...opt,
      method: 'POST',
      url,
      ...p
    });
  }
  /**
   * 
   * @summary 检查邮箱是否存在
   * @param params ParamsauthUserValidEmailEmailGet
   
   * @param opt ajax config
   * @returns models.AuthUserValidTelephoneMobile
   */
  public authUserValidEmailEmailGet = (
    params: ParamsauthUserValidEmailEmailGet,
    opt?: { headers: ParamsHeaderauthUserValidEmailEmailGet } & Omit<ExtraFetchParams, 'headers'> 
  ) : AjaxPromise<models.AuthUserValidTelephoneMobile>  => {
    const {
    email,
} = params;
    const url = this.$basePath + `/auth/user/validEmail/${email}`;
    const p: any = {};
    ajax.check(params.email, 'email');
    return ajax.ajax({
      ...opt,
      method: 'GET',
      url,
      ...p
    });
  }
  /**
   * 
   * @summary 检查手机号是否存在
   * @param params ParamsauthUserValidTelephoneMobileGet
   
   * @param opt ajax config
   * @returns models.AuthUserValidTelephoneMobile
   */
  public authUserValidTelephoneMobileGet = (
    params: ParamsauthUserValidTelephoneMobileGet,
    opt?: { headers: ParamsHeaderauthUserValidTelephoneMobileGet } & Omit<ExtraFetchParams, 'headers'> 
  ) : AjaxPromise<models.AuthUserValidTelephoneMobile>  => {
    const {
    mobile,
} = params;
    const url = this.$basePath + `/auth/user/validTelephone/${mobile}`;
    const p: any = {};
    ajax.check(params.mobile, 'mobile');
    return ajax.ajax({
      ...opt,
      method: 'GET',
      url,
      ...p
    });
  }
}

export default new UserApi();