import {
  Button,
  Spin
} from 'antd'
import React, { useEffect, useState } from 'react'
import axios from 'axios'
// import mammoth from 'mammoth'

import { token } from '../../../utils/utils'
// import { loadPdf } from './pdf-file-viewer'
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';

import pdfjsWorker from 'pdfjs-dist/build/pdf.worker.entry';

export const ViewFile = (props: any) => {
  const [loading, setLoading] = useState<any>(true)

  const {
    adcode
  } = props.data;
  const prefix = axios.defaults.baseURL
  const url = `${prefix || ''}/api/i3060/roof/getRoofWord?adcode=${adcode}&type=1` // ${isWord ? 'readWord' : 'readPdf'}/${id}`

  useEffect(() => {
    viewTemplate()
  }, [])

  const [base64, setBase64] = useState<any>();
  const [pageNum, setPageNum] = useState(1);
  const [total, setTotal] = useState(0);

  const viewTemplate = () => {
    setLoading(true)
    const authToken: string = token.get() || ''
    fetch(url, {
      method: 'get',
      headers: {
        Authorization: authToken,
        'Content-Type': 'application/pdf;charset=UTF-8',
      },
    }).then((resp) => {
      resp.arrayBuffer().then((arrayBuffer) => {
        const blob = new Blob([arrayBuffer], {type: 'application/pdf'});
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onload = () => {
          setBase64(reader.result);
        }
        setLoading(false);
      })
    })
  }

  return (
    <div
      className="pg-viewer-wrapper"
      style={{
        width: '100%',
        height: '60vh',
        overflowY: loading ? 'hidden' : 'auto',
        overflowX: 'hidden',
        userSelect: 'none',
        touchAction: 'none',
      }}
    >
      {loading && (
        <Spin
          spinning={loading}
          style={{
            width: '100%',
            height: '60vh',
          }}
        />
      )}
      {
        base64 && <Document
          file={base64}
          onLoadSuccess={(pageTotal) => {setTotal(pageTotal.numPages);}}
          options={{ workerSrc: pdfjsWorker }}
        >
          <Page style={{
            userSelect: 'none',
          }} scale={2} pageNumber={pageNum} />
        </Document>
      }
      <div style={{
        position: 'absolute',
        bottom: 0,
        left: 0,
        right: 0,
        backgroundColor: '#eeeeee',
        height: 50,
      }}>
      <Button onClick={() => {
        if(pageNum > 1) {
          setPageNum(pageNum - 1);
        }
      }}>上一页</Button>
      <Button onClick={() => {
        if(pageNum < total) {
          setPageNum(pageNum + 1);
        }
      }}>下一页</Button>
      </div>
    </div>
  )
}
