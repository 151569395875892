import React, { ReactElement, useEffect, useMemo } from 'react'
import {
  Button,
  Card,
  Col,
  Input,
  Layout,
  Select,
  Spin,
  Table,
  Tooltip,
  Row,
  Modal,
  message,
  List,
  Cascader,
} from 'antd'
import {
  LoadingOutlined,
  ExclamationCircleFilled,
  LeftOutlined,
  PlayCircleOutlined,
  PlusCircleFilled,
  UnorderedListOutlined,
  CaretDownOutlined,
} from '@ant-design/icons'
import './index.less'
import { TechPlanList } from './tech-plan-list'
import { FinanceService } from '../../../apis/project-service-history'
import { useNavigate, useParams } from 'react-router-dom'
// import { ParamConfig } from './param-config'
import ParamConfigCard from './param-config-card'
import FinanceList from '../../program/manage/detail/components/finance-list'
import Global, { CustomIcons } from '../../../global'
import { RESOURCE_NAME_EXP, ZH_EXP } from '../../../utils/constant'
import { WorkspaceModal } from '../workspace'
import CostModal from './costModal'
import { FinanceApi } from '../../../services'
import useAuth from '../../../utils/auth'

const formatStr = (str: string, length = 6) => {
  let num = length * 2
  if (str.replace(ZH_EXP, '**').length <= num) {
    return str
  }
  var m = Math.floor(8 / 2)
  for (var i = m; i < str.length; i++) {
    if (str.substring(0, i).replace(ZH_EXP, '**').length >= num) {
      return str.substring(0, i) + '...'
    }
  }
}

export const TooltipStr = (str: string, callback: any) => {
  let name = formatStr(str)
  let num = str.replace(ZH_EXP, '**').length
  return num >= 12 ? (
    <Tooltip title={str}>
      <div
        className="hover-outer"
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          flexWrap: 'nowrap',
        }}
      >
        <span
          style={{
            whiteSpace: 'nowrap',
            flexShrink: 1,
            textOverflow: 'ellipsis',
            overflow: 'hidden',
          }}
        >
          {name}
        </span>
        <Button
          className="hover-inner"
          type="text"
          onClick={() => {
            callback()
          }}
          icon={<CustomIcons type="klf-edit1" />}
        />
      </div>
    </Tooltip>
  ) : (
    <div
      className="hover-outer"
      style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        flexWrap: 'nowrap',
      }}
    >
      <span
        style={{
          whiteSpace: 'nowrap',
          flexShrink: 1,
          textOverflow: 'ellipsis',
          overflow: 'hidden',
        }}
      >
        {str}
      </span>
      <Button
        className="hover-inner"
        type="text"
        onClick={() => {
          callback()
        }}
        icon={<CustomIcons type="klf-edit1" />}
      />
    </div>
  )
}

export const EditInput = (props: { name: string; onConfirm: (str: string) => void; extra?: ReactElement }) => {
  const [edit, setEdit] = React.useState(false)
  if (edit) {
    return (
      <div
        style={{
          flexShrink: 1,
        }}
      >
        <Input
          allowClear
          defaultValue={props.name}
          onBlur={(val) => {
            if (!RESOURCE_NAME_EXP.test(val.target.value)) {
              const dom = val.target
              message.error('1-50个字符,中文、英文、_、数字和-组成')
              dom?.focus()
              return
            }
            setEdit(false)
            props.onConfirm?.(val.target.value)
          }}
        />
      </div>
    )
  }
  return TooltipStr(props.name, () => {
    setEdit(true)
  })
  // <div
  //   className="hover-outer"
  //   style={{
  //     display: 'flex',
  //     flexDirection: 'row',
  //     alignItems: 'center',
  //     flexWrap: 'nowrap',
  //   }}
  // >
  //   <span
  //     style={{
  //       whiteSpace: 'nowrap',
  //       flexShrink: 1,
  //       textOverflow: 'ellipsis',
  //       overflow: 'hidden',
  //     }}
  //   >
  //     {props.name}
  //   </span>
  //   <Button
  //     className="hover-inner"
  //     type="text"
  //     onClick={() => {
  //       setEdit(true)
  //     }}
  //     icon={<CustomIcons type="klf-edit1" />}
  //   />
  // </div>
}

const queryArr: number[] = []
let queryInterval: any

const editTypeMap: any = {
  projectInvest: '项目投资',
  operationCost: '运营成本',
  financeCost: '财务成本',
  awardSubsidy: '激励补贴',
}

const costMapNumber: any = {
  projectInvest: 1,
  operationCost: 2,
  financeCost: 3,
  awardSubsidy: 4,
}

export const FinancialWorkspace = () => {
  const params = useParams()
  const nav = useNavigate()

  const [finTypes, setFinTypes] = React.useState<{ key: string; name: string; unit: string; width?: number }[]>([])

  const [loading, setLoading] = React.useState(true)
  const [editName, setEditName] = React.useState(false)
  const [type, setType] = React.useState(location.href.includes('comp') ? 1 : 0)
  const [selectedFinType, setSelectedFinType] = React.useState<string>()
  const [techData] = React.useState<any>()
  const [configScene, setConfigScene] = React.useState(false)

  const [configMap, setConfigMap] = React.useState<any[]>([])
  const [max, setMax] = React.useState<number>()
  const [min, setMin] = React.useState<number>()

  const [configSceneDataSource, setConfigSceneDataSource] = React.useState<any[]>()
  const [planListDataSource, setPlanListDataSource] = React.useState<any[]>()

  const [library, setLibrary] = React.useState<any>()
  const [rows, setRows] = React.useState<string[]>([])
  const [x, setX] = React.useState([])

  const [calcing, setCalcing] = React.useState(false)
  // const [foldCfgs] = React.useState<string[]>(['projectInvest', 'operationCost', 'financeCost', 'awardSubsidy'])

  const [costModalVisible, setCostModalVisible] = React.useState(false)
  const [costModalData, setCostModalData] = React.useState<any[]>([])
  const [curCostModalData, setCurCostModalData] = React.useState<any>({})

  const [editFlag, setEditFlag] = React.useState(false)
  const [editType, setEditType] = React.useState<any>()
  const [editConfigId, setEditConfigId] = React.useState('')

  const auth = useAuth(params.id) || {}

  const hasAuth = useMemo(() => {
    return !!auth['financial_plan']
  }, [auth])

  const loadConfigScene = (callback?: () => void) => {
    FinanceService.list6({
      projectId: params.id!,
    })
      .then((resp) => {
        setConfigSceneDataSource(resp.data || [])
      })
      .finally(() => {
        callback?.()
      })
  }

  useEffect(() => {
    if (!queryInterval) {
      queryInterval = setInterval(() => {
        if (queryArr.length > 0) {
          queryArr.length = 0
          loadData(selectedFinType, true)
        }
      }, 5000)
    }
    return () => {
      clearInterval(queryInterval)
      queryInterval = undefined
      queryArr.length = 0
    }
  }, [])

  useEffect(() => {
    if (configScene) {
      loadConfigScene()
    } else {
      loadData(selectedFinType)
    }
  }, [configScene])

  const placeFileItem = (id: string, collected: boolean) => {
    FinanceService.lock({
      financeId: id,
      root: {
        type: collected ? 1 : 0,
      },
    }).then(() => {
      loadData(selectedFinType)
    })
  }

  const collectionItem = (id: string, collected: boolean) => {
    FinanceService.mark({
      financeId: id,
      root: {
        type: collected ? 1 : 0,
      },
    }).then(() => {
      loadData(selectedFinType)
    })
  }

  const refreshItem = (record: any) => {
    FinanceService.calculate({
      projectId: params.id!,
      root: {
        financeId: record.id,
      },
    }).then(() => {
      loadData(selectedFinType)
    })
  }

  const ExtraActions = (props: { data: any }) => {
    const row = props.data

    return (
      <div
        className="hover-inner"
        style={{
          display: 'inline-block',
          whiteSpace: 'nowrap',
        }}
      >
        {row.marked === true ? (
          <Tooltip title="取消星标">
            <Button
              onClick={() => {
                collectionItem(row.id, false)
              }}
              type="link"
              icon={<CustomIcons type="klf-mark" />}
            />
          </Tooltip>
        ) : (
          <Tooltip title="设为星标">
            <Button
              onClick={() => {
                collectionItem(row.id, true)
              }}
              type="text"
              icon={<CustomIcons type="klf-non-mark" />}
            />
          </Tooltip>
        )}
        {row.calcStatus === 2 && (
          <Tooltip title={row.locked ? '取消归档' : '归档'}>
            <Button
              type={row.locked ? 'link' : 'text'}
              onClick={() => {
                if (!row.locked) {
                  Modal.confirm({
                    keyboard: false,
                    title: '归档',
                    icon: <CustomIcons type="klf-alarm" />,
                    content: '归档该方案后将不能对该方案进行编辑，确认归档么？',
                    onOk: () => {
                      placeFileItem(row.id, !row.locked)
                    },
                    cancelButtonProps: {
                      type: 'primary',
                      ghost: true,
                    },
                  })
                } else {
                  placeFileItem(row.id, !row.locked)
                }
              }}
              icon={<CustomIcons type={row.locked ? 'klf-file' : 'klf-file-line'} />}
            />
          </Tooltip>
        )}

        {(row.calcStatus === 0 || row.calcStatus === 3 || row.calcStatus === 4) && (
          <Tooltip title={row.calcStatus === 0 ? '一键计算' : '重新计算'}>
            <Button
              type="text"
              onClick={() => {
                if (row.calcStatus === 4) {
                  Modal.confirm({
                    keyboard: false,
                    title: '重新计算',
                    icon: <CustomIcons type="klf-alarm1" />,
                    content: `确认重新计算${row.name}？原计算结果将覆盖`,
                    onOk: () => {
                      refreshItem(row)
                    },
                    cancelButtonProps: {
                      type: 'primary',
                      ghost: true,
                    },
                  })
                } else {
                  refreshItem(row)
                }
              }}
              icon={<CustomIcons type="klf-recalculate1" />}
            />
          </Tooltip>
        )}
      </div>
    )
  }

  const columns = [
    {
      title: '财务情景',
      dataIndex: 'name',
      key: 'name',
      width: 252,
      render: (res: string, row: any) => (
        <EditInput
          name={res}
          onConfirm={(str) => {
            FinanceService.editName({
              paramListId: row.id!,
              root: {
                name: str,
              },
            })
              .then(() => {
                loadData(selectedFinType)
              })
              .catch(() => {
                loadData(selectedFinType)
              })
          }}
        />
      ),
    },
    ...rows.map((o) => ({
      title: o,
      dataIndex: o,
      key: o,
      width: 256,
      render: (res: any) => {
        if (!res) {
          return null
        }
        if (selectedFinType !== 'name') {
          switch (res.calcStatus) {
            case 3:
              return (
                <div className="hover-outer one-line" style={{ color: '#767F95' }}>
                  计算失败
                  <ExtraActions data={res} />
                </div>
              )
            case 4:
              return (
                <div className="hover-outer one-line" style={{ color: '#FF574C' }}>
                  结果失效
                  <ExtraActions data={res} />
                </div>
              )
            case 1:
              return (
                <div className="hover-outer one-line">
                  <span
                    style={{
                      whiteSpace: 'nowrap',
                    }}
                  >
                    方案计算中
                    <LoadingOutlined
                      style={{
                        marginLeft: '1rem',
                      }}
                    />
                  </span>
                  <ExtraActions data={res} />
                </div>
              )
          }
        }

        if (res.value !== undefined && res.value !== null && res.value !== res.name && !isNaN(res.value)) {
          if (res.value === max) {
            return (
              <div className="hover-outer one-line">
                <Tooltip title="Max">
                  <div style={{ color: '#1CB887', display: 'inline', flexShrink: 1 }}>
                    {res.value}
                    {finTypes.find((o) => o.key === selectedFinType)?.unit || ''}
                  </div>
                </Tooltip>
                <ExtraActions data={res} />
              </div>
            )
          }
          if (res.value === min) {
            return (
              <div className="hover-outer one-line">
                <Tooltip title="Min">
                  <div style={{ color: '#F97942', display: 'inline', flexShrink: 1 }}>
                    {res.value}
                    {finTypes.find((o) => o.key === selectedFinType)?.unit || ''}
                  </div>
                </Tooltip>
                <ExtraActions data={res} />
              </div>
            )
          }
          return (
            <div className="hover-outer one-line">
              <div
                style={{
                  flexShrink: 1,
                }}
              >
                {res.value}
                {finTypes.find((o) => o.key === selectedFinType)?.unit || ''}
              </div>
              <ExtraActions data={res} />
            </div>
          )
        } else {
          return (
            <div className="hover-outer one-line">
              <EditInput
                name={res.name}
                onConfirm={(str) => {
                  FinanceService.rename1({
                    financeId: res.id,
                    root: {
                      name: str,
                    },
                  })
                    .then(() => {
                      loadData(selectedFinType)
                    })
                    .catch(() => {
                      loadData(selectedFinType)
                    })
                }}
              />
              <ExtraActions data={res} />
            </div>
          )
        }
      },
    })),
    {
      dataIndex: '',
      key: '',
    },
    {
      title: (
        <Button
          onClick={() => {
            const modal = WorkspaceModal.confirm({
              keyboard: false,
              title: <h2>选择规划运行方案</h2>,
              closable: true,
              content: (
                <TechPlanList
                  projectId={params.id!}
                  selected={x}
                  onClose={(reload) => {
                    if (reload) {
                      loadData(selectedFinType)
                    }
                    modal.destroy()
                  }}
                />
              ),
              icon: null,
              width: 1240,
              okButtonProps: {
                style: { display: 'none' },
              },
              cancelButtonProps: {
                style: { display: 'none' },
              },
            })
          }}
          type="link"
          style={{
            fontSize: 20,
          }}
          icon={
            <svg
              width="20px"
              height="20px"
              viewBox="0 0 20 20"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              xmlnsXlink="http://www.w3.org/1999/xlink"
            >
              <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g transform="translate(-1348.000000, -178.000000)">
                  <g transform="translate(48.000000, 160.000000)">
                    <g transform="translate(1276.000000, 0.000000)">
                      <g transform="translate(24.000000, 18.000000)">
                        <rect x="0" y="0" width="20" height="20"></rect>
                        <g fill="#1EC692" fillRule="nonzero">
                          <path
                            d="M19.6070708,5.02470776 C19.6070708,5.02470776 16.7641865,7.89044938 16.4741838,8.18330932 C16.184181,8.47474067 15.2027431,8.44474038 15.2027431,8.44474038 C15.2027431,8.44474038 12.607004,8.15045186 12.2312862,7.77759116 C11.859854,7.40330188 11.6027087,4.68613311 11.6027087,4.68613311 C11.6027087,4.68613311 11.5298509,3.93041161 11.9041402,3.55469375 C12.2770009,3.18040446 15.0527416,0.403235119 15.0527416,0.403235119 C15.0527416,0.403235119 15.4084593,0.00323130401 14.8927401,0.000374133908 C13.2284385,-0.0053402063 9.8469777,0.0332315901 8.20981923,1.67467582 L7.86981599,2.0103933 C5.97836938,3.91612576 5.81122493,6.76901011 7.25552442,8.86474439 L0.906892441,14.4862266 C0.144028023,15.249091 -0.680265552,17.5033982 0.906892441,19.0934134 C2.49405043,20.6834285 4.74121472,19.8562778 5.50122197,19.0919848 C5.50122197,19.0919848 10.0384081,13.981936 11.1398472,12.7704959 C13.2284385,14.2690816 16.1027516,14.1205088 18.0170556,12.1990619 L18.3542017,11.8619158 C19.9413597,10.2690435 19.9927887,6.91472579 19.9999661,5.21756675 C20.0042174,4.67613301 19.6070708,5.02470776 19.6070708,5.02470776 Z M3.68977612,18.1919762 C2.64833762,18.1919762 1.80690102,17.3519682 1.80690102,16.3105297 C1.80690102,15.2719483 2.64976621,14.4290832 3.68977612,14.4290832 C4.72978604,14.4290832 5.56979405,15.2733769 5.56979405,16.3105297 C5.56979405,17.3519682 4.72978604,18.1919762 3.68977612,18.1919762 Z"
                            transform="translate(10.000000, 10.000706) scale(-1, 1) translate(-10.000000, -10.000706) "
                          />
                        </g>
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </svg>
          }
        />
      ),
      dataIndex: '',
      key: 'actions',
      width: 80,
      fixed: 'right' as any,
    },
  ]

  const sceneColumns = [
    {
      title: '财务情景名称',
      dataIndex: 'name',
      key: 'name',
      fixed: 'left' as any,
      width: 150,
      render: (res: string, row: any) => (
        <EditInput
          key={res}
          name={res}
          onConfirm={(str) => {
            FinanceService.editName({
              paramListId: row.paramListId,
              root: {
                name: str,
              },
            })
              .then(() => {
                loadConfigScene()
              })
              .catch(() => {
                loadConfigScene()
              })
          }}
        />
      ),
    },
    ...configMap.map((o) => ({
      title: o.name,
      dataIndex: o.key,
      key: o.key,
      width: 150,
      // width: idx === 3 ? 190 : 150,
      render: (res: { libraryId: string; libraryName: string }, row: any) => {
        const { libraryName = '', libraryId } = res || {}
        let optionData = curCostModalData[o.key]?.map((item: any) => ({
          value: item.id,
          label: formatStr(item.name),
        }))
        optionData = optionData.filter((item: any) => !!item.name || !!item.label)
        let libraryData = library[o.key]?.map((item: any) => ({
          value: item.id,
          label: formatStr(item.name),
        }))

        let option = [
          ...optionData,
          {
            value: 'other',
            label: '从参数库添加',
            children: libraryData.length
              ? [...libraryData]
              : [
                  {
                    value: '',
                    label: (
                      <div className="ant-empty-description" style={{ pointerEvents: 'none', cursor: 'not-allowed' }}>
                        暂无数据
                      </div>
                    ),
                  },
                ],
          },
        ]

        let name = formatStr(libraryName || '')
        let num = libraryName.replace(ZH_EXP, '**').length
        return (
          <>
            <Button
              type="default"
              style={{ width: '80%', position: 'relative', zIndex: 2 }}
              className={libraryName ? 'edit-config-button' : ''}
              onClick={() => {
                // getLibraryData(false)
              }}
            >
              {libraryName ? (
                num >= 12 ? (
                  <Tooltip title={libraryName}>
                    <span>{name}</span>
                  </Tooltip>
                ) : (
                  <span>{libraryName}</span>
                )
              ) : (
                <span></span>
              )}
            </Button>
            <Cascader
              options={option}
              style={{ width: '100%' }}
              onChange={(val: any) => {
                // 从其他参数中选择
                if (val.length > 1) {
                  if (!val[val.length - 1]) return
                  addSingleFinancial({ id: val[val.length - 1], type: o.key, row, o })
                } else {
                  if (libraryId === val[val.length - 1]) return
                  if (row.relationFinanceList.filter((o: any) => o.calcStatus === 2)?.length > 0) {
                    Modal.confirm({
                      keyboard: false,
                      title: '修改财务情景',
                      content: (
                        <>
                          <p>{`确认修改${row.name}？此操作将导致以下财务方案失效`}</p>
                          <List
                            style={{
                              maxHeight: 300,
                              overflowY: 'auto',
                              paddingRight: 5,
                            }}
                            grid={{ gutter: 10, column: 1 }}
                            dataSource={row.relationFinanceList.filter((o: any) => o.calcStatus === 2)}
                            size="small"
                            renderItem={(item: any) => (
                              <List.Item
                                style={{
                                  backgroundColor: 'rgba(249, 121, 66, 0.06)',
                                  color: 'rgba(249, 121, 66, 1)',
                                  height: 40,
                                  display: 'flex',
                                  flexDirection: 'row',
                                  justifyContent: 'space-between',
                                  alignItems: 'center',
                                }}
                              >
                                <span>{item.name}</span>
                                {item.locked && (
                                  <span style={{ fontSize: 16 }}>
                                    <CustomIcons type="klf-file-alert" />
                                  </span>
                                )}
                              </List.Item>
                            )}
                          />
                        </>
                      ),
                      onOk: () => {
                        new Promise((resolve) => {
                          const { name, projectInvest, operationCost, financeCost, awardSubsidy } = row
                          FinanceService.financeParamList2({
                            paramListId: row.paramListId,
                            root: {
                              name,
                              projectInvestId: projectInvest?.libraryId,
                              operationCostId: operationCost?.libraryId,
                              financeCostId: financeCost?.libraryId,
                              awardSubsidyId: awardSubsidy?.libraryId,
                              [`${o.key}Id`]: val[val.length - 1],
                            },
                          })
                            .then(() => {
                              loadConfigScene(() => {
                                resolve({})
                              })
                            })
                            .catch(() => {
                              resolve({})
                            })
                        })
                      },
                    })
                  } else {
                    const { name, projectInvest, operationCost, financeCost, awardSubsidy } = row
                    FinanceService.financeParamList2({
                      paramListId: row.paramListId,
                      root: {
                        name,
                        projectInvestId: projectInvest?.libraryId,
                        operationCostId: operationCost?.libraryId,
                        financeCostId: financeCost?.libraryId,
                        awardSubsidyId: awardSubsidy?.libraryId,
                        [`${o.key}Id`]: val[val.length - 1],
                      },
                    })
                      .then(() => {
                        loadConfigScene()
                      })
                      .catch(() => {})
                  }
                }
              }}
              dropdownClassName="tech-plan-cascader"
            >
              <Button
                icon={
                  <div>
                    <CustomIcons
                      type="klf-stack"
                      style={{
                        fontSize: 16,
                      }}
                    />
                    <CaretDownOutlined
                      style={{
                        fontSize: 10,
                      }}
                    />
                  </div>
                }
                type="link"
                ghost
                onClick={() => {
                  getLibraryData(false)
                }}
                className="tech-cascader-button"
              />

              {/* <Button
                type="default"
                style={{ width: '100%' }}
                onClick={() => {
                  getLibraryData(false)
                }}
              >
                {libraryName ? (
                  name && name.length > 8 ? (
                    <Tooltip title={libraryName}>
                      <span>{name}</span>
                    </Tooltip>
                  ) : (
                    <span>{libraryName}</span>
                  )
                ) : (
                  <span></span>
                )}
              </Button> */}
            </Cascader>
          </>
        )
      },
    })),
    {
      title: '',
      dataIndex: '',
      key: '',
      width: 50,
      render: (val: any, row: any) => {
        return (
          <Button
            className="hover-display"
            type="link"
            icon={<CustomIcons type="klf-delete" />}
            onClick={() => {
              const { paramListId } = row
              Modal.confirm({
                keyboard: false,
                content: (
                  <>
                    {row.relationFinanceList.length === 0 && <p>{`确认删除${row.name}？`}</p>}
                    {row.relationFinanceList.length > 0 && <p>{`确认删除${row.name}？此操作将导致以下财务方案删除`}</p>}
                    {row.relationFinanceList.length > 0 && (
                      <List
                        style={{
                          maxHeight: 300,
                          overflowY: 'auto',
                          paddingRight: 5,
                        }}
                        grid={{ gutter: 10, column: 1 }}
                        dataSource={row.relationFinanceList}
                        size="small"
                        renderItem={(item: any) => (
                          <List.Item
                            style={{
                              backgroundColor: 'rgba(249, 121, 66, 0.06)',
                              color: 'rgba(249, 121, 66, 1)',
                              height: 40,
                              display: 'flex',
                              flexDirection: 'row',
                              justifyContent: 'space-between',
                              alignItems: 'center',
                            }}
                          >
                            <span>{item.name}</span>
                            {item.locked && (
                              <span style={{ fontSize: 16 }}>
                                <CustomIcons type="klf-file-alert" />
                              </span>
                            )}
                          </List.Item>
                        )}
                      />
                    )}
                  </>
                ),
                title: '删除财务情景',
                icon: <CustomIcons type="klf-alarm1" />,
                onOk: () => {
                  return new Promise((resolve) => {
                    FinanceService.financeParamList1({
                      paramListId,
                    })
                      .then(() => {
                        loadConfigScene(() => {
                          resolve({})
                        })
                      })
                      .catch(() => {
                        resolve({})
                      })
                  })
                },
                okButtonProps: {
                  danger: true,
                },
                cancelButtonProps: {
                  danger: true,
                  ghost: true,
                },
              })
            }}
          />
        )
      },
    },
  ]

  // 获取左侧财务情景参数
  const getProjectLibrary = (addFlag?: boolean) => {
    const FinanceApiService = new FinanceApi()
    FinanceApiService.i3060FinanceParamListGetProjectLibraryGet({ projectId: params.id! }).then((resp: any) => {
      setCurCostModalData(resp.data)
      addFlag && setCostModalVisible(true)
    })
  }

  const getConfig = () => {
    setLoading(true)
    FinanceService.getPerspectiveAnalyseQueryType()
      .then((resp) => {
        setFinTypes(resp.data)
        setSelectedFinType(resp.data[0].key)
      })
      .catch(() => {})
      .finally(() => {
        setLoading(false)
      })
    FinanceService.getLibrary().then((resp) => {
      setLibrary(resp.data)
      for (let item in resp.data) {
        resp.data[item].map((ele: any) => (ele.pid = item))
      }
      setCostModalData([
        resp.data.projectInvest,
        resp.data.operationCost,
        resp.data.financeCost,
        resp.data.awardSubsidy,
      ])
    })
    getProjectLibrary()
  }

  const getLibraryData = (addFlag?: boolean) => {
    FinanceService.getLibrary().then((resp) => {
      setLibrary(resp.data)
      for (let item in resp.data) {
        resp.data[item].map((ele: any) => (ele.pid = item))
      }
      setCostModalData([
        resp.data.projectInvest,
        resp.data.operationCost,
        resp.data.financeCost,
        resp.data.awardSubsidy,
      ])
      !!addFlag && setCostModalVisible(true)
    })
  }

  const chooseCostItem = (params: any) => {
    params.checked = !params.checked
    setCostModalData([...costModalData])
  }

  useEffect(() => {
    if (selectedFinType) {
      loadData(selectedFinType)
    }
  }, [selectedFinType])

  const loadData = (queryType?: string, ignoreLoading = false) => {
    if (!queryType) {
      return
    }
    if (!ignoreLoading) {
      setLoading(true)
    }
    FinanceService.perspectiveAnalyse({
      projectId: params.id!,
      queryType,
    })
      .then((resp) => {
        const { x, y } = resp.data
        const res: any[] = []
        y.forEach((o: any) => {
          const { id, infos, name } = o
          const map: any = {}
          infos?.forEach((oo: any, idx: number) => {
            const { id, value, name } = oo
            if (oo.calcStatus === 1) {
              queryArr.push(oo.id)
            }
            map[x[idx].name] = { ...oo, value: value !== name ? parseFloat(value) : undefined, name, id }
          })
          res.push({ id, name, ...map })
        })
        setX(x.map((o: any) => o.id))
        setRows(x.map((o: any) => o.name))
        if (queryType !== 'name') {
          const rr = y
            .map((o: any) => o.infos)
            .flat()
            .map((o: any) => o.value)
          setMax(Math.max(...rr))
          setMin(Math.min(...rr))
        } else {
          setMax(undefined)
          setMin(undefined)
        }
        setPlanListDataSource(res)
      })
      .catch(() => {})
      .finally(() => {
        setLoading(false)
      })
  }

  const formatCostDataSubmit = (chooseItem: any[]) => {
    const FinanceApiService = new FinanceApi()
    let costObj: any = {
      projectInvest: [],
      operationCost: [],
      financeCost: [],
      awardSubsidy: [],
    }
    chooseItem.forEach((item) => {
      costObj[item.pid] = costObj[item.pid].concat([item.id])
    })
    let obj = {
      projectId: params.id,
      ...costObj,
    }
    FinanceApiService.i3060FinanceParamListImportLibraryPost({ ...obj }).then(() => {
      getProjectLibrary()
    })
  }

  const addSingleFinancial = ({ id, type, row, o }: { id: string; type: string; row: any; o: any }) => {
    const FinanceApiService = new FinanceApi()
    let typeMap: any = {
      projectInvestId: 1,
      operationCostId: 2,
      financeCostId: 3,
      awardSubsidyId: 4,
    }
    let obj = {
      projectId: params.id || '',
      id,
      type: typeMap[`${type}Id`],
    }
    FinanceApiService.i3060FinanceParamListImportSingleLibraryPost({ ...obj })
      .then((res: any) => {
        getProjectLibrary()
        if (row.relationFinanceList.filter((o: any) => o.calcStatus === 2)?.length > 0) {
          Modal.confirm({
            keyboard: false,
            title: '修改财务情景',
            content: (
              <>
                <p>{`确认修改${row.name}？此操作将导致以下财务方案失效`}</p>
                <List
                  style={{
                    maxHeight: 300,
                    overflowY: 'auto',
                    paddingRight: 5,
                  }}
                  grid={{ gutter: 10, column: 1 }}
                  dataSource={row.relationFinanceList.filter((o: any) => o.calcStatus === 2)}
                  size="small"
                  renderItem={(item: any) => (
                    <List.Item
                      style={{
                        backgroundColor: 'rgba(249, 121, 66, 0.06)',
                        color: 'rgba(249, 121, 66, 1)',
                        height: 40,
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                      }}
                    >
                      <span>{item.name}</span>
                      {item.locked && (
                        <span style={{ fontSize: 16 }}>
                          <CustomIcons type="klf-file-alert" />
                        </span>
                      )}
                    </List.Item>
                  )}
                />
              </>
            ),
            onOk: () => {
              new Promise((resolve) => {
                const { name, projectInvest, operationCost, financeCost, awardSubsidy } = row
                FinanceService.financeParamList2({
                  paramListId: row.paramListId,
                  root: {
                    name,
                    projectInvestId: projectInvest?.libraryId,
                    operationCostId: operationCost?.libraryId,
                    financeCostId: financeCost?.libraryId,
                    awardSubsidyId: awardSubsidy?.libraryId,
                    [`${o.key}Id`]: res?.data?.id,
                  },
                })
                  .then(() => {
                    loadConfigScene(() => {
                      resolve({})
                    })
                  })
                  .catch(() => {
                    resolve({})
                  })
              })
            },
          })
        } else {
          const { name, projectInvest, operationCost, financeCost, awardSubsidy } = row
          FinanceService.financeParamList2({
            paramListId: row.paramListId,
            root: {
              name,
              projectInvestId: projectInvest?.libraryId,
              operationCostId: operationCost?.libraryId,
              financeCostId: financeCost?.libraryId,
              awardSubsidyId: awardSubsidy?.libraryId,
              [`${o.key}Id`]: res?.data?.id,
            },
          })
            .then(() => {
              loadConfigScene()
            })
            .catch(() => {})
        }
      })
      .catch(() => {})
      .finally(() => {})
  }

  // 删除模板
  const deleteConfig = ({ type, id, name }: { type: any; id: any; name: string }) => {
    const FinanceApiService = new FinanceApi()

    FinanceApiService.i3060FinanceParamListCheckProjectLibraryPost({
      projectId: params.id || '',
      type,
      id,
    }).then((res: any) => {
      const { data } = res
      // 0 没有被引用、1 有计算结果、2 有被引用
      Modal.confirm({
        title: <strong>删除参数</strong>,
        className: 'danger-modal',
        keyboard: false,
        autoFocusButton: null,
        content:
          data === 0
            ? `确认删除【${name}】?`
            : `确定删除【${name}】？此操作将删除财务情景中的相关参数，需重新配置财务情景。`,
        width: 480,
        okType: 'primary',
        okText: '确定',
        icon: <ExclamationCircleFilled style={{ color: '#f97942' }} />,
        onOk: async () => {
          FinanceApiService.i3060FinanceParamListDeleteProjectLibraryPost({
            type,
            id,
            projectId: params.id || '',
          }).then((res: any) => {
            if (res.code === 200) {
              message.success('删除成功')
              getProjectLibrary()
              loadConfigScene()
            }
          })
        },
        okButtonProps: {
          danger: true,
          style: { background: '#f97942' },
        },
        cancelButtonProps: {
          danger: true,
        },
      })
    })
  }

  const changeNameCallback = () => {
    getProjectLibrary()
    loadConfigScene()
  }

  useEffect(() => {
    setLoading(false)
    getConfig()
    setConfigMap([
      { name: '项目投资参数', key: 'projectInvest', required: true },
      { name: '运营成本参数', key: 'operationCost', required: true },
      { name: '财务成本参数', key: 'financeCost', required: true },
      { name: '激励补贴参数（非必填）', key: 'awardSubsidy' },
    ])
  }, [])
  return (
    <Layout
      style={{
        height: '100%',
        overflow: 'hidden',
      }}
    >
      <Layout.Header
        style={{
          position: 'fixed',
          zIndex: 1,
          width: '100%',
          backgroundColor: 'white',
          paddingLeft: '1rem',
          paddingRight: '1rem',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          height: 60,
          boxShadow: '0px 2px 14px 0px rgba(190, 194, 204, 0.3)',
        }}
      >
        <Button
          icon={<LeftOutlined />}
          type="text"
          onClick={() => {
            nav(`/program/detail/${params.id}/fin`)
          }}
        >
          财务方案
        </Button>
        {techData && (
          <div>
            {!editName && (
              <div>
                {techData.technologyName}
                <Button
                  type="text"
                  icon={<CustomIcons type="klf-edit1" />}
                  onClick={() => {
                    setEditName(true)
                    setTimeout(() => {
                      document.getElementById('technologyNameEditor')?.focus()
                    })
                  }}
                />
              </div>
            )}
            {editName && (
              <Input
                id="technologyNameEditor"
                allowClear
                onBlur={() => {
                  setEditName(false)
                }}
                defaultValue={techData.technologyName}
              ></Input>
            )}
          </div>
        )}
        {!configScene && (
          <Button
            icon={<PlayCircleOutlined />}
            type="primary"
            onClick={() => {
              setCalcing(true)
              FinanceService.batchCalculate({
                financeId: params.id!,
              })
                .then(() => {
                  loadData()
                  setType(1)
                  message.success('开始批量计算')
                })
                .catch(() => {})
                .finally(() => {
                  setCalcing(false)
                })
            }}
          >
            批量计算
          </Button>
        )}
      </Layout.Header>
      <Layout.Content className="workspace-layout" style={{ marginTop: 64, background: 'none' }}>
        <Spin
          spinning={loading}
          style={{
            width: '100%',
            height: '100%',
          }}
        >
          {configScene && (
            <Row
              style={{
                height: '100%',
                justifyContent: 'space-between',
              }}
            >
              <Col
                style={{
                  width: 300,
                  height: '100%',
                  paddingLeft: 14,
                }}
              >
                {!editFlag ? (
                  <Card
                    title={
                      <div
                        style={{
                          fontSize: 20,
                          color: '#262E3F',
                          fontWeight: 'bold',
                        }}
                      >
                        项目参数组
                      </div>
                    }
                    extra={
                      <Tooltip title="从参数库添加">
                        <Button
                          className="add-cost-button"
                          type="link"
                          icon={<CustomIcons type="klf-add2" />}
                          onClick={() => {
                            // getProjectLibrary(true)
                            getLibraryData(true)
                          }}
                        />
                      </Tooltip>
                    }
                    className="full-filled-card"
                    style={{
                      height: '100%',
                      overflow: 'hidden',
                      display: 'flex',
                      flexDirection: 'column',
                      borderRadius: 4,
                    }}
                    headStyle={{
                      height: 76,
                      fontSize: 20,
                      color: '#262E3F',
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      borderBottom: 'none',
                    }}
                    bodyStyle={{
                      overflowY: 'auto',
                      flexGrow: 1,
                      flexShrink: 1,
                      paddingTop: 0,
                      padding: '0',
                      // margin: '0 16px',
                    }}
                  >
                    {[
                      {
                        key: 'projectInvest',
                        name: '项目投资',
                        hoverIcon: <CustomIcons type="klf-finance-investment-selected" />,
                        normalIcon: <CustomIcons type="klf-finance-investment" />,
                      },
                      {
                        key: 'operationCost',
                        name: '运营成本',
                        hoverIcon: <CustomIcons type="klf-finance-operate-selected" />,
                        normalIcon: <CustomIcons type="klf-finance-operate" />,
                      },
                      {
                        key: 'financeCost',
                        name: '财务成本',
                        hoverIcon: <CustomIcons type="klf-finance-finance-selected" />,
                        normalIcon: <CustomIcons type="klf-finance-finance" />,
                      },
                      {
                        key: 'awardSubsidy',
                        name: '激励补贴',
                        hoverIcon: <CustomIcons type="klf-finance-allowance-selected" />,
                        normalIcon: <CustomIcons type="klf-finance-allowance" />,
                      },
                    ].map((o) => {
                      let costData = curCostModalData[o.key]
                      return (
                        <div key={o.key} className="config-section">
                          <div
                            className="config-title"
                            style={{
                              marginTop: 12,
                              paddingLeft: '16px',
                              // cursor: 'pointer',
                            }}
                            // onClick={() => {
                            //   const idx = foldCfgs.findIndex((cfg) => cfg === o.key)
                            //   if (idx >= 0) {
                            //     foldCfgs.splice(idx, 1)
                            //   } else {
                            //     foldCfgs.push(o.key)
                            //   }
                            //   setFoldCfgs([...foldCfgs])
                            // }}
                          >
                            <span className="hover-icon">{o.hoverIcon}</span>
                            <span className="normal-icon">{o.normalIcon}</span>
                            <span
                              style={{
                                marginLeft: '.5rem',
                              }}
                            >
                              {o.name}
                            </span>
                          </div>
                          {!!costData.length && (
                            <div className="config-content">
                              {
                                costData.map((oo: any) => {
                                  let name = formatStr(oo.name, 10)
                                  let num = oo.name.replace(ZH_EXP, '**').length
                                  if (!name) return null
                                  return (
                                    // foldCfgs.includes(oo.pid) && (
                                    <div key={oo.id} className="config-container">
                                      <Button
                                        type="text"
                                        className="config-tips"
                                        style={{
                                          lineHeight: 1,
                                          textAlign: 'left',
                                        }}
                                        onClick={() => {
                                          setEditConfigId(oo.id)
                                          setEditFlag(true)
                                          setEditType(o.key)
                                        }}
                                      >
                                        {num >= 20 ? (
                                          <Tooltip title={oo.name}>
                                            <span>{name}</span>
                                          </Tooltip>
                                        ) : (
                                          <>{oo.name}</>
                                        )}
                                        {/* {oo.name} */}
                                      </Button>

                                      <CustomIcons
                                        type="klf-delete"
                                        className="config-del-btn"
                                        onClick={() => {
                                          // 删除
                                          deleteConfig({
                                            type: costMapNumber[o.key],
                                            id: oo.id,
                                            name: oo.name,
                                          })
                                        }}
                                      />
                                    </div>
                                  )
                                })
                                // )
                              }
                            </div>
                          )}
                        </div>
                      )
                    })}
                  </Card>
                ) : (
                  <Card
                    title={
                      <div
                        style={{
                          fontSize: 20,
                          color: '#262E3F',
                          fontWeight: 'bold',
                          width: 200,
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                        }}
                      >
                        <CustomIcons
                          type="klf-zhankai"
                          className="zhankai-icon"
                          onClick={() => {
                            setEditFlag(false)
                          }}
                        />
                        {!!editType && editTypeMap[editType]}参数设置
                      </div>
                    }
                    className="full-filled-card"
                    style={{
                      height: '100%',
                      overflow: 'hidden',
                      display: 'flex',
                      flexDirection: 'column',
                      borderRadius: 4,
                    }}
                    headStyle={{
                      height: 76,
                      fontSize: 20,
                      color: '#262E3F',
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      borderBottom: 'none',
                    }}
                    bodyStyle={{
                      overflowY: 'auto',
                      flexGrow: 1,
                      flexShrink: 1,
                      paddingTop: 0,
                      padding: '0 16px',
                    }}
                  >
                    <ParamConfigCard
                      editType={editType}
                      editConfigId={editConfigId}
                      projectId={params.id}
                      changeNameCallback={changeNameCallback}
                    />
                  </Card>
                )}
              </Col>
              <Col
                flex="1 1"
                style={{
                  height: '100%',
                  maxWidth: 'calc(100vw - 340px)',
                }}
              >
                <Card
                  className="full-filled-card"
                  title={
                    <div
                      style={{
                        fontSize: 20,
                        color: '#262E3F',
                        fontWeight: 'bold',
                      }}
                    >
                      财务情景配置
                    </div>
                  }
                  style={{
                    height: '100%',
                    overflow: 'hidden',
                    display: 'flex',
                    flexDirection: 'column',
                    borderRadius: 4,
                  }}
                  headStyle={{
                    height: 76,
                    fontSize: 20,
                    color: '#262E3F',
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    borderBottom: 'none',
                  }}
                  bodyStyle={{
                    overflowY: 'auto',
                    flexGrow: 1,
                    flexShrink: 1,
                    paddingTop: 0,
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <div
                    style={{
                      // flexGrow: 1,
                      overflow: 'hidden',
                    }}
                  >
                    <Table
                      className="no-border"
                      scroll={{ x: '100%', y: 'calc(100vh - 320px)' }}
                      dataSource={configSceneDataSource}
                      columns={sceneColumns}
                      pagination={false}
                    />
                    {!configSceneDataSource ||
                      (configSceneDataSource.length < Global.role.financeParamNum && (
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                          }}
                        >
                          <div
                            style={{
                              height: 1,
                              flexGrow: 1,
                              backgroundColor: '#EBEBEB',
                            }}
                          />
                          <Button
                            icon={<PlusCircleFilled />}
                            type="link"
                            onClick={() => {
                              FinanceService.financeParamList({
                                projectId: params.id!,
                              })
                                .then(() => {
                                  loadConfigScene()
                                })
                                .catch(() => {})
                            }}
                          >
                            添加情景配置
                          </Button>
                          <div
                            style={{
                              height: 1,
                              flexGrow: 1,
                              backgroundColor: '#EBEBEB',
                            }}
                          />
                        </div>
                      ))}
                  </div>
                  <div
                    style={{
                      paddingTop: '1rem',
                    }}
                  >
                    <Button
                      type="primary"
                      style={{
                        marginLeft: '.5rem',
                      }}
                      onClick={() => {
                        setConfigSceneDataSource([])
                        setConfigScene(false)
                      }}
                    >
                      返回
                    </Button>
                  </div>
                </Card>
              </Col>
            </Row>
          )}

          {!configScene && (
            <Card
              className="full-filled-card no-border-bottom-card"
              title={
                <div
                  style={{
                    fontSize: 20,
                    color: '#262E3F',
                    fontWeight: 'bold',
                  }}
                >
                  方案列表
                </div>
              }
              style={{
                height: '100%',
                overflow: 'hidden',
                display: 'flex',
                flexDirection: 'column',
                borderRadius: 4,
              }}
              headStyle={{
                color: '#262E3F',
                height: 76,
                padding: '24px !important',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
              bodyStyle={{
                paddingTop: 0,
                overflowY: 'auto',
                flexGrow: 1,
                flexShrink: 1,
              }}
              extra={
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                  }}
                >
                  {type === 0 && <span>选择查看指标</span>}
                  {type === 0 && finTypes.length > 0 && (
                    <Select
                      style={{ marginLeft: 10, marginRight: 10, width: 180 }}
                      value={selectedFinType}
                      onChange={(val) => {
                        setSelectedFinType(val)
                      }}
                    >
                      {finTypes.map((o) => (
                        <Select.Option value={o.key} key={o.key}>
                          {o.name}
                        </Select.Option>
                      ))}
                    </Select>
                  )}
                  <Button
                    style={{
                      width: 120,
                      marginRight: 10,
                    }}
                    icon={<UnorderedListOutlined />}
                    onClick={() => {
                      setType(type == 0 ? 1 : 0)
                    }}
                  >
                    {type === 1 ? '透视分析' : '结果对比'}
                  </Button>
                  <Button
                    type="primary"
                    style={{
                      width: 120,
                    }}
                    onClick={() => {
                      setConfigScene(true)
                    }}
                  >
                    配置财务情景
                  </Button>
                </div>
              }
            >
              <Spin spinning={calcing}>
                {!calcing && (
                  <div>
                    {type === 0 && <Table dataSource={planListDataSource} columns={columns} pagination={false} />}
                    {/*{type === 1 && <Table dataSource={compareDataSource} columns={resultColumns} pagination={false} />}*/}
                    {type === 1 && <FinanceList timeSort={[]} scrollHeight={'calc(100vh - 300px)'} hasAuth={hasAuth} />}
                  </div>
                )}
              </Spin>
            </Card>
          )}
          {costModalVisible && (
            <CostModal
              visible={costModalVisible}
              onCancel={() => {
                setCostModalVisible(false)
              }}
              dataSource={costModalData}
              chooseCostItem={chooseCostItem}
              onOk={(chooseItem: []) => {
                setCostModalVisible(false)
                formatCostDataSubmit(chooseItem)
              }}
              curCostModalData={curCostModalData}
            />
          )}
        </Spin>
      </Layout.Content>
    </Layout>
  )
}
