class LocationState {
  private contract = 'i3060.contract'
  private projectLib = 'i3060.project.lib'

  setContract(isEngineering: boolean) {
    localStorage.setItem(this.contract, JSON.stringify({ engineering: isEngineering }))
  }

  getContract() {
    try {
      return JSON.parse(localStorage.getItem(this.contract))?.engineering ?? false
    } catch (e) {
      return false
    }
  }

  removeContract() {
    localStorage.removeItem(this.contract)
  }

  setProjectLib(isEngineering: boolean) {
    localStorage.setItem(this.projectLib, JSON.stringify({ engineering: isEngineering }))
  }

  getProjectLib() {
    try {
      return JSON.parse(localStorage.getItem(this.projectLib))?.engineering ?? false
    } catch (e) {
      return false
    }
  }

  removeProjectLib() {
    localStorage.removeItem(this.projectLib)
  }
}

export default new LocationState()
