/**
 * project
 * unknown
 *
 * OpenAPI spec version: last
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import ajax, { AjaxPromise, ExtraFetchParams } from '@ajax';
import * as models from '../model/models';

/* tslint:disable:no-unused-variable member-ordering object-literal-shorthand */

/**
  * @description i3060TechnicalAddProjectIdPost参数
  * @property `projectId` 项目id
  */
export interface Paramsi3060TechnicalAddProjectIdPost {
  // pathParams
  /**
   * 项目id
   */
  projectId: string;
}
/**
  * @description i3060TechnicalAllGet headers 参数
  * @property `Authorization` Authorization (Only:undefined)
  */
export interface ParamsHeaderi3060TechnicalAllGet {
  /** Authorization (Only:undefined) */
  'Authorization': string;
}
/**
  * @description i3060TechnicalAllGet参数
  * @property `createTimeSort` 创建时间 0 不排序 1 asc 2 desc
  * @property `updateTimeSort` 更新时间 0 不排序 1 asc 2 desc
  * @property `[marked]` marked 星标 不传为全部  0:否 1:是
  * @property `[locked]` locked 归档 不传为全部  0:否 1:是
  * @property `[page]` 默认1
  * @property `[name]` 技术方案名称
  * @property `[projectName]` 项目名称
  * @property `[creatorUserName]` 创建人行嘛
  * @property `calcStatus` 0:未计算 1:计算中 2:计算完成 3: 计算失败 4:计算失效 多个用逗号隔开
  * @property `size` 分页
  */
export interface Paramsi3060TechnicalAllGet {
  // queryParams
  /**
   * 创建时间 0 不排序 1 asc 2 desc
   */
  createTimeSort: string;
  /**
   * 更新时间 0 不排序 1 asc 2 desc
   */
  updateTimeSort: string;
  /**
   * marked 星标 不传为全部  0:否 1:是
   */
  marked?: string;
  /**
   * locked 归档 不传为全部  0:否 1:是
   */
  locked?: string;
  /**
   * 默认1
   */
  page?: string;
  /**
   * 技术方案名称
   */
  name?: string;
  /**
   * 项目名称
   */
  projectName?: string;
  /**
   * 创建人行嘛
   */
  creatorUserName?: string;
  /**
   * 0:未计算 1:计算中 2:计算完成 3: 计算失败 4:计算失效 多个用逗号隔开
   */
  calcStatus: string;
  /**
   * 分页
   */
  size: string;
}
/**
  * @description i3060TechnicalCopyTechnicalIdPost参数
  * @property `technicalId` 方案id
  */
export interface Paramsi3060TechnicalCopyTechnicalIdPost {
  // pathParams
  /**
   * 方案id
   */
  technicalId: string;
}
/**
  */
export type ParamsBodyi3060TechnicalCopyTechnicalIdPost = any;
/**
  * @description i3060TechnicalListProjectIdGet headers 参数
  * @property `Authorization` Authorization (Only:undefined)
  */
export interface ParamsHeaderi3060TechnicalListProjectIdGet {
  /** Authorization (Only:undefined) */
  'Authorization': string;
}
/**
  * @description i3060TechnicalListProjectIdGet参数
  * @property `projectId` 项目id
  * @property `createTimeSort` 创建时间 0 不排序 1 asc 2 desc
  * @property `updateTimeSort` 更新时间 0 不排序 1 asc 2 desc
  * @property `[marked]` marked 星标 不传为全部  0:否 1:是
  * @property `[locked]` locked 归档 不传为全部  0:否 1:是
  * @property `[installedCapacityMin]` 装机容量区间
  * @property `[installedCapacityMax]` 装机容量区间
  * @property `[dayAvgPowerGenerationMin]` 日均发电区间
  * @property `[dayAvgPowerGenerationMax]` 日均发电区间
  * @property `[useHourYearMin]` 年使用小时区间
  * @property `[useHourYearMax]` 年使用小时区间
  * @property `[selfAbsorptionRateMin]` 自消纳百分比区间
  * @property `[selfAbsorptionRateMax]` 自消纳百分比区间
  * @property `[batteryCapacityMin]` 电池容量区间
  * @property `[batteryCapacityMax]` 电池容量区间
  * @property `[pcsPowerMin]` pcs功率区间
  * @property `[pcsPowerMax]` pcs功率区间
  * @property `[dayAvgDischargeMin]` 日均放电区间
  * @property `[dayAvgDischargeMax]` 日均放电区间
  * @property `[dayAvgChargeDischargeMin]` 日均充放电区间
  * @property `[dayAvgChargeDischargeMax]` 日均充放电区间
  * @property `[page]` 默认1
  * @property `[size]` 默认10 最大50
  * @property `installedCapacitySort` 装机容量 0 不排序 1 asc 2 desc
  * @property `dayAvgPowerGenerationSort` 日均发电 0 不排序 1 asc 2 desc
  * @property `useHourYearSort` 年利用小时数 0 不排序 1 asc 2 desc
  * @property `selfAbsorptionRateSort` 自消纳比 0 不排序 1 asc 2 desc
  * @property `batteryCapacitySort` 电池容量 0 不排序 1 asc 2 desc
  * @property `pcsPowerSort` psc总功率 0 不排序 1 asc 2 desc
  * @property `dayAvgDischargeSort` 日均放电区间 0 不排序 1 asc 2 desc
  * @property `dayAvgChargeDischargeSort` 日均充放 0 不排序 1 asc 2 desc
  * @property `markedSort` 星标 0 不排序 1 asc 2 desc
  * @property `lockedSort` 归档 0 不排序 1 asc 2 desc
  * @property `creatorNameSort` 创建人 0 不排序 1 asc 2 desc
  * @property `annualGenerationHoursSort` 年发电小时数 排序0 不排序 1 asc 2 desc
  * @property `annualGenerationHoursMin` 年发电小时数 区间最小
  * @property `annualGenerationHoursMax` 年发电小时数  区间最大
  */
export interface Paramsi3060TechnicalListProjectIdGet {
  // pathParams
  /**
   * 项目id
   */
  projectId: string;
  // queryParams
  /**
   * 创建时间 0 不排序 1 asc 2 desc
   */
  createTimeSort: string;
  /**
   * 更新时间 0 不排序 1 asc 2 desc
   */
  updateTimeSort: string;
  /**
   * marked 星标 不传为全部  0:否 1:是
   */
  marked?: string;
  /**
   * locked 归档 不传为全部  0:否 1:是
   */
  locked?: string;
  /**
   * 装机容量区间
   */
  installedCapacityMin?: string;
  /**
   * 装机容量区间
   */
  installedCapacityMax?: string;
  /**
   * 日均发电区间
   */
  dayAvgPowerGenerationMin?: string;
  /**
   * 日均发电区间
   */
  dayAvgPowerGenerationMax?: string;
  /**
   * 年使用小时区间
   */
  useHourYearMin?: string;
  /**
   * 年使用小时区间
   */
  useHourYearMax?: string;
  /**
   * 自消纳百分比区间
   */
  selfAbsorptionRateMin?: string;
  /**
   * 自消纳百分比区间
   */
  selfAbsorptionRateMax?: string;
  /**
   * 电池容量区间
   */
  batteryCapacityMin?: string;
  /**
   * 电池容量区间
   */
  batteryCapacityMax?: string;
  /**
   * pcs功率区间
   */
  pcsPowerMin?: string;
  /**
   * pcs功率区间
   */
  pcsPowerMax?: string;
  /**
   * 日均放电区间
   */
  dayAvgDischargeMin?: string;
  /**
   * 日均放电区间
   */
  dayAvgDischargeMax?: string;
  /**
   * 日均充放电区间
   */
  dayAvgChargeDischargeMin?: string;
  /**
   * 日均充放电区间
   */
  dayAvgChargeDischargeMax?: string;
  /**
   * 默认1
   */
  page?: string;
  /**
   * 默认10 最大50
   */
  size?: string;
  /**
   * 装机容量 0 不排序 1 asc 2 desc
   */
  installedCapacitySort: string;
  /**
   * 日均发电 0 不排序 1 asc 2 desc
   */
  dayAvgPowerGenerationSort: string;
  /**
   * 年利用小时数 0 不排序 1 asc 2 desc
   */
  useHourYearSort: string;
  /**
   * 自消纳比 0 不排序 1 asc 2 desc
   */
  selfAbsorptionRateSort: string;
  /**
   * 电池容量 0 不排序 1 asc 2 desc
   */
  batteryCapacitySort: string;
  /**
   * psc总功率 0 不排序 1 asc 2 desc
   */
  pcsPowerSort: string;
  /**
   * 日均放电区间 0 不排序 1 asc 2 desc
   */
  dayAvgDischargeSort: string;
  /**
   * 日均充放 0 不排序 1 asc 2 desc
   */
  dayAvgChargeDischargeSort: string;
  /**
   * 星标 0 不排序 1 asc 2 desc
   */
  markedSort: string;
  /**
   * 归档 0 不排序 1 asc 2 desc
   */
  lockedSort: string;
  /**
   * 创建人 0 不排序 1 asc 2 desc
   */
  creatorNameSort: string;
  /**
   * 年发电小时数 排序0 不排序 1 asc 2 desc
   */
  annualGenerationHoursSort: string;
  /**
   * 年发电小时数 区间最小
   */
  annualGenerationHoursMin: string;
  /**
   * 年发电小时数  区间最大
   */
  annualGenerationHoursMax: string;
}
/**
  * @description i3060TechnicalLockTechnicalIdPatch参数
  * @property `technicalId` 方案id
  */
export interface Paramsi3060TechnicalLockTechnicalIdPatch {
  // pathParams
  /**
   * 方案id
   */
  technicalId: string;
}
/**
  */
export type ParamsBodyi3060TechnicalLockTechnicalIdPatch = models.I3060TechnicalLockTechnicalIdBody;
/**
  * @description i3060TechnicalMarkTechnicalIdPatch headers 参数
  * @property `Authorization` Authorization (Only:undefined)
  */
export interface ParamsHeaderi3060TechnicalMarkTechnicalIdPatch {
  /** Authorization (Only:undefined) */
  'Authorization': string;
}
/**
  * @description i3060TechnicalMarkTechnicalIdPatch参数
  * @property `technicalId` 方案id
  */
export interface Paramsi3060TechnicalMarkTechnicalIdPatch {
  // pathParams
  /**
   * 方案id
   */
  technicalId: string;
}
/**
  */
export type ParamsBodyi3060TechnicalMarkTechnicalIdPatch = models.I3060TechnicalMarkTechnicalIdBody;
/**
  * @description i3060TechnicalParamLibrary0CreateOrEditBatchPost headers 参数
  * @property `Authorization` Authorization (Only:undefined)
  */
export interface ParamsHeaderi3060TechnicalParamLibrary0CreateOrEditBatchPost {
  /** Authorization (Only:undefined) */
  'Authorization': string;
}
/**
  * @description i3060TechnicalParamLibrary0CreateOrEditBatchPost参数
  * @property `code` 短信验证码（不知道要不要）
  */
export interface Paramsi3060TechnicalParamLibrary0CreateOrEditBatchPost {
  // queryParams
  /**
   * 短信验证码（不知道要不要）
   */
  code: string;
}
/**
  */
export type ParamsBodyi3060TechnicalParamLibrary0CreateOrEditBatchPost = Array<models.I3060TechnicalParamLibrary0CreateOrEditBatchBody>;
/**
  * @description i3060TechnicalParamLibrary0DefaultLibraryIdPut headers 参数
  * @property `Authorization` Authorization (Only:undefined)
  */
export interface ParamsHeaderi3060TechnicalParamLibrary0DefaultLibraryIdPut {
  /** Authorization (Only:undefined) */
  'Authorization': string;
}
/**
  * @description i3060TechnicalParamLibrary0DefaultLibraryIdPut参数
  * @property `libraryId` 参数库id
  */
export interface Paramsi3060TechnicalParamLibrary0DefaultLibraryIdPut {
  // pathParams
  /**
   * 参数库id
   */
  libraryId: string;
}
/**
  */
export type ParamsBodyi3060TechnicalParamLibrary0DefaultLibraryIdPut = models.I3060TechnicalParamLibrary0DefaultLibraryIdBody;
/**
  * @description i3060TechnicalParamLibrary0GetDefaultGet headers 参数
  * @property `Authorization` Authorization (Only:undefined)
  */
export interface ParamsHeaderi3060TechnicalParamLibrary0GetDefaultGet {
  /** Authorization (Only:undefined) */
  'Authorization': string;
}
/**
  * @description i3060TechnicalParamLibrary0GetDefaultGet参数
  */
export interface Paramsi3060TechnicalParamLibrary0GetDefaultGet {
}
/**
  * @description i3060TechnicalParamLibrary0GetInfoLibraryIdGet headers 参数
  * @property `Authorization` Authorization (Only:undefined)
  */
export interface ParamsHeaderi3060TechnicalParamLibrary0GetInfoLibraryIdGet {
  /** Authorization (Only:undefined) */
  'Authorization': string;
}
/**
  * @description i3060TechnicalParamLibrary0GetInfoLibraryIdGet参数
  * @property `libraryId` 参数库id
  */
export interface Paramsi3060TechnicalParamLibrary0GetInfoLibraryIdGet {
  // pathParams
  /**
   * 参数库id
   */
  libraryId: string;
}
/**
  * @description i3060TechnicalParamLibrary0GetListGet headers 参数
  * @property `Authorization` Authorization (Only:undefined)
  */
export interface ParamsHeaderi3060TechnicalParamLibrary0GetListGet {
  /** Authorization (Only:undefined) */
  'Authorization': string;
}
/**
  * @description i3060TechnicalParamLibrary0GetListGet参数
  */
export interface Paramsi3060TechnicalParamLibrary0GetListGet {
}
/**
  * @description i3060TechnicalParamLibrary1CreateOrEditBatchPost headers 参数
  * @property `Authorization` Authorization (Only:undefined)
  */
export interface ParamsHeaderi3060TechnicalParamLibrary1CreateOrEditBatchPost {
  /** Authorization (Only:undefined) */
  'Authorization': string;
}
/**
  * @description i3060TechnicalParamLibrary1CreateOrEditBatchPost参数
  * @property `code` 短信验证码（不知道要不要）
  */
export interface Paramsi3060TechnicalParamLibrary1CreateOrEditBatchPost {
  // queryParams
  /**
   * 短信验证码（不知道要不要）
   */
  code: string;
}
/**
  */
export type ParamsBodyi3060TechnicalParamLibrary1CreateOrEditBatchPost = Array<models.I3060TechnicalParamLibrary1CreateOrEditBatchBody>;
/**
  * @description i3060TechnicalParamLibrary1DefaultLibraryIdPut headers 参数
  * @property `Authorization` Authorization (Only:undefined)
  */
export interface ParamsHeaderi3060TechnicalParamLibrary1DefaultLibraryIdPut {
  /** Authorization (Only:undefined) */
  'Authorization': string;
}
/**
  * @description i3060TechnicalParamLibrary1DefaultLibraryIdPut参数
  * @property `libraryId` 参数库id
  */
export interface Paramsi3060TechnicalParamLibrary1DefaultLibraryIdPut {
  // pathParams
  /**
   * 参数库id
   */
  libraryId: string;
}
/**
  */
export type ParamsBodyi3060TechnicalParamLibrary1DefaultLibraryIdPut = models.I3060TechnicalParamLibrary1DefaultLibraryIdBody;
/**
  * @description i3060TechnicalParamLibrary1GetDefaultGet headers 参数
  * @property `Authorization` Authorization (Only:undefined)
  */
export interface ParamsHeaderi3060TechnicalParamLibrary1GetDefaultGet {
  /** Authorization (Only:undefined) */
  'Authorization': string;
}
/**
  * @description i3060TechnicalParamLibrary1GetDefaultGet参数
  */
export interface Paramsi3060TechnicalParamLibrary1GetDefaultGet {
}
/**
  * @description i3060TechnicalParamLibrary1GetInfoLibraryIdGet headers 参数
  * @property `Authorization` Authorization (Only:undefined)
  */
export interface ParamsHeaderi3060TechnicalParamLibrary1GetInfoLibraryIdGet {
  /** Authorization (Only:undefined) */
  'Authorization': string;
}
/**
  * @description i3060TechnicalParamLibrary1GetInfoLibraryIdGet参数
  * @property `libraryId` 参数库id
  */
export interface Paramsi3060TechnicalParamLibrary1GetInfoLibraryIdGet {
  // pathParams
  /**
   * 参数库id
   */
  libraryId: string;
}
/**
  * @description i3060TechnicalParamLibrary1GetListGet headers 参数
  * @property `Authorization` Authorization (Only:undefined)
  */
export interface ParamsHeaderi3060TechnicalParamLibrary1GetListGet {
  /** Authorization (Only:undefined) */
  'Authorization': string;
}
/**
  * @description i3060TechnicalParamLibrary1GetListGet参数
  */
export interface Paramsi3060TechnicalParamLibrary1GetListGet {
}
/**
  * @description i3060TechnicalParamLibraryGetNameIdListGet headers 参数
  * @property `Authorization` Authorization (Only:undefined)
  */
export interface ParamsHeaderi3060TechnicalParamLibraryGetNameIdListGet {
  /** Authorization (Only:undefined) */
  'Authorization': string;
}
/**
  * @description i3060TechnicalParamLibraryGetNameIdListGet参数
  */
export interface Paramsi3060TechnicalParamLibraryGetNameIdListGet {
}
/**
  * @description i3060TechnicalRenameTechnicalIdPatch headers 参数
  * @property `Authorization` Authorization (Only:undefined)
  */
export interface ParamsHeaderi3060TechnicalRenameTechnicalIdPatch {
  /** Authorization (Only:undefined) */
  'Authorization': string;
}
/**
  * @description i3060TechnicalRenameTechnicalIdPatch参数
  * @property `technicalId` 技术方案id
  */
export interface Paramsi3060TechnicalRenameTechnicalIdPatch {
  // pathParams
  /**
   * 技术方案id
   */
  technicalId: string;
}
/**
  */
export type ParamsBodyi3060TechnicalRenameTechnicalIdPatch = models.I3060TechnicalRenameTechnicalIdBody;
/**
  * @description i3060TechnicalTechnicalIdDelete headers 参数
  * @property `Authorization` Authorization (Only:undefined)
  */
export interface ParamsHeaderi3060TechnicalTechnicalIdDelete {
  /** Authorization (Only:undefined) */
  'Authorization': string;
}
/**
  * @description i3060TechnicalTechnicalIdDelete参数
  * @property `technicalId` 方案id
  */
export interface Paramsi3060TechnicalTechnicalIdDelete {
  // pathParams
  /**
   * 方案id
   */
  technicalId: string;
}
/**
  * @description i3060TechnicalTechnicalIdGet参数
  * @property `technicalId` 
  */
export interface Paramsi3060TechnicalTechnicalIdGet {
  // pathParams
  /**
   * 
   */
  technicalId: string;
}

export class TechnologyApi {
  protected $basePath = '/api'.replace(/\/$/, '');

  public constructor(basePath?: string) {
    if (basePath !== undefined) {
      this.$basePath = basePath.replace(/\/$/, '');
    }
  }

  /**
   * 
   * @summary 新建技术方案工作台
   * @param params Paramsi3060TechnicalAddProjectIdPost
   
   * @param opt ajax config
   * @returns models.I3060TechnicalAddProjectId
   */
  public i3060TechnicalAddProjectIdPost = (
    params: Paramsi3060TechnicalAddProjectIdPost,
    opt?: ExtraFetchParams
  ) : AjaxPromise<models.I3060TechnicalAddProjectId>  => {
    const {
    projectId,
} = params;
    const url = this.$basePath + `/i3060/technical/add/${projectId}`;
    const p: any = {};
    ajax.check(params.projectId, 'projectId');
    return ajax.ajax({
      ...opt,
      method: 'POST',
      url,
      ...p
    });
  }
  /**
   * 
   * @summary 跨项目技术方案列表
   * @param params Paramsi3060TechnicalAllGet
   
   * @param opt ajax config
   * @returns models.I3060TechnicalAll
   */
  public i3060TechnicalAllGet = (
    params: Paramsi3060TechnicalAllGet,
    opt?: { headers: ParamsHeaderi3060TechnicalAllGet } & Omit<ExtraFetchParams, 'headers'> 
  ) : AjaxPromise<models.I3060TechnicalAll>  => {
    const url = this.$basePath + `/i3060/technical/all`;
    const p: any = {};
    p.query = {};
    if ('createTimeSort' in params) p.query.createTimeSort = params.createTimeSort;
    ajax.check(params.createTimeSort, 'createTimeSort');
    if ('updateTimeSort' in params) p.query.updateTimeSort = params.updateTimeSort;
    ajax.check(params.updateTimeSort, 'updateTimeSort');
    if ('marked' in params) p.query.marked = params.marked;
    if ('locked' in params) p.query.locked = params.locked;
    if ('page' in params) p.query.page = params.page;
    if ('name' in params) p.query.name = params.name;
    if ('projectName' in params) p.query.projectName = params.projectName;
    if ('creatorUserName' in params) p.query.creatorUserName = params.creatorUserName;
    if ('calcStatus' in params) p.query.calcStatus = params.calcStatus;
    ajax.check(params.calcStatus, 'calcStatus');
    if ('size' in params) p.query.size = params.size;
    ajax.check(params.size, 'size');
    return ajax.ajax({
      ...opt,
      method: 'GET',
      url,
      ...p
    });
  }
  /**
   * 
   * @summary 技术方案复制
   * @param params Paramsi3060TechnicalCopyTechnicalIdPost
   * @param data: ParamsBodyi3060TechnicalCopyTechnicalIdPost
   * @param opt ajax config
   * @returns models.AuthUserSendCheckEmail
   */
  public i3060TechnicalCopyTechnicalIdPost = (
    params: Paramsi3060TechnicalCopyTechnicalIdPost,
    data: ParamsBodyi3060TechnicalCopyTechnicalIdPost,opt?: ExtraFetchParams
  ) : AjaxPromise<models.AuthUserSendCheckEmail>  => {
    const {
    technicalId,
} = params;
    const url = this.$basePath + `/i3060/technical/copy/${technicalId}`;
    const p: any = {};
    ajax.check(params.technicalId, 'technicalId');
    p.data = data;
    return ajax.ajax({
      ...opt,
      method: 'POST',
      url,
      ...p
    });
  }
  /**
   * 
   * @summary 技术方案列表
   * @param params Paramsi3060TechnicalListProjectIdGet
   
   * @param opt ajax config
   * @returns models.I3060TechnicalListProjectId
   */
  public i3060TechnicalListProjectIdGet = (
    params: Paramsi3060TechnicalListProjectIdGet,
    opt?: { headers: ParamsHeaderi3060TechnicalListProjectIdGet } & Omit<ExtraFetchParams, 'headers'> 
  ) : AjaxPromise<models.I3060TechnicalListProjectId>  => {
    const {
    projectId,
} = params;
    const url = this.$basePath + `/i3060/technical/list/${projectId}`;
    const p: any = {};
    p.query = {};
    if ('createTimeSort' in params) p.query.createTimeSort = params.createTimeSort;
    ajax.check(params.createTimeSort, 'createTimeSort');
    if ('updateTimeSort' in params) p.query.updateTimeSort = params.updateTimeSort;
    ajax.check(params.updateTimeSort, 'updateTimeSort');
    if ('marked' in params) p.query.marked = params.marked;
    if ('locked' in params) p.query.locked = params.locked;
    if ('installedCapacityMin' in params) p.query.installedCapacityMin = params.installedCapacityMin;
    if ('installedCapacityMax' in params) p.query.installedCapacityMax = params.installedCapacityMax;
    if ('dayAvgPowerGenerationMin' in params) p.query.dayAvgPowerGenerationMin = params.dayAvgPowerGenerationMin;
    if ('dayAvgPowerGenerationMax' in params) p.query.dayAvgPowerGenerationMax = params.dayAvgPowerGenerationMax;
    if ('useHourYearMin' in params) p.query.useHourYearMin = params.useHourYearMin;
    if ('useHourYearMax' in params) p.query.useHourYearMax = params.useHourYearMax;
    if ('selfAbsorptionRateMin' in params) p.query.selfAbsorptionRateMin = params.selfAbsorptionRateMin;
    if ('selfAbsorptionRateMax' in params) p.query.selfAbsorptionRateMax = params.selfAbsorptionRateMax;
    if ('batteryCapacityMin' in params) p.query.batteryCapacityMin = params.batteryCapacityMin;
    if ('batteryCapacityMax' in params) p.query.batteryCapacityMax = params.batteryCapacityMax;
    if ('pcsPowerMin' in params) p.query.pcsPowerMin = params.pcsPowerMin;
    if ('pcsPowerMax' in params) p.query.pcsPowerMax = params.pcsPowerMax;
    if ('dayAvgDischargeMin' in params) p.query.dayAvgDischargeMin = params.dayAvgDischargeMin;
    if ('dayAvgDischargeMax' in params) p.query.dayAvgDischargeMax = params.dayAvgDischargeMax;
    if ('dayAvgChargeDischargeMin' in params) p.query.dayAvgChargeDischargeMin = params.dayAvgChargeDischargeMin;
    if ('dayAvgChargeDischargeMax' in params) p.query.dayAvgChargeDischargeMax = params.dayAvgChargeDischargeMax;
    if ('page' in params) p.query.page = params.page;
    if ('size' in params) p.query.size = params.size;
    if ('installedCapacitySort' in params) p.query.installedCapacitySort = params.installedCapacitySort;
    ajax.check(params.installedCapacitySort, 'installedCapacitySort');
    if ('dayAvgPowerGenerationSort' in params) p.query.dayAvgPowerGenerationSort = params.dayAvgPowerGenerationSort;
    ajax.check(params.dayAvgPowerGenerationSort, 'dayAvgPowerGenerationSort');
    if ('useHourYearSort' in params) p.query.useHourYearSort = params.useHourYearSort;
    ajax.check(params.useHourYearSort, 'useHourYearSort');
    if ('selfAbsorptionRateSort' in params) p.query.selfAbsorptionRateSort = params.selfAbsorptionRateSort;
    ajax.check(params.selfAbsorptionRateSort, 'selfAbsorptionRateSort');
    if ('batteryCapacitySort' in params) p.query.batteryCapacitySort = params.batteryCapacitySort;
    ajax.check(params.batteryCapacitySort, 'batteryCapacitySort');
    if ('pcsPowerSort' in params) p.query.pcsPowerSort = params.pcsPowerSort;
    ajax.check(params.pcsPowerSort, 'pcsPowerSort');
    if ('dayAvgDischargeSort' in params) p.query.dayAvgDischargeSort = params.dayAvgDischargeSort;
    ajax.check(params.dayAvgDischargeSort, 'dayAvgDischargeSort');
    if ('dayAvgChargeDischargeSort' in params) p.query.dayAvgChargeDischargeSort = params.dayAvgChargeDischargeSort;
    ajax.check(params.dayAvgChargeDischargeSort, 'dayAvgChargeDischargeSort');
    if ('markedSort' in params) p.query.markedSort = params.markedSort;
    ajax.check(params.markedSort, 'markedSort');
    if ('lockedSort' in params) p.query.lockedSort = params.lockedSort;
    ajax.check(params.lockedSort, 'lockedSort');
    if ('creatorNameSort' in params) p.query.creatorNameSort = params.creatorNameSort;
    ajax.check(params.creatorNameSort, 'creatorNameSort');
    if ('annualGenerationHoursSort' in params) p.query.annualGenerationHoursSort = params.annualGenerationHoursSort;
    ajax.check(params.annualGenerationHoursSort, 'annualGenerationHoursSort');
    if ('annualGenerationHoursMin' in params) p.query.annualGenerationHoursMin = params.annualGenerationHoursMin;
    ajax.check(params.annualGenerationHoursMin, 'annualGenerationHoursMin');
    if ('annualGenerationHoursMax' in params) p.query.annualGenerationHoursMax = params.annualGenerationHoursMax;
    ajax.check(params.annualGenerationHoursMax, 'annualGenerationHoursMax');
    ajax.check(params.projectId, 'projectId');
    return ajax.ajax({
      ...opt,
      method: 'GET',
      url,
      ...p
    });
  }
  /**
   * 
   * @summary 技术方案归档
   * @param params Paramsi3060TechnicalLockTechnicalIdPatch
   * @param data: ParamsBodyi3060TechnicalLockTechnicalIdPatch
   * @param opt ajax config
   * @returns models.AuthUserSendCheckEmail
   */
  public i3060TechnicalLockTechnicalIdPatch = (
    params: Paramsi3060TechnicalLockTechnicalIdPatch,
    data: ParamsBodyi3060TechnicalLockTechnicalIdPatch,opt?: ExtraFetchParams
  ) : AjaxPromise<models.AuthUserSendCheckEmail>  => {
    const {
    technicalId,
} = params;
    const url = this.$basePath + `/i3060/technical/lock/${technicalId}`;
    const p: any = {};
    ajax.check(params.technicalId, 'technicalId');
    p.data = data;
    return ajax.ajax({
      ...opt,
      method: 'PATCH',
      url,
      ...p
    });
  }
  /**
   * 
   * @summary 标记技术方案
   * @param params Paramsi3060TechnicalMarkTechnicalIdPatch
   * @param data: ParamsBodyi3060TechnicalMarkTechnicalIdPatch
   * @param opt ajax config
   * @returns models.AuthUserSendCheckEmail
   */
  public i3060TechnicalMarkTechnicalIdPatch = (
    params: Paramsi3060TechnicalMarkTechnicalIdPatch,
    data: ParamsBodyi3060TechnicalMarkTechnicalIdPatch,opt?: { headers: ParamsHeaderi3060TechnicalMarkTechnicalIdPatch } & Omit<ExtraFetchParams, 'headers'> 
  ) : AjaxPromise<models.AuthUserSendCheckEmail>  => {
    const {
    technicalId,
} = params;
    const url = this.$basePath + `/i3060/technical/mark/${technicalId}`;
    const p: any = {};
    ajax.check(params.technicalId, 'technicalId');
    p.data = data;
    return ajax.ajax({
      ...opt,
      method: 'PATCH',
      url,
      ...p
    });
  }
  /**
   * 
   * @summary 添加或修改储能参数库
   * @param params Paramsi3060TechnicalParamLibrary0CreateOrEditBatchPost
   * @param data: ParamsBodyi3060TechnicalParamLibrary0CreateOrEditBatchPost
   * @param opt ajax config
   * @returns models.AuthUserUpdate
   */
  public i3060TechnicalParamLibrary0CreateOrEditBatchPost = (
    params: Paramsi3060TechnicalParamLibrary0CreateOrEditBatchPost,
    data: ParamsBodyi3060TechnicalParamLibrary0CreateOrEditBatchPost,opt?: { headers: ParamsHeaderi3060TechnicalParamLibrary0CreateOrEditBatchPost } & Omit<ExtraFetchParams, 'headers'> 
  ) : AjaxPromise<models.AuthUserUpdate>  => {
    const url = this.$basePath + `/i3060/technicalParamLibrary/0/createOrEditBatch`;
    const p: any = {};
    p.query = {};
    if ('code' in params) p.query.code = params.code;
    ajax.check(params.code, 'code');
    p.data = data;
    return ajax.ajax({
      ...opt,
      method: 'POST',
      url,
      ...p
    });
  }
  /**
   * 
   * @summary 设置储能默认
   * @param params Paramsi3060TechnicalParamLibrary0DefaultLibraryIdPut
   * @param data: ParamsBodyi3060TechnicalParamLibrary0DefaultLibraryIdPut
   * @param opt ajax config
   * @returns models.AuthUserUpdate
   */
  public i3060TechnicalParamLibrary0DefaultLibraryIdPut = (
    params: Paramsi3060TechnicalParamLibrary0DefaultLibraryIdPut,
    data: ParamsBodyi3060TechnicalParamLibrary0DefaultLibraryIdPut,opt?: { headers: ParamsHeaderi3060TechnicalParamLibrary0DefaultLibraryIdPut } & Omit<ExtraFetchParams, 'headers'> 
  ) : AjaxPromise<models.AuthUserUpdate>  => {
    const {
    libraryId,
} = params;
    const url = this.$basePath + `/i3060/technicalParamLibrary/0/default/${libraryId}`;
    const p: any = {};
    ajax.check(params.libraryId, 'libraryId');
    p.data = data;
    return ajax.ajax({
      ...opt,
      method: 'PUT',
      url,
      ...p
    });
  }
  /**
   * 
   * @summary 获取储能默认参数
   * @param params Paramsi3060TechnicalParamLibrary0GetDefaultGet
   
   * @param opt ajax config
   * @returns models.I3060TechnicalParamLibrary0GetDefault
   */
  public i3060TechnicalParamLibrary0GetDefaultGet = (
    params: Paramsi3060TechnicalParamLibrary0GetDefaultGet,
    opt?: { headers: ParamsHeaderi3060TechnicalParamLibrary0GetDefaultGet } & Omit<ExtraFetchParams, 'headers'> 
  ) : AjaxPromise<models.I3060TechnicalParamLibrary0GetDefault>  => {
    const url = this.$basePath + `/i3060/technicalParamLibrary/0/getDefault`;
    const p: any = {};
    return ajax.ajax({
      ...opt,
      method: 'GET',
      url,
      ...p
    });
  }
  /**
   * 
   * @summary 获取储能详情
   * @param params Paramsi3060TechnicalParamLibrary0GetInfoLibraryIdGet
   
   * @param opt ajax config
   * @returns models.I3060TechnicalParamLibrary0GetDefault
   */
  public i3060TechnicalParamLibrary0GetInfoLibraryIdGet = (
    params: Paramsi3060TechnicalParamLibrary0GetInfoLibraryIdGet,
    opt?: { headers: ParamsHeaderi3060TechnicalParamLibrary0GetInfoLibraryIdGet } & Omit<ExtraFetchParams, 'headers'> 
  ) : AjaxPromise<models.I3060TechnicalParamLibrary0GetDefault>  => {
    const {
    libraryId,
} = params;
    const url = this.$basePath + `/i3060/technicalParamLibrary/0/getInfo/${libraryId}`;
    const p: any = {};
    ajax.check(params.libraryId, 'libraryId');
    return ajax.ajax({
      ...opt,
      method: 'GET',
      url,
      ...p
    });
  }
  /**
   * 
   * @summary 获取储能列表
   * @param params Paramsi3060TechnicalParamLibrary0GetListGet
   
   * @param opt ajax config
   * @returns models.I3060TechnicalParamLibrary0GetList
   */
  public i3060TechnicalParamLibrary0GetListGet = (
    params: Paramsi3060TechnicalParamLibrary0GetListGet,
    opt?: { headers: ParamsHeaderi3060TechnicalParamLibrary0GetListGet } & Omit<ExtraFetchParams, 'headers'> 
  ) : AjaxPromise<models.I3060TechnicalParamLibrary0GetList>  => {
    const url = this.$basePath + `/i3060/technicalParamLibrary/0/getList`;
    const p: any = {};
    return ajax.ajax({
      ...opt,
      method: 'GET',
      url,
      ...p
    });
  }
  /**
   * 
   * @summary 添加或修改光伏参数库
   * @param params Paramsi3060TechnicalParamLibrary1CreateOrEditBatchPost
   * @param data: ParamsBodyi3060TechnicalParamLibrary1CreateOrEditBatchPost
   * @param opt ajax config
   * @returns models.AuthUserUpdate
   */
  public i3060TechnicalParamLibrary1CreateOrEditBatchPost = (
    params: Paramsi3060TechnicalParamLibrary1CreateOrEditBatchPost,
    data: ParamsBodyi3060TechnicalParamLibrary1CreateOrEditBatchPost,opt?: { headers: ParamsHeaderi3060TechnicalParamLibrary1CreateOrEditBatchPost } & Omit<ExtraFetchParams, 'headers'> 
  ) : AjaxPromise<models.AuthUserUpdate>  => {
    const url = this.$basePath + `/i3060/technicalParamLibrary/1/createOrEditBatch`;
    const p: any = {};
    p.query = {};
    if ('code' in params) p.query.code = params.code;
    ajax.check(params.code, 'code');
    p.data = data;
    return ajax.ajax({
      ...opt,
      method: 'POST',
      url,
      ...p
    });
  }
  /**
   * 
   * @summary 设置光伏默认
   * @param params Paramsi3060TechnicalParamLibrary1DefaultLibraryIdPut
   * @param data: ParamsBodyi3060TechnicalParamLibrary1DefaultLibraryIdPut
   * @param opt ajax config
   * @returns models.AuthUserUpdate
   */
  public i3060TechnicalParamLibrary1DefaultLibraryIdPut = (
    params: Paramsi3060TechnicalParamLibrary1DefaultLibraryIdPut,
    data: ParamsBodyi3060TechnicalParamLibrary1DefaultLibraryIdPut,opt?: { headers: ParamsHeaderi3060TechnicalParamLibrary1DefaultLibraryIdPut } & Omit<ExtraFetchParams, 'headers'> 
  ) : AjaxPromise<models.AuthUserUpdate>  => {
    const {
    libraryId,
} = params;
    const url = this.$basePath + `/i3060/technicalParamLibrary/1/default/${libraryId}`;
    const p: any = {};
    ajax.check(params.libraryId, 'libraryId');
    p.data = data;
    return ajax.ajax({
      ...opt,
      method: 'PUT',
      url,
      ...p
    });
  }
  /**
   * 
   * @summary 获取光伏默认参数
   * @param params Paramsi3060TechnicalParamLibrary1GetDefaultGet
   
   * @param opt ajax config
   * @returns models.I3060TechnicalParamLibrary1GetDefault
   */
  public i3060TechnicalParamLibrary1GetDefaultGet = (
    params: Paramsi3060TechnicalParamLibrary1GetDefaultGet,
    opt?: { headers: ParamsHeaderi3060TechnicalParamLibrary1GetDefaultGet } & Omit<ExtraFetchParams, 'headers'> 
  ) : AjaxPromise<models.I3060TechnicalParamLibrary1GetDefault>  => {
    const url = this.$basePath + `/i3060/technicalParamLibrary/1/getDefault`;
    const p: any = {};
    return ajax.ajax({
      ...opt,
      method: 'GET',
      url,
      ...p
    });
  }
  /**
   * 
   * @summary 获取光伏详情
   * @param params Paramsi3060TechnicalParamLibrary1GetInfoLibraryIdGet
   
   * @param opt ajax config
   * @returns models.I3060TechnicalParamLibrary1GetInfoLibraryId
   */
  public i3060TechnicalParamLibrary1GetInfoLibraryIdGet = (
    params: Paramsi3060TechnicalParamLibrary1GetInfoLibraryIdGet,
    opt?: { headers: ParamsHeaderi3060TechnicalParamLibrary1GetInfoLibraryIdGet } & Omit<ExtraFetchParams, 'headers'> 
  ) : AjaxPromise<models.I3060TechnicalParamLibrary1GetInfoLibraryId>  => {
    const {
    libraryId,
} = params;
    const url = this.$basePath + `/i3060/technicalParamLibrary/1/getInfo/${libraryId}`;
    const p: any = {};
    ajax.check(params.libraryId, 'libraryId');
    return ajax.ajax({
      ...opt,
      method: 'GET',
      url,
      ...p
    });
  }
  /**
   * 
   * @summary 获取光伏列表
   * @param params Paramsi3060TechnicalParamLibrary1GetListGet
   
   * @param opt ajax config
   * @returns models.I3060TechnicalParamLibrary1GetList
   */
  public i3060TechnicalParamLibrary1GetListGet = (
    params: Paramsi3060TechnicalParamLibrary1GetListGet,
    opt?: { headers: ParamsHeaderi3060TechnicalParamLibrary1GetListGet } & Omit<ExtraFetchParams, 'headers'> 
  ) : AjaxPromise<models.I3060TechnicalParamLibrary1GetList>  => {
    const url = this.$basePath + `/i3060/technicalParamLibrary/1/getList`;
    const p: any = {};
    return ajax.ajax({
      ...opt,
      method: 'GET',
      url,
      ...p
    });
  }
  /**
   * 
   * @summary 查询技术参数库的名称和id列表
   * @param params Paramsi3060TechnicalParamLibraryGetNameIdListGet
   
   * @param opt ajax config
   * @returns models.I3060TechnicalParamLibraryGetNameIdList
   */
  public i3060TechnicalParamLibraryGetNameIdListGet = (
    params: Paramsi3060TechnicalParamLibraryGetNameIdListGet,
    opt?: { headers: ParamsHeaderi3060TechnicalParamLibraryGetNameIdListGet } & Omit<ExtraFetchParams, 'headers'> 
  ) : AjaxPromise<models.I3060TechnicalParamLibraryGetNameIdList>  => {
    const url = this.$basePath + `/i3060/technicalParamLibrary/getNameIdList`;
    const p: any = {};
    return ajax.ajax({
      ...opt,
      method: 'GET',
      url,
      ...p
    });
  }
  /**
   * 
   * @summary 技术方案重命名
   * @param params Paramsi3060TechnicalRenameTechnicalIdPatch
   * @param data: ParamsBodyi3060TechnicalRenameTechnicalIdPatch
   * @param opt ajax config
   * @returns models.AuthUserSendCheckEmail
   */
  public i3060TechnicalRenameTechnicalIdPatch = (
    params: Paramsi3060TechnicalRenameTechnicalIdPatch,
    data: ParamsBodyi3060TechnicalRenameTechnicalIdPatch,opt?: { headers: ParamsHeaderi3060TechnicalRenameTechnicalIdPatch } & Omit<ExtraFetchParams, 'headers'> 
  ) : AjaxPromise<models.AuthUserSendCheckEmail>  => {
    const {
    technicalId,
} = params;
    const url = this.$basePath + `/i3060/technical/rename/${technicalId}`;
    const p: any = {};
    ajax.check(params.technicalId, 'technicalId');
    p.data = data;
    return ajax.ajax({
      ...opt,
      method: 'PATCH',
      url,
      ...p
    });
  }
  /**
   * 
   * @summary 删除技术方案
   * @param params Paramsi3060TechnicalTechnicalIdDelete
   
   * @param opt ajax config
   * @returns models.AuthUserSendCheckEmail
   */
  public i3060TechnicalTechnicalIdDelete = (
    params: Paramsi3060TechnicalTechnicalIdDelete,
    opt?: { headers: ParamsHeaderi3060TechnicalTechnicalIdDelete } & Omit<ExtraFetchParams, 'headers'> 
  ) : AjaxPromise<models.AuthUserSendCheckEmail>  => {
    const {
    technicalId,
} = params;
    const url = this.$basePath + `/i3060/technical/${technicalId}`;
    const p: any = {};
    ajax.check(params.technicalId, 'technicalId');
    return ajax.ajax({
      ...opt,
      method: 'DELETE',
      url,
      ...p
    });
  }
  /**
   * 
   * @summary 获取技术方案详情
   * @param params Paramsi3060TechnicalTechnicalIdGet
   
   * @param opt ajax config
   * @returns models.I3060TechnicalTechnicalId
   */
  public i3060TechnicalTechnicalIdGet = (
    params: Paramsi3060TechnicalTechnicalIdGet,
    opt?: ExtraFetchParams
  ) : AjaxPromise<models.I3060TechnicalTechnicalId>  => {
    const {
    technicalId,
} = params;
    const url = this.$basePath + `/i3060/technical/${technicalId}`;
    const p: any = {};
    ajax.check(params.technicalId, 'technicalId');
    return ajax.ajax({
      ...opt,
      method: 'GET',
      url,
      ...p
    });
  }
}

export default new TechnologyApi();