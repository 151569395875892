import React, { useEffect, useRef, useState } from 'react'
import { getAMap } from '../account-detail/components/map-script'
import { message, Spin } from 'antd'
import GeoJSON from 'geojson'
import colorUtil from '@antv/color-util'
import { Slider } from 'antd'
import { CustomIcons } from '../../global'
import { PauseOutlined, StepBackwardOutlined, StepForwardOutlined } from '@ant-design/icons'
import { EvaluationService, ProjectServiceHistory } from '../../apis/project-service-history'
import { query } from './resource-sift'

declare var window: any

let areaCodes: {
  adcode: string
  name: string
  position: number[]
}[] = []

const colorFields = [
  colorUtil.gradient(
    [
      '#FF574C',
      '#FF6B50',
      '#FF8056',
      '#FF945A',
      '#FFA860',
      '#FBBB66',
      '#C6BD86',
      '#91BFA6',
      '#5CC1C6',
      '#27C3E6',
    ].reverse()
  ),
  colorUtil.gradient(
    [
      '#FF574C',
      '#4376EF',
      '#5478E7',
      '#778CC5',
      '#767CD9',
      '#8E85C8',
      '#AA92AE',
      '#C69F95',
      '#E3AD7D',
      '#FFBB64',
    ].reverse()
  ),
  colorUtil.gradient(
    [
      '#1EC692',
      '#37C58D',
      '#50C387',
      '#69C283',
      '#82C17D',
      '#9BC078',
      '#B4BF74',
      '#CDBD6E',
      '#E6BC69',
      '#FFBB64',
    ].reverse()
  ),
]

export const colorArrs: { [key: string]: any } = {
  GHI: colorFields[0],
  DNI: colorFields[0],
  TMP: colorFields[0],
  DHI: colorFields[0],
  SUNRISE: colorFields[0],
  SUNSET: colorFields[0],
  RH: colorFields[1],
  RAINFALL: colorFields[1],
  WS: colorFields[2],
  PRES: colorFields[2],
}

const minMaxArrs: { [key: string]: any } = {
  GHI: [],
  DNI: [],
  TMP: [-20, 40],
  DHI: [],
  SUNRISE: [],
  SUNSET: [],
  RH: [],
  RAINFALL: [],
  WS: [],
  PRES: [],
}

let roofData: any

const unitMap: any = {
  GHI: 'W/㎡',
  DNI: 'W/㎡',
  DHI: 'W/㎡',
  TMP: '℃',
  WS: 'm/s',
  PRES: 'kPa',
  RH: '%',
  RAINFALL: 'mm',

  regionArea: 'k㎡',
  roofCount: '个',
  roofRatio: '‰',
  roofArea: '㎡',
  avgRoofArea: '㎡',
}

const filterSelectors = [
  {
    selectedIcon: 'klf-a-taiyangzhishebeifen14',
    icon: 'klf-a-taiyangzhishebeifen2',
    title: '太阳总辐射',
    key: 'GHI',
  },
  {
    selectedIcon: 'klf-a-taiyangzhishebeifen12',
    icon: 'klf-taiyangzhishe',
    title: '太阳直接辐射',
    key: 'DNI',
  },
  {
    selectedIcon: 'klf-a-taiyangzhishebeifen13',
    icon: 'klf-taiyangzhishebeifen',
    title: '散射辐射',
    key: 'DHI',
  },
  {
    selectedIcon: 'klf-a-taiyangzhishebeifen15',
    icon: 'klf-a-taiyangzhishebeifen3',
    title: '温度',
    key: 'TMP',
  },
  {
    selectedIcon: 'klf-a-taiyangzhishebeifen16',
    icon: 'klf-a-taiyangzhishebeifen4',
    title: '风速',
    key: 'WS',
  },
  {
    selectedIcon: 'klf-a-taiyangzhishebeifen17',
    icon: 'klf-a-taiyangzhishebeifen5',
    title: '气压',
    key: 'PRES',
  },
  {
    selectedIcon: 'klf-a-taiyangzhishebeifen18',
    icon: 'klf-a-taiyangzhishebeifen6',
    title: '相对湿度',
    key: 'RH',
  },
  {
    selectedIcon: 'klf-a-taiyangzhishebeifen19',
    icon: 'klf-a-taiyangzhishebeifen7',
    title: '降水量',
    key: 'RAINFALL',
  },
]

const roofFilterSelectors = [
  {
    selectedIcon: 'klf-a-taiyangzhishebeifen21',
    icon: 'klf-a-taiyangzhishebeifen9',
    title: '区域面积',
    key: 'regionArea',
  },
  {
    selectedIcon: 'klf-a-taiyangzhishebeifen20',
    icon: 'klf-a-taiyangzhishebeifen8',
    title: '屋顶数量',
    key: 'roofCount',
  },
  {
    selectedIcon: 'klf-a-taiyangzhishebeifen23',
    icon: 'klf-a-taiyangzhishebeifen11',
    title: '屋顶覆盖率',
    key: 'roofRatio',
  },
  {
    selectedIcon: 'klf-a-taiyangzhishebeifen21',
    icon: 'klf-a-taiyangzhishebeifen9',
    title: '屋顶总面积1234',
    key: 'roofArea',
  },
  {
    selectedIcon: 'klf-a-taiyangzhishebeifen22',
    icon: 'klf-a-taiyangzhishebeifen10',
    title: '屋顶平均面积',
    key: 'avgRoofArea',
  },
]

const monthData: any[] = []
let wxLayer: any
let customLayer: any
let roadLayer: any
let infoWindow: any
let roofPointLayer: any

let Loca: any
let AMapUI: any
let AMap: any
let loca: any
let pl: any
let roofPl: any
let polygon: any
let districtExplorer: any

const TIMES = 9

let currentLevel: string = ''

const polygonPoints: any[] = []

const marks: any = {}
for (let i = 1; i <= 12; i++) {
  marks[i] = `${i}月`
}

let cluster: any

let tle: any

const parseDom = (str: string | number) => {
  if (typeof str !== 'string') {
    return str
  }
  const strArr = str.split('^')
  if (strArr.length === 2) {
    return `<span>${strArr[0]}</span><span class="e-radix">${strArr[1]}</span>`
  } else {
    return str
  }
}

export const parseNumber = (num: any, config: {type: string, radix: number, div?: number, beginValue?: number} = { type: '', radix: -1 }) => {
  if (typeof num === 'string') {
    num = parseFloat(num)
  }
  if (!num && num !== 0) {
    return '--'
  }
  const div = config.div || 1
  num /= div;
  if (config.type === 'e') {
    if(config.beginValue !== undefined && num < config.beginValue) {
      return parseFloat(num.toFixed(config.radix));
    }
    let e = 0
    while (num > 10) {
      num /= 10
      e++
    }
    while (num < 1) {
      num *= 10
      e--
    }
    if (e === 0) {
      return parseFloat(num.toFixed(config.radix))
    }
    return `${parseFloat(num.toFixed(config.radix))}×10^${e}`
  } else if (config.radix >= 0) {
    return parseFloat((num).toFixed(config.radix))
  } else {
    return num
  }
}

export const parseSuffixNumber = (num: any, suffix: number = 2) => {
  if (parseFloat(num) > 1) {
    return parseFloat(parseFloat(num).toFixed(2))
  } else {
    const numArr = num.toString().split('.')
    const idx = /[^0]/.exec(numArr[1])!.index
    return num.toFixed(idx + suffix)
  }
}

const getInfoWindow = (obj: any) => {
  return `<div class="map-popover">
  <div class="map-popover-label">${obj.label}<div class="province-scan-button" style="display: ${
    currentLevel === 'district' ? 'block' : 'none'
  }" onclick="doScan(this)">屋顶扫描</div></div>
  <div style='display: flex; flex-direction: row; flex-wrap: wrap;'>
    <div class="map-popover-item">
      <div class="map-popover-value">${parseDom(
        parseNumber(obj.regionArea, {
          type: 'e',
          radix: 2,
        })
      )}<span class="map-popover-unit">k㎡</span></div>
      <div class="map-popover-name">区域面积</div>
    </div>
    <div class="map-popover-item">
      <div class="map-popover-value">${parseNumber(
        obj.yearTotalIrradiance
      )}<span class="map-popover-unit">kWh/㎡</span></div>
      <div class="map-popover-name">年总辐照</div>
    </div>
    <div class="map-popover-item">
      <div class="map-popover-value">${parseNumber(obj.roofCount)}<span class="map-popover-unit">个</span></div>
      <div class="map-popover-name">屋顶数量</div>
    </div>
    <div class="map-popover-item">
      <div class="map-popover-value">${parseNumber(
        parseSuffixNumber(obj.roofRatio * 1000, 2)
      )}<span class="map-popover-unit">‰</span></div>
      <div class="map-popover-name">屋顶覆盖率</div>
    </div>
    <div class="map-popover-item">
      <div class="map-popover-value">${parseNumber(obj.roofArea, {
        type: '',
        radix: 0,
      })}<span class="map-popover-unit">㎡</span></div>
      <div class="map-popover-name">屋顶总面积</div>
    </div>
    <div class="map-popover-item">
      <div class="map-popover-value">${parseNumber(obj.avgRoofArea, {
        type: '',
        radix: 2,
      })}<span class="map-popover-unit">㎡</span></div>
      <div class="map-popover-name">屋顶平均面积</div>
    </div>
  </div>
</div>`
}
;(window as any).doAdd = (id: number, name: string) => {
  EvaluationService.add({
    root: [
      {
        regionCode: (window as any).currentAdcode,
        name,
        id,
      },
    ],
  }).then(() => {
    message.success('成功添加至资源管理列表').then()
    const doms = document.getElementsByClassName('add-source')
    if (doms) {
      for (let i = 0; i < doms.length; i++) {
        const dom: any = doms[i]
        dom.innerText = '已添加'
        dom.onclick = null
      }
    }
  })
}
const createRoofDom = (obj: any) => {
  //added: false
  // area: 8817.42
  // formattedAddress: "上海市浦东新区南汇新城镇海底世界馆上海海昌海洋公园"
  // geometry: (10) [{…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}]
  // id: 979449284
  // name: "海底世界"
  return `<div class="map-popover">
  <div class="map-popover-label"><span style="overflow: hidden; white-space: nowrap; text-overflow: ellipsis;">${
    obj.name
  }</span><div class="province-scan-button add-source" onclick="if(!${obj.added}) {doAdd(${obj.id}, '${
    obj.name
  }')}" style="white-space: nowrap;">${obj.added ? '已添加' : '添加至资源管理'}</div></div>
  <div style='display: flex; flex-direction: column'>
    <div class="map-popover-item" style="display: flex; flex-direction: row;align-items: center; width: 100%;">
      <div class="map-popover-name">屋顶面积：</div>
      <div class="map-popover-value" style="margin-left: 10px;">${parseDom(
        parseNumber(obj.area, {
          type: 'c',
          radix: 2,
        })
      )}<span class="map-popover-unit">㎡</span></div>
    </div>
    <div class="map-popover-item" style="display: flex; flex-direction: row;align-items: flex-start; width: 100%;">
      <div class="map-popover-name">地址：</div>
      <div class="map-popover-name" style="margin-left: 10px; white-space: normal; text-align: left; flex-shrink: 1;">${
        obj.formattedAddress
      }</div>
    </div>
  </div>
</div>`
}

const noQueryData = () => {
  if (query.adcodes.length === 0) {
    return true
  }
  return false
}

export const ResourceMap = (props: any) => {
  const [map, setMap] = useState<any>()

  const [loading, setLoading] = useState(false)
  const [month, setMonth] = useState(0)
  const [sliderMonth, setSliderMonth] = useState(0)

  const [currentSelected, setCurrentSelected] = useState('GHI')
  const [currentRoofSelected, setCurrentRoofSelected] = useState('regionArea')

  const [openSelector, setOpenSelector] = useState(false)
  const [currentType, setCurrentType] = useState('weather')
  const [isPlaying, setIsPlaying] = useState(false)

  const [regionData, setRegionData] = useState<any>()

  const [layerType, setLayerType] = useState('road')

  const [currentRoofLevel, setCurrentRoofLevel] = useState(1)

  const [, setCurrentArea] = useState<string[]>([])
  const [clusterData, setClusterData] = useState<any[]>([])
  useEffect(() => {
    if (clusterData && !props.hideCluster && !noQueryData()) {
      cluster?.setData(clusterData)
    }
  }, [clusterData])

  useEffect(() => {
    if (cluster) {
      if (props.hideCluster) {
        cluster?.setData([])
      } else {
        cluster?.setData(clusterData)
      }
    }
  }, [props.hideCluster])

  useEffect(() => {
    ;(window as any).pl = pl
    if (currentType === 'weather') {
      pl?.show()
      roofPl?.hide()
    } else {
      pl?.hide()
      roofPl?.show()
      loadRoofData()
    }
  }, [currentType])

  const [min, setMin] = useState(0)
  const [max, setMax] = useState(0)

  useEffect(() => {
    if (roofData && roofPl && loca && currentRoofSelected) {
      const idxArr = ['', '', 'roofCount', 'roofArea', 'regionArea', 'avgRoofArea', 'roofRatio']
      const idx = idxArr.indexOf(currentRoofSelected)
      if (idx < 0) {
        return
      }
      const srcData = roofData.map((o: any) => ({
        lat: parseFloat(o[1]),
        lng: parseFloat(o[0]),
        value: parseFloat(o[idx]),
      }))
      const max = Math.max(...srcData.map((o: any) => o.value))
      const min = Math.min(...srcData.map((o: any) => o.value))
      setMin(min)
      setMax(max)
      const distance = max - min
      const data = (GeoJSON as any).parse(srcData, { Point: ['lat', 'lng'] })
      const geo = new Loca.GeoJSONSource({
        data,
      })
      const style = {
        radius: 55000 * currentRoofLevel,
        gap: 0,
        altitude: 0,
        unit: 'meter',
        borderWidth: 0,
        opacity: 0.6,
        height: 0,
        topColor: function (i: number, feature: any) {
          let data = feature.coordinates[0].properties
          const value = data.value - min

          let ci = value / distance
          return colorArrs[currentSelected](ci)
        },
      }
      roofPl.setSource(geo)
      roofPl.setStyle(style)
      loca.requestRender()
    }
  }, [currentRoofSelected])

  useEffect(() => {
    if (props.pointerData) {
      const points: any[] = []

      props.pointerData.forEach((o: any) => {
        const { adcode } = o
        points.push({ lnglat: areaCodes.find((o) => o.adcode == adcode)?.position })
      })

      setClusterData(points)

      if (map) {
        if (!cluster) {
          cluster = new AMap.MarkerCluster(map, [], { gridSize: 60 })
        } else {
          cluster.setData([])
        }
      }
    }
  }, [props.pointerData])

  useEffect(() => {
    if (props.currentArea) {
      setCurrentArea(props.currentArea)
      if (!(window as any).ignoreListener) {
        setTimeout(() => {
          ;(window as any).reloadAreaNode?.({ adcode: 100000 })
        })
      }
    }
  }, [props.currentArea])

  useEffect(() => {
    switch (layerType) {
      case 'wx':
        wxLayer?.show()
        customLayer?.show()
        roadLayer?.hide()
        break
      case 'custom':
        wxLayer?.hide()
        customLayer?.show()
        roadLayer?.hide()
        break
      case 'road':
        wxLayer?.show()
        customLayer?.show()
        roadLayer?.show()
        break
    }
  }, [layerType])

  useEffect(() => {
    if (isPlaying && !tle) {
      tle = setInterval(() => {
        doPlay()
      }, 5000)
    } else {
      clearInterval(tle)
      tle = undefined
    }
  }, [isPlaying])

  const doPlay = () => {
    setMonth((month) => {
      if (month < 11) {
        return month + 1
      } else {
        setIsPlaying(false)
        return month + 1
      }
    })
  }

  const onResize = () => {
    setMap((map: any) => {
      map?.resize()
      return map
    })
  }

  useEffect(() => {
    EvaluationService.regionData().then((resp) => {
      setRegionData(resp.data)
    })
    return () => {
      monthData.length = 0
      wxLayer = undefined
      customLayer = undefined
      roadLayer = undefined
      infoWindow = undefined
      roofPointLayer = undefined

      Loca = undefined
      AMapUI = undefined
      AMap = undefined
      loca = undefined
      pl = undefined
      roofPl = undefined
      polygon = undefined
      districtExplorer = undefined

      cluster = undefined

      currentLevel = ''
      ;(window as any).ignoreListener = false

      polygonPoints.length = 0

      tle = undefined
    }
  }, [])

  useEffect(() => {
    if (month > 0) {
      setSliderMonth(month)
      loadData(month)
    }
  }, [month])

  useEffect(() => {
    if (currentSelected && month > 0) {
      loadData()
    }
  }, [currentSelected])

  const [infoWindowLoading, setInfoWindowLoading] = React.useState<string>()
  useEffect(() => {
    if (infoWindowLoading) {
      const obj = regionData[infoWindowLoading]
      if (obj) {
        infoWindow?.setContent(getInfoWindow(obj))
      } else {
        infoWindow?.setContent('当前地区暂无数据')
      }
    } else {
      infoWindow?.setContent('')
    }
  }, [infoWindowLoading])

  useEffect(() => {
    window.addEventListener('resize', onResize)
    return () => {
      loca = undefined
      pl = undefined
      window.removeEventListener('resize', onResize)
    }
  }, [])

  const renderMapContainer = (map: any) => {
    loca = new Loca.Container({
      map: map,
      opacity: 1,
    })

    pl = new Loca.HexagonLayer({
      zIndex: 10,
      opacity: 0.6,
      visible: true,
      acceptLight: false,
    })

    roofPl = new Loca.GridLayer({
      zIndex: 10,
      opacity: 0.6,
      visible: false,
      acceptLight: false,
    })

    districtExplorer = new AMapUI.DistrictExplorer({
      map: map,
      eventSupport: true,
    })

    infoWindow = new AMap.InfoWindow({
      content: '',
      offset: new AMap.Pixel(2, -15),
    })

    const renderAreaNode = (districtExplorer: any, areaNode: any) => {
      districtExplorer.setAreaNodesForLocating([areaNode])
      districtExplorer.setHoverFeature(null)
      districtExplorer.clearFeaturePolygons()
      setCurrentArea((currentArea) => {
        districtExplorer.renderSubFeatures(areaNode, function (feature: any) {
          if (currentArea) {
            if (currentArea.includes(feature.properties.adcode.toString())) {
              return {
                cursor: 'default',
                bubble: true,
                strokeColor: 'rgba(0,255,0,1)', //线颜色
                strokeOpacity: 0.2, //线透明度
                strokeWeight: 1, //线宽
                fillColor: 'rgba(0,255,0,1)', //填充色
                fillOpacity: 0, //填充透明度
              }
            } else {
              return null
            }
          }
          return {
            cursor: 'default',
            bubble: true,
            strokeColor: 'rgba(0,255,0,1)', //线颜色
            strokeOpacity: 0.2, //线透明度
            strokeWeight: 1, //线宽
            fillColor: 'rgba(0,255,0,1)', //填充色
            fillOpacity: 0, //填充透明度
          }
        })

        districtExplorer.renderParentFeature(areaNode, {
          cursor: 'default',
          bubble: true,
          strokeColor: 'rgba(0,255,0,1)', //线颜色
          fillColor: null,
          strokeWeight: 1, //线宽
        })

        map.setFitView(districtExplorer.getAllFeaturePolygons())

        return currentArea
      })
    }

    ;(window as any).showInfoWindow = (adcode: string) => {
      setCurrentArea((currentArea) => {
        const obj = areaCodes.find((o) => o.adcode === adcode)
        if (currentArea) {
          if (currentArea.includes(adcode.toString())) {
            ;(window as any).doScan = function () {
              const e = window.event || arguments.callee.caller.arguments[0]
              e.preventDefault()
              e.stopPropagation()
              ;(window as any).ignoreListener = true
              // registEventListener(false)
              ;(window as any).currentAdcode = adcode.toString()
              setShowLoca(false)
              setShowAction(false)
              infoWindow?.close()
              setShowCoverLayer(true)

              setTimeout(() => {
                window.currentPolys?.setOptions({
                  fillOpacity: 0,
                  strokeOpacity: 0.2,
                })
              }, 500)
            }
            currentLevel = 'district'
            setInfoWindowLoading(adcode)
            infoWindow.open(map, obj?.position)
          }
        }
        return currentArea
      })
    }
    ;(window as any).moveToRoof = (center: string[]) => {
      const lnglat = new AMap.LngLat(parseFloat(center[0]), parseFloat(center[1]))
      map.setZoom(17)
      map.setCenter(lnglat)
    }

    const showInfoWindow = (feature: any, isHover: any, position?: any) => {
      setCurrentArea((currentArea) => {
        if (currentArea) {
          if (currentArea.includes(feature.properties.adcode.toString())) {
            ;(window as any).doScan = function () {
              const e = window.event || arguments.callee.caller.arguments[0]
              e.preventDefault()
              e.stopPropagation()
              ;(window as any).ignoreListener = true
              // registEventListener(false)
              ;(window as any).currentAdcode = feature.properties.adcode.toString()
              setShowLoca(false)
              setShowAction(false)
              infoWindow?.close()
              setShowCoverLayer(true)

              setTimeout(() => {
                window.currentPolys?.setOptions({
                  fillOpacity: 0,
                  strokeOpacity: 0.2,
                })
              }, 500)
            }
            currentLevel = feature.properties.level
            setInfoWindowLoading(isHover ? feature.properties.adcode : undefined)
            const props = feature.properties
            // console.log(props.center)
            infoWindow.open(map, position || props.center)
          }
        }
        return currentArea
      })
    }

    const toggleHoverFeature = (feature: any, isHover: any) => {
      if (!feature) {
        return
      }
      const props = feature.properties

      if (isHover) {
        // clickedFeature = feature
        // console.log(position);
      }

      const polys = districtExplorer.findFeaturePolygonsByAdcode(props.adcode)
      for (let i = 0, len = polys.length; i < len; i++) {
        if (isHover) {
          window.currentPolys = polys[i]
        }
        polys[i].setOptions({
          fillOpacity: isHover ? 0.5 : 0,
          strokeOpacity: isHover ? 1 : 0.2,
        })
      }
    }

    ;(window as any).cancelScan = () => {
      ;(window as any).ignoreListener = false
      ;(window as any).currentAdcode = ''
      setShowLoca(true)
      setShowAction(true)
      infoWindow?.close()
      setShowCoverLayer(false)
      const geo = new Loca.GeoJSONSource({
        data: '',
      })
      roofPointLayer.setSource(geo)
      roofPointLayer && loca?.remove?.(roofPointLayer)
    }

    const reloadAreaNode = ((window as any).reloadAreaNode = (obj: any) => {
      const adcode = obj.adcode
      infoWindow?.close()
      districtExplorer?.loadAreaNode(adcode, function (error: any, areaNode: any) {
        if (error) {
          return
        }
        //绘制载入的区划节点
        renderAreaNode(districtExplorer, areaNode)
      })
    })

    const registEventListener = (regist = true) => {
      if (regist) {
        //监听feature的hover事件
        districtExplorer.on('featureMouseout featureMouseover', (e: any, feature: any) => {
          if (!(window as any).ignoreListener) {
            if (e.type === 'featureMouseover') {
              showInfoWindow(feature, e.type === 'featureMouseover')
            } else {
              infoWindow?.close()
            }
            toggleHoverFeature(feature, e.type === 'featureMouseover')
          }
        })

        //feature被点击
        // map.on('dblclick', function () {
        //   if (!(window as any).ignoreListener) {
        //     if (clickedFeature) {
        //       const props = clickedFeature.properties
        //       reloadAreaNode(props.adcode)
        //     }
        //   }
        // })
        //监听feature的hover事件
        districtExplorer.on('featureClick', (e: any, feature: any) => {
          if (!(window as any).ignoreListener) {
            infoWindow?.close()
            reloadAreaNode({ adcode: feature.properties.adcode })
            // showInfoWindow(feature, e.type === 'featureClick')
          }
        })
        //外部区域被点击
        districtExplorer.on('outsideClick', function () // e: any
        {
          if (!(window as any).ignoreListener) {
            reloadAreaNode({ adcode: 100000 })
          }
        })
      } else {
        districtExplorer.off('featureMouseout featureMouseover')
        districtExplorer.off('featureClick')
        districtExplorer.off('outsideClick')
        map.off('dblclick')
      }
    }

    registEventListener()

    reloadAreaNode({adcode: 100000})
    const data = (GeoJSON as any).parse([], { Point: ['lat', 'lng'] })

    const geo = new Loca.GeoJSONSource({
      data,
    })
    pl.setSource(geo)
    pl.setStyle({})

    roofPl.setSource(geo)
    roofPl.setStyle({})

    loca.add(pl)
    loca.add(roofPl)

    roofPointLayer = new Loca.PointLayer({
      zIndex: 10,
      opacity: 1,
      visible: true,
      blend: 'lighter',
    })
    const style = {
      radius: 3.5,
      unit: 'px',
      color: '#3C1FA8',
      borderWidth: 0,
      blurWidth: 3.5,
    }

    roofPointLayer.setSource(geo)
    roofPointLayer.setStyle(style)
    loca.add(roofPointLayer)

    setMonth(1)
  }

  useEffect(() => {
    if (map) {
      map.on('complete', () => {
        setTimeout(() => {
          renderMapContainer(map)
        }, 500)
      })
    }
  }, [map])

  const loadData = (val: number = 1, callback?: Function) => {
    setLoading(true)
    let srv: any
    if (monthData[val]?.[currentSelected]) {
      srv = () =>
        new Promise((resolve) => {
          resolve(monthData[val][currentSelected])
        })
    } else {
      srv = () => {
        return new Promise((resolve) => {
          ProjectServiceHistory.getTmyWeather({
            weatherElement: currentSelected,
            time: `2000-${('0' + val).slice(-2)}`,
            freq: 'M',
          }).then((resp: any) => {
            resolve(resp)
          })
        })
      }
    }
    srv()
      .then((jsonData: any) => {
        if (!monthData[val]) {
          monthData[val] = { [currentSelected]: jsonData }
        } else {
          monthData[val][currentSelected] = jsonData
        }
        setLoading(false)
        const srcData = jsonData.data
          .filter((o: any, idx: number) => {
            return idx % TIMES === 0
          })
          .map((o: any) => ({
            lat: parseFloat(o[1]),
            lng: parseFloat(o[0]),
            value: parseFloat(o[2]),
          }))
        const max = minMaxArrs[currentSelected][1] || Math.max(...srcData.map((o: any) => o.value))
        const min = minMaxArrs[currentSelected][0] || Math.min(...srcData.map((o: any) => o.value))
        setMin(min)
        setMax(max)
        const distance = max - min
        const data = (GeoJSON as any).parse(srcData, { Point: ['lat', 'lng'] })
        const geo = new Loca.GeoJSONSource({
          data,
        })
        const style = {
          radius: 11000 * TIMES,
          gap: 0,
          altitude: 0,
          unit: 'meter',
          borderWidth: 0,
          opacity: 0.6,
          height: 0,
          topColor: function (i: number, feature: any) {
            let data = feature.coordinates[0].properties
            const value = data.value - min

            let ci = value / distance
            return colorArrs[currentSelected](ci)
          },
        }
        pl.setSource(geo)
        pl.setStyle(style)
        loca.requestRender()
        callback?.(true)
      })
      .catch(() => {
        setLoading(false)
        callback?.(false)
      })
  }

  const loadRoofData = (level: string = '0.5', callback?: Function) => {
    setLoading(true)
    if (!map) {
      return
    }
    const bound = map.getBounds()
    if (bound) {
      const {
        northEast: { lat: rightTopLat, lng: rightTopLng },
        southWest: { lat: leftBottomLat, lng: leftBottomLng },
      } = bound

      const obj = {
        rightTopLat,
        rightTopLng,
        leftBottomLat,
        leftBottomLng,
      }

      EvaluationService.getGridData({
        level,
        ...obj,
      })
        .then((jsonData: any) => {
          setLoading(false)
          roofData = jsonData.data
          const srcData = jsonData.data.map((o: any) => ({
            lat: parseFloat(o[1]),
            lng: parseFloat(o[0]),
            value: parseFloat(o[4]),
          }))
          const max = Math.max(...srcData.map((o: any) => o.value))
          const min = Math.min(...srcData.map((o: any) => o.value))
          setMin(min)
          setMax(max)
          const distance = max - min
          const data = (GeoJSON as any).parse(srcData, { Point: ['lat', 'lng'] })
          const geo = new Loca.GeoJSONSource({
            data,
          })
          const style = {
            radius: 11000 * currentRoofLevel,
            gap: 0,
            altitude: 0,
            unit: 'meter',
            borderWidth: 0,
            opacity: 0.6,
            height: 0,
            topColor: function (i: number, feature: any) {
              let data = feature.coordinates[0].properties
              const value = data.value - min

              let ci = value / distance
              return colorArrs[currentSelected](ci)
            },
          }
          roofPl.setSource(geo)
          roofPl.setStyle(style)
          loca.requestRender()
          callback?.(true)
        })
        .catch(() => {
          setLoading(false)
          callback?.(false)
        })
    }
  }

  const [showLoca, setShowLoca] = useState(true)
  useEffect(() => {
    if (showLoca) {
      pl?.show()
    } else {
      pl?.hide()
    }
  }, [showLoca])

  const [showSift] = useState(true)

  useEffect(() => {
    // console.log(currentRoofLevel)
    if (currentRoofLevel) {
      loadRoofData(currentRoofLevel.toString())
    }
  }, [currentRoofLevel])

  useEffect(() => {
    props?.showSiftHandler?.(showSift)
  }, [showSift])

  const [showAction, setShowAction] = useState(false)

  const mapRef = useRef(null)

  const [containerArea, setContainerArea] = useState<any>()
  const [roofRects, setRoofRects] = useState<any[]>([])

  useEffect(() => {
    if (roofRects && roofRects.length > 0) {
      const polygons = map.getAllOverlays('polygon')
      map.remove(polygons.filter((o: any) => o.getExtData()?.name === 'roof'))
      polygonPoints.length = 0

      roofRects.forEach((roofRect: any) => {
        const _data = roofRect.pos
        const data = _data.map((o: string[]) => {
          return o.map(parseFloat)
        })
        const _polygon = new AMap.Polygon({
          path: data,
          fillColor: roofRect.src?.added ? '#F9D542' : '#F97942',
          strokeOpacity: 1,
          fillOpacity: 0.5,
          strokeColor: roofRect.src?.added ? '#179E75' : '#F97942',
          bubble: true,
          strokeWeight: roofRect.src?.added ? 2 : 0,
          zIndex: 9999,
          extData: {
            name: 'roof',
            baseStroke: roofRect.src?.added ? 2 : 0,
            baseColor: roofRect.src?.added ? '#179E75' : '#F97942',
          },
        })

        _polygon.on('mouseover', (evt: any) => {
          evt.target.setOptions({ strokeWeight: 2, strokeColor: '#F97942' })
        })

        _polygon.on('mouseout', (evt: any) => {
          evt.target.setOptions({
            strokeWeight: evt.target.getExtData().baseStroke,
            strokeColor: evt.target.getExtData().baseColor,
          })
        })

        _polygon.on('click', (evt: any) => {
          evt.target.setOptions({ strokeWeight: 2, strokeColor: '#F97942' })
          if ((window as any).roofInfoWindow) {
            ;(window as any).roofInfoWindow.close()
          }
          ;(window as any).roofInfoWindow = new AMap.InfoWindow({
            content: createRoofDom(roofRect.src),
            closeWhenClickMap: true,
            retainWhenClose: true,
          })
          ;(window as any).roofInfoWindow.on('close', () => {
            evt.target.setOptions({
              strokeWeight: evt.target.getExtData().baseStroke,
              strokeColor: evt.target.getExtData().baseColor,
            })
          })
          setTimeout(() => {
            ;(window as any).roofInfoWindow.open(map, evt.lnglat)
          }, 0)
        })

        _polygon.setPath(data)

        polygonPoints.push(_polygon)

        map.add(_polygon)
      })
    }
  }, [roofRects])

  useEffect(() => {
    if (containerArea) {
      EvaluationService.getRoofByGeo({
        regionCode: (window as any).currentAdcode,
        ...containerArea,
      })
        .then((resp) => {
          setRoofRects(
            resp.data.map((o: any) => {
              return {
                src: o,
                pos: o.geometry.map((oo: any) => {
                  const key = Object.keys(oo)[0]
                  const val = oo[key]
                  return [key, val]
                }),
              }
            })
          )
        })
        .catch(() => {})
    }
  }, [containerArea])

  const onZoomEnd = (map: any) => {
    setCurrentType((currentType) => {
      if (currentType !== 'weather') {
        let level = 1
        const zoom = map.getZoom()
        if (zoom < 5) {
          level = 1
        } else if (zoom < 7) {
          level = 0.5
        } else if (zoom < 9) {
          level = 0.2
        } else if (zoom < 11) {
          level = 0.1
        } else if (zoom < 13) {
          level = 0.05
        } else if (zoom < 15) {
          level = 0.02
        } else if (zoom < 17) {
          level = 0.01
        }
        setCurrentRoofLevel((currentRoofLevel: number) => {
          if (level !== currentRoofLevel) {
            return level
          }
          return currentRoofLevel
        })
      }
      return currentType
    })
    if (map.getZoom() > 15) {
      const bound = map.getBounds()
      if (bound) {
        const {
          northEast: { lat: rightTopLat, lng: rightTopLng },
          southWest: { lat: leftBottomLat, lng: leftBottomLng },
        } = bound

        const obj = {
          rightTopLat,
          rightTopLng,
          leftBottomLat,
          leftBottomLng,
        }

        setContainerArea(obj)
      }
      roofPointLayer?.hide()
    } else {
      const polygons = map.getAllOverlays('polygon')
      map.remove(polygons.filter((o: any) => o.getExtData()?.name === 'roof'))
      roofPointLayer?.show()
    }
  }

  useEffect(() => {
    if (!map && mapRef.current) {
      getAMap().then((_AMap) => {
        Loca = (window as any).Loca
        AMapUI = (window as any).AMapUI
        AMap = _AMap

        // wxLayer = new AMap.TileLayer.Satellite()
        customLayer = new AMap.TileLayer({
          rejectMapMask: true,
        })
        roadLayer = new AMap.TileLayer.RoadNet()

        wxLayer = new AMap.TileLayer({
          getTileUrl: function (x: any, y: any, z: any) {
            return `http://114.217.8.57:8888/static/v1.0.0/klfmbt/${z}/${x}/${y}.png`
          },
          // getTileUrl: 'http://114.217.8.57:8888/static/v1.0.0/klfmbt/[z]/[x]/[y].png',
          // getTileUrl: 'http://mt{1,2,3,0}.google.cn/vt/lyrs=m@142&hl=zh-CN&gl=cn&x=[x]&y=[y]&z=[z]&s=Galil',
          zIndex: 2,
        })

        const opts = {
          subdistrict: 3,
          extensions: 'all',
        }
        const ds = new AMap.DistrictSearch(opts)
        ds.search('中国', function (status: string, result: any) {
          areaCodes = result.districtList
            .map((o: any) =>
              o.districtList?.map((oo: any) => oo.districtList?.map((ooo: any) => ooo.districtList || ooo) || oo)
            )
            .flat(10)
            .map((dst: any) => ({
              adcode: dst.adcode,
              name: dst.name,
              position: [dst.center.lng, dst.center.lat],
            }))

          const bounds = result.districtList[0].boundaries
          const mask = []
          for (let i = 0; i < bounds.length; i += 1) {
            mask.push([bounds[i]])
          }

          const _map = new AMap.Map('weather-map', {
            mask: mask,
            resizeEnable: true,
            center: [116.418261, 39.921984],
            zoom: 4,
            viewMode: '3D',
            doubleClickZoom: false,
            mapStyle: 'amap://styles/05e321edfe9e69b5902e4dc372c36fda',
            layers: [customLayer, wxLayer, roadLayer],
            // layers: [srvLayer],
          })

          _map.on('zoomend', () => {
            onZoomEnd(_map)
          })

          _map.on('moveend', () => {
            onZoomEnd(_map)
          })

          setMap(_map)
        })
      })
    }
  }, [mapRef])

  const [showCoverLayer, setShowCoverLayer] = useState(false)
  const [roof, setRoof] = useState<any[]>()

  useEffect(() => {
    if (roof) {
      props.roofDataHandler?.(roof)
      const srcData = roof.map((o: any) => ({
        lat: o.centerPoint[1],
        lng: o.centerPoint[0],
        value: o.area,
      }))

      const data = (GeoJSON as any).parse(srcData, { Point: ['lat', 'lng'] })
      const geo = new Loca.GeoJSONSource({
        data,
      })
      roofPointLayer.setSource(geo)
      const style = {
        radius: 5,
        unit: 'px',
        color: '#3C1FA8',
        borderWidth: 0,
        blurWidth: 3.5,
      }
      roofPointLayer.setSource(geo)
      roofPointLayer.setStyle(style)

      loca.add(roofPointLayer)

      roofPointLayer.show()
    }
  }, [roof])

  useEffect(() => {
    if (showCoverLayer) {
      props.showSiftHandler?.(false)
      props.showRoofSiftHandler?.(true)

      EvaluationService.getRoof({
        regionCode: (window as any).currentAdcode,
      }).then((resp) => {
        setRoof(resp.data)
      })
      addCoverLayer()
    } else {
      props.showRoofSiftHandler?.(false)
      props.showSiftHandler?.(true)

      map?.remove(polygon)
    }
  }, [showCoverLayer])

  const addCoverLayer = () => {
    // if (polygon) {
    //   map.add(polygon)
    // } else {
    // console.log((window as any).currentAdcode)
    new AMap.DistrictSearch({
      level: '0',
      extensions: 'all',
      subdistrict: 0,
    }).search((window as any).currentAdcode, function (status: string, result: any) {
      // 外多边形坐标数组和内多边形坐标数组
      const outer = [
        new AMap.LngLat(-360, 90, true),
        new AMap.LngLat(-360, -90, true),
        new AMap.LngLat(360, -90, true),
        new AMap.LngLat(360, 90, true),
      ]
      const holes = result.districtList[0].boundaries

      const pathArray = [outer]
      pathArray.push.apply(pathArray, holes)
      polygon = new AMap.Polygon({
        path: pathArray,
        strokeColor: 'transparent',
        strokeWeight: 1,
        fillColor: '#000',
        fillOpacity: 0.5,
      })
      polygon.setPath(pathArray)

      map.add(polygon)
    })
    // }
  }

  return (
    <div
      className="resource-reckon"
      style={{
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        padding: 0,
      }}
    >
      <div ref={mapRef} id="weather-map" className="absolute-fill" />
      {loading && (
        <Spin
          spinning
          className="absolute-fill"
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: 'rgba(0,0,0,0.2)',
          }}
        />
      )}
      <div
        className="absolute-fill"
        style={{
          pointerEvents: 'none',
        }}
      >
        {openSelector && (
          <div
            className="weather-custom-radio-group"
            style={{
              position: 'absolute',
              overflowX: 'auto',
              overflowY: 'hidden',
              right: 255,
              bottom: 115,
              left: 20,
              pointerEvents: 'all',
              zIndex: 9999,
            }}
          >
            <div
              style={{
                backgroundColor: 'rgba(0, 0, 0, 0.6)',
                pointerEvents: 'all',
                color: 'white',
                minWidth: 86,
                height: 40,
                borderRadius: 20,
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center',
                cursor: 'pointer',
              }}
              onClick={() => {
                setOpenSelector(false)
                setCurrentType((currentType) => {
                  setIsPlaying(false)
                  return currentType === 'weather' ? 'roof' : 'weather'
                })
              }}
            >
              {currentType === 'weather' ? '屋顶资源' : '天气'}
            </div>
          </div>
        )}
        {showLoca && (
          <div
            className="weather-custom-radio-group"
            style={{
              position: 'absolute',
              overflowX: 'auto',
              overflowY: 'hidden',
              right: 255,
              bottom: 65,
              left: 20,
              pointerEvents: 'all',
            }}
          >
            <div
              style={{
                backgroundColor: 'rgba(0, 0, 0, 0.6)',
                pointerEvents: 'all',
                color: 'white',
                minWidth: currentType === 'weather' ? 86 : 106,
                height: 40,
                borderRadius: 20,
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center',
                cursor: 'pointer',
              }}
              onClick={() => {
                setOpenSelector(true)
              }}
            >
              {currentType === 'roof' ? '屋顶资源' : '天气'}
              <CustomIcons
                style={{
                  marginLeft: 10,
                  color: 'white',
                }}
                type={openSelector ? 'klf-arrow-up' : 'klf-arrow-down'}
              />
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
              }}
            >
              {(currentType === 'weather' ? filterSelectors : roofFilterSelectors).map((o) => (
                <div
                  style={{
                    paddingRight: 14,
                    height: 32,
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    borderRadius: 16,
                    backgroundColor:
                      o.key === currentSelected || o.key === currentRoofSelected
                        ? 'rgba(30, 198, 146, 1)'
                        : 'rgba(0, 0, 0, 0.6)',
                    color: 'white',
                    marginLeft: 14,
                    pointerEvents: 'all',
                    cursor: 'pointer',
                    whiteSpace: 'nowrap',
                  }}
                  key={o.key}
                  onClick={() => {
                    if (currentType === 'weather') {
                      setCurrentSelected(o.key)
                    } else {
                      setCurrentRoofSelected(o.key)
                    }
                  }}
                >
                  <div
                    style={{
                      width: 32,
                      height: 32,
                      borderRadius: 16,
                      backgroundColor:
                        o.key === currentSelected || o.key === currentRoofSelected ? 'rgba(28, 184, 135, 1)' : 'white',
                      marginRight: 6,
                      fontSize: 18,
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <CustomIcons
                      type={o.key === currentSelected || o.key === currentRoofSelected ? o.icon : o.selectedIcon}
                    />
                  </div>
                  {o.title}
                </div>
              ))}
            </div>
          </div>
        )}
        {showLoca && currentType === 'weather' && (
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              position: 'absolute',
              bottom: 20,
              height: 32,
              left: 20,
              right: 105,
            }}
          >
            {/*playButton*/}
            <div
              style={{
                color: 'white',
                fontSize: 16,
                textAlign: 'center',
                borderRadius: 16,
                width: 32,
                height: 32,
                lineHeight: '32px',
                backgroundColor: 'rgba(0, 0, 0, 0.6)',
                pointerEvents: 'all',
                cursor: 'pointer',
                marginRight: 14,
              }}
              onClick={() => {
                setMonth((month) => (month >= 12 ? 1 : month))
                setIsPlaying((isPlaying) => !isPlaying)
              }}
            >
              {!isPlaying && <CustomIcons type="klf-play" />}
              {isPlaying && <PauseOutlined />}
            </div>
            {/*preButton*/}
            <div
              style={{
                color: 'white',
                fontSize: 22,
                textAlign: 'center',
                borderRadius: 16,
                width: 32,
                height: 32,
                lineHeight: '32px',
                backgroundColor: 'rgba(0, 0, 0, 0.6)',
                pointerEvents: 'all',
                cursor: 'pointer',
                marginRight: 14,
              }}
              onClick={() => {
                setIsPlaying(false)
                setMonth((month) => (month <= 1 ? 12 : month - 1))
              }}
            >
              <StepBackwardOutlined />
            </div>
            <Slider
              style={{
                flexGrow: 1,
                pointerEvents: 'all',
              }}
              step={1}
              min={1}
              max={12}
              marks={marks}
              value={sliderMonth}
              onChange={(val) => {
                setSliderMonth(val)
              }}
              onAfterChange={(val) => {
                setIsPlaying(false)
                setMonth(val)
              }}
            />
            {/*nextButton*/}
            <div
              style={{
                color: 'white',
                fontSize: 22,
                textAlign: 'center',
                borderRadius: 16,
                width: 32,
                height: 32,
                lineHeight: '32px',
                backgroundColor: 'rgba(0, 0, 0, 0.6)',
                pointerEvents: 'all',
                cursor: 'pointer',
                marginLeft: 14,
              }}
              onClick={() => {
                setIsPlaying(false)
                setMonth((month) => (month >= 12 ? 1 : month + 1))
              }}
            >
              <StepForwardOutlined />
            </div>
          </div>
        )}
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'flex-end',
            position: 'absolute',
            overflow: 'hidden',
            justifyContent: 'flex-end',
            right: 20,
            bottom: 20,
          }}
        >
          {showAction && (
            <div
              style={{
                width: 266,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-end',
              }}
            >
              {layerType !== 'custom' && (
                <div
                  style={{
                    backgroundColor: 'white',
                    borderRadius: 4,
                    height: 40,
                    marginBottom: 8,
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: 114,
                    pointerEvents: 'all',
                    cursor: 'pointer',
                    fontSize: 14,
                    color: layerType === 'road' ? 'rgba(28, 184, 135, 1)' : 'rgba(38, 46, 63, 1)',
                  }}
                  onClick={() => {
                    setLayerType((layerType) => {
                      if (layerType === 'wx') {
                        return 'road'
                      } else {
                        return 'wx'
                      }
                    })
                  }}
                >
                  <span
                    style={{
                      marginRight: 10,
                      fontSize: 16,
                      lineHeight: '16px',
                    }}
                  >
                    <CustomIcons type="klf-icon_yixuan-danxuan" />
                  </span>
                  查看路网
                </div>
              )}
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  backgroundColor: 'white',
                  borderRadius: 4,
                  height: 40,
                  width: showCoverLayer ? 88 : '100%',
                }}
              >
                {!showCoverLayer && (
                  <div
                    style={{
                      flexGrow: 1,
                      borderRight: '1px solid #EBEBEB',
                      textAlign: 'center',
                      pointerEvents: 'all',
                      cursor: 'pointer',
                      color: !props.hideCluster ? 'rgba(28, 184, 135, 1)' : 'rgba(38, 46, 63, 1)',
                    }}
                    onClick={() => {
                      props.doHideCluster?.()
                      // setShowSift(!showSift)
                    }}
                  >
                    <span
                      style={{
                        marginRight: 10,
                        fontSize: 16,
                      }}
                    >
                      <CustomIcons type={!props.hideCluster ? 'klf-a-juxing4' : 'klf-a-juxing5'} />
                    </span>
                    筛选
                  </div>
                )}
                {!showCoverLayer && (
                  <div
                    style={{
                      flexGrow: 1,
                      borderRight: '1px solid #EBEBEB',
                      textAlign: 'center',
                      pointerEvents: 'all',
                      cursor: 'pointer',
                      color: showLoca ? 'rgba(28, 184, 135, 1)' : 'rgba(38, 46, 63, 1)',
                    }}
                    onClick={() => {
                      setShowLoca(!showLoca)
                    }}
                  >
                    <span
                      style={{
                        marginRight: 10,
                        fontSize: 16,
                      }}
                    >
                      <CustomIcons type="klf-navi-resource" />
                    </span>
                    资源
                  </div>
                )}
                <div
                  style={{
                    flexGrow: 1,
                    textAlign: 'center',
                    pointerEvents: 'all',
                    cursor: 'pointer',
                    color: layerType !== 'custom' ? 'rgba(28, 184, 135, 1)' : 'rgba(38, 46, 63, 1)',
                  }}
                  onClick={() => {
                    setLayerType((layerType) => {
                      if (layerType === 'wx' || layerType === 'road') {
                        return 'custom'
                      } else {
                        return 'road'
                      }
                    })
                  }}
                >
                  <span
                    style={{
                      marginRight: 10,
                      fontSize: 16,
                    }}
                  >
                    <CustomIcons type={layerType !== 'custom' ? 'klf-a-juxing7' : 'klf-a-juxing6'} />
                  </span>
                  卫星
                </div>
              </div>
            </div>
          )}
          <div
            style={{
              marginLeft: 16,
              fontSize: 24,
            }}
          >
            <div
              style={{
                width: 24,
                height: 24,
                lineHeight: '24px',
                textAlign: 'center',
                borderRadius: 2,
                pointerEvents: 'all',
                cursor: 'pointer',
                backgroundColor: 'white',
              }}
              onClick={() => {
                map?.zoomIn()
              }}
            >
              <CustomIcons type="klf-a-juxing2" />
            </div>
            <div
              style={{
                width: 24,
                height: 24,
                lineHeight: '24px',
                textAlign: 'center',
                borderRadius: 2,
                pointerEvents: 'all',
                cursor: 'pointer',
                backgroundColor: 'white',
                marginTop: 1,
              }}
              onClick={() => {
                map?.zoomOut()
              }}
            >
              <CustomIcons type="klf-a-juxing3" />
            </div>
            <div
              style={{
                width: 24,
                height: 24,
                lineHeight: '24px',
                textAlign: 'center',
                borderRadius: 2,
                pointerEvents: 'all',
                cursor: 'pointer',
                backgroundColor: 'white',
                marginTop: 4,
              }}
              onClick={() => {
                map.setFitView()
              }}
            >
              <CustomIcons type="klf-a-juxing1" />
            </div>
            <div
              style={{
                width: 24,
                height: 24,
                lineHeight: '24px',
                textAlign: 'center',
                borderRadius: 2,
                pointerEvents: 'all',
                cursor: 'pointer',
                backgroundColor: 'white',
                marginTop: 4,
              }}
              onClick={() => {
                setShowAction(!showAction)
              }}
            >
              <CustomIcons type={showAction ? 'klf-a-juxing8' : 'klf-juxing'} />
            </div>
          </div>
        </div>
      </div>
      <div
        style={{
          position: 'absolute',
          right: 10,
          top: 60,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-end',
        }}
      >
        {max}
        {currentType === 'weather' ? unitMap[currentSelected] : unitMap[currentRoofSelected]}
        {[
          0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 0, 1, 2, 3, 4, 5, 6,
          7, 8, 9, 0, 1, 2, 3, 4, 5, 6, 7, 8, 9,
        ].map((o, idx) => (
          <div
            key={idx}
            style={{
              backgroundColor: colorArrs[currentSelected](1 - idx / 50),
              width: 10,
              height: 3,
            }}
          />
        ))}
        {min}
        {currentType === 'weather' ? unitMap[currentSelected] : unitMap[currentRoofSelected]}
      </div>
    </div>
  )
}
