import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import { Card, message, Tabs } from 'antd'
import CardTitle from '../components/CardTitle'
import Info from './Info'
import Member from './Member'
import Change from './Change'

import './index.less'
import { BreadcrumbObservable, SideMenuObservable } from '../../../../application'
import ProjectAccountControllerApi from '../../../../services/api/ProjectAccountControllerApi'
import { CurrAuthContext } from '../config'
import { ProjectServiceHistory } from '../../../../apis/project-service-history'
interface IProps {
  id?: string
  activeVal?: string
  type?: string
}

export const GroupProjectDetail = React.memo(() => {
  const { id, activeVal = 'info', type }: IProps = useParams()
  const [currAuth, setCurrAuth] = useState<any>({})
  const [info, setInfo] = useState<any>({})
  React.useEffect(() => {
    SideMenuObservable.next(true)
    BreadcrumbObservable.next([])
  }, [])

  const getDetail = () => {
    ProjectServiceHistory.get({
      projectId: id,
    }).then((res: any) => {
      const { code, msg, data = {} } = res || {}
      if (code === 200) {
        const addressObject = {
          address: data.address,
          longitude: data.longitude,
          latitude: data.latitude,
        }
        // ProjectServiceHistory.inputTips({
        //   cityCode:  '',
        //   address: encodeURIComponent(data.address),
        // }).then((resp: any) => {
        //   if (resp?.data?.length) {
        //     form.setFieldsValue({ city: resp?.data[0].adcode })
        //   }
        // })
        setInfo({
          ...data,
          addressObject,
        })
      } else {
        msg && message.error(msg)
      }
    })
  }
  React.useEffect(() => {
    if (id) {
      ProjectAccountControllerApi.i3060ProjectAccountCurrAuthGet({
        projectId: id,
      }).then((res: any) => {
        if (res.data) {
          setCurrAuth(res.data)
        }
      })
      getDetail()
    }
  }, [id])
  const [activeKey, setActiveKey] = useState(activeVal)
  const { roleType, isAdministrators, source }: any = currAuth || {}
  const url = type ? '/' + type : ''
  const value = { roleType, isAdministrators, source, info }
  return (
    <Card
      className="no-padding-card full-height"
      style={{
        height: '100%',
        padding: '0 24px 30px',
        overflow: 'auto',
      }}
      title={<CardTitle title="项目设置" path={`/program/detail/${id}${url}`} />}
    >
      <CurrAuthContext.Provider value={value}>
        <Tabs
          className="settings-tab"
          activeKey={activeKey}
          onChange={(key) => {
            setActiveKey(key)
            location.assign(`#/program/detail/${id}${url}/settings/${key}`)
          }}
        >
          <Tabs.TabPane className="settings-pane" tab="项目信息" key="info">
            <Info />
          </Tabs.TabPane>
          <Tabs.TabPane className="settings-pane" tab="成员权限" key="member">
            <Member info={info} />
          </Tabs.TabPane>
          {isAdministrators || [1].includes(roleType) ? (
            <Tabs.TabPane className="settings-pane" tab="项目变更" key="change">
              <Change />
            </Tabs.TabPane>
          ) : null}
        </Tabs>
      </CurrAuthContext.Provider>
    </Card>
  )
})
