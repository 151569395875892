import React from 'react'
import { Button, Descriptions, Space, Table } from 'antd'
import { DoubleRightOutlined } from '@ant-design/icons'

export default ({ dataSource }) => {
  return (
    <Space direction="vertical" size={24}>
      {
        dataSource?.map((item: any, index: number) => {
          if (!(item.visible ?? true)) {
            return null
          }
          return (
            <div key={index}>
              <Descriptions
                title={item.title}
                layout="horizontal"
                extra={
                  item?.more ? (
                    <Button type="link" disabled={item.disabled} onClick={item.more}>
                      查看更多信息
                      <DoubleRightOutlined />
                    </Button>
                  ) : null
                }
              >
                {item.infoList?.map((desc: any, index: number) => {
                  return (
                    <Descriptions.Item key={index} label={desc.name}>
                      {desc.val ?? '暂无'}
                    </Descriptions.Item>
                  )
                })}
              </Descriptions>
              {item.tableColumns?.length > 0 && (item.tableData?.length > 0 || item.tableVisible) ? (
                <Table
                  bordered
                  rowKey={(...args: any[]) => args[1]}
                  className="bordered-table"
                  columns={item.tableColumns}
                  dataSource={item.tableData}
                  pagination={false}
                  size="small"
                  style={{ width: '100%' }}
                />
              ) : null}
            </div>
          )
        })
      }
    </Space>
  )
}
