import { message } from 'antd'
import {
  CollectionConService,
  InvestDecisionService,
  ProjectRecordService,
  ResourceService,
} from '../../../../apis/wl-service'

export type SubmitType = {
  onVisibleChange?: (visible: boolean) => void // 按钮状态处理函数
  isDraft?: boolean // 保存草稿、创建、更新
  success?: (data?: any) => void // 成功回调
  error?: () => void // 失败回调
  values: any // 用于提交的数据体
  // form?: FormInstance
  showMessage?: boolean // 操作成功是否提示
}
export interface IFormRef {
  getName: () => string
  submit: (options: SubmitType) => void
}

class Store {
  protected _fns: Function[]

  constructor() {
    this._fns = []
  }

  get keyMap() {
    return {
      resourceDev: 'i3060.invest.project.resource_develop', // 新建资源存储键名
      investDecision: 'i3060.invest.project.invest_decision', // 新建投资存储键名
      projectRecord: 'i3060.invest.project.project_record', // 新建备案存储键名
      collectionCon: 'i3060.invest.project.collectionCon.online', // 在线填写收资信息
      collectionConUpload: 'i3060.invest.project.collectionCon.offline', // 上传收资信息
      projectProposal: 'i3060.invest.project.projectProposal', // 项目建议书
      projectId: 'i3060.invest.project.project_id', // 项目ID
      options: 'i3060.invest.project.options', // 基础数据
      changedForm: 'i3060.invest.project.changedForm', // 有过修改的表单键值
    }
  }

  // 提交方法合集
  get submit() {
    const generatorSubmit = (opt: {
      key: string
      label?: string
      service: (values: any) => Promise<any>
      success?: (data?: any) => void
    }) => {
      return async ({ values, ...options }: SubmitType) => {
        // 开始提交
        options.onVisibleChange?.(true)
        values.isDraft = Number(options.isDraft ?? false)
        values.is_draft = Number(options.isDraft ?? false)
        try {
          const res: any = await opt.service(values)
          if (res?.code === 200) {
            options.showMessage && message.success(res.msg ?? `${opt.label ?? ''}操作成功~`)
            options.success?.(res?.data)

            // 操作成功后，存储的本地表单
            this.delChangedForm(opt.key)

            // 成功后，重新加载基础数据中的项目列表数据
            opt.success?.(res?.data)
          } else if (res?.msg) {
            options.error?.()
            message.error(res.msg)
          }
        } catch (err: any) {
          options.error?.()
          console.warn(`${opt.label ?? ''}更新失败: `, err)
        } finally {
          options.onVisibleChange?.(false)
        }
      }
    }

    return [
      {
        key: this.keyMap.resourceDev,
        label: '新建资源',
        service: (values: any) =>
          ResourceService.update({
            id: values.project_id ?? values.id ?? '',
            root: values,
          }),
        success: async (data: any) => {
          if (!this.projectId) {
            this.setProjectId(data ?? '', this.keyMap.resourceDev)
          }
          const self = this
          try {
            const res: any = await CollectionConService.basicData()
            if (res?.data) {
              const options = {
                ...res.data,
                developList: res.data.developList?.map(({ id, name }: any) => {
                  return { id, name, fullName: name }
                }),
              }
              self.setOptions(options, true)
            }
          } catch (innerErr: any) {
            console.warn('新建资源/收资信息基础数据获取失败：:', innerErr)
          }
        },
      },
      {
        key: this.keyMap.investDecision,
        label: '新建投资',
        service: (values: any) =>
          InvestDecisionService.update({
            id: values.id ?? '',
            root: values,
          }),
      },
      {
        key: this.keyMap.projectRecord,
        label: '新建备案',
        service: (values: any) =>
          ProjectRecordService.update({
            id: values.id ?? '',
            root: values,
          }),
      },
      {
        key: this.keyMap.collectionCon,
        label: '收资信息 > 在线填写',
        service: (values: any) => CollectionConService.update({ id: values?.id ?? '', root: values }),
      },
    ].reduce((total: any, currentValue: any) => {
      total[currentValue.key] = generatorSubmit(currentValue)
      return total
    }, {})
  }

  subscribe(fn: Function) {
    this._fns.push(fn)
  }

  unsubscribe(fn: Function) {
    this._fns = this._fns.filter((el) => el !== fn)
  }

  apply(o: any, thisObj?: any) {
    const scope = thisObj ?? window
    this._fns.forEach((fn) => {
      fn.call(scope, o)
    })
  }

  // 选中项目ID
  public projectId: string = this.getItem(this.keyMap.projectId)?.id ?? ''
  // 设置选中项目缓存的ID值
  setProjectId(id: string, moduleName?: string) {
    if (id !== this.projectId) {
      // 项目id改变后清空除了基础数据之外的所有缓存数据
      this.clearAll([this.keyMap.options])
    }
    this.projectId = id
    this.setItem(this.keyMap.projectId, { id, moduleName })
    this.apply(id)
  }

  // 从缓存中读取选中项目详情
  getProject() {
    return this.getItem(this.keyMap.resourceDev)
  }

  // 获取选中项目模块名
  getModuleName() {
    return this.getItem(this.keyMap.projectId)?.moduleName
  }

  // 获取基础数据
  getOptions(key?: string) {
    const options = this.getItem(this.keyMap.options) ?? {}
    return key ? options?.[key] : options
  }

  // 设置基础数据
  setOptions(values: any, mergeFlag?: boolean) {
    mergeFlag = mergeFlag ?? false
    if (mergeFlag) {
      values = { ...this.getOptions(), ...values }
    }
    this.setItem(this.keyMap.options, values)
  }

  // 清空基础数据
  clearOptions() {
    this.removeItem(this.keyMap.options)
  }

  // 清空本地存储数据
  clearAll(excludes?: string[]) {
    this.projectId = ''
    Object.entries(this.keyMap).forEach(([, k]: string[]) => {
      if (!excludes?.includes(k)) {
        this.removeItem(k)
      }
    })
  }

  // 获取有变化的表单值
  getChangedForm() {
    return this.getItem(this.keyMap.changedForm)
  }

  // 添加有变化的表单值
  addChangedForm(key: string) {
    const k = this.keyMap.changedForm
    this.setItem(k, Array.from(new Set([...(this.getItem(k) ?? []), key])))
  }

  // 删除有变化的表单值
  delChangedForm(key: string) {
    const k = this.keyMap.changedForm
    const values = this.getItem(k)?.filter((v: string) => v !== key)
    if (values) {
      this.setItem(k, values)
    } else {
      this.removeItem(k)
    }
  }

  // 表单值变化
  onValuesChange(key: string, allValues: any) {
    this.setItem(key, allValues)
    this.addChangedForm(key)
  }

  // 删除本地存储数据
  removeItem(key: string) {
    if (this.validStorage(window.sessionStorage)) {
      window.sessionStorage.removeItem(key)
    }
  }

  // 设置本地存储数据
  setItem(key: string, value: any) {
    if (this.validStorage(window.sessionStorage)) {
      window.sessionStorage.setItem(
        key,
        typeof value === 'object'
          ? JSON.stringify(value, (k, val) => {
              if (typeof val === 'function') {
                return val.toString().replace(/\t|\n/g, '')
              }
              return val
            })
          : value
      )
    }
  }

  // 获取本地存储数据
  getItem(key?: string) {
    if (!key) {
      return
    }
    if (this.validStorage(window.sessionStorage)) {
      try {
        const value = window.sessionStorage.getItem(key)
        return value
          ? JSON.parse(value, (k, val) => {
              if (key === 'function') {
                // return eval('(' + val + ')')
                return Function('"use strict";return (' + val + ')')()
              }
              return val
            })
          : undefined
      } catch (e) {
        return undefined
      }
    }
    return undefined
  }

  // 判断浏览器是否支持
  validStorage(storage?: any): boolean {
    storage = storage ?? window.sessionStorage
    if (storage) {
      try {
        storage.setItem('storageTest', 'value')
        if (storage.getItem('storageTest') !== 'value') {
          storage.removeItem('storageTest')
          throw '您的浏览器处于无痕模式，请退出无痕模式后再重新打开页面'
        }
        storage.removeItem('storageTest')
        return true
      } catch (e) {
        console.error(e)
        return false
      }
    }

    return false
  }
}

export default new Store()
