import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import InviteControllerApi from '../../../services/api/InviteControllerApi'
import type { InviteIntoOrg, InviteIntoProject } from '../typings'
import styles from '../index.module.scss'
import RemoveModal from '../../user-center/rights-template/components/RemoveModal'

export enum InviteType {
  '组织' = 1,
  '项目' = 2,
}

type IProps = {
  children?: React.ReactNode
}

// 邀请组件
const Invite: React.FC<IProps> = (props) => {
  const [loading, setLoading] = useState<boolean>(false)
  const [inviteCode, setInviteCode] = useState<string>()
  const [inviteType, setInviteType] = useState<number>(1)
  const [inviteOrg, setInviteOrg] = useState<InviteIntoOrg>()
  const [inviteProject, setInviteProject] = useState<InviteIntoProject>()
  const [removeVisible, setRemoveVisible] = useState<boolean>(false)

  const result = new URLSearchParams(location.search)
  const invite = result.get('invite')
  const isLogin = location.hash.includes('/login/index')

  const { children } = props

  const handleRemoveOk = () => {
    setRemoveVisible(false)
  }

  const fetchInviteInfo = () => {
    if (loading || !inviteCode) return
    setLoading(true)
    InviteControllerApi.authInviteCodeToContentCodeGet({
      code: inviteCode,
    })
      .then((res) => {
        setLoading(false)
        try {
          const { code, data } = res || {}
          if (code === 200) {
            const { type, content } = data || {}
            setInviteType(type)
            if (content) {
              const data = JSON.parse(content)
              type === 1 ? setInviteOrg(data) : setInviteProject(data)
            }
          } else if (code === 50004) {
            sessionStorage.removeItem('INVITE')
            setRemoveVisible(true)
          }
        } catch (err) {
          console.log('🚀 ~ file: login.tsx ~ line 63 ~ .then ~ err', err)
        }
      })
      .catch(() => {
        sessionStorage.removeItem('INVITE')
        setLoading(false)
      })
  }

  useEffect(() => {
    if (invite) {
      sessionStorage.setItem('INVITE', invite)
    }
    setInviteCode(sessionStorage.getItem('INVITE'))
  }, [invite])

  useEffect(() => {
    if (inviteCode) {
      fetchInviteInfo()
    }
  }, [inviteCode])

  return inviteOrg || inviteProject ? (
    <>
      <div className={styles.inviteTitle}>
        <span className={styles.inviter}>{inviteOrg?.userName || inviteProject?.userName}邀请你加入</span>
        <span className={styles.invited}>
          {inviteOrg?.tenantName || inviteProject?.projectName}
          {InviteType[inviteType]}
        </span>
      </div>
      {isLogin && (
        <div className={styles.inviteTips}>
          <span>请先登录，若没有账号请先</span>
          <Link to="/login/register">注册账户</Link>
        </div>
      )}
      {removeVisible && (
        <RemoveModal visible={removeVisible} title="链接过期" onOk={handleRemoveOk}>
          <p>该邀请链接已过期，请联系管理员重新获取链接</p>
        </RemoveModal>
      )}
    </>
  ) : (
    <>
    {children}
    {removeVisible && (
        <RemoveModal visible={removeVisible} title="链接过期" onOk={handleRemoveOk}>
          <p>该邀请链接已过期，请联系管理员重新获取链接</p>
        </RemoveModal>
      )}
    </>
  )
}

export default Invite
