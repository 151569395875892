import React, { useMemo } from 'react'
import ReactECharts from 'echarts-for-react'
import { PowerStationDetail } from '../type'
import moment from 'moment'

import '../index.less'

type IProps = {
  detail: PowerStationDetail
}

const PowerStationMonitor: React.FC<IProps> = React.memo((props) => {
  const { detail } = props
  const { generationElectricityChart } = detail || {}
  const { xAxisData, yAxisData } = useMemo(() => {
    const xAxisData = []
    const yAxisData = []
    const list = generationElectricityChart || []
    for (let i = 0; i < list.length; i++) {
      const { xdata, ydata } = list[i] || {}
      xAxisData.push(moment(xdata).format('YYYY-MM-DD'))
      yAxisData.push(ydata)
    }
    if (!xAxisData.length) {
      xAxisData.push(1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15)
    }
    if (!yAxisData.length) {
      yAxisData.push(0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0)
    }
    return {
      xAxisData,
      yAxisData,
    }
  }, [generationElectricityChart])
  return (
    <div className="power-station-monitor">
      <div className="power-station-monitor-header">
        <h3 className="power-station-monitor-title">发电量监控</h3>
      </div>
      <div className="power-station-monitor-content">
        <ReactECharts
          style={{
            width: '100%',
            height: 194,
          }}
          option={{
            grid: { left: 15, right: 5, top: 35, bottom: 0, containLabel: true },
            tooltip: {
              trigger: 'axis',
              formatter: (argsList: any) => {
                const args = argsList[0]
                return `${args.marker}${args.name}<br /><div style="text-align: right;">${args.value}kWh</div>`
              },
            },
            xAxis: {
              type: 'category',
              // name: '日',
              nameTextStyle: {
                verticalAlign: 'top',
                padding: [8, 0, 0, 0],
                color: '#98A0B3',
              },
              nameGap: -10,
              data: xAxisData,
              axisTick: {
                show: false,
              },
            },
            yAxis: {
              type: 'value',
              name: 'kWh',
              nameTextStyle: {
                padding: [0, 50, 5, 0],
                color: '#98A0B3',
              },
              splitLine: {
                lineStyle: {
                  type: 'dashed',
                  color: '#CFD6E8',
                },
              },
            },
            series: [
              {
                data: yAxisData,
                type: 'bar',
                barWidth: 8,
                barMaxWidth: 8,
                barCategoryGap: 4,
                itemStyle: {
                  color: '#1EC692',
                  borderRadius: [2, 2, 0, 0],
                },
              },
            ],
          }}
        />
      </div>
    </div>
  )
})

export default PowerStationMonitor
