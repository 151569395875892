import React from 'react'
import { useNavigate } from 'react-router-dom'
import IconBack from '../../../../../assets/back.png'

import './index.less'

type CardTitleProps = {
  title?: React.ReactNode
  showBack?: boolean
  path?: string
}

const CardTitle = React.memo((props: CardTitleProps) => {
  const navigate = useNavigate()
  const { title, showBack = true, path } = props

  const goBack = () => (path ? navigate(path) : navigate(-1))

  return (
    <div className="card-title">
      {showBack && (
        <div className="card-title-back" onClick={goBack}>
          <img src={IconBack} alt="返回" />
        </div>
      )}
      <h3>{title}</h3>
    </div>
  )
})

export default CardTitle
