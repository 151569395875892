import { Col, Row } from 'antd'

import styles from './index.module.scss'
import { Outlet, useNavigate } from 'react-router-dom'
import React, { useEffect } from 'react'
import Global from '../../global'
import Logo from '../../Layout/components/Logo'

declare var isDemoEnv: boolean

const LoginPage = () => {
  const nav = useNavigate()

  useEffect(() => {
    if (Global.user?.id) {
      nav('/')
    }
  }, [])

  return (
    <>
      <div style={{ backgroundImage: 'url(/assets/background.png)', backgroundSize: 'cover' }}>
        <div className={styles.loginPage}>
          <div className="login-wrap">
            <div>
              <Row justify="center">
                <Col xxl={10} xl={10} lg={12} md={14} xs={20}>
                  <div className="login-left">
                    <div className="login-text">
                      <div className="logo">
                        <Logo
                          size={50}
                          cantJump={true}
                          image={isDemoEnv ? './logo-demo.png' : undefined}
                          text={isDemoEnv ? '' : <img src={`./logo-text.svg`} style={{ height: 60 }} alt="logo-text" />}
                        />
                      </div>
                      <div className="title">数字化新能源助力中国低碳经济发展</div>
                      <div className="platform-desc">做领先的低碳能源资产一站式管理平台</div>
                    </div>
                    <div className={styles.loginBgShadow}>
                      <img src="assets/login-bg.png" alt="bg" />
                      <img src="/assets/login-bgshadow.png" className="bg" alt="bg" />
                    </div>
                  </div>
                </Col>
                <Col xxl={6} xl={10} lg={12} md={20} xs={20}>
                  <div className="login-right">
                    <Outlet />
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default LoginPage
