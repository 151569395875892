/**
 * project
 * unknown
 *
 * OpenAPI spec version: last
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import ajax, { AjaxPromise, ExtraFetchParams } from '@ajax';
import * as models from '../model/models';

/* tslint:disable:no-unused-variable member-ordering object-literal-shorthand */

/**
  * @description i3060ProjectManageDeleteProjectIdPost参数
  * @property `projectId` 项目id(Long)
  * @property `type` 删除类型 1.普通删除  2.永久删除(Integer)
  */
export interface Paramsi3060ProjectManageDeleteProjectIdPost {
  // pathParams
  /**
   * 项目id(Long)
   */
  projectId: string;
  // queryParams
  /**
   * 删除类型 1.普通删除  2.永久删除(Integer)
   */
  type: string;
}
/**
  */
export type ParamsBodyi3060ProjectManageDeleteProjectIdPost = any;
/**
  * @description i3060ProjectManageListUserIdGet参数
  * @property `userId` 用户id(Long)
  * @property `[keyword]` 关键词(String)
  */
export interface Paramsi3060ProjectManageListUserIdGet {
  // pathParams
  /**
   * 用户id(Long)
   */
  userId: string;
  // queryParams
  /**
   * 关键词(String)
   */
  keyword?: string;
}
/**
  * @description i3060ProjectManagePageGet参数
  * @property `keyword` 关键词搜索
  * @property `page` 
  * @property `size` 
  */
export interface Paramsi3060ProjectManagePageGet {
  // queryParams
  /**
   * 关键词搜索
   */
  keyword: string;
  /**
   * 
   */
  page: string;
  /**
   * 
   */
  size: string;
}
/**
  * @description i3060ProjectManageRecycleBinGet参数
  * @property `keyword` 关键词搜索
  * @property `page` 
  * @property `size` 
  */
export interface Paramsi3060ProjectManageRecycleBinGet {
  // queryParams
  /**
   * 关键词搜索
   */
  keyword: string;
  /**
   * 
   */
  page: string;
  /**
   * 
   */
  size: string;
}
/**
  * @description i3060projectManageRecoveryProjectIdPost参数
  * @property `projectId` 项目id(Long)
  * @property `projectName` 项目名称(String)
  */
export interface Paramsi3060projectManageRecoveryProjectIdPost {
  // pathParams
  /**
   * 项目id(Long)
   */
  projectId: string;
  // queryParams
  /**
   * 项目名称(String)
   */
  projectName: string;
}

export class ProjectManageControllerApi {
  protected $basePath = '/api'.replace(/\/$/, '');

  public constructor(basePath?: string) {
    if (basePath !== undefined) {
      this.$basePath = basePath.replace(/\/$/, '');
    }
  }

  /**
   * ```   /_**      * 删除项目      *      * @param projectId 项目id      * @param type      删除类型 1.普通删除  2.永久删除      * @return      *_/     @PostMapping(\"/delete/{projectId}\")     public Result<Boolean> deleteProject(@PathVariable Long projectId,@NotNull(message = \"删除类型不能为null\") @RequestParam(\"type\") Integer type) ```
   * @summary 删除项目
   * @param params Paramsi3060ProjectManageDeleteProjectIdPost
   * @param data: ParamsBodyi3060ProjectManageDeleteProjectIdPost
   * @param opt ajax config
   * @returns models.ResultBoolean
   */
  public i3060ProjectManageDeleteProjectIdPost = (
    params: Paramsi3060ProjectManageDeleteProjectIdPost,
    data: ParamsBodyi3060ProjectManageDeleteProjectIdPost,opt?: ExtraFetchParams
  ) : AjaxPromise<models.ResultBoolean>  => {
    const {
    projectId,
} = params;
    const url = this.$basePath + `/i3060/project/manage/delete/${projectId}`;
    const p: any = {};
    p.query = {};
    if ('type' in params) p.query.type = params.type;
    ajax.check(params.type, 'type');
    ajax.check(params.projectId, 'projectId');
    p.data = data;
    return ajax.ajax({
      ...opt,
      method: 'POST',
      url,
      ...p
    });
  }
  /**
   * 
   * @summary 项目列表（根据成员查询）
   * @param params Paramsi3060ProjectManageListUserIdGet
   
   * @param opt ajax config
   * @returns models.ResultListProjectListRes
   */
  public i3060ProjectManageListUserIdGet = (
    params: Paramsi3060ProjectManageListUserIdGet,
    opt?: ExtraFetchParams
  ) : AjaxPromise<models.ResultListProjectListRes>  => {
    const {
    userId,
} = params;
    const url = this.$basePath + `/i3060/project/manage/list/${userId}`;
    const p: any = {};
    p.query = {};
    if ('keyword' in params) p.query.keyword = params.keyword;
    ajax.check(params.userId, 'userId');
    return ajax.ajax({
      ...opt,
      method: 'GET',
      url,
      ...p
    });
  }
  /**
   * ```   /_**      * 项目管理      *      * @return      *_/     @GetMapping(\"/page\")     public Result<RPage<ProjectManageRes>> selectManageList(@ModelAttribute ProjectQueryReq req) ```
   * @summary 项目管理
   * @param params Paramsi3060ProjectManagePageGet
   
   * @param opt ajax config
   * @returns models.ResultRPageProjectManageRes
   */
  public i3060ProjectManagePageGet = (
    params: Paramsi3060ProjectManagePageGet,
    opt?: ExtraFetchParams
  ) : AjaxPromise<models.ResultRPageProjectManageRes>  => {
    const url = this.$basePath + `/i3060/project/manage/page`;
    const p: any = {};
    p.query = {};
    if ('keyword' in params) p.query.keyword = params.keyword;
    ajax.check(params.keyword, 'keyword');
    if ('page' in params) p.query.page = params.page;
    ajax.check(params.page, 'page');
    if ('size' in params) p.query.size = params.size;
    ajax.check(params.size, 'size');
    return ajax.ajax({
      ...opt,
      method: 'GET',
      url,
      ...p
    });
  }
  /**
   * 
   * @summary 给项目初始化负责人以及租户
   
   
   * @param opt ajax config
   * @returns any
   */
  public i3060ProjectManageProjectDataRepairGet = (
    
    opt?: ExtraFetchParams
  ) : AjaxPromise<any>  => {
    const url = this.$basePath + `/i3060/project/manage/project/dataRepair`;
    const p: any = {};
    return ajax.ajax({
      ...opt,
      method: 'GET',
      url,
      ...p
    });
  }
  /**
   * ```   /_**      * 回收站列表      *      * @return      *_/     @GetMapping(\"/recycle/bin\")     public Result<RPage<ProjectRecycleBinRes>> selectRecycleBinList(@ModelAttribute ProjectQueryReq req) ```
   * @summary 回收站列表
   * @param params Paramsi3060ProjectManageRecycleBinGet
   
   * @param opt ajax config
   * @returns models.ResultRPageProjectRecycleBinRes
   */
  public i3060ProjectManageRecycleBinGet = (
    params: Paramsi3060ProjectManageRecycleBinGet,
    opt?: ExtraFetchParams
  ) : AjaxPromise<models.ResultRPageProjectRecycleBinRes>  => {
    const url = this.$basePath + `/i3060/project/manage/recycle/bin`;
    const p: any = {};
    p.query = {};
    if ('keyword' in params) p.query.keyword = params.keyword;
    ajax.check(params.keyword, 'keyword');
    if ('page' in params) p.query.page = params.page;
    ajax.check(params.page, 'page');
    if ('size' in params) p.query.size = params.size;
    ajax.check(params.size, 'size');
    return ajax.ajax({
      ...opt,
      method: 'GET',
      url,
      ...p
    });
  }
  /**
   * ```   /_**      * 项目恢复      *      * @param projectId   项目id      * @param projectName 项目名称      * @return      *_/     @PostMapping(\"/recovery/{projectId}\")     public Result<Boolean> recoveryProject(@PathVariable Long projectId,@RequestParam(\"projectName\") String projectName) ```
   * @summary 项目恢复
   * @param params Paramsi3060projectManageRecoveryProjectIdPost
   
   * @param opt ajax config
   * @returns models.ResultBoolean
   */
  public i3060projectManageRecoveryProjectIdPost = (
    params: Paramsi3060projectManageRecoveryProjectIdPost,
    opt?: ExtraFetchParams
  ) : AjaxPromise<models.ResultBoolean>  => {
    const {
    projectId,
} = params;
    const url = this.$basePath + `/i3060project/manage/recovery/${projectId}`;
    const p: any = {};
    p.query = {};
    if ('projectName' in params) p.query.projectName = params.projectName;
    ajax.check(params.projectName, 'projectName');
    ajax.check(params.projectId, 'projectId');
    return ajax.ajax({
      ...opt,
      method: 'POST',
      url,
      ...p
    });
  }
}

export default new ProjectManageControllerApi();