import { UserOutlined } from '@ant-design/icons'
import { Button, message, Modal, Select, Table, Tooltip } from 'antd'
import React, { useEffect, useState } from 'react'
import { CustomIcons } from '../../../../global'
import HelperJurisdictionControllerApi from '../../../../services/api/HelperJurisdictionControllerApi'
import AddMembersModal from './AddTeamworkerModal'

const { Option } = Select

const ManageMembersModal = React.memo((props: any) => {
  const roleTypeList = [
    { id: 1, name: '仅查看' },
    { id: 2, name: '编辑' },
    { id: 3, name: '拷贝' },
  ]
  const {
    isModalVisible,
    setIsModalVisible,
    row: { projectId, id },
  }: any = props
  const [isVisible, setIsVisible] = useState(false)
  const [loading, setLoading] = useState(false)
  const [memberList, setMemberList] = useState<any[]>([])

  const fetchMemberList = () => {
    console.log(projectId)
    if (loading) return
    setLoading(true)
    HelperJurisdictionControllerApi.i3060HelperJurisdictionListDataIdGet({
      dataId: id,
      dataType: '1',
    })
      .then((res) => {
        debugger
        setLoading(false)
        if (res.code === 200) setMemberList(res?.data || [])
        else message.error(res.msg)
      })
      .catch(() => {
        setLoading(false)
      })
  }

  const removeCheck = ({ id }) => {
    HelperJurisdictionControllerApi.i3060HelperJurisdictionDeleteIdDelete({ id }, { id })
      .then((res) => {
        setLoading(false)
        if (res.code === 200) fetchMemberList()
        else message.error(res.msg)
      })
      .catch(() => {
        setLoading(false)
      })
  }
  useEffect(() => {
    if (id && isModalVisible) fetchMemberList()
  }, [id, isModalVisible])
  return (
    <>
      <Modal
        title="管理协作者"
        maskClosable={false}
        visible={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        footer={null}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignContent: 'center',
            marginBottom: 13,
            marginTop: -6,
          }}
        >
          <Button type="link" onClick={() => setIsVisible(true)}>
            添加协作者
          </Button>
        </div>
        <Table
          pagination={false}
          dataSource={memberList}
          columns={[
            {
              title: '成员',
              dataIndex: 'userName',
              key: 'userName',
              width: 150,
              ellipsis: {
                showTitle: false,
              },
              render: (text: string) => (
                <>
                  <UserOutlined className="add-members-item-avatar" />
                  <Tooltip title={text}>
                    <span className="add-members-item-name">{text}</span>
                  </Tooltip>
                </>
              ),
            },
            {
              title: '方案权限',
              dataIndex: 'roleType',
              key: 'roleType',
              width: 168,
              render: (text: string, row: any) => (
                <div className="add-members-item-action">
                  <Select
                    style={{ width: '80%' }}
                    showSearch
                    bordered={true}
                    value={row.roleType}
                    placeholder="请选择"
                    disabled
                  >
                    {roleTypeList?.map((res: any) => (
                      <Option value={res.id} key={res.id}>
                        {res.name}
                      </Option>
                    ))}
                  </Select>
                  <Button
                    style={{ padding: 0, width: '20%' }}
                    onClick={() => removeCheck(row)}
                    size="small"
                    type="text"
                    icon={<CustomIcons type="klf-delete" />}
                    // icon={<DeleteOutlined />}
                  />
                </div>
              ),
            },
          ]}
        />
      </Modal>

      <AddMembersModal
        {...{
          visible: isVisible,
          onOk: () => {
            setIsVisible(false)
            fetchMemberList()
          },
          onCancel: () => setIsVisible(false),
          dataId: id,
          projectId,
        }}
      ></AddMembersModal>
    </>
  )
})

export default ManageMembersModal
