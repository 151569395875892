import {
  Button,
  Card,
  Col,
  Form,
  Image,
  InputNumber,
  message,
  Modal,
  Row,
  Slider,
  Spin,
  Table,
  Timeline,
  Tooltip,
  Upload,
} from 'antd'
import React, { useEffect } from 'react'
import '../../index.less'
import * as echarts from 'echarts'
import ReactECharts from 'echarts-for-react'
import { ProjectServiceHistory } from '../../../../apis/project-service-history'
import { useParams } from 'react-router-dom'
import { bearObservable, Empty, InfoList } from '../index'
import { IndustrySelector, SceneSelector } from '../../../../components/industry-selector'
import { CloudUploadOutlined, DownloadOutlined } from '@ant-design/icons'
import axios from 'axios'
import { token } from '../../../../utils/utils'
import { BearTemplate } from './bear-template'
import moment from 'moment'
import { ApplicationModal } from '../../../../application'
import Global, { CustomIcons } from '../../../../global'
import _ from 'lodash'

const size = 'middle'
const showIndustry = false

const formItemLayout = {
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 12 },
  },
}

const translateMap: { [key: string]: string } = {
  industryName: '用户行业',
  sceneName: '用电场景',
  transformerTotalCapacity: '变压器总容量',
  maxLoad: '最大负载率',
  fluctuate: '波动率',
}

const seasonMap: { [key: string]: string }[] = [
  { springWorkday: '春季', summerWorkday: '夏季', autumnWorkday: '秋季', winterWorkday: '冬季' },
  { springHoliday: '春季', summerHoliday: '夏季', autumnHoliday: '秋季', winterHoliday: '冬季' },
]

const areaColorMap: { [key: string]: { color: (opacity?: number) => string } } = {
  春季: {
    color: (opacity) => {
      return `rgba(30, 198, 146, ${opacity || 1})`
    },
  },
  夏季: {
    color: (opacity) => {
      return `rgba(50, 116, 246, ${opacity || 1})`
    },
  },
  秋季: {
    color: (opacity) => {
      return `rgba(249, 121, 66, ${opacity || 1})`
    },
  },
  冬季: {
    color: (opacity) => {
      return `rgba(246, 185, 50, ${opacity || 1})`
    },
  },
  0: {
    color: (opacity) => {
      return `rgba(30, 198, 146, ${opacity || 1})`
    },
  },
  1: {
    color: (opacity) => {
      return `rgba(50, 116, 246, ${opacity || 1})`
    },
  },
  2: {
    color: (opacity) => {
      return `rgba(249, 121, 66, ${opacity || 1})`
    },
  },
  3: {
    color: (opacity) => {
      return `rgba(246, 185, 50, ${opacity || 1})`
    },
  },
  4: {
    color: (opacity) => {
      return `rgba(181, 50, 246, ${opacity || 1})`
    },
  },
  5: {
    color: (opacity) => {
      return `rgba(39, 195, 230, ${opacity || 1})`
    },
  },
}

export const Bear = (props: any) => {
  const params = useParams()
  const [selected, setSelected] = React.useState<number>(0)
  const [inited, setInited] = React.useState(false)
  const [bearForm] = Form.useForm()
  const [chartData, setChartData] = React.useState<any>()
  const [finished, setFinished] = React.useState(false)
  const [forceEdit, setForceEdit] = React.useState(false)
  const [result, setResult] = React.useState<any>({})
  const [, setLoading] = React.useState(true)

  const [file, setFile] = React.useState<File>()
  const [uploading, setUploading] = React.useState(false)

  const [currentStep, setCurrentStep] = React.useState(1)

  const [templateForm] = Form.useForm()

  const [template, setTemplate] = React.useState<any>()

  const [downloading, setDownloading] = React.useState(false)

  const auth = Global.projectAuth || {}
  const hasAuth = !!auth.load_curve

  const downloadTemplate = (projectId: string, body: any) => {
    setDownloading(true)
    const prefix = axios.defaults.baseURL
    const url = `${prefix || ''}/api/i3060/load/template/${projectId}`
    const authToken: string = token.get() || ''
    fetch(url, {
      method: 'POST',
      headers: {
        Authorization: authToken,
        'Content-Type': 'application/json;charset=UTF-8',
      },
      body: JSON.stringify(body),
    })
      .then((resp) => {
        let fileName =
          resp.headers.get('content-disposition')?.split(';')[1].split('filename=')[1] || `template-${Date.now()}.xlsx`
        resp.blob().then((blob) => {
          if ((window.navigator as any).msSaveOrOpenBlob) {
            ;(window.navigator as any).msSaveBlob(blob, fileName)
          } else {
            const a = document.createElement('a')
            document.body.appendChild(a)
            const url = window.URL.createObjectURL(blob)
            a.href = url
            a.download = fileName
            a.target = '_blank'
            a.click()
            a.remove()
            window.URL.revokeObjectURL(url)
          }
          setCurrentStep(3)
        })
      })
      .finally(() => {
        setDownloading(false)
      })
  }

  React.useEffect(() => {
    bearForm.setFieldsValue({
      fluctuate: 0,
      industryCode: '',
      sceneName: '',
      maxLoad: 100,
      transformerTotalCapacity: '1000',
    })

    setLoading(false)

    if (!props.projectId) {
      const subscription = InfoList.subscribe((o) => {
        setFinished(o.bear.finished)
        setForceEdit(o.bear.forceEdit)
      })
      return () => {
        subscription.unsubscribe()
      }
    } else {
      setFinished(true)
      setForceEdit(false)
    }
  }, [])

  useEffect(() => {
    if (forceEdit) {
      loadResult()
    }
  }, [forceEdit])

  useEffect(() => {
    if (finished) {
      loadResult()
    } else {
      setInited(true)
    }
  }, [finished])

  useEffect(() => {
    const { industryCode, sceneName = '', transformerTotalCapacity, maxLoad, fluctuate } = result
    bearForm.setFieldsValue({
      industryCode: industryCode || '',
      sceneName,
      transformerTotalCapacity: transformerTotalCapacity || 1000,
      maxLoad: maxLoad || 100,
      fluctuate: fluctuate || 0,
    })
    setResult({ ...result })
  }, [selected])

  const loadIndustryChartData = (code: string) => {
    setChartData(undefined)
    setLoading(true)
    if (code) {
      ProjectServiceHistory.industryStandardModel({
        industryCode: code,
      })
        .then((resp) => {
          if (!resp.data || resp.data.length === 0) {
            message.warning('该行业暂未提供典型负荷曲线，仅能通过离线负荷数据设置负荷曲线')
          }
          const _chartData: any = {
            time: [],
            value: [],
          }
          resp.data?.forEach(({ name, value }: any) => {
            _chartData.time.push(name)
            _chartData.value.push(value)
          })

          setChartData({ ..._chartData })
        })
        .catch(() => {})
    }
  }

  const getDailyEle = () => {
    if (chartData) {
      const solvedData = getSolvedChartData(chartData.value, true)
      const sum = _.sumBy(solvedData.map((o: any) => parseFloat(o))) / 4
      return parseFloat(sum.toFixed(2))
    }
  }

  const getParamId = () => {
    if (props.projectId) {
      return props.projectId!
    } else {
      return params.id!
    }
  }

  const loadResult = () => {
    setChartData(undefined)
    setInited(false)
    ProjectServiceHistory.chart({
      projectId: getParamId(),
    })
      .then((resp) => {
        const {
          startTime,
          endTime,
          type,
          governanceInfo: transformer,
          industryCode = '',
          sceneName = '',
          interval,
          transformerTotalCapacity,
          maxLoad,
          fluctuate,
        } = resp.data
        resp.data = {
          ...resp.data,
          industryCode,
          sceneName,
          transformerTotalCapacity: transformerTotalCapacity || 1000,
          maxLoad: maxLoad || 100,
          fluctuate: fluctuate || 0,
        }
        bearForm.setFieldsValue({
          industryCode,
          sceneName,
          transformerTotalCapacity: transformerTotalCapacity || 1000,
          maxLoad: maxLoad || 100,
          fluctuate: fluctuate || 0,
        })

        if (type === 2) {
          const template = {
            startTime: startTime + ' 00:00:00',
            endTime: endTime + ' 23:59:59',
            level: interval,
            transformer: Object.values(transformer).map((o: any) => ({
              transformerName: o.name,
              maxCapacity: o.transformerTotalCapacity,
            })),
          }
          setTemplate(template)
          templateForm.setFieldsValue({
            timeRange: [
              (template.startTime && moment(template.startTime)) || undefined,
              (template.endTime && moment(template.endTime)) || undefined,
            ],
            level: template.level,
            transformer: template.transformer,
          })

          setCurrentStep(3)
        }

        if (industryCode) {
          loadIndustryChartData(industryCode)
        }
        if (sceneName) {
          loadIndustryChartData(sceneName)
        }
        setResult(resp.data)
        setSelected(type - 1)
      })
      .catch(() => {})
      .finally(() => {
        setInited(true)
      })
  }

  const getBaseLine = (val: number, maxPower: number, cap: number) => {
    let res = +val * 1.0
    res = (res / maxPower) * cap * (parseFloat(bearForm.getFieldValue('maxLoad')) / 100)
    return res
  }

  const getLine = (val: number, maxPower: number, wav: number, mean: number, std: number, cap: number) => {
    let res = +val * 1.0
    res = (res - mean) * (1 + (wav / 100 / 2) * (1 - 2 * Math.log(std / mean))) + mean
    res < 0 && (res = 0)
    res > cap && (res = cap)
    return res
  }

  const avg = function (arr: number[]) {
    const len = arr.length
    let sum = 0.0
    for (let i = 0; i < len; i++) {
      sum += arr[i] * 1
    }
    return sum / len
  }

  const solveStd = (baseData: number[] = []) => {
    const avgValue = avg(baseData)
    const length = baseData.length
    let powSum = 0.0
    for (let i = 0; i < length; i++) {
      const dev = baseData[i] - avgValue // 计算数组元素与平均值的差
      powSum += dev ** 2 // 计算差的平方
    }
    return Math.sqrt(powSum / length)
  }

  const getSolvedChartData = (originValues = [], adjust = false) => {
    const { transformerTotalCapacity, fluctuate } = bearForm.getFieldsValue()
    const cap = parseFloat(transformerTotalCapacity)
    const wave = parseFloat(fluctuate)
    const maxPower = Math.max(...originValues)
    const baseLine = originValues.map((o: number) => {
      return getBaseLine(o, maxPower, cap)
    })
    const avgValue = avg(baseLine)
    const std = solveStd(baseLine)

    if (adjust) {
      const res = baseLine.map((o: number) => {
        const result = getLine(o, maxPower, wave, avgValue, std, cap)
        if (result) {
          return parseFloat(result.toFixed(1))
        } else {
          return '0.00'
        }
      })
      return res
    } else {
      const base = baseLine.map((o: number) => parseFloat(o.toFixed(1)))
      return base
    }
  }

  const getTextResult = (key: string, val: string) => {
    const keys = Object.keys(translateMap).filter((o, idx) => idx === 3 || idx === 4)
    if (keys.includes(key)) {
      return `${val}%`
    }
    const keys2 = Object.keys(translateMap).filter((o, idx) => idx === 2)
    if (keys2.includes(key)) {
      return `${val}kVA`
    }
    return val
  }

  const getGovernData = (isAfter = true, title?: string, top = 70) => {
    if (!(isAfter ? result.afterGovernance : result.beforeGovernance)) {
      return null
    }
    return (
      <ReactECharts
        style={{
          width: '100%',
          height: '350px',
        }}
        option={{
          animationDuration: 50,
          title: { text: title !== undefined ? title : isAfter ? '治理后' : '治理前' },
          tooltip: {
            trigger: 'axis',
            formatter: (args: any) => {
              let res = `<strong>${args[0].axisValue}</strong>`
              if (args.length > 0) {
                res += args
                  .map(
                    (o: any) =>
                      `<div style='width: 200px;'>${o.marker}${o.seriesName} <span style='float: right'>${o.value}kW</span></div>`
                  )
                  .join('')
              }
              return res
            },
          },
          grid: { left: '30px', right: '3%', top, bottom: '30px', containLabel: true },
          xAxis: {
            type: 'category',
            boundaryGap: true,
            data: result.time,
          },
          yAxis: {
            type: 'value',
            name: '单位：kW',
            nameTextStyle: {
              color: 'gray',
            },
          },
          legend: {
            show: true,
            top: 'bottom',
            icon: 'rect',
          },
          series: Object.keys(isAfter ? result.afterGovernance : result.beforeGovernance).map((o, index) => {
            return {
              name: o,
              type: 'line',
              // sampling: 'lttb',
              showSymbol: false,
              itemStyle: {
                color: areaColorMap[index].color(),
              },
              areaStyle: {
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  {
                    offset: 0,
                    color: areaColorMap[index].color(0.1),
                  },
                  {
                    offset: 1,
                    color: areaColorMap[index].color(0.01),
                  },
                ]),
              },
              lineStyle: {
                width: 1,
              },
              data: (isAfter ? result.afterGovernance : result.beforeGovernance)[o],
            }
          }),
        }}
      />
    )
  }

  const reloadTemplateData = () => {
    if (!template) {
      templateForm.resetFields()
      return
    }
    templateForm.setFieldsValue({
      timeRange: [
        (template.startTime && moment(template.startTime)) || undefined,
        (template.startTime && moment(template.endTime)) || undefined,
      ],
      level: template.level,
      transformer: template.transformer,
    })
  }

  if (!inited) {
    return (
      <div style={{ height: '100%', overflowY: 'hidden', textAlign: 'center' }}>
        <Spin spinning></Spin>
      </div>
    )
  }

  return finished || hasAuth ? (
    <div style={{ height: '100%', overflowY: 'auto' }}>
      <div className="button-group">
        <Button
          disabled={finished && !forceEdit}
          onClick={() => {
            setSelected(0)
          }}
          className={`${selected === 0 ? 'active' : ''}`}
          shape="round"
          size={size}
        >
          典型负荷数据
        </Button>
        <Button
          disabled={finished && !forceEdit}
          onClick={() => {
            setSelected(1)
          }}
          className={`${selected === 1 ? 'active' : ''}`}
          shape="round"
          size={size}
        >
          上传负荷数据
        </Button>
        <Button
          disabled
          onClick={() => {
            setSelected(2)
          }}
          className={`${selected === 2 ? 'active' : ''}`}
          shape="round"
          size={size}
        >
          物联负荷数据
        </Button>
      </div>
      <div
        className="bear-content"
        style={{
          overflowY: 'auto',
          height: 'calc(100vh - 360px)',
        }}
      >
        {selected === 0 && (
          <div>
            {!forceEdit && finished && (
              <div>
                <div
                  style={{
                    marginTop: '1rem',
                  }}
                >
                  <Row>
                    {Object.keys(translateMap)?.map((key: string) => {
                      if (!result[key]) {
                        return null
                      }
                      return (
                        <Col span={6} key={key}>
                          <strong>{translateMap[key]}：</strong>
                          <span>{getTextResult(key, result[key])}</span>
                        </Col>
                      )
                    })}
                  </Row>
                </div>
                <div
                  style={{
                    marginTop: '1rem',
                  }}
                >
                  <ReactECharts
                    style={{
                      width: '100%',
                      height: '350px',
                    }}
                    option={{
                      animationDuration: 50,
                      title: { text: '工作日' },
                      tooltip: {
                        trigger: 'axis',
                      },
                      grid: { left: '15px', right: '3%', top: '70px', bottom: 40, containLabel: true },
                      xAxis: {
                        type: 'category',
                        boundaryGap: false,
                        data: result.time,
                      },
                      yAxis: {
                        type: 'value',
                        name: '单位：kW',
                        nameTextStyle: {
                          color: 'gray',
                        },
                      },
                      legend: {
                        show: true,
                        top: 'bottom',
                        icon: 'rect',
                      },
                      series: Object.keys(seasonMap[0]).map((o) => {
                        return {
                          name: seasonMap[0][o],
                          type: 'line',
                          sampling: 'lttb',
                          showSymbol: false,
                          itemStyle: {
                            color: areaColorMap[seasonMap[0][o]].color(),
                          },
                          areaStyle: {
                            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                              {
                                offset: 0,
                                color: areaColorMap[seasonMap[0][o]].color(0.1),
                              },
                              {
                                offset: 1,
                                color: areaColorMap[seasonMap[0][o]].color(0.01),
                              },
                            ]),
                          },
                          lineStyle: {
                            width: 1,
                          },
                          data: result[o],
                        }
                      }),
                    }}
                  />
                </div>
                <div
                  style={{
                    marginTop: '1rem',
                  }}
                >
                  <ReactECharts
                    style={{
                      width: '100%',
                      height: '350px',
                    }}
                    option={{
                      animationDuration: 50,
                      title: { text: '非工作日' },
                      tooltip: {
                        trigger: 'axis',
                      },
                      grid: { left: '15px', right: '3%', top: '70px', bottom: 40, containLabel: true },
                      xAxis: {
                        type: 'category',
                        boundaryGap: false,
                        data: result.time,
                      },
                      yAxis: {
                        type: 'value',
                        name: '单位：kW',
                        nameTextStyle: {
                          color: 'gray',
                        },
                      },
                      legend: {
                        show: true,
                        top: 'bottom',
                        icon: 'rect',
                      },
                      series: Object.keys(seasonMap[1]).map((o) => {
                        return {
                          name: seasonMap[1][o],
                          type: 'line',
                          sampling: 'lttb',
                          showSymbol: false,
                          itemStyle: {
                            color: areaColorMap[seasonMap[1][o]].color(),
                          },
                          areaStyle: {
                            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                              {
                                offset: 0,
                                color: areaColorMap[seasonMap[1][o]].color(0.1),
                              },
                              {
                                offset: 1,
                                color: areaColorMap[seasonMap[1][o]].color(0.01),
                              },
                            ]),
                          },
                          lineStyle: {
                            width: 1,
                          },
                          data: result[o],
                        }
                      }),
                    }}
                  />
                </div>
              </div>
            )}
            {(forceEdit || !finished) && (
              <div>
                <Form
                  className="bear-form"
                  {...formItemLayout}
                  form={bearForm}
                  onFinish={(values: any) => {
                    ProjectServiceHistory.standard3({
                      projectId: getParamId(),
                      root: values,
                    })
                      .then(() => {
                        bearObservable.next(true)
                        loadResult()
                      })
                      .catch(() => {})
                  }}
                >
                  {!showIndustry && (
                    <Form.Item
                      name="sceneName"
                      label="用电场景："
                      rules={[{ required: true, message: '请选择用电场景' }]}
                    >
                      <SceneSelector
                        onChange={(val) => {
                          loadIndustryChartData(val)
                        }}
                      />
                    </Form.Item>
                  )}
                  {showIndustry && (
                    <Form.Item
                      name="industryCode"
                      label="用户行业："
                      rules={[{ required: true, message: '请选择用户行业' }]}
                    >
                      <IndustrySelector
                        onChange={(val) => {
                          loadIndustryChartData(val)
                        }}
                      />
                    </Form.Item>
                  )}
                  <Form.Item
                    name="transformerTotalCapacity"
                    label="变压器总容量："
                    rules={[{ required: true, message: '请输入变压器总容量' }]}
                  >
                    <InputNumber
                      style={{
                        width: '100%',
                      }}
                      controls={false}
                      addonAfter="kVA"
                      max={999999}
                      min={0}
                      onBlur={() => {
                        setChartData({ ...chartData })
                      }}
                    />
                  </Form.Item>
                  <Form.Item
                    name="maxLoad"
                    label="最大负载率："
                    rules={[{ required: true, message: '请选择最大负载率' }]}
                  >
                    <Row>
                      <Col span={15}>
                        <Slider
                          min={0}
                          max={100}
                          onChange={(val: number) => {
                            bearForm.setFieldsValue({ maxLoad: val })
                            setChartData({ ...chartData })
                          }}
                          value={bearForm.getFieldValue('maxLoad')}
                          step={1}
                        />
                      </Col>
                      <Col span={8} offset={1}>
                        <InputNumber
                          controls={false}
                          style={{
                            width: '100%',
                          }}
                          min={0}
                          max={100}
                          type="number"
                          addonAfter="%"
                          value={bearForm.getFieldValue('maxLoad')}
                          onChange={(val: any) => {
                            bearForm.setFieldsValue({ maxLoad: val })
                            setChartData({ ...chartData })
                          }}
                          onBlur={(e: any) => {
                            if (e.target.value > 100) {
                              bearForm.setFieldsValue({ maxLoad: 100 })
                              setChartData({ ...chartData })
                            }
                          }}
                          precision={2}
                        />
                      </Col>
                    </Row>
                  </Form.Item>
                  <Form.Item name="fluctuate" label="波动率：" rules={[{ required: true, message: '请选择波动率' }]}>
                    <Row>
                      <Col span={15}>
                        <Slider
                          min={0}
                          max={100}
                          onChange={(val: number) => {
                            bearForm.setFieldsValue({ fluctuate: val })
                            setChartData({ ...chartData })
                          }}
                          value={bearForm.getFieldValue('fluctuate')}
                          step={1}
                        />
                      </Col>
                      <Col span={8} offset={1}>
                        <InputNumber
                          controls={false}
                          style={{
                            width: '100%',
                          }}
                          min={0}
                          max={100}
                          type="number"
                          addonAfter="%"
                          value={bearForm.getFieldValue('fluctuate')}
                          onChange={(val: any) => {
                            bearForm.setFieldsValue({ fluctuate: val })
                            setChartData({ ...chartData })
                          }}
                          onBlur={(e: any) => {
                            if (e.target.value > 100) {
                              bearForm.setFieldsValue({ fluctuate: 100 })
                              setChartData({ ...chartData })
                            }
                          }}
                          precision={2}
                        />
                      </Col>
                    </Row>
                  </Form.Item>
                  <Form.Item label="负荷曲线：" wrapperCol={{ span: 16 }}>
                    <div
                      style={{
                        width: '100%',
                        height: chartData ? 400 : '1rem',
                      }}
                    >
                      {chartData && (
                        <ReactECharts
                          style={{
                            width: '100%',
                            height: '100%',
                          }}
                          option={{
                            tooltip: {
                              trigger: 'axis',
                            },
                            grid: { left: '20px', right: '3%', top: '10%', bottom: 30, containLabel: true },
                            xAxis: {
                              type: 'category',
                              boundaryGap: false,
                              data: chartData.time,
                            },
                            yAxis: {
                              type: 'value',
                              name: `单位：kW    日用电量：${getDailyEle()} kWh`,
                              nameTextStyle: {
                                align: 'left',
                                color: 'gray',
                                padding: [0, 0, 0, -40],
                              },
                              min: 0,
                              max: (bearForm.getFieldValue('transformerTotalCapacity') || 1000) * 1.2,
                            },
                            legend: {
                              show: true,
                              top: 'bottom',
                              icon: 'rect',
                            },
                            series:
                              (chartData.value && [
                                {
                                  name: '典型曲线',
                                  type: 'line',
                                  sampling: 'lttb',
                                  lineStyle: {
                                    width: 1,
                                  },
                                  symbol: 'none',
                                  itemStyle: {
                                    color: 'rgb(30, 198, 146)',
                                  },
                                  areaStyle: {
                                    color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                                      {
                                        offset: 0,
                                        color: 'rgba(30, 198, 146, 0.1)',
                                      },
                                      {
                                        offset: 1,
                                        color: 'rgba(30, 198, 146, 0)',
                                      },
                                    ]),
                                  },
                                  data: getSolvedChartData(chartData.value),
                                },
                                {
                                  name: '校正曲线',
                                  type: 'line',
                                  sampling: 'lttb',
                                  symbol: 'none',
                                  lineStyle: {
                                    width: 1,
                                  },
                                  itemStyle: {
                                    color: 'rgb(24, 144, 255)',
                                  },
                                  areaStyle: {
                                    color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                                      {
                                        offset: 0,
                                        color: 'rgba(24, 144, 255, 0.1)',
                                      },
                                      {
                                        offset: 1,
                                        color: 'rgba(24, 144, 255, 0)',
                                      },
                                    ]),
                                  },
                                  data: getSolvedChartData(chartData.value, true),
                                },
                              ]) ||
                              [],
                          }}
                        />
                      )}
                    </div>
                  </Form.Item>
                  <Form.Item className="actions-footer">
                    <Button type="primary" htmlType="submit">
                      确定
                    </Button>
                    {finished && (
                      <Button
                        onClick={() => {
                          bearObservable.next(true)
                          loadResult()
                        }}
                      >
                        取消
                      </Button>
                    )}
                  </Form.Item>
                </Form>
              </div>
            )}
          </div>
        )}
        {selected === 1 && (
          <div
            style={{
              padding: '1rem',
            }}
          >
            {!forceEdit && finished && result && (
              <div>
                <Row>
                  <Col span={8}>
                    <strong>数据时间段：</strong>
                    <span>
                      {result.startTime} ~ {result.endTime}
                    </span>
                  </Col>
                  <Col span={8}>
                    <strong>数据时间颗粒度：</strong>
                    <span>{result.interval}分钟</span>
                  </Col>
                </Row>
                <div
                  style={{
                    marginTop: '1rem',
                    height: 400,
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}
                  >
                    <strong>数据治理负荷曲线</strong>
                    <Tooltip title="治理前后对比">
                      <Button
                        icon={<CustomIcons type="klf-compare" />}
                        type={'link'}
                        onClick={() => {
                          Modal.info({
                            width: 1000,
                            title: '治理前后对比',
                            icon: null,
                            closable: true,
                            okButtonProps: {
                              style: { display: 'none' },
                            },
                            content: (
                              <div>
                                {getGovernData(false)}
                                {getGovernData(true)}
                              </div>
                            ),
                          })
                        }}
                      />
                    </Tooltip>
                  </div>
                  {getGovernData(true, '', 45)}

                  <div className="hide-on-plot">
                    <strong>数据诊断治理情况</strong>
                    {result.governanceInfo && (
                      <Row
                        style={{
                          marginTop: '1rem',
                        }}
                      >
                        {Object.keys(result.governanceInfo).map((name) => {
                          const obj: any = result.governanceInfo[name]
                          return (
                            <Col
                              key={name}
                              span={6}
                              style={{
                                padding: '.5rem',
                              }}
                            >
                              <Card
                                className="header-border-bottom"
                                title={
                                  <div
                                    style={{
                                      fontSize: 14,
                                      fontWeight: 600,
                                    }}
                                  >
                                    {name}
                                    <div className="border-bottom-dotted-line" />
                                  </div>
                                }
                                headStyle={{
                                  padding: '0 24px',
                                }}
                                bodyStyle={{
                                  padding: '10px 0px',
                                }}
                                extra={
                                  <div
                                    style={{
                                      margin: '-8px 0',
                                      display: 'flex',
                                      justifyContent: 'center',
                                      alignItems: 'center',
                                    }}
                                  >
                                    <Image
                                      style={{
                                        width: 89,
                                        height: 76,
                                      }}
                                      src="assets/zd.png"
                                      alt=""
                                      preview={false}
                                    />
                                  </div>
                                }
                              >
                                <Row>
                                  <Col
                                    span={8}
                                    style={{
                                      textAlign: 'center',
                                    }}
                                  >
                                    <p
                                      style={{
                                        fontSize: 22,
                                        fontWeight: 600,
                                        margin: 0,
                                      }}
                                    >
                                      {obj.bigNumberRate}
                                      <span
                                        style={{
                                          fontSize: 12,
                                        }}
                                      >
                                        %
                                      </span>
                                    </p>
                                    <p
                                      style={{
                                        fontSize: 12,
                                        fontWeight: 400,
                                        color: 'gray',
                                        margin: 0,
                                      }}
                                    >
                                      异常大
                                    </p>
                                  </Col>
                                  <Col
                                    span={8}
                                    style={{
                                      textAlign: 'center',
                                    }}
                                  >
                                    <p
                                      style={{
                                        fontSize: 22,
                                        fontWeight: 600,
                                        margin: 0,
                                      }}
                                    >
                                      {obj.nullNumberRate}
                                      <span
                                        style={{
                                          fontSize: 12,
                                        }}
                                      >
                                        %
                                      </span>
                                    </p>
                                    <p
                                      style={{
                                        fontSize: 12,
                                        fontWeight: 400,
                                        color: 'gray',
                                        margin: 0,
                                      }}
                                    >
                                      缺失
                                    </p>
                                  </Col>
                                  <Col
                                    span={8}
                                    style={{
                                      textAlign: 'center',
                                    }}
                                  >
                                    <p
                                      style={{
                                        fontSize: 22,
                                        fontWeight: 600,
                                        margin: 0,
                                      }}
                                    >
                                      {obj.negativeNumberRate}
                                      <span
                                        style={{
                                          fontSize: 12,
                                        }}
                                      >
                                        %
                                      </span>
                                    </p>
                                    <p
                                      style={{
                                        fontSize: 12,
                                        fontWeight: 400,
                                        color: 'gray',
                                        margin: 0,
                                      }}
                                    >
                                      负值
                                    </p>
                                  </Col>
                                </Row>
                              </Card>
                            </Col>
                          )
                        })}
                      </Row>
                    )}
                  </div>
                </div>
              </div>
            )}
            {(forceEdit || !finished) && (
              <Spin spinning={uploading}>
                <Timeline>
                  <Timeline.Item
                    dot={<h1 className={`timeline-dot ${currentStep > 0 ? 'timeline-dot-finished' : ''}`}>1</h1>}
                  >
                    <Row>
                      <Col
                        style={{
                          width: '8rem',
                        }}
                      >
                        设置模板
                      </Col>
                      <Col
                        style={{
                          width: '50rem',
                        }}
                      >
                        <div
                          className={`upload-template ${!template ? 'upload-template-hover' : ''}`}
                          onClick={() => {
                            if (template) {
                              return
                            }
                            ApplicationModal.confirm({
                              keyboard: false,
                              icon: null,
                              title: '设置模板',
                              closable: true,
                              content: <BearTemplate config={{ form: templateForm }} />,
                              width: 600,
                              onCancel: () => {
                                reloadTemplateData()
                              },
                              onOk: () => {
                                return new Promise((resolve, reject) => {
                                  templateForm
                                    .validateFields()
                                    .then((resp) => {
                                      const { timeRange, level, transformer } = templateForm.getFieldsValue()
                                      setTemplate({
                                        startTime: timeRange[0].format('YYYY-MM-DD 00:00:00'),
                                        endTime: timeRange[1].format('YYYY-MM-DD 23:59:59'),
                                        level,
                                        transformer,
                                      })
                                      setCurrentStep(2)
                                      resolve(resp)
                                    })
                                    .catch(reject)
                                })
                              },
                            })
                          }}
                        >
                          {template && (
                            <div>
                              <Row>
                                <Col span={13}>
                                  <strong>数据时间段：</strong>
                                  <span>
                                    {template.startTime.split(' ')[0]} - {template.endTime.split(' ')[0]}
                                  </span>
                                </Col>
                                <Col span={11}>
                                  <strong>数据时间颗粒度：</strong>
                                  <span>{template.level}分钟</span>
                                  <Button
                                    onClick={() => {
                                      ApplicationModal.confirm({
                                        keyboard: false,
                                        icon: null,
                                        title: '设置模板',
                                        closable: true,
                                        content: <BearTemplate config={{ form: templateForm }} />,
                                        width: 600,
                                        onCancel: () => {
                                          reloadTemplateData()
                                        },
                                        onOk: () => {
                                          return new Promise((resolve, reject) => {
                                            templateForm
                                              .validateFields()
                                              .then((resp) => {
                                                const { timeRange, level, transformer } = templateForm.getFieldsValue()
                                                setTemplate({
                                                  startTime: timeRange[0].format('YYYY-MM-DD 00:00:00'),
                                                  endTime: timeRange[1].format('YYYY-MM-DD 23:59:59'),
                                                  level,
                                                  transformer,
                                                })
                                                setCurrentStep(2)
                                                resolve(resp)
                                              })
                                              .catch(reject)
                                          })
                                        },
                                      })
                                    }}
                                    type="link"
                                    icon={<CustomIcons type="klf-edit1" />}
                                  >
                                    编辑
                                  </Button>
                                </Col>
                              </Row>
                              <Table
                                pagination={false}
                                style={{
                                  width: '100%',
                                  marginTop: '.5rem',
                                }}
                                className="bordered-table"
                                bordered={true}
                                dataSource={template.transformer}
                                columns={[
                                  {
                                    title: '变压器名称',
                                    key: 'transformerName',
                                    dataIndex: 'transformerName',
                                    width: 200,
                                  },
                                  {
                                    title: '变压器总容量上限',
                                    key: 'maxCapacity',
                                    dataIndex: 'maxCapacity',
                                    width: 200,
                                    render: (value) => <span>{value}kVA</span>,
                                  },
                                ]}
                              />
                            </div>
                          )}
                          {!template && (
                            <div className="template-init">
                              <div
                                style={{
                                  textAlign: 'center',
                                }}
                                className="ant-upload-drag-icon"
                              >
                                <CustomIcons type="klf-template-set" />
                                <p>设置模板</p>
                                <p>点击设置离线负荷数据模板</p>
                              </div>
                            </div>
                          )}
                        </div>
                      </Col>
                    </Row>
                  </Timeline.Item>
                  <Timeline.Item
                    dot={<h1 className={`timeline-dot ${currentStep > 1 ? 'timeline-dot-finished' : ''}`}>2</h1>}
                  >
                    <Row>
                      <Col
                        style={{
                          width: '8rem',
                        }}
                      >
                        下载模板
                      </Col>
                      <Col
                        style={{
                          width: '30rem',
                        }}
                      >
                        <Button
                          type={currentStep < 2 ? 'default' : 'primary'}
                          ghost={currentStep < 2 ? false : true}
                          disabled={currentStep < 2}
                          onClick={() => {
                            downloadTemplate(getParamId(), template)
                          }}
                          icon={<DownloadOutlined />}
                          loading={downloading}
                        >
                          下载
                        </Button>
                      </Col>
                    </Row>
                  </Timeline.Item>
                  <Timeline.Item
                    dot={<h1 className={`timeline-dot ${currentStep > 2 ? 'timeline-dot-finished' : ''}`}>3</h1>}
                    color="red"
                  >
                    <Row>
                      <Col
                        style={{
                          width: '8rem',
                        }}
                      >
                        上传文件
                      </Col>
                      <Col
                        style={{
                          width: '35rem',
                        }}
                      >
                        <Upload.Dragger
                          disabled={currentStep <= 2}
                          className="file-dragger"
                          style={{
                            width: '100%',
                            height: '3rem',
                          }}
                          {...{
                            name: 'file',
                            multiple: false,
                            accept: '.xlsx',
                            maxCount: 1,
                            showUploadList: false,
                            customRequest: (item: any) => {
                              setFile(item.file)
                              item.onSuccess()
                              setCurrentStep(4)
                            },
                          }}
                        >
                          <p
                            className="ant-upload-drag-icon"
                            style={{
                              width: '100%',
                            }}
                          >
                            {!file && (
                              <CloudUploadOutlined
                                style={{
                                  color: currentStep <= 2 ? 'lightgrey' : '',
                                }}
                              />
                            )}
                            {file && (
                              <svg
                                width="40px"
                                height="40px"
                                viewBox="0 0 40 40"
                                version="1.1"
                                xmlns="http://www.w3.org/2000/svg"
                                xmlnsXlink="http://www.w3.org/1999/xlink"
                              >
                                <defs>
                                  <path
                                    d="M31.7119453,40 L2.59570234,40 C1.16098893,40 0,38.8636363 0,37.4586776 L0,2.54132231 C-0.0205397791,1.13636363 1.13989019,0 2.57460362,0 L23.4412444,0 L34.28599,10.0826446 L34.28599,37.4586776 C34.28599,38.8636363 33.12556,40 31.7119453,40 Z"
                                    id="path-1"
                                  />
                                </defs>
                                <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                  <g transform="translate(-88.000000, -509.000000)">
                                    <g transform="translate(64.000000, 497.000000)">
                                      <g transform="translate(24.000000, 12.000000)">
                                        <g>
                                          <mask fill="white">
                                            <use xlinkHref="#path-1" />
                                          </mask>
                                          <use fill="#1EC692" xlinkHref="#path-1" />
                                          <polygon
                                            fill="#FFFFFF"
                                            fillRule="nonzero"
                                            mask="url(#mask-2)"
                                            points="14.0647995 13.3333334 10.8153551 13.3333334 15.1681329 19.8848834 10.4764662 26.6666667 13.6609107 26.6666667 17.1220217 21.4217347 17.2497995 21.4217347 20.5186884 26.6666667 23.8097995 26.6666667 19.2025947 19.7692563 23.660928 13.3333334 20.4975947 13.3333334 17.2703725 18.3480322 17.1431502 18.3480322 14.0648169 13.3333334"
                                          />
                                        </g>
                                        <g id="Clipped" fill="#7AE78B" fillRule="nonzero">
                                          <path d="M23.4412444,-0.99305968 L23.4412444,8.15436493 C23.4412444,9.20633588 24.2940353,10.0591268 25.3460063,10.0591268 L35.8221967,10.0591268 L35.8221967,10.0591268 L23.4412444,-0.99305968 Z" />
                                        </g>
                                      </g>
                                    </g>
                                  </g>
                                </g>
                              </svg>
                            )}
                            {!file && (
                              <span>
                                <span className="title">请上传文件</span> <span className="tips">支持扩展名：xlsx</span>
                              </span>
                            )}
                            {file && (
                              <span
                                className="title"
                                style={{
                                  textAlign: 'left',
                                }}
                              >
                                {file.name}
                                <br />
                                <span
                                  className="tips"
                                  style={{
                                    marginLeft: 0,
                                  }}
                                >
                                  {(file.size / 1024).toFixed(2)}KB
                                </span>
                              </span>
                            )}
                          </p>
                        </Upload.Dragger>
                      </Col>
                    </Row>
                  </Timeline.Item>
                  <Timeline.Item
                    dot={<h1 className={`timeline-dot ${currentStep > 3 ? 'timeline-dot-finished' : ''}`}>4</h1>}
                  >
                    <Row>
                      <Col
                        style={{
                          width: '8rem',
                        }}
                      >
                        数据诊断与治理
                      </Col>
                      <Col
                        style={{
                          width: '30rem',
                        }}
                      >
                        <Button
                          disabled={!file}
                          onClick={() => {
                            setUploading(true)
                            const { startTime, endTime, level, transformer } = template
                            ProjectServiceHistory.upload({
                              projectId: getParamId(),
                              startTime,
                              endTime,
                              level,
                              transformer: JSON.stringify(transformer),
                              filename: file,
                            })
                              .then(() => {
                                bearObservable.next(true)
                                setFinished(true)
                                loadResult()
                              })
                              .catch(() => {})
                              .finally(() => {
                                setUploading(false)
                              })
                          }}
                          type="primary"
                        >
                          确定
                        </Button>
                        {finished && (
                          <Button
                            style={{
                              marginLeft: '1rem',
                            }}
                            onClick={() => {
                              bearObservable.next(true)
                              loadResult()
                            }}
                          >
                            取消
                          </Button>
                        )}
                      </Col>
                    </Row>
                  </Timeline.Item>
                </Timeline>
              </Spin>
            )}
          </div>
        )}
      </div>
    </div>
  ) : (
    <Empty />
  )
}
