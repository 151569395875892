export const weatherConfigSrc = [
  ["太阳散射辐射","DHI","https://dev-tile.terraqt.com/tile/xyz/{z}/{x}/{y}.pbf?path=d028e889-46fe-42b2-9c7f-af1e26fb8ccf/der3.aj/20000101/CN-100000/rastertiles_1651913035"],
  ["太阳散射辐射","DHI","https://dev-tile.terraqt.com/tile/xyz/{z}/{x}/{y}.pbf?path=d028e889-46fe-42b2-9c7f-af1e26fb8ccf/der3.aj/20000201/CN-100000/rastertiles_1651913042"],
  ["太阳散射辐射","DHI","https://dev-tile.terraqt.com/tile/xyz/{z}/{x}/{y}.pbf?path=d028e889-46fe-42b2-9c7f-af1e26fb8ccf/der3.aj/20000601/CN-100000/rastertiles_1651913068"],
  ["太阳散射辐射","DHI","https://dev-tile.terraqt.com/tile/xyz/{z}/{x}/{y}.pbf?path=d028e889-46fe-42b2-9c7f-af1e26fb8ccf/der3.aj/20000701/CN-100000/rastertiles_1651913075"],
  ["太阳散射辐射","DHI","https://dev-tile.terraqt.com/tile/xyz/{z}/{x}/{y}.pbf?path=d028e889-46fe-42b2-9c7f-af1e26fb8ccf/der3.aj/20000401/CN-100000/rastertiles_1651913055"],
  ["太阳散射辐射","DHI","https://dev-tile.terraqt.com/tile/xyz/{z}/{x}/{y}.pbf?path=d028e889-46fe-42b2-9c7f-af1e26fb8ccf/der3.aj/20000501/CN-100000/rastertiles_1651913062"],
  ["太阳散射辐射","DHI","https://dev-tile.terraqt.com/tile/xyz/{z}/{x}/{y}.pbf?path=d028e889-46fe-42b2-9c7f-af1e26fb8ccf/der3.aj/20001101/CN-100000/rastertiles_1651913100"],
  ["太阳散射辐射","DHI","https://dev-tile.terraqt.com/tile/xyz/{z}/{x}/{y}.pbf?path=d028e889-46fe-42b2-9c7f-af1e26fb8ccf/der3.aj/20001001/CN-100000/rastertiles_1651913093"],
  ["太阳散射辐射","DHI","https://dev-tile.terraqt.com/tile/xyz/{z}/{x}/{y}.pbf?path=d028e889-46fe-42b2-9c7f-af1e26fb8ccf/der3.aj/20001201/CN-100000/rastertiles_1651913107"],
  ["太阳散射辐射","DHI","https://dev-tile.terraqt.com/tile/xyz/{z}/{x}/{y}.pbf?path=d028e889-46fe-42b2-9c7f-af1e26fb8ccf/der3.aj/20000901/CN-100000/rastertiles_1651913087"],
  ["太阳散射辐射","DHI","https://dev-tile.terraqt.com/tile/xyz/{z}/{x}/{y}.pbf?path=d028e889-46fe-42b2-9c7f-af1e26fb8ccf/der3.aj/20000301/CN-100000/rastertiles_1651913049"],
  // ["太阳散射辐射","DHI","https://dev-tile.terraqt.com/tile/xyz/{z}/{x}/{y}.pbf?path=d028e889-46fe-42b2-9c7f-af1e26fb8ccf/der3.aj/20001231/CN-100000/rastertiles_1651912058"],
  ["太阳散射辐射","DHI","https://dev-tile.terraqt.com/tile/xyz/{z}/{x}/{y}.pbf?path=d028e889-46fe-42b2-9c7f-af1e26fb8ccf/der3.aj/20000801/CN-100000/rastertiles_1651913081"],
  ["太阳直接辐射","DNI","https://dev-tile.terraqt.com/tile/xyz/{z}/{x}/{y}.pbf?path=d028e889-46fe-42b2-9c7f-af1e26fb8ccf/der3.ai/20000401/CN-100000/rastertiles_1651913431"],
  ["太阳直接辐射","DNI","https://dev-tile.terraqt.com/tile/xyz/{z}/{x}/{y}.pbf?path=d028e889-46fe-42b2-9c7f-af1e26fb8ccf/der3.ai/20000501/CN-100000/rastertiles_1651913438"],
  ["太阳直接辐射","DNI","https://dev-tile.terraqt.com/tile/xyz/{z}/{x}/{y}.pbf?path=d028e889-46fe-42b2-9c7f-af1e26fb8ccf/der3.ai/20000101/CN-100000/rastertiles_1651913412"],
  ["太阳直接辐射","DNI","https://dev-tile.terraqt.com/tile/xyz/{z}/{x}/{y}.pbf?path=d028e889-46fe-42b2-9c7f-af1e26fb8ccf/der3.ai/20000301/CN-100000/rastertiles_1651913425"],
  ["太阳直接辐射","DNI","https://dev-tile.terraqt.com/tile/xyz/{z}/{x}/{y}.pbf?path=d028e889-46fe-42b2-9c7f-af1e26fb8ccf/der3.ai/20001201/CN-100000/rastertiles_1651913482"],
  ["太阳直接辐射","DNI","https://dev-tile.terraqt.com/tile/xyz/{z}/{x}/{y}.pbf?path=d028e889-46fe-42b2-9c7f-af1e26fb8ccf/der3.ai/20000601/CN-100000/rastertiles_1651913444"],
  ["太阳直接辐射","DNI","https://dev-tile.terraqt.com/tile/xyz/{z}/{x}/{y}.pbf?path=d028e889-46fe-42b2-9c7f-af1e26fb8ccf/der3.ai/20000701/CN-100000/rastertiles_1651913451"],
  ["太阳直接辐射","DNI","https://dev-tile.terraqt.com/tile/xyz/{z}/{x}/{y}.pbf?path=d028e889-46fe-42b2-9c7f-af1e26fb8ccf/der3.ai/20000801/CN-100000/rastertiles_1651913457"],
  ["太阳直接辐射","DNI","https://dev-tile.terraqt.com/tile/xyz/{z}/{x}/{y}.pbf?path=d028e889-46fe-42b2-9c7f-af1e26fb8ccf/der3.ai/20000901/CN-100000/rastertiles_1651913463"],
  ["太阳直接辐射","DNI","https://dev-tile.terraqt.com/tile/xyz/{z}/{x}/{y}.pbf?path=d028e889-46fe-42b2-9c7f-af1e26fb8ccf/der3.ai/20001101/CN-100000/rastertiles_1651913475"],
  ["太阳直接辐射","DNI","https://dev-tile.terraqt.com/tile/xyz/{z}/{x}/{y}.pbf?path=d028e889-46fe-42b2-9c7f-af1e26fb8ccf/der3.ai/20001001/CN-100000/rastertiles_1651913469"],
  // ["太阳直接辐射","DNI","https://dev-tile.terraqt.com/tile/xyz/{z}/{x}/{y}.pbf?path=d028e889-46fe-42b2-9c7f-af1e26fb8ccf/der3.ai/20001231/CN-100000/rastertiles"],
  ["太阳直接辐射","DNI","https://dev-tile.terraqt.com/tile/xyz/{z}/{x}/{y}.pbf?path=d028e889-46fe-42b2-9c7f-af1e26fb8ccf/der3.ai/20000201/CN-100000/rastertiles_1651913419"],
  ["总辐射辐照度（下行）","GHI","https://dev-tile.terraqt.com/tile/xyz/{z}/{x}/{y}.pbf?path=d028e889-46fe-42b2-9c7f-af1e26fb8ccf/der3.j/20000801/CN-100000/rastertiles_1651913533"],
  ["总辐射辐照度（下行）","GHI","https://dev-tile.terraqt.com/tile/xyz/{z}/{x}/{y}.pbf?path=d028e889-46fe-42b2-9c7f-af1e26fb8ccf/der3.j/20000901/CN-100000/rastertiles_1651913540"],
  ["总辐射辐照度（下行）","GHI","https://dev-tile.terraqt.com/tile/xyz/{z}/{x}/{y}.pbf?path=d028e889-46fe-42b2-9c7f-af1e26fb8ccf/der3.j/20001001/CN-100000/rastertiles_1651913546"],
  ["总辐射辐照度（下行）","GHI","https://dev-tile.terraqt.com/tile/xyz/{z}/{x}/{y}.pbf?path=d028e889-46fe-42b2-9c7f-af1e26fb8ccf/der3.j/20001101/CN-100000/rastertiles_1651913552"],
  ["总辐射辐照度（下行）","GHI","https://dev-tile.terraqt.com/tile/xyz/{z}/{x}/{y}.pbf?path=d028e889-46fe-42b2-9c7f-af1e26fb8ccf/der3.j/20001201/CN-100000/rastertiles_1651913559"],
  ["总辐射辐照度（下行）","GHI","https://dev-tile.terraqt.com/tile/xyz/{z}/{x}/{y}.pbf?path=d028e889-46fe-42b2-9c7f-af1e26fb8ccf/der3.j/20000301/CN-100000/rastertiles_1651913501"],
  ["总辐射辐照度（下行）","GHI","https://dev-tile.terraqt.com/tile/xyz/{z}/{x}/{y}.pbf?path=d028e889-46fe-42b2-9c7f-af1e26fb8ccf/der3.j/20000401/CN-100000/rastertiles_1651913507"],
  ["总辐射辐照度（下行）","GHI","https://dev-tile.terraqt.com/tile/xyz/{z}/{x}/{y}.pbf?path=d028e889-46fe-42b2-9c7f-af1e26fb8ccf/der3.j/20000501/CN-100000/rastertiles_1651913513"],
  ["总辐射辐照度（下行）","GHI","https://dev-tile.terraqt.com/tile/xyz/{z}/{x}/{y}.pbf?path=d028e889-46fe-42b2-9c7f-af1e26fb8ccf/der3.j/20000601/CN-100000/rastertiles_1651913520"],
  ["总辐射辐照度（下行）","GHI","https://dev-tile.terraqt.com/tile/xyz/{z}/{x}/{y}.pbf?path=d028e889-46fe-42b2-9c7f-af1e26fb8ccf/der3.j/20000701/CN-100000/rastertiles_1651913526"],
  ["总辐射辐照度（下行）","GHI","https://dev-tile.terraqt.com/tile/xyz/{z}/{x}/{y}.pbf?path=d028e889-46fe-42b2-9c7f-af1e26fb8ccf/der3.j/20000101/CN-100000/rastertiles_1651913488"],
  ["总辐射辐照度（下行）","GHI","https://dev-tile.terraqt.com/tile/xyz/{z}/{x}/{y}.pbf?path=d028e889-46fe-42b2-9c7f-af1e26fb8ccf/der3.j/20000201/CN-100000/rastertiles_1651913494"],
  // ["总辐射辐照度（下行）","GHI","https://dev-tile.terraqt.com/tile/xyz/{z}/{x}/{y}.pbf?path=d028e889-46fe-42b2-9c7f-af1e26fb8ccf/der3.j/20001231/CN-100000/rastertiles"],
  ["气压","PRES","https://dev-tile.terraqt.com/tile/xyz/{z}/{x}/{y}.pbf?path=d028e889-46fe-42b2-9c7f-af1e26fb8ccf/der3.i/20000601/CN-100000/rastertiles_1651913598"],
  ["气压","PRES","https://dev-tile.terraqt.com/tile/xyz/{z}/{x}/{y}.pbf?path=d028e889-46fe-42b2-9c7f-af1e26fb8ccf/der3.i/20000701/CN-100000/rastertiles_1651913605"],
  ["气压","PRES","https://dev-tile.terraqt.com/tile/xyz/{z}/{x}/{y}.pbf?path=d028e889-46fe-42b2-9c7f-af1e26fb8ccf/der3.i/20000801/CN-100000/rastertiles_1651913612"],
  ["气压","PRES","https://dev-tile.terraqt.com/tile/xyz/{z}/{x}/{y}.pbf?path=d028e889-46fe-42b2-9c7f-af1e26fb8ccf/der3.i/20001001/CN-100000/rastertiles_1651913625"],
  ["气压","PRES","https://dev-tile.terraqt.com/tile/xyz/{z}/{x}/{y}.pbf?path=d028e889-46fe-42b2-9c7f-af1e26fb8ccf/der3.i/20000201/CN-100000/rastertiles_1651913573"],
  ["气压","PRES","https://dev-tile.terraqt.com/tile/xyz/{z}/{x}/{y}.pbf?path=d028e889-46fe-42b2-9c7f-af1e26fb8ccf/der3.i/20000301/CN-100000/rastertiles_1651913579"],
  ["气压","PRES","https://dev-tile.terraqt.com/tile/xyz/{z}/{x}/{y}.pbf?path=d028e889-46fe-42b2-9c7f-af1e26fb8ccf/der3.i/20000401/CN-100000/rastertiles_1651913585"],
  ["气压","PRES","https://dev-tile.terraqt.com/tile/xyz/{z}/{x}/{y}.pbf?path=d028e889-46fe-42b2-9c7f-af1e26fb8ccf/der3.i/20000501/CN-100000/rastertiles_1651913592"],
  ["气压","PRES","https://dev-tile.terraqt.com/tile/xyz/{z}/{x}/{y}.pbf?path=d028e889-46fe-42b2-9c7f-af1e26fb8ccf/der3.i/20001201/CN-100000/rastertiles_1651913637"],
  ["气压","PRES","https://dev-tile.terraqt.com/tile/xyz/{z}/{x}/{y}.pbf?path=d028e889-46fe-42b2-9c7f-af1e26fb8ccf/der3.i/20000101/CN-100000/rastertiles_1651913566"],
  ["气压","PRES","https://dev-tile.terraqt.com/tile/xyz/{z}/{x}/{y}.pbf?path=d028e889-46fe-42b2-9c7f-af1e26fb8ccf/der3.i/20001101/CN-100000/rastertiles_1651913631"],
  // ["气压","PRES","https://dev-tile.terraqt.com/tile/xyz/{z}/{x}/{y}.pbf?path=d028e889-46fe-42b2-9c7f-af1e26fb8ccf/der3.i/20001231/CN-100000/rastertiles"],
  ["气压","PRES","https://dev-tile.terraqt.com/tile/xyz/{z}/{x}/{y}.pbf?path=d028e889-46fe-42b2-9c7f-af1e26fb8ccf/der3.i/20000901/CN-100000/rastertiles_1651913618"],
  ["降水量","RAINFALL","https://dev-tile.terraqt.com/tile/xyz/{z}/{x}/{y}.pbf?path=d028e889-46fe-42b2-9c7f-af1e26fb8ccf/der3.c/20000401/CN-100000/rastertiles_1651913663"],
  ["降水量","RAINFALL","https://dev-tile.terraqt.com/tile/xyz/{z}/{x}/{y}.pbf?path=d028e889-46fe-42b2-9c7f-af1e26fb8ccf/der3.c/20000801/CN-100000/rastertiles_1651913688"],
  ["降水量","RAINFALL","https://dev-tile.terraqt.com/tile/xyz/{z}/{x}/{y}.pbf?path=d028e889-46fe-42b2-9c7f-af1e26fb8ccf/der3.c/20000901/CN-100000/rastertiles_1651913695"],
  ["降水量","RAINFALL","https://dev-tile.terraqt.com/tile/xyz/{z}/{x}/{y}.pbf?path=d028e889-46fe-42b2-9c7f-af1e26fb8ccf/der3.c/20000201/CN-100000/rastertiles_1651913650"],
  ["降水量","RAINFALL","https://dev-tile.terraqt.com/tile/xyz/{z}/{x}/{y}.pbf?path=d028e889-46fe-42b2-9c7f-af1e26fb8ccf/der3.c/20000301/CN-100000/rastertiles_1651913656"],
  ["降水量","RAINFALL","https://dev-tile.terraqt.com/tile/xyz/{z}/{x}/{y}.pbf?path=d028e889-46fe-42b2-9c7f-af1e26fb8ccf/der3.c/20000101/CN-100000/rastertiles_1651913644"],
  ["降水量","RAINFALL","https://dev-tile.terraqt.com/tile/xyz/{z}/{x}/{y}.pbf?path=d028e889-46fe-42b2-9c7f-af1e26fb8ccf/der3.c/20000701/CN-100000/rastertiles_1651913682"],
  ["降水量","RAINFALL","https://dev-tile.terraqt.com/tile/xyz/{z}/{x}/{y}.pbf?path=d028e889-46fe-42b2-9c7f-af1e26fb8ccf/der3.c/20000601/CN-100000/rastertiles_1651913675"],
  ["降水量","RAINFALL","https://dev-tile.terraqt.com/tile/xyz/{z}/{x}/{y}.pbf?path=d028e889-46fe-42b2-9c7f-af1e26fb8ccf/der3.c/20000501/CN-100000/rastertiles_1651913669"],
  ["降水量","RAINFALL","https://dev-tile.terraqt.com/tile/xyz/{z}/{x}/{y}.pbf?path=d028e889-46fe-42b2-9c7f-af1e26fb8ccf/der3.c/20001101/CN-100000/rastertiles_1651913710"],
  ["降水量","RAINFALL","https://dev-tile.terraqt.com/tile/xyz/{z}/{x}/{y}.pbf?path=d028e889-46fe-42b2-9c7f-af1e26fb8ccf/der3.c/20001201/CN-100000/rastertiles_1651913717"],
  ["降水量","RAINFALL","https://dev-tile.terraqt.com/tile/xyz/{z}/{x}/{y}.pbf?path=d028e889-46fe-42b2-9c7f-af1e26fb8ccf/der3.c/20001001/CN-100000/rastertiles_1651913701"],
  // ["降水量","RAINFALL","https://dev-tile.terraqt.com/tile/xyz/{z}/{x}/{y}.pbf?path=d028e889-46fe-42b2-9c7f-af1e26fb8ccf/der3.c/20001231/CN-100000/rastertiles"],
  ["相对湿度","RH","https://dev-tile.terraqt.com/tile/xyz/{z}/{x}/{y}.pbf?path=d028e889-46fe-42b2-9c7f-af1e26fb8ccf/der3.b/20000201/CN-100000/rastertiles_1651913730"],
  ["相对湿度","RH","https://dev-tile.terraqt.com/tile/xyz/{z}/{x}/{y}.pbf?path=d028e889-46fe-42b2-9c7f-af1e26fb8ccf/der3.b/20000601/CN-100000/rastertiles_1651913756"],
  ["相对湿度","RH","https://dev-tile.terraqt.com/tile/xyz/{z}/{x}/{y}.pbf?path=d028e889-46fe-42b2-9c7f-af1e26fb8ccf/der3.b/20000701/CN-100000/rastertiles_1651913763"],
  ["相对湿度","RH","https://dev-tile.terraqt.com/tile/xyz/{z}/{x}/{y}.pbf?path=d028e889-46fe-42b2-9c7f-af1e26fb8ccf/der3.b/20000801/CN-100000/rastertiles_1651913770"],
  ["相对湿度","RH","https://dev-tile.terraqt.com/tile/xyz/{z}/{x}/{y}.pbf?path=d028e889-46fe-42b2-9c7f-af1e26fb8ccf/der3.b/20000901/CN-100000/rastertiles_1651913776"],
  ["相对湿度","RH","https://dev-tile.terraqt.com/tile/xyz/{z}/{x}/{y}.pbf?path=d028e889-46fe-42b2-9c7f-af1e26fb8ccf/der3.b/20001001/CN-100000/rastertiles_1651913783"],
  ["相对湿度","RH","https://dev-tile.terraqt.com/tile/xyz/{z}/{x}/{y}.pbf?path=d028e889-46fe-42b2-9c7f-af1e26fb8ccf/der3.b/20001101/CN-100000/rastertiles_1651913791"],
  ["相对湿度","RH","https://dev-tile.terraqt.com/tile/xyz/{z}/{x}/{y}.pbf?path=d028e889-46fe-42b2-9c7f-af1e26fb8ccf/der3.b/20001201/CN-100000/rastertiles_1651913798"],
  ["相对湿度","RH","https://dev-tile.terraqt.com/tile/xyz/{z}/{x}/{y}.pbf?path=d028e889-46fe-42b2-9c7f-af1e26fb8ccf/der3.b/20000401/CN-100000/rastertiles_1651913743"],
  ["相对湿度","RH","https://dev-tile.terraqt.com/tile/xyz/{z}/{x}/{y}.pbf?path=d028e889-46fe-42b2-9c7f-af1e26fb8ccf/der3.b/20000501/CN-100000/rastertiles_1651913749"],
  ["相对湿度","RH","https://dev-tile.terraqt.com/tile/xyz/{z}/{x}/{y}.pbf?path=d028e889-46fe-42b2-9c7f-af1e26fb8ccf/der3.b/20000301/CN-100000/rastertiles_1651913736"],
  // ["相对湿度","RH","https://dev-tile.terraqt.com/tile/xyz/{z}/{x}/{y}.pbf?path=d028e889-46fe-42b2-9c7f-af1e26fb8ccf/der3.b/20001231/CN-100000/rastertiles"],
  ["相对湿度","RH","https://dev-tile.terraqt.com/tile/xyz/{z}/{x}/{y}.pbf?path=d028e889-46fe-42b2-9c7f-af1e26fb8ccf/der3.b/20000101/CN-100000/rastertiles_1651913723"],
  ["气温","TMP","https://dev-tile.terraqt.com/tile/xyz/{z}/{x}/{y}.pbf?path=d028e889-46fe-42b2-9c7f-af1e26fb8ccf/der3.a/20000101/CN-100000/rastertiles_1651913804"],
  ["气温","TMP","https://dev-tile.terraqt.com/tile/xyz/{z}/{x}/{y}.pbf?path=d028e889-46fe-42b2-9c7f-af1e26fb8ccf/der3.a/20000501/CN-100000/rastertiles_1651913829"],
  ["气温","TMP","https://dev-tile.terraqt.com/tile/xyz/{z}/{x}/{y}.pbf?path=d028e889-46fe-42b2-9c7f-af1e26fb8ccf/der3.a/20000601/CN-100000/rastertiles_1651913836"],
  ["气温","TMP","https://dev-tile.terraqt.com/tile/xyz/{z}/{x}/{y}.pbf?path=d028e889-46fe-42b2-9c7f-af1e26fb8ccf/der3.a/20000701/CN-100000/rastertiles_1651913842"],
  ["气温","TMP","https://dev-tile.terraqt.com/tile/xyz/{z}/{x}/{y}.pbf?path=d028e889-46fe-42b2-9c7f-af1e26fb8ccf/der3.a/20000901/CN-100000/rastertiles_1651913854"],
  ["气温","TMP","https://dev-tile.terraqt.com/tile/xyz/{z}/{x}/{y}.pbf?path=d028e889-46fe-42b2-9c7f-af1e26fb8ccf/der3.a/20001001/CN-100000/rastertiles_1651913861"],
  ["气温","TMP","https://dev-tile.terraqt.com/tile/xyz/{z}/{x}/{y}.pbf?path=d028e889-46fe-42b2-9c7f-af1e26fb8ccf/der3.a/20000301/CN-100000/rastertiles_1651913816"],
  ["气温","TMP","https://dev-tile.terraqt.com/tile/xyz/{z}/{x}/{y}.pbf?path=d028e889-46fe-42b2-9c7f-af1e26fb8ccf/der3.a/20000401/CN-100000/rastertiles_1651913823"],
  ["气温","TMP","https://dev-tile.terraqt.com/tile/xyz/{z}/{x}/{y}.pbf?path=d028e889-46fe-42b2-9c7f-af1e26fb8ccf/der3.a/20001201/CN-100000/rastertiles_1651913873"],
  ["气温","TMP","https://dev-tile.terraqt.com/tile/xyz/{z}/{x}/{y}.pbf?path=d028e889-46fe-42b2-9c7f-af1e26fb8ccf/der3.a/20000201/CN-100000/rastertiles_1651913810"],
  ["气温","TMP","https://dev-tile.terraqt.com/tile/xyz/{z}/{x}/{y}.pbf?path=d028e889-46fe-42b2-9c7f-af1e26fb8ccf/der3.a/20001101/CN-100000/rastertiles_1651913867"],
  // ["气温","TMP","https://dev-tile.terraqt.com/tile/xyz/{z}/{x}/{y}.pbf?path=d028e889-46fe-42b2-9c7f-af1e26fb8ccf/der3.a/20001231/CN-100000/rastertiles"],
  ["气温","TMP","https://dev-tile.terraqt.com/tile/xyz/{z}/{x}/{y}.pbf?path=d028e889-46fe-42b2-9c7f-af1e26fb8ccf/der3.a/20000801/CN-100000/rastertiles_1651913848"],
  ["风速","WS","https://dev-tile.terraqt.com/tile/xyz/{z}/{x}/{y}.pbf?path=d028e889-46fe-42b2-9c7f-af1e26fb8ccf/der3.e/20000201/CN-100000/rastertiles_1651913885"],
  ["风速","WS","https://dev-tile.terraqt.com/tile/xyz/{z}/{x}/{y}.pbf?path=d028e889-46fe-42b2-9c7f-af1e26fb8ccf/der3.e/20000301/CN-100000/rastertiles_1651913893"],
  ["风速","WS","https://dev-tile.terraqt.com/tile/xyz/{z}/{x}/{y}.pbf?path=d028e889-46fe-42b2-9c7f-af1e26fb8ccf/der3.e/20000401/CN-100000/rastertiles_1651913899"],
  ["风速","WS","https://dev-tile.terraqt.com/tile/xyz/{z}/{x}/{y}.pbf?path=d028e889-46fe-42b2-9c7f-af1e26fb8ccf/der3.e/20000501/CN-100000/rastertiles_1651913905"],
  ["风速","WS","https://dev-tile.terraqt.com/tile/xyz/{z}/{x}/{y}.pbf?path=d028e889-46fe-42b2-9c7f-af1e26fb8ccf/der3.e/20000601/CN-100000/rastertiles_1651913912"],
  ["风速","WS","https://dev-tile.terraqt.com/tile/xyz/{z}/{x}/{y}.pbf?path=d028e889-46fe-42b2-9c7f-af1e26fb8ccf/der3.e/20000101/CN-100000/rastertiles_1651913879"],
  ["风速","WS","https://dev-tile.terraqt.com/tile/xyz/{z}/{x}/{y}.pbf?path=d028e889-46fe-42b2-9c7f-af1e26fb8ccf/der3.e/20000901/CN-100000/rastertiles_1651913931"],
  ["风速","WS","https://dev-tile.terraqt.com/tile/xyz/{z}/{x}/{y}.pbf?path=d028e889-46fe-42b2-9c7f-af1e26fb8ccf/der3.e/20001001/CN-100000/rastertiles_1651913937"],
  ["风速","WS","https://dev-tile.terraqt.com/tile/xyz/{z}/{x}/{y}.pbf?path=d028e889-46fe-42b2-9c7f-af1e26fb8ccf/der3.e/20001101/CN-100000/rastertiles_1651913943"],
  ["风速","WS","https://dev-tile.terraqt.com/tile/xyz/{z}/{x}/{y}.pbf?path=d028e889-46fe-42b2-9c7f-af1e26fb8ccf/der3.e/20001201/CN-100000/rastertiles_1651913950"],
  ["风速","WS","https://dev-tile.terraqt.com/tile/xyz/{z}/{x}/{y}.pbf?path=d028e889-46fe-42b2-9c7f-af1e26fb8ccf/der3.e/20000801/CN-100000/rastertiles_1651913925"],
  // ["风速","WS","https://dev-tile.terraqt.com/tile/xyz/{z}/{x}/{y}.pbf?path=d028e889-46fe-42b2-9c7f-af1e26fb8ccf/der3.e/20001231/CN-100000/rastertiles"],
  ["风速","WS","https://dev-tile.terraqt.com/tile/xyz/{z}/{x}/{y}.pbf?path=d028e889-46fe-42b2-9c7f-af1e26fb8ccf/der3.e/20000701/CN-100000/rastertiles_1651913918"]
]

export const weatherConfig = weatherConfigSrc.reduce((sum, o) => {
  const [name, key, url] = o;
  const kMap = sum[key] || {
    name,
    key,
    urls: [],
  };

  const month = parseInt(/2000(\d)*/.exec(url)[0].substring(4, 6), 10);

  kMap.urls[month] = url;

  return {
    ...sum,
    [key]: kMap,
  }
}, {});
