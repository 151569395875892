import { Cascader } from 'antd'
import React from 'react'
import { CommonService } from '../apis/project-service-history'

const deepMap = (data: any): any[] => {
  const rsp: any = {}
  rsp.value = `${data.code}|||${data.name}`
  rsp.label = data.name
  const chidren = data.children
  if (chidren) {
    rsp.children = chidren.map((o: any) => deepMap(o))
  }
  return rsp
}

const deepMapScene = (data: any): any[] => {
  const rsp: any = {}
  rsp.value = data.name
  rsp.label = data.name
  const chidren = data.children
  if (chidren) {
    rsp.children = chidren.map((o: any) => deepMapScene(o))
  }
  return rsp
}

export const SceneSelector = (props: { value?: string; onChange?: (selected: string) => void }) => {
  const [options, setOptions] = React.useState<any[]>()
  React.useEffect(() => {
    CommonService.sceneList()
      .then((resp) => {
        console.log(resp.data.map((o: any) => deepMapScene(o)))
        setOptions(resp.data.map((o: any) => deepMapScene(o)))
      })
      .catch(() => {})
  }, [])

  const displayRender = (labels: any, selectedOptions: any) =>
    labels.map((label: any, i: number) => {
      const option = selectedOptions[i]
      if (!option) {
        return <span></span>
      }
      return (
        <span key={option.value}>
          {label} {i === 0 ? ' - ' : ''}{' '}
        </span>
      )
    })

  const getValueWithCode = (code?: string) => {
    if (!code || !options) {
      return null
    }
    const result = ['', '', '']
    options.forEach((o: any) => {
      o.children.forEach((oo: any) => {
        if (oo.value.split(' - ').includes(code)) {
          result[0] = o.value
          result[1] = oo.value
        }
      })
    })
    return result
  }

  if (!options) {
    return null
  }
  return (
    <Cascader
      className="form-cascader"
      options={options}
      value={getValueWithCode(props.value) || ['', '']}
      displayRender={displayRender}
      onChange={(data: any) => {
        if (data && data.length === 2) {
          props.onChange?.(data[1])
        } else {
          props.onChange?.('')
        }
      }}
    />
  )
}

export const IndustrySelector = (props: { value?: string; onChange?: (selected: string) => void }) => {
  const [options, setOptions] = React.useState<any[]>()
  React.useEffect(() => {
    CommonService.industryCodeList()
      .then((resp) => {
        setOptions(resp.data.map((o: any) => deepMap(o)))
      })
      .catch(() => {})
  }, [])

  const displayRender = (labels: any, selectedOptions: any) =>
    labels.map((label: any, i: number) => {
      const option = selectedOptions[i]
      if (!option) {
        return <span></span>
      }
      return (
        <span key={option.value}>
          {label} {i === 0 ? ' - ' : ''}{' '}
        </span>
      )
    })

  const getValueWithCode = (code?: string) => {
    if (!code || !options) {
      return null
    }
    const result = ['', '', '']
    options.forEach((o: any) => {
      o.children.forEach((oo: any) => {
        if (oo.value.includes(code)) {
          result[0] = o.value
          result[1] = oo.value
        }
      })
    })
    return result
  }

  if (!options) {
    return null
  }
  return (
    <Cascader
      className="form-cascader"
      options={options}
      value={getValueWithCode(props.value) || ['', '']}
      displayRender={displayRender}
      onChange={(data: any) => {
        if (data && data.length === 2) {
          props.onChange?.(data[1].split('|||')[0])
        } else {
          props.onChange?.('')
        }
      }}
    />
  )
}
