import React, { useEffect, useState } from 'react'
import { parseNumber, parseSuffixNumber, ResourceMap } from './resource-map'
import { Button, Drawer, message, Modal, Radio, Select, Spin, Tooltip } from 'antd'
import { ApplicationModal, BreadcrumbObservable } from '../../application'
import { query, ResourceSift } from './resource-sift'
import { RoofSift } from './roof-sift'
import { EyeFilled, EyeInvisibleFilled } from '@ant-design/icons'
import { MiniToolBar } from './mini-tool-bar'
import { ResourceMapbox } from './resource-mapbox'
import { EvaluationV2Api } from '../../services'
import { pageSize } from './roof-sift-modal'
import { CommonService, EvaluationService } from '../../apis/project-service-history'
import { CitySelector } from './city-selector'
import axios from 'axios'
import { token } from '../../utils/utils'
import { ViewFile } from './docx/view-file'
import _ from "lodash";
import {ProjectService} from "../../apis/project-service";

const ReportDownloader = (props: any) => {
  const [edge, setEdge] = useState('country')
  const [adcode, setAdcode] = useState('')
  const [type, setType] = useState('1')

  const [downloading, setDownloading] = useState(false)

  const downloadTemplate = (item: any, isWord = true) => {
    setDownloading(true)
    const prefix = axios.defaults.baseURL
    const url = `${prefix || ''}/api/i3060/roof/getRoofWord?adcode=${item.adcode}&type=${isWord ? '0' : '1'}` // ${isWord ? 'readWord' : 'readPdf'}/${id}`
    const authToken: string = token.get() || ''
    fetch(url, {
      method: 'get',
      headers: {
        Authorization: authToken,
        'Content-Type': 'application/json;charset=UTF-8'
      }

    })
      .then((resp) => {
        let fileName =
          resp.headers.get('content-disposition')?.split(';')[1].split('filename=')[1] || `roof-report-${item.adcode}-${Date.now()}.docx`
        fileName = decodeURIComponent(fileName)
        resp
          .blob()
          .then((blob) => {
            if ((window.navigator as any).msSaveOrOpenBlob) {
              ;(window.navigator as any).msSaveBlob(blob, fileName)
            } else {
              const a = document.createElement('a')
              document.body.appendChild(a)
              const url = window.URL.createObjectURL(blob)
              a.href = url
              a.download = fileName
              a.target = '_blank'
              a.click()
              a.remove()
              window.URL.revokeObjectURL(url)
            }
          })
          .finally(() => {
            setDownloading(false)
            message.success('报告下载中...')
            props.close()
          })
      })
      .catch(() => {
        setDownloading(false)
      })
      .finally(() => {
        Modal.destroyAll()
      })
  }


  return (
    <div>
      <div style={{
        marginBottom: '1rem'
      }}>
        <span className='required' style={{
          marginRight: 20
        }}>报告范围</span>
        <Radio.Group value={edge} onChange={(val) => {
          setAdcode('')
          setEdge(val.target.value)
        }}>
          <Radio value='country'>全国</Radio>
          <Radio value='province'>省级</Radio>
          <Radio value='city'>市级</Radio>
          <Radio value='district'>区县</Radio>
        </Radio.Group>
      </div>
      {
        edge !== 'country' && <div style={{
          marginTop: 20
        }}>
        <span className='required' style={{
          marginRight: 20
        }}>选择{edge === 'province' ? '省份' : edge === 'city' ? '市级' : '县级'}</span>
          <CitySelector type={edge} value={adcode} onChange={(data) => {
            setAdcode(data)
          }} />
        </div>
      }
      <div style={{
        marginTop: 20
      }}>
        <span className='required' style={{
          marginRight: 20
        }}>报告格式</span>
        <Radio.Group buttonStyle='solid' value={type} onChange={(dom) => {
          setType(dom.target.value)
        }}>
          <Radio.Button value='1'>PDF</Radio.Button>
          <Radio.Button value='0'>WORD</Radio.Button>
        </Radio.Group>
      </div>
      <div style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        marginTop: 20
      }}>
        {
          localStorage.getItem('viewMode') === '1' && <Button style={{
            marginRight: 5
          }} type='link' onClick={() => {
            Modal.info({
              title: '屋顶资源分析报告',
              content: <ViewFile data={{ adcode: edge === 'country' ? '100000' : adcode }}/>,
              width: 1200,
            });
          }}>预览</Button>
        }
        <Button style={{
          marginRight: 5
        }} type='primary' ghost onClick={() => {
          props.close?.()
        }}>取消</Button>
        <Button loading={downloading} type='primary' onClick={() => {
          if (edge !== 'country' && !adcode) {
            message.error(`请选择${edge === 'province' ? '省份' : '市区'}`)
            return
          }
          downloadTemplate({
            adcode: edge === 'country' ? '100000' : adcode
          }, type === '0')
        }}>生成</Button>
      </div>
    </div>
  )
}

export const preMap: { [key: string]: { radix?: number; type?: string; div?: number, formatter?: (val: number) => string } } = {
  regionAreaSort: {
    radix: 2,
    type: 'e',
    div: 1000000
  }, //'}>区域面积 k㎡</Select.Option>
  yearTotalIrradianceSort: {
    radix: 2,
    type: 'custom'
  }, //'}>年辐照 kWh/㎡</Select.Option>
  roofCountSort: {
    radix: 0,
    type: 'custom'
  }, //'}>武定数量 个</Select.Option>
  roofAreaSort: {
    radix: 2,
    type: 'custom'
  }, //'}>屋顶面积 ㎡</Select.Option>
  avgRoofAreaSort: {
    radix: 2,
    type: 'custom'
  }, //'}>屋顶面积平均值 ㎡</Select.Option>
  roofRatioSort: {
    formatter: (val: number) => {
      return parseSuffixNumber(val * 1000, 2)
    }
  } //'}>覆盖率 %</Select.Option>
}

let page = 1

export const ResourceReckon = () => {
  const [showSift, setShowSift] = useState(true)
  const [showRoofSift, setShowRoofSift] = useState(false)

  const [selectType, setSelectType] = useState<string>('avgRoofAreaSort')
  const [regionListData, setRegionListData] = useState<any>()
  const [regionListSrcData, setRegionListSrcData] = useState<any>()

  const [regionListGlobalData, setRegionListGlobalData] = useState<any>()
  const [fiteredTotalData, setFilteredTotalData] = useState<any>()

  const [hideCluster, setHideCluster] = useState(true)

  const [showTool, setShowTool] = useState(false)

  const mapbox = location.href.includes('mapbox')

  useEffect(() => {
    setRegionListData(regionListSrcData)
  }, [regionListSrcData])


  useEffect(() => {
    BreadcrumbObservable.next([])
    page = 1
    loadGlobalData()
    return () => {
      page = 1
      const obj: any = {
        label: '',
        adcodes: [],
        regionAreaMin: undefined,
        regionAreaMax: undefined,
        yearTotalIrradianceMin: undefined,
        yearTotalIrradianceMax: undefined,
        roofCountMin: undefined,
        roofCountMax: undefined,
        roofAreaMin: undefined,
        roofAreaMax: undefined,
        avgRoofAreaMin: undefined,
        avgRoofAreaMax: undefined,
        roofRatioMin: undefined,
        roofRatioMax: undefined,

        regionAreaSort: '0',
        yearTotalIrradianceSort: '0',
        roofCountSort: '0',
        roofAreaSort: '0',
        avgRoofAreaSort: '0',
        roofRatioSort: '0'
      }
      Object.keys(obj).forEach((key) => {
        query[key] = obj[key]
      })
    }
  }, [])

  const [loading, setLoading] = useState(false)
  const [hasMore, setHasMore] = useState(true)
  const loadGlobalData = () => {
    new EvaluationV2Api().i3060RegionRoofGetRegionDataGet({
      level: 'district'
    } as any).then((resp: any) => {
      let gDt: any[] = Object.values(resp.data) || []
      if (!regionListGlobalData) {
        setRegionListGlobalData(gDt)
      }
    })
  }
  const loadData = (append = false) => {
    setLoading(true)
    if (!append) {
      page = 1
    } else {
      page++
    }
    const {
      label,
      adcodes,
      regionAreaMin,
      regionAreaMax,
      yearTotalIrradianceMin,
      yearTotalIrradianceMax,
      roofCountMin,
      roofCountMax,
      roofAreaMin,
      roofAreaMax,
      avgRoofAreaMin,
      avgRoofAreaMax,
      roofRatioMin,
      roofRatioMax
    } = query
    const listQuery = {
      label,
      adcodes: adcodes.join(','),
      regionAreaMin,
      regionAreaMax,
      yearTotalIrradianceMin,
      yearTotalIrradianceMax,
      roofCountMin,
      roofCountMax,
      roofAreaMin,
      roofAreaMax,
      avgRoofAreaMin,
      avgRoofAreaMax,
      roofRatioMin,
      roofRatioMax
    }

    new EvaluationV2Api().i3060RegionRoofGetRegionRoofSummaryPost({
      [selectType]: '2',
      ...listQuery,
      page: page,
      size: pageSize
    } as any).then((rsp) => {
      const resp = rsp.data
      let dt: any[] = []
      if (append) {
        dt = [...regionListSrcData, ...resp.data]
      } else {
        dt = resp.data
      }
      if (dt.length >= resp.total) {
        setHasMore(false)
      } else {
        setHasMore(true)
      }
      setRegionListSrcData(dt)
    }).finally(() => {
      setLoading(false)
    })

    EvaluationService.list(listQuery).then((resp) => {
      setFilteredTotalData(resp.data)
    })
  }

  useEffect(() => {
    if (selectType) {
      loadData()
    }
  }, [selectType])

  const [showReporter, setShowReporter] = useState(false)
  useEffect(() => {
    CommonService.showKeys().then(resp => {
      setShowReporter(!!resp.data?.includes('roofWord'))
    })
  }, [])

  const [addressConsigneeList, setAddressConsigneeList] = useState([]);

  const onConsigneeSearch = (val: string) => {
    ProjectService.getI3060MapInputTips({ cityCode: '', address: val }).then((resp: any) => {
      setAddressConsigneeList(resp.data)
    })
  }

  return (
    <div>
      {mapbox && <ResourceMapbox
        pointerData={fiteredTotalData}
        srcData={regionListGlobalData}
        hideCluster={hideCluster}
        doHideCluster={() => {
          setHideCluster((cluster) => !cluster)
        }}
        showSift={showSift} showRoofSift={showRoofSift} showSiftHandler={(showSift: boolean) => {
        setShowSift(showSift)
      }} showRoofSiftHandler={(showSift: boolean) => {
        setShowRoofSift(showSift)
      }} />}
      {!mapbox && (
        <ResourceMap
          showSiftHandler={(showSift: boolean) => {
            setShowSift(showSift)
          }}
          showRoofSiftHandler={(showSift: boolean) => {
            setShowRoofSift(showSift)
          }}
          hideCluster={hideCluster}
          doHideCluster={() => {
            setHideCluster((cluster) => !cluster)
          }}
          pointerData={regionListSrcData}
          currentArea={
            regionListGlobalData
              ?.map((o: any) => [o.adcode, o.parentAdcode, o.grandparentAdcode])
              .flat()
              .filter(Boolean) || []
          }
        />
      )}
      {showRoofSift && (
        <RoofSift
          data={regionListGlobalData.find((o: any) => o.adcode == (window as any).currentAdcode) || {}}
          onBack={() => {
            ;(window as any).cancelScan?.()
            setShowSift(true)
            setShowRoofSift(false)
          }}
        />
      )}
      {showSift && (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            position: 'absolute',
            overflow: 'hidden',
            left: 20,
            top: 20,
            width: 246,
            bottom: 125,
            pointerEvents: 'all'
          }}
        >
          <div
            style={{
              backgroundColor: 'white'
            }}
          >
            <Select
              disabled={!fiteredTotalData || fiteredTotalData.length === 0}
              style={{
                width: '100%',
              }}
              size="middle"
              showSearch
              onFocus={() => {console.log('onFocus')}}
              onChange={(value: string) => {
                const dt = JSON.parse(value)
                const obj = fiteredTotalData?.find((oo) => {
                  return oo.adcode == dt.adcode
                })

                if (obj) {
                  const [lng, lat] = dt.location.split(',');

                  const to = {
                    ...obj,
                    lng,
                    lat,
                    zoom: 14,
                  }
                  ;(window as any).reloadAreaNode?.({...to})
                  ;(window as any).doScan?.();
                }
              }}
              placeholder="请输入地址"
              onSearch={_.debounce(onConsigneeSearch, 300)}
              filterOption={() => true}
            >
              {addressConsigneeList?.map((o: any, idx) => (
                <Select.Option key={`${o.address}--${idx}_consignee`} value={JSON.stringify(o)}>
                  {o.name}
                  <span className="hide-in-input" style={{ fontSize: 10, color: 'lightgrey', marginLeft: '1rem' }}>
                      {o.district}
                    </span>
                </Select.Option>
              ))}
            </Select>
            {/*<Select*/}
            {/*  style={{*/}
            {/*    width: '100%'*/}
            {/*  }}*/}
            {/*  showSearch*/}
            {/*  allowClear*/}
            {/*  placeholder='请输入地址'*/}
            {/*  filterOption={(input, option: any) => {*/}
            {/*    return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0*/}
            {/*  }}*/}
            {/*  onChange={(value) => {*/}
            {/*    const dt = JSON.parse(value)*/}
            {/*    const obj = fiteredTotalData?.find((oo) => {*/}
            {/*      return oo.adcode == dt.adcode*/}
            {/*    })*/}
            {/*    if (obj) {*/}
            {/*      console.log(dt, obj);*/}
            {/*      ;(window as any).reloadAreaNode?.({ ...dt, parentAdcode: obj.parentAdcode })*/}
            {/*      ;(window as any).showInfoWindow?.(dt)*/}
            {/*    }*/}
            {/*  }}*/}
            {/*>*/}
            {/*  {regionListGlobalData?.map((o: any) => {*/}
            {/*    return (*/}
            {/*      <Select.Option key={o.adcode} value={JSON.stringify(o)}>*/}
            {/*        {o.label}*/}
            {/*      </Select.Option>*/}
            {/*    )*/}
            {/*  })}*/}
            {/*</Select>*/}
          </div>
          <div
            style={{
              flexGrow: 1,
              marginTop: 16,
              paddingLeft: 0,
              paddingRight: 0,
              paddingTop: 4,
              paddingBottom: 16,
              backgroundColor: 'white',
              display: 'flex',
              flexDirection: 'column',
              height: 1
            }}
          >
            <div
              style={{
                fontSize: 16,
                color: '#262E3F',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                paddingRight: 16
              }}
            >
              <div
                style={{
                  width: 3,
                  height: 22,
                  background: '#1EC692',
                  marginRight: 13
                }}
              />
              <span
                style={{
                  lineHeight: '30px',
                  flexGrow: 1
                }}
              >
                区域排名
              </span>
              <Button
                type='link'
                icon={hideCluster ? <EyeInvisibleFilled /> : <EyeFilled />}
                onClick={() => {
                  setHideCluster(!hideCluster)
                }}
              />
              <Button
                type='link'
                onClick={() => {
                  const modal = ApplicationModal.confirm({
                    keyboard: false,
                    icon: null,
                    title: '更多筛选',
                    closable: true,
                    content: (
                      <ResourceSift
                        onClose={(data: any) => {
                          modal.destroy()
                          if (data) {
                            loadData()
                          }
                        }}
                      />
                    ),
                    width: 1250,
                    okButtonProps: {
                      style: { display: 'none' }
                    },
                    cancelButtonProps: {
                      style: { display: 'none' }
                    }
                  })
                }}
              >
                更多筛选
              </Button>
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                marginTop: 8,
                boxShadow: 'rgba(0,0,0,0.1) 0px -6px 6px 0px',
                paddingLeft: 16,
                paddingRight: 16,
                paddingTop: 5
              }}
            >
              <div
                style={{
                  fontSize: '14px',
                  color: '#262E3F',
                  width: 60,
                  whiteSpace: 'nowrap'
                }}
              >
                区域名称
              </div>
              <Select
                style={{
                  flexGrow: 1,
                  marginLeft: 14
                }}
                value={selectType}
                onChange={(key) => {
                  setRegionListData(undefined)
                  setSelectType(key)
                }}
              >
                <Select.Option value={'regionAreaSort'}>区域面积 k㎡</Select.Option>
                <Select.Option value={'yearTotalIrradianceSort'}>年总辐照 kWh/㎡</Select.Option>
                <Select.Option value={'roofCountSort'}>屋顶数量 个</Select.Option>
                <Select.Option value={'roofAreaSort'}>屋顶总面积 ㎡</Select.Option>
                <Select.Option value={'avgRoofAreaSort'}>屋顶平均面积 ㎡</Select.Option>
                <Select.Option value={'roofRatioSort'}>屋顶覆盖率 ‰</Select.Option>
              </Select>
            </div>
            {regionListData && (
              <div
                style={{
                  flexGrow: 1,
                  overflowY: 'auto',
                  overflowX: 'hidden',
                  marginTop: 10,
                  height: 'calc(100% - 340px)'
                }}
              >
                {regionListData?.map((o: any, idx: number) => (
                  <div
                    key={o.adcode}
                    style={{
                      fontSize: 12,
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      paddingLeft: 16,
                      paddingRight: 16,
                      height: 32
                    }}
                  >
                    <div
                      style={{
                        color: idx === 0 ? '#1CB887' : idx === 1 ? '#F86728' : idx === 2 ? '#3274F6' : '#767F95',
                        minWidth: 40
                      }}
                    >
                      {idx + 1}
                    </div>
                    <Tooltip title={o.label} placement='top'>
                      <div
                        style={{
                          color: '#262E3F',
                          marginRight: 5,
                          minWidth: 80,
                          flexShrink: 1,
                          overflow: 'hidden',
                          whiteSpace: 'nowrap',
                          textOverflow: 'ellipsis',
                          cursor: 'pointer'
                        }}
                        onClick={() => {
                          const obj = fiteredTotalData?.find((oo) => {
                            return oo.adcode == o.adcode
                          })
                          if (obj) {
                            ;(window as any).reloadAreaNode?.({ ...o, parentAdcode: obj.parentAdcode })
                            ;(window as any).showInfoWindow?.(o)
                          }
                        }}
                      >
                        {o.label.split('-').slice(-1)[0]}
                      </div>
                    </Tooltip>
                    <div
                      style={{
                        minWidth: 80,
                        textOverflow: 'ellipsis'
                      }}
                    >
                      <div
                        style={{
                          background: 'rgba(30, 198, 146, 0.2)',
                          color: '#1CB887',
                          width:
                            (80 * o[selectType.replace('Sort', '')]) /
                            Math.max(...regionListData.map((o: any) => o[selectType.replace('Sort', '')]))
                        }}
                      >
                        {
                          (preMap[selectType]
                            ? preMap[selectType].formatter?.(o[selectType.replace('Sort', '')]) ||
                            parseNumber(o[selectType.replace('Sort', '')], preMap[selectType] as any)
                            : o[selectType.replace('Sort', '')])
                        }
                      </div>
                    </div>
                  </div>
                ))}
                {
                  hasMore && <div
                    style={{
                      fontSize: 12,
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      paddingLeft: 16,
                      paddingRight: 16,
                      height: 32,
                      cursor: 'pointer'
                    }}
                    onClick={() => {
                      loadData(true)
                    }}
                  >
                    <Spin spinning={loading}>加载更多...</Spin>
                  </div>
                }
              </div>
            )}

          </div>
        </div>
      )}
      {
        (location.href.includes('localhost') || showReporter) && <Button
          style={{
            position: 'absolute',
            top: 20,
            right: 120
          }}
          type='primary'
          onClick={() => {
            const dlg = ApplicationModal.confirm({
              title: '资源报告设置',
              icon: null,
              content: (
                <ReportDownloader close={() => {
                  dlg.destroy()
                }} />
              ),
              className: 'hide-footer',
              cancelButtonProps: {
                style: { display: 'none' }
              },
              okButtonProps: {
                style: { display: 'none' }
              },
              width: 600
            })
          }}
        >
          生成报告
        </Button>
      }
      <Button
        style={{
          position: 'absolute',
          top: 20,
          right: 10
        }}
        type='primary'
        onClick={() => {
          setShowTool(true)
        }}
      >
        光伏小工具
      </Button>
      <Drawer
        getContainer={false}
        mask={false}
        maskClosable
        closable={false}
        destroyOnClose={true}
        placement='right'
        onClose={() => setShowTool(false)}
        visible={showTool}
        className='no-drawer-boxshadow mask-transparent'
        style={{ position: 'absolute', backgroundColor: 'transparent' }}
        width={334}
        bodyStyle={{
          padding: 0
        }}
      >
        <MiniToolBar
          onClose={() => {
            setShowTool(false)
          }}
        />
      </Drawer>
    </div>
  )
}
