/**
 * project
 * unknown
 *
 * OpenAPI spec version: last
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import ajax, { AjaxPromise, ExtraFetchParams } from '@ajax';
import * as models from '../model/models';

/* tslint:disable:no-unused-variable member-ordering object-literal-shorthand */

/**
  * @description authCommonSendFinanceValidMsgGet headers 参数
  * @property `Authorization` Authorization (Only:)
  */
export interface ParamsHeaderauthCommonSendFinanceValidMsgGet {
  /** Authorization (Only:) */
  'Authorization': string;
}
/**
  * @description authCommonSendFinanceValidMsgGet参数
  * @property `captchaVerification` 滑块验证码
  */
export interface ParamsauthCommonSendFinanceValidMsgGet {
  // queryParams
  /**
   * 滑块验证码
   */
  captchaVerification: string;
}
/**
  * @description i3060RegionGet headers 参数
  * @property `Authorization` Authorization (Only:)
  */
export interface ParamsHeaderi3060RegionGet {
  /** Authorization (Only:) */
  'Authorization': string;
}
/**
  * @description i3060RegionGet参数
  */
export interface Paramsi3060RegionGet {
}
/**
  * @description i3060RegionGetBoundaryGet headers 参数
  * @property `Authorization` Authorization (Only:)
  */
export interface ParamsHeaderi3060RegionGetBoundaryGet {
  /** Authorization (Only:) */
  'Authorization': string;
}
/**
  * @description i3060RegionGetBoundaryGet参数
  * @property `code` 城市code
  * @property `level` 
  */
export interface Paramsi3060RegionGetBoundaryGet {
  // queryParams
  /**
   * 城市code
   */
  code: string;
  /**
   * 
   */
  level: string;
}
/**
  * @description i3060ResourceExportPost参数
  * @property `files` 文件列表
  */
export interface Paramsi3060ResourceExportPost {
  // formParams
  /**
   * 文件列表
   */
  files: any;
}
/**
  * @description removei3060RegionGetBoundaryGet headers 参数
  * @property `Authorization` Authorization (Only:)
  */
export interface ParamsHeaderremovei3060RegionGetBoundaryGet {
  /** Authorization (Only:) */
  'Authorization': string;
}
/**
  * @description removei3060RegionGetBoundaryGet参数
  * @property `code` 城市code
  * @property `level` 
  */
export interface Paramsremovei3060RegionGetBoundaryGet {
  // queryParams
  /**
   * 城市code
   */
  code: string;
  /**
   * 
   */
  level: string;
}

export class CommonApi {
  protected $basePath = '/api'.replace(/\/$/, '');

  public constructor(basePath?: string) {
    if (basePath !== undefined) {
      this.$basePath = basePath.replace(/\/$/, '');
    }
  }

  /**
   * 
   * @summary 发送删除或编辑参数库验证码
   * @param params ParamsauthCommonSendFinanceValidMsgGet
   
   * @param opt ajax config
   * @returns models.AuthCommonSendFinanceValidMsg
   */
  public authCommonSendFinanceValidMsgGet = (
    params: ParamsauthCommonSendFinanceValidMsgGet,
    opt?: { headers: ParamsHeaderauthCommonSendFinanceValidMsgGet } & Omit<ExtraFetchParams, 'headers'> 
  ) : AjaxPromise<models.AuthCommonSendFinanceValidMsg>  => {
    const url = this.$basePath + `/auth/common/sendFinanceValidMsg`;
    const p: any = {};
    p.query = {};
    if ('captchaVerification' in params) p.query.captchaVerification = params.captchaVerification;
    ajax.check(params.captchaVerification, 'captchaVerification');
    return ajax.ajax({
      ...opt,
      method: 'GET',
      url,
      ...p
    });
  }
  /**
   * 
   * @summary 行业数据
   
   
   * @param opt ajax config
   * @returns models.I3060IndustryModelLoadIndustryCodeList
   */
  public i3060IndustryModelLoadIndustryCodeListGet = (
    
    opt?: ExtraFetchParams
  ) : AjaxPromise<models.I3060IndustryModelLoadIndustryCodeList>  => {
    const url = this.$basePath + `/i3060/industryModelLoad/industryCodeList`;
    const p: any = {};
    return ajax.ajax({
      ...opt,
      method: 'GET',
      url,
      ...p
    });
  }
  /**
   * 
   * @summary 获取用电场景分类
   
   
   * @param opt ajax config
   * @returns models.I3060IndustryModelLoadSceneList
   */
  public i3060IndustryModelLoadSceneListGet = (
    
    opt?: ExtraFetchParams
  ) : AjaxPromise<models.I3060IndustryModelLoadSceneList>  => {
    const url = this.$basePath + `/i3060/industryModelLoad/sceneList`;
    const p: any = {};
    return ajax.ajax({
      ...opt,
      method: 'GET',
      url,
      ...p
    });
  }
  /**
   * 
   * @summary 查询基础配置key
   
   
   * @param opt ajax config
   * @returns models.I3060OtherAuthShowKeys
   */
  public i3060OtherAuthShowKeysGet = (
    
    opt?: ExtraFetchParams
  ) : AjaxPromise<models.I3060OtherAuthShowKeys>  => {
    const url = this.$basePath + `/i3060/other/auth/showKeys`;
    const p: any = {};
    return ajax.ajax({
      ...opt,
      method: 'GET',
      url,
      ...p
    });
  }
  /**
   * 
   * @summary 查询省市区接口
   * @param params Paramsi3060RegionGet
   
   * @param opt ajax config
   * @returns models.I3060Region
   */
  public i3060RegionGet = (
    params: Paramsi3060RegionGet,
    opt?: { headers: ParamsHeaderi3060RegionGet } & Omit<ExtraFetchParams, 'headers'> 
  ) : AjaxPromise<models.I3060Region>  => {
    const url = this.$basePath + `/i3060/region`;
    const p: any = {};
    return ajax.ajax({
      ...opt,
      method: 'GET',
      url,
      ...p
    });
  }
  /**
   * 
   * @summary 通过code查询城市边界
   * @param params Paramsi3060RegionGetBoundaryGet
   
   * @param opt ajax config
   * @returns models.Removei3060RegionGetBoundary
   */
  public i3060RegionGetBoundaryGet = (
    params: Paramsi3060RegionGetBoundaryGet,
    opt?: { headers: ParamsHeaderi3060RegionGetBoundaryGet } & Omit<ExtraFetchParams, 'headers'> 
  ) : AjaxPromise<models.Removei3060RegionGetBoundary>  => {
    const url = this.$basePath + `/i3060/region/getBoundary`;
    const p: any = {};
    p.query = {};
    if ('code' in params) p.query.code = params.code;
    ajax.check(params.code, 'code');
    if ('level' in params) p.query.level = params.level;
    ajax.check(params.level, 'level');
    return ajax.ajax({
      ...opt,
      method: 'GET',
      url,
      ...p
    });
  }
  /**
   * 
   * @summary 导入地区屋顶
   * @param params Paramsi3060ResourceExportPost
   
   * @param opt ajax config
   * @returns any
   */
  public i3060ResourceExportPost = (
    params: Paramsi3060ResourceExportPost,
    opt?: ExtraFetchParams
  ) : AjaxPromise<any>  => {
    const url = this.$basePath + `/i3060/resource/export`;
    const p: any = {};
    p.form = new FormData();
    // 上传文件
    opt = {
      ...opt,
      headers: {
        ...(opt && opt.headers),
        'Content-Type': 'multipart/form-data'
      }
    };
    // p.form = new FormData();
    if ('files' in params) p.form.append('files', params.files);
    ajax.check(params.files, 'files');
    return ajax.ajax({
      ...opt,
      method: 'POST',
      url,
      ...p
    });
  }
  /**
   * 
   * @summary 新通过code查询城市边界（弃用）
   * @param params Paramsremovei3060RegionGetBoundaryGet
   
   * @param opt ajax config
   * @returns models.Removei3060RegionGetBoundary
   */
  public removei3060RegionGetBoundaryGet = (
    params: Paramsremovei3060RegionGetBoundaryGet,
    opt?: { headers: ParamsHeaderremovei3060RegionGetBoundaryGet } & Omit<ExtraFetchParams, 'headers'> 
  ) : AjaxPromise<models.Removei3060RegionGetBoundary>  => {
    const url = this.$basePath + `/removei3060/region/getBoundary`;
    const p: any = {};
    p.query = {};
    if ('code' in params) p.query.code = params.code;
    ajax.check(params.code, 'code');
    if ('level' in params) p.query.level = params.level;
    ajax.check(params.level, 'level');
    return ajax.ajax({
      ...opt,
      method: 'GET',
      url,
      ...p
    });
  }
}

export default new CommonApi();