import React from 'react'
import { Form, Input } from 'antd'

// 合作方个人字段
export default () => {
  return (
    <>
      <Form.Item
        label="姓名"
        name="name"
        rules={[
          { required: true, message: '请输入姓名' },
          {
            validator: (rule: any, value: string) => {
              if (value && value.length > 18) {
                return Promise.reject(`姓名最多允许输入18个字`)
              }
              return Promise.resolve()
            },
          },
        ]}
      >
        <Input placeholder="请输入姓名" />
      </Form.Item>
      <Form.Item
        label="身份证号码"
        name="code"
        rules={[
          { required: true, message: '请输入身份证号码' },
          {
            validator: (r: any, v: string) => {
              if (v && !/(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/.test(v)) {
                return Promise.reject('身份证输入有误，请重新输入~')
              }
              return Promise.resolve()
            },
          },
        ]}
      >
        <Input placeholder="请输入身份证号码" />
      </Form.Item>
      <Form.Item
        label="联系电话"
        name="phone"
        rules={[
          { required: true, message: '请输入联系电话' },
          {
            validator: (r: any, v: string) => {
              if (v && !/^\d{11}$/.test(v)) {
                return Promise.reject('联系电话输入有误，请重新输入~')
              }
              return Promise.resolve()
            },
          },
        ]}
      >
        <Input placeholder="请输入联系电话" />
      </Form.Item>
    </>
  )
}
