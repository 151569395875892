import React, { useEffect, useState, useReducer, useRef } from 'react'
import styles from '../index.module.scss'
import {
  Spin,
  Table,
  Input,
  Form,
  Button,
  Space,
  Dropdown,
  Menu,
  notification,
  message,
  Modal,
  // List,
  Tooltip,
} from 'antd'
import { EllipsisOutlined, PlusCircleFilled, PlusOutlined, ExclamationCircleFilled } from '@ant-design/icons'
import { FinanceService } from '../../../../apis/project-service-history'
import IncentiveModal from './incentive-modal'
import IncentiveReadonlyModal from './incentive-readonly-modal'
import { incentiveReducer } from '../reducer'
import { microgridFactor, subsidyType } from '../incentive-data'
import { getFieldLabel } from '../../../../utils/utils'
import { CustomIcons } from '../../../../global'
import { SubBreadcrumbObservable } from '../../layout'
import { paramsStoreShowError } from '../..'
// import CodeModal from './CodeModal'
import { RESOURCE_NAME_EXP } from '../../../../utils/constant'

// 激励补贴参数
const IncentiveSubsidy = () => {
  const [loading, setLoading] = useState(false)
  const [
    ,
    // saveLoading,
    setSaveLoading,
  ] = useState(false)
  const [readonly, setReadonly] = useState(true)
  const [dataSource, setDataSource] = useState<any[]>([])
  let dataSourceRef = useRef<any[]>()

  const [confirmLoading, setConfirmLoading] = useState(false)
  const [backupDataSource, setBackupDataSource] = useState<any>([])
  // const [verifyModalVisible, setVerifyModalVisible] = useState(false)

  const inititalState = {
    visible: false,
    type: 'read',
    currentItem: {},
    index: 0,
    showType: 0,
  }
  const [state, hookDispatch] = useReducer(incentiveReducer, inititalState)

  const [form] = Form.useForm()
  const columns = [
    {
      title: '',
      dataIndex: 'name',
      width: 300,
      render: (text: string, row: any, index: any) => {
        return (
          <div
            className="incentive-table-name"
            onClick={() => {
              readonly ? showDetail(row, 0, index, row.parentIndex) : showDetail(row, 1, index, row.parentIndex)
            }}
            style={{ paddingLeft: 12 }}
          >
            {row.name}
          </div>
        )
      },
    },
    {
      title: '',
      dataIndex: 'microgridFactor',
      width: 300,
      render: (text: string) => {
        return <>{getFieldLabel(microgridFactor, text)}</>
      },
    },
    {
      title: '',
      dataIndex: 'subsidyType',
      width: 300,
      render: (text: string) => {
        return <>{getFieldLabel(subsidyType, text)}</>
      },
    },
    {
      title: '',
      dataIndex: 'other',
      render: (text: any, record: any, index: number) => {
        return (
          !readonly && (
            <div className={styles.operaIcons}>
              <Space>
                {/* <Tooltip title="复制">
                  <CustomIcons type="klf-copy" />
                </Tooltip> */}
                <Tooltip title="删除">
                  <CustomIcons
                    type="klf-delete"
                    onClick={() => {
                      deleteSubRow(record, index)
                    }}
                  />
                </Tooltip>
              </Space>
            </div>
          )
        )
      },
    },
  ]

  useEffect(() => {
    SubBreadcrumbObservable.next([
      {
        name: '设置',
        url: '',
      },
      {
        name: '参数库',
        url: '/user-center/user-params?type=fin',
      },
      {
        name: '激励补贴参数',
        url: '/user-center/incentive',
      },
    ])
  }, [])

  useEffect(() => {
    dataSourceRef.current = dataSource
  })

  const onCancel = () => {
    location.assign(`#/user-center/user-params?type=fin`)
  }

  // const save = async (code: any) => {
  //   if (!code) {
  //     message.error('请输入验证码')
  //     return
  //   }
  //   return saveData(code)
  // }

  const deleteSubRow = (record: any, index: number) => {
    const dataSourceData = dataSource.slice()
    const currentList = dataSourceData[record.parentIndex]?.detailList?.slice()
    currentList.splice(index, 1)
    dataSourceData.splice(record.parentIndex, 1, {
      ...dataSource[record.parentIndex],
      detailList: currentList,
    })
    setDataSource(dataSourceData)
    console.info(record, index)
  }

  const saveData = async (code?: any) => {
    setSaveLoading(true)
    FinanceService.createOrUpdateBatch({ code, root: dataSource })
      .then(() => {
        message.success('保存成功')
        onCancel()
      })
      .catch(() => {})
      .finally(() => {
        setSaveLoading(false)
      })
  }

  const showWarnModal = (editData: any, delData: any, addData: any) => {
    // const editList = editData.editIds
    // const delList = delData.delIds
    // const editProject = editData.list
    // const delProject = delData.list
    // const editProjectList = editProject?.projectList || []
    // const delProjectList = delProject?.projectList || []
    // const projectList = [...editProjectList, ...delProjectList]
    // const editFinanceList = editProject?.financeLibraryList || []
    // const delFinanceList = delProject?.financeLibraryList || []
    // const financeLibraryList = [...editFinanceList, ...delFinanceList]

    console.info(addData)
    saveData()
    // Modal.confirm({
    //   title: <strong>修改删除财务参数</strong>,
    //   className: 'danger-modal',
    //   keyboard: false,
    //   autoFocusButton: null,
    //   content: (
    //     <>
    //       <>
    //         <p>
    //           确认
    //           {delList.length === 0 && editList.length === 0 && '保存'}
    //           {delList.length > 0 && `删除【${delList?.map((o: any) => o.name).join('、')}】`}
    //           {editList.length > 0 && `修改【${editList?.map((o: any) => o.name).join('、')}】`}？
    //           <p>
    //             {delList.find((o: any) => o.isDefault)
    //               ? `此操作将导致默认激励补贴参数设置为空${projectList?.length > 0 ? '，以下项目：' : '。'}`
    //               : projectList?.length > 0
    //               ? '此操作将导致以下项目：'
    //               : ''}
    //           </p>
    //         </p>
    //         {projectList?.length > 0 && (
    //           <List
    //             style={{
    //               maxHeight: 300,
    //               overflowY: 'auto',
    //               paddingRight: 5,
    //             }}
    //             grid={{ gutter: 10, column: 1 }}
    //             dataSource={projectList}
    //             size="small"
    //             renderItem={(item: any) => (
    //               <List.Item
    //                 style={{
    //                   backgroundColor: 'rgba(249, 121, 66, 0.06)',
    //                   color: 'rgba(249, 121, 66, 1)',
    //                   height: 40,
    //                   display: 'flex',
    //                   flexDirection: 'row',
    //                   justifyContent: 'space-between',
    //                   alignItems: 'center',
    //                 }}
    //               >
    //                 <span>{item.name}</span>
    //                 {item.locked && (
    //                   <span style={{ fontSize: 16 }}>
    //                     <CustomIcons type="klf-file-alert" />
    //                   </span>
    //                 )}
    //               </List.Item>
    //             )}
    //           ></List>
    //         )}
    //         {financeLibraryList?.length > 0 && (
    //           <>
    //             <p>
    //               的以下财务方案{delList.length > 0 && '删除'}
    //               {editList.length > 0 && '失效'}：
    //             </p>
    //             <List
    //               style={{
    //                 maxHeight: 300,
    //                 overflowY: 'auto',
    //                 paddingRight: 5,
    //               }}
    //               grid={{ gutter: 10, column: 1 }}
    //               dataSource={financeLibraryList}
    //               size="small"
    //               renderItem={(item: any) => (
    //                 <List.Item
    //                   style={{
    //                     backgroundColor: 'rgba(249, 121, 66, 0.06)',
    //                     color: 'rgba(249, 121, 66, 1)',
    //                     height: 40,
    //                     display: 'flex',
    //                     flexDirection: 'row',
    //                     justifyContent: 'space-between',
    //                     alignItems: 'center',
    //                   }}
    //                 >
    //                   <span>{item.name}</span>
    //                   {item.lock && (
    //                     <span style={{ fontSize: 16 }}>
    //                       <CustomIcons type="klf-file-alert" />
    //                     </span>
    //                   )}
    //                 </List.Item>
    //               )}
    //             ></List>
    //           </>
    //         )}
    //       </>
    //     </>
    //   ),
    //   width: 480,
    //   okType: 'primary',
    //   okText: '确定',
    //   icon: <ExclamationCircleFilled style={{ color: '#f97942' }} />,
    //   onOk: async () => {
    //     // setVerifyModalVisible(true)
    //     saveData()
    //   },
    //   okButtonProps: {
    //     danger: true,
    //     style: { background: '#f97942' },
    //   },
    //   cancelButtonProps: {
    //     danger: true,
    //   },
    // })
  }

  const getWarnToast = (ids: any) => {
    return FinanceService.warnToast({
      type: 'award_subsidy_id',
      ids: ids.join(','),
    })
      .then((res) => {
        return Promise.resolve(res.data)
      })
      .catch(() => {})
  }

  // 获取修改与删除的数据
  const validEditAndDeleteData = () => {
    let delIds: any = []
    let editIds: any = []
    let addData: any = []
    backupDataSource?.forEach?.((item: any) => {
      // 判断是否被删除
      if (!dataSource?.map((newItem: any) => newItem.awardSubsidyId)?.includes(item.awardSubsidyId)) {
        delIds.push({
          name: item.name,
          isDefaut: item.isDefault,
          id: item.awardSubsidyId,
        })
        return
      }
      let findItem = dataSource.find((newItem: any) => newItem.awardSubsidyId === item.awardSubsidyId)
      for (let key in item) {
        // 数据与初始数据不等并且是页面上展示的数据
        if (item[key] != findItem[key] && findItem[key] !== undefined && key != 'detailList' && key != 'name') {
          console.info(item[key], findItem[key])
          editIds.push({
            name: item.name,
            id: item.awardSubsidyId,
          })
          return
        } else if (key == 'detailList') {
          let isEditSubKey = false
          item[key]?.forEach?.((oldKeyField: any, index: number) => {
            const newKeyField = findItem?.[key]?.[index]
            for (let subKey in oldKeyField) {
              // 判断子元素是否有变更
              if (
                oldKeyField[subKey] != newKeyField?.[subKey] &&
                oldKeyField[subKey] != null &&
                oldKeyField[subKey] != undefined &&
                oldKeyField[subKey] != 'name'
              ) {
                isEditSubKey = true
              }
              if (oldKeyField[subKey] != newKeyField?.[subKey] && oldKeyField[subKey] == 'name') {
                addData.push(item)
              }
            }
          })
          // 如果只是名字不同，直接保存
          if (item.name != findItem.name) {
            addData.push(item)
          }
          if (isEditSubKey) {
            editIds.push({
              name: item.name,
              id: item.awardSubsidyId,
            })
          }
        }
      }
    })
    dataSource.forEach((item: any) => {
      if (!item.awardSubsidyId) {
        addData.push(item)
      }
      item.detailList?.forEach((subItem: any) => {
        if (!subItem.awardSubsidyDetailId) {
          addData.push(item)
        }
      })
    })

    // 如果没有编辑和删除数据
    if (editIds.length === 0 && delIds.length === 0) {
      // 如果没有添加数据 直接返回
      if (addData.length === 0) {
        onCancel()
        return
      } else {
        // 如果添加了数据  调用保存接口
        setConfirmLoading(true)
        return saveData().finally(() => {
          setConfirmLoading(false)
        })
      }
    }

    Promise.all([
      editIds.length && getWarnToast(editIds.map((o: any) => o.id)),
      delIds.length && getWarnToast(delIds.map((o: any) => o.id)),
    ])
      .then((res) => {
        showWarnModal(
          {
            editIds,
            list: res[0],
          },
          {
            delIds,
            list: res[1],
          },
          addData
        )
      })
      .finally(() => {
        setConfirmLoading(false)
      })
  }

  const validData = () => {
    return new Promise((resolve, reject) => {
      let errors: any[] = []
      dataSource.forEach((dataItem, index) => {
        if (!dataItem.name) {
          errors.push({
            index,
            field: '名称',
            content: '激励补贴名称必填',
          })
        }
        if (dataSource.filter((item) => item.name === dataItem.name).length > 1) {
          errors.push({
            index,
            field: '名称',
            content: '激励补贴名称存在重复',
          })
        }
      })
      if (errors.length === 0) {
        resolve('')
      } else {
        paramsStoreShowError(errors[0].content)
        reject()
      }
    })
  }

  const submit = () => {
    validData().then(() => {
      form.validateFields().then(() => {
        validEditAndDeleteData()
      })
    })
  }

  const generateName = () => {
    const list = dataSource.slice() || []
    let name = '激励补贴7'
    for (let i = 7; i > 0; i--) {
      if (!list.map((item) => item.name).includes(`激励补贴${i}`)) {
        name = `激励补贴${i}`
      }
    }
    return name
  }

  //添加激励补贴
  const addItem = () => {
    const list = dataSource.slice()
    if (list.length >= 6) {
      paramsStoreShowError('激励补贴参数最多6个')
      return
    }
    // 关闭没保存的编辑
    list.forEach((item) => {
      item.rename = false
    })
    list.push({
      index: list.length,
      rename: true,
      name: generateName(),
      detailList: [],
    })
    setDataSource(list)
  }

  // 查看/编辑详情
  const showDetail = (item: any, showType: number = 0, index: any, parentIndex: any) => {
    hookDispatch({
      type: 'updateState',
      payload: {
        visible: true,
        currentItem: item,
        type: readonly ? 'read' : 'edit',
        showType,
        parentIndex,
        index,
      },
    })
  }

  const showAllSubDetail = (item: any) => {
    hookDispatch({
      type: 'updateState',
      payload: {
        visible: true,
        currentItem: item,
        type: readonly ? 'read' : 'edit',
        showType: 1,
      },
    })
  }

  // 添加补贴
  const addRow = (dataItem: any, index: number) => {
    hookDispatch({
      type: 'updateState',
      payload: {
        visible: true,
        currentItem: dataItem,
        type: 'add',
        index,
      },
    })
  }

  const [renameForm] = Form.useForm()
  // 重命名
  const reName = (record: any) => {
    console.log(record)
    renameForm.setFieldsValue({
      rename: record.item.name,
    })
    Modal.destroyAll()
    Modal.confirm({
      keyboard: false,
      autoFocusButton: null,
      title: '重命名',
      icon: <CustomIcons type="klf-alarm1" />,
      content: (
        <Form form={renameForm}>
          <Form.Item
            style={{
              display: 'flex',
              alignItems: 'center',
            }}
            name="rename"
            label="请输入激励补贴参数名称"
            rules={[
              {
                required: true,
                message: '请输入激励补贴参数名称',
              },
              {
                pattern: RESOURCE_NAME_EXP,
                message: '1-50个字符,中文、英文、_、数字和-组成',
              },
            ]}
          >
            <Input placeholder="请输入"></Input>
          </Form.Item>
        </Form>
      ),
      width: 500,
      okType: 'primary',
      okText: '确定',
      cancelText: '取消',
      onOk: () => {
        return new Promise((resolve, reject) => {
          const { rename } = renameForm.getFieldsValue()
          renameForm
            .validateFields()
            .then(() => {
              const list = dataSource.slice()
              const item = list[record.index]
              item.name = rename
              setDataSource(list)
              resolve({})
            })
            .catch((e) => {
              reject(e)
            })
        })
      },
    })
  }

  useEffect(() => {
    const inputDom = document.querySelector('.incentive-name-input input')
    if (inputDom) {
      ;(inputDom as HTMLElement).focus()
    }
  }, [dataSource])

  // 重命名与删除
  const ActionMenu = (props: any) => {
    return (
      <Menu className="custom-row-menu">
        <Menu.Item
          onClick={() => {
            reName(props)
          }}
        >
          重命名
        </Menu.Item>
        <Menu.Item
          onClick={() => {
            copyItem(props)
          }}
        >
          拷贝
        </Menu.Item>
        <Menu.Item
          onClick={() => {
            const { index } = props
            const list = dataSource.slice()
            list.splice(index, 1)
            setDataSource(list)
          }}
        >
          删除
        </Menu.Item>
      </Menu>
    )
  }

  const onCancelModal = () => {
    hookDispatch({
      type: 'updateState',
      payload: {
        visible: false,
        currentItem: {},
        type: 'read',
        index: 0,
      },
    })
  }

  // 重命名确认保存
  const renameKeydown = (e: any, item: any, index: number) => {
    if (e.code === 'Enter' && e.target.value?.trim()) {
      const list = dataSource.slice()
      list.splice(index, 1, {
        ...item,
        rename: false,
        name: e.target.value,
      })
      setDataSource(list)
    }
  }

  const generateCopyName = (name: string) => {
    const list = dataSource.slice() || []
    let copyName = `${name}_拷贝7`
    for (let i = 7; i > 0; i--) {
      if (!list.map((item) => item.name).includes(`${name}_拷贝${i}`)) {
        copyName = `${name}_拷贝${i}`
      }
    }
    return copyName
  }

  // 拷贝
  const copyItem = (props: any) => {
    const list = dataSourceRef.current?.slice() || []

    // 如果当前数量超过6个，不可以拷贝
    if (list.length >= 6) {
      paramsStoreShowError('激励补贴参数最多6个')
      return
    }

    // 关闭没保存的编辑
    list.forEach((item) => {
      item.rename = false
    })

    const detailList = props.item.detailList?.slice() || []
    list.push({
      ...props.item,
      rename: true,
      index: list.length,
      awardSubsidyId: undefined,
      name: generateCopyName(props.item.name),
      detailList: detailList.map?.((item: any) => {
        return {
          ...item,
          parentIndex: list.length,
          awardSubsidyDetailId: undefined,
        }
      }),
    })
    setDataSource(list)
  }

  const init = () => {
    setLoading(true)
    FinanceService.getDetailList()
      .then((res) => {
        setDataSource(
          res?.data?.map((item: any, index: number) => {
            return {
              ...item,
              oldName: item.name,
              rename: false,
              index,
              detailList: item.detailList?.map?.((subItem: any) => {
                return {
                  ...subItem,
                  parentIndex: index,
                }
              }),
            }
          }) || []
        )

        setBackupDataSource(res?.data)
      })
      .catch(() => {})
      .finally(() => {
        setLoading(false)
      })
  }

  useEffect(() => {
    if (location.hash.indexOf('edit') > -1) {
      setReadonly(false)
    }
    init()
  }, [])

  return (
    <div className={`${styles.projectInvestment} ${styles.incentive} `}>
      <h2 style={{ fontWeight: 700, display: 'flex', justifyContent: 'space-between' }}>
        激励补贴参数
        {readonly && (
          <Button
            className="actived"
            onClick={() => {
              setReadonly(false)
            }}
            icon={<CustomIcons type="klf-edit1" style={{ color: 'rgba(28, 184, 135, 1)' }} />}
          >
            编辑
          </Button>
        )}
      </h2>
      <Spin spinning={loading}>
        <Form
          form={form}
          style={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          {dataSource?.map((dataItem: any, index: number) => {
            return (
              <Table
                key={dataItem.id || index}
                dataSource={dataItem.detailList}
                columns={columns}
                pagination={false}
                scroll={{ x: 'max-content', y: 'auto' }}
                title={() =>
                  dataItem.rename ? (
                    <Input
                      className="incentive-name-input"
                      defaultValue={dataItem.name}
                      placeholder="名称"
                      allowClear
                      style={{ width: 226 }}
                      onChange={(e) => {
                        dataItem = {
                          ...dataItem,
                          name: e.target.value?.trim(),
                        }
                      }}
                      onBlur={(e) => {
                        const val = e.target.value
                        if (!val.trim()) {
                          notification.warning({
                            icon: <ExclamationCircleFilled style={{ color: '#f97942' }} />,
                            message: <strong>警告提示</strong>,
                            description: '激励补贴名称不能为空',
                          })
                          dataItem = {
                            ...dataItem,
                            name: dataItem.oldName,
                          }
                        } else {
                          dataItem = {
                            ...dataItem,
                            name: e.target.value?.trim(),
                            rename: false,
                          }
                        }
                        const dataList = dataSource.slice()
                        dataList.splice(index, 1, { ...dataItem })
                        setDataSource(dataList)
                      }}
                      onKeyDown={(e) => {
                        renameKeydown(e, dataItem, index)
                      }}
                    />
                  ) : (
                    <>
                      <div>
                        <span
                          className={dataItem.detailList && readonly && 'incentive-table-name'}
                          onClick={() => {
                            readonly && dataItem.detailList && showAllSubDetail(dataItem)
                          }}
                        >
                          {dataItem.name}
                        </span>
                        {/* {dataItem.isDefault && (
                          <div
                            className="absolute-top"
                            style={{
                              position: 'absolute',
                              top: 0,
                              right: 10,
                              fontSize: 16,
                            }}
                          >
                            <CustomIcons
                              className="absolute-top primary-mark"
                              type="klf-bookmark_added_filled_regular"
                              style={{
                                color: '#1ec692',
                              }}
                            />
                          </div>
                        )} */}
                        {/* {readonly && (
                          <Dropdown
                            overlay={
                              <Menu className="custom-row-menu">
                                <Menu.Item
                                  disabled={dataItem.isDefault}
                                  key={`rename${index}`}
                                  onClick={() => {
                                    FinanceService.default({
                                      awardSubsidyId: dataItem.awardSubsidyId,
                                      root: {
                                        setDefault: 1,
                                      },
                                    })
                                      .then(() => {
                                        init()
                                      })
                                      .catch(() => {})
                                  }}
                                >
                                  设为默认
                                </Menu.Item>
                              </Menu>
                            }
                            destroyPopupOnHide={true}
                          >
                            <Button type="link" className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
                              <EllipsisOutlined color="gray" />
                            </Button>
                          </Dropdown>
                        )} */}
                        {!readonly && (
                          <Dropdown
                            key={`action-${dataItem.id}`}
                            overlay={<ActionMenu id={dataItem.id} index={index} item={dataItem} />}
                          >
                            <Button type="link" className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
                              <EllipsisOutlined color="gray" />
                            </Button>
                          </Dropdown>
                        )}
                      </div>
                    </>
                  )
                }
                footer={
                  !readonly
                    ? () => (
                        <div className="add-row">
                          <PlusOutlined
                            style={{ color: 'rgba(28, 184, 135, 1)' }}
                            onClick={() => {
                              addRow(dataItem, index)
                            }}
                          />
                          <span
                            onClick={() => {
                              addRow(dataItem, index)
                            }}
                          >
                            添加补贴
                          </span>
                        </div>
                      )
                    : undefined
                }
              />
            )
          })}
          {!readonly && (
            <>
              <div className="add-wrap-hor">
                <div className="border"></div>
                <div
                  className="btn-wrap"
                  onClick={() => {
                    addItem()
                  }}
                >
                  <PlusCircleFilled size={16} />
                  <span>添加新激励补贴</span>
                </div>
              </div>
              <div className="footer-btns">
                <Space>
                  <Button type="primary" loading={confirmLoading} onClick={submit}>
                    确定
                  </Button>
                  <Button className="actived" onClick={onCancel}>
                    取消
                  </Button>
                </Space>
              </div>
            </>
          )}
        </Form>
      </Spin>

      {state.visible && (state.type === 'edit' || state.type === 'add') && (
        <IncentiveModal
          {...state}
          // 新建保存
          onSuccess={(data: any) => {
            console.info(state.currentItem.index, state.currentItem)
            const dataSourceList = dataSource.slice()
            let list = []
            if (!state.currentItem.detailList) {
              list.push({ ...data, parentIndex: state.currentItem.index })
            } else {
              list = state.currentItem.detailList.slice()
              list.push({ ...data, parentIndex: state.currentItem.index })
            }
            dataSourceList.splice(state.index, 1, {
              ...state.currentItem,
              detailList: list,
            })
            setDataSource(dataSourceList)
            onCancelModal()
          }}
          // 编辑保存
          onSave={(data: any) => {
            const dataSourceList = dataSource.slice()
            console.info(dataSourceList, state.currentItem.parentIndex, data)
            const list = dataSourceList[state.currentItem.parentIndex].detailList.slice()
            list.splice(state.index, 1, {
              ...data,
              parentIndex: state.currentItem.parentIndex,
            })
            console.info(data, state.currentItem.parentIndex, list, state.index)
            dataSourceList.splice(state.currentItem.parentIndex, 1, {
              ...dataSourceList[state.currentItem.parentIndex],
              detailList: list.slice(),
            })
            setDataSource(dataSourceList)
            onCancelModal()
          }}
          onCancel={onCancelModal}
        />
      )}

      {state.visible && state.type === 'read' && <IncentiveReadonlyModal onCancel={onCancelModal} {...state} />}
      {/* {verifyModalVisible && (
        <CodeModal
          visible={verifyModalVisible}
          saveLoading={saveLoading}
          onSuccess={save}
          onCancel={() => {
            setVerifyModalVisible(false)
          }}
        />
      )} */}
    </div>
  )
}

export default IncentiveSubsidy
