import {Button, Progress, Spin} from 'antd'
import React, {useEffect, useState} from 'react'
import {FinanceService, ProjectServiceHistory, ProjectWorkbenchService} from '../../../apis/project-service-history'
import {getAMap} from '../../account-detail/components/map-script'
import {CustomIcons, getDomData, getFixedNumber} from '../../../global'
import {PvDesign} from '../../workspace/technical/pv-config/pv-design'
// import axios from 'axios'
// import { token } from '../../../utils/utils'
import ReactECharts from 'echarts-for-react'
import moment from 'moment'
import * as echarts from 'echarts'
import {Bear} from '../edge/bear'
import {PvModelSelect} from '../../workspace/technical/pv-config/pv-model-select'
import {PvInverterSelect} from '../../workspace/technical/pv-config/pv-inverter-select'
import {TopologyElement} from './topology-element'

// @ts-ignore
import mapboxgl from '!mapbox-gl'
import {TechResult} from "../../workspace/technical/tech-result";

const currentIdxs = [0, 1, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16]

const idxMap = [
  'addressImage', // 0     项目地址位置图片
  'photovoltaicImage', // 1    光伏电站屋顶情况示意图
  'photovoltaicImage', // 2    全国太阳总辐射图
  'solarRadiationByProvinceImage', // 3    太阳总辐射省平均图片
  'daySolarRadiationImage', // 4    项目典型年逐日总辐射图
  'elePriceImage', // 5    电量电价走势图{边界设置 电价截图}
  'workDayLoadImage', // 6    工作日负荷 图片
  'noWorkDayLoadImage', // 7     非工作日负荷 图片
  'deviceIVImage', // 8    组件IV曲线
  'inverterEfficiencyImage', // 9    逆变器效率曲线图
  'roofDetailedDesignImage', // 10    屋顶详细设计
  'yearAfterYearCapacityImage', // 11    逐年发电量示意图
  'typicalDailyGenerationImage', // 12    典型日发电曲线
  'topologyImage', // 13    系统拓扑示意图
  'typicalDailyOperationCurve', // 14    典型日运行曲线
  'dayAvgDischargeImage', // 15    日均放电
  'dayAvgChargeDischarge', // 16    日均循环次数
]
let interval: any
let itv: any
let closed = true
export const RenderReportDialog = (props: any) => {
  const {projectId, financeId, techId} = props

  useEffect(() => {
    closed = false
    return () => {
      closed = true
    }
  }, [])

  const [, setLoading] = useState(true)

  /**
   * 0: 项目位置地图
   * 1: 屋顶位置地图
   * 2: 全国总辐射地图（预截）
   * 3: xx省太阳总辐射地图（预截）
   * 4: 典型年逐日总辐射图表
   * 5: 电量电价走势图表
   * 6: 负荷数据图表
   * 7: 组件IV曲线图表
   * 8: 逆变器效率曲线图表
   * 9: 光伏阵列布置（地图+表格）
   * 10: 逐年发电量图表
   * 11: 典型日发电曲线
   * 12: 系统拓扑图
   * 13: 典型日运行曲线
   * 14: 日均放电
   * 15: 日均循环次数
   */
  const [dataList, setDataList] = useState<{
    data: string
    finished: boolean
    uploading: boolean
  }[]>([])

  useEffect(() => {
    if (props.uploadAll) {
      if (dataList.filter((o, idx) => currentIdxs.includes(idx)).filter(Boolean).length !== currentIdxs.length) {
        return
      } else {
        setPercent(95)
        percentDld(3000)
        const list = dataList
          .map((o, idx) => {
            if (!o) return undefined
            return {
              imageType: idxMap[idx],
              base64: o.data
            }
          })
          .filter(Boolean)
        FinanceService.uploadFileList({
          financeId,
          root: list
        }).then(() => {
          downloadTemplate(financeId)
        })
      }
      return
    }
    dataList?.forEach((o, idx) => {
      if (!o || o.uploading || o.finished) {
        return
      }
      const data = o.data
      o.uploading = true
      o.finished = false
      FinanceService.uploadFile({
        financeId,
        root: {
          imageType: idxMap[idx],
          base64: data
        }
      }).then(() => {
        o.uploading = false
        o.finished = true
        // setDataList([...dataList])
      })
    })
  }, [dataList])

  const addDataWithIdx = (data: string, idx: number) => {
    dataList[idx] = {
      uploading: false,
      finished: false,
      data
    }
    setDataList([...dataList])
  }

  const [center, setCenter] = useState<any>()
  const [province, setProvince] = useState()

  const getPositionImage = () => {
    percentItv(0, 6000)
    return new Promise((resolve) => {
      ProjectWorkbenchService.workbench1({
        technologyId: techId
      }).then((resp) => {
        setProvince(resp.data.province)
      })
      ProjectServiceHistory.get({
        projectId: projectId
      }).then((resp) => {
        const {longitude, latitude, address} = resp.data
        const mapbox = true
        let center: [number, number] = [parseFloat(longitude), parseFloat(latitude)]
        setCenter(center)
        if (!mapbox) {
          getAMap().then((AMap) => {
            const map = new AMap.Map('project-map', {
              resizeEnable: true,
              zoom: 12,
              center,
              mapStyle: 'amap://styles/05e321edfe9e69b5902e4dc372c36fda',
              WebGLParams: {
                preserveDrawingBuffer: true
              },
              layers: []
            })

            const layer = new AMap.TileLayer()
            layer.on('complete', () => {
              let base64 = ''
              itv =
                !closed &&
                setInterval(() => {
                  if (closed && itv) {
                    clearInterval(itv)
                  }
                  getDomData(document.querySelector('#project-map'), {
                    width: 1000,
                    height: 400
                  }).then((dataUrl: string) => {
                    const dstBase64 = dataUrl.replace('data:image/jpeg;base64,', '')
                    if (base64 !== dstBase64) {
                      base64 = dstBase64
                      return
                    } else {
                      clearInterval(itv)
                      addDataWithIdx(base64, 0)
                      resolve(true)
                    }
                  })
                }, 3000)
            })

            map.addLayer(layer)

            new AMap.Marker({
              map,
              position: center,
              icon: '//a.amap.com/jsapi_demos/static/demo-center/icons/poi-marker-default.png',
              anchor: 'bottom-center'
            })

            new AMap.Text({
              map,
              offset: new AMap.Pixel(0, -60),
              text: address,
              anchor: 'center', // 设置文本标记锚点
              style: {
                padding: '.75rem 1.25rem',
                'border-radius': '.25rem',
                'background-color': 'white',
                'border-width': 1,
                'border-color': 'lightgrey',
                'text-align': 'center',
                'font-size': '13px'
              },
              position: center
            })

            map.setZoomAndCenter(12, center)
          })
        } else {
          const map = new mapboxgl.Map({
            style: 'mapbox://styles/owops/cl2h1u3kx000u15o26t9bmtyh',
            center: center,
            zoom: 12,
            minZoom: 1,
            maxZoom: 20,
            container: 'project-map',
            interactive: false,
            preserveDrawingBuffer: true,
            projection: 'equirectangular' // starting projection
          } as any)


          const popup = new mapboxgl.Popup({
            offset: [0, -40],
            closeButton: false
          })
          new mapboxgl.Marker({offset: [0, -15]})
            .setLngLat(center)
            .addTo(map)
          const txt = address

          popup.setLngLat(center)
            .setText(txt)
            .addTo(map)

          map.on('data', (e) => {
            if (e.isSourceLoaded && e.sourceCacheId === 'symbol:composite' && e.source.url === 'mapbox://mapbox.mapbox-terrain-v2,mapbox.mapbox-streets-v8') {
              // const dataUrl = map.getCanvas().toDataURL('image/jpeg', 1);
              setTimeout(() => {
                getDomData(document.querySelector('#project-map'), {
                  width: 1000,
                  height: 400
                }).then((dataUrl: string) => {
                  const dstBase64 = dataUrl.replace('data:image/jpeg;base64,', '')
                  addDataWithIdx(dstBase64, 0)
                  resolve(true)
                })
              }, 500)
            }
          })
        }
      })
    })
  }

  const [pvInfo, setPvInfo] = useState<any>()

  const [loadedFunc, setLoadedFunc] = useState<any>()

  const getRoofImage = () => {
    percentItv(1, 6000)
    return new Promise((resolve) => {
      ProjectWorkbenchService.getRoofModuleInfo({
        technologyId: techId
      })
        .then((resp) => {
          setPvInfo(resp.data)
          const func = () => {
            setTimeout(() => {
              !closed &&
              getDomData(document.querySelector('#pv-design'), {width: 1000, height: 300}).then((dataUrl: string) => {
                const dstBase64 = dataUrl.replace('data:image/jpeg;base64,', '')
                addDataWithIdx(dstBase64, 1)
                getDomData(document.querySelector('#pv-design'), {width: 1000}).then((dataUrl: string) => {
                  addDataWithIdx(dataUrl.replace('data:image/jpeg;base64,', ''), 10)
                  resolve(true)
                })
              })
            }, 500)
          }

          setLoadedFunc({func})
        })
        .catch((e) => {
          console.log(e)
        })
    })
  }

  const [ghiData, setGhiData] = useState<any>()

  const getGhiPlotImage = () => {
    percentItv(2, 5000)
    return new Promise((resolve) => {
      ProjectServiceHistory.getShow({
        projectId,
        weatherElement: 'GHI'
      }).then((resp) => {
        const res: {
          date: string
          value: number
        }[] = []
        Object.keys(resp.data).forEach((dateTime) => {
          const date = moment(dateTime).format('MM-DD')
          const value = parseFloat(resp.data[dateTime][0])
          let idx = res.findIndex((o) => o.date === date)
          if (idx < 0) {
            idx = res.length
            res[idx] = {
              date,
              value: 0
            }
          }
          res[idx].value += value
        })
        setGhiData(res)

        setTimeout(() => {
          getDomData(document.querySelector('#ghi-plot'), {width: 1000, height: 300}).then((dataUrl: string) => {
            addDataWithIdx(dataUrl.replace('data:image/jpeg;base64,', ''), 4)
            resolve(true)
          })
        }, 2000)
      })
    })
  }

  const [eleChartData, setEleChartData] = React.useState<any>()

  const getElePlotImage = () => {
    percentItv(3, 5000)
    return new Promise((resolve) => {
      ProjectServiceHistory.chart1({
        projectId
      })
        .then((resp) => {
          setEleChartData({
            basicEnergyCharge: resp.data.basicEnergyCharge,
            updateTime: resp.data.updateTime,
            charts: resp.data.charts,
            time: resp.data.time
          })

          setTimeout(() => {
            getDomData(document.querySelector('#ele-plot'), {width: 1000, height: 300}).then((dataUrl: string) => {
              addDataWithIdx(dataUrl.replace('data:image/jpeg;base64,', ''), 5)
              resolve(true)
            })
          }, 2000)
        })
        .catch(() => {
        })
    })
  }

  const getBearPlotImage = () => {
    percentItv(4, 5000)
    return new Promise((resolve) => {
      setTimeout(() => {
        getDomData(document.querySelector('#bear-plot'), {width: 1000, y: 50}).then((dataUrl: string) => {
          addDataWithIdx(dataUrl.replace('data:image/jpeg;base64,', ''), 6)
        })
        getDomData(document.querySelector('#bear-plot'), {width: 1000, y: 50}).then((dataUrl: string) => {
          addDataWithIdx(dataUrl.replace('data:image/jpeg;base64,', ''), 7)
          resolve(true)
        })
      }, 5000)
    })
  }

  const [currentPv, setCurrentPv] = useState<any>()

  const getPvImage = () => {
    percentItv(5, 7000)
    return new Promise((resolve) => {
      ProjectWorkbenchService.workbench1({
        technologyId: techId
      }).then((resp) => {
        setCurrentPv(resp.data)
        itv =
          !closed &&
          setInterval(() => {
            if (closed && itv) {
              clearInterval(itv)
            }
            const pvM = document.querySelector('#pv-model-current')
            const pvI = document.querySelector('#pv-inverter-current')
            if (pvM && pvI) {
              clearInterval(itv)
              setTimeout(() => {
                getDomData(document.querySelector('#pv-model-current'), {width: 1000}).then((dataUrl: string) => {
                  addDataWithIdx(dataUrl.replace('data:image/jpeg;base64,', ''), 8)

                  getDomData(document.querySelector('#pv-inverter-current'), {width: 1000}).then(
                    (dataUrl: string) => {
                      addDataWithIdx(dataUrl.replace('data:image/jpeg;base64,', ''), 9)
                      resolve(true)
                    }
                  )
                })
              }, 5000)
            }
          }, 1000)
      })
    })
  }

  const getLength = () => {
    if (props.uploadAll) {
      return total - dataList.filter(Boolean).length
    }
    return currentIdxs.filter((o) => {
      return !dataList[o] || !dataList[o].finished
    }).length
  }

  const total = currentIdxs.length

  const [generateEle, setGenerateEle] = useState<any>()
  const getGenerateEleImage = () => {
    percentItv(6, 5000)
    return new Promise((resolve) => {
      FinanceService.getActivePowerChart({
        financeId
      }).then((resp) => {
        setGenerateEle(resp.data)

        setTimeout(() => {
          getDomData(document.querySelector('#generate-ele-plot'), {width: 1000}).then((dataUrl: string) => {
            addDataWithIdx(dataUrl.replace('data:image/jpeg;base64,', ''), 12)
            resolve(true)
          })
        }, 5000)
      })
    })
  }

  const [yearlyData, setYearlyData] = useState<any>()
  const getYearlyGenerateEleImage = () => {
    percentItv(7, 5000)
    return new Promise((resolve) => {
      FinanceService.getYearYearPower({
        financeId
      }).then((resp) => {
        const years: any[] = []
        const gridEnergys: any[] = []
        const flatConsumes: any[] = []
        const peakConsumes: any[] = []
        const valleyConsumes: any[] = []
        resp.data.forEach((o: any) => {
          const {year, gridEnergy, flatConsume, peakConsume, valleyConsume} = o
          years.push(year)
          gridEnergys.push(gridEnergy)
          flatConsumes.push(flatConsume)
          peakConsumes.push(peakConsume)
          valleyConsumes.push(valleyConsume)
        })

        setYearlyData({
          year: years,
          gridEnergy: gridEnergys,
          flatConsume: flatConsumes,
          peakConsume: peakConsumes,
          valleyConsume: valleyConsumes
        })

        setTimeout(() => {
          getDomData(document.querySelector('#generate-ele-year-plot'), {width: 1000}).then((dataUrl: string) => {
            addDataWithIdx(dataUrl.replace('data:image/jpeg;base64,', ''), 11)
            resolve(true)
          })
        }, 3000)
      })
    })
  }

  const [proposalData, setProposalData] = useState<any>()
  const [svgLoaded, setSvgLoaded] = useState(false)

  const getElementByKey = (key: string) => {
    return document.getElementById(key)!
  }

  const changeElementVisible = (key: string, visible: boolean) => {
    getElementByKey(key).style.opacity = visible ? '1' : '0'
  }

  const getProposalImage = () => {
    percentItv(8, 5000)
    ProjectWorkbenchService.workbench1({
      technologyId: techId
    }).then((resp) => {
      setProposalData(resp.data)
    })
  }

  useEffect(() => {
    if (proposalData && svgLoaded) {
      const {
        photovoltaicToEnergyStorage,
        photovoltaicToLoad,
        photovoltaicToPowerGrid,
        powerGridToEnergyStorage,
        energyStorageToLoad,
        photovoltaicParallelNetwork
      } = proposalData.topologyInfo

      try {
        changeElementVisible('pv_battery', photovoltaicToEnergyStorage)
        changeElementVisible('pv_bear', photovoltaicToLoad)
        changeElementVisible('gw_pv', photovoltaicToPowerGrid && photovoltaicParallelNetwork === 1)
        changeElementVisible('gw_pv_low', photovoltaicToPowerGrid && photovoltaicParallelNetwork === 2)
        changeElementVisible('gw_battery', powerGridToEnergyStorage)
        changeElementVisible('battery_bear', energyStorageToLoad)
      } catch (e) {
        //console.log(e)
      }

      setTimeout(() => {
        getDomData(document.querySelector('#proposal-top'), {width: 1000}).then((dataUrl: string) => {
          addDataWithIdx(dataUrl.replace('data:image/jpeg;base64,', ''), 13)

          getDomData(document.querySelector('#proposal-result'), {width: 1000}).then((dataUrl: string) => {
            addDataWithIdx(dataUrl.replace('data:image/jpeg;base64,', ''), 14)

            if (proposalData?.energyStorageInfo) {
              getDomData(document.querySelector('#daily-ele'), {width: 1000}).then((dataUrl: string) => {
                addDataWithIdx(dataUrl.replace('data:image/jpeg;base64,', ''), 15)

                getDomData(document.querySelector('#daily-cnt'), {width: 1000}).then((dataUrl: string) => {
                  addDataWithIdx(dataUrl.replace('data:image/jpeg;base64,', ''), 16)

                })
              })
            } else {
              addDataWithIdx('null', 15);
              addDataWithIdx('null', 16);
            }
          })
        })
      }, 3000)

    }
  }, [proposalData, svgLoaded])

  const [, setItv] = useState<any>()


  const percentItv = (base: number, dur: number) => {
    const threadhold = (1 / 9) * 95

    setItv((itv: any) => {
      if (itv) {
        clearInterval(itv)
      }

      const dt = Date.now()
      const ditv =
        !closed &&
        setInterval(() => {
          if (closed && ditv) {
            clearInterval(ditv)
          }
          const from = threadhold * base
          setPercent(() => {
            const th = Date.now() - dt
            return parseFloat((from + Math.min((th / dur) * threadhold, threadhold - 0.01)).toFixed(2))
          })
        }, 300)

      return ditv
    })
  }

  const percentDld = (dur: number) => {
    setItv((itv: any) => {
      if (itv) {
        clearInterval(itv)
      }

      const dt = Date.now()
      const ditv =
        !closed &&
        setInterval(() => {
          if (closed && ditv) {
            clearInterval(ditv)
          }
          const from = 95
          setPercent(() => {
            const th = Date.now() - dt
            return parseFloat((from + Math.min((th / dur) * 5, 4.99)).toFixed(2))
          })
        }, 300)

      return ditv
    })
  }

  useEffect(() => {
    setLoading(true)
    const block = true;
    if (block) {
      !closed &&
      getPositionImage().then(() => {
        console.log('1 finished')
        !closed &&
        getRoofImage().then(() => {
          console.log('2 finished')
          !closed &&
          getGhiPlotImage().then(() => {
            console.log('3 finished')
            !closed &&
            getElePlotImage().then(() => {
              console.log('4 finished')
              !closed &&
              getBearPlotImage().then(() => {
                console.log('5 finished')
                !closed &&
                getPvImage().then(() => {
                  console.log('6 finished')
                  !closed &&
                  getGenerateEleImage().then(() => {
                    console.log('7 finished')
                    !closed &&
                    getYearlyGenerateEleImage().then(() => {
                      console.log('8 finished')
                      !closed && getProposalImage()
                    })
                  })
                })
              })
            })
          })
        })
      })
    } else {
      downloadTemplate(financeId)
    }

    if (!props.uploadAll && !interval) {
      interval = setInterval(() => {
        setDataList((dataList) => {
          const length = currentIdxs.filter((o) => {
            return !dataList[o] || !dataList[o].finished
          }).length

          if (length === 0) {
            downloadTemplate(financeId)
            clearInterval(interval)
            interval = undefined
          }
          return dataList
        })
      }, 10000)
    }
    return () => {
      clearInterval(interval)
      interval = undefined
    }
  }, [])

  const [, setDownloading] = useState(false)
  const [uploaded, setUploaded] = useState(false)
  const [percent, setPercent] = useState(0)
  const downloadTemplate = (financeId: string) => {
    setDownloading(true)
    // const prefix = axios.defaults.baseURL
    // const url = `${prefix || ''}/api/i3060/word/fillWord/${financeId}`
    // const authToken: string = token.get() || ''
    FinanceService.fillWord({
      financeId
    })
      .then(() => {
        setUploaded(true)
        setItv((itv: any) => {
          if (itv) {
            clearInterval(itv)
            setPercent(100)
          }
          return undefined
        })
      })
      .finally(() => {
        setDownloading(false)
      })
  }

  return (
    <div
      id='bg-white'
      style={{
        height: 265,
        overflow: 'hidden'
      }}
    >
      <Spin
        spinning={false}
        style={{
          width: '100%',
          height: '100%'
        }}
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          <Progress
            className={uploaded ? 'fill-color-green' : 'progress-percent'}
            type='circle'
            strokeColor={{
              '0%': '#1ec692',
              '100%': '#1ec692'
            }}
            format={(percent) => {
              if (percent && percent >= 100) {
                return <CustomIcons type='klf-zhengque-correct'/>
              }
              return percent
            }}
            percent={percent}
          />
        </div>
        <div
          style={{
            fontSize: 16,
            color: 'black',
            textAlign: 'center',
            paddingTop: 20
          }}
        >
          <div>
            {!uploaded && <div>报告生成中...</div>}
            {uploaded && <div>图片资源生成成功</div>}
            <div
              style={{
                marginTop: 5,
                fontSize: 12,
                color: 'lightgrey'
              }}
            >
              {!uploaded && getLength() !== 0 && (
                <span>
                  {total - getLength()}/{total}的资源信息已生成
                </span>
              )}
              {!uploaded && getLength() === 0 && <span>图片资源上传中...</span>}
              {uploaded && <span>可返回至项目报告列表等待报告生成。</span>}
            </div>
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-around',
            padding: '0 50px',
            marginTop: 40
          }}
        >
          <Button
            type='primary'
            ghost
            onClick={() => {
              if (itv) {
                clearInterval(itv)
              }
              setItv((itv: any) => {
                if (itv) {
                  clearInterval(itv)
                }
                return undefined
              })
              props.callback?.(uploaded)
            }}
          >
            {uploaded ? '返回列表' : '取消'}
          </Button>
        </div>
      </Spin>
      <div
        style={{
          marginTop: 10,
          marginBottom: 100,
          height: 60,
          textAlign: 'center'
        }}
      />
      <div
        id='project-map'
        style={{
          width: 1000,
          height: 400,
          left: 0,
          top: 0
        }}
      />
      <div
        id='pv-design'
        style={{
          width: 1000
        }}
      >
        {center && pvInfo && (
          <PvDesign
            viewMode={true}
            info={pvInfo}
            technologyId={techId}
            geo={center}
            hideActions={true}
            roofInfo={pvInfo.geometryList}
            province={province}
            roofLoaded={loadedFunc?.func}
          />
        )}
      </div>

      <div
        style={{
          width: 1000
        }}
        id='ghi-plot'
      >
        {ghiData && (
          <ReactECharts
            style={{width: '1000px', height: '300px'}}
            option={{
              animationDuration: 50,
              grid: {left: '0px', right: '10px', top: '30px', bottom: '30px', containLabel: true},
              yAxis: [
                {
                  type: 'value',
                  name: 'kW·h/㎡'
                },
                {
                  type: 'value',
                  name: `{a|--} 平均值: ${getFixedNumber(
                    (Object.values(ghiData.map((o: any) => o.value)) as number[]).reduce(
                      (a: number, b: number) => a + b
                    ) / Object.values(ghiData.map((o: any) => o.value)).length,
                    2
                  )}kW·h/㎡`,
                  nameTextStyle: {
                    color: 'gray',
                    align: 'right',
                    rich: {
                      a: {
                        color: '#1ec692'
                      }
                    }
                  }
                }
              ],
              xAxis: {
                type: 'category',
                data: ghiData.map((o: any) => o.date)
              },
              series: [
                {
                  name: '辐射',
                  type: 'line',
                  showSymbol: false,
                  data: ghiData.map((o: any) => o.value),
                  label: {
                    show: false
                  },
                  large: true,
                  sampling: 'lttb',
                  lineStyle: {
                    width: 1
                  },
                  itemStyle: {
                    color: 'rgb(30, 198, 146)'
                  },
                  areaStyle: {
                    color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                      {
                        offset: 0,
                        color: 'rgb(30, 198, 146, 0.1)'
                      },
                      {
                        offset: 1,
                        color: 'rgb(30, 198, 146, 0)'
                      }
                    ])
                  },
                  markLine: {
                    silent: true,
                    symbol: 'none',
                    data: [
                      {
                        yAxis:
                          (Object.values(ghiData.map((o: any) => o.value)) as number[]).reduce(
                            (a: number, b: number) => a + b
                          ) / Object.values(ghiData.map((o: any) => o.value)).length
                      }
                    ],
                    lineStyle: {
                      normal: {
                        type: 'dashed',
                        color: '#1ec692'
                      }
                    },
                    label: {
                      show: false
                    }
                  }
                }
              ]
            }}
          />
        )}
      </div>

      <div
        style={{
          width: 1000
        }}
        id='ele-plot'
      >
        {eleChartData && (
          <ReactECharts
            style={{
              width: 1000,
              height: 310
            }}
            option={{
              animationDuration: 50,
              grid: {
                left: 30,
                right: '3%',
                top: 40,
                bottom: 30,
                containLabel: true
              },
              xAxis: {
                type: 'category',
                axisLabel: {
                  interval: 7
                },
                boundaryGap: false,
                data: Object.values(eleChartData.time)[0]
              },
              yAxis: {
                type: 'value',
                name: '单位：元/kWh',
                nameTextStyle: {
                  color: 'gray'
                }
              },
              legend: {
                show: true,
                top: 'bottom',
                icon: 'rect'
              },
              series: Object.keys(eleChartData.charts).map((key) => {
                return {
                  name: key,
                  type: 'line',
                  sampling: 'lttb',
                  showSymbol: false,
                  lineStyle: {
                    width: 1
                  },
                  itemStyle: {
                    color: key.includes('非') ? 'rgb(30, 198, 146)' : 'rgb(24, 144, 255)'
                  },
                  areaStyle: {
                    color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                      {
                        offset: 0,
                        color: key.includes('非') ? 'rgba(30, 198, 146, 0.1)' : 'rgba(24, 144, 255, 0.1)'
                      },
                      {
                        offset: 1,
                        color: key.includes('非') ? 'rgba(30, 198, 146, 0)' : 'rgba(24, 144, 255, 0)'
                      }
                    ])
                  },
                  data: eleChartData.charts[key]
                }
              })
            }}
          />
        )}
      </div>
      <div
        style={{
          width: 1000
        }}
        id='bear-plot'
      >
        {projectId && <Bear projectId={projectId}/>}
      </div>

      <div
        style={{
          width: 1000
        }}
        id='pv-plot'
      >
        {currentPv && <PvModelSelect viewMode current={currentPv} technologyId={techId}/>}
        {currentPv && <PvInverterSelect viewMode current={currentPv} technologyId={techId}/>}
      </div>

      <div
        style={{
          width: 1000
        }}
        id='generate-ele-plot'
      >
        {generateEle && (
          <ReactECharts
            style={{
              width: 1000,
              height: 310
            }}
            option={{
              animationDuration: 50,
              grid: {
                left: 30,
                right: '3%',
                top: 40,
                bottom: 30,
                containLabel: true
              },
              xAxis: {
                type: 'category',
                axisLabel: {
                  interval: generateEle.time?.length > 50 ? 7 : 3
                },
                boundaryGap: false,
                data: generateEle.time
              },
              yAxis: {
                type: 'value',
                nameTextStyle: {
                  color: 'gray'
                }
              },
              legend: {
                show: true,
                top: 'bottom',
                icon: 'rect'
              },
              series: ['pv2grid', 'pv2demandel'].map((key) => {
                return {
                  name: key === 'pv2grid' ? '光伏到电网' : '光伏到负荷',
                  type: 'line',
                  sampling: 'lttb',
                  stack: 'Total',
                  showSymbol: false,
                  lineStyle: {
                    width: 1
                  },
                  itemStyle: {
                    color: key.includes('grid') ? 'rgb(30, 198, 146)' : 'rgb(24, 144, 255)'
                  },
                  areaStyle: {
                    color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                      {
                        offset: 0,
                        color: key.includes('grid') ? 'rgba(30, 198, 146, 0.1)' : 'rgba(24, 144, 255, 0.1)'
                      },
                      {
                        offset: 1,
                        color: key.includes('grid') ? 'rgba(30, 198, 146, 0)' : 'rgba(24, 144, 255, 0)'
                      }
                    ])
                  },
                  data: generateEle[key]
                }
              })
            }}
          />
        )}
      </div>

      <div
        style={{
          width: 1000
        }}
        id='generate-ele-year-plot'
      >
        {yearlyData && (
          <ReactECharts
            style={{
              width: 1000,
              height: 310
            }}
            option={{
              animationDuration: 50,
              grid: {
                left: 30,
                right: '3%',
                top: 40,
                bottom: 30,
                containLabel: true
              },
              xAxis: {
                type: 'category',
                axisLabel: {
                  interval: 0
                },
                boundaryGap: false,
                data: yearlyData.year
              },
              yAxis: {
                type: 'value',
                nameTextStyle: {
                  color: 'gray'
                }
              },
              legend: {
                show: true,
                top: 'bottom',
                icon: 'rect'
              },
              series: ['gridEnergy', 'flatConsume', 'peakConsume', 'valleyConsume'].map((key) => {
                return {
                  name:
                    key === 'gridEnergy'
                      ? '上网电量'
                      : key === 'flatConsume'
                        ? '平消纳'
                        : key === 'peakConsume'
                          ? '峰消纳'
                          : '谷消纳',
                  type: 'line',
                  sampling: 'lttb',
                  stack: 'Total',
                  showSymbol: false,
                  lineStyle: {
                    width: 1
                  },
                  itemStyle: {
                    color: key.includes('gridEnergy')
                      ? 'rgb(50, 116, 246)'
                      : key.includes('flatConsume')
                        ? 'rgb(181, 50, 246)'
                        : key.includes('peakConsume')
                          ? 'rgb(28, 184, 135)'
                          : 'rgb(249, 121, 66)'
                  },
                  areaStyle: {
                    color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                      {
                        offset: 0,
                        color: key.includes('gridEnergy')
                          ? 'rgb(50, 116, 246, 0.1)'
                          : key.includes('flatConsume')
                            ? 'rgb(181, 50, 246, 0.1)'
                            : key.includes('peakConsume')
                              ? 'rgb(28, 184, 135, 0.1)'
                              : 'rgb(249, 121, 66,0.1)'
                      },
                      {
                        offset: 1,
                        color: key.includes('gridEnergy')
                          ? 'rgb(50, 116, 246, 0)'
                          : key.includes('flatConsume')
                            ? 'rgb(181, 50, 246, 0)'
                            : key.includes('peakConsume')
                              ? 'rgb(28, 184, 135, 0)'
                              : 'rgb(249, 121, 66, 0)'
                      }
                    ])
                  },
                  data: yearlyData[key]
                }
              })
            }}
          />
        )}
      </div>

      <div
        style={{
          width: 1000
        }}
        id='proposal-top'
      >
        {proposalData && (
          <TopologyElement
            onLoad={() => {
              changeElementVisible('pv', !!proposalData.photovoltaicInfo)
              changeElementVisible('battery', !!proposalData.energyStorageInfo)
              setSvgLoaded(true)
            }}
          />
        )}
      </div>

      <div
        style={{
          width: 1000
        }}
        id='proposal-result'
      >
        {
          proposalData && <TechResult
            isCapture
            proposalData={proposalData}
            onChangeSeason={() => {
            }}
          />
        }
      </div>

      <div style={{
        width: 1000,
      }}
           id="daily-ele"
      >
        <div className="result-sub-title">日放电量</div>
        {
          proposalData?.energyStorageInfo && <div className="result-charts">
            <ReactECharts
              style={{
                width: '100%',
                height: '180px',
              }}
              option={{
                animationDuration: 50,
                grid: {left: '50px', right: '10px', top: '40px', bottom: '25px'},
                tooltip: {
                  show: true,
                  trigger: 'axis',
                  formatter: (argsList: any) => {
                    const args: any = argsList[0]
                    return `${args.name}<br />${args.marker}${parseFloat(args.value.toFixed(2))}kWh`
                  },
                },
                xAxis: {
                  type: 'category',
                  boundaryGap: true,
                  data: Object.keys(proposalData.energyStorageInfo.dayDischargeChart),
                  // show: false,
                },
                yAxis: [
                  {
                    type: 'value',
                    name: 'kWh',
                    splitLine: {
                      lineStyle: {
                        type: 'dashed',
                      },
                    },
                    boundaryGap: false,
                    nameTextStyle: {
                      color: 'gray',
                      padding: [0, 40, 0, 0],
                    },
                  },
                  {
                    type: 'value',
                    name: `{a|--} 平均值: ${getFixedNumber(
                      (Object.values(proposalData.energyStorageInfo.dayDischargeChart) as number[]).reduce(
                        (a: number, b: number) => a + b
                      ) / Object.values(proposalData.energyStorageInfo.dayDischargeChart).length,
                      2
                    )}kWh`,
                    nameTextStyle: {
                      color: 'gray',
                      align: 'right',
                      rich: {
                        a: {
                          color: '#1ec692',
                        },
                      },
                    },
                    axisLine: {
                      // show: false,
                    },
                    axisLabel: {
                      // show: false,
                    },
                    axisTick: {
                      // show: false,
                    },
                  },
                ],
                series: [
                  {
                    name: '',
                    type: (proposalData.loadType === 1 && Object.values(proposalData.demandLimit).length < 20) ? 'bar' : 'line',
                    data: Object.values(proposalData.energyStorageInfo.dayDischargeChart),
                    barWidth: 18,
                    itemStyle: {
                      color: '#1EC692',
                      barBorderRadius: [4, 4, 0, 0],
                    },
                    markLine: {
                      silent: true,
                      symbol: 'none',
                      data: [
                        {
                          yAxis:
                            (
                              Object.values(proposalData.energyStorageInfo.dayDischargeChart) as number[]
                            ).reduce((a: number, b: number) => a + b) /
                            Object.values(proposalData.energyStorageInfo.dayDischargeChart).length,
                        },
                      ],
                      lineStyle: {
                        normal: {
                          type: 'dashed',
                          color: '#1EC692',
                        },
                      },
                      label: {
                        show: false,
                      },
                    },
                  },
                ],
              }}
            />
          </div>
        }
      </div>
      <div style={{
        width: 1000,
      }}
           id="daily-cnt"
      >
        <div className="result-sub-title">日循环次数</div>
        {
          proposalData?.energyStorageInfo && <div className="result-charts">
            <ReactECharts
              style={{
                width: '100%',
                height: '180px',
              }}
              option={{
                animationDuration: 50,
                grid: {left: '50px', right: '10px', top: '40px', bottom: '25px'},
                tooltip: {
                  show: true,
                  trigger: 'axis',
                  formatter: (argsList: any) => {
                    const args: any = argsList[0]
                    return `${args.name}<br />${args.marker}${parseFloat(args.value.toFixed(2))}次`
                  },
                },
                xAxis: {
                  type: 'category',
                  boundaryGap: true,
                  data: Object.keys(proposalData.energyStorageInfo.dayChargeDischargeChart),
                  // show: false,
                },
                yAxis: [
                  {
                    type: 'value',
                    name: '次',
                    splitLine: {
                      lineStyle: {
                        type: 'dashed',
                      },
                    },
                    boundaryGap: false,
                    nameTextStyle: {
                      color: 'gray',
                      padding: [0, 40, 0, 0],
                    },
                  },
                  {
                    type: 'value',
                    name: `{a|--} 平均值: ${getFixedNumber(
                      (
                        Object.values(proposalData.energyStorageInfo.dayChargeDischargeChart) as number[]
                      ).reduce((a: number, b: number) => a + b) /
                      Object.values(proposalData.energyStorageInfo.dayChargeDischargeChart).length,
                      2
                    )}次`,
                    nameTextStyle: {
                      color: 'gray',
                      align: 'right',
                      rich: {
                        a: {
                          color: '#3274F6',
                        },
                      },
                    },
                    axisLine: {
                      // show: false,
                    },
                    axisLabel: {
                      // show: false,
                    },
                    axisTick: {
                      // show: false,
                    },
                  },
                ],
                series: [
                  {
                    name: '',
                    type: (proposalData.loadType === 1 && Object.values(proposalData.demandLimit).length < 20) ? 'bar' : 'line',
                    data: Object.values(proposalData.energyStorageInfo.dayChargeDischargeChart),
                    barWidth: 18,
                    itemStyle: {
                      color: '#3274F6',
                      barBorderRadius: [4, 4, 0, 0],
                    },
                    markLine: {
                      silent: true,
                      symbol: 'none',
                      data: [
                        {
                          yAxis:
                            (
                              Object.values(
                                proposalData.energyStorageInfo.dayChargeDischargeChart
                              ) as number[]
                            ).reduce((a: number, b: number) => a + b) /
                            Object.values(proposalData.energyStorageInfo.dayChargeDischargeChart).length,
                        },
                      ],
                      lineStyle: {
                        normal: {
                          type: 'dashed',
                          color: '#3274F6',
                        },
                      },
                      label: {
                        show: false,
                      },
                    },
                  },
                ],
              }}
            />
          </div>
        }
      </div>
    </div>
  )
}
