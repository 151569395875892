import RecentActions from './components/recent-actions'
import ActivityLogs from './components/activity-logs'
import StatisticsCard from './components/statistics-card'
import ProjectMap from './components/project-map'
import styles from './index.module.scss'
import { BreadcrumbObservable, SideMenuObservable } from '../../application'
import React from 'react'

const AccountDetail = () => {
  React.useEffect(() => {
    SideMenuObservable.next(false)
    BreadcrumbObservable.next([])
  }, [])

  return (
    <div className={`${styles.content} ro-content`}>
      <div className='custom-scroll-bar'>
        <div className={styles.leftDiv}>
          <StatisticsCard />
          <ProjectMap />
        </div>
        <div className={styles.rightDiv}>
          <div
            style={{
              maxHeight: 'calc(50% - 10px)',
              height: 'calc(50% - 10px)',
              minHeight: 'calc(50% - 10px)',
              overflowY: 'hidden',
              overflowX: 'auto',
            }}
          >
            <RecentActions />
          </div>
          <div
            style={{
              marginTop: 20,
              height: 'calc(50% - 10px)',
              minHeight: 'calc(50% - 10px)',
              overflowY: 'hidden',
              overflowX: 'auto',
            }}
          >
            <ActivityLogs />
          </div>
        </div>
      </div>
    </div>
  )
}

export default AccountDetail
