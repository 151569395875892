import React, { useContext, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Button, Menu, Space, Table, Tag, Popover, message, Modal, Input, Select } from 'antd'
import { PlusOutlined, SearchOutlined, SettingOutlined } from '@ant-design/icons'
import './index.less'
import AddMembersModal from '../../components/AddMembersModal'
import Global, { CustomIcons } from '../../../../../global'
import ProjectAccountControllerApi from '../../../../../services/api/ProjectAccountControllerApi'
import { CurrAuthContext } from '../../config'
import share from '../../../../../assets/share.png'
import { PermissionTemplateControllerService } from '../../../../../apis/project-service-stash'
import { AccountControllerService } from '../../../../../apis/project-service-history'
import { copy } from '../../../../../utils/utils'
const { Option } = Select

enum RoleName {
  '负责人' = 1,
  '管理员' = 2,
  '成员' = 3,
}

enum SourceName {
  '内部' = 1,
  '外部' = 2,
}

enum OperationName {
  '查看' = 0,
  '编辑' = 1,
}

type MemberItem = {
  id: number
  userId?: number // 账号id
  userName?: string // 账号名称
  roleType?: number //角色类型：1.项目负责人2.项目管理员3.普通成员
  source?: number // 来源1.内部2.外部
  telephone?: string // 手机号
  email?: string // 邮箱
  showSetPermissionButton?: boolean // 展示编辑权限按钮true.展示编辑false.展示查看
  showSetPersonChargeButton?: boolean // 展示设置为负责人按钮
  showSetAdministratorsButton?: boolean // 展示设置项目管理员按钮
  showMoveAccountButton?: boolean // 展示移除成员按钮
}
const routerLocation = location

const ProjectList = React.memo((props: { info: any }) => {
  const { roleType, isAdministrators, source }: any = useContext(CurrAuthContext)
  console.log('CurrAuthContext', roleType, isAdministrators, source)
  const { id }: any = useParams()
  const [addVisible, setAddVisible] = useState<boolean>(false)
  const [dataSource, setDataSource] = useState<MemberItem[]>([])
  const [keyword, setKeyword] = useState<string>('')
  const [templateList, setTemplateList] = useState<any[]>([])
  const [inviteTemplate, setInviteTemplate] = useState<any>('')
  // const [total] = useState<number>(500)

  // 用户基础信息
  const [user, setUser] = useState(Global.user)
  Global.updateUser.push((e: any) => {
    setUser(e)
  })

  const navigate = useNavigate()

  const { info } = props

  const handleAdd = () => {
    setAddVisible(true)
  }
  const handleAaddCancel = () => {
    setAddVisible(false)
  }
  const handleTemplateChange = (value: string) => {
    setInviteTemplate(value)
  }

  const fetchPermissionTemplateList = () => {
    PermissionTemplateControllerService.list1().then((res: any) => {
      const list = res?.data || []
      setTemplateList(list)
      const defaultItem = list.find((item) => item?.defaultChecked)
      if (defaultItem) {
        setInviteTemplate(Number(defaultItem.templateId))
        // setDefaultTemplateId(Number(defaultItem.templateId))
      }
    })
  }

  const handleAddOk = (data: any) => {
    if (data?.length) {
      const userList = data.map((res) => ({
        userId: res.userId,
        projectId: id,
        userName: res.userName,
        templateId: res.permissionId,
        // ...res,
      }))
      ProjectAccountControllerApi.i3060ProjectAccountAddPost(userList).then((res: any) => {
        if (res.data) {
          message.success('操作成功!')
          setAddVisible(false)
          getList(keyword)
        }
      })
    } else message.error('请选择成员！')
  }

  const delItem = (row: any) => {
    Modal.confirm({
      keyboard: false,
      title: '移除成员',
      icon: <CustomIcons type="klf-alarm1" />,
      content: (
        <div>
          <p>确定移除成员{row.userName}？移除后，该成员的数据将转移至项目负责人</p>
        </div>
      ),
      width: 500,
      okType: 'primary',
      okText: '确定',
      okButtonProps: {
        danger: true,
      },
      onOk: () => {
        ProjectAccountControllerApi.i3060ProjectAccountRemovePost({
          projectId: id,
          userId: row.userId,
        }).then((res: any) => {
          if (res.data) {
            message.success('操作成功!')
            getList(keyword)
          }
        })
      },
      cancelText: '取消',
      cancelButtonProps: {
        danger: true,
      },
    })
  }

  // roleType: 1.项目负责人2.项目管理员3.普通成员
  // type  1负责人 2管理员
  const roleSet = (row: any, type: any, text?: any) => {
    console.log('🚀 ~ file: index.tsx ~ line 116 ~ roleSet ~ text', text)
    let setRoleType = null
    const { roleType } = row
    if (type == 2) {
      setRoleType = roleType == 2 ? 3 : 2
    } else {
      setRoleType = roleType == 1 ? 3 : 1
    }
    // Modal.confirm({
    //   keyboard: false,
    //   title: text + RoleName[type],
    //   icon: <CustomIcons type="klf-alarm1" />,
    //   content: (
    //     <div>
    //       <p>{`确定${text}"${row.userName}"${RoleName[type]}？`}</p>
    //     </div>
    //   ),
    //   width: 500,
    //   okType: 'primary',
    //   okText: '确定',
    //   okButtonProps: {
    //     danger: true,
    //   },
    //   onOk: () => {
    ProjectAccountControllerApi.i3060ProjectAccountRoleSetPost({
      projectId: id,
      userId: row.userId,
      roleType: setRoleType,
    }).then((res: any) => {
      if (res.data) {
        message.success('操作成功!')
        getList(keyword)
      }
    })
    //   },
    //   cancelText: '取消',
    //   cancelButtonProps: {
    //     danger: true,
    //   },
    // })
  }

  const columns = [
    {
      title: '成员名称',
      dataIndex: 'userName',
      key: 'userName',
      render: (text: string, record: any) => {
        // roleType: 1.项目负责人2.项目管理员3.普通成员
        const { roleType, source } = record || {}
        return (
          <Space size={0}>
            <span style={{ marginRight: 12 }}>{text}</span>
            <Tag color="green">{RoleName[roleType]}</Tag>
            <Tag color="pink">{SourceName[source]}</Tag>
          </Space>
        )
      },
    },
    {
      title: '手机号',
      dataIndex: 'telephone',
      key: 'telephone',
    },
    {
      title: '邮箱',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: '操作',
      key: 'action',
      width: 64,
      render: (record: any) => {
        // showSetPermissionButton:展示编辑权限按钮
        // showSetPersonChargeButton:展示设置为负责人按钮
        // showSetAdministratorsButton:展示设置项目管理员按钮
        // showMoveAccountButton:展示移除成员按钮
        const {
          userId,
          showPermissionButton,
          showSetPermissionButton,
          showSetPersonChargeButton,
          showSetAdministratorsButton,
          showMoveAccountButton,
        } = record || {}
        return (
          <div className="project-member-action">
            <Space size={12}>
              {showPermissionButton && (
                <div
                  className="project-member-action-set"
                  onClick={() => {
                    navigate(`template/${userId}/0`, {
                      replace: false,
                      state: { ...record },
                    })
                  }}
                >
                  {OperationName[0]}权限
                </div>
              )}
              {showSetPermissionButton && (
                <div
                  className="project-member-action-set"
                  onClick={() => {
                    navigate(`template/${userId}/1`, {
                      replace: false,
                      state: { ...record },
                    })
                  }}
                >
                  {OperationName[1]}权限
                </div>
              )}
              {showSetAdministratorsButton || showSetPersonChargeButton || showMoveAccountButton ? (
                <Popover
                  placement="leftTop"
                  overlayClassName="menu-popover-overlay"
                  key={`action-${record.id}`}
                  content={
                    <Menu mode="inline" selectable={false}>
                      {showSetAdministratorsButton ? (
                        <Menu.Item
                          style={{
                            padding: 0,
                          }}
                          onClick={() => roleSet(record, 2, record?.roleType == 2 ? '取消' : '设为')}
                        >
                          {record?.roleType == 2 ? '取消' : '设为'}管理员
                        </Menu.Item>
                      ) : null}

                      {showSetPersonChargeButton ? (
                        <Menu.Item
                          style={{
                            padding: 0,
                          }}
                          onClick={() => roleSet(record, 1, record?.roleType == 1 ? '取消' : '设为')}
                        >
                          {record?.roleType == 1 ? '取消' : '设为'}负责人
                        </Menu.Item>
                      ) : null}
                      {showMoveAccountButton && (
                        <Menu.Item
                          style={{
                            padding: 0,
                          }}
                          onClick={() => delItem(record)}
                        >
                          移除
                        </Menu.Item>
                      )}
                    </Menu>
                  }
                >
                  <Button
                    type="link"
                    className="ant-dropdsetSelectKeys(['user-params'])own-link"
                    onClick={(e) => e.preventDefault()}
                    icon={<CustomIcons type="klf-more" />}
                  />
                </Popover>
              ) : null}
            </Space>
          </div>
        )
      },
    },
  ]

  const getList = (keyword?: any) => {
    ProjectAccountControllerApi.i3060ProjectAccountListProjectIdGet({
      projectId: id,
      keyword,
    }).then((res: any) => {
      const result = res?.data || []
      setDataSource(result)
    })
  }

  useEffect(() => {
    getList()
    fetchPermissionTemplateList()
  }, [])

  return (
    <div className="project-member">
      <div className="project-member-header">
        <div className="project-member-header-left">
          <Space size={10}>
            <div className="project-member-header-item">
              <span className="project-member-header-active">{dataSource?.length}</span>
              &nbsp;
              <span>名项目成员</span>
            </div>
            <div
              className="project-member-header-item project-member-header-hover"
              onClick={() => window.open(routerLocation.href.split('#')[0] + '#/user-center/rights-template')}
            >
              <SettingOutlined className="project-member-header-icon" />
              <span className="project-member-header-active">项目权限模板设置</span>
            </div>
          </Space>
        </div>
        <div className="project-member-header-right">
          <Space size={10}>
            <div className="project-member-header-item">
              <Input
                placeholder="搜索已有成员名称"
                value={keyword}
                onChange={(e: any) => setKeyword(e?.target?.value)}
                suffix={<SearchOutlined onClick={() => getList(keyword)} style={{ color: 'rgba(0,0,0,.45)' }} />}
              />
            </div>
            <Space className="project-member-header-item">
              {(roleType && roleType != 3) || isAdministrators ? (
                <Popover
                  placement="bottom"
                  trigger="click"
                  overlayClassName="menu-popover-overlay"
                  content={
                    <div
                      className="add-members-link"
                      style={{
                        padding: '16px 24px',
                      }}
                    >
                      <h3 className="add-members-link-title">链接分享</h3>
                      <div className="add-members-link-create">
                        <Select
                          showSearch
                          size="large"
                          bordered={false}
                          value={inviteTemplate?.toString()}
                          placeholder="请选择权限模板"
                          onChange={handleTemplateChange}
                        >
                          {templateList?.map(({ templateId, templateName }) => (
                            <Option key={templateId} value={`${templateId}`}>
                              <div className="add-members-link-option">
                                <span className="add-members-link-option-prefix">加入项目后，权限为 </span>
                                <span>{templateName}</span>
                              </div>
                            </Option>
                          ))}
                        </Select>
                        <div
                          className="add-members-link-create-btn"
                          onClick={() => {
                            if (!inviteTemplate) {
                              message.warning('请选择权限模板')
                              return
                            }
                            const content = {
                              projectId: id,
                              projectName: info?.name,
                              userId: user?.id,
                              userName: user?.userName,
                              templateId: inviteTemplate,
                            }
                            AccountControllerService.add({
                              root: {
                                type: 2,
                                content: JSON.stringify(content),
                              },
                            })
                              .then((res: any) => {
                                try {
                                  const url = location.origin + location.pathname + `?invite=${res.data}`
                                  copy(url, () => {
                                    message.success('复制成功！')
                                  })
                                } catch (error) {
                                  console.log(error)
                                }
                              })
                              .catch((err) => {
                                console.log(err)
                              })
                          }}
                        >
                          复制链接
                        </div>
                      </div>
                      <p className="add-members-link-tips">链接有效期7天，可分享给组织外用户</p>
                    </div>
                  }
                >
                  <Button
                    className="project-member-xCenter"
                    icon={<img src={share} alt="" width="12" />}
                    type="primary"
                  ></Button>
                </Popover>
              ) : null}
              {(roleType && roleType != 3 && source == 1) || isAdministrators ? (
                <Button icon={<PlusOutlined />} type="primary" onClick={handleAdd}>
                  添加成员
                </Button>
              ) : null}
            </Space>
          </Space>
        </div>
      </div>
      <Table
        className="project-member-table"
        columns={columns}
        dataSource={dataSource}
        rowKey="id"
        pagination={false}
      />
      {addVisible && (
        <AddMembersModal dataSource={dataSource} visible={addVisible} onOk={handleAddOk} onCancel={handleAaddCancel} />
      )}
    </div>
  )
})

export default ProjectList
