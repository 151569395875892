import { Button, Col, Form, Input, InputNumber, Row, Spin, Table } from 'antd'
import React from 'react'
import { getAMap } from '../../../account-detail/components/map-script'
import { ProjectWorkbenchService } from '../../../../apis/project-service-history'

import '../index.less'
import { GaodeMap, Scene } from '@antv/l7'
// @ts-ignore
import mapboxgl from '!mapbox-gl'
import { EvaluationV2Api } from '../../../../services'
import { getRoofId } from '../../../resource-reckon/resource-mapbox'
import _ from 'lodash'
import { parseSuffixNumber } from '../../../resource-reckon/resource-map'

const mbx = true

const createRoofDom = (obj: any) => {
  const { area, name } = obj
  return `<div>地址：${name}</div><div>面积：${parseSuffixNumber(area, 2)}㎡</div>`
}

const roofInfoGetFunc = _.debounce((feature: any, [center_x, center_y], map, popup) => {
  const { district } = feature
  const srv = new EvaluationV2Api()

  srv.i3060EvaluationGetRoofInfoGet({
    code: district,
    id: getRoofId(feature.properties)
  }).then((resp) => {
    if (resp.data) {
      popup
        .setLngLat([center_x, center_y])
        .setHTML(createRoofDom(resp.data))
        .setMaxWidth('470px')
        .addTo(map)
    }
  })
}, 100)


export const PvDesign = (props: any) => {
  const [current, setCurrent] = React.useState<any>()
  const [loading, setLoading] = React.useState(false)
  const [currentModel, setCurrentModel] = React.useState<any>()
  const [uploading, setUploading] = React.useState(false)
  const [, setCurrentIds] = React.useState<number[]>([])
  const [form] = Form.useForm()

  const [filters, setFilters] = React.useState([])
  const [area, setArea] = React.useState(0)

  const { info, viewMode, roofInfo } = props

  const [, setRoofLoaded] = React.useState<any>()
  React.useEffect(() => {
    setRoofLoaded({ func: props.roofLoaded })
  }, [props.roofLoaded])

  React.useEffect(() => {
    form.setFieldsValue({
      selectedArea: area.toString(),
    })
    if(!viewMode) {
      calcWithArea(area);
    }
  }, [area])

  React.useEffect(() => {
    const {
      transverseSpacing,
      longitudinalSpacing,
      tiltAngle,
      installationAzimuthAngle,
      supportHeight,
      areaEffectiveCoefficient,
      selectedArea,
      buildingHeight,
      inverterCapacityRate
    } = info

    form.setFieldsValue({
      transverseSpacing,
      longitudinalSpacing,
      tiltAngle,
      installationAzimuthAngle,
      supportHeight,
      areaEffectiveCoefficient,
      selectedArea,
      buildingHeight,
      inverterCapacityRate
    })
  }, [info])

  React.useEffect(() => {
    if (current) {
      const {
        transverseSpacing,
        longitudinalSpacing,
        tiltAngle,
        installationAzimuthAngle,
        supportHeight,
        areaEffectiveCoefficient,
        inverterCapacityRate
      } = current

      form.setFieldsValue({
        transverseSpacing,
        longitudinalSpacing,
        tiltAngle,
        installationAzimuthAngle,
        supportHeight,
        areaEffectiveCoefficient,
        inverterCapacityRate
      })
    }
  }, [current])

  const calcWithArea = (selectedArea: number) => {
    const {
      transverseSpacing,
      longitudinalSpacing,
      tiltAngle,
      installationAzimuthAngle,
      supportHeight,
      inverterCapacityRate
    } = form.getFieldsValue()

    let { areaEffectiveCoefficient } = form.getFieldsValue()
    if (!selectedArea || isNaN(selectedArea)) {
      form.setFieldsValue({
        selectedArea: 0.01
      })
      selectedArea = 0.01
    }
    if (!areaEffectiveCoefficient) {
      form.setFieldsValue({
        areaEffectiveCoefficient: info.areaEffectiveCoefficient
      })
      areaEffectiveCoefficient = info.areaEffectiveCoefficient
    }


    if(!selectedArea ||
      !transverseSpacing ||
      !longitudinalSpacing ||
      !tiltAngle ||
      !installationAzimuthAngle ||
      !supportHeight||
      !inverterCapacityRate ||
      !areaEffectiveCoefficient) {

      return;
    }

    ProjectWorkbenchService.calcRoofModuleNum({
      technologyId: props.technologyId,
      root: {
        selectedArea,
        transverseSpacing,
        longitudinalSpacing,
        tiltAngle,
        installationAzimuthAngle,
        supportHeight,
        areaEffectiveCoefficient,
        inverterCapacityRate
      }
    })
      .then((resp) => {
        setCurrentModel(resp.data)
      })
      .catch(() => {
      })
  }

  const getRoofInfo = () => {
    setLoading(true)
    ProjectWorkbenchService.getRoofModuleInfo({
      technologyId: props.technologyId
    })
      .then((resp) => {
        setCurrent(resp.data)

        getAMap().then((AMap: any) => {
          const map = new AMap.Map('roof-map-instance', {
            resizeEnable: true,
            center: [parseFloat(props.geo[0]), parseFloat(props.geo[1])],
            zoom: 16,
            mapStyle: 'amap://styles/05e321edfe9e69b5902e4dc372c36fda',
            WebGLParams: {
              preserveDrawingBuffer: true
            }
          })

          new Scene({
            id: 'roof-map-instance',
            map: new GaodeMap({
              mapInstance: map
            })
          })

          const satelliteLayer = new AMap.TileLayer.Satellite()
          map.add([satelliteLayer])

          const marker = new AMap.Marker({
            position: new AMap.LngLat(...props.geo.map(parseFloat))
          })
          map.add(marker)

          map.on('dblclick', (event: any) => {
            map.setCenter(event.lnglat)
          })
          ProjectWorkbenchService.getNearbyRoof({
            technologyId: props.technologyId,
            distance: '3'
          })
            .then((resp) => {
              if (resp.data?.length > 0) {
                const removedPolygon: any = {}

                resp.data.forEach((o: any) => {
                  const openInfo = (location: any, ignorePatch = false) => {
                    const id = o.id
                    if (removedPolygon[id]) {
                      map.remove(removedPolygon[id].polygon)
                      delete removedPolygon[id]
                      if (!ignorePatch) {
                        const selectedArea = Object.values(removedPolygon)
                          .map((o: any) => o.data.area)
                          .reduce((o, sum) => sum + o, 0)
                        form.setFieldsValue({
                          selectedArea: selectedArea ? parseFloat(selectedArea.toFixed(2)) : selectedArea
                        })
                        calcWithArea(selectedArea)
                      }

                      return
                    }
                    removedPolygon[id] = {
                      data: o,
                      polygon: new AMap.Polygon({
                        path: path,
                        strokeColor: '#05A573',
                        strokeWeight: 2,
                        strokeOpacity: 1,
                        fillOpacity: 0.7,
                        fillColor: '#0B7656',
                        zIndex: 50
                      })
                    }
                    if (!ignorePatch) {
                      const selectedArea = Object.values(removedPolygon)
                        .map((o: any) => o.data.area)
                        .reduce((o, sum) => sum + o, 0)
                      form.setFieldsValue({
                        selectedArea: selectedArea ? parseFloat(selectedArea.toFixed(2)) : selectedArea
                      })
                      calcWithArea(selectedArea)
                    }
                    !viewMode &&
                    removedPolygon[id].polygon.on('click', () => {
                      if (removedPolygon[id]) {
                        map.remove(removedPolygon[id].polygon)
                        delete removedPolygon[id]
                        const selectedArea = Object.values(removedPolygon)
                          .map((o: any) => o.data.area)
                          .reduce((o, sum) => sum + o, 0)
                        form.setFieldsValue({
                          selectedArea: selectedArea ? parseFloat(selectedArea.toFixed(2)) : selectedArea
                        })
                        calcWithArea(selectedArea)

                        setCurrentIds((currentIds) => {
                          const idx = currentIds.indexOf(id)
                          if (idx >= 0) {
                            currentIds.splice(idx, 1)
                          }
                          return [...currentIds]
                        })
                      }
                    })

                    map.add(removedPolygon[id].polygon)

                    setCurrentIds((currentIds) => {
                      const idx = currentIds.indexOf(id)
                      if (idx >= 0) {
                        currentIds.splice(idx, 1)
                      } else {
                        currentIds.push(id)
                      }
                      return [...currentIds]
                    })

                    if (ignorePatch) {
                      return
                    }
                    const info = []
                    o.formattedAddress && info.push(`<div>地址：${o.formattedAddress}</div>`)
                    o.area && info.push(`<div>面积：${o.area}㎡</div>`)
                    // o.buildingHeight && info.push(`<div>建筑高度：${o.buildingHeight}m</div>`)

                    const infoWindow = new AMap.InfoWindow({
                      content: info.join('<br />')
                    })

                    infoWindow.open(map, location)
                  }

                  const path = o.geometry.map((oo: any) => {
                    const lng = Object.keys(oo)[0]
                    const lat = oo[lng]

                    const lnglat = new AMap.LngLat(parseFloat(lng), parseFloat(lat))

                    if (info.roofIds?.includes(o.id)) {
                      map.setCenter(new AMap.LngLat(parseFloat(lng), parseFloat(lat)))
                      // openInfo(lnglat)
                    }
                    return lnglat
                  })

                  const polygon = new AMap.Polygon({
                    path: path,
                    strokeColor: '#05A573',
                    strokeWeight: 2,
                    strokeOpacity: 1,
                    fillOpacity: 0.1,
                    fillColor: '#1EC692',
                    zIndex: 50
                  })

                  const lng = Object.keys(o.geometry[0])[0]
                  const lat = o.geometry[0][lng]
                  const lnglat = new AMap.LngLat(parseFloat(lng), parseFloat(lat))
                  if (info.roofIds?.includes(o.id)) {
                    openInfo(lnglat, true)
                  }

                  !viewMode &&
                  polygon.on('click', (e: any) => {
                    openInfo(e.lnglat)
                  })

                  map.add(polygon)
                })
              }
            })
            .catch(() => {
            })
            .finally(() => {
              setLoading(false)
            })
        })
      })
      .catch(() => {
        setLoading(false)
      })
  }

  const createMap = () => {
    getAMap().then((AMap: any) => {
      const map = new AMap.Map('roof-map-instance', {
        resizeEnable: true,
        center: [parseFloat(props.geo[0]), parseFloat(props.geo[1])],
        zoom: 16,
        mapStyle: 'amap://styles/05e321edfe9e69b5902e4dc372c36fda',
        WebGLParams: {
          preserveDrawingBuffer: true
        }
      })

      new Scene({
        id: 'roof-map-instance',
        map: new GaodeMap({
          mapInstance: map
        })
      })

      const satelliteLayer = new AMap.TileLayer.Satellite()
      map.add([satelliteLayer])

      const marker = new AMap.Marker({
        position: new AMap.LngLat(...props.geo.map(parseFloat))
      })
      map.add(marker)

      roofInfo.forEach((info: any, idx: number) => {
        const path = info.map((oo: any) => {
          const lng = Object.keys(oo)[0]
          const lat = oo[lng]

          const lnglat = new AMap.LngLat(parseFloat(lng), parseFloat(lat))

          if (idx === 0) {
            map.setCenter(new AMap.LngLat(parseFloat(lng), parseFloat(lat)))
          }
          return lnglat
        })

        const polygon = new AMap.Polygon({
          path: path,
          strokeColor: '#05A573',
          strokeWeight: 2,
          strokeOpacity: 1,
          fillOpacity: 0.5,
          fillColor: '#1EC692',
          zIndex: 50
        })

        map.add(polygon)
      })
    })
    getRoofInfo()
  }

  const mbxHandler = () => {
    const key = 'roof-map-instance'

    const map = new mapboxgl.Map({
      style: 'mapbox://styles/owops/cl2h1u3kx000u15o26t9bmtyh',
      center: props.geo.map(parseFloat),
      zoom: 15,
      minZoom: 1,
      maxZoom: 20,
      container: key,
      preserveDrawingBuffer: true,
      projection: 'equirectangular' // starting projection
    } as any)

    map.on('zoomend', () => {
      console.log(map.getZoom())
    })

    map.on('data', (e: any) => {
      if (e.isSourceLoaded && e.sourceId === 'roof' && e.sourceCacheId === 'other:roof') {
        setRoofLoaded((roofLoaded) => {
          roofLoaded?.func?.();
          return undefined;
        })
      }

    })

    map.on('load', () => {
      const startTime = Date.now();

      const itv = setInterval(() => {
        const endTime = Date.now();
        if(endTime - startTime > 20000) {
          setRoofLoaded((roofLoaded) => {
            roofLoaded?.func?.();
            clearInterval(itv);
            return undefined;
          })
        }
      }, 1000);

      map.addSource('satelite', {
        type: 'raster',
        maxzoom: 20,
        tiles: ['http://114.217.8.57:8888/static/v1.0.0/klfmbt/{z}/{x}/{y}.png']
      })

      map.addLayer(
        {
          id: 'stLayer',
          type: 'raster',
          maxzoom: 20,
          source: 'satelite',
          'source-layer': 'default',
          paint: {
            'raster-opacity': 1
          }
        },
        'hillshade'
      )

      map.addSource('roof', {
        type: 'vector',
        scheme: 'xyz',
        minzoom: 1,
        maxzoom: 13,
        tiles: [
          `https://dev-tile.terraqt.com/tile/xyz/{z}/{x}/{y}.pbf?path=f7a3c99d-4306-404a-ad33-5f1099992871/cor10403/20191201/CN-${props.province}/vectortiles`
        ]
      })

      map.addLayer({
        id: 'roofLayer',
        type: 'fill',
        source: 'roof',
        'source-layer': 'default',
        paint: {
          'fill-color': 'rgba(0, 255, 0, 0.2)',
          'fill-opacity': 0.6,
          'fill-outline-color': 'rgba(0, 255, 0, 1)'
        }
      })

      map.addLayer({
        id: 'roofLight',
        type: 'fill',
        source: 'roof',
        'source-layer': 'default',
        paint: {
          'fill-color': '#088',
          'fill-outline-color': 'rgba(0, 255, 0, 1)',
          'fill-opacity': 0.6
        },
        filter: ['in', 'id', '']
      })

      map.addLayer({
        id: 'roofSelected',
        type: 'fill',
        source: 'roof',
        'source-layer': 'default',
        paint: {
          'fill-color': '#088',
          'fill-outline-color': 'rgba(0, 255, 0, 1)',
          'fill-opacity': 1
        },
        filter: ['in', 'id', ...filters.map(o => o.id)]
      })

      const popup = new mapboxgl.Popup({
        closeButton: true,
        closeOnClick: true,
        closeOnMove: true
      })

      map.on('mousemove', 'roofLayer', (e: any) => {
        const feature = e.features?.[0]

        if (feature?.properties) {
          map.setFilter('roofLight', ['in', 'id', feature.properties?.id])

          const { center_x, center_y } = feature.properties

          roofInfoGetFunc({
            ...feature,
            province: props.province,
            district: props.district
          }, [center_x, center_y], map, popup)
        }
      })

      map.on('click', 'roofLayer', (e: any) => {
        const feature = e.features?.[0]
        if (feature?.properties?.id) {
          const { area } = feature.properties
          let curArea;
          setFilters((filters) => {
            const dst = [...filters];
            const idx = dst.findIndex(o => o.id === feature.properties?.id)
            setArea((area) => {
              if(curArea === undefined) {
                curArea = area;
              }
              const aa = feature.properties.area;
              if(idx >= 0) return curArea - aa;
              else return curArea + aa;
            })
            if (idx >= 0) {
              dst.splice(idx, 1)
            } else {
              dst.push({ id: feature.properties?.id, area, feature: feature.properties })
            }
            map.setFilter('roofSelected', ['in', 'id', ...dst.map(o => o.id)])
            return dst
          })

          popup.remove()
        }
      })
    })
  }

  const createMapbox = () => {
    setLoading(true)
    ProjectWorkbenchService.getRoofModuleInfo({
      technologyId: props.technologyId
    })
      .then((resp) => {
        setCurrent(resp.data)
        const filters = resp.data.ids?.map(o => ({ id: o, area: 0, })) || [];
        setFilters(filters);
        const area = resp.data.selectedArea;
        if(area) {
          setArea(parseFloat(resp.data.selectedArea));
        }
        setTimeout(() => {
          mbxHandler();
        }, 100);
      })
      .finally(() => {
        setLoading(false)
      })
  }

  React.useEffect(() => {
    if (!mbx) {
      if (roofInfo) {
        setLoading(false)
        setCurrent(props.info)
        setTimeout(() => {
          createMap()
        }, 500)
      }
    } else {
      createMapbox()
    }
  }, [])

  return (
    <Spin spinning={loading}>
      {!current && (
        <div
          style={{
            height: '3rem'
          }}
        />
      )}
      {current && (
        <div>
          <div
            id='roof-map-instance'
            style={{
              height: 300,
              border: '1px solid gray',
              marginBottom: '1rem'
            }}
          />
          <div>
            <strong>排布规则</strong>
          </div>
          <Form className='system-form' labelCol={{ span: 9 }} wrapperCol={{ span: 16 }} form={form}>
            <Row>
              <Col span={8}>
                {viewMode && (
                  <Form.Item label='模组横向间距' name='transverseSpacing'>
                    <span className='tech-result-value'>{form.getFieldValue('transverseSpacing')}m</span>
                  </Form.Item>
                )}
                {!viewMode && (
                  <Form.Item label='模组横向间距' name='transverseSpacing'>
                    <InputNumber
                      precision={2}
                      min={0}
                      max={100}
                      controls={false}
                      addonAfter='m'
                      onBlur={() => {
                        const val = form.getFieldValue('selectedArea')
                        setTimeout(() => {
                          calcWithArea(parseFloat(val))
                        })
                      }}
                    />
                  </Form.Item>
                )}
              </Col>
              <Col span={8}>
                {viewMode && (
                  <Form.Item label='模组倾斜角度' name='tiltAngle'>
                    <span className='tech-result-value'>{form.getFieldValue('tiltAngle')}°</span>
                  </Form.Item>
                )}
                {!viewMode && (
                  <Form.Item label='模组倾斜角度' name='tiltAngle'>
                    <InputNumber
                      precision={2}
                      controls={false}
                      min={0}
                      max={90}
                      addonAfter='°'
                      defaultValue={form.getFieldValue('tiltAngle')}
                      onBlur={() => {
                        const val = form.getFieldValue('selectedArea')
                        setTimeout(() => {
                          calcWithArea(parseFloat(val))
                        })
                      }}
                    />
                  </Form.Item>
                )}
              </Col>
              <Col span={8}>
                {viewMode && (
                  <Form.Item label='建筑高度' name='buildingHeight'>
                    <span className='tech-result-value'>{form.getFieldValue('buildingHeight')}m</span>
                  </Form.Item>
                )}
                {!viewMode && (
                  <Form.Item label='建筑高度' name='buildingHeight'>
                    <InputNumber
                      disabled
                      defaultValue={current.buildbuildingHeightingHeight}
                      precision={2}
                      controls={false}
                      min={0}
                      max={999}
                      addonAfter='m'
                      onBlur={() => {
                        const val = form.getFieldValue('selectedArea')
                        setTimeout(() => {
                          calcWithArea(parseFloat(val))
                        })
                      }}
                    />
                  </Form.Item>
                )}
              </Col>
              <Col span={8}>
                {viewMode && (
                  <Form.Item label='模组纵向间距' name='longitudinalSpacing'>
                    <span className='tech-result-value'>{form.getFieldValue('longitudinalSpacing')}m</span>
                  </Form.Item>
                )}
                {!viewMode && (
                  <Form.Item label='模组纵向间距' name='longitudinalSpacing'>
                    <InputNumber
                      precision={2}
                      controls={false}
                      min={0}
                      max={100}
                      addonAfter='m'
                      defaultValue={form.getFieldValue('longitudinalSpacing')}
                      onBlur={() => {
                        const val = form.getFieldValue('selectedArea')
                        setTimeout(() => {
                          calcWithArea(parseFloat(val))
                        })
                      }}
                    />
                  </Form.Item>
                )}
              </Col>
              <Col span={8}>
                {viewMode && (
                  <Form.Item label='安装方位角度' name='installationAzimuthAngle'>
                    <span className='tech-result-value'>{form.getFieldValue('installationAzimuthAngle')}°</span>
                  </Form.Item>
                )}
                {!viewMode && (
                  <Form.Item label='安装方位角度' name='installationAzimuthAngle'>
                    <InputNumber
                      precision={2}
                      controls={false}
                      min={0}
                      max={360}
                      addonAfter='°'
                      defaultValue={form.getFieldValue('installationAzimuthAngle')}
                      onBlur={() => {
                        const val = form.getFieldValue('selectedArea')
                        setTimeout(() => {
                          calcWithArea(parseFloat(val))
                        })
                      }}
                    />
                  </Form.Item>
                )}
              </Col>
              <Col span={8}>
                {viewMode && (
                  <Form.Item label='支架高度'>
                    <span className='tech-result-value'>{form.getFieldValue('supportHeight')}m</span>
                  </Form.Item>
                )}
                {!viewMode && (
                  <Form.Item label='支架高度' name='supportHeight'>
                    <InputNumber
                      precision={2}
                      controls={false}
                      min={0}
                      max={5}
                      addonAfter='m'
                      defaultValue={form.getFieldValue('supportHeight')}
                      onBlur={() => {
                        const val = form.getFieldValue('selectedArea')
                        setTimeout(() => {
                          calcWithArea(parseFloat(val))
                        })
                      }}
                    />
                  </Form.Item>
                )}
              </Col>

              <Col span={8}>
                {viewMode && (
                  <Form.Item label='逆变器容配比'>
                    <span className='tech-result-value'>{form.getFieldValue('inverterCapacityRate')}</span>
                  </Form.Item>
                )}
                {!viewMode && (
                  <Form.Item label='逆变器容配比' name='inverterCapacityRate'>
                    <InputNumber
                      className="border-number-right"
                      style={{
                        width: '100%'
                      }}
                      precision={2}
                      controls={false}
                      min={1}
                      max={1.5}
                      defaultValue={form.getFieldValue('inverterCapacityRate')}
                      onBlur={() => {
                        const val = form.getFieldValue('selectedArea')
                        setTimeout(() => {
                          calcWithArea(parseFloat(val))
                        })
                      }}
                    />
                  </Form.Item>
                )}
              </Col>
            </Row>
            <Row
              className='system-form'
              style={{
                borderTop: '1px dotted lightgray',
                paddingTop: '1rem',
                paddingBottom: '1rem',
                borderBottom: '1px dotted lightgray'
              }}
            >
              <Col span={8}>
                {viewMode && (
                  <Form.Item label='所选区域面积' name='selectedArea'>
                    <span className='tech-result-value'>{form.getFieldValue('selectedArea')}㎡</span>
                  </Form.Item>
                )}
                {!viewMode && (
                  <Form.Item label='所选区域面积' name='selectedArea'>
                    <InputNumber
                      defaultValue={form.getFieldValue('selectedArea')}
                      precision={2}
                      controls={false}
                      min={0.01}
                      max={999999}
                      addonAfter='㎡'
                      onBlur={(dom) => {
                        const val = dom.target.value;
                        calcWithArea(Math.min(parseFloat(val), 999999))
                      }}
                    />
                  </Form.Item>
                )}
              </Col>
              <Col span={8}>
                {viewMode && (
                  <Form.Item label='面积有效系数' name='areaEffectiveCoefficient'>
                    <span className='tech-result-value'>{form.getFieldValue('areaEffectiveCoefficient')}%</span>
                  </Form.Item>
                )}
                {!viewMode && (
                  <Form.Item label='面积有效系数' name='areaEffectiveCoefficient'>
                    <InputNumber
                      precision={0}
                      min={0}
                      max={100}
                      addonAfter='%'
                      controls={false}
                      defaultValue={form.getFieldValue('areaEffectiveCoefficient')}
                      onBlur={() => {
                        const val = form.getFieldValue('selectedArea')
                        setTimeout(() => {
                          calcWithArea(parseFloat(val))
                        })
                      }}
                    />
                  </Form.Item>
                )}
              </Col>
              <Col span={8}>
                <Form.Item label='模组尺寸'>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center'
                    }}
                  >
                    <strong>宽</strong>
                    {viewMode && <span className='tech-result-value'>{current.width}m</span>}
                    {!viewMode && <Input disabled value={current.width} suffix='m' />}
                    <strong
                      style={{
                        marginLeft: '.5rem'
                      }}
                    >
                      长
                    </strong>
                    {viewMode && <span className='tech-result-value'>{current.height}m</span>}
                    {!viewMode && <Input disabled value={current.height} suffix='m' />}
                  </div>
                </Form.Item>
              </Col>
            </Row>
          </Form>
          <Table
            className='custom-height-table bordered-table'
            dataSource={
              currentModel
                ? [
                  { name: '预计个数', pv: currentModel.pvModuleNum, nb: currentModel.inverterNum },
                  {
                    name: '预计装机容量（kW）',
                    pv: currentModel.pvModuleCapacity,
                    nb: currentModel.inverterCapaity
                  }
                ]
                : [
                  { name: '预计个数', pv: current.photovoltaicNum, nb: current.inverterNum },
                  {
                    name: '预计装机容量（kW）',
                    pv: current.photovoltaicInstallCapacity,
                    nb: current.inverterInstallCapacity
                  }
                ]
            }
            bordered
            columns={[
              {
                title: '',
                dataIndex: 'name',
                key: 'name',
                width: 1
              },
              {
                title: '光伏模组',
                dataIndex: 'pv',
                key: 'pv',
                width: 1
              },
              {
                title: '逆变器',
                dataIndex: 'nb',
                key: 'nb',
                width: 1
              }
            ]}
            pagination={false}
          />
        </div>
      )}
      {!props.hideActions && (
        <div className='ant-modal-confirm-btns'>
          {!viewMode && (
            <Button
              onClick={() => {
                props.onClose?.()
              }}
            >
              取 消
            </Button>
          )}
          <Button
            type='primary'
            loading={uploading}
            onClick={() => {
              if (viewMode) {
                props.onClose?.()
                return
              }
              setUploading(true)
              const {
                transverseSpacing,
                longitudinalSpacing,
                tiltAngle,
                installationAzimuthAngle,
                supportHeight,
                areaEffectiveCoefficient,
                selectedArea,
                inverterCapacityRate
              } = form.getFieldsValue()
              ProjectWorkbenchService.updateRoofModule({
                technologyId: props.technologyId,
                root: {
                  ids: filters.map(o => o.id),
                  transverseSpacing,
                  longitudinalSpacing,
                  tiltAngle,
                  installationAzimuthAngle,
                  supportHeight,
                  areaEffectiveCoefficient,
                  selectedArea,
                  inverterCapacityRate
                }
              })
                .then(() => {
                  props.onClose?.(true)
                })
                .finally(() => {
                  setUploading(false)
                })
            }}
          >
            确 定
          </Button>
        </div>
      )}
    </Spin>
  )
}
