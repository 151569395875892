import type { investType, incentiveModalType } from './typings'

const BaseReducer = <T>(
  state: investType,
  { type, payload, callback }: { type: string; payload: T; callback?: () => void }
) => {
  if (callback) {
    callback()
  }
  switch (type) {
    case 'updateState':
      return { ...state, ...payload }
    default:
      return state
  }
}

const incentiveReducer = <T>(
  state: incentiveModalType,
  { type, payload, callback }: { type: string; payload: T; callback?: () => void }
) => {
  if (callback) {
    callback()
  }
  switch (type) {
    case 'updateState':
      return { ...state, ...payload }
    default:
      return state
  }
}

export { BaseReducer, incentiveReducer }
