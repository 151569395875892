import { Statistic } from 'antd'
import React from 'react'

import '../index.less'

type StatisticItem = {
  title: string
  value: number
  icon: string
  unit: string
}

type IProps = {
  list: StatisticItem[]
}

const PowerStationStatistic: React.FC<IProps> = React.memo((props) => {
  const { list } = props
  return (
    <div className="power-station-statistic">
      {list.map((item: StatisticItem, index: number) => {
        const { icon, title, value, unit } = item || {}
        return (
          <div key={index} className="power-station-statistic-item">
            <img className="power-station-statistic-icon" src={icon} alt="" />
            <div className="power-station-statistic-content">
              <h6 className="power-station-statistic-title">{title}</h6>
              <div className="power-station-statistic-footer">
                <Statistic value={value} />
                <span className="power-station-statistic-unit">{unit}</span>
              </div>
            </div>
          </div>
        )
      })}
    </div>
  )
})

export default PowerStationStatistic
