import React, { useMemo } from 'react'
import { Outlet, useParams } from 'react-router-dom'
import { KLFTabs } from '../../../components/klf-tab'
import { Button, Card, Modal, Spin, Tooltip, Image } from 'antd'
import { ProjectServiceHistory } from '../../../apis/project-service-history'
import { BehaviorSubject, Subject } from 'rxjs'
import { BreadcrumbObservable, SideMenuObservable } from '../../../application'
import { CustomIcons } from '../../../global'
import useAuth from '../../../utils/auth'
import IconAuthEmpty from '../../../assets/auth-empty.png'

const keys = ['electricity', 'bear', 'weather', 'calc']

export const Empty = () => (
  <div
    style={{
      textAlign: 'center',
    }}
  >
    <Image
      style={{
        width: 240,
        height: 240,
        marginTop: 145,
      }}
      src={IconAuthEmpty}
      alt=""
      preview={false}
    />
    <br />
    <span style={{ color: '#AEAFB2' }}>该部分信息未录入</span>
  </div>
)

export const InfoList = new BehaviorSubject({
  electricity: { finished: false, forceEdit: false },
  bear: { finished: false, forceEdit: false },
  weather: { finished: false, forceEdit: false },
  calc: { finished: false, forceEdit: false },
})

export const eleObservable = new Subject()
export const bearObservable = new Subject()
export const weatherObservable = new Subject()
export const calcObservable = new Subject()

export const catalogueObservable = new Subject();

export const Edge = () => {
  const params = useParams()

  const [activatedPage, setActivatedPage] = React.useState(keys.find((o) => location.href.includes(o)) || keys[0])

  const [infoList, setInfoList] = React.useState<any>()
  const [loading, setLoading] = React.useState(true)
  const [authLoading, setAuthLoading] = React.useState(true)

  const [canEdit, setEditable] = React.useState(true)

  const reloadInfoList = () => {
    ProjectServiceHistory.infoList({
      projectId: params.id!,
    }).then((resp) => {
      const { countBorder, electricityScheme, loadCurve, weather, loadCurveType } = resp.data
      const infoList = {
        electricity: { finished: electricityScheme, forceEdit: false },
        bear: { finished: loadCurve, forceEdit: false },
        weather: { finished: weather, forceEdit: false },
        calc: { finished: countBorder, forceEdit: false },
        loadCurveType,
      }
      setInfoList(infoList)
      setLoading(false)
      InfoList.next(infoList)
    })
  }

  const auth = useAuth(params.id) || {}

  const hasAuth = useMemo(() => {
    let isAuth = false
    if (auth) {
      switch (activatedPage) {
        case 'electricity':
          isAuth = !!auth['electricity_price_scheme']
          break
        case 'bear':
          isAuth = !!auth['load_curve']
          break
        case 'calc':
          isAuth = !!auth['time_range_accuracy']
          break
        case 'weather':
          isAuth = !!auth['weather_data']
          break
      }
    }
    return isAuth
  }, [activatedPage, auth])

  React.useEffect(() => {
    if (auth) {
      setAuthLoading(false)
    }
  }, [auth])

  React.useEffect(() => {
    SideMenuObservable.next(true)

    const eleObserve$ = eleObservable.subscribe(() => {
      reloadInfoList()
    })
    const bearObserve$ = bearObservable.subscribe(() => {
      reloadInfoList()
    })
    const weatherObserve$ = weatherObservable.subscribe(() => {
      reloadInfoList()
    })
    const calcObserve$ = calcObservable.subscribe(() => {
      reloadInfoList()
    })

    const catalogueObserve$ = catalogueObservable.subscribe((next) => {
      if(next) {
        setInfoList(infoList => {
          infoList['electricity'].forceEdit = true
          InfoList.next({ ...infoList })
          return infoList;
        })
      }
    })

    InfoList.next({
      electricity: { finished: false, forceEdit: false },
      bear: { finished: false, forceEdit: false },
      weather: { finished: false, forceEdit: false },
      calc: { finished: false, forceEdit: false },
    })

    setLoading(true)
    reloadInfoList()

    ProjectServiceHistory.get({
      projectId: params.id!,
    }).then((resp) => {
      const item = resp.data
      const { lockedTechnicalProposalNum } = item
      setEditable(lockedTechnicalProposalNum === 0)
      BreadcrumbObservable.next([
        {
          name: '项目管理',
          url: '/program/manage',
        },
        {
          name: item.name,
          url: `/program/detail/${item.id}`,
        },
        {
          name: '边界设置',
          url: `/program/edge/${item.id}/electricity`,
        },
      ])
    })

    return () => {
      eleObserve$.unsubscribe()
      bearObserve$.unsubscribe()
      weatherObserve$.unsubscribe()
      calcObserve$.unsubscribe()
      catalogueObserve$.unsubscribe()
    }
  }, [])

  const getName = (key: string) => {
    return { electricity: '电价方案', bear: '负荷曲线', calc: '时间范围与精度' }[key]
  }

  const showEditButton = () => {
    if (!infoList) return false
    if (location.href.includes(keys[0]) && !location.href.includes('electricity/catalogue')) return false
    return infoList[activatedPage].finished && !infoList[activatedPage].forceEdit
  }

  return (
    <Card
      className="no-border-bottom-card"
      title={
        <div
          style={{
            fontSize: 20,
            color: '#262E3F',
            fontWeight: 'bold',
          }}
        >
          边界设置
        </div>
      }
      style={{
        minHeight: '100%',
      }}
      bodyStyle={{
        paddingTop: 0,
        paddingBottom: '1rem',
        maxWidth: 1600,
      }}
    >
      {!loading && (
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <span>技术边界：</span>
            {infoList && (
              <KLFTabs
                config={[
                  {
                    title: '电价方案',
                    key: `${keys[0]}/catalogue`,
                    iconUrl: 'electricity',
                    finished: infoList.electricity.finished,
                  },
                  {
                    title: '负荷曲线',
                    key: keys[1],
                    iconUrl: 'bear',
                    finished: infoList.bear.finished,
                  },
                  {
                    title: '天气数据',
                    key: keys[2],
                    iconUrl: 'weather',
                    finished: infoList.weather.finished,
                  },
                ]}
                activatedKey={activatedPage}
                callback={(key) => {
                  let _key = key
                  if (key === 'electricity/catalogue') {
                    _key = 'electricity'
                  }
                  setActivatedPage(_key)
                  infoList?.[key] && (infoList[key].forceEdit = false)
                  setInfoList({ ...infoList })
                }}
              />
            )}
            <span>计算边界：</span>
            {infoList && (
              <KLFTabs
                config={[
                  {
                    title: '时间范围与精度',
                    key: keys[3],
                    iconUrl: 'calc',
                    finished: infoList.calc.finished,
                    disabled: !infoList.bear.finished,
                  },
                ]}
                activatedKey={activatedPage}
                callback={(key) => {
                  setActivatedPage(key)
                  infoList[key].forceEdit = false
                  setInfoList({ ...infoList })
                }}
              />
            )}
          </div>
          <div>
            {showEditButton() && activatedPage !== keys[0] && activatedPage !== keys[2] && (
              <Tooltip title="该项目下有归档的技术方案" trigger={canEdit ? 'none' : 'hover'}>
                <Button
                  type="primary"
                  disabled={!hasAuth}
                  ghost
                  onClick={() => {
                    Modal.confirm({
                      keyboard: false,
                      title: `修改${activatedPage === 'electricity' ? '目录电价' : getName(activatedPage)}`,
                      icon: <CustomIcons type="klf-alarm1" />,
                      content: (
                        <div>
                          <p>确认修改{activatedPage === 'electricity' ? '目录电价' : getName(activatedPage)}？</p>
                          <p>该项目下与之关联的技术方案结果将全部失效（归档方案将解除归档），财务方案将被全部删除</p>
                        </div>
                      ),
                      onOk: () => {
                        infoList[activatedPage].forceEdit = true
                        console.log({ ...infoList })
                        setInfoList({ ...infoList })
                        InfoList.next({ ...infoList })
                      },
                      cancelButtonProps: {
                        type: 'primary',
                        ghost: true,
                      },
                    })
                  }}
                >
                  <CustomIcons type="klf-edit1" />
                  编辑设置
                </Button>
              </Tooltip>
            )}
          </div>
        </div>
      )}
      <div
        style={{
          flexGrow: 1,
          marginTop: '1rem',
          height: 'calc(100vh - 300px)',
        }}
        className="edge-inner-spin"
      >
        <Spin spinning={loading}>{!loading && !authLoading && <Outlet />}</Spin>
      </div>
    </Card>
  )
}
