import React, { useEffect, useState, useReducer, useRef } from 'react'
import styles from '../index.module.scss'
import {
  Spin,
  Table,
  InputNumber,
  Input,
  Form,
  Button,
  Space,
  Dropdown,
  Menu,
  Modal,
  message,
  Tooltip,
  // List,
} from 'antd'
import {
  PlusCircleFilled,
  EllipsisOutlined,
  //  ExclamationCircleFilled
} from '@ant-design/icons'
import { FinanceService } from '../../../../apis/project-service-history'
import { BaseReducer } from '../reducer'
import { CustomIcons } from '../../../../global'
import { SubBreadcrumbObservable } from '../../layout'
import { paramsStoreShowError } from '../..'
// import CodeModal from './CodeModal'
import { RESOURCE_NAME_EXP } from '../../../../utils/constant'

const projectTypeList = [
  {
    name: '光伏',
    code: 'gf',
    rows: [
      {
        name: '单位静态投资',
        unit: '¥/kWp',
        type: 'static',
        field: '_photovoltaicStaticInvestmentPerUnit',
        isNotEdit: true,
      },
      {
        name: '设备及建安工程费',
        unit: '¥/kWp',
        type: 'primary',
        field: '_photovoltaicEquipmentConstructionCost',
        isNotEdit: true,
      },
      {
        name: '光伏模组',
        unit: '¥/kWp',
        field: 'photovoltaicModuleUnitPrice',
        type: 'desc',
        precision: 2,
        max: 999999,
      },
      {
        name: '逆变器',
        unit: '¥/kWp',
        field: 'photovoltaicInverterUnitPrice',
        type: 'desc',
        precision: 2,
        max: 999999,
      },
      {
        name: '其他设备',
        field: 'photovoltaicOtherFacilityPrice',
        unit: '¥/kWp',
        type: 'desc',
        precision: 2,
        max: 999999,
      },
      {
        name: '安装工程费',
        unit: '¥/kWp',
        field: 'photovoltaicInstallCostPrice',
        type: 'desc',
        precision: 2,
        max: 999999,
      },
      {
        name: '建筑工程费',
        unit: '¥/kW',
        field: 'photovoltaicArchitectureProjectCost',
        type: 'desc',
        precision: 2,
        max: 999999,
      },
      {
        name: '其他费用',
        unit: '¥/kWp',
        field: '_photovoltaicOtherFees',
        type: 'primary',
        isNotEdit: true,
      },
      {
        name: '项目管理费',
        unit: '%',
        field: 'photovoltaicProjectManagementCostProportion',
        type: 'desc',
        precision: 2,
      },
      {
        name: '生产准备费',
        unit: '%',
        field: 'photovoltaicYieldPlanCostProportion',
        type: 'desc',
        precision: 2,
      },
      {
        name: '勘察设计费',
        unit: '%',
        field: 'photovoltaicProspectDesignCostProportion',
        type: 'desc',
        precision: 2,
      },

      {
        name: '基本预备费',
        unit: '¥/kWp',
        type: 'primary',
        field: '_photovoltaicBasicReserve',
        isNotEdit: true,
      },
      {
        name: '基本预备费率',
        unit: '%',
        field: 'photovoltaicBasicReadyCostRate',
        type: 'desc',
        precision: 2,
      },
    ],
  },
  {
    name: '储能',
    code: 'cn',
    rows: [
      {
        name: '单位静态投资',
        unit: '¥/kWh',
        type: 'static',
        field: '_storedStaticInvestmentPerUnit',
        isNotEdit: true,
      },
      {
        name: '设备及建安工程费',
        unit: '¥/kWh',
        type: 'primary',
        field: '_storedEquipmentConstructionCost',
        isNotEdit: true,
      },
      {
        name: '电池模组',
        unit: '¥/kWh',
        field: 'storedEnergyCellModuleUnitPrice',
        type: 'desc',
        precision: 2,
        max: 999999,
      },
      {
        name: '年下降率',
        unit: '%',
        field: 'storedEnergyCellModuleUnitPriceYearsDeclineRate',
        type: 'desc',
        precision: 2,
      },
      {
        name: '储能变流器',
        unit: '¥/kWh',
        field: 'storedEnergyConverterUnitPrice',
        type: 'desc',
        precision: 2,
        max: 999999,
      },
      {
        name: '其他设备',
        field: 'storedEnergyOtherFacilityPrice',
        unit: '¥/kWh',
        type: 'desc',
        precision: 2,
        max: 999999,
      },
      {
        name: '安装工程费',
        field: 'storedEnergyInstallCostPrice',
        unit: '¥/kWh',
        type: 'desc',
        precision: 2,
        max: 999999,
      },
      {
        name: '建筑工程费',
        unit: '¥/kWh',
        field: 'storedEnergyArchitectureProjectCost',
        type: 'desc',
        precision: 2,
        max: 999999,
      },
      {
        name: '其他费用',
        unit: '¥/kWh',
        field: '_storedOtherFees',
        type: 'primary',
        isNotEdit: true,
      },
      {
        name: '项目管理费',
        unit: '%',
        field: 'storedEnergyProjectManagementCostProportion',
        type: 'desc',
        precision: 2,
      },
      {
        name: '生产准备费',
        unit: '%',
        field: 'storedEnergyYieldPlanCostProportion',
        type: 'desc',
        precision: 2,
      },
      {
        name: '勘察设计费',
        unit: '%',
        field: 'storedEnergyProspectDesignCostProportion',
        type: 'desc',
        precision: 2,
      },
      {
        name: '基本预备费',
        unit: '¥/kWp',
        type: 'primary',
        field: '_storedBasicReserve',
        isNotEdit: true,
      },
      {
        name: '基本预备费率',
        unit: '%',
        field: 'storedEnergyBasicReadyCostRate',
        type: 'desc',
        precision: 2,
      },
      {
        name: '',
        hidden: true,
        field: 'projectInvestId',
      },
    ],
  },
]

// 项目投资参数
const ProjectInvestment = () => {
  const [loading, setLoading] = useState(false)
  const [confirmLoading, setConfirmLoading] = useState(false)
  const [
    ,
    // saveLoading
    setSaveLoading,
  ] = useState(false)
  const [defaultData, setDefaultData] = useState<any>()
  const [readonly, setReadonly] = useState(true)
  const [defaultStaticObj, setDefaultStaticObj] = useState<any>({})
  const [defaultStoredStaticObj, setDefaultStoredStaticObj] = useState<any>({})
  const [tempDataSource, setTempDefaultSource] = useState<any>([])

  let colRef = useRef<any[]>()

  // 光伏项目投资计算参数
  const projectComputedCount = ({ data, type, addFlag }: { data: any; type: string; addFlag?: boolean }) => {
    if (!addFlag) {
      // 设备及建安工程费 = 光伏模组+ 逆变器+ 其他设备+ 安装工程费+ 建筑工程费
      let equipmentCost =
        data['photovoltaicModuleUnitPrice'] +
        data['photovoltaicInverterUnitPrice'] +
        data['photovoltaicOtherFacilityPrice'] +
        data['photovoltaicInstallCostPrice'] +
        data['photovoltaicArchitectureProjectCost']

      // 其他费用 = (项目管理费+ 生产准备费+ 勘察设计费) * 设备及建安工程费
      let otherFeesCost =
        (data['photovoltaicProjectManagementCostProportion'] / 100 +
          data['photovoltaicYieldPlanCostProportion'] / 100 +
          data['photovoltaicProspectDesignCostProportion'] / 100) *
        equipmentCost

      // 基本预备费 = 基本预备费率*(设备及建安工程费 + 其他费用)
      let basicReserveCost = (data['photovoltaicBasicReadyCostRate'] / 100) * (equipmentCost + otherFeesCost)

      // 单位静态投资 =（一）设备及建安工程费+（二）其他费用+（三）基本预备费
      var staticInvestmentPerUnit = equipmentCost + otherFeesCost + basicReserveCost

      // 设备及建安工程费占静态单位投资比例
      let equipmentCostPercent = equipmentCost / staticInvestmentPerUnit
      // 其他费用示占静态单位投资比例
      let otherFeesCostPercent = otherFeesCost / staticInvestmentPerUnit
      // 基本预备费占静态单位投资比例
      let basicReserveCostPercent = basicReserveCost / staticInvestmentPerUnit

      switch (type) {
        // 单位静态投资
        case '_photovoltaicStaticInvestmentPerUnit': {
          data['_photovoltaicStaticInvestmentPerUnit'] = staticInvestmentPerUnit.toFixed(2)
          return (
            <div
              style={{
                color: '#262E3F',
                fontSize: '14px',
              }}
            >
              {data['_photovoltaicStaticInvestmentPerUnit']}
              <span
                style={{
                  color: '#767F95',
                }}
              >
                (100%)
              </span>
            </div>
          )
        }
        // 设备及建安工程费
        case '_photovoltaicEquipmentConstructionCost': {
          data['_photovoltaicEquipmentConstructionCost'] = equipmentCost.toFixed(2)
          return (
            <div
              style={{
                color: '#262E3F',
                fontSize: '14px',
              }}
            >
              {data['_photovoltaicEquipmentConstructionCost']}
              <span
                style={{
                  color: '#767F95',
                }}
              >
                ({Number(equipmentCostPercent * 100).toFixed(2)}%)
              </span>
            </div>
          )
        }
        // 其他费用
        case '_photovoltaicOtherFees': {
          data['_photovoltaicOtherFees'] = otherFeesCost.toFixed(2)
          return (
            <div
              style={{
                color: '#262E3F',
                fontSize: '14px',
              }}
            >
              {data['_photovoltaicOtherFees']}
              <span
                style={{
                  color: '#767F95',
                }}
              >
                ({Number(otherFeesCostPercent * 100).toFixed(2)}%)
              </span>
            </div>
          )
        }
        // 基本预备费
        case '_photovoltaicBasicReserve': {
          data['_photovoltaicBasicReserve'] = basicReserveCost.toFixed(2)
          return (
            <div
              style={{
                color: '#262E3F',
                fontSize: '14px',
              }}
            >
              {data['_photovoltaicBasicReserve']}
              <span
                style={{
                  color: '#767F95',
                }}
              >
                ({Number(basicReserveCostPercent * 100).toFixed(2)}%)
              </span>
            </div>
          )
        }
        default:
          return ''
      }
    } else {
      return defaultStaticObj[type]
    }
  }
  // 储能项目投资计算参数
  const storedComputedCount = ({ data, type, addFlag }: { data: any; type: string; addFlag?: boolean }) => {
    if (!addFlag) {
      // 设备及建安工程费 =  电池模组+ 储能变流器 + 其他设备+ 安装工程费+ 建筑工程费
      let equipmentCost =
        data['storedEnergyCellModuleUnitPrice'] +
        data['storedEnergyConverterUnitPrice'] +
        data['storedEnergyOtherFacilityPrice'] +
        data['storedEnergyInstallCostPrice'] +
        data['storedEnergyArchitectureProjectCost']
      // 其他费用 = (项目管理费+ 生产准备费+ 勘察设计费) * 设备及建安工程费
      let otherFeesCost =
        (data['storedEnergyProjectManagementCostProportion'] / 100 +
          data['storedEnergyYieldPlanCostProportion'] / 100 +
          data['storedEnergyProspectDesignCostProportion'] / 100) *
        equipmentCost
      // 基本预备费 = 基本预备费率*(设备及建安工程费 + 其他费用)
      let basicReserveCost = (data['storedEnergyBasicReadyCostRate'] / 100) * (equipmentCost + otherFeesCost)
      let staticInvestmentPerUnit = equipmentCost + otherFeesCost + basicReserveCost

      // 设备及建安工程费占静态单位投资比例
      let equipmentCostPercent = equipmentCost / staticInvestmentPerUnit
      // 其他费用示占静态单位投资比例
      let otherFeesCostPercent = otherFeesCost / staticInvestmentPerUnit
      // 基本预备费占静态单位投资比例
      let basicReserveCostPercent = basicReserveCost / staticInvestmentPerUnit

      switch (type) {
        // 单位静态投资 =（一）设备及建安工程费+（二）其他费用+（三）基本预备费
        case '_storedStaticInvestmentPerUnit': {
          data['_storedStaticInvestmentPerUnit'] = (equipmentCost + otherFeesCost + basicReserveCost).toFixed(2)
          return (
            <div
              style={{
                color: '#262E3F',
                fontSize: '14px',
              }}
            >
              {data['_storedStaticInvestmentPerUnit']}
              <span
                style={{
                  color: '#767F95',
                }}
              >
                (100%)
              </span>
            </div>
          )
        }
        // 设备及建安工程费
        case '_storedEquipmentConstructionCost': {
          data['_storedEquipmentConstructionCost'] = equipmentCost.toFixed(2)
          return (
            <div
              style={{
                color: '#262E3F',
                fontSize: '14px',
              }}
            >
              {data['_storedEquipmentConstructionCost']}
              <span
                style={{
                  color: '#767F95',
                }}
              >
                ({Number(equipmentCostPercent * 100).toFixed(2)}%)
              </span>
            </div>
          )
        }
        // 其他费用
        case '_storedOtherFees': {
          data['_storedOtherFees'] = otherFeesCost.toFixed(2)
          return (
            <div
              style={{
                color: '#262E3F',
                fontSize: '14px',
              }}
            >
              {data['_storedOtherFees']}
              <span
                style={{
                  color: '#767F95',
                }}
              >
                ({Number(otherFeesCostPercent * 100).toFixed(2)}%)
              </span>
            </div>
          )
        }
        // 基本预备费
        case '_storedBasicReserve': {
          data['_storedBasicReserve'] = basicReserveCost.toFixed(2)
          return (
            <div
              style={{
                color: '#262E3F',
                fontSize: '14px',
              }}
            >
              {data['_storedBasicReserve']}
              <span
                style={{
                  color: '#767F95',
                }}
              >
                ({Number(basicReserveCostPercent * 100).toFixed(2)}%)
              </span>
            </div>
          )
        }
        default:
          return ''
      }
    } else {
      return defaultStoredStaticObj[type]
    }
  }

  const inititalState = {
    dataSource: [],
    columns: [
      {
        title: '项目投资名称',
        colSpan: 2,
        dataIndex: 'type',
        width: 36,
        className: 'column-title primary-column',
        render: (value: any, row: any) => {
          const obj = {
            children: <div style={{ width: 12, margin: '0 auto' }}>{value}</div>,
            props: {
              rowSpan: 0,
            },
          }
          if (row.index === 0) {
            obj.props.rowSpan = row.fieldsCount
          }
          if (!row.field || row.hidden) {
            obj.children = <></>
          }
          return obj
        },
      },
      {
        title: '项目投资名称',
        colSpan: 0,
        dataIndex: 'name',
        width: 188,
        className: 'column-title',
        render: (value: any, row: any) => {
          return (
            <div>
              <span
                className="row-title"
                style={
                  row.strong === 'desc'
                    ? { color: '#767F95', textIndent: '12px', display: 'inline-block' }
                    : row.strong === 'static'
                    ? { color: '#1CB887' }
                    : {}
                }
              >
                {value}
              </span>
              &nbsp;
              <span className="unit" style={{ fontWeight: 300 }}>
                {row.unit}
              </span>
            </div>
          )
        },
      },
      {
        title: (
          <div>
            推荐项目投资
            {/* {defaultData?.isDefault && (
              <div className="absolute-top">
                <CustomIcons className="absolute-top primary-mark" type="klf-bookmark_added_filled_regular" />
              </div>
            )} */}
            {/* {readonly && (
              <Dropdown
                key={`action-0`}
                overlay={
                  <Menu className="custom-row-menu">
                    <Menu.Item
                      key={`rename${0}`}
                      onClick={() => {
                        setDefaultData((defaultData: any) => {
                          console.log(defaultData)
                          FinanceService.default3({
                            projectInvestId: defaultData.id,
                            root: {
                              setDefault: 1,
                            },
                          })
                            .then(() => {
                              init()
                            })
                            .catch(() => {})
                          return defaultData
                        })
                      }}
                    >
                      设为默认
                    </Menu.Item>
                  </Menu>
                }
                destroyPopupOnHide={true}
              >
                <Button type="link" className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
                  <EllipsisOutlined color="gray" />
                </Button>
              </Dropdown>
            )} */}
          </div>
        ),
        dataIndex: 'default',
        className: 'even',
        width: 148,
        render: (value: any, row: any) => {
          const obj = {
            children: readonly ? value : <Input min={0} disabled value={value} />,
            props: {},
          }
          if (!row.field || row.hidden) {
            obj.children = <></>
          }
          return obj
        },
      },
      {
        title: '',
        dataIndex: 'empty',
        className: 'empty',
      },
    ],
  }
  const initState = () => {
    // 每次对初始参数进行加工
    inititalState.columns[2].title = (
      <div>
        推荐项目投资
        {/* {defaultData?.isDefault && (
          <div className="absolute-top">
            <CustomIcons className="absolute-top primary-mark" type="klf-bookmark_added_filled_regular" />
          </div>
        )} */}
        {/* {readonly && (
          <Dropdown
            key={`action-0`}
            overlay={
              <Menu className="custom-row-menu">
                <Menu.Item
                  key={`rename${0}`}
                  onClick={() => {
                    setDefaultData((defaultData: any) => {
                      console.log(defaultData)
                      FinanceService.default3({
                        projectInvestId: defaultData.id,
                        root: {
                          setDefault: 1,
                        },
                      })
                        .then(() => {
                          init()
                        })
                        .catch(() => {})
                      return defaultData
                    })
                  }}
                >
                  设为默认
                </Menu.Item>
              </Menu>
            }
            destroyPopupOnHide={true}
          >
            <Button type="link" className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
              <EllipsisOutlined color="gray" />
            </Button>
          </Dropdown>
        )} */}
      </div>
    )

    return {
      ...inititalState,
    }
  }
  const [state, hookDispatch] = useReducer(BaseReducer, inititalState, initState)
  const [backupDataSource, setBackupDataSource] = useState<any>([])
  // const [verifyModalVisible, setVerifyModalVisible] = useState(false)
  const [form] = Form.useForm()

  useEffect(() => {
    colRef.current = state.columns
  })

  useEffect(() => {
    SubBreadcrumbObservable.next([
      {
        name: '设置',
        url: '',
      },
      {
        name: '参数库',
        url: '/user-center/user-params?type=fin',
      },
      {
        name: '项目投资参数',
        url: '/user-center/investment',
      },
    ])
  }, [])

  // 开启编辑功能时，显示添加列
  useEffect(() => {
    if (!readonly) {
      const cols: any[] = colRef.current?.slice() || []
      hookDispatch({
        type: 'updateState',
        payload: {
          columns: [
            ...cols,
            {
              title: ' ',
              dataIndex: 'add',
              fixed: 'right',
              width: 56,
              render: (value: any, row: any, index: any) => {
                const obj = {
                  children: (
                    <>
                      <div className="add-btn" onClick={addColumn}>
                        <div className="btn-wrap">
                          <PlusCircleFilled size={16} />
                          <div>添加</div>
                        </div>
                      </div>
                    </>
                  ),
                  props: {
                    rowSpan: 0,
                  },
                }
                if (index === 0) {
                  obj.props.rowSpan = 25
                }
                return obj
              },
            },
            {
              title: '',
              dataIndex: 'empty',
              className: 'empty',
            },
          ],
        },
      })
    }
  }, [readonly])

  // 取消时跳转回主列表
  const onCancel = () => {
    location.assign(`#/user-center/user-params?type=fin`)
  }

  // const save = async (code: any) => {
  //   if (!code) {
  //     message.error('请输入验证码')
  //     return
  //   }
  //   return saveData(code)
  // }

  const saveData = async (code?: any) => {
    const values = form.getFieldsValue()
    const data = values.data?.filter((item: any) => !!item) || []
    const cols = colRef.current?.slice()
    // 征地费用字段默认添加
    const { photovoltaicLandAcquisitionCost, storedEnergyLandAcquisitionCost } = defaultData
    const listData = data.map((item: any, index: number) => {
      return {
        ...item,
        name: item.name ? item.name : cols?.filter((col) => col.text)?.[index].text,
        photovoltaicLandAcquisitionCost,
        storedEnergyLandAcquisitionCost,
      }
    })
    setSaveLoading(true)
    await FinanceService.createOrEditBatch({
      code,
      root: listData,
    })
      .then(() => {
        message.success('保存成功')
        onCancel()
      })
      .catch(() => {})
      .finally(() => {
        setSaveLoading(false)
      })
  }
  const showWarnModal = () => {
    saveData().finally(() => {
      setConfirmLoading(false)
    })
    // Modal.confirm({
    //   title: <strong>修改删除财务参数</strong>,
    //   className: 'danger-modal',
    //   keyboard: false,
    //   autoFocusButton: null,
    //   width: 480,
    //   okType: 'primary',
    //   okText: '确定',
    //   icon: <ExclamationCircleFilled style={{ color: '#f97942' }} />,
    //   onOk: async () => {
    //     saveData()
    //   },
    //   okButtonProps: {
    //     danger: true,
    //     style: { background: '#f97942' },
    //   },
    //   cancelButtonProps: {
    //     danger: true,
    //   },
    // })
  }

  // const getWarnToast = (ids: any) => {
  //   return FinanceService.warnToast({
  //     type: 'project_invest_id',
  //     ids: ids.join(','),
  //   })
  //     .then((res) => {
  //       return Promise.resolve(res.data)
  //     })
  //     .catch(() => {})
  // }

  // 获取修改与删除的数据
  const validEditAndDeleteData = () => {
    const values = form.getFieldsValue()
    const dataList = values.data?.filter((item: any) => !!item) || []
    const cols = colRef.current?.slice()
    const listData = dataList.map((item: any, index: number) => {
      return {
        ...item,
        name: item.name ? item.name : cols?.filter((col) => col.text)?.[index].text,
      }
    })
    let delIds: any = []
    let editIds: any = []
    let addData: any = []
    backupDataSource?.forEach?.((item: any) => {
      // 判断是否被删除
      if (!listData?.map((colItem: any) => colItem.projectInvestId)?.includes(item.projectInvestId)) {
        delIds.push({
          name: item.name,
          id: item.projectInvestId,
          isDefault: item.isDefault,
        })
        return
      }
      let findItem = listData.find((colItem: any) => colItem.projectInvestId === item.projectInvestId)
      for (let key in item) {
        // 数据与初始数据不等并且是页面上展示的数据
        if (item[key] != findItem[key] && findItem[key] !== undefined && key != 'name') {
          editIds.push({
            name: item.name,
            id: item.projectInvestId,
          })
          return
        }
        // 如果只是名字不同，直接保存
        if (item.name != findItem.name) {
          addData.push(item)
        }
      }
    })
    listData.forEach((item: any) => {
      if (!item.projectInvestId) {
        addData.push(item)
      }
    })

    // 如果没有编辑和删除数据
    if (editIds.length === 0 && delIds.length === 0) {
      // 如果没有添加数据 直接返回
      if (addData.length === 0) {
        onCancel()
        return
      } else {
        // 如果添加了数据  调用保存接口
        setConfirmLoading(true)
        return saveData().finally(() => {
          setConfirmLoading(false)
        })
      }
    }

    showWarnModal()

    // Promise.all([
    //   editIds.length && getWarnToast(editIds.map((o: any) => o.id)),
    //   delIds.length && getWarnToast(delIds.map((o: any) => o.id)),
    // ])
    //   .then(() => {
    //     showWarnModal()
    //   })
    //   .finally(() => {
    //     setConfirmLoading(false)
    //   })
  }

  // 保存数据
  const submit = () => {
    form.validateFields().then(() => {
      setConfirmLoading(true)
      const values = form.getFieldsValue()
      const data = values.data?.filter((item: any) => !!item) || []
      const cols = colRef.current?.slice()
      const listData = data.map((item: any, index: number) => {
        return {
          ...item,
          name: item.name ? item.name : cols?.filter((col) => col.text)?.[index].text,
        }
      })
      let err = []
      // 验证是否重名
      listData.forEach((item: any) => {
        if (listData.filter((data: any) => data.name === item.name).length > 1) {
          err.push('')
        }
      })
      if (err.length) {
        paramsStoreShowError('项目投资名称存在重复')
        setConfirmLoading(false)
        return
      }
      validEditAndDeleteData()
    })
  }

  const [renameForm] = Form.useForm()

  // const onNameKeyDown = (e: any, record: any, index: number) => {
  //   if (e.code === 'Enter' && e.target.value?.trim()) {
  //     const title = e.target.value?.trim()
  //     const cols = colRef.current?.slice()
  //     const current = cols?.[index + 3]
  //     if (!title) {
  //       return
  //     }
  //     current.title = (
  //       <div className={styles.paramsColTitle}>
  //         <Tooltip title={title}>
  //           <div className="col-title-content">{title}</div>
  //         </Tooltip>
  //         <div className="col-title-ell">
  //           {
  //             <Dropdown
  //               key={`action-${cols?.length || 0}`}
  //               overlay={<ActionMenu id={record.id} index={index} item={record} />}
  //               destroyPopupOnHide={true}
  //             >
  //               <Button type="link" className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
  //                 <EllipsisOutlined color="gray" />
  //               </Button>
  //             </Dropdown>
  //           }
  //         </div>
  //       </div>
  //     )
  //     current.text = `${title}`
  //
  //     cols?.splice(index + 3, 1, {
  //       ...current,
  //     })
  //     hookDispatch({
  //       type: 'updateState',
  //       payload: {
  //         columns: [...(cols as [])],
  //       },
  //     })
  //   }
  // }

  const onNameBlur = (e: any, record: any, index: number, isAdd = false) => {
    const title = (typeof e === 'string' ? e : e.target.value)?.trim()
    const cols = colRef.current?.slice()
    let current: any
    if (isAdd) {
      current = cols.find((o) => o.index === index)
    } else {
      current = cols?.[index + 3]
    }

    if (!title) {
      return
    }
    current.title = (
      <div className={styles.paramsColTitle}>
        <Tooltip title={title}>
          <div className="col-title-content">{title}</div>
        </Tooltip>
        <div className="col-title-ell">
          {
            <Dropdown
              key={`action-${cols?.length || 0}`}
              overlay={<ActionMenu id={record.id} index={index} item={record} />}
              destroyPopupOnHide={true}
            >
              <Button type="link" className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
                <EllipsisOutlined color="gray" />
              </Button>
            </Dropdown>
          }
        </div>
      </div>
    )
    current.text = `${title}`

    if (!isAdd) {
      cols?.splice(index + 3, 1, {
        ...current,
      })
    }
    hookDispatch({
      type: 'updateState',
      payload: {
        columns: [...(cols as [])],
      },
    })
  }

  // 重命名与删除
  const ActionMenu = (props: any) => {
    const { item, index } = props
    return (
      <Menu className="custom-row-menu">
        <Menu.Item
          key={`rename${index}`}
          onClick={() => {
            renameForm.setFieldsValue({
              rename: item.name,
            })
            Modal.destroyAll()
            Modal.confirm({
              keyboard: false,
              autoFocusButton: null,
              title: '重命名',
              icon: <CustomIcons type="klf-alarm1" />,
              content: (
                <Form form={renameForm}>
                  <Form.Item
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                    }}
                    name="rename"
                    label="请输入项目投资参数名称"
                    rules={[
                      {
                        required: true,
                        message: '请输入项目投资参数名称',
                      },
                      {
                        pattern: RESOURCE_NAME_EXP,
                        message: '1-50个字符,中文、英文、_、数字和-组成',
                      },
                    ]}
                  >
                    <Input placeholder="请输入"></Input>
                  </Form.Item>
                </Form>
              ),
              width: 500,
              okType: 'primary',
              okText: '确定',
              cancelText: '取消',
              onOk: () => {
                return new Promise((resolve, reject) => {
                  const { rename } = renameForm.getFieldsValue()
                  renameForm
                    .validateFields()
                    .then(() => {
                      item.name = rename
                      onNameBlur(rename, item, index, !!item.index)
                      resolve({})
                    })
                    .catch((e) => {
                      reject(e)
                    })
                })
              },
            })
          }}
        >
          重命名
        </Menu.Item>
        <Menu.Item
          key={`copy${index}`}
          onClick={() => {
            const cols = colRef.current?.slice()
            if ((cols?.length || 0) >= 11) {
              paramsStoreShowError('项目投资参数最多6个')
              return
            }
            const colIndex = cols?.findIndex((col) => col.name === item.name) as number
            const current = cols?.[colIndex]

            cols?.splice(cols.length - 2, 0, {
              ...generateColumn({
                readonly: false,
                data: {
                  ...current,
                  name: `${generateCopyName(current.text || current.name)}`,
                  text: `${generateCopyName(current.text || current.name)}`,
                  projectInvestId: undefined,
                  className: cols.length % 2 === 0 ? 'even' : 'odd',
                },
                index: cols.length,
              }),
            })

            hookDispatch({
              type: 'updateState',
              payload: {
                columns: [...(cols as [])],
              },
            })
          }}
        >
          拷贝
        </Menu.Item>
        <Menu.Item
          key={`del${index}`}
          onClick={() => {
            const cols = colRef.current?.slice()
            const colIndex = cols?.findIndex((col) => col.name === item.name) as number
            cols?.splice(colIndex, 1)
            const tempIndex = tempDataSource.findIndex((col: any) => col.name === item.name) as number
            tempDataSource.splice(tempIndex, 1)
            setTempDefaultSource([...tempDataSource])
            hookDispatch({
              type: 'updateState',
              payload: {
                columns: [...(cols as [])],
              },
            })
          }}
        >
          删除
        </Menu.Item>
      </Menu>
    )
  }

  const generateColumn = ({
    readonly,
    data = {},
    index = 0,
    addFlag = false,
  }: {
    readonly: boolean
    data?: any
    index: number
    id?: number
    addFlag?: boolean
  }) => {
    const rowData = {
      projectInvestId: data?.projectInvestId,
    }

    const cols = colRef.current?.slice() || []
    const name = data.name || `项目投资${(cols?.length || 0) - 3}`
    return {
      ...data,
      text: name,
      className: index % 2 === 0 ? 'odd' : 'even',
      width: 140,
      title: (
        <>
          <div className={styles.paramsColTitle}>
            <Tooltip title={name}>
              <div className="col-title-content">
                {name}
                {/* {data.isDefault && (
                  <div className="absolute-top">
                    <CustomIcons className="absolute-top primary-mark" type="klf-bookmark_added_filled_regular" />
                  </div>
                )} */}
              </div>
            </Tooltip>
            {/* {readonly && (
              <div className="col-title-ell">
                <Dropdown
                  key={`action-${cols?.length || 0}`}
                  overlay={
                    <Menu className="custom-row-menu">
                      <Menu.Item
                        disabled={data.isDefault}
                        key={`rename${index}`}
                        onClick={() => {
                          FinanceService.default3({
                            projectInvestId: data.projectInvestId,
                            root: {
                              setDefault: 1,
                            },
                          })
                            .then(() => {
                              init()
                            })
                            .catch(() => {})
                        }}
                      >
                        设为默认
                      </Menu.Item>
                    </Menu>
                  }
                  destroyPopupOnHide={true}
                >
                  <Button type="link" className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
                    <EllipsisOutlined color="gray" />
                  </Button>
                </Dropdown>
              </div>
            )} */}
            {!readonly && (
              <div className="col-title-ell">
                <Dropdown
                  key={`action-${cols?.length || 0}`}
                  overlay={<ActionMenu id={data?.projectInvestId} index={index} item={data} />}
                  destroyPopupOnHide={true}
                >
                  <Button type="link" className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
                    <EllipsisOutlined color="gray" />
                  </Button>
                </Dropdown>
              </div>
            )}
          </div>
        </>
      ),
      dataIndex: `add-${cols.length - 1}`,
      render: (value: any, row: any) => {
        const obj = {
          children: renderColumnValue({
            readonly,
            row,
            index,
            data,
            rowData,
            addFlag,
          }),
          props: {},
        }
        if (!row.field) {
          obj.children = <></>
        }
        return obj
      },
    }
  }

  const generateName = () => {
    const cols = colRef.current?.slice() || []
    let name = '项目投资7'
    for (let i = 7; i > 0; i--) {
      if (!cols.map((col) => col.name).includes(`项目投资${i}`)) {
        name = `项目投资${i}`
      }
    }
    return name
  }

  const generateCopyName = (name: string) => {
    const cols = colRef.current?.slice() || []
    let copyName = `${name}_拷贝7`
    for (let i = 7; i > 0; i--) {
      if (!cols.map((col) => col.name).includes(`${name}_拷贝${i}`)) {
        copyName = `${name}_拷贝${i}`
      }
    }
    return copyName
  }

  // 点击添加添加列
  const addColumn = () => {
    const cols = colRef.current?.slice() || []
    if (cols.length >= 11) {
      paramsStoreShowError('项目投资参数最多6个')
      return
    }
    let name = generateName()
    updateColumns(
      generateColumn({
        readonly: false,
        data: {
          name,
          index: colRef.current?.length as number,
          ...defaultData,
        },
        index: colRef.current?.length as number,
        addFlag: true,
      }),
      false
    )
    let curObj = { ...defaultData, name }
    tempDataSource.push(curObj)
    setTempDefaultSource([...tempDataSource])
  }

  // 插入列数据
  const updateColumns = (datas: any, readonly: boolean = true) => {
    const cols = colRef.current?.slice() || []
    const data = datas instanceof Array ? datas : [datas]
    // 最后一列添加
    cols?.splice(readonly ? cols.length - 1 : cols.length - 2, 0, ...data)
    hookDispatch({
      type: 'updateState',
      payload: {
        columns: [...(cols as [])],
      },
    })
  }

  const generateDataSource = (data: any) => {
    const dataList: any[] = []
    projectTypeList.forEach((type: any) => {
      type.rows.forEach((row: any, index: number) => {
        dataList.push({
          ...row,
          index: index,
          fieldsCount: type.rows.length,
          default: data[row.field] || 0,
          type: type.name,
          strong: row.type,
          projectInvestId: data.projectInvestId,
        })
      })
    })
    return dataList
  }

  const init = () => {
    setLoading(true)
    // 获取账号下所有数据
    FinanceService.list2()
      .then((res) => {
        const accountData = res.data
        setBackupDataSource(res.data)
        // 获取默认数据
        setTempDefaultSource(JSON.parse(JSON.stringify(res.data)))
        // 获取账号下默认参数
        FinanceService.getDefault2()
          .then((res) => {
            inititalState.columns[2].render = (value: any, row: any) => {
              row.type === '光伏' ? defaultComputedCount(res.data) : defaultStroedComputedCount(res.data)

              const obj = {
                children: [
                  '_photovoltaicStaticInvestmentPerUnit',
                  '_photovoltaicEquipmentConstructionCost',
                  '_photovoltaicOtherFees',
                  '_photovoltaicBasicReserve',
                  '_storedStaticInvestmentPerUnit',
                  '_storedEquipmentConstructionCost',
                  '_storedOtherFees',
                  '_storedBasicReserve',
                ].includes(row.field) ? (
                  row.type === '光伏' ? (
                    projectComputedCount({ data: res.data, type: row.field })
                  ) : (
                    storedComputedCount({
                      data: res.data,
                      type: row.field,
                    })
                  )
                ) : readonly ? (
                  value
                ) : (
                  <Input min={0} disabled value={value} />
                ),
                props: {},
              }
              if (!row.field || row.hidden) {
                obj.children = <></>
              }
              return obj
            }
            hookDispatch({
              type: 'updateState',
              payload: {
                columns: [...(inititalState.columns as [])],
              },
            })
            hookDispatch({
              type: 'updateState',
              payload: {
                dataSource: generateDataSource(res.data),
              },
            })
            let basedata: any[] = []
            accountData.forEach((item: any, index: number) => {
              basedata.push(generateColumn({ readonly, data: item, index }))
            })

            if (colRef.current) {
              for (let i = colRef.current.length - 1; i >= 0; i--) {
                if (colRef.current[i].projectInvestId) {
                  colRef.current.splice(i, 1)
                }
              }
            }

            // let _data = setDefaultData(res.data)
            setDefaultData(res.data)
            updateColumns(basedata)
          })
          .catch(() => {})
          .finally(() => {
            setLoading(false)
          })
          .then(() => {})
      })
      .catch(() => {})
      .finally(() => {
        setLoading(false)
      })
  }

  const renderComputedDom = ({ type, data }: { type: string; data: any }) => {
    switch (type) {
      // 光伏 单位静态投资
      case '_photovoltaicStaticInvestmentPerUnit': {
        return (
          <div
            style={{
              color: '#262E3F',
              fontSize: '14px',
            }}
          >
            {data['_photovoltaicStaticInvestmentPerUnit'].toFixed(2)}
            <span
              style={{
                color: '#767F95',
              }}
            >
              (100%)
            </span>
          </div>
        )
      }
      // 光伏 设备及建安工程费
      case '_photovoltaicEquipmentConstructionCost': {
        return (
          <div
            style={{
              color: '#262E3F',
              fontSize: '14px',
            }}
          >
            {data['_photovoltaicEquipmentConstructionCost'].toFixed(2)}
            <span
              style={{
                color: '#767F95',
              }}
            >
              ({Number(data['_photovoltaicEquipmentConstructionCostPercent'] * 100).toFixed(2)}%)
            </span>
          </div>
        )
      }
      // 光伏 其他费用
      case '_photovoltaicOtherFees': {
        return (
          <div
            style={{
              color: '#262E3F',
              fontSize: '14px',
            }}
          >
            {data['_photovoltaicOtherFees'].toFixed(2)}
            <span
              style={{
                color: '#767F95',
              }}
            >
              ({Number(data['_photovoltaicOtherFeesPercent'] * 100).toFixed(2)}%)
            </span>
          </div>
        )
      }
      // 光伏 基本预备费
      case '_photovoltaicBasicReserve': {
        return (
          <div
            style={{
              color: '#262E3F',
              fontSize: '14px',
            }}
          >
            {data['_photovoltaicBasicReserve'].toFixed(2)}
            <span
              style={{
                color: '#767F95',
              }}
            >
              ({Number(data['_photovoltaicBasicReservePercent'] * 100).toFixed(2)}%)
            </span>
          </div>
        )
      }
      // 储能 单位静态投资
      case '_storedStaticInvestmentPerUnit': {
        return (
          <div
            style={{
              color: '#262E3F',
              fontSize: '14px',
            }}
          >
            {data['_storedStaticInvestmentPerUnit'].toFixed(2)}
            <span
              style={{
                color: '#767F95',
              }}
            >
              (100%)
            </span>
          </div>
        )
      }
      // 储能 设备及建安工程费
      case '_storedEquipmentConstructionCost': {
        return (
          <div
            style={{
              color: '#262E3F',
              fontSize: '14px',
            }}
          >
            {data['_storedEquipmentConstructionCost'].toFixed(2)}
            <span
              style={{
                color: '#767F95',
              }}
            >
              ({Number(data['_storedEquipmentConstructionCostPercent'] * 100).toFixed(2)}%)
            </span>
          </div>
        )
      }
      // 储能 其他费用
      case '_storedOtherFees': {
        return (
          <div
            style={{
              color: '#262E3F',
              fontSize: '14px',
            }}
          >
            {data['_storedOtherFees'].toFixed(2)}
            <span
              style={{
                color: '#767F95',
              }}
            >
              ({Number(data['_storedOtherFeesPercent'] * 100).toFixed(2)}%)
            </span>
          </div>
        )
      }
      // 基本预备费
      case '_storedBasicReserve': {
        return (
          <div
            style={{
              color: '#262E3F',
              fontSize: '14px',
            }}
          >
            {data['_storedBasicReserve'].toFixed(2)}
            <span
              style={{
                color: '#767F95',
              }}
            >
              ({Number(data['_storedBasicReservePercent'] * 100).toFixed(2)}%)
            </span>
          </div>
        )
      }
      default:
        return ''
    }
  }

  const openEditStatus = () => {
    setReadonly(false)
    const cols = colRef.current?.slice(3, colRef.current.length - 1)
    const colList = cols?.map((col, index) => {
      return generateColumn({ readonly: false, data: col, index })
    })
    const defaultCol = {
      title: <div>推荐项目投资</div>,
      dataIndex: 'default',
      className: 'even',
      width: 148,
      render: (value: any, row: any) => {
        const obj = {
          children: [
            '_photovoltaicStaticInvestmentPerUnit',
            '_photovoltaicEquipmentConstructionCost',
            '_photovoltaicOtherFees',
            '_photovoltaicBasicReserve',
            '_storedStaticInvestmentPerUnit',
            '_storedEquipmentConstructionCost',
            '_storedOtherFees',
            '_storedBasicReserve',
          ].includes(row.field) ? (
            row.type === '光伏' ? (
              projectComputedCount({ data: defaultData, type: row.field })
            ) : (
              storedComputedCount({
                data: defaultData,
                type: row.field,
              })
            )
          ) : (
            <Input disabled value={value} />
          ),
          props: {},
        }
        if (!row.field || row.hidden) {
          obj.children = <></>
        }
        return obj
      },
    }

    hookDispatch({
      type: 'updateState',
      payload: {
        columns: [...(colRef.current?.slice(0, 2) as []), defaultCol, ...(colList as [])],
      },
    })
  }

  const formatDefaultData = ({ data }: { data: any; name?: string }) => {
    let {
      _photovoltaicEquipmentConstructionCost,
      _photovoltaicEquipmentConstructionCostPercent = 0,
      _photovoltaicStaticInvestmentPerUnit,
      _photovoltaicOtherFees,
      _photovoltaicOtherFeesPercent = 0,
      _photovoltaicBasicReserve,
      _photovoltaicBasicReservePercent,
      photovoltaicModuleUnitPrice,
      photovoltaicInverterUnitPrice,
      photovoltaicOtherFacilityPrice,
      photovoltaicInstallCostPrice,
      photovoltaicArchitectureProjectCost,
      photovoltaicProjectManagementCostProportion,
      photovoltaicYieldPlanCostProportion,
      photovoltaicProspectDesignCostProportion,
      photovoltaicBasicReadyCostRate,

      _storedEquipmentConstructionCost,
      storedEnergyCellModuleUnitPrice,
      storedEnergyConverterUnitPrice,
      storedEnergyOtherFacilityPrice,
      storedEnergyInstallCostPrice,
      storedEnergyArchitectureProjectCost,
      _storedEquipmentConstructionCostPercent,
      _storedStaticInvestmentPerUnit,
      _storedOtherFees,
      _storedOtherFeesPercent,
      _storedBasicReserve,
      _storedBasicReservePercent,
      storedEnergyBasicReadyCostRate,
      storedEnergyProjectManagementCostProportion,
      storedEnergyYieldPlanCostProportion,
      storedEnergyProspectDesignCostProportion,
    } = data

    // 设备及建安工程费
    _photovoltaicEquipmentConstructionCost =
      photovoltaicModuleUnitPrice +
      photovoltaicInverterUnitPrice +
      photovoltaicOtherFacilityPrice +
      photovoltaicInstallCostPrice +
      photovoltaicArchitectureProjectCost
    // 其他费用
    _photovoltaicOtherFees =
      (photovoltaicProjectManagementCostProportion / 100 +
        photovoltaicYieldPlanCostProportion / 100 +
        photovoltaicProspectDesignCostProportion / 100) *
      _photovoltaicEquipmentConstructionCost
    // 基本预备费
    _photovoltaicBasicReserve =
      (photovoltaicBasicReadyCostRate / 100) * (_photovoltaicEquipmentConstructionCost + _photovoltaicOtherFees)

    // 单位静态投资
    _photovoltaicStaticInvestmentPerUnit =
      _photovoltaicEquipmentConstructionCost + _photovoltaicOtherFees + _photovoltaicBasicReserve

    // ====

    // 设备建安工程费
    _storedEquipmentConstructionCost =
      storedEnergyCellModuleUnitPrice +
      storedEnergyConverterUnitPrice +
      storedEnergyOtherFacilityPrice +
      storedEnergyInstallCostPrice +
      storedEnergyArchitectureProjectCost

    // 其他费用
    _storedOtherFees =
      (storedEnergyProjectManagementCostProportion / 100 +
        storedEnergyYieldPlanCostProportion / 100 +
        storedEnergyProspectDesignCostProportion / 100) *
      _storedEquipmentConstructionCost

    // 基本预备费
    _storedBasicReserve = (storedEnergyBasicReadyCostRate / 100) * (_storedEquipmentConstructionCost + _storedOtherFees)

    // 单位静态投资
    _storedStaticInvestmentPerUnit = _storedEquipmentConstructionCost + _storedOtherFees + _storedBasicReserve

    // 其他费用百分比
    _storedOtherFeesPercent = _storedOtherFees / _storedStaticInvestmentPerUnit

    // 基本预备费百分比
    _storedBasicReservePercent = _storedBasicReserve / _storedStaticInvestmentPerUnit

    // 设备建安百分比
    _storedEquipmentConstructionCostPercent = _storedEquipmentConstructionCost / _photovoltaicStaticInvestmentPerUnit

    // 设备及建安工程费 百分比
    _photovoltaicEquipmentConstructionCostPercent =
      _photovoltaicEquipmentConstructionCost / _photovoltaicStaticInvestmentPerUnit

    // 基本预备费百分比
    _photovoltaicBasicReservePercent = _photovoltaicBasicReserve / _photovoltaicStaticInvestmentPerUnit

    // 其他费用 百分比
    _photovoltaicOtherFeesPercent = _photovoltaicOtherFees / _photovoltaicStaticInvestmentPerUnit

    return {
      ...data,
      _photovoltaicEquipmentConstructionCost,
      _photovoltaicOtherFees,
      _photovoltaicStaticInvestmentPerUnit,
      _photovoltaicBasicReserve,
      _storedOtherFees,
      _storedBasicReserve,
      _storedStaticInvestmentPerUnit,
      _storedOtherFeesPercent,
      _storedBasicReservePercent,
      _photovoltaicEquipmentConstructionCostPercent,
      _photovoltaicBasicReservePercent,
      _photovoltaicOtherFeesPercent,
      _storedEquipmentConstructionCost,
      _storedEquipmentConstructionCostPercent,
    }
  }

  const changeNumberValue = ({ value, name, rowName }: { value: any; name: string; rowName: string }) => {
    let tempObj = tempDataSource.find((item: any) => item.name === rowName)
    tempObj[name] = value
    tempObj = formatDefaultData({ data: tempObj })
    setTempDefaultSource([...tempDataSource])
    hookDispatch({
      type: 'updateState',
      payload: {
        dataSource: JSON.parse(JSON.stringify(state.dataSource)),
      },
    })
  }

  // （光伏/储能）单位静态投资，（光伏/储能）设备及建安工程费，（光伏/储能）其他费用，（光伏/储能）基本预备费
  const renderColumnValue = ({
    readonly,
    row,
    index,
    data,
    rowData,
  }: // addFlag = false,
  {
    readonly: boolean
    row: any
    index: number
    data: any
    rowData: any
    addFlag?: boolean
  }) => {
    if (
      [
        '_photovoltaicStaticInvestmentPerUnit',
        '_photovoltaicEquipmentConstructionCost',
        '_photovoltaicOtherFees',
        '_photovoltaicBasicReserve',
        '_storedStaticInvestmentPerUnit',
        '_storedEquipmentConstructionCost',
        '_storedOtherFees',
        '_storedBasicReserve',
      ].includes(row.field)
    ) {
      const { name } = data
      let curData = tempDataSource.find((ele: any) => ele.name === name)
      let tempData = formatDefaultData({ data: curData ? curData : data })
      return renderComputedDom({ type: row.field, data: tempData })
    }

    if (readonly) {
      if (!row.hidden && (data[row.field] || data[row.field] === 0)) {
        return data[row.field]
      } else {
        return data.default
      }
    } else {
      return (
        <Form.Item
          hidden={row.hidden}
          name={['data', index, row.field]}
          initialValue={
            row.hidden
              ? rowData.projectInvestId
              : data[row.field] || data[row.field] == 0
              ? data[row.field]
              : row.default
          }
          rules={
            row.hidden
              ? []
              : [
                  {
                    required: true,
                    message: '',
                  },
                ]
          }
        >
          <InputNumber
            min={row.min ? row.min : 0}
            max={row.max ? row.max : row.unit === '%' ? 100 : undefined}
            formatter={(val) => {
              const arr = new Array(row.precision)
              arr.fill('\\d')
              const pat = `^(\\-)*(\\d+)\\.(${row.precision ? arr.join('') : ''}).*$`
              return row.valueType === 'int'
                ? '' + Number.parseInt(`${val}`.replace(new RegExp(pat), '$1$2.$3'))
                : `${val}`.replace(new RegExp(pat), '$1$2.$3')
            }}
            style={{ width: '100%' }}
            onChange={(e) => {
              changeNumberValue({
                value: e,
                name: row.field,
                rowName: data.name,
              })
            }}
          />
        </Form.Item>
      )
    }
  }

  // 添加后的光伏默认参数计算
  const defaultComputedCount = (data: any) => {
    // 设备及建安工程费 = 光伏模组+ 逆变器+ 其他设备+ 安装工程费+ 建筑工程费
    let equipmentCost =
      data['photovoltaicModuleUnitPrice'] +
      data['photovoltaicInverterUnitPrice'] +
      data['photovoltaicOtherFacilityPrice'] +
      data['photovoltaicInstallCostPrice'] +
      data['photovoltaicArchitectureProjectCost']

    // 其他费用 = (项目管理费+ 生产准备费+ 勘察设计费) * 设备及建安工程费
    let otherFeesCost =
      (data['photovoltaicProjectManagementCostProportion'] / 100 +
        data['photovoltaicYieldPlanCostProportion'] / 100 +
        data['photovoltaicProspectDesignCostProportion'] / 100) *
      equipmentCost

    // 基本预备费 = 基本预备费率*(设备及建安工程费 + 其他费用)
    let basicReserveCost = (data['photovoltaicBasicReadyCostRate'] / 100) * (equipmentCost + otherFeesCost)

    // 单位静态投资 =（一）设备及建安工程费+（二）其他费用+（三）基本预备费
    var staticInvestmentPerUnit = equipmentCost + otherFeesCost + basicReserveCost

    // 设备及建安工程费占静态单位投资比例
    let equipmentCostPercent = equipmentCost / staticInvestmentPerUnit
    // 其他费用示占静态单位投资比例
    let otherFeesCostPercent = otherFeesCost / staticInvestmentPerUnit
    // 基本预备费占静态单位投资比例
    let basicReserveCostPercent = basicReserveCost / staticInvestmentPerUnit

    let defaultStaticObj = {
      _photovoltaicStaticInvestmentPerUnit: (
        <div
          style={{
            color: '#262E3F',
            fontSize: '14px',
          }}
        >
          {data['_photovoltaicEquipmentConstructionCost']}
          <span
            style={{
              color: '#767F95',
            }}
          >
            ({Number(equipmentCostPercent * 100).toFixed(2)}%)
          </span>
        </div>
      ),

      _photovoltaicEquipmentConstructionCost: (
        <div
          style={{
            color: '#262E3F',
            fontSize: '14px',
          }}
        >
          {data['_photovoltaicEquipmentConstructionCost']}
          <span
            style={{
              color: '#767F95',
            }}
          >
            ({Number(equipmentCostPercent * 100).toFixed(2)}%)
          </span>
        </div>
      ),
      _photovoltaicOtherFees: (
        <div
          style={{
            color: '#262E3F',
            fontSize: '14px',
          }}
        >
          {data['_photovoltaicOtherFees']}
          <span
            style={{
              color: '#767F95',
            }}
          >
            ({Number(otherFeesCostPercent * 100).toFixed(2)}%)
          </span>
        </div>
      ),
      _photovoltaicBasicReserve: (
        <div
          style={{
            color: '#262E3F',
            fontSize: '14px',
          }}
        >
          {data['_photovoltaicBasicReserve']}
          <span
            style={{
              color: '#767F95',
            }}
          >
            ({Number(basicReserveCostPercent * 100).toFixed(2)}%)
          </span>
        </div>
      ),
    }
    setDefaultStaticObj(defaultStaticObj)
  }
  //  添加后的储能默认参数计算
  const defaultStroedComputedCount = (data: any) => {
    // 设备及建安工程费 =  = 电池模组+ 储能变流器 + 其他设备+ 安装工程费+ 建筑工程费
    let equipmentCost =
      data['storedEnergyCellModuleUnitPrice'] +
      data['storedEnergyConverterUnitPrice'] +
      data['storedEnergyOtherFacilityPrice'] +
      data['storedEnergyInstallCostPrice'] +
      data['storedEnergyArchitectureProjectCost']
    // 其他费用 = (项目管理费+ 生产准备费+ 勘察设计费) * 设备及建安工程费
    let otherFeesCost =
      (data['storedEnergyProjectManagementCostProportion'] / 100 +
        data['storedEnergyYieldPlanCostProportion'] / 100 +
        data['storedEnergyProspectDesignCostProportion'] / 100) *
      equipmentCost
    // 基本预备费 = 基本预备费率*(设备及建安工程费 + 其他费用)
    let basicReserveCost = equipmentCost + otherFeesCost
    let staticInvestmentPerUnit = equipmentCost + otherFeesCost + basicReserveCost

    // 设备及建安工程费占静态单位投资比例
    let equipmentCostPercent = equipmentCost / staticInvestmentPerUnit
    // 其他费用示占静态单位投资比例
    let otherFeesCostPercent = otherFeesCost / staticInvestmentPerUnit
    // 基本预备费占静态单位投资比例
    let basicReserveCostPercent = basicReserveCost / staticInvestmentPerUnit

    let defaultStaticObj = {
      _storedStaticInvestmentPerUnit: (
        <div
          style={{
            color: '#262E3F',
            fontSize: '14px',
          }}
        >
          {data['_storedStaticInvestmentPerUnit']}
          <span
            style={{
              color: '#767F95',
            }}
          >
            (100%)
          </span>
        </div>
      ),

      // (equipmentCost + otherFeesCost + basicReserveCost).toFixed(2),
      _storedEquipmentConstructionCost: (
        <div
          style={{
            color: '#262E3F',
            fontSize: '14px',
          }}
        >
          {data['_storedEquipmentConstructionCost']}
          <span
            style={{
              color: '#767F95',
            }}
          >
            ({Number(equipmentCostPercent * 100).toFixed(2)}%)
          </span>
        </div>
      ),
      // equipmentCost,
      _storedOtherFees: (
        <div
          style={{
            color: '#262E3F',
            fontSize: '14px',
          }}
        >
          {data['_storedOtherFees']}
          <span
            style={{
              color: '#767F95',
            }}
          >
            ({Number(otherFeesCostPercent * 100).toFixed(2)}%)
          </span>
        </div>
      ),
      _storedBasicReserve: (
        <div
          style={{
            color: '#262E3F',
            fontSize: '14px',
          }}
        >
          {data['_storedBasicReserve']}
          <span
            style={{
              color: '#767F95',
            }}
          >
            ({Number(basicReserveCostPercent * 100).toFixed(2)}%)
          </span>
        </div>
      ),
    }
    setDefaultStoredStaticObj(defaultStaticObj)
  }

  useEffect(() => {
    init()
  }, [])

  return (
    <div className={styles.projectInvestment}>
      <h2 style={{ fontWeight: 700, display: 'flex', justifyContent: 'space-between' }}>
        项目投资参数
        {readonly && (
          <Button
            className="actived"
            disabled={loading}
            onClick={() => {
              openEditStatus()
            }}
            icon={<CustomIcons type="klf-edit1" style={{ color: 'rgba(28, 184, 135, 1)' }} />}
          >
            编辑
          </Button>
        )}
      </h2>
      <Spin spinning={loading}>
        <Form form={form}>
          {!loading && (
            <Table
              dataSource={state.dataSource}
              columns={state.columns}
              pagination={false}
              scroll={{ x: 'max-content', y: readonly ? 'calc(100vh - 300px)' : 'calc(100vh - 360px)' }}
            />
          )}
          {!readonly && (
            <div className="footer-btns">
              <Space>
                <Button type="primary" loading={confirmLoading} onClick={submit}>
                  确定
                </Button>
                <Button className="actived" onClick={onCancel}>
                  取消
                </Button>
              </Space>
            </div>
          )}
        </Form>
      </Spin>
      {/* {verifyModalVisible && (
        <CodeModal
          visible={verifyModalVisible}
          onSuccess={save}
          saveLoading={saveLoading}
          onCancel={() => {
            setVerifyModalVisible(false)
          }}
        />
      )} */}
    </div>
  )
}

export default ProjectInvestment
