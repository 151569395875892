import React from 'react'
import '../../index.less'
import { ProjectServiceHistory } from '../../../../apis/project-service-history'
import { Outlet, useParams } from 'react-router-dom'
import { KLFButtons } from '../../../../components/klf-btn'
import { CustomIcons, Global } from '../../../../global'
import { InfoList } from '../index'
import './index.less'
import { Subject } from 'rxjs'

const keys = ['catalogue', 'normFireCoal', 'detail', 'add']
const KLFBtnConfig: any[] = [
  {
    title: '目录电价',
    key: keys[0],
    finished: false,
  },
  {
    title: '标杆燃煤电价',
    key: keys[1],
    finished: true,
  },
]
const customBtnObj = [
  {
    title: '自定义电价',
    key: keys[3],
    finished: false,
    iconUrl: 'klf-add2',
    btnType: 'dashed',
    maxWidth: '130px',
  },
]

export const ElectricityObservable = new Subject()

export const Electricity = () => {
  const [activatedPage, setActivatedPage] = React.useState(keys.find((o) => location.href.includes(o)) || keys[0])
  const params = useParams()

  const [klfBtnConfig, setKlfBtnConfig] = React.useState<any[]>([])
  const [more, setMore] = React.useState<boolean>(false)

  const auth = Global.projectAuth || {}
  const hasAuth = !!auth.electricity_price_scheme

  React.useEffect(() => {
    const config = [...klfBtnConfig]
    const customIndex = config.findIndex((item) => item.key === keys[3])
    if (customIndex > -1) {
      config[customIndex].disabled = !hasAuth
    }
    setKlfBtnConfig(config)
  }, [hasAuth])
  
  React.useEffect(() => {
    setKlfBtnConfig([])
    getCustomNameIdList()
    const obs = ElectricityObservable.subscribe(() => {
      getCustomNameIdList()
    })
    return () => {
      obs.unsubscribe()
    }
  }, [])

  React.useEffect(() => {
    const subscription = InfoList.subscribe((o: any) => {
      KLFBtnConfig[0].finished = o.electricity.finished
    })
    return () => {
      subscription.unsubscribe()
    }
  }, [])

  /**
   * 查询自定义电list名称和id
   */
  const getCustomNameIdList = () => {
    ProjectServiceHistory.queryCustomNameIdList({ projectId: params.id! })
      .then((resp) => {
        const btnData: any[] = []
        resp.data.map((item: any) => {
          btnData.push({
            title: item.name,
            key: `${keys[2]}/${item.id}`,
            finished: true,
            id: item.id,
          })
        })
        const config = [...KLFBtnConfig, ...btnData]
        const custom: any = { ...customBtnObj[0] }
        custom.disabled = !hasAuth
        config.push(custom)
        setKlfBtnConfig(config)
      })
      .catch(() => {
        const config = [...KLFBtnConfig]
        const custom: any = { ...customBtnObj[0] }
        custom.disabled = !hasAuth
        config.push(custom)
        setKlfBtnConfig(config)
      })
  }

  return (
    <div style={{ height: '100%', overflowY: 'auto' }}>
      {(KLFBtnConfig[0].finished || hasAuth) && (
        <div
          className="button-group"
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'flex-start',
            height: more ? 'auto' : '48px',
            overflow: more ? 'auto' : 'hidden',
            transition: 'all .3s,visibility 0s',
          }}
        >
          <KLFButtons
            config={klfBtnConfig}
            activatedKey={activatedPage}
            callback={(key) => {
              setActivatedPage(key)
            }}
          />
          <span
            onClick={() => {
              setMore(!more)
            }}
            style={{
              cursor: 'pointer',
              transform: more ? 'rotate(0deg)' : 'rotate(90deg)',
              transition: 'transform .24s',
            }}
          >
            <CustomIcons type="klf-xiangxia" />
          </span>
        </div>
      )}

      <div
        style={{
          minHeight: 'calc(100vh - 365px)',
          // overflowY: 'auto',
        }}
      >
        <Outlet />
      </div>
    </div>
  )
}
