import { useEffect, useState } from 'react'
import { Row, Col, InputNumber, message } from 'antd'
import { CustomIcons } from '../../../../global'
import { FinanceApi } from '../../../../services'
import { EditInput } from './../index'

const projectTypeList = [
  {
    name: '固定成本',
    code: 'gdcb',
    rows: [
      {
        name: '生产成本',
        unit: '',
        isTitle: true,
        type: 'primary',
        hasSpace: true,
      },
      {
        name: '残值率',
        unit: '',
        isTitle: true,
        type: 'primary',
      },
      {
        name: '光伏',
        unit: '%',
        field: 'photovoltaicSalvageRate',
        type: 'desc',
        precision: 1,
      },
      {
        name: '储能',
        unit: '%',
        field: 'storedEnergySalvageRate',
        type: 'desc',
        precision: 1,
      },
      {
        name: '储能PCS',
        unit: '%',
        field: 'storedEnergyPcsSalvageRate',
        type: 'desc',
        precision: 1,
      },
      {
        name: '储能BOP',
        unit: '%',
        field: 'storedEnergyBopSalvageRate',
        type: 'desc',
        precision: 1,
      },

      {
        name: '折旧年限',
        unit: '',
        type: 'primary',
        isTitle: true,
      },
      {
        name: '光伏',
        unit: '年',
        field: 'photovoltaicDepreciationYear',
        type: 'desc',
        min: 1,
        max: 20,
      },
      {
        name: '储能',
        unit: '年',
        field: 'storedEnergyDepreciationYear',
        type: 'desc',
        min: 1,
        max: 15,
      },
      {
        name: '储能PCS',
        unit: '年',
        field: 'storedEnergyPcsDepreciationYear',
        type: 'desc',
        min: 1,
        max: 15,
      },
      {
        name: '储能BOP',
        unit: '年',
        field: 'storedEnergyBopDepreciationYear',
        type: 'desc',
        min: 1,
        max: 15,
      },
      {
        name: '摊销年限',
        unit: '',
        type: 'primary',
        isTitle: true,
      },
      {
        name: '光伏',
        unit: '年',
        field: 'photovoltaicAmortizeYear',
        type: 'desc',
        min: 1,
        max: 5,
      },
      {
        name: '储能',
        unit: '年',
        field: 'storedEnergyAmortizeYear',
        type: 'desc',
        min: 1,
        max: 5,
      },
      {
        name: '储能PCS',
        unit: '年',
        field: 'storedEnergyPcsAmortizeYear',
        type: 'desc',
        min: 1,
        max: 5,
      },
      {
        name: '储能BOP',
        unit: '年',
        field: 'storedEnergyBopAmortizeYear',
        type: 'desc',
        min: 1,
        max: 5,
      },
      {
        name: '薪酬及福利',
        unit: '',
        type: 'primary',
        isTitle: true,
      },
      {
        name: '光伏',
        unit: '元/kWp',
        field: 'photovoltaicCompensationUnitPrice',
        type: 'desc',
        precision: 2,
        min: 0,
        max: 999999,
      },
      {
        name: '储能',
        unit: '元/kWh',
        field: 'storedEnergyCompensationUnitPrice',
        type: 'desc',
        precision: 2,
        min: 0,
        max: 999999,
      },
      {
        name: '保险费',
        unit: '',
        type: 'primary',
        isTitle: true,
      },
      {
        name: '光伏',
        unit: '%',
        field: 'photovoltaicPremiumRate',
        type: 'desc',
        precision: 2,
        min: 0,
        max: 100,
      },
      {
        name: '储能',
        unit: '%',
        field: 'storedEnergyPremiumRate',
        type: 'desc',
        precision: 2,
        min: 0,
        max: 100,
      },
      {
        name: '运维成本',
        unit: '',
        type: 'primary',
        isTitle: true,
      },
      {
        name: '年增长率',
        unit: '%',
        field: 'yearGrowthRate',
        type: 'primary',
        precision: 1,
      },
      {
        name: '光伏',
        unit: '元/kWp',
        field: 'photovoltaicUnitPrice',
        hidden: true,
        type: 'primary',
        precision: 2,
        min: 0,
        max: 999999,
      },
      {
        name: '储能',
        unit: '元/kWh',
        field: 'storedEnergyUnitPrice',
        hidden: true,
        type: 'primary',
        precision: 2,
        min: 0,
        max: 999999,
      },

      {
        name: '管理成本',
        unit: '',
        type: 'primary',
        hasSpace: true,
        isTitle: true,
      },
      {
        name: '光伏',
        unit: '元/kWp',
        field: 'photovoltaicAdministrativeCostUnitPrice',
        type: 'desc',
        precision: 2,
        min: 0,
        max: 999999,
      },
      {
        name: '储能',
        unit: '元/kWh',
        field: 'storedEnergyAdministrativeCostUnitPrice',
        type: 'desc',
        precision: 2,
        min: 0,
        max: 999999,
      },

      {
        name: '财务成本',
        unit: '',
        type: 'primary',
        hasSpace: true,
        isTitle: true,
      },
      {
        name: '利息',
        unit: '',
        field: 'interest',
        type: 'primary',
        setValue: '自动计算',
      },
      {
        name: '租金',
        unit: '',
        type: 'primary',
        isTitle: true,
      },
      {
        name: '光伏',
        unit: '元/m^2/年',
        field: 'photovoltaicRentUnitPrice',
        type: 'desc',
        precision: 2,
        min: 0,
        max: 999999,
      },
      {
        name: '储能',
        unit: '元/年',
        field: 'storedEnergyRentUnitPrice',
        type: 'desc',
        precision: 2,
        min: 0,
        max: 999999,
      },
      {
        name: '其他',
        unit: '',
        type: 'primary',
        hasSpace: true,
        isTitle: true,
      },
      {
        name: '光伏',
        unit: '元/kWp',
        field: 'photovoltaicOtherPrice',
        type: 'desc',
        precision: 2,
        min: 0,
        max: 999999,
      },
      {
        name: '储能',
        unit: '元/kWh',
        field: 'storedEnergyOtherPrice',
        type: 'desc',
        precision: 2,
        min: 0,
        max: 999999,
      },
    ],
  },
  {
    name: '可变成本',
    code: 'kbcb',
    rows: [
      {
        name: '购电成本',
        unit: '',
        isTitle: true,
        type: 'primary',
        hasSpace: true,
      },
      {
        name: '储能',
        unit: '',
        field: 'interest',
        type: 'primary',
        setValue: '自动计算',
      },
      {
        name: '其他',
        unit: '',
        isTitle: true,
        type: 'primary',
        hasSpace: true,
      },
      {
        name: '年增长率',
        unit: '%',
        field: 'otherYearGrowthRate',
        type: 'primary',
        precision: 1,
      },
      {
        name: '光伏',
        unit: '元/kWp',
        field: 'otherPhotovoltaicUnitPrice',
        type: 'desc',
        precision: 2,
        max: 999999,
      },
      {
        name: '储能',
        unit: '元/kWh',
        field: 'otherStoredEnergyUnitPrice',
        type: 'desc',
        precision: 2,
        max: 999999,
      },
    ],
  },
]

const icon: any = {
  projectInvest: 'klf-finance-investment',
  operationCost: 'klf-finance-operate',
  financeCost: 'klf-finance-finance',
  awardSubsidy: 'klf-finance-allowance',
}

const OperationCostCard = (props: any) => {
  const { editType, result, editConfigId, projectId, changeNameCallback } = props
  const [curName, setCurName] = useState('')
  const [curResult, setCurResult] = useState<any>()

  const formatDefaultData = (data: any) => {
    setCurResult({ ...data })
  }

  const changeNumber = ({ value, name }: { value: number; name: string }) => {
    curResult[name] = value
    formatDefaultData(curResult)
    submitData()
  }

  const submitData = () => {
    let data = { ...curResult, projectInvestId: editConfigId, name: curName || curResult.name }
    const FinanceApiService = new FinanceApi()
    // console.log(data, editConfigId)
    // @ts-ignore
    FinanceApiService.i3060FinanceOperationCostEditPost(data)
      .then(() => {})
      .catch(() => {})
      .finally(() => {})
  }

  useEffect(() => {
    if (result) {
      formatDefaultData(result)
    }
  }, [result])

  const { name } = curResult || {}
  useEffect(() => {
    if (name) {
      setCurName(name)
    }
  }, [])

  if (!result || !curResult) return <></>
  return (
    <div className="param-config-card">
      <Row>
        <Col className="param-title" span={24}>
          <CustomIcons type={icon[editType]} className="icon" />
          <EditInput
            name={curName || name}
            onConfirm={(str) => {
              const FinanceApiService = new FinanceApi()
              FinanceApiService.i3060FinanceParamListRenameProjectLibraryPost({
                projectId,
                name: str,
                type: '2',
                id: editConfigId,
              })
                .then((res: any) => {
                  if (res.code === 200) {
                    changeNameCallback()
                    setCurName(str)
                  }
                })
                .catch(() => {})
                .finally(() => {})
            }}
          />
        </Col>
      </Row>
      {projectTypeList.map((ele) => {
        return (
          <>
            <Row>
              <Col className="config-main-title" span={24}>
                {ele.name}
              </Col>
            </Row>
            {ele['rows'].map((item: any, index) => {
              return item.field === 'interest' ? (
                <Row
                  className="config-item"
                  style={{
                    color: '#767f95',
                    textIndent: !item.hasSpace ? '10px' : '',
                  }}
                >
                  <Col span={12} className="name">
                    {item.name}
                  </Col>
                  <Col span={12} className="content">
                    <span className="number">{item.setValue}</span>
                  </Col>
                </Row>
              ) : item.isTitle ? (
                <Row className="config-item">
                  <Col
                    span={12}
                    className="name"
                    style={{
                      color: '#262E3F',
                      textIndent: !item.hasSpace ? '10px' : '',
                    }}
                  >
                    {item.name}
                  </Col>
                  <Col span={12} className="content">
                    <span className="number">{curResult[item.field]}</span>
                    <span className="unit">{item.unit}</span>
                  </Col>
                </Row>
              ) : (
                <Row className="mb8 " key={`gf-${index}`}>
                  <Col
                    span={10}
                    className="edit-name name"
                    style={{
                      textIndent: '10px',
                    }}
                  >
                    {item.name}
                  </Col>
                  <Col span={14} className="content">
                    <InputNumber
                      min={item.min ? item.min : 0}
                      max={item.max ? item.max : item.unit === '%' ? 100 : undefined}
                      formatter={(val) => {
                        const arr = new Array(item.precision)
                        arr.fill('\\d')
                        const pat = `^(\\-)*(\\d+)\\.(${item.precision ? arr.join('') : ''}).*$`
                        return `${val}`.replace(new RegExp(pat), '$1$2.$3')
                      }}
                      style={{ width: '100%' }}
                      addonAfter={item.unit}
                      defaultValue={curResult[item.field]}
                      onBlur={(e) => {
                        let { value } = e.target
                        if (value === '') {
                          message.error('不能为空')
                          return
                        }
                        if (item.unit === '%') {
                          value = Number(value) > 100 ? '100' : value
                        }
                        if (curResult[item.field] == value) return

                        let max = item.max ? item.max : item.unit === '%' ? 100 : undefined
                        let min = item.min ? item.min : 0

                        if (max && value > max) {
                          value = max
                        }
                        if (value < min) {
                          value = min
                        }

                        const arr = new Array(item.precision)
                        arr.fill('\\d')
                        const pat = `^(\\-)*(\\d+)\\.(${item.precision ? arr.join('') : ''}).*$`
                        value = `${value}`.replace(new RegExp(pat), '$1$2.$3')
                        changeNumber({
                          value: Number(value),
                          name: item.field,
                        })
                      }}
                    />
                  </Col>
                </Row>
              )
            })}
          </>
        )
      })}
    </div>
  )
}
export default OperationCostCard
