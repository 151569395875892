import { Outlet } from 'react-router-dom'
import React from 'react'
import './workspace.less'
import { Modal } from 'antd'

export let WorkspaceModal: any

export const Workspace = () => {
  const [modal, container] = Modal.useModal()

  React.useEffect(() => {
    WorkspaceModal = modal
    return () => {
      WorkspaceModal = undefined
    }
  }, [])

  return (
    <div>
      <Outlet />
      {container}
    </div>
  )
}
