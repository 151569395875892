import React, { useImperativeHandle } from 'react'
import moment from 'moment'
import { Form, Input, Row, Spin, Table, FormInstance } from 'antd'
import { PlanExecuteService } from '../../../../apis/wl-service'
import { CustomFormItem, CustomFormTitle, formItemLayout } from '../../../../components/wl-form-item'
import { engineering_propertiesOptions } from '../../common/constant'
import useProject from '../common/useProject'

export interface IPlanExecuteFormProps {
  id?: string
  editable?: boolean
}

export const RefForm = ({ id, editable = false }: IPlanExecuteFormProps, ref: any) => {
  // type=params作为参数发送到服务端
  // type=component转换数据用于组件渲染
  const formatter = (values: any, type: 'params' | 'component') => {
    // 转换时间值
    if (editable) {
      ;['write_month'].forEach((k: string) => {
        if (type === 'component') {
          values[k] = values[k] ? moment(values[k]) : null
        }
        if (type === 'params') {
          values[k] = values[k] ? moment(values[k]).format('YYYY-MM-DD') : ''
        }
      })
    }

    // 转换文件值
    ;['analysis_report_file', 'construction_file', 'qt_file'].forEach((k: string) => {
      if (type === 'component' && !Array.isArray(values[k])) {
        values[k] = values[k] ? JSON.parse(values[k]) : []
      }

      if (type === 'params') {
        values[k] = values[k]?.length ? JSON.stringify(values[k]) : ''
      }
    })

    // 设置select
    ;['engineering_properties'].forEach((k: string) => {
      if (type === 'component' && typeof values[k] === 'number') {
        values[k] = values[k].toString()
      }
    })

    return values
  }

  const [{ imperativeHandle, form, formData, loading, projectId, options, getDetails, onValuesChange }] = useProject({
    detailsApi: async (projectId) => {
      return await (projectId ? PlanExecuteService.info({ id: projectId }) : PlanExecuteService.draft())
    },
    submitOptions: {
      label: '新建投资进度',
      Api: (values: any) =>
        PlanExecuteService.update({
          id: values.id ?? '',
          root: values,
        }),
    },
    formatter,
    key: 'planExecute',
    shareable: !id,
    mergeResponse: ({ resourceList = {}, investPlanList = {}, investPlanListInfo = [], recordList = {} }: any) => {
      return {
        mark: resourceList?.mark,
        project_id: resourceList?.id,
        project_name: resourceList?.projectName,
        project_code: resourceList?.projectCode ?? recordList?.projectCode,
        project_type: resourceList?.resourcetype,
        projectModule: resourceList?.projectModule,

        work_quantity: investPlanListInfo?.[0]?.work_quantity ?? '',
        money_quantity: investPlanListInfo?.[0]?.money_quantity ?? '',

        scale: investPlanList?.scale,
        work_schedule: investPlanList?.workSchedule ?? '',
        engineering_properties: investPlanList?.engineeringProperties ?? '',

        ...(investPlanList?.buildStartYear != null && investPlanList?.buildEndYear != null
          ? {
              build_start_end_year: investPlanList?.buildStartYear + ' 至 ' + investPlanList?.buildEndYear,
            }
          : {}),

        filing_invest: recordList?.filingInvest,
      }
    },
  })

  useImperativeHandle(ref, imperativeHandle)

  // 根据projectId获取详情
  React.useEffect(() => {
    getDetails(id ?? projectId).then()
  }, [id, projectId])

  const computed = {
    // 计算偏差量
    getDeviation: (r: any, p: any): string => {
      if (r && p) {
        if (r === p) {
          return '0%'
        } else {
          return Math.round(((r - p) / p) * 10000) / 100.0 + '%'
        }
      }
      return ''
    },

    // 求和
    sum: (v: any) => {
      return Object.keys(v).reduce((acc, k) => {
        acc += +(v[k] ?? 0)
        return acc
      }, 0)
    },
  }

  // 计算实际资金投资计划
  const sumZjMonthReal = () => {
    const v = form.getFieldsValue(['zjjzgc', 'zjazgc', 'zjsbgqjgz', 'zjqtfy'])
    const sum = computed.sum(v)
    const r = computed.getDeviation(sum, form.getFieldValue('zj_month_plan'))
    form.setFieldsValue({ zjMonthReal: sum, zj_deviation: r })
  }

  //计算实际工程量投资计划
  const sumGcMonthReal = () => {
    const v = form.getFieldsValue(['gcljzgc', 'gclazgc', 'gclsbgqjgz', 'gclqtfy'])
    const sum = computed.sum(v)
    const r = computed.getDeviation(sum, form.getFieldValue('gc_month_plan'))
    form.setFieldsValue({ gcMonthReal: sum, gc_deviation: r })
  }

  return (
    <Spin spinning={loading}>
      <Form
        name="planExecuteForm"
        form={form}
        labelWrap
        {...formItemLayout.span8}
        autoComplete="off"
        initialValues={formData}
        onValuesChange={(changedValues, allValues) => {
          onValuesChange(changedValues, allValues)
        }}
      >
        <Form.Item name="id" hidden>
          <Input />
        </Form.Item>

        <Form.Item hidden name="projectModule">
          <Input />
        </Form.Item>

        <CustomFormTitle>基本信息</CustomFormTitle>
        <Row>
          <CustomFormItem
            label="项目名称"
            name="mark"
            type="project_selector"
            span={3}
            editable={editable}
            options={options.projectIdOptions}
            setOptions={options.setProjectIdOptions}
            setValues={form.setFieldsValue}
            value={formData.project_name}
            componentProps={{
              disabled: !!id,
            }}
            rules={[
              {
                required: true,
                message: '请选择项目名称',
              },
            ]}
          />

          <CustomFormItem
            label="项目代码"
            name="project_code"
            span={3}
            type="input"
            componentProps={{ disabled: true }}
            editable={editable}
            value={formData.project_code}
          />
          <CustomFormItem
            label="项目类型"
            name="project_type"
            span={3}
            type="select"
            options={options.projectTypeOptions}
            editable={editable}
            computed={true}
            value={formData.project_type}
            componentProps={{
              disabled: true,
            }}
          />

          <CustomFormItem
            label="填报年月日"
            name="write_month"
            type="datepicker"
            span={3}
            editable={editable}
            value={formData.write_month}
            componentProps={{
              format: 'YYYY-MM-DD',
            }}
            rules={[{ required: true, message: '请填写填报年月日' }]}
          />

          <CustomFormItem
            label="项目总投资(万元)"
            name="filing_invest"
            span={3}
            type="number"
            editable={editable}
            value={formData.filing_invest}
            componentProps={{
              disabled: true,
            }}
          />

          <CustomFormItem
            label="建设起止年份"
            name="build_start_end_year"
            span={3}
            type="input"
            editable={editable}
            value={formData.build_start_end_year}
            componentProps={{
              disabled: true,
            }}
          />

          <CustomFormItem
            label="工程性质"
            name="engineering_properties"
            span={3}
            type="select"
            editable={editable}
            computed={true}
            options={engineering_propertiesOptions}
            value={formData.engineering_properties}
            componentProps={{
              disabled: true,
            }}
          />

          <CustomFormItem
            label="本期建设规模(MW)"
            name="scale"
            span={3}
            type="number"
            editable={editable}
            value={formData.scale}
            componentProps={{
              disabled: true,
            }}
          />
          <CustomFormItem
            label="工程量主要建设内容及进度要求"
            name="work_schedule"
            span={1}
            type="textarea"
            editable={editable}
            value={formData.work_schedule}
            componentProps={{
              disabled: true,
            }}
          />
        </Row>

        <Form.Item wrapperCol={{ span: 24 }} shouldUpdate={true}>
          {({ getFieldValue }: FormInstance) => {
            const date = getFieldValue('write_month')
            const year_text = date ? moment(date).year() : ''
            const month_text = date ? moment(date).month() + 1 : ''

            return (
              <>
                <CustomFormTitle>投资计划执行(工程量)</CustomFormTitle>
                <Form.Item wrapperCol={{ span: 24 }}>
                  <Table
                    bordered
                    className="bordered-table"
                    pagination={false}
                    rowKey={(...args: any[]) => args[1]}
                    size="small"
                    tableLayout="fixed"
                    dataSource={[formData]}
                    columns={[
                      {
                        dataIndex: 'work_quantity',
                        title: '累计至' + (year_text ? (year_text as any) - 1 : '') + '年底工程量完成',
                        align: 'center',
                        render: (text: any) => {
                          return (
                            <CustomFormItem
                              noStyle
                              label=""
                              name="work_quantity"
                              span={1}
                              type="input"
                              editable={editable}
                              value={text}
                              componentProps={{
                                disabled: true,
                              }}
                            />
                          )
                        },
                      },
                      {
                        dataIndex: 'gclbndzjwctz',
                        title: '本年度至今完成工程量投资',
                        align: 'center',
                        render: (text: any) => {
                          return (
                            <CustomFormItem
                              noStyle
                              label=""
                              name="gclbndzjwctz"
                              span={1}
                              type="input"
                              editable={editable}
                              value={text}
                            />
                          )
                        },
                      },
                      {
                        dataIndex: 'gclzkgljwctz',
                        title: '自开工累计完成投资',
                        align: 'center',
                        render: (text: any) => {
                          return (
                            <CustomFormItem
                              noStyle
                              label=""
                              name="gclzkgljwctz"
                              span={1}
                              type="input"
                              editable={editable}
                              value={text}
                            />
                          )
                        },
                      },
                      {
                        dataIndex: 'gclncjhxd',
                        title: year_text + '年工程量年初计划(下达值)',
                        align: 'center',
                        render: (text: any) => {
                          return (
                            <CustomFormItem
                              noStyle
                              label=""
                              name="gclncjhxd"
                              span={1}
                              type="input"
                              editable={editable}
                              value={text}
                              componentProps={
                                {
                                  // disabled: true,
                                }
                              }
                            />
                          )
                        },
                      },
                      {
                        dataIndex: 'gclnzjhxd',
                        title: year_text + '年工程量年终调整计划(下达值)',
                        align: 'center',
                        render: (text: any) => {
                          return (
                            <CustomFormItem
                              noStyle
                              label=""
                              name="gclnzjhxd"
                              span={1}
                              type="input"
                              editable={editable}
                              value={text}
                              componentProps={
                                {
                                  // disabled: true,
                                }
                              }
                            />
                          )
                        },
                      },
                      {
                        dataIndex: 'gc_month_plan',
                        title: year_text + '年工程量投资计划' + month_text + '月计划',
                        align: 'center',
                        render: (text: any) => {
                          return (
                            <CustomFormItem
                              noStyle
                              label=""
                              name="gc_month_plan"
                              span={1}
                              type="number"
                              editable={editable}
                              value={text}
                              componentProps={{
                                onChange: (value: any) => {
                                  const r = computed.getDeviation(getFieldValue('gcMonthReal'), value)
                                  if (r) {
                                    form.setFieldsValue({ gc_deviation: r })
                                  }
                                },
                              }}
                            />
                          )
                        },
                      },
                      {
                        dataIndex: 'gcMonthReal',
                        title: year_text + '年工程量投资计划' + month_text + '月实际',
                        align: 'center',
                        render: (text: any) => {
                          return (
                            <CustomFormItem
                              noStyle
                              label=""
                              name="gcMonthReal"
                              span={1}
                              type="number"
                              editable={editable}
                              value={text}
                              componentProps={{
                                disabled: true,
                              }}
                            />
                          )
                        },
                      },
                      {
                        dataIndex: 'gc_deviation',
                        title: '偏差率',
                        align: 'center',
                        render: (text: any) => {
                          return (
                            <CustomFormItem
                              noStyle
                              label=""
                              name="gc_deviation"
                              span={1}
                              type="input"
                              editable={editable}
                              value={text}
                              componentProps={{
                                disabled: true,
                              }}
                            />
                          )
                        },
                      },
                      {
                        dataIndex: 'gc_kh_point',
                        title: '考核得分',
                        align: 'center',
                        render: (text: any) => {
                          return (
                            <CustomFormItem
                              noStyle
                              label=""
                              name="gc_kh_point"
                              span={1}
                              type="number"
                              editable={editable}
                              value={text}
                            />
                          )
                        },
                      },
                    ]}
                  />
                </Form.Item>

                <CustomFormTitle>投资计划执行(资金)</CustomFormTitle>
                <Table
                  bordered
                  className="bordered-table"
                  pagination={false}
                  rowKey={(...args: any[]) => args[1]}
                  size="small"
                  tableLayout="fixed"
                  dataSource={[formData]}
                  columns={[
                    {
                      dataIndex: 'money_quantity',
                      title: '累计至' + (year_text ? (year_text as any) - 1 : '') + '年底资金完成',
                      align: 'center',
                      render: (text: any) => {
                        return (
                          <CustomFormItem
                            noStyle
                            label=""
                            name="money_quantity"
                            span={1}
                            type="input"
                            editable={editable}
                            value={text}
                            componentProps={{
                              disabled: true,
                            }}
                          />
                        )
                      },
                    },
                    {
                      dataIndex: 'zjbndzjwctz',
                      title: '本年度至今完成资金投资',
                      align: 'center',
                      render: (text: any) => {
                        return (
                          <CustomFormItem
                            noStyle
                            label=""
                            name="zjbndzjwctz"
                            span={1}
                            type="input"
                            editable={editable}
                            value={text}
                          />
                        )
                      },
                    },
                    {
                      dataIndex: 'zjzkgljwctz',
                      title: '自开工累计完成投资',
                      align: 'center',
                      render: (text: any) => {
                        return (
                          <CustomFormItem
                            noStyle
                            label=""
                            name="zjzkgljwctz"
                            span={1}
                            type="input"
                            editable={editable}
                            value={text}
                          />
                        )
                      },
                    },
                    {
                      dataIndex: 'zjncjhxd',
                      title: year_text + '年资金年初计划(下达值)',
                      align: 'center',
                      render: (text: any) => {
                        return (
                          <CustomFormItem
                            noStyle
                            label=""
                            name="zjncjhxd"
                            span={1}
                            type="input"
                            editable={editable}
                            value={text}
                            componentProps={
                              {
                                // disabled: true,
                              }
                            }
                          />
                        )
                      },
                    },
                    {
                      dataIndex: 'zjnzjhxd',
                      title: year_text + '年资金年终调整计划(下达值)',
                      align: 'center',
                      render: (text: any) => {
                        return (
                          <CustomFormItem
                            noStyle
                            label=""
                            name="zjnzjhxd"
                            span={1}
                            type="input"
                            editable={editable}
                            value={text}
                            componentProps={
                              {
                                // disabled: true,
                              }
                            }
                          />
                        )
                      },
                    },
                    {
                      dataIndex: 'zj_month_plan',
                      title: year_text + '年资金投资计划' + month_text + '月计划',
                      align: 'center',
                      render: (text: any) => {
                        return (
                          <CustomFormItem
                            noStyle
                            label=""
                            name="zj_month_plan"
                            span={1}
                            type="number"
                            editable={editable}
                            value={text}
                            componentProps={{
                              onChange: (value: any) => {
                                const r = computed.getDeviation(getFieldValue('zjMonthReal'), value)
                                if (r) {
                                  form.setFieldsValue({ zj_deviation: r })
                                }
                              },
                            }}
                          />
                        )
                      },
                    },
                    {
                      dataIndex: 'zjMonthReal',
                      title: year_text + '年资金投资计划' + month_text + '月实际',
                      align: 'center',
                      render: (text: any) => {
                        return (
                          <CustomFormItem
                            noStyle
                            label=""
                            name="zjMonthReal"
                            span={1}
                            type="number"
                            editable={editable}
                            value={text}
                            componentProps={{
                              disabled: true,
                            }}
                          />
                        )
                      },
                    },
                    {
                      dataIndex: 'zj_deviation',
                      title: '偏差率',
                      align: 'center',
                      render: (text: any) => {
                        return (
                          <CustomFormItem
                            noStyle
                            label=""
                            name="zj_deviation"
                            span={1}
                            type="input"
                            editable={editable}
                            value={text}
                            componentProps={{
                              disabled: true,
                            }}
                          />
                        )
                      },
                    },
                    {
                      dataIndex: 'zj_kh_point',
                      title: '考核得分',
                      align: 'center',
                      render: (text: any) => {
                        return (
                          <CustomFormItem
                            noStyle
                            label=""
                            name="zj_kh_point"
                            span={1}
                            type="number"
                            editable={editable}
                            value={text}
                          />
                        )
                      },
                    },
                  ]}
                />
              </>
            )
          }}
        </Form.Item>

        <CustomFormTitle>本月工程量完成投资明细</CustomFormTitle>
        <Row>
          <CustomFormItem
            label="建筑工程(万元)"
            name="gcljzgc"
            span={3}
            type="number"
            editable={editable}
            value={formData.gcljzgc}
            componentProps={{
              onChange: sumGcMonthReal,
            }}
          />
          <CustomFormItem
            label="安装工程(万元)"
            name="gclazgc"
            span={3}
            type="number"
            editable={editable}
            value={formData.gclazgc}
            componentProps={{
              onChange: sumGcMonthReal,
            }}
          />
          <CustomFormItem
            label="设备工器具购置(万元)"
            name="gclsbgqjgz"
            span={3}
            type="number"
            editable={editable}
            value={formData.gclsbgqjgz}
            componentProps={{
              onChange: sumGcMonthReal,
            }}
          />
          <CustomFormItem
            label="其他费用(万元)"
            name="gclqtfy"
            span={3}
            type="number"
            editable={editable}
            value={formData.gclqtfy}
            componentProps={{
              onChange: sumGcMonthReal,
            }}
          />
        </Row>

        <CustomFormTitle>本月资金完成投资明细</CustomFormTitle>
        <Row>
          <CustomFormItem
            label="建筑工程(万元)"
            name="zjjzgc"
            span={3}
            type="number"
            editable={editable}
            value={formData.zjjzgc}
            componentProps={{
              onChange: sumZjMonthReal,
            }}
          />
          <CustomFormItem
            label="安装工程(万元)"
            name="zjazgc"
            span={3}
            type="number"
            editable={editable}
            value={formData.zjazgc}
            componentProps={{
              onChange: sumZjMonthReal,
            }}
          />
          <CustomFormItem
            label="设备工器具购置(万元)"
            name="zjsbgqjgz"
            span={3}
            type="number"
            editable={editable}
            value={formData.zjsbgqjgz}
            componentProps={{
              onChange: sumZjMonthReal,
            }}
          />
          <CustomFormItem
            label="其他费用(万元)"
            name="zjqtfy"
            span={3}
            type="number"
            editable={editable}
            value={formData.zjqtfy}
            componentProps={{
              onChange: sumZjMonthReal,
            }}
          />
        </Row>

        <CustomFormTitle>本月资金到位情况</CustomFormTitle>
        <Row>
          <CustomFormItem
            label="中央财政资金(万元)"
            name="zyczzj"
            span={3}
            type="number"
            editable={editable}
            value={formData.zyczzj}
          />
          <CustomFormItem
            label="省财政资金(万元)"
            name="sczzj"
            span={3}
            type="number"
            editable={editable}
            value={formData.sczzj}
          />
          <CustomFormItem
            label="县财政资金(万元)"
            name="xczzj"
            span={3}
            type="number"
            editable={editable}
            value={formData.xczzj}
          />
          <CustomFormItem
            label="企业自有资金(万元)"
            name="qyzyzj"
            span={3}
            type="number"
            editable={editable}
            value={formData.qyzyzj}
          />
          <CustomFormItem
            label="外商投资(万元)"
            name="wstz"
            span={3}
            type="number"
            editable={editable}
            value={formData.wstz}
          />
          <CustomFormItem
            label="银行贷款(万元)"
            name="yhdk"
            span={3}
            type="number"
            editable={editable}
            value={formData.yhdk}
          />
          <CustomFormItem
            label="其他资金(万元)"
            name="qtzj"
            span={3}
            type="number"
            editable={editable}
            value={formData.qtzj}
          />
          <CustomFormItem
            label="本月新增固定资产(万元)"
            name="byxzgdzc"
            span={3}
            type="number"
            editable={editable}
            value={formData.byxzgdzc}
          />
        </Row>

        <CustomFormTitle>附件</CustomFormTitle>
        <Row>
          <CustomFormItem
            label="投资计划分析报告"
            name="analysis_report_file"
            span={2}
            type="file"
            editable={editable}
            value={formData.analysis_report_file}
          />
          <CustomFormItem
            label="现场施工建设文件"
            name="construction_file"
            span={2}
            type="file"
            editable={editable}
            value={formData.construction_file}
          />

          <CustomFormItem
            label="其他附件"
            name="qt_file"
            span={2}
            type="file"
            editable={editable}
            value={formData.qt_file}
          />
        </Row>
      </Form>
    </Spin>
  )
}

export default React.forwardRef(RefForm)
