import React, { useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'

import { PowerStationDetail } from '../type'
import { STATISTIC_LIST } from '../constants'
import PowerStationCurve from './Curve'
import PowerStationEquipment from './Equipment'
import PowerStationMonitor from './Monitor'
import PowerStationPosition from './Position'
import PowerStationStatistic from './Statistic'
import PowerStationControllerApi from '../../../../services/api/PowerStationControllerApi'
import {BreadcrumbObservable} from "../../../../application";

import '../index.less'

const PowerStation: React.FC = React.memo(() => {
  const [loading, setLoading] = useState<boolean>(false)
  console.log('🚀 ~ file: index.tsx ~ line 8 ~ constPowerStation:React.FC=React.memo ~ loading', loading)
  const [detail, setDetail] = useState<PowerStationDetail>()

  const { id } = useParams()

  let timer:any = null

  useEffect(() => {
    BreadcrumbObservable.next([])
  }, [])

  const [LeftStatisticList, RightStatisticList] = useMemo(() => {
    const LeftStatisticList = []
    const RightStatisticList = []
    const len = STATISTIC_LIST.length
    for (let i = 0; i < len; i++) {
      const item = STATISTIC_LIST[i];
      item.value = detail?.[item.field]
      if (i % 2 === 1) {
        RightStatisticList.push(item)
      } else {
        LeftStatisticList.push(item)
      }
    }
    return [LeftStatisticList, RightStatisticList]
  }, [STATISTIC_LIST, detail])

  const fetchDetail = (background = false) => {
    if (!id) return
    if (!background) {
      setLoading(true)
    }
    PowerStationControllerApi.i3060PowerStationShowGet({ powerStationId: id })
      .then((res) => {
        setLoading(false)
        const { code, data } = res || {}
        if (code === 200) {
          setDetail(data)
        }
      })
      .catch(() => {
        setLoading(false)
      })
  }

  useEffect(() => {
    fetchDetail()

    timer = setInterval(() => {
      fetchDetail()
    }, 300000)

    return () => {
      clearInterval(timer)
    }
  }, [])

  return (
    <section className="power-station-detail">
      <div className="power-station-detail-half power-station-detail-left">
        <PowerStationPosition detail={detail} />
        <PowerStationStatistic list={LeftStatisticList} />
        <PowerStationCurve detail={detail} />
      </div>
      <div className="power-station-detail-half">
        <PowerStationStatistic list={RightStatisticList} />
        <PowerStationEquipment detail={detail} />
        <PowerStationMonitor detail={detail} />
      </div>
    </section>
  )
})

export default PowerStation
