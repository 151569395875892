import {
  Badge,
  Button,
  Checkbox,
  Form,
  Image,
  Input,
  Menu,
  message,
  Modal,
  Popover,
  Space,
  Spin,
  Table,
  Tooltip,
} from 'antd'
import React, { useEffect, useState } from 'react'
import { FinanceService } from '../../../../../apis/project-service-history'
import { CustomIcons } from '../../../../../global'
import { RenderReportDialog } from '../../render-report-dialog'
import { NAME_EXP } from '../../../../../utils/constant'
import styles from '../index.module.scss'
import axios from 'axios'
import { token } from '../../../../../utils/utils'
import { BreadcrumbObservable } from '../../../../../application'
import mammoth from 'mammoth'

import './docx.scss'

const uploadIcon = (
  <svg
    width="16px"
    height="16px"
    viewBox="0 0 16 16"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g strokeWidth="1.5" fill="none">
      <path
        stroke="#1EC692"
        d="m4.71325,15.22017l-1.70542,0c-1.10457,0 -2,-0.89543 -2,-2l0,-10.23834c0,-1.10457 0.89543,-2 2,-2l9.98961,0c1.10457,0 2,0.89543 2,2l0,10.23834c0,1.10457 -0.89543,2 -2,2l-1.36307,0l0,0"
      />
      <rect stroke="none" fill="#1EC692" height="1.5" width="14" y="4.2" x="1.00783" />
      <polyline
        stroke="#1EC692"
        points="5.191547393798828,10.394306182861328 8.002582550048828,7.713825225830078 10.818378448486328,10.394306182861328 "
        strokeLinecap="round"
      />
      <rect
        transform="rotate(45)"
        fill="#1EC692"
        stroke="none"
        strokeLinecap="round"
        rx="0.75"
        height="7"
        width="1.5"
        y="8.5"
        x="7.25"
      />
      <rect fill="#1EC692" stroke="none" strokeLinecap="round" rx="0.75" height="7" width="1.5" y="8.5" x="7.25" />
    </g>
  </svg>
)

let query = {
  wordName: '',
  technicalName: '',
  financeName: '',
  status: undefined,
  createTimeSort: 0, // 1 asc 2: desc
  updateTimeSort: 0,
  generateTimeSort: 0,
} as any

const colorArr: any[] = ['default', 'processing', 'success', 'error']

const statusStyle = {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
} as any

export const ViewFile = (props: any) => {
  const [loading, setLoading] = useState<any>(true)

  useEffect(() => {
    viewTemplate()
  }, [])

  const id = props.itemId
  const prefix = axios.defaults.baseURL
  const url = `${prefix || ''}/api/i3060/financeWord/readWord/${id}`

  const viewTemplate = () => {
    setLoading(true)
    const authToken: string = token.get() || ''
    fetch(url, {
      method: 'get',
      headers: {
        Authorization: authToken,
        'Content-Type': 'application/json;charset=UTF-8',
      },
    }).then((resp) => {
      resp.arrayBuffer().then((blob) => {
        setLoading(false)
        mammoth.convertToHtml({ arrayBuffer: blob }, { includeDefaultStyleMap: true }).then((result: any) => {
          const docEl = document.createElement('div')
          docEl.className = 'document-container'
          docEl.innerHTML = result.value
          const element = document.getElementById('docx')
          if (element) {
            element.innerHTML = docEl.outerHTML
          }
        })
      })
    })
  }
  return (
    <div
      className="pg-viewer-wrapper"
      style={{
        width: '100%',
        height: '60vh',
        overflowY: loading ? 'hidden' : 'auto',
        overflowX: 'hidden',
      }}
    >
      {loading && (
        <Spin
          spinning={loading}
          style={{
            width: '100%',
            height: '60vh',
          }}
        />
      )}
      <div id="docx" />
    </div>
  )
}

export const ReportList = (props: any) => {
  const [singleUpload] = useState((window as any).singleUpload || false)
  const [viewWord] = useState((window as any).viewPdf || false)
  const [dataSource, setDataSource] = useState<any>()

  const { hasAuth } = props

  // 查看报告
  const showReport = (item: any, uploadAll = false) => {
    const dlg = Modal.confirm({
      title: '',
      icon: null,
      content: (
        <RenderReportDialog
          projectId={item.projectId}
          financeId={item.financeId}
          techId={item.technicalId}
          uploadAll={uploadAll}
          callback={(fl: boolean) => {
            dlg.destroy()
            if (fl) {
              message.success('报告生成成功！')
              loadData()
            }
          }}
        />
      ),
      className: 'hide-footer',
      cancelText: '取消',
      cancelButtonProps: {
        style: { display: 'none' },
      },
      okText: '取消',
      okButtonProps: {
        style: { display: 'none' },
      },
      width: 280,
    })
  }

  const downloadTemplatePdf = (row: any) => {
    setDownloading(true)
    FinanceService.getPdfTag({
      wordId: row.id,
    }).then((resp) => {
      const tag = resp.data
      downloadTemplate({ id: tag, name: row.name }, false)
    })
  }

  const [downloading, setDownloading] = useState(false)

  const downloadTemplate = (item: any, isWord = true) => {
    const fn = `${item.name}.pdf`
    setDownloading(true)
    const id = item.id
    const prefix = axios.defaults.baseURL
    const url = `${prefix || ''}/api/i3060/financeWord/${isWord ? 'readWord' : 'readPdf'}/${id}`
    const authToken: string = token.get() || ''
    fetch(url, {
      method: 'get',
      headers: {
        Authorization: authToken,
        'Content-Type': 'application/json;charset=UTF-8',
      },
    })
      .then((resp) => {
        let fileName =
          resp.headers.get('content-disposition')?.split(';')[1].split('filename=')[1] || `report-${Date.now()}.docx`
        console.log(fn)
        fileName = isWord ? decodeURIComponent(fileName) : `${fn}`
        resp
          .blob()
          .then((blob) => {
            if ((window.navigator as any).msSaveOrOpenBlob) {
              ;(window.navigator as any).msSaveBlob(blob, fileName)
            } else {
              const a = document.createElement('a')
              document.body.appendChild(a)
              const url = window.URL.createObjectURL(blob)
              a.href = url
              a.download = fileName
              a.target = '_blank'
              a.click()
              a.remove()
              window.URL.revokeObjectURL(url)
            }
          })
          .finally(() => {
            setDownloading(false)
          })
      })
      .catch(() => {
        setDownloading(false)
      })
      .finally(() => {
        Modal.destroyAll()
      })
  }

  const [wordNameFilter, setWordNameFilter] = useState('')
  const [technicalNameFilter, setTechnicalNameFilter] = useState('')
  const [financeNameFilter, setFinanceNameFilter] = useState('')
  const [form] = Form.useForm()
  // 编辑方案名称
  const editItemName = (item: any, index: number) => {
    const id = `name-${item.id}`
    setTimeout(() => {
      const element = document.getElementById(id)
      element?.focus()
    })
    dataSource[index] = {
      ...item,
      edit_name: true,
    }
    setDataSource([...dataSource])
  }

  const columns = [
    {
      title: <strong>报告名称</strong>,
      key: 'name',
      dataIndex: 'name',
      width: 200,
      lock: 'left',
      render: (text: string, row: any, index: number) => {
        return (
          <Tooltip title={text} key={`name-${row.id}`}>
            {row.edit_name ? (
              <Input
                allowClear
                id={`name-${row.id}`}
                maxLength={16}
                defaultValue={text}
                onBlur={(val) => {
                  if (!NAME_EXP.test(val.target.value)) {
                    const dom = document.getElementById(`name-${row.id}`)
                    message.error('"1-16个字符,中文、英文、_、数字和-组成"')
                    dom?.focus()
                    return
                  }
                  FinanceService.rename({
                    wordId: row.id,
                    root: {
                      newName: val.target.value,
                    },
                  })
                    .then(() => {
                      loadData()
                    })
                    .catch(() => {})
                }}
              />
            ) : (
              <div className={`${styles.listName} hover-outer`}>
                <Button
                  style={{
                    textAlign: 'left',
                    padding: 0,
                  }}
                  type="link"
                  disabled={!hasAuth}
                  onClick={() => {
                    FinanceService.getPdfTag({
                      wordId: row.id,
                    }).then((resp) => {
                      const tag = resp.data
                      window.open(`/api/i3060/financeWord/readPdf/${tag}`, '_blank')
                    })
                  }}
                >
                  {text}
                </Button>

                <Button
                  className="hover-inner"
                  type="text"
                  disabled={!hasAuth}
                  icon={<CustomIcons type="klf-edit1" />}
                  onClick={() => {
                    editItemName(row, index)
                  }}
                />
              </div>
            )}
          </Tooltip>
        )
      },
      onFilterDropdownVisibleChange: (visible: boolean) => {
        if (!visible) {
          setWordNameFilter(query.wordName)
        }
      },
      filterIcon: (
        <CustomIcons
          type="klf-search"
          style={{
            color: query.wordName ? '#1ec692' : '#bfbfbf',
          }}
        />
      ),
      filterDropdown: ({ confirm, visible }: any) => {
        if (!visible) {
          ;(window as any).closeDropdown = null
        } else {
          setTimeout(() => {
            ;(window as any).closeDropdown = confirm
          })
        }
        return (
          <div
            style={{
              padding: '.5rem 1rem',
            }}
          >
            <Input
              placeholder="请输入技术方案名"
              value={wordNameFilter}
              onBlur={(dom) => {
                setWordNameFilter(dom.target.value)
              }}
              onChange={(dom) => {
                setWordNameFilter(dom.target.value)
              }}
              onPressEnter={() => {
                query.wordName = wordNameFilter
                loadData()
                confirm?.()
              }}
            />
            <div
              style={{
                marginTop: '.5rem',
                textAlign: 'right',
              }}
            >
              <Button
                onClick={() => {
                  setWordNameFilter('')
                }}
              >
                重置
              </Button>
              <Button
                type="primary"
                style={{
                  marginLeft: '.5rem',
                }}
                onClick={() => {
                  query.wordName = wordNameFilter
                  loadData()
                  confirm?.()
                }}
              >
                确定
              </Button>
            </div>
          </div>
        )
      },
    },
    {
      title: '技术方案',
      key: 'technicalName',
      dataIndex: 'technicalName',
      onFilterDropdownVisibleChange: (visible: boolean) => {
        if (!visible) {
          setTechnicalNameFilter(query.technicalName)
        }
      },
      filterIcon: (
        <CustomIcons
          type="klf-search"
          style={{
            color: query.technicalName ? '#1ec692' : '#bfbfbf',
          }}
        />
      ),
      filterDropdown: ({ confirm, visible }: any) => {
        if (!visible) {
          ;(window as any).closeDropdown = null
        } else {
          setTimeout(() => {
            ;(window as any).closeDropdown = confirm
          })
        }
        return (
          <div
            style={{
              padding: '.5rem 1rem',
            }}
          >
            <Input
              placeholder="请输入技术方案名"
              value={technicalNameFilter}
              onBlur={(dom) => {
                setTechnicalNameFilter(dom.target.value)
              }}
              onChange={(dom) => {
                setTechnicalNameFilter(dom.target.value)
              }}
              onPressEnter={() => {
                query.technicalName = technicalNameFilter
                loadData()
                confirm?.()
              }}
            />
            <div
              style={{
                marginTop: '.5rem',
                textAlign: 'right',
              }}
            >
              <Button
                onClick={() => {
                  setTechnicalNameFilter('')
                }}
              >
                重置
              </Button>
              <Button
                type="primary"
                style={{
                  marginLeft: '.5rem',
                }}
                onClick={() => {
                  query.technicalName = technicalNameFilter
                  loadData()
                  confirm?.()
                }}
              >
                确定
              </Button>
            </div>
          </div>
        )
      },
    },
    {
      title: '财务方案',
      key: 'financeName',
      dataIndex: 'financeName',
      onFilterDropdownVisibleChange: (visible: boolean) => {
        if (!visible) {
          setFinanceNameFilter(query.financeName)
        }
      },
      filterIcon: (
        <CustomIcons
          type="klf-search"
          style={{
            color: query.financeName ? '#1ec692' : '#bfbfbf',
          }}
        />
      ),
      filterDropdown: ({ confirm, visible }: any) => {
        if (!visible) {
          ;(window as any).closeDropdown = null
        } else {
          setTimeout(() => {
            ;(window as any).closeDropdown = confirm
          })
        }
        return (
          <div
            style={{
              padding: '.5rem 1rem',
            }}
          >
            <Input
              placeholder="请输入财务方案名"
              value={financeNameFilter}
              onBlur={(dom) => {
                setFinanceNameFilter(dom.target.value)
              }}
              onChange={(dom) => {
                setFinanceNameFilter(dom.target.value)
              }}
              onPressEnter={() => {
                query.financeName = financeNameFilter
                loadData()
                confirm?.()
              }}
            />
            <div
              style={{
                marginTop: '.5rem',
                textAlign: 'right',
              }}
            >
              <Button
                onClick={() => {
                  setFinanceNameFilter('')
                }}
              >
                重置
              </Button>
              <Button
                type="primary"
                style={{
                  marginLeft: '.5rem',
                }}
                onClick={() => {
                  query.financeName = financeNameFilter
                  loadData()
                  confirm?.()
                }}
              >
                确定
              </Button>
            </div>
          </div>
        )
      },
    },
    {
      title: '状态',
      key: 'status',
      dataIndex: 'status',
      width: 150,
      filterMultiple: true,
      filterIcon: <CustomIcons type="klf-filter" />,
      onFilterDropdownVisibleChange: (visible: boolean) => {
        if (!visible) {
          form.setFieldsValue({ status: query.status })
        }
      },
      filterDropdown: ({ confirm, visible, filters }: any) => {
        if (!visible) {
          ;(window as any).closeDropdown = null
        } else {
          setTimeout(() => {
            ;(window as any).closeDropdown = confirm
          })
        }
        return (
          <div className={styles.customFilter}>
            <Form
              onFinish={() => {
                query.status = form.getFieldValue('status')
                loadData()
                confirm()
              }}
              {...{
                labelCol: { span: 24 },
                wrapperCol: { span: 24 },
              }}
              colon={false}
              form={form}
            >
              <Form.Item
                className="label-low"
                name={'status'}
                label={'状态'}
                style={{
                  alignItems: 'center',
                }}
              >
                <Checkbox.Group>
                  <Space direction="vertical">
                    {filters.map((filter: any) => (
                      <Checkbox key={filter.value} value={filter.value}>
                        {filter.text}
                      </Checkbox>
                    ))}
                  </Space>
                </Checkbox.Group>
              </Form.Item>
              <Form.Item className="filter-btns">
                <div
                  style={{
                    marginTop: '.5rem',
                    textAlign: 'right',
                  }}
                >
                  <Button
                    onClick={() => {
                      form.setFieldsValue({
                        status: undefined,
                      })
                    }}
                  >
                    重置
                  </Button>
                  <Button
                    type="primary"
                    style={{
                      marginLeft: '.5rem',
                    }}
                    htmlType="submit"
                  >
                    确定
                  </Button>
                </div>
              </Form.Item>
            </Form>
          </div>
        )
      },
      filters: [
        { text: '未生成', value: '0' },
        { text: '生成中', value: '1' },
        { text: '已生成', value: '2' },
        { text: '生成失败', value: '3' },
      ],
      render: (res: string) => {
        switch (res.toString()) {
          case '0':
            return (
              <div
                style={{
                  ...statusStyle,
                }}
              >
                <Badge status={colorArr[0]} />
                未生成
              </div>
            )
          case '1':
            return (
              <div
                style={{
                  ...statusStyle,
                }}
              >
                <Badge status={colorArr[1]} />
                生成中
              </div>
            )
          case '2':
            return (
              <div
                style={{
                  ...statusStyle,
                }}
              >
                <Badge status={colorArr[2]} />
                已生成
              </div>
            )
          case '3':
            return (
              <div
                style={{
                  ...statusStyle,
                }}
              >
                <Badge status={colorArr[3]} />
                生成失败
              </div>
            )
          case '4':
            return (
              <div
                style={{
                  ...statusStyle,
                }}
              >
                <Badge status={colorArr[3]} />
                已失效
              </div>
            )
        }
      },
    },
    {
      title: '生成时间',
      key: 'generateTime',
      dataIndex: 'generateTime',
      width: 200,
      sorter: true,
      render: (res?: string) => {
        return res || '--'
      },
    },
    {
      title: <strong>操作</strong>,
      key: '',
      dataIndex: '',
      width: 100,
      lock: 'right',
      render: (res: string, row: any) => {
        return (
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            {row.status === 2 && (
              <Tooltip title="查看报告">
                <Button
                  type="link"
                  disabled={!hasAuth}
                  onClick={() => {
                    if (viewWord) {
                      Modal.info({
                        title: '',
                        content: <ViewFile itemId={row.id} />,
                        width: 1200,
                      })
                    } else {
                      FinanceService.getPdfTag({
                        wordId: row.id,
                      }).then((resp) => {
                        const tag = resp.data
                        window.open(`/api/i3060/financeWord/readPdf/${tag}`, '_blank')
                      })
                    }
                  }}
                >
                  <CustomIcons
                    style={{
                      fontSize: 16,
                    }}
                    type="klf-chazhaowenjian"
                  />
                </Button>
              </Tooltip>
            )}
            {singleUpload && (row.status === 0 || row.status === 4) && (
              <Tooltip title="生成报告">
                <Button
                  disabled={row.status === 1 || !hasAuth}
                  type="link"
                  onClick={() => {
                    showReport(row)
                  }}
                >
                  {uploadIcon}
                </Button>
              </Tooltip>
            )}
            {!singleUpload && (row.status === 0 || row.status === 2 || row.status === 4 || row.status === 3) && (
              <Tooltip title={row.status === 2 ? '重新生成报告' : '生成报告'}>
                <Button
                  type="link"
                  disabled={!hasAuth}
                  onClick={() => {
                    showReport(row, true)
                  }}
                >
                  {uploadIcon}
                </Button>
              </Tooltip>
            )}
            {row.status === 2 && (
              <Tooltip title="下载报告">
                <Popover
                  placement="bottom"
                  overlayClassName="menu-popover-overlay"
                  content={
                    <Menu
                      mode="inline"
                      style={{
                        borderRight: 'none',
                        paddingTop: '.5rem',
                        paddingBottom: '.5rem',
                      }}
                      selectable={false}
                    >
                      <Menu.Item
                        style={{
                          height: 30,
                        }}
                        disabled={!hasAuth}
                        onClick={() => {
                          downloadTemplate(row)
                        }}
                      >
                        下载Word
                      </Menu.Item>
                      <Menu.Item
                        style={{
                          height: 30,
                        }}
                        disabled={!hasAuth}
                        onClick={() => {
                          downloadTemplatePdf(row)
                        }}
                      >
                        下载Pdf
                      </Menu.Item>
                    </Menu>
                  }
                >
                  <Button type="link">
                    <CustomIcons
                      style={{
                        fontSize: 16,
                      }}
                      type="klf-xiazai1"
                    />
                  </Button>
                </Popover>
              </Tooltip>
            )}
          </div>
        )
      },
    },
  ]

  const [loading, setLoading] = useState<any>(true)
  const [firstTimeLoaded, setFirstTimeLoaded] = useState<any>(false)

  const loadData = (showLoading = true) => {
    if (showLoading) {
      setLoading(true)
    }
    FinanceService.list4({
      projectId: props.projectId,
      ...query,
      status: query.status?.join(','),
    })
      .then((resp) => {
        setDataSource(resp.data)
        if (resp.data.map((o: any) => o.status === 1).filter(Boolean).length > 0) {
          setTimeout(() => {
            loadData(false)
          }, 5000)
        }
      })
      .finally(() => {
        setLoading(false)
        setFirstTimeLoaded(true)
      })
  }

  useEffect(() => {
    loadData()
    return () => {
      query = {
        wordName: '',
        technicalName: '',
        financeName: '',
        status: undefined,
        createTimeSort: 0, // 1 asc 2: desc
        updateTimeSort: 0,
        generateTimeSort: 0,
      }
    }
  }, [])

  // 过滤  排序
  const changeTable = (pager: any, filter: any, sorter: any) => {
    const { order, field } = sorter
    let ope = '0'
    if (order === 'ascend') {
      ope = '1'
    }
    if (order === 'descend') {
      ope = '2'
    }

    Object.keys(query).forEach((key) => {
      if (key.includes('Sort')) {
        query[key] = '0'
      }
    })

    query[`${field}Sort`] = ope

    loadData()
  }

  useEffect(() => {
    query.createTimeSort = '0'
    query.updateTimeSort = '0'
    if (props.timeSort.length === 2) {
      query[props.timeSort[0]] = props.timeSort[1]
    }
    loadData()

    BreadcrumbObservable.next([
      {
        name: '项目管理',
        url: '/program/manage',
      },
      {
        name: props.item?.name,
        url: `/program/detail/${props.item?.id}`,
      },
    ])
  }, [props.timeSort])

  if (!firstTimeLoaded) {
    return null
  }
  return (
    <Spin spinning={downloading}>
      <div>
        {query.wordName === '' &&
          query.technicalName === '' &&
          query.financeName === '' &&
          !query.status &&
          (!dataSource || dataSource.length === 0) && (
            <div
              style={{
                height: 'calc(100vh - 400px)',
                minHeight: 162,
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <div
                style={{
                  textAlign: 'center',
                }}
              >
                <div>
                  <Image
                    style={{
                      width: 340,
                      height: 340,
                    }}
                    src={'./assets/empty-report.png'}
                    alt=""
                    preview={false}
                  />
                </div>
                <div>
                  在生成报告前，您需要先
                  <Button
                    type="link"
                    onClick={() => {
                      props.switchTab?.('finance')
                    }}
                  >
                    归档财务方案
                  </Button>
                </div>
              </div>
            </div>
          )}
        {!(
          query.wordName === '' &&
          query.technicalName === '' &&
          query.financeName === '' &&
          !query.status &&
          (!dataSource || dataSource.length === 0)
        ) && (
          <Table
            loading={loading}
            scroll={{ x: 'max-content', y: props.scrollHeight || 'calc(100vh - 400px)' }}
            size="small"
            columns={columns}
            dataSource={dataSource}
            pagination={false}
            onChange={changeTable}
          />
        )}
      </div>
    </Spin>
  )
}
