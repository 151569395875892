import React from 'react'
import { Col, message, Modal, Row, Table, TableColumnProps, Typography } from 'antd'
import { getActionColumn } from '../../../../components/wl-table-page'
import { OnlineForm } from '../../../invest-dev/project-library-create/components/online-form'
import { FileService, ProjectDocumentService } from '../../../../apis/wl-service'

export interface ICommonTableProps<RecordType> {
  projectId?: string
  title?: string
  dataSource: RecordType[]
  columns?: TableColumnProps<RecordType>
  type?: 1 | 2 // 1 项目建议书 2 收资信息 // 内置两个表格数据
}

export default ({ projectId, title, dataSource, columns, type }: ICommonTableProps<any>) => {
  const [collectionInfoDialog, setCollectionInfoDialog] = React.useState<{ visible: boolean; dataSource?: any }>({
    visible: false,
    dataSource: {},
  }) // 收资表弹窗信息

  const computed = React.useMemo(() => {
    switch (type) {
      case 1:
        return {
          title: '项目建议书',
          columns: [
            { dataIndex: 'name', align: 'center', title: '项目报告' },
            {
              dataIndex: 'skillPlanName',
              align: 'center',
              title: '技术方案',
              render: (text: any) => {
                return <Typography.Link href={`/program/technical`}>{text}</Typography.Link>
              },
            },
            {
              dataIndex: 'financialPlanName',
              align: 'center',
              title: '财务方案',
              render: (text: any) => {
                return <Typography.Link href={`program/financial`}>{text}</Typography.Link>
              },
            },
            { dataIndex: 'generationTime', align: 'center', title: '生成时间' },
            { dataIndex: 'createName', align: 'center', title: '创建人' },
            getActionColumn([
              {
                key: 'download',
                label: '下载',
                onClick: (row: any) => {
                  ProjectDocumentService.oauth({
                    projectId,
                    code: 3,
                  }).then((res: any) => {
                    if (res?.data) {
                      FileService.zipCollection(row.id)
                    } else {
                      message.warn(res?.msg ?? `您无权限下载${row.name}哦~`)
                    }
                  }).catch((err: any) => {
                    console.warn('请求下载权限接口失败:', err)
                  })
                },
              },
            ]),
          ],
        }

      case 2:
        return {
          title: '收资信息',
          columns: [
            {
              dataIndex: 'name',
              align: 'center',
              title: '收资表',
              render: (text: any, row: any) => {
                return (
                  <Typography.Link
                    onClick={() => {
                      setCollectionInfoDialog({ dataSource: row, visible: true })
                    }}
                  >
                    {text}
                  </Typography.Link>
                )
              },
            },
            { dataIndex: 'createTime', align: 'center', title: '创建时间' },
            { dataIndex: 'updateTime', align: 'center', title: '更新时间' },
            { dataIndex: 'createName', align: 'center', title: '创建人' },
            getActionColumn([
              {
                key: 'download',
                label: '下载',
                onClick: (row: any) => {
                  ProjectDocumentService.oauth({
                    projectId,
                    code: 2,
                  }).then((res: any) => {
                    if (res?.data) {
                      if (row.fileUrl) {
                        window.location.href = row.fileUrl
                      }
                    } else {
                      message.warn(res?.msg ?? `您无权限下载${row.name}哦~`)
                    }
                  }).catch((err: any) => {
                    console.warn('请求下载权限接口失败:', err)
                  })
                },
              },
            ]),
          ],
        }

      default:
        return { title, columns }
    }
  }, [title, columns, type])

  return (
    <Row style={{ width: '100%' }}>
      <Col flex={1}>
        <Typography.Title level={5}>{computed.title}</Typography.Title>
      </Col>
      <Col>
        总计表格：<Typography.Text type="warning">{dataSource?.length ?? 0}</Typography.Text>
      </Col>
      <Col span={24} style={{ marginTop: 8 }}>
        <Table
          rowKey={(...args: any[]) => args[1]}
          bordered
          className="bordered-table"
          columns={computed.columns as any[]}
          dataSource={dataSource || []}
          pagination={false}
          locale={{ emptyText: '暂无数据' }}
          size="small"
        />
        {type === 2 ? (
          <Modal
            width={1000}
            title={`${collectionInfoDialog.dataSource?.name ?? ''}（精简版）`}
            footer={null}
            visible={collectionInfoDialog.visible}
            onCancel={() => {
              setCollectionInfoDialog({ visible: false })
            }}
          >
            <OnlineForm dataSource={collectionInfoDialog.dataSource} editable={false} />
          </Modal>
        ) : null}
      </Col>
    </Row>
  )
}
