import React from 'react'
import { Pagination } from 'antd'

import './index.less'

type IProps = {
  total: number
  pageNum: number
  pageSize: number
  onChange?: (pageNum: number, pageSize: number) => void
}

const CustomPagination = React.memo((props: IProps) => {
  const { total, pageNum, pageSize, onChange } = props

  return (
    <div className="pagination-wrapper">
      <Pagination
        className="left-pagination"
        size="small"
        total={total}
        showTotal={(total) => {
          return `共 ${total} 条`
        }}
        showSizeChanger
        current={pageNum}
        pageSize={pageSize}
        defaultPageSize={20}
        pageSizeOptions={['10', '20', '50']}
        onChange={onChange}
        onShowSizeChange={(pageNum, pageSize) => onChange?.(1, pageSize)}
        locale={{ items_per_page: '' }}
      />
      <Pagination
        size="small"
        total={total}
        current={pageNum}
        defaultPageSize={20}
        pageSize={pageSize}
        showQuickJumper
        showSizeChanger={false}
        locale={{ jump_to: '前往' }}
        onChange={onChange}
      />
    </div>
  )
})

export default CustomPagination
