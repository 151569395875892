import React from 'react'
import { useParams } from 'react-router-dom'
import { Button, Dropdown, Menu, Modal, Space, Typography } from 'antd'
import { DownOutlined } from '@ant-design/icons'
import {
  ProjectProductionForm,
  ProjectCostForm,
  InvestPlanForm,
  PlanExecuteForm,
} from '../../../project-entry/project-moudle-detail/components'
import { StateContext, SubmitType, IFormRef } from './StateContext'

export interface MyFormProps {
  componentRef: React.MutableRefObject<IFormRef>
  onNext: () => void
}

export default ({ componentRef, onNext }: MyFormProps) => {
  const params = useParams()
  const formRef = React.useRef<IFormRef>() // 表单ref
  const { dispatch } = React.useContext<any>(StateContext)
  const [current, setCurrent] = React.useState<'1' | '2' | '3' | '4'>('1') // 当前tab
  const [currentCost, setCurrentCost] = React.useState('1') // 造价当前项

  // 暴漏方法
  React.useImperativeHandle(componentRef, () => ({
    getName: () => formRef.current?.getName?.() ?? '',
    submit: (options: SubmitType) => {
      formRef.current?.submit({
        ...options,
        success: (data: any) => {
          if (options?.isDraft) {
            return
          }

          options?.success?.(data)

          if (current === '4') {
            onNext()
          } else {
            setCurrent((prev: string) => (+prev + 1).toString() as '2' | '3' | '4')
          }
        },
      })
    },
  }))

  React.useEffect(() => {
    // 是否显示保存草稿按钮
    dispatch({ type: 'visibleDraft', payload: !params.id })

    // 是否显示确定按钮
    dispatch({ type: 'visibleSubmit', payload: true })
  }, [params])

  const btnGroup = React.useMemo(() => {
    return [
      { key: '1', label: '新建建设' },
      {
        key: '2',
        label: '新建造价',
        children: [
          { key: '1', label: '可研预算' },
          { key: '2', label: '初设概算' },
          { key: '3', label: '竣工结算' },
        ],
      },
      { key: '3', label: '新建投资计划' },
      { key: '4', label: '新建投资进度' },
    ]
  }, [])

  // 当前tab内容
  const content = React.useMemo(() => {
    switch (current) {
      case '1':
        return <ProjectProductionForm ref={formRef} id={params.id} editable={true} />

      case '2':
        return <ProjectCostForm ref={formRef} t={currentCost} id={params.id} editable={true} />

      case '3':
        return <InvestPlanForm ref={formRef} id={params.id} editable={true} />

      case '4':
        return <PlanExecuteForm ref={formRef} id={params.id} editable={true} />

      default:
        return null
    }
  }, [current, currentCost, params.id])

  return (
    <>
      <Space direction="horizontal" style={{ margin: '16px 24px' }}>
        {btnGroup.map((item: any) => {
          if (item.children?.length > 0) {
            return (
              <Dropdown
                key={item.key}
                overlay={
                  <Menu
                    items={item.children}
                    onClick={({ key }: any) => {
                      setCurrent('2')
                      setCurrentCost(key)
                    }}
                  />
                }
              >
                <Button type={current === item.key ? 'primary' : 'default'}>
                  <Space>
                    {item.label}
                    <DownOutlined />
                  </Space>
                </Button>
              </Dropdown>
            )
          }
          return (
            <Button
              key={item.key}
              type={current === item.key ? 'primary' : 'default'}
              onClick={() => {
                let prev = btnGroup?.find((v: any) => v.key === current)?.label ?? ''
                Modal.confirm({
                  title: '温馨提示',
                  content: (
                    <Typography.Paragraph>
                      从<Typography.Text type="danger"> {prev} </Typography.Text>
                      切换到
                      <Typography.Text type="success"> {item.label} </Typography.Text>
                      后您当前的修改将会消失，您确定继续吗？
                    </Typography.Paragraph>
                  ),
                  onOk: () => {
                    setCurrent(item.key)
                  },
                })
              }}
            >
              {item.label}
            </Button>
          )
        })}
      </Space>

      <div style={{ padding: '0px 24px 24px', height: 'calc(100% - 76px)', overflow: 'auto' }}>{content}</div>
    </>
  )
}
