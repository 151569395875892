import React from 'react'
import {
  Form,
  Input,
  Button,
  Radio,
  DatePicker,
  Modal,
  InputNumber,
  Collapse
} from 'antd';
import moment from 'moment';
import { useParams } from 'react-router-dom'

import { LineChart } from "../components/LineCharts"
import { Electric } from "../components/Electric"
import { CustomIcons } from '../../../../../global'
import { ProjectServiceHistory } from '../../../../../apis/project-service-history'
import { ProjectApi } from '../../../../../services'
import { ELEC_CHARTS_COLORS } from "../../../../../utils/constant";
import { Init_empty_Data } from "./EmptyData";
import "./index.less"
import { ElectricityObservable } from '../index'
import { KlfDateRangePicker } from './klf-date-range-picker'

const { RangePicker } = DatePicker;
const { Panel } = Collapse;

const translateMap: { [key: string]: string } = {
  // electricityPrice: '电价版本',
  // userType: '用电类型',
  // voltageLevel: '电压等级',
  // isTime: '是否分时',
  chargeType: '收费方式',
  basicEnergyChargePattern: '基本电费收费方式',
}


const convert = (data: any) => {
  return {
    ...data,
    chargeType: data.chargeType?.toString(),
    basicEnergyChargePattern: data?.basicEnergyChargePattern?.toString(),
    seasonReqList: data.seasonReqList.map((o: any) => {
      return {
        ...o,
        timeFrames: o.timeFrames.map(oo => {
          return {
            month: [moment(`2000-${oo.startMd}`, 'YYYY-MM-DD'), moment(`2000-${oo.endMd}`, 'YYYY-MM-DD')]
          }
        }),
        timeSharePrices: o.timeSharePrices.map(oo => {
          return {
            ...oo,
            time: [
              moment(`${oo.startTime}`, 'HH:mm'),
              oo.endTime === '24:00' ? moment(`00:00`, 'HH:mm').add(1, 'days') : moment(`${oo.endTime}`, 'HH:mm')
            ],
          }
        })
      };
    })
  }
}

export const formatConvertEleData = convert;

export const CreateElectricity = () => {
  const [form] = Form.useForm();
  const [values, setValues] = React.useState<any>({});

  const colors = ELEC_CHARTS_COLORS;
  const params = useParams();
  const [config, setConfig] = React.useState<{ [key: string]: any }>({
    chargeType: {1: "单一制", 2: "两部制"},
  })

  const [isEmptyTemp, setIsEmptyTemp] = React.useState(true); // true: 新建空白自定义电价

  const [result, setResult] = React.useState<any>({
    electricityPrice: '',
    userType: '',
    voltageLevel: '',
    isTime: '',
    chargeType: '',
    basicEnergyChargePattern: '',
  })
  const [chartData, setChartData] = React.useState<any>()
 
  React.useEffect(()=>{
    const isEmptyTemp = params.customId === "-1";
    setIsEmptyTemp(isEmptyTemp);
    initEmptyTempData()
    if(params.customId === "-1"){
      // 空白模板
      initSeasonReqList()
    } else if(["0","1"].includes(params.customId)){
      // 目录模板/标杆燃煤模板
      queryTempCustom(params.customId);
    } else {
      // 其他自定义模板
      getResult()
    }
    
    getCustomNameIdList()
  }, [params.customId])

  // 初始化电量电价
  const initSeasonReqList = () => {
    const list = [];
    // list[0] = {
    //   seasonName: "不分季节",
    //   timeFrames: [
    //     {
    //       month: [moment('2000/01/01', "YYYY/MM/DD"), moment('2000/12/31', "YYYY/MM/DD")]
    //     }
    //   ],
    //   timeSharePrices: [
    //     {
    //       time: [moment('00:00', "HH:mm"), moment('23:45', "HH:mm")],
    //       price: "0"
    //     }
    //   ]
    // }
   
    form.setFieldsValue({
      seasonReqList: list,
    })
  }

  const addRootField = (add: Function, ) => {
    add({
      seasonName: "",
      timeFrames: [
        {
          month: [moment('2000/01/01', "YYYY/MM/DD"), moment('2000/12/31', "YYYY/MM/DD")]
        }
      ],
      timeSharePrices: [
        {
          time: [moment('00:00', "HH:mm"), moment('00:00', "HH:mm").add(1, 'days')],
          price: "0"
        }
      ]
    }, 0)
  }

  // 初始化空白模版图表数据
  const initEmptyTempData = () => {
    const data = Init_empty_Data
    setChartData({
      // charts: data.charts,
      charts: {},
      time: data.time,
    })
  }

  React.useEffect(()=>{
    getConfig()
  }, [isEmptyTemp])

  const queryTempCustom = (type: string) => {
    const api = new ProjectApi();
    api.i3060BorderElectricityQueryTempCustomGet({
      type,
      projectId: params.id!,
    }).then(resp => {
      const data = convert(resp.data);
      onValuesChange({}, data);
      form.setFieldsValue({ ...data });
    })
  }
  
  // 获取自定义电价信息
  const getResult = (callback?: Function) => {
    const api = new ProjectApi();
    api.i3060BorderElectricityQueryCustomProjectIdGet({
      customId: params.customId!,
      projectId: params.id!,
    }).then((resp) => {
      const data: any = resp.data[0];
      const { electricityPrice, userType, voltageLevel, isTime, chargeType, basicEnergyChargePattern=2 } = data
      setResult({
        userType,
        voltageLevel,
        isTime,
        chargeType,
        basicEnergyChargePattern,
        electricityPrice,
      });

      setChartData({
        basicEnergyCharge: data.basicEnergyCharge,
        updateTime: data.updateTime,
        charts: data.charts,
        time: data.time,
      })


      const dt = convert(data.reqJson);
      onValuesChange({}, dt);
      form.setFieldsValue({
        ...dt,
        customName: (data as any).customName,
      });
      //
      // form.setFieldsValue({
      //   ...resp.data
      // })
      // form.setFieldsValue({
      //   chargeType: chargeType.toString(),
      //   basicEnergyChargePattern: basicEnergyChargePattern.toString(),
      //   basicEnergyCharge: resp.data.basicEnergyCharge,
      // })
      callback?.()
    })
    callback?.()
  }

  // 获取字段配置信息
  const getConfig = () => {
    ProjectServiceHistory.option({
      projectId: params.id!,
    })
      .then((resp) => {
        const cfg: any = {}
        Object.keys(resp.data).forEach((key) => {
          const val = resp.data[key]
          if (Array.isArray(val)) {
            const obj: any = {}
            val.forEach((o) => {
              obj[o] = o === true ? '是' : o === false ? '否' : o
            })
            cfg[key] = obj
          } else {
            cfg[key] = resp.data[key]
          }
        })

        setConfig(cfg)
      })
      .catch(() => {
        setConfig({ ...config })
      })
      .finally(() => {})
  }

  /**
   * 查询自定义电list名称和id
   */
   const getCustomNameIdList = () => {
    ProjectServiceHistory.queryCustomNameIdList({projectId: params.id!})
      .then(({data = []})=>{
        if(params.type === 'add'){
          form.setFieldsValue({
            customName: `自定义电价${data.length + 1}`,
          })
        }
      })
  }

  const onValuesChange = (_:any, allValues:any) => {
    setValues(allValues);
  };

  const disabledDate = (date: moment.Moment) => {
    const start = moment('2000-01-01')
    const end = moment('2001-01-01')
    if (date.isBefore(start) || !date.isBefore(end)) {
      return true
    }
    return false
  }

  // 日期选择
  const renderMonthForm = (pFields: any) => {
    return (
      <Form.List name={[pFields.name, 'timeFrames']} initialValue={['']}>
        {(fields, { add, remove }) => (
          <>
            {fields.map(({ key, name, ...restField }) => (
              <div key={key} style={{ display: 'flex' }}>
                <Form.Item
                  {...restField}
                  name={[name, 'month']}
                  rules={[
                    {
                      required: true,
                      message: '请选择日期区间',
                    },
                  ]}
                  style={{
                    width: "280px",
                    marginBottom: "16px"
                  }}
                >
                  <RangePicker
                    getPopupContainer={() => document.getElementById('createElectricityForm')}
                    disabledDate={disabledDate}
                    format={'MM/DD'} 
                    style={{width: "100%"}}
                    dropdownClassName="tech-rangePicker"
                    // disabledDate={disabledDate}
                    // onChange={(val) => {
                    //   if(!val) {
                    //     return;
                    //   }
                    //   const valArr = form.getFieldValue(['seasonReqList', pFields.name, 'timeFrames']);
                    //   const valLength = valArr.length;
                    //   const [begin, end] = val;
                    //   if(!begin || !end) {
                    //     return;
                    //   }
                    //   if(name < valLength - 1) {
                    //     valArr[name + 1].month[0] = end;
                    //   }
                    //   if(name > 0) {
                    //     valArr[name - 1].month[1] = begin;
                    //   }
                    // }}
                  />
                </Form.Item>
                {fields.length > 1 && (
                  <Button 
                  className='deleted-btn'
                  type="link" 
                  icon={<CustomIcons type={"klf-juxing1"}/>}
                  onClick={() => {
                    remove(name)
                  }}
                  />
                )}
              </div>
            ))}
            {fields?.length < 8 ? (
            <div style={{
              position: "relative",
              textAlign: "center"
            }}>
              <Button 
              style={{
                position: "relative",
                background: "#fff",
                zIndex: 1,
              }} 
              onClick={() => {
                const valArr = form.getFieldValue(['seasonReqList', pFields.name, 'timeFrames']);
                const lastVal = valArr[valArr.length - 1].month[1];
                valArr[valArr.length - 1].month = [valArr[valArr.length - 1].month[0], undefined];
                form.setFields([
                  {
                    name: ['seasonReqList', pFields.name, 'timeFrames'],
                    value: [...valArr],
                  }
                ]);
                add({
                  month: [undefined, lastVal],
                });
              }
            }
              type="text" 
              icon={<CustomIcons type={'klf-tianjia'}/>}
            >
              添加日期
              </Button>
              <div style={{
                position: "absolute",
                top: "50%",
                left: "0",
                width: "100%",
                borderBottom: "1px dashed #EBEBEB",
              }}/>
            </div>
            ) : null}
          </>
        )}
      </Form.List>
    )
  };
  
  // 分时电价
  const renderTimeForm = (pFields:any) => {
    return (
      <Form.List name={[pFields.name, 'timeSharePrices']} initialValue={['']}>
        {(fields, { add, remove }) => (
          <>
            {fields.map(({ key, name, ...restField }) => {
              const formField = form.getFieldValue(['seasonReqList', pFields.name, 'timeSharePrices']);
              const limitArr: [moment.Moment, moment.Moment] = [
                moment('00:00', 'HH:mm'),
                moment('00:00', 'HH:mm').add(1, 'days'),
              ];
              for(let i = name - 1; i >= 0; i--) {
                if(formField[i].time[1] !== undefined) {
                  limitArr[0] = moment(formField[i].time[1].valueOf());
                  break;
                }
                if(formField[i].time[0] !== undefined) {
                  limitArr[0] = moment(formField[i].time[0].valueOf())
                  break;
                }
              }
              for(let i = name + 1; i < formField.length; i++) {
                if(formField[i].time[0] !== undefined) {
                  limitArr[1] = moment(formField[i].time[0].valueOf());
                  break;
                }
                if(formField[i].time[1] !== undefined) {
                  limitArr[1] = moment(formField[i].time[1].valueOf());
                  break;
                }
              }

              return (
                <div key={key} style={{ display: 'flex' }}>
                  <Form.Item
                    {...restField}
                    name={[name, 'time']}
                    rules={[{ required: true, message: '请选择' }]}
                    style={{
                      marginBottom: '16px',
                      marginRight: '12px'
                    }}
                  >
                    <KlfDateRangePicker
                      limit={limitArr}
                      step={15}
                      disabled={[name === 0, name === formField.length - 1]}
                      onChange={(val) => {
                        if (!val) {
                          return
                        }
                        const valLength = formField.length
                        const [begin, end] = val
                        if (name < valLength - 1) {
                          if(end) {
                            formField[name + 1].time[0] = end
                          }
                        }
                        if (name > 0) {
                          if(begin) {
                            formField[name - 1].time[1] = begin
                          }
                        }
                      }}
                    />
                  </Form.Item>
                  <Form.Item
                    {...restField}
                    name={[name, 'price']}
                    rules={[{ required: true, message: '请选择' }]}
                    style={{
                      marginBottom: "16px"
                    }}
                  >
                    <InputNumber
                      className='min-price-input'
                      style={{width: '120px'}}
                      placeholder="请输入"
                      size="middle"
                      addonAfter="元/kWh"
                      controls={false}
                      precision={4}
                      min={0.0000}
                      max={1000000}
                    />

                  </Form.Item>
                  {fields.length > 1 && (
                    <Button
                      className='deleted-btn'
                      type="link"
                      icon={<CustomIcons type={"klf-juxing1"}/>}
                      onClick={() => {
                        const valArr = form.getFieldValue(['seasonReqList', pFields.name, 'timeSharePrices']);
                        if (name < valArr.length - 1) {
                          valArr[name + 1].time[0] = valArr[name].time[0];
                        } else {
                          valArr[valArr.length - 2].time[1] = valArr[valArr.length - 1].time[1];
                        }
                        remove(name)
                      }}
                    />
                  )}
                </div>
              )
            })}
            {fields?.length < 24 ? (
            <div style={{
              position: "relative",
              textAlign: "center"
            }}>
              <Button 
              style={{
                position: "relative",
                background: "#fff",
                zIndex: 1,
              }}
              onClick={() => {
                  const valArr = form.getFieldValue(['seasonReqList', pFields.name, 'timeSharePrices']);
                  const lastVal = valArr[valArr.length - 1].time[1];
                  valArr[valArr.length - 1].time = [valArr[valArr.length - 1].time[0], undefined];
                  form.setFields([
                    {
                      name: ['seasonReqList', pFields.name, 'timeSharePrices'],
                      value: [...valArr],
                    }
                  ]);
                  add({
                    // price: valArr[valArr.length - 1].price,
                    price: "",
                    time: [undefined, lastVal],
                  });
                }
              }
              type="text" 
              icon={<CustomIcons type={'klf-tianjia'}/>}
            >
              添加分时电价
              </Button>
              <div style={{
                position: "absolute",
                top: "50%",
                left: "0",
                width: "100%",
                borderBottom: "1px dashed #EBEBEB",
              }}/>
            </div>
            ) : null}
          </>
        )}
      </Form.List>
    )
  };

  const electricData = React.useMemo(() => {
    if (!values?.seasonReqList?.length) {
      return [];
    }
    return values?.seasonReqList?.map((v:any, index: number) => ({
      name: v?.seasonName,
      idx: index,
      values: v.timeFrames?.map(o => o.month),
    }));
  }, [values?.seasonReqList]);

  const getFsInfo = (name?: number) => {
    const valArr = form.getFieldValue('seasonReqList');
    const res = valArr.filter((o, idx) => name !== undefined ? idx === name : true).map(o => {
      return o.timeSharePrices.map(oo => ({
        ...oo,
        name: o.seasonName,
      }))
    })
    return res;
  }

  const handleFinish = (srcValue: any) => {
    const values = {
      ...srcValue,
      customId: params.type === 'edit' ? params.customId : undefined,
      customName: form.getFieldValue('customName'),
      seasonReqList: srcValue.seasonReqList.map(o => {
        return {
          ...o,
          timeFrames: o.timeFrames.map(oo => {
            return {
              startMd: oo.month?.[0]?.format('MM-DD'),
              endMd: oo.month?.[1]?.format('MM-DD'),
            }
          }),
          timeSharePrices: o.timeSharePrices.map((oo, idx) => {
            const isLast = idx === o.timeSharePrices.length - 1;
            return {
              price: oo.price,
              startTime: oo.time?.[0]?.format('HH:mm'),
              endTime: isLast ? '24:00' : moment(oo.time?.[1]).add(0, 'minutes').format('HH:mm'),
            }
          })
        }
      })
    }

    const api = new ProjectApi();
    api.i3060BorderElectricitySaveCustomProjectIdPost({
      projectId: params.id!,
    }, {
      ...values,
    }).then((resData) => {
      if(resData.code === 200){
        location.assign(`#/program/edge/${params.id}/electricity/detail/${resData["data"]}`);
        ElectricityObservable.next('load');
      }
    }).catch(() => {})
  }

  const showBasicEnergyCharge = () => {
    if(!form.getFieldValue("chargeType")) return false
    // 收费方式 一部制
    if(form.getFieldValue("chargeType") == "1") return false
    return true;
  }

  const showBasicPrice = () => {
    if(!form.getFieldValue("chargeType")) return false
    if(form.getFieldValue("chargeType") == "2" && !form.getFieldValue("basicEnergyChargePattern")){
      return false
    }

    return true;
  }

  const genExtra = (remove, fields, field ) => (
    <>
    {fields.length > 1 && (
        <Button
          className='deleted-btn'
          type="link"
          icon={<CustomIcons type={"klf-juxing1"}/>}
          onClick={() => {
            Modal.confirm({
              title: '删除',
              icon: <CustomIcons type="klf-alarm1" />,
              content: '确认是否删除当前时段电价',
              okText: '确认',
              cancelText: '取消',
              onOk: ()=> {
                remove(field.name);
              }
            });
          }}
          style={{
            width: "18px"
          }}
        />
      )}
    </>
  );

  const genHeader = (field, i) => {
    return (
            <div 
              onClick={event => {
                event.stopPropagation();
              }}
              style={{
                display: "flex",
                marginLeft: "8px",
                boxSizing: "border-box"
              }}
            >
              <span style={{
                display: 'inline-block',
                width: "16px",
                height: "16px",
                borderRadius: "2px",
                margin: "8px 10px 0 0",
                background: colors[i]
              }} />
              <Form.Item
                rules={[
                  {
                    validator: (rule: any, value: any) => {
                      return new Promise((resolve, reject) => {
                        let length = 0
                        for (let i = 0; i < value.length; i++) {
                          if (/[\u4E00-\u9FA5]+/.test(value[i])) {
                            length += 2
                          } else {
                            length += 1
                          }
                        }
                        const seasonNames = values?.seasonReqList?.map((o, idx) => {
                          if(i !== idx) return o.seasonName
                        })

                        if(!value){
                          reject('请输入时段名称')
                        } else if(!/^[\u4E00-\u9FA5A-Za-z0-9]+$/.test(value)){
                          reject('仅支持中文、英文大小写、数字')
                        }else if(seasonNames.includes(value)){
                          reject('时段名称不能重复')
                        } else if(length > 17){
                          reject('时段名称长度1~16,中文占2个字符')
                        } else {
                          resolve('')
                        }
                      })
                    },
                  },
                ]}
                name={[field.name, 'seasonName']}
                style={{ width: "300px", marginBottom: "8px"}}
              >
                <Input
                  placeholder="请输入时段名称"
                  allowClear
                  style={{
                    borderRadius: "2px"
                  }}
                />
              </Form.Item>
            </div>
    )
  }

  return (
    <div className="edit-container klf-scrollbar">
    <Form
      id="createElectricityForm"
      form={form}
      name="priceEdit"
      onValuesChange={onValuesChange}
      scrollToFirstError
      onFinish={
        (values: any) => {
          handleFinish(values)
        }
      }
    >
      {/* 隐藏域 projectId */}
      <Form.Item
          name="projectid"
          initialValue={params.id!}
          hidden
        >
          <Input />
        </Form.Item>
        {/* 隐藏域 customId； 编辑状态时，放开customId */}
        {
          params.type === "edit" &&
          <Form.Item
            name="customId"
            initialValue={params.customId}
            hidden
          >
            <Input />
          </Form.Item>
        }
      <div style={{
        marginTop: "30px"
      }}>
         
          <Form.Item
            name="customName"
            initialValue=""
            rules={[
              {
                required: true,
                message: '请输入电价名称',
              },
              {
                pattern: /^[\u4E00-\u9FA5A-Za-z0-9]+$/,
                message: '仅支持中文、英文大小写、数字',
              },
              {
                validator: (rule: any, value: any) => {
                  return new Promise((resolve, reject) => {
                    let length = 0
                    for (let i = 0; i < value.length; i++) {
                      if (/[\u4E00-\u9FA5]+/.test(value[i])) {
                        length += 2
                      } else {
                        length += 1
                      }
                    }
                    if (length > 0 && length < 17) {
                      resolve('')
                    } else {
                      reject('名称长度1~16,中文占2个字符')
                    }
                  })
                },
              },
            ]}
          >
            <Input onBlur={() => {
              // if(form.validateFields(['customName'])) {
              //   setEditName(false)
              // }
            }} allowClear={true} size="large" style={{
              width: "280px",
              borderRadius: "2px"
            }}/>
          </Form.Item>
        
      </div>
      <div style={{
        display: "flex",
        marginTop: "20px",
        justifyContent: "space-between",
        borderBottom: "1px dashed #EBEBEB"
      }}>
        <div className='left-content'>
          {showBasicPrice() && 
            <div className="price_title">
              {values.basicEnergyChargePattern?.toString() === '2' ? '需量' : '容量'}电价：
              <span className="price_sub_title">
                {values.basicEnergyChargePattern?.toString() === '2' ? values?.demandCharge || 0 : values?.capacityCharge || 0}
                元/{values.basicEnergyChargePattern?.toString() === '2' ? 'kW·月' : 'kVA·月'}
              </span>
            </div>
          }
        </div>
        <div style={{
          width: "462px"
        }}>
          <Form.Item 
            name="chargeType"
            label={`${translateMap["chargeType"]}`}
            rules={[
              { required: true, message: `请选择${translateMap["chargeType"]}` }
            ]}
            style={{
              marginBottom: "16px"
            }}
          >
            <Radio.Group 
              onChange={(e) => {
                const _res = result;
                _res.chargeType = e.target.value
                _res.basicEnergyChargePattern = ""
                _res.basicEnergyCharge = ""
                
                form.setFieldsValue({basicEnergyChargePattern: ""})
                form.setFieldsValue({basicEnergyCharge: ""})
                setResult(_res)
                setConfig({ ...config })
              }}
            >
              {config["chargeType"] &&
              Object.keys(config["chargeType"]).map((itemKey, idx) => {
                return (
                  <Radio value={itemKey} key={`${idx}-${itemKey}`}>
                    {config["chargeType"][itemKey]}
                  </Radio>
                )
              })}
            </Radio.Group>
          </Form.Item>
          { showBasicEnergyCharge() &&
          <>
          {
            config && Object.keys(config).map((key, index) => {
              return  (
                <> 
                  {(key !== "chargeType" && translateMap[key]) && <>
                  <Form.Item 
                    key={index + key}
                    name={key}
                    label={`${translateMap[key]}`}
                    rules={[
                      { required: true, message: `请选择${translateMap[key]}` }
                    ]}
                    >
                    <Radio.Group 
                      onChange={(e) => {
                        const _res = result;
                        if(key === "basicEnergyChargePattern"){
                          // 需量电价
                          _res.basicEnergyChargePattern = e.target.value
                        } else if(key === "chargeType"){
                          _res.chargeType = e.target.value
                        }
                        setResult(_res)
                        setConfig({ ...config })
                      }}
                    >
                      {config[key] &&
                      Object.keys(config[key]).map((itemKey, idx) => {
                        return (
                          <Radio value={itemKey} key={`${idx}-${itemKey}`}>
                            {config[key][itemKey]}
                          </Radio>
                        )
                      })}
                    </Radio.Group>
                  </Form.Item>
                  </>}
                </>
              )
            })
          }
          </>
        }
        {showBasicEnergyCharge() && form.getFieldValue("basicEnergyChargePattern") && (
          <Form.Item 
            name={`${values.basicEnergyChargePattern?.toString() === '2' ? 'demandCharge' : 'capacityCharge'}`}
            label={`${values.basicEnergyChargePattern?.toString() === '2' ? '需量' : '容量'}电价`}
            rules={[{ required: true, message: `请设${values.basicEnergyChargePattern?.toString() === '2' ? '需量' : '容量'}电价` }]}
            initialValue={values?.basicEnergyCharge || 0}
          >
            <InputNumber
                  style={{width: '355px'}}
                  placeholder="请输入"
                  size="middle"
                  addonAfter={`元/${values.basicEnergyChargePattern?.toString() === '2' ? 'kW·月' : 'kVA·月'}`}
                  controls={false}
                  precision={4}
                  min={0.0000}
                  max={1000000}
                />
          </Form.Item>
        )}
        </div>
      </div>
      <div style={{
        display: "flex",
        marginTop: "20px",
        justifyContent: "space-between",
      }}>
        <div style={{
          flex: 1
        }}>
          <div style={{
            fontSize: "16px",
            color: "#262E3F",
            height: "40px",
            lineHeight: "40px",
            marginBottom: "24px"
          }}>电量电价</div>
          <Electric colors={colors} data={electricData} />
          {chartData &&
            <LineChart showSymbol={true} isEdit={params.type === 'edit'} colors={colors} chartInfo={chartData} fsInfo={getFsInfo()}/>
          }
        </div>
        {/* 电量电价-编辑 */}
        <div style={{
          width: "462px",
          height: "529px",
          background: "rgba(23, 158, 117, 0.03)",
          borderRadius: "4px",
          padding: "10px 16px"
        }}>
          <Form.List name="seasonReqList" initialValue={[{}]}>
            {(fields, { add, remove }) => {
              return (
                <>
                  <div style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignContent: "center",
                    marginBottom: "12px",
                    lineHeight: "22px",
                    height: "40px"
                  }}>
                    <div style={{
                      fontSize: "16px",
                      color: "#262E3F",
                    }}>电量电价-编辑</div>
                    {fields?.length < 6 && <Button type="primary" ghost onClick={() => {
                      addRootField(add)
                    }}>添加</Button>}
                  </div>
                  <Collapse
                    defaultActiveKey={[0,1,2,3,4,5,6]}
                    expandIconPosition="right"
                    bordered={false}
                    className="fieldsCollapse klf-scrollbar"
                    expandIcon={()=>(<CustomIcons type={'klf-xiangxia'}/>)}
                    style={{
                      height: "452px",
                      overflowY: "auto"
                    }}
                  >
                    {fields.map((field, i) => (
                      <Panel header={genHeader(field, i)} key={field.key} extra={genExtra(remove, fields, field)} 
                      style={{
                        background: "#FFFFFF",
                        borderRadius: "2px",
                        padding: "10px 8px 0",
                        marginBottom: "10px"
                      }}
                      >
                        <>
                        <div style={{
                            width: "100%",
                            borderBottom: "1px dashed #EBEBEB",
                            marginBottom: "16px"
                          }}/>
                          <Form.Item
                            label="日期选择"
                            required={true}
                            style={{ marginBottom: "16px"}}
                            className="fixed-label"
                          >
                            {renderMonthForm(field)}
                          </Form.Item>
                          <Form.Item
                            className="fixed-label"
                            label="分时电价"
                            required={true}
                            style={{
                              marginBottom: "16px",
                            }}
                          >
                            {renderTimeForm(field)}
                          </Form.Item>
                        </>
                      </Panel>
                      ))}
                  </Collapse>
                 
                </>
              );
            }}
          </Form.List>
        </div>
      </div>
      
      <Form.Item style={{
        padding: "58px 0 30px 46px"
      }}>
        <Button size="large" type="primary" htmlType="submit" style={{ marginRight: "10px"}}>
          确定
        </Button>
        <Button size="large" onClick={() => {
          history.back();
        }}>
          取消
        </Button>
      </Form.Item>
    </Form>
    </div>
  );
}
