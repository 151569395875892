import React from 'react'
import { Alert, Form, Select, Table, Typography } from 'antd'
import UploadFile from '../../../../components/wl-upload'
import { getActionColumn } from '../../../../components/wl-table-page/components/table-action'
import { CollectionConService } from '../../../../apis/wl-service'
import store, { SubmitType } from './store'

// 上传收资表
export default ({ componentRef }: any) => {
  const unmountRef = React.useRef<boolean>(false)
  const [form] = Form.useForm()
  const [disabled, setDisabled] = React.useState<boolean>(!store.projectId) // 上传是否禁用
  const [projectOptions, setProjectOptions] = React.useState<any>() // 项目列表
  const [dataSource, setDataSource] = React.useState([]) // 表格数据
  const storeKey = React.useMemo(() => store.keyMap.collectionConUpload, [store]) // 用于本地数据存储的键值

  React.useImperativeHandle(componentRef, () => {
    return {
      getName: () => storeKey,
      submit: async (options: SubmitType) => {
        try {
          let values: any = await form.validateFields()
          values = formatter(values, 'params')
          store.submit[storeKey]({
            ...options,
            values,
          })
        } catch (e) {
          console.warn('validateFields:', e)
        }
      },
    }
  })

  // 设置组件挂载状态
  React.useEffect(() => {
    unmountRef.current = false
    return () => {
      unmountRef.current = true
    }
  }, [])

  // 监听上传禁用状态
  React.useEffect(() => {
    const fn = () => {
      setDisabled(!store.projectId)
    }
    store.subscribe(fn)
    return () => {
      store.unsubscribe(fn)
    }
  }, [])

  // 获取项目列表
  React.useEffect(() => {
    const prevProjectOptions = store.getOptions('developList')

    // 未选中项目时
    if (!prevProjectOptions?.length) {
      // 获取项目名称
      CollectionConService.basicData()
        .then((resp: any) => {
          if (resp?.data) {
            const options = {
              ...resp.data,
              developList: resp.data.developList?.map(({ id, name }: any) => {
                return { id, name, fullName: name }
              }),
            }
            store.setOptions(options, true)
            !unmountRef.current && setProjectOptions(options.developList)
          } else {
            !unmountRef.current && setProjectOptions([])
          }
        })
        .catch((e) => {
          console.warn(`获取项目库项目列表失败：`, e)
        })
    } else {
      setProjectOptions(prevProjectOptions)
    }
  }, [])

  // 获取详情
  React.useEffect(() => {
    // 从缓存中恢复数据
    const cacheData = store.getItem(storeKey)
    if (cacheData) {
      form.setFieldsValue(formatter(cacheData, 'component'))
    } else {
      form.resetFields()
    }

    form.setFieldsValue({ projectId: store.projectId })

    if (cacheData?.__tableData) {
      setDataSource(cacheData.__tableData)
    } else {
      // 首次加载表格数据
      loadTableData().then()
    }
  }, [store.projectId])

  // 获取表格数据
  const loadTableData = async () => {
    const setValues = (values: any) => {
      store.setItem(storeKey, { ...store.getItem(storeKey), __tableData: values })
      !unmountRef.current && setDataSource(values)
    }

    if (!store.projectId) {
      setValues([])
      return
    }

    try {
      const resp: any = await CollectionConService.info({ projectId: store.projectId })
      if (resp?.code === 200) {
        setValues(resp.data ?? [])
      }
    } catch (e) {
      console.warn(`获取收资信息列表失败：`, e)
    }
  }

  // type=params作为参数发送到服务端
  // type=component转换数据用于组件渲染
  const formatter = (values: any, type: 'params' | 'component') => {
    values = { ...values }

    // 转换文件
    ;['file'].forEach((k: string) => {
      if (type === 'component') {
        values[k] = values[k] ? JSON.parse(values[k]) : []
      }
      if (type === 'params') {
        values[k] = values[k]?.length ? JSON.stringify(values[k]) : ''
      }
    })

    return values
  }

  const columns = React.useMemo(() => {
    return [
      {
        dataIndex: '_order',
        title: '序号',
        width: 80,
        fixed: 'left',
        align: 'center',
        render: (...args: any[]) => args[2] + 1,
      },
      { dataIndex: 'collectionTemplateName', title: '收资表', width: 160, fixed: 'left' },
      { dataIndex: 'projectName', title: '项目名称', width: 140 },
      { dataIndex: 'projectCode', title: '项目代码', width: 100 },
      { dataIndex: 'resourcetypename', title: '项目类型', width: 100 },
      { dataIndex: 'enterpriseNatureName', title: '企业性质', width: 100 },
      { dataIndex: 'mainBusiness', title: '主营业务', width: 100 },
      { dataIndex: 'propertyName', title: '产权证', width: 100 },
      { dataIndex: 'buildingAge', title: '建筑物年限(剩余)', width: 150 },
      getActionColumn([
        {
          key: 'delete',
          type: 'delete',
          getter: (row: any) => {
            return CollectionConService.remove({ id: row.id })
          },
          success: () => loadTableData(),
        },
      ]),
    ]
  }, [])

  return (
    <Form
      form={form}
      name="offlineForm"
      className="wl-form-item"
      layout="vertical"
      // onValuesChange={(changedValues, allValues) => {
      // store.onValuesChange(storeKey, { ...store.getItem(storeKey), ...formatter(allValues, 'params') })
      // }}
    >
      <Form.Item
        name="projectId"
        label="项目名称"
        hidden={!!store.projectId && store.getModuleName() !== storeKey}
        rules={[{ required: true, message: '请选择项目' }]}
      >
        <Select
          dropdownMatchSelectWidth={false}
          allowClear
          dropdownClassName="right-actions"
          placeholder="请选择"
          style={{ maxWidth: 300 }}
          showSearch
          optionFilterProp="children"
          filterOption={(input: string, option: any) => {
            return (option!.children as unknown as string).toLowerCase().includes(input.toLowerCase())
          }}
          onChange={(value: string) => {
            // 项目id变化
            store.setProjectId(value ?? '', storeKey)
          }}
        >
          {projectOptions?.map(({ id, name }: any) => {
            return <Select.Option key={id}>{name}</Select.Option>
          })}
        </Select>
      </Form.Item>

      <Form.Item
        required
        name="file"
        label={<Typography.Title level={5}>收资表上传</Typography.Title>}
        rules={[{ required: true, message: '请上传收资表' }]}
      >
        {disabled ? (
          <Alert type="warning" message="请先选择项目再上传收资表哦~" />
        ) : (
          <UploadFile
            accept=".docx,.doc,.pdf,.jpg,.png"
            extra="支持扩展名：.docx .pdf .jpg .png"
            extraWrap={false}
            maxCount={1}
            showProgress
            disabled={disabled}
            onChange={async (value) => {
              const fileId = value?.[0]?.fileId ?? ''
              if (!fileId) return

              try {
                const resp: any = await CollectionConService.upload({
                  projectId: store.projectId ?? '',
                  root: { fileId },
                })
                if (resp?.code === 200) {
                  await loadTableData()
                }
              } catch (e) {
                console.warn('上传收资表失败:', e)
              }
            }}
          />
        )}
      </Form.Item>
      <Form.Item>
        <Table rowKey="id" columns={columns as any} dataSource={dataSource} scroll={{ x: '100%' }} pagination={false} />
      </Form.Item>
    </Form>
  )
}
