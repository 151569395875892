import { ReloadOutlined, RightOutlined } from '@ant-design/icons'
import { message, Spin, Modal } from 'antd'
import { useEffect, useRef, useState } from 'react'
import { CSSTransition } from 'react-transition-group'

import { UserService } from '../../../apis/project-service-history'
import { aesEncrypt } from '../../../utils/utils'
import styles from '../index.module.scss'

interface ISize {
  height: number
  width: number
}

interface IVerifyProps {
  mode: string
  captchaType: string
  vSpace: number
  barSize: ISize
  showRefresh: string
  transitionWidth?: any
  finishText: string
  transitionLeft: string
  update: Function
  toggleVisible: Function
  widthDom: any
  visible?: boolean
  onModalCancel?: Function
}

const VerifySlide = (props: IVerifyProps) => {
  const { update, toggleVisible, onModalCancel } = props
  const sliderDom = useRef<any>(null)
  // 滑动方块
  const moveBlock = useRef<any>(null)

  const [loading, setLoading] = useState(false)
  // 图片和bar宽度
  const [imgWidth, setImgWidth] = useState(380)
  // 图片地址
  const [imgSrc, setImgSrc] = useState(null)
  // 图片地址
  const [barSrc, setBarSrc] = useState(null)
  // 后端返回的唯一token值
  const [token, setToken] = useState('')
   //后端返回的加密秘钥 字段
  const [secretKey, setSecretKey] = useState('')
  const [state, setState] = useState({
    blockSize: {
      width: 50,
      height: 50,
    },
    setSize: {
      imgHeight: 200,
      imgWidth: 380,
      barHeight: 40,
      barWidth: 380,
    },
    backImgBase: '', // 验证码背景图片
    blockBackImgBase: '', // 验证滑块的背景图片
    startMoveTime: '', //移动开始的时间
    endMovetime: '', //移动结束的时间
    tipsBackColor: '', //提示词的背景颜色
    captchaType: 'blockPuzzle',
    moveBlockBackgroundColor: 'rgb(255, 255, 255)',
    leftBarBorderColor: '',
    iconColor: '',
    barAreaLeft: 0,
    barAreaOffsetWidth: 0,
    startLeft: 0,
    moveBlockLeft: '',
    leftBarWidth: '100%',
    status: false, //鼠标状态
    isEnd: false, //是够验证完成
    passFlag: false,
    tipWords: '',
    iconClass: '',
    text: '向右滑动完成验证',
  })
  const isMouseDownRef = useRef<boolean>(false)
  // const loading = useRef<boolean>(true)

  // 初始话 uuid
  const uuid = async () => {
    var s = []
    var hexDigits = '0123456789abcdef'
    for (var i = 0; i < 36; i++) {
      s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1)
    }
    s[14] = '4' // bits 12-15 of the time_hi_and_version field to 0010
    s[19] = hexDigits.substr((Number(s[19]) & 0x3) | 0x8, 1) // bits 6-7 of the clock_seq_hi_and_reserved to 01
    s[8] = s[13] = s[18] = s[23] = '-'

    var slider = 'slider' + '-' + s.join('')
    var point = 'point' + '-' + s.join('')
    // 判断下是否存在 slider
    if (!localStorage.getItem('slider')) {
      localStorage.setItem('slider', slider)
    }
    if (!localStorage.getItem('point')) {
      localStorage.setItem('point', point)
    }
  }

  const getData = () => {
    setLoading(true)
    return UserService.get({
      root: {
        captchaType: state.captchaType,
        clientUid: localStorage.getItem('slider') || undefined,
        ts:Date.now()
      },
    })
      .then((res: any) => {
        if (res.data.repCode === '0000') {
          setToken(res?.data?.repData?.token)
          setSecretKey(res.data.repData.secretKey)
          setBarSrc(res.data.repData.jigsawImageBase64)
          setImgSrc(res.data.repData.originalImageBase64)
        } else if (res.data.repCode == '6201') {
          // 请求次数超限
          setState({
            ...state,
            leftBarBorderColor: '#d9534f',
            iconColor: '#fff',
            iconClass: 'icon-close',
            passFlag: false,
            tipWords: res?.data?.repMsg,
          })
          setImgSrc(null)
          setBarSrc(null)
          update && update('')
          toggleVisible && toggleVisible(false)
          setTimeout(() => {
            setState({
              ...state,
              tipWords: '',
            })
          }, 1000)
        }else if(res.data.repCode === '6112'){
          // 现阶段问题  第一次请求时可能失败
          refresh()
        } else {
          setImgSrc(null)
          setBarSrc(null)
          update && update('')
          toggleVisible && toggleVisible(false)
          message.error(res?.data?.repMsg)
          // 其他错误
          setState({
            ...state,
            leftBarBorderColor: '#d9534f',
            iconColor: '#fff',
            iconClass: 'icon-close',
            passFlag: false,
            tipWords: res?.data?.repMsg,
          })
          setTimeout(() => {
            setState({
              ...state,
              tipWords: '',
            })
          }, 1000)
        }
        setLoading(false)
      })
      .catch(() => {
        console.error('错误捕获')
        message.error('请求失败，请重试')
        update && update('')
        toggleVisible && toggleVisible(false)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const refresh = () => {
    getData().then(() => {
      setState({
        ...state,
        moveBlockLeft: '0px',
        leftBarWidth: '',
        text: '向右滑动完成验证',
        moveBlockBackgroundColor: '#fff',
        leftBarBorderColor: '',
        iconColor: '',
        status: false,
        isEnd: false,
      })
    })
  }

  const start = (e: any) => {
    e = e || window.event
    e.stopPropagation()
    let x = e.clientX
    if (!e.touches) {
      //兼容PC端
      x = e.clientX
    } else {
      //兼容移动端
      x = e.touches[0].pageX
    }
    isMouseDownRef.current = true
    state.startLeft = Math.floor(x - state.barAreaLeft)
    state.startMoveTime = `${+new Date()}` //开始滑动的时间
    if (state.isEnd == false) {
      setState({
        ...state,
        text: '',
        moveBlockBackgroundColor: '#337ab7',
        leftBarBorderColor: '#337AB7',
        iconColor: '#fff',
        status: true,
      })
    }
  }

  const move = (e: any) => {
    e = e || window.event
    if (isMouseDownRef.current && state.isEnd == false) {
      let x = e.clientX
      if (!e.touches) {
        //兼容PC端
        x = e.clientX
      } else {
        //兼容移动端
        x = e.touches[0].pageX
      }
      var bar_area_left = state.barAreaLeft
      var move_block_left = x - bar_area_left //小方块相对于父元素的left值
      if (move_block_left >= state.barAreaOffsetWidth - parseInt(`${parseInt(`${state.blockSize.width}`) / 2}`) - 2) {
        move_block_left = state.barAreaOffsetWidth - parseInt(`${parseInt(`${state.blockSize.width}`) / 2}`) - 2
      }
      if (move_block_left <= 0) {
        move_block_left = parseInt(`${state.blockSize.width / 2}`)
      }
      //拖动后小方块的left值
      const moveBlockLeft = move_block_left - state?.startLeft + 'px'
      const leftBarWidth = move_block_left - state?.startLeft + 'px'
      setState({
        ...state,
        moveBlockLeft,
        leftBarWidth,
      })
    }
  }

  const doReCheck = (x: any) => {
    let data = {
      captchaVerification: secretKey
        ? aesEncrypt(token + '---' + JSON.stringify({ x, y: 5.0 }), secretKey)
        : JSON.stringify(token + '---' + { x, y: 5.0 }),
    }
    update && update(data.captchaVerification)
    return Promise.resolve()
  }

  const end = () => {
    state.endMovetime = `${+new Date()}`
    //判断是否重合
    if (isMouseDownRef.current && state.isEnd == false) {
      var moveLeftDistance = parseInt((state.moveBlockLeft || '').replace('px', ''))
      moveLeftDistance = (moveLeftDistance * 310) / imgWidth
      isMouseDownRef.current = false
      let data = {
        captchaType: state.captchaType,
        token,
        pointJson: secretKey
          ? aesEncrypt(JSON.stringify({ x: moveLeftDistance, y: 5.0 }), secretKey)
          : JSON.stringify({ x: moveLeftDistance, y: 5.0 }),
      }

      // 一次验证滑动
      UserService.check({ root: data }).then(async (resp: any) => {
        const result = resp.data
        if (result.repCode == '0000') {
          // 二次验证保留result
          doReCheck(moveLeftDistance).then(() => {
            onModalCancel && onModalCancel()
            state.isEnd = true
            state.passFlag = true
            setState({
              ...state,
              tipWords: `${((Number(state.endMovetime) - Number(state.startMoveTime)) / 1000).toFixed(2)}s验证成功`,
            })
            setTimeout(() => {
              toggleVisible && toggleVisible(false)
              state.tipWords = '向右滑动完成验证'
            }, 1000)
          })
        } else {
          setState({
            ...state,
            isEnd: true,
            moveBlockBackgroundColor: '#d9534f',
            leftBarBorderColor: '#d9534f',
            iconColor: '#fff',
            iconClass: 'icon-close',
            passFlag: false,
            tipWords: result?.repMsg || '验证失败',
          })
          refresh()
        }
      })
    }
  }

  const setBarArea = (event: any) => {
    let barAreaLeft = event ? event.getBoundingClientRect().left : 0
    let barAreaOffsetWidth = event ? event.offsetWidth : 0
    state.barAreaLeft = barAreaLeft
    state.barAreaOffsetWidth = barAreaOffsetWidth
  }

  const slideDom = () => (
    <div
      className={mode === 'form' ? styles.verifybox : `${styles.verifybox} ${styles.verifyModal}`}
      onMouseMove={move}
      onMouseUp={end}
      onTouchMove={move}
      onTouchEnd={end}
    >
      <Spin spinning={loading}>
        <div className="verify-img-out" style={{ height: parseInt(`${state.setSize.imgHeight}`) + vSpace }}>
          <div className="verify-img-panel" style={{ height: state.setSize.imgHeight }}>
            {imgSrc ? <img src={'data:image/png;base64,' + imgSrc} alt="" /> : <div></div>}
            <div className="verify-refresh" onClick={() => refresh()}>
              <ReloadOutlined className="reload-icon" />
            </div>
            <CSSTransition in={state.tipWords.length > 0} timeout={250} classNames="tips" unmountOnExit>
              <span className={state.passFlag ? `${'verify-tips'} ${'suc-bg'}` : `${'verify-tips'} ${'err-bg'}`}>
                {state.tipWords}
              </span>
            </CSSTransition>
          </div>
        </div>
        {imgSrc && (
          <div
            className="verify-bar-area"
            style={{
              width: imgWidth,
              height: barSize.height,
              lineHeight: `${barSize.height}px`,
              userSelect: 'none',
            }}
            ref={(bararea) => setBarArea(bararea)}
          >
            <span className="verify-msg">{state.text}</span>
            <div
              ref={sliderDom}
              className="verify-left-bar"
              style={{
                width: state.leftBarWidth !== undefined ? state.leftBarWidth : barSize.width,
                height: barSize.height,
                borderColor: state.leftBarBorderColor,
              }}
            >
              <div
                className="verify-move-block"
                onTouchStart={(e) => start(e)}
                onMouseDown={(e) => start(e)}
                ref={moveBlock}
                style={{
                  width: barSize.height,
                  height: barSize.height,
                  backgroundColor: state.moveBlockBackgroundColor,
                  left: state.moveBlockLeft,
                  transition: transitionLeft,
                }}
              >
                <RightOutlined style={{ color: state.iconColor, fontSize: '24px', lineHeight: '42px' }} />
                <div
                  className="verify-sub-block"
                  style={{
                    width: Math.floor((parseInt(`${imgWidth}`) * 47) / 310) + 'px',
                    height: state.setSize.imgHeight,
                    top: '-' + (parseInt(`${state.setSize.imgHeight}`) + vSpace) + 'px',
                    backgroundSize: imgWidth + ' ' + state.setSize.imgHeight,
                  }}
                >
                  {barSrc ? (
                    <img
                      src={'data:image/png;base64,' + barSrc}
                      alt=""
                      style={{ width: '100%', height: '100%', display: 'block' }}
                    />
                  ) : (
                    <div style={{ width: '100%', height: '100%', display: 'block' }} />
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
      </Spin>
    </div>
  )

  useEffect(() => {
    const { widthDom } = props
    setImgWidth(widthDom?.current?.offsetWidth || 380)
    uuid()
    getData()
  }, [])

  const { vSpace, barSize, transitionLeft, mode, visible } = props
  return (
    <div>
      {mode === 'form' ? (
        slideDom()
      ) : (
        <Modal
          className='verify-slider-modal'
          maskClosable={false}
          width={state.setSize.imgWidth + 48}
          footer={false}
          onCancel={() => {
            onModalCancel && onModalCancel()
          }}
          visible={visible}
        >
          {slideDom()}
        </Modal>
      )}
    </div>
  )
}

export default VerifySlide
