import React, { useEffect, useState } from 'react'
import { Anchor, Button, Spin, Table } from 'antd'
import { ProjectServiceHistory } from '../../../../apis/project-service-history'
import _ from 'lodash'
import ReactECharts from 'echarts-for-react'
import { CustomIcons, getFixedNumber } from '../../../../global'

const chartCfg: any = {
  dni: { name: '太阳直接辐射', unit: 'W/㎡' },
  dhi: { name: '散射辐射', unit: 'W/㎡' },
  ghi: { name: '太阳总辐射', unit: 'W/㎡' },
  tmp: { name: '温度', unit: '℃' },
  ws: { name: '风速', unit: 'm/s' },
  wd: { name: '风向', unit: '°(deg)' },
  pres: { name: '气压', unit: 'kPa' },
  rh: { name: '相对湿度', unit: '%' },
  rainfall: { name: '降水量（每月累计）', unit: 'mm' },
  sunriseSunset: { name: '昼长', unit: 'h' },
}
const cellWidth = 150
export const WeatherCharts = (props: { projectId: string; isTable: boolean; disabledDownload?: boolean }) => {
  const { projectId, isTable, disabledDownload } = props

  const loadTotal = () => {
    setLoading(true)
    ProjectServiceHistory.showWeatherTable({
      projectId,
    })
      .then((resp) => {
        const data = resp.data
        const ds: any[] = _.cloneDeep(Object.values(chartCfg))
        Object.keys(data).forEach((key) => {
          const val = data[key]
          const { dni, dhi, ghi, tmp, ws, wd, pres, rh, rainfall, sunriseSunset } = val
          ds[0][key] = dni
          ds[1][key] = dhi
          ds[2][key] = ghi
          ds[3][key] = tmp
          ds[4][key] = ws
          ds[5][key] = wd
          ds[6][key] = pres
          ds[7][key] = rh
          ds[8][key] = rainfall
          ds[9][key] = sunriseSunset
        })
        setDataSource(ds)
        // setChartData(data)
      })
      .catch(() => {})
      .finally(() => {
        setLoading(false)
      })
  }

  const [loading, setLoading] = useState(false)

  const downloadPdf = () => {
    setLoading(true)
    ProjectServiceHistory.validationReport()
      .then((resp) => {
        window.open(resp.data, '_blank')
      })
      .catch(() => {})
      .finally(() => {
        setLoading(false)
      })
  }

  useEffect(() => {
    loadTotal()
  }, [])

  const columns = [
    {
      title: '',
      dataIndex: 'name',
      key: 'name',
      width: 160,
      fixed: 'left' as any,
      render: (text: string, row: any) => {
        return (
          <div className="data-name">
            {row.name}
            <span className="data-name-unit">{row.unit}</span>
          </div>
        )
      },
    },
    ...[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((o) => ({
      title: `${o}月`,
      dataIndex: `${o}`,
      key: `${o}`,
      width: cellWidth,
    })),
    {
      title: '典型年总值',
      dataIndex: '',
      key: '',
      width: cellWidth,
      render: (text: string, row: any) => {
        const arr = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]
        switch (row.unit) {
          case 'W/㎡':
          case 'mm':
            return (
              <div className="data-name">
                {parseFloat(
                  arr
                    .reduce((sum, key) => {
                      return sum + row[key]
                    }, 0)
                    .toFixed(2)
                )}
              </div>
            )
          default:
            return '--'
        }
      },
    },
    {
      title: '平均值',
      dataIndex: '',
      key: '',
      width: cellWidth,
      render: (text: string, row: any) => {
        const arr = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]
        return (
          <div className="data-name">
            {parseFloat(
              (
                arr.reduce((sum, key) => {
                  return sum + row[key]
                }, 0) / 12
              ).toFixed(2)
            )}
          </div>
        )
      },
    },
  ]
  const [dataSource, setDataSource] = useState<any[]>([])

  return (
    <Spin spinning={loading}>
      <div
        style={{
          textAlign: 'right',
          marginBottom: '.5rem',
        }}
      >
        天气来源：TPDC、ECMWF{' '}
        <Button type="link" disabled={disabledDownload} onClick={downloadPdf}>
          下载验证报告
          <CustomIcons type={'klf-download'} />
        </Button>
      </div>
      {isTable && (
        <div
          style={{
            maxHeight: 'calc(100vh - 400px)',
            overflowY: 'auto',
          }}
        >
          <Table
            scroll={{ x: '100%' }}
            className="weather-table-total"
            columns={columns}
            dataSource={dataSource}
            pagination={false}
          />
        </div>
      )}
      {!isTable && (
        <div
          style={{
            marginTop: '2rem',
            position: 'relative',
          }}
        >
          <div
            style={{
              position: 'absolute',
              right: 20,
              top: 0,
            }}
          >
            <Anchor
              getContainer={() => {
                return document.getElementById('weather-chart-content')!
              }}
            >
              {Object.keys(chartCfg).map((key) => {
                const obj = chartCfg[key]
                return <Anchor.Link key={key} href={`#/program/edge/${projectId}/weather/${key}`} title={obj.name} />
              })}
            </Anchor>
          </div>
          <div
            id={'weather-chart-content'}
            style={{
              maxHeight: 'calc(100vh - 400px)',
              overflowY: 'auto',
              position: 'relative',
              width: 'calc(100% - 150px)',
              display: 'flex',
              flexDirection: 'row',
              flexWrap: 'wrap',
              paddingBottom: 50,
            }}
          >
            {Object.keys(chartCfg).map((key, idx) => {
              const result: any[] = []
              const data = dataSource[idx]
              if (!data) {
                return
              }
              ;[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].forEach((o) => {
                if (data[o.toString()]) {
                  result.push(data[o.toString()])
                }
              })
              return (
                <div
                  id={`/program/edge/${projectId}/weather/${key}`}
                  key={key}
                  style={{
                    width: '50%',
                    height: '320px',
                    marginBottom: 50,
                  }}
                >
                  <div
                    style={{
                      fontSize: 16,
                      color: '#262E3F',
                    }}
                  >
                    {data['name']}
                  </div>
                  <div
                    style={{
                      textAlign: 'right',
                      marginRight: '20%',
                      fontSize: 12,
                      height: 12,
                      color: '#98A0B3',
                    }}
                  >
                    {(data.unit === 'W/㎡' || data.unit === 'mm') && (
                      <span>
                        典型年总值：
                        {`${getFixedNumber(
                          result.reduce((sum: number, cur: number) => sum + cur, 0),
                          2
                        )}${data.unit}`}
                      </span>
                    )}
                  </div>
                  <ReactECharts
                    style={{
                      width: '80%',
                      height: 280,
                    }}
                    option={{
                      grid: { left: 30, right: 0, top: 40, bottom: 0, containLabel: true },
                      tooltip: {
                        show: true,
                        trigger: 'axis',
                        formatter: (argsList: any[]) => {
                          const args: any = argsList[0]
                          return `${args.name}<br />${args.marker}${parseFloat(args.value.toFixed(2))}${data.unit}`
                        },
                      },
                      xAxis: {
                        type: 'category',
                        boundaryGap: true,
                        data: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((o) => `${o}月`),
                        // show: false,
                      },
                      yAxis: [
                        {
                          type: 'value',
                          name: data.unit,
                          splitLine: {
                            lineStyle: {
                              type: 'dashed',
                            },
                          },
                          boundaryGap: false,
                          nameTextStyle: {
                            color: 'gray',
                            padding: [0, 40, 0, 0],
                          },
                        },
                        {
                          type: 'value',
                          name: `{a|--} 平均值: ${getFixedNumber(
                            result.reduce((sum: number, cur: number) => sum + cur, 0) / result.length,
                            2
                          )}${data.unit}`,
                          nameTextStyle: {
                            color: 'gray',
                            align: 'right',
                            rich: {
                              a: {
                                color: '#1ec692',
                              },
                            },
                          },
                          axisLine: {
                            // show: false,
                          },
                          axisLabel: {
                            // show: false,
                          },
                          axisTick: {
                            // show: false,
                          },
                        },
                      ],
                      series: [
                        {
                          name: '',
                          type: 'bar',
                          data: result,
                          barWidth: 20,
                          itemStyle: {
                            color: '#1EC692',
                            barBorderRadius: [4, 4, 0, 0],
                          },
                          markLine: {
                            silent: true,
                            symbol: 'none',
                            data: [
                              {
                                yAxis: result.reduce((sum: number, cur: number) => sum + cur, 0) / result.length,
                              },
                            ],
                            lineStyle: {
                              normal: {
                                type: 'dashed',
                                color: '#1EC692',
                              },
                            },
                            label: {
                              show: false,
                            },
                          },
                        },
                      ],
                    }}
                  />
                </div>
              )
            })}
          </div>
        </div>
      )}
    </Spin>
  )
}
