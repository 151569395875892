import React, { useEffect, useState } from 'react'
import { render } from 'react-dom'
import { useLocation, useParams } from 'react-router-dom'
import { DownloadOutlined } from '@ant-design/icons'
import { Card, Col, Empty, Image, ImageProps, Row, Space, Typography } from 'antd'

import { BreadcrumbObservable, SideMenuObservable } from '../../../application'
import { FileService, ResourceService } from '../../../apis/wl-service'

export default () => {
  const location = useLocation()
  const params: { id?: string; type?: string } = useParams()
  const [loading, setLoading] = useState<boolean>(false) // 页面加载状态
  const [dataSource, setDataSource] = useState([]) // 照片源

  // 设置面包屑
  useEffect(() => {
    SideMenuObservable.next(false)
    BreadcrumbObservable.next([
      location.state?.engineering
        ? { name: '工程管理', url: `/invest/projectLibrary`, state: location.state }
        : { name: '投资开发', url: '/invest/workbench' },
      { name: '项目库', url: `/invest/projectLibrary`, state: location.state },
      { name: '项目综合文档', url: `/invest/document/${params.id}`, state: location.state },
      { name: '照片', url: `/invest/document/photos/${params.id}`, state: location.state },
    ])
  }, [params.id, location.state])

  useEffect(() => {
    setLoading(true)
    ResourceService.depotPhoto({ id: params.id ?? '' })
      .then((resp: any) => {
        setDataSource(resp?.data ?? [])
      })
      .catch(() => {})
      .finally(() => {
        setLoading(false)
      })
  }, [params])

  return (
    <Card
      bodyStyle={{ height: 'calc(100vh - 190px)' }}
      // className="no-border-bottom-card"
      className="right-actions"
      loading={loading}
      style={{ height: '100%' }}
      title={<Typography.Title level={4}>照片</Typography.Title>}
    >
      {!dataSource?.length ? (
        <Empty
          imageStyle={{
            height: 60,
          }}
          description={<span>暂无数据</span>}
        />
      ) : (
        <Image.PreviewGroup>
          <Row gutter={12}>
            {dataSource?.map((item: any, index: number) => {
              return (
                <Col xs={8} sm={6} xl={4} key={index} style={{ position: 'relative' }}>
                  {/*  width={200}  */}
                  <MyImage
                    style={{ border: '1px solid #eee' }}
                    src={item.thumbnail}
                    preview={{ src: item.HD }}
                    fileId={item.download}
                  />
                </Col>
              )
            })}
          </Row>
        </Image.PreviewGroup>
      )}
    </Card>
  )
}

interface MyImageProps extends ImageProps {
  fileId?: string
}
export const MyImage = (props: MyImageProps) => {
  const ref = React.useRef<any>()
  React.useLayoutEffect(() => {
    const oMask = ref.current.querySelector('.ant-image-mask')
    if (!oMask) {
      return
    }
    const span = document.createElement('span')
    span.className = 'ant-image-mask-info'
    oMask.appendChild(span)
    render(
      <Space
        direction="horizontal"
        size={4}
        style={{ position: 'relative', zIndex: 99, marginLeft: 12 }}
        onClick={(e) => {
          e.nativeEvent.stopPropagation()
          props.fileId && FileService.download(props.fileId)
        }}
      >
        <DownloadOutlined />
        下载
      </Space>,
      span
    )
  }, [])
  return (
    <span ref={ref}>
      <Image {...props} />
    </span>
  )
}
