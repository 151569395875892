/**
 * project
 * unknown
 *
 * OpenAPI spec version: last
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import ajax, { AjaxPromise, ExtraFetchParams } from '@ajax';
import * as models from '../model/models';

/* tslint:disable:no-unused-variable member-ordering object-literal-shorthand */

/**
  * List<AddProjectAccount>
  */
export type ParamsBodyi3060ProjectAccountAddPost = Array<models.I3060ProjectAccountAddBody>;
/**
  * @description i3060ProjectAccountCheckJoinGet参数
  * @property `projectId` 项目id(Long)
  */
export interface Paramsi3060ProjectAccountCheckJoinGet {
  // queryParams
  /**
   * 项目id(Long)
   */
  projectId: string;
}
/**
  * @description i3060ProjectAccountCurrAuthGet参数
  * @property `projectId` 项目id(Long)
  */
export interface Paramsi3060ProjectAccountCurrAuthGet {
  // queryParams
  /**
   * 项目id(Long)
   */
  projectId: string;
}
/**
  * DeleteTenantProjectAccount :DeleteTenantProjectAccount
  */
export type ParamsBodyi3060ProjectAccountDeleteByTenantPost = models.DeleteTenantProjectAccount;
/**
  * AddProjectAccount :AddProjectAccount
  */
export type ParamsBodyi3060ProjectAccountInvitationConfirmPost = models.AddProjectAccount;
/**
  * @description i3060ProjectAccountListProjectIdGet参数
  * @property `projectId` 项目id(Long)
  * @property `keyword` 搜索词(String)
  */
export interface Paramsi3060ProjectAccountListProjectIdGet {
  // pathParams
  /**
   * 项目id(Long)
   */
  projectId: string;
  // queryParams
  /**
   * 搜索词(String)
   */
  keyword: string;
}
/**
  * @description i3060ProjectAccountOperationAuthGet参数
  * @property `projectId` 项目id(Long)
  */
export interface Paramsi3060ProjectAccountOperationAuthGet {
  // queryParams
  /**
   * 项目id(Long)
   */
  projectId: string;
}
/**
  * ProjectAccountReq :ProjectAccountReq
  */
export type ParamsBodyi3060ProjectAccountRemovePost = models.ProjectAccountReq;
/**
  * @description i3060ProjectAccountResourcesGet参数
  * @property `userId` 用户id
  * @property `projectId` 项目id
  */
export interface Paramsi3060ProjectAccountResourcesGet {
  // queryParams
  /**
   * 用户id
   */
  userId: string;
  /**
   * 项目id
   */
  projectId: string;
}
/**
  * AccountRoleSetReq :AccountRoleSetReq
  */
export type ParamsBodyi3060ProjectAccountRoleSetPost = models.AccountRoleSetReq;
/**
  * SaveAccountResourcesReq :SaveAccountResourcesReq
  */
export type ParamsBodyi3060ProjectAccountSaveResourcesPost = models.SaveAccountResourcesReq;
/**
  * @description i3060ProjectAccountSelectionProjectIdGet headers 参数
  * @property `Authorization` Authorization (Only:29bfca482bd9860a72e0462a35a15da1cf78a00faae47a723aa33ac0e8a8d5b2)
  */
export interface ParamsHeaderi3060ProjectAccountSelectionProjectIdGet {
  /** Authorization (Only:29bfca482bd9860a72e0462a35a15da1cf78a00faae47a723aa33ac0e8a8d5b2) */
  'Authorization': string;
}
/**
  * @description i3060ProjectAccountSelectionProjectIdGet参数
  * @property `projectId` 项目id(Long)
  * @property `[keyword]` 项目名称模糊搜索(String)
  */
export interface Paramsi3060ProjectAccountSelectionProjectIdGet {
  // pathParams
  /**
   * 项目id(Long)
   */
  projectId: string;
  // queryParams
  /**
   * 项目名称模糊搜索(String)
   */
  keyword?: string;
}

export class ProjectAccountControllerApi {
  protected $basePath = '/api'.replace(/\/$/, '');

  public constructor(basePath?: string) {
    if (basePath !== undefined) {
      this.$basePath = basePath.replace(/\/$/, '');
    }
  }

  /**
   * 
   * @summary 添加成员
   
   * @param data: ParamsBodyi3060ProjectAccountAddPost// List<AddProjectAccount>
   * @param opt ajax config
   * @returns models.ResultBoolean
   */
  public i3060ProjectAccountAddPost = (
    
    data: ParamsBodyi3060ProjectAccountAddPost,opt?: ExtraFetchParams
  ) : AjaxPromise<models.ResultBoolean>  => {
    const url = this.$basePath + `/i3060/project/account/add`;
    const p: any = {};
    p.data = data;
    return ajax.ajax({
      ...opt,
      method: 'POST',
      url,
      ...p
    });
  }
  /**
   * ```   /_**      * 加入项目check      *      * @param projectId 项目id      *_/     @GetMapping(\"/check/join\")     public Result<Boolean> checkJoin(@RequestParam(value = \"projectId\") Long projectId) ```
   * @summary 加入项目check
   * @param params Paramsi3060ProjectAccountCheckJoinGet
   
   * @param opt ajax config
   * @returns models.ResultBoolean
   */
  public i3060ProjectAccountCheckJoinGet = (
    params: Paramsi3060ProjectAccountCheckJoinGet,
    opt?: ExtraFetchParams
  ) : AjaxPromise<models.ResultBoolean>  => {
    const url = this.$basePath + `/i3060/project/account/check/join`;
    const p: any = {};
    p.query = {};
    if ('projectId' in params) p.query.projectId = params.projectId;
    ajax.check(params.projectId, 'projectId');
    return ajax.ajax({
      ...opt,
      method: 'GET',
      url,
      ...p
    });
  }
  /**
   * 
   * @summary 查询当前用户在该项目的权限信息
   * @param params Paramsi3060ProjectAccountCurrAuthGet
   
   * @param opt ajax config
   * @returns models.ResultCurrProjectAuthRes
   */
  public i3060ProjectAccountCurrAuthGet = (
    params: Paramsi3060ProjectAccountCurrAuthGet,
    opt?: ExtraFetchParams
  ) : AjaxPromise<models.ResultCurrProjectAuthRes>  => {
    const url = this.$basePath + `/i3060/project/account/currAuth`;
    const p: any = {};
    p.query = {};
    if ('projectId' in params) p.query.projectId = params.projectId;
    ajax.check(params.projectId, 'projectId');
    return ajax.ajax({
      ...opt,
      method: 'GET',
      url,
      ...p
    });
  }
  /**
   * ```   /_**      * 删除租户下成员      *      * @param req      * @return      *_/     @PostMapping(\"/deleteByTenant\")     public Result<Boolean> deleteTenantProjectAccount(@RequestBody DeleteTenantProjectAccount req) ```
   * @summary 删除租户下成员
   
   * @param data: ParamsBodyi3060ProjectAccountDeleteByTenantPost// DeleteTenantProjectAccount :DeleteTenantProjectAccount
   * @param opt ajax config
   * @returns models.ResultBoolean
   */
  public i3060ProjectAccountDeleteByTenantPost = (
    
    data: ParamsBodyi3060ProjectAccountDeleteByTenantPost,opt?: ExtraFetchParams
  ) : AjaxPromise<models.ResultBoolean>  => {
    const url = this.$basePath + `/i3060/project/account/deleteByTenant`;
    const p: any = {};
    p.data = data;
    return ajax.ajax({
      ...opt,
      method: 'POST',
      url,
      ...p
    });
  }
  /**
   * ```   /_**      * 项目邀请链接确认      *      * @param req      *_/     @PostMapping(\"/invitation/confirm\")     public Result<Boolean> addAccount(@RequestBody AddProjectAccount req) ```
   * @summary 项目邀请链接确认
   
   * @param data: ParamsBodyi3060ProjectAccountInvitationConfirmPost// AddProjectAccount :AddProjectAccount
   * @param opt ajax config
   * @returns models.ResultBoolean
   */
  public i3060ProjectAccountInvitationConfirmPost = (
    
    data: ParamsBodyi3060ProjectAccountInvitationConfirmPost,opt?: ExtraFetchParams
  ) : AjaxPromise<models.ResultBoolean>  => {
    const url = this.$basePath + `/i3060/project/account/invitation/confirm`;
    const p: any = {};
    p.data = data;
    return ajax.ajax({
      ...opt,
      method: 'POST',
      url,
      ...p
    });
  }
  /**
   * 
   * @summary 项目成员列表
   * @param params Paramsi3060ProjectAccountListProjectIdGet
   
   * @param opt ajax config
   * @returns models.ResultListProjectAccountRes
   */
  public i3060ProjectAccountListProjectIdGet = (
    params: Paramsi3060ProjectAccountListProjectIdGet,
    opt?: ExtraFetchParams
  ) : AjaxPromise<models.ResultListProjectAccountRes>  => {
    const {
    projectId,
} = params;
    const url = this.$basePath + `/i3060/project/account/list/${projectId}`;
    const p: any = {};
    p.query = {};
    if ('keyword' in params) p.query.keyword = params.keyword;
    ajax.check(params.keyword, 'keyword');
    ajax.check(params.projectId, 'projectId');
    return ajax.ajax({
      ...opt,
      method: 'GET',
      url,
      ...p
    });
  }
  /**
   * ``` 权限code说明      \"electricity_price_scheme\", # 电价方案     \"load_curve\", # 负荷曲线     \"weather_data\", #  天气数据     \"time_range_accuracy\", # 时间范围与精度     \"technical_proposal\", #  技术方案     \"financial_plan\", #  财务方案     \"programme_report\" #  方案报告     \"invest_contract_editing\" # 合同审批编辑权限     \"invest_contract_download\", # 合同文件下载权限     \"invest_cooperate_download\", # 合作方下载     \"invest_info_edit\", # 项目基本信息编辑权限     \"invest_info_export\" # 基本信息导出权限     \"invest_project_upload\" #项目收资表上传/新建权限     \"invest_project_download\" #收资信息下载权限     \"invest_scheme_upload\" #项目方案上传/新建权限     \"invest_scheme_download\" #项目方案下载权限 ```
   * @summary 获取当前用户项目操作权限
   * @param params Paramsi3060ProjectAccountOperationAuthGet
   
   * @param opt ajax config
   * @returns models.Result
   */
  public i3060ProjectAccountOperationAuthGet = (
    params: Paramsi3060ProjectAccountOperationAuthGet,
    opt?: ExtraFetchParams
  ) : AjaxPromise<models.Result>  => {
    const url = this.$basePath + `/i3060/project/account/operation/auth`;
    const p: any = {};
    p.query = {};
    if ('projectId' in params) p.query.projectId = params.projectId;
    ajax.check(params.projectId, 'projectId');
    return ajax.ajax({
      ...opt,
      method: 'GET',
      url,
      ...p
    });
  }
  /**
   * 
   * @summary 移除项目成员
   
   * @param data: ParamsBodyi3060ProjectAccountRemovePost// ProjectAccountReq :ProjectAccountReq
   * @param opt ajax config
   * @returns models.ResultBoolean
   */
  public i3060ProjectAccountRemovePost = (
    
    data: ParamsBodyi3060ProjectAccountRemovePost,opt?: ExtraFetchParams
  ) : AjaxPromise<models.ResultBoolean>  => {
    const url = this.$basePath + `/i3060/project/account/remove`;
    const p: any = {};
    p.data = data;
    return ajax.ajax({
      ...opt,
      method: 'POST',
      url,
      ...p
    });
  }
  /**
   * ```   /_**      * 查看项目成员权限数据      *      * @param req      * @return      *_/     @GetMapping(\"/resources\")     public Result<List<ProjectAccountResourcesRes>> selectAccountResources(@ModelAttribute ProjectAccountReq req) ```
   * @summary 查看项目成员权限数据
   * @param params Paramsi3060ProjectAccountResourcesGet
   
   * @param opt ajax config
   * @returns models.I3060ProjectAccountResources
   */
  public i3060ProjectAccountResourcesGet = (
    params: Paramsi3060ProjectAccountResourcesGet,
    opt?: ExtraFetchParams
  ) : AjaxPromise<models.I3060ProjectAccountResources>  => {
    const url = this.$basePath + `/i3060/project/account/resources`;
    const p: any = {};
    p.query = {};
    if ('userId' in params) p.query.userId = params.userId;
    ajax.check(params.userId, 'userId');
    if ('projectId' in params) p.query.projectId = params.projectId;
    ajax.check(params.projectId, 'projectId');
    return ajax.ajax({
      ...opt,
      method: 'GET',
      url,
      ...p
    });
  }
  /**
   * 
   * @summary 设置负责人、管理员
   
   * @param data: ParamsBodyi3060ProjectAccountRoleSetPost// AccountRoleSetReq :AccountRoleSetReq
   * @param opt ajax config
   * @returns models.ResultBoolean
   */
  public i3060ProjectAccountRoleSetPost = (
    
    data: ParamsBodyi3060ProjectAccountRoleSetPost,opt?: ExtraFetchParams
  ) : AjaxPromise<models.ResultBoolean>  => {
    const url = this.$basePath + `/i3060/project/account/role/set`;
    const p: any = {};
    p.data = data;
    return ajax.ajax({
      ...opt,
      method: 'POST',
      url,
      ...p
    });
  }
  /**
   * 
   * @summary 保存用户资源权限
   
   * @param data: ParamsBodyi3060ProjectAccountSaveResourcesPost// SaveAccountResourcesReq :SaveAccountResourcesReq
   * @param opt ajax config
   * @returns models.ResultBoolean
   */
  public i3060ProjectAccountSaveResourcesPost = (
    
    data: ParamsBodyi3060ProjectAccountSaveResourcesPost,opt?: ExtraFetchParams
  ) : AjaxPromise<models.ResultBoolean>  => {
    const url = this.$basePath + `/i3060/project/account/save/resources`;
    const p: any = {};
    p.data = data;
    return ajax.ajax({
      ...opt,
      method: 'POST',
      url,
      ...p
    });
  }
  /**
   * ```   /_**      * 项目选择成员列表      *      * @param projectId 项目id      * @param keyword   项目名称模糊搜索      * @return      *_/     @GetMapping(\"/selection/{projectId}\")     public Result<List<AccountSelectionRes>> projectAccountSelectionList(@PathVariable Long projectId, @RequestParam(value = \"keyword\", required = false) String keyword) ```
   * @summary 项目选择成员列表
   * @param params Paramsi3060ProjectAccountSelectionProjectIdGet
   
   * @param opt ajax config
   * @returns models.ResultListAccountSelectionRes
   */
  public i3060ProjectAccountSelectionProjectIdGet = (
    params: Paramsi3060ProjectAccountSelectionProjectIdGet,
    opt?: { headers: ParamsHeaderi3060ProjectAccountSelectionProjectIdGet } & Omit<ExtraFetchParams, 'headers'> 
  ) : AjaxPromise<models.ResultListAccountSelectionRes>  => {
    const {
    projectId,
} = params;
    const url = this.$basePath + `/i3060/project/account/selection/${projectId}`;
    const p: any = {};
    p.query = {};
    if ('keyword' in params) p.query.keyword = params.keyword;
    ajax.check(params.projectId, 'projectId');
    return ajax.ajax({
      ...opt,
      method: 'GET',
      url,
      ...p
    });
  }
}

export default new ProjectAccountControllerApi();