import React, { useImperativeHandle } from 'react'
import moment from 'moment'
import { cloneDeep, set } from 'lodash'
import { Form, Input, Typography, Row, Col, FormInstance, Spin } from 'antd'
import { CollectionConService, CommonService, ProjectRecordService, ResourceService } from '../../../../apis/wl-service'
import { CustomFormItem, CustomFormTitle, formItemLayout } from '../../../../components/wl-form-item'
import {
  importantLevelOptions2,
  policy_typeOptions,
  isGetYearTargetOptions,
  is_pzOptions,
  pzTypeOptions,
} from '../../common/constant'
import store, { SubmitType } from '../../../invest-dev/project-library-create/components/store'

export interface IRecordFormProps {
  id?: string
  editable?: boolean
}

export const RefForm = ({ id, editable = false }: IRecordFormProps, ref: any) => {
  const unmountRef = React.useRef<boolean>(false)
  const [form] = Form.useForm()
  const storeKey = React.useMemo<string>(() => (editable ? store.keyMap.projectRecord : ''), [store, editable]) // 用于本地数据存储的键值
  const [formData, setFormData] = React.useState<any>({}) // 存储表单数据
  const [loading, setLoading] = React.useState<boolean>(false) // 数据加载状态
  const [options, setOptions] = React.useState<{ [name: string]: any[] }>({
    resourceTypeOptions: [], // 项目类型
    developList: [], // 项目（屋顶企业）名称列表（此处的项目全部已新建过资源）
    enterpriseNature: [], // 企业性质
    property: [], // 产权
    collectionTemplate: [], // 收资表模板数据
  }) // 基础选择数据

  useImperativeHandle(ref, () => ({
    getName: () => storeKey,
    submit: async (options: SubmitType) => {
      try {
        let values: any = await form.validateFields()
        values = formatter(values, 'params')
        store.submit[storeKey]({
          ...options,
          values,
        })
      } catch (err) {
        console.warn('submit form Validate Failed:', err)
      }
    },
  }))

  // 设置组件挂载状态
  React.useEffect(() => {
    unmountRef.current = false
    return () => {
      unmountRef.current = true
    }
  }, [])

  // 获取基础数据
  React.useEffect(() => {
    if (unmountRef.current) return

    // 编辑时从缓存中读取数据
    const prevOptions = editable && store.getOptions()

    // 项目类型
    prevOptions?.resourceTypeOptions
      ? setOptions((previous: any) => ({
          ...previous,
          resourceTypeOptions: prevOptions.resourceTypeOptions,
        }))
      : CommonService.getDictionaryDataSelectorValid('15301742e5ec4e92843192cb76010e08')
          .then((data: any) => {
            const resourceTypeOptions = data?.data?.list ?? []
            !unmountRef.current && setOptions((previous: any) => ({ ...previous, resourceTypeOptions }))
            editable && store.setOptions({ resourceTypeOptions }, true)
          })
          .catch((error: any) => {
            console.warn('项目类型获取失败：', error?.message)
          })

    if (editable) {
      // 项目列表 // 使用收资信息中的developList
      prevOptions?.developList
        ? setOptions((previous: any) => ({
            ...previous,
            developList: prevOptions.developList,
            enterpriseNature: prevOptions.enterpriseNature,
            property: prevOptions.property,
            collectionTemplate: prevOptions.collectionTemplate,
          }))
        : CollectionConService.basicData()
            .then((res: any) => {
              const current = res?.data ?? {}
              current.developList = current.developList?.map(({ id, name }: any) => ({ id, name, fullName: name }))
              if (!unmountRef.current) {
                setOptions((previous: any) => ({ ...previous, ...current }))
              }
              editable && store.setOptions(current, true)
            })
            .catch((e) => {
              console.warn('获取收资信息基础数据失败：', e)
            })
    }
  }, [editable])

  // 根据projectId获取详情
  React.useEffect(() => {
    detail(editable ? store.projectId : id)
  }, [id, editable, store.projectId])

  // type=params作为参数发送到服务端
  // type=component转换数据用于组件渲染
  const formatter = (values: any, type: 'params' | 'component') => {
    values = cloneDeep(values)

    // 转换时间值
    if (editable) {
      ;['project_start_time', 'project_end_time', 'filing_time'].forEach((k: string) => {
        if (type === 'component') {
          values[k] = values[k] ? moment(values[k]) : null
        }
        if (type === 'params') {
          values[k] = values[k] ? moment(values[k]).format('YYYY-MM-DD') : ''
        }
      })
    }

    // 转换select
    ;['important_level', 'policy_type', 'is_get_year_target', 'is_bak', 'pztype'].forEach((k: string) => {
      if (type === 'component' && typeof values[k] === 'number') {
        values[k] = values[k].toString()
      }
    })

    // 转换文件
    ;['append_file', 'qt_file'].forEach((k: string) => {
      if (type === 'component') {
        values[k] = values[k] ? JSON.parse(values[k]) : []
      }
      if (type === 'params') {
        values[k] = values[k]?.length ? JSON.stringify(values[k]) : ''
      }
    })

    return values
  }

  // 根据projectId获取详情
  const detail = async (projectId?: string) => {
    if (unmountRef.current) return

    // 设置、存储表单数据
    const setValues = (values: any) => {
      if (unmountRef.current) return

      try {
        if (editable) {
          // 设置form 表单中 的项目选中项
          values.projectId = store.projectId ?? ''
          values.project_id = store.projectId ?? ''
          store.setItem(storeKey, values)
        }

        //   form.resetFields()
        form.setFieldsValue(formatter(values, 'component'))
        !unmountRef.current && setFormData(values)
      } catch (e: any) {
        console.warn(e)
      }
    }

    const prevDetailData = editable && store.getItem(storeKey)
    if (prevDetailData) {
      setValues(prevDetailData)
      return
    }

    try {
      setLoading(true)

      let resp: any = await (projectId ? ProjectRecordService.info({ projectId }) : ProjectRecordService.draft())
      const requestId = resp?.data?.project_id ?? projectId
      if (requestId) {
        const prevProject = editable && store.getProject()

        let project = prevProject
        if (requestId !== prevProject?.project_id) {
          project = await ResourceService.info({ id: requestId })
          project = project?.data
        }

        set(resp, ['data'], {
          ...(resp?.data ?? {}),
          project_id: project?.id,
          project_name: project?.project_name,
          project_type: project?.resourcetype,
        })
      }
      setValues(resp?.data ?? {})
    } catch (e) {
      console.warn(`获取备案详情失败`, e)
    } finally {
      setLoading(false)
    }
  }

  return (
    <Spin spinning={loading}>
      <Form
        name="recordForm"
        form={form}
        labelWrap
        {...formItemLayout.span8}
        autoComplete="off"
        initialValues={formData}
        onValuesChange={(changedValues, allValues) => {
          store.onValuesChange(storeKey, formatter(allValues, 'params'))
        }}
      >
        <Form.Item name="id" hidden>
          <Input />
        </Form.Item>

        <CustomFormTitle>基本信息</CustomFormTitle>
        <Row>
          <CustomFormItem
            label="项目名称"
            name="project_id"
            type="select"
            span={3}
            editable={editable}
            computed={false}
            options={options?.developList}
            value={formData.project_name}
            componentProps={{
              disabled: true,
              onChange: (value: any) => {
                // 项目id变化
                store.setProjectId(value ?? '', storeKey)
              },
            }}
            rules={[
              {
                required: false,
                message: '请选择项目名称',
              },
            ]}
          >
            <Form.Item name="project_name" hidden={true}>
              <Input />
            </Form.Item>
          </CustomFormItem>

          <CustomFormItem
            label="项目代码"
            name="project_code"
            span={3}
            type="input"
            editable={editable}
            value={formData.project_code}
            componentProps={{}}
          />

          <CustomFormItem
            label="项目类型"
            name="project_type"
            span={3}
            type="select"
            editable={editable}
            computed={true}
            options={options?.resourceTypeOptions}
            value={formData.project_type}
            componentProps={{
              disabled: true,
            }}
          />

          <CustomFormItem
            label="重要程度"
            name="important_level"
            span={3}
            type="select"
            editable={editable}
            computed={true}
            options={importantLevelOptions2}
            value={formData.important_level}
            componentProps={{}}
            rules={[
              {
                required: false,
                message: '请选择重要程度',
              },
            ]}
          />

          <CustomFormItem
            label="二级企业"
            name="company_name"
            span={3}
            type="input"
            editable={editable}
            value={formData.company_name}
            componentProps={{}}
          />
        </Row>
        <Row>
          <CustomFormItem
            label="电价政策类型"
            name="policy_type"
            span={3}
            type="select"
            editable={editable}
            computed={true}
            options={policy_typeOptions}
            value={formData.policy_type}
            componentProps={{}}
          />

          <CustomFormItem
            label="是否获得年度指标"
            name="is_get_year_target"
            span={3}
            type="select"
            editable={editable}
            computed={true}
            options={isGetYearTargetOptions}
            value={formData.is_get_year_target}
            componentProps={{}}
          />

          <CustomFormItem
            label="项目电价(元/kWh)"
            name="project_powerprice"
            span={3}
            type="number"
            editable={editable}
            value={formData.project_powerprice}
            componentProps={{}}
          />

          <CustomFormItem
            label="是否批准"
            name="is_bak"
            span={3}
            type="select"
            editable={editable}
            computed={true}
            options={is_pzOptions}
            value={formData.is_bak}
            componentProps={{}}
            rules={[
              {
                required: false,
                message: '请选择是否批准',
              },
            ]}
          />

          <CustomFormItem
            label="批准方式"
            name="pztype"
            span={3}
            type="select"
            editable={editable}
            computed={true}
            options={pzTypeOptions}
            value={formData.pztype}
            componentProps={{}}
            rules={[
              {
                required: false,
                message: '请选择批准方式',
              },
            ]}
          />

          <CustomFormItem
            label="文件依据"
            name="document_gist"
            span={3}
            type="input"
            editable={editable}
            value={formData.document_gist}
            componentProps={{}}
          />
        </Row>
        <Form.Item noStyle shouldUpdate={(prevValues, currentValues) => prevValues.is_bak !== currentValues.is_bak}>
          {({ getFieldValue }: FormInstance) =>
            getFieldValue('is_bak') === '1' ? (
              <>
                <CustomFormTitle>批准信息</CustomFormTitle>
                <Row>
                  <CustomFormItem
                    label="批准投资主体"
                    name="filing_invest_main"
                    span={3}
                    type="input"
                    editable={editable}
                    value={formData.filing_invest_main}
                    componentProps={{}}
                    rules={[
                      {
                        required: false,
                        message: '请填写备案投资主体',
                      },
                    ]}
                  />

                  <CustomFormItem
                    label="批准规模(MW)"
                    name="install_size"
                    span={3}
                    type="number"
                    editable={editable}
                    value={formData.install_size}
                    componentProps={{}}
                    rules={[
                      {
                        required: false,
                        message: '请填写项目规模',
                      },
                    ]}
                  />

                  <CustomFormItem
                    label="批准投资额:(万元)"
                    name="filing_invest"
                    span={3}
                    type="number"
                    editable={editable}
                    value={formData.filing_invest}
                    componentProps={{}}
                    rules={[
                      {
                        required: false,
                        message: '请填写备案投资额',
                      },
                    ]}
                  />

                  <CustomFormItem
                    label="批准机构"
                    name="filing_organization"
                    span={3}
                    type="input"
                    editable={editable}
                    value={formData.filing_organization}
                    componentProps={{}}
                    rules={[
                      {
                        required: false,
                        message: '请填写备案机构',
                      },
                    ]}
                  />

                  <CustomFormItem
                    label="批准日期"
                    name="filing_time"
                    span={3}
                    type="datepicker"
                    editable={editable}
                    value={formData.filing_time}
                    componentProps={{
                      format: 'YYYY-MM-DD',
                    }}
                    rules={[
                      {
                        required: false,
                        message: '请填写备案日期',
                      },
                    ]}
                  />

                  <CustomFormItem
                    label="批准文号"
                    name="filing_number"
                    span={3}
                    type="input"
                    editable={editable}
                    value={formData.filing_number}
                    componentProps={{}}
                  />

                  <CustomFormItem
                    label="计划开工时间"
                    name="project_start_time"
                    span={3}
                    type="datepicker"
                    editable={editable}
                    value={formData.project_start_time}
                    componentProps={{
                      format: 'YYYY-MM-DD',
                      disabledDate: (current: any) => {
                        const end = form.getFieldValue('project_end_time')
                        return current && end && current > end.endOf('day')
                      },
                    }}
                  />

                  <CustomFormItem
                    label="计划完工时间"
                    name="project_end_time"
                    span={3}
                    type="datepicker"
                    editable={editable}
                    value={formData.project_end_time}
                    componentProps={{
                      format: 'YYYY-MM-DD',
                      disabledDate: (current: any) => {
                        const start = form.getFieldValue('project_start_time')
                        return current && start && current < start.endOf('day')
                      },
                    }}
                  />

                  <CustomFormItem
                    label="详细地址"
                    name="address"
                    span={1}
                    type="input"
                    editable={editable}
                    value={formData.address}
                    componentProps={{}}
                  />
                </Row>
              </>
            ) : null
          }
        </Form.Item>

        <Row>
          <Col xs={24}>
            {editable ? (
              <Form.Item label="备注" name="memo" {...formItemLayout.span24}>
                <Input.TextArea showCount maxLength={200} placeholder="请输入" autoSize={{ minRows: 4 }} />
              </Form.Item>
            ) : (
              <Form.Item {...formItemLayout.span24}>
                <Typography.Title level={5}>备注</Typography.Title>
                <Typography.Paragraph>{formData.memo || '暂无备注'}</Typography.Paragraph>
              </Form.Item>
            )}
          </Col>
        </Row>

        <CustomFormTitle>附件上传</CustomFormTitle>
        <Row>
          <CustomFormItem
            label="备案证"
            name="append_file"
            span={2}
            type="file"
            editable={editable}
            value={formData.append_file}
            componentProps={{}}
          />

          <CustomFormItem
            label="其他附件"
            name="qt_file"
            span={2}
            type="file"
            editable={editable}
            value={formData.qt_file}
            componentProps={{}}
          />
        </Row>
      </Form>
    </Spin>
  )
}

export default React.forwardRef(RefForm)
