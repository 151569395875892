import React,{useState} from 'react'
import { Row, Col, Image } from 'antd';
import ReactECharts from 'echarts-for-react'
import './index.less'
import styles from './index.module.scss'

// 导入图片
import headeronea from '../../assets/headeronea.png'
import headeroneb from '../../assets/headeroneb.png'
import headeronec from '../../assets/headeronec.png'

//导入图表数据
import chartsData from './chartsData'

export const DataSummary = () => {
  const [electricity] = useState([
    {name:'屋面光伏',value:43811,img:headeronec,extra:[
      {name:'党政机关',value:865},
      {name:'工商',value:536},
      {name:'居民',value:235},
    ]},
    {name:'地面光伏',value:23811,img:headeronea},
    {name:'容量合计',value:13811,img:headeroneb},
  ]);

  //发展目标任务

  return (
    <div className={styles.main}>
      <Row gutter={[8,8]}>
        {/* 第一排 */}
        {
          electricity?.map((item,index)=>{
            return <Col span={8} key={index}>
              <div className={styles.dataPanel}>
                <div className={styles.box}>
                    <Image width={45} height={45} src={item.img} alt="" />
                    <div className={styles.content}>
                      <div className={styles.name}>{item.name}</div>
                      <div className={styles.value}>
                        {item.value}<span className={styles.unit}>MW</span>
                      </div>
                    </div>
                  </div>
                  <div className={styles.item}>
                    {
                      item.extra?.map((el,k)=>{
                        return <div className={styles.itemBox} key={k}>
                          <div className={styles.name}>{el.name}</div>
                          <div className={styles.value}>
                            {el.value}<span className={styles.unit}>MW</span>
                          </div>
                        </div>
                      })
                    }
                  </div>   
              </div>
          </Col>
          })
        }
        {/* 大图 */}
        <Col span={24}>
          <div className={styles.bgChartsPanel}>   
            <Row gutter={8}>
              <Col span={16}>
                <div className={styles.bgChartsPanel}>   
                  <div className={styles.titlePanel}>
                      {chartsData.plateDevelopmentGoals.title}
                    </div>
                    <div className={`${styles.content} ${styles.verticalCenter}`}>
                      <div className={styles.horizontalCenter}>
                        <div className={styles.dataUnit}>
                            <div className={styles.name}>
                              当前合计
                            </div>
                            <div className={styles.value}>
                              2000 <span className={styles.unit}>MW</span>
                            </div>
                          </div>
                          <div className={styles.dataUnit}>
                            <div className={styles.name}>
                              目标合计
                            </div>
                            <div className={styles.value}>
                              3000 <span className={styles.unit}>MW</span>
                            </div>
                          </div>
                      </div>
                      <ReactECharts option={chartsData.plateDevelopmentGoals.data()} style={{width:'100%',height:'80%'}}></ReactECharts>
                    </div>
                </div>
              </Col>
              <Col span={8}>
                <div className={styles.bgChartsPanel}>   
                    <div className={styles.titlePanel}>
                        {chartsData.plateDevelopmentGoals.title}
                      </div>
                      <div className={`${styles.content} ${styles.verticalCenter}`}>
                        <div className={styles.dataUnit}>
                          <div className={styles.name}>
                            当前合计
                          </div>
                          <div className={styles.value}>
                            2000 <span className={styles.unit}>MW</span>
                          </div>
                        </div>
                        <ReactECharts option={chartsData.plateDevelopmentGoals.insideAndOutsideProvince()} style={{width:'100%',height:'60%'}}></ReactECharts>
                        <div>
                          <div className={styles.dataRow}>
                            <span className={styles.dots1}> ● </span>
                            <span className={styles.item}> 省内屋面光伏 </span>
                            <span className={styles.separation}> | </span>
                            <span className={styles.percentage}> 80.3%</span>
                          </div>
                          <div className={styles.dataRow}>
                            <span className={styles.dots2}> ● </span>
                            <span className={styles.item}> 省外屋面光伏  </span>
                            <span className={styles.separation}> | </span>
                            <span className={styles.percentage}> 20.7%</span>
                          </div>
                        </div>
                      </div>
                  </div>
              </Col>
            </Row>
          </div>
        </Col>
        <Col span={24}>
          <div className={styles.bgChartsPanel}>   
            <div className={styles.titlePanel}>
                {chartsData.plate.title}
              </div>
              <div className={styles.content}>
                <ReactECharts option={chartsData.plate.data()} style={{width:'100%',height:'100%'}}></ReactECharts>
              </div>
          </div>
        </Col>
        {/* 小图 */}
        <Col span={12}>
            <div className={styles.smChartsPanel}>   
              <div className={styles.titlePanel}>
                {chartsData.totalCapacityStatistics.title}
              </div>
                <Row>
                  <Col span={12}>
                    <div className={`${styles.content} ${styles.verticalCenter}`}>
                      <div className={styles.dataUnit}>
                        <div className={styles.name}>
													屋面光伏总容量
												</div>
                        <div className={styles.value}>
													2000 <span className={styles.unit}>MW</span>
                        </div>
                      </div>
                      <ReactECharts option={chartsData.totalCapacityStatistics.roofData()} style={{width:'100%',height:'60%'}}></ReactECharts>
                      <div>
                        <div className={styles.dataRow}>
                          <span className={styles.dots1}> ● </span>
                          <span className={styles.item}> 省内屋面光伏 </span>
                          <span className={styles.separation}> | </span>
                          <span className={styles.percentage}> 80.3%</span>
                        </div>
                        <div className={styles.dataRow}>
                          <span className={styles.dots2}> ● </span>
                          <span className={styles.item}> 省外屋面光伏  </span>
                          <span className={styles.separation}> | </span>
                          <span className={styles.percentage}> 20.7%</span>
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col span={12}>
                    <div className={`${styles.content} ${styles.verticalCenter}`}>
                      <div className={styles.dataUnit}>
                        <div className={styles.name}>
                          地面光伏总容量
												</div>
                        <div className={styles.value}>
													2000 <span className={styles.unit}>MW</span>
                        </div>
                      </div>
                      <ReactECharts option={chartsData.totalCapacityStatistics.groundData()} style={{width:'100%',height:'60%'}}></ReactECharts>
                      <div>
                        <div className={styles.dataRow}>
                          <span className={styles.dots1}> ● </span>
                          <span className={styles.item}> 省内地面光伏 </span>
                          <span className={styles.separation}> | </span>
                          <span className={styles.percentage}> 80.3%</span>
                        </div>
                        <div className={styles.dataRow}>
                          <span className={styles.dots2}> ● </span>
                          <span className={styles.item}> 省外地面光伏  </span>
                          <span className={styles.separation}> | </span>
                          <span className={styles.percentage}> 20.7%</span>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>          
            </div>
        </Col>
        <Col span={12}>
          <div className={styles.smChartsPanel}>   
            <div className={styles.titlePanel}>
              {chartsData.newPlateCapacity.title}
            </div>
            <div className={styles.content}>
              <ReactECharts option={chartsData.newPlateCapacity.data()} style={{width:'100%',height:'100%'}}></ReactECharts>
            </div>
          </div>
        </Col>
        <Col span={12}>
          <div className={styles.smChartsPanel}>   
            <div className={styles.titlePanel}>
              {chartsData.groupCostAnalysis.title}
            </div>
            <div className={styles.content}>
              <ReactECharts option={chartsData.groupCostAnalysis.data()} style={{width:'100%',height:'100%'}}></ReactECharts>
            </div>
          </div>
        </Col>
        <Col span={12}>
          <div className={styles.smChartsPanel}>   
            <div className={styles.titlePanel}>
              {chartsData.annualDecision.title}
            </div>
            <div className={styles.content}>
              <ReactECharts option={chartsData.annualDecision.data()} style={{width:'100%',height:'100%'}}></ReactECharts>
            </div>
          </div>
        </Col>
        <Col span={12}>
          <div className={styles.smChartsPanel}>   
            <div className={styles.titlePanel}>
              {chartsData.monthlyDecisionOfPrj.title}
            </div>
            <div className={styles.content}>
              <ReactECharts option={chartsData.monthlyDecisionOfPrj.data()} style={{width:'100%',height:'100%'}}></ReactECharts>
            </div>
          </div>
        </Col>
        <Col span={12}>
          <div className={styles.smChartsPanel}>   
            <div className={styles.titlePanel}>
              {chartsData.annualDecisionOfPrj.title}
            </div>
            <div className={styles.content}>
              <ReactECharts option={chartsData.annualDecisionOfPrj.data()} style={{width:'100%',height:'100%'}}></ReactECharts>
            </div>
          </div>
        </Col>
        <Col span={12}>
          <div className={styles.smChartsPanel}>   
            <div className={styles.titlePanel}>
              {chartsData.monthlyProductionCapacity.title}
            </div>
            <div className={styles.content}>
              <ReactECharts option={chartsData.monthlyProductionCapacity.data()} style={{width:'100%',height:'100%'}}></ReactECharts>
            </div>
          </div>
        </Col>
        <Col span={12}>
          <div className={styles.smChartsPanel}>   
            <div className={styles.titlePanel}>
              {chartsData.annualProductionCapacity.title}
            </div>
            <div className={styles.content}>
              <ReactECharts option={chartsData.annualProductionCapacity.data()} style={{width:'100%',height:'100%'}}></ReactECharts>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  )
}
