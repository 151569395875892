import React from 'react'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { message, Tabs } from 'antd'
import {
  ResourceDevForm,
  RecordForm,
  InvestDecisionForm,
} from '../../../project-entry/project-moudle-detail/components'
import store, { IFormRef, SubmitType } from './store'

export interface MyFormProps {
  componentRef: React.MutableRefObject<IFormRef>
  visibleDraft: (visible: boolean) => void
  visibleSubmit: (visible: boolean) => void
  onNext: () => void
}

export default ({ componentRef, visibleDraft, visibleSubmit, onNext }: MyFormProps) => {
  const params = useParams()
  const nav = useNavigate()
  const [searchParams] = useSearchParams()
  const formRef = React.useRef<IFormRef>() // 表单ref
  const [current, setCurrent] = React.useState<'1' | '2' | '3'>('1') // 当前tab

  // 暴漏方法
  React.useImperativeHandle(componentRef, () => ({
    getName: () => formRef.current?.getName?.() ?? '',
    submit: (options: SubmitType) => {
      formRef.current?.submit({
        ...options,
        success: (data: any) => {
          if (options?.isDraft) {
            return
          }

          // 编辑时直接提交返回原页面
          if (searchParams.get('t')) {
            nav(-1)
            store.clearAll()
          } else {
            // 新建时跳到下一个tab页
            if (current === '1' || current === '2') {
              setCurrent(`${+current + 1}` as any)
            } else {
              onNext()
            }
            options?.success?.(data)
          }
        },
      })
    },
  }))

  React.useEffect(() => {
    setCurrent((searchParams.get('t') as any) ?? '1')
  }, [searchParams])

  React.useEffect(() => {
    // 是否显示保存草稿按钮
    visibleDraft(!params.id)

    // 是否显示确定按钮
    visibleSubmit(true)
  }, [params])

  // 监听projectId变化
  React.useEffect(() => {
    const fn = () => {
    }
    store.subscribe(fn)
    return () => {
      store.unsubscribe(fn)
    }
  }, [])

  const tabList = React.useMemo(() => {
    return [
      { key: '1', label: '新建资源' },
      { key: '2', label: '新建投资' },
      { key: '3', label: '新建备案' },
    ]
  }, [])

  // 当前tab内容
  const content = React.useMemo(() => {
    switch (current) {
      case '1':
        return <ResourceDevForm ref={formRef} id={params.id} editable={true} />

      case '2':
        return <InvestDecisionForm ref={formRef} id={params.id} editable={true} />

      case '3':
        return <RecordForm ref={formRef} id={params.id} editable={true} />

      default:
        return null
    }
  }, [current])

  return (
    <Tabs
      type="card"
      activeKey={current}
      onTabClick={(key: string) => {
        if (!store.projectId) {
          message.warn(`请先新建资源`)
          return
        }
        setCurrent(key as any)
      }}
    >
      {tabList.map(({ key, label }: any) => (
        <Tabs.TabPane tab={label} key={key}>
          {key === current ? content : null}
        </Tabs.TabPane>
      ))}
    </Tabs>
  )
}
