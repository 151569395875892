import { createFromIconfontCN } from '@ant-design/icons'
import html2canvas from 'html2canvas'
import moment from 'moment'
import axios from "axios";
import {token} from "./utils/utils";

export const Global = {
  user: {} as any,
  role: {
    //项目数量
    projectNum: 8,
    //技术方案数
    technicalNum: 10,
    //项目投资数量
    financeProjectInvestNum: 6,
    //运营成本
    financeOperationCostNum: 6,
    //财务成本
    financeCostNum: 6,
    //激励补贴
    financeAwardSubsidyNum: 6,
    //财务情景
    financeParamNum: 6,
    //财务方案选择技术方案数量
    financeSelectTechnicalNum: 6,
    //财务方案
    financeNum: 36,
    //并行计算的技术方案数量
    syncCalcTechnicalNum: 5,
    //时序计算最大点数
    timingCalcMaxPoints: 8760,
  },
  emailTipVisible: false,
  updateUser: <any>[],
  changeMenu: <any>[],
  invite: null,
  projectAuth: null,
  projectDataAuth: {} as any,
}

export default Global

export const CustomIcons = createFromIconfontCN({
  scriptUrl: ['//at.alicdn.com/t/c/font_2992477_x4b9571lfml.js', '/assets/iconfont.js'],
})

export const getFixedNumber = (val: number, suffix: number) => {
  if (!val && val !== 0) {
    return '0'
  }
  return parseFloat(val.toFixed(suffix))
}

export const ChartColorMaps = [
  {
    line: 'rgba(30, 198, 146, 1)',
    area: ['rgba(30, 198, 146, 0.08)', 'rgba(30, 198, 146, 0)'],
  },
  {
    line: 'rgba(50, 116, 246, 1)',
    area: ['rgba(50, 116, 246, 0.08)', 'rgba(50, 116, 246, 0)'],
  },
  {
    line: 'rgba(246, 185, 50, 1)',
    area: ['rgba(246, 185, 50, 0.08)', 'rgba(246, 185, 50, 0)'],
  },
]

export const validate7Days = (_: any, value: any) => {
  if (!value || value.length < 2) {
    return Promise.resolve()
  }
  const [start, end] = value as moment.Moment[]
  if (start.diff(end, 'days') > -7) {
    return Promise.reject(new Error(_.message))
  } else {
    return Promise.resolve()
  }
}

export function getDomData(dom: any, { width, height, x, y }: any) {
  return new Promise<string>((resolve) => {
    const options: any = {
      allowTaint: true,
      useCORS: true,
      x: 0,
      y: 0,
    }
    width && (options.width = width)
    height && (options.height = height)
    x && (options.x = x)
    y && (options.y = y)
    console.log(dom, options)
    html2canvas(dom, options).then((canvas) => {
      canvas.id = 'render-canvas'
      const dataUrl = canvas.toDataURL('image/jpeg', 1)
      // const newImg = document.createElement('img')
      // newImg.src = dataUrl
      // document.body.appendChild(canvas)
      resolve(dataUrl)
    })
  })
}

;(window as any).getDomData = getDomData
;(window as any).html2canvas = html2canvas

export const showRegister = !!localStorage.getItem('show-register') || false

export const MAX_ATTACHMENT_COUNT = 10;

export const doDownload = (path: string, setLoading = (fl) => {return fl;}, type= 'GET', body?: any) => {
  setLoading(true)
  const prefix = axios.defaults.baseURL
  if(path[0] !== '/') {
    path = '/' + path;
  }
  const url = `${prefix || ''}${path}`
  const authToken: string = token.get() || ''
  fetch(url, {
    method: type,
    headers: {
      Authorization: authToken,
      'Content-Type': 'application/json;charset=UTF-8',
    },
    body: JSON.stringify(body),
  })
    .then((resp) => {
      let fileName =
        resp.headers.get('content-disposition')?.split(';')[1].split('filename=')[1] || `template-${Date.now()}.xlsx`
      fileName = decodeURIComponent(fileName)
      resp.blob().then((blob) => {
        if ((window.navigator as any).msSaveOrOpenBlob) {
          ;(window.navigator as any).msSaveBlob(blob, fileName)
        } else {
          const a = document.createElement('a')
          document.body.appendChild(a)
          const url = window.URL.createObjectURL(blob)
          a.href = url
          a.download = fileName
          a.target = '_blank'
          a.click()
          a.remove()
          window.URL.revokeObjectURL(url)
        }
      })
    })
    .finally(() => {
      setLoading(false)
    })
}

(window as any).doDownload =  doDownload
