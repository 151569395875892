import { message } from 'antd'

export type SubmitType = {
  onVisibleChange?: (visible: boolean) => void // 按钮状态处理函数
  isDraft?: boolean // 保存草稿、创建、更新
  success?: (data?: any) => void // 成功回调
  error?: () => void // 失败回调
  values: any // 用于提交的数据体
  // form?: FormInstance
  showMessage?: boolean // 操作成功是否提示
}

export interface ISubmitOptions {
  label?: string
  Api: (values: any) => Promise<any>
  success?: () => void
}
export const generatorSubmit = (opt: ISubmitOptions) => {
  return async ({ values, ...options }: SubmitType) => {
    // 开始提交
    options.onVisibleChange?.(true)
    // values.isDraft = Number(options.isDraft ?? false)
    values.is_draft = Number(options.isDraft ?? false)
    if ('mark' in values && values.mark?.startsWith('_mark')) {
      values.mark = ''
    }

    try {
      const res: any = await opt.Api(values)
      if (res?.code === 200) {
        options.showMessage && message.success(res.msg ?? `${opt.label ?? ''}操作成功~`)
        options.success?.(res?.data)

        opt.success?.()
      } else if (res?.msg) {
        options.error?.()
        message.error(res.msg)
      }
    } catch (err: any) {
      options.error?.()
      console.warn(`${opt.label ?? ''}更新失败: `, err)
    } finally {
      options.onVisibleChange?.(false)
    }
  }
}
