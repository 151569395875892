import { Cascader, Select, Spin } from 'antd'
import React from 'react'
import { RoofWordService } from '../../apis/project-service-history'

const deepMap = (data: any, maxDeep = 2): any[] => {
  const rsp: any = {}
  rsp.value = `${data.code}|||${data.name}`
  rsp.label = data.name
  const chidren = data.children
  if (chidren && maxDeep > 0) {
    rsp.children = chidren.map((o: any) => deepMap(o, maxDeep - 1))
  }
  return rsp
}

export const CitySelector = (props: { type?: string; value?: string; onChange?: (selected: string) => void }) => {

  const [options, setOptions] = React.useState<any[]>()
  const [cityOptions, setCityOptions] = React.useState<any[]>()

  React.useEffect(() => {
    RoofWordService.getRegionCode()
      .then((resp) => {
        setCityOptions(resp.data.map((o: any) => deepMap(o, 1)))
        setOptions(resp.data.map((o: any) => deepMap(o)))
      })
      .catch(() => {
      })
  }, [])

  const displayRender = (labels: any, selectedOptions: any) =>
    labels.map((label: any, i: number) => {
      const option = selectedOptions[i]
      if (!option) {
        return <span></span>
      }
      return (
        <span key={option.value}>
          {i !== 0 ? ' - ' : ''}{' '}{label}
        </span>
      )
    })

  const getValueWithCode = (code?: string) => {
    if (!code || !options) {
      return null
    }
    const result = ['', '', '']
    options.forEach((o: any) => {
      if (props.type === 'province') {
        if (o.value.includes(code)) {
          result[0] = o.value;
        }
      } else if (props.type === 'city') {
        o.children.forEach((oo: any) => {
          if (oo.value.includes(code)) {
            result[0] = o.value
            result[1] = oo.value
          }
        })
      } else {
        o.children.forEach((oo: any) => {
          oo.children.forEach((ooo: any) => {
            if (ooo.value.includes(code)) {
              result[0] = o.value
              result[1] = oo.value
              result[2] = ooo.value
            }
          })
        })
      }
    })
    return result
  }

  if (!options) {
    return <Spin></Spin>
  }
  if (props.type === 'province') {
    return (
      <Select
        style={{
          width: 300,
        }}
        className='form-cascader'
        options={options}
        value={getValueWithCode(props.value)?.[0] || ''}
        onChange={(data: any) => {
          if(data) {
            props.onChange?.(data.split('|||')[0])
          } else {
            props.onChange?.('')
          }
        }}
      />
    )
  }

  if (props.type === 'city') {
    return (
      <Cascader
        className='form-cascader'
        style={{
          width: 300,
        }}
        options={cityOptions}
        value={getValueWithCode(props.value) || ['', '']}
        displayRender={displayRender}
        onChange={(data: any) => {
          if (data && data.length === 2) {
            props.onChange?.(data[1].split('|||')[0])
          } else {
            props.onChange?.('')
          }
        }}
      />
    )
  }

  return (
    <Cascader
      className='form-cascader'
      style={{
        width: 300,
      }}
      options={options}
      value={getValueWithCode(props.value) || ['', '', '']}
      displayRender={displayRender}
      onChange={(data: any) => {
        if (data && data.length === 3) {
          props.onChange?.(data[2].split('|||')[0])
        } else {
          props.onChange?.('')
        }
      }}
    />
  )
}
