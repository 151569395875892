// 支付方式
export const payTypeOptions = [
  { id: '', fullName: '全部' },
  { id: '0', fullName: '单笔' },
  { id: '1', fullName: '批量' },
]

// 支付状态
export const payStatusOptions = [
  { id: '', fullName: '全部' },
  { id: '-10', fullName: '待审核', color: 'geekblue' },
  { id: '-20', fullName: '未支付', color: 'gold' },
  { id: '1', fullName: '支付中', color: 'gold' },
  { id: '4', fullName: '已支付', color: 'green' },
  { id: '5', fullName: '支付失败', color: 'red' },
]

// 申请类型
export const applyTypeOptions = [
  { id: '', fullName: '全部' },
  { id: '4', fullName: '支付货款-银联户' },
  { id: '2', fullName: '支付货款-非平台公司户' },
]

// 申请状态
export const applyStatusOptions = [
  { id: '', fullName: '全部' },
  { id: '0', fullName: '申请中', color: 'gold' },
  { id: '-1', fullName: '申请驳回', color: 'red' },
  { id: '-99', fullName: '解冻失败', color: 'red' },
  { id: '202', fullName: '已完成', color: 'green' },
]

// 操作事件 - 业务类型
export const businessTypeOptions = [
  { id: '0', fullName: '其他', color: '' },
  { id: '1', fullName: '新增', color: 'gold' },
  { id: '2', fullName: '修改', color: 'gold' },
  { id: '3', fullName: '删除', color: 'danger' },
]

// 入金记录状态
export const moneyFromListStatusOptions = [
  { id: '0', fullName: '未解冻', color: 'danger' },
  { id: '1', fullName: '已解冻', color: 'green' },
  { id: '-99 ', fullName: '解冻失败', color: 'danger' },
]
