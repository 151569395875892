import { Button, Card, Checkbox, Col, DatePicker, Row, Select, Slider, Spin } from 'antd'
import React, { useEffect, useState } from 'react'
import ReactECharts from 'echarts-for-react'

import './index.less'
import { PauseOutlined } from '@ant-design/icons'
import { PlayObservable$ } from './index'
import { CustomIcons } from '../../../global'
import { ProjectWorkbenchApi } from '../../../services'
import { ELEC_CHARTS_COLORS } from '../../../utils/constant'
import moment from 'moment'
import {parseNumber} from "../../resource-reckon/resource-map";
import {WorkspaceModal} from "../workspace";

const chartTypes = [
  {
    key: 'pvPower',
    name: '光伏'
  },
  {
    key: 'pv2battery',
    name: '光伏到储能',
    lines: ['pv_battery_fill']
  },
  {
    key: 'pv2grid',
    name: '光伏到电网',
    lines: [
      'gw_transformer_pv_fill',
      'transformer_pv_pv_fill',
      'gw_transformer_pv_fill_low',
      'transformer_pv_low_pv_fill'
    ]
  },
  {
    key: 'pv2demandel',
    name: '光伏到负荷',
    lines: ['pv_bear_fill']
  },
  {
    key: 'battery2grid',
    name: '储能到电网',
    lines: ['gw_transformer_battery_fill', 'transformer_battery_battery_fill']
  },
  {
    key: 'battery2demandel',
    name: '储能到负荷',
    lines: ['battery_bear_fill']
  },
  {
    key: 'batteryIn',
    name: '储能充电'
  },
  {
    key: 'batteryOut',
    name: '储能放电'
  },
  {
    key: 'grid2battery',
    name: '电网到储能',
    lines: ['gw_transformer_battery_fill', 'transformer_battery_battery_fill']
  },
  {
    key: 'grid2demandel',
    name: '电网到负荷',
    lines: ['gw_transformer_bear_fill', 'transformer_bear_bear_fill']
  },
  {
    key: 'batterySoc',
    name: '储能荷电状态'
  },
  {
    key: 'demandel',
    name: '负荷'
  }
]

const marks: any = {}
for (let i = 0; i < 24; i++) {
  marks[i * 60] = ''
}

let intervalHandler: any

export const InnerSlider = (props: { proposalData: any; selected?: string; currentType: string, energyFlowInfo }) => {
  const [time, setTime] = React.useState(0)

  const { particleSize } = props.proposalData
  // const { energyFlowInfo } = powerFlowDiagram
  const current = props.energyFlowInfo

  useEffect(() => {
    return () => {
      doAnimate()
      clearInterval(intervalHandler)
      intervalHandler = undefined
    }
  }, [])

  const doAnimate = (time?: number) => {
    if (!time) {
      PlayObservable$.next({
        infos: chartTypes
          .map((o) => o.lines)
          .flat()
          .filter(Boolean) as string[],
        animate: false
      })
      return
    }
    const idx = Math.floor(time / particleSize)
    const res: string[] = []
    PlayObservable$.next({
      infos: chartTypes
        .map((o) => o.lines)
        .flat()
        .filter(Boolean) as string[],
      animate: false
    })
    chartTypes.forEach((o) => {
      if (o.lines && current[o.key][idx] > 0) {
        res.push(...o.lines)
      }
    })
    PlayObservable$.next({ infos: res, animate: true })
  }
  return (
    <div
      style={{
        width: '100%',
        paddingLeft: 44,
        paddingRight: 48,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
      }}
    >
      <div
        style={{
          width: 60,
          textAlign: 'right'
        }}
      >
        <Button
          shape='circle'
          type='link'
          size='large'
          onClick={() => {
            if (!intervalHandler) {
              intervalHandler = setInterval(() => {
                setTime((time) => {
                  doAnimate(time)
                  if (time >= 23 * 60) {
                    doAnimate()
                    clearInterval(intervalHandler)
                    intervalHandler = undefined
                  }
                  return time + 2
                })
              }, 100)
            } else {
              clearInterval(intervalHandler)
              doAnimate()
              intervalHandler = undefined
              setTime(time - 1)
              setTime(time + 1)
            }
          }}
          icon={
            !intervalHandler ? (
              <CustomIcons
                type='klf-play'
                style={{
                  fontSize: 24
                }}
              />
            ) : (
              <PauseOutlined
                style={{
                  fontSize: 24
                }}
              />
            )
          }
        />
      </div>
      <Slider
        marks={marks}
        min={0}
        max={23 * 60}
        value={time}
        tooltipVisible={false}
        onChange={(val) => {
          setTime(val)
        }}
        style={{
          width: '100%',
          flexGrow: 1
        }}
        className={`line-tooltip ${props.selected ? 'line-tooltip-2' : 'line-tooltip-1'}`}
      />
    </div>
  )
}

const colorsArr = ELEC_CHARTS_COLORS

export const TechResult = (props: {
  proposalData: any
  currentSelected?: string
  onChangeSeason: (season: string) => void
  isCapture?: boolean
}) => {
  const { dataTime } = props.proposalData.powerFlowDiagram

  const [currentType, setCurrentType] = React.useState(dataTime?.[0])

  const [currentEnergyFlowInfo, setCurrentEnergyFlowInfo] = useState(props.proposalData.powerFlowDiagram.energyFlowInfo[currentType])

  const [key2TimeAndPrice, setKey2TimeAndPrice] = useState<any[]>()

  const [max, setMax] = React.useState(0)

  const [colorsDom, setColorsDom] = useState([])

  const getColorsDom = () => {
    return colorsDom
  }

  useEffect(() => {
    if (props.proposalData.topologyElectricityPrice?.key2TimeAndPrice) {
      const val = Object.values(props.proposalData.topologyElectricityPrice.key2TimeAndPrice)[0] as any
      const crtTime = moment(currentType).format('MM-DD')
      const crt = val.find(o => o.times.includes(crtTime))
      setColorsDom(Object.keys(crt.priceList).map(key => ({
        sName: val.name,
        price: crt.priceList[key],
        name: key,
        times: crt.priceTime[key]
      })))
    }
  }, [props.proposalData])

  useEffect(() => {
    if (key2TimeAndPrice) {
      // const crtTime = currentType.replace('2020-', '')
      const crtTime = moment(currentType).format('MM-DD')
      const crt = key2TimeAndPrice.find(o => o.times.includes(crtTime))
      setColorsDom(Object.keys(crt.priceList).map(key => {
        return {
          sName: crt.name,
          price: crt.priceList[key],
          name: key,
          times: crt.priceTime[key]
        }
      }))
    }
  }, [key2TimeAndPrice])

  useEffect(() => {

    if (currentType) {
      let max = 0
      const currentEnergyFlowInfo = props.proposalData.powerFlowDiagram.currentEnergyFlowInfo
      if (!currentEnergyFlowInfo) return
      chartTypes.forEach((o) => {
        max = Math.max(...currentEnergyFlowInfo[o.key], max)
      })

      setMax(max)
      setCurrentEnergyFlowInfo(currentEnergyFlowInfo)
      props.onChangeSeason?.(currentType)
    }
  }, [currentType])

  const [currentSelectedElePriceKey, setCurrentSelectedElePriceKey] = useState(props.proposalData.topologyElectricityPrice?.keys[0])

  const [showAll, setShowAll] = useState(true)

  const [loading, setLoading] = useState(false)

  const loadSelectedElePriceKey = (key: string = currentSelectedElePriceKey, time: string = currentType, _showAll = showAll) => {
    setLoading(true)
    new ProjectWorkbenchApi().i3060TechnicalWorkbenchGetTopologyElectricityInfoIdGet({
      id: props.proposalData.technologyId,
      key,
      time,
      showAll: _showAll ? '1' : '0'
    }).then(resp => {
      setCurrentEnergyFlowInfo(resp.data.energyFlowInfo)
      setKey2TimeAndPrice(resp.data.key2TimeAndPrice)
    }).finally(() => {
      setLoading(false)
    })
  }

  useEffect(() => {
    loadSelectedElePriceKey()
  }, [])

  const isOffline = props.proposalData?.loadType === 2
  const dateTimeList = props.proposalData.powerFlowDiagram?.dataTime

  const disabledDate = (date: moment.Moment) => {
    if (isOffline) {
      const dateTimeList = props.proposalData.powerFlowDiagram?.dataTime
      if (dateTimeList?.length > 0) {
        return !dateTimeList.includes(date.format('YYYY-MM-DD'))
      } else {
        return false
      }
    }

    const start = moment('2020-01-01')
    const end = moment('2021-01-01')
    if (date.isBefore(start) || !date.isBefore(end)) {
      return true
    }
    return false
  }

  if (!currentEnergyFlowInfo || !dataTime) return null


  const getChartElement = (
    name: string,
    chartTypes: any[],
    showSecondY = true,
    hideZoomButton = false,
    chartHeight = '150px',
  ) => {

    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'flex-start',
          height: chartHeight,
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            height: `${chartHeight.replace('- 151px', '- 189px')}`,
          }}
        >
          <div
            style={{
              fontSize: 14,
              color: '#767F95',
              lineHeight: '22px'
            }}
          >
            {name.split('').map((o) => [o, <br key={o} />])}
          </div>
          <div
            style={{
              width: 1,
              height: 60,
              background: '#EBEBEB',
              marginLeft: 16,
              marginRight: 16
            }}
          />
        </div>
        <div
          style={{
            flexGrow: 1,
            height: '100%',
            position: 'relative',
          }}
        >
          <ReactECharts
            style={{
              width: '100%',
              height: chartHeight,
              textAlign: 'left'
            }}
            notMerge
            option={{
              tooltip: {
                trigger: 'axis',
                formatter: (args: any) => {
                  let res = ''
                  if (args.length === 0) return res
                  res += `<div style='font-weight: 600;width: 200px;margin-bottom: 5px;'>${args[0].name}</div>`
                  args.forEach((o: any) => {
                    const doms = getColorsDom()
                    const oo = doms.find(oo => oo.name.includes(o.seriesName))
                    if (oo) {
                      if (o.value !== 0) {
                        res += `<div style='font-weight: 600;width: 200px;margin-bottom: 5px;'>${o.seriesName}<div style='float: right'>${oo.price}元/kWh</div></div>`
                      }
                    } else {
                      res += `<div style='width: 200px;'>${o.marker}${o.seriesName} <div style='float: right'>${
                        o.value
                      }${o.seriesName === '储能荷电状态' ? '%' : 'kW'}</div></div>`
                    }
                  })
                  return res
                }
              },
              legend: {
                data: chartTypes.filter((o) => currentEnergyFlowInfo[o.key]?.length > 0).map((o) => o.name),
                show: true,
                top: 'bottom',
                icon: 'rect'
              },
              grid: {
                top: '10px',
                left: '30px',
                right: '20px',
                bottom: '28px',
                containLabel: true
              },
              xAxis: {
                type: 'category',
                boundaryGap: false,
                data: currentEnergyFlowInfo.time.map((o: string) => o.split(':').slice(0, 2).join(':')),
                show: false
              },
              yAxis: [
                {
                  type: 'value',
                  min: 0,
                  splitLine: {
                    lineStyle: {
                      type: 'dashed'
                    }
                  },
                  axisLabel: {
                    showMaxLabel: true,
                    formatter: (value: number) => {
                      if (value === max * 1.2) {
                        return 'kW'
                      }
                      return parseNumber(value, {
                        type: 'e',
                        radix: 2,
                        beginValue: 100000,
                      });
                    }
                  },
                  boundaryGap: false,
                  max: (value) => {
                    // chartTypeMax[name] * 1.2
                    return value.max * 1.2 || 1;
                  },
                },
                {
                  type: 'value',
                  min: 0,
                  splitLine: {
                    show: false
                  },
                  axisLabel: {
                    showMaxLabel: true,
                    color: showSecondY ? 'rgb(118, 127, 149)' : 'white',
                    formatter: (value: number) => {
                      if (value === 120) {
                        return '%'
                      }
                      return value
                    }
                  },
                  boundaryGap: false,
                  max: 120
                }
              ],
              series: [
                ...getColorsDom().map((colorDom, idx) => {
                  return {
                    name: colorDom.name,
                    type: 'line',
                    step: 'middle',
                    itemStyle: {
                      color: 'transparent'
                    },
                    symbol: 'none',
                    areaStyle: {
                      color: `${colorsArr[idx]}26`
                    },
                    yAxisIndex: 1,
                    data: currentEnergyFlowInfo.time
                      .map((o: string) => o.split(':').slice(0, 2).join(':'))
                      .map((o: string) => {
                        if (currentEnergyFlowInfo.flatTime.includes(o) && colorDom.times.includes(o)) {
                          return 120;
                        } else {
                          return 0
                        }
                      })
                  }
                }),
                ...chartTypes
                  .map(
                    (o) =>
                      currentEnergyFlowInfo[o.key]?.length > 0 && {
                        name: o.name,
                        type: 'line',
                        step: 'middle',
                        symbol: 'none',
                        smooth: true,
                        yAxisIndex: o.key === 'batterySoc' ? 1 : 0,
                        data: currentEnergyFlowInfo[o.key]
                      }
                  )
                  .filter(Boolean)
              ]
            }}
          />
          {
            !hideZoomButton && <div style={{
              position: 'absolute',
              right: 50,
              top: 10,
            }}>
              <Button icon={<CustomIcons type="klf-Zoom" />} type="text" onClick={() => {
                WorkspaceModal.confirm({
                  className: 'max-size-modal',
                  title: `${name} - 有功功率图`,
                  icon: null,
                  content: <div style={{
                    width: '100%',
                    height: 'calc(100vh - 151px)',
                  }}>{getChartElement(name, chartTypes, showSecondY, true, 'calc(100vh - 151px)')}</div>,
                  width: '100vw',
                  height: '100vh',
                  cancelButtonProps: {
                    style: {
                      display: 'none',
                    }
                  },
                });
              }} />
            </div>
          }
        </div>
      </div>
    )
  }

  return (
    <Card
      style={{
        width: '100%',
        pointerEvents: 'all'
      }}
      bodyStyle={{
        padding: '20px 16px'
      }}
    >
      <Spin spinning={loading}>
        <Row style={{ height: 40, alignItems: 'center', flexWrap: 'nowrap' }}>
          <Col
            flex='1 1'
            style={{
              alignItems: 'center'
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center'
              }}
            >
              <span
                style={{
                  fontSize: 16,
                  fontWeight: 'bold',
                  color: '#1CB887',
                  flexShrink: 0
                }}
              >
                有功功率图
              </span>
              {
                !props.isCapture && props.proposalData.topologyElectricityPrice && <div style={{
                  flexShrink: 0
                }}>
                  <Select style={{
                    width: 200,
                    marginLeft: '1rem',
                    textAlign: 'left'
                  }} value={currentSelectedElePriceKey} onChange={(val) => {
                    // setLoading(true)
                    setCurrentSelectedElePriceKey(val)
                    loadSelectedElePriceKey(val)
                  }}>
                    {
                      props.proposalData.topologyElectricityPrice.keys.map(key => {
                        return <Select.Option key={key} value={key}>{key}</Select.Option>
                      })
                    }
                  </Select>
                </div>
              }
              {
                props.isCapture && <span style={{marginLeft: '.5rem'}}>{currentSelectedElePriceKey}</span>
              }
              {
                !props.isCapture && <Checkbox
                  checked={showAll}
                  style={{
                    marginLeft: '1rem',
                    flexShrink: 0
                  }}
                  onChange={(dom) => {
                    const val = dom.target.checked
                    setShowAll(val)
                    loadSelectedElePriceKey(undefined, undefined, val)
                  }}
                >显示所有数据</Checkbox>
              }
              <div style={{
                display: 'flex',
                alignItems: 'center',
                overflowX: 'auto',
                flexShrink: 1,
                flexGrow: 1
              }}>
                {
                  getColorsDom().map((o, idx) => {
                    return [
                      <div
                        key={o.price + '-color'}
                        style={{
                          width: 8,
                          height: 8,
                          background: `${colorsArr[idx]}26`,
                          border: '1px solid #1CB887',
                          borderColor: colorsArr[idx],
                          marginLeft: 16,
                          marginRight: 6,
                          flexShrink: 0
                        }}
                      />,
                      <span key={o.price} style={{
                        flexShrink: 0
                      }}>{o.name}</span>
                    ]
                  })
                }
              </div>
            </div>
          </Col>
          <Col
            style={{
              paddingRight: 20,
              flexShrink: 0
            }}
          >
            <span
              style={{
                marginRight: '1rem'
              }}
            >
              时间颗粒度：{props.proposalData.particleSize}分钟
            </span>
            {
              !props.isCapture && <DatePicker
                dropdownClassName={isOffline ? '' : 'default-select-date'}
                style={{
                  width: 150
                }}
                showToday={false}
                disabledDate={disabledDate}
                format='MM-DD'
                defaultValue={moment(`${currentType}`) as any}
                defaultPickerValue={(isOffline ? moment(dateTimeList?.[0]) : moment('2020-01-01')) as any}
                onChange={(src) => {
                  const val = isOffline ? src.format('YYYY-MM-DD') : src.format('2020-MM-DD')
                  setCurrentType(val)
                  loadSelectedElePriceKey(undefined, val)
                }}
              />
            }
          </Col>
        </Row>
        {props.currentSelected === 'pv' &&
          getChartElement(
            '光伏',
            chartTypes.filter((o) => o.name.includes('光伏')),
            false
          )}
        {props.currentSelected === 'battery' &&
          getChartElement(
            '储能',
            chartTypes.filter((o) => o.name.includes('储能')),
            true
          )}
        {getChartElement('系统', chartTypes)}
        {
          !props.isCapture && <InnerSlider
            selected={props.currentSelected}
            proposalData={props.proposalData}
            currentType={currentType}
            energyFlowInfo={currentEnergyFlowInfo}
          />
        }
        {
          !props.isCapture && <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'flex-start'
            }}
          >
            <div
              style={{
                width: 47,
                flexShrink: 0
              }}
            />
            <div
              style={{
                flexGrow: 1,
                height: '100%'
              }}
            >
              <ReactECharts
                style={{
                  height: 15,
                  textAlign: 'left'
                }}
                option={{
                  grid: {
                    top: '0px',
                    left: '24px',
                    right: '20px',
                    bottom: '0px',
                    containLabel: true
                  },
                  xAxis: {
                    type: 'category',
                    boundaryGap: false,
                    data: currentEnergyFlowInfo.time.map((o: string) => o.split(':').slice(0, 2).join(':')),
                    axisLine: {
                      show: false
                    },
                    axisTick: {
                      show: false
                    }
                  },
                  yAxis: {
                    name: 'kW',
                    type: 'value',
                    min: 0,
                    boundaryGap: false,
                    max: max * 1.2,
                    show: false
                  },
                  series: []
                }}
              />
            </div>
          </div>
        }
      </Spin>
    </Card>
  )
}
