import {parseNumber} from "../resource-map";
import area from "@turf/area";
import {Button, Form, Spin} from "antd";
import React, {useEffect, useState} from "react";
import {MountainForm} from "./mountain-form";
import {MountainFormChange, MountainFormResult} from "./mountain-form-result";
import {ResourcesControllerService, ResourceService} from "../../../apis/project-service";
import center from "@turf/center";
import _ from "lodash";
import bbox from "@turf/bbox";
import distance from "@turf/distance";
import {CustomIcons, doDownload} from "../../../global";

export const MountainCalculation = (props: any) => {
  const {data, drawer, onChange, adInfo} = props;

  const [step, setStep] = useState(0);
  const [result, setResult] = useState<any>();
  const [calcing, setCalcing] = useState(false)

  const [calcForm] = Form.useForm();
  const [eleForm] = Form.useForm();
  const [finForm] = Form.useForm();

  const [calcData, setCalcData] = useState<any>();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (data?.length > 0 && calcData && drawer.getAll().features.length > 0) {
      ResourcesControllerService.getI3060ResourceCalculateCapacityOrArea({
        availableArea: area(drawer.getAll()).toString(),
        areaEffiParaType: '1',
      }).then(resp => {
        calcData.pvCapacity = resp.data.pvCapacity;
      })
    }
  }, [data, calcData])

  useEffect(() => {
    ResourceService.getI3060ResourceGetPvDefaultParam({}).then(resp => {
      setCalcData(resp.data);
    }).finally(() => {
      setLoading(false);
    })
  }, [])

  useEffect(() => {
    props.setAreaEditable?.(step === 0);
  }, [step])

  const areaDisabled = () => {
    if (drawer?.getAll) {
      let bound;
      try {
        bound = bbox(drawer.getAll())
      } catch {
        return false;
      }
      const [x1, y1, x2, y2] = bound;

      if (!bound.every((o) => o !== Infinity)) {
        return false;
      }

      const lines = [
        [[x1, y1], [x1, y2]],
        [[x1, y1], [x2, y1]],
        [[x2, y2], [x1, y2]],
        [[x2, y2], [x2, y1]],
      ]

      const distArr = lines.map((o) => {
        return distance(o[0], o[1], {units: 'meters'});
      })

      return !distArr.every(o => o < 100000);
    }
    return false;
  }

  return <div>
    <div style={{
      fontSize: 14,
      color: '#262E3F',
      lineHeight: '40px',
      padding: '0 1rem'
    }}>光伏计算
    </div>
    {
      step === 0 && <div style={{padding: '0 1rem',}}>
        <div style={{
          fontSize: 14,
          color: '#262E3F',
          lineHeight: '51px',
        }}><span className="title-circle">1</span>选择自定义区域
        </div>
        <div style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          height: 34,
          background: '#F7F6F8',
          color: '#767F95'
        }}>
          <div style={{
            width: '50%',
            flexGrow: 0,
            flexShrink: 0,
          }}>区域名称
          </div>
          <div style={{
            width: '50%',
            flexGrow: 0,
            flexShrink: 0,
          }}>区域面积
          </div>
        </div>
        {
          data.map((o) => (
            <div className={props.selectedId === o.id ? 'calc-selected-row' : ''} style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              lineHeight: '34px',
            }} key={o.properties.idx} onClick={() => {
              drawer.changeMode('simple_select', {
                featureIds: [o.id],
              });
              props.changeSelectedId?.(o.id);

              const bound = bbox(o)
              const spl = 4
              const dx = bound[2] - bound[0]
              const dy = bound[3] - bound[1]
              const dsx = dx / spl
              const dsy = dy / spl
              const lt: [number, number] = [bound[0] - dsx, bound[1] - dsy]
              const rb: [number, number] = [bound[2] + dsx, bound[3] + dsy]
              const map = (window as any).mbxMap;
              const min = map.project(lt)
              const max = map.project(rb)
              map.fitScreenCoordinates(min, max, map.getBearing())

            }}>
              <div style={{
                width: '50%',
                flexGrow: 0,
                flexShrink: 0,
              }}>区域{o.properties.idx}</div>
              <div style={{
                width: '50%',
                flexGrow: 0,
                flexShrink: 0,
                display: "flex",
                flexDirection: 'row',
                justifyContent: "space-between",
              }}><span>{parseNumber(area(o), {
                type: 'e',
                radix: 2,
                beginValue: 100000,
              })} m^2</span>
                <Button type="link" onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  drawer.changeMode('simple_select', {
                    featureIds: [o.id],
                  });
                  drawer.trash()
                }}><CustomIcons type="klf-delete"/></Button>
              </div>
            </div>
          ))
        }
      </div>
    }
    {
      step === 1 && <div>
        <div style={{
          fontSize: 14,
          color: '#262E3F',
          lineHeight: '51px',
          padding: '0 1rem',
        }}><span className="title-circle">2</span>设置参数
        </div>
        <div>
          {
            loading && <Spin spinning/>
          }
          {
            !loading && <MountainForm
              area={area(drawer.getAll())}
              center={drawer?.getAll?.length > 0 && center(drawer.getAll())?.geometry?.coordinates}
              data={calcData}
              form={calcForm}
            />
          }
        </div>
      </div>
    }
    {
      step === 2 && <div>
        <div style={{
          fontSize: 14,
          color: '#262E3F',
          lineHeight: '51px',
          padding: '0 1rem',
        }}><span className="title-circle">3</span>查看计算结果
        </div>
        <div>
          <MountainFormResult
            result={result}
            onChange={(type: string) => {
              setStep({'ele': 3, 'fin': 4}[type])
            }}
          />
        </div>
      </div>
    }
    {
      step === 3 && <div>
        <div style={{
          fontSize: 14,
          color: '#262E3F',
          lineHeight: '51px',
          padding: '0 1rem',
        }}><span className="title-circle">3</span>查看计算结果
        </div>
        <div>
          <MountainFormChange
            form={eleForm}
            type='ele'
          />
        </div>
      </div>
    }
    {
      step === 4 && <div>
        <div style={{
          fontSize: 14,
          color: '#262E3F',
          lineHeight: '51px',
          padding: '0 1rem',
        }}><span className="title-circle">3</span>查看计算结果
        </div>
        <div>
          <MountainFormChange
            form={finForm}
            type='fin'
          />
        </div>
      </div>
    }
    <div style={{
      textAlign: "right",
      marginTop: '1rem',
      padding: '0 1rem 1rem 1rem',
    }}>
      {
        step === 0 && areaDisabled() && <div style={{
          display: 'inline-block',
          marginRight: '.5rem',
          color: '#f97942',
          textAlign: "left",
        }}>区域跨度过大，请重新选择<br/></div>
      }
      {
        step === 0 && <Button type="primary" ghost onClick={() => {
          drawer?.deleteAll();
          onChange?.([]);
        }}>清空</Button>
      }
      {
        (step === 1 || step === 2) && <Button type="primary" ghost onClick={() => {
          setStep(step - 1);
        }}>上一步</Button>
      }
      {
        (step === 3 || step === 4) && <Button type="primary" ghost onClick={() => {
          setStep(2);
        }}>取消</Button>
      }
      {
        step === 0 && <Button disabled={data.length === 0 || areaDisabled()} style={{
          marginLeft: '.5rem'
        }} type="primary" onClick={() => {
          setStep(1);
        }}>下一步</Button>
      }
      {
        step === 1 && <Button loading={calcing} disabled={data.length === 0} style={{
          marginLeft: '.5rem'
        }} type="primary" onClick={() => {

          calcForm.validateFields().then((data) => {

            const financeTool2Req = _.cloneDeep(data);

            // const square = area(drawer.getAll())
            const [longitude, latitude] = center(drawer.getAll())?.geometry?.coordinates.map(o => o.toString());

            const changeVal = (obj: any) => {
              Object.keys(obj).forEach(o => {
                const so = obj[o];
                if (typeof so !== "object") {
                  obj[o] = so.toString();
                } else {
                  changeVal(so);
                }
              })
            }
            changeVal(financeTool2Req);

            console.log(financeTool2Req);
            const {
              pvCapacity,
              surfaceTilt,
              systemEfficiency,
              firstYearLoss,
              yearlyLoss,
              techProposalParam,
              capitalCostParam,
              operationCostParam,
              measurementParam

            } = financeTool2Req;
            setCalcing(true);
            ResourceService.postI3060ResourceCalculatePvTool2({
              root: {
                surfaceTilt,
                systemEfficiency,
                firstYearLoss,
                yearlyLoss,
                pvCapacity,
                financeTool2Req: {
                  techProposalParam,
                  capitalCostParam,
                  operationCostParam,
                  measurementParam
                },
                latitude,
                longitude,
                adcode: adInfo.adcode,
              }
            }).then(resp => {
              const {
                firstYearPvPower,
                firstYearPvHours,
                yearlyPvPower,
                yearlyPvHours,
                irrOne,
                irrZero,
                npv,
                returnOnInvestment,
                roe,
                paybackPeriod,
                totalRevenue,
                ancf,
              } = resp.data;
              setResult(resp.data);
              eleForm.setFieldsValue({
                firstYearPvPower,
                firstYearPvHours,
                yearlyPvPower,
                yearlyPvHours
              });
              finForm.setFieldsValue({
                irrOne,
                irrZero,
                npv,
                returnOnInvestment,
                roe,
                paybackPeriod,
                totalRevenue,
                ancf,
              });
              setStep(2);
            }).finally(() => {
              setCalcing(false);
            })
          })
        }}>计算</Button>
      }
      {
        step === 2 && <Button disabled={data.length === 0} style={{
          marginLeft: '.5rem'
        }} type="primary" onClick={() => {
          props.setLoading(true);
          const {
            firstYearPvPower,
            firstYearPvHours,
            yearlyPvPower,
            yearlyPvHours,
            irrOne,
            irrZero,
            npv,
            returnOnInvestment,
            roe,
            paybackPeriod,
            totalRevenue,
            ancf,
            firstYearPvPowerMonthly,
            pvPower25Year,
          } = result;
          const pvResult = {
            firstYearPvPower,
            firstYearPvHours,
            yearlyPvPower,
            yearlyPvHours,
            firstYearPvPowerMonthly,
            pvPower25Year
          };
          const financialResult = {
            irrOne,
            irrZero,
            npv,
            returnOnInvestment,
            roe,
            paybackPeriod,
            totalRevenue,
            ancf,
          };

          const financeTool2Req = calcForm.getFieldsValue([
            'techProposalParam',
            'capitalCostParam',
            'operationCostParam',
            'measurementParam',
            'systemEfficiency',
            'firstYearLoss',
            'yearlyLoss',
            'surfaceTilt',
            'pvCapacity',
          ]);

          const changeVal = (obj: any) => {
            Object.keys(obj).forEach(o => {
              console.log(o);
              const so = obj[o];
              if (so === undefined || so === null) {
                return;
              }
              if (typeof so !== "object") {
                obj[o] = so.toString();
              } else {
                changeVal(so);
              }
            })
          }
          changeVal(financeTool2Req);

          const {
            techProposalParam,
            capitalCostParam,
            operationCostParam,
            measurementParam,
            systemEfficiency,
            firstYearLoss,
            yearlyLoss,
            surfaceTilt,
            pvCapacity,
          } = financeTool2Req;

          // console.log(f)

          const financialParam = {
            techProposalParam,
            capitalCostParam,
            operationCostParam,
            measurementParam,
          }
          const [longitude, latitude] = center(drawer.getAll())?.geometry?.coordinates.map(o => o.toString());
          const pvParam = {
            systemEfficiency,
            firstYearLoss,
            yearlyLoss,
            surfaceTilt,
            pvCapacity,
            latitude,
            longitude,
            adcode: adInfo.adcode,
          };

          let lastData = '';
          const itv = () => {
            ;(window as any).doShootMap((dataUrl) => {
              const imgBase64 = dataUrl.replace('data:image/jpeg;base64,', '')
              if (lastData !== imgBase64) {
                lastData = imgBase64;
                setTimeout(() => {
                  itv();
                }, 2000);
              } else {
                doDownload('/api/i3060/resource/generateReport', props.setLoading, 'POST', {
                  pvParam,
                  pvResult,
                  financialResult,
                  financialParam,
                  totalArea: area(drawer.getAll()),
                  imgBase64,
                });
              }
            })
          }

          itv();


        }}>生成报告</Button>
      }
      {
        step === 3 && <Button loading={calcing} disabled={data.length === 0} style={{
          marginLeft: '.5rem'
        }} type="primary" onClick={() => {
          eleForm.validateFields().then(resp => {
            const {
              firstYearPvPower,
              firstYearPvHours,
              yearlyPvPower,
              yearlyPvHours,
            } = resp;

            const data = calcForm.getFieldsValue([
              'pvCapacity',
              'surfaceTilt',
              'systemEfficiency',
              'firstYearLoss',
              'yearlyLoss',
              'techProposalParam',
              'capitalCostParam',
              'operationCostParam',
              'measurementParam',
            ])
            const financeTool2Req = _.cloneDeep(data);

            // const square = area(drawer.getAll())
            const [longitude, latitude] = center(drawer.getAll())?.geometry?.coordinates.map(o => o.toString());

            const changeVal = (obj: any) => {
              if (!obj) return;
              Object.keys(obj).forEach(o => {
                const so = obj[o];
                if (typeof so !== "object") {
                  obj[o] = so.toString();
                } else {
                  changeVal(so);
                }
              })
            }
            changeVal(financeTool2Req);

            console.log(financeTool2Req);
            const {
              pvCapacity,
              surfaceTilt,
              systemEfficiency,
              firstYearLoss,
              yearlyLoss,
              techProposalParam,
              capitalCostParam,
              operationCostParam,
              measurementParam
            } = financeTool2Req;
            setCalcing(true);
            ResourceService.postI3060ResourceCalculateFinance({
              root: {
                surfaceTilt,
                systemEfficiency,
                firstYearLoss,
                yearlyLoss,
                pvCapacity,
                firstYearPvPower,
                firstYearPvHours,
                yearlyPvPower,
                yearlyPvHours,
                financeTool2Req: {
                  techProposalParam,
                  capitalCostParam,
                  operationCostParam,
                  measurementParam
                },
                latitude,
                longitude,
                adcode: adInfo.adcode,
              }
            }).then(resp => {
              const {
                firstYearPvPower,
                firstYearPvHours,
                yearlyPvPower,
                yearlyPvHours,
                irrOne,
                irrZero,
                npv,
                returnOnInvestment,
                roe,
                paybackPeriod,
                totalRevenue,
                ancf,
              } = resp.data;
              setResult(resp.data);
              eleForm.setFieldsValue({
                firstYearPvPower,
                firstYearPvHours,
                yearlyPvPower,
                yearlyPvHours
              });
              finForm.setFieldsValue({
                irrOne,
                irrZero,
                npv,
                returnOnInvestment,
                roe,
                paybackPeriod,
                totalRevenue,
                ancf,
              });
              setStep(2);
            }).finally(() => {
              setCalcing(false);
            })
          })
        }}>计算财务</Button>
      }
      {
        step === 4 && <Button disabled={data.length === 0} style={{
          marginLeft: '.5rem'
        }} type="primary" onClick={() => {
          finForm.validateFields().then(resp => {
            setResult({
              ...result,
              ...resp,
            })
            setStep(2);
          })
        }}>确定</Button>
      }
    </div>
  </div>
}
