import { CloseOutlined } from '@ant-design/icons'
import { Button, Form, InputNumber, message, Radio, Select, Spin, Tabs } from 'antd'
import _ from 'lodash'
import React, { useEffect, useState } from 'react'
import { ProjectServiceHistory, ResourceService } from '../../apis/project-service-history'
import ReactECharts from 'echarts-for-react'
import { getFixedNumber } from '../../global'

const parseValueToStr = (obj: any) => {
  if (!obj) {
    return undefined
  }
  Object.keys(obj).forEach((key) => {
    const val = obj[key]
    if (typeof val === 'number') {
      obj[key] = val.toString()
    }
    if (typeof val === 'object') {
      parseValueToStr(val)
    }
  })
}

const resultConfig = {
  fin: [
    {
      key: 'irrOne',
      unit: '%',
      tips: '总投资内部收益率'
    },
    {
      key: 'irrZero',
      unit: '%',
      tips: '资本金内部收益率'
    },
    {
      key: 'npv',
      unit: '万元',
      tips: '财务净现值'
    },
    {
      key: 'returnOnInvestment',
      unit: '%',
      tips: '总投资收益率'
    },
    {
      key: 'roe',
      unit: '%',
      tips: '资本金净利润率'
    },
    {
      key: 'paybackPeriod',
      unit: '年',
      tips: '静态投资回收期'
    },
    {
      key: 'totalRevenue',
      unit: '万元',
      tips: '总收益'
    },
    {
      key: 'ancf',
      unit: '万元',
      tips: '累计现金流'
    }
  ],
  ele: [
    {
      key: 'firstYearPvPower',
      unit: 'kWh',
      tips: '首年发电量'
    },
    {
      key: 'firstYearPvHours',
      unit: 'h',
      tips: '首年发电小时数'
    },
    {
      key: 'yearlyPvPower',
      unit: 'kWh',
      tips: '年均发电量'
    },
    {
      key: 'yearlyPvHours',
      unit: 'h',
      tips: '年均发电小时数'
    }
  ]
}

let adcode = ''

export const MiniToolBar = (props: any) => {
  const [addressList, setAddressList] = useState([])
  const [form] = Form.useForm()

  const [yearTotalRadite, setYearTotalRadite] = useState<any>()

  const [activeKey, setActiveKey] = useState('1')
  const [calcType, setCalcType] = useState<any>('area')
  const [calcing, setCalcing] = useState(false)

  const [compPrice, setCompPrice] = useState(0)

  const [backup, setBackup] = useState<any>()
  const [, setVer] = useState(0)

  const [result, setResult] = useState<any>({
    ancf: '--',
    firstYearPvHours: '--',
    firstYearPvPower: '--',
    firstYearPvPowerMonthly: [],
    irrOne: '--',
    irrZero: '--',
    npv: '--',
    paybackPeriod: '--',
    pvPower25Year: [],
    returnOnInvestment: '--',
    roe: '--',
    totalRevenue: '--',
    yearlyPvHours: '--',
    yearlyPvPower: '--'
  })

  const isChanged = (key: string): any => {
    const errs = form.getFieldError(key.split('.'))
    if (errs.length > 0) {
      return 'error'
    }
    if (!backup) {
      return 'success'
    }
    const fields = form.getFieldsValue()
    const fld = _.get(fields, key)
    const bku = _.get(backup, key)
    if (fld?.toString() !== bku?.toString()) {
      return 'warning'
    }
    return 'success'
  }

  const calcWithValues = (values) => {
    const {
      addressObject,
      availableArea,
      financeToolReq: {
        capitalCostParam: { unitCost },
        techProposalParam: { consumptionRate, gridPrice, localPrice },
        measurementParam: {
          unitWorkingCapital,
          longLoanRate,
          longLoanInterestRate,
          longLoanYear,
          addedValueTaxRate,
          incomeTaxRate,
          discountRate
        },
        operationCostParam: { omCostUnitPrice }
      },
      firstYearLoss,
      pvCapacity,
      systemEfficiency,
      yearlyLoss
    } = values
    const { location } = JSON.parse(addressObject)
    const [longitude, latitude] = location.split(',')
    const root = {
      longitude,
      latitude,
      adcode,
      availableArea,
      financeToolReq: {
        capitalCostParam: {
          unitCost
        },
        techProposalParam: {
          consumptionRate,
          gridPrice,
          localPrice
        },
        measurementParam: {
          unitWorkingCapital,
          longLoanRate,
          longLoanInterestRate,
          longLoanYear,
          addedValueTaxRate,
          incomeTaxRate,
          discountRate
        },
        operationCostParam: {
          omCostUnitPrice
        }
      },
      firstYearLoss,
      pvCapacity,
      systemEfficiency,
      yearlyLoss
    }
    parseValueToStr(root)
    ResourceService.calculatePvTool({
      root
    })
      .then((resp) => {
        setActiveKey('2')
        setResult(resp.data)
        setBackup({ ...root, addressObject })
        setLoading(false)
      })
      .catch(() => {
        setLoading(false)
      })
  }

  useEffect(() => {
    ResourceService.getDefaultCalculate({}).then((resp) => {
      form.setFieldsValue({ ...resp.data })
      calcCompPrice(resp.data)
    })
  }, [])

  const calcCompPrice = (data = form.getFieldsValue()) => {
    const {
      financeToolReq: {
        techProposalParam: { consumptionRate, gridPrice, localPrice }
      }
    } = data
    if (consumptionRate !== undefined && gridPrice !== undefined && localPrice !== undefined) {
      const _compPrice: number = gridPrice * (1 - consumptionRate / 100) + (localPrice * consumptionRate) / 100
      setCompPrice(parseFloat(_compPrice.toFixed(4)))
    }
  }

  const onSearch = (val: string) => {
    ProjectServiceHistory.inputTips({ cityCode: '', address: val }).then((resp: any) => {
      setAddressList(resp.data)
    })
  }
  const [loading, setLoading] = useState(false)
  return (
    <div
      className='mini-tool-bar'
      style={{
        padding: 10,
        width: '100%',
        height: '100%'
      }}
    >
      <Spin spinning={loading}>
        <Tabs
          activeKey={activeKey}
          onChange={setActiveKey}
          type='card'
          size='small'
          style={{
            height: '100%'
          }}
          tabBarExtraContent={
            <Button
              type='link'
              onClick={() => {
                props.onClose?.()
              }}
              style={{
                color: 'lightgrey'
              }}
            >
              <CloseOutlined />
            </Button>
          }
        >
          <Tabs.TabPane tab='参数设置' key='1'>
            <Form
              form={form}
              style={{
                padding: '.5rem 1rem',
                height: '100%',
                overflowY: 'auto',
                overflowX: 'hidden'
              }}
              wrapperCol={{ span: 14 }}
              labelCol={{ span: 10 }}
            >
              <Form.Item
                label='项目地址'
                name='addressObject'
                validateStatus={isChanged('addressObject')}
                rules={[
                  {
                    required: true,
                    message: '请选择项目地址'
                  }
                ]}
              >
                <Select
                  className='gaode-input small-empty'
                  size='middle'
                  showSearch
                  onChange={(val: string) => {
                    const obj = JSON.parse(val)
                    adcode = obj.adcode
                    if (obj?.location) {
                      const lngLat = obj.location.split(',').map(parseFloat)
                      form.setFieldsValue({
                        longitude: lngLat[0],
                        latitude: lngLat[1]
                      })
                      ResourceService.calculateSolarRadiation({
                        longitude: lngLat[0],
                        latitude: lngLat[1]
                      }).then((resp) => {
                        setYearTotalRadite(resp.data)
                      })
                    }
                  }}
                  onSearch={_.debounce(onSearch, 300)}
                  filterOption={() => true}
                >
                  {addressList?.map((o: any, idx) => (
                    <Select.Option key={`${o.address}--${idx}`} value={JSON.stringify(o)}>
                      {o.name}
                      <span className='hide-in-input' style={{ fontSize: 10, color: 'lightgrey', marginLeft: '1rem' }}>
                        {o.district}
                      </span>
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                label='年总辐照'
                rules={[
                  {
                    required: true,
                    message: ''
                  }
                ]}
              >
                <span>{yearTotalRadite === undefined ? '--' : yearTotalRadite} kWh/㎡</span>
              </Form.Item>
              <div className='split-line' />
              <Form.Item
                label='计算方式'
                rules={[
                  {
                    required: true,
                    message: ''
                  }
                ]}
              >
                <Radio.Group
                  disabled={calcing}
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    marginRight: -25
                  }}
                  value={calcType}
                  options={[
                    {
                      label: '装机面积',
                      value: 'area'
                    },
                    {
                      label: '装机容量',
                      value: 'capacity'
                    }
                  ]}
                  onChange={(dom) => {
                    setCalcType(dom.target.value)
                  }}
                />
              </Form.Item>
              {calcType !== 'capacity' && (
                <Form.Item
                  label='装机面积'
                  name='availableArea'
                  validateStatus={isChanged('availableArea')}
                  rules={[
                    {
                      required: true,
                      message: '请输入装机面积'
                    }
                  ]}
                >
                  <InputNumber
                    style={{
                      width: '100%'
                    }}
                    placeholder='请输入装机面积'
                    size='middle'
                    addonAfter='㎡'
                    controls={false}
                    precision={2}
                    min={0.01}
                    max={999999}
                    onBlur={(dom) => {
                      setCalcing(true)
                      setTimeout(() => {
                        if (dom.target.value) {
                          ResourceService.calculateCapacityOrArea({
                            availableArea: dom.target.value
                          }).then((resp) => {
                            const { pvCapacity } = resp.data
                            console.log(pvCapacity)
                            form.setFieldsValue({
                              pvCapacity
                            })
                            setCalcing(false)
                          })
                        } else {
                          form.setFieldsValue({
                            pvCapacity: 0
                          })
                          setCalcing(false)
                        }
                      }, 100)
                    }}
                  />
                </Form.Item>
              )}
              {calcType === 'capacity' && (
                <Form.Item
                  label='装机容量'
                  name='pvCapacity'
                  validateStatus={isChanged('pvCapacity')}
                  rules={[
                    {
                      required: true,
                      message: '请输入装机容量'
                    }
                  ]}
                >
                  <InputNumber
                    style={{
                      width: '100%'
                    }}
                    placeholder='请输入装机容量'
                    size='middle'
                    addonAfter='kWp'
                    controls={false}
                    precision={2}
                    min={0.01}
                    max={999999}
                    onBlur={(dom) => {
                      setCalcing(true)
                      setTimeout(() => {
                        if (dom.target.value) {
                          ResourceService.calculateCapacityOrArea({
                            pvCapacity: dom.target.value
                          }).then((resp) => {
                            const { availableArea } = resp.data
                            form.setFieldsValue({
                              availableArea
                            })
                            setCalcing(false)
                          })
                        } else {
                          form.setFieldsValue({
                            availableArea: 0
                          })
                          setCalcing(false)
                        }
                      }, 100)
                    }}
                  />
                </Form.Item>
              )}
              {calcType === 'capacity' && (
                <Form.Item
                  label='装机面积'
                  name='availableArea'
                  rules={[
                    {
                      required: true,
                      message: ''
                    }
                  ]}
                >
                  <span>{form.getFieldValue('availableArea') || '--'}㎡</span>
                </Form.Item>
              )}
              {calcType !== 'capacity' && (
                <Form.Item
                  label='装机容量'
                  name='pvCapacity'
                  rules={[
                    {
                      required: true,
                      message: ''
                    }
                  ]}
                >
                  <span>{form.getFieldValue('pvCapacity') || '--'}kWp</span>
                </Form.Item>
              )}
              <div className='split-line' />
              <Form.Item
                label='系统效率'
                name='systemEfficiency'
                validateStatus={isChanged('systemEfficiency')}
                rules={[
                  {
                    required: true,
                    message: '请输入系统效率'
                  }
                ]}
              >
                <InputNumber
                  style={{
                    width: '100%'
                  }}
                  placeholder='请输入系统效率'
                  size='middle'
                  addonAfter='%'
                  controls={false}
                  precision={2}
                  min={0}
                  max={100}
                  onBlur={() => {
                    setVer((ver) => ver + 1)
                  }}
                />
              </Form.Item>
              <Form.Item
                label='首年衰减率'
                name='firstYearLoss'
                validateStatus={isChanged('firstYearLoss')}
                rules={[
                  {
                    required: true,
                    message: '请输入首年衰减率'
                  }
                ]}
              >
                <InputNumber
                  style={{
                    width: '100%'
                  }}
                  placeholder='请输入首年衰减率'
                  size='middle'
                  addonAfter='%'
                  controls={false}
                  precision={2}
                  min={0}
                  max={100}
                  onBlur={() => {
                    setVer((ver) => ver + 1)
                  }}
                />
              </Form.Item>
              <Form.Item
                label='逐年衰减率'
                name='yearlyLoss'
                validateStatus={isChanged('yearlyLoss')}
                rules={[
                  {
                    required: true,
                    message: '请输入逐年衰减率'
                  }
                ]}
              >
                <InputNumber
                  style={{
                    width: '100%'
                  }}
                  placeholder='请输入逐年衰减率'
                  size='middle'
                  addonAfter='%'
                  controls={false}
                  precision={2}
                  min={0}
                  max={100}
                  onBlur={() => {
                    setVer((ver) => ver + 1)
                  }}
                />
              </Form.Item>
              <div className='split-line' />
              <Form.Item
                label='单位造价'
                name={'financeToolReq.capitalCostParam.unitCost'.split('.')}
                validateStatus={isChanged('financeToolReq.capitalCostParam.unitCost')}
                rules={[
                  {
                    required: true,
                    message: '请输入单位造价'
                  }
                ]}
              >
                <InputNumber
                  style={{
                    width: '100%'
                  }}
                  placeholder='请输入单位造价'
                  size='middle'
                  addonAfter='元/kW'
                  controls={false}
                  precision={2}
                  min={0.01}
                  max={999999}
                  onBlur={() => {
                    setVer((ver) => ver + 1)
                  }}
                />
              </Form.Item>
              <Form.Item
                label='消纳率'
                name={'financeToolReq.techProposalParam.consumptionRate'.split('.')}
                validateStatus={isChanged('financeToolReq.techProposalParam.consumptionRate')}
                rules={[
                  {
                    required: true,
                    message: '请输入消纳率'
                  }
                ]}
              >
                <InputNumber
                  style={{
                    width: '100%'
                  }}
                  placeholder='请输入消纳率'
                  size='middle'
                  addonAfter='%'
                  controls={false}
                  precision={2}
                  min={0}
                  max={100}
                  onChange={() => {
                    setTimeout(() => {
                      calcCompPrice()
                    }, 50)
                  }}
                  onBlur={() => {
                    setVer((ver) => ver + 1)
                  }}
                />
              </Form.Item>
              <Form.Item
                label='上网电价'
                name={'financeToolReq.techProposalParam.gridPrice'.split('.')}
                validateStatus={isChanged('financeToolReq.techProposalParam.gridPrice')}
                rules={[
                  {
                    required: true,
                    message: '请输入上网电价'
                  }
                ]}
              >
                <InputNumber
                  style={{
                    width: '100%'
                  }}
                  placeholder='请输入上网电价'
                  size='middle'
                  addonAfter='元/kWh'
                  controls={false}
                  precision={4}
                  min={0.0001}
                  max={999999}
                  onChange={() => {
                    setTimeout(() => {
                      calcCompPrice()
                    }, 50)
                  }}
                  onBlur={() => {
                    setVer((ver) => ver + 1)
                  }}
                />
              </Form.Item>
              <Form.Item
                label='消纳电价'
                name={'financeToolReq.techProposalParam.localPrice'.split('.')}
                validateStatus={isChanged('financeToolReq.techProposalParam.localPrice')}
                rules={[
                  {
                    required: true,
                    message: '请输入消纳电价'
                  }
                ]}
              >
                <InputNumber
                  style={{
                    width: '100%'
                  }}
                  placeholder='请输入消纳电价'
                  size='middle'
                  addonAfter='元/kWh'
                  controls={false}
                  precision={4}
                  min={0.0001}
                  max={999999}
                  onChange={() => {
                    setTimeout(() => {
                      calcCompPrice()
                    }, 50)
                  }}
                  onBlur={() => {
                    setVer((ver) => ver + 1)
                  }}
                />
              </Form.Item>
              <Form.Item
                label='综合电价'
                rules={[
                  {
                    required: true,
                    message: ''
                  }
                ]}
              >
                <span>{compPrice || '--'}元/kWh</span>
              </Form.Item>
              <div className='split-line' />
              <Form.Item
                label='流动资金'
                name={'financeToolReq.measurementParam.unitWorkingCapital'.split('.')}
                validateStatus={isChanged('financeToolReq.measurementParam.unitWorkingCapital')}
                rules={[
                  {
                    required: true,
                    message: '请输入流动资金'
                  }
                ]}
              >
                <InputNumber
                  style={{
                    width: '100%'
                  }}
                  placeholder='请输入流动资金'
                  size='middle'
                  addonAfter='元/kW'
                  controls={false}
                  precision={2}
                  min={0.01}
                  max={999999}
                  onBlur={() => {
                    setVer((ver) => ver + 1)
                  }}
                />
              </Form.Item>
              <Form.Item
                label='长期贷款比例'
                name={'financeToolReq.measurementParam.longLoanRate'.split('.')}
                validateStatus={isChanged('financeToolReq.measurementParam.longLoanRate')}
                rules={[
                  {
                    required: true,
                    message: '请输入长期贷款比例'
                  }
                ]}
              >
                <InputNumber
                  style={{
                    width: '100%'
                  }}
                  placeholder='请输入长期贷款比例'
                  size='middle'
                  addonAfter='%'
                  controls={false}
                  precision={1}
                  min={0}
                  max={100}
                  onBlur={() => {
                    setVer((ver) => ver + 1)
                  }}
                />
              </Form.Item>
              <Form.Item
                label='长期贷款利率'
                name={'financeToolReq.measurementParam.longLoanInterestRate'.split('.')}
                validateStatus={isChanged('financeToolReq.measurementParam.longLoanInterestRate')}
                rules={[
                  {
                    required: true,
                    message: '请输入长期贷款利率'
                  }
                ]}
              >
                <InputNumber
                  style={{
                    width: '100%'
                  }}
                  placeholder='请输入长期贷款利率'
                  size='middle'
                  addonAfter='%'
                  controls={false}
                  precision={2}
                  min={0.01}
                  max={100}
                  onBlur={() => {
                    setVer((ver) => ver + 1)
                  }}
                />
              </Form.Item>
              <Form.Item
                label='长期贷款年限'
                name={'financeToolReq.measurementParam.longLoanYear'.split('.')}
                validateStatus={isChanged('financeToolReq.measurementParam.longLoanYear')}
                rules={[
                  {
                    required: true,
                    message: '请输入长期贷款年限'
                  }
                ]}
              >
                <InputNumber
                  style={{
                    width: '100%'
                  }}
                  placeholder='请输入长期贷款年限'
                  size='middle'
                  addonAfter='年'
                  controls={false}
                  precision={0}
                  min={1}
                  max={25}
                  onBlur={() => {
                    setVer((ver) => ver + 1)
                  }}
                />
              </Form.Item>
              <div className='split-line' />
              <Form.Item
                label='经营成本'
                name={'financeToolReq.operationCostParam.omCostUnitPrice'.split('.')}
                validateStatus={isChanged('financeToolReq.operationCostParam.omCostUnitPrice')}
                rules={[
                  {
                    required: true,
                    message: '请输入经营成本'
                  }
                ]}
              >
                <InputNumber
                  style={{
                    width: '100%'
                  }}
                  placeholder='请输入经营成本'
                  size='middle'
                  addonAfter='元/kW'
                  controls={false}
                  precision={2}
                  min={0.01}
                  max={999999}
                  onBlur={() => {
                    setVer((ver) => ver + 1)
                  }}
                />
              </Form.Item>
              <Form.Item
                label='增值税'
                name={'financeToolReq.measurementParam.addedValueTaxRate'.split('.')}
                validateStatus={isChanged('financeToolReq.measurementParam.addedValueTaxRate')}
                rules={[
                  {
                    required: true,
                    message: '请输入增值税'
                  }
                ]}
              >
                <InputNumber
                  style={{
                    width: '100%'
                  }}
                  placeholder='请输入增值税'
                  size='middle'
                  addonAfter='%'
                  controls={false}
                  precision={2}
                  min={0}
                  max={100}
                  onBlur={() => {
                    setVer((ver) => ver + 1)
                  }}
                />
              </Form.Item>
              <Form.Item
                label='所得税'
                name={'financeToolReq.measurementParam.incomeTaxRate'.split('.')}
                validateStatus={isChanged('financeToolReq.measurementParam.incomeTaxRate')}
                rules={[
                  {
                    required: true,
                    message: '请输入所得税'
                  }
                ]}
              >
                <InputNumber
                  style={{
                    width: '100%'
                  }}
                  placeholder='请输入所得税'
                  size='middle'
                  addonAfter='%'
                  controls={false}
                  precision={2}
                  min={0}
                  max={100}
                  onBlur={() => {
                    setVer((ver) => ver + 1)
                  }}
                />
              </Form.Item>
              <Form.Item
                label='贴现率'
                name={'financeToolReq.measurementParam.discountRate'.split('.')}
                validateStatus={isChanged('financeToolReq.measurementParam.discountRate')}
                rules={[
                  {
                    required: true,
                    message: '请输入贴现率'
                  }
                ]}
              >
                <InputNumber
                  style={{
                    width: '100%'
                  }}
                  placeholder='请输入贴现率'
                  size='middle'
                  addonAfter='%'
                  controls={false}
                  precision={2}
                  min={0}
                  max={100}
                  onBlur={() => {
                    setVer((ver) => ver + 1)
                  }}
                />
              </Form.Item>
              <Form.Item
                className='action-buttons'
                wrapperCol={{ span: 24 }}
                style={{
                  justifyContent: 'flex-end'
                }}
              >
                <Button
                  type='primary'
                  onClick={() => {
                    setLoading(true)
                    setTimeout(() => {
                      form
                        .validateFields()
                        .then((values) => {
                          calcWithValues(values)
                        })
                        .catch(() => {
                          message.error('请检查参数')
                          setLoading(false)
                        })
                    }, 300);
                  }}
                >
                  计算
                </Button>
              </Form.Item>
            </Form>
          </Tabs.TabPane>
          <Tabs.TabPane tab='计算结果' key='2'>
            <div className='calc-result'>
              <div className='calc-result-section'>
                <div className='calc-result-title'>财务估算</div>
                {resultConfig.fin.map((o) => (
                  <div className='item-content' key={o.key}>
                    <div className='item-content-value'>
                      {result[o.key]}
                      <span className='item-content-unit'>{o.unit}</span>
                    </div>
                    <div className='item-content-tips'>{o.tips}</div>
                    <div className='v-split-line' />
                  </div>
                ))}
              </div>
              <div className='calc-result-section'>
                <div className='calc-result-title'>发电量估算</div>
                {resultConfig.ele.map((o) => (
                  <div className='item-content' key={o.key}>
                    <div className='item-content-value'>
                      {result[o.key]}
                      <span className='item-content-unit'>{o.unit}</span>
                    </div>
                    <div className='item-content-tips'>{o.tips}</div>
                    <div className='v-split-line' />
                  </div>
                ))}

                <div className='calc-chart'>
                  <div className='calc-chart-title'>首年逐月发电量</div>
                  {result?.firstYearPvPowerMonthly?.length > 0 && (
                    <ReactECharts
                      style={{
                        width: '100%',
                        height: '180px'
                      }}
                      option={{
                        grid: { left: 10, right: 0, top: 40, bottom: 0, containLabel: true },
                        tooltip: {
                          show: true,
                          trigger: 'axis',
                          formatter: (argsList: any[]) => {
                            const args: any = argsList[0]
                            return `${args.name}<br />${args.marker}${parseFloat(args.value.toFixed(2))}kWh`
                          }
                        },
                        xAxis: {
                          type: 'category',
                          boundaryGap: true,
                          data: Object.keys(result.firstYearPvPowerMonthly).map((o) => `${parseInt(o) + 1}`)
                          // show: false,
                        },
                        yAxis: [
                          {
                            type: 'value',
                            name: 'kWh',
                            splitLine: {
                              lineStyle: {
                                type: 'dashed'
                              }
                            },
                            boundaryGap: false,
                            nameTextStyle: {
                              color: 'gray',
                              padding: [0, 40, 0, 0]
                            }
                          },
                          {
                            type: 'value',
                            name: `{a|--} 平均值: ${getFixedNumber(
                              result.firstYearPvPowerMonthly.reduce((a: number, b: number) => a + b, 0) /
                              result.firstYearPvPowerMonthly.length,
                              2
                            )}kWh`,
                            nameTextStyle: {
                              color: 'gray',
                              align: 'right',
                              rich: {
                                a: {
                                  color: '#1ec692'
                                }
                              }
                            },
                            axisLine: {
                              // show: false,
                            },
                            axisLabel: {
                              // show: false,
                            },
                            axisTick: {
                              // show: false,
                            }
                          }
                        ],
                        series: [
                          {
                            name: '',
                            type: 'bar',
                            data: result.firstYearPvPowerMonthly,
                            barWidth: 10,
                            itemStyle: {
                              color: '#1EC692',
                              barBorderRadius: [4, 4, 0, 0]
                            },
                            markLine: {
                              silent: true,
                              symbol: 'none',
                              data: [
                                {
                                  yAxis:
                                    (result.firstYearPvPowerMonthly as number[]).reduce(
                                      (a: number, b: number) => a + b,
                                      0
                                    ) / result.firstYearPvPowerMonthly.length
                                }
                              ],
                              lineStyle: {
                                normal: {
                                  type: 'dashed',
                                  color: '#1EC692'
                                }
                              },
                              label: {
                                show: false
                              }
                            }
                          }
                        ]
                      }}
                    />
                  )}
                </div>
              </div>
            </div>
          </Tabs.TabPane>
        </Tabs>
      </Spin>
    </div>
  )
}
