import React, { useEffect, useRef, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Button, Dropdown, Menu, Space, Tooltip, Typography } from 'antd'
import { EditOutlined, PlusOutlined } from '@ant-design/icons'

import { CommonService, CostService, dynamicText, ResponseOptionType } from '../../../apis/wl-service'
import TablePage, { getColumnFilterProps, getActionColumn, TablePageInstance } from '../../../components/wl-table-page'
import ModifyApplyDialog from '../common/modify-apply-dialog'
import commit from '../common/commit'
import useBreadcrumbs from '../common/breadcrumb-hooks'

export default () => {
  const nav = useNavigate()
  const location = useLocation()
  const tableRef = useRef<TablePageInstance>(null)
  const [dataSet, setDataSet] = useState<{
    moduleId: string
    resourceTypeOptions: ResponseOptionType[]
  }>({
    moduleId: '5',
    resourceTypeOptions: [],
  }) // 选择项字段存储
  const [modifyApplyDialog, setModifyApplyDialog] = useState<boolean>(false)
  const [{ breadcrumb, title, editable }] = useBreadcrumbs(dataSet.moduleId)

  // 获取选择器字典
  useEffect(() => {
    CommonService.getDictionaryDataSelectorValid('15301742e5ec4e92843192cb76010e08')
      .then((resp: any) => {
        setDataSet((previous) => ({ ...previous, resourceTypeOptions: resp?.data?.list || [] }))
      })
      .catch(() => {
      })
  }, [])

  const jumpDetail = (row?: any, editable?: boolean, index?: number | string) => {
    if (typeof editable === 'boolean') {
      // 跳到当前页面的详情页
      nav(
        `/invest/project/${editable ?? true}${row?.id ? '/' + row.id : ''}?moduleId=${dataSet.moduleId}&t=${index ?? ''}`, { state: location.state },
      )
    } else {
      // 跳到项目详情页
      nav(`/invest/projectDetail/${row.project_id}`, { state: location.state })
    }
  }

  // 表格列配置
  const columns = (tableParams: any) => [
    {
      title: '项目名称',
      dataIndex: 'project_name',
      width: 120,
      fixed: 'left' as any,
      ...getColumnFilterProps({
        type: 'input',
        placeholder: '请输入项目名称',
        value: tableParams.project_name,
        onSearch: (value) => {
          tableRef.current?.reload?.({
            project_name: value,
            page: 1,
          })
        },
      }),
      render: (text: string, row: any) => {
        text = text ?? row.project_id
        return (
          text && (
            <Tooltip title={text}>
              <Typography.Link ellipsis onClick={() => jumpDetail(row)}>
                {text}
              </Typography.Link>
            </Tooltip>
          )
        )
      },
    },
    {
      title: '项目类型',
      dataIndex: 'project_type',
      width: 100,
      render: (text: string) =>
        text && (
          <Tooltip title={text}>
            <Typography.Text ellipsis>{dynamicText(text, dataSet.resourceTypeOptions)}</Typography.Text>
          </Tooltip>
        ),
    },
    {
      title: '核准/备案建设规模(MW)',
      dataIndex: 'install_size',
      width: 120,
      render: (text: string) =>
        text && (
          <Tooltip title={text}>
            <Typography.Text ellipsis>{text}</Typography.Text>
          </Tooltip>
        ),
    },
    {
      title: '核准/备案总投资(万元)',
      dataIndex: 'filing_invest',
      width: 120,
      render: (text: string) =>
        text && (
          <Tooltip title={text}>
            <Typography.Text ellipsis>{text}</Typography.Text>
          </Tooltip>
        ),
    },
    {
      title: '备案时间',
      dataIndex: 'filing_time',
      width: 100,
      render: (text: string) =>
        text && (
          <Tooltip title={text}>
            <Typography.Text ellipsis>{text}</Typography.Text>
          </Tooltip>
        ),
    },
    {
      title: '决策可研估算：建设规模(MW)',
      dataIndex: 'filing_scale',
      width: 160,
      render: (text: string) =>
        text && (
          <Tooltip title={text}>
            <Typography.Text ellipsis>{text}</Typography.Text>
          </Tooltip>
        ),
    },
    {
      title: '决策可研估算：总投资(万元)',
      dataIndex: 'total_invest',
      width: 160,
      render: (text: string) =>
        text && (
          <Tooltip title={text}>
            <Typography.Text ellipsis>{text}</Typography.Text>
          </Tooltip>
        ),
    },
    {
      title: '决策时间',
      dataIndex: 'decision_time',
      width: 100,
      render: (text: string) =>
        text && (
          <Tooltip title={text}>
            <Typography.Text ellipsis>{text}</Typography.Text>
          </Tooltip>
        ),
    },
    {
      title: '初设概算：总投资(万元)',
      dataIndex: 'csztz',
      width: 120,
      render: (text: string) =>
        text && (
          <Tooltip title={text}>
            <Typography.Text ellipsis>{text}</Typography.Text>
          </Tooltip>
        ),
    },
    {
      title: '初设概算：单位造价（元/kW)',
      dataIndex: 'csdwqwzj',
      width: 140,
      render: (text: string) =>
        text && (
          <Tooltip title={text}>
            <Typography.Text ellipsis>{text}</Typography.Text>
          </Tooltip>
        ),
    },
    {
      title: '竣工结算：总投资(万元)',
      dataIndex: 'jgztz',
      width: 140,
      render: (text: string) =>
        text && (
          <Tooltip title={text}>
            <Typography.Text ellipsis>{text}</Typography.Text>
          </Tooltip>
        ),
    },
    {
      title: '竣工结算：单位造价（元/kW)',
      dataIndex: 'jgdwqwzj',
      width: 140,
      render: (text: string) =>
        text && (
          <Tooltip title={text}>
            <Typography.Text ellipsis>{text}</Typography.Text>
          </Tooltip>
        ),
    },
    getActionColumn([
      {
        key: 'detail',
        label: '详情',
        onClick: (row: any) => {
          jumpDetail(row, false)
        },
      },
      {
        key: 'commit',
        label: '提交',
        visible: (row: any) => editable && row.is_commit != 1,
        onClick: (row: any) => {
          commit({
            params: {
              moduleId: dataSet.moduleId,
              id: row.project_id,
              dataId: row.id,
            },
            success: () => tableRef.current?.reload?.(),
          })
        },
      },
      {
        key: 'edit1',
        label: '可研预算',
        visible: (row: any) => editable && row.is_commit != 1,
        onClick: (row: any) => {
          jumpDetail(row, row.is_commit != 1, 1)
        },
      },
      {
        key: 'edit2',
        label: '初设概算',
        visible: (row: any) => editable && row.is_commit != 1,
        onClick: (row: any) => {
          jumpDetail(row, row.is_commit != 1, 2)
        },
      },
      {
        key: 'edit3',
        label: '竣工结算',
        visible: (row: any) => editable && row.is_commit != 1,
        onClick: (row: any) => {
          jumpDetail(row, row.is_commit != 1, 3)
        },
      },
      {
        key: 'delete',
        type: 'delete',
        // visible: (row: any) => row.is_commit != 1,
        getter: (row: any) => {
          return CostService.remove({ id: row.id })
        },
        success: (arg: any) => tableRef.current?.reload?.(arg),
      },
    ]),
  ]

  const menu = (
    <Menu
      items={[
        { key: 1, label: '可研预算' },
        { key: 2, label: '初设概算' },
        { key: 3, label: '竣工结算' },
      ]}
      onClick={({ key }) => {
        jumpDetail(undefined, true, key)
      }}
    />
  )

  return (
    <TablePage
      ref={tableRef}
      title={title}
      extra={
        !editable ? null : (
          <Space>
            <Dropdown overlay={menu} placement="bottomLeft">
              <Button type="primary" icon={<PlusOutlined />}>
                新建造价
              </Button>
            </Dropdown>

            <Button
              icon={<EditOutlined />}
              onClick={() => {
                setModifyApplyDialog(true)
              }}
            >
              申请修改
            </Button>
          </Space>
        )
      }
      breadcrumb={breadcrumb}
      columns={columns}
      rowKey="id"
      getter={(arg: any) => CostService.list(arg)}
    >
      <ModifyApplyDialog
        moduleId={dataSet.moduleId}
        visible={modifyApplyDialog}
        close={() => setModifyApplyDialog(false)}
        success={() => {
          tableRef.current?.reload?.()
        }}
      />
    </TablePage>
  )
}
