/**
 * project
 * unknown
 *
 * OpenAPI spec version: last
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import ajax, { AjaxPromise, ExtraFetchParams } from '@ajax';
import * as models from '../model/models';

/* tslint:disable:no-unused-variable member-ordering object-literal-shorthand */

/**
  * @description i3060ResourceCalculateCapacityOrAreaGet headers 参数
  * @property `Authorization` Authorization (Only:)
  */
export interface ParamsHeaderi3060ResourceCalculateCapacityOrAreaGet {
  /** Authorization (Only:) */
  'Authorization': string;
}
/**
  * @description i3060ResourceCalculateCapacityOrAreaGet参数
  * @property `availableArea` 装机面积
  * @property `pvCapacity` pvCapacity
  */
export interface Paramsi3060ResourceCalculateCapacityOrAreaGet {
  // queryParams
  /**
   * 装机面积
   */
  availableArea: string;
  /**
   * pvCapacity
   */
  pvCapacity: string;
}
/**
  * @description i3060ResourceCalculatePvToolPost headers 参数
  * @property `Authorization` Authorization (Only:)
  */
export interface ParamsHeaderi3060ResourceCalculatePvToolPost {
  /** Authorization (Only:) */
  'Authorization': string;
}
/**
  * @description i3060ResourceCalculatePvToolPost参数
  */
export interface Paramsi3060ResourceCalculatePvToolPost {
}
/**
  */
export type ParamsBodyi3060ResourceCalculatePvToolPost = models.I3060ResourceCalculatePvToolBody;
/**
  * @description i3060ResourceCalculateSolarRadiationGet headers 参数
  * @property `Authorization` Authorization (Only:)
  */
export interface ParamsHeaderi3060ResourceCalculateSolarRadiationGet {
  /** Authorization (Only:) */
  'Authorization': string;
}
/**
  * @description i3060ResourceCalculateSolarRadiationGet参数
  * @property `longitude` 经度
  * @property `latitude` 纬度
  */
export interface Paramsi3060ResourceCalculateSolarRadiationGet {
  // queryParams
  /**
   * 经度
   */
  longitude: string;
  /**
   * 纬度
   */
  latitude: string;
}
/**
  * @description i3060ResourceGetDefaultCalculateGet headers 参数
  * @property `Authorization` Authorization (Only:)
  */
export interface ParamsHeaderi3060ResourceGetDefaultCalculateGet {
  /** Authorization (Only:) */
  'Authorization': string;
}
/**
  * @description i3060ResourceGetDefaultCalculateGet参数
  */
export interface Paramsi3060ResourceGetDefaultCalculateGet {
}
/**
  * @description i3060ResourceInfoIdGet参数
  * @property `id` 资源id
  */
export interface Paramsi3060ResourceInfoIdGet {
  // pathParams
  /**
   * 资源id
   */
  id: string;
}
/**
  * @description i3060ResourceListGet参数
  * @property `name` 资源名
  * @property `type` 类型 1:屋顶
  * @property `regionCode` 地区码
  * @property `createdTimeSort` 创建时间 0 不排序 1:asc 2:desc
  * @property `createdName` 创建者名字
  * @property `page` 
  * @property `size` 
  */
export interface Paramsi3060ResourceListGet {
  // queryParams
  /**
   * 资源名
   */
  name: string;
  /**
   * 类型 1:屋顶
   */
  type: string;
  /**
   * 地区码
   */
  regionCode: string;
  /**
   * 创建时间 0 不排序 1:asc 2:desc
   */
  createdTimeSort: string;
  /**
   * 创建者名字
   */
  createdName: string;
  /**
   * 
   */
  page: string;
  /**
   * 
   */
  size: string;
}
/**
  * @description i3060ResourceRemoveIdPost参数
  * @property `id` 资源id
  */
export interface Paramsi3060ResourceRemoveIdPost {
  // pathParams
  /**
   * 资源id
   */
  id: string;
}
/**
  */
export type ParamsBodyi3060ResourceRemoveIdPost = any;
/**
  */
export type ParamsBodyi3060ResourceRenamePost = models.I3060ResourceRenameBody;

export class ResourceApi {
  protected $basePath = '/api'.replace(/\/$/, '');

  public constructor(basePath?: string) {
    if (basePath !== undefined) {
      this.$basePath = basePath.replace(/\/$/, '');
    }
  }

  /**
   * 
   * @summary 光伏发电小工具_装机容量、装机面积
   * @param params Paramsi3060ResourceCalculateCapacityOrAreaGet
   
   * @param opt ajax config
   * @returns models.I3060ResourceCalculateCapacityOrArea
   */
  public i3060ResourceCalculateCapacityOrAreaGet = (
    params: Paramsi3060ResourceCalculateCapacityOrAreaGet,
    opt?: { headers: ParamsHeaderi3060ResourceCalculateCapacityOrAreaGet } & Omit<ExtraFetchParams, 'headers'> 
  ) : AjaxPromise<models.I3060ResourceCalculateCapacityOrArea>  => {
    const url = this.$basePath + `/i3060/resource/calculateCapacityOrArea`;
    const p: any = {};
    p.query = {};
    if ('availableArea' in params) p.query.availableArea = params.availableArea;
    ajax.check(params.availableArea, 'availableArea');
    if ('pvCapacity' in params) p.query.pvCapacity = params.pvCapacity;
    ajax.check(params.pvCapacity, 'pvCapacity');
    return ajax.ajax({
      ...opt,
      method: 'GET',
      url,
      ...p
    });
  }
  /**
   * 
   * @summary 光伏发电小工具_计算
   * @param params Paramsi3060ResourceCalculatePvToolPost
   * @param data: ParamsBodyi3060ResourceCalculatePvToolPost
   * @param opt ajax config
   * @returns models.I3060ResourceCalculatePvTool
   */
  public i3060ResourceCalculatePvToolPost = (
    params: Paramsi3060ResourceCalculatePvToolPost,
    data: ParamsBodyi3060ResourceCalculatePvToolPost,opt?: { headers: ParamsHeaderi3060ResourceCalculatePvToolPost } & Omit<ExtraFetchParams, 'headers'> 
  ) : AjaxPromise<models.I3060ResourceCalculatePvTool>  => {
    const url = this.$basePath + `/i3060/resource/calculatePvTool`;
    const p: any = {};
    p.data = data;
    return ajax.ajax({
      ...opt,
      method: 'POST',
      url,
      ...p
    });
  }
  /**
   * 
   * @summary 光伏发电小工具_计算年总辐照
   * @param params Paramsi3060ResourceCalculateSolarRadiationGet
   
   * @param opt ajax config
   * @returns models.I3060ResourceCalculateSolarRadiation
   */
  public i3060ResourceCalculateSolarRadiationGet = (
    params: Paramsi3060ResourceCalculateSolarRadiationGet,
    opt?: { headers: ParamsHeaderi3060ResourceCalculateSolarRadiationGet } & Omit<ExtraFetchParams, 'headers'> 
  ) : AjaxPromise<models.I3060ResourceCalculateSolarRadiation>  => {
    const url = this.$basePath + `/i3060/resource/calculateSolarRadiation`;
    const p: any = {};
    p.query = {};
    if ('longitude' in params) p.query.longitude = params.longitude;
    ajax.check(params.longitude, 'longitude');
    if ('latitude' in params) p.query.latitude = params.latitude;
    ajax.check(params.latitude, 'latitude');
    return ajax.ajax({
      ...opt,
      method: 'GET',
      url,
      ...p
    });
  }
  /**
   * 
   * @summary 光伏发电小工具_默认值
   * @param params Paramsi3060ResourceGetDefaultCalculateGet
   
   * @param opt ajax config
   * @returns models.I3060ResourceGetDefaultCalculate
   */
  public i3060ResourceGetDefaultCalculateGet = (
    params: Paramsi3060ResourceGetDefaultCalculateGet,
    opt?: { headers: ParamsHeaderi3060ResourceGetDefaultCalculateGet } & Omit<ExtraFetchParams, 'headers'> 
  ) : AjaxPromise<models.I3060ResourceGetDefaultCalculate>  => {
    const url = this.$basePath + `/i3060/resource/getDefaultCalculate`;
    const p: any = {};
    return ajax.ajax({
      ...opt,
      method: 'GET',
      url,
      ...p
    });
  }
  /**
   * 
   * @summary 资源估算地区筛选条件列表
   
   
   * @param opt ajax config
   * @returns models.I3060ResourceGetQueryRegion
   */
  public i3060ResourceGetQueryRegionGet = (
    
    opt?: ExtraFetchParams
  ) : AjaxPromise<models.I3060ResourceGetQueryRegion>  => {
    const url = this.$basePath + `/i3060/resource/getQueryRegion`;
    const p: any = {};
    return ajax.ajax({
      ...opt,
      method: 'GET',
      url,
      ...p
    });
  }
  /**
   * 
   * @summary 资源详情
   * @param params Paramsi3060ResourceInfoIdGet
   
   * @param opt ajax config
   * @returns models.I3060ResourceInfoId
   */
  public i3060ResourceInfoIdGet = (
    params: Paramsi3060ResourceInfoIdGet,
    opt?: ExtraFetchParams
  ) : AjaxPromise<models.I3060ResourceInfoId>  => {
    const {
    id,
} = params;
    const url = this.$basePath + `/i3060/resource/info/${id}`;
    const p: any = {};
    ajax.check(params.id, 'id');
    return ajax.ajax({
      ...opt,
      method: 'GET',
      url,
      ...p
    });
  }
  /**
   * 
   * @summary 资源列表
   * @param params Paramsi3060ResourceListGet
   
   * @param opt ajax config
   * @returns models.I3060ResourceList
   */
  public i3060ResourceListGet = (
    params: Paramsi3060ResourceListGet,
    opt?: ExtraFetchParams
  ) : AjaxPromise<models.I3060ResourceList>  => {
    const url = this.$basePath + `/i3060/resource/list`;
    const p: any = {};
    p.query = {};
    if ('name' in params) p.query.name = params.name;
    ajax.check(params.name, 'name');
    if ('type' in params) p.query.type = params.type;
    ajax.check(params.type, 'type');
    if ('regionCode' in params) p.query.regionCode = params.regionCode;
    ajax.check(params.regionCode, 'regionCode');
    if ('createdTimeSort' in params) p.query.createdTimeSort = params.createdTimeSort;
    ajax.check(params.createdTimeSort, 'createdTimeSort');
    if ('createdName' in params) p.query.createdName = params.createdName;
    ajax.check(params.createdName, 'createdName');
    if ('page' in params) p.query.page = params.page;
    ajax.check(params.page, 'page');
    if ('size' in params) p.query.size = params.size;
    ajax.check(params.size, 'size');
    return ajax.ajax({
      ...opt,
      method: 'GET',
      url,
      ...p
    });
  }
  /**
   * 
   * @summary 资源删除
   * @param params Paramsi3060ResourceRemoveIdPost
   * @param data: ParamsBodyi3060ResourceRemoveIdPost
   * @param opt ajax config
   * @returns models.I3060FinanceAwardSubsidyEdit
   */
  public i3060ResourceRemoveIdPost = (
    params: Paramsi3060ResourceRemoveIdPost,
    data: ParamsBodyi3060ResourceRemoveIdPost,opt?: ExtraFetchParams
  ) : AjaxPromise<models.I3060FinanceAwardSubsidyEdit>  => {
    const {
    id,
} = params;
    const url = this.$basePath + `/i3060/resource/remove/${id}`;
    const p: any = {};
    ajax.check(params.id, 'id');
    p.data = data;
    return ajax.ajax({
      ...opt,
      method: 'POST',
      url,
      ...p
    });
  }
  /**
   * 
   * @summary 资源重命名
   
   * @param data: ParamsBodyi3060ResourceRenamePost
   * @param opt ajax config
   * @returns models.I3060FinanceAwardSubsidyEdit
   */
  public i3060ResourceRenamePost = (
    
    data: ParamsBodyi3060ResourceRenamePost,opt?: ExtraFetchParams
  ) : AjaxPromise<models.I3060FinanceAwardSubsidyEdit>  => {
    const url = this.$basePath + `/i3060/resource/rename`;
    const p: any = {};
    p.data = data;
    return ajax.ajax({
      ...opt,
      method: 'POST',
      url,
      ...p
    });
  }
}

export default new ResourceApi();