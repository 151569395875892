import { useEffect, useState } from 'react'
import { Row, Col, InputNumber, message } from 'antd'
import { CustomIcons } from '../../../../global'
import { FinanceApi } from '../../../../services'

import { EditInput } from './../index'

const projectTypeList = [
  {
    name: '光 伏',
    code: 'gf',
    rows: [
      {
        name: '单位静态投资',
        unit: '¥/kWp',
        type: 'static',
        field: '_photovoltaicStaticInvestmentPerUnit',
        isNotEdit: true,
        percent: '_photovoltaicStaticInvestmentPerUnitPercent',
      },
      {
        name: '设备及建安工程费',
        unit: '¥/kWp',
        type: 'primary',
        field: '_photovoltaicEquipmentConstructionCost',
        isNotEdit: true,
        percent: '_photovoltaicEquipmentConstructionCostPercent',
      },
      {
        name: '光伏模组',
        unit: '¥/kWp',
        field: 'photovoltaicModuleUnitPrice',
        type: 'desc',
        precision: 2,
        max: 999999,
      },
      {
        name: '逆变器',
        unit: '¥/kWp',
        field: 'photovoltaicInverterUnitPrice',
        type: 'desc',
        precision: 2,
        max: 999999,
      },
      {
        name: '其他设备',
        field: 'photovoltaicOtherFacilityPrice',
        unit: '¥/kWp',
        type: 'desc',
        precision: 2,
        max: 999999,
      },
      {
        name: '安装工程费',
        unit: '¥/kWp',
        field: 'photovoltaicInstallCostPrice',
        type: 'primary',
        precision: 2,
        max: 999999,
      },
      {
        name: '建筑工程费',
        unit: '¥/kW',
        field: 'photovoltaicArchitectureProjectCost',
        type: 'primary',
        precision: 2,
        max: 999999,
      },
      {
        name: '其他费用',
        unit: '¥/kWp',
        field: '_photovoltaicOtherFees',
        type: 'primary',
        isNotEdit: true,
        percent: '_photovoltaicOtherFeesPercent',
      },
      {
        name: '项目管理费',
        unit: '%',
        field: 'photovoltaicProjectManagementCostProportion',
        type: 'desc',
        precision: 2,
      },
      {
        name: '生产准备费',
        unit: '%',
        field: 'photovoltaicYieldPlanCostProportion',
        type: 'desc',
        precision: 2,
      },
      {
        name: '勘察设计费',
        unit: '%',
        field: 'photovoltaicProspectDesignCostProportion',
        type: 'desc',
        precision: 2,
      },

      {
        name: '基本预备费',
        unit: '¥/kWp',
        type: 'primary',
        field: '_photovoltaicBasicReserve',
        isNotEdit: true,
        percent: '_photovoltaicBasicReservePercent',
      },
      {
        name: '基本预备费率',
        unit: '%',
        field: 'photovoltaicBasicReadyCostRate',
        type: 'primary',
        precision: 2,
      },
    ],
  },
  {
    name: '储 能',
    code: 'cn',
    rows: [
      {
        name: '单位静态投资',
        unit: '¥/kWh',
        type: 'static',
        field: '_storedStaticInvestmentPerUnit',
        isNotEdit: true,
        percent: '_storedStaticInvestmentPerUnitPercent',
      },
      {
        name: '设备及建安工程费',
        unit: '¥/kWh',
        type: 'primary',
        field: '_storedEquipmentConstructionCost',
        isNotEdit: true,
        percent: '_storedEquipmentConstructionCostPercent',
      },
      {
        name: '电池模组',
        unit: '¥/kWh',
        field: 'storedEnergyCellModuleUnitPrice',
        type: 'desc',
        precision: 2,
        max: 999999,
      },
      {
        name: '年下降率',
        unit: '%',
        field: 'storedEnergyCellModuleUnitPriceYearsDeclineRate',
        type: 'desc',
        precision: 2,
      },
      {
        name: '储能变流器',
        unit: '¥/kWh',
        field: 'storedEnergyConverterUnitPrice',
        type: 'desc',
        precision: 2,
        max: 999999,
      },
      {
        name: '其他设备',
        field: 'storedEnergyOtherFacilityPrice',
        unit: '¥/kWh',
        type: 'desc',
        precision: 2,
        max: 999999,
      },
      {
        name: '安装工程费',
        field: 'storedEnergyInstallCostPrice',
        unit: '¥/kWh',
        type: 'desc',
        precision: 2,
        max: 999999,
      },
      {
        name: '建筑工程费',
        unit: '¥/kWh',
        field: 'storedEnergyArchitectureProjectCost',
        type: 'primary',
        precision: 2,
        max: 999999,
      },
      {
        name: '其他费用',
        unit: '¥/kWh',
        field: '_storedOtherFees',
        type: 'primary',
        isNotEdit: true,
        percent: '_storedOtherFeesPercent',
      },
      {
        name: '项目管理费',
        unit: '%',
        field: 'storedEnergyProjectManagementCostProportion',
        type: 'desc',
        precision: 2,
      },
      {
        name: '生产准备费',
        unit: '%',
        field: 'storedEnergyYieldPlanCostProportion',
        type: 'desc',
        precision: 2,
      },
      {
        name: '勘察设计费',
        unit: '%',
        field: 'storedEnergyProspectDesignCostProportion',
        type: 'desc',
        precision: 2,
      },
      {
        name: '基本预备费',
        unit: '¥/kWp',
        type: 'primary',
        field: '_storedBasicReserve',
        isNotEdit: true,
        percent: '_storedBasicReservePercent',
      },
      {
        name: '基本预备费率',
        unit: '%',
        field: 'storedEnergyBasicReadyCostRate',
        type: 'primary',
        precision: 2,
      },
    ],
  },
]
const icon: any = {
  projectInvest: 'klf-finance-investment',
  operationCost: 'klf-finance-operate',
  financeCost: 'klf-finance-finance',
  awardSubsidy: 'klf-finance-allowance',
}

const ProjectInvestCard = (props: any) => {
  const { editType, result, editConfigId, projectId, changeNameCallback } = props
  const [curResult, setCurResult] = useState<any>()
  const [curName, setCurName] = useState('')

  const formatDefaultData = (data: any) => {
    let {
      _photovoltaicEquipmentConstructionCost,
      _photovoltaicEquipmentConstructionCostPercent = 0,
      _photovoltaicStaticInvestmentPerUnit,
      _photovoltaicOtherFees,
      _photovoltaicOtherFeesPercent = 0,
      _photovoltaicBasicReserve,
      _photovoltaicBasicReservePercent,
      photovoltaicModuleUnitPrice,
      photovoltaicInverterUnitPrice,
      photovoltaicOtherFacilityPrice,
      photovoltaicInstallCostPrice,
      photovoltaicArchitectureProjectCost,
      photovoltaicProjectManagementCostProportion,
      photovoltaicYieldPlanCostProportion,
      photovoltaicProspectDesignCostProportion,
      photovoltaicBasicReadyCostRate,

      _storedEquipmentConstructionCost,
      storedEnergyCellModuleUnitPrice,
      storedEnergyConverterUnitPrice,
      storedEnergyOtherFacilityPrice,
      storedEnergyInstallCostPrice,
      storedEnergyArchitectureProjectCost,
      _storedEquipmentConstructionCostPercent,
      _storedStaticInvestmentPerUnit,
      _storedOtherFees,
      _storedOtherFeesPercent,
      _storedBasicReserve,
      _storedBasicReservePercent,
      storedEnergyBasicReadyCostRate,
      storedEnergyProjectManagementCostProportion,
      storedEnergyYieldPlanCostProportion,
      storedEnergyProspectDesignCostProportion,
    } = data

    // 设备及建安工程费
    _photovoltaicEquipmentConstructionCost =
      photovoltaicModuleUnitPrice +
      photovoltaicInverterUnitPrice +
      photovoltaicOtherFacilityPrice +
      photovoltaicInstallCostPrice +
      photovoltaicArchitectureProjectCost
    // 其他费用
    _photovoltaicOtherFees =
      (photovoltaicProjectManagementCostProportion / 100 +
        photovoltaicYieldPlanCostProportion / 100 +
        photovoltaicProspectDesignCostProportion / 100) *
      _photovoltaicEquipmentConstructionCost
    // 基本预备费
    _photovoltaicBasicReserve =
      (photovoltaicBasicReadyCostRate / 100) * (_photovoltaicEquipmentConstructionCost + _photovoltaicOtherFees)

    // 单位静态投资
    _photovoltaicStaticInvestmentPerUnit =
      _photovoltaicEquipmentConstructionCost + _photovoltaicOtherFees + _photovoltaicBasicReserve

    // ====

    // 设备建安工程费
    _storedEquipmentConstructionCost =
      storedEnergyCellModuleUnitPrice +
      storedEnergyConverterUnitPrice +
      storedEnergyOtherFacilityPrice +
      storedEnergyInstallCostPrice +
      storedEnergyArchitectureProjectCost

    // 其他费用
    _storedOtherFees =
      (storedEnergyProjectManagementCostProportion / 100 +
        storedEnergyYieldPlanCostProportion / 100 +
        storedEnergyProspectDesignCostProportion / 100) *
      _storedEquipmentConstructionCost

    // 基本预备费
    _storedBasicReserve = (storedEnergyBasicReadyCostRate / 100) * (_storedEquipmentConstructionCost + _storedOtherFees)

    // 单位静态投资
    _storedStaticInvestmentPerUnit = _storedEquipmentConstructionCost + _storedOtherFees + _storedBasicReserve

    // 其他费用百分比
    _storedOtherFeesPercent = _storedOtherFees / _storedStaticInvestmentPerUnit

    // 基本预备费百分比
    _storedBasicReservePercent = _storedBasicReserve / _storedStaticInvestmentPerUnit

    // 设备建安百分比
    _storedEquipmentConstructionCostPercent = _storedEquipmentConstructionCost / _photovoltaicStaticInvestmentPerUnit

    // 设备及建安工程费 百分比
    _photovoltaicEquipmentConstructionCostPercent =
      _photovoltaicEquipmentConstructionCost / _photovoltaicStaticInvestmentPerUnit

    // 基本预备费百分比
    _photovoltaicBasicReservePercent = _photovoltaicBasicReserve / _photovoltaicStaticInvestmentPerUnit

    // 其他费用 百分比
    _photovoltaicOtherFeesPercent = _photovoltaicOtherFees / _photovoltaicStaticInvestmentPerUnit

    let obj = {
      ...data,
      _photovoltaicStaticInvestmentPerUnitPercent: 100,
      _storedStaticInvestmentPerUnitPercent: 100,
      _photovoltaicEquipmentConstructionCost: _photovoltaicEquipmentConstructionCost.toFixed(2),
      _photovoltaicOtherFees: _photovoltaicOtherFees.toFixed(2),
      _photovoltaicStaticInvestmentPerUnit: _photovoltaicStaticInvestmentPerUnit.toFixed(2),
      _photovoltaicBasicReserve: _photovoltaicBasicReserve.toFixed(2),
      _storedOtherFees: _storedOtherFees.toFixed(2),
      _storedBasicReserve: _storedBasicReserve.toFixed(2),
      _storedStaticInvestmentPerUnit: _storedStaticInvestmentPerUnit.toFixed(2),
      _storedOtherFeesPercent: (_storedOtherFeesPercent * 100).toFixed(2),
      _storedBasicReservePercent: (_storedBasicReservePercent * 100).toFixed(2),
      _photovoltaicEquipmentConstructionCostPercent: (_photovoltaicEquipmentConstructionCostPercent * 100).toFixed(2),
      _photovoltaicBasicReservePercent: (_photovoltaicBasicReservePercent * 100).toFixed(2),
      _photovoltaicOtherFeesPercent: (_photovoltaicOtherFeesPercent * 100).toFixed(2),
      _storedEquipmentConstructionCost: _storedEquipmentConstructionCost.toFixed(2),
      _storedEquipmentConstructionCostPercent: _storedEquipmentConstructionCostPercent.toFixed(2),
    }
    setCurResult({ ...obj })
  }

  const changeNumber = ({ value, name }: { value: number; name: string }) => {
    curResult[name] = value
    formatDefaultData(curResult)
    submitData()
  }

  const submitData = () => {
    let data = { ...curResult, projectInvestId: editConfigId, name: curName || curResult.name }
    delete data._photovoltaicStaticInvestmentPerUnitPercent
    delete data._storedStaticInvestmentPerUnitPercent
    delete data._photovoltaicEquipmentConstructionCost
    delete data._photovoltaicOtherFees
    delete data._photovoltaicStaticInvestmentPerUnit
    delete data._photovoltaicBasicReserve
    delete data._storedOtherFees
    delete data._storedBasicReserve
    delete data._storedStaticInvestmentPerUnit
    delete data._storedOtherFeesPercent
    delete data._storedBasicReservePercent
    delete data._photovoltaicEquipmentConstructionCostPercent
    delete data._photovoltaicBasicReservePercent
    delete data._photovoltaicOtherFeesPercent
    delete data._storedEquipmentConstructionCost
    delete data._storedEquipmentConstructionCostPercent
    const FinanceApiService = new FinanceApi()
    // @ts-ignore
    FinanceApiService.i3060FinanceProjectInvestEditPost({}, data)
      .then(() => {})
      .catch(() => {})
      .finally(() => {})
  }

  useEffect(() => {
    if (result) {
      formatDefaultData(result)
    }
  }, [result])

  const { name } = curResult || {}

  useEffect(() => {
    if (name) {
      setCurName(name)
    }
  }, [])

  if (!result || !curResult) return <></>
  return (
    <div className="param-config-card">
      <Row>
        <Col className="param-title" span={24}>
          <CustomIcons type={icon[editType]} className="icon" />
          <EditInput
            name={curName || name}
            onConfirm={(str) => {
              const FinanceApiService = new FinanceApi()
              FinanceApiService.i3060FinanceParamListRenameProjectLibraryPost({
                projectId,
                name: str,
                type: '1',
                id: editConfigId,
              })
                .then((res: any) => {
                  if (res.code === 200) {
                    changeNameCallback()
                    setCurName(str)
                  }
                })
                .catch(() => {})
                .finally(() => {})
            }}
          />
        </Col>
      </Row>
      {projectTypeList.map((ele) => {
        return (
          <>
            <Row>
              <Col className="config-main-title" span={24}>
                {ele.name}
              </Col>
            </Row>
            {ele['rows'].map((item: any, index: number) => {
              if (!item.field) return null
              return item.isNotEdit ? (
                <Row className={`config-item ${index === 0 ? 'mb10' : ''}`}>
                  <Col
                    span={10}
                    className="name"
                    style={{
                      color: index === 0 ? '#1CB887' : '#262E3F',
                    }}
                  >
                    {item.name}
                  </Col>
                  <Col span={14} className="content">
                    <span className="number">{curResult[item.field]}</span>
                    <span className="unit">{item.unit}</span>
                    <span className="percent">({curResult[item.percent]}%)</span>
                  </Col>
                </Row>
              ) : (
                <Row className="config-item" key={`gf-${index}`}>
                  <Col span={10} className="edit-name name" style={{ textIndent: '10px' }}>
                    {item.name}
                  </Col>
                  <Col span={14} className="content">
                    <InputNumber
                      // controls={false}
                      min={item.min ? item.min : 0}
                      max={item.max ? item.max : item.unit === '%' ? 100 : undefined}
                      formatter={(val) => {
                        const arr = new Array(item.precision)
                        arr.fill('\\d')
                        const pat = `^(\\-)*(\\d+)\\.(${item.precision ? arr.join('') : ''}).*$`
                        return `${val}`.replace(new RegExp(pat), '$1$2.$3')
                      }}
                      style={{ width: '100%' }}
                      addonAfter={item.unit}
                      defaultValue={curResult[item.field]}
                      onBlur={(e) => {
                        let { value } = e.target
                        if (value === '') {
                          message.error(`${item.name}不能为空`)
                          return
                        }
                        if (item.unit === '%') {
                          value = Number(value) > 100 ? '100' : value
                        }
                        if (curResult[item.field] == value) return
                        let max = item.max ? item.max : item.unit === '%' ? 100 : undefined
                        let min = item.min ? item.min : 0

                        if (max && value > max) {
                          value = max
                        }
                        if (value < min) {
                          value = min
                        }
                        const arr = new Array(item.precision)
                        arr.fill('\\d')
                        const pat = `^(\\-)*(\\d+)\\.(${item.precision ? arr.join('') : ''}).*$`
                        value = `${value}`.replace(new RegExp(pat), '$1$2.$3')
                        changeNumber({
                          value: Number(value),
                          name: item.field,
                        })
                      }}
                    />
                  </Col>
                </Row>
              )
            })}
          </>
        )
      })}
    </div>
  )
}
export default ProjectInvestCard
