import { useState } from 'react'
import {  message, Button } from 'antd'
import { UserService } from '../../../apis/project-service-history'

// function getBase64(img: any, callback: Function) {
//   const reader = new FileReader()
//   reader.addEventListener('load', () => callback(reader.result))
//   reader.readAsDataURL(img)
// }

// base64 转二进制
function dataURLtoBlob(dataurl: any) {
  var arr = dataurl.split(','),
  mime = arr[0].match(/:(.*?);/)[1],
  bstr = atob(arr[1]),
  n = bstr.length,
  u8arr = new Uint8Array(n);
  while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
  }
  return new Blob([u8arr], {
      type: mime
  });
}

interface IProps {
  user: any
  onChange?: Function
}

// 用户中心 修改用户信息用
const HeadUpload = (props: IProps) => {
  const { onChange } = props

  const [loading, setLoading] = useState(false)

  // 压缩图片
  const resizeMe = (img: any, type: any, max_width: any, max_height: any) => {
    let canvas = document.createElement('canvas')
    let width = img.width
    let height = img.height
    max_width = !isNaN(max_width) ? max_width : 0
    max_height = !isNaN(max_height) ? max_height : 0
    // 在这里图片是等比例缩放的，调用方法时填入图片允许的最大宽度或者是最大的高度
    //如果最大宽度为0 则按照最大高度固定，宽度自适应的方式来实现
    //如果是最大高度为0，则按照最大的宽度来实现
    if (max_width == 0) {
      if (height > max_height) {
        width = Math.round((width *= max_height / height))
        height = max_height
      }
    }
    if (max_height == 0) {
      if (width > max_width) {
        height = Math.round((height *= max_width / width))
        width = max_width
      }
    }
    canvas.width = width
    canvas.height = height
    let ctx = canvas.getContext('2d')
    canvas.width = width
    canvas.height = height
    ctx?.drawImage(img, 0, 0, width, height)
    type = type === 'jpg' ? 'jpeg' : type
    return canvas.toDataURL('image/' + type, 0.7) //这里的0.7值的是图片的质量
  }

  // 选中文件处理
  const selectFileImage = (el: any) => {
    var reader = new FileReader()
    var file = el.target.files[0]
    // var fileName = file.name
    var fileType = file.name.split('.')[1]
    reader.readAsArrayBuffer(file)

    reader.onload = (ev: any) => {
      var blob = new Blob([ev.target['result']])
      window['URL'] = window['URL'] || window['webkitURL']
      var blobURL = window['URL'].createObjectURL(blob)
      var image = new Image()
      image.src = blobURL
      image.onload = () => {
        const thumb = resizeMe(image, fileType, 400, 0) //获得的路径是将图片转换成了base64
        const data = dataURLtoBlob(thumb)
        setLoading(true)
        UserService.uploadHeadImg({ file: data })
          .then((res) => {
            onChange && onChange(res?.data)
          })
          .catch(() => {})
          .finally(() => {
            setLoading(false)
          })
      }
    }
  }

  // 上传图片验证
  const beforeUpload = (e: any) => {
    const file = e.target.files[0]
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png'
    if (!isJpgOrPng) {
      message.error('只能上传jpg、png图片')
      return
    }
    const isLt200K = (file.size / 1024) / 1024 / 2 < 1
    if (!isLt200K) {
      message.error('上传的头像文件大小必须小于2MB！')
      return
    }
    selectFileImage(e)
  }

  // const handleChange = (info: any) => {
  //   if (info.file.status === 'uploading') {
  //     return
  //   }
  //   if (info.file.status === 'done') {
  //     getBase64(info.file.originFileObj, (imageUrl: string) => {
  //       onChange && onChange(imageUrl)
  //     })
  //   }
  // }

  // 自定义上传
  // const uploadHeaderFile = (e: any) => {
  //   setLoading(true)
  //   UserService.uploadHeadImg({ file: e.file })
  //     .then((res) => {
  //       onChange && onChange(res?.data)
  //     })
  //     .catch(() => {})
  //     .finally(() => {
  //       setLoading(false)
  //     })
  // }

  return (
    //   <Upload
    //   name="file"
    //   listType="picture-card"
    //   showUploadList={false}
    //   customRequest={uploadHeaderFile}
    //   beforeUpload={beforeUpload}
    //   onChange={handleChange}
    //   accept=".png,.jpg"
    //   style={{ marginBottom: 20 }}
    // >
    //   <Button loading={loading}>编辑</Button>
    // </Upload>
    <>
      <input type="file" name="file" id='header-upload' onChange={beforeUpload} accept=".png,.jpg" style={{ marginBottom: 20 }} />
      <Button loading={loading} onClick={()=>{
        document.querySelector<HTMLElement>('#header-upload')?.click()
      }}>编辑</Button>
    </>
  )
}

export default HeadUpload
