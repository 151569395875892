import { useEffect, useState } from 'react'
import { Modal, Tooltip, Button, Space, notification } from 'antd'
import { ExclamationCircleFilled } from '@ant-design/icons'
import { CustomIcons } from '../../../../global'
import './index.less'

const ParamsTypes = [
  {
    title: '项目投资',
    icon: <CustomIcons type="klf-finance-investment" />,
    url: 'investment',
  },
  {
    title: '运营成本',
    icon: <CustomIcons type="klf-finance-operate" />,
    url: 'operating',
  },
  {
    title: '财务成本',
    icon: <CustomIcons type="klf-finance-finance" />,
    url: 'financial-cost',
  },
  {
    title: '激励补贴',
    icon: <CustomIcons type="klf-finance-allowance" />,
    url: 'incentive',
  },
]

const CostModal = (props: any) => {
  const { visible, onCancel, dataSource = [], onOk, curCostModalData = [] } = props
  const [chooseItem, setChooseItem] = useState<any[]>([])
  const chooseCostItem = (params: any) => {
    // 超过6个

    let item = chooseItem.find((ele) => ele.id === params.id)
    let arr = []
    if (item) {
      arr = chooseItem.filter((ele) => ele.id !== params.id)
      setChooseItem(arr)
    } else {
      const { pid } = params
      let curChooseItem = curCostModalData[pid]
      let chooseItems = chooseItem.filter((item: any) => item.pid === pid)
      if (curChooseItem.length + chooseItems.length >= 6) {
        notification.warning({
          icon: <ExclamationCircleFilled style={{ color: '#f97942' }} />,
          message: <strong>警告提示</strong>,
          description: '最多添加6个参数',
          duration: 3,
        })
        return
      }
      arr = [...chooseItem, params]
      console.log(params, arr)
      setChooseItem([...chooseItem, params])
    }
  }

  const submitCostItem = () => {
    onOk(chooseItem)
  }
  useEffect(() => {
    setChooseItem([])
    console.log(curCostModalData, 'curCostModalData')
  }, [visible])

  return (
    <Modal
      className="cost-modal-wrap"
      width={1124}
      visible={visible}
      title={'添加参数'}
      maskClosable={false}
      onCancel={onCancel}
      keyboard={false}
      footer={false}
    >
      <p className="title">请选择财务参数库中的参数为模板，添加至该项目参数组来使用</p>
      <div className="cost-modal-content">
        {ParamsTypes.map((params, index) => {
          // 参数模块数据列表
          const listData = dataSource[index]
          return (
            <div key={index} className="module-item">
              <div className="module-title">
                {params.icon}
                <span className="title">{params.title}</span>
              </div>
              <div className="list">
                {listData?.map((item: any) => {
                  let checkedFlag = chooseItem.find((ele) => ele.id === item.id)
                  return (
                    <>
                      <div
                        className={item.checked ? 'checked item' : 'item'}
                        style={{
                          position: 'relative',
                        }}
                        onClick={() => chooseCostItem(item)}
                        key={`cost-${item.id}`}
                      >
                        {item.name?.length > 10 ? (
                          <Tooltip title={item.name}>{item.name.substring(0, 10)}...</Tooltip>
                        ) : (
                          item.name
                        )}

                        {!!checkedFlag && (
                          <div
                            style={{
                              position: 'absolute',
                              width: 20,
                              height: 20,
                              fontSize: 16,
                              bottom: 0,
                              right: 0,
                            }}
                          >
                            <CustomIcons
                              className="absolute-bottom primary-mark"
                              type="klf-done"
                              style={{
                                color: '#1ec692',
                              }}
                            />
                          </div>
                        )}
                      </div>
                    </>
                  )
                })}
              </div>
            </div>
          )
        })}
      </div>
      <div className="btns">
        <Space>
          <Button className="actived" onClick={onCancel}>
            取消
          </Button>
          <Button type="primary" onClick={submitCostItem}>
            确定
          </Button>
        </Space>
      </div>
    </Modal>
  )
}
export default CostModal
