import React from 'react'
import { Button, Radio, Form, Row, Col, Spin, Card, Modal, Tooltip } from 'antd'
import * as echarts from 'echarts'
import ReactECharts from 'echarts-for-react'
import { useParams } from 'react-router-dom'

import { ProjectServiceHistory } from '../../../../../apis/project-service-history'
import { KLFAlert } from '../components/klf-alert'
import { catalogueObservable, eleObservable, Empty, InfoList } from '../../index'
import { colorRgb, ELEC_CHARTS_COLORS } from '../../../../../utils/constant'
import Global, { CustomIcons } from '../../../../../global'

const formItemLayout = {
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 21 },
  },
}

const translateMap: { [key: string]: string } = {
  electricityPrice: '电价版本',
  userType: '用电类型',
  voltageLevel: '电压等级',
  isTime: '是否分时',
  chargeType: '收费方式',
  basicEnergyChargePattern: '基本电费收费方式',
}

export const Catalogue = () => {
  const [form] = Form.useForm()

  const params = useParams()

  const [config, setConfig] = React.useState<{ [key: string]: any }>()
  const [finished, setFinished] = React.useState(false)
  const [forceEdit, setForceEdit] = React.useState(false)
  const [uploading, setUploading] = React.useState(false)

  const [result, setResult] = React.useState<any>({
    electricityPrice: '',
    userType: '',
    voltageLevel: '',
    isTime: '',
    chargeType: '',
    basicEnergyChargePattern: '',
  })

  const [chartData, setChartData] = React.useState<any>()

  const auth = Global.projectAuth || {}
  const hasAuth = !!auth.electricity_price_scheme

  const [canEdit, setEditable] = React.useState(false);
  const [editing, setEditing] = React.useState(false);

  React.useEffect(() => {
    ProjectServiceHistory.get({
      projectId: params.id!,
    }).then((resp) => {
      const item = resp.data
      const { lockedTechnicalProposalNum } = item
      setEditable(lockedTechnicalProposalNum === 0)
    })

    getConfig()
    const subscription = InfoList.subscribe((o: any) => {
      setFinished(o.electricity.finished)
      setForceEdit(o.electricity.forceEdit)
    })
    return () => {
      subscription.unsubscribe()
    }
  }, [])

  React.useEffect(() => {
    if (forceEdit) {
      getResult(() => {
        getConfig()
      })
    }
  }, [forceEdit])

  React.useEffect(() => {
    if (finished) {
      getResult()
    }
  }, [finished])

  const getResult = (callback?: Function) => {
    ProjectServiceHistory.chart1({
      projectId: params.id!,
    })
      .then((resp) => {
        const { electricityPrice, userType, voltageLevel, isTime, chargeType, basicEnergyChargePattern } = resp.data

        setResult({
          userType,
          voltageLevel,
          isTime,
          chargeType,
          basicEnergyChargePattern,
          electricityPrice,
        })

        setChartData({
          basicEnergyCharge: resp.data.basicEnergyCharge,
          updateTime: resp.data.updateTime,
          charts: resp.data.charts,
          time: resp.data.time,
        })

        form.setFieldsValue({
          electricityPrice: electricityPrice?.toString(),
          userType: userType.toString(),
          voltageLevel: voltageLevel.toString(),
          isTime: isTime.toString(),
          chargeType: chargeType.toString(),
          basicEnergyChargePattern: basicEnergyChargePattern.toString(),
        })

        callback?.()

        setUploading(false)
      })
      .catch(() => {
        setUploading(false)
      })
  }

  const getConfig = (onlyUserType = false, onlyElectricityPrice = false) => {
    const electricityPrice = form.getFieldValue('electricityPrice')
    const userType = onlyElectricityPrice ? undefined : form.getFieldValue('userType')
    const voltageLevel = onlyUserType ? undefined : form.getFieldValue('voltageLevel')

    ProjectServiceHistory.option({
      projectId: params.id!,
      userType,
      electricityPrice,
      voltageLevel,
    })
      .then((resp) => {
        const cfg: any = {}
        Object.keys(resp.data).forEach((key) => {
          const val = resp.data[key]
          if (Array.isArray(val)) {
            const obj: any = {}
            val.forEach((o) => {
              obj[o] = o === true ? '是' : o === false ? '否' : o
            })
            cfg[key] = obj
          } else {
            cfg[key] = resp.data[key]
          }

          if (Object.keys(cfg[key]).length === 1) {
            form.setFieldsValue({ [key]: Object.keys(cfg[key])[0] })
          }
          const currentVal = form.getFieldValue(key)
          console.log(key);
          if (currentVal !== undefined && !Object.keys(cfg[key]).includes(currentVal.toString())) {
            form.setFieldsValue({ [key]: '' })
          }
        })
        const { electricityPrice, userType, voltageLevel, isTime, chargeType, basicEnergyChargePattern } = cfg

        setConfig({
          electricityPrice,
          userType,
          voltageLevel,
          isTime,
          chargeType,
          basicEnergyChargePattern,
        })
      })
      .catch(() => {
        setConfig({ ...config })
      })
      .finally(() => {})
  }

  const onSubmit = (values: any) => {
    setUploading(true)
    const { electricityPrice, userType, voltageLevel, isTime, chargeType, basicEnergyChargePattern } = values
    ProjectServiceHistory.catalog1({
      projectId: params.id!,
      root: { electricityPrice, userType, voltageLevel, isTime, chargeType, basicEnergyChargePattern },
    })
      .then(() => {
        setForceEdit(false)
        getResult()
        eleObservable.next(true)
        setEditing(false);
      })
      .catch(() => {
        setUploading(false)
      })
  }

  const getStringsWith = (configKey: string, val: any) => {
    if (config) {
      return config[configKey][val]
    }
    return ''
  }
  return (
    <Spin spinning={uploading}>
      {finished || hasAuth ? (
        <Card
          className="no-padding-card electricity-info-card"
          title="目录电价"
          bordered={false}
          style={{
            marginTop: '14px',
          }}
          headStyle={{
            padding: 0,
            minHeight: '40px',
            lineHeight: '40px',
            fontSize: '18px',
            fontWeight: 'bold',
            color: '#262E3F',
          }}
          extra={(!editing && <Tooltip title="该项目下有归档的技术方案" trigger={canEdit ? 'none' : 'hover'}>
            <Button
              type="primary"
              disabled={!hasAuth}
              ghost
              onClick={() => {
                Modal.confirm({
                  keyboard: false,
                  title: `修改目录电价`,
                  icon: <CustomIcons type="klf-alarm1" />,
                  content: (
                    <div>
                      <p>确认修改目录电价？</p>
                      <p>该项目下与之关联的技术方案结果将全部失效（归档方案将解除归档），财务方案将被全部删除</p>
                    </div>
                  ),
                  onOk: () => {
                    catalogueObservable.next(true);
                    setEditing(true);
                  },
                  cancelButtonProps: {
                    type: 'primary',
                    ghost: true,
                  },
                })
              }}
            >
              <CustomIcons type="klf-edit1" />
              编辑设置
            </Button>
          </Tooltip>)}
        >
          {finished && !forceEdit && (
            <div>
              <KLFAlert projectId={params.id!} hasAuth={hasAuth} />

              <Row
                className="dotted-row"
                style={{
                  // borderBottom: '1px dotted #EBEBEB',
                  paddingBottom: 10,
                  position: 'relative',
                }}
              >
                <div className="border-bottom-dotted-line" />
                {Object.keys(result)?.map((key: string) => {
                  if (!result[key]) {
                    return null
                  }
                  return (
                    <Col span={4} key={key}>
                      <strong>{translateMap[key]}: </strong>
                      <span>{getStringsWith(key, result[key])}</span>
                    </Col>
                  )
                })}
              </Row>
              {chartData && (
                <Card
                  bodyStyle={{
                    padding: 0,
                  }}
                  className="no-padding-card"
                  bordered={false}
                  headStyle={{
                    borderBottom: 'none',
                  }}
                  title={
                    <span
                      style={{
                        fontWeight: 400,
                        fontSize: 16,
                      }}
                    >
                      <strong>{result.basicEnergyChargePattern?.toString() === '2' ? '需量' : '容量'}电价：</strong>
                      {chartData.basicEnergyCharge}元/
                      {result.basicEnergyChargePattern?.toString() === '2' ? 'kW·月' : 'kVA·月'}
                    </span>
                  }
                  extra={
                    <span
                      style={{
                        float: 'right',
                      }}
                    >
                      <strong>更新时间：</strong>
                      {chartData.updateTime}
                    </span>
                  }
                  style={{
                    marginTop: '0',
                    paddingRight: '1rem',
                  }}
                >
                  <div>
                    {chartData && (
                      <div
                        style={{
                          width: '100%',
                        }}
                      >
                        <div
                          style={{
                            color: '#262E3F',
                            fontWeight: 600,
                            fontSize: 16,
                          }}
                        >
                          电量电价
                        </div>
                        <ReactECharts
                          style={{
                            width: '100%',
                            height: 310,
                          }}
                          option={{
                            tooltip: {
                              trigger: 'axis',
                              formatter: (args: any) => {
                                let res = `<strong>${args[0].axisValue}</strong>`
                                if (args.length > 0) {
                                  res += args
                                    .map(
                                      (o: any) =>
                                        `<div style='width: 200px;'>${o.marker}${o.seriesName} <span style='float: right'>${o.value}元/kWh</span></div>`
                                    )
                                    .join('')
                                }
                                return res
                              },
                            },
                            grid: {
                              left: 30,
                              right: '3%',
                              top: 40,
                              bottom: 30,
                              containLabel: true,
                            },
                            xAxis: {
                              type: 'category',
                              axisLabel: {
                                interval: 3,
                              },
                              boundaryGap: false,
                              data: Object.values(chartData.time)[0],
                            },
                            yAxis: {
                              type: 'value',
                              name: '单位：元/kWh',
                              nameTextStyle: {
                                color: 'gray',
                              },
                            },
                            legend: {
                              show: true,
                              top: 'bottom',
                              icon: 'rect',
                            },
                            series: Object.keys(chartData.charts).map((key, idx) => {
                              return {
                                name: key,
                                type: 'line',
                                sampling: 'lttb',
                                showSymbol: false,
                                lineStyle: {
                                  width: 1,
                                },
                                itemStyle: {
                                  color: ELEC_CHARTS_COLORS[idx],
                                  // color: key.includes('非') ? 'rgb(30, 198, 146)' : 'rgb(24, 144, 255)',
                                },
                                areaStyle: {
                                  color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                                    {
                                      offset: 0,
                                      color: colorRgb(ELEC_CHARTS_COLORS[idx], 0.1),
                                    },
                                    {
                                      offset: 1,
                                      color: colorRgb(ELEC_CHARTS_COLORS[idx], 0),
                                    },
                                  ]),
                                },
                                data: chartData.charts[key],
                              }
                            }),
                          }}
                        />
                      </div>
                    )}
                  </div>
                </Card>
              )}
            </div>
          )}
          {(!finished || forceEdit) && (
            <Form
              form={form}
              className="ele-form"
              {...formItemLayout}
              onFinish={onSubmit}
              style={{
                marginTop: '14px',
              }}
            >
              {config &&
                Object.keys(config).map((key, index) => {
                  if (key === 'basicEnergyChargePattern' && form.getFieldValue('chargeType') !== '2') {
                    return null
                  }
                  return (
                    <Form.Item
                      key={index + key}
                      name={key}
                      label={`${translateMap[key]}：`}
                      rules={[{ required: true, message: `请选择${translateMap[key]}` }]}
                    >
                      <Radio.Group
                        onChange={() => {
                          if (key === 'electricityPrice') {
                            getConfig(true, true)
                          } else if (key === 'userType') {
                            getConfig(true)
                          } else if (key === 'voltageLevel') {
                            getConfig()
                          }
                          setConfig({ ...config })
                        }}
                      >
                        {config[key] &&
                          Object.keys(config[key]).map((itemKey, idx) => {
                            return (
                              <Radio value={itemKey} key={`${idx}-${itemKey}`}>
                                {config[key][itemKey]}
                              </Radio>
                            )
                          })}
                      </Radio.Group>
                    </Form.Item>
                  )
                })}
              <Form.Item className="actions-footer">
                <Button type="primary" htmlType="submit">
                  确定
                </Button>
                {finished && (
                  <Button
                    onClick={() => {
                      // eleObservable.next(true)
                      window.location.reload()
                    }}
                  >
                    取消
                  </Button>
                )}
              </Form.Item>
            </Form>
          )}
        </Card>
      ) : (
        <Empty />
      )}
    </Spin>
  )
}
