import { useState, useEffect } from 'react'
import { Row, Col, Form, Select, Input, InputNumber, Popconfirm, Button, Space, notification, message } from 'antd'
import { PlusOutlined, ExclamationCircleFilled } from '@ant-design/icons'

import { NUMBER_EXP } from '../../../../utils/constant'
import { CustomIcons } from '../../../../global'
import { FinanceApi } from '../../../../services'

import './index.less'

const FORM_LAYOUT_HALF = {
  labelCol: {
    xs: { span: 10 },
    sm: { span: 10 },
    md: { span: 10 },
    lg: { span: 10 },
    xl: { span: 10 },
    xxl: { span: 10 },
  },
  wrapperCol: {
    xs: { span: 14 },
    sm: { span: 14 },
    md: { span: 14 },
    lg: { span: 14 },
    xl: { span: 14 },
    xxl: { span: 14 },
  },
}

const AwardSubsidyForm = (props: any) => {
  const { currentItem, awardSubsidyId, detailList, name, index } = props
  const [form] = Form.useForm()
  const [item, setItem] = useState<any>()
  const [subsidyType, setSubsidyType] = useState<any>()
  const [incentiveList, setIncentiveList] = useState<any>([])
  const [rows, setRows] = useState<any>([
    {
      index: 0,
    },
  ])

  const showError = (text: string) => {
    notification.warning({
      icon: <ExclamationCircleFilled style={{ color: '#f97942' }} />,
      message: <strong>警告提示</strong>,
      description: text,
      duration: 3,
    })
  }

  const subsidyType0 = () => {
    const values = form.getFieldsValue(['microgridFactor'])
    return (
      <>
        <Row>
          <Col span={24}>
            <Form.Item
              name="money"
              label="金额"
              initialValue={item.money || 0}
              rules={[
                {
                  required: true,
                  message: '',
                },
              ]}
              getValueFromEvent={(e: any) => {
                const values = form.getFieldsValue(['moneyType'])
                let val = e.target.value
                if (val < 0) {
                  val = 0
                } else if (val > 100 && values.moneyType === 0) {
                  val = 100
                } else {
                  val = Number.parseFloat(e.target.value.replace(NUMBER_EXP, ''))
                }
                return val
              }}
            >
              <Input
                className="compact"
                placeholder="请输入"
                type="number"
                min={0}
                suffix={
                  <Form.Item
                    name="moneyType"
                    initialValue={
                      item.moneyType || item.moneyType === 0 ? item.moneyType : values.microgridFactor !== 1 ? 2 : 3
                    }
                  >
                    <Select
                      style={{ width: 80 }}
                      onChange={() => {
                        const values = form.getFieldsValue(['money', 'moneyType', 'moneyMax'])
                        if (values.money && values.money > 100 && values.moneyType === 0) {
                          form.setFieldsValue({
                            money: 100,
                          })
                        }
                        if (values.moneyType != 1 && values.moneyMax > 999999999) {
                          form.setFieldsValue({
                            moneyMax: 999999999,
                          })
                        }
                        saveIncentive('moneyType')
                      }}
                    >
                      <Select.Option value={0}>%</Select.Option>
                      <Select.Option value={1}>元</Select.Option>
                      {values.microgridFactor !== 1 && <Select.Option value={2}>元/kWh</Select.Option>}
                      {values.microgridFactor !== 2 && <Select.Option value={3}>元/kW</Select.Option>}
                    </Select>
                  </Form.Item>
                }
                onBlur={() => {
                  saveIncentive('money')
                }}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              name="moneyMax"
              label="最大值"
              initialValue={item.moneyMax || 0}
              rules={[
                {
                  required: true,
                  message: '',
                },
              ]}
              getValueFromEvent={(e: any) => {
                const values = form.getFieldsValue(['moneyType'])
                let val = e.target.value
                if (e.target.value === '') {
                  return ''
                } else if (e.target.value < 0) {
                  return 0
                } else if (values.moneyType != 1) {
                  if (val > 999999999.99) {
                    return 999999999.99
                  } else {
                    const arr = new Array(2)
                    arr.fill('\\d')
                    const pat = `^(\\-)*(\\d+)\\.(${arr.join('')}).*$`
                    return Number(`${val}`.replace(new RegExp(pat), '$1$2.$3'))
                  }
                } else {
                  return Number(`${Number(e.target.value.replace(NUMBER_EXP, ''))}`)
                }
              }}
            >
              <Input
                suffix="元"
                type="number"
                min={0}
                placeholder="请输入"
                onBlur={() => {
                  saveIncentive('moneyMax')
                }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item
              name="grantDeadline"
              label="发放时间"
              initialValue={item.grantDeadline || 0}
              rules={[
                {
                  required: true,
                  message: '',
                },
              ]}
              getValueFromEvent={(e: any) => {
                if (e.target.value === '') {
                  return ''
                } else {
                  return `${Number(e.target.value.replace(NUMBER_EXP, ''))}`
                }
              }}
            >
              <Input
                suffix="年"
                type="number"
                min={0}
                placeholder="请输入"
                onBlur={() => {
                  saveIncentive('grantDeadline')
                }}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              name="isAvoidIncomeTax"
              label="是否免所得税"
              rules={[
                {
                  required: true,
                  message: '',
                },
              ]}
              initialValue={item.isAvoidIncomeTax}
            >
              <Select
                placeholder="请选择"
                onChange={() => {
                  saveIncentive('isAvoidIncomeTax')
                }}
              >
                <Select.Option value={0}>否</Select.Option>
                <Select.Option value={1}>是</Select.Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
      </>
    )
  }

  // 电价补贴
  const subsidyType1 = () => {
    return (
      <>
        <Row>
          <Col span={24}>
            <Form.Item
              name="subsidyObject"
              label="补贴对象"
              rules={[
                {
                  required: true,
                  message: '',
                },
              ]}
              initialValue={item.subsidyObject}
            >
              <Select
                placeholder="请选择"
                onChange={() => {
                  saveIncentive('subsidyObject')
                }}
              >
                <Select.Option value={0}>消纳电量</Select.Option>
                <Select.Option value={1}>上网电量</Select.Option>
                <Select.Option value={2}>全部电量</Select.Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              name="unitSubsidy"
              label="单位补贴"
              initialValue={item.unitSubsidy || 0}
              rules={[
                {
                  required: true,
                  message: '',
                },
              ]}
              getValueFromEvent={(e: any) => {
                if (e.target.value === '') {
                  return ''
                } else {
                  return `${
                    Number(e.target.value.replace(NUMBER_EXP, '')) > 999999
                      ? 999999
                      : Number(e.target.value.replace(NUMBER_EXP, ''))
                  }`
                }
              }}
            >
              <Input
                suffix="¥/kWh"
                type="number"
                min={0}
                placeholder="请输入"
                onBlur={() => {
                  saveIncentive('unitSubsidy')
                }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item
              name="grantDeadline"
              label="发放期限"
              initialValue={item.grantDeadline || 0}
              rules={[
                {
                  required: true,
                  message: '',
                },
              ]}
              getValueFromEvent={(e: any) => {
                if (e.target.value === '' || e.target.value < 0) {
                  return 0
                } else {
                  return `${Number.parseInt(e.target.value.replace(NUMBER_EXP, ''))}`
                }
              }}
            >
              <Input
                suffix="年"
                type="number"
                min={0}
                placeholder="请输入"
                onBlur={() => {
                  saveIncentive('grantDeadline')
                }}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              name="isAvoidIncomeTax"
              label="是否免所得税"
              rules={[
                {
                  required: true,
                  message: '',
                },
              ]}
              initialValue={item.isAvoidIncomeTax}
            >
              <Select
                placeholder="请选择"
                onChange={() => {
                  saveIncentive('isAvoidIncomeTax')
                }}
              >
                <Select.Option value={0}>否</Select.Option>
                <Select.Option value={1}>是</Select.Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
      </>
    )
  }

  // 添加补贴详情
  const addRow = () => {
    const list = rows?.slice() || []
    list.push({
      index: (rows?.length || 1) - 1,
    })
    setRows(list)
  }

  const getTaxReliefMinMax = (index: number) => {
    const list = incentiveList.slice(0, index + 1)
    const startMin = list?.[index - 1]?.endYear + 1 || 0
    const startMax = list?.[index + 1]?.startYear
    const endMin = list?.[index]?.startYear || list?.[index - 1]?.endYear + 1
    const endMax = list?.[index + 1]?.startYear
    return [startMin, startMax, endMin, endMax]
  }

  // 删除减免行
  const delRow = (row: any, index: number) => {
    const list = rows?.slice() || []
    list.splice(index, 1)
    setRows([...list])
    setIncentiveList([...list])
    saveIncentiveListData()
  }

  // 减免增值税 / 减免所得税
  const subsidyType2 = () => {
    return (
      <>
        {rows?.map((row: any, index: any) => {
          const [startMin, startMax, endMin, endMax] = getTaxReliefMinMax(index)
          return (
            <Row key={index}>
              <Col span={22}>
                <Form.Item label="起止时间">
                  <div className="years-wrap">
                    <span>第</span>
                    <Form.Item
                      name={['taxReliefList', index, 'startYear']}
                      rules={[
                        {
                          required: true,
                          message: '',
                        },
                      ]}
                      initialValue={item?.['taxReliefMap']?.[index]?.['startYear']}
                    >
                      <InputNumber
                        placeholder="0"
                        min={startMin}
                        max={startMax}
                        onChange={(e) => {
                          setIncentiveListData(index, 'startYear', e)
                        }}
                        onBlur={() => {
                          saveIncentiveListData()
                        }}
                      />
                    </Form.Item>
                    <span>-</span>
                    <Form.Item
                      name={['taxReliefList', index, 'endYear']}
                      rules={[
                        {
                          required: true,
                          message: '',
                        },
                      ]}
                      initialValue={item?.['taxReliefMap']?.[index]?.['endYear']}
                    >
                      <InputNumber
                        placeholder="0"
                        min={endMin}
                        max={endMax}
                        onChange={(e) => {
                          setIncentiveListData(index, 'endYear', e)
                        }}
                        onBlur={() => {
                          saveIncentiveListData()
                        }}
                      />
                    </Form.Item>
                    <span>年</span>
                  </div>
                </Form.Item>
              </Col>
              <Col span={2}>
                <Popconfirm
                  title="确认删除?"
                  onConfirm={() => {
                    delRow(row, index)
                  }}
                >
                  <CustomIcons type="klf-delete" className="custom-del-btn" />
                </Popconfirm>
              </Col>
              <Col span={24}>
                <Form.Item
                  name={['taxReliefList', index, 'rate']}
                  initialValue={item?.['taxReliefMap']?.[index]?.['rate']}
                  label="减免税额百分比"
                  labelCol={{ span: 10 }}
                  wrapperCol={{ span: 14 }}
                  rules={[
                    {
                      required: true,
                      message: '',
                    },
                  ]}
                  getValueFromEvent={(e: any) => {
                    let val = e.target.value
                    if (val < 0) {
                      val = 0
                    } else if (val > 100) {
                      val = 100
                    } else {
                      const arr = new Array(1)
                      arr.fill('\\d')
                      const pat = `^(\\-)*(\\d+)\\.(${arr.join('')}).*$`
                      const numberValue = Number(e.target.value.replace(NUMBER_EXP, ''))
                      val = `${numberValue}`.replace(new RegExp(pat), '$1$2.$3')
                    }
                    return val
                  }}
                >
                  <Input
                    suffix="%"
                    type="number"
                    min={0}
                    max={100}
                    placeholder="请输入"
                    onBlur={() => {
                      saveIncentiveListData()
                    }}
                  />
                </Form.Item>
              </Col>
            </Row>
          )
        })}
        <Row>
          <Col span={24} style={{ paddingLeft: '10px' }}>
            <Button type="dashed" className="add-row" onClick={addRow}>
              <PlusOutlined />
              <span>添加时段</span>
            </Button>
          </Col>
        </Row>
      </>
    )
  }

  // 保存时验证数据
  const validData = () => {
    const values = form.getFieldsValue()

    return new Promise((resolve, reject) => {
      if (
        currentItem.detailList?.filter(
          (item: any) => item.microgridFactor === values.microgridFactor && item.subsidyType === values.subsidyType
        )?.length
      ) {
        showError('同一激励补贴下的补贴类型只能存在一种')
        reject()
      }
      resolve('')
    })
  }

  // 保存
  const onSave = (data: any) => {
    const FinanceApiService = new FinanceApi()
    // @ts-ignore
    FinanceApiService.i3060FinanceAwardSubsidyEditPost('', { ...data })
      .then(() => {})
      .catch(() => {})
      .finally(() => {})
  }

  //   保存数据
  const saveIncentive = async (type?: string) => {
    validData().then(() => {
      const values = form.getFieldsValue()
      let curValues = { ...values }
      const taxReliefList = form.getFieldValue('taxReliefList')
      const errors = taxReliefList?.filter((item: any, index: number) => {
        return (
          (subsidyType === 2 || subsidyType === 3) &&
          (item.startYear < taxReliefList?.[index - 1]?.endYear || item.endYear < item.startYear)
        )
      })
      if (errors?.length > 0) {
        message.error('请检查补贴数据')
        return
      }
      if (type === 'isAvoidIncomeTax') {
        curValues['isAvoidIncomeTax'] = curValues['isAvoidIncomeTax'] ? 1 : 0
      }

      if (type) {
        let value = values[type]
        let curValue = item[type]
        if (value === curValue && type !== 'taxReliefList') return
        let editObj = {
          name: currentItem.name,
          awardSubsidyDetailId: currentItem.awardSubsidyDetailId,
          //   ...currentItem,
          ...curValues,
        }
        detailList[index] = editObj
        let curDetailList = detailList.map((item: any) => {
          return {
            ...item,
            taxReliefList:
              item.awardSubsidyDetailId === currentItem.awardSubsidyDetailId
                ? values.taxReliefList
                  ? values.taxReliefList
                  : item.taxReliefMap
                : item.taxReliefMap,
          }
        })
        onSave({ name, awardSubsidyId, detailList: curDetailList })
      }
    })
  }

  const setIncentiveListData = (index: number, key: string, e: any) => {
    const list = incentiveList.slice()
    list.splice(index, 1, {
      ...list[index],
      [key]: e,
    })
    setIncentiveList(list)
  }

  const saveIncentiveListData = () => {
    saveIncentive('taxReliefList')
  }

  useEffect(() => {
    currentItem['isAvoidIncomeTax'] = currentItem['isAvoidIncomeTax'] ? 1 : 0
    setItem(currentItem)
    if (currentItem.taxReliefMap) {
      setRows(
        currentItem.taxReliefMap?.map((item: any, index: number) => {
          return {
            index: index,
          }
        })
      )
      setIncentiveList(
        currentItem.taxReliefMap.map((item: any) => {
          return {
            startYear: item.startYear,
            endYear: item.endYear,
          }
        })
      )
    }
    setSubsidyType(currentItem?.subsidyType)
    form.setFieldsValue(currentItem)
  }, [])

  if (!item || !currentItem) return null
  return (
    <div className="incentiveAddModal">
      <Form form={form} {...FORM_LAYOUT_HALF} requiredMark={false}>
        <Row>
          <Col span={24}>
            <Form.Item
              name="microgridFactor"
              label="微网要素"
              initialValue={item.microgridFactor}
              rules={[
                {
                  required: true,
                  message: '',
                },
              ]}
            >
              <Select
                placeholder="请选择"
                onChange={() => {
                  setSubsidyType(null)
                  form.setFieldsValue({
                    subsidyType: null,
                  })
                }}
              >
                <Select.Option value={1}>光伏</Select.Option>
                <Select.Option value={2}>储能</Select.Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item
              name="subsidyType"
              label="补贴类型"
              initialValue={item.subsidyType}
              rules={[
                {
                  required: true,
                  message: '',
                },
              ]}
            >
              <Select
                placeholder="请选择"
                onChange={(e) => {
                  saveIncentive('subsidyType')
                  setSubsidyType(e)
                  const microgridFactor = form.getFieldValue('microgridFactor')
                  if (e === 0 && microgridFactor == 1) {
                    form.setFieldsValue({
                      moneyType: 3,
                    })
                  }
                  if (e === 0 && microgridFactor == 2) {
                    form.setFieldsValue({
                      moneyType: 2,
                    })
                  }
                }}
              >
                <Select.Option value={0}>一次性现金补贴</Select.Option>
                <Select.Option value={1}>电价补贴</Select.Option>
                <Select.Option value={2}>减免增值税</Select.Option>
                <Select.Option value={3}>减免所得税</Select.Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Space />

        {subsidyType === 0 && subsidyType0()}
        {subsidyType === 1 && subsidyType1()}
        {(subsidyType === 2 || subsidyType === 3) && subsidyType2()}
      </Form>
    </div>
  )
}
export default AwardSubsidyForm
