export * from './AccountControllerApi';
import AccountControllerApi from './AccountControllerApi';
export * from './AssetsTreeApi';
import AssetsTreeApi from './AssetsTreeApi';
export * from './CdataApi';
import CdataApi from './CdataApi';
export * from './CommonApi';
import CommonApi from './CommonApi';
export * from './EvaluationApi';
import EvaluationApi from './EvaluationApi';
export * from './EvaluationV2Api';
import EvaluationV2Api from './EvaluationV2Api';
export * from './FinanceApi';
import FinanceApi from './FinanceApi';
export * from './HelperJurisdictionControllerApi';
import HelperJurisdictionControllerApi from './HelperJurisdictionControllerApi';
export * from './InviteControllerApi';
import InviteControllerApi from './InviteControllerApi';
export * from './LargeScreenControllerApi';
import LargeScreenControllerApi from './LargeScreenControllerApi';
export * from './PermissionTemplateControllerApi';
import PermissionTemplateControllerApi from './PermissionTemplateControllerApi';
export * from './PowerStationControllerApi';
import PowerStationControllerApi from './PowerStationControllerApi';
export * from './ProjectApi';
import ProjectApi from './ProjectApi';
export * from './ProjectAccountControllerApi';
import ProjectAccountControllerApi from './ProjectAccountControllerApi';
export * from './ProjectManageControllerApi';
import ProjectManageControllerApi from './ProjectManageControllerApi';
export * from './ProjectWorkbenchApi';
import ProjectWorkbenchApi from './ProjectWorkbenchApi';
export * from './ResourceApi';
import ResourceApi from './ResourceApi';
export * from './RoofWordApi';
import RoofWordApi from './RoofWordApi';
export * from './TechnologyApi';
import TechnologyApi from './TechnologyApi';
export * from './TenantControllerApi';
import TenantControllerApi from './TenantControllerApi';
export * from './UserApi';
import UserApi from './UserApi';
export const APIS = { AccountControllerApi, AssetsTreeApi, CdataApi, CommonApi, EvaluationApi, EvaluationV2Api, FinanceApi, HelperJurisdictionControllerApi, InviteControllerApi, LargeScreenControllerApi, PermissionTemplateControllerApi, PowerStationControllerApi, ProjectApi, ProjectAccountControllerApi, ProjectManageControllerApi, ProjectWorkbenchApi, ResourceApi, RoofWordApi, TechnologyApi, TenantControllerApi, UserApi };
