import React, { useEffect, useState } from 'react'
import s from './index.module.scss'
import { Button, Card, Space, message, Menu, Popover } from 'antd'
import { useLocation, useParams } from 'react-router-dom'
import { SubBreadcrumbObservable } from '../../../../user-center/layout'
import PermissionTemplateControllerApi from '../../../../../services/api/PermissionTemplateControllerApi'
import { SideMenuObservable } from '../../../../../application'
import CardTitle from '../../components/CardTitle'
import ProjectAccountControllerApi from '../../../../../services/api/ProjectAccountControllerApi'
import { CaretDownOutlined, UserOutlined } from '@ant-design/icons'
import Global, { CustomIcons } from '../../../../../global'
import { PermissionTemplateControllerService } from '../../../../../apis/project-service-stash'
import PermissionList from '../../components/PermissionList'
import { getCheckedIds } from '../../../../../utils/utils'

const routerLocation = location

const ProjectTemplateEdit = () => {
  const { id, type, activeVal, userId, isEdit }: any = useParams()
  const { state }: any = useLocation()
  const [resources, setResources] = useState([])
  const [checkIdList, setCheckIdList] = useState([])
  const [items, setItems] = useState<any>([])

  useEffect(() => {
    SideMenuObservable.next(true)
    SubBreadcrumbObservable.next([])
    PermissionTemplateControllerApi.authPermissionTemplateResourcesAllTreeGet().then((res: any) => {
      const list = res?.data || []
      if (res?.code == 200) setResources(list)
    })
  }, [])
  console.log(123)

  useEffect(() => {
    ProjectAccountControllerApi.i3060ProjectAccountResourcesGet({ userId, projectId: id }).then((res: any) => {
      const { code, data } = res || {}
      if (code === 200) {
        const list = data || []
        const checkedIds = getCheckedIds(list)
        setCheckIdList(checkedIds)
      }
    })
    PermissionTemplateControllerApi.authPermissionTemplateListGet().then((res: any) => {
      setItems(res.data || [])
    })
  }, [userId])

  const url = type ? '/' + type : ''

  const goBack = () => routerLocation.assign(`#/program/detail/${id + url}/settings/${activeVal}`)

  const saveResources = () => {
    ProjectAccountControllerApi.i3060ProjectAccountSaveResourcesPost({ userId, projectId: id, checkIdList }).then(
      () => {
        message.success('操作成功！')
        goBack()
      }
    )
  }
  const [user, setUser] = useState(Global.user)
  Global.updateUser.push((e: any) => {
    setUser(e)
  })
  const upItemeList = ({ templateId }: any) => {
    PermissionTemplateControllerService.query({
      templateId,
      userId: user.id,
    })
      .then((res) => {
        const { code, data } = res || {}
        if (code === 200) {
          const list = data?.filter((res) => res?.chooseFlag && res.id) || []
          setCheckIdList(list.map((res) => res.id) || [])
        }
      })
      .catch(() => {})
  }

  return (
    <Card className="main-card" title={<CardTitle title={isEdit == '1' ? '权限设置' : '查看权限'} goBack={goBack} />}>
      <div
        className={s.authTemplate}
        style={
          isEdit == '1'
            ? {
                paddingBottom: 50,
              }
            : {}
        }
      >
        <div className={s.authTemplateDetail}>
          <div className={s.authTemplateHeader}>
            <Space size={12}>
              <div className={s.xStart}>
                <div>
                  <UserOutlined className="add-members-item-avatar" />
                  {state?.userName}
                </div>
                {isEdit != '0' ? (
                  <Popover
                    overlayClassName="menu-popover-overlay"
                    content={
                      <Menu
                        mode="inline"
                        selectable={false}
                      >
                        {items?.map((res: any) => (
                          <Menu.Item
                            key={res?.templateId}
                            style={{
                              padding: 0,
                              width: 100,
                            }}
                            onClick={() => upItemeList(res)}
                          >
                            {res?.templateName}
                          </Menu.Item>
                        ))}
                      </Menu>
                    }
                  >
                    <Button
                      icon={
                        <div style={{ marginLeft: 10 }} className={s.xStart}>
                          <CustomIcons
                            type="klf-stack"
                            style={{
                              fontSize: 16,
                            }}
                          />
                          <CaretDownOutlined
                            style={{
                              fontSize: 10,
                              marginLeft: 4,
                            }}
                          />
                        </div>
                      }
                      type="link"
                      ghost
                    />
                  </Popover>
                ) : null}
              </div>
            </Space>
          </div>
          <div className={s.authTemplatePermission}>
            <PermissionList
              checkedKeys={checkIdList}
              list={resources}
              disabled={isEdit == '0'}
              onCheck={(values) => setCheckIdList(values)}
              overflowY="calc(100vh - 340px)"
            />
          </div>
          {isEdit == '1' && (
            <div className={s.authTemplateDetailFooter}>
              <Button type="primary" size="large" style={{ marginRight: 10 }} onClick={saveResources}>
                应用
              </Button>
              <Button size="large" onClick={goBack}>
                返回
              </Button>
            </div>
          )}
        </div>
      </div>
    </Card>
  )
}

export default ProjectTemplateEdit
