import React,{ useEffect, useState } from 'react'
import s from "./index.module.scss"
import { Button, Card, Input, Space, Table, Checkbox } from 'antd'
import { SubBreadcrumbObservable } from './layout'
import PermissionTemplateControllerApi from '../../services/api/PermissionTemplateControllerApi'
import { useNavigate, useParams } from 'react-router-dom'
import IconBack from '../../assets/back.png'
import IconMember from '../../assets/member.png'
import Global from '../../global'

const AuthTemplateEdit = () => {

  const navigate = useNavigate()
  const params = useParams()
  const [resources, setResources] = useState([])
  const [templateName, setTemplateName] = useState('')
  const [authority, setAuthority] = useState(false)

  useEffect(() => {
    SubBreadcrumbObservable.next([
      {
        name: '设置',
        url: '',
      },
      {
        name: '项目权限模板',
        url: '/user-center/auth-template',
      },
      {
        name: '新增',
        url: '/user-center/auth-template/edit',
      }
    ])
    PermissionTemplateControllerApi.authPermissionTemplateResourcesQueryGet({ templateId: params.id, userId: Global.user.id }).then((res: any) => {
      foldResources(res.data)
    })
  }, [])

  const foldResources = (data = []) => {
    const result: any = []
    const record = []
    data?.forEach((val: any) => {
      if (record.length === 0) {
        setTemplateName(val.templateName)
        setAuthority(val.authority)
      }
      const modularAt = record.indexOf(val.modular)
      if (modularAt === -1) {
        record.push(val.modular)
        result.push({
          modular: val.modular,
          operations: [{
            id: val.id,
            operationObject: val.operationObject,
            functionPermission: val.functionPermission,
            chooseFlag: val.chooseFlag
          }]
        })
      } else {
        result.operations.push({
          id: val.id,
          operationObject: val.operationObject,
          functionPermission: val.functionPermission,
          chooseFlag: val.chooseFlag
        })
      }
    })
    setResources(result)
  }

  // const expandResoutces = () => {}

  const CardTitle = React.memo((props: any) => {
    const goBack = () => navigate('')
    return (
      <div className={s.authTemplateEditTitle}>
        <div className={s.authTemplateEditTitleBack} onClick={goBack}>
          <img src={IconBack} alt="返回" />
        </div>
        <h3>{props.title}</h3>
      </div>
    )
  })

  const handleChange = (values: string[]) => {
    console.log('🚀 ~ file: index.tsx ~ line 154 ~ handleChange ~ values', values)
  }

  const columns = [
    {
      title: '所属模块',
      dataIndex: 'modular',
      key: 'modular',
      width: 100,
    },
    {
      title: '操作对象',
      dataIndex: 'operations',
      key: 'operations',
      width: 150,
      render: (text, record) => (
        <div className={s.authTemplatePermissionList}>
          {record?.operations?.map(({ id, operationObject }) => (
            <div className={s.authTemplatePermissionItem} key={id}>
              {operationObject}
            </div>
          ))}
        </div>
      ),
    },
    {
      title: '操作权限',
      key: 'functionPermission',
      render: (text, record) => (
        <div className={s.authTemplatePermissionList}>
          {record?.operations?.map(({ id, functionPermission, chooseFlag }) => (
            <div className={s.authTemplatePermissionItem} key={id}>
              <Checkbox key={chooseFlag} value={chooseFlag}>
                {functionPermission}
              </Checkbox>
            </div>
          ))}
        </div>
      ),
    },
  ]

  return (
    <Card className="main-card" title={<CardTitle title="权限设置" />}>
      <div className={s.authTemplate}>
        <div className={s.authTemplateDetail}>
          <div className={s.authTemplateHeader}>
            <Space size={12}>
              <img width={32} src={IconMember} alt="" />
              <Input allowClear size="large" value={templateName} />
            </Space>
          </div>
          <div className={s.authTemplatePermission}>
            <Checkbox.Group className={s.authTemplateBox} disabled={false} onChange={handleChange}>
              <Table
                className={s.authTemplatePermissionTable}
                columns={columns}
                dataSource={resources}
                pagination={false}
                scroll={{ y: 500 }}
              />
            </Checkbox.Group>
          </div>
          {authority && (
            <div className={s.authTemplateDetailFooter}>
              <Button type="primary" size="large" style={{ marginRight: 10 }}>
                应用
              </Button>
              <Button size="large">返回</Button>
            </div>
          )}
        </div>
      </div>
    </Card>
  )
}

export default AuthTemplateEdit
