/**
 * project
 * unknown
 *
 * OpenAPI spec version: last
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import ajax, { AjaxPromise, ExtraFetchParams } from '@ajax';
import * as models from '../model/models';

/* tslint:disable:no-unused-variable member-ordering object-literal-shorthand */

/**
  */
export type ParamsBodyi3060RoofGetNearbyRoofByIdsPost = models.I3060RoofGetNearbyRoofByIdsBody;
/**
  * @description i3060TechnicalWorkbenchAddEnergyStorageTechnologyIdPost参数
  * @property `technologyId` 技术方案id
  */
export interface Paramsi3060TechnicalWorkbenchAddEnergyStorageTechnologyIdPost {
  // pathParams
  /**
   * 技术方案id
   */
  technologyId: string;
}
/**
  * @description i3060TechnicalWorkbenchAddPhotovoltaicTechnologyIdPost参数
  * @property `technologyId` 技术方案id add/list 接口有返回
  */
export interface Paramsi3060TechnicalWorkbenchAddPhotovoltaicTechnologyIdPost {
  // pathParams
  /**
   * 技术方案id add/list 接口有返回
   */
  technologyId: string;
}
/**
  * @description i3060TechnicalWorkbenchCalcRoofModuleNumTechnologyIdPost参数
  * @property `technologyId` 
  */
export interface Paramsi3060TechnicalWorkbenchCalcRoofModuleNumTechnologyIdPost {
  // pathParams
  /**
   * 
   */
  technologyId: string;
}
/**
  */
export type ParamsBodyi3060TechnicalWorkbenchCalcRoofModuleNumTechnologyIdPost = models.I3060TechnicalWorkbenchCalcRoofModuleNumTechnologyIdBody;
/**
  * @description i3060TechnicalWorkbenchCalcTechnicalTechnologyIdPost参数
  * @property `technologyId` 
  */
export interface Paramsi3060TechnicalWorkbenchCalcTechnicalTechnologyIdPost {
  // pathParams
  /**
   * 
   */
  technologyId: string;
}
/**
  * @description i3060TechnicalWorkbenchCheckParamLibraryTechnicalProposalIdPost参数
  * @property `technicalProposalId` 技术方案id
  */
export interface Paramsi3060TechnicalWorkbenchCheckParamLibraryTechnicalProposalIdPost {
  // pathParams
  /**
   * 技术方案id
   */
  technicalProposalId: string;
}
/**
  */
export type ParamsBodyi3060TechnicalWorkbenchCheckParamLibraryTechnicalProposalIdPost = models.I3060TechnicalWorkbenchCheckParamLibraryTechnicalProposalIdBody;
/**
  * @description i3060TechnicalWorkbenchGetAutoCheckOptTechnologyIdGet参数
  * @property `technologyId` 
  */
export interface Paramsi3060TechnicalWorkbenchGetAutoCheckOptTechnologyIdGet {
  // pathParams
  /**
   * 
   */
  technologyId: string;
}
/**
  * @description i3060TechnicalWorkbenchGetDefaultDemandTechnicalProposalIdGet参数
  * @property `technicalProposalId` 方案id
  */
export interface Paramsi3060TechnicalWorkbenchGetDefaultDemandTechnicalProposalIdGet {
  // pathParams
  /**
   * 方案id
   */
  technicalProposalId: string;
}
/**
  * @description i3060TechnicalWorkbenchGetEnergyStorageSystemEfficiencyTechnologyIdGet参数
  * @property `technologyId` 
  */
export interface Paramsi3060TechnicalWorkbenchGetEnergyStorageSystemEfficiencyTechnologyIdGet {
  // pathParams
  /**
   * 
   */
  technologyId: string;
}
/**
  * @description i3060TechnicalWorkbenchGetNearbyRoofTechnologyIdGet参数
  * @property `technologyId` 
  * @property `distance` 范围 单位 公里
  */
export interface Paramsi3060TechnicalWorkbenchGetNearbyRoofTechnologyIdGet {
  // pathParams
  /**
   * 
   */
  technologyId: string;
  // queryParams
  /**
   * 范围 单位 公里
   */
  distance: string;
}
/**
  * @description i3060TechnicalWorkbenchGetPhotovoltaicSystemEfficiencyTechnologyIdGet参数
  * @property `technologyId` 
  */
export interface Paramsi3060TechnicalWorkbenchGetPhotovoltaicSystemEfficiencyTechnologyIdGet {
  // pathParams
  /**
   * 
   */
  technologyId: string;
}
/**
  * @description i3060TechnicalWorkbenchGetReferencePriceOptionTechnicalProposalIdGet headers 参数
  * @property `Authorization` Authorization (Only:)
  */
export interface ParamsHeaderi3060TechnicalWorkbenchGetReferencePriceOptionTechnicalProposalIdGet {
  /** Authorization (Only:) */
  'Authorization': string;
}
/**
  * @description i3060TechnicalWorkbenchGetReferencePriceOptionTechnicalProposalIdGet参数
  * @property `technicalProposalId` 技术方案id
  */
export interface Paramsi3060TechnicalWorkbenchGetReferencePriceOptionTechnicalProposalIdGet {
  // pathParams
  /**
   * 技术方案id
   */
  technicalProposalId: string;
}
/**
  * @description i3060TechnicalWorkbenchGetRoofModuleInfoTechnologyIdGet参数
  * @property `technologyId` 
  */
export interface Paramsi3060TechnicalWorkbenchGetRoofModuleInfoTechnologyIdGet {
  // pathParams
  /**
   * 
   */
  technologyId: string;
}
/**
  * @description i3060TechnicalWorkbenchGetTopologyElectricityInfoIdGet参数
  * @property `id` 技术方案id
  * @property `key` 左侧列表选择的项目 自定义电价的名字
  * @property `time` 时间 右侧时间控件选择的时间 2020年开头
  * @property `showAll` 展示全部
  */
export interface Paramsi3060TechnicalWorkbenchGetTopologyElectricityInfoIdGet {
  // pathParams
  /**
   * 技术方案id
   */
  id: string;
  // queryParams
  /**
   * 左侧列表选择的项目 自定义电价的名字
   */
  key: string;
  /**
   * 时间 右侧时间控件选择的时间 2020年开头
   */
  time: string;
  /**
   * 展示全部
   */
  showAll: string;
}
/**
  * @description i3060TechnicalWorkbenchRemoveEnergyStorageTechnologyIdPost参数
  * @property `technologyId` 
  */
export interface Paramsi3060TechnicalWorkbenchRemoveEnergyStorageTechnologyIdPost {
  // pathParams
  /**
   * 
   */
  technologyId: string;
}
/**
  * @description i3060TechnicalWorkbenchRemovePhotovoltaicTechnologyIdPost参数
  * @property `technologyId` 技术方案id
  */
export interface Paramsi3060TechnicalWorkbenchRemovePhotovoltaicTechnologyIdPost {
  // pathParams
  /**
   * 技术方案id
   */
  technologyId: string;
}
/**
  * @description i3060TechnicalWorkbenchTechnicalProposalIdGet headers 参数
  * @property `Authorization` Authorization (Only:)
  */
export interface ParamsHeaderi3060TechnicalWorkbenchTechnicalProposalIdGet {
  /** Authorization (Only:) */
  'Authorization': string;
}
/**
  * @description i3060TechnicalWorkbenchTechnicalProposalIdGet参数
  * @property `technicalProposalId` 技术方案id
  */
export interface Paramsi3060TechnicalWorkbenchTechnicalProposalIdGet {
  // pathParams
  /**
   * 技术方案id
   */
  technicalProposalId: string;
}
/**
  * @description i3060TechnicalWorkbenchTechnologyIdGet参数
  * @property `technologyId` 
  */
export interface Paramsi3060TechnicalWorkbenchTechnologyIdGet {
  // pathParams
  /**
   * 
   */
  technologyId: string;
}
/**
  * @description i3060TechnicalWorkbenchUpdateBatteryTechnologyIdPost参数
  * @property `technologyId` 
  */
export interface Paramsi3060TechnicalWorkbenchUpdateBatteryTechnologyIdPost {
  // pathParams
  /**
   * 
   */
  technologyId: string;
}
/**
  */
export type ParamsBodyi3060TechnicalWorkbenchUpdateBatteryTechnologyIdPost = models.I3060TechnicalWorkbenchUpdateBatteryTechnologyIdBody;
/**
  * @description i3060TechnicalWorkbenchUpdateDemandTechnicalProposalIdPost参数
  * @property `technicalProposalId` 方案id
  */
export interface Paramsi3060TechnicalWorkbenchUpdateDemandTechnicalProposalIdPost {
  // pathParams
  /**
   * 方案id
   */
  technicalProposalId: string;
}
/**
  */
export type ParamsBodyi3060TechnicalWorkbenchUpdateDemandTechnicalProposalIdPost = Array<models.I3060TechnicalWorkbenchUpdateDemandTechnicalProposalIdBody>;
/**
  * @description i3060TechnicalWorkbenchUpdateEnergyStorageCapacityTechnologyIdPost参数
  * @property `technologyId` 
  */
export interface Paramsi3060TechnicalWorkbenchUpdateEnergyStorageCapacityTechnologyIdPost {
  // pathParams
  /**
   * 
   */
  technologyId: string;
}
/**
  */
export type ParamsBodyi3060TechnicalWorkbenchUpdateEnergyStorageCapacityTechnologyIdPost = models.I3060TechnicalWorkbenchUpdateEnergyStorageCapacityTechnologyIdBody;
/**
  * @description i3060TechnicalWorkbenchUpdateEnergyStorageSystemEfficiencyTechnologyIdPost参数
  * @property `technologyId` 方案id
  */
export interface Paramsi3060TechnicalWorkbenchUpdateEnergyStorageSystemEfficiencyTechnologyIdPost {
  // pathParams
  /**
   * 方案id
   */
  technologyId: string;
}
/**
  */
export type ParamsBodyi3060TechnicalWorkbenchUpdateEnergyStorageSystemEfficiencyTechnologyIdPost = models.I3060TechnicalWorkbenchUpdateEnergyStorageSystemEfficiencyTechnologyIdBody;
/**
  * @description i3060TechnicalWorkbenchUpdateEnergyStorageTopologyTechnologyIdPost参数
  * @property `technologyId` 
  */
export interface Paramsi3060TechnicalWorkbenchUpdateEnergyStorageTopologyTechnologyIdPost {
  // pathParams
  /**
   * 
   */
  technologyId: string;
}
/**
  */
export type ParamsBodyi3060TechnicalWorkbenchUpdateEnergyStorageTopologyTechnologyIdPost = models.I3060TechnicalWorkbenchUpdateEnergyStorageTopologyTechnologyIdBody;
/**
  * @description i3060TechnicalWorkbenchUpdateInverterModuleTechnologyIdPost参数
  * @property `technologyId` 
  */
export interface Paramsi3060TechnicalWorkbenchUpdateInverterModuleTechnologyIdPost {
  // pathParams
  /**
   * 
   */
  technologyId: string;
}
/**
  */
export type ParamsBodyi3060TechnicalWorkbenchUpdateInverterModuleTechnologyIdPost = models.I3060TechnicalWorkbenchUpdateInverterModuleTechnologyIdBody;
/**
  * @description i3060TechnicalWorkbenchUpdatePhotovoltaicModuleTechnologyIdPost参数
  * @property `technologyId` 技术方案id
  */
export interface Paramsi3060TechnicalWorkbenchUpdatePhotovoltaicModuleTechnologyIdPost {
  // pathParams
  /**
   * 技术方案id
   */
  technologyId: string;
}
/**
  */
export type ParamsBodyi3060TechnicalWorkbenchUpdatePhotovoltaicModuleTechnologyIdPost = models.I3060TechnicalWorkbenchUpdatePhotovoltaicModuleTechnologyIdBody;
/**
  * @description i3060TechnicalWorkbenchUpdatePhotovoltaicOtherInfoTechnicalProposalIdPost headers 参数
  * @property `Authorization` Authorization (Only:)
  */
export interface ParamsHeaderi3060TechnicalWorkbenchUpdatePhotovoltaicOtherInfoTechnicalProposalIdPost {
  /** Authorization (Only:) */
  'Authorization': string;
}
/**
  * @description i3060TechnicalWorkbenchUpdatePhotovoltaicOtherInfoTechnicalProposalIdPost参数
  * @property `technicalProposalId` 技术方案id
  */
export interface Paramsi3060TechnicalWorkbenchUpdatePhotovoltaicOtherInfoTechnicalProposalIdPost {
  // pathParams
  /**
   * 技术方案id
   */
  technicalProposalId: string;
}
/**
  */
export type ParamsBodyi3060TechnicalWorkbenchUpdatePhotovoltaicOtherInfoTechnicalProposalIdPost = models.I3060TechnicalWorkbenchUpdatePhotovoltaicOtherInfoTechnicalProposalIdBody;
/**
  * @description i3060TechnicalWorkbenchUpdatePhotovoltaicSystemEfficiencyTechnologyIdPost参数
  * @property `technologyId` 
  */
export interface Paramsi3060TechnicalWorkbenchUpdatePhotovoltaicSystemEfficiencyTechnologyIdPost {
  // pathParams
  /**
   * 
   */
  technologyId: string;
}
/**
  */
export type ParamsBodyi3060TechnicalWorkbenchUpdatePhotovoltaicSystemEfficiencyTechnologyIdPost = models.I3060TechnicalWorkbenchUpdatePhotovoltaicSystemEfficiencyTechnologyIdBody;
/**
  * @description i3060TechnicalWorkbenchUpdatePhotovoltaicTopologyTechnologyIdPost headers 参数
  * @property `Authorization` Authorization (Only:undefined)
  */
export interface ParamsHeaderi3060TechnicalWorkbenchUpdatePhotovoltaicTopologyTechnologyIdPost {
  /** Authorization (Only:undefined) */
  'Authorization': string;
}
/**
  * @description i3060TechnicalWorkbenchUpdatePhotovoltaicTopologyTechnologyIdPost参数
  * @property `technologyId` 技术方案id
  */
export interface Paramsi3060TechnicalWorkbenchUpdatePhotovoltaicTopologyTechnologyIdPost {
  // pathParams
  /**
   * 技术方案id
   */
  technologyId: string;
}
/**
  */
export type ParamsBodyi3060TechnicalWorkbenchUpdatePhotovoltaicTopologyTechnologyIdPost = models.I3060TechnicalWorkbenchUpdatePhotovoltaicTopologyTechnologyIdBody;
/**
  * @description i3060TechnicalWorkbenchUpdateRevenueSharingTechnologyIdPost参数
  * @property `technologyId` 
  */
export interface Paramsi3060TechnicalWorkbenchUpdateRevenueSharingTechnologyIdPost {
  // pathParams
  /**
   * 
   */
  technologyId: string;
}
/**
  */
export type ParamsBodyi3060TechnicalWorkbenchUpdateRevenueSharingTechnologyIdPost = models.I3060TechnicalWorkbenchUpdateRevenueSharingTechnologyIdBody;
/**
  * @description i3060TechnicalWorkbenchUpdateRoofModuleTechnologyIdPost参数
  * @property `technologyId` 
  */
export interface Paramsi3060TechnicalWorkbenchUpdateRoofModuleTechnologyIdPost {
  // pathParams
  /**
   * 
   */
  technologyId: string;
}
/**
  */
export type ParamsBodyi3060TechnicalWorkbenchUpdateRoofModuleTechnologyIdPost = models.I3060TechnicalWorkbenchUpdateRoofModuleTechnologyIdBody;
/**
  * @description i3060TechnicalWorkbenchUpdateTechnologyNameTechnologyIdPost参数
  * @property `technologyId` 技术方案id
  */
export interface Paramsi3060TechnicalWorkbenchUpdateTechnologyNameTechnologyIdPost {
  // pathParams
  /**
   * 技术方案id
   */
  technologyId: string;
}
/**
  */
export type ParamsBodyi3060TechnicalWorkbenchUpdateTechnologyNameTechnologyIdPost = models.I3060TechnicalWorkbenchUpdateTechnologyNameTechnologyIdBody;
/**
  * @description i3060WorkbenchPhotovoltaicInverterListGet参数
  * @property `inverterName` 逆变器名字
  * @property `manufacturer` 品牌
  * @property `maxDcPowerMin` 最大支流功率区间
  * @property `maxDcPowerMax` 最大直流功率区间
  * @property `maxAcPowerMin` 最大交流功率区间
  * @property `maxAcPowerMax` 最大交流功率区间
  * @property `page` 1
  * @property `size` 10
  */
export interface Paramsi3060WorkbenchPhotovoltaicInverterListGet {
  // queryParams
  /**
   * 逆变器名字
   */
  inverterName: string;
  /**
   * 品牌
   */
  manufacturer: string;
  /**
   * 最大支流功率区间
   */
  maxDcPowerMin: string;
  /**
   * 最大直流功率区间
   */
  maxDcPowerMax: string;
  /**
   * 最大交流功率区间
   */
  maxAcPowerMin: string;
  /**
   * 最大交流功率区间
   */
  maxAcPowerMax: string;
  /**
   * 1
   */
  page: string;
  /**
   * 10
   */
  size: string;
}
/**
  * @description i3060WorkbenchPhotovoltaicModelListGet参数
  * @property `pvModuleName` 型号
  * @property `manufacturer` 品牌
  * @property `cellType` 电池片类型       * 多晶      * 单晶      * Thin Film
  * @property `stcMin` stc最大功率 区间
  * @property `stcMax` stc最大功率 区间
  * @property `ptcMin` ptc最大功率 区间
  * @property `ptcMax` ptc最大功率 区间
  * @property `bifacial` 单面/双面
  * @property `openVoltageMin` 开路电压 区间
  * @property `openVoltageMax` 开路电压 区间
  * @property `page` 1
  * @property `size` 100
  */
export interface Paramsi3060WorkbenchPhotovoltaicModelListGet {
  // queryParams
  /**
   * 型号
   */
  pvModuleName: string;
  /**
   * 品牌
   */
  manufacturer: string;
  /**
   * 电池片类型       * 多晶      * 单晶      * Thin Film
   */
  cellType: string;
  /**
   * stc最大功率 区间
   */
  stcMin: string;
  /**
   * stc最大功率 区间
   */
  stcMax: string;
  /**
   * ptc最大功率 区间
   */
  ptcMin: string;
  /**
   * ptc最大功率 区间
   */
  ptcMax: string;
  /**
   * 单面/双面
   */
  bifacial: string;
  /**
   * 开路电压 区间
   */
  openVoltageMin: string;
  /**
   * 开路电压 区间
   */
  openVoltageMax: string;
  /**
   * 1
   */
  page: string;
  /**
   * 100
   */
  size: string;
}

export class ProjectWorkbenchApi {
  protected $basePath = '/api'.replace(/\/$/, '');

  public constructor(basePath?: string) {
    if (basePath !== undefined) {
      this.$basePath = basePath.replace(/\/$/, '');
    }
  }

  /**
   * 
   * @summary 根据屋顶id列表查询屋顶坐标
   
   * @param data: ParamsBodyi3060RoofGetNearbyRoofByIdsPost
   * @param opt ajax config
   * @returns models.I3060RoofGetNearbyRoofByIds
   */
  public i3060RoofGetNearbyRoofByIdsPost = (
    
    data: ParamsBodyi3060RoofGetNearbyRoofByIdsPost,opt?: ExtraFetchParams
  ) : AjaxPromise<models.I3060RoofGetNearbyRoofByIds>  => {
    const url = this.$basePath + `/i3060/roof/getNearbyRoofByIds`;
    const p: any = {};
    p.data = data;
    return ajax.ajax({
      ...opt,
      method: 'POST',
      url,
      ...p
    });
  }
  /**
   * 
   * @summary 新增储能
   * @param params Paramsi3060TechnicalWorkbenchAddEnergyStorageTechnologyIdPost
   
   * @param opt ajax config
   * @returns models.I3060TechnicalWorkbenchAddEnergyStorageTechnologyId
   */
  public i3060TechnicalWorkbenchAddEnergyStorageTechnologyIdPost = (
    params: Paramsi3060TechnicalWorkbenchAddEnergyStorageTechnologyIdPost,
    opt?: ExtraFetchParams
  ) : AjaxPromise<models.I3060TechnicalWorkbenchAddEnergyStorageTechnologyId>  => {
    const {
    technologyId,
} = params;
    const url = this.$basePath + `/i3060/technical/workbench/addEnergyStorage/${technologyId}`;
    const p: any = {};
    ajax.check(params.technologyId, 'technologyId');
    return ajax.ajax({
      ...opt,
      method: 'POST',
      url,
      ...p
    });
  }
  /**
   * 
   * @summary 增加光伏
   * @param params Paramsi3060TechnicalWorkbenchAddPhotovoltaicTechnologyIdPost
   
   * @param opt ajax config
   * @returns models.I3060TechnicalWorkbenchUpdatePhotovoltaicTopologyTechnologyId
   */
  public i3060TechnicalWorkbenchAddPhotovoltaicTechnologyIdPost = (
    params: Paramsi3060TechnicalWorkbenchAddPhotovoltaicTechnologyIdPost,
    opt?: ExtraFetchParams
  ) : AjaxPromise<models.I3060TechnicalWorkbenchUpdatePhotovoltaicTopologyTechnologyId>  => {
    const {
    technologyId,
} = params;
    const url = this.$basePath + `/i3060/technical/workbench/addPhotovoltaic/${technologyId}`;
    const p: any = {};
    ajax.check(params.technologyId, 'technologyId');
    return ajax.ajax({
      ...opt,
      method: 'POST',
      url,
      ...p
    });
  }
  /**
   * 
   * @summary 计算屋顶获取可安装模组
   * @param params Paramsi3060TechnicalWorkbenchCalcRoofModuleNumTechnologyIdPost
   * @param data: ParamsBodyi3060TechnicalWorkbenchCalcRoofModuleNumTechnologyIdPost
   * @param opt ajax config
   * @returns models.I3060TechnicalWorkbenchCalcRoofModuleNumTechnologyId
   */
  public i3060TechnicalWorkbenchCalcRoofModuleNumTechnologyIdPost = (
    params: Paramsi3060TechnicalWorkbenchCalcRoofModuleNumTechnologyIdPost,
    data: ParamsBodyi3060TechnicalWorkbenchCalcRoofModuleNumTechnologyIdPost,opt?: ExtraFetchParams
  ) : AjaxPromise<models.I3060TechnicalWorkbenchCalcRoofModuleNumTechnologyId>  => {
    const {
    technologyId,
} = params;
    const url = this.$basePath + `/i3060/technical/workbench/calcRoofModuleNum/${technologyId}`;
    const p: any = {};
    ajax.check(params.technologyId, 'technologyId');
    p.data = data;
    return ajax.ajax({
      ...opt,
      method: 'POST',
      url,
      ...p
    });
  }
  /**
   * 
   * @summary 技术方案计算
   * @param params Paramsi3060TechnicalWorkbenchCalcTechnicalTechnologyIdPost
   
   * @param opt ajax config
   * @returns models.AuthUserSendCheckEmail
   */
  public i3060TechnicalWorkbenchCalcTechnicalTechnologyIdPost = (
    params: Paramsi3060TechnicalWorkbenchCalcTechnicalTechnologyIdPost,
    opt?: ExtraFetchParams
  ) : AjaxPromise<models.AuthUserSendCheckEmail>  => {
    const {
    technologyId,
} = params;
    const url = this.$basePath + `/i3060/technical/workbench/calcTechnical/${technologyId}`;
    const p: any = {};
    ajax.check(params.technologyId, 'technologyId');
    return ajax.ajax({
      ...opt,
      method: 'POST',
      url,
      ...p
    });
  }
  /**
   * 
   * @summary 切换技术方案参数库方案
   * @param params Paramsi3060TechnicalWorkbenchCheckParamLibraryTechnicalProposalIdPost
   * @param data: ParamsBodyi3060TechnicalWorkbenchCheckParamLibraryTechnicalProposalIdPost
   * @param opt ajax config
   * @returns models.AuthUserSendCheckEmail
   */
  public i3060TechnicalWorkbenchCheckParamLibraryTechnicalProposalIdPost = (
    params: Paramsi3060TechnicalWorkbenchCheckParamLibraryTechnicalProposalIdPost,
    data: ParamsBodyi3060TechnicalWorkbenchCheckParamLibraryTechnicalProposalIdPost,opt?: ExtraFetchParams
  ) : AjaxPromise<models.AuthUserSendCheckEmail>  => {
    const {
    technicalProposalId,
} = params;
    const url = this.$basePath + `/i3060/technical/workbench/checkParamLibrary/${technicalProposalId}`;
    const p: any = {};
    ajax.check(params.technicalProposalId, 'technicalProposalId');
    p.data = data;
    return ajax.ajax({
      ...opt,
      method: 'POST',
      url,
      ...p
    });
  }
  /**
   * 
   * @summary 获取储能系统优选属性
   * @param params Paramsi3060TechnicalWorkbenchGetAutoCheckOptTechnologyIdGet
   
   * @param opt ajax config
   * @returns models.I3060TechnicalWorkbenchGetAutoCheckOptTechnologyId
   */
  public i3060TechnicalWorkbenchGetAutoCheckOptTechnologyIdGet = (
    params: Paramsi3060TechnicalWorkbenchGetAutoCheckOptTechnologyIdGet,
    opt?: ExtraFetchParams
  ) : AjaxPromise<models.I3060TechnicalWorkbenchGetAutoCheckOptTechnologyId>  => {
    const {
    technologyId,
} = params;
    const url = this.$basePath + `/i3060/technical/workbench/getAutoCheckOpt/${technologyId}`;
    const p: any = {};
    ajax.check(params.technologyId, 'technologyId');
    return ajax.ajax({
      ...opt,
      method: 'GET',
      url,
      ...p
    });
  }
  /**
   * 
   * @summary 获取默认需量限制
   * @param params Paramsi3060TechnicalWorkbenchGetDefaultDemandTechnicalProposalIdGet
   
   * @param opt ajax config
   * @returns models.I3060TechnicalWorkbenchGetDefaultDemandTechnicalProposalId
   */
  public i3060TechnicalWorkbenchGetDefaultDemandTechnicalProposalIdGet = (
    params: Paramsi3060TechnicalWorkbenchGetDefaultDemandTechnicalProposalIdGet,
    opt?: ExtraFetchParams
  ) : AjaxPromise<models.I3060TechnicalWorkbenchGetDefaultDemandTechnicalProposalId>  => {
    const {
    technicalProposalId,
} = params;
    const url = this.$basePath + `/i3060/technical/workbench/getDefaultDemand/${technicalProposalId}`;
    const p: any = {};
    ajax.check(params.technicalProposalId, 'technicalProposalId');
    return ajax.ajax({
      ...opt,
      method: 'GET',
      url,
      ...p
    });
  }
  /**
   * 
   * @summary 查询储能系统效率
   * @param params Paramsi3060TechnicalWorkbenchGetEnergyStorageSystemEfficiencyTechnologyIdGet
   
   * @param opt ajax config
   * @returns models.I3060TechnicalWorkbenchGetEnergyStorageSystemEfficiencyTechnologyId
   */
  public i3060TechnicalWorkbenchGetEnergyStorageSystemEfficiencyTechnologyIdGet = (
    params: Paramsi3060TechnicalWorkbenchGetEnergyStorageSystemEfficiencyTechnologyIdGet,
    opt?: ExtraFetchParams
  ) : AjaxPromise<models.I3060TechnicalWorkbenchGetEnergyStorageSystemEfficiencyTechnologyId>  => {
    const {
    technologyId,
} = params;
    const url = this.$basePath + `/i3060/technical/workbench/getEnergyStorageSystemEfficiency/${technologyId}`;
    const p: any = {};
    ajax.check(params.technologyId, 'technologyId');
    return ajax.ajax({
      ...opt,
      method: 'GET',
      url,
      ...p
    });
  }
  /**
   * 
   * @summary 获取项目附近屋顶坐标数据
   * @param params Paramsi3060TechnicalWorkbenchGetNearbyRoofTechnologyIdGet
   
   * @param opt ajax config
   * @returns models.I3060TechnicalWorkbenchGetNearbyRoofTechnologyId
   */
  public i3060TechnicalWorkbenchGetNearbyRoofTechnologyIdGet = (
    params: Paramsi3060TechnicalWorkbenchGetNearbyRoofTechnologyIdGet,
    opt?: ExtraFetchParams
  ) : AjaxPromise<models.I3060TechnicalWorkbenchGetNearbyRoofTechnologyId>  => {
    const {
    technologyId,
} = params;
    const url = this.$basePath + `/i3060/technical/workbench/getNearbyRoof/${technologyId}`;
    const p: any = {};
    p.query = {};
    if ('distance' in params) p.query.distance = params.distance;
    ajax.check(params.distance, 'distance');
    ajax.check(params.technologyId, 'technologyId');
    return ajax.ajax({
      ...opt,
      method: 'GET',
      url,
      ...p
    });
  }
  /**
   * 
   * @summary 获取光伏系统效率详细参数
   * @param params Paramsi3060TechnicalWorkbenchGetPhotovoltaicSystemEfficiencyTechnologyIdGet
   
   * @param opt ajax config
   * @returns models.I3060TechnicalWorkbenchGetPhotovoltaicSystemEfficiencyTechnologyId
   */
  public i3060TechnicalWorkbenchGetPhotovoltaicSystemEfficiencyTechnologyIdGet = (
    params: Paramsi3060TechnicalWorkbenchGetPhotovoltaicSystemEfficiencyTechnologyIdGet,
    opt?: ExtraFetchParams
  ) : AjaxPromise<models.I3060TechnicalWorkbenchGetPhotovoltaicSystemEfficiencyTechnologyId>  => {
    const {
    technologyId,
} = params;
    const url = this.$basePath + `/i3060/technical/workbench/getPhotovoltaicSystemEfficiency/${technologyId}`;
    const p: any = {};
    ajax.check(params.technologyId, 'technologyId');
    return ajax.ajax({
      ...opt,
      method: 'GET',
      url,
      ...p
    });
  }
  /**
   * 
   * @summary 获取拓扑设置-参考电价下拉选项
   * @param params Paramsi3060TechnicalWorkbenchGetReferencePriceOptionTechnicalProposalIdGet
   
   * @param opt ajax config
   * @returns models.I3060TechnicalWorkbenchGetReferencePriceOptionTechnicalProposalId
   */
  public i3060TechnicalWorkbenchGetReferencePriceOptionTechnicalProposalIdGet = (
    params: Paramsi3060TechnicalWorkbenchGetReferencePriceOptionTechnicalProposalIdGet,
    opt?: { headers: ParamsHeaderi3060TechnicalWorkbenchGetReferencePriceOptionTechnicalProposalIdGet } & Omit<ExtraFetchParams, 'headers'> 
  ) : AjaxPromise<models.I3060TechnicalWorkbenchGetReferencePriceOptionTechnicalProposalId>  => {
    const {
    technicalProposalId,
} = params;
    const url = this.$basePath + `/i3060/technical/workbench/getReferencePriceOption/${technicalProposalId}`;
    const p: any = {};
    ajax.check(params.technicalProposalId, 'technicalProposalId');
    return ajax.ajax({
      ...opt,
      method: 'GET',
      url,
      ...p
    });
  }
  /**
   * 
   * @summary 查询屋顶信息
   * @param params Paramsi3060TechnicalWorkbenchGetRoofModuleInfoTechnologyIdGet
   
   * @param opt ajax config
   * @returns models.I3060TechnicalWorkbenchGetRoofModuleInfoTechnologyId
   */
  public i3060TechnicalWorkbenchGetRoofModuleInfoTechnologyIdGet = (
    params: Paramsi3060TechnicalWorkbenchGetRoofModuleInfoTechnologyIdGet,
    opt?: ExtraFetchParams
  ) : AjaxPromise<models.I3060TechnicalWorkbenchGetRoofModuleInfoTechnologyId>  => {
    const {
    technologyId,
} = params;
    const url = this.$basePath + `/i3060/technical/workbench/getRoofModuleInfo/${technologyId}`;
    const p: any = {};
    ajax.check(params.technologyId, 'technologyId');
    return ajax.ajax({
      ...opt,
      method: 'GET',
      url,
      ...p
    });
  }
  /**
   * 
   * @summary 结算结果页面能流图以及电价相关
   * @param params Paramsi3060TechnicalWorkbenchGetTopologyElectricityInfoIdGet
   
   * @param opt ajax config
   * @returns models.I3060TechnicalWorkbenchGetTopologyElectricityInfoId
   */
  public i3060TechnicalWorkbenchGetTopologyElectricityInfoIdGet = (
    params: Paramsi3060TechnicalWorkbenchGetTopologyElectricityInfoIdGet,
    opt?: ExtraFetchParams
  ) : AjaxPromise<models.I3060TechnicalWorkbenchGetTopologyElectricityInfoId>  => {
    const {
    id,
} = params;
    const url = this.$basePath + `/i3060/technical/workbench/getTopologyElectricityInfo/${id}`;
    const p: any = {};
    p.query = {};
    if ('key' in params) p.query.key = params.key;
    ajax.check(params.key, 'key');
    if ('time' in params) p.query.time = params.time;
    ajax.check(params.time, 'time');
    if ('showAll' in params) p.query.showAll = params.showAll;
    ajax.check(params.showAll, 'showAll');
    ajax.check(params.id, 'id');
    return ajax.ajax({
      ...opt,
      method: 'GET',
      url,
      ...p
    });
  }
  /**
   * 
   * @summary 获取光伏发电验证报告
   
   
   * @param opt ajax config
   * @returns any
   */
  public i3060TechnicalWorkbenchPhotovoltaicValidReportGet = (
    
    opt?: ExtraFetchParams
  ) : AjaxPromise<any>  => {
    const url = this.$basePath + `/i3060/technical/workbench/photovoltaicValidReport`;
    const p: any = {};
    return ajax.ajax({
      ...opt,
      method: 'GET',
      url,
      ...p
    });
  }
  /**
   * 
   * @summary 移除储能
   * @param params Paramsi3060TechnicalWorkbenchRemoveEnergyStorageTechnologyIdPost
   
   * @param opt ajax config
   * @returns models.I3060TechnicalWorkbenchRemoveEnergyStorageTechnologyId
   */
  public i3060TechnicalWorkbenchRemoveEnergyStorageTechnologyIdPost = (
    params: Paramsi3060TechnicalWorkbenchRemoveEnergyStorageTechnologyIdPost,
    opt?: ExtraFetchParams
  ) : AjaxPromise<models.I3060TechnicalWorkbenchRemoveEnergyStorageTechnologyId>  => {
    const {
    technologyId,
} = params;
    const url = this.$basePath + `/i3060/technical/workbench/removeEnergyStorage/${technologyId}`;
    const p: any = {};
    ajax.check(params.technologyId, 'technologyId');
    return ajax.ajax({
      ...opt,
      method: 'POST',
      url,
      ...p
    });
  }
  /**
   * 
   * @summary 移除光伏
   * @param params Paramsi3060TechnicalWorkbenchRemovePhotovoltaicTechnologyIdPost
   
   * @param opt ajax config
   * @returns models.I3060TechnicalWorkbenchUpdatePhotovoltaicTopologyTechnologyId
   */
  public i3060TechnicalWorkbenchRemovePhotovoltaicTechnologyIdPost = (
    params: Paramsi3060TechnicalWorkbenchRemovePhotovoltaicTechnologyIdPost,
    opt?: ExtraFetchParams
  ) : AjaxPromise<models.I3060TechnicalWorkbenchUpdatePhotovoltaicTopologyTechnologyId>  => {
    const {
    technologyId,
} = params;
    const url = this.$basePath + `/i3060/technical/workbench/removePhotovoltaic/${technologyId}`;
    const p: any = {};
    ajax.check(params.technologyId, 'technologyId');
    return ajax.ajax({
      ...opt,
      method: 'POST',
      url,
      ...p
    });
  }
  /**
   * 
   * @summary 技术方案工作台详情
   * @param params Paramsi3060TechnicalWorkbenchTechnicalProposalIdGet
   
   * @param opt ajax config
   * @returns models.I3060TechnicalWorkbenchTechnicalProposalId
   */
  public i3060TechnicalWorkbenchTechnicalProposalIdGet = (
    params: Paramsi3060TechnicalWorkbenchTechnicalProposalIdGet,
    opt?: { headers: ParamsHeaderi3060TechnicalWorkbenchTechnicalProposalIdGet } & Omit<ExtraFetchParams, 'headers'> 
  ) : AjaxPromise<models.I3060TechnicalWorkbenchTechnicalProposalId>  => {
    const {
    technicalProposalId,
} = params;
    const url = this.$basePath + `/i3060/technical/workbench/${technicalProposalId}`;
    const p: any = {};
    ajax.check(params.technicalProposalId, 'technicalProposalId');
    return ajax.ajax({
      ...opt,
      method: 'GET',
      url,
      ...p
    });
  }
  /**
   * 
   * @summary 获取技术方案详情（编辑）
   * @param params Paramsi3060TechnicalWorkbenchTechnologyIdGet
   
   * @param opt ajax config
   * @returns models.I3060TechnicalWorkbenchTechnologyId
   */
  public i3060TechnicalWorkbenchTechnologyIdGet = (
    params: Paramsi3060TechnicalWorkbenchTechnologyIdGet,
    opt?: ExtraFetchParams
  ) : AjaxPromise<models.I3060TechnicalWorkbenchTechnologyId>  => {
    const {
    technologyId,
} = params;
    const url = this.$basePath + `/i3060/technical/workbench/${technologyId}`;
    const p: any = {};
    ajax.check(params.technologyId, 'technologyId');
    return ajax.ajax({
      ...opt,
      method: 'GET',
      url,
      ...p
    });
  }
  /**
   * 
   * @summary 修改储能电池信息
   * @param params Paramsi3060TechnicalWorkbenchUpdateBatteryTechnologyIdPost
   * @param data: ParamsBodyi3060TechnicalWorkbenchUpdateBatteryTechnologyIdPost
   * @param opt ajax config
   * @returns models.AuthUserSendCheckEmail
   */
  public i3060TechnicalWorkbenchUpdateBatteryTechnologyIdPost = (
    params: Paramsi3060TechnicalWorkbenchUpdateBatteryTechnologyIdPost,
    data: ParamsBodyi3060TechnicalWorkbenchUpdateBatteryTechnologyIdPost,opt?: ExtraFetchParams
  ) : AjaxPromise<models.AuthUserSendCheckEmail>  => {
    const {
    technologyId,
} = params;
    const url = this.$basePath + `/i3060/technical/workbench/updateBattery/${technologyId}`;
    const p: any = {};
    ajax.check(params.technologyId, 'technologyId');
    p.data = data;
    return ajax.ajax({
      ...opt,
      method: 'POST',
      url,
      ...p
    });
  }
  /**
   * 
   * @summary 修改需量限制
   * @param params Paramsi3060TechnicalWorkbenchUpdateDemandTechnicalProposalIdPost
   * @param data: ParamsBodyi3060TechnicalWorkbenchUpdateDemandTechnicalProposalIdPost
   * @param opt ajax config
   * @returns models.I3060TechnicalWorkbenchUpdatePhotovoltaicTopologyTechnologyId
   */
  public i3060TechnicalWorkbenchUpdateDemandTechnicalProposalIdPost = (
    params: Paramsi3060TechnicalWorkbenchUpdateDemandTechnicalProposalIdPost,
    data: ParamsBodyi3060TechnicalWorkbenchUpdateDemandTechnicalProposalIdPost,opt?: ExtraFetchParams
  ) : AjaxPromise<models.I3060TechnicalWorkbenchUpdatePhotovoltaicTopologyTechnologyId>  => {
    const {
    technicalProposalId,
} = params;
    const url = this.$basePath + `/i3060/technical/workbench/updateDemand/${technicalProposalId}`;
    const p: any = {};
    ajax.check(params.technicalProposalId, 'technicalProposalId');
    p.data = data;
    return ajax.ajax({
      ...opt,
      method: 'POST',
      url,
      ...p
    });
  }
  /**
   * 
   * @summary 修改储能容量
   * @param params Paramsi3060TechnicalWorkbenchUpdateEnergyStorageCapacityTechnologyIdPost
   * @param data: ParamsBodyi3060TechnicalWorkbenchUpdateEnergyStorageCapacityTechnologyIdPost
   * @param opt ajax config
   * @returns models.AuthUserSendCheckEmail
   */
  public i3060TechnicalWorkbenchUpdateEnergyStorageCapacityTechnologyIdPost = (
    params: Paramsi3060TechnicalWorkbenchUpdateEnergyStorageCapacityTechnologyIdPost,
    data: ParamsBodyi3060TechnicalWorkbenchUpdateEnergyStorageCapacityTechnologyIdPost,opt?: ExtraFetchParams
  ) : AjaxPromise<models.AuthUserSendCheckEmail>  => {
    const {
    technologyId,
} = params;
    const url = this.$basePath + `/i3060/technical/workbench/updateEnergyStorageCapacity/${technologyId}`;
    const p: any = {};
    ajax.check(params.technologyId, 'technologyId');
    p.data = data;
    return ajax.ajax({
      ...opt,
      method: 'POST',
      url,
      ...p
    });
  }
  /**
   * 
   * @summary 修改储能系统效率属性
   * @param params Paramsi3060TechnicalWorkbenchUpdateEnergyStorageSystemEfficiencyTechnologyIdPost
   * @param data: ParamsBodyi3060TechnicalWorkbenchUpdateEnergyStorageSystemEfficiencyTechnologyIdPost
   * @param opt ajax config
   * @returns models.AuthUserSendCheckEmail
   */
  public i3060TechnicalWorkbenchUpdateEnergyStorageSystemEfficiencyTechnologyIdPost = (
    params: Paramsi3060TechnicalWorkbenchUpdateEnergyStorageSystemEfficiencyTechnologyIdPost,
    data: ParamsBodyi3060TechnicalWorkbenchUpdateEnergyStorageSystemEfficiencyTechnologyIdPost,opt?: ExtraFetchParams
  ) : AjaxPromise<models.AuthUserSendCheckEmail>  => {
    const {
    technologyId,
} = params;
    const url = this.$basePath + `/i3060/technical/workbench/updateEnergyStorageSystemEfficiency/${technologyId}`;
    const p: any = {};
    ajax.check(params.technologyId, 'technologyId');
    p.data = data;
    return ajax.ajax({
      ...opt,
      method: 'POST',
      url,
      ...p
    });
  }
  /**
   * 
   * @summary 修改储能拓扑
   * @param params Paramsi3060TechnicalWorkbenchUpdateEnergyStorageTopologyTechnologyIdPost
   * @param data: ParamsBodyi3060TechnicalWorkbenchUpdateEnergyStorageTopologyTechnologyIdPost
   * @param opt ajax config
   * @returns models.AuthUserSendCheckEmail
   */
  public i3060TechnicalWorkbenchUpdateEnergyStorageTopologyTechnologyIdPost = (
    params: Paramsi3060TechnicalWorkbenchUpdateEnergyStorageTopologyTechnologyIdPost,
    data: ParamsBodyi3060TechnicalWorkbenchUpdateEnergyStorageTopologyTechnologyIdPost,opt?: ExtraFetchParams
  ) : AjaxPromise<models.AuthUserSendCheckEmail>  => {
    const {
    technologyId,
} = params;
    const url = this.$basePath + `/i3060/technical/workbench/updateEnergyStorageTopology/${technologyId}`;
    const p: any = {};
    ajax.check(params.technologyId, 'technologyId');
    p.data = data;
    return ajax.ajax({
      ...opt,
      method: 'POST',
      url,
      ...p
    });
  }
  /**
   * 
   * @summary 修改逆变器
   * @param params Paramsi3060TechnicalWorkbenchUpdateInverterModuleTechnologyIdPost
   * @param data: ParamsBodyi3060TechnicalWorkbenchUpdateInverterModuleTechnologyIdPost
   * @param opt ajax config
   * @returns models.AuthUserSendCheckEmail
   */
  public i3060TechnicalWorkbenchUpdateInverterModuleTechnologyIdPost = (
    params: Paramsi3060TechnicalWorkbenchUpdateInverterModuleTechnologyIdPost,
    data: ParamsBodyi3060TechnicalWorkbenchUpdateInverterModuleTechnologyIdPost,opt?: ExtraFetchParams
  ) : AjaxPromise<models.AuthUserSendCheckEmail>  => {
    const {
    technologyId,
} = params;
    const url = this.$basePath + `/i3060/technical/workbench/updateInverterModule/${technologyId}`;
    const p: any = {};
    ajax.check(params.technologyId, 'technologyId');
    p.data = data;
    return ajax.ajax({
      ...opt,
      method: 'POST',
      url,
      ...p
    });
  }
  /**
   * 
   * @summary 设置光伏模组
   * @param params Paramsi3060TechnicalWorkbenchUpdatePhotovoltaicModuleTechnologyIdPost
   * @param data: ParamsBodyi3060TechnicalWorkbenchUpdatePhotovoltaicModuleTechnologyIdPost
   * @param opt ajax config
   * @returns models.I3060TechnicalWorkbenchUpdatePhotovoltaicTopologyTechnologyId
   */
  public i3060TechnicalWorkbenchUpdatePhotovoltaicModuleTechnologyIdPost = (
    params: Paramsi3060TechnicalWorkbenchUpdatePhotovoltaicModuleTechnologyIdPost,
    data: ParamsBodyi3060TechnicalWorkbenchUpdatePhotovoltaicModuleTechnologyIdPost,opt?: ExtraFetchParams
  ) : AjaxPromise<models.I3060TechnicalWorkbenchUpdatePhotovoltaicTopologyTechnologyId>  => {
    const {
    technologyId,
} = params;
    const url = this.$basePath + `/i3060/technical/workbench/updatePhotovoltaicModule/${technologyId}`;
    const p: any = {};
    ajax.check(params.technologyId, 'technologyId');
    p.data = data;
    return ajax.ajax({
      ...opt,
      method: 'POST',
      url,
      ...p
    });
  }
  /**
   * 
   * @summary 更新光伏设置-其他信息-首年功率衰减率/逐年功率衰减率
   * @param params Paramsi3060TechnicalWorkbenchUpdatePhotovoltaicOtherInfoTechnicalProposalIdPost
   * @param data: ParamsBodyi3060TechnicalWorkbenchUpdatePhotovoltaicOtherInfoTechnicalProposalIdPost
   * @param opt ajax config
   * @returns models.AuthUserSendCheckEmail
   */
  public i3060TechnicalWorkbenchUpdatePhotovoltaicOtherInfoTechnicalProposalIdPost = (
    params: Paramsi3060TechnicalWorkbenchUpdatePhotovoltaicOtherInfoTechnicalProposalIdPost,
    data: ParamsBodyi3060TechnicalWorkbenchUpdatePhotovoltaicOtherInfoTechnicalProposalIdPost,opt?: { headers: ParamsHeaderi3060TechnicalWorkbenchUpdatePhotovoltaicOtherInfoTechnicalProposalIdPost } & Omit<ExtraFetchParams, 'headers'> 
  ) : AjaxPromise<models.AuthUserSendCheckEmail>  => {
    const {
    technicalProposalId,
} = params;
    const url = this.$basePath + `/i3060/technical/workbench/updatePhotovoltaicOtherInfo/${technicalProposalId}`;
    const p: any = {};
    ajax.check(params.technicalProposalId, 'technicalProposalId');
    p.data = data;
    return ajax.ajax({
      ...opt,
      method: 'POST',
      url,
      ...p
    });
  }
  /**
   * 
   * @summary 修改光伏系统参数
   * @param params Paramsi3060TechnicalWorkbenchUpdatePhotovoltaicSystemEfficiencyTechnologyIdPost
   * @param data: ParamsBodyi3060TechnicalWorkbenchUpdatePhotovoltaicSystemEfficiencyTechnologyIdPost
   * @param opt ajax config
   * @returns models.AuthUserSendCheckEmail
   */
  public i3060TechnicalWorkbenchUpdatePhotovoltaicSystemEfficiencyTechnologyIdPost = (
    params: Paramsi3060TechnicalWorkbenchUpdatePhotovoltaicSystemEfficiencyTechnologyIdPost,
    data: ParamsBodyi3060TechnicalWorkbenchUpdatePhotovoltaicSystemEfficiencyTechnologyIdPost,opt?: ExtraFetchParams
  ) : AjaxPromise<models.AuthUserSendCheckEmail>  => {
    const {
    technologyId,
} = params;
    const url = this.$basePath + `/i3060/technical/workbench/updatePhotovoltaicSystemEfficiency/${technologyId}`;
    const p: any = {};
    ajax.check(params.technologyId, 'technologyId');
    p.data = data;
    return ajax.ajax({
      ...opt,
      method: 'POST',
      url,
      ...p
    });
  }
  /**
   * 添加自定义电价json  ``` json {   \"photovoltaicToLoadReference\": {     \"consultPriceType\": \"1608\",     \"adjustCoefficient\": \"98.02\",     \"unitValuePrice\": null   },   \"photovoltaicToUserToPowerGridReference\": {     \"consultPriceType\": \"-1\",     \"adjustCoefficient\": \"9999999\",     \"unitValuePrice\": \"0.606\"   },   \"photovoltaicToStoredEnergyReference\": {     \"consultPriceType\": \"1609\",     \"adjustCoefficient\": \"81.09\",     \"unitValuePrice\": null   },   \"powerGridToUserToStoredEnergyReference\": {     \"consultPriceType\": \"-1\",     \"adjustCoefficient\": \"99.01\",     \"unitValuePrice\": \"0.888\"   },   \"storedEnergyToLoadReference\": {     \"consultPriceType\": \"-2\",     \"adjustCoefficient\": \"80.1\",     \"unitValuePrice\": null   },   \"powerGridToUserToLoadReference\": {     \"consultPriceType\": \"-3\",     \"adjustCoefficient\": null,     \"unitValuePrice\": \"0.505\"   } } ```
   * @summary 修改光伏拓扑数据
   * @param params Paramsi3060TechnicalWorkbenchUpdatePhotovoltaicTopologyTechnologyIdPost
   * @param data: ParamsBodyi3060TechnicalWorkbenchUpdatePhotovoltaicTopologyTechnologyIdPost
   * @param opt ajax config
   * @returns models.I3060TechnicalWorkbenchUpdatePhotovoltaicTopologyTechnologyId
   */
  public i3060TechnicalWorkbenchUpdatePhotovoltaicTopologyTechnologyIdPost = (
    params: Paramsi3060TechnicalWorkbenchUpdatePhotovoltaicTopologyTechnologyIdPost,
    data: ParamsBodyi3060TechnicalWorkbenchUpdatePhotovoltaicTopologyTechnologyIdPost,opt?: { headers: ParamsHeaderi3060TechnicalWorkbenchUpdatePhotovoltaicTopologyTechnologyIdPost } & Omit<ExtraFetchParams, 'headers'> 
  ) : AjaxPromise<models.I3060TechnicalWorkbenchUpdatePhotovoltaicTopologyTechnologyId>  => {
    const {
    technologyId,
} = params;
    const url = this.$basePath + `/i3060/technical/workbench/updatePhotovoltaicTopology/${technologyId}`;
    const p: any = {};
    ajax.check(params.technologyId, 'technologyId');
    p.data = data;
    return ajax.ajax({
      ...opt,
      method: 'POST',
      url,
      ...p
    });
  }
  /**
   * 
   * @summary 修改储能运行策略
   * @param params Paramsi3060TechnicalWorkbenchUpdateRevenueSharingTechnologyIdPost
   * @param data: ParamsBodyi3060TechnicalWorkbenchUpdateRevenueSharingTechnologyIdPost
   * @param opt ajax config
   * @returns models.AuthUserSendCheckEmail
   */
  public i3060TechnicalWorkbenchUpdateRevenueSharingTechnologyIdPost = (
    params: Paramsi3060TechnicalWorkbenchUpdateRevenueSharingTechnologyIdPost,
    data: ParamsBodyi3060TechnicalWorkbenchUpdateRevenueSharingTechnologyIdPost,opt?: ExtraFetchParams
  ) : AjaxPromise<models.AuthUserSendCheckEmail>  => {
    const {
    technologyId,
} = params;
    const url = this.$basePath + `/i3060/technical/workbench/updateRevenueSharing/${technologyId}`;
    const p: any = {};
    ajax.check(params.technologyId, 'technologyId');
    p.data = data;
    return ajax.ajax({
      ...opt,
      method: 'POST',
      url,
      ...p
    });
  }
  /**
   * 
   * @summary 修改地图相关配置
   * @param params Paramsi3060TechnicalWorkbenchUpdateRoofModuleTechnologyIdPost
   * @param data: ParamsBodyi3060TechnicalWorkbenchUpdateRoofModuleTechnologyIdPost
   * @param opt ajax config
   * @returns models.AuthUserSendCheckEmail
   */
  public i3060TechnicalWorkbenchUpdateRoofModuleTechnologyIdPost = (
    params: Paramsi3060TechnicalWorkbenchUpdateRoofModuleTechnologyIdPost,
    data: ParamsBodyi3060TechnicalWorkbenchUpdateRoofModuleTechnologyIdPost,opt?: ExtraFetchParams
  ) : AjaxPromise<models.AuthUserSendCheckEmail>  => {
    const {
    technologyId,
} = params;
    const url = this.$basePath + `/i3060/technical/workbench/updateRoofModule/${technologyId}`;
    const p: any = {};
    ajax.check(params.technologyId, 'technologyId');
    p.data = data;
    return ajax.ajax({
      ...opt,
      method: 'POST',
      url,
      ...p
    });
  }
  /**
   * 
   * @summary 修改技术方案名称
   * @param params Paramsi3060TechnicalWorkbenchUpdateTechnologyNameTechnologyIdPost
   * @param data: ParamsBodyi3060TechnicalWorkbenchUpdateTechnologyNameTechnologyIdPost
   * @param opt ajax config
   * @returns models.AuthUserSendCheckEmail
   */
  public i3060TechnicalWorkbenchUpdateTechnologyNameTechnologyIdPost = (
    params: Paramsi3060TechnicalWorkbenchUpdateTechnologyNameTechnologyIdPost,
    data: ParamsBodyi3060TechnicalWorkbenchUpdateTechnologyNameTechnologyIdPost,opt?: ExtraFetchParams
  ) : AjaxPromise<models.AuthUserSendCheckEmail>  => {
    const {
    technologyId,
} = params;
    const url = this.$basePath + `/i3060/technical/workbench/updateTechnologyName/${technologyId}`;
    const p: any = {};
    ajax.check(params.technologyId, 'technologyId');
    p.data = data;
    return ajax.ajax({
      ...opt,
      method: 'POST',
      url,
      ...p
    });
  }
  /**
   * 
   * @summary 获取电池列表
   
   
   * @param opt ajax config
   * @returns models.I3060WorkbenchEnergyStorageBatteryList
   */
  public i3060WorkbenchEnergyStorageBatteryListGet = (
    
    opt?: ExtraFetchParams
  ) : AjaxPromise<models.I3060WorkbenchEnergyStorageBatteryList>  => {
    const url = this.$basePath + `/i3060/workbench/energyStorage/batteryList`;
    const p: any = {};
    return ajax.ajax({
      ...opt,
      method: 'GET',
      url,
      ...p
    });
  }
  /**
   * 
   * @summary 获取逆变器列表
   * @param params Paramsi3060WorkbenchPhotovoltaicInverterListGet
   
   * @param opt ajax config
   * @returns models.I3060WorkbenchPhotovoltaicInverterList
   */
  public i3060WorkbenchPhotovoltaicInverterListGet = (
    params: Paramsi3060WorkbenchPhotovoltaicInverterListGet,
    opt?: ExtraFetchParams
  ) : AjaxPromise<models.I3060WorkbenchPhotovoltaicInverterList>  => {
    const url = this.$basePath + `/i3060/workbench/photovoltaic/inverterList`;
    const p: any = {};
    p.query = {};
    if ('inverterName' in params) p.query.inverterName = params.inverterName;
    ajax.check(params.inverterName, 'inverterName');
    if ('manufacturer' in params) p.query.manufacturer = params.manufacturer;
    ajax.check(params.manufacturer, 'manufacturer');
    if ('maxDcPowerMin' in params) p.query.maxDcPowerMin = params.maxDcPowerMin;
    ajax.check(params.maxDcPowerMin, 'maxDcPowerMin');
    if ('maxDcPowerMax' in params) p.query.maxDcPowerMax = params.maxDcPowerMax;
    ajax.check(params.maxDcPowerMax, 'maxDcPowerMax');
    if ('maxAcPowerMin' in params) p.query.maxAcPowerMin = params.maxAcPowerMin;
    ajax.check(params.maxAcPowerMin, 'maxAcPowerMin');
    if ('maxAcPowerMax' in params) p.query.maxAcPowerMax = params.maxAcPowerMax;
    ajax.check(params.maxAcPowerMax, 'maxAcPowerMax');
    if ('page' in params) p.query.page = params.page;
    ajax.check(params.page, 'page');
    if ('size' in params) p.query.size = params.size;
    ajax.check(params.size, 'size');
    return ajax.ajax({
      ...opt,
      method: 'GET',
      url,
      ...p
    });
  }
  /**
   * 
   * @summary 获取光伏模组列表
   * @param params Paramsi3060WorkbenchPhotovoltaicModelListGet
   
   * @param opt ajax config
   * @returns models.I3060WorkbenchPhotovoltaicModelList
   */
  public i3060WorkbenchPhotovoltaicModelListGet = (
    params: Paramsi3060WorkbenchPhotovoltaicModelListGet,
    opt?: ExtraFetchParams
  ) : AjaxPromise<models.I3060WorkbenchPhotovoltaicModelList>  => {
    const url = this.$basePath + `/i3060/workbench/photovoltaic/modelList`;
    const p: any = {};
    p.query = {};
    if ('pvModuleName' in params) p.query.pvModuleName = params.pvModuleName;
    ajax.check(params.pvModuleName, 'pvModuleName');
    if ('manufacturer' in params) p.query.manufacturer = params.manufacturer;
    ajax.check(params.manufacturer, 'manufacturer');
    if ('cellType' in params) p.query.cellType = params.cellType;
    ajax.check(params.cellType, 'cellType');
    if ('stcMin' in params) p.query.stcMin = params.stcMin;
    ajax.check(params.stcMin, 'stcMin');
    if ('stcMax' in params) p.query.stcMax = params.stcMax;
    ajax.check(params.stcMax, 'stcMax');
    if ('ptcMin' in params) p.query.ptcMin = params.ptcMin;
    ajax.check(params.ptcMin, 'ptcMin');
    if ('ptcMax' in params) p.query.ptcMax = params.ptcMax;
    ajax.check(params.ptcMax, 'ptcMax');
    if ('bifacial' in params) p.query.bifacial = params.bifacial;
    ajax.check(params.bifacial, 'bifacial');
    if ('openVoltageMin' in params) p.query.openVoltageMin = params.openVoltageMin;
    ajax.check(params.openVoltageMin, 'openVoltageMin');
    if ('openVoltageMax' in params) p.query.openVoltageMax = params.openVoltageMax;
    ajax.check(params.openVoltageMax, 'openVoltageMax');
    if ('page' in params) p.query.page = params.page;
    ajax.check(params.page, 'page');
    if ('size' in params) p.query.size = params.size;
    ajax.check(params.size, 'size');
    return ajax.ajax({
      ...opt,
      method: 'GET',
      url,
      ...p
    });
  }
}

export default new ProjectWorkbenchApi();