import React, { useEffect, useRef, useState, useCallback, useMemo } from 'react'
import moment from 'moment'
import { Button, DatePicker, Input, Select, Space } from 'antd'
import type { ColumnType } from 'antd/lib/table'
import { CustomIcons } from '../../global'
import AddressSelector from '../wl-address'

interface IParams {
  type?: 'input' | 'select' | 'address_selector' | 'RangePicker'
  format?: string
  options?: { key?: string; label?: string; id?: string; fullName?: string }[]
  placeholder?: string
  value: any
  onSearch: (changedValue: string) => void
}

interface IProps extends IParams {
  visible: boolean
  clearFilters?: () => void
  confirm?: Function
}

const ColumnFilter: React.FC<IProps> = ({
  format,
  options,
  placeholder,
  type,
  value,
  visible,
  confirm,
  clearFilters,
  onSearch,
}) => {
  const searchInput = useRef<any>(null)
  const [searchText, setSearchText] = useState<any>('')

  useEffect(() => {
    if (visible) {
      setTimeout(() => searchInput.current?.select(), 100)
    }
  }, [visible])

  useEffect(() => {
    if (type === 'RangePicker') {
      let [start, end] = value ?? []
      start = moment.isMoment(start) ? start : start ? moment(start) : null
      end = moment.isMoment(end) ? end : end ? moment(end) : null
      setSearchText([start, end])
    } else {
      setSearchText(value)
    }
  }, [value, type])

  const handleChange = useCallback((e: any) => {
    let value = e
    if (e?.target) {
      value = e.target.value
    }
    setSearchText(value)
  }, [])

  const handleReset = useCallback(() => {
    clearFilters?.()
    setSearchText(undefined)
  }, [])

  const handleSearch = useCallback(() => {
    // confirm({ closeDropdown: false })
    let value = searchText
    if (type === 'RangePicker') {
      value = value?.map((v: any) => (v ? v.format(format ?? 'YYYY-MM-DD') : '')) ?? []
    }
    onSearch(value)
    confirm?.()
  }, [searchText, type])

  const inputEl = useMemo(() => {
    switch (type) {
      case 'address_selector':
        return <AddressSelector value={searchText} onChange={handleChange} />

      case 'RangePicker':
        return <DatePicker.RangePicker value={searchText} onChange={handleChange} />

      case 'select':
        return (
          <Select
            placeholder={placeholder}
            allowClear
            style={{ width: 240 }}
            value={searchText?.toString()}
            onChange={handleChange}
          >
            {options?.map((item: any) => {
              const id = (item.key ?? item.id)?.toString()
              return (
                <Select.Option key={id} value={id}>
                  {item.label ?? item.fullName}
                </Select.Option>
              )
            })}
          </Select>
        )

      default:
        return (
          <Input
            ref={searchInput}
            placeholder={placeholder}
            allowClear
            value={searchText}
            onChange={handleChange}
            onPressEnter={handleSearch}
          />
        )
    }
  }, [searchText])

  return (
    <div style={{ padding: 8 }}>
      {inputEl}
      <Space align="center" style={{ marginTop: 16, display: 'flex', justifyContent: 'flex-end' }}>
        <Button onClick={handleReset}>重置</Button>
        <Button type="primary" onClick={handleSearch}>
          确定
        </Button>
      </Space>
    </div>
  )
}

export const getColumnSearchProps = (params: IParams): ColumnType<any> => {
  return {
    filterDropdown: ({ confirm, clearFilters, visible }) => {
      if (!visible) {
        ;(window as any).closeDropdown = null
        return null
      }
      setTimeout(() => {
        ;(window as any).closeDropdown = confirm
      })

      return (
        <ColumnFilter
          {...params}
          type={params.type ?? 'input'}
          visible={visible}
          clearFilters={clearFilters}
          confirm={confirm}
        />
      )
    },
    filterIcon: (filtered: boolean) => (
      <CustomIcons type="klf-search" style={{ color: filtered ? '#1ec692' : '#bfbfbf' }} />
    ),
    // filterIcon: (filtered: boolean) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
  }
}

export default getColumnSearchProps
