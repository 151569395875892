import { Table, Form, Input, Button, Space, Tooltip, Popover, Image, Modal, Menu, message } from 'antd'
import React, { useEffect, useState } from 'react'

import styles from '../index.module.scss'
import { FinanceService } from '../../../../../apis/project-service-history'
import { useParams } from 'react-router-dom'
import { FormInstance } from 'antd/lib/form/hooks/useForm'
import { BreadcrumbObservable, SideMenuObservable } from '../../../../../application'
import { CustomIcons, getFixedNumber } from '../../../../../global'
import { LinkOutlined, LoadingOutlined } from '@ant-design/icons'
import { NAME_EXP } from '../../../../../utils/constant'
import { RenderReportDialog } from '../../render-report-dialog'
import axios from 'axios'
import { token } from '../../../../../utils/utils'

interface Iprops {
  item?: any
  timeSort: string[]
  hasAuth?: boolean
  create?: () => void
  scrollHeight?: string
  onChange?: () => void
  cannotCalc?: boolean
}

const formatRes = (res?: string | number, unit?: string, show: boolean = false) => {
  if (!show) {
    return ''
  }
  if (res || res === 0) {
    return (
      <span
        style={{
          whiteSpace: 'nowrap',
        }}
      >
        {`${getFixedNumber(parseFloat(res.toString()), 2)} ${unit}`}
      </span>
    )
  } else {
    return '--'
  }
}
const layout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 18 },
}

let query: any = {
  page: 1,
  total: 0,
  size: 500,
  markedSort: '0',
  lockedSort: '0',
  createTimeSort: '0',
  updateTimeSort: '0',
  innerYieldRateMin: undefined,
  innerYieldRateMax: undefined,
  innerYieldRateChange: false,
  netPresentValueMin: undefined,
  netPresentValueMax: undefined,
  netPresentValueChange: false,
  returnInvestmentMin: undefined,
  returnInvestmentMax: undefined,
  returnInvestmentChange: false,
  staticInvestmentPaybackPeriodMin: undefined,
  staticInvestmentPaybackPeriodMax: undefined,
  staticInvestmentPaybackPeriodChange: false,
  initialInvestmentMin: undefined,
  initialInvestmentMax: undefined,
  initialInvestmentChange: false,
  totalRevenueMin: undefined,
  totalRevenueMax: undefined,
  totalRevenueChange: false,
  cumulativeCashFlowMin: undefined,
  cumulativeCashFlowMax: undefined,
  cumulativeCashFlowChange: false,
  netProfitMarginOfCapitalMin: undefined,
  netProfitMarginOfCapitalMax: undefined,
  netProfitMarginOfCapitalSort: '0',
  innerYieldRateSort: '0',
  netPresentValueSort: '0',
  returnInvestmentSort: '0',
  staticInvestmentPaybackPeriodSort: '0',
  initialInvestmentSort: '0',
  totalRevenueSort: '0',
  cumulativeCashFlowSort: '0',
}

const hasQuery = () => {
  const {
    innerYieldRateMin,
    innerYieldRateMax,
    netPresentValueMin,
    netPresentValueMax,
    returnInvestmentMin,
    returnInvestmentMax,
    staticInvestmentPaybackPeriodMin,
    staticInvestmentPaybackPeriodMax,
    initialInvestmentMin,
    initialInvestmentMax,
    totalRevenueMin,
    totalRevenueMax,
    cumulativeCashFlowMin,
    cumulativeCashFlowMax,
    netProfitMarginOfCapitalMin,
    netProfitMarginOfCapitalMax,
  } = query

  const obj = {
    innerYieldRateMin,
    innerYieldRateMax,
    netPresentValueMin,
    netPresentValueMax,
    returnInvestmentMin,
    returnInvestmentMax,
    staticInvestmentPaybackPeriodMin,
    staticInvestmentPaybackPeriodMax,
    initialInvestmentMin,
    initialInvestmentMax,
    totalRevenueMin,
    totalRevenueMax,
    cumulativeCashFlowMin,
    cumulativeCashFlowMax,
    netProfitMarginOfCapitalMin,
    netProfitMarginOfCapitalMax,
  }

  return Object.values(obj).filter((o) => o !== undefined).length > 0
}

const queryArr: number[] = []
let queryInterval: any

// 财务方案列表
const FinanceList = (props: Iprops) => {
  const { hasAuth } = props
  const params = useParams()

  const [dataSource, setDataSource] = useState<any[]>([])

  const [firstLoading, setFirstLoading] = useState(true)
  const [loading, setLoading] = useState(false)

  const [innerYieldForm] = Form.useForm()
  const [netPresentdForm] = Form.useForm()
  const [returnInvestmentForm] = Form.useForm()
  const [staticInvestmentPaybackPeriodForm] = Form.useForm()
  const [netProfitMarginOfCapitalForm] = Form.useForm()
  const [initialInvestmentForm] = Form.useForm()
  const [totalRevenueForm] = Form.useForm()
  const [cumulativeCashFlowForm] = Form.useForm()

  useEffect(() => {
    query = {
      page: 1,
      total: 0,
      size: 500,
      markedSort: '0',
      lockedSort: '0',
      createTimeSort: '0',
      updateTimeSort: '0',
      innerYieldRateMin: undefined,
      innerYieldRateMax: undefined,
      innerYieldRateChange: false,
      netPresentValueMin: undefined,
      netPresentValueMax: undefined,
      netPresentValueChange: false,
      returnInvestmentMin: undefined,
      returnInvestmentMax: undefined,
      returnInvestmentChange: false,
      staticInvestmentPaybackPeriodMin: undefined,
      staticInvestmentPaybackPeriodMax: undefined,
      staticInvestmentPaybackPeriodChange: false,
      initialInvestmentMin: undefined,
      initialInvestmentMax: undefined,
      initialInvestmentChange: false,
      totalRevenueMin: undefined,
      totalRevenueMax: undefined,
      totalRevenueChange: false,
      cumulativeCashFlowMin: undefined,
      cumulativeCashFlowMax: undefined,
      cumulativeCashFlowChange: false,
      netProfitMarginOfCapitalMin: undefined,
      netProfitMarginOfCapitalMax: undefined,
      netProfitMarginOfCapitalSort: '0',
      innerYieldRateSort: '0',
      netPresentValueSort: '0',
      returnInvestmentSort: '0',
      staticInvestmentPaybackPeriodSort: '0',
      initialInvestmentSort: '0',
      totalRevenueSort: '0',
      cumulativeCashFlowSort: '0',
    }

    if (!queryInterval) {
      queryInterval = setInterval(() => {
        if (queryArr.length > 0) {
          queryArr.length = 0
          queryList(true)
        }
      }, 5000)
    }
    return () => {
      clearInterval(queryInterval)
      queryInterval = undefined
      queryArr.length = 0
    }
  }, [])

  useEffect(() => {
    if (dataSource) {
      queryArr.push(...dataSource.filter((o) => o.calcStatus === 1 || o.wordStatus === 1).map((o) => o.id))
    }
  }, [dataSource])

  const dropdownArr: {
    [key: string]: {
      name: string
      form: FormInstance
      unit: string
    }
  } = {
    innerYieldRate: {
      name: '资本内部收益率',
      form: innerYieldForm,
      unit: '%',
    },
    netPresentValue: {
      name: '净现值',
      form: netPresentdForm,
      unit: '万元',
    },
    returnInvestment: {
      name: '投资总收益率',
      form: returnInvestmentForm,
      unit: '%',
    },
    netProfitMarginOfCapital: {
      name: '资本金净利润率',
      form: netProfitMarginOfCapitalForm,
      unit: '%',
    },
    staticInvestmentPaybackPeriod: {
      name: '静态投资回收期',
      form: staticInvestmentPaybackPeriodForm,
      unit: '年',
    },
    initialInvestment: {
      name: '初始投资',
      form: initialInvestmentForm,
      unit: '万元',
    },
    totalRevenue: {
      name: '总收益',
      form: totalRevenueForm,
      unit: '万元',
    },
    cumulativeCashFlow: {
      name: '累计现金流',
      form: cumulativeCashFlowForm,
      unit: '万元',
    },
  }

  const placeFileItem = (id: string, is_placefile: boolean) => {
    FinanceService.lock({
      financeId: id,
      root: {
        type: is_placefile ? '1' : '0',
      },
    }).then(() => {
      queryList()
    })
  }

  const createRangeFilter = (confirm: any, key: string) => {
    return (
      <div className={styles.customFilter}>
        <Form
          onFinish={(values) => {
            query[`${key}Change`] = true
            energyFilter(values, confirm)
          }}
          {...layout}
          colon={false}
          form={dropdownArr[key].form}
        >
          <Form.Item
            label={dropdownArr[key].name}
            style={{
              alignItems: 'center',
            }}
          >
            <Input.Group compact>
              <Form.Item noStyle name={`${key}Min`}>
                <Input type="number" style={{ width: 150 }} placeholder="最小值" />
              </Form.Item>
              <Input
                className="site-input-split"
                style={{
                  width: 30,
                  borderLeft: 0,
                  borderRight: 0,
                  pointerEvents: 'none',
                }}
                placeholder="-"
                disabled
              />
              <Form.Item noStyle name={`${key}Max`}>
                <Input type="number" style={{ width: 180 }} placeholder="最大值" suffix={dropdownArr[key].unit} />
              </Form.Item>
            </Input.Group>
          </Form.Item>
          <Form.Item label=" " className="filter-btns">
            <Space>
              <Button
                onClick={() => {
                  dropdownArr[key].form.setFieldsValue({
                    [`${key}Min`]: undefined,
                    [`${key}Max`]: undefined,
                  })
                }}
              >
                重置
              </Button>
              <Button type="primary" htmlType="submit">
                确定
              </Button>
            </Space>
          </Form.Item>
        </Form>
      </div>
    )
  }

  useEffect(() => {
    query.createTimeSort = '0'
    query.updateTimeSort = '0'
    query.markedSort = '0'
    query.lockedSort = '0'

    if (props.timeSort.length === 2) {
      query[props.timeSort[0]] = props.timeSort[1]
    } else if (props.timeSort.length === 1) {
      if (props.timeSort[0] === 'mark') {
        query.markedSort = '2'
      } else if (props.timeSort[0] === 'lock') {
        query.lockedSort = '2'
      }
    }
    queryList()
  }, [props.timeSort])

  const energyFilter = (values: any, confirm: Function) => {
    confirm()
  }

  // 列表操作列
  const listOperaColumn = (text: string, row: any) => {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
        }}
        className={styles.operaBtns}
        key={`ope-${row.id}`}
      >
        <Popover
          overlayClassName="menu-popover-overlay"
          content={
            <Menu
              mode="inline"
              style={{
                borderRight: 'none',
                padding: '.5rem',
                paddingBottom: '.5rem',
                width: '8rem',
              }}
              selectable={false}
            >
              <Menu.Item
                disabled={row.calcStatus !== 2 || !hasAuth}
                style={{
                  height: 30,
                  width: '100%',
                }}
                onClick={() => {
                  outputReport(row)
                }}
              >
                下载财务结果
              </Menu.Item>
              <Menu.Item
                disabled={row.wordStatus === 1 || !row.locked || !hasAuth}
                style={{
                  height: 30,
                  width: '100%',
                }}
                onClick={() => {
                  showReport(row)
                }}
              >
                {row.wordStatus === 2 ? '查看报告' : '生成报告'}
              </Menu.Item>
            </Menu>
          }
        >
          <Button type="link" icon={<CustomIcons type="klf-more" />} />
        </Popover>
        {row.marked === true ? (
          <Tooltip title="取消星标">
            <Button
              onClick={() => {
                collectionItem(row.id, false)
              }}
              type="link"
              icon={<CustomIcons type="klf-mark" />}
            />
          </Tooltip>
        ) : (
          <Tooltip title="设为星标">
            <Button
              onClick={() => {
                collectionItem(row.id, true)
              }}
              type="link"
              icon={<CustomIcons type="klf-non-mark" />}
            />
          </Tooltip>
        )}
        {row.calcStatus === 2 && (
          <Tooltip title={row.locked ? '解除归档' : '归档'}>
            <Button
              type="link"
              disabled={!hasAuth}
              onClick={() => {
                if (!row.locked) {
                  Modal.confirm({
                    keyboard: false,
                    title: '归档',
                    icon: <CustomIcons type="klf-alarm" />,
                    content: '归档该方案后将不能对该方案进行编辑，确认归档么？',
                    onOk: () => {
                      placeFileItem(row.id, !row.locked)
                    },
                    cancelButtonProps: {
                      type: 'primary',
                      ghost: true,
                    },
                  })
                } else {
                  placeFileItem(row.id, !row.locked)
                }
              }}
              icon={<CustomIcons type={row.locked ? 'klf-file' : 'klf-file-line'} />}
            />
          </Tooltip>
        )}
        {(row.calcStatus === 0 || row.calcStatus === 3 || row.calcStatus === 4) && (
          <Tooltip title={row.calcStatus === 0 ? '一键计算' : '重新计算'}>
            <Button
              type="link"
              disabled={!hasAuth}
              onClick={() => {
                if (props.cannotCalc) {
                  Modal.confirm({
                    keyboard: false,
                    title: '提示',
                    icon: <CustomIcons type="klf-alarm1" />,
                    content: `请先设置时间范围与精度`,
                    cancelButtonProps: {
                      style: {
                        display: 'none',
                      },
                    },
                  })
                } else {
                  if (row.calcStatus === 4) {
                    Modal.confirm({
                      keyboard: false,
                      title: '重新计算',
                      icon: <CustomIcons type="klf-alarm1" />,
                      content: `确认重新计算${row.name}？原计算结果将覆盖`,
                      onOk: () => {
                        refreshItem(row)
                      },
                      cancelButtonProps: {
                        type: 'primary',
                        ghost: true,
                      },
                    })
                  } else {
                    refreshItem(row)
                  }
                }
              }}
              icon={<CustomIcons type="klf-recalculate1" />}
            />
          </Tooltip>
        )}
      </div>
    )
  }

  // 过滤  排序
  const changeTable = (pager: any, filter: any, sorter: any) => {
    const { order, field } = sorter
    let ope = '0'
    if (order === 'ascend') {
      ope = '1'
    }
    if (order === 'descend') {
      ope = '2'
    }

    Object.keys(query).forEach((key) => {
      if (key.includes('Sort')) {
        query[key] = '0'
      }
    })

    query[`${field}Sort`] = ope

    queryList()
  }

  // 收藏/标星
  const collectionItem = (id: string, is_collection: boolean) => {
    FinanceService.mark({
      financeId: id,
      root: {
        type: is_collection ? '1' : '0',
      },
    }).then(() => {
      queryList()
    })
  }

  const [, setDownloading] = useState(false)
  const outputReport = (item: any) => {
    setDownloading(true)
    const id = item.id
    const prefix = axios.defaults.baseURL
    const url = `${prefix || ''}/api/i3060/finance/outputReport/${id}`
    const authToken: string = token.get() || ''
    fetch(url, {
      method: 'get',
      headers: {
        Authorization: authToken,
        'Content-Type': 'application/json;charset=UTF-8',
      },
    })
      .then((resp) => {
        let fileName =
          resp.headers.get('content-disposition')?.split(';')[1].split('filename=')[1] || `report-${Date.now()}.docx`
        console.log(fileName)
        fileName = decodeURIComponent(fileName)
        resp
          .blob()
          .then((blob) => {
            if ((window.navigator as any).msSaveOrOpenBlob) {
              ;(window.navigator as any).msSaveBlob(blob, fileName)
            } else {
              const a = document.createElement('a')
              document.body.appendChild(a)
              const url = window.URL.createObjectURL(blob)
              a.href = url
              a.download = fileName
              a.target = '_blank'
              a.click()
              a.remove()
              window.URL.revokeObjectURL(url)
            }
          })
          .finally(() => {
            setDownloading(false)
          })
      })
      .catch(() => {
        setDownloading(false)
      })
  }
  // 查看报告
  const showReport = (item: any) => {
    if (item.wordStatus === 2) {
      FinanceService.getPdfTag({
        wordId: item.wordId,
      }).then((resp) => {
        const tag = resp.data
        window.open(`/api/i3060/financeWord/readPdf/${tag}`, '_blank')
      })
    } else {
      const dlg = Modal.confirm({
        title: '',
        icon: null,
        content: (
          <RenderReportDialog
            projectId={item.projectId}
            financeId={item.id}
            techId={item.technicalProposalId}
            uploadAll={true}
            callback={(fl: boolean) => {
              dlg.destroy()
              if (fl) {
                message.success('资源上传完成，报告生成中，请稍后查看...')
                queryList()
              }
            }}
          />
        ),
        cancelText: '取消',
        okText: '取消',
        okButtonProps: {
          style: { display: 'none' },
        },
        cancelButtonProps: {
          style: { display: 'none' },
        },
        width: 280,
      })
    }
  }

  // 刷新状态
  const refreshItem = (item: any) => {
    FinanceService.calculate({
      projectId: params.id!,
      root: {
        financeId: item.id,
      },
    })
      .then(() => {
        message.success('开始计算')
        queryList()
      })
      .catch(() => {})
  }

  // 编辑方案名称
  const editItemName = (item: any, index: number) => {
    const list = dataSource.slice()
    list[index] = {
      ...item,
      edit_name: true,
    }
    setDataSource(list)
  }

  const queryList = (ignoreLoading = false) => {
    if (!ignoreLoading) {
      setLoading(true)
    }
    const {
      page,
      size,
      createTimeSort,
      updateTimeSort,
      markedSort,
      lockedSort,
      marked,
      locked,
      innerYieldRateMin,
      innerYieldRateMax,
      netPresentValueMin,
      netPresentValueMax,
      returnInvestmentMin,
      returnInvestmentMax,
      staticInvestmentPaybackPeriodMin,
      staticInvestmentPaybackPeriodMax,
      initialInvestmentMin,
      initialInvestmentMax,
      totalRevenueMin,
      totalRevenueMax,
      cumulativeCashFlowMin,
      cumulativeCashFlowMax,
      innerYieldRateSort,
      netPresentValueSort,
      returnInvestmentSort,
      staticInvestmentPaybackPeriodSort,
      initialInvestmentSort,
      totalRevenueSort,
      cumulativeCashFlowSort,
      netProfitMarginOfCapitalMin,
      netProfitMarginOfCapitalMax,
      netProfitMarginOfCapitalSort,
    } = query
    FinanceService.list3({
      projectId: params.id!,
      page,
      size,
      markedSort,
      lockedSort,
      createTimeSort,
      updateTimeSort,
      marked,
      locked,
      innerYieldRateMin,
      innerYieldRateMax,
      netPresentValueMin,
      netPresentValueMax,
      returnInvestmentMin,
      returnInvestmentMax,
      staticInvestmentPaybackPeriodMin,
      staticInvestmentPaybackPeriodMax,
      initialInvestmentMin,
      initialInvestmentMax,
      totalRevenueMin,
      totalRevenueMax,
      cumulativeCashFlowMin,
      cumulativeCashFlowMax,
      innerYieldRateSort,
      netPresentValueSort,
      returnInvestmentSort,
      staticInvestmentPaybackPeriodSort,
      initialInvestmentSort,
      totalRevenueSort,
      cumulativeCashFlowSort,
      netProfitMarginOfCapitalMin,
      netProfitMarginOfCapitalMax,
      netProfitMarginOfCapitalSort,
    })
      .then((resp) => {
        query.total = Math.floor(Math.abs(resp.data.total))
        query.size = Math.floor(Math.abs(resp.data.size))
        query.page = Math.floor(Math.abs(resp.data.page))
        setDataSource(resp.data.data || [])
      })
      .catch(() => {})
      .finally(() => {
        if (!ignoreLoading) {
          setLoading(false)
        }
        setFirstLoading(false)
      })
  }

  const init = () => {
    queryList()
  }

  useEffect(() => {
    init()
    BreadcrumbObservable.next([
      {
        name: '项目管理',
        url: '/program/manage',
      },
      {
        name: 'asd',
        url: ``,
      },
    ])
    SideMenuObservable.next(true)
  }, [])

  const columns = [
    {
      title: '方案名称',
      dataIndex: 'name',
      key: 'name',
      width: 200,
      fixed: 'left' as any,
      render: (text: string, row: any, index: number) => {
        return (
          <Tooltip
            title={
              <div>
                {row.financeParamName}
                <LinkOutlined style={{ color: '#1EC692' }} />
                {row.technicalProposalName}
              </div>
            }
          >
            {row.edit_name ? (
              <Input
                id={`fin-name-${index}`}
                maxLength={16}
                defaultValue={text}
                allowClear
                onBlur={(dom) => {
                  if (!NAME_EXP.test(dom.target.value)) {
                    const dom = document.getElementById(`fin-name-${index}`)
                    message.error('"1-16个字符,中文、英文、_、数字和-组成"')
                    dom?.focus()
                    return
                  }
                  FinanceService.rename1({
                    financeId: row.id,
                    root: {
                      name: dom.target.value,
                    },
                  })
                    .then(() => {
                      queryList()
                    })
                    .catch(() => {})
                }}
              />
            ) : (
              <div className={`${styles.listName} hover-outer`}>
                <span className="name">{text}</span>
                <Button
                  className="hover-inner"
                  type="text"
                  icon={<CustomIcons type="klf-edit1" />}
                  onClick={() => editItemName(row, index)}
                />
              </div>
            )}
          </Tooltip>
        )
      },
    },
    {
      title: '资本金内部收益率',
      dataIndex: 'innerYieldRate',
      key: 'innerYieldRate',
      sorter: true,
      width: 180,
      render: (res: string, row: any) => {
        switch (row.calcStatus) {
          case 0:
            return '方案未计算'
          case 1:
            return (
              <span
                style={{
                  whiteSpace: 'nowrap',
                }}
              >
                方案计算中
                <LoadingOutlined
                  style={{
                    marginLeft: '1rem',
                  }}
                />
              </span>
            )
          case 2:
            return formatRes(res, '%', row.calcStatus === 2)
          case 3:
            return <span style={{ color: '#767F95' }}>计算失败</span>
          case 4:
            return <span style={{ color: '#FF574C' }}>结果失效</span>
        }
      },
      filterIcon: <CustomIcons type="klf-filter" />,
      filterDropdown: ({ confirm, visible }: any) => {
        if (!visible) {
          ;(window as any).closeDropdown = null
        } else {
          setTimeout(() => {
            ;(window as any).closeDropdown = confirm
          })
        }
        return createRangeFilter(confirm, 'innerYieldRate')
      },
      onFilterDropdownVisibleChange: (visible: boolean) => {
        const key = 'innerYieldRate'
        if (!visible) {
          if (query[`${key}Change`]) {
            query[`${key}Change`] = false
            query[`${key}Min`] = dropdownArr[key].form.getFieldsValue()[`${key}Min`]
            query[`${key}Max`] = dropdownArr[key].form.getFieldsValue()[`${key}Max`]
            queryList()
          }
        } else {
          dropdownArr[key].form.setFieldsValue({
            [`${key}Max`]: query[`${key}Max`],
            [`${key}Min`]: query[`${key}Min`],
          })
        }
      },
    },
    {
      title: '净现值',
      dataIndex: 'netPresentValue',
      key: 'netPresentValue',
      sorter: true,
      width: 100,
      render: (res: string, row: any) => {
        return formatRes(res, '万元', row.calcStatus === 2)
      },
      filterIcon: <CustomIcons type="klf-filter" />,
      filterDropdown: ({ confirm, visible }: any) => {
        if (!visible) {
          ;(window as any).closeDropdown = null
        } else {
          setTimeout(() => {
            ;(window as any).closeDropdown = confirm
          })
        }
        if (!visible) {
          ;(window as any).closeDropdown = null
        } else {
          setTimeout(() => {
            ;(window as any).closeDropdown = confirm
          })
        }
        return createRangeFilter(confirm, 'netPresentValue')
      },
      onFilterDropdownVisibleChange: (visible: boolean) => {
        const key = 'netPresentValue'
        if (!visible) {
          if (query[`${key}Change`]) {
            query[`${key}Change`] = false
            query[`${key}Min`] = dropdownArr[key].form.getFieldsValue()[`${key}Min`]
            query[`${key}Max`] = dropdownArr[key].form.getFieldsValue()[`${key}Max`]
            queryList()
          }
        } else {
          dropdownArr[key].form.setFieldsValue({
            [`${key}Max`]: query[`${key}Max`],
            [`${key}Min`]: query[`${key}Min`],
          })
        }
      },
    },
    {
      title: '投资总收益率',
      dataIndex: 'returnInvestment',
      key: 'returnInvestment',
      sorter: true,
      width: 150,
      render: (res: string, row: any) => {
        return formatRes(res, '%', row.calcStatus === 2)
      },
      filterIcon: <CustomIcons type="klf-filter" />,
      filterDropdown: ({ confirm, visible }: any) => {
        if (!visible) {
          ;(window as any).closeDropdown = null
        } else {
          setTimeout(() => {
            ;(window as any).closeDropdown = confirm
          })
        }
        return createRangeFilter(confirm, 'returnInvestment')
      },
      onFilterDropdownVisibleChange: (visible: boolean) => {
        const key = 'returnInvestment'
        if (!visible) {
          if (query[`${key}Change`]) {
            query[`${key}Change`] = false
            query[`${key}Min`] = dropdownArr[key].form.getFieldsValue()[`${key}Min`]
            query[`${key}Max`] = dropdownArr[key].form.getFieldsValue()[`${key}Max`]
            queryList()
          }
        } else {
          dropdownArr[key].form.setFieldsValue({
            [`${key}Max`]: query[`${key}Max`],
            [`${key}Min`]: query[`${key}Min`],
          })
        }
      },
    },
    {
      title: '资本金净利润率',
      dataIndex: 'netProfitMarginOfCapital',
      key: 'netProfitMarginOfCapital',
      sorter: true,
      width: 160,
      render: (res: string, row: any) => {
        return formatRes(res, '%', row.calcStatus === 2)
      },
      filterIcon: <CustomIcons type="klf-filter" />,
      filterDropdown: ({ confirm, visible }: any) => {
        if (!visible) {
          ;(window as any).closeDropdown = null
        } else {
          setTimeout(() => {
            ;(window as any).closeDropdown = confirm
          })
        }
        return createRangeFilter(confirm, 'netProfitMarginOfCapital')
      },
      onFilterDropdownVisibleChange: (visible: boolean) => {
        const key = 'netProfitMarginOfCapital'
        if (!visible) {
          if (query[`${key}Change`]) {
            query[`${key}Change`] = false
            query[`${key}Min`] = dropdownArr[key].form.getFieldsValue()[`${key}Min`]
            query[`${key}Max`] = dropdownArr[key].form.getFieldsValue()[`${key}Max`]
            queryList()
          }
        } else {
          dropdownArr[key].form.setFieldsValue({
            [`${key}Max`]: query[`${key}Max`],
            [`${key}Min`]: query[`${key}Min`],
          })
        }
      },
    },
    {
      title: '静态投资回收期',
      dataIndex: 'staticInvestmentPaybackPeriod',
      key: 'staticInvestmentPaybackPeriod',
      sorter: true,
      width: 160,
      render: (res: string, row: any) => {
        return formatRes(res, '年', row.calcStatus === 2)
      },
      filterIcon: <CustomIcons type="klf-filter" />,
      filterDropdown: ({ confirm, visible }: any) => {
        if (!visible) {
          ;(window as any).closeDropdown = null
        } else {
          setTimeout(() => {
            ;(window as any).closeDropdown = confirm
          })
        }
        return createRangeFilter(confirm, 'staticInvestmentPaybackPeriod')
      },
      onFilterDropdownVisibleChange: (visible: boolean) => {
        const key = 'staticInvestmentPaybackPeriod'
        if (!visible) {
          if (query[`${key}Change`]) {
            query[`${key}Change`] = false
            query[`${key}Min`] = dropdownArr[key].form.getFieldsValue()[`${key}Min`]
            query[`${key}Max`] = dropdownArr[key].form.getFieldsValue()[`${key}Max`]
            queryList()
          }
        } else {
          dropdownArr[key].form.setFieldsValue({
            [`${key}Max`]: query[`${key}Max`],
            [`${key}Min`]: query[`${key}Min`],
          })
        }
      },
    },
    {
      title: '初始投资',
      dataIndex: 'initialInvestment',
      key: 'initialInvestment',
      sorter: true,
      width: 100,
      render: (res: string, row: any) => {
        return formatRes(res, '万元', row.calcStatus === 2)
      },
      filterIcon: <CustomIcons type="klf-filter" />,
      filterDropdown: ({ confirm, visible }: any) => {
        if (!visible) {
          ;(window as any).closeDropdown = null
        } else {
          setTimeout(() => {
            ;(window as any).closeDropdown = confirm
          })
        }
        return createRangeFilter(confirm, 'initialInvestment')
      },
      onFilterDropdownVisibleChange: (visible: boolean) => {
        const key = 'initialInvestment'
        if (!visible) {
          if (query[`${key}Change`]) {
            query[`${key}Change`] = false
            query[`${key}Min`] = dropdownArr[key].form.getFieldsValue()[`${key}Min`]
            query[`${key}Max`] = dropdownArr[key].form.getFieldsValue()[`${key}Max`]
            queryList()
          }
        } else {
          dropdownArr[key].form.setFieldsValue({
            [`${key}Max`]: query[`${key}Max`],
            [`${key}Min`]: query[`${key}Min`],
          })
        }
      },
    },
    {
      title: '总收益',
      dataIndex: 'totalRevenue',
      key: 'totalRevenue',
      sorter: true,
      width: 100,
      render: (res: string, row: any) => {
        return formatRes(res, '万元', row.calcStatus === 2)
      },
      filterIcon: <CustomIcons type="klf-filter" />,
      filterDropdown: ({ confirm, visible }: any) => {
        if (!visible) {
          ;(window as any).closeDropdown = null
        } else {
          setTimeout(() => {
            ;(window as any).closeDropdown = confirm
          })
        }
        return createRangeFilter(confirm, 'totalRevenue')
      },
      onFilterDropdownVisibleChange: (visible: boolean) => {
        const key = 'totalRevenue'
        if (!visible) {
          if (query[`${key}Change`]) {
            query[`${key}Change`] = false
            query[`${key}Min`] = dropdownArr[key].form.getFieldsValue()[`${key}Min`]
            query[`${key}Max`] = dropdownArr[key].form.getFieldsValue()[`${key}Max`]
            queryList()
          }
        } else {
          dropdownArr[key].form.setFieldsValue({
            [`${key}Max`]: query[`${key}Max`],
            [`${key}Min`]: query[`${key}Min`],
          })
        }
      },
    },
    {
      title: '累计现金流',
      dataIndex: 'cumulativeCashFlow',
      key: 'cumulativeCashFlow',
      sorter: true,
      width: 140,
      render: (res: string, row: any) => {
        return formatRes(res, '万元', row.calcStatus === 2)
      },
      filterIcon: <CustomIcons type="klf-filter" />,
      filterDropdown: ({ confirm, visible }: any) => {
        if (!visible) {
          ;(window as any).closeDropdown = null
        } else {
          setTimeout(() => {
            ;(window as any).closeDropdown = confirm
          })
        }
        return createRangeFilter(confirm, 'cumulativeCashFlow')
      },
      onFilterDropdownVisibleChange: (visible: boolean) => {
        const key = 'cumulativeCashFlow'
        if (!visible) {
          if (query[`${key}Change`]) {
            query[`${key}Change`] = false
            query[`${key}Min`] = dropdownArr[key].form.getFieldsValue()[`${key}Min`]
            query[`${key}Max`] = dropdownArr[key].form.getFieldsValue()[`${key}Max`]
            queryList()
          }
        } else {
          dropdownArr[key].form.setFieldsValue({
            [`${key}Max`]: query[`${key}Max`],
            [`${key}Min`]: query[`${key}Min`],
          })
        }
      },
    },
    {
      title: '操作',
      dataIndex: '',
      key: '',
      width: 100,
      fixed: 'right' as any,
      render: listOperaColumn,
    },
  ]

  return (
    <div style={{ height: '100%', width: '100%' }}>
      {!hasQuery() && !firstLoading && dataSource.length === 0 && (
        <div
          style={{
            height: '100%',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <div
            style={{
              textAlign: 'center',
            }}
          >
            <div>
              <Image
                style={{
                  width: 340,
                  height: 340,
                }}
                src={'./assets/fin-empty.png'}
                alt=""
                preview={false}
              />
            </div>
            <Button
              type="primary"
              disabled={!hasAuth}
              style={{
                width: 200,
              }}
              onClick={() => {
                props.create?.()
              }}
            >
              开始财务测算
            </Button>
          </div>
        </div>
      )}
      {!firstLoading && (hasQuery() || dataSource.length > 0) && (
        <Table
          loading={loading}
          scroll={{ x: 'max-content', y: props.scrollHeight || 'calc(100vh - 400px)' }}
          rowKey="id"
          size="small"
          columns={columns}
          dataSource={dataSource}
          className={`${styles.technicalSolutionWrap} right-actions`}
          onChange={changeTable}
          pagination={false}
        />
      )}
    </div>
  )
}

export default FinanceList
