import CryptoJS from 'crypto-js'
import moment from 'moment'

/**
 * @word 要加密的内容
 * @keyWord String  服务器随机返回的关键字
 *  */
export function aesEncrypt(word: string, keyWord = 'XwKsGlMcdPMEhR1B') {
  var key = CryptoJS.enc.Utf8.parse(keyWord)
  var srcs = CryptoJS.enc.Utf8.parse(word)
  var encrypted = CryptoJS.AES.encrypt(srcs, key, { mode: CryptoJS.mode.ECB, padding: CryptoJS.pad.Pkcs7 })
  return encrypted.toString()
}

export function downloadFile(file: any, filename = '') {
  const blob = file.data

  const downloadElement = document.createElement('a')
  const href = window.URL.createObjectURL(blob)
  downloadElement.href = href
  downloadElement.download = filename
  document.body.appendChild(downloadElement)
  downloadElement.click()
  document.body.removeChild(downloadElement)
  window.URL.revokeObjectURL(href)
}

/**
 * @datetime 计算的时间
 *  */
export function relativeDateTime(datetime: string | number) {
  const target = moment(datetime)
  const now = moment()
  if (now.diff(target, 's') < 60) {
    return `${now.diff(target, 's')} 秒前`
  } else if (now.diff(target, 'm') < 60) {
    return `${now.diff(target, 'm')} 分钟前`
  } else if (now.diff(target, 'h') < 24) {
    return `${now.diff(target, 'h')} 小时前`
  } else if (now.diff(target, 'd') < 31) {
    return `${now.diff(target, 'd')} 天前`
  } else if (now.diff(target, 'm') < 12) {
    return `${now.diff(target, 'm')} 月前`
  } else if (now.diff(target, 'y') < 10) {
    return `${now.diff(target, 'y')} 年前`
  } else {
    return target.format('YYYY-MM-DD HH-mm')
  }
}

/**
 * token的保存和读取
 */
export const token = {
  get() {
    return localStorage.getItem('token')
  },
  set(token: string) {
    localStorage.setItem('token', token)
  },
  clear() {
    localStorage.removeItem('token')
  },
}

/**
 * 根据字段值获取名
 */
export const getFieldLabel = (list: any[], value: any) => {
  return list.find((item) => item.value === value)?.label || value
}

//一键复制
export function copy(value:any, cb:any){
  // 动态创建 textarea 标签
  const textarea:any= document.createElement('textarea')
  // 将该 textarea 设为 readonly 防止 iOS 下自动唤起键盘，同时将 textarea 移出可视区域
  textarea.readOnly = 'readonly'
  textarea.style.position = 'absolute'
  textarea.style.left = '-9999px'
  // 将要 copy 的值赋给 textarea 标签的 value 属性  
  // 网上有些例子是赋值给innerText,这样也会赋值成功，但是识别不了\r\n的换行符，赋值给value属性就可以
  textarea.value = value
  // 将 textarea 插入到 body 中
  document.body.appendChild(textarea)
  // 选中值并复制
  textarea.select()
  textarea.setSelectionRange(0, textarea.value.length)
  document.execCommand('Copy')
  document.body.removeChild(textarea)
  if (cb && Object.prototype.toString.call(cb) === '[object Function]') {
    cb()
  }
}


/**
 * 根据已选权限ids
 */
 export const getCheckedIds = (data: any[]) => {
  const ids = []
  data?.map((item: any) => {
    const { id, checked, children } = item || {}
    if (Array.isArray(children) && children.length) {
      const list = getCheckedIds(children)
      ids.push(...list)
    } else if (checked && id) {
      ids.push(id)
    }
  })
  return ids
}
