import React, { useEffect, useState } from 'react'
import { Card } from 'antd'

import ToolBar from './components/ToolBar'
import PowerStationList from './components/List'
import CardTitle from '../../user-center/rights-template/components/CardTitle'
import PowerStationControllerApi from '../../../services/api/PowerStationControllerApi'
import {BreadcrumbObservable} from "../../../application";

import './index.less'

// const CustomData = [
//   {
//     id: 1,
//     stationName: '电站名称1',
//     projectName: '所属项目1',
//     capacity: '3,200MW',
//     level: '120kV',
//     date: '2021.10.02 18:23:23',
//     num: 8,
//   },
// ]

const DefaultParams = {
  page: 0,
  size: 10,
}

const PowerStation: React.FC = React.memo(() => {
  const [loading, setLoading] = useState<boolean>(false)
  const [list, setList] = useState<any[]>([])
  const [total, setTotal] = useState<number>(0)
  const [pageNum, setPageNum] = useState<number>(1)
  const [pageSize, setPageSize] = useState<number>(10)

  useEffect(() => {
    BreadcrumbObservable.next([])
  }, [])

  const fetchList = (params: any) => {
    setLoading(true)
    PowerStationControllerApi.i3060PowerStationPageGet(params)
      .then((res) => {
        setLoading(false)
        const { code, data } = res || {}
        if (code === 200) {
          setTotal(data?.total || 0)
          setList(data?.data || [])
        }
      })
      .catch(() => {
        setLoading(false)
      })
  }

  const handlePageChange = (page: number, size: number) => {
    setPageNum(page)
    setPageSize(size)
    fetchList({ page: pageNum, size: pageSize })
  }

  useEffect(() => {
    fetchList({ ...DefaultParams })
  }, [])

  return (
    <Card
      title={<CardTitle showBack={false} title="电站列表" />}
      className={`no-border-bottom-card list-card-wrap`}
      extra={<ToolBar loading={loading} />}
    >
      <PowerStationList
        list={list}
        total={total}
        pageNum={pageNum}
        pageSize={pageSize}
        onChange={(pageNum, pageSize) => handlePageChange?.(pageNum, pageSize)}
      />
    </Card>
  )
})

export default PowerStation
