import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Button, Form, Input, message, Modal, Space } from 'antd'
import { debounce } from 'lodash'
import CardTitle from '../components/CardTitle'
import PermissionResource from '../components/PermissionResource'
import { PermissionTemplateControllerService } from '../../../../apis/project-service-history'
import PermissionTemplateControllerApi from '../../../../services/api/PermissionTemplateControllerApi'
import Global from '../../../../global'
import { TemplateAuth } from '../type'
import { NAME_REG } from '../../../../utils/constant'
import { getCheckedIds } from '../index'
import iconMember from '../../../../assets/member.png'

import '../index.less'

export const GroupProjectRightsTemplateEdit = React.memo(() => {
  const navigate = useNavigate()
  const params = useParams()
  const { templateId } = params

  // 用户基础信息
  const [user, setUser] = useState(Global.user)
  Global.updateUser.push((e: any) => {
    setUser(e)
  })

  const [loading, setLoading] = useState<boolean>(false)
  const [renameVisible, setRenameVisible] = React.useState<boolean>(false)
  const [resourceList, setResourceList] = useState<TemplateAuth[]>()
  const [checkIdList, setCheckIdList] = useState<React.Key[]>([])
  const [templateName, setTemplateName] = useState<string>()
  const [originalName, setOriginalName] = useState<string>()
  const [isEdit] = useState<boolean>(!!templateId && templateId !== '0')

  const [form] = Form.useForm()

  const fetchById = () => {
    if (loading || !user?.id) return
    setLoading(true)
    PermissionTemplateControllerApi.authPermissionTemplateResourcesQueryTreeGet({
      templateId,
    })
      .then((res) => {
        setLoading(false)
        const { code, data } = res || {}
        if (code === 200) {
          const { templateName, permissionsTreeRes } = data || {}
          const list = permissionsTreeRes || []
          setResourceList(list)
          setTemplateName(templateName || '')
          setOriginalName(templateName || '')
          const checkedIds = getCheckedIds(list)
          setCheckIdList(checkedIds)
        }
      })
      .catch(() => {
        setLoading(false)
      })
  }

  const fetchAll = () => {
    if (loading) return
    setLoading(true)
    PermissionTemplateControllerApi.authPermissionTemplateResourcesAllTreeGet()
      .then((res) => {
        setLoading(false)
        const { code, data } = res || {}
        if (code === 200) {
          const list = data || []
          setResourceList(list as any[])
        }
      })
      .catch(() => {
        setLoading(false)
      })
  }

  const saveResource = () => {
    if (loading || !templateId) return
    // if (!checkIdList?.length) {
    //   message.warning('请选择操作权限')
    //   return
    // }
    setLoading(true)
    PermissionTemplateControllerService.addOrUpdate({
      root: {
        templateId,
        resourcesIdList: checkIdList,
      },
    })
      .then((res) => {
        setLoading(false)
        const { code, msg } = res || {}
        if (code === 200) {
          message.success('保存成功')
        } else {
          msg && message.error(msg)
        }
      })
      .catch(() => {
        setLoading(false)
      })
  }

  const addTemplate = () => {
    if (loading) return
    if (!templateName) {
      message.warning('请输入权限模板名称')
      return
    } else if (!NAME_REG.test(templateName)) {
      message.warning('名称格式不正确，汉字、英文大小写、数字、-和_')
      return
    }
    // if (!checkIdList?.length) {
    //   message.warning('请选择操作权限')
    //   return
    // }
    setLoading(true)

    PermissionTemplateControllerService.addTemplateAndResources({
      root: {
        templateName,
        resourcesList: checkIdList,
      },
    })
      .then((res) => {
        setLoading(false)
        const { code, msg } = res || {}
        if (code === 200) {
          message.success('添加成功')
          navigate(-1)
        } else {
          msg && message.error(msg)
        }
      })
      .catch(() => {
        setLoading(false)
      })
  }

  const updateTemplate = (params: any) => {
    if (!params?.id) return
    setLoading(true)
    PermissionTemplateControllerService.update({
      root: {
        ...params,
      },
    })
      .then((res) => {
        const { code, msg } = res || {}
        if (code === 200) {
          setRenameVisible(false)
          message.success('重命名成功')
          fetchById()
        } else {
          msg && message.error(msg)
        }
      })
      .catch(() => {
        setLoading(false)
      })
  }

  const handleSave = debounce(
    () => {
      if (isEdit) {
        saveResource()
      } else {
        addTemplate()
      }
    },
    500,
    {
      leading: true,
      trailing: false,
    }
  )

  const handleRename = () => {
    setRenameVisible(true)
  }
  const handleRenameCancel = () => {
    form.resetFields(['templateName'])
    setTemplateName(originalName)
    setRenameVisible(false)
  }
  const handleRenameOk = () => {
    form.validateFields().then((value) => {
      updateTemplate({
        id: templateId,
        templateName: value.templateName,
      })
    })
  }

  useEffect(() => {
    if (isEdit) {
      fetchById()
    } else {
      fetchAll()
    }
  }, [templateId])

  return (
    <div className="rights-template">
      <CardTitle title="权限设置" />
      <div className="rights-template-detail">
        <div className="rights-template-detail-header">
          <Space size={12}>
            <img width={32} src={iconMember} alt="" />
            {isEdit ? (
              <>
                <span className="rights-template-detail-name">{templateName}</span>
                <span className="rights-template-detail-rename" onClick={handleRename}>
                  重命名
                </span>
              </>
            ) : (
              <Input
                allowClear
                size="large"
                showCount
                maxLength={32}
                value={templateName}
                onChange={(e) => setTemplateName(e.target.value)}
              />
            )}
          </Space>
        </div>
        <div className="rights-template-detail-content">
          <PermissionResource
            checkedKeys={checkIdList}
            list={resourceList}
            onCheck={(values) => setCheckIdList(values)}
          />
        </div>
        <div className="rights-template-detail-footer">
          <Button loading={loading} type="primary" size="large" style={{ marginRight: 10 }} onClick={handleSave}>
            应用
          </Button>
          <Button size="large" onClick={() => navigate(-1)}>
            返回
          </Button>
        </div>
        {renameVisible && (
          <Modal
            className="authModal"
            title="重命名"
            visible={renameVisible}
            onOk={handleRenameOk}
            onCancel={handleRenameCancel}
          >
            <Form className="rights-template-form" initialValues={{ templateName }} form={form} autoComplete="off">
              <Form.Item
                name="templateName"
                rules={[
                  { required: true, message: '请输入模板名称' },
                  { pattern: NAME_REG, message: '汉字、英文大小写、数字、-和_' },
                ]}
              >
                <Input
                  placeholder={'请输入模板名称'}
                  showCount
                  maxLength={32}
                  size="large"
                />
              </Form.Item>
            </Form>
          </Modal>
        )}
      </div>
    </div>
  )
})
