import { Button, DatePicker, Form, Slider } from 'antd'
import React, { useState } from 'react'
import { ProjectServiceHistory } from '../../../../apis/project-service-history'
import { useParams } from 'react-router-dom'
import { calcObservable, Empty, InfoList } from '../index'
import Global, { validate7Days } from '../../../../global'
import moment from 'moment'

const formItemLayout = {
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 12 },
  },
}

export const Calc = () => {
  const params = useParams()

  const [config, setConfig] = React.useState<any>()
  const [forceEdit, setForceEdit] = React.useState(true)
  const [loadCurveType, setLoadCurveType] = React.useState(1)

  const [disabledMax, setDisabledMax] = React.useState(15)

  const [dates, setDates] = useState<moment.Moment[]>([])

  React.useEffect(() => {
    const observable = InfoList.subscribe((o: any) => {
      const loadCurveType = o.loadCurveType
      setLoadCurveType(loadCurveType || 1)
      setForceEdit(o.calc.forceEdit)
    })

    return () => {
      observable.unsubscribe()
    }
  })

  const finished = (): boolean => {
    return config && config.settingStartTime && config.settingEndTime && config.settingGranularity
  }

  const auth = Global.projectAuth || {}
  const hasAuth = !!auth.time_range_accuracy

  const marks = () => {
    const res: any = {}
    ;[10, 20, 30, 60].forEach((o: number) => {
      res[o] = `${o === 10 ? 15 : o}分钟`
    })

    // config.optionalGranularityList.forEach((o: number) => {
    //   res[o] = `${o}分钟`
    // })
    return res
  }
  const loadConfig = () => {
    ProjectServiceHistory.countBorder({
      projectId: params.id!,
    })
      .then((resp) => {
        const granularity = resp.data.settingGranularity || resp.data.optionalGranularityList[0]
        calcForm.setFieldsValue({
          timeRange:
            (resp.data.settingStartTime &&
              resp.data.settingEndTime && [moment(resp.data.settingStartTime), moment(resp.data.settingEndTime)]) ||
            undefined,
          granularity: granularity === 15 ? 10 : granularity,
        })
        setConfig(resp.data)
        setDisabledMax(resp.data.optionalGranularityList[0])
        calcObservable.next(true)
      })
      .catch(() => {})
  }

  React.useEffect(() => {
    loadConfig()
  }, [])

  const [calcForm] = Form.useForm()

  const disabledDate = (current: moment.Moment) => {
    if (!config) {
      return true
    }
    const { optionalStartTime, optionalEndTime } = config
    const startDate = moment(optionalStartTime)
    const endDate = moment(optionalEndTime)
    const tooLate = current.diff(startDate, 'days') < 0
    const tooEarly = endDate.diff(current, 'days') < 0

    const max7 = () => {
      if (!dates || dates.length === 0) {
        return false
      }
      const tooLate = dates[0] && current.diff(dates[0], 'days') < 7
      const tooEarly = dates[1] && dates[1].diff(current, 'days') < 7
      return tooEarly || tooLate
    }

    return tooEarly || tooLate || max7()
  }

  if (finished() || hasAuth)
    return (
      <div
        style={{
          overflowY: 'auto',
          height: 'calc(100vh - 290px)',
        }}
      >
        {forceEdit}
        <Form
          className="calc-form"
          {...formItemLayout}
          form={calcForm}
          onFinish={(val: any) => {
            const { timeRange, granularity } = val
            ProjectServiceHistory.countBorder1({
              projectId: params.id!,
              root: {
                startTime: timeRange && timeRange[0].format('YYYY-MM-DD 00:00:00'),
                endTime: timeRange && timeRange[1].format('YYYY-MM-DD 23:59:59'),
                granularity: granularity === 10 ? 15 : granularity,
              },
            })
              .then(() => {
                setConfig(undefined)
                loadConfig()
              })
              .catch(() => {})
          }}
        >
          {loadCurveType === 2 && (
            <Form.Item
              name="timeRange"
              label="计算时间段"
              rules={[
                { required: true, message: '请选择计算时间段' },
                { validator: validate7Days, message: '*请设置7天以上的时间跨度以满足模型计算要求' },
              ]}
            >
              {config && (
                <DatePicker.RangePicker
                  disabled={finished() && !forceEdit}
                  style={{ width: '100%' }}
                  disabledDate={disabledDate}
                  onCalendarChange={(val: any) => {
                    setDates(val)
                  }}
                  defaultPickerValue={[moment(config?.optionalStartTime), moment(config?.optionalEndTime)] as any}
                  onOpenChange={(visible) => {
                    if (!visible) {
                      setDates([])
                    }
                  }}
                />
              )}
            </Form.Item>
          )}
          <Form.Item
            name="granularity"
            label="计算时间颗粒度"
            rules={[{ required: true, message: '请选择计算时间颗粒度' }]}
          >
            {config && (
              <Slider
                disabled={finished() && !forceEdit}
                className={`custom-slider min-${disabledMax}`}
                marks={marks()}
                step={null}
                max={62}
                onChange={(val) => {
                  let min: number = disabledMax
                  min = min === 15 ? 10 : min
                  if (val < min) {
                    calcForm.setFieldsValue({
                      granularity: min,
                    })
                  }
                }}
                tipFormatter={(val?: number) => `${val === 10 ? 15 : val}分钟`}
              />
            )}
          </Form.Item>
          {(!finished() || forceEdit) && (
            <Form.Item className="actions-footer">
              <Button type="primary" htmlType="submit">
                确定
              </Button>
              {finished() && (
                <Button
                  onClick={() => {
                    calcObservable.next(true)
                    loadConfig()
                  }}
                >
                  取消
                </Button>
              )}
            </Form.Item>
          )}
        </Form>
      </div>
    )
  return <Empty />
}
