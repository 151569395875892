import React from 'react'
import './index.less'
import { Spin } from 'antd'
import { FinanceService } from '../../../../apis/project-service-history'
import ProjectInvestCard from './ProjectInvestCard'
import OperationCostCard from './OperationCostCard'
import FinanceCostCard from './FinanceCostCard'
import AwardSubsidyCard from './AwardSubsidyCard'

const ParamConfigCard = (props: any) => {
  const { editType, editConfigId, projectId, changeNameCallback } = props
  const [loading, setLoading] = React.useState(true)
  const [result, setResult] = React.useState<any>()
  React.useEffect(() => {
    setLoading(true)
    // 项目投资
    switch (editType) {
      case 'projectInvest':
        FinanceService.info4({
          projectInvestId: editConfigId,
        })
          .then((resp) => {
            setResult(resp.data)
            console.log(result, resp, 'result')
          })
          .catch(() => {})
          .finally(() => setLoading(false))
        break
      // 运营参数
      case 'operationCost':
        FinanceService.info3({
          operationCostId: editConfigId,
        })
          .then((resp) => {
            setResult(resp.data)
            console.log(result, resp, 'result')
          })
          .catch(() => {})
          .finally(() => setLoading(false))
        break
      //
      case 'financeCost':
        FinanceService.info2({
          financeCostId: editConfigId,
        })
          .then((resp) => {
            setResult(resp.data)
            console.log(result, resp, 'result')
          })
          .catch(() => {})
          .finally(() => setLoading(false))
        break
      case 'awardSubsidy':
        FinanceService.info1({
          awardSubsidyId: editConfigId,
        })
          .then((resp) => {
            setResult(resp.data)
            console.log(result, resp, 'result')
          })
          .catch(() => {})
          .finally(() => setLoading(false))
        break
      default:
    }
  }, [])

  const changeResult = () => {
    // setResult({ ...data })
  }

  return (
    <Spin spinning={loading}>
      <div className="param-config-card">
        {editType === 'projectInvest' && (
          <ProjectInvestCard
            editType={editType}
            result={result}
            changeResult={changeResult}
            editConfigId={editConfigId}
            projectId={projectId}
            changeNameCallback={changeNameCallback}
          />
        )}
        {editType === 'operationCost' && (
          <OperationCostCard
            editType={editType}
            result={result}
            changeResult={changeResult}
            editConfigId={editConfigId}
            changeNameCallback={changeNameCallback}
            projectId={projectId}
          />
        )}
        {editType === 'financeCost' && (
          <FinanceCostCard
            editType={editType}
            result={result}
            changeResult={changeResult}
            editConfigId={editConfigId}
            changeNameCallback={changeNameCallback}
            projectId={projectId}
          />
        )}
        {editType === 'awardSubsidy' && (
          <AwardSubsidyCard
            editType={editType}
            result={result}
            changeResult={changeResult}
            editConfigId={editConfigId}
            changeNameCallback={changeNameCallback}
            projectId={projectId}
          />
        )}
      </div>
    </Spin>
  )
}
export default ParamConfigCard
