/**
 * project
 * unknown
 *
 * OpenAPI spec version: last
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import ajax, { AjaxPromise, ExtraFetchParams } from '@ajax';
import * as models from '../model/models';

/* tslint:disable:no-unused-variable member-ordering object-literal-shorthand */

/**
  */
export type ParamsBodyi3060HelperJurisdictionBatchAddPost = Array<models.I3060HelperJurisdictionBatchAddBody>;
/**
  * @description i3060HelperJurisdictionCurrDataAuthDataIdGet参数
  * @property `dataId` 项目数据id(Long)
  * @property `dataType` 项目数据类型 1.技术方案(Integer)
  */
export interface Paramsi3060HelperJurisdictionCurrDataAuthDataIdGet {
  // pathParams
  /**
   * 项目数据id(Long)
   */
  dataId: string;
  // queryParams
  /**
   * 项目数据类型 1.技术方案(Integer)
   */
  dataType: string;
}
/**
  * @description i3060HelperJurisdictionDeleteIdDelete参数
  * @property `id` (Long)
  */
export interface Paramsi3060HelperJurisdictionDeleteIdDelete {
  // pathParams
  /**
   * (Long)
   */
  id: string;
}
/**
  */
export type ParamsBodyi3060HelperJurisdictionDeleteIdDelete = any;
/**
  * @description i3060HelperJurisdictionListDataIdGet参数
  * @property `dataId` 项目数据id(Long)
  * @property `dataType` 项目数据类型 1.技术方案(Integer)
  */
export interface Paramsi3060HelperJurisdictionListDataIdGet {
  // pathParams
  /**
   * 项目数据id(Long)
   */
  dataId: string;
  // queryParams
  /**
   * 项目数据类型 1.技术方案(Integer)
   */
  dataType: string;
}
/**
  * ProjectMembersReq :ProjectMembersReq
  */
export type ParamsBodyi3060HelperJurisdictionProjectMembersListPost = models.ProjectMembersReq;

export class HelperJurisdictionControllerApi {
  protected $basePath = '/api'.replace(/\/$/, '');

  public constructor(basePath?: string) {
    if (basePath !== undefined) {
      this.$basePath = basePath.replace(/\/$/, '');
    }
  }

  /**
   * ```   /_**      * 新增协助者批量      *_/     @PostMapping(\"/batch/add\")     public Result<Boolean> batchAddData(@RequestBody List<HelperJurisdictionReq> helperJurisdictionReqList) ```
   * @summary 新增协助者批量
   
   * @param data: ParamsBodyi3060HelperJurisdictionBatchAddPost
   * @param opt ajax config
   * @returns models.ResultBoolean1
   */
  public i3060HelperJurisdictionBatchAddPost = (
    
    data: ParamsBodyi3060HelperJurisdictionBatchAddPost,opt?: ExtraFetchParams
  ) : AjaxPromise<models.ResultBoolean1>  => {
    const url = this.$basePath + `/i3060/helper/jurisdiction/batch/add`;
    const p: any = {};
    p.data = data;
    return ajax.ajax({
      ...opt,
      method: 'POST',
      url,
      ...p
    });
  }
  /**
   * ```   /_**      * 获取当前数据资源权限      *      * @param dataId    项目数据id      * @param dataType  项目数据类型 1.技术方案      * @return      *_/     @GetMapping(\"/curr/dataAuth/{dataId}\")     public Result<List<String>> currResourceDataAuth(@PathVariable Long dataId, Integer dataType) ```
   * @summary 获取当前数据资源权限
   * @param params Paramsi3060HelperJurisdictionCurrDataAuthDataIdGet
   
   * @param opt ajax config
   * @returns models.ResultListString
   */
  public i3060HelperJurisdictionCurrDataAuthDataIdGet = (
    params: Paramsi3060HelperJurisdictionCurrDataAuthDataIdGet,
    opt?: ExtraFetchParams
  ) : AjaxPromise<models.ResultListString>  => {
    const {
    dataId,
} = params;
    const url = this.$basePath + `/i3060/helper/jurisdiction/curr/dataAuth/${dataId}`;
    const p: any = {};
    p.query = {};
    if ('dataType' in params) p.query.dataType = params.dataType;
    ajax.check(params.dataType, 'dataType');
    ajax.check(params.dataId, 'dataId');
    return ajax.ajax({
      ...opt,
      method: 'GET',
      url,
      ...p
    });
  }
  /**
   * ```   /_**      * 删除协助者数据      *      * @param id      * @return      *_/     @DeleteMapping(\"/delete/{id}\")     public Result<Boolean> delete(@PathVariable Long id) ```
   * @summary 删除协助者数据
   * @param params Paramsi3060HelperJurisdictionDeleteIdDelete
   * @param data: ParamsBodyi3060HelperJurisdictionDeleteIdDelete
   * @param opt ajax config
   * @returns models.ResultBoolean1
   */
  public i3060HelperJurisdictionDeleteIdDelete = (
    params: Paramsi3060HelperJurisdictionDeleteIdDelete,
    data: ParamsBodyi3060HelperJurisdictionDeleteIdDelete,opt?: ExtraFetchParams
  ) : AjaxPromise<models.ResultBoolean1>  => {
    const {
    id,
} = params;
    const url = this.$basePath + `/i3060/helper/jurisdiction/delete/${id}`;
    const p: any = {};
    ajax.check(params.id, 'id');
    p.data = data;
    return ajax.ajax({
      ...opt,
      method: 'DELETE',
      url,
      ...p
    });
  }
  /**
   * ```   /_**      * 通过项目数据id和项目数据类型type获取资源协助者列表      *      * @param dataId   项目数据id      * @param dataType 项目数据类型 1.技术方案      * @return      *_/     @GetMapping(\"/list/{dataId}\")     public Result<List<HelperJurisdictionListResp>> selectByResourceId(@PathVariable Long dataId, Integer dataType) ```
   * @summary 通过项目数据id和项目数据类型type获取资源协助者列表
   * @param params Paramsi3060HelperJurisdictionListDataIdGet
   
   * @param opt ajax config
   * @returns models.ResultListHelperJurisdictionListResp
   */
  public i3060HelperJurisdictionListDataIdGet = (
    params: Paramsi3060HelperJurisdictionListDataIdGet,
    opt?: ExtraFetchParams
  ) : AjaxPromise<models.ResultListHelperJurisdictionListResp>  => {
    const {
    dataId,
} = params;
    const url = this.$basePath + `/i3060/helper/jurisdiction/list/${dataId}`;
    const p: any = {};
    p.query = {};
    if ('dataType' in params) p.query.dataType = params.dataType;
    ajax.check(params.dataType, 'dataType');
    ajax.check(params.dataId, 'dataId');
    return ajax.ajax({
      ...opt,
      method: 'GET',
      url,
      ...p
    });
  }
  /**
   * ```  /_**      * 查询项目成员列表（普通成员），已有协助者置灰（project_account）      *_/     @PostMapping(\"/project/members/list\")     public Result<List<HelpProjectAccountRes>> selectProjectMembers(@RequestBody ProjectMembersReq projectMembersReq) ```
   * @summary 查询项目成员列表（普通成员），已有协助者置灰（project_account）
   
   * @param data: ParamsBodyi3060HelperJurisdictionProjectMembersListPost// ProjectMembersReq :ProjectMembersReq
   * @param opt ajax config
   * @returns models.I3060HelperJurisdictionProjectMembersList
   */
  public i3060HelperJurisdictionProjectMembersListPost = (
    
    data: ParamsBodyi3060HelperJurisdictionProjectMembersListPost,opt?: ExtraFetchParams
  ) : AjaxPromise<models.I3060HelperJurisdictionProjectMembersList>  => {
    const url = this.$basePath + `/i3060/helper/jurisdiction/project/members/list`;
    const p: any = {};
    p.data = data;
    return ajax.ajax({
      ...opt,
      method: 'POST',
      url,
      ...p
    });
  }
}

export default new HelperJurisdictionControllerApi();