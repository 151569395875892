/**
 * project
 * unknown
 *
 * OpenAPI spec version: last
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import ajax, { AjaxPromise, ExtraFetchParams } from '@ajax';
import * as models from '../model/models';

/* tslint:disable:no-unused-variable member-ordering object-literal-shorthand */

/**
  * TenantReq :TenantReq
  */
export type ParamsBodyauthTenantAddPost = models.TenantReq;
/**
  * @description authTenantChangeGet参数
  * @property `tenantId` (Long)
  * @property `isAdministrators` (Boolean)
  */
export interface ParamsauthTenantChangeGet {
  // queryParams
  /**
   * (Long)
   */
  tenantId: string;
  /**
   * (Boolean)
   */
  isAdministrators: string;
}
/**
  * @description authTenantListGet参数
  * @property `[isAdministrators]` (Boolean)
  */
export interface ParamsauthTenantListGet {
  // queryParams
  /**
   * (Boolean)
   */
  isAdministrators?: string;
}
/**
  */
export type ParamsBodyauthTenantUpdatePost = models.AuthTenantUpdateBody;

export class TenantControllerApi {
  protected $basePath = '/api'.replace(/\/$/, '');

  public constructor(basePath?: string) {
    if (basePath !== undefined) {
      this.$basePath = basePath.replace(/\/$/, '');
    }
  }

  /**
   * 
   * @summary 新增组织
   
   * @param data: ParamsBodyauthTenantAddPost// TenantReq :TenantReq
   * @param opt ajax config
   * @returns models.ResultBoolean
   */
  public authTenantAddPost = (
    
    data: ParamsBodyauthTenantAddPost,opt?: ExtraFetchParams
  ) : AjaxPromise<models.ResultBoolean>  => {
    const url = this.$basePath + `/auth/tenant/add`;
    const p: any = {};
    p.data = data;
    return ajax.ajax({
      ...opt,
      method: 'POST',
      url,
      ...p
    });
  }
  /**
   * 
   * @summary 切换用户组织
   * @param params ParamsauthTenantChangeGet
   
   * @param opt ajax config
   * @returns models.ResultLong
   */
  public authTenantChangeGet = (
    params: ParamsauthTenantChangeGet,
    opt?: ExtraFetchParams
  ) : AjaxPromise<models.ResultLong>  => {
    const url = this.$basePath + `/auth/tenant/change`;
    const p: any = {};
    p.query = {};
    if ('tenantId' in params) p.query.tenantId = params.tenantId;
    ajax.check(params.tenantId, 'tenantId');
    if ('isAdministrators' in params) p.query.isAdministrators = params.isAdministrators;
    ajax.check(params.isAdministrators, 'isAdministrators');
    return ajax.ajax({
      ...opt,
      method: 'GET',
      url,
      ...p
    });
  }
  /**
   * ```   /_**      * 查询用户所有组织(后台调用isAdministrators 传true)      *_/     @GetMapping(\"/list\")     public Result<List<TenantRes>> userTeantList(@RequestParam(value = \"isAdministrators\", required = false) Boolean isAdministrators) ```
   * @summary 查询用户所有组织(后台调用isAdministrators 传true，前台调用不传
   * @param params ParamsauthTenantListGet
   
   * @param opt ajax config
   * @returns models.ResultListTenantRes
   */
  public authTenantListGet = (
    params: ParamsauthTenantListGet,
    opt?: ExtraFetchParams
  ) : AjaxPromise<models.ResultListTenantRes>  => {
    const url = this.$basePath + `/auth/tenant/list`;
    const p: any = {};
    p.query = {};
    if ('isAdministrators' in params) p.query.isAdministrators = params.isAdministrators;
    return ajax.ajax({
      ...opt,
      method: 'GET',
      url,
      ...p
    });
  }
  /**
   * 
   * @summary 修改组织名称
   
   * @param data: ParamsBodyauthTenantUpdatePost
   * @param opt ajax config
   * @returns models.AuthTenantUpdate
   */
  public authTenantUpdatePost = (
    
    data: ParamsBodyauthTenantUpdatePost,opt?: ExtraFetchParams
  ) : AjaxPromise<models.AuthTenantUpdate>  => {
    const url = this.$basePath + `/auth/tenant/update`;
    const p: any = {};
    p.data = data;
    return ajax.ajax({
      ...opt,
      method: 'POST',
      url,
      ...p
    });
  }
  /**
   * 
   * @summary 获取用户当前组织，没有设置设置默认组织
   
   
   * @param opt ajax config
   * @returns models.ResultCurTenantRes
   */
  public authTenantUserTenantGet = (
    
    opt?: ExtraFetchParams
  ) : AjaxPromise<models.ResultCurTenantRes>  => {
    const url = this.$basePath + `/auth/tenant/userTenant`;
    const p: any = {};
    return ajax.ajax({
      ...opt,
      method: 'GET',
      url,
      ...p
    });
  }
}

export default new TenantControllerApi();