/**
 * project
 * unknown
 *
 * OpenAPI spec version: last
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import ajax, { AjaxPromise, ExtraFetchParams } from '@ajax';
import * as models from '../model/models';

/* tslint:disable:no-unused-variable member-ordering object-literal-shorthand */

/**
  * @description i3060FinanceAllGet headers 参数
  * @property `Authorization` Authorization (Only:undefined)
  */
export interface ParamsHeaderi3060FinanceAllGet {
  /** Authorization (Only:undefined) */
  'Authorization': string;
}
/**
  * @description i3060FinanceAllGet参数
  * @property `[createTimeSort]` 创建时间 0 不排序 1 asc 2 desc
  * @property `[updateTimeSort]` 更新时间 0 不排序 1 asc 2 desc
  * @property `[marked]` marked 星标 不传为全部  0:否 1:是
  * @property `[locked]` locked 归档 不传为全部  0:否 1:是
  * @property `size` 
  * @property `page` 
  * @property `[creatorUserName]` 创建人姓名
  * @property `[name]` 财务方案名称
  * @property `[projectName]` 项目名称
  * @property `calcStatus` 0:未计算 1:计算中 2:计算完成 3: 计算失败 4:计算失效 多个用逗号隔开
  */
export interface Paramsi3060FinanceAllGet {
  // queryParams
  /**
   * 创建时间 0 不排序 1 asc 2 desc
   */
  createTimeSort?: string;
  /**
   * 更新时间 0 不排序 1 asc 2 desc
   */
  updateTimeSort?: string;
  /**
   * marked 星标 不传为全部  0:否 1:是
   */
  marked?: string;
  /**
   * locked 归档 不传为全部  0:否 1:是
   */
  locked?: string;
  /**
   * 
   */
  size: string;
  /**
   * 
   */
  page: string;
  /**
   * 创建人姓名
   */
  creatorUserName?: string;
  /**
   * 财务方案名称
   */
  name?: string;
  /**
   * 项目名称
   */
  projectName?: string;
  /**
   * 0:未计算 1:计算中 2:计算完成 3: 计算失败 4:计算失效 多个用逗号隔开
   */
  calcStatus: string;
}
/**
  * @description i3060FinanceAwardSubsidyAwardSubsidyIdDelete参数
  * @property `awardSubsidyId` 激励补贴id
  */
export interface Paramsi3060FinanceAwardSubsidyAwardSubsidyIdDelete {
  // pathParams
  /**
   * 激励补贴id
   */
  awardSubsidyId: string;
}
/**
  */
export type ParamsBodyi3060FinanceAwardSubsidyAwardSubsidyIdDelete = any;
/**
  * @description i3060FinanceAwardSubsidyAwardSubsidyIdPut参数
  * @property `awardSubsidyId` 激励补贴id
  */
export interface Paramsi3060FinanceAwardSubsidyAwardSubsidyIdPut {
  // pathParams
  /**
   * 激励补贴id
   */
  awardSubsidyId: string;
}
/**
  */
export type ParamsBodyi3060FinanceAwardSubsidyAwardSubsidyIdPut = models.I3060FinanceAwardSubsidyAwardSubsidyIdBody;
/**
  * @description i3060FinanceAwardSubsidyCopyAwardSubsidyIdPost参数
  * @property `awardSubsidyId` 激励补贴id
  */
export interface Paramsi3060FinanceAwardSubsidyCopyAwardSubsidyIdPost {
  // pathParams
  /**
   * 激励补贴id
   */
  awardSubsidyId: string;
}
/**
  */
export type ParamsBodyi3060FinanceAwardSubsidyCopyAwardSubsidyIdPost = any;
/**
  * @description i3060FinanceAwardSubsidyCreateOrUpdateBatchPost headers 参数
  * @property `Authorization` Authorization (Only:undefined)
  */
export interface ParamsHeaderi3060FinanceAwardSubsidyCreateOrUpdateBatchPost {
  /** Authorization (Only:undefined) */
  'Authorization': string;
}
/**
  * @description i3060FinanceAwardSubsidyCreateOrUpdateBatchPost参数
  */
export interface Paramsi3060FinanceAwardSubsidyCreateOrUpdateBatchPost {
}
/**
  */
export type ParamsBodyi3060FinanceAwardSubsidyCreateOrUpdateBatchPost = Array<models.I3060FinanceAwardSubsidyEditBody>;
/**
  * @description i3060FinanceAwardSubsidyDefaultAwardSubsidyIdPut参数
  * @property `awardSubsidyId` 激励补贴id
  */
export interface Paramsi3060FinanceAwardSubsidyDefaultAwardSubsidyIdPut {
  // pathParams
  /**
   * 激励补贴id
   */
  awardSubsidyId: string;
}
/**
  */
export type ParamsBodyi3060FinanceAwardSubsidyDefaultAwardSubsidyIdPut = models.I3060FinanceAwardSubsidyDefaultAwardSubsidyIdBody;
/**
  * @description i3060FinanceAwardSubsidyDetailAwardSubsidyDetailIdDelete参数
  * @property `awardSubsidyDetailId` 补贴激励id
  */
export interface Paramsi3060FinanceAwardSubsidyDetailAwardSubsidyDetailIdDelete {
  // pathParams
  /**
   * 补贴激励id
   */
  awardSubsidyDetailId: string;
}
/**
  */
export type ParamsBodyi3060FinanceAwardSubsidyDetailAwardSubsidyDetailIdDelete = any;
/**
  * @description i3060FinanceAwardSubsidyDetailAwardSubsidyDetailIdPut参数
  * @property `awardSubsidyDetailId` 补贴激励id
  */
export interface Paramsi3060FinanceAwardSubsidyDetailAwardSubsidyDetailIdPut {
  // pathParams
  /**
   * 补贴激励id
   */
  awardSubsidyDetailId: string;
}
/**
  */
export type ParamsBodyi3060FinanceAwardSubsidyDetailAwardSubsidyDetailIdPut = models.I3060FinanceAwardSubsidyDetailAwardSubsidyDetailIdBody;
/**
  * @description i3060FinanceAwardSubsidyDetailAwardSubsidyIdPost参数
  * @property `awardSubsidyId` 激励补贴id
  */
export interface Paramsi3060FinanceAwardSubsidyDetailAwardSubsidyIdPost {
  // pathParams
  /**
   * 激励补贴id
   */
  awardSubsidyId: string;
}
/**
  */
export type ParamsBodyi3060FinanceAwardSubsidyDetailAwardSubsidyIdPost = models.I3060FinanceAwardSubsidyDetailAwardSubsidyIdBody;
/**
  * @description i3060FinanceAwardSubsidyDetailDetailNameAwardSubsidyIdGet headers 参数
  * @property `Authorization` Authorization (Only:undefined)
  */
export interface ParamsHeaderi3060FinanceAwardSubsidyDetailDetailNameAwardSubsidyIdGet {
  /** Authorization (Only:undefined) */
  'Authorization': string;
}
/**
  * @description i3060FinanceAwardSubsidyDetailDetailNameAwardSubsidyIdGet参数
  * @property `awardSubsidyId` 激励补贴id
  */
export interface Paramsi3060FinanceAwardSubsidyDetailDetailNameAwardSubsidyIdGet {
  // pathParams
  /**
   * 激励补贴id
   */
  awardSubsidyId: string;
}
/**
  * @description i3060FinanceAwardSubsidyDetailGetDetailListGet headers 参数
  * @property `Authorization` Authorization (Only:1)
  */
export interface ParamsHeaderi3060FinanceAwardSubsidyDetailGetDetailListGet {
  /** Authorization (Only:1) */
  'Authorization': string;
}
/**
  * @description i3060FinanceAwardSubsidyDetailGetDetailListGet参数
  */
export interface Paramsi3060FinanceAwardSubsidyDetailGetDetailListGet {
}
/**
  * @description i3060FinanceAwardSubsidyDetailInfoAwardSubsidyDetailIdGet headers 参数
  * @property `Authorization` Authorization (Only:)
  */
export interface ParamsHeaderi3060FinanceAwardSubsidyDetailInfoAwardSubsidyDetailIdGet {
  /** Authorization (Only:) */
  'Authorization': string;
}
/**
  * @description i3060FinanceAwardSubsidyDetailInfoAwardSubsidyDetailIdGet参数
  * @property `awardSubsidyDetailId` 补贴激励id
  */
export interface Paramsi3060FinanceAwardSubsidyDetailInfoAwardSubsidyDetailIdGet {
  // pathParams
  /**
   * 补贴激励id
   */
  awardSubsidyDetailId: string;
}
/**
  * @description i3060FinanceAwardSubsidyEditPost headers 参数
  * @property `Authorization` Authorization (Only:undefined)
  */
export interface ParamsHeaderi3060FinanceAwardSubsidyEditPost {
  /** Authorization (Only:undefined) */
  'Authorization': string;
}
/**
  * @description i3060FinanceAwardSubsidyEditPost参数
  */
export interface Paramsi3060FinanceAwardSubsidyEditPost {
}
/**
  */
export type ParamsBodyi3060FinanceAwardSubsidyEditPost = models.I3060FinanceAwardSubsidyEditBody;
/**
  * @description i3060FinanceAwardSubsidyInfoAwardSubsidyIdGet headers 参数
  * @property `Authorization` Authorization (Only:undefined)
  */
export interface ParamsHeaderi3060FinanceAwardSubsidyInfoAwardSubsidyIdGet {
  /** Authorization (Only:undefined) */
  'Authorization': string;
}
/**
  * @description i3060FinanceAwardSubsidyInfoAwardSubsidyIdGet参数
  * @property `awardSubsidyId` 激励补贴id
  */
export interface Paramsi3060FinanceAwardSubsidyInfoAwardSubsidyIdGet {
  // pathParams
  /**
   * 激励补贴id
   */
  awardSubsidyId: string;
}
/**
  */
export type ParamsBodyi3060FinanceAwardSubsidyPost = any;
/**
  * @description i3060FinanceBatchCalculateFinanceIdPost参数
  * @property `financeId` 
  */
export interface Paramsi3060FinanceBatchCalculateFinanceIdPost {
  // pathParams
  /**
   * 
   */
  financeId: string;
}
/**
  */
export type ParamsBodyi3060FinanceBatchCalculateFinanceIdPost = any;
/**
  * @description i3060FinanceCalculateProjectIdPost参数
  * @property `projectId` 项目id
  */
export interface Paramsi3060FinanceCalculateProjectIdPost {
  // pathParams
  /**
   * 项目id
   */
  projectId: string;
}
/**
  */
export type ParamsBodyi3060FinanceCalculateProjectIdPost = models.I3060FinanceCalculateProjectIdBody;
/**
  * @description i3060FinanceCostCopyFinanceCostIdPost参数
  * @property `financeCostId` 财务成本id
  */
export interface Paramsi3060FinanceCostCopyFinanceCostIdPost {
  // pathParams
  /**
   * 财务成本id
   */
  financeCostId: string;
}
/**
  */
export type ParamsBodyi3060FinanceCostCopyFinanceCostIdPost = any;
/**
  */
export type ParamsBodyi3060FinanceCostCreateOrEditBatchPost = Array<models.I3060FinanceCostEditBody>;
/**
  * @description i3060FinanceCostDefaultFinanceCostIdPut参数
  * @property `financeCostId` 财务成本id
  */
export interface Paramsi3060FinanceCostDefaultFinanceCostIdPut {
  // pathParams
  /**
   * 财务成本id
   */
  financeCostId: string;
}
/**
  */
export type ParamsBodyi3060FinanceCostDefaultFinanceCostIdPut = models.I3060FinanceCostDefaultFinanceCostIdBody;
/**
  */
export type ParamsBodyi3060FinanceCostEditPost = models.I3060FinanceCostEditBody;
/**
  * @description i3060FinanceCostFinanceCostIdDelete参数
  * @property `financeCostId` 财务成本id
  */
export interface Paramsi3060FinanceCostFinanceCostIdDelete {
  // pathParams
  /**
   * 财务成本id
   */
  financeCostId: string;
}
/**
  */
export type ParamsBodyi3060FinanceCostFinanceCostIdDelete = any;
/**
  * @description i3060FinanceCostInfoFinanceCostIdGet参数
  * @property `financeCostId` 财务成本id
  */
export interface Paramsi3060FinanceCostInfoFinanceCostIdGet {
  // pathParams
  /**
   * 财务成本id
   */
  financeCostId: string;
}
/**
  * @description i3060FinanceCostListGet headers 参数
  * @property `Authorization` Authorization (Only:undefined)
  */
export interface ParamsHeaderi3060FinanceCostListGet {
  /** Authorization (Only:undefined) */
  'Authorization': string;
}
/**
  * @description i3060FinanceCostListGet参数
  */
export interface Paramsi3060FinanceCostListGet {
}
/**
  */
export type ParamsBodyi3060FinanceCostPost = any;
/**
  * @description i3060FinanceFinanceIdDelete headers 参数
  * @property `Authorization` Authorization (Only:undefined)
  */
export interface ParamsHeaderi3060FinanceFinanceIdDelete {
  /** Authorization (Only:undefined) */
  'Authorization': string;
}
/**
  * @description i3060FinanceFinanceIdDelete参数
  * @property `financeId` 方案id
  */
export interface Paramsi3060FinanceFinanceIdDelete {
  // pathParams
  /**
   * 方案id
   */
  financeId: string;
}
/**
  * @description i3060FinanceGetPerspectiveAnalyseQueryTypeGet headers 参数
  * @property `Authorization` Authorization (Only:)
  */
export interface ParamsHeaderi3060FinanceGetPerspectiveAnalyseQueryTypeGet {
  /** Authorization (Only:) */
  'Authorization': string;
}
/**
  * @description i3060FinanceGetPerspectiveAnalyseQueryTypeGet参数
  */
export interface Paramsi3060FinanceGetPerspectiveAnalyseQueryTypeGet {
}
/**
  * @description i3060FinanceLibraryWarnToastGet headers 参数
  * @property `Authorization` Authorization (Only:)
  */
export interface ParamsHeaderi3060FinanceLibraryWarnToastGet {
  /** Authorization (Only:) */
  'Authorization': string;
}
/**
  * @description i3060FinanceLibraryWarnToastGet参数
  * @property `type` project_invest_id项目投资id；operation_cost_id运营成本id；finance_cost_id财务成本id；award_subsidy_id激励补贴id；
  * @property `ids` id集合
  */
export interface Paramsi3060FinanceLibraryWarnToastGet {
  // queryParams
  /**
   * project_invest_id项目投资id；operation_cost_id运营成本id；finance_cost_id财务成本id；award_subsidy_id激励补贴id；
   */
  type: string;
  /**
   * id集合
   */
  ids: string;
}
/**
  * @description i3060FinanceListProjectIdGet headers 参数
  * @property `Authorization` Authorization (Only:undefined)
  */
export interface ParamsHeaderi3060FinanceListProjectIdGet {
  /** Authorization (Only:undefined) */
  'Authorization': string;
}
/**
  * @description i3060FinanceListProjectIdGet参数
  * @property `projectId` 项目id
  * @property `[createTimeSort]` 创建时间 0 不排序 1 asc 2 desc
  * @property `[updateTimeSort]` 更新时间 0 不排序 1 asc 2 desc
  * @property `[marked]` marked 星标 不传为全部  0:否 1:是
  * @property `[locked]` locked 归档 不传为全部  0:否 1:是
  * @property `[innerYieldRateMin]` 资本内部收益率
  * @property `[innerYieldRateMax]` 资本内部收益率
  * @property `[netPresentValueMin]` 净现值  单位；万
  * @property `[netPresentValueMax]` 净现值  单位；万
  * @property `[returnInvestmentMin]` 总投资收益率
  * @property `[returnInvestmentMax]` 总投资收益率
  * @property `[staticInvestmentPaybackPeriodMin]` 静态投资回收期 年
  * @property `[staticInvestmentPaybackPeriodMax]` 静态投资回收期 年
  * @property `[initialInvestmentMin]` 初始投资  单位；万
  * @property `[initialInvestmentMax]` 初始投资  单位；万
  * @property `[totalRevenueMin]` 总收益  单位；万
  * @property `[totalRevenueMax]` 总收益  单位；万
  * @property `[cumulativeCashFlowMin]` 累计现金流 单位；万
  * @property `[cumulativeCashFlowMax]` 累计现金流 单位；万
  * @property `netProfitMarginOfCapitalMin` 资本金净利润率
  * @property `netProfitMarginOfCapitalMax` 资本金净利润率
  * @property `page` 
  * @property `size` 
  * @property `innerYieldRateSort` 资本内部收益率
  * @property `netPresentValueSort` 净现值 
  * @property `returnInvestmentSort` 资本金净利润率
  * @property `staticInvestmentPaybackPeriodSort` 总投资收益率
  * @property `initialInvestmentSort` 静态投资回收期
  * @property `totalRevenueSort` 初始投资
  * @property `cumulativeCashFlowSort` 总收益
  * @property `netProfitMarginOfCapitalSort` 累计现金流
  * @property `markedSort` 星标 0 不排序 1 asc 2 desc
  * @property `lockedSort` 归档 0 不排序 1 asc 2 desc
  */
export interface Paramsi3060FinanceListProjectIdGet {
  // pathParams
  /**
   * 项目id
   */
  projectId: string;
  // queryParams
  /**
   * 创建时间 0 不排序 1 asc 2 desc
   */
  createTimeSort?: string;
  /**
   * 更新时间 0 不排序 1 asc 2 desc
   */
  updateTimeSort?: string;
  /**
   * marked 星标 不传为全部  0:否 1:是
   */
  marked?: string;
  /**
   * locked 归档 不传为全部  0:否 1:是
   */
  locked?: string;
  /**
   * 资本内部收益率
   */
  innerYieldRateMin?: string;
  /**
   * 资本内部收益率
   */
  innerYieldRateMax?: string;
  /**
   * 净现值  单位；万
   */
  netPresentValueMin?: string;
  /**
   * 净现值  单位；万
   */
  netPresentValueMax?: string;
  /**
   * 总投资收益率
   */
  returnInvestmentMin?: string;
  /**
   * 总投资收益率
   */
  returnInvestmentMax?: string;
  /**
   * 静态投资回收期 年
   */
  staticInvestmentPaybackPeriodMin?: string;
  /**
   * 静态投资回收期 年
   */
  staticInvestmentPaybackPeriodMax?: string;
  /**
   * 初始投资  单位；万
   */
  initialInvestmentMin?: string;
  /**
   * 初始投资  单位；万
   */
  initialInvestmentMax?: string;
  /**
   * 总收益  单位；万
   */
  totalRevenueMin?: string;
  /**
   * 总收益  单位；万
   */
  totalRevenueMax?: string;
  /**
   * 累计现金流 单位；万
   */
  cumulativeCashFlowMin?: string;
  /**
   * 累计现金流 单位；万
   */
  cumulativeCashFlowMax?: string;
  /**
   * 资本金净利润率
   */
  netProfitMarginOfCapitalMin: string;
  /**
   * 资本金净利润率
   */
  netProfitMarginOfCapitalMax: string;
  /**
   * 
   */
  page: string;
  /**
   * 
   */
  size: string;
  /**
   * 资本内部收益率
   */
  innerYieldRateSort: string;
  /**
   * 净现值 
   */
  netPresentValueSort: string;
  /**
   * 资本金净利润率
   */
  returnInvestmentSort: string;
  /**
   * 总投资收益率
   */
  staticInvestmentPaybackPeriodSort: string;
  /**
   * 静态投资回收期
   */
  initialInvestmentSort: string;
  /**
   * 初始投资
   */
  totalRevenueSort: string;
  /**
   * 总收益
   */
  cumulativeCashFlowSort: string;
  /**
   * 累计现金流
   */
  netProfitMarginOfCapitalSort: string;
  /**
   * 星标 0 不排序 1 asc 2 desc
   */
  markedSort: string;
  /**
   * 归档 0 不排序 1 asc 2 desc
   */
  lockedSort: string;
}
/**
  * @description i3060FinanceLockFinanceIdPatch headers 参数
  * @property `Authorization` Authorization (Only:undefined)
  */
export interface ParamsHeaderi3060FinanceLockFinanceIdPatch {
  /** Authorization (Only:undefined) */
  'Authorization': string;
}
/**
  * @description i3060FinanceLockFinanceIdPatch参数
  * @property `financeId` 方案id
  */
export interface Paramsi3060FinanceLockFinanceIdPatch {
  // pathParams
  /**
   * 方案id
   */
  financeId: string;
}
/**
  */
export type ParamsBodyi3060FinanceLockFinanceIdPatch = models.I3060FinanceLockFinanceIdBody;
/**
  * @description i3060FinanceMarkFinanceIdPatch headers 参数
  * @property `Authorization` Authorization (Only:undefined)
  */
export interface ParamsHeaderi3060FinanceMarkFinanceIdPatch {
  /** Authorization (Only:undefined) */
  'Authorization': string;
}
/**
  * @description i3060FinanceMarkFinanceIdPatch参数
  * @property `financeId` 方案id
  */
export interface Paramsi3060FinanceMarkFinanceIdPatch {
  // pathParams
  /**
   * 方案id
   */
  financeId: string;
}
/**
  */
export type ParamsBodyi3060FinanceMarkFinanceIdPatch = models.I3060FinanceMarkFinanceIdBody;
/**
  * @description i3060FinanceOperationCostCopyOperationCostIdPost参数
  * @property `operationCostId` 运营成本id
  */
export interface Paramsi3060FinanceOperationCostCopyOperationCostIdPost {
  // pathParams
  /**
   * 运营成本id
   */
  operationCostId: string;
}
/**
  */
export type ParamsBodyi3060FinanceOperationCostCopyOperationCostIdPost = any;
/**
  */
export type ParamsBodyi3060FinanceOperationCostCreateOrEditBatchPost = Array<models.I3060FinanceOperationCostEditBody>;
/**
  * @description i3060FinanceOperationCostDefaultOperationCostIdPut参数
  * @property `operationCostId` 运营成本id
  */
export interface Paramsi3060FinanceOperationCostDefaultOperationCostIdPut {
  // pathParams
  /**
   * 运营成本id
   */
  operationCostId: string;
}
/**
  */
export type ParamsBodyi3060FinanceOperationCostDefaultOperationCostIdPut = models.I3060FinanceOperationCostDefaultOperationCostIdBody;
/**
  */
export type ParamsBodyi3060FinanceOperationCostEditPost = models.I3060FinanceOperationCostEditBody;
/**
  * @description i3060FinanceOperationCostInfoOperationCostIdGet参数
  * @property `operationCostId` 运营成本id
  */
export interface Paramsi3060FinanceOperationCostInfoOperationCostIdGet {
  // pathParams
  /**
   * 运营成本id
   */
  operationCostId: string;
}
/**
  * @description i3060FinanceOperationCostListGet headers 参数
  * @property `Authorization` Authorization (Only:undefined)
  */
export interface ParamsHeaderi3060FinanceOperationCostListGet {
  /** Authorization (Only:undefined) */
  'Authorization': string;
}
/**
  * @description i3060FinanceOperationCostListGet参数
  */
export interface Paramsi3060FinanceOperationCostListGet {
}
/**
  * @description i3060FinanceOperationCostOperationCostIdDelete参数
  * @property `operationCostId` 运营成本id
  */
export interface Paramsi3060FinanceOperationCostOperationCostIdDelete {
  // pathParams
  /**
   * 运营成本id
   */
  operationCostId: string;
}
/**
  */
export type ParamsBodyi3060FinanceOperationCostOperationCostIdDelete = any;
/**
  */
export type ParamsBodyi3060FinanceOperationCostPost = any;
/**
  * @description i3060FinanceOutputReportFinanceIdGet headers 参数
  * @property `Authorization` Authorization (Only:)
  */
export interface ParamsHeaderi3060FinanceOutputReportFinanceIdGet {
  /** Authorization (Only:) */
  'Authorization': string;
}
/**
  * @description i3060FinanceOutputReportFinanceIdGet参数
  * @property `financeId` 财务方案
  */
export interface Paramsi3060FinanceOutputReportFinanceIdGet {
  // pathParams
  /**
   * 财务方案
   */
  financeId: string;
}
/**
  */
export type ParamsBodyi3060FinanceParamListCheckProjectLibraryPost = models.I3060FinanceParamListCheckProjectLibraryBody;
/**
  */
export type ParamsBodyi3060FinanceParamListDeleteProjectLibraryPost = models.I3060FinanceParamListDeleteProjectLibraryBody;
/**
  * @description i3060FinanceParamListEditNameParamListIdPut headers 参数
  * @property `Authorization` Authorization (Only:undefined)
  */
export interface ParamsHeaderi3060FinanceParamListEditNameParamListIdPut {
  /** Authorization (Only:undefined) */
  'Authorization': string;
}
/**
  * @description i3060FinanceParamListEditNameParamListIdPut参数
  * @property `paramListId` 参数组列表id
  */
export interface Paramsi3060FinanceParamListEditNameParamListIdPut {
  // pathParams
  /**
   * 参数组列表id
   */
  paramListId: string;
}
/**
  */
export type ParamsBodyi3060FinanceParamListEditNameParamListIdPut = models.I3060FinanceParamListEditNameParamListIdBody;
/**
  * @description i3060FinanceParamListGetProjectLibraryGet参数
  * @property `projectId` 项目id
  */
export interface Paramsi3060FinanceParamListGetProjectLibraryGet {
  // queryParams
  /**
   * 项目id
   */
  projectId: string;
}
/**
  */
export type ParamsBodyi3060FinanceParamListImportLibraryPost = models.I3060FinanceParamListImportLibraryBody;
/**
  */
export type ParamsBodyi3060FinanceParamListImportSingleLibraryPost = models.I3060FinanceParamListImportSingleLibraryBody;
/**
  * @description i3060FinanceParamListListProjectIdGet headers 参数
  * @property `Authorization` Authorization (Only:undefined)
  */
export interface ParamsHeaderi3060FinanceParamListListProjectIdGet {
  /** Authorization (Only:undefined) */
  'Authorization': string;
}
/**
  * @description i3060FinanceParamListListProjectIdGet参数
  * @property `projectId` 项目id
  */
export interface Paramsi3060FinanceParamListListProjectIdGet {
  // pathParams
  /**
   * 项目id
   */
  projectId: string;
}
/**
  * @description i3060FinanceParamListParamListIdDelete参数
  * @property `paramListId` 参数组列表id
  */
export interface Paramsi3060FinanceParamListParamListIdDelete {
  // pathParams
  /**
   * 参数组列表id
   */
  paramListId: string;
}
/**
  */
export type ParamsBodyi3060FinanceParamListParamListIdDelete = any;
/**
  * @description i3060FinanceParamListParamListIdPut headers 参数
  * @property `Authorization` Authorization (Only:undefined)
  */
export interface ParamsHeaderi3060FinanceParamListParamListIdPut {
  /** Authorization (Only:undefined) */
  'Authorization': string;
}
/**
  * @description i3060FinanceParamListParamListIdPut参数
  * @property `paramListId` 参数组列表id
  */
export interface Paramsi3060FinanceParamListParamListIdPut {
  // pathParams
  /**
   * 参数组列表id
   */
  paramListId: string;
}
/**
  */
export type ParamsBodyi3060FinanceParamListParamListIdPut = models.I3060FinanceParamListParamListIdBody;
/**
  * @description i3060FinanceParamListProjectIdPost headers 参数
  * @property `Authorization` Authorization (Only:undefined)
  */
export interface ParamsHeaderi3060FinanceParamListProjectIdPost {
  /** Authorization (Only:undefined) */
  'Authorization': string;
}
/**
  * @description i3060FinanceParamListProjectIdPost参数
  * @property `projectId` 项目id
  */
export interface Paramsi3060FinanceParamListProjectIdPost {
  // pathParams
  /**
   * 项目id
   */
  projectId: string;
}
/**
  */
export type ParamsBodyi3060FinanceParamListProjectIdPost = any;
/**
  */
export type ParamsBodyi3060FinanceParamListRenameProjectLibraryPost = models.I3060FinanceParamListRenameProjectLibraryBody;
/**
  * @description i3060FinancePerspectiveAnalyseProjectIdGet参数
  * @property `projectId` 项目id
  * @property `queryType` 选择查看指标
  */
export interface Paramsi3060FinancePerspectiveAnalyseProjectIdGet {
  // pathParams
  /**
   * 项目id
   */
  projectId: string;
  // queryParams
  /**
   * 选择查看指标
   */
  queryType: string;
}
/**
  * @description i3060FinanceProjectInvestCopyProjectInvestIdPost headers 参数
  * @property `Authorization` Authorization (Only:undefined)
  */
export interface ParamsHeaderi3060FinanceProjectInvestCopyProjectInvestIdPost {
  /** Authorization (Only:undefined) */
  'Authorization': string;
}
/**
  * @description i3060FinanceProjectInvestCopyProjectInvestIdPost参数
  * @property `projectInvestId` 项目投资id
  */
export interface Paramsi3060FinanceProjectInvestCopyProjectInvestIdPost {
  // pathParams
  /**
   * 项目投资id
   */
  projectInvestId: string;
}
/**
  */
export type ParamsBodyi3060FinanceProjectInvestCopyProjectInvestIdPost = any;
/**
  * @description i3060FinanceProjectInvestCreateOrEditBatchPost headers 参数
  * @property `Authorization` Authorization (Only:undefined)
  */
export interface ParamsHeaderi3060FinanceProjectInvestCreateOrEditBatchPost {
  /** Authorization (Only:undefined) */
  'Authorization': string;
}
/**
  * @description i3060FinanceProjectInvestCreateOrEditBatchPost参数
  */
export interface Paramsi3060FinanceProjectInvestCreateOrEditBatchPost {
}
/**
  */
export type ParamsBodyi3060FinanceProjectInvestCreateOrEditBatchPost = Array<models.I3060FinanceProjectInvestCreateOrEditBatchBody>;
/**
  * @description i3060FinanceProjectInvestDefaultProjectInvestIdPut参数
  * @property `projectInvestId` 项目投资id
  */
export interface Paramsi3060FinanceProjectInvestDefaultProjectInvestIdPut {
  // pathParams
  /**
   * 项目投资id
   */
  projectInvestId: string;
}
/**
  */
export type ParamsBodyi3060FinanceProjectInvestDefaultProjectInvestIdPut = models.I3060FinanceProjectInvestDefaultProjectInvestIdBody;
/**
  * @description i3060FinanceProjectInvestEditPost headers 参数
  * @property `Authorization` Authorization (Only:undefined)
  */
export interface ParamsHeaderi3060FinanceProjectInvestEditPost {
  /** Authorization (Only:undefined) */
  'Authorization': string;
}
/**
  * @description i3060FinanceProjectInvestEditPost参数
  */
export interface Paramsi3060FinanceProjectInvestEditPost {
}
/**
  */
export type ParamsBodyi3060FinanceProjectInvestEditPost = models.I3060FinanceProjectInvestEditBody;
/**
  * @description i3060FinanceProjectInvestInfoProjectInvestIdGet参数
  * @property `projectInvestId` 项目投资id
  */
export interface Paramsi3060FinanceProjectInvestInfoProjectInvestIdGet {
  // pathParams
  /**
   * 项目投资id
   */
  projectInvestId: string;
}
/**
  * @description i3060FinanceProjectInvestListGet headers 参数
  * @property `Authorization` Authorization (Only:undefined)
  */
export interface ParamsHeaderi3060FinanceProjectInvestListGet {
  /** Authorization (Only:undefined) */
  'Authorization': string;
}
/**
  * @description i3060FinanceProjectInvestListGet参数
  */
export interface Paramsi3060FinanceProjectInvestListGet {
}
/**
  */
export type ParamsBodyi3060FinanceProjectInvestPost = any;
/**
  * @description i3060FinanceProjectInvestProjectInvestIdDelete headers 参数
  * @property `Authorization` Authorization (Only:undefined)
  */
export interface ParamsHeaderi3060FinanceProjectInvestProjectInvestIdDelete {
  /** Authorization (Only:undefined) */
  'Authorization': string;
}
/**
  * @description i3060FinanceProjectInvestProjectInvestIdDelete参数
  * @property `projectInvestId` 
  */
export interface Paramsi3060FinanceProjectInvestProjectInvestIdDelete {
  // pathParams
  /**
   * 
   */
  projectInvestId: string;
}
/**
  * @description i3060FinanceRenameFinanceIdPatch headers 参数
  * @property `Authorization` Authorization (Only:undefined)
  */
export interface ParamsHeaderi3060FinanceRenameFinanceIdPatch {
  /** Authorization (Only:undefined) */
  'Authorization': string;
}
/**
  * @description i3060FinanceRenameFinanceIdPatch参数
  * @property `financeId` 方案名称
  */
export interface Paramsi3060FinanceRenameFinanceIdPatch {
  // pathParams
  /**
   * 方案名称
   */
  financeId: string;
}
/**
  */
export type ParamsBodyi3060FinanceRenameFinanceIdPatch = models.I3060FinanceRenameFinanceIdBody;
/**
  * @description i3060FinanceSchemePlanListProjectIdGet参数
  * @property `projectId` 项目id
  * @property `createTimeSort` 创建时间 0 不排序 1 asc 2 desc
  * @property `updateTimeSort` 更新时间 0 不排序 1 asc 2 desc
  * @property `[installedCapacityMin]` 装机容量区间-最小
  * @property `[installedCapacityMax]` 装机容量区间-最大
  * @property `[dayAvgPowerGenerationMin]` 日均发电区间
  * @property `[dayAvgPowerGenerationMax]` 日均发电区间
  * @property `[useHourYearMin]` 年使用小时区间
  * @property `[useHourYearMax]` 年使用小时区间
  * @property `[selfAbsorptionRateMin]` 自消纳百分比区间
  * @property `[selfAbsorptionRateMax]` 自消纳百分比区间
  * @property `[batteryCapacityMin]` 电池容量区间
  * @property `[batteryCapacityMax]` 电池容量区间
  * @property `[dayAvgDischargeMin]` 日均放电区间
  * @property `[dayAvgDischargeMax]` 日均放电区间
  * @property `[dayAvgChargeDischargeMin]` 日均充放电区间
  * @property `[dayAvgChargeDischargeMax]` 日均充放电区间
  * @property `installedCapacitySort` 装机容量 0 不排序 1 asc 2 desc
  * @property `dayAvgPowerGenerationSort` 日均发电 0 不排序 1 asc 2 desc
  * @property `useHourYearSort` 年利用小时数 0 不排序 1 asc 2 desc
  * @property `selfAbsorptionRateSort` 自消纳比 0 不排序 1 asc 2 desc
  * @property `batteryCapacitySort` 电池容量 0 不排序 1 asc 2 desc
  * @property `pcsPowerSort` psc总功率 0 不排序 1 asc 2 desc
  * @property `dayAvgDischargeSort` 日均放电区间 0 不排序 1 asc 2 desc
  * @property `dayAvgChargeDischargeSort` 日均充放 0 不排序 1 asc 2 desc
  * @property `[pcsPowerMin]` pcs功率区间
  * @property `[pcsPowerMax]` pcs功率区间
  * @property `annualGenerationHoursSort` 年发电小时数 0 不排序 1 asc 2 desc
  * @property `annualGenerationHoursMin` 年使用小时区间
  * @property `annualGenerationHoursMax` 年使用小时区间
  */
export interface Paramsi3060FinanceSchemePlanListProjectIdGet {
  // pathParams
  /**
   * 项目id
   */
  projectId: string;
  // queryParams
  /**
   * 创建时间 0 不排序 1 asc 2 desc
   */
  createTimeSort: string;
  /**
   * 更新时间 0 不排序 1 asc 2 desc
   */
  updateTimeSort: string;
  /**
   * 装机容量区间-最小
   */
  installedCapacityMin?: string;
  /**
   * 装机容量区间-最大
   */
  installedCapacityMax?: string;
  /**
   * 日均发电区间
   */
  dayAvgPowerGenerationMin?: string;
  /**
   * 日均发电区间
   */
  dayAvgPowerGenerationMax?: string;
  /**
   * 年使用小时区间
   */
  useHourYearMin?: string;
  /**
   * 年使用小时区间
   */
  useHourYearMax?: string;
  /**
   * 自消纳百分比区间
   */
  selfAbsorptionRateMin?: string;
  /**
   * 自消纳百分比区间
   */
  selfAbsorptionRateMax?: string;
  /**
   * 电池容量区间
   */
  batteryCapacityMin?: string;
  /**
   * 电池容量区间
   */
  batteryCapacityMax?: string;
  /**
   * 日均放电区间
   */
  dayAvgDischargeMin?: string;
  /**
   * 日均放电区间
   */
  dayAvgDischargeMax?: string;
  /**
   * 日均充放电区间
   */
  dayAvgChargeDischargeMin?: string;
  /**
   * 日均充放电区间
   */
  dayAvgChargeDischargeMax?: string;
  /**
   * 装机容量 0 不排序 1 asc 2 desc
   */
  installedCapacitySort: string;
  /**
   * 日均发电 0 不排序 1 asc 2 desc
   */
  dayAvgPowerGenerationSort: string;
  /**
   * 年利用小时数 0 不排序 1 asc 2 desc
   */
  useHourYearSort: string;
  /**
   * 自消纳比 0 不排序 1 asc 2 desc
   */
  selfAbsorptionRateSort: string;
  /**
   * 电池容量 0 不排序 1 asc 2 desc
   */
  batteryCapacitySort: string;
  /**
   * psc总功率 0 不排序 1 asc 2 desc
   */
  pcsPowerSort: string;
  /**
   * 日均放电区间 0 不排序 1 asc 2 desc
   */
  dayAvgDischargeSort: string;
  /**
   * 日均充放 0 不排序 1 asc 2 desc
   */
  dayAvgChargeDischargeSort: string;
  /**
   * pcs功率区间
   */
  pcsPowerMin?: string;
  /**
   * pcs功率区间
   */
  pcsPowerMax?: string;
  /**
   * 年发电小时数 0 不排序 1 asc 2 desc
   */
  annualGenerationHoursSort: string;
  /**
   * 年使用小时区间
   */
  annualGenerationHoursMin: string;
  /**
   * 年使用小时区间
   */
  annualGenerationHoursMax: string;
}
/**
  * @description i3060FinanceSchemePlanQueryRefProjectIdGet参数
  * @property `projectId` 项目id
  */
export interface Paramsi3060FinanceSchemePlanQueryRefProjectIdGet {
  // pathParams
  /**
   * 项目id
   */
  projectId: string;
}
/**
  * @description i3060FinanceSchemePlanRefProjectIdPost headers 参数
  * @property `Authorization` Authorization (Only:undefined)
  */
export interface ParamsHeaderi3060FinanceSchemePlanRefProjectIdPost {
  /** Authorization (Only:undefined) */
  'Authorization': string;
}
/**
  * @description i3060FinanceSchemePlanRefProjectIdPost参数
  * @property `projectId` 项目id
  */
export interface Paramsi3060FinanceSchemePlanRefProjectIdPost {
  // pathParams
  /**
   * 项目id
   */
  projectId: string;
}
/**
  */
export type ParamsBodyi3060FinanceSchemePlanRefProjectIdPost = models.I3060FinanceSchemePlanRefProjectIdBody;
/**
  * @description i3060FinanceWordGetPdfTagWordIdGet参数
  * @property `wordId` 
  */
export interface Paramsi3060FinanceWordGetPdfTagWordIdGet {
  // pathParams
  /**
   * 
   */
  wordId: string;
}
/**
  * @description i3060FinanceWordListProjectIdGet headers 参数
  * @property `Authorization` Authorization (Only:)
  */
export interface ParamsHeaderi3060FinanceWordListProjectIdGet {
  /** Authorization (Only:) */
  'Authorization': string;
}
/**
  * @description i3060FinanceWordListProjectIdGet参数
  * @property `projectId` 项目id
  * @property `[wordName]` 文档名称
  * @property `[technicalName]` 技术方案名称
  * @property `[financeName]` 财务方案名称
  * @property `[status]` 生成状态：0未生成；1生成中；2已生成；3生成失败 多个用\",\"隔开
  * @property `createTimeSort` 0 不排序 1 asc 2 desc
  * @property `updateTimeSort` 更新时间 0 不排序 1 asc 2 desc
  * @property `generateTimeSort` 生成时间 0 不排序 1 asc 2 desc
  */
export interface Paramsi3060FinanceWordListProjectIdGet {
  // pathParams
  /**
   * 项目id
   */
  projectId: string;
  // queryParams
  /**
   * 文档名称
   */
  wordName?: string;
  /**
   * 技术方案名称
   */
  technicalName?: string;
  /**
   * 财务方案名称
   */
  financeName?: string;
  /**
   * 生成状态：0未生成；1生成中；2已生成；3生成失败 多个用\",\"隔开
   */
  status?: string;
  /**
   * 0 不排序 1 asc 2 desc
   */
  createTimeSort: string;
  /**
   * 更新时间 0 不排序 1 asc 2 desc
   */
  updateTimeSort: string;
  /**
   * 生成时间 0 不排序 1 asc 2 desc
   */
  generateTimeSort: string;
}
/**
  * @description i3060FinanceWordReadPdfTagGet参数
  * @property `tag` 
  */
export interface Paramsi3060FinanceWordReadPdfTagGet {
  // pathParams
  /**
   * 
   */
  tag: string;
}
/**
  * @description i3060FinanceWordReadWordWordIdGet参数
  * @property `wordId` 
  */
export interface Paramsi3060FinanceWordReadWordWordIdGet {
  // pathParams
  /**
   * 
   */
  wordId: string;
}
/**
  * @description i3060FinanceWordRenameWordIdPatch参数
  * @property `wordId` 项目文档id
  */
export interface Paramsi3060FinanceWordRenameWordIdPatch {
  // pathParams
  /**
   * 项目文档id
   */
  wordId: string;
}
/**
  */
export type ParamsBodyi3060FinanceWordRenameWordIdPatch = models.I3060FinanceWordRenameWordIdBody;
/**
  * @description i3060FinanceWordUploadFileFinanceIdPost headers 参数
  * @property `Authorization` Authorization (Only:undefined)
  */
export interface ParamsHeaderi3060FinanceWordUploadFileFinanceIdPost {
  /** Authorization (Only:undefined) */
  'Authorization': string;
}
/**
  * @description i3060FinanceWordUploadFileFinanceIdPost参数
  * @property `financeId` 财务方案id
  */
export interface Paramsi3060FinanceWordUploadFileFinanceIdPost {
  // pathParams
  /**
   * 财务方案id
   */
  financeId: string;
}
/**
  */
export type ParamsBodyi3060FinanceWordUploadFileFinanceIdPost = models.I3060FinanceWordUploadFileFinanceIdBody;
/**
  * @description i3060FinanceWordUploadFileListFinanceIdPost headers 参数
  * @property `Authorization` Authorization (Only:undefined)
  */
export interface ParamsHeaderi3060FinanceWordUploadFileListFinanceIdPost {
  /** Authorization (Only:undefined) */
  'Authorization': string;
}
/**
  * @description i3060FinanceWordUploadFileListFinanceIdPost参数
  * @property `financeId` 财务方案id
  */
export interface Paramsi3060FinanceWordUploadFileListFinanceIdPost {
  // pathParams
  /**
   * 财务方案id
   */
  financeId: string;
}
/**
  */
export type ParamsBodyi3060FinanceWordUploadFileListFinanceIdPost = Array<models.I3060FinanceWordUploadFileListFinanceIdBody>;
/**
  * @description i3060WordFillWordFinanceIdGet headers 参数
  * @property `Authorization` Authorization (Only:)
  */
export interface ParamsHeaderi3060WordFillWordFinanceIdGet {
  /** Authorization (Only:) */
  'Authorization': string;
}
/**
  * @description i3060WordFillWordFinanceIdGet参数
  * @property `financeId` 
  */
export interface Paramsi3060WordFillWordFinanceIdGet {
  // pathParams
  /**
   * 
   */
  financeId: string;
}
/**
  * @description i3060WordGetActivePowerChartGet参数
  * @property `financeId` 财务id
  */
export interface Paramsi3060WordGetActivePowerChartGet {
  // queryParams
  /**
   * 财务id
   */
  financeId: string;
}
/**
  * @description i3060WordGetYearYearPowerGet参数
  * @property `financeId` 财务方案id
  */
export interface Paramsi3060WordGetYearYearPowerGet {
  // queryParams
  /**
   * 财务方案id
   */
  financeId: string;
}

export class FinanceApi {
  protected $basePath = '/api'.replace(/\/$/, '');

  public constructor(basePath?: string) {
    if (basePath !== undefined) {
      this.$basePath = basePath.replace(/\/$/, '');
    }
  }

  /**
   * 
   * @summary 跨项目财务管理列表
   * @param params Paramsi3060FinanceAllGet
   
   * @param opt ajax config
   * @returns models.I3060FinanceAll
   */
  public i3060FinanceAllGet = (
    params: Paramsi3060FinanceAllGet,
    opt?: { headers: ParamsHeaderi3060FinanceAllGet } & Omit<ExtraFetchParams, 'headers'> 
  ) : AjaxPromise<models.I3060FinanceAll>  => {
    const url = this.$basePath + `/i3060/finance/all`;
    const p: any = {};
    p.query = {};
    if ('createTimeSort' in params) p.query.createTimeSort = params.createTimeSort;
    if ('updateTimeSort' in params) p.query.updateTimeSort = params.updateTimeSort;
    if ('marked' in params) p.query.marked = params.marked;
    if ('locked' in params) p.query.locked = params.locked;
    if ('size' in params) p.query.size = params.size;
    ajax.check(params.size, 'size');
    if ('page' in params) p.query.page = params.page;
    ajax.check(params.page, 'page');
    if ('creatorUserName' in params) p.query.creatorUserName = params.creatorUserName;
    if ('name' in params) p.query.name = params.name;
    if ('projectName' in params) p.query.projectName = params.projectName;
    if ('calcStatus' in params) p.query.calcStatus = params.calcStatus;
    ajax.check(params.calcStatus, 'calcStatus');
    return ajax.ajax({
      ...opt,
      method: 'GET',
      url,
      ...p
    });
  }
  /**
   * 
   * @summary 财务方案-参数库-删除激励补贴
   * @param params Paramsi3060FinanceAwardSubsidyAwardSubsidyIdDelete
   * @param data: ParamsBodyi3060FinanceAwardSubsidyAwardSubsidyIdDelete
   * @param opt ajax config
   * @returns models.AuthUserUpdate
   */
  public i3060FinanceAwardSubsidyAwardSubsidyIdDelete = (
    params: Paramsi3060FinanceAwardSubsidyAwardSubsidyIdDelete,
    data: ParamsBodyi3060FinanceAwardSubsidyAwardSubsidyIdDelete,opt?: ExtraFetchParams
  ) : AjaxPromise<models.AuthUserUpdate>  => {
    const {
    awardSubsidyId,
} = params;
    const url = this.$basePath + `/i3060/financeAwardSubsidy/${awardSubsidyId}`;
    const p: any = {};
    ajax.check(params.awardSubsidyId, 'awardSubsidyId');
    p.data = data;
    return ajax.ajax({
      ...opt,
      method: 'DELETE',
      url,
      ...p
    });
  }
  /**
   * 
   * @summary 财务方案-参数库-编辑激励补贴
   * @param params Paramsi3060FinanceAwardSubsidyAwardSubsidyIdPut
   * @param data: ParamsBodyi3060FinanceAwardSubsidyAwardSubsidyIdPut
   * @param opt ajax config
   * @returns models.AuthUserUpdate
   */
  public i3060FinanceAwardSubsidyAwardSubsidyIdPut = (
    params: Paramsi3060FinanceAwardSubsidyAwardSubsidyIdPut,
    data: ParamsBodyi3060FinanceAwardSubsidyAwardSubsidyIdPut,opt?: ExtraFetchParams
  ) : AjaxPromise<models.AuthUserUpdate>  => {
    const {
    awardSubsidyId,
} = params;
    const url = this.$basePath + `/i3060/financeAwardSubsidy/${awardSubsidyId}`;
    const p: any = {};
    ajax.check(params.awardSubsidyId, 'awardSubsidyId');
    p.data = data;
    return ajax.ajax({
      ...opt,
      method: 'PUT',
      url,
      ...p
    });
  }
  /**
   * 
   * @summary 财务方案-参数库-拷贝激励补贴
   * @param params Paramsi3060FinanceAwardSubsidyCopyAwardSubsidyIdPost
   * @param data: ParamsBodyi3060FinanceAwardSubsidyCopyAwardSubsidyIdPost
   * @param opt ajax config
   * @returns models.AuthUserUpdate
   */
  public i3060FinanceAwardSubsidyCopyAwardSubsidyIdPost = (
    params: Paramsi3060FinanceAwardSubsidyCopyAwardSubsidyIdPost,
    data: ParamsBodyi3060FinanceAwardSubsidyCopyAwardSubsidyIdPost,opt?: ExtraFetchParams
  ) : AjaxPromise<models.AuthUserUpdate>  => {
    const {
    awardSubsidyId,
} = params;
    const url = this.$basePath + `/i3060/financeAwardSubsidy/copy/${awardSubsidyId}`;
    const p: any = {};
    ajax.check(params.awardSubsidyId, 'awardSubsidyId');
    p.data = data;
    return ajax.ajax({
      ...opt,
      method: 'POST',
      url,
      ...p
    });
  }
  /**
   * 
   * @summary 财务方案-参数库-添加或编辑激励补贴
   * @param params Paramsi3060FinanceAwardSubsidyCreateOrUpdateBatchPost
   * @param data: ParamsBodyi3060FinanceAwardSubsidyCreateOrUpdateBatchPost
   * @param opt ajax config
   * @returns models.I3060FinanceAwardSubsidyEdit
   */
  public i3060FinanceAwardSubsidyCreateOrUpdateBatchPost = (
    params: Paramsi3060FinanceAwardSubsidyCreateOrUpdateBatchPost,
    data: ParamsBodyi3060FinanceAwardSubsidyCreateOrUpdateBatchPost,opt?: { headers: ParamsHeaderi3060FinanceAwardSubsidyCreateOrUpdateBatchPost } & Omit<ExtraFetchParams, 'headers'> 
  ) : AjaxPromise<models.I3060FinanceAwardSubsidyEdit>  => {
    const url = this.$basePath + `/i3060/financeAwardSubsidy/createOrUpdateBatch`;
    const p: any = {};
    p.data = data;
    return ajax.ajax({
      ...opt,
      method: 'POST',
      url,
      ...p
    });
  }
  /**
   * 
   * @summary 财务方案-参数库-激励补贴-设置为默认
   * @param params Paramsi3060FinanceAwardSubsidyDefaultAwardSubsidyIdPut
   * @param data: ParamsBodyi3060FinanceAwardSubsidyDefaultAwardSubsidyIdPut
   * @param opt ajax config
   * @returns models.AuthUserUpdate
   */
  public i3060FinanceAwardSubsidyDefaultAwardSubsidyIdPut = (
    params: Paramsi3060FinanceAwardSubsidyDefaultAwardSubsidyIdPut,
    data: ParamsBodyi3060FinanceAwardSubsidyDefaultAwardSubsidyIdPut,opt?: ExtraFetchParams
  ) : AjaxPromise<models.AuthUserUpdate>  => {
    const {
    awardSubsidyId,
} = params;
    const url = this.$basePath + `/i3060/financeAwardSubsidy/default/${awardSubsidyId}`;
    const p: any = {};
    ajax.check(params.awardSubsidyId, 'awardSubsidyId');
    p.data = data;
    return ajax.ajax({
      ...opt,
      method: 'PUT',
      url,
      ...p
    });
  }
  /**
   * 
   * @summary 财务方案-参数库-激励补贴-删除补贴激励
   * @param params Paramsi3060FinanceAwardSubsidyDetailAwardSubsidyDetailIdDelete
   * @param data: ParamsBodyi3060FinanceAwardSubsidyDetailAwardSubsidyDetailIdDelete
   * @param opt ajax config
   * @returns models.AuthUserUpdate
   */
  public i3060FinanceAwardSubsidyDetailAwardSubsidyDetailIdDelete = (
    params: Paramsi3060FinanceAwardSubsidyDetailAwardSubsidyDetailIdDelete,
    data: ParamsBodyi3060FinanceAwardSubsidyDetailAwardSubsidyDetailIdDelete,opt?: ExtraFetchParams
  ) : AjaxPromise<models.AuthUserUpdate>  => {
    const {
    awardSubsidyDetailId,
} = params;
    const url = this.$basePath + `/i3060/financeAwardSubsidyDetail/${awardSubsidyDetailId}`;
    const p: any = {};
    ajax.check(params.awardSubsidyDetailId, 'awardSubsidyDetailId');
    p.data = data;
    return ajax.ajax({
      ...opt,
      method: 'DELETE',
      url,
      ...p
    });
  }
  /**
   * 
   * @summary 财务方案-参数库-激励补贴-编辑补贴激励
   * @param params Paramsi3060FinanceAwardSubsidyDetailAwardSubsidyDetailIdPut
   * @param data: ParamsBodyi3060FinanceAwardSubsidyDetailAwardSubsidyDetailIdPut
   * @param opt ajax config
   * @returns models.AuthUserUpdate
   */
  public i3060FinanceAwardSubsidyDetailAwardSubsidyDetailIdPut = (
    params: Paramsi3060FinanceAwardSubsidyDetailAwardSubsidyDetailIdPut,
    data: ParamsBodyi3060FinanceAwardSubsidyDetailAwardSubsidyDetailIdPut,opt?: ExtraFetchParams
  ) : AjaxPromise<models.AuthUserUpdate>  => {
    const {
    awardSubsidyDetailId,
} = params;
    const url = this.$basePath + `/i3060/financeAwardSubsidyDetail/${awardSubsidyDetailId}`;
    const p: any = {};
    ajax.check(params.awardSubsidyDetailId, 'awardSubsidyDetailId');
    p.data = data;
    return ajax.ajax({
      ...opt,
      method: 'PUT',
      url,
      ...p
    });
  }
  /**
   * 
   * @summary 财务方案-参数库-激励补贴-创建补贴激励
   * @param params Paramsi3060FinanceAwardSubsidyDetailAwardSubsidyIdPost
   * @param data: ParamsBodyi3060FinanceAwardSubsidyDetailAwardSubsidyIdPost
   * @param opt ajax config
   * @returns models.AuthUserUpdate
   */
  public i3060FinanceAwardSubsidyDetailAwardSubsidyIdPost = (
    params: Paramsi3060FinanceAwardSubsidyDetailAwardSubsidyIdPost,
    data: ParamsBodyi3060FinanceAwardSubsidyDetailAwardSubsidyIdPost,opt?: ExtraFetchParams
  ) : AjaxPromise<models.AuthUserUpdate>  => {
    const {
    awardSubsidyId,
} = params;
    const url = this.$basePath + `/i3060/financeAwardSubsidyDetail/${awardSubsidyId}`;
    const p: any = {};
    ajax.check(params.awardSubsidyId, 'awardSubsidyId');
    p.data = data;
    return ajax.ajax({
      ...opt,
      method: 'POST',
      url,
      ...p
    });
  }
  /**
   * 
   * @summary 财务方案-参数库-激励补贴-获取补贴激励名称
   * @param params Paramsi3060FinanceAwardSubsidyDetailDetailNameAwardSubsidyIdGet
   
   * @param opt ajax config
   * @returns models.I3060FinanceAwardSubsidyDetailDetailNameAwardSubsidyId
   */
  public i3060FinanceAwardSubsidyDetailDetailNameAwardSubsidyIdGet = (
    params: Paramsi3060FinanceAwardSubsidyDetailDetailNameAwardSubsidyIdGet,
    opt?: { headers: ParamsHeaderi3060FinanceAwardSubsidyDetailDetailNameAwardSubsidyIdGet } & Omit<ExtraFetchParams, 'headers'> 
  ) : AjaxPromise<models.I3060FinanceAwardSubsidyDetailDetailNameAwardSubsidyId>  => {
    const {
    awardSubsidyId,
} = params;
    const url = this.$basePath + `/i3060/financeAwardSubsidyDetail/detailName/${awardSubsidyId}`;
    const p: any = {};
    ajax.check(params.awardSubsidyId, 'awardSubsidyId');
    return ajax.ajax({
      ...opt,
      method: 'GET',
      url,
      ...p
    });
  }
  /**
   * 
   * @summary 财务方案-参数库-激励补贴-查询补贴激励列表
   * @param params Paramsi3060FinanceAwardSubsidyDetailGetDetailListGet
   
   * @param opt ajax config
   * @returns models.I3060FinanceAwardSubsidyDetailGetDetailList
   */
  public i3060FinanceAwardSubsidyDetailGetDetailListGet = (
    params: Paramsi3060FinanceAwardSubsidyDetailGetDetailListGet,
    opt?: { headers: ParamsHeaderi3060FinanceAwardSubsidyDetailGetDetailListGet } & Omit<ExtraFetchParams, 'headers'> 
  ) : AjaxPromise<models.I3060FinanceAwardSubsidyDetailGetDetailList>  => {
    const url = this.$basePath + `/i3060/financeAwardSubsidyDetail/getDetailList`;
    const p: any = {};
    return ajax.ajax({
      ...opt,
      method: 'GET',
      url,
      ...p
    });
  }
  /**
   * 
   * @summary 财务方案-参数库-激励补贴-获取补贴激励详情
   * @param params Paramsi3060FinanceAwardSubsidyDetailInfoAwardSubsidyDetailIdGet
   
   * @param opt ajax config
   * @returns models.I3060FinanceAwardSubsidyDetailInfoAwardSubsidyDetailId
   */
  public i3060FinanceAwardSubsidyDetailInfoAwardSubsidyDetailIdGet = (
    params: Paramsi3060FinanceAwardSubsidyDetailInfoAwardSubsidyDetailIdGet,
    opt?: { headers: ParamsHeaderi3060FinanceAwardSubsidyDetailInfoAwardSubsidyDetailIdGet } & Omit<ExtraFetchParams, 'headers'> 
  ) : AjaxPromise<models.I3060FinanceAwardSubsidyDetailInfoAwardSubsidyDetailId>  => {
    const {
    awardSubsidyDetailId,
} = params;
    const url = this.$basePath + `/i3060/financeAwardSubsidyDetail/info/${awardSubsidyDetailId}`;
    const p: any = {};
    ajax.check(params.awardSubsidyDetailId, 'awardSubsidyDetailId');
    return ajax.ajax({
      ...opt,
      method: 'GET',
      url,
      ...p
    });
  }
  /**
   * 
   * @summary 财务方案-参数库-编辑激励补贴
   * @param params Paramsi3060FinanceAwardSubsidyEditPost
   * @param data: ParamsBodyi3060FinanceAwardSubsidyEditPost
   * @param opt ajax config
   * @returns models.I3060FinanceAwardSubsidyEdit
   */
  public i3060FinanceAwardSubsidyEditPost = (
    params: Paramsi3060FinanceAwardSubsidyEditPost,
    data: ParamsBodyi3060FinanceAwardSubsidyEditPost,opt?: { headers: ParamsHeaderi3060FinanceAwardSubsidyEditPost } & Omit<ExtraFetchParams, 'headers'> 
  ) : AjaxPromise<models.I3060FinanceAwardSubsidyEdit>  => {
    const url = this.$basePath + `/i3060/financeAwardSubsidy/edit`;
    const p: any = {};
    p.data = data;
    return ajax.ajax({
      ...opt,
      method: 'POST',
      url,
      ...p
    });
  }
  /**
   * 
   * @summary 财务方案-参数库-获取激励补贴详情
   * @param params Paramsi3060FinanceAwardSubsidyInfoAwardSubsidyIdGet
   
   * @param opt ajax config
   * @returns models.I3060FinanceAwardSubsidyInfoAwardSubsidyId
   */
  public i3060FinanceAwardSubsidyInfoAwardSubsidyIdGet = (
    params: Paramsi3060FinanceAwardSubsidyInfoAwardSubsidyIdGet,
    opt?: { headers: ParamsHeaderi3060FinanceAwardSubsidyInfoAwardSubsidyIdGet } & Omit<ExtraFetchParams, 'headers'> 
  ) : AjaxPromise<models.I3060FinanceAwardSubsidyInfoAwardSubsidyId>  => {
    const {
    awardSubsidyId,
} = params;
    const url = this.$basePath + `/i3060/financeAwardSubsidy/info/${awardSubsidyId}`;
    const p: any = {};
    ajax.check(params.awardSubsidyId, 'awardSubsidyId');
    return ajax.ajax({
      ...opt,
      method: 'GET',
      url,
      ...p
    });
  }
  /**
   * 
   * @summary 财务方案-参数库-创建激励补贴
   
   * @param data: ParamsBodyi3060FinanceAwardSubsidyPost
   * @param opt ajax config
   * @returns models.I3060FinanceAwardSubsidy
   */
  public i3060FinanceAwardSubsidyPost = (
    
    data: ParamsBodyi3060FinanceAwardSubsidyPost,opt?: ExtraFetchParams
  ) : AjaxPromise<models.I3060FinanceAwardSubsidy>  => {
    const url = this.$basePath + `/i3060/financeAwardSubsidy`;
    const p: any = {};
    p.data = data;
    return ajax.ajax({
      ...opt,
      method: 'POST',
      url,
      ...p
    });
  }
  /**
   * 
   * @summary 财务方案-方案列表-批量计算
   * @param params Paramsi3060FinanceBatchCalculateFinanceIdPost
   * @param data: ParamsBodyi3060FinanceBatchCalculateFinanceIdPost
   * @param opt ajax config
   * @returns models.AuthUserSendCheckEmail
   */
  public i3060FinanceBatchCalculateFinanceIdPost = (
    params: Paramsi3060FinanceBatchCalculateFinanceIdPost,
    data: ParamsBodyi3060FinanceBatchCalculateFinanceIdPost,opt?: ExtraFetchParams
  ) : AjaxPromise<models.AuthUserSendCheckEmail>  => {
    const {
    financeId,
} = params;
    const url = this.$basePath + `/i3060/finance/batchCalculate/${financeId}`;
    const p: any = {};
    ajax.check(params.financeId, 'financeId');
    p.data = data;
    return ajax.ajax({
      ...opt,
      method: 'POST',
      url,
      ...p
    });
  }
  /**
   * 
   * @summary 财务方案-方案列表-计算
   * @param params Paramsi3060FinanceCalculateProjectIdPost
   * @param data: ParamsBodyi3060FinanceCalculateProjectIdPost
   * @param opt ajax config
   * @returns models.AuthUserUpdate
   */
  public i3060FinanceCalculateProjectIdPost = (
    params: Paramsi3060FinanceCalculateProjectIdPost,
    data: ParamsBodyi3060FinanceCalculateProjectIdPost,opt?: ExtraFetchParams
  ) : AjaxPromise<models.AuthUserUpdate>  => {
    const {
    projectId,
} = params;
    const url = this.$basePath + `/i3060/finance/calculate/${projectId}`;
    const p: any = {};
    ajax.check(params.projectId, 'projectId');
    p.data = data;
    return ajax.ajax({
      ...opt,
      method: 'POST',
      url,
      ...p
    });
  }
  /**
   * 
   * @summary 财务方案-参数库-拷贝财务成本
   * @param params Paramsi3060FinanceCostCopyFinanceCostIdPost
   * @param data: ParamsBodyi3060FinanceCostCopyFinanceCostIdPost
   * @param opt ajax config
   * @returns models.AuthUserUpdate
   */
  public i3060FinanceCostCopyFinanceCostIdPost = (
    params: Paramsi3060FinanceCostCopyFinanceCostIdPost,
    data: ParamsBodyi3060FinanceCostCopyFinanceCostIdPost,opt?: ExtraFetchParams
  ) : AjaxPromise<models.AuthUserUpdate>  => {
    const {
    financeCostId,
} = params;
    const url = this.$basePath + `/i3060/financeCost/copy/${financeCostId}`;
    const p: any = {};
    ajax.check(params.financeCostId, 'financeCostId');
    p.data = data;
    return ajax.ajax({
      ...opt,
      method: 'POST',
      url,
      ...p
    });
  }
  /**
   * 
   * @summary 财务方案-参数库-批量保存或编辑财务成本
   
   * @param data: ParamsBodyi3060FinanceCostCreateOrEditBatchPost
   * @param opt ajax config
   * @returns models.AuthUserUpdate
   */
  public i3060FinanceCostCreateOrEditBatchPost = (
    
    data: ParamsBodyi3060FinanceCostCreateOrEditBatchPost,opt?: ExtraFetchParams
  ) : AjaxPromise<models.AuthUserUpdate>  => {
    const url = this.$basePath + `/i3060/financeCost/createOrEditBatch`;
    const p: any = {};
    p.data = data;
    return ajax.ajax({
      ...opt,
      method: 'POST',
      url,
      ...p
    });
  }
  /**
   * 
   * @summary 财务方案-参数库-财务成本-设置为默认
   * @param params Paramsi3060FinanceCostDefaultFinanceCostIdPut
   * @param data: ParamsBodyi3060FinanceCostDefaultFinanceCostIdPut
   * @param opt ajax config
   * @returns models.AuthUserUpdate
   */
  public i3060FinanceCostDefaultFinanceCostIdPut = (
    params: Paramsi3060FinanceCostDefaultFinanceCostIdPut,
    data: ParamsBodyi3060FinanceCostDefaultFinanceCostIdPut,opt?: ExtraFetchParams
  ) : AjaxPromise<models.AuthUserUpdate>  => {
    const {
    financeCostId,
} = params;
    const url = this.$basePath + `/i3060/financeCost/default/${financeCostId}`;
    const p: any = {};
    ajax.check(params.financeCostId, 'financeCostId');
    p.data = data;
    return ajax.ajax({
      ...opt,
      method: 'PUT',
      url,
      ...p
    });
  }
  /**
   * 
   * @summary 财务方案-参数库-编辑财务成本
   
   * @param data: ParamsBodyi3060FinanceCostEditPost
   * @param opt ajax config
   * @returns models.AuthUserUpdate
   */
  public i3060FinanceCostEditPost = (
    
    data: ParamsBodyi3060FinanceCostEditPost,opt?: ExtraFetchParams
  ) : AjaxPromise<models.AuthUserUpdate>  => {
    const url = this.$basePath + `/i3060/financeCost/edit`;
    const p: any = {};
    p.data = data;
    return ajax.ajax({
      ...opt,
      method: 'POST',
      url,
      ...p
    });
  }
  /**
   * 
   * @summary 财务方案-参数库-删除财务成本
   * @param params Paramsi3060FinanceCostFinanceCostIdDelete
   * @param data: ParamsBodyi3060FinanceCostFinanceCostIdDelete
   * @param opt ajax config
   * @returns models.AuthUserUpdate
   */
  public i3060FinanceCostFinanceCostIdDelete = (
    params: Paramsi3060FinanceCostFinanceCostIdDelete,
    data: ParamsBodyi3060FinanceCostFinanceCostIdDelete,opt?: ExtraFetchParams
  ) : AjaxPromise<models.AuthUserUpdate>  => {
    const {
    financeCostId,
} = params;
    const url = this.$basePath + `/i3060/financeCost/${financeCostId}`;
    const p: any = {};
    ajax.check(params.financeCostId, 'financeCostId');
    p.data = data;
    return ajax.ajax({
      ...opt,
      method: 'DELETE',
      url,
      ...p
    });
  }
  /**
   * 
   * @summary 财务方案-参数组配置-财务成本-获取默认值
   
   
   * @param opt ajax config
   * @returns models.I3060FinanceCostGetDefault
   */
  public i3060FinanceCostGetDefaultGet = (
    
    opt?: ExtraFetchParams
  ) : AjaxPromise<models.I3060FinanceCostGetDefault>  => {
    const url = this.$basePath + `/i3060/financeCost/getDefault`;
    const p: any = {};
    return ajax.ajax({
      ...opt,
      method: 'GET',
      url,
      ...p
    });
  }
  /**
   * 
   * @summary 财务方案-参数库-获取财务成本详情
   * @param params Paramsi3060FinanceCostInfoFinanceCostIdGet
   
   * @param opt ajax config
   * @returns models.I3060FinanceCostInfoFinanceCostId
   */
  public i3060FinanceCostInfoFinanceCostIdGet = (
    params: Paramsi3060FinanceCostInfoFinanceCostIdGet,
    opt?: ExtraFetchParams
  ) : AjaxPromise<models.I3060FinanceCostInfoFinanceCostId>  => {
    const {
    financeCostId,
} = params;
    const url = this.$basePath + `/i3060/financeCost/info/${financeCostId}`;
    const p: any = {};
    ajax.check(params.financeCostId, 'financeCostId');
    return ajax.ajax({
      ...opt,
      method: 'GET',
      url,
      ...p
    });
  }
  /**
   * 
   * @summary 财务方案-参数库-财务成本-获取当前用户下所有
   * @param params Paramsi3060FinanceCostListGet
   
   * @param opt ajax config
   * @returns models.I3060FinanceCostList
   */
  public i3060FinanceCostListGet = (
    params: Paramsi3060FinanceCostListGet,
    opt?: { headers: ParamsHeaderi3060FinanceCostListGet } & Omit<ExtraFetchParams, 'headers'> 
  ) : AjaxPromise<models.I3060FinanceCostList>  => {
    const url = this.$basePath + `/i3060/financeCost/list`;
    const p: any = {};
    return ajax.ajax({
      ...opt,
      method: 'GET',
      url,
      ...p
    });
  }
  /**
   * 
   * @summary 财务方案-参数库-创建财务成本
   
   * @param data: ParamsBodyi3060FinanceCostPost
   * @param opt ajax config
   * @returns models.I3060FinanceCostInfoFinanceCostId
   */
  public i3060FinanceCostPost = (
    
    data: ParamsBodyi3060FinanceCostPost,opt?: ExtraFetchParams
  ) : AjaxPromise<models.I3060FinanceCostInfoFinanceCostId>  => {
    const url = this.$basePath + `/i3060/financeCost`;
    const p: any = {};
    p.data = data;
    return ajax.ajax({
      ...opt,
      method: 'POST',
      url,
      ...p
    });
  }
  /**
   * 
   * @summary 删除财务方案
   * @param params Paramsi3060FinanceFinanceIdDelete
   
   * @param opt ajax config
   * @returns models.AuthUserSendCheckEmail
   */
  public i3060FinanceFinanceIdDelete = (
    params: Paramsi3060FinanceFinanceIdDelete,
    opt?: { headers: ParamsHeaderi3060FinanceFinanceIdDelete } & Omit<ExtraFetchParams, 'headers'> 
  ) : AjaxPromise<models.AuthUserSendCheckEmail>  => {
    const {
    financeId,
} = params;
    const url = this.$basePath + `/i3060/finance/${financeId}`;
    const p: any = {};
    ajax.check(params.financeId, 'financeId');
    return ajax.ajax({
      ...opt,
      method: 'DELETE',
      url,
      ...p
    });
  }
  /**
   * 
   * @summary 财务方案-方案列表-获取透视分析查询类型
   * @param params Paramsi3060FinanceGetPerspectiveAnalyseQueryTypeGet
   
   * @param opt ajax config
   * @returns models.I3060FinanceGetPerspectiveAnalyseQueryType
   */
  public i3060FinanceGetPerspectiveAnalyseQueryTypeGet = (
    params: Paramsi3060FinanceGetPerspectiveAnalyseQueryTypeGet,
    opt?: { headers: ParamsHeaderi3060FinanceGetPerspectiveAnalyseQueryTypeGet } & Omit<ExtraFetchParams, 'headers'> 
  ) : AjaxPromise<models.I3060FinanceGetPerspectiveAnalyseQueryType>  => {
    const url = this.$basePath + `/i3060/finance/getPerspectiveAnalyseQueryType`;
    const p: any = {};
    return ajax.ajax({
      ...opt,
      method: 'GET',
      url,
      ...p
    });
  }
  /**
   * 
   * @summary 查询警告弹窗
   * @param params Paramsi3060FinanceLibraryWarnToastGet
   
   * @param opt ajax config
   * @returns models.I3060FinanceLibraryWarnToast
   */
  public i3060FinanceLibraryWarnToastGet = (
    params: Paramsi3060FinanceLibraryWarnToastGet,
    opt?: { headers: ParamsHeaderi3060FinanceLibraryWarnToastGet } & Omit<ExtraFetchParams, 'headers'> 
  ) : AjaxPromise<models.I3060FinanceLibraryWarnToast>  => {
    const url = this.$basePath + `/i3060/financeLibrary/warnToast`;
    const p: any = {};
    p.query = {};
    if ('type' in params) p.query.type = params.type;
    ajax.check(params.type, 'type');
    if ('ids' in params) p.query.ids = params.ids;
    ajax.check(params.ids, 'ids');
    return ajax.ajax({
      ...opt,
      method: 'GET',
      url,
      ...p
    });
  }
  /**
   * 
   * @summary 财务管理列表
   * @param params Paramsi3060FinanceListProjectIdGet
   
   * @param opt ajax config
   * @returns models.I3060FinanceListProjectId
   */
  public i3060FinanceListProjectIdGet = (
    params: Paramsi3060FinanceListProjectIdGet,
    opt?: { headers: ParamsHeaderi3060FinanceListProjectIdGet } & Omit<ExtraFetchParams, 'headers'> 
  ) : AjaxPromise<models.I3060FinanceListProjectId>  => {
    const {
    projectId,
} = params;
    const url = this.$basePath + `/i3060/finance/list/${projectId}`;
    const p: any = {};
    p.query = {};
    if ('createTimeSort' in params) p.query.createTimeSort = params.createTimeSort;
    if ('updateTimeSort' in params) p.query.updateTimeSort = params.updateTimeSort;
    if ('marked' in params) p.query.marked = params.marked;
    if ('locked' in params) p.query.locked = params.locked;
    if ('innerYieldRateMin' in params) p.query.innerYieldRateMin = params.innerYieldRateMin;
    if ('innerYieldRateMax' in params) p.query.innerYieldRateMax = params.innerYieldRateMax;
    if ('netPresentValueMin' in params) p.query.netPresentValueMin = params.netPresentValueMin;
    if ('netPresentValueMax' in params) p.query.netPresentValueMax = params.netPresentValueMax;
    if ('returnInvestmentMin' in params) p.query.returnInvestmentMin = params.returnInvestmentMin;
    if ('returnInvestmentMax' in params) p.query.returnInvestmentMax = params.returnInvestmentMax;
    if ('staticInvestmentPaybackPeriodMin' in params) p.query.staticInvestmentPaybackPeriodMin = params.staticInvestmentPaybackPeriodMin;
    if ('staticInvestmentPaybackPeriodMax' in params) p.query.staticInvestmentPaybackPeriodMax = params.staticInvestmentPaybackPeriodMax;
    if ('initialInvestmentMin' in params) p.query.initialInvestmentMin = params.initialInvestmentMin;
    if ('initialInvestmentMax' in params) p.query.initialInvestmentMax = params.initialInvestmentMax;
    if ('totalRevenueMin' in params) p.query.totalRevenueMin = params.totalRevenueMin;
    if ('totalRevenueMax' in params) p.query.totalRevenueMax = params.totalRevenueMax;
    if ('cumulativeCashFlowMin' in params) p.query.cumulativeCashFlowMin = params.cumulativeCashFlowMin;
    if ('cumulativeCashFlowMax' in params) p.query.cumulativeCashFlowMax = params.cumulativeCashFlowMax;
    if ('netProfitMarginOfCapitalMin' in params) p.query.netProfitMarginOfCapitalMin = params.netProfitMarginOfCapitalMin;
    ajax.check(params.netProfitMarginOfCapitalMin, 'netProfitMarginOfCapitalMin');
    if ('netProfitMarginOfCapitalMax' in params) p.query.netProfitMarginOfCapitalMax = params.netProfitMarginOfCapitalMax;
    ajax.check(params.netProfitMarginOfCapitalMax, 'netProfitMarginOfCapitalMax');
    if ('page' in params) p.query.page = params.page;
    ajax.check(params.page, 'page');
    if ('size' in params) p.query.size = params.size;
    ajax.check(params.size, 'size');
    if ('innerYieldRateSort' in params) p.query.innerYieldRateSort = params.innerYieldRateSort;
    ajax.check(params.innerYieldRateSort, 'innerYieldRateSort');
    if ('netPresentValueSort' in params) p.query.netPresentValueSort = params.netPresentValueSort;
    ajax.check(params.netPresentValueSort, 'netPresentValueSort');
    if ('returnInvestmentSort' in params) p.query.returnInvestmentSort = params.returnInvestmentSort;
    ajax.check(params.returnInvestmentSort, 'returnInvestmentSort');
    if ('staticInvestmentPaybackPeriodSort' in params) p.query.staticInvestmentPaybackPeriodSort = params.staticInvestmentPaybackPeriodSort;
    ajax.check(params.staticInvestmentPaybackPeriodSort, 'staticInvestmentPaybackPeriodSort');
    if ('initialInvestmentSort' in params) p.query.initialInvestmentSort = params.initialInvestmentSort;
    ajax.check(params.initialInvestmentSort, 'initialInvestmentSort');
    if ('totalRevenueSort' in params) p.query.totalRevenueSort = params.totalRevenueSort;
    ajax.check(params.totalRevenueSort, 'totalRevenueSort');
    if ('cumulativeCashFlowSort' in params) p.query.cumulativeCashFlowSort = params.cumulativeCashFlowSort;
    ajax.check(params.cumulativeCashFlowSort, 'cumulativeCashFlowSort');
    if ('netProfitMarginOfCapitalSort' in params) p.query.netProfitMarginOfCapitalSort = params.netProfitMarginOfCapitalSort;
    ajax.check(params.netProfitMarginOfCapitalSort, 'netProfitMarginOfCapitalSort');
    if ('markedSort' in params) p.query.markedSort = params.markedSort;
    ajax.check(params.markedSort, 'markedSort');
    if ('lockedSort' in params) p.query.lockedSort = params.lockedSort;
    ajax.check(params.lockedSort, 'lockedSort');
    ajax.check(params.projectId, 'projectId');
    return ajax.ajax({
      ...opt,
      method: 'GET',
      url,
      ...p
    });
  }
  /**
   * 
   * @summary 财务方案归档
   * @param params Paramsi3060FinanceLockFinanceIdPatch
   * @param data: ParamsBodyi3060FinanceLockFinanceIdPatch
   * @param opt ajax config
   * @returns models.AuthUserSendCheckEmail
   */
  public i3060FinanceLockFinanceIdPatch = (
    params: Paramsi3060FinanceLockFinanceIdPatch,
    data: ParamsBodyi3060FinanceLockFinanceIdPatch,opt?: { headers: ParamsHeaderi3060FinanceLockFinanceIdPatch } & Omit<ExtraFetchParams, 'headers'> 
  ) : AjaxPromise<models.AuthUserSendCheckEmail>  => {
    const {
    financeId,
} = params;
    const url = this.$basePath + `/i3060/finance/lock/${financeId}`;
    const p: any = {};
    ajax.check(params.financeId, 'financeId');
    p.data = data;
    return ajax.ajax({
      ...opt,
      method: 'PATCH',
      url,
      ...p
    });
  }
  /**
   * 
   * @summary 财务方案标记
   * @param params Paramsi3060FinanceMarkFinanceIdPatch
   * @param data: ParamsBodyi3060FinanceMarkFinanceIdPatch
   * @param opt ajax config
   * @returns models.AuthUserSendCheckEmail
   */
  public i3060FinanceMarkFinanceIdPatch = (
    params: Paramsi3060FinanceMarkFinanceIdPatch,
    data: ParamsBodyi3060FinanceMarkFinanceIdPatch,opt?: { headers: ParamsHeaderi3060FinanceMarkFinanceIdPatch } & Omit<ExtraFetchParams, 'headers'> 
  ) : AjaxPromise<models.AuthUserSendCheckEmail>  => {
    const {
    financeId,
} = params;
    const url = this.$basePath + `/i3060/finance/mark/${financeId}`;
    const p: any = {};
    ajax.check(params.financeId, 'financeId');
    p.data = data;
    return ajax.ajax({
      ...opt,
      method: 'PATCH',
      url,
      ...p
    });
  }
  /**
   * 
   * @summary 财务方案-参数库-拷贝运营成本
   * @param params Paramsi3060FinanceOperationCostCopyOperationCostIdPost
   * @param data: ParamsBodyi3060FinanceOperationCostCopyOperationCostIdPost
   * @param opt ajax config
   * @returns models.AuthUserUpdate
   */
  public i3060FinanceOperationCostCopyOperationCostIdPost = (
    params: Paramsi3060FinanceOperationCostCopyOperationCostIdPost,
    data: ParamsBodyi3060FinanceOperationCostCopyOperationCostIdPost,opt?: ExtraFetchParams
  ) : AjaxPromise<models.AuthUserUpdate>  => {
    const {
    operationCostId,
} = params;
    const url = this.$basePath + `/i3060/financeOperationCost/copy/${operationCostId}`;
    const p: any = {};
    ajax.check(params.operationCostId, 'operationCostId');
    p.data = data;
    return ajax.ajax({
      ...opt,
      method: 'POST',
      url,
      ...p
    });
  }
  /**
   * 
   * @summary 财务方案-参数库-批量保存或编辑运营成本
   
   * @param data: ParamsBodyi3060FinanceOperationCostCreateOrEditBatchPost
   * @param opt ajax config
   * @returns models.AuthUserUpdate
   */
  public i3060FinanceOperationCostCreateOrEditBatchPost = (
    
    data: ParamsBodyi3060FinanceOperationCostCreateOrEditBatchPost,opt?: ExtraFetchParams
  ) : AjaxPromise<models.AuthUserUpdate>  => {
    const url = this.$basePath + `/i3060/financeOperationCost/createOrEditBatch`;
    const p: any = {};
    p.data = data;
    return ajax.ajax({
      ...opt,
      method: 'POST',
      url,
      ...p
    });
  }
  /**
   * 
   * @summary 财务方案-参数库-运营成本-设置为默认
   * @param params Paramsi3060FinanceOperationCostDefaultOperationCostIdPut
   * @param data: ParamsBodyi3060FinanceOperationCostDefaultOperationCostIdPut
   * @param opt ajax config
   * @returns models.AuthUserUpdate
   */
  public i3060FinanceOperationCostDefaultOperationCostIdPut = (
    params: Paramsi3060FinanceOperationCostDefaultOperationCostIdPut,
    data: ParamsBodyi3060FinanceOperationCostDefaultOperationCostIdPut,opt?: ExtraFetchParams
  ) : AjaxPromise<models.AuthUserUpdate>  => {
    const {
    operationCostId,
} = params;
    const url = this.$basePath + `/i3060/financeOperationCost/default/${operationCostId}`;
    const p: any = {};
    ajax.check(params.operationCostId, 'operationCostId');
    p.data = data;
    return ajax.ajax({
      ...opt,
      method: 'PUT',
      url,
      ...p
    });
  }
  /**
   * 
   * @summary 财务方案-参数库-编辑运营成本
   
   * @param data: ParamsBodyi3060FinanceOperationCostEditPost
   * @param opt ajax config
   * @returns models.AuthUserUpdate
   */
  public i3060FinanceOperationCostEditPost = (
    
    data: ParamsBodyi3060FinanceOperationCostEditPost,opt?: ExtraFetchParams
  ) : AjaxPromise<models.AuthUserUpdate>  => {
    const url = this.$basePath + `/i3060/financeOperationCost/edit`;
    const p: any = {};
    p.data = data;
    return ajax.ajax({
      ...opt,
      method: 'POST',
      url,
      ...p
    });
  }
  /**
   * 
   * @summary 财务方案-参数组配置-运营成本-获取默认值
   
   
   * @param opt ajax config
   * @returns models.I3060FinanceOperationCostGetDefault
   */
  public i3060FinanceOperationCostGetDefaultGet = (
    
    opt?: ExtraFetchParams
  ) : AjaxPromise<models.I3060FinanceOperationCostGetDefault>  => {
    const url = this.$basePath + `/i3060/financeOperationCost/getDefault`;
    const p: any = {};
    return ajax.ajax({
      ...opt,
      method: 'GET',
      url,
      ...p
    });
  }
  /**
   * 
   * @summary 财务方案-参数库-获取运营成本详情
   * @param params Paramsi3060FinanceOperationCostInfoOperationCostIdGet
   
   * @param opt ajax config
   * @returns models.I3060FinanceOperationCostInfoOperationCostId
   */
  public i3060FinanceOperationCostInfoOperationCostIdGet = (
    params: Paramsi3060FinanceOperationCostInfoOperationCostIdGet,
    opt?: ExtraFetchParams
  ) : AjaxPromise<models.I3060FinanceOperationCostInfoOperationCostId>  => {
    const {
    operationCostId,
} = params;
    const url = this.$basePath + `/i3060/financeOperationCost/info/${operationCostId}`;
    const p: any = {};
    ajax.check(params.operationCostId, 'operationCostId');
    return ajax.ajax({
      ...opt,
      method: 'GET',
      url,
      ...p
    });
  }
  /**
   * 
   * @summary 财务方案-参数库-运营成本-获取当前用户下所有
   * @param params Paramsi3060FinanceOperationCostListGet
   
   * @param opt ajax config
   * @returns models.I3060FinanceOperationCostList
   */
  public i3060FinanceOperationCostListGet = (
    params: Paramsi3060FinanceOperationCostListGet,
    opt?: { headers: ParamsHeaderi3060FinanceOperationCostListGet } & Omit<ExtraFetchParams, 'headers'> 
  ) : AjaxPromise<models.I3060FinanceOperationCostList>  => {
    const url = this.$basePath + `/i3060/financeOperationCost/list`;
    const p: any = {};
    return ajax.ajax({
      ...opt,
      method: 'GET',
      url,
      ...p
    });
  }
  /**
   * 
   * @summary 财务方案-参数库-删除运营成本
   * @param params Paramsi3060FinanceOperationCostOperationCostIdDelete
   * @param data: ParamsBodyi3060FinanceOperationCostOperationCostIdDelete
   * @param opt ajax config
   * @returns models.AuthUserUpdate
   */
  public i3060FinanceOperationCostOperationCostIdDelete = (
    params: Paramsi3060FinanceOperationCostOperationCostIdDelete,
    data: ParamsBodyi3060FinanceOperationCostOperationCostIdDelete,opt?: ExtraFetchParams
  ) : AjaxPromise<models.AuthUserUpdate>  => {
    const {
    operationCostId,
} = params;
    const url = this.$basePath + `/i3060/financeOperationCost/${operationCostId}`;
    const p: any = {};
    ajax.check(params.operationCostId, 'operationCostId');
    p.data = data;
    return ajax.ajax({
      ...opt,
      method: 'DELETE',
      url,
      ...p
    });
  }
  /**
   * 
   * @summary 财务方案-参数库-创建运营成本
   
   * @param data: ParamsBodyi3060FinanceOperationCostPost
   * @param opt ajax config
   * @returns models.I3060FinanceOperationCostInfoOperationCostId
   */
  public i3060FinanceOperationCostPost = (
    
    data: ParamsBodyi3060FinanceOperationCostPost,opt?: ExtraFetchParams
  ) : AjaxPromise<models.I3060FinanceOperationCostInfoOperationCostId>  => {
    const url = this.$basePath + `/i3060/financeOperationCost`;
    const p: any = {};
    p.data = data;
    return ajax.ajax({
      ...opt,
      method: 'POST',
      url,
      ...p
    });
  }
  /**
   * 
   * @summary 导出财务报告
   * @param params Paramsi3060FinanceOutputReportFinanceIdGet
   
   * @param opt ajax config
   * @returns any
   */
  public i3060FinanceOutputReportFinanceIdGet = (
    params: Paramsi3060FinanceOutputReportFinanceIdGet,
    opt?: { headers: ParamsHeaderi3060FinanceOutputReportFinanceIdGet } & Omit<ExtraFetchParams, 'headers'> 
  ) : AjaxPromise<any>  => {
    const {
    financeId,
} = params;
    const url = this.$basePath + `/i3060/finance/outputReport/${financeId}`;
    const p: any = {};
    ajax.check(params.financeId, 'financeId');
    return ajax.ajax({
      ...opt,
      method: 'GET',
      url,
      ...p
    });
  }
  /**
   * 
   * @summary 财务工作台-删除项目财务参数校验
   
   * @param data: ParamsBodyi3060FinanceParamListCheckProjectLibraryPost
   * @param opt ajax config
   * @returns models.I3060FinanceParamListCheckProjectLibrary
   */
  public i3060FinanceParamListCheckProjectLibraryPost = (
    
    data: ParamsBodyi3060FinanceParamListCheckProjectLibraryPost,opt?: ExtraFetchParams
  ) : AjaxPromise<models.I3060FinanceParamListCheckProjectLibrary>  => {
    const url = this.$basePath + `/i3060/financeParamList/checkProjectLibrary`;
    const p: any = {};
    p.data = data;
    return ajax.ajax({
      ...opt,
      method: 'POST',
      url,
      ...p
    });
  }
  /**
   * 
   * @summary 财务工作台-删除项目财务参数
   
   * @param data: ParamsBodyi3060FinanceParamListDeleteProjectLibraryPost
   * @param opt ajax config
   * @returns models.I3060BorderElectricityDeleteCustomCustomId
   */
  public i3060FinanceParamListDeleteProjectLibraryPost = (
    
    data: ParamsBodyi3060FinanceParamListDeleteProjectLibraryPost,opt?: ExtraFetchParams
  ) : AjaxPromise<models.I3060BorderElectricityDeleteCustomCustomId>  => {
    const url = this.$basePath + `/i3060/financeParamList/deleteProjectLibrary`;
    const p: any = {};
    p.data = data;
    return ajax.ajax({
      ...opt,
      method: 'POST',
      url,
      ...p
    });
  }
  /**
   * 
   * @summary 财务方案-编辑参数组名称
   * @param params Paramsi3060FinanceParamListEditNameParamListIdPut
   * @param data: ParamsBodyi3060FinanceParamListEditNameParamListIdPut
   * @param opt ajax config
   * @returns models.AuthUserUpdate
   */
  public i3060FinanceParamListEditNameParamListIdPut = (
    params: Paramsi3060FinanceParamListEditNameParamListIdPut,
    data: ParamsBodyi3060FinanceParamListEditNameParamListIdPut,opt?: { headers: ParamsHeaderi3060FinanceParamListEditNameParamListIdPut } & Omit<ExtraFetchParams, 'headers'> 
  ) : AjaxPromise<models.AuthUserUpdate>  => {
    const {
    paramListId,
} = params;
    const url = this.$basePath + `/i3060/financeParamList/editName/${paramListId}`;
    const p: any = {};
    ajax.check(params.paramListId, 'paramListId');
    p.data = data;
    return ajax.ajax({
      ...opt,
      method: 'PUT',
      url,
      ...p
    });
  }
  /**
   * 
   * @summary 财务方案-获取参数库列表
   
   
   * @param opt ajax config
   * @returns models.I3060FinanceParamListGetProjectLibrary
   */
  public i3060FinanceParamListGetLibraryGet = (
    
    opt?: ExtraFetchParams
  ) : AjaxPromise<models.I3060FinanceParamListGetProjectLibrary>  => {
    const url = this.$basePath + `/i3060/financeParamList/getLibrary`;
    const p: any = {};
    return ajax.ajax({
      ...opt,
      method: 'GET',
      url,
      ...p
    });
  }
  /**
   * 
   * @summary 财务方案-获取参数库列表含有默认值
   
   
   * @param opt ajax config
   * @returns models.I3060FinanceParamListGetProjectLibrary
   */
  public i3060FinanceParamListGetLibraryHasDefaultGet = (
    
    opt?: ExtraFetchParams
  ) : AjaxPromise<models.I3060FinanceParamListGetProjectLibrary>  => {
    const url = this.$basePath + `/i3060/financeParamList/getLibraryHasDefault`;
    const p: any = {};
    return ajax.ajax({
      ...opt,
      method: 'GET',
      url,
      ...p
    });
  }
  /**
   * 
   * @summary 财务方案-获取项目内参数库列表
   * @param params Paramsi3060FinanceParamListGetProjectLibraryGet
   
   * @param opt ajax config
   * @returns models.I3060FinanceParamListGetProjectLibrary
   */
  public i3060FinanceParamListGetProjectLibraryGet = (
    params: Paramsi3060FinanceParamListGetProjectLibraryGet,
    opt?: ExtraFetchParams
  ) : AjaxPromise<models.I3060FinanceParamListGetProjectLibrary>  => {
    const url = this.$basePath + `/i3060/financeParamList/getProjectLibrary`;
    const p: any = {};
    p.query = {};
    if ('projectId' in params) p.query.projectId = params.projectId;
    ajax.check(params.projectId, 'projectId');
    return ajax.ajax({
      ...opt,
      method: 'GET',
      url,
      ...p
    });
  }
  /**
   * 
   * @summary 财务工作台-导入财务参数库参数
   
   * @param data: ParamsBodyi3060FinanceParamListImportLibraryPost
   * @param opt ajax config
   * @returns models.I3060FinanceParamListImportLibrary
   */
  public i3060FinanceParamListImportLibraryPost = (
    
    data: ParamsBodyi3060FinanceParamListImportLibraryPost,opt?: ExtraFetchParams
  ) : AjaxPromise<models.I3060FinanceParamListImportLibrary>  => {
    const url = this.$basePath + `/i3060/financeParamList/importLibrary`;
    const p: any = {};
    p.data = data;
    return ajax.ajax({
      ...opt,
      method: 'POST',
      url,
      ...p
    });
  }
  /**
   * 
   * @summary 财务工作台-导入单个财务参数库参数
   
   * @param data: ParamsBodyi3060FinanceParamListImportSingleLibraryPost
   * @param opt ajax config
   * @returns models.I3060FinanceParamListImportSingleLibrary
   */
  public i3060FinanceParamListImportSingleLibraryPost = (
    
    data: ParamsBodyi3060FinanceParamListImportSingleLibraryPost,opt?: ExtraFetchParams
  ) : AjaxPromise<models.I3060FinanceParamListImportSingleLibrary>  => {
    const url = this.$basePath + `/i3060/financeParamList/importSingleLibrary`;
    const p: any = {};
    p.data = data;
    return ajax.ajax({
      ...opt,
      method: 'POST',
      url,
      ...p
    });
  }
  /**
   * 
   * @summary 财务方案-获取参数组列表
   * @param params Paramsi3060FinanceParamListListProjectIdGet
   
   * @param opt ajax config
   * @returns models.I3060FinanceParamListListProjectId
   */
  public i3060FinanceParamListListProjectIdGet = (
    params: Paramsi3060FinanceParamListListProjectIdGet,
    opt?: { headers: ParamsHeaderi3060FinanceParamListListProjectIdGet } & Omit<ExtraFetchParams, 'headers'> 
  ) : AjaxPromise<models.I3060FinanceParamListListProjectId>  => {
    const {
    projectId,
} = params;
    const url = this.$basePath + `/i3060/financeParamList/list/${projectId}`;
    const p: any = {};
    ajax.check(params.projectId, 'projectId');
    return ajax.ajax({
      ...opt,
      method: 'GET',
      url,
      ...p
    });
  }
  /**
   * 
   * @summary 财务方案-删除参数组配置
   * @param params Paramsi3060FinanceParamListParamListIdDelete
   * @param data: ParamsBodyi3060FinanceParamListParamListIdDelete
   * @param opt ajax config
   * @returns models.AuthUserUpdate
   */
  public i3060FinanceParamListParamListIdDelete = (
    params: Paramsi3060FinanceParamListParamListIdDelete,
    data: ParamsBodyi3060FinanceParamListParamListIdDelete,opt?: ExtraFetchParams
  ) : AjaxPromise<models.AuthUserUpdate>  => {
    const {
    paramListId,
} = params;
    const url = this.$basePath + `/i3060/financeParamList/${paramListId}`;
    const p: any = {};
    ajax.check(params.paramListId, 'paramListId');
    p.data = data;
    return ajax.ajax({
      ...opt,
      method: 'DELETE',
      url,
      ...p
    });
  }
  /**
   * 
   * @summary 财务方案-编辑参数组列表
   * @param params Paramsi3060FinanceParamListParamListIdPut
   * @param data: ParamsBodyi3060FinanceParamListParamListIdPut
   * @param opt ajax config
   * @returns models.AuthUserUpdate
   */
  public i3060FinanceParamListParamListIdPut = (
    params: Paramsi3060FinanceParamListParamListIdPut,
    data: ParamsBodyi3060FinanceParamListParamListIdPut,opt?: { headers: ParamsHeaderi3060FinanceParamListParamListIdPut } & Omit<ExtraFetchParams, 'headers'> 
  ) : AjaxPromise<models.AuthUserUpdate>  => {
    const {
    paramListId,
} = params;
    const url = this.$basePath + `/i3060/financeParamList/${paramListId}`;
    const p: any = {};
    ajax.check(params.paramListId, 'paramListId');
    p.data = data;
    return ajax.ajax({
      ...opt,
      method: 'PUT',
      url,
      ...p
    });
  }
  /**
   * 
   * @summary 财务方案-创建参数组配置
   * @param params Paramsi3060FinanceParamListProjectIdPost
   * @param data: ParamsBodyi3060FinanceParamListProjectIdPost
   * @param opt ajax config
   * @returns models.I3060FinanceParamListProjectId
   */
  public i3060FinanceParamListProjectIdPost = (
    params: Paramsi3060FinanceParamListProjectIdPost,
    data: ParamsBodyi3060FinanceParamListProjectIdPost,opt?: { headers: ParamsHeaderi3060FinanceParamListProjectIdPost } & Omit<ExtraFetchParams, 'headers'> 
  ) : AjaxPromise<models.I3060FinanceParamListProjectId>  => {
    const {
    projectId,
} = params;
    const url = this.$basePath + `/i3060/financeParamList/${projectId}`;
    const p: any = {};
    ajax.check(params.projectId, 'projectId');
    p.data = data;
    return ajax.ajax({
      ...opt,
      method: 'POST',
      url,
      ...p
    });
  }
  /**
   * 
   * @summary 财务工作台-修改项目内参数库名字
   
   * @param data: ParamsBodyi3060FinanceParamListRenameProjectLibraryPost
   * @param opt ajax config
   * @returns models.I3060BorderElectricityDeleteCustomCustomId
   */
  public i3060FinanceParamListRenameProjectLibraryPost = (
    
    data: ParamsBodyi3060FinanceParamListRenameProjectLibraryPost,opt?: ExtraFetchParams
  ) : AjaxPromise<models.I3060BorderElectricityDeleteCustomCustomId>  => {
    const url = this.$basePath + `/i3060/financeParamList/renameProjectLibrary`;
    const p: any = {};
    p.data = data;
    return ajax.ajax({
      ...opt,
      method: 'POST',
      url,
      ...p
    });
  }
  /**
   * 
   * @summary 财务方案-方案列表-透视分析
   * @param params Paramsi3060FinancePerspectiveAnalyseProjectIdGet
   
   * @param opt ajax config
   * @returns models.I3060FinancePerspectiveAnalyseProjectId
   */
  public i3060FinancePerspectiveAnalyseProjectIdGet = (
    params: Paramsi3060FinancePerspectiveAnalyseProjectIdGet,
    opt?: ExtraFetchParams
  ) : AjaxPromise<models.I3060FinancePerspectiveAnalyseProjectId>  => {
    const {
    projectId,
} = params;
    const url = this.$basePath + `/i3060/finance/perspectiveAnalyse/${projectId}`;
    const p: any = {};
    p.query = {};
    if ('queryType' in params) p.query.queryType = params.queryType;
    ajax.check(params.queryType, 'queryType');
    ajax.check(params.projectId, 'projectId');
    return ajax.ajax({
      ...opt,
      method: 'GET',
      url,
      ...p
    });
  }
  /**
   * 
   * @summary 财务方案-参数库-拷贝项目投资
   * @param params Paramsi3060FinanceProjectInvestCopyProjectInvestIdPost
   * @param data: ParamsBodyi3060FinanceProjectInvestCopyProjectInvestIdPost
   * @param opt ajax config
   * @returns models.AuthUserUpdate
   */
  public i3060FinanceProjectInvestCopyProjectInvestIdPost = (
    params: Paramsi3060FinanceProjectInvestCopyProjectInvestIdPost,
    data: ParamsBodyi3060FinanceProjectInvestCopyProjectInvestIdPost,opt?: { headers: ParamsHeaderi3060FinanceProjectInvestCopyProjectInvestIdPost } & Omit<ExtraFetchParams, 'headers'> 
  ) : AjaxPromise<models.AuthUserUpdate>  => {
    const {
    projectInvestId,
} = params;
    const url = this.$basePath + `/i3060/financeProjectInvest/copy/${projectInvestId}`;
    const p: any = {};
    ajax.check(params.projectInvestId, 'projectInvestId');
    p.data = data;
    return ajax.ajax({
      ...opt,
      method: 'POST',
      url,
      ...p
    });
  }
  /**
   * 
   * @summary 财务方案-参数库-批量保存或编辑项目投资
   * @param params Paramsi3060FinanceProjectInvestCreateOrEditBatchPost
   * @param data: ParamsBodyi3060FinanceProjectInvestCreateOrEditBatchPost
   * @param opt ajax config
   * @returns models.AuthUserUpdate
   */
  public i3060FinanceProjectInvestCreateOrEditBatchPost = (
    params: Paramsi3060FinanceProjectInvestCreateOrEditBatchPost,
    data: ParamsBodyi3060FinanceProjectInvestCreateOrEditBatchPost,opt?: { headers: ParamsHeaderi3060FinanceProjectInvestCreateOrEditBatchPost } & Omit<ExtraFetchParams, 'headers'> 
  ) : AjaxPromise<models.AuthUserUpdate>  => {
    const url = this.$basePath + `/i3060/financeProjectInvest/createOrEditBatch`;
    const p: any = {};
    p.data = data;
    return ajax.ajax({
      ...opt,
      method: 'POST',
      url,
      ...p
    });
  }
  /**
   * 
   * @summary 财务方案-参数库-项目投资-设置为默认
   * @param params Paramsi3060FinanceProjectInvestDefaultProjectInvestIdPut
   * @param data: ParamsBodyi3060FinanceProjectInvestDefaultProjectInvestIdPut
   * @param opt ajax config
   * @returns models.AuthUserUpdate
   */
  public i3060FinanceProjectInvestDefaultProjectInvestIdPut = (
    params: Paramsi3060FinanceProjectInvestDefaultProjectInvestIdPut,
    data: ParamsBodyi3060FinanceProjectInvestDefaultProjectInvestIdPut,opt?: ExtraFetchParams
  ) : AjaxPromise<models.AuthUserUpdate>  => {
    const {
    projectInvestId,
} = params;
    const url = this.$basePath + `/i3060/financeProjectInvest/default/${projectInvestId}`;
    const p: any = {};
    ajax.check(params.projectInvestId, 'projectInvestId');
    p.data = data;
    return ajax.ajax({
      ...opt,
      method: 'PUT',
      url,
      ...p
    });
  }
  /**
   * 
   * @summary 财务方案-参数库-编辑项目投资
   * @param params Paramsi3060FinanceProjectInvestEditPost
   * @param data: ParamsBodyi3060FinanceProjectInvestEditPost
   * @param opt ajax config
   * @returns models.AuthUserUpdate
   */
  public i3060FinanceProjectInvestEditPost = (
    params: Paramsi3060FinanceProjectInvestEditPost,
    data: ParamsBodyi3060FinanceProjectInvestEditPost,opt?: { headers: ParamsHeaderi3060FinanceProjectInvestEditPost } & Omit<ExtraFetchParams, 'headers'> 
  ) : AjaxPromise<models.AuthUserUpdate>  => {
    const url = this.$basePath + `/i3060/financeProjectInvest/edit`;
    const p: any = {};
    p.data = data;
    return ajax.ajax({
      ...opt,
      method: 'POST',
      url,
      ...p
    });
  }
  /**
   * 
   * @summary 财务方案-参数库-项目投资-获取默认值
   
   
   * @param opt ajax config
   * @returns models.I3060FinanceProjectInvestGetDefault
   */
  public i3060FinanceProjectInvestGetDefaultGet = (
    
    opt?: ExtraFetchParams
  ) : AjaxPromise<models.I3060FinanceProjectInvestGetDefault>  => {
    const url = this.$basePath + `/i3060/financeProjectInvest/getDefault`;
    const p: any = {};
    return ajax.ajax({
      ...opt,
      method: 'GET',
      url,
      ...p
    });
  }
  /**
   * 
   * @summary 财务方案-参数库-获取项目投资详情
   * @param params Paramsi3060FinanceProjectInvestInfoProjectInvestIdGet
   
   * @param opt ajax config
   * @returns models.I3060FinanceProjectInvestInfoProjectInvestId
   */
  public i3060FinanceProjectInvestInfoProjectInvestIdGet = (
    params: Paramsi3060FinanceProjectInvestInfoProjectInvestIdGet,
    opt?: ExtraFetchParams
  ) : AjaxPromise<models.I3060FinanceProjectInvestInfoProjectInvestId>  => {
    const {
    projectInvestId,
} = params;
    const url = this.$basePath + `/i3060/financeProjectInvest/info/${projectInvestId}`;
    const p: any = {};
    ajax.check(params.projectInvestId, 'projectInvestId');
    return ajax.ajax({
      ...opt,
      method: 'GET',
      url,
      ...p
    });
  }
  /**
   * 
   * @summary 财务方案-参数库-项目投资-获取当前用户下所有
   * @param params Paramsi3060FinanceProjectInvestListGet
   
   * @param opt ajax config
   * @returns models.I3060FinanceProjectInvestList
   */
  public i3060FinanceProjectInvestListGet = (
    params: Paramsi3060FinanceProjectInvestListGet,
    opt?: { headers: ParamsHeaderi3060FinanceProjectInvestListGet } & Omit<ExtraFetchParams, 'headers'> 
  ) : AjaxPromise<models.I3060FinanceProjectInvestList>  => {
    const url = this.$basePath + `/i3060/financeProjectInvest/list`;
    const p: any = {};
    return ajax.ajax({
      ...opt,
      method: 'GET',
      url,
      ...p
    });
  }
  /**
   * 
   * @summary 财务方案-参数库-创建项目投资
   
   * @param data: ParamsBodyi3060FinanceProjectInvestPost
   * @param opt ajax config
   * @returns models.I3060FinanceProjectInvestInfoProjectInvestId
   */
  public i3060FinanceProjectInvestPost = (
    
    data: ParamsBodyi3060FinanceProjectInvestPost,opt?: ExtraFetchParams
  ) : AjaxPromise<models.I3060FinanceProjectInvestInfoProjectInvestId>  => {
    const url = this.$basePath + `/i3060/financeProjectInvest`;
    const p: any = {};
    p.data = data;
    return ajax.ajax({
      ...opt,
      method: 'POST',
      url,
      ...p
    });
  }
  /**
   * 
   * @summary 财务方案-参数库-删除项目投资
   * @param params Paramsi3060FinanceProjectInvestProjectInvestIdDelete
   
   * @param opt ajax config
   * @returns models.AuthUserUpdate
   */
  public i3060FinanceProjectInvestProjectInvestIdDelete = (
    params: Paramsi3060FinanceProjectInvestProjectInvestIdDelete,
    opt?: { headers: ParamsHeaderi3060FinanceProjectInvestProjectInvestIdDelete } & Omit<ExtraFetchParams, 'headers'> 
  ) : AjaxPromise<models.AuthUserUpdate>  => {
    const {
    projectInvestId,
} = params;
    const url = this.$basePath + `/i3060/financeProjectInvest/${projectInvestId}`;
    const p: any = {};
    ajax.check(params.projectInvestId, 'projectInvestId');
    return ajax.ajax({
      ...opt,
      method: 'DELETE',
      url,
      ...p
    });
  }
  /**
   * 
   * @summary 修改财务管理方案名称
   * @param params Paramsi3060FinanceRenameFinanceIdPatch
   * @param data: ParamsBodyi3060FinanceRenameFinanceIdPatch
   * @param opt ajax config
   * @returns models.AuthUserSendCheckEmail
   */
  public i3060FinanceRenameFinanceIdPatch = (
    params: Paramsi3060FinanceRenameFinanceIdPatch,
    data: ParamsBodyi3060FinanceRenameFinanceIdPatch,opt?: { headers: ParamsHeaderi3060FinanceRenameFinanceIdPatch } & Omit<ExtraFetchParams, 'headers'> 
  ) : AjaxPromise<models.AuthUserSendCheckEmail>  => {
    const {
    financeId,
} = params;
    const url = this.$basePath + `/i3060/finance/rename/${financeId}`;
    const p: any = {};
    ajax.check(params.financeId, 'financeId');
    p.data = data;
    return ajax.ajax({
      ...opt,
      method: 'PATCH',
      url,
      ...p
    });
  }
  /**
   * 
   * @summary 财务方案-选择规划运行方案-查询方案列表
   * @param params Paramsi3060FinanceSchemePlanListProjectIdGet
   
   * @param opt ajax config
   * @returns models.I3060FinanceSchemePlanListProjectId
   */
  public i3060FinanceSchemePlanListProjectIdGet = (
    params: Paramsi3060FinanceSchemePlanListProjectIdGet,
    opt?: ExtraFetchParams
  ) : AjaxPromise<models.I3060FinanceSchemePlanListProjectId>  => {
    const {
    projectId,
} = params;
    const url = this.$basePath + `/i3060/financeSchemePlan/list/${projectId}`;
    const p: any = {};
    p.query = {};
    if ('createTimeSort' in params) p.query.createTimeSort = params.createTimeSort;
    ajax.check(params.createTimeSort, 'createTimeSort');
    if ('updateTimeSort' in params) p.query.updateTimeSort = params.updateTimeSort;
    ajax.check(params.updateTimeSort, 'updateTimeSort');
    if ('installedCapacityMin' in params) p.query.installedCapacityMin = params.installedCapacityMin;
    if ('installedCapacityMax' in params) p.query.installedCapacityMax = params.installedCapacityMax;
    if ('dayAvgPowerGenerationMin' in params) p.query.dayAvgPowerGenerationMin = params.dayAvgPowerGenerationMin;
    if ('dayAvgPowerGenerationMax' in params) p.query.dayAvgPowerGenerationMax = params.dayAvgPowerGenerationMax;
    if ('useHourYearMin' in params) p.query.useHourYearMin = params.useHourYearMin;
    if ('useHourYearMax' in params) p.query.useHourYearMax = params.useHourYearMax;
    if ('selfAbsorptionRateMin' in params) p.query.selfAbsorptionRateMin = params.selfAbsorptionRateMin;
    if ('selfAbsorptionRateMax' in params) p.query.selfAbsorptionRateMax = params.selfAbsorptionRateMax;
    if ('batteryCapacityMin' in params) p.query.batteryCapacityMin = params.batteryCapacityMin;
    if ('batteryCapacityMax' in params) p.query.batteryCapacityMax = params.batteryCapacityMax;
    if ('dayAvgDischargeMin' in params) p.query.dayAvgDischargeMin = params.dayAvgDischargeMin;
    if ('dayAvgDischargeMax' in params) p.query.dayAvgDischargeMax = params.dayAvgDischargeMax;
    if ('dayAvgChargeDischargeMin' in params) p.query.dayAvgChargeDischargeMin = params.dayAvgChargeDischargeMin;
    if ('dayAvgChargeDischargeMax' in params) p.query.dayAvgChargeDischargeMax = params.dayAvgChargeDischargeMax;
    if ('installedCapacitySort' in params) p.query.installedCapacitySort = params.installedCapacitySort;
    ajax.check(params.installedCapacitySort, 'installedCapacitySort');
    if ('dayAvgPowerGenerationSort' in params) p.query.dayAvgPowerGenerationSort = params.dayAvgPowerGenerationSort;
    ajax.check(params.dayAvgPowerGenerationSort, 'dayAvgPowerGenerationSort');
    if ('useHourYearSort' in params) p.query.useHourYearSort = params.useHourYearSort;
    ajax.check(params.useHourYearSort, 'useHourYearSort');
    if ('selfAbsorptionRateSort' in params) p.query.selfAbsorptionRateSort = params.selfAbsorptionRateSort;
    ajax.check(params.selfAbsorptionRateSort, 'selfAbsorptionRateSort');
    if ('batteryCapacitySort' in params) p.query.batteryCapacitySort = params.batteryCapacitySort;
    ajax.check(params.batteryCapacitySort, 'batteryCapacitySort');
    if ('pcsPowerSort' in params) p.query.pcsPowerSort = params.pcsPowerSort;
    ajax.check(params.pcsPowerSort, 'pcsPowerSort');
    if ('dayAvgDischargeSort' in params) p.query.dayAvgDischargeSort = params.dayAvgDischargeSort;
    ajax.check(params.dayAvgDischargeSort, 'dayAvgDischargeSort');
    if ('dayAvgChargeDischargeSort' in params) p.query.dayAvgChargeDischargeSort = params.dayAvgChargeDischargeSort;
    ajax.check(params.dayAvgChargeDischargeSort, 'dayAvgChargeDischargeSort');
    if ('pcsPowerMin' in params) p.query.pcsPowerMin = params.pcsPowerMin;
    if ('pcsPowerMax' in params) p.query.pcsPowerMax = params.pcsPowerMax;
    if ('annualGenerationHoursSort' in params) p.query.annualGenerationHoursSort = params.annualGenerationHoursSort;
    ajax.check(params.annualGenerationHoursSort, 'annualGenerationHoursSort');
    if ('annualGenerationHoursMin' in params) p.query.annualGenerationHoursMin = params.annualGenerationHoursMin;
    ajax.check(params.annualGenerationHoursMin, 'annualGenerationHoursMin');
    if ('annualGenerationHoursMax' in params) p.query.annualGenerationHoursMax = params.annualGenerationHoursMax;
    ajax.check(params.annualGenerationHoursMax, 'annualGenerationHoursMax');
    ajax.check(params.projectId, 'projectId');
    return ajax.ajax({
      ...opt,
      method: 'GET',
      url,
      ...p
    });
  }
  /**
   * 
   * @summary 财务方案-选择规划运行方案-查询关联的技术方案
   * @param params Paramsi3060FinanceSchemePlanQueryRefProjectIdGet
   
   * @param opt ajax config
   * @returns models.I3060FinanceSchemePlanQueryRefProjectId
   */
  public i3060FinanceSchemePlanQueryRefProjectIdGet = (
    params: Paramsi3060FinanceSchemePlanQueryRefProjectIdGet,
    opt?: ExtraFetchParams
  ) : AjaxPromise<models.I3060FinanceSchemePlanQueryRefProjectId>  => {
    const {
    projectId,
} = params;
    const url = this.$basePath + `/i3060/financeSchemePlan/queryRef/${projectId}`;
    const p: any = {};
    ajax.check(params.projectId, 'projectId');
    return ajax.ajax({
      ...opt,
      method: 'GET',
      url,
      ...p
    });
  }
  /**
   * 
   * @summary 财务方案-规划运行方案选择-关联技术方案
   * @param params Paramsi3060FinanceSchemePlanRefProjectIdPost
   * @param data: ParamsBodyi3060FinanceSchemePlanRefProjectIdPost
   * @param opt ajax config
   * @returns models.AuthUserUpdate
   */
  public i3060FinanceSchemePlanRefProjectIdPost = (
    params: Paramsi3060FinanceSchemePlanRefProjectIdPost,
    data: ParamsBodyi3060FinanceSchemePlanRefProjectIdPost,opt?: { headers: ParamsHeaderi3060FinanceSchemePlanRefProjectIdPost } & Omit<ExtraFetchParams, 'headers'> 
  ) : AjaxPromise<models.AuthUserUpdate>  => {
    const {
    projectId,
} = params;
    const url = this.$basePath + `/i3060/financeSchemePlan/ref/${projectId}`;
    const p: any = {};
    ajax.check(params.projectId, 'projectId');
    p.data = data;
    return ajax.ajax({
      ...opt,
      method: 'POST',
      url,
      ...p
    });
  }
  /**
   * 
   * @summary 项目报告-获取报告pdf文件tag
   * @param params Paramsi3060FinanceWordGetPdfTagWordIdGet
   
   * @param opt ajax config
   * @returns models.I3060FinanceWordGetPdfTagWordId
   */
  public i3060FinanceWordGetPdfTagWordIdGet = (
    params: Paramsi3060FinanceWordGetPdfTagWordIdGet,
    opt?: ExtraFetchParams
  ) : AjaxPromise<models.I3060FinanceWordGetPdfTagWordId>  => {
    const {
    wordId,
} = params;
    const url = this.$basePath + `/i3060/financeWord/getPdfTag/${wordId}`;
    const p: any = {};
    ajax.check(params.wordId, 'wordId');
    return ajax.ajax({
      ...opt,
      method: 'GET',
      url,
      ...p
    });
  }
  /**
   * 
   * @summary 项目报告-列表
   * @param params Paramsi3060FinanceWordListProjectIdGet
   
   * @param opt ajax config
   * @returns models.I3060FinanceWordListProjectId
   */
  public i3060FinanceWordListProjectIdGet = (
    params: Paramsi3060FinanceWordListProjectIdGet,
    opt?: { headers: ParamsHeaderi3060FinanceWordListProjectIdGet } & Omit<ExtraFetchParams, 'headers'> 
  ) : AjaxPromise<models.I3060FinanceWordListProjectId>  => {
    const {
    projectId,
} = params;
    const url = this.$basePath + `/i3060/financeWord/list/${projectId}`;
    const p: any = {};
    p.query = {};
    if ('wordName' in params) p.query.wordName = params.wordName;
    if ('technicalName' in params) p.query.technicalName = params.technicalName;
    if ('financeName' in params) p.query.financeName = params.financeName;
    if ('status' in params) p.query.status = params.status;
    if ('createTimeSort' in params) p.query.createTimeSort = params.createTimeSort;
    ajax.check(params.createTimeSort, 'createTimeSort');
    if ('updateTimeSort' in params) p.query.updateTimeSort = params.updateTimeSort;
    ajax.check(params.updateTimeSort, 'updateTimeSort');
    if ('generateTimeSort' in params) p.query.generateTimeSort = params.generateTimeSort;
    ajax.check(params.generateTimeSort, 'generateTimeSort');
    ajax.check(params.projectId, 'projectId');
    return ajax.ajax({
      ...opt,
      method: 'GET',
      url,
      ...p
    });
  }
  /**
   * 通过 [http://117.80.117.82:23000/project/11/interface/api/1334](http://117.80.117.82:23000/project/11/interface/api/1334) 接口获取tag然后直接带上参数浏览器地址栏访问
   * @summary 项目报告-获取文件内容pdf
   * @param params Paramsi3060FinanceWordReadPdfTagGet
   
   * @param opt ajax config
   * @returns any
   */
  public i3060FinanceWordReadPdfTagGet = (
    params: Paramsi3060FinanceWordReadPdfTagGet,
    opt?: ExtraFetchParams
  ) : AjaxPromise<any>  => {
    const {
    tag,
} = params;
    const url = this.$basePath + `/i3060/financeWord/readPdf/${tag}`;
    const p: any = {};
    ajax.check(params.tag, 'tag');
    return ajax.ajax({
      ...opt,
      method: 'GET',
      url,
      ...p
    });
  }
  /**
   * 
   * @summary 项目报告-获取文件内容
   * @param params Paramsi3060FinanceWordReadWordWordIdGet
   
   * @param opt ajax config
   * @returns any
   */
  public i3060FinanceWordReadWordWordIdGet = (
    params: Paramsi3060FinanceWordReadWordWordIdGet,
    opt?: ExtraFetchParams
  ) : AjaxPromise<any>  => {
    const {
    wordId,
} = params;
    const url = this.$basePath + `/i3060/financeWord/readWord/${wordId}`;
    const p: any = {};
    ajax.check(params.wordId, 'wordId');
    return ajax.ajax({
      ...opt,
      method: 'GET',
      url,
      ...p
    });
  }
  /**
   * 
   * @summary 项目报告-更名
   * @param params Paramsi3060FinanceWordRenameWordIdPatch
   * @param data: ParamsBodyi3060FinanceWordRenameWordIdPatch
   * @param opt ajax config
   * @returns any
   */
  public i3060FinanceWordRenameWordIdPatch = (
    params: Paramsi3060FinanceWordRenameWordIdPatch,
    data: ParamsBodyi3060FinanceWordRenameWordIdPatch,opt?: ExtraFetchParams
  ) : AjaxPromise<any>  => {
    const {
    wordId,
} = params;
    const url = this.$basePath + `/i3060/financeWord/rename/${wordId}`;
    const p: any = {};
    ajax.check(params.wordId, 'wordId');
    p.data = data;
    return ajax.ajax({
      ...opt,
      method: 'PATCH',
      url,
      ...p
    });
  }
  /**
   * 
   * @summary 财务方案word文档-文档图片上传(单个)
   * @param params Paramsi3060FinanceWordUploadFileFinanceIdPost
   * @param data: ParamsBodyi3060FinanceWordUploadFileFinanceIdPost
   * @param opt ajax config
   * @returns any
   */
  public i3060FinanceWordUploadFileFinanceIdPost = (
    params: Paramsi3060FinanceWordUploadFileFinanceIdPost,
    data: ParamsBodyi3060FinanceWordUploadFileFinanceIdPost,opt?: { headers: ParamsHeaderi3060FinanceWordUploadFileFinanceIdPost } & Omit<ExtraFetchParams, 'headers'> 
  ) : AjaxPromise<any>  => {
    const {
    financeId,
} = params;
    const url = this.$basePath + `/i3060/financeWord/uploadFile/${financeId}`;
    const p: any = {};
    ajax.check(params.financeId, 'financeId');
    p.data = data;
    return ajax.ajax({
      ...opt,
      method: 'POST',
      url,
      ...p
    });
  }
  /**
   * 
   * @summary 财务方案word文档-文档图片上传(批量)
   * @param params Paramsi3060FinanceWordUploadFileListFinanceIdPost
   * @param data: ParamsBodyi3060FinanceWordUploadFileListFinanceIdPost
   * @param opt ajax config
   * @returns any
   */
  public i3060FinanceWordUploadFileListFinanceIdPost = (
    params: Paramsi3060FinanceWordUploadFileListFinanceIdPost,
    data: ParamsBodyi3060FinanceWordUploadFileListFinanceIdPost,opt?: { headers: ParamsHeaderi3060FinanceWordUploadFileListFinanceIdPost } & Omit<ExtraFetchParams, 'headers'> 
  ) : AjaxPromise<any>  => {
    const {
    financeId,
} = params;
    const url = this.$basePath + `/i3060/financeWord/uploadFileList/${financeId}`;
    const p: any = {};
    ajax.check(params.financeId, 'financeId');
    p.data = data;
    return ajax.ajax({
      ...opt,
      method: 'POST',
      url,
      ...p
    });
  }
  /**
   * 
   * @summary 生成归档财务报告
   * @param params Paramsi3060WordFillWordFinanceIdGet
   
   * @param opt ajax config
   * @returns any
   */
  public i3060WordFillWordFinanceIdGet = (
    params: Paramsi3060WordFillWordFinanceIdGet,
    opt?: { headers: ParamsHeaderi3060WordFillWordFinanceIdGet } & Omit<ExtraFetchParams, 'headers'> 
  ) : AjaxPromise<any>  => {
    const {
    financeId,
} = params;
    const url = this.$basePath + `/i3060/word/fillWord/${financeId}`;
    const p: any = {};
    ajax.check(params.financeId, 'financeId');
    return ajax.ajax({
      ...opt,
      method: 'GET',
      url,
      ...p
    });
  }
  /**
   * 
   * @summary 财务报告典型日发电曲线
   * @param params Paramsi3060WordGetActivePowerChartGet
   
   * @param opt ajax config
   * @returns models.I3060WordGetActivePowerChart
   */
  public i3060WordGetActivePowerChartGet = (
    params: Paramsi3060WordGetActivePowerChartGet,
    opt?: ExtraFetchParams
  ) : AjaxPromise<models.I3060WordGetActivePowerChart>  => {
    const url = this.$basePath + `/i3060/word/getActivePowerChart`;
    const p: any = {};
    p.query = {};
    if ('financeId' in params) p.query.financeId = params.financeId;
    ajax.check(params.financeId, 'financeId');
    return ajax.ajax({
      ...opt,
      method: 'GET',
      url,
      ...p
    });
  }
  /**
   * 
   * @summary 获取报告逐年发电量图表
   * @param params Paramsi3060WordGetYearYearPowerGet
   
   * @param opt ajax config
   * @returns models.I3060WordGetYearYearPower
   */
  public i3060WordGetYearYearPowerGet = (
    params: Paramsi3060WordGetYearYearPowerGet,
    opt?: ExtraFetchParams
  ) : AjaxPromise<models.I3060WordGetYearYearPower>  => {
    const url = this.$basePath + `/i3060/word/getYearYearPower`;
    const p: any = {};
    p.query = {};
    if ('financeId' in params) p.query.financeId = params.financeId;
    ajax.check(params.financeId, 'financeId');
    return ajax.ajax({
      ...opt,
      method: 'GET',
      url,
      ...p
    });
  }
}

export default new FinanceApi();