import { Modal } from 'antd'

import styles from '../index.module.scss'
function ServiceTrimModal(props: any) {
  const { visible, onCancel } = props

  return (
    visible && (
      <Modal
        centered
        visible={visible}
        footer={false}
        width={900}
        title="服务条款"
        maskClosable={false}
        className={styles.serviceModal}
        onCancel={onCancel}
      >
        <div className="top-tips">
          <div className="icon">
            <img src="../assets/tips.svg" alt="" />
          </div>
          重要提示：请您仔细阅读以下各项条款。如您不同意本服务条款任意内容，请不要注册或使用。如您进入并完成本平台的注册程序，即表示您与本平台已达成意思一致，自愿接受本服务条款的所有内容。
        </div>
        <div className="service-content">
          <div className="section">
            <h2>一、条款的范围与确认</h2>
            <div>
              <span>01、</span>
              <p>
                本条款中的“产品服务”指北京凯利丰新能源科技有限公司向用户提供的凯利丰3060（
                <a href="https://www.i3060.com/" target="_blank" rel="noreferrer">
                  https://www.i3060.com/
                </a>
                ）上所展示的服务功能，各项服务的所有权和运营权均归北京凯利丰新能源科技有限公司所有。具体服务内容以您所订购或授权使用的内容为准。
              </p>
            </div>
            <div>
              <span>02、</span>
              <p>
                本条款有助于您了解本平台的服务内容及用户的使用权利、义务，请您仔细阅读本条款（特别是以粗体标注的内容）。在接受本条款之前，请您仔细阅读全部内容，如您无法准确理解或不同意本条款的任一内容，请不要进行后续操作。
              </p>
            </div>
            <div>
              <span>03、</span>
              <p>
                通过网络页面点击确认、实际购买、使用我们提供的服务等操作，或以其他方式选择接受本条款的，即表示您同意并接受本条款的全部内容。
              </p>
            </div>
            <div>
              <span>04、</span>
              <p>
                由于互联网行业的高速发展，本条款列明的条款未必能完整罗列并覆盖您与本平台的所有权利与义务，现有的约定也不能保证完全符合未来发展的需求。因此，本平台有权在后续运营中对本条款做合法合规的修改和补充，所做的修改补充将再次对其进行公示以便获得您的确认，您需定期登录本平台查看最新的条款规定，对于服务条款、隐私政策或规则的修改将在公布时或规定的生效日立即生效。
              </p>
            </div>
            <div>
              <span>05、</span>
              <p>
                若在新的条款生效后访问和使用本平台产品服务，视为您同意接受修订后的服务条款。若您不同意变更修改后的条款内容，您有权停止使用相关服务、退出账号登录、删除您有关的账号信息中的内容等方式来终止服务条款。
              </p>
            </div>
            <div>
              <span>06、</span>
              <p>
                如果您代表某个机构而非您个人注册、登录及使用本平台产品服务，则您将被认为已获得充分授权代表该机构同意本条款以及本条款后续的修改和补充。
              </p>
            </div>
          </div>
          <div className="section">
            <h2>二、账号注册与安全</h2>
            <div>
              <span>07、</span>
              <p>
                您在使用本平台产品服务前需要注册本平台帐号。您须对注册信息的真实性、合法性、有效性承担全部责任，并配合我们依法核验您的注册信息；您不得未经授权代表他人或冒充他人；您也不得冒用或利用他人的名义发布任何信息或恶意使用注册帐号导致其他用户误认。
              </p>
            </div>
            <div>
              <span>08、</span>
              <p>
                您有责任妥善保管注册帐号及密码信息的安全，您需要对注册帐号项下的行为承担法律责任。因您的原因造成的账号、密码等信息被冒用、盗用或非法使用等不当事项，由此引起的一切风险、责任、损失、费用等应由您自行承担。
              </p>
            </div>
            <div>
              <span>09、</span>
              <p>
                您在注册帐号中设置的用户名、头像、个人介绍等必须遵守法律法规、公序良俗、社会公德，且不会侵害其他第三方的合法权益，否则我们可能会取消您的用户名、头像、个人介绍等。
              </p>
            </div>
            <div>
              <span>10、</span>
              <p>
                如您发现有他人冒用或盗用您的帐号，请您立即以有效方式通知我们，您也可以向我们申请暂停或终止本平台产品服务。
              </p>
            </div>
          </div>
          <div className="section">
            <h2>三、用户使用相关规则</h2>
            <div>
              <span>11、</span>
              <p>
                您应遵守中华人民共和国相关法律法规及您所属、所居住的国家或地区的法律法规，不得将本平台产品或服务用于任何非法目的，也不得以任何非法方式使用本平台产品或服务，如有违反，其所有造成的法律责任、结果损失和相关赔偿等均由用户本人自行承担。
              </p>
            </div>
            <div>
              <span>12、</span>
              <p>
                您承诺不得使用本平台服务、从事以下行为，包括但不限于：
                <ul>
                  <li>
                    发布、传送、传播、储存违反国家法律法规政策、危害国家安全统一、民族团结、社会稳定、公序良俗、社会公德以及侮辱、诽谤、歧视、淫秽、暴力等的内容；
                  </li>
                  <li>发布、传送、传播、储存侵害他人名誉权、肖像权、知识产权、商业秘密等合法权利的内容； </li>
                  <li>虚构事实、隐瞒真相以误导、欺骗他人； </li>
                  <li>发表、传送、传播广告信息、不实信息及垃圾信息； </li>
                  <li>其他违反法律法规、政策及公序良俗或侵犯第三方合法权益的行为。 </li>
                </ul>
              </p>
            </div>
            <div>
              <span>13、</span>
              <p>您承诺发送或传播的内容应有合法来源，相关内容为您所有或您已获得权利人的充分授权。</p>
            </div>
            <div>
              <span>14、</span>
              <p>您不得使用未经授权的插件、外挂或第三方工具对本条款项下的服务进行干扰、破坏、修改或施加其他影响。</p>
            </div>
            <div>
              <span>15、</span>
              <p>
                您不得对本平台产品系统和程序采取反向工程手段进行破解，不得对上述系统和程序（包括但不限于源程序、目标程序、技术文档、服务器数据等）进行复制、修改、编译、整合或篡改，不得修改或增减本平台产品系统的任何功能，如有违反，本平台将保留依法追究相关责任者一切法律责任的权利。
              </p>
            </div>
            <div>
              <span>16、</span>
              <p>
                如您违反法律法规或本条款、补充条款，我们将作出独立判断立即暂停或终止您的服务帐号。您需自行对其全部行为承担法律责任，对此我们将不承担任何法律责任。相关国家机关或机构、我们、第三方可能会对您提起诉讼、罚款或采取其他制裁措施，您应独立承担相关责任，造成损害的，您应依法予以赔偿。如因此导致我们遭受损失的，您也应当一并赔偿。
              </p>
            </div>
          </div>
          <div className="section">
            <h2>四、内容相关规则</h2>
            <div>
              <span>17、</span>
              <p>
                您在本平台上发布的项目、方案以及与之相关的数据等，我们不对其进行权利审核，其著作权本平台将均视为归您本人或您代表的机构所有。您可授权第三方以任何方式使用，不需要得到我们的同意。如您的作品中有他人授权的作品内容或含有临摹仿照他人作品内容的部分，作为内容的发布者，您需自行对所发布的内容负责，因此所引发的一切纠纷，由您自行承担法律责任。如因此导致我们遭受损失的，您应当承担损失赔偿责任。
              </p>
            </div>
            <div>
              <span>18、</span>
              <p>
                为了保障您的合法权益，建议您不要发布任何不希望别人使用的资源内容。如果您对使用者有特殊的限制，请在发布资源时，在内容或描述中标明您允许的使用方式，以供其他使用者在使用时进行参考。平台在您发布您的资源内容后，视为您已同意平台将允许其他用户对该资源内容进行查看、复制、编辑和保存在内的一系列使用行为；但平台无法对使用该资源内容的其他用户进行使用方式和使用范围及用途上的限制，对于此情况，您表示理解并不持异议。
              </p>
            </div>
            <div>
              <span>19、</span>
              <p>
                为了促进您创作内容的分享和传播，您通过本平台发表的公开资源，我们将视为您授予我们免费的、全球的、不可撤销的非独家使用许可，我们有权将内容用于各种形态开发、运营产品和服务上。
              </p>
            </div>
            <div>
              <span>20、</span>
              <p>
                如果任何第三方侵犯了您在本平台的相关权利，您同意授权本平台或其指定的代理人代表您本人或本平台对该第三方提出警告、投诉、发起行政执法、诉讼、进行上诉（但非义务），或谈判和解，并且您同意在本平台认为必要的情况下参与共同维权。
              </p>
            </div>
            <div>
              <span>21、</span>
              <p>
                如我们发现或收到他人举报您发布的内容违反法律法规、国家政策或本协议约定的，我们有权进行独立判断并采取技术手段予以删除、屏蔽或断开链接。同时有权视您的行为性质，采取包括但不限于暂停或终止服务，限制、冻结或终止帐号使用，追究法律责任等措施。
              </p>
            </div>
            <div>
              <span>24、</span>
              <p>
                某些资源中可能包含原作者在其他途径获取的数据或内容，并且这些数据或内容可能存在不同的许可协议。建议您在使用之前，通过阅读资源内容、描述页中标识说明或与联系作者了解具体情况。如因为使用平台上其他用户发布的资源内容而给您带来的纠纷、赔偿及其他一系列的法律问题，均由您和相关第三方自行解决；本平台为资源共享平台，平台在提供告知义务后，不承担任何相关的法律责任，亦不承担其他任何的保证或保障。
              </p>
            </div>
          </div>
          <div className="section">
            <h2>五、隐私政策</h2>
            <div>
              <span>25、</span>
              <p>保护您的个人隐私是我们的一项基本原则。</p>
            </div>
            <div>
              <span>26、</span>
              <p>
                您在注册帐号或使用本服务的过程中，可能需要填写一些必要的信息。请保持这些信息的及时更新，以便我们向您提供帮助，或更好地为您提供服务。若国家法律法规有特殊规定的，您需要填写真实的身份信息。若您填写的信息不完整，则可能无法使用本服务或在使用过程中受到限制。
              </p>
            </div>
            <div>
              <span>27、</span>
              <p>
                一般情况下，您可随时浏览、修改自己提交的信息，但出于安全性和身份识别（如号码申诉服务）的考虑，您可能无法修改注册时提供的初始注册信息及其他验证信息。
              </p>
            </div>
            <div>
              <span>28、</span>
              <p>
                我们将运用现有技术条件下的各种安全技术和程序建立尽可能完善的管理制度来保护您的个人信息和数据安全，以免遭受未经授权的访问、使用或披露。
              </p>
            </div>
            <div>
              <span>29、</span>
              <p>
                除以下情形外，我们不会将您的个人信息、数据转移或披露给任何非关联的第三方：
                <ul>
                  <li>相关法律法规或司法机构、政府行政机关要求；</li>
                  <li>为完成合并、分立、收购或资产转让而转移； </li>
                  <li>为提供您要求的服务所必需； </li>
                  <li>事先获得您或您代表的机构的明确同意或授权； </li>
                </ul>
              </p>
            </div>
          </div>
          <div className="section">
            <h2>六、数据安全</h2>
            <div>
              <span>30、</span>
              <p>保护您的数据安全是我们的一项基本原则。</p>
            </div>
            <div>
              <span>31、</span>
              <p>
                我们将尽力保护您使用本平台产品服务产生的数据，并为此在现有技术条件下采取合理的数据传输、存储、转换等预防保护措施。但是，互联网数据传输、存储、转换均可能存在一定未知且不确定的数据安全风险，该等风险将导致包括但不限于数据丢失、泄露、损坏、无法读取或提取等后果。您确认，您已明确知晓并同意接受该等因互联网引发的风险和后果，并已采取恰当的措施，以便在该等风险发生时将损失降至最低。
              </p>
            </div>
            <div>
              <span>32、</span>
              <p>
                因互联网技术本身等非我们主观故意或重大过失所导致危害您数据安全的情况，我们不承担任何赔偿责任。因我们重大过失危害您数据安全的情况，依据公平合理原则，我们的赔偿责任以向您收取的服务费用为赔偿上限。
              </p>
            </div>
          </div>
          <div className="section">
            <h2>七、知识产权声明</h2>
            <div>
              <span>33、</span>
              <p>
                我司所有系统及本网站上所有内容，包括但不限于著作、图片、档案、资讯、资料、网站架构、网站画面的安排、网页设计、程序代码、数据、算法等，均由我司依法拥有其知识产权，包括但不限于商标权、专利权、著作权、商业秘密等。
              </p>
            </div>
            <div>
              <span>34、</span>
              <p>
                非经我司书面同意，任何人不得擅自使用、修改、反向编译、复制、公开传播、改变、散布、发行或公开发表本网站程序或内容。
              </p>
            </div>
            <div>
              <span>35、</span>
              <p>尊重知识产权是您应尽的义务，如有违反，您应承担相应的法律责任和赔偿。</p>
            </div>
          </div>
          <div className="section">
            <h2>八、未成年人使用声明</h2>
            <div>
              <span></span>
              <p>
                本平台相关服务主要面向成人。若您是未满18周岁的未成年人，应在监护人监护、指导并获得监护人同意情况下阅读本服务条款。如果没有父母或监护人的同意，未成年人不得创建自己的用户账户。
              </p>
            </div>
            <div>
              <span></span>
              <p>
                未成年用户理解如因其个人违反法律法规、本协议内容，则该用户及其监护人应依照法律规定承担因此而导致的一切后果。
              </p>
            </div>
          </div>
          <div className="section">
            <h2>九、收费服务</h2>
            <div>
              <span>42、</span>
              <p>如您使用本平台的收费服务项目，请遵守相关的条款并按期足额支付费用。</p>
            </div>
            <div>
              <span>43、</span>
              <p>
                我们可能根据实际需要对收费服务的收费标准、内容、方式等进行修改和变更，我们也可能会对部分免费服务开始收费。前述修改、变更或开始收费前，我们将在相应服务页面进行通知或公告。如您不同意上述修改、变更或付费内容，则应停止使用该服务。我们有权更正可能无意发生的错误收费定价，出现定价错误时，我们将向您发出通知，并允许您以正确的价格继续交易，或在您不承担费用的情况下取消该笔订单。
              </p>
            </div>
          </div>
          <div className="section">
            <h2>十、不可抗力与其他免责事由</h2>
            <div>
              <span>44、</span>
              <p>
                由于不可抗力事件导致用户遭受损失的，我们不承担责任。不可抗力事件包括但不限于：
                <ul>
                  <li>自然灾害、如台风、洪水、冰雹等；</li>
                  <li>政府行为，如征收、征用等； </li>
                  <li>社会异常事件，如罢工、骚乱、疫情等；</li>
                  <li>网络及设备故障，如计算机病毒或黑客攻击、互联网络、通信线路、供电设施等原因造成的服务中断等。</li>
                </ul>
              </p>
            </div>
            <div>
              <span>45、</span>
              <p>
                您理解并同意，在使用本服务的过程中，可能会遇到网络信息或其他用户行为带来的风险，我们不对任何信息的真实性、适用性、合法性承担责任，也不对因其他用户或第三方的侵权行为给您造成的损害负责。这些风险包括但不限于：
                <ul>
                  <li>来自他人匿名或冒名的含有威胁、诽谤、令人反感或非法内容的信息；</li>
                  <li>
                    因使用本条款项下的服务，遭受他人误导、欺骗或其他导致或可能导致的任何心理、生理上的伤害以及经济上的损失；
                  </li>
                  <li>其他因网络信息或用户行为引起的风险。</li>
                </ul>
              </p>
            </div>
            <div>
              <span>46、</span>
              <p>
                平台服务中可能会包含指向第三方网站所控制的其他事件或活动链接。我们不对任何此类第三方网站提供的信息、产品、资源、服务表示认可或承担任何责任。如果您通过服务访问、使用第三方网站提供服务或内容而引起的任何责任，均由您自行承担后果，我们不承担任何责任。
              </p>
            </div>
            <div>
              <span>47、</span>
              <p>
                您理解并同意，我们对于与本条款有关或由本条款引起的任何直接的、间接的、惩罚性的、特殊的、派生的损失（包括但不限于业务损失、收益损失、利润损失、商誉或其他经济利益的损失），不论是如何产生的，也不论是由对本条款的违约还是由其他用户或第三方的侵权造成的，均不承担责任，即使您已被告知该等损失的可能性。
              </p>
            </div>
          </div>
          <div className="section">
            <h2>十一、服务的变更、中断、终止</h2>
            <div>
              <span>48、</span>
              <p>
                为了改善用户体验、完善服务内容，保证本平台产品服务的安全性和功能的一致性，我们可能在未通知用户的情况下随时对本平台产品服务或相关功能、相关应用软件进行更新、功能强化、升级、修改或转移。对此情况，您表示理解并不持异议。
              </p>
            </div>
            <div>
              <span>49、</span>
              <p>
                如因自然灾害,其他信息服务商故障，相关信息设备和软件检修、维护、故障或者任何不可阻挡因素导致服务中断或终止，将不视为我们违约，但我们应尽可能减少该因素对用户的影响，对此您不持异议。
              </p>
            </div>
            <div>
              <span>50、</span>
              <p>
                如发生下列任何一种情形，我们有权中断或终止向您提供的服务：
                <ul>
                  <li>
                    根据法律规定您应提交真实信息，而您提供的个人资料不真实、或与注册时信息不一致又未能提供合理证明；
                  </li>
                  <li>您违反相关法律法规、国家政策或本条款、补充条款的约定；</li>
                  <li>按照法律规定或行业主管部门的要求；</li>
                  <li>我们提前以公告或其他书面方式通知您终止本服务；</li>
                  <li>出于安全的原因或其他必要的情形。</li>
                </ul>
              </p>
            </div>
            <div>
              <span>51、</span>
              <p>
                如您的服务因包括但不限于上述原因被终止，我们可以从服务器上永久地删除您的数据，但法律法规另有规定的除外。服务终止后，我们没有义务向您返还数据。对此您不持异议。
              </p>
            </div>
          </div>
          <div className="section">
            <h2>十二、法律适用、管辖与其他</h2>
            <div>
              <span>52、</span>
              <p>
                本条款之订立、生效、解释、修订、补充、终止、执行与争议解决均适用中华人民共和国法律；如法律无相关规定的，参照商业惯例及/或行业惯例。
              </p>
            </div>
            <div>
              <span>53、</span>
              <p>
                您因使用本平台产品服务所产生的相关的争议，由我们与您协商解决。协商不成时，任何一方均可向平台经营所在地有管辖权的人民法院提起诉讼。
              </p>
            </div>
            <div>
              <span>54、</span>
              <p>
                本条款任一条款被视为废止、无效或不可执行，该条应视为可分的且并不影响本服务条款项下其余条款的有效性及可执行性。
              </p>
            </div>
          </div>
          <div className="section">
            <h2>十三、联系我们</h2>
            <div>
              <span>55、</span>
              <p>
                如您发现我们的服务中有内容侵犯您的合法权益（包括但不限于肖像权、著作权、内容版权、相关产品知识产权等），您可以发送邮件至
                <a href="mailto:service@klf3060.com">service@klf3060.com</a>
                将下列信息提供给我们，我们会及时给予您必要反馈和帮助。
                <ul>
                  <li>涉嫌侵权作品在平台上的位置或链接；</li>
                  <li>情况说明或（初步的）权属证明信息；</li>
                  <li>著作权人或其合法代理人的身份资料和联系方式。</li>
                </ul>
              </p>
            </div>
            <div>
              <span>56、</span>
              <p>此电子邮件地址作为本服务条款的组成部分可能会不时进行更新。</p>
            </div>
          </div>
        </div>
      </Modal>
    )
  )
}

export default ServiceTrimModal
