import {Button, Col, Modal, Row, Tabs} from "antd";

import './t-data-screen-modal.less'
import React, {useEffect, useState} from "react";
import {PowerStationControllerService} from "../../../../apis/project-service";
import moment from "moment";
import {parseNumber} from "../../../resource-reckon/resource-map";
import {kxReg} from "./t-data-screen";
import ReactECharts from "echarts-for-react";
import Global, {ChartColorMaps} from "../../../../global";
import * as echarts from "echarts";

const configuration = [
  {
    title: '当前发电功率',
    unit: 'kW',
    key: 'currPower'
  },
  {
    title: '当日发电量',
    unit: 'kWh',
    key: 'currGenerationElectricity'
  },
  {
    title: '装机容量',
    unit: 'kW',
    key: 'installedCapacity'
  },
  {
    title: '设备在线率',
    unit: '%',
    key: '',
    tipFormat: (data) => {
      return `${data.onlineNum} / ${data.deviceNum}`
    },
    format: (data: any) => {
      return `${parseNumber((data.onlineNum / data.deviceNum) * 100, {
        radix: 2,
        type: '',
      })}`;
    }
  },
]

export const TDataScreenModal = (props) => {
  console.log(props);

  const [data, setData] = useState<any>();
  const [realTimeData, setRealTimeData] = useState<any>();
  const [genEleData, setGenEleData] = useState<any>();

  const loadRealtimeData = () => {
    PowerStationControllerService.getI3060PowerStationShow({
      powerStationId: props.id,
    }).then(resp => {
      const xData = [];
      const data = moment().startOf('day');
      for (let i = 0; i < 300; i++) {
        if (i > 288) {
          xData.push('25:00:00');
        } else if (i === 288) {
          xData.push('24:00:00');
        } else {
          xData.push(data.format('HH:mm:ss'));
          data.add(5, 'minutes');
        }
      }
      setRealTimeData({
        dataUpdateTime: resp.data.dataUpdateTime,
        xdata: xData,
        ydata: resp.data.realTimePowerChart.map(o => o.ydata),
      });
    })
  }

  useEffect(() => {
    PowerStationControllerService.getI3060PowerStationShow({
      powerStationId: props.id,
    }).then(resp => {
      setData(resp.data);
      const xData = [];
      const data = moment().startOf('day');
      for (let i = 0; i < 300; i++) {
        if (i > 288) {
          xData.push('25:00:00');
        } else if (i === 288) {
          xData.push('24:00:00');
        } else {
          xData.push(data.format('HH:mm:ss'));
          data.add(5, 'minutes');
        }
      }
      setRealTimeData({
        dataUpdateTime: resp.data.dataUpdateTime,
        xdata: xData,
        ydata: resp.data.realTimePowerChart.map(o => o.ydata),
      });
      setGenEleData({
        xdata: resp.data.generationElectricityChart.map(o => o.xdata),
        ydata: resp.data.generationElectricityChart.map(o => o.ydata),
      });
    })

    const itv = setInterval(() => {
      loadRealtimeData();
    }, 60000)

    return () => {
      clearInterval(itv);
    }
  }, [])

  return <div style={{
    width: 640,
    height: 538,
  }}>
    <div className="screen-modal-title">
      {props.name}
      <Button type="link" onClick={() => {
        Modal.destroyAll();
        const url = `#/operation-management/power-station/detail/${props.id}`;
        location.assign(url)
        Global.changeMenu.forEach((Fn: any) => {
          Fn('#/operation-management/power-station')
        })
      }}>查看详情</Button>
    </div>
    <div className="screen-modal-info">
      <Row>
        <div className="screen-modal-label">{data?.address}</div>
        <div className="screen-modal-content">
          <img src="./assets/map-icon.png" alt="坐标" style={{
            width: 24,
            height: 24,
          }}/>
          {data?.lng ? Math.abs(data.lng) : ''}{data?.lng >= 0 ? 'E°' : 'W°'}&nbsp;&nbsp;&nbsp;&nbsp;
          {data?.lat ? Math.abs(data.lat) : ''}{data?.lat >= 0 ? 'N°' : 'S°'}
        </div>
      </Row>
      <Row>
        <Col span={8}>
          <Row>
            <div className="screen-modal-label">电压等级：</div>
            <div className="screen-modal-content">{data?.voltageLevel}KV</div>
          </Row>
        </Col>
        <Col span={8}>
          <Row>
            <div className="screen-modal-label">并网日期：</div>
            <div
              className="screen-modal-content">{data?.gridConnectionDate ? moment(data.gridConnectionDate).format('YYYY-MM-DD') : ''}</div>
          </Row>
        </Col>
      </Row>
    </div>
    <Row style={{
      marginTop: 16,
      marginBottom: 10,
    }}>
      {
        configuration.map(o => (<Col span={6} key={o.key}>
          {
            data && <div className="screen-modal-card">
              <div className="screen-modal-card-title">{o.title}<span>{o.tipFormat ? o.tipFormat(data) : ''}</span></div>
              <div
                className="screen-modal-card-number">{o.format ? o.format(data) : data?.[o.key]?.toString().replace(kxReg, ',')}<span
                className="screen-modal-card-unit">{o.unit}</span></div>
            </div>
          }
        </Col>))
      }
    </Row>
    <Tabs>
      <Tabs.TabPane tab="功率" key="1">
        <div className="tds-content-card-title">实时功率曲线<span
          style={{
            float: 'right',
            color: '#767F95',
            fontSize: 14,
          }}>
            <span style={{
              color: '#262E3F',
            }}>更新时间：</span>{realTimeData?.dataUpdateTime ? moment(realTimeData?.dataUpdateTime).format('YYYY-MM-DD HH:mm:ss') : '--'}</span></div>
        {
          realTimeData && <ReactECharts
            style={{
              width: '100%',
              height: 218,
            }}
            option={{
              animationDuration: 50,
              tooltip: {
                trigger: 'axis',
                formatter: (args: any) => {
                  let res = `<strong>${args[0].axisValue}</strong>`
                  if (args.length > 0) {
                    res += args
                      .map(
                        (o: any) =>
                          `<div style='width: 200px;'>${o.marker}${o.seriesName} <span style='float: right'>${o.value}kW</span></div>`
                      )
                      .join('')
                  }
                  return res
                },
              },
              grid: {left: '30px', right: '3%', top: '40px', bottom: '0px', containLabel: true},
              xAxis: {
                type: 'category',
                boundaryGap: true,
                data: realTimeData.xdata,
                axisTick: {
                  show: false,
                },
                axisLabel: {
                  showMaxLabel: true,
                  interval: 11,
                  formatter: (o) => {
                    console.log(parseInt(o.split(':')[0], 10));
                    if (parseInt(o.split(':')[0], 10) > 24) {
                      return '时';
                    }
                    return parseInt(o.split(':')[0], 10);
                  }
                },
              },
              yAxis: {
                type: 'value',
                name: '单位：kW',
                nameTextStyle: {
                  color: 'gray',
                },
              },
              // legend: {
              //   show: true,
              //   top: 'bottom',
              //   icon: 'rect',
              // },
              series: [{
                name: '实时功率',
                type: 'line',
                // sampling: 'lttb',
                smooth: true,
                showSymbol: false,
                itemStyle: {
                  color: ChartColorMaps[0].line,
                },
                areaStyle: {
                  color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                    {
                      offset: 0,
                      color: ChartColorMaps[0].area[0],
                    },
                    {
                      offset: 1,
                      color: ChartColorMaps[0].area[1],
                    },
                  ]),
                },
                lineStyle: {
                  width: 2,
                },
                data: realTimeData.ydata,
              }],
            }}
          />
        }
      </Tabs.TabPane>
      <Tabs.TabPane tab="发电量" key="2">
        <div className="tds-content-card-title">发电量曲线</div>
        {
          genEleData && <ReactECharts
            style={{
              width: '100%',
              height: 218,
            }}
            option={{
              grid: {left: '30px', right: '3%', top: '40px', bottom: '0px', containLabel: true},
              tooltip: {
                trigger: 'axis',
                formatter: (args: any) => {
                  let res = `<strong>${args[0].axisValue}</strong>`
                  if (args.length > 0) {
                    res += args
                      .map(
                        (o: any) =>
                          `<div style='width: 200px;'>${o.marker}${o.seriesName} <span style='float: right'>${o.value}kWh</span></div>`
                      )
                      .join('')
                  }
                  return res
                },
              },
              xAxis: {
                type: 'category',
                boundaryGap: true,
                data: genEleData.xdata,
              },
              yAxis: {
                type: 'value',
                name: 'kWh',
                nameTextStyle: {
                  color: 'gray',
                },
                splitLine: {
                  lineStyle: {
                    type: [5, 5],
                    dashOffset: 5,
                  },
                },
              },
              series: [
                {
                  name: '发电量',
                  type: 'bar',
                  smooth: true,
                  showSymbol: false,
                  data: genEleData.ydata,
                  itemStyle: {
                    color: ChartColorMaps[0].line,
                  },
                },
              ],
            }}
          />
        }
      </Tabs.TabPane>
    </Tabs>
  </div>
}
