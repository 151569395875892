import React, { useEffect, useRef } from 'react'
import { Checkbox, Table } from 'antd'
import { TemplateAuth } from '../../type'
import './index.less'

interface IProps {
  disabled?: boolean
  checkedKeys?: React.Key[]
  list?: TemplateAuth[]
  onCheck?: (values: React.Key[]) => void
  overflowY?: string
}

interface RowProps {
  text: any
  record: any
}

interface ActionRenderProps extends RowProps {
  onChange?: (values: string[]) => void
}

// 操作对象行渲染
const SubModuleRender = React.memo((props: RowProps) => {
  const { record } = props
  const { children } = record || {}
  return (
    <div className="project-permission-row-list">
      {children?.map((item: TemplateAuth, index: number) => {
        return (
          <div className="project-permission-row-item" key={index}>
            {item.title}
          </div>
        )
      })}
    </div>
  )
})

// 操作行渲染
const ActionRender = React.memo((props: ActionRenderProps) => {
  const { record } = props
  const { children } = record || {}
  return (
    <div className="project-permission-row-list">
      {children?.map((item: TemplateAuth, index: number) => {
        const list = item.children || []
        return (
          <div className="project-permission-row-item" key={index}>
            {list?.map(({ id, functionPermission }) => (
              <Checkbox key={id} value={id}>
                {functionPermission}
              </Checkbox>
            ))}
          </div>
        )
      })}
    </div>
  )
})

const ProjectPermission = React.memo((props: IProps) => {
  const ref = useRef(null)
  const scrollRef = useRef({ y: 400 })

  const { disabled, checkedKeys, list = [], onCheck } = props

  const handleChange = (values: string[]) => {
    onCheck?.(values)
  }

  const columns = [
    {
      title: '所属模块',
      dataIndex: 'title',
      key: 'title',
      width: 104,
    },
    {
      title: '操作对象',
      dataIndex: 'children',
      key: 'children',
      width: 146,
      render: (text, record) => <SubModuleRender text={text} record={record} />,
    },
    {
      title: '操作权限',
      key: 'action',
      render: (text, record) => <ActionRender text={text} record={record} />,
    },
  ]

  const refreshScroll = () => {
    const current = ref.current
    if (current) {
      scrollRef.current = {
        y: current.clientHeight - 100,
      }
    }
  }

  useEffect(() => {
    refreshScroll()
    window.addEventListener('resize', refreshScroll)

    return () => window.removeEventListener('resize', refreshScroll)
  }, [])

  return (
    <div className="project-permission" ref={ref}>
      <Checkbox.Group
        className="project-permission-box"
        value={checkedKeys}
        disabled={disabled}
        onChange={handleChange}
      >
        <Table
          className="project-permission-table"
          childrenColumnName="undefined"
          columns={columns}
          dataSource={list}
          pagination={false}
          scroll={scrollRef.current}
        />
      </Checkbox.Group>
    </div>
  )
})

export default ProjectPermission
