import { Button } from 'antd'
import { useState, useRef } from 'react'

import styles from '../index.module.scss'
import VerifySlide from './verify-slide'

interface Iprops {
  update: Function
  isVerifyPass: boolean
  onChange?: Function
  mode?: string
  callback?: Function
  modalVisible?: boolean
  form?: any
  name?: string
  onModalCancel?: Function
  disabled?: boolean
}

function Verify(props: Iprops) {
  const buttomRef = useRef(null)
  const {
    onChange,
    form,
    name = 'captchaVerification',
    mode = 'form',
    isVerifyPass,
    update,
    disabled,
    modalVisible,
    onModalCancel,
  } = props
  const [visible, setVisible] = useState(false)
  const toggleVisible = (value: boolean) => {
    setVisible(value)
  }

  return (
    <div className={styles.verifyWrap}>
      {mode === 'form' &&
        (isVerifyPass ? (
          <span className="verify-success">验证通过</span>
        ) : visible ? (
          <Button
            type="default"
            ref={buttomRef}
            onClick={() => {
              toggleVisible(false)
            }}
            style={{ display: 'flex', justifyContent: 'center' }}
          >
            关闭
          </Button>
        ) : (
          <Button
            type="default"
            disabled={disabled}
            onClick={() => {
              toggleVisible(true)
            }}
          >
            <div>
              <img src="./assets/verifyIcon.png" className={disabled ? 'verify-icon-disabled' : 'verify-icon'} alt="" height="28" />
              <span>点击按钮进行验证</span>
            </div>
            <img src="./assets/verifyBtnIcon.png" alt="" height="20" />
          </Button>
        ))}
      {(visible || modalVisible) && (
        <VerifySlide
          toggleVisible={toggleVisible}
          update={async (e: any) => {
            update && update(e)
            onChange && onChange(e)
            form && form.validateFields([name])
          }}
          visible={mode === 'modal' ? modalVisible : visible}
          onModalCancel={onModalCancel}
          widthDom={buttomRef}
          mode={mode}
          captchaType="blockPuzzle"
          vSpace={10}
          barSize={{ height: 40, width: 320 }}
          showRefresh="true"
          finishText="点击刷新"
          transitionLeft="width 2s"
        />
      )}
    </div>
  )
}

export default Verify
