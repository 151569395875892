import React from 'react'
import { Form, Input, Radio } from 'antd'

// 合作方企业字段
export default () => {
  return (
    <>
      <Form.Item label="合作企业类型" name="companyType" initialValue="0">
        <Radio.Group>
          <Radio value="0">企业</Radio>
          <Radio value="1">个体工商户</Radio>
        </Radio.Group>
      </Form.Item>
      <Form.Item
        label="合作方名称"
        name="name"
        rules={[
          {
            required: true,
            message: '请输入合作方名称',
          },
          {
            validator: (rule: any, value: string) => {
              if (value && value.length > 50) {
                return Promise.reject(`合作方名称最多允许输入50个字`)
              }
              return Promise.resolve()
            },
          },
        ]}
      >
        <Input allowClear placeholder="请输入合作方名称" />
      </Form.Item>
      <Form.Item
        label="社会统一信用代码"
        name="code"
        rules={[
          { required: true, message: '请输入社会统一信用代码' },
          {
            validator: (rule: any, value: string) => {
              if (value && !/^[a-zA-Z\d+]{18}$/.test(value.trim())) {
                return Promise.reject(`请输入18位社会统一信用代码`)
              }
              return Promise.resolve()
            },
          },
        ]}
      >
        <Input allowClear placeholder="请输入社会统一信用代码" />
      </Form.Item>
      <Form.Item
        label="注册资金(万)"
        name="registeredCapital"
        rules={[
          { required: true, message: '请输入注册资金(万)' },
          {
            validator: (r: any, v: string) => {
              if (v && !/^\d+(?:\.\d{1,6})?$/.test(v)) {
                return Promise.reject('请输入精度为六位小数以内的正数~')
              }
              return Promise.resolve()
            },
          },
        ]}
      >
        <Input allowClear placeholder="请输入注册资金(万)" />
      </Form.Item>
      <Form.Item label="企业地址" name="address" rules={[{ required: true, message: '请输入企业地址' }]}>
        <Input allowClear placeholder="请输入企业地址" />
      </Form.Item>
      <Form.Item label="经营范围" name="propertyJson" rules={[{ required: true, message: '请输入经营范围' }]}>
        <Input allowClear placeholder="请输入经营范围" />
      </Form.Item>
      <Form.Item
        label="邮箱"
        name="mail"
        rules={[
          { required: true, message: '请输入邮箱' },
          { type: 'email', message: '请输入正确的邮箱格式' },
        ]}
      >
        <Input allowClear placeholder="请输入邮箱" />
      </Form.Item>
      <Form.Item
        label="企业法人"
        name="legalPerson"
        rules={[
          { required: true, message: '请输入企业法人' },
          {
            validator: (rule: any, value: string) => {
              if (value && value.length > 18) {
                return Promise.reject(`企业法人最多允许输入18个字`)
              }
              return Promise.resolve()
            },
          },
        ]}
      >
        <Input allowClear placeholder="请输入企业法人" />
      </Form.Item>
      <Form.Item
        label="企业法人电话"
        name="phone"
        rules={[
          { required: true, message: '请输入企业法人电话' },
          {
            validator: (r: any, v: string) => {
              if (v && !/^\d{11}$/.test(v)) {
                return Promise.reject('企业法人电话输入有误，请重新输入~')
              }
              return Promise.resolve()
            },
          },
        ]}
      >
        <Input allowClear placeholder="请输入企业法人电话" />
      </Form.Item>
      <Form.Item
        label="相关负责人"
        name="principal"
        rules={[
          {
            validator: (rule: any, value: string) => {
              if (value && value.length > 18) {
                return Promise.reject(`相关负责人最多允许输入18个字`)
              }
              return Promise.resolve()
            },
          },
        ]}
      >
        <Input allowClear placeholder="请输入相关负责人" />
      </Form.Item>
      <Form.Item
        label="企业负责人身份证号"
        name="principalCode"
        rules={[
          {
            validator: (r: any, v: string) => {
              if (v && !/(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/.test(v)) {
                return Promise.reject('企业负责人身份证号输入有误，请重新输入~')
              }
              return Promise.resolve()
            },
          },
        ]}
      >
        <Input allowClear placeholder="请输入企业负责人身份证号" />
      </Form.Item>
      <Form.Item
        label="企业负责人联系电话"
        name="principalPhone"
        rules={[
          {
            validator: (r: any, v: string) => {
              if (v && !/^\d{11}$/.test(v)) {
                return Promise.reject('企业负责人联系电话输入有误，请重新输入~')
              }
              return Promise.resolve()
            },
          },
        ]}
      >
        <Input allowClear placeholder="请输入企业负责人联系电话" />
      </Form.Item>
      <Form.Item
        label="银联负责人"
        name="unionPerson"
        rules={[
          {
            validator: (rule: any, value: string) => {
              if (value && value.length > 18) {
                return Promise.reject(`银联负责人最多允许输入18个字`)
              }
              return Promise.resolve()
            },
          },
        ]}
      >
        <Input allowClear placeholder="请输入银联负责人" />
      </Form.Item>
      <Form.Item
        label="银联负责人电话"
        name="unionPersonPhone"
        rules={[
          {
            validator: (r: any, v: string) => {
              if (v && !/^\d{11}$/.test(v)) {
                return Promise.reject('银联负责人电话输入有误，请重新输入~')
              }
              return Promise.resolve()
            },
          },
        ]}
      >
        <Input allowClear placeholder="请输入银联负责人电话" />
      </Form.Item>
      <Form.Item label="基本户银行卡号" name="cardNo" rules={[{ required: true, message: '请输入基本户银行卡号' }]}>
        <Input allowClear placeholder="请输入基本户银行卡号" />
      </Form.Item>
      <Form.Item label="基本户开户行" name="bank" rules={[{ required: true, message: '请输入基本户开户行' }]}>
        <Input allowClear placeholder="请输入基本户开户行" />
      </Form.Item>
      <Form.Item label="基本户联行号" name="bankNo" rules={[{ required: true, message: '请输入基本户联行号' }]}>
        <Input allowClear placeholder="请输入基本户联行号" />
      </Form.Item>
      <Form.Item
        label="一般户银行卡号"
        name="normalCardNo"
        rules={[{ required: true, message: '请输入一般户银行卡号' }]}
      >
        <Input allowClear placeholder="请输入一般户银行卡号" />
      </Form.Item>
      <Form.Item label="一般户开户行" name="normalBank" rules={[{ required: true, message: '请输入一般户开户行' }]}>
        <Input allowClear placeholder="请输入一般户开户行" />
      </Form.Item>
      <Form.Item label="一般户联行号" name="normalBankNo" rules={[{ required: true, message: '请输入一般户联行号' }]}>
        <Input allowClear placeholder="请输入一般户联行号" />
      </Form.Item>
    </>
  )
}
