import React, { useState } from 'react'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { Alert, Button, Card, message, Space } from 'antd'
import { BreadcrumbObservable, SideMenuObservable } from '../../../application'
import BasicInfoForm from './components/basic-info-form'
import CollectInfoForm from './components/collect-info-form'
import ProjectPlanForm from './components/project-plan-form'
import store, { SubmitType } from './components/store'

export default () => {
  const params = useParams()
  const nav = useNavigate()
  const [searchParams] = useSearchParams()
  const [validStorage, setValidStorage] = useState<boolean>(false) // storage是否可用
  const [activeTabKey, setActiveTabKey] = useState('') // 当前Tab
  const [submiting1, setSubmiting1] = useState<boolean>(false) // 保存草稿按钮的状态
  const [submiting2, setSubmiting2] = useState<boolean>(false) // 确定按钮的状态
  const [visibleDraft, setVisibleDraft] = useState<boolean>(false) // 保存草稿按钮是否可见
  const [visibleSubmit, setVisibleSubmit] = useState<boolean>(false) // 确定按钮是否可见
  const formRef = React.useRef<any>() // 表单ref

  // 设置面包屑
  React.useEffect(() => {
    SideMenuObservable.next(false)
    BreadcrumbObservable.next([
      {
        name: '投资开发',
        url: '/invest/workbench',
      },
      {
        name: '项目库',
        url: '/invest/projectLibrary',
      },
      {
        name: '新建项目',
        url: `/invest/projectLibrary/true`,
      },
    ])
  }, [])

  // 检测浏览器是否支持本地存储
  React.useEffect(() => {
    setValidStorage(store.validStorage())
  }, [])

  // 编辑时设置projectId缓存
  React.useEffect(() => {
    const editable: boolean = params.editable === 'true'
    // 查看详情
    if (!editable) {
      store.clearAll()
      return
    }
    if (params?.id !== store.projectId) {
      // 清空缓存的基础数据
      store.clearOptions()
    }
    params?.id && store.setProjectId(params.id, store.keyMap.resourceDev)
  }, [params, store])

  // 设置默认显示tab
  React.useEffect(() => {
    setActiveTabKey(searchParams.get('s') ?? '1')
  }, [searchParams])

  // 切换tab页
  const onTabChange = React.useCallback((key: string) => {
    setActiveTabKey(key as any)
  }, [])

  const tabList = React.useMemo(() => {
    return [
      { key: '1', tab: '基本信息' },
      { key: '2', tab: '收资信息' },
      { key: '3', tab: '项目方案' },
    ]
  }, [])

  const content = React.useMemo(() => {
    switch (activeTabKey) {
      case '1':
        return (
          <BasicInfoForm
            componentRef={formRef}
            visibleDraft={setVisibleDraft}
            visibleSubmit={setVisibleSubmit}
            onNext={() => {
              setActiveTabKey('2')
            }}
          />
        )

      case '2':
        return (
          <CollectInfoForm
            componentRef={formRef}
            visibleDraft={setVisibleDraft}
            visibleSubmit={setVisibleSubmit}
            onNext={() => {
              setActiveTabKey('3')
            }}
          />
        )

      case '3':
        return (
          <ProjectPlanForm
            componentRef={formRef}
            visibleDraft={setVisibleDraft}
            visibleSubmit={setVisibleSubmit}
            onNext={() => {
              nav(-1)
              store.clearAll()
            }}
          />
        )

      default:
        return null
    }
  }, [activeTabKey])

  const submit = (options: SubmitType): void => {
    const allChangedForm = store.getChangedForm()?.filter((k: string) => !!store.getItem(k)) ?? []
    if (
      allChangedForm.length === 0 ||
      (allChangedForm.length === 1 && allChangedForm[0] === formRef.current.getName())
    ) {
      formRef.current.submit?.({ ...options, showMessage: true })
      return
    }

    for (let i = 0, len = allChangedForm.length; i < len; i++) {
      const k = allChangedForm[i]
      store.submit?.[k]?.({
        ...options,
        values: store.getItem(k),
        success: () => {
          if (i === len - 1) {
            message.success('操作成功~')
          }
        },
      })
    }
  }

  return (
    <Card
      bodyStyle={{ height: 'calc(100vh - 204px)', overflow: 'auto' }}
      className="right-actions"
      style={{ height: '100%' }}
      tabBarExtraContent={
        <Space>
          {visibleDraft ? (
            <Button
              type="primary"
              loading={submiting1}
              onClick={() => {
                if (submiting1) return
                submit({
                  onVisibleChange: setSubmiting1,
                  isDraft: true,
                  values: {},
                })
              }}
            >
              保存草稿
            </Button>
          ) : null}
          {
            visibleSubmit ? (
              <Button
                type="primary"
                loading={submiting2}
                onClick={() => {
                  if (submiting2) return
                  submit({
                    onVisibleChange: setSubmiting2,
                    isDraft: false,
                    values: {},
                  })
                }}
              >
                确定
              </Button>
            ) : null
          }
          <Button
            onClick={() => {
              store.clearAll()
              nav(-1)
            }}
          >
            取消
          </Button>
        </Space>
      }
      tabList={tabList}
      activeTabKey={activeTabKey}
      onTabChange={onTabChange}
    >
      {validStorage ? null : (
        <Alert
          type="warning"
          style={{ marginBottom: 24 }}
          message="无痕模式下，页签切换会导致数据丢失；请关闭无痕模式，或先保存草稿或提交后再切换页签。"
        />
      )}
      {content}
    </Card>
  )
}
