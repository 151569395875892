import { Button, Card, Col, Row, Tooltip } from 'antd'
import React  from 'react'
import { CustomIcons, getFixedNumber } from '../../../global'

import './index.less'
import ReactECharts from 'echarts-for-react'
import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons'
import { PVConfig } from './pv-config'
import { BatteryConfig } from './battery-config'
import { EleSelector } from '.'

const configMap = [
  {
    name: '光伏',
    icon: '',
    key: 'photovoltaicInfo',
    rows: [
      { key: 'installedCapacity', unit: 'kW', label: '装机容量' },
      { key: 'dayAvgPowerGeneration', unit: 'kWh', label: '日均发电' },
      { key: 'useHourByYear', unit: 'h', label: '年利用小时数' },
      { key: 'annualGenerationHours', unit: 'h', label: '年发电小时数' },
      { key: 'selfAbsorptionRate', unit: '%', label: '自消纳比' },
    ],
  },
  {
    name: '储能',
    icon: '',
    key: 'energyStorageInfo',
    rows: [
      { key: 'batteryCapacity', unit: 'kWh', label: '电池实际容量' },
      { key: 'pcsPower', unit: 'kW', label: 'PCS总功率' },
      { key: 'dayAvgDischarge', unit: 'kWh', label: '日均放电' },
      { key: 'dayAvgChargeDischarge', unit: '次', label: '日均循环' },
    ],
  },
  {
    name: '碳排',
    icon: '',
    key: '',
    rows: [
      { key: 'annualPowerGeneration', unit: 'MWh', label: '年发电总量' },
      { key: 'carbonReduction', unit: 'tCO2', label: '减碳量' },
      { key: 'equivalentTree', unit: '棵', label: '等效种树' },
      { key: 'greenMile', unit: '辆', label: '替代车辆' },
    ],
  },
]

export const TechResultConfig = (props: {
  proposalData: any
  currentSelected?: string
  season: string
  showPhotovoltaicInfoRecord?: any
  eleSelectorsState?: any
}) => {
  const [isConfig, setIsConfig] = React.useState(false)
  const [configTypes, setConfigTypes] = React.useState<string[]>(['global', 'pv', 'battery'])

  const { proposalData, showPhotovoltaicInfoRecord } = props

  if (!proposalData) return null

  const topology = [
    !!proposalData.topologyInfo.photovoltaicToLoad && {
      start: '光伏',
      end: '负荷',
      key: 'pv_user',
      steps: [],
      fold: true,
      selected: !!proposalData.topologyInfo.photovoltaicToLoad,
      // configuration: (
      //   <div>
      //     <Row className="inner-config-content">
      //       <Col span={10}>销售电价折扣</Col>
      //       <Col span={14}>
      //         <span className="inner-config-value">{proposalData.topologyInfo.electricityPriceDiscount}%</span>
      //       </Col>
      //     </Row>
      //   </div>
      // ),
    },
    !!proposalData.topologyInfo.photovoltaicToPowerGrid && {
      start: '光伏',
      end: '电网',
      key: 'pv_gw',
      steps: [proposalData.topologyInfo.photovoltaicParallelNetwork === 2 ? '用户变' : '光伏变'],
      configuration: (
        <div>
          <Row className="inner-config-content">
            <Col span={10}>并网点</Col>
            <Col span={14}>
              <span className="inner-config-value">
                {proposalData.topologyInfo.photovoltaicParallelNetwork === 2 ? '低压' : '高压'}
              </span>
            </Col>
          </Row>
          {/*REMOVE PRICE */}
          {/*<Row className="inner-config-content">*/}
          {/*  <Col span={10}>上网电价</Col>*/}
          {/*  <Col span={14}>*/}
          {/*    <span className="inner-config-value">{proposalData.topologyInfo.internetElectricityPrice}元/kWh</span>*/}
          {/*  </Col>*/}
          {/*</Row>*/}
        </div>
      ),
    },

    !!proposalData.topologyInfo.photovoltaicToEnergyStorage && {
      start: '光伏',
      end: '储能',
      key: 'pv_battery',
      steps: [],
      // configuration: (
      //   <div>
      //     <Row className="inner-config-content">
      //       <Col span={10}>LCOE</Col>
      //       <Col span={14}>
      //         <span className="inner-config-value">{proposalData.topologyInfo.lcde}元/kWh</span>
      //       </Col>
      //     </Row>
      //   </div>
      // ),
    },
    proposalData.topologyInfo.powerGridToEnergyStorage && {
      start: '电网',
      end: '储能',
      key: 'gw_battery',
      steps: [proposalData.topologyInfo.energyStorageParallelNetwork === 2 ? '用户变' : '储能变'],
      configuration: (
        <div>
          <Row className="inner-config-content">
            <Col span={10}>并网点</Col>
            <Col span={14}>
              <span className="inner-config-value">
                {proposalData.topologyInfo.energyStorageParallelNetwork === 2 ? '低压' : '高压'}
              </span>
            </Col>
          </Row>
          <Row className="inner-config-content">
            <Col span={10}>电费支付</Col>
            <Col span={14}>
              <span className="inner-config-value">
                {proposalData.topologyInfo.electricityPaymentType === 1 ? '由用户支付' : '自行支付'}
              </span>
            </Col>
          </Row>
        </div>
      ),
    },
    !!proposalData.topologyInfo.energyStorageToLoad && {
      start: '储能',
      end: '负荷',
      key: 'battery_user',
      steps: [],
    },
    {
      start: '电网',
      end: '负荷',
      key: 'gw_user',
      steps: ['用户变'],
      disabled: true,
    },
  ].filter(Boolean)

  return (
    <div
      className="result-config"
      style={{
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
      }}
    >
      <Card
        style={{
          height: 50,
          textAlign: 'left',
          backgroundColor: '#F7F6F8',
          borderBottomLeftRadius: 0,
          borderBottomRightRadius: 0,
        }}
        bodyStyle={{
          padding: '0',
        }}
      >
        <div
          style={{
            fontWeight: 'bold',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            height: 50,
            borderBottom: 'none',
          }}
        >
          <Button
            type="link"
            style={{
              fontSize: isConfig ? 12 : 14,
              color: isConfig ? '#262E3F' : '#1CB887',
              padding: '0 16px',
              height: '100%',
            }}
            className={`${isConfig ? '' : 'custom-tab-button-selected'}`}
            onClick={() => {
              setIsConfig(false)
            }}
          >
            结果
          </Button>
          <Button
            type="link"
            style={{
              fontSize: isConfig ? 14 : 12,
              color: isConfig ? '#1CB887' : '#262E3F',
              padding: '0 16px',
              height: '100%',
            }}
            className={`${isConfig ? 'custom-tab-button-selected' : ''}`}
            onClick={() => {
              setIsConfig(true)
            }}
          >
            参数设置
          </Button>
        </div>
      </Card>
      {isConfig && (
        <Card
          style={{
            flexGrow: 1,
            textAlign: 'left',
            marginTop: 0,
            borderTop: 'none',
            borderTopLeftRadius: 0,
            borderTopRightRadius: 0,
            maxHeight: 'calc(100% - 50px)',
            overflowY: 'auto',
          }}
          bodyStyle={{
            padding: 0,
          }}
        >
          <div className="info-body">
            <div
              className="result-title"
              onClick={() => {
                setConfigTypes((configTypes) => {
                  const key = 'global'
                  const idx = configTypes.findIndex((o) => o === key)
                  if (idx >= 0) {
                    configTypes.splice(idx, 1)
                  } else {
                    configTypes.push(key)
                  }
                  return [...configTypes]
                })
              }}
            >
              全局
              <span style={{ float: 'right', marginRight: 16 }}>
                {configTypes.includes('global') ? <CaretDownOutlined /> : <CaretUpOutlined />}
              </span>
            </div>
            {configTypes.includes('global') && (
              <div className="config-main-body">
                <div className="ant-card-head-title">
                  策略目标<span className="card-tips">{proposalData.strategyType === 1 ? '经济最优' : ''}</span>
                </div>
                <div className="ant-card-head-title">需量限制</div>
                <div
                  style={{
                    padding: '0 1rem 16px 1rem',
                  }}
                >
                  <div
                    style={{
                      width: '100%',
                      height: 150,
                    }}
                  >
                    <ReactECharts
                      style={{
                        width: '100%',
                        height: '100%',
                      }}
                      option={{
                        grid: { left: '0px', right: '0px', top: '30px', bottom: '0px', containLabel: true },
                        tooltip: {
                          trigger: 'axis',
                          formatter: (argsList: any) => {
                            const args = argsList[0]
                            return `${args.seriesName}<br />${args.marker}${args.name}月: ${args.value}kW`
                          },
                        },
                        xAxis: {
                          type: 'category',
                          data: Object.keys(proposalData.demandLimit),
                          axisLabel: {
                            formatter: (str: string) => {
                              return `${str}月`
                            },
                          },
                        },
                        yAxis: {
                          type: 'value',
                          name: 'kW',
                          nameTextStyle: {
                            color: 'gray',
                            padding: [0, 40, 0, 0],
                          },
                        },
                        series: [
                          {
                            name: '需量',
                            type: (proposalData.loadType === 1 && Object.values(proposalData.demandLimit).length < 20) ? 'bar' : 'line',
                            data: Object.values(proposalData.demandLimit),
                            barWidth: Math.min(150 / Object.values(proposalData.demandLimit).length, 18),
                            itemStyle: {
                              color: '#1EC692',
                              barBorderRadius: [4, 4, 0, 0],
                            },
                          },
                        ],
                      }}
                    />
                  </div>
                </div>
                <div className="ant-card-head-title">
                  用户变总容量<span className="card-tips">{proposalData.userTransformerCapacity}kVA</span>
                </div>
                <Card
                  title={
                    <div
                      style={{
                        padding: '4px 0',
                      }}
                    >
                      拓扑设置
                    </div>
                  }
                  className="no-padding-card"
                  style={{
                    boxShadow: 'none',
                    border: 'none',
                  }}
                  extra={
                    <Row>
                      <Col>
                        <div className="square-point start-point" />
                        起点
                      </Col>
                      <Col>
                        <div className="square-point end-point" />
                        终点
                      </Col>
                    </Row>
                  }
                  headStyle={{
                    borderBottom: 'none',
                  }}
                >
                  <div
                    style={{
                      padding: '0',
                      // borderBottom: '1px solid #f0f0f0',
                    }}
                  >
                    <div
                      style={{
                        padding: '0 6px',
                        // borderBottom: '1px solid #f0f0f0',
                      }}
                    >
                      {topology.map((o, idx) => {
                        return (
                          <Card
                            className="custom-card"
                            key={o.key}
                            style={{
                              marginTop: 0,
                              marginBottom: '.5rem',
                              boxShadow: '0px 2px 14px 0px rgba(190, 194, 204, 0.3)',
                            }}
                            title={
                              <Row
                                style={{
                                  justifyContent: 'space-between',
                                  alignItems: 'center',
                                  height: 52,
                                  width: '100%',
                                }}
                              >
                                <Col>
                                  <span className="start-label">{o.start}</span>
                                </Col>
                                <Col key={`arrow-${idx}`} className="arrow-right-label">
                                  <CustomIcons type="klf-arrow" />
                                </Col>
                                {o.steps.map((o: string, idx: number) => [
                                  <Col key={idx} className="cross-label">
                                    {o}
                                  </Col>,
                                  <Col key={`arrow-${idx}`} className="arrow-right-label">
                                    <CustomIcons type="klf-arrow" />
                                  </Col>,
                                ])}
                                <Col>
                                  <span className="end-label">{o.end}</span>
                                </Col>
                              </Row>
                            }
                            headStyle={{
                              minHeight: 48,
                            }}
                            bodyStyle={{
                              padding: '0px 12px 8px 12px',
                              display: 'block',
                            }}
                          >
                            <div className="dotted-line" />
                            <EleSelector
                              viewMode
                              kKey={o.key}
                              technologyId={''}
                              eleSelectors={props.eleSelectorsState || []}
                              data={proposalData}
                              callback={() => {}}
                            />
                            {o.configuration}
                          </Card>
                        )
                      })}
                    </div>
                  </div>
                </Card>
              </div>
            )}
          </div>
          {proposalData.photovoltaicInfo && (
            <div className="info-body">
              <div
                className="result-title"
                onClick={() => {
                  setConfigTypes((configTypes) => {
                    const key = 'pv'
                    const idx = configTypes.findIndex((o) => o === key)
                    if (idx >= 0) {
                      configTypes.splice(idx, 1)
                    } else {
                      configTypes.push(key)
                    }
                    return [...configTypes]
                  })
                }}
              >
                光伏
                <span style={{ float: 'right', marginRight: 16 }}>
                  {configTypes.includes('pv') ? <CaretDownOutlined /> : <CaretUpOutlined />}
                </span>
              </div>
              {configTypes.includes('pv') && (
                <div>
                  <PVConfig
                    viewMode
                    loading={false}
                    info={proposalData.photovoltaicInfo}
                    geo={[proposalData.lng, proposalData.lat]}
                    onTriggerReload={() => {}}
                  />
                </div>
              )}
            </div>
          )}
          {proposalData.energyStorageInfo && (
            <div className="info-body">
              <div
                className="result-title"
                onClick={() => {
                  setConfigTypes((configTypes) => {
                    const key = 'battery'
                    const idx = configTypes.findIndex((o) => o === key)
                    if (idx >= 0) {
                      configTypes.splice(idx, 1)
                    } else {
                      configTypes.push(key)
                    }
                    return [...configTypes]
                  })
                }}
              >
                储能
                <span style={{ float: 'right', marginRight: 16 }}>
                  {configTypes.includes('battery') ? <CaretDownOutlined /> : <CaretUpOutlined />}
                </span>
              </div>
              {configTypes.includes('battery') && (
                <div>
                  <BatteryConfig
                    loading={false}
                    viewMode
                    info={proposalData.energyStorageInfo}
                    onTriggerReload={() => {}}
                  />
                </div>
              )}
            </div>
          )}
        </Card>
      )}
      {!isConfig && (
        <Card
          style={{
            flexGrow: 1,
            textAlign: 'left',
            marginTop: 0,
            borderTop: 'none',
            borderTopLeftRadius: 0,
            borderTopRightRadius: 0,
            maxHeight: 'calc(100% - 50px)',
            overflowY: 'auto',
          }}
          bodyStyle={{
            padding: 0,
          }}
        >
          {!props.currentSelected &&
            configMap.map((o) => {
              if (o.key && (!props.proposalData || !props.proposalData?.[o.key])) {
                return null
              }
              return (
                <div
                  key={o.name}
                  className="info-body"
                  style={{
                    paddingLeft: 16,
                    paddingRight: 16,
                  }}
                >
                  <div className={`info-title ${o.key === 'photovoltaicInfo' && 'photovoltaicInfo-flex'}`}>
                    {o.icon}
                    {o.name}
                    {o.key === 'photovoltaicInfo' && (
                      <Tooltip title="查看光伏发电验证报告">
                        <span className="info-title-record" onClick={showPhotovoltaicInfoRecord}>
                          查看验证报告
                        </span>
                      </Tooltip>
                    )}
                  </div>
                  <Row>
                    {o.rows.map((oo) => (
                      <Col span={12} key={oo.key} className="info-col">
                        <div className="info-content">
                          <span>
                            {getFixedNumber(
                              o.key ? props.proposalData?.[o.key]?.[oo.key] : props.proposalData[oo.key],
                              2
                            )}
                          </span>
                          <span className="info-unit">{oo.unit}</span>
                        </div>
                        <div className="info-label">{oo.label}</div>
                      </Col>
                    ))}
                  </Row>
                </div>
              )
            })}
          {props.currentSelected === 'pv' && (
            <div className="info-body">
              <div className="result-title">光伏</div>
              <div className="result-sub-title">日发电量</div>
              <div className="result-charts">
                <ReactECharts
                  style={{
                    width: '100%',
                    height: '180px',
                  }}
                  option={{
                    grid: { left: 10, right: 0, top: 40, bottom: 0, containLabel: true },
                    tooltip: {
                      show: true,
                      trigger: 'axis',
                      formatter: (argsList: any[]) => {
                        const args: any = argsList[0]
                        return `${args.name}<br />${args.marker}${parseFloat(args.value.toFixed(2))}kWh`
                      },
                    },
                    xAxis: {
                      type: 'category',
                      boundaryGap: true,
                      data: Object.keys(props.proposalData.photovoltaicInfo.dayAvgPowerGenerationChart),
                      // show: false,
                    },
                    yAxis: [
                      {
                        type: 'value',
                        name: 'kWh',
                        splitLine: {
                          lineStyle: {
                            type: 'dashed',
                          },
                        },
                        boundaryGap: false,
                        nameTextStyle: {
                          color: 'gray',
                          padding: [0, 40, 0, 0],
                        },
                      },
                      {
                        type: 'value',
                        name: `{a|--} 平均值: ${getFixedNumber(
                          (
                            Object.values(props.proposalData.photovoltaicInfo.dayAvgPowerGenerationChart) as number[]
                          ).reduce((a: number, b: number) => a + b) /
                            Object.values(props.proposalData.photovoltaicInfo.dayAvgPowerGenerationChart).length,
                          2
                        )}kWh`,
                        nameTextStyle: {
                          color: 'gray',
                          align: 'right',
                          rich: {
                            a: {
                              color: '#1ec692',
                            },
                          },
                        },
                        axisLine: {
                          // show: false,
                        },
                        axisLabel: {
                          // show: false,
                        },
                        axisTick: {
                          // show: false,
                        },
                      },
                    ],
                    series: [
                      {
                        name: '',
                        type: (proposalData.loadType === 1 && Object.values(props.proposalData.photovoltaicInfo.dayAvgPowerGenerationChart).length < 20)  ? 'bar' : 'line',
                        data: Object.values(props.proposalData.photovoltaicInfo.dayAvgPowerGenerationChart),
                        barWidth: 18,
                        itemStyle: {
                          color: '#1EC692',
                          barBorderRadius: [4, 4, 0, 0],
                        },
                        markLine: {
                          silent: true,
                          symbol: 'none',
                          data: [
                            {
                              yAxis:
                                (
                                  Object.values(
                                    props.proposalData.photovoltaicInfo.dayAvgPowerGenerationChart
                                  ) as number[]
                                ).reduce((a: number, b: number) => a + b) /
                                Object.values(props.proposalData.photovoltaicInfo.dayAvgPowerGenerationChart).length,
                            },
                          ],
                          lineStyle: {
                            normal: {
                              type: 'dashed',
                              color: '#1EC692',
                            },
                          },
                          label: {
                            show: false,
                          },
                        },
                      },
                    ],
                  }}
                />
              </div>
              <div className="result-sub-title">自消纳率</div>
              <div className="result-charts">
                <ReactECharts
                  style={{
                    width: '100%',
                    height: '180px',
                  }}
                  option={{
                    grid: { left: 10, right: 0, top: 40, bottom: 0, containLabel: true },
                    tooltip: {
                      show: true,
                      trigger: 'axis',
                      formatter: (argsList: any[]) => {
                        const args: any = argsList[0]
                        return `${args.name}<br />${args.marker}${parseFloat(args.value.toFixed(2))}%`
                      },
                    },
                    xAxis: {
                      type: 'category',
                      boundaryGap: true,
                      data: Object.keys(props.proposalData.photovoltaicInfo.dayAvgPowerGenerationChart),
                      // show: false,
                    },
                    yAxis: [
                      {
                        type: 'value',
                        name: '%',
                        splitLine: {
                          lineStyle: {
                            type: 'dashed',
                          },
                        },
                        boundaryGap: false,
                        nameTextStyle: {
                          color: 'gray',
                          padding: [0, 40, 0, 0],
                        },
                      },
                      {
                        type: 'value',
                        name: `{a|--} 平均值: ${getFixedNumber(
                          (
                            Object.values(props.proposalData.photovoltaicInfo.selfAbsorptionRateChart) as number[]
                          ).reduce((a: number, b: number) => a + b) /
                            Object.values(props.proposalData.photovoltaicInfo.selfAbsorptionRateChart).length,
                          2
                        )}%`,
                        nameTextStyle: {
                          color: 'gray',
                          align: 'right',
                          rich: {
                            a: {
                              color: '#3274F6',
                            },
                          },
                        },
                        axisLine: {
                          // show: false,
                        },
                        axisLabel: {
                          // show: false,
                        },
                        axisTick: {
                          // show: false,
                        },
                      },
                    ],
                    series: [
                      {
                        name: '',
                        type: (proposalData.loadType === 1 && Object.values(props.proposalData.photovoltaicInfo.selfAbsorptionRateChart).length < 20)  ? 'bar' : 'line',
                        data: Object.values(props.proposalData.photovoltaicInfo.selfAbsorptionRateChart),
                        barWidth: 18,
                        itemStyle: {
                          color: '#3274F6',
                          barBorderRadius: [4, 4, 0, 0],
                        },
                        markLine: {
                          silent: true,
                          symbol: 'none',
                          data: [
                            {
                              yAxis:
                                (
                                  Object.values(props.proposalData.photovoltaicInfo.selfAbsorptionRateChart) as number[]
                                ).reduce((a: number, b: number) => a + b) /
                                Object.values(props.proposalData.photovoltaicInfo.selfAbsorptionRateChart).length,
                            },
                          ],
                          lineStyle: {
                            normal: {
                              type: 'dashed',
                              color: '#3274F6',
                            },
                          },
                          label: {
                            show: false,
                          },
                        },
                      },
                    ],
                  }}
                />
              </div>
              <div className="result-sub-title">能流图</div>
              <div className="result-charts">
                <ReactECharts
                  style={{
                    width: '100%',
                    height: '180px',
                  }}
                  option={{
                    tooltip: {
                      show: true,
                      formatter: (...argsList: any[]) => {
                        const args: any = argsList[0]
                        if (args.marker.includes('transparent')) {
                          return `${args.name.replace(' > ', '-')}<br />${args.marker.replace(
                            'transparent',
                            args.data.lineStyle.color
                          )}${parseFloat(args.value.toFixed(2))}kWh`
                        } else {
                          return `${args.name.replace(' > ', '-')}<br />${args.marker}${parseFloat(
                            args.value.toFixed(2)
                          )}kWh`
                        }
                      },
                    },
                    series: [
                      {
                        type: 'sankey',
                        nodeGap: 20,
                        layout: 'none',
                        nodeWidth: 3,
                        emphasis: {
                          focus: 'adjacency',
                        },
                        width: '240px',
                        labelLayout: (params: any) => {
                          if (params.text === '光伏') {
                            return {
                              x: params.rect.x + 10,
                              y: params.rect.y + params.rect.height / 2,
                              verticalAlign: 'middle',
                              align: 'left',
                            }
                          }
                          return {
                            x: params.rect.x - 10,
                            y: params.rect.y + params.rect.height / 2,
                            verticalAlign: 'middle',
                            align: 'right',
                          }
                        },
                        data: [
                          proposalData.photovoltaicInfo && {
                            name: '光伏',
                            itemStyle: {
                              color: '#98A0B3',
                            },
                          },
                          {
                            name: '负荷',
                            itemStyle: {
                              color: '#1EC692',
                            },
                          },
                          proposalData.energyStorageInfo && {
                            name: '储能',
                            itemStyle: {
                              color: '#3274F6',
                            },
                          },
                          {
                            name: '电网',
                            itemStyle: {
                              color: '#F97942',
                            },
                          },
                        ].filter(Boolean),
                        links: [
                          {
                            source: '光伏',
                            target: '负荷',
                            value: props.proposalData.photovoltaicInfo.photovoltaicToLoadEnergyFlow,
                            lineStyle: {
                              color: 'rgba(30, 198, 146, .5)',
                            },
                          },
                          {
                            source: '光伏',
                            target: '储能',
                            value: props.proposalData.photovoltaicInfo.photovoltaicToEnergyStorageEnergyFlow,
                            lineStyle: {
                              color: 'rgba(50, 116, 246, .5)',
                            },
                          },
                          {
                            source: '光伏',
                            target: '电网',
                            value: props.proposalData.photovoltaicInfo.photovoltaicToPowerGridEnergyFlow,
                            lineStyle: {
                              color: 'rgba(249, 121, 66, .5)',
                            },
                          },
                        ],
                      },
                    ],
                  }}
                />
              </div>
            </div>
          )}
          {props.currentSelected === 'battery' && (
            <div className="info-body">
              <div className="result-title">储能</div>
              <div className="result-sub-title">日放电量</div>
              <div className="result-charts">
                <ReactECharts
                  style={{
                    width: '100%',
                    height: '180px',
                  }}
                  option={{
                    grid: { left: '50px', right: '10px', top: '40px', bottom: '25px' },
                    tooltip: {
                      show: true,
                      trigger: 'axis',
                      formatter: (argsList: any) => {
                        const args: any = argsList[0]
                        return `${args.name}<br />${args.marker}${parseFloat(args.value.toFixed(2))}kWh`
                      },
                    },
                    xAxis: {
                      type: 'category',
                      boundaryGap: true,
                      data: Object.keys(props.proposalData.energyStorageInfo.dayDischargeChart),
                      // show: false,
                    },
                    yAxis: [
                      {
                        type: 'value',
                        name: 'kWh',
                        splitLine: {
                          lineStyle: {
                            type: 'dashed',
                          },
                        },
                        boundaryGap: false,
                        nameTextStyle: {
                          color: 'gray',
                          padding: [0, 40, 0, 0],
                        },
                      },
                      {
                        type: 'value',
                        name: `{a|--} 平均值: ${getFixedNumber(
                          (Object.values(props.proposalData.energyStorageInfo.dayDischargeChart) as number[]).reduce(
                            (a: number, b: number) => a + b
                          ) / Object.values(props.proposalData.energyStorageInfo.dayDischargeChart).length,
                          2
                        )}kWh`,
                        nameTextStyle: {
                          color: 'gray',
                          align: 'right',
                          rich: {
                            a: {
                              color: '#1ec692',
                            },
                          },
                        },
                        axisLine: {
                          // show: false,
                        },
                        axisLabel: {
                          // show: false,
                        },
                        axisTick: {
                          // show: false,
                        },
                      },
                    ],
                    series: [
                      {
                        name: '',
                        type: (proposalData.loadType === 1 && Object.values(proposalData.demandLimit).length < 20)  ? 'bar' : 'line',
                        data: Object.values(props.proposalData.energyStorageInfo.dayDischargeChart),
                        barWidth: 18,
                        itemStyle: {
                          color: '#1EC692',
                          barBorderRadius: [4, 4, 0, 0],
                        },
                        markLine: {
                          silent: true,
                          symbol: 'none',
                          data: [
                            {
                              yAxis:
                                (
                                  Object.values(props.proposalData.energyStorageInfo.dayDischargeChart) as number[]
                                ).reduce((a: number, b: number) => a + b) /
                                Object.values(props.proposalData.energyStorageInfo.dayDischargeChart).length,
                            },
                          ],
                          lineStyle: {
                            normal: {
                              type: 'dashed',
                              color: '#1EC692',
                            },
                          },
                          label: {
                            show: false,
                          },
                        },
                      },
                    ],
                  }}
                />
              </div>
              <div className="result-sub-title">日循环次数</div>
              <div className="result-charts">
                <ReactECharts
                  style={{
                    width: '100%',
                    height: '180px',
                  }}
                  option={{
                    grid: { left: '50px', right: '10px', top: '40px', bottom: '25px' },
                    tooltip: {
                      show: true,
                      trigger: 'axis',
                      formatter: (argsList: any) => {
                        const args: any = argsList[0]
                        return `${args.name}<br />${args.marker}${parseFloat(args.value.toFixed(2))}次`
                      },
                    },
                    xAxis: {
                      type: 'category',
                      boundaryGap: true,
                      data: Object.keys(props.proposalData.energyStorageInfo.dayChargeDischargeChart),
                      // show: false,
                    },
                    yAxis: [
                      {
                        type: 'value',
                        name: '次',
                        splitLine: {
                          lineStyle: {
                            type: 'dashed',
                          },
                        },
                        boundaryGap: false,
                        nameTextStyle: {
                          color: 'gray',
                          padding: [0, 40, 0, 0],
                        },
                      },
                      {
                        type: 'value',
                        name: `{a|--} 平均值: ${getFixedNumber(
                          (
                            Object.values(props.proposalData.energyStorageInfo.dayChargeDischargeChart) as number[]
                          ).reduce((a: number, b: number) => a + b) /
                            Object.values(props.proposalData.energyStorageInfo.dayChargeDischargeChart).length,
                          2
                        )}次`,
                        nameTextStyle: {
                          color: 'gray',
                          align: 'right',
                          rich: {
                            a: {
                              color: '#3274F6',
                            },
                          },
                        },
                        axisLine: {
                          // show: false,
                        },
                        axisLabel: {
                          // show: false,
                        },
                        axisTick: {
                          // show: false,
                        },
                      },
                    ],
                    series: [
                      {
                        name: '',
                        type: (proposalData.loadType === 1 && Object.values(proposalData.demandLimit).length < 20)  ? 'bar' : 'line',
                        data: Object.values(props.proposalData.energyStorageInfo.dayChargeDischargeChart),
                        barWidth: 18,
                        itemStyle: {
                          color: '#3274F6',
                          barBorderRadius: [4, 4, 0, 0],
                        },
                        markLine: {
                          silent: true,
                          symbol: 'none',
                          data: [
                            {
                              yAxis:
                                (
                                  Object.values(
                                    props.proposalData.energyStorageInfo.dayChargeDischargeChart
                                  ) as number[]
                                ).reduce((a: number, b: number) => a + b) /
                                Object.values(props.proposalData.energyStorageInfo.dayChargeDischargeChart).length,
                            },
                          ],
                          lineStyle: {
                            normal: {
                              type: 'dashed',
                              color: '#3274F6',
                            },
                          },
                          label: {
                            show: false,
                          },
                        },
                      },
                    ],
                  }}
                />
              </div>
              <div className="result-sub-title">能流图</div>
              <div className="result-charts">
                <ReactECharts
                  style={{
                    width: '100%',
                    height: '180px',
                  }}
                  option={{
                    tooltip: {
                      show: true,
                      trigger: 'axis',
                      formatter: (argsList: any) => {
                        const args: any = argsList[0]
                        if (args.marker.includes('transparent')) {
                          return `${args.name.replace(' > ', '-')}<br />${args.marker.replace(
                            'transparent',
                            args.data.lineStyle.color
                          )}${parseFloat(args.value.toFixed(2))}kWh`
                        } else {
                          return `${args.name.replace(' > ', '-')}<br />${args.marker}${parseFloat(
                            args.value.toFixed(2)
                          )}kWh`
                        }
                      },
                    },
                    series: [
                      {
                        type: 'sankey',
                        layout: 'none',
                        nodeWidth: 3,
                        nodeGap: 20,
                        emphasis: {
                          focus: 'adjacency',
                        },
                        width: '240px',
                        labelLayout: (params: any) => {
                          if (params.text === '光伏' || params.text === '电网' || params.text === '储能') {
                            return {
                              x: params.rect.x + 10,
                              y: params.rect.y + params.rect.height / 2,
                              verticalAlign: 'middle',
                              align: 'left',
                            }
                          }
                          return {
                            x: params.rect.x - 10,
                            y: params.rect.y + params.rect.height / 2,
                            verticalAlign: 'middle',
                            align: 'right',
                          }
                        },
                        data: [
                          proposalData.photovoltaicInfo && {
                            name: '光伏',
                            itemStyle: {
                              color: '#1EC692',
                            },
                          },
                          {
                            name: '电网',
                            itemStyle: {
                              color: '#3274F6',
                            },
                          },
                          proposalData.energyStorageInfo && {
                            name: '储能',
                            itemStyle: {
                              color: '#9A9EAE',
                            },
                          },
                          {
                            name: '负荷',
                            itemStyle: {
                              color: '#B532F0',
                            },
                          },
                          {
                            name: '损耗',
                            itemStyle: {
                              color: '#F77C3E',
                            },
                          },
                        ].filter(Boolean),
                        links: [
                          {
                            source: '光伏',
                            target: '储能',
                            value: props.proposalData.energyStorageInfo.photovoltaicToEnergyStorageEnergyFlow,
                            lineStyle: {
                              color: 'rgba(30, 198, 146, .5)',
                            },
                          },
                          {
                            source: '电网',
                            target: '储能',
                            value: props.proposalData.energyStorageInfo.powerGridToEnergyStorageEnergyFlow,
                            lineStyle: {
                              color: 'rgba(50, 116, 246, .5)',
                            },
                          },
                          {
                            source: '储能',
                            target: '负荷',
                            value: props.proposalData.energyStorageInfo.energyStorageToLoadEnergyFlow,
                            lineStyle: {
                              color: 'rgba(181, 50, 241, .5)',
                            },
                          },
                          {
                            source: '储能',
                            target: '损耗',
                            value: props.proposalData.energyStorageInfo.energyStorageToLossEnergyFlow,
                            lineStyle: {
                              color: 'rgba(249, 121, 66, .5)',
                            },
                          },
                        ],
                      },
                    ],
                  }}
                />
              </div>
            </div>
          )}
        </Card>
      )}
    </div>
  )
}
