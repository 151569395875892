/**
 * project
 * unknown
 *
 * OpenAPI spec version: last
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import ajax, { AjaxPromise, ExtraFetchParams } from '@ajax';
import * as models from '../model/models';

/* tslint:disable:no-unused-variable member-ordering object-literal-shorthand */

/**
  * @description i3060RoofGetRoofWordGet参数
  * @property `adcode` 地区code
  * @property `type` 0:word下载后缀为.docx 1:pdf下载后缀为.pdf
  */
export interface Paramsi3060RoofGetRoofWordGet {
  // queryParams
  /**
   * 地区code
   */
  adcode: string;
  /**
   * 0:word下载后缀为.docx 1:pdf下载后缀为.pdf
   */
  type: string;
}
/**
  * @description i3060RoofInitResourceDetailChartGet参数
  * @property `adcode` 
  */
export interface Paramsi3060RoofInitResourceDetailChartGet {
  // queryParams
  /**
   * 
   */
  adcode: string;
}
/**
  * @description i3060RoofInitResourceIndicatorsChartGet参数
  * @property `adcode` 
  */
export interface Paramsi3060RoofInitResourceIndicatorsChartGet {
  // queryParams
  /**
   * 
   */
  adcode: string;
}
/**
  * @description i3060RoofInitRoofSectionChartGet参数
  * @property `adcode` 
  */
export interface Paramsi3060RoofInitRoofSectionChartGet {
  // queryParams
  /**
   * 
   */
  adcode: string;
}
/**
  * @description i3060RoofQueryAvailableAreasDataGet参数
  * @property `adcode` 地区code
  */
export interface Paramsi3060RoofQueryAvailableAreasDataGet {
  // queryParams
  /**
   * 地区code
   */
  adcode: string;
}
/**
  * @description i3060RoofQueryDistrictAreaDataGet参数
  * @property `adcode` 地区code
  */
export interface Paramsi3060RoofQueryDistrictAreaDataGet {
  // queryParams
  /**
   * 地区code
   */
  adcode: string;
}

export class RoofWordApi {
  protected $basePath = '/api'.replace(/\/$/, '');

  public constructor(basePath?: string) {
    if (basePath !== undefined) {
      this.$basePath = basePath.replace(/\/$/, '');
    }
  }

  /**
   * 
   * @summary 获取省市code
   
   
   * @param opt ajax config
   * @returns models.I3060RoofGetRegionCode
   */
  public i3060RoofGetRegionCodeGet = (
    
    opt?: ExtraFetchParams
  ) : AjaxPromise<models.I3060RoofGetRegionCode>  => {
    const url = this.$basePath + `/i3060/roof/getRegionCode`;
    const p: any = {};
    return ajax.ajax({
      ...opt,
      method: 'GET',
      url,
      ...p
    });
  }
  /**
   * 返回流文件  .docx 需要重新重命名
   * @summary 屋顶报告word/pdf下载
   * @param params Paramsi3060RoofGetRoofWordGet
   
   * @param opt ajax config
   * @returns any
   */
  public i3060RoofGetRoofWordGet = (
    params: Paramsi3060RoofGetRoofWordGet,
    opt?: ExtraFetchParams
  ) : AjaxPromise<any>  => {
    const url = this.$basePath + `/i3060/roof/getRoofWord`;
    const p: any = {};
    p.query = {};
    if ('adcode' in params) p.query.adcode = params.adcode;
    ajax.check(params.adcode, 'adcode');
    if ('type' in params) p.query.type = params.type;
    ajax.check(params.type, 'type');
    return ajax.ajax({
      ...opt,
      method: 'GET',
      url,
      ...p
    });
  }
  /**
   * 
   * @summary 2.屋顶资源-资源详情
   * @param params Paramsi3060RoofInitResourceDetailChartGet
   
   * @param opt ajax config
   * @returns models.I3060RoofInitResourceDetailChart
   */
  public i3060RoofInitResourceDetailChartGet = (
    params: Paramsi3060RoofInitResourceDetailChartGet,
    opt?: ExtraFetchParams
  ) : AjaxPromise<models.I3060RoofInitResourceDetailChart>  => {
    const url = this.$basePath + `/i3060/roof/initResourceDetailChart`;
    const p: any = {};
    p.query = {};
    if ('adcode' in params) p.query.adcode = params.adcode;
    ajax.check(params.adcode, 'adcode');
    return ajax.ajax({
      ...opt,
      method: 'GET',
      url,
      ...p
    });
  }
  /**
   * 
   * @summary 2.屋顶资源-资源指标
   * @param params Paramsi3060RoofInitResourceIndicatorsChartGet
   
   * @param opt ajax config
   * @returns models.I3060RoofInitResourceIndicatorsChart
   */
  public i3060RoofInitResourceIndicatorsChartGet = (
    params: Paramsi3060RoofInitResourceIndicatorsChartGet,
    opt?: ExtraFetchParams
  ) : AjaxPromise<models.I3060RoofInitResourceIndicatorsChart>  => {
    const url = this.$basePath + `/i3060/roof/initResourceIndicatorsChart`;
    const p: any = {};
    p.query = {};
    if ('adcode' in params) p.query.adcode = params.adcode;
    ajax.check(params.adcode, 'adcode');
    return ajax.ajax({
      ...opt,
      method: 'GET',
      url,
      ...p
    });
  }
  /**
   * 
   * @summary 1.摘要-屋顶分布区间
   * @param params Paramsi3060RoofInitRoofSectionChartGet
   
   * @param opt ajax config
   * @returns models.I3060RoofInitRoofSectionChart
   */
  public i3060RoofInitRoofSectionChartGet = (
    params: Paramsi3060RoofInitRoofSectionChartGet,
    opt?: ExtraFetchParams
  ) : AjaxPromise<models.I3060RoofInitRoofSectionChart>  => {
    const url = this.$basePath + `/i3060/roof/initRoofSectionChart`;
    const p: any = {};
    p.query = {};
    if ('adcode' in params) p.query.adcode = params.adcode;
    ajax.check(params.adcode, 'adcode');
    return ajax.ajax({
      ...opt,
      method: 'GET',
      url,
      ...p
    });
  }
  /**
   * 
   * @summary 未安装屋顶总面积直方图数据
   * @param params Paramsi3060RoofQueryAvailableAreasDataGet
   
   * @param opt ajax config
   * @returns models.I3060RoofQueryAvailableAreasData
   */
  public i3060RoofQueryAvailableAreasDataGet = (
    params: Paramsi3060RoofQueryAvailableAreasDataGet,
    opt?: ExtraFetchParams
  ) : AjaxPromise<models.I3060RoofQueryAvailableAreasData>  => {
    const url = this.$basePath + `/i3060/roof/queryAvailableAreasData`;
    const p: any = {};
    p.query = {};
    if ('adcode' in params) p.query.adcode = params.adcode;
    ajax.check(params.adcode, 'adcode');
    return ajax.ajax({
      ...opt,
      method: 'GET',
      url,
      ...p
    });
  }
  /**
   * 
   * @summary 查询行政区域面积直方图面积数据
   * @param params Paramsi3060RoofQueryDistrictAreaDataGet
   
   * @param opt ajax config
   * @returns models.I3060RoofQueryDistrictAreaData
   */
  public i3060RoofQueryDistrictAreaDataGet = (
    params: Paramsi3060RoofQueryDistrictAreaDataGet,
    opt?: ExtraFetchParams
  ) : AjaxPromise<models.I3060RoofQueryDistrictAreaData>  => {
    const url = this.$basePath + `/i3060/roof/queryDistrictAreaData`;
    const p: any = {};
    p.query = {};
    if ('adcode' in params) p.query.adcode = params.adcode;
    ajax.check(params.adcode, 'adcode');
    return ajax.ajax({
      ...opt,
      method: 'GET',
      url,
      ...p
    });
  }
}

export default new RoofWordApi();