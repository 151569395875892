import React, { useEffect, useRef, useState } from 'react'
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { Button, Space, Card, Dropdown, Menu } from 'antd'
import { DownOutlined } from '@ant-design/icons'

import { BreadcrumbObservable, SideMenuObservable } from '../../../application'

import ResourceDevForm from './components/resource-dev-form'
import RecordForm from './components/record-form'
import InvestDecisionForm from './components/invest-decision-form'
import ProjectProductionForm from './components/project-production-form'
import PlanExecuteForm from './components/plan-execute-form'
import InvestPlanForm from './components/invest-plan-form'
import ProjectCostForm from './components/project-cost-form'
import { IFormRef } from './components'

export default () => {
  const params: { id?: string; editable?: string } = useParams()
  const location = useLocation()
  const [searchParams] = useSearchParams()
  const nav = useNavigate()
  const formRef = useRef<IFormRef>()
  const [submiting, setSubmiting] = useState<boolean>(false)
  const [drafting, setDrafting] = useState<boolean>(false)
  const [currentCost, setCurrentCost] = useState<string>('')

  const ref = useRef({
    modules: [
      { key: '1', label: '资源开发' },
      { key: '2', label: '项目核准备案' },
      { key: '3', label: '投资决策' },
      { key: '4', label: '建设' }, // 基建项目
      {
        key: '5',
        label: '造价',
        children: [
          { label: '可研预算', key: '1' },
          { label: '初设概算', key: '2' },
          { label: '竣工结算', key: '3' },
        ],
      }, // 项目造价
      { key: '6', label: '投资计划' },
      { key: '7', label: '投资进度' }, // 投资计划执行
    ],
  })

  // 设置面包屑
  useEffect(() => {
    SideMenuObservable.next(false)

    let breadcrumb: any = BreadcrumbObservable.getValue()
    if (breadcrumb.length > 0) {
      window.localStorage.setItem('wl-breadcrumb', JSON.stringify(breadcrumb))
    } else {
      breadcrumb = window.localStorage.getItem('wl-breadcrumb')
      breadcrumb = breadcrumb ? JSON.parse(breadcrumb) : []
      BreadcrumbObservable.next(breadcrumb)
    }

    return () => {
      // window.localStorage.removeItem('wl-breadcrumb')
    }
  }, [])

  // 设置造价阶段
  useEffect(() => {
    setCurrentCost(searchParams.get('t') ?? '')
  }, [searchParams.get('t')])

  const editable: boolean = params.editable === 'true'
  const content = React.useMemo(() => {
    const formProps = {
      ref: formRef,
      id: params.id,
      editable,
    }
    switch (searchParams.get('moduleId')) {
      case '1':
        return <ResourceDevForm {...formProps} />
      case '2':
        return <RecordForm {...formProps} />
      case '3':
        return <InvestDecisionForm {...formProps} />
      case '4':
        return <ProjectProductionForm {...formProps} />
      case '5':
        return <ProjectCostForm t={currentCost} {...formProps} />
      case '6':
        return <InvestPlanForm {...formProps} />
      case '7':
        return <PlanExecuteForm {...formProps} />
      default:
        return null
    }
  }, [searchParams, params, editable, currentCost])

  return (
    <Card
      style={{ height: '100%' }}
      bodyStyle={{ height: 'calc(100vh - 210px)', overflow: 'auto' }}
      className="right-actions"
      tabBarExtraContent={
        <Space>
          {editable && !params.id && (
            <Button
              type="primary"
              loading={drafting}
              onClick={() => {
                if (drafting) return
                formRef.current?.submit({
                  onVisibleChange: setDrafting,
                  isDraft: true,
                  success: () => nav(-1),
                })
              }}
            >
              保存草稿
            </Button>
          )}
          {editable && (
            <Button
              type="primary"
              loading={submiting}
              onClick={() => {
                if (submiting) return
                formRef.current?.submit({
                  onVisibleChange: setSubmiting,
                  isDraft: false,
                  success: () => nav(-1),
                })
              }}
            >
              确定
            </Button>
          )}
          <Button onClick={() => nav(-1)}>返回</Button>
        </Space>
      }
      {...React.useMemo(() => {
        const curModule = ref.current.modules.find(({ key }) => key === searchParams.get('moduleId'))
        const title = curModule?.label
        if (params?.id) {
          if (editable) {
            const tab =
              Array.isArray(curModule?.children) && curModule?.children.length > 0 ? (
                <Dropdown
                  overlay={
                    <Menu
                      items={curModule.children}
                      onClick={({ key }: any) => {
                        setCurrentCost(key)
                      }}
                    />
                  }
                >
                  <Space>
                    {`编辑${title}`}
                    <DownOutlined />
                  </Space>
                </Dropdown>
              ) : (
                `编辑${title}`
              )

            return {
              activeTabKey: location.state?.engineering ? '1' : '',
              tabList: [{ key: '1', tab }],
            }
          }
          return { activeTabKey: '', tabList: [{ key: '1', tab: title }] }
        } else {
          return { activeTabKey: '', tabList: [{ key: '1', tab: '新增' + title }] }
        }
      }, [params, ref.current.modules, searchParams, location.state])}
    >
      <div>{content}</div>
    </Card>
  )
}
