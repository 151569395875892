/** Generate by swagger-axios-codegen */
// @ts-nocheck
/* eslint-disable */

import { IRequestOptions, IRequestConfig, getConfigs, axios } from './serviceOptions';
export const basePath = '/api';

export interface IList<T> extends Array<T> {}
export interface List<T> extends Array<T> {}
export interface IDictionary<TValue> {
  [key: string]: TValue;
}
export interface Dictionary<TValue> extends IDictionary<TValue> {}

export interface IListResult<T> {
  items?: T[];
}

export class ListResultDto<T> implements IListResult<T> {
  items?: T[];
}

export interface IPagedResult<T> extends IListResult<T> {
  totalCount?: number;
  items?: T[];
}

export class PagedResultDto<T = any> implements IPagedResult<T> {
  totalCount?: number;
  items?: T[];
}

// customer definition
// empty

export class UserService {
  /**
   * 90天，强制修改密码接口
   */
  static postAuthUserUpdatePasswordByAccount(
    params: {
      /**  */
      root?: object;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/auth/user/updatePasswordByAccount';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['root'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 上传用户头像
   */
  static postAuthUserUploadHeadImg(
    params: {
      /** 图片文件 */
      file?: any;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/auth/user/uploadHeadImg';

      const configs: IRequestConfig = getConfigs('post', 'multipart/form-data', url, options);

      let data = null;
      data = new FormData();
      if (params['file']) {
        if (Object.prototype.toString.call(params['file']) === '[object Array]') {
          for (const item of params['file']) {
            data.append('file', item as any);
          }
        } else {
          data.append('file', params['file'] as any);
        }
      }

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 人机验证验证滑动
   */
  static postAuthCaptchaCheck(
    params: {
      /**  */
      root?: object;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/auth/captcha/check';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['root'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 修改密码
   */
  static postAuthUserUpdatePassword(
    params: {
      /**  */
      root?: object;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/auth/user/updatePassword';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['root'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 修改用户基本信息
   */
  static postAuthUserUpdate(
    params: {
      /**  */
      root?: object;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/auth/user/update';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['root'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 发送验证邮箱
   */
  static postAuthUserSendCheckEmail(
    params: {
      /**  */
      root?: object;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/auth/user/sendCheckEmail';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['root'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 更新手机号码
   */
  static postAuthUserUpdateTelephone(
    params: {
      /**  */
      root?: object;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/auth/user/updateTelephone';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['root'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 更新邮箱
   */
  static postAuthUserUpdateEmail(
    params: {
      /**  */
      root?: object;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/auth/user/updateEmail';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['root'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 更新邮箱 邮箱验证
   */
  static postAuthUserSendUpdateEmailCode(
    params: {
      /**  */
      root?: object;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/auth/user/sendUpdateEmailCode';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['root'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 查询用户信息
   */
  static getAuthUserInfo(options: IRequestOptions = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/auth/user/info';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 校验邮箱是否可用
   */
  static getAuthI3060CheckEmail(
    params: {
      /** 邮箱 */
      email?: string;
      /**  */
      root?: object;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/auth/i3060/checkEmail';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { email: params['email'] };
      let data = params['root'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 检查密码正确性
   */
  static postAuthUserCheckPwd(
    params: {
      /**  */
      root?: object;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/auth/user/checkPwd';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['root'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 检查手机号是否存在
   */
  static getAuthUserValidTelephoneWithmobile(
    params: {
      /** 手机号 */
      mobile: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/auth/user/validTelephone/{mobile}';
      url = url.replace('{mobile}', params['mobile'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 检查邮箱是否存在
   */
  static getAuthUserValidEmailWithemail(
    params: {
      /** 邮箱 */
      email: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/auth/user/validEmail/{email}';
      url = url.replace('{email}', params['email'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 注册
   */
  static postAuthI3060Register(
    params: {
      /**  */
      root?: object;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/auth/i3060/register';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['root'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 注册发送短信
   */
  static postAuthI3060SendRegisterCode(
    params: {
      /**  */
      root?: object;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/auth/i3060/sendRegisterCode';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['root'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 登录
   */
  static postAuthI3060Login(
    params: {
      /**  */
      root?: object;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/auth/i3060/login';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['root'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 登录发送短信
   */
  static postAuthI3060SendLoginCode(
    params: {
      /**  */
      root?: object;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/auth/i3060/sendLoginCode';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['root'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 短信验证码登录
   */
  static postAuthI3060LoginByCode(
    params: {
      /**  */
      root?: object;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/auth/i3060/loginByCode';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['root'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取人机验证图片
   */
  static postAuthCaptchaGet(
    params: {
      /**  */
      root?: object;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/auth/captcha/get';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['root'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取账户角色限制
   */
  static getAuthUserRoleInfo(options: IRequestOptions = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/auth/user/roleInfo';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 解绑手机号码发送短信
   */
  static postAuthUserSendUnBindPhoneCode(
    params: {
      /**  */
      root?: object;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/auth/user/sendUnBindPhoneCode';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['root'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 重置密码-发送手机号验证码
   */
  static getAuthResetPasswordSendMobileValid(
    params: {
      /** 手机号 */
      mobile?: string;
      /** 滑块验证码 */
      captchaVerification?: string;
      /**  */
      root?: object;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/auth/resetPassword/sendMobileValid';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { mobile: params['mobile'], captchaVerification: params['captchaVerification'] };
      let data = params['root'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 重置密码-发送邮箱验证码
   */
  static getAuthResetPasswordSendMailValid(
    params: {
      /** 邮箱 */
      mail?: string;
      /** 滑块验证码 */
      captchaVerification?: string;
      /**  */
      root?: object;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/auth/resetPassword/sendMailValid';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { mail: params['mail'], captchaVerification: params['captchaVerification'] };
      let data = params['root'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 重置密码-手机号验证码重置密码
   */
  static getAuthResetPasswordUpdatePwdByMobile(
    params: {
      /** 手机号 */
      mobile?: string;
      /** 验证码 */
      validCode?: string;
      /** 新密码 */
      newPassword?: string;
      /**  */
      root?: object;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/auth/resetPassword/updatePwdByMobile';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { mobile: params['mobile'], validCode: params['validCode'], newPassword: params['newPassword'] };
      let data = params['root'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 重置密码-邮箱验证码重置密码
   */
  static getAuthResetPasswordUpdatePwdByMail(
    params: {
      /** 邮箱 */
      mail?: string;
      /** 验证码 */
      validCode?: string;
      /** 新密码 */
      newPassword?: string;
      /**  */
      root?: object;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/auth/resetPassword/updatePwdByMail';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { mail: params['mail'], validCode: params['validCode'], newPassword: params['newPassword'] };
      let data = params['root'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 验证绑定手机号码发送短信
   */
  static postAuthUserSendBindTelephone(
    params: {
      /**  */
      root?: object;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/auth/user/sendBindTelephone';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['root'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 验证邮箱
   */
  static getAuthUserCheckEmail(
    params: {
      /** 邮箱验证码 */
      code?: string;
      /** 邮箱 */
      email?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/auth/user/checkEmail';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { code: params['code'], email: params['email'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class CommonService {
  /**
   * 发送删除或编辑参数库验证码
   */
  static getAuthCommonSendFinanceValidMsg(
    params: {
      /** 滑块验证码 */
      captchaVerification?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/auth/common/sendFinanceValidMsg';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { captchaVerification: params['captchaVerification'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 导入地区屋顶
   */
  static postI3060ResourceExport(
    params: {
      /** 文件列表 */
      files?: any;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/resource/export';

      const configs: IRequestConfig = getConfigs('post', 'multipart/form-data', url, options);

      let data = null;
      data = new FormData();
      if (params['files']) {
        if (Object.prototype.toString.call(params['files']) === '[object Array]') {
          for (const item of params['files']) {
            data.append('files', item as any);
          }
        } else {
          data.append('files', params['files'] as any);
        }
      }

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 新通过code查询城市边界（弃用）
   */
  static getRemovei3060RegionGetBoundary(
    params: {
      /** 城市code */
      code?: string;
      /**  */
      level?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/removei3060/region/getBoundary';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { code: params['code'], level: params['level'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 查询基础配置key
   */
  static getI3060OtherAuthShowKeys(options: IRequestOptions = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/other/auth/showKeys';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 查询省市区接口
   */
  static getI3060Region(options: IRequestOptions = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/region';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取用电场景分类
   */
  static getI3060IndustryModelLoadSceneList(options: IRequestOptions = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/industryModelLoad/sceneList';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 行业数据
   */
  static getI3060IndustryModelLoadIndustryCodeList(options: IRequestOptions = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/industryModelLoad/industryCodeList';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 通过code查询城市边界
   */
  static getI3060RegionGetBoundary(
    params: {
      /** 城市code */
      code?: string;
      /**  */
      level?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/region/getBoundary';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { code: params['code'], level: params['level'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class ProjectService {
  /**
   * 保存自定义电价
   */
  static postI3060BorderElectricitySaveCustomWithprojectId(
    params: {
      /** 项目id */
      projectId: string;
      /**  */
      root?: object;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/borderElectricity/saveCustom/{projectId}';
      url = url.replace('{projectId}', params['projectId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['root'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 删除自定义电价
   */
  static deleteI3060BorderElectricityDeleteCustomWithcustomId(
    params: {
      /** 自定义电价id */
      customId: string;
      /** 项目id */
      projectId?: string;
      /**  */
      root?: object;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/borderElectricity/deleteCustom/{customId}';
      url = url.replace('{customId}', params['customId'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);
      configs.params = { projectId: params['projectId'] };
      let data = params['root'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 天气数据-下载天气数据
   */
  static getI3060WeatherDownloadWithprojectId(
    params: {
      /** 项目id */
      projectId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/weather/download/{projectId}';
      url = url.replace('{projectId}', params['projectId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 天气数据-展示天气汇总表格
   */
  static getI3060WeatherShowWeatherTableWithprojectId(
    params: {
      /** 项目id */
      projectId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/weather/showWeatherTable/{projectId}';
      url = url.replace('{projectId}', params['projectId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 天气数据-获取全国天气数据
   */
  static getI3060WeatherGetWeatherData(
    params: {
      /** 天气下拉选项返回的value */
      weatherElement?: string;
      /** 时间 */
      timestamp?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/weather/getWeatherData';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { weatherElement: params['weatherElement'], timestamp: params['timestamp'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 天气数据-获取全国天气数据（聚合）
   */
  static getI3060WeatherGetTmyWeather(
    params: {
      /** 天气下拉选项返回的value */
      weatherElement?: string;
      /** 时间 2000-10 */
      freq?: string;
      /** M */
      time?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/weather/getTmyWeather';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { weatherElement: params['weatherElement'], freq: params['freq'], time: params['time'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 天气数据-获取天气验证报告pdf
   */
  static getI3060WeatherValidationReport(options: IRequestOptions = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/weather/validationReport';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 新获取地区经纬度（弃用）
   */
  static getRemovei3060MapInputTips(
    params: {
      /**  */
      cityCode?: string;
      /**  */
      address?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/removei3060/map/inputTips';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { cityCode: params['cityCode'], address: params['address'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 查询标杆燃煤电价
   */
  static getI3060BorderElectricityQueryNormFireCoalWithprojectId(
    params: {
      /** 项目id */
      projectId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/borderElectricity/queryNormFireCoal/{projectId}';
      url = url.replace('{projectId}', params['projectId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 查询自定义电价
   */
  static getI3060BorderElectricityQueryCustomWithprojectId(
    params: {
      /** 项目id */
      projectId: string;
      /** 自定义电价id(为空则查全部) */
      customId?: string;
      /**  */
      root?: object;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/borderElectricity/queryCustom/{projectId}';
      url = url.replace('{projectId}', params['projectId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { customId: params['customId'] };
      let data = params['root'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 查询自定义电价关联的技术方案名称
   */
  static getI3060BorderElectricityQueryCustomRefTechnicalNameWithcustomId(
    params: {
      /** 自定义电价id */
      customId: string;
      /**  */
      root?: object;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/borderElectricity/queryCustomRefTechnicalName/{customId}';
      url = url.replace('{customId}', params['customId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = params['root'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 查询自定义电价名称和id
   */
  static getI3060BorderElectricityQueryCustomNameIdListWithprojectId(
    params: {
      /** 项目id */
      projectId: string;
      /**  */
      root?: object;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/borderElectricity/queryCustomNameIdList/{projectId}';
      url = url.replace('{projectId}', params['projectId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = params['root'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 查询自定义电价模版数据
   */
  static getI3060BorderElectricityQueryTempCustom(
    params: {
      /** 0 目录电价；1标杆燃煤 */
      type?: string;
      /** 项目id */
      projectId?: string;
      /**  */
      root?: object;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/borderElectricity/queryTempCustom';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { type: params['type'], projectId: params['projectId'] };
      let data = params['root'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 计算边界-获取已经设置的配置项
   */
  static getI3060BorderSettingInfoListWithprojectId(
    params: {
      /** 项目id */
      projectId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/borderSetting/infoList/{projectId}';
      url = url.replace('{projectId}', params['projectId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 项目数量
   */
  static getI3060ProjectCount(options: IRequestOptions = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/project/count';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 计算边界-时间范围与精度-获取配置项
   */
  static getI3060BorderSettingCountBorderWithprojectId(
    params: {
      /** 项目id */
      projectId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/borderSetting/countBorder/{projectId}';
      url = url.replace('{projectId}', params['projectId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 计算边界-时间范围与精度-添加配置项
   */
  static postI3060BorderSettingCountBorderWithprojectId(
    params: {
      /** 项目id */
      projectId: string;
      /**  */
      root?: object;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/borderSetting/countBorder/{projectId}';
      url = url.replace('{projectId}', params['projectId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['root'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 计算边界-时间范围与精度-编辑配置项
   */
  static putI3060BorderSettingCountBorderWithprojectId(
    params: {
      /** 项目id */
      projectId: string;
      /**  */
      root?: object;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/borderSetting/countBorder/{projectId}';
      url = url.replace('{projectId}', params['projectId'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params['root'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取地区经纬度
   */
  static getI3060MapInputTips(
    params: {
      /**  */
      cityCode?: string;
      /**  */
      address?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/map/inputTips';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { cityCode: params['cityCode'], address: params['address'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 负荷曲线-典型负荷数据-获取项目负荷图表
   */
  static getI3060LoadChartWithprojectId(
    params: {
      /** 项目id */
      projectId: string;
      /**  */
      root?: object;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/load/chart/{projectId}';
      url = url.replace('{projectId}', params['projectId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = params['root'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 负荷曲线-典型负荷数据-获取地区标准曲线
   */
  static getI3060LoadIndustryStandardModelWithindustryCode(
    params: {
      /** 行业Code */
      industryCode: string;
      /**  */
      root?: object;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/load/industryStandardModel/{industryCode}';
      url = url.replace('{industryCode}', params['industryCode'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = params['root'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 负荷曲线-典型负荷数据-添加行业典型数据
   */
  static postI3060LoadStandardWithprojectId(
    params: {
      /** 项目id */
      projectId: string;
      /**  */
      root?: object;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/load/standard/{projectId}';
      url = url.replace('{projectId}', params['projectId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['root'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 负荷曲线-离线负荷数据-下载模版
   */
  static postI3060LoadTemplateWithprojectId(
    params: {
      /** 项目id */
      projectId: string;
      /**  */
      root?: object;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/load/template/{projectId}';
      url = url.replace('{projectId}', params['projectId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['root'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 负荷曲线-离线负荷数据-数据诊断与治理
   */
  static postI3060LoadUploadWithprojectId(
    params: {
      /** 项目id */
      projectId: string;
      /** 模版上传文件 */
      filename?: any;
      /** 开始时间 */
      startTime?: string;
      /** 结束时间 */
      endTime?: string;
      /** 颗粒度 */
      level?: string;
      /** 变压器星系 */
      transformer?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/load/upload/{projectId}';
      url = url.replace('{projectId}', params['projectId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'multipart/form-data', url, options);

      let data = null;
      data = new FormData();
      if (params['filename']) {
        if (Object.prototype.toString.call(params['filename']) === '[object Array]') {
          for (const item of params['filename']) {
            data.append('filename', item as any);
          }
        } else {
          data.append('filename', params['filename'] as any);
        }
      }

      if (params['startTime']) {
        if (Object.prototype.toString.call(params['startTime']) === '[object Array]') {
          for (const item of params['startTime']) {
            data.append('startTime', item as any);
          }
        } else {
          data.append('startTime', params['startTime'] as any);
        }
      }

      if (params['endTime']) {
        if (Object.prototype.toString.call(params['endTime']) === '[object Array]') {
          for (const item of params['endTime']) {
            data.append('endTime', item as any);
          }
        } else {
          data.append('endTime', params['endTime'] as any);
        }
      }

      if (params['level']) {
        if (Object.prototype.toString.call(params['level']) === '[object Array]') {
          for (const item of params['level']) {
            data.append('level', item as any);
          }
        } else {
          data.append('level', params['level'] as any);
        }
      }

      if (params['transformer']) {
        if (Object.prototype.toString.call(params['transformer']) === '[object Array]') {
          for (const item of params['transformer']) {
            data.append('transformer', item as any);
          }
        } else {
          data.append('transformer', params['transformer'] as any);
        }
      }

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 电价方案-目录电价-查询菜单选项
   */
  static getI3060BorderElectricityOptionWithprojectId(
    params: {
      /** 项目id */
      projectId: string;
      /** 用电类型：1 工商业；2 大工业 */
      userType?: string;
      /** 电压等级 */
      voltageLevel?: string;
      /** 电价库版本 */
      electricityPrice?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/borderElectricity/option/{projectId}';
      url = url.replace('{projectId}', params['projectId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        userType: params['userType'],
        voltageLevel: params['voltageLevel'],
        electricityPrice: params['electricityPrice']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 电价方案-目录电价-查询
   */
  static getI3060BorderElectricityCatalogWithprojectId(
    params: {
      /** 项目id */
      projectId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/borderElectricity/catalog/{projectId}';
      url = url.replace('{projectId}', params['projectId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 电价方案-目录电价-添加
   */
  static postI3060BorderElectricityCatalogWithprojectId(
    params: {
      /** 项目id */
      projectId: string;
      /**  */
      root?: object;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/borderElectricity/catalog/{projectId}';
      url = url.replace('{projectId}', params['projectId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['root'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 电价方案-目录电价-编辑
   */
  static patchI3060BorderElectricityCatalogWithprojectId(
    params: {
      /** 项目id */
      projectId: string;
      /**  */
      root?: object;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/borderElectricity/catalog/{projectId}';
      url = url.replace('{projectId}', params['projectId'] + '');

      const configs: IRequestConfig = getConfigs('patch', 'application/json', url, options);

      let data = params['root'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 电价方案-目录电价-查询图表
   */
  static getI3060BorderElectricityChartWithprojectId(
    params: {
      /** 项目id */
      projectId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/borderElectricity/chart/{projectId}';
      url = url.replace('{projectId}', params['projectId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 天气数据-获取天气下拉选项
   */
  static getI3060WeatherGetCaseList(options: IRequestOptions = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/weather/getCaseList';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 天气数据-获取天气数据
   */
  static getI3060WeatherGetShowWithprojectId(
    params: {
      /** 项目id */
      projectId: string;
      /** 天气下拉选项返回的value */
      weatherElement?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/weather/getShow/{projectId}';
      url = url.replace('{projectId}', params['projectId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { weatherElement: params['weatherElement'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 项目列表查询
   */
  static getI3060ProjectList(
    params: {
      /** 项目名 */
      name?: string;
      /** 技术方案数排序 0 不排序 1 asc 2 desc */
      tpnSort?: string;
      /** 财务方案数排序 0 不排序 1 asc 2 desc */
      fsnSort?: string;
      /** 创建时间排序 0 不排序 1 asc 2 desc */
      createTimeSort?: string;
      /** 更新排序 0 不排序 1 asc 2 desc */
      updateTimeSort?: string;
      /** 操作人 0 不排序 1 asc 2 desc */
      operateNameSort?: string;
      /** 默认1 */
      page?: string;
      /** 默认10 最大50 */
      size?: string;
      /** 创建者名字 */
      operateName?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/project/list';

      const configs: IRequestConfig = getConfigs('get', 'multipart/form-data', url, options);
      configs.params = {
        name: params['name'],
        tpnSort: params['tpnSort'],
        fsnSort: params['fsnSort'],
        createTimeSort: params['createTimeSort'],
        updateTimeSort: params['updateTimeSort'],
        operateNameSort: params['operateNameSort'],
        page: params['page'],
        size: params['size'],
        operateName: params['operateName']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 项目列表名称重命名
   */
  static patchI3060ProjectRenameWithprojectId(
    params: {
      /** 项目id */
      projectId: string;
      /**  */
      root?: object;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/project/rename/{projectId}';
      url = url.replace('{projectId}', params['projectId'] + '');

      const configs: IRequestConfig = getConfigs('patch', 'application/json', url, options);

      let data = params['root'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 项目列表新增项目
   */
  static postI3060ProjectAdd(
    params: {
      /**  */
      root?: object;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/project/add';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['root'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取项目详情
   */
  static getI3060ProjectGetWithprojectId(
    params: {
      /** 项目id */
      projectId: string;
      /**  */
      authorization?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/project/get/{projectId}';
      url = url.replace('{projectId}', params['projectId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Authorization: params['authorization'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 项目列表删除项目
   */
  static deleteI3060ProjectDeleteWithprojectId(
    params: {
      /** 项目id */
      projectId: string;
      /**  */
      authorization?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/project/delete/{projectId}';
      url = url.replace('{projectId}', params['projectId'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'multipart/form-data', url, options);
      configs.params = { Authorization: params['authorization'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 用户概览-卡片统计
   */
  static getI3060OverviewAssetCount(
    params: {
      /**  */
      authorization?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/overview/assetCount';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Authorization: params['authorization'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 用户概览-项目分布
   */
  static getI3060OverviewProjectDistribute(
    params: {
      /**  */
      authorization?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/overview/projectDistribute';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Authorization: params['authorization'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 用户概览-活动日志
   */
  static getI3060OperateGetActivityLog(options: IRequestOptions = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/operate/getActivityLog';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 用户概览-操作记录
   */
  static getI3060OperateGetRecentlyLogWithtype(
    params: {
      /** PROJECT=项目;TECHNICAL=技术;FINANCE=财务;RESOURCES=资源估算 */
      type: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/operate/getRecentlyLog/{type}';
      url = url.replace('{type}', params['type'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class TechnologyService {
  /**
   * 技术方案列表
   */
  static getI3060TechnicalListWithprojectId(
    params: {
      /** 项目id */
      projectId: string;
      /** 创建时间 0 不排序 1 asc 2 desc */
      createTimeSort?: string;
      /** 更新时间 0 不排序 1 asc 2 desc */
      updateTimeSort?: string;
      /** marked 星标 不传为全部  0:否 1:是 */
      marked?: string;
      /** locked 归档 不传为全部  0:否 1:是 */
      locked?: string;
      /** 装机容量区间 */
      installedCapacityMin?: string;
      /** 装机容量区间 */
      installedCapacityMax?: string;
      /** 日均发电区间 */
      dayAvgPowerGenerationMin?: string;
      /** 日均发电区间 */
      dayAvgPowerGenerationMax?: string;
      /** 年使用小时区间 */
      useHourYearMin?: string;
      /** 年使用小时区间 */
      useHourYearMax?: string;
      /** 自消纳百分比区间 */
      selfAbsorptionRateMin?: string;
      /** 自消纳百分比区间 */
      selfAbsorptionRateMax?: string;
      /** 电池容量区间 */
      batteryCapacityMin?: string;
      /** 电池容量区间 */
      batteryCapacityMax?: string;
      /** pcs功率区间 */
      pcsPowerMin?: string;
      /** pcs功率区间 */
      pcsPowerMax?: string;
      /** 日均放电区间 */
      dayAvgDischargeMin?: string;
      /** 日均放电区间 */
      dayAvgDischargeMax?: string;
      /** 日均充放电区间 */
      dayAvgChargeDischargeMin?: string;
      /** 日均充放电区间 */
      dayAvgChargeDischargeMax?: string;
      /** 默认1 */
      page?: string;
      /** 默认10 最大50 */
      size?: string;
      /** 装机容量 0 不排序 1 asc 2 desc */
      installedCapacitySort?: string;
      /** 日均发电 0 不排序 1 asc 2 desc */
      dayAvgPowerGenerationSort?: string;
      /** 年利用小时数 0 不排序 1 asc 2 desc */
      useHourYearSort?: string;
      /** 自消纳比 0 不排序 1 asc 2 desc */
      selfAbsorptionRateSort?: string;
      /** 电池容量 0 不排序 1 asc 2 desc */
      batteryCapacitySort?: string;
      /** psc总功率 0 不排序 1 asc 2 desc */
      pcsPowerSort?: string;
      /** 日均放电区间 0 不排序 1 asc 2 desc */
      dayAvgDischargeSort?: string;
      /** 日均充放 0 不排序 1 asc 2 desc */
      dayAvgChargeDischargeSort?: string;
      /** 星标 0 不排序 1 asc 2 desc */
      markedSort?: string;
      /** 归档 0 不排序 1 asc 2 desc */
      lockedSort?: string;
      /** 创建人 0 不排序 1 asc 2 desc */
      creatorNameSort?: string;
      /** 年发电小时数 排序0 不排序 1 asc 2 desc */
      annualGenerationHoursSort?: string;
      /** 年发电小时数 区间最小 */
      annualGenerationHoursMin?: string;
      /** 年发电小时数  区间最大 */
      annualGenerationHoursMax?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/technical/list/{projectId}';
      url = url.replace('{projectId}', params['projectId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'multipart/form-data', url, options);
      configs.params = {
        createTimeSort: params['createTimeSort'],
        updateTimeSort: params['updateTimeSort'],
        marked: params['marked'],
        locked: params['locked'],
        installedCapacityMin: params['installedCapacityMin'],
        installedCapacityMax: params['installedCapacityMax'],
        dayAvgPowerGenerationMin: params['dayAvgPowerGenerationMin'],
        dayAvgPowerGenerationMax: params['dayAvgPowerGenerationMax'],
        useHourYearMin: params['useHourYearMin'],
        useHourYearMax: params['useHourYearMax'],
        selfAbsorptionRateMin: params['selfAbsorptionRateMin'],
        selfAbsorptionRateMax: params['selfAbsorptionRateMax'],
        batteryCapacityMin: params['batteryCapacityMin'],
        batteryCapacityMax: params['batteryCapacityMax'],
        pcsPowerMin: params['pcsPowerMin'],
        pcsPowerMax: params['pcsPowerMax'],
        dayAvgDischargeMin: params['dayAvgDischargeMin'],
        dayAvgDischargeMax: params['dayAvgDischargeMax'],
        dayAvgChargeDischargeMin: params['dayAvgChargeDischargeMin'],
        dayAvgChargeDischargeMax: params['dayAvgChargeDischargeMax'],
        page: params['page'],
        size: params['size'],
        installedCapacitySort: params['installedCapacitySort'],
        dayAvgPowerGenerationSort: params['dayAvgPowerGenerationSort'],
        useHourYearSort: params['useHourYearSort'],
        selfAbsorptionRateSort: params['selfAbsorptionRateSort'],
        batteryCapacitySort: params['batteryCapacitySort'],
        pcsPowerSort: params['pcsPowerSort'],
        dayAvgDischargeSort: params['dayAvgDischargeSort'],
        dayAvgChargeDischargeSort: params['dayAvgChargeDischargeSort'],
        markedSort: params['markedSort'],
        lockedSort: params['lockedSort'],
        creatorNameSort: params['creatorNameSort'],
        annualGenerationHoursSort: params['annualGenerationHoursSort'],
        annualGenerationHoursMin: params['annualGenerationHoursMin'],
        annualGenerationHoursMax: params['annualGenerationHoursMax']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 技术方案重命名
   */
  static patchI3060TechnicalRenameWithtechnicalId(
    params: {
      /** 技术方案id */
      technicalId: string;
      /**  */
      root?: object;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/technical/rename/{technicalId}';
      url = url.replace('{technicalId}', params['technicalId'] + '');

      const configs: IRequestConfig = getConfigs('patch', 'application/json', url, options);

      let data = params['root'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 删除技术方案
   */
  static deleteI3060TechnicalWithtechnicalId(
    params: {
      /** 方案id */
      technicalId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/technical/{technicalId}';
      url = url.replace('{technicalId}', params['technicalId'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'multipart/form-data', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取技术方案详情
   */
  static getI3060TechnicalWithtechnicalId(
    params: {
      /**  */
      technicalId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/technical/{technicalId}';
      url = url.replace('{technicalId}', params['technicalId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 标记技术方案
   */
  static patchI3060TechnicalMarkWithtechnicalId(
    params: {
      /** 方案id */
      technicalId: string;
      /**  */
      root?: object;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/technical/mark/{technicalId}';
      url = url.replace('{technicalId}', params['technicalId'] + '');

      const configs: IRequestConfig = getConfigs('patch', 'application/json', url, options);

      let data = params['root'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 技术方案归档
   */
  static patchI3060TechnicalLockWithtechnicalId(
    params: {
      /** 方案id */
      technicalId: string;
      /**  */
      root?: object;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/technical/lock/{technicalId}';
      url = url.replace('{technicalId}', params['technicalId'] + '');

      const configs: IRequestConfig = getConfigs('patch', 'application/json', url, options);

      let data = params['root'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 技术方案复制
   */
  static postI3060TechnicalCopyWithtechnicalId(
    params: {
      /** 方案id */
      technicalId: string;
      /**  */
      root?: object;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/technical/copy/{technicalId}';
      url = url.replace('{technicalId}', params['technicalId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['root'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 跨项目技术方案列表
   */
  static getI3060TechnicalAll(
    params: {
      /** 创建时间 0 不排序 1 asc 2 desc */
      createTimeSort?: string;
      /** 更新时间 0 不排序 1 asc 2 desc */
      updateTimeSort?: string;
      /** marked 星标 不传为全部  0:否 1:是 */
      marked?: string;
      /** locked 归档 不传为全部  0:否 1:是 */
      locked?: string;
      /** 默认1 */
      page?: string;
      /** 技术方案名称 */
      name?: string;
      /** 项目名称 */
      projectName?: string;
      /** 创建人行嘛 */
      creatorUserName?: string;
      /** 0:未计算 1:计算中 2:计算完成 3: 计算失败 4:计算失效 多个用逗号隔开 */
      calcStatus?: string;
      /** 分页 */
      size?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/technical/all';

      const configs: IRequestConfig = getConfigs('get', 'multipart/form-data', url, options);
      configs.params = {
        createTimeSort: params['createTimeSort'],
        updateTimeSort: params['updateTimeSort'],
        marked: params['marked'],
        locked: params['locked'],
        page: params['page'],
        name: params['name'],
        projectName: params['projectName'],
        creatorUserName: params['creatorUserName'],
        calcStatus: params['calcStatus'],
        size: params['size']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 新建技术方案工作台
   */
  static postI3060TechnicalAddWithprojectId(
    params: {
      /** 项目id */
      projectId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/technical/add/{projectId}';
      url = url.replace('{projectId}', params['projectId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取储能默认参数
   */
  static getI3060TechnicalParamLibrary0GetDefault(options: IRequestOptions = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/technicalParamLibrary/0/getDefault';

      const configs: IRequestConfig = getConfigs('get', 'multipart/form-data', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取光伏默认参数
   */
  static getI3060TechnicalParamLibrary1GetDefault(options: IRequestOptions = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/technicalParamLibrary/1/getDefault';

      const configs: IRequestConfig = getConfigs('get', 'multipart/form-data', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 查询技术参数库的名称和id列表
   */
  static getI3060TechnicalParamLibraryGetNameIdList(options: IRequestOptions = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/technicalParamLibrary/getNameIdList';

      const configs: IRequestConfig = getConfigs('get', 'multipart/form-data', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取储能详情
   */
  static getI3060TechnicalParamLibrary0GetInfoWithlibraryId(
    params: {
      /** 参数库id */
      libraryId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/technicalParamLibrary/0/getInfo/{libraryId}';
      url = url.replace('{libraryId}', params['libraryId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'multipart/form-data', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取光伏详情
   */
  static getI3060TechnicalParamLibrary1GetInfoWithlibraryId(
    params: {
      /** 参数库id */
      libraryId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/technicalParamLibrary/1/getInfo/{libraryId}';
      url = url.replace('{libraryId}', params['libraryId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'multipart/form-data', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取储能列表
   */
  static getI3060TechnicalParamLibrary0GetList(options: IRequestOptions = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/technicalParamLibrary/0/getList';

      const configs: IRequestConfig = getConfigs('get', 'multipart/form-data', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取光伏列表
   */
  static getI3060TechnicalParamLibrary1GetList(options: IRequestOptions = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/technicalParamLibrary/1/getList';

      const configs: IRequestConfig = getConfigs('get', 'multipart/form-data', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 添加或修改储能参数库
   */
  static postI3060TechnicalParamLibrary0CreateOrEditBatch(
    params: {
      /** 短信验证码（不知道要不要） */
      code?: string;
      /**  */
      root?: any | null[];
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/technicalParamLibrary/0/createOrEditBatch';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = { code: params['code'] };
      let data = params['root'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 添加或修改光伏参数库
   */
  static postI3060TechnicalParamLibrary1CreateOrEditBatch(
    params: {
      /** 短信验证码（不知道要不要） */
      code?: string;
      /**  */
      root?: any | null[];
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/technicalParamLibrary/1/createOrEditBatch';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = { code: params['code'] };
      let data = params['root'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 设置储能默认
   */
  static putI3060TechnicalParamLibrary0DefaultWithlibraryId(
    params: {
      /** 参数库id */
      libraryId: string;
      /**  */
      root?: object;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/technicalParamLibrary/0/default/{libraryId}';
      url = url.replace('{libraryId}', params['libraryId'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params['root'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 设置光伏默认
   */
  static putI3060TechnicalParamLibrary1DefaultWithlibraryId(
    params: {
      /** 参数库id */
      libraryId: string;
      /**  */
      root?: object;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/technicalParamLibrary/1/default/{libraryId}';
      url = url.replace('{libraryId}', params['libraryId'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params['root'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class FinanceService {
  /**
   * 导出财务报告
   */
  static getI3060FinanceOutputReportWithfinanceId(
    params: {
      /** 财务方案 */
      financeId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/finance/outputReport/{financeId}';
      url = url.replace('{financeId}', params['financeId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 财务工作台-修改项目内参数库名字
   */
  static postI3060FinanceParamListRenameProjectLibrary(
    params: {
      /**  */
      root?: object;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/financeParamList/renameProjectLibrary';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['root'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 财务工作台-删除项目财务参数
   */
  static postI3060FinanceParamListDeleteProjectLibrary(
    params: {
      /**  */
      root?: object;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/financeParamList/deleteProjectLibrary';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['root'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 财务工作台-删除项目财务参数校验
   */
  static postI3060FinanceParamListCheckProjectLibrary(
    params: {
      /**  */
      root?: object;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/financeParamList/checkProjectLibrary';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['root'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 财务工作台-导入单个财务参数库参数
   */
  static postI3060FinanceParamListImportSingleLibrary(
    params: {
      /**  */
      root?: object;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/financeParamList/importSingleLibrary';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['root'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 财务工作台-导入财务参数库参数
   */
  static postI3060FinanceParamListImportLibrary(
    params: {
      /**  */
      root?: object;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/financeParamList/importLibrary';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['root'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 财务方案-参数库-编辑激励补贴
   */
  static postI3060FinanceAwardSubsidyEdit(
    params: {
      /**  */
      root?: object;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/financeAwardSubsidy/edit';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['root'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 财务方案-参数库-编辑财务成本
   */
  static postI3060FinanceCostEdit(
    params: {
      /**  */
      root?: object;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/financeCost/edit';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['root'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 财务方案-获取项目内参数库列表
   */
  static getI3060FinanceParamListGetProjectLibrary(
    params: {
      /** 项目id */
      projectId?: string;
      /**  */
      root?: object;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/financeParamList/getProjectLibrary';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { projectId: params['projectId'] };
      let data = params['root'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 财务管理列表
   */
  static getI3060FinanceListWithprojectId(
    params: {
      /** 项目id */
      projectId: string;
      /** 创建时间 0 不排序 1 asc 2 desc */
      createTimeSort?: string;
      /** 更新时间 0 不排序 1 asc 2 desc */
      updateTimeSort?: string;
      /** marked 星标 不传为全部  0:否 1:是 */
      marked?: string;
      /** locked 归档 不传为全部  0:否 1:是 */
      locked?: string;
      /** 资本内部收益率 */
      innerYieldRateMin?: string;
      /** 资本内部收益率 */
      innerYieldRateMax?: string;
      /** 净现值  单位；万 */
      netPresentValueMin?: string;
      /** 净现值  单位；万 */
      netPresentValueMax?: string;
      /** 总投资收益率 */
      returnInvestmentMin?: string;
      /** 总投资收益率 */
      returnInvestmentMax?: string;
      /** 静态投资回收期 年 */
      staticInvestmentPaybackPeriodMin?: string;
      /** 静态投资回收期 年 */
      staticInvestmentPaybackPeriodMax?: string;
      /** 初始投资  单位；万 */
      initialInvestmentMin?: string;
      /** 初始投资  单位；万 */
      initialInvestmentMax?: string;
      /** 总收益  单位；万 */
      totalRevenueMin?: string;
      /** 总收益  单位；万 */
      totalRevenueMax?: string;
      /** 累计现金流 单位；万 */
      cumulativeCashFlowMin?: string;
      /** 累计现金流 单位；万 */
      cumulativeCashFlowMax?: string;
      /** 资本金净利润率 */
      netProfitMarginOfCapitalMin?: string;
      /** 资本金净利润率 */
      netProfitMarginOfCapitalMax?: string;
      /**  */
      page?: string;
      /**  */
      size?: string;
      /** 资本内部收益率 */
      innerYieldRateSort?: string;
      /** 净现值  */
      netPresentValueSort?: string;
      /** 资本金净利润率 */
      returnInvestmentSort?: string;
      /** 总投资收益率 */
      staticInvestmentPaybackPeriodSort?: string;
      /** 静态投资回收期 */
      initialInvestmentSort?: string;
      /** 初始投资 */
      totalRevenueSort?: string;
      /** 总收益 */
      cumulativeCashFlowSort?: string;
      /** 累计现金流 */
      netProfitMarginOfCapitalSort?: string;
      /** 星标 0 不排序 1 asc 2 desc */
      markedSort?: string;
      /** 归档 0 不排序 1 asc 2 desc */
      lockedSort?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/finance/list/{projectId}';
      url = url.replace('{projectId}', params['projectId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'multipart/form-data', url, options);
      configs.params = {
        createTimeSort: params['createTimeSort'],
        updateTimeSort: params['updateTimeSort'],
        marked: params['marked'],
        locked: params['locked'],
        innerYieldRateMin: params['innerYieldRateMin'],
        innerYieldRateMax: params['innerYieldRateMax'],
        netPresentValueMin: params['netPresentValueMin'],
        netPresentValueMax: params['netPresentValueMax'],
        returnInvestmentMin: params['returnInvestmentMin'],
        returnInvestmentMax: params['returnInvestmentMax'],
        staticInvestmentPaybackPeriodMin: params['staticInvestmentPaybackPeriodMin'],
        staticInvestmentPaybackPeriodMax: params['staticInvestmentPaybackPeriodMax'],
        initialInvestmentMin: params['initialInvestmentMin'],
        initialInvestmentMax: params['initialInvestmentMax'],
        totalRevenueMin: params['totalRevenueMin'],
        totalRevenueMax: params['totalRevenueMax'],
        cumulativeCashFlowMin: params['cumulativeCashFlowMin'],
        cumulativeCashFlowMax: params['cumulativeCashFlowMax'],
        netProfitMarginOfCapitalMin: params['netProfitMarginOfCapitalMin'],
        netProfitMarginOfCapitalMax: params['netProfitMarginOfCapitalMax'],
        page: params['page'],
        size: params['size'],
        innerYieldRateSort: params['innerYieldRateSort'],
        netPresentValueSort: params['netPresentValueSort'],
        returnInvestmentSort: params['returnInvestmentSort'],
        staticInvestmentPaybackPeriodSort: params['staticInvestmentPaybackPeriodSort'],
        initialInvestmentSort: params['initialInvestmentSort'],
        totalRevenueSort: params['totalRevenueSort'],
        cumulativeCashFlowSort: params['cumulativeCashFlowSort'],
        netProfitMarginOfCapitalSort: params['netProfitMarginOfCapitalSort'],
        markedSort: params['markedSort'],
        lockedSort: params['lockedSort']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 财务方案-参数库-运营成本-获取当前用户下所有
   */
  static getI3060FinanceOperationCostList(
    params: {
      /**  */
      root?: object;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/financeOperationCost/list';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = params['root'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 财务方案-参数库-财务成本-获取当前用户下所有
   */
  static getI3060FinanceCostList(
    params: {
      /**  */
      root?: object;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/financeCost/list';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = params['root'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 财务方案-参数库-项目投资-获取当前用户下所有
   */
  static getI3060FinanceProjectInvestList(
    params: {
      /**  */
      root?: object;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/financeProjectInvest/list';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = params['root'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 财务方案-参数库-获取项目投资详情
   */
  static getI3060FinanceProjectInvestInfoWithprojectInvestId(
    params: {
      /** 项目投资id */
      projectInvestId: string;
      /**  */
      root?: object;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/financeProjectInvest/info/{projectInvestId}';
      url = url.replace('{projectInvestId}', params['projectInvestId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = params['root'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 财务方案-参数库-获取运营成本详情
   */
  static getI3060FinanceOperationCostInfoWithoperationCostId(
    params: {
      /** 运营成本id */
      operationCostId: string;
      /**  */
      root?: object;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/financeOperationCost/info/{operationCostId}';
      url = url.replace('{operationCostId}', params['operationCostId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = params['root'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 财务方案-参数库-获取财务成本详情
   */
  static getI3060FinanceCostInfoWithfinanceCostId(
    params: {
      /** 财务成本id */
      financeCostId: string;
      /**  */
      root?: object;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/financeCost/info/{financeCostId}';
      url = url.replace('{financeCostId}', params['financeCostId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = params['root'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 财务方案-参数库-获取激励补贴详情
   */
  static getI3060FinanceAwardSubsidyInfoWithawardSubsidyId(
    params: {
      /** 激励补贴id */
      awardSubsidyId: string;
      /**  */
      root?: object;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/financeAwardSubsidy/info/{awardSubsidyId}';
      url = url.replace('{awardSubsidyId}', params['awardSubsidyId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = params['root'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 财务方案-参数库-激励补贴-获取补贴激励详情
   */
  static getI3060FinanceAwardSubsidyDetailInfoWithawardSubsidyDetailId(
    params: {
      /** 补贴激励id */
      awardSubsidyDetailId: string;
      /**  */
      root?: object;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/financeAwardSubsidyDetail/info/{awardSubsidyDetailId}';
      url = url.replace('{awardSubsidyDetailId}', params['awardSubsidyDetailId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = params['root'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 财务方案-参数库-激励补贴-编辑补贴激励
   */
  static putI3060FinanceAwardSubsidyDetailWithawardSubsidyDetailId(
    params: {
      /** 补贴激励id */
      awardSubsidyDetailId: string;
      /**  */
      root?: object;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/financeAwardSubsidyDetail/{awardSubsidyDetailId}';
      url = url.replace('{awardSubsidyDetailId}', params['awardSubsidyDetailId'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params['root'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 财务方案-参数库-激励补贴-删除补贴激励
   */
  static deleteI3060FinanceAwardSubsidyDetailWithawardSubsidyDetailId(
    params: {
      /** 补贴激励id */
      awardSubsidyDetailId: string;
      /**  */
      root?: object;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/financeAwardSubsidyDetail/{awardSubsidyDetailId}';
      url = url.replace('{awardSubsidyDetailId}', params['awardSubsidyDetailId'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = params['root'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 财务方案-编辑参数组名称
   */
  static putI3060FinanceParamListEditNameWithparamListId(
    params: {
      /** 参数组列表id */
      paramListId: string;
      /**  */
      root?: object;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/financeParamList/editName/{paramListId}';
      url = url.replace('{paramListId}', params['paramListId'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params['root'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 财务方案-获取参数库列表含有默认值
   */
  static getI3060FinanceParamListGetLibraryHasDefault(
    params: {
      /**  */
      root?: object;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/financeParamList/getLibraryHasDefault';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = params['root'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 财务方案-参数库-添加或编辑激励补贴
   */
  static postI3060FinanceAwardSubsidyCreateOrUpdateBatch(
    params: {
      /**  */
      root?: any | null[];
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/financeAwardSubsidy/createOrUpdateBatch';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['root'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 查询警告弹窗
   */
  static getI3060FinanceLibraryWarnToast(
    params: {
      /** project_invest_id项目投资id；operation_cost_id运营成本id；finance_cost_id财务成本id；award_subsidy_id激励补贴id； */
      type?: string;
      /** id集合 */
      ids?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/financeLibrary/warnToast';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { type: params['type'], ids: params['ids'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 财务方案-参数库-激励补贴-设置为默认
   */
  static putI3060FinanceAwardSubsidyDefaultWithawardSubsidyId(
    params: {
      /** 激励补贴id */
      awardSubsidyId: string;
      /**  */
      root?: object;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/financeAwardSubsidy/default/{awardSubsidyId}';
      url = url.replace('{awardSubsidyId}', params['awardSubsidyId'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params['root'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 财务方案-参数库-财务成本-设置为默认
   */
  static putI3060FinanceCostDefaultWithfinanceCostId(
    params: {
      /** 财务成本id */
      financeCostId: string;
      /**  */
      root?: object;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/financeCost/default/{financeCostId}';
      url = url.replace('{financeCostId}', params['financeCostId'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params['root'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 财务方案-参数库-运营成本-设置为默认
   */
  static putI3060FinanceOperationCostDefaultWithoperationCostId(
    params: {
      /** 运营成本id */
      operationCostId: string;
      /**  */
      root?: object;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/financeOperationCost/default/{operationCostId}';
      url = url.replace('{operationCostId}', params['operationCostId'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params['root'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 财务方案-参数库-项目投资-设置为默认
   */
  static putI3060FinanceProjectInvestDefaultWithprojectInvestId(
    params: {
      /** 项目投资id */
      projectInvestId: string;
      /**  */
      root?: object;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/financeProjectInvest/default/{projectInvestId}';
      url = url.replace('{projectInvestId}', params['projectInvestId'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params['root'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 财务方案word文档-文档图片上传(批量)
   */
  static postI3060FinanceWordUploadFileListWithfinanceId(
    params: {
      /** 财务方案id */
      financeId: string;
      /**  */
      root?: any | null[];
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/financeWord/uploadFileList/{financeId}';
      url = url.replace('{financeId}', params['financeId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['root'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 生成归档财务报告
   */
  static getI3060WordFillWordWithfinanceId(
    params: {
      /**  */
      financeId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/word/fillWord/{financeId}';
      url = url.replace('{financeId}', params['financeId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取报告逐年发电量图表
   */
  static getI3060WordGetYearYearPower(
    params: {
      /** 财务方案id */
      financeId?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/word/getYearYearPower';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { financeId: params['financeId'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 财务报告典型日发电曲线
   */
  static getI3060WordGetActivePowerChart(
    params: {
      /** 财务id */
      financeId?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/word/getActivePowerChart';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { financeId: params['financeId'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 项目报告-列表
   */
  static getI3060FinanceWordListWithprojectId(
    params: {
      /** 项目id */
      projectId: string;
      /** 文档名称 */
      wordName?: string;
      /** 技术方案名称 */
      technicalName?: string;
      /** 财务方案名称 */
      financeName?: string;
      /** 生成状态：0未生成；1生成中；2已生成；3生成失败 多个用","隔开 */
      status?: string;
      /** 0 不排序 1 asc 2 desc */
      createTimeSort?: string;
      /** 更新时间 0 不排序 1 asc 2 desc */
      updateTimeSort?: string;
      /** 生成时间 0 不排序 1 asc 2 desc */
      generateTimeSort?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/financeWord/list/{projectId}';
      url = url.replace('{projectId}', params['projectId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        wordName: params['wordName'],
        technicalName: params['technicalName'],
        financeName: params['financeName'],
        status: params['status'],
        createTimeSort: params['createTimeSort'],
        updateTimeSort: params['updateTimeSort'],
        generateTimeSort: params['generateTimeSort']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 项目报告-更名
   */
  static patchI3060FinanceWordRenameWithwordId(
    params: {
      /** 项目文档id */
      wordId: string;
      /**  */
      root?: object;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/financeWord/rename/{wordId}';
      url = url.replace('{wordId}', params['wordId'] + '');

      const configs: IRequestConfig = getConfigs('patch', 'application/json', url, options);

      let data = params['root'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 项目报告-获取文件内容
   */
  static getI3060FinanceWordReadWordWithwordId(
    params: {
      /**  */
      wordId: string;
      /**  */
      root?: object;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/financeWord/readWord/{wordId}';
      url = url.replace('{wordId}', params['wordId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = params['root'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 财务方案word文档-文档图片上传(单个)
   */
  static postI3060FinanceWordUploadFileWithfinanceId(
    params: {
      /** 财务方案id */
      financeId: string;
      /**  */
      root?: object;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/financeWord/uploadFile/{financeId}';
      url = url.replace('{financeId}', params['financeId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['root'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 项目报告-获取文件内容pdf
   */
  static getI3060FinanceWordReadPdfWithtag(
    params: {
      /**  */
      tag: string;
      /**  */
      root?: object;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/financeWord/readPdf/{tag}';
      url = url.replace('{tag}', params['tag'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = params['root'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 项目报告-获取报告pdf文件tag
   */
  static getI3060FinanceWordGetPdfTagWithwordId(
    params: {
      /**  */
      wordId: string;
      /**  */
      root?: object;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/financeWord/getPdfTag/{wordId}';
      url = url.replace('{wordId}', params['wordId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = params['root'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 财务方案-参数库-编辑项目投资
   */
  static postI3060FinanceProjectInvestEdit(
    params: {
      /**  */
      root?: object;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/financeProjectInvest/edit';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['root'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 财务方案-参数库-编辑运营成本
   */
  static postI3060FinanceOperationCostEdit(
    params: {
      /**  */
      root?: object;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/financeOperationCost/edit';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['root'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 财务方案-参数组配置-财务成本-获取默认值
   */
  static getI3060FinanceCostGetDefault(options: IRequestOptions = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/financeCost/getDefault';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 跨项目财务管理列表
   */
  static getI3060FinanceAll(
    params: {
      /** 创建时间 0 不排序 1 asc 2 desc */
      createTimeSort?: string;
      /** 更新时间 0 不排序 1 asc 2 desc */
      updateTimeSort?: string;
      /** marked 星标 不传为全部  0:否 1:是 */
      marked?: string;
      /** locked 归档 不传为全部  0:否 1:是 */
      locked?: string;
      /**  */
      size?: string;
      /**  */
      page?: string;
      /** 创建人姓名 */
      creatorUserName?: string;
      /** 财务方案名称 */
      name?: string;
      /** 项目名称 */
      projectName?: string;
      /** 0:未计算 1:计算中 2:计算完成 3: 计算失败 4:计算失效 多个用逗号隔开 */
      calcStatus?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/finance/all';

      const configs: IRequestConfig = getConfigs('get', 'multipart/form-data', url, options);
      configs.params = {
        createTimeSort: params['createTimeSort'],
        updateTimeSort: params['updateTimeSort'],
        marked: params['marked'],
        locked: params['locked'],
        size: params['size'],
        page: params['page'],
        creatorUserName: params['creatorUserName'],
        name: params['name'],
        projectName: params['projectName'],
        calcStatus: params['calcStatus']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 修改财务管理方案名称
   */
  static patchI3060FinanceRenameWithfinanceId(
    params: {
      /** 方案名称 */
      financeId: string;
      /**  */
      root?: object;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/finance/rename/{financeId}';
      url = url.replace('{financeId}', params['financeId'] + '');

      const configs: IRequestConfig = getConfigs('patch', 'application/json', url, options);

      let data = params['root'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 删除财务方案
   */
  static deleteI3060FinanceWithfinanceId(
    params: {
      /** 方案id */
      financeId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/finance/{financeId}';
      url = url.replace('{financeId}', params['financeId'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'multipart/form-data', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 财务方案标记
   */
  static patchI3060FinanceMarkWithfinanceId(
    params: {
      /** 方案id */
      financeId: string;
      /**  */
      root?: object;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/finance/mark/{financeId}';
      url = url.replace('{financeId}', params['financeId'] + '');

      const configs: IRequestConfig = getConfigs('patch', 'application/json', url, options);

      let data = params['root'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 财务方案归档
   */
  static patchI3060FinanceLockWithfinanceId(
    params: {
      /** 方案id */
      financeId: string;
      /**  */
      root?: object;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/finance/lock/{financeId}';
      url = url.replace('{financeId}', params['financeId'] + '');

      const configs: IRequestConfig = getConfigs('patch', 'application/json', url, options);

      let data = params['root'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 财务方案-方案列表-获取透视分析查询类型
   */
  static getI3060FinanceGetPerspectiveAnalyseQueryType(options: IRequestOptions = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/finance/getPerspectiveAnalyseQueryType';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 财务方案-方案列表-透视分析
   */
  static getI3060FinancePerspectiveAnalyseWithprojectId(
    params: {
      /** 项目id */
      projectId: string;
      /** 选择查看指标 */
      queryType?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/finance/perspectiveAnalyse/{projectId}';
      url = url.replace('{projectId}', params['projectId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { queryType: params['queryType'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 财务方案-方案列表-计算
   */
  static postI3060FinanceCalculateWithprojectId(
    params: {
      /** 项目id */
      projectId: string;
      /**  */
      root?: object;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/finance/calculate/{projectId}';
      url = url.replace('{projectId}', params['projectId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['root'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 财务方案-方案列表-批量计算
   */
  static postI3060FinanceBatchCalculateWithfinanceId(
    params: {
      /**  */
      financeId: string;
      /**  */
      root?: object;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/finance/batchCalculate/{financeId}';
      url = url.replace('{financeId}', params['financeId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['root'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 财务方案-规划运行方案选择-关联技术方案
   */
  static postI3060FinanceSchemePlanRefWithprojectId(
    params: {
      /** 项目id */
      projectId: string;
      /**  */
      root?: object;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/financeSchemePlan/ref/{projectId}';
      url = url.replace('{projectId}', params['projectId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['root'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 财务方案-选择规划运行方案-查询关联的技术方案
   */
  static getI3060FinanceSchemePlanQueryRefWithprojectId(
    params: {
      /** 项目id */
      projectId: string;
      /** 技术方案id列表 */
      root?: any | null[];
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/financeSchemePlan/queryRef/{projectId}';
      url = url.replace('{projectId}', params['projectId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = params['root'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 财务方案-选择规划运行方案-查询方案列表
   */
  static getI3060FinanceSchemePlanListWithprojectId(
    params: {
      /** 项目id */
      projectId: string;
      /** 创建时间 0 不排序 1 asc 2 desc */
      createTimeSort?: string;
      /** 更新时间 0 不排序 1 asc 2 desc */
      updateTimeSort?: string;
      /** 装机容量区间-最小 */
      installedCapacityMin?: string;
      /** 装机容量区间-最大 */
      installedCapacityMax?: string;
      /** 日均发电区间 */
      dayAvgPowerGenerationMin?: string;
      /** 日均发电区间 */
      dayAvgPowerGenerationMax?: string;
      /** 年使用小时区间 */
      useHourYearMin?: string;
      /** 年使用小时区间 */
      useHourYearMax?: string;
      /** 自消纳百分比区间 */
      selfAbsorptionRateMin?: string;
      /** 自消纳百分比区间 */
      selfAbsorptionRateMax?: string;
      /** 电池容量区间 */
      batteryCapacityMin?: string;
      /** 电池容量区间 */
      batteryCapacityMax?: string;
      /** 日均放电区间 */
      dayAvgDischargeMin?: string;
      /** 日均放电区间 */
      dayAvgDischargeMax?: string;
      /** 日均充放电区间 */
      dayAvgChargeDischargeMin?: string;
      /** 日均充放电区间 */
      dayAvgChargeDischargeMax?: string;
      /** 装机容量 0 不排序 1 asc 2 desc */
      installedCapacitySort?: string;
      /** 日均发电 0 不排序 1 asc 2 desc */
      dayAvgPowerGenerationSort?: string;
      /** 年利用小时数 0 不排序 1 asc 2 desc */
      useHourYearSort?: string;
      /** 自消纳比 0 不排序 1 asc 2 desc */
      selfAbsorptionRateSort?: string;
      /** 电池容量 0 不排序 1 asc 2 desc */
      batteryCapacitySort?: string;
      /** psc总功率 0 不排序 1 asc 2 desc */
      pcsPowerSort?: string;
      /** 日均放电区间 0 不排序 1 asc 2 desc */
      dayAvgDischargeSort?: string;
      /** 日均充放 0 不排序 1 asc 2 desc */
      dayAvgChargeDischargeSort?: string;
      /** pcs功率区间 */
      pcsPowerMin?: string;
      /** pcs功率区间 */
      pcsPowerMax?: string;
      /** 年发电小时数 0 不排序 1 asc 2 desc */
      annualGenerationHoursSort?: string;
      /** 年使用小时区间 */
      annualGenerationHoursMin?: string;
      /** 年使用小时区间 */
      annualGenerationHoursMax?: string;
      /** 技术方案id列表 */
      root?: any | null[];
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/financeSchemePlan/list/{projectId}';
      url = url.replace('{projectId}', params['projectId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        createTimeSort: params['createTimeSort'],
        updateTimeSort: params['updateTimeSort'],
        installedCapacityMin: params['installedCapacityMin'],
        installedCapacityMax: params['installedCapacityMax'],
        dayAvgPowerGenerationMin: params['dayAvgPowerGenerationMin'],
        dayAvgPowerGenerationMax: params['dayAvgPowerGenerationMax'],
        useHourYearMin: params['useHourYearMin'],
        useHourYearMax: params['useHourYearMax'],
        selfAbsorptionRateMin: params['selfAbsorptionRateMin'],
        selfAbsorptionRateMax: params['selfAbsorptionRateMax'],
        batteryCapacityMin: params['batteryCapacityMin'],
        batteryCapacityMax: params['batteryCapacityMax'],
        dayAvgDischargeMin: params['dayAvgDischargeMin'],
        dayAvgDischargeMax: params['dayAvgDischargeMax'],
        dayAvgChargeDischargeMin: params['dayAvgChargeDischargeMin'],
        dayAvgChargeDischargeMax: params['dayAvgChargeDischargeMax'],
        installedCapacitySort: params['installedCapacitySort'],
        dayAvgPowerGenerationSort: params['dayAvgPowerGenerationSort'],
        useHourYearSort: params['useHourYearSort'],
        selfAbsorptionRateSort: params['selfAbsorptionRateSort'],
        batteryCapacitySort: params['batteryCapacitySort'],
        pcsPowerSort: params['pcsPowerSort'],
        dayAvgDischargeSort: params['dayAvgDischargeSort'],
        dayAvgChargeDischargeSort: params['dayAvgChargeDischargeSort'],
        pcsPowerMin: params['pcsPowerMin'],
        pcsPowerMax: params['pcsPowerMax'],
        annualGenerationHoursSort: params['annualGenerationHoursSort'],
        annualGenerationHoursMin: params['annualGenerationHoursMin'],
        annualGenerationHoursMax: params['annualGenerationHoursMax']
      };
      let data = params['root'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 财务方案-获取参数库列表
   */
  static getI3060FinanceParamListGetLibrary(
    params: {
      /**  */
      root?: object;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/financeParamList/getLibrary';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = params['root'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 财务方案-创建参数组配置
   */
  static postI3060FinanceParamListWithprojectId(
    params: {
      /** 项目id */
      projectId: string;
      /**  */
      root?: object;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/financeParamList/{projectId}';
      url = url.replace('{projectId}', params['projectId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['root'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 财务方案-删除参数组配置
   */
  static deleteI3060FinanceParamListWithparamListId(
    params: {
      /** 参数组列表id */
      paramListId: string;
      /**  */
      root?: object;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/financeParamList/{paramListId}';
      url = url.replace('{paramListId}', params['paramListId'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = params['root'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 财务方案-编辑参数组列表
   */
  static putI3060FinanceParamListWithparamListId(
    params: {
      /** 参数组列表id */
      paramListId: string;
      /**  */
      root?: object;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/financeParamList/{paramListId}';
      url = url.replace('{paramListId}', params['paramListId'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params['root'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 财务方案-获取参数组列表
   */
  static getI3060FinanceParamListListWithprojectId(
    params: {
      /** 项目id */
      projectId: string;
      /**  */
      root?: object;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/financeParamList/list/{projectId}';
      url = url.replace('{projectId}', params['projectId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = params['root'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 财务方案-参数组配置-运营成本-获取默认值
   */
  static getI3060FinanceOperationCostGetDefault(options: IRequestOptions = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/financeOperationCost/getDefault';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 财务方案-参数库-项目投资-获取默认值
   */
  static getI3060FinanceProjectInvestGetDefault(options: IRequestOptions = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/financeProjectInvest/getDefault';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 财务方案-参数库-创建项目投资
   */
  static postI3060FinanceProjectInvest(
    params: {
      /**  */
      root?: object;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/financeProjectInvest';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['root'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 财务方案-参数库-删除项目投资
   */
  static deleteI3060FinanceProjectInvestWithprojectInvestId(
    params: {
      /**  */
      projectInvestId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/financeProjectInvest/{projectInvestId}';
      url = url.replace('{projectInvestId}', params['projectInvestId'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'multipart/form-data', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 财务方案-参数库-拷贝项目投资
   */
  static postI3060FinanceProjectInvestCopyWithprojectInvestId(
    params: {
      /** 项目投资id */
      projectInvestId: string;
      /**  */
      root?: object;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/financeProjectInvest/copy/{projectInvestId}';
      url = url.replace('{projectInvestId}', params['projectInvestId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['root'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 财务方案-参数库-批量保存或编辑项目投资
   */
  static postI3060FinanceProjectInvestCreateOrEditBatch(
    params: {
      /**  */
      root?: any | null[];
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/financeProjectInvest/createOrEditBatch';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['root'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 财务方案-参数库-创建运营成本
   */
  static postI3060FinanceOperationCost(
    params: {
      /**  */
      root?: object;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/financeOperationCost';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['root'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 财务方案-参数库-删除运营成本
   */
  static deleteI3060FinanceOperationCostWithoperationCostId(
    params: {
      /** 运营成本id */
      operationCostId: string;
      /**  */
      root?: object;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/financeOperationCost/{operationCostId}';
      url = url.replace('{operationCostId}', params['operationCostId'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = params['root'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 财务方案-参数库-拷贝运营成本
   */
  static postI3060FinanceOperationCostCopyWithoperationCostId(
    params: {
      /** 运营成本id */
      operationCostId: string;
      /**  */
      root?: object;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/financeOperationCost/copy/{operationCostId}';
      url = url.replace('{operationCostId}', params['operationCostId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['root'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 财务方案-参数库-批量保存或编辑运营成本
   */
  static postI3060FinanceOperationCostCreateOrEditBatch(
    params: {
      /**  */
      root?: any | null[];
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/financeOperationCost/createOrEditBatch';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['root'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 财务方案-参数库-创建财务成本
   */
  static postI3060FinanceCost(
    params: {
      /**  */
      root?: object;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/financeCost';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['root'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 财务方案-参数库-删除财务成本
   */
  static deleteI3060FinanceCostWithfinanceCostId(
    params: {
      /** 财务成本id */
      financeCostId: string;
      /**  */
      root?: object;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/financeCost/{financeCostId}';
      url = url.replace('{financeCostId}', params['financeCostId'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = params['root'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 财务方案-参数库-拷贝财务成本
   */
  static postI3060FinanceCostCopyWithfinanceCostId(
    params: {
      /** 财务成本id */
      financeCostId: string;
      /**  */
      root?: object;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/financeCost/copy/{financeCostId}';
      url = url.replace('{financeCostId}', params['financeCostId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['root'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 财务方案-参数库-批量保存或编辑财务成本
   */
  static postI3060FinanceCostCreateOrEditBatch(
    params: {
      /**  */
      root?: any | null[];
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/financeCost/createOrEditBatch';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['root'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 财务方案-参数库-创建激励补贴
   */
  static postI3060FinanceAwardSubsidy(
    params: {
      /**  */
      root?: object;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/financeAwardSubsidy';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['root'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 财务方案-参数库-删除激励补贴
   */
  static deleteI3060FinanceAwardSubsidyWithawardSubsidyId(
    params: {
      /** 激励补贴id */
      awardSubsidyId: string;
      /**  */
      root?: object;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/financeAwardSubsidy/{awardSubsidyId}';
      url = url.replace('{awardSubsidyId}', params['awardSubsidyId'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = params['root'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 财务方案-参数库-编辑激励补贴
   */
  static putI3060FinanceAwardSubsidyWithawardSubsidyId(
    params: {
      /** 激励补贴id */
      awardSubsidyId: string;
      /**  */
      root?: object;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/financeAwardSubsidy/{awardSubsidyId}';
      url = url.replace('{awardSubsidyId}', params['awardSubsidyId'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params['root'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 财务方案-参数库-拷贝激励补贴
   */
  static postI3060FinanceAwardSubsidyCopyWithawardSubsidyId(
    params: {
      /** 激励补贴id */
      awardSubsidyId: string;
      /**  */
      root?: object;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/financeAwardSubsidy/copy/{awardSubsidyId}';
      url = url.replace('{awardSubsidyId}', params['awardSubsidyId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['root'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 财务方案-参数库-激励补贴-获取补贴激励名称
   */
  static getI3060FinanceAwardSubsidyDetailDetailNameWithawardSubsidyId(
    params: {
      /** 激励补贴id */
      awardSubsidyId: string;
      /**  */
      root?: object;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/financeAwardSubsidyDetail/detailName/{awardSubsidyId}';
      url = url.replace('{awardSubsidyId}', params['awardSubsidyId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = params['root'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 财务方案-参数库-激励补贴-创建补贴激励
   */
  static postI3060FinanceAwardSubsidyDetailWithawardSubsidyId(
    params: {
      /** 激励补贴id */
      awardSubsidyId: string;
      /**  */
      root?: object;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/financeAwardSubsidyDetail/{awardSubsidyId}';
      url = url.replace('{awardSubsidyId}', params['awardSubsidyId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['root'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 财务方案-参数库-激励补贴-查询补贴激励列表
   */
  static getI3060FinanceAwardSubsidyDetailGetDetailList(
    params: {
      /**  */
      root?: object;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/financeAwardSubsidyDetail/getDetailList';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = params['root'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class CdataService {
  /**
   * 新获取用电场景类型
   */
  static getI3060SceneModelLoadSceneList(options: IRequestOptions = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/sceneModelLoad/sceneList';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 新行业数据
   */
  static getI3060IndustryCodeIndustryCodeList(options: IRequestOptions = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/industryCode/industryCodeList';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class ProjectWorkbenchService {
  /**
   * 修改储能容量
   */
  static postI3060TechnicalWorkbenchUpdateEnergyStorageCapacityWithtechnologyId(
    params: {
      /**  */
      technologyId: string;
      /**  */
      root?: object;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/technical/workbench/updateEnergyStorageCapacity/{technologyId}';
      url = url.replace('{technologyId}', params['technologyId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['root'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 修改储能拓扑
   */
  static postI3060TechnicalWorkbenchUpdateEnergyStorageTopologyWithtechnologyId(
    params: {
      /**  */
      technologyId: string;
      /**  */
      root?: object;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/technical/workbench/updateEnergyStorageTopology/{technologyId}';
      url = url.replace('{technologyId}', params['technologyId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['root'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 修改储能电池信息
   */
  static postI3060TechnicalWorkbenchUpdateBatteryWithtechnologyId(
    params: {
      /**  */
      technologyId: string;
      /**  */
      root?: object;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/technical/workbench/updateBattery/{technologyId}';
      url = url.replace('{technologyId}', params['technologyId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['root'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 修改储能系统效率属性
   */
  static postI3060TechnicalWorkbenchUpdateEnergyStorageSystemEfficiencyWithtechnologyId(
    params: {
      /** 方案id */
      technologyId: string;
      /**  */
      root?: object;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/technical/workbench/updateEnergyStorageSystemEfficiency/{technologyId}';
      url = url.replace('{technologyId}', params['technologyId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['root'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 修改储能运行策略
   */
  static postI3060TechnicalWorkbenchUpdateRevenueSharingWithtechnologyId(
    params: {
      /**  */
      technologyId: string;
      /**  */
      root?: object;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/technical/workbench/updateRevenueSharing/{technologyId}';
      url = url.replace('{technologyId}', params['technologyId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['root'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 修改光伏拓扑数据
   */
  static postI3060TechnicalWorkbenchUpdatePhotovoltaicTopologyWithtechnologyId(
    params: {
      /** 技术方案id */
      technologyId: string;
      /**  */
      root?: object;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/technical/workbench/updatePhotovoltaicTopology/{technologyId}';
      url = url.replace('{technologyId}', params['technologyId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['root'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 修改光伏系统参数
   */
  static postI3060TechnicalWorkbenchUpdatePhotovoltaicSystemEfficiencyWithtechnologyId(
    params: {
      /**  */
      technologyId: string;
      /**  */
      root?: object;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/technical/workbench/updatePhotovoltaicSystemEfficiency/{technologyId}';
      url = url.replace('{technologyId}', params['technologyId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['root'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 修改地图相关配置
   */
  static postI3060TechnicalWorkbenchUpdateRoofModuleWithtechnologyId(
    params: {
      /**  */
      technologyId: string;
      /**  */
      root?: object;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/technical/workbench/updateRoofModule/{technologyId}';
      url = url.replace('{technologyId}', params['technologyId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['root'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 修改技术方案名称
   */
  static postI3060TechnicalWorkbenchUpdateTechnologyNameWithtechnologyId(
    params: {
      /** 技术方案id */
      technologyId: string;
      /**  */
      root?: object;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/technical/workbench/updateTechnologyName/{technologyId}';
      url = url.replace('{technologyId}', params['technologyId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['root'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 修改逆变器
   */
  static postI3060TechnicalWorkbenchUpdateInverterModuleWithtechnologyId(
    params: {
      /**  */
      technologyId: string;
      /**  */
      root?: object;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/technical/workbench/updateInverterModule/{technologyId}';
      url = url.replace('{technologyId}', params['technologyId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['root'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 修改需量限制
   */
  static postI3060TechnicalWorkbenchUpdateDemandWithtechnicalProposalId(
    params: {
      /** 方案id */
      technicalProposalId: string;
      /**  */
      root?: any | null[];
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/technical/workbench/updateDemand/{technicalProposalId}';
      url = url.replace('{technicalProposalId}', params['technicalProposalId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['root'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 切换技术方案参数库方案
   */
  static postI3060TechnicalWorkbenchCheckParamLibraryWithtechnicalProposalId(
    params: {
      /** 技术方案id */
      technicalProposalId: string;
      /**  */
      root?: object;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/technical/workbench/checkParamLibrary/{technicalProposalId}';
      url = url.replace('{technicalProposalId}', params['technicalProposalId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['root'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 增加光伏
   */
  static postI3060TechnicalWorkbenchAddPhotovoltaicWithtechnologyId(
    params: {
      /** 技术方案id add/list 接口有返回 */
      technologyId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/technical/workbench/addPhotovoltaic/{technologyId}';
      url = url.replace('{technologyId}', params['technologyId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 技术方案工作台详情
   */
  static getI3060TechnicalWorkbenchWithtechnicalProposalId(
    params: {
      /** 技术方案id */
      technicalProposalId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/technical/workbench/{technicalProposalId}';
      url = url.replace('{technicalProposalId}', params['technicalProposalId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 技术方案计算
   */
  static postI3060TechnicalWorkbenchCalcTechnicalWithtechnologyId(
    params: {
      /**  */
      technologyId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/technical/workbench/calcTechnical/{technologyId}';
      url = url.replace('{technologyId}', params['technologyId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 新增储能
   */
  static postI3060TechnicalWorkbenchAddEnergyStorageWithtechnologyId(
    params: {
      /** 技术方案id */
      technologyId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/technical/workbench/addEnergyStorage/{technologyId}';
      url = url.replace('{technologyId}', params['technologyId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 更新光伏设置-其他信息-首年功率衰减率/逐年功率衰减率
   */
  static postI3060TechnicalWorkbenchUpdatePhotovoltaicOtherInfoWithtechnicalProposalId(
    params: {
      /** 技术方案id */
      technicalProposalId: string;
      /**  */
      root?: object;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/technical/workbench/updatePhotovoltaicOtherInfo/{technicalProposalId}';
      url = url.replace('{technicalProposalId}', params['technicalProposalId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['root'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 查询储能系统效率
   */
  static getI3060TechnicalWorkbenchGetEnergyStorageSystemEfficiencyWithtechnologyId(
    params: {
      /**  */
      technologyId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/technical/workbench/getEnergyStorageSystemEfficiency/{technologyId}';
      url = url.replace('{technologyId}', params['technologyId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 查询屋顶信息
   */
  static getI3060TechnicalWorkbenchGetRoofModuleInfoWithtechnologyId(
    params: {
      /**  */
      technologyId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/technical/workbench/getRoofModuleInfo/{technologyId}';
      url = url.replace('{technologyId}', params['technologyId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 根据屋顶id列表查询屋顶坐标
   */
  static postI3060RoofGetNearbyRoofByIds(
    params: {
      /**  */
      root?: object;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/roof/getNearbyRoofByIds';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['root'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 移除储能
   */
  static postI3060TechnicalWorkbenchRemoveEnergyStorageWithtechnologyId(
    params: {
      /**  */
      technologyId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/technical/workbench/removeEnergyStorage/{technologyId}';
      url = url.replace('{technologyId}', params['technologyId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 移除光伏
   */
  static postI3060TechnicalWorkbenchRemovePhotovoltaicWithtechnologyId(
    params: {
      /** 技术方案id */
      technologyId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/technical/workbench/removePhotovoltaic/{technologyId}';
      url = url.replace('{technologyId}', params['technologyId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 结算结果页面能流图以及电价相关
   */
  static getI3060TechnicalWorkbenchGetTopologyElectricityInfoWithid(
    params: {
      /** 技术方案id */
      id: string;
      /** 左侧列表选择的项目 自定义电价的名字 */
      key?: string;
      /** 时间 右侧时间控件选择的时间 2020年开头 */
      time?: string;
      /** 展示全部 */
      showAll?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/technical/workbench/getTopologyElectricityInfo/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { key: params['key'], time: params['time'], showAll: params['showAll'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取储能系统优选属性
   */
  static getI3060TechnicalWorkbenchGetAutoCheckOptWithtechnologyId(
    params: {
      /**  */
      technologyId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/technical/workbench/getAutoCheckOpt/{technologyId}';
      url = url.replace('{technologyId}', params['technologyId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取光伏发电验证报告
   */
  static getI3060TechnicalWorkbenchPhotovoltaicValidReport(options: IRequestOptions = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/technical/workbench/photovoltaicValidReport';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取光伏模组列表
   */
  static getI3060WorkbenchPhotovoltaicModelList(
    params: {
      /** 型号 */
      pvModuleName?: string;
      /** 品牌 */
      manufacturer?: string;
      /** 电池片类型
       * 多晶
       * 单晶
       * Thin Film */
      cellType?: string;
      /** stc最大功率 区间 */
      stcMin?: string;
      /** stc最大功率 区间 */
      stcMax?: string;
      /** ptc最大功率 区间 */
      ptcMin?: string;
      /** ptc最大功率 区间 */
      ptcMax?: string;
      /** 单面/双面 */
      bifacial?: string;
      /** 开路电压 区间 */
      openVoltageMin?: string;
      /** 开路电压 区间 */
      openVoltageMax?: string;
      /** 1 */
      page?: string;
      /** 100 */
      size?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/workbench/photovoltaic/modelList';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        pvModuleName: params['pvModuleName'],
        manufacturer: params['manufacturer'],
        cellType: params['cellType'],
        stcMin: params['stcMin'],
        stcMax: params['stcMax'],
        ptcMin: params['ptcMin'],
        ptcMax: params['ptcMax'],
        bifacial: params['bifacial'],
        openVoltageMin: params['openVoltageMin'],
        openVoltageMax: params['openVoltageMax'],
        page: params['page'],
        size: params['size']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取光伏系统效率详细参数
   */
  static getI3060TechnicalWorkbenchGetPhotovoltaicSystemEfficiencyWithtechnologyId(
    params: {
      /**  */
      technologyId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/technical/workbench/getPhotovoltaicSystemEfficiency/{technologyId}';
      url = url.replace('{technologyId}', params['technologyId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取技术方案详情（编辑）
   */
  static getI3060TechnicalWorkbenchWithtechnologyId(
    params: {
      /**  */
      technologyId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/technical/workbench/{technologyId}';
      url = url.replace('{technologyId}', params['technologyId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取拓扑设置-参考电价下拉选项
   */
  static getI3060TechnicalWorkbenchGetReferencePriceOptionWithtechnicalProposalId(
    params: {
      /** 技术方案id */
      technicalProposalId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/technical/workbench/getReferencePriceOption/{technicalProposalId}';
      url = url.replace('{technicalProposalId}', params['technicalProposalId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取电池列表
   */
  static getI3060WorkbenchEnergyStorageBatteryList(options: IRequestOptions = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/workbench/energyStorage/batteryList';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取逆变器列表
   */
  static getI3060WorkbenchPhotovoltaicInverterList(
    params: {
      /** 逆变器名字 */
      inverterName?: string;
      /** 品牌 */
      manufacturer?: string;
      /** 最大支流功率区间 */
      maxDcPowerMin?: string;
      /** 最大直流功率区间 */
      maxDcPowerMax?: string;
      /** 最大交流功率区间 */
      maxAcPowerMin?: string;
      /** 最大交流功率区间 */
      maxAcPowerMax?: string;
      /** 1 */
      page?: string;
      /** 10 */
      size?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/workbench/photovoltaic/inverterList';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        inverterName: params['inverterName'],
        manufacturer: params['manufacturer'],
        maxDcPowerMin: params['maxDcPowerMin'],
        maxDcPowerMax: params['maxDcPowerMax'],
        maxAcPowerMin: params['maxAcPowerMin'],
        maxAcPowerMax: params['maxAcPowerMax'],
        page: params['page'],
        size: params['size']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取项目附近屋顶坐标数据
   */
  static getI3060TechnicalWorkbenchGetNearbyRoofWithtechnologyId(
    params: {
      /**  */
      technologyId: string;
      /** 范围 单位 公里 */
      distance?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/technical/workbench/getNearbyRoof/{technologyId}';
      url = url.replace('{technologyId}', params['technologyId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { distance: params['distance'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取默认需量限制
   */
  static getI3060TechnicalWorkbenchGetDefaultDemandWithtechnicalProposalId(
    params: {
      /** 方案id */
      technicalProposalId: string;
      /**  */
      root?: any | null[];
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/technical/workbench/getDefaultDemand/{technicalProposalId}';
      url = url.replace('{technicalProposalId}', params['technicalProposalId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = params['root'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 计算屋顶获取可安装模组
   */
  static postI3060TechnicalWorkbenchCalcRoofModuleNumWithtechnologyId(
    params: {
      /**  */
      technologyId: string;
      /**  */
      root?: object;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/technical/workbench/calcRoofModuleNum/{technologyId}';
      url = url.replace('{technologyId}', params['technologyId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['root'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 设置光伏模组
   */
  static postI3060TechnicalWorkbenchUpdatePhotovoltaicModuleWithtechnologyId(
    params: {
      /** 技术方案id */
      technologyId: string;
      /**  */
      root?: object;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/technical/workbench/updatePhotovoltaicModule/{technologyId}';
      url = url.replace('{technologyId}', params['technologyId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['root'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class EvaluationService {
  /**
   * 地区筛选栏接口
   */
  static getI3060EvaluationRegionCode(options: IRequestOptions = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/evaluation/regionCode';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 屋顶扫描
   */
  static getI3060EvaluationGetRoof(
    params: {
      /** 名字 */
      name?: string;
      /** 地址 */
      address?: string;
      /** 地区code */
      regionCode?: string;
      /** 面积 */
      areaMin?: string;
      /**  */
      areaMax?: string;
      /**  */
      areaSort?: string;
      /** 时间 取选项的value 多个逗号隔开  必须选到子项选父项为子项全选 */
      times?: string;
      /**  */
      timeSort?: string;
      /**  */
      yearTotalIrradianceMin?: string;
      /**  */
      yearTotalIrradianceMax?: string;
      /**  */
      yearTotalIrradianceSort?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/evaluation/getRoof';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        name: params['name'],
        address: params['address'],
        regionCode: params['regionCode'],
        areaMin: params['areaMin'],
        areaMax: params['areaMax'],
        areaSort: params['areaSort'],
        times: params['times'],
        timeSort: params['timeSort'],
        yearTotalIrradianceMin: params['yearTotalIrradianceMin'],
        yearTotalIrradianceMax: params['yearTotalIrradianceMax'],
        yearTotalIrradianceSort: params['yearTotalIrradianceSort']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 屋顶扫描时间条件筛选
   */
  static getI3060EvaluationGetRoofQueryParam(
    params: {
      /** 地区code */
      regionCode?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/evaluation/getRoofQueryParam';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { regionCode: params['regionCode'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 根据左下右上坐标获取屋顶数据
   */
  static getI3060EvaluationGetRoofByGeo(
    params: {
      /** 地区code */
      regionCode?: string;
      /** 左下经度 */
      leftBottomLng?: string;
      /** 左下纬度 */
      leftBottomLat?: string;
      /** 右上纬度 */
      rightTopLng?: string;
      /** 右上经度 */
      rightTopLat?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/evaluation/getRoofByGeo';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        regionCode: params['regionCode'],
        leftBottomLng: params['leftBottomLng'],
        leftBottomLat: params['leftBottomLat'],
        rightTopLng: params['rightTopLng'],
        rightTopLat: params['rightTopLat']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 根据左下右上坐标获取网格屋顶信息
   */
  static getI3060EvaluationGetGridData(
    params: {
      /** 级别 0.01 0.02 0.05 0.1 0.2 0.5 1 其中0.01 0.02 0.05 数据都很多 建议用坐标 其他可以直接中国的左下右上 */
      level?: string;
      /** 左下经度 */
      leftBottomLng?: string;
      /** 左下纬度 */
      leftBottomLat?: string;
      /** 右上纬度 */
      rightTopLng?: string;
      /** 右上经度 */
      rightTopLat?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/evaluation/getGridData';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        level: params['level'],
        leftBottomLng: params['leftBottomLng'],
        leftBottomLat: params['leftBottomLat'],
        rightTopLng: params['rightTopLng'],
        rightTopLat: params['rightTopLat']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取全国市、区资源详情（不带条件）
   */
  static getI3060EvaluationRegionData(
    params: {
      /** 地区等级，province,city,district 省市区 逗号隔开 可以传单个 也可以不传 默认所有 */
      level?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/evaluation/regionData';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { level: params['level'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取全国市、区资源详情（带条件）
   */
  static getI3060EvaluationList(
    params: {
      /** 名字 */
      label?: string;
      /** 省份code 逗号隔开 */
      adcodes?: string;
      /** 区域面积 最小 */
      regionAreaMin?: string;
      /** 区域面积 最大 */
      regionAreaMax?: string;
      /** 区域面积 排序  0 不排序 1 asc 2 desc */
      regionAreaSort?: string;
      /** 年辐照 */
      yearTotalIrradianceMin?: string;
      /**  */
      yearTotalIrradianceMax?: string;
      /**  */
      yearTotalIrradianceSort?: string;
      /** 屋顶数量 */
      roofCountMin?: string;
      /**  */
      roofCountMax?: string;
      /**  */
      roofCountSort?: string;
      /** 屋顶面积 */
      roofAreaMin?: string;
      /**  */
      roofAreaMax?: string;
      /**  */
      roofAreaSort?: string;
      /** 屋顶面积平均值 */
      avgRoofAreaMin?: string;
      /**  */
      avgRoofAreaMax?: string;
      /**  */
      avgRoofAreaSort?: string;
      /** 覆盖率 */
      roofRatioMin?: string;
      /**  */
      roofRatioMax?: string;
      /**  */
      roofRatioSort?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/evaluation/list';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        label: params['label'],
        adcodes: params['adcodes'],
        regionAreaMin: params['regionAreaMin'],
        regionAreaMax: params['regionAreaMax'],
        regionAreaSort: params['regionAreaSort'],
        yearTotalIrradianceMin: params['yearTotalIrradianceMin'],
        yearTotalIrradianceMax: params['yearTotalIrradianceMax'],
        yearTotalIrradianceSort: params['yearTotalIrradianceSort'],
        roofCountMin: params['roofCountMin'],
        roofCountMax: params['roofCountMax'],
        roofCountSort: params['roofCountSort'],
        roofAreaMin: params['roofAreaMin'],
        roofAreaMax: params['roofAreaMax'],
        roofAreaSort: params['roofAreaSort'],
        avgRoofAreaMin: params['avgRoofAreaMin'],
        avgRoofAreaMax: params['avgRoofAreaMax'],
        avgRoofAreaSort: params['avgRoofAreaSort'],
        roofRatioMin: params['roofRatioMin'],
        roofRatioMax: params['roofRatioMax'],
        roofRatioSort: params['roofRatioSort']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 资源添加
   */
  static postI3060ResourceAdd(
    params: {
      /**  */
      root?: any | null[];
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/resource/add';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['root'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class ResourceService {
  /**
   * 山地光伏小工具_发电量估算修改
   */
  static postI3060ResourceCalculateFinance(
    params: {
      /**  */
      root?: object;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/resource/calculateFinance';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['root'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 山地光伏小工具_生成报告
   */
  static postI3060ResourceGenerateReport(
    params: {
      /**  */
      root?: object;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/resource/generateReport';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['root'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 山地光伏小工具_计算
   */
  static postI3060ResourceCalculatePvTool2(
    params: {
      /**  */
      root?: object;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/resource/calculatePvTool2';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['root'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 山地光伏小工具_默认参数
   */
  static getI3060ResourceGetPvDefaultParam(options: IRequestOptions = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/resource/getPVDefaultParam';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 资源列表
   */
  static getI3060ResourceList(
    params: {
      /** 资源名 */
      name?: string;
      /** 类型 1:屋顶 */
      type?: string;
      /** 地区码 */
      regionCode?: string;
      /** 创建时间 0 不排序 1:asc 2:desc */
      createdTimeSort?: string;
      /** 创建者名字 */
      createdName?: string;
      /**  */
      page?: string;
      /**  */
      size?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/resource/list';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        name: params['name'],
        type: params['type'],
        regionCode: params['regionCode'],
        createdTimeSort: params['createdTimeSort'],
        createdName: params['createdName'],
        page: params['page'],
        size: params['size']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 资源重命名
   */
  static postI3060ResourceRename(
    params: {
      /**  */
      root?: object;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/resource/rename';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['root'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 资源删除
   */
  static postI3060ResourceRemoveWithid(
    params: {
      /** 资源id */
      id: string;
      /**  */
      root?: object;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/resource/remove/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['root'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 资源详情
   */
  static getI3060ResourceInfoWithid(
    params: {
      /** 资源id */
      id: string;
      /**  */
      root?: any | null[];
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/resource/info/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = params['root'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 资源估算地区筛选条件列表
   */
  static getI3060ResourceGetQueryRegion(options: IRequestOptions = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/resource/getQueryRegion';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 光伏发电小工具_默认值
   */
  static getI3060ResourceGetDefaultCalculate(options: IRequestOptions = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/resource/getDefaultCalculate';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 光伏发电小工具_计算
   */
  static postI3060ResourceCalculatePvTool1(
    params: {
      /**  */
      root?: object;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/resource/calculatePvTool';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['root'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 光伏发电小工具_计算年总辐照
   */
  static getI3060ResourceCalculateSolarRadiation(
    params: {
      /** 经度 */
      longitude?: string;
      /** 纬度 */
      latitude?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/resource/calculateSolarRadiation';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { longitude: params['longitude'], latitude: params['latitude'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class EvaluationV2Service {
  /**
   * 屋顶扫描
   */
  static getI3060EvaluationGetRoofV2(
    params: {
      /** 名字 */
      name?: string;
      /** 地区code */
      regionCode?: string;
      /** 面积 */
      areaMin?: string;
      /**  */
      areaMax?: string;
      /**  */
      areaSort?: string;
      /** 时间 取选项的value 多个逗号隔开  必须选到子项选父项为子项全选 */
      times?: string;
      /** 分页 */
      page?: string;
      /** 分页 */
      size?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/evaluation/getRoofV2';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        name: params['name'],
        regionCode: params['regionCode'],
        areaMin: params['areaMin'],
        areaMax: params['areaMax'],
        areaSort: params['areaSort'],
        times: params['times'],
        page: params['page'],
        size: params['size']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 屋顶数据下载
   */
  static getI3060EvaluationDownloadRoof(
    params: {
      /** 名字 */
      name?: string;
      /** 地区code */
      regionCode?: string;
      /** 面积 */
      areaMin?: string;
      /**  */
      areaMax?: string;
      /**  */
      areaSort?: string;
      /** 时间 取选项的value 多个逗号隔开  必须选到子项选父项为子项全选 */
      times?: string;
      /** 分页 */
      page?: string;
      /** 分页 */
      size?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/evaluation/downloadRoof';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        name: params['name'],
        regionCode: params['regionCode'],
        areaMin: params['areaMin'],
        areaMax: params['areaMax'],
        areaSort: params['areaSort'],
        times: params['times'],
        page: params['page'],
        size: params['size']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 屋顶详情
   */
  static getI3060EvaluationGetRoofInfo(
    params: {
      /** 区code */
      code?: string;
      /** 屋顶id */
      id?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/evaluation/getRoofInfo';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { code: params['code'], id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取全国市、区资源详情
   */
  static postI3060RegionRoofGetRegionRoofSummary(
    params: {
      /**  */
      root?: object;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/region_roof/getRegionRoofSummary';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['root'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取全国市、区资源详情（不带条件）
   */
  static getI3060RegionRoofGetRegionData(
    params: {
      /** 地区等级，province,city,district 省市区 逗号隔开 可以传单个 也可以不传 默认所有 */
      level?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/region_roof/getRegionData';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { level: params['level'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class RoofWordService {
  /**
   * 1.摘要-屋顶分布区间
   */
  static getI3060RoofInitRoofSectionChart(
    params: {
      /**  */
      adcode?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/roof/initRoofSectionChart';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { adcode: params['adcode'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 2.屋顶资源-资源指标
   */
  static getI3060RoofInitResourceIndicatorsChart(
    params: {
      /**  */
      adcode?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/roof/initResourceIndicatorsChart';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { adcode: params['adcode'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 2.屋顶资源-资源详情
   */
  static getI3060RoofInitResourceDetailChart(
    params: {
      /**  */
      adcode?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/roof/initResourceDetailChart';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { adcode: params['adcode'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 屋顶报告word/pdf下载
   */
  static getI3060RoofGetRoofWord(
    params: {
      /** 地区code */
      adcode?: string;
      /** 0:word下载后缀为.docx 1:pdf下载后缀为.pdf */
      type?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/roof/getRoofWord';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { adcode: params['adcode'], type: params['type'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 未安装屋顶总面积直方图数据
   */
  static getI3060RoofQueryAvailableAreasData(
    params: {
      /** 地区code */
      adcode?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/roof/queryAvailableAreasData';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { adcode: params['adcode'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 查询行政区域面积直方图面积数据
   */
  static getI3060RoofQueryDistrictAreaData(
    params: {
      /** 地区code */
      adcode?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/roof/queryDistrictAreaData';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { adcode: params['adcode'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取省市code
   */
  static getI3060RoofGetRegionCode(options: IRequestOptions = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/roof/getRegionCode';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class TenantControllerService {
  /**
   * 修改组织名称
   */
  static postAuthTenantUpdate(
    params: {
      /**  */
      root?: object;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/auth/tenant/update';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['root'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 切换用户组织
   */
  static getAuthTenantChange(
    params: {
      /** (Long) */
      tenantId?: string;
      /** (Boolean) */
      isAdministrators?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/auth/tenant/change';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { tenantId: params['tenantId'], isAdministrators: params['isAdministrators'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 新增组织
   */
  static postAuthTenantAdd(
    params: {
      /** TenantReq :TenantReq */
      root?: object;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/auth/tenant/add';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['root'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 查询用户所有组织(后台调用isAdministrators 传true，前台调用不传
   */
  static getAuthTenantList(
    params: {
      /** (Boolean) */
      isAdministrators?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/auth/tenant/list';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { isAdministrators: params['isAdministrators'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取用户当前组织，没有设置设置默认组织
   */
  static getAuthTenantUserTenant(options: IRequestOptions = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/auth/tenant/userTenant';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class AccountControllerService {
  /**
   * 取消管理员
   */
  static postEAuthAccountCancelAdmin(
    params: {
      /**  */
      root?: object;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/e-auth/account/cancelAdmin';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['root'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 查看用户详情
   */
  static getAuthAccountDetail(
    params: {
      /** (Long) */
      userId?: string;
      /** (Long) */
      tenantId?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/auth/account/detail';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { userId: params['userId'], tenantId: params['tenantId'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 生成用户链接和项目链接
   */
  static postAuthAccountAdd(
    params: {
      /** AccountAddReq :AccountAddReq */
      root?: object;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/auth/account/add';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['root'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 用户接受邀请
   */
  static postAuthAccountUserAcceptInvite(
    params: {
      /** AcceptInviteReq :AcceptInviteReq */
      root?: object;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/auth/account/userAcceptInvite';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['root'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 用户是否加入组织
   */
  static postAuthAccountIsJoinTenant(
    params: {
      /**  */
      root?: object;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/auth/account/isJoinTenant';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['root'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 登录或者注册用户是否被移除组织
   */
  static postAuthAccountIsRemoveTenant(
    params: {
      /**  */
      root?: object;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/auth/account/isRemoveTenant';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['root'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 移除组织用户
   */
  static postAuthAccountDelTenantAccount(
    params: {
      /** List<TenantAccountIdReq> */
      root?: any | null[];
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/auth/account/delTenantAccount';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['root'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 组织下的用户
   */
  static postEAuthAccountInfo(
    params: {
      /**  */
      root?: object;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/e-auth/account/info';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['root'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 设置管理员
   */
  static postEAuthAccountSetAdmin(
    params: {
      /**  */
      root?: object;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/e-auth/account/setAdmin';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['root'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class PermissionTemplateControllerService {
  /**
   * 修改模板名称或者是否默认
   */
  static postAuthPermissionTemplateUpdate(
    params: {
      /** PermissionTemplateReq :PermissionTemplateReq */
      root?: object;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/auth/permissionTemplate/update';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['root'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 删除模板
   */
  static postAuthPermissionTemplateDelete(
    params: {
      /** (Long) */
      id?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/auth/permissionTemplate/delete';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = { id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 新增模板以及模板资源
   */
  static postAuthPermissionTemplateAddTemplateAndResources(
    params: {
      /** TemplateResourcesReq :TemplateResourcesReq */
      root?: object;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/auth/permissionTemplate/addTemplateAndResources';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['root'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 新增用户模板
   */
  static postAuthPermissionTemplateAdd(
    params: {
      /** PermissionTemplateReq :PermissionTemplateReq */
      root?: object;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/auth/permissionTemplate/add';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['root'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 新增编辑模板资源
   */
  static postAuthPermissionTemplateResourcesAddOrUpdate(
    params: {
      /**  */
      root?: object;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/auth/permissionTemplate/resources/addOrUpdate';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['root'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 查询所有的模板资源
   */
  static getAuthPermissionTemplateResourcesAll(options: IRequestOptions = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/auth/permissionTemplate/resourcesAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 查询模版资源树
   */
  static getAuthPermissionTemplateResourcesAllTree(options: IRequestOptions = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/auth/permissionTemplate/resourcesAllTree';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 查询资源
   */
  static postAuthPermissionTemplateResourcesByTemplateIds(
    params: {
      /** Set<Long> */
      root?: any | null[];
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/auth/permissionTemplate/resourcesByTemplateIds';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['root'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 模板资源查看
   */
  static getAuthPermissionTemplateResourcesQuery(
    params: {
      /** (Long) */
      userId?: string;
      /** (Long) */
      templateId?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/auth/permissionTemplate/resources/query';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { userId: params['userId'], templateId: params['templateId'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 模板资源查看树
   */
  static getAuthPermissionTemplateResourcesQueryTree(
    params: {
      /** (Long) */
      templateId?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/auth/permissionTemplate/resources/query/tree';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { templateId: params['templateId'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 用户模板列表
   */
  static postAuthPermissionTemplateList(
    params: {
      /**  */
      root?: object;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/auth/permissionTemplate/list';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['root'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 用户模板列表
   */
  static getAuthPermissionTemplateList(options: IRequestOptions = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/auth/permissionTemplate/list';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class ProjectAccountControllerService {
  /**
   * 保存用户资源权限
   */
  static postI3060ProjectAccountSaveResources(
    params: {
      /** SaveAccountResourcesReq :SaveAccountResourcesReq */
      root?: object;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/project/account/save/resources';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['root'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 删除租户下成员
   */
  static postI3060ProjectAccountDeleteByTenant(
    params: {
      /** DeleteTenantProjectAccount :DeleteTenantProjectAccount */
      root?: object;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/project/account/deleteByTenant';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['root'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 加入项目check
   */
  static getI3060ProjectAccountCheckJoin(
    params: {
      /** 项目id(Long) */
      projectId?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/project/account/check/join';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { projectId: params['projectId'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 查看项目成员权限数据
   */
  static getI3060ProjectAccountResources(
    params: {
      /** 用户id */
      userId?: string;
      /** 项目id */
      projectId?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/project/account/resources';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { userId: params['userId'], projectId: params['projectId'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 查询当前用户在该项目的权限信息
   */
  static getI3060ProjectAccountCurrAuth(
    params: {
      /** 项目id(Long) */
      projectId?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/project/account/currAuth';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { projectId: params['projectId'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 添加成员
   */
  static postI3060ProjectAccountAdd(
    params: {
      /** List<AddProjectAccount> */
      root?: any | null[];
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/project/account/add';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['root'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 移除项目成员
   */
  static postI3060ProjectAccountRemove(
    params: {
      /** ProjectAccountReq :ProjectAccountReq */
      root?: object;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/project/account/remove';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['root'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取当前用户项目操作权限
   */
  static getI3060ProjectAccountOperationAuth(
    params: {
      /** 项目id(Long) */
      projectId?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/project/account/operation/auth';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { projectId: params['projectId'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 设置负责人、管理员
   */
  static postI3060ProjectAccountRoleSet(
    params: {
      /** AccountRoleSetReq :AccountRoleSetReq */
      root?: object;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/project/account/role/set';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['root'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 项目成员列表
   */
  static getI3060ProjectAccountListWithprojectId(
    params: {
      /** 项目id(Long) */
      projectId: string;
      /** 搜索词(String) */
      keyword?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/project/account/list/{projectId}';
      url = url.replace('{projectId}', params['projectId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { keyword: params['keyword'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 项目选择成员列表
   */
  static getI3060ProjectAccountSelectionWithprojectId(
    params: {
      /** 项目id(Long) */
      projectId: string;
      /** 项目名称模糊搜索(String) */
      keyword?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/project/account/selection/{projectId}';
      url = url.replace('{projectId}', params['projectId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { keyword: params['keyword'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 项目邀请链接确认
   */
  static postI3060ProjectAccountInvitationConfirm(
    params: {
      /** AddProjectAccount :AddProjectAccount */
      root?: object;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/project/account/invitation/confirm';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['root'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class ProjectManageControllerService {
  /**
   * 删除项目
   */
  static postI3060ProjectManageDeleteWithprojectId(
    params: {
      /** 项目id(Long) */
      projectId: string;
      /** 删除类型 1.普通删除  2.永久删除(Integer) */
      type?: string;
      /**  */
      root?: object;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/project/manage/delete/{projectId}';
      url = url.replace('{projectId}', params['projectId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = { type: params['type'] };
      let data = params['root'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 回收站列表
   */
  static getI3060ProjectManageRecycleBin(
    params: {
      /** 关键词搜索 */
      keyword?: string;
      /**  */
      page?: string;
      /**  */
      size?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/project/manage/recycle/bin';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { keyword: params['keyword'], page: params['page'], size: params['size'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 给项目初始化负责人以及租户
   */
  static getI3060ProjectManageProjectDataRepair(options: IRequestOptions = {}): Promise<any | null> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/project/manage/project/dataRepair';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 项目列表（根据成员查询）
   */
  static getI3060ProjectManageListWithuserId(
    params: {
      /** 用户id(Long) */
      userId: string;
      /** 关键词(String) */
      keyword?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/project/manage/list/{userId}';
      url = url.replace('{userId}', params['userId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { keyword: params['keyword'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 项目恢复
   */
  static postI3060ProjectManageRecoveryWithprojectId(
    params: {
      /** 项目id(Long) */
      projectId: string;
      /** 项目名称(String) */
      projectName?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/project/manage/recovery/{projectId}';
      url = url.replace('{projectId}', params['projectId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = { projectName: params['projectName'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 项目管理
   */
  static getI3060ProjectManagePage(
    params: {
      /** 关键词搜索 */
      keyword?: string;
      /**  */
      page?: string;
      /**  */
      size?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/project/manage/page';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { keyword: params['keyword'], page: params['page'], size: params['size'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class InviteControllerService {
  /**
   * 邀请code获取详情
   */
  static getAuthInviteCodeToContentWithcode(
    params: {
      /** 邀请code */
      code: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/auth/invite/codeToContent/{code}';
      url = url.replace('{code}', params['code'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class HelperJurisdictionControllerService {
  /**
   * 删除协助者数据
   */
  static deleteI3060HelperJurisdictionDeleteWithid(
    params: {
      /** (Long) */
      id: string;
      /**  */
      root?: object;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/helper/jurisdiction/delete/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = params['root'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 新增协助者批量
   */
  static postI3060HelperJurisdictionBatchAdd(
    params: {
      /**  */
      root?: any | null[];
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/helper/jurisdiction/batch/add';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['root'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 查询项目成员列表（普通成员），已有协助者置灰（project_account）
   */
  static postI3060HelperJurisdictionProjectMembersList(
    params: {
      /** ProjectMembersReq :ProjectMembersReq */
      root?: object;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/helper/jurisdiction/project/members/list';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['root'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取当前数据资源权限
   */
  static getI3060HelperJurisdictionCurrDataAuthWithdataId(
    params: {
      /** 项目数据id(Long) */
      dataId: string;
      /** 项目数据类型 1.技术方案(Integer) */
      dataType?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/helper/jurisdiction/curr/dataAuth/{dataId}';
      url = url.replace('{dataId}', params['dataId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { dataType: params['dataType'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 通过项目数据id和项目数据类型type获取资源协助者列表
   */
  static getI3060HelperJurisdictionListWithdataId(
    params: {
      /** 项目数据id(Long) */
      dataId: string;
      /** 项目数据类型 1.技术方案(Integer) */
      dataType?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/helper/jurisdiction/list/{dataId}';
      url = url.replace('{dataId}', params['dataId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { dataType: params['dataType'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class WorkOrderService {
  /**
   * 创建工单
   */
  static postAuthManageWorkOrderSubmit(
    params: {
      /**  */
      root?: object;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/auth/manage/work_order/submit';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['root'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 工单信息
   */
  static getAuthManageWorkOrderInfo(
    params: {
      /** 工单编号 */
      orderNo?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/auth/manage/work_order/info';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { orderNo: params['orderNo'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 工单列表
   */
  static getAuthManageWorkOrderList(
    params: {
      /** 工单号筛选 */
      orderNo?: string;
      /** 描述 */
      orderDesc?: string;
      /** 响应状态 0:未回复（用户侧） 1:已回复（用户侧） */
      respondStatus?: string;
      /** 创建时间 1:asc 2:desc */
      createTimeSort?: string;
      /** 更新时间 1:asc 2:desc */
      updateTimeSort?: string;
      /**  */
      page?: string;
      /**  */
      size?: string;
      /** 类型1:业务咨询 2:使用帮助 3:问题反馈 4:其他  多选逗号分隔 */
      type?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/auth/manage/work_order/list';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        orderNo: params['orderNo'],
        orderDesc: params['orderDesc'],
        respondStatus: params['respondStatus'],
        createTimeSort: params['createTimeSort'],
        updateTimeSort: params['updateTimeSort'],
        page: params['page'],
        size: params['size'],
        type: params['type']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 工单回复
   */
  static postAuthManageWorkOrderReply(
    params: {
      /**  */
      root?: object;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/auth/manage/work_order/reply';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['root'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 工单文件上传
   */
  static postAuthManageWorkOrderUploadFile(
    params: {
      /** 文件 */
      file?: any;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/auth/manage/work_order/uploadFile';

      const configs: IRequestConfig = getConfigs('post', 'multipart/form-data', url, options);

      let data = null;
      data = new FormData();
      if (params['file']) {
        if (Object.prototype.toString.call(params['file']) === '[object Array]') {
          for (const item of params['file']) {
            data.append('file', item as any);
          }
        } else {
          data.append('file', params['file'] as any);
        }
      }

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 工单消息列表
   */
  static getAuthManageWorkOrderMsgList(
    params: {
      /**  */
      orderNo?: string;
      /**  */
      page?: string;
      /**  */
      size?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/auth/manage/work_order/msg_list';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { orderNo: params['orderNo'], page: params['page'], size: params['size'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class ManageService {
  /**
   * manage系统登陆
   */
  static postManageLogin(
    params: {
      /**  */
      root?: object;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/manage/login';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['root'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 工单信息
   */
  static getManageWorkOrderInfo(
    params: {
      /** 工单编号 */
      orderNo?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/manage/work_order/info';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { orderNo: params['orderNo'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 工单列表
   */
  static getManageWorkOrderList(
    params: {
      /** 工单号筛选 */
      orderNo?: string;
      /** 描述 */
      orderDesc?: string;
      /** 响应状态 0:未回复（用户侧） 1:已回复（用户侧） */
      respondStatus?: string;
      /** 创建时间 1:asc 2:desc */
      createTimeSort?: string;
      /** 更新时间 1:asc 2:desc */
      updateTimeSort?: string;
      /**  */
      page?: string;
      /**  */
      size?: string;
      /** 类型1:业务咨询 2:使用帮助 3:问题反馈 4:其他  多选逗号分隔 */
      type?: string;
      /**  昵称 */
      userName?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/manage/work_order/list';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        orderNo: params['orderNo'],
        orderDesc: params['orderDesc'],
        respondStatus: params['respondStatus'],
        createTimeSort: params['createTimeSort'],
        updateTimeSort: params['updateTimeSort'],
        page: params['page'],
        size: params['size'],
        type: params['type'],
        userName: params['userName']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 工单回复
   */
  static postManageWorkOrderReply(
    params: {
      /**  */
      root?: object;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/manage/work_order/reply';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['root'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 工单文件上传
   */
  static postManageWorkOrderUploadFile(
    params: {
      /** 文件 */
      file?: any;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/manage/work_order/uploadFile';

      const configs: IRequestConfig = getConfigs('post', 'multipart/form-data', url, options);

      let data = null;
      data = new FormData();
      if (params['file']) {
        if (Object.prototype.toString.call(params['file']) === '[object Array]') {
          for (const item of params['file']) {
            data.append('file', item as any);
          }
        } else {
          data.append('file', params['file'] as any);
        }
      }

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 工单消息列表
   */
  static getManageWorkOrderMsgList(
    params: {
      /**  */
      orderNo?: string;
      /**  */
      page?: string;
      /**  */
      size?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/manage/work_order/msg_list';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { orderNo: params['orderNo'], page: params['page'], size: params['size'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class DeviceStateService {
  /**
   * 电站下拉框
   */
  static getI3060DeviceStateGetPowerStationComboBox(options: IRequestOptions = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/deviceState/getPowerStationComboBox';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取模糊查询树结构
   */
  static getI3060DeviceStateGetLikeTree(options: IRequestOptions = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/deviceState/getLikeTree';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取测点数据图标选项
   */
  static getI3060DeviceStateGetMeasuringPointCheckbox(
    params: {
      /** 节点id */
      entityId?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/deviceState/getMeasuringPointCheckbox';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { entityId: params['entityId'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取测点数据图表详情
   */
  static getI3060DeviceStateGetMeasuringPointDiagram(
    params: {
      /** 节点id */
      entityId?: string;
      /** 查询选项 */
      identifyList?: string;
      /** 查询时间 : yyyy-MM-dd */
      queryTime?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/deviceState/getMeasuringPointDiagram';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        entityId: params['entityId'],
        identifyList: params['identifyList'],
        queryTime: params['queryTime']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取测点资产树
   */
  static getI3060DeviceStateGetMeasuringPoint(
    params: {
      /** 节点id */
      entityId?: string;
      /** 电站id */
      powerStationId?: string;
      /** 搜索电站或设备名称 */
      searchName?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/deviceState/getMeasuringPoint';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        entityId: params['entityId'],
        powerStationId: params['powerStationId'],
        searchName: params['searchName']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 设备情况及类型分布
   */
  static getI3060DeviceStateGetDeviceTypeDistribution(
    params: {
      /**  */
      powerStationId?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/deviceState/getDeviceTypeDistribution';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { powerStationId: params['powerStationId'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 设备状况列表
   */
  static getI3060DeviceStateGetDeviceStateList(
    params: {
      /** 电站id */
      powerStationId?: string;
      /** 设备类型 */
      deviceType?: string;
      /**  */
      page?: string;
      /**  */
      size?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/deviceState/getDeviceStateList';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        powerStationId: params['powerStationId'],
        deviceType: params['deviceType'],
        page: params['page'],
        size: params['size']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 设备类型下拉框
   */
  static getI3060DeviceStateGetDeviceTypeComboBox(options: IRequestOptions = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/deviceState/getDeviceTypeComboBox';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class LargeScreenControllerService {
  /**
   * 实时功率曲线
   */
  static getI3060LargeScreenRealTimePowerChart(options: IRequestOptions = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/largeScreen/realTime/powerChart';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 电站分布
   */
  static getI3060LargeScreenPowerStationDistribute(options: IRequestOptions = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/largeScreen/powerStation/distribute';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 电站排名
   */
  static getI3060LargeScreenPowerStationRanking(options: IRequestOptions = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/largeScreen/powerStation/ranking';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 直方图
   */
  static getI3060LargeScreenHistogram(
    params: {
      /** 类型  1.装机容量 2.电站数量 3.发电量监控(Integer) */
      type?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/largeScreen/histogram';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { type: params['type'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 运维大屏统计栏目
   */
  static getI3060LargeScreenStatisticsColumn(options: IRequestOptions = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/largeScreen/statistics/column';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class PowerStationControllerService {
  /**
   * 创建电站
   */
  static postI3060PowerStationCreate(
    params: {
      /** CreatePowerStationReq :CreatePowerStationReq */
      root?: object;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/powerStation/create';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['root'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 电站列表分页
   */
  static getI3060PowerStationPage(
    params: {
      /**  */
      page?: string;
      /**  */
      size?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/powerStation/page';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { page: params['page'], size: params['size'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 电站详情
   */
  static getI3060PowerStationShow(
    params: {
      /**  */
      powerStationId?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/powerStation/show';

      const configs: IRequestConfig = getConfigs('get', 'multipart/form-data', url, options);
      configs.params = { powerStationId: params['powerStationId'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 项目下拉框
   */
  static getI3060ProjectProjectSelect(
    params: {
      /** 项目名称模糊搜索(String) */
      keyword?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/project/projectSelect';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { keyword: params['keyword'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class AssetsTreeService {
  /**
   * 获取电站节点
   */
  static getI3060AssetsTreeGetPowerStation(
    params: {
      /**  */
      projectId?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/assetsTree/getPowerStation';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { projectId: params['projectId'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取节点设备
   */
  static getI3060AssetsTreeGetDevice(
    params: {
      /**  */
      thingxTreeId?: string;
      /**  */
      thingxPowerStationId?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/assetsTree/getDevice';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { thingxTreeId: params['thingxTreeId'], thingxPowerStationId: params['thingxPowerStationId'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class JinHeControllerService {
  /**
   * 上传资料
   */
  static postAuthJinheUploadCompany(
    params: {
      /** 用户企业名称 */
      companyName?: string;
      /** 信用代码 */
      creditCode?: string;
      /** 企业法人 */
      legalPerson?: string;
      /** 法人手机号 */
      legalPersonPhone?: string;
      /** 相关负责人 */
      principal?: string;
      /** 企业负责人身份证号 */
      principalIdNum?: string;
      /** 企业负责人联系电话 */
      phone?: string;
      /** 银联负责人 */
      unionPerson?: string;
      /** 银联负责人电话 */
      unionPersonPhone?: string;
      /** 地区 */
      region?: string;
      /** 企业邮箱 */
      enterpriseMailbox?: string;
      /** 固定电话 */
      fixedTelephone?: string;
      /** 营业执照 base64 */
      businessLicense?: any;
      /** 营业执照开始时间yyyy-MM-dd */
      businessLicenseBeginDate?: string;
      /** 营业执照结束时间yyyy-MM-dd */
      businessLicenseEndDate?: string;
      /** 法人身份证号码 */
      idNumber?: string;
      /** 法人身份证正面 base64 */
      identityFront?: any;
      /** 法人身份证反面 base64 */
      identityBack?: any;
      /** 身份证开始时间 yyyy-MM-dd */
      identityBeginDate?: string;
      /** 身份证结束时间 yyyy-MM-dd */
      identityEndDate?: string;
      /** 银联负责人身份证号码 */
      unionPersonalIdCard?: string;
      /** 银联负责人身份证正面url */
      unionPersonalCardImg?: any;
      /** 银联负责人身份证反面url */
      unionPersonalCardImgSide?: any;
      /** 开户许可证 */
      openAccountPermit?: any;
      /** 开户许可证号 */
      openAccountPermitNo?: string;
      /** 企业经营地址 */
      unionAddress?: string;
      /** 企业证件类型：1-三证三号 2-三证合一 0-个体工商户 */
      companyCertificatesType?: string;
      /** 银行卡类型 */
      cardType?: string;
      /** 基本户银行卡号 */
      cardNo?: string;
      /** 基本户开户行 */
      bank?: string;
      /** 基本户联行号 */
      bankNo?: string;
      /** 一般户银行卡号 */
      normalCardNo?: string;
      /** 一般户开户行 */
      normalBank?: string;
      /** 一般户联行号 */
      normalBankNo?: string;
      /** 认证授权书 */
      authUrl?: any;
      /** 收款人 */
      invoicePayeeName?: string;
      /** 复核人 */
      invoiceReview?: string;
      /** 复核人手机号 */
      invoiceReviewPhone?: string;
      /** 开票人 */
      invoiceDrawer?: string;
      /** 手机号 */
      invoiceDrawerPhone?: string;
      /** 发票额度 */
      invoiceQuota?: string;
      /** 发票月度额度 */
      invoiceQuotaMonth?: string;
      /** 税盘类型  航天/百旺 */
      taxPlateType?: string;
      /** 所属平台 （I3060） */
      belongPlatform?: string;
      /** 1:企业 2：个体工商户 */
      companyType?: string;
      /** 所属对象（供应商/贸易商/个人/资方/合作方） */
      belongObject?: string;
      /** 涉及行业 */
      relatedIndustry?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/auth/jinhe/uploadCompany';

      const configs: IRequestConfig = getConfigs('post', 'multipart/form-data', url, options);

      let data = null;
      data = new FormData();
      if (params['companyName']) {
        if (Object.prototype.toString.call(params['companyName']) === '[object Array]') {
          for (const item of params['companyName']) {
            data.append('companyName', item as any);
          }
        } else {
          data.append('companyName', params['companyName'] as any);
        }
      }

      if (params['creditCode']) {
        if (Object.prototype.toString.call(params['creditCode']) === '[object Array]') {
          for (const item of params['creditCode']) {
            data.append('creditCode', item as any);
          }
        } else {
          data.append('creditCode', params['creditCode'] as any);
        }
      }

      if (params['legalPerson']) {
        if (Object.prototype.toString.call(params['legalPerson']) === '[object Array]') {
          for (const item of params['legalPerson']) {
            data.append('legalPerson', item as any);
          }
        } else {
          data.append('legalPerson', params['legalPerson'] as any);
        }
      }

      if (params['legalPersonPhone']) {
        if (Object.prototype.toString.call(params['legalPersonPhone']) === '[object Array]') {
          for (const item of params['legalPersonPhone']) {
            data.append('legalPersonPhone', item as any);
          }
        } else {
          data.append('legalPersonPhone', params['legalPersonPhone'] as any);
        }
      }

      if (params['principal']) {
        if (Object.prototype.toString.call(params['principal']) === '[object Array]') {
          for (const item of params['principal']) {
            data.append('principal', item as any);
          }
        } else {
          data.append('principal', params['principal'] as any);
        }
      }

      if (params['principalIdNum']) {
        if (Object.prototype.toString.call(params['principalIdNum']) === '[object Array]') {
          for (const item of params['principalIdNum']) {
            data.append('principalIdNum', item as any);
          }
        } else {
          data.append('principalIdNum', params['principalIdNum'] as any);
        }
      }

      if (params['phone']) {
        if (Object.prototype.toString.call(params['phone']) === '[object Array]') {
          for (const item of params['phone']) {
            data.append('phone', item as any);
          }
        } else {
          data.append('phone', params['phone'] as any);
        }
      }

      if (params['unionPerson']) {
        if (Object.prototype.toString.call(params['unionPerson']) === '[object Array]') {
          for (const item of params['unionPerson']) {
            data.append('unionPerson', item as any);
          }
        } else {
          data.append('unionPerson', params['unionPerson'] as any);
        }
      }

      if (params['unionPersonPhone']) {
        if (Object.prototype.toString.call(params['unionPersonPhone']) === '[object Array]') {
          for (const item of params['unionPersonPhone']) {
            data.append('unionPersonPhone', item as any);
          }
        } else {
          data.append('unionPersonPhone', params['unionPersonPhone'] as any);
        }
      }

      if (params['region']) {
        if (Object.prototype.toString.call(params['region']) === '[object Array]') {
          for (const item of params['region']) {
            data.append('region', item as any);
          }
        } else {
          data.append('region', params['region'] as any);
        }
      }

      if (params['enterpriseMailbox']) {
        if (Object.prototype.toString.call(params['enterpriseMailbox']) === '[object Array]') {
          for (const item of params['enterpriseMailbox']) {
            data.append('enterpriseMailbox', item as any);
          }
        } else {
          data.append('enterpriseMailbox', params['enterpriseMailbox'] as any);
        }
      }

      if (params['fixedTelephone']) {
        if (Object.prototype.toString.call(params['fixedTelephone']) === '[object Array]') {
          for (const item of params['fixedTelephone']) {
            data.append('fixedTelephone', item as any);
          }
        } else {
          data.append('fixedTelephone', params['fixedTelephone'] as any);
        }
      }

      if (params['businessLicense']) {
        if (Object.prototype.toString.call(params['businessLicense']) === '[object Array]') {
          for (const item of params['businessLicense']) {
            data.append('businessLicense', item as any);
          }
        } else {
          data.append('businessLicense', params['businessLicense'] as any);
        }
      }

      if (params['businessLicenseBeginDate']) {
        if (Object.prototype.toString.call(params['businessLicenseBeginDate']) === '[object Array]') {
          for (const item of params['businessLicenseBeginDate']) {
            data.append('businessLicenseBeginDate', item as any);
          }
        } else {
          data.append('businessLicenseBeginDate', params['businessLicenseBeginDate'] as any);
        }
      }

      if (params['businessLicenseEndDate']) {
        if (Object.prototype.toString.call(params['businessLicenseEndDate']) === '[object Array]') {
          for (const item of params['businessLicenseEndDate']) {
            data.append('businessLicenseEndDate', item as any);
          }
        } else {
          data.append('businessLicenseEndDate', params['businessLicenseEndDate'] as any);
        }
      }

      if (params['idNumber']) {
        if (Object.prototype.toString.call(params['idNumber']) === '[object Array]') {
          for (const item of params['idNumber']) {
            data.append('idNumber', item as any);
          }
        } else {
          data.append('idNumber', params['idNumber'] as any);
        }
      }

      if (params['identityFront']) {
        if (Object.prototype.toString.call(params['identityFront']) === '[object Array]') {
          for (const item of params['identityFront']) {
            data.append('identityFront', item as any);
          }
        } else {
          data.append('identityFront', params['identityFront'] as any);
        }
      }

      if (params['identityBack']) {
        if (Object.prototype.toString.call(params['identityBack']) === '[object Array]') {
          for (const item of params['identityBack']) {
            data.append('identityBack', item as any);
          }
        } else {
          data.append('identityBack', params['identityBack'] as any);
        }
      }

      if (params['identityBeginDate']) {
        if (Object.prototype.toString.call(params['identityBeginDate']) === '[object Array]') {
          for (const item of params['identityBeginDate']) {
            data.append('identityBeginDate', item as any);
          }
        } else {
          data.append('identityBeginDate', params['identityBeginDate'] as any);
        }
      }

      if (params['identityEndDate']) {
        if (Object.prototype.toString.call(params['identityEndDate']) === '[object Array]') {
          for (const item of params['identityEndDate']) {
            data.append('identityEndDate', item as any);
          }
        } else {
          data.append('identityEndDate', params['identityEndDate'] as any);
        }
      }

      if (params['unionPersonalIdCard']) {
        if (Object.prototype.toString.call(params['unionPersonalIdCard']) === '[object Array]') {
          for (const item of params['unionPersonalIdCard']) {
            data.append('unionPersonalIdCard', item as any);
          }
        } else {
          data.append('unionPersonalIdCard', params['unionPersonalIdCard'] as any);
        }
      }

      if (params['unionPersonalCardImg']) {
        if (Object.prototype.toString.call(params['unionPersonalCardImg']) === '[object Array]') {
          for (const item of params['unionPersonalCardImg']) {
            data.append('unionPersonalCardImg', item as any);
          }
        } else {
          data.append('unionPersonalCardImg', params['unionPersonalCardImg'] as any);
        }
      }

      if (params['unionPersonalCardImgSide']) {
        if (Object.prototype.toString.call(params['unionPersonalCardImgSide']) === '[object Array]') {
          for (const item of params['unionPersonalCardImgSide']) {
            data.append('unionPersonalCardImgSide', item as any);
          }
        } else {
          data.append('unionPersonalCardImgSide', params['unionPersonalCardImgSide'] as any);
        }
      }

      if (params['openAccountPermit']) {
        if (Object.prototype.toString.call(params['openAccountPermit']) === '[object Array]') {
          for (const item of params['openAccountPermit']) {
            data.append('openAccountPermit', item as any);
          }
        } else {
          data.append('openAccountPermit', params['openAccountPermit'] as any);
        }
      }

      if (params['openAccountPermitNo']) {
        if (Object.prototype.toString.call(params['openAccountPermitNo']) === '[object Array]') {
          for (const item of params['openAccountPermitNo']) {
            data.append('openAccountPermitNo', item as any);
          }
        } else {
          data.append('openAccountPermitNo', params['openAccountPermitNo'] as any);
        }
      }

      if (params['unionAddress']) {
        if (Object.prototype.toString.call(params['unionAddress']) === '[object Array]') {
          for (const item of params['unionAddress']) {
            data.append('unionAddress', item as any);
          }
        } else {
          data.append('unionAddress', params['unionAddress'] as any);
        }
      }

      if (params['companyCertificatesType']) {
        if (Object.prototype.toString.call(params['companyCertificatesType']) === '[object Array]') {
          for (const item of params['companyCertificatesType']) {
            data.append('companyCertificatesType', item as any);
          }
        } else {
          data.append('companyCertificatesType', params['companyCertificatesType'] as any);
        }
      }

      if (params['cardType']) {
        if (Object.prototype.toString.call(params['cardType']) === '[object Array]') {
          for (const item of params['cardType']) {
            data.append('cardType', item as any);
          }
        } else {
          data.append('cardType', params['cardType'] as any);
        }
      }

      if (params['cardNo']) {
        if (Object.prototype.toString.call(params['cardNo']) === '[object Array]') {
          for (const item of params['cardNo']) {
            data.append('cardNo', item as any);
          }
        } else {
          data.append('cardNo', params['cardNo'] as any);
        }
      }

      if (params['bank']) {
        if (Object.prototype.toString.call(params['bank']) === '[object Array]') {
          for (const item of params['bank']) {
            data.append('bank', item as any);
          }
        } else {
          data.append('bank', params['bank'] as any);
        }
      }

      if (params['bankNo']) {
        if (Object.prototype.toString.call(params['bankNo']) === '[object Array]') {
          for (const item of params['bankNo']) {
            data.append('bankNo', item as any);
          }
        } else {
          data.append('bankNo', params['bankNo'] as any);
        }
      }

      if (params['normalCardNo']) {
        if (Object.prototype.toString.call(params['normalCardNo']) === '[object Array]') {
          for (const item of params['normalCardNo']) {
            data.append('normalCardNo', item as any);
          }
        } else {
          data.append('normalCardNo', params['normalCardNo'] as any);
        }
      }

      if (params['normalBank']) {
        if (Object.prototype.toString.call(params['normalBank']) === '[object Array]') {
          for (const item of params['normalBank']) {
            data.append('normalBank', item as any);
          }
        } else {
          data.append('normalBank', params['normalBank'] as any);
        }
      }

      if (params['normalBankNo']) {
        if (Object.prototype.toString.call(params['normalBankNo']) === '[object Array]') {
          for (const item of params['normalBankNo']) {
            data.append('normalBankNo', item as any);
          }
        } else {
          data.append('normalBankNo', params['normalBankNo'] as any);
        }
      }

      if (params['authUrl']) {
        if (Object.prototype.toString.call(params['authUrl']) === '[object Array]') {
          for (const item of params['authUrl']) {
            data.append('authUrl', item as any);
          }
        } else {
          data.append('authUrl', params['authUrl'] as any);
        }
      }

      if (params['invoicePayeeName']) {
        if (Object.prototype.toString.call(params['invoicePayeeName']) === '[object Array]') {
          for (const item of params['invoicePayeeName']) {
            data.append('invoicePayeeName', item as any);
          }
        } else {
          data.append('invoicePayeeName', params['invoicePayeeName'] as any);
        }
      }

      if (params['invoiceReview']) {
        if (Object.prototype.toString.call(params['invoiceReview']) === '[object Array]') {
          for (const item of params['invoiceReview']) {
            data.append('invoiceReview', item as any);
          }
        } else {
          data.append('invoiceReview', params['invoiceReview'] as any);
        }
      }

      if (params['invoiceReviewPhone']) {
        if (Object.prototype.toString.call(params['invoiceReviewPhone']) === '[object Array]') {
          for (const item of params['invoiceReviewPhone']) {
            data.append('invoiceReviewPhone', item as any);
          }
        } else {
          data.append('invoiceReviewPhone', params['invoiceReviewPhone'] as any);
        }
      }

      if (params['invoiceDrawer']) {
        if (Object.prototype.toString.call(params['invoiceDrawer']) === '[object Array]') {
          for (const item of params['invoiceDrawer']) {
            data.append('invoiceDrawer', item as any);
          }
        } else {
          data.append('invoiceDrawer', params['invoiceDrawer'] as any);
        }
      }

      if (params['invoiceDrawerPhone']) {
        if (Object.prototype.toString.call(params['invoiceDrawerPhone']) === '[object Array]') {
          for (const item of params['invoiceDrawerPhone']) {
            data.append('invoiceDrawerPhone', item as any);
          }
        } else {
          data.append('invoiceDrawerPhone', params['invoiceDrawerPhone'] as any);
        }
      }

      if (params['invoiceQuota']) {
        if (Object.prototype.toString.call(params['invoiceQuota']) === '[object Array]') {
          for (const item of params['invoiceQuota']) {
            data.append('invoiceQuota', item as any);
          }
        } else {
          data.append('invoiceQuota', params['invoiceQuota'] as any);
        }
      }

      if (params['invoiceQuotaMonth']) {
        if (Object.prototype.toString.call(params['invoiceQuotaMonth']) === '[object Array]') {
          for (const item of params['invoiceQuotaMonth']) {
            data.append('invoiceQuotaMonth', item as any);
          }
        } else {
          data.append('invoiceQuotaMonth', params['invoiceQuotaMonth'] as any);
        }
      }

      if (params['taxPlateType']) {
        if (Object.prototype.toString.call(params['taxPlateType']) === '[object Array]') {
          for (const item of params['taxPlateType']) {
            data.append('taxPlateType', item as any);
          }
        } else {
          data.append('taxPlateType', params['taxPlateType'] as any);
        }
      }

      if (params['belongPlatform']) {
        if (Object.prototype.toString.call(params['belongPlatform']) === '[object Array]') {
          for (const item of params['belongPlatform']) {
            data.append('belongPlatform', item as any);
          }
        } else {
          data.append('belongPlatform', params['belongPlatform'] as any);
        }
      }

      if (params['companyType']) {
        if (Object.prototype.toString.call(params['companyType']) === '[object Array]') {
          for (const item of params['companyType']) {
            data.append('companyType', item as any);
          }
        } else {
          data.append('companyType', params['companyType'] as any);
        }
      }

      if (params['belongObject']) {
        if (Object.prototype.toString.call(params['belongObject']) === '[object Array]') {
          for (const item of params['belongObject']) {
            data.append('belongObject', item as any);
          }
        } else {
          data.append('belongObject', params['belongObject'] as any);
        }
      }

      if (params['relatedIndustry']) {
        if (Object.prototype.toString.call(params['relatedIndustry']) === '[object Array]') {
          for (const item of params['relatedIndustry']) {
            data.append('relatedIndustry', item as any);
          }
        } else {
          data.append('relatedIndustry', params['relatedIndustry'] as any);
        }
      }

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取url
   */
  static getAuthJinheGetFileUrl(options: IRequestOptions = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/auth/jinhe/getFileUrl';

      const configs: IRequestConfig = getConfigs('get', 'multipart/form-data', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class ResourcesControllerService {
  /**
   * 经纬度换详细地址和省市区code
   */
  static getI3060ResourceGetAddressInfoByXy(
    params: {
      /** 经度 */
      x?: string;
      /** 纬度 */
      y?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/resource/getAddressInfoByXy';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { x: params['x'], y: params['y'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 光伏发电小工具_装机容量、装机面积
   */
  static getI3060ResourceCalculateCapacityOrArea(
    params: {
      /** 装机面积 */
      availableArea?: string;
      /** 装机容量 */
      pvCapacity?: string;
      /** 0屋顶光伏；1山地光伏 */
      areaEffiParaType?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/i3060/resource/calculateCapacityOrArea';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        availableArea: params['availableArea'],
        pvCapacity: params['pvCapacity'],
        areaEffiParaType: params['areaEffiParaType']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}
