import { Collapse, Drawer, Space, Spin, Typography } from 'antd'
import React, { useEffect, useState } from 'react'
import { ProjectDocumentService } from '../../../../apis/wl-service'

export interface IFileProps {
  projectId: string
  from: string
  visible: boolean
  visibleChange: (visible: boolean) => void
}

const App: React.FC<IFileProps> = ({ projectId, from, visible, visibleChange }) => {
  const [loading, setLoading] = useState<boolean>(false) // 数据加载状态
  const [data, setData] = useState<any[]>([]) // 附件数据源

  // 获取附件
  useEffect(() => {
    if (!projectId || !visible) return

    setLoading(true)
    ProjectDocumentService.detail({ id: projectId })
      .then((resp: any) => {
        const list = resp?.data ?? []
        if (from === 'invest') {
          list.splice(3, 3)
        }
        setData(list)
      })
      .catch((e) => {
        console.warn(e)
      })
      .finally(() => {
        setLoading(false)
      })

    return () => {}
  }, [projectId, visible])

  return (
    <Spin spinning={loading}>
      <Drawer
        className="right-actions"
        title="附件"
        placement="right"
        bodyStyle={{ padding: 0 }}
        visible={visible}
        onClose={() => {
          visibleChange(false)
        }}
      >
        <Collapse bordered={false} defaultActiveKey={[]}>
          {data.map((item: any, index: number) => {
            return (
              <Collapse.Panel key={index} header={item.name}>
                {item.documentJson?.length > 0 ? (
                  <Space direction="vertical" wrap={false}>
                    {item.documentJson.map((itema: any, indexa: number) => {
                      return (
                        <Typography.Text key={indexa} style={{ display: 'block' }}>
                          {indexa + 1}.&nbsp; {itema.fileName}
                        </Typography.Text>
                      )
                    })}
                  </Space>
                ) : (
                  <Typography.Text>暂无附件</Typography.Text>
                )}
              </Collapse.Panel>
            )
          })}
        </Collapse>
      </Drawer>
    </Spin>
  )
}

export default App
