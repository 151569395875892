import React, { useEffect } from 'react'
import { Modal, Form, Input, Select, message } from 'antd'
import { ResourceService, ResponseOptionType } from '../../../apis/wl-service'
const { TextArea } = Input

export interface ModifyApplyModalProps {
  visible: boolean
  title?: string
  moduleId: string
  close: () => void
  success?: () => void
}

export default ({ visible, moduleId, title, close, success }: ModifyApplyModalProps) => {
  const [form] = Form.useForm()
  const [loading, setLoading] = React.useState<boolean>(false)
  const [options, setOptions] = React.useState<ResponseOptionType[]>([])

  useEffect(() => {
    if (visible) {
      ResourceService.loadSelfProject()
        .then((resp: any) => {
          setOptions(resp?.data ?? [])
        })
        .catch((err: any) => {
          console.warn(err)
        })
    }
  }, [visible])

  const handleOk = () => {
    if (loading) {
      return
    }

    form
      .validateFields()
      .then((values: any) => {
        setLoading(true)
        values.moduleId = moduleId
        ResourceService.applyUpdate(values)
          .then((resp: any) => {
            if (resp?.code === 200) {
              message.success(resp.msg)
              close()
              success?.()
            } else if (resp?.msg) {
              message.error(resp.msg)
            }
          })
          .catch((err: any) => {
            console.warn(err)
          })
          .finally(() => {
            setLoading(false)
          })
      })
      .catch((err) => {
        console.warn(err)
      })
  }

  return (
    <Modal
      keyboard={false}
      maskClosable={false}
      title={title ?? '请选择项目后，申请修改'}
      visible={visible}
      width={640}
      okButtonProps={{
        loading,
      }}
      onCancel={close}
      onOk={handleOk}
    >
      <Form form={form} labelCol={{ span: 4 }} wrapperCol={{ span: 20 }} labelWrap>
        <Form.Item
          label="项目名称"
          name="project_id"
          rules={[
            {
              required: true,
              message: '请选择项目名称',
            },
          ]}
        >
          <Select
            allowClear
            placeholder="请选择项目名称"
            optionFilterProp="children"
            // onSearch={onSearch}
            filterOption={(input, option) =>
              (option!.children as unknown as string).toLowerCase().includes(input.toLowerCase())
            }
          >
            {options.map((v) => (
              <Select.Option key={v.id}>{v.fullName}</Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          label="申请说明:"
          name="apply_memo"
          rules={[
            {
              required: true,
              message: '请输入申请说明',
            },
          ]}
        >
          <TextArea allowClear showCount maxLength={50} autoSize={{ minRows: 3 }} placeholder="请输入申请说明" />
        </Form.Item>
      </Form>
    </Modal>
  )
}
