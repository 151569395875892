import React, { useEffect, useState } from 'react'
import {
  Form,
  Input,
  InputNumber,
  Table,
  Button,
  InputProps,
  InputNumberProps,
  DatePicker,
  DatePickerProps,
  FormItemProps,
} from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import { omit } from 'lodash'

export const DynamicCell: React.FC<any> = ({
  children,
  dataIndex,
  editing,
  field,
  inputType,
  title,
  componentProps,
  rules,
  onChange,
  ...restProps
}) => {
  let inputNode
  switch (inputType) {
    case 'number':
      inputNode = (
        <InputNumber style={{ width: '100%' }} min={0} {...(componentProps as InputNumberProps)} onBlur={onChange} />
      )
      break

    case 'datepicker':
      inputNode = (
        <DatePicker
          placeholder=""
          style={{ width: '100%' }}
          {...(componentProps as DatePickerProps)}
          onChange={onChange}
        />
      )
      break

    default:
      inputNode = <Input allowClear {...(componentProps as InputProps)} onBlur={onChange} />
  }
  return (
    <td {...omit(restProps, ['onCell'])}>
      {editing ? (
        <Form.Item
          {...field}
          name={[field.name, dataIndex]}
          style={{ margin: 0 }}
          rules={
            rules ?? [
              {
                required: false,
                message: `请填写 ${title}!`,
              },
            ]
          }
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  )
}

export interface DynamicColumnType<RecordType> {
  dataIndex?: string
  render?: (text: any, record: RecordType, index: number) => void
  title?: string
  width?: number | string
  editable?: boolean
  inputType?: 'input' | 'number' | 'datepicker'
  componentProps?: InputProps | InputNumberProps | DatePickerProps
  rules?: FormItemProps['rules']
  children?: DynamicColumnType<RecordType>[]
}

export interface DynamicTableProps<RecordType> {
  editable: boolean
  name?: string
  columns: DynamicColumnType<RecordType>[]
  value?: RecordType[]
  addText?: string
  onChange?: (value: RecordType[]) => void
}
export default ({ value, onChange, editable, name, columns, addText }: DynamicTableProps<any>) => {
  const [form] = Form.useForm()
  const [data, setData] = useState<any[]>([])

  // 更新列表值
  useEffect(() => {
    if (editable) {
      form.setFieldsValue({ [name ?? 'tableForm']: value ?? [] })
    } else {
      setData(value?.map((item, i) => ({ key: i, ...(item ?? {}) })) ?? [])
    }
  }, [value])

  const emitChange = () => {
    const values = form.getFieldsValue()
    onChange?.(values[name ?? 'tableForm'].map((v: any) => ({ ...(v ?? {}) })))
  }

  const mergedColumns = (columns: any[]): any[] => {
    return (columns as any).map((col: any) => {
      col.align = 'center'
      if (editable && Array.isArray(col.children)) {
        col.children = mergedColumns(col.children)
      }

      if (!editable || !col.editable) {
        return col
      }

      const { editable: e, ...props } = col
      return {
        ...col,
        onCell: (record: any) => {
          return {
            ...props,
            editing: e,
            field: record,
            onChange: emitChange,
          }
        },
      }
    })
  }

  return (
    <Form form={form} component={false}>
      <Form.List name={name ?? 'tableForm'}>
        {(fields, { add, remove }) => {
          return (
            <>
              <Form.Item>
                <Table
                  components={{
                    body: { cell: DynamicCell },
                  }}
                  bordered
                  locale={{ emptyText: '暂无数据' }}
                  size="small"
                  rowKey="key"
                  dataSource={editable ? fields : data}
                  columns={mergedColumns(columns).concat(
                    editable
                      ? {
                          title: '操作',
                          width: 100,
                          dataIndex: 'operation',
                          align: 'center',
                          render: (text: any, record: any) => {
                            return editable ? (
                              <Button
                                type="text"
                                danger
                                onClick={() => {
                                  remove(record.name)
                                  emitChange()
                                }}
                              >
                                删除
                              </Button>
                            ) : null
                          },
                        }
                      : []
                  )}
                  className="bordered-table"
                  rowClassName="editable-row"
                  pagination={false}
                />
              </Form.Item>
              {editable && (
                <Form.Item>
                  <Button
                    block
                    icon={<PlusOutlined />}
                    type="dashed"
                    onClick={() => {
                      add()
                      emitChange()
                    }}
                  >
                    {addText ?? '新增'}
                  </Button>
                </Form.Item>
              )}
            </>
          )
        }}
      </Form.List>
    </Form>
  )
}
