import _axios from 'axios'
import { IRequestOptions, IRequestConfig, getConfigs, axios } from './serviceOptions'

export const basePath = '/api/project'

export type ResponseOptionType = {
  id: string
  fullName: string
}

// 公共服务模块 start **************************************************************************************************
// 代码生成器数据匹配
export function dynamicText(value: any, options: { id: string; fullName: string }[]) {
  if (Array.isArray(value)) {
    if (!options || !Array.isArray(options)) return value.join()
    let textList = []
    for (let i = 0; i < value.length; i++) {
      let item = options.find(o => o.id == value[i])
      if (!item || !item.fullName) {
        textList.push(value[i])
      } else {
        textList.push(item.fullName)
      }
    }
    return textList.join()
  }
  if (!options || !Array.isArray(options)) return value
  return options.find((o) => o.id == value)?.fullName ?? value
}

export class CommonService {
  // 获取某个字典数据下拉框列表
  static getDictionaryDataSelector(dictionaryTypeId: any, options: IRequestOptions = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + `/Base/DictionaryData/${dictionaryTypeId}/Data/Selector`

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      configs.data = null;
      axios(configs, resolve, reject);
    });
  }

  // 获取数据字典列表
  static getDictionaryData(dictionaryTypeId: any, options: IRequestOptions = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + `/Base/DictionaryData/${dictionaryTypeId}`

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      configs.data = null;
      axios(configs, resolve, reject);
    });
  }


  // 获取某个字典数据下拉框列表有效的
  static getDictionaryDataSelectorValid(dictionaryTypeId: any, options: IRequestOptions = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + `/Base/DictionaryData/${dictionaryTypeId}/Data/SelectorValid`

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      configs.data = null;
      axios(configs, resolve, reject);
    });
  }
}

// 行政区划
export class ProvinceService {
  // 获取行政区划下拉框数据
  static getSelector(id: any, options: IRequestOptions = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + `/Base/Area/${id}/Selector`

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      configs.data = null;
      axios(configs, resolve, reject);
    });
  }
}

// 公共服务模块 end **************************************************************************************************

// 附件管理 start **************************************************************************************************
export class FileService {
  static path = (url?: string) => `${_axios.defaults.baseURL ?? location.origin}${url ?? ''}`

  // 删除文件
  static remove(
    params: {
      path: string;
      root?: object;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      const url = `/api/fileserver/energy-file?path=${params.path}`

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      configs.data = params['root'] ?? null;
      axios(configs, resolve, reject);
    });
  }

  // 上传文件/图片
  static upload = (): string => FileService.path() + `/api/fileserver/energy-file/Uploader`

  // 下载文件
  static download = (path: string): void => {
    window.location.href = FileService.path() + `/api/fileserver/energy-file/downLoad?path=${path}`
  }

  // 附件批量下载-zip包
  static zip = (json: string): void => {
    const a: any = document.createElement('a')
    a.download = true
    a.target = '_self'
    a.href = FileService.path() + `/api/fileserver/energy-file/downLoad/batch?json=${json}`
    a.click()
  }

  // 附件批量下载-zip包
  static zipCollection = (id: string): void => {
    if (!id) return

    const a: any = document.createElement('a')
    a.download = true
    a.target = '_self'
    a.href = FileService.path(`/api/fileserver/energy-file/collection/zip?id=${id}`)
    a.click()
  }

  // 预览文件
  static preview(
    params: {
      path: string; // required
      root?: object;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      const url = `/api/fileserver/energy-file/preview?path=${params.path}`

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      configs.data = params['root'] ?? null;
      axios(configs, resolve, reject);
    });
  }
}

// 附件管理 end **************************************************************************************************


// 费用管理模块 start **************************************************************************************************
export class FeeService {
  // 列表查询-费用申请
  static applyList(
    params: {
      create_time?: string; // 用款申请日期开始
      create_end_time?: string; // 用款申请日期结束
      contract_no?: string; // 合同号
      apply_for_no?: string // 用款申请单号
      company_name?: string; // 用款公司
      use_amount_type_id?: string; // 用款类型 4 支付货款-银联户 2 支付货款-非平台公司户
      projectCode?: string; // 项目代码
      projectName?: string; // 项目名称
      status?: string; // 申请状态 0 申请中 1 申请驳回 2 解冻失败 3 已完成
      page: number; // 当前页
      pageSize: number; // 每页条数
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      const url = basePath + `/Energy_fee/applyList`;

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      const { page, pageSize, ...args } = params
      configs.params = Object.assign({
        page_index: page ?? 1,
        page_size: pageSize ?? 20,
      }, args);

      configs.data = null;

      axios(configs, resolve, reject);
    });
  }

  // 费用申请-明细查询
  static applyDetail(
    params: {
      id: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      const url = basePath + `/Energy_fee/apply/detail/${params.id}`;

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      configs.data = null;

      axios(configs, resolve, reject);
    });
  }

  // 费用申请-日志查询
  static applyLogs(
    params: {
      id: string;
      page?: number;
      pageSize?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      const url = basePath + `/Energy_fee/apply/logs`;

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      const { page, pageSize, ...args } = params
      configs.params = Object.assign({
        id: params.id,
        page_index: page ?? 1,
        page_size: pageSize ?? 20,
      }, args);

      configs.data = null;

      axios(configs, resolve, reject);
    });
  }

  // 列表查询-费用支付
  static payList(
    params: {
      contract_start_date?: string; // 日期开始
      contract_sn?: string; // 合同号
      contract_end_date?: string; // 日期结束
      pay_work_no?: string // 支付单号
      projectCode?: string; // 项目代码
      projectName?: string; // 项目名称
      status?: string; // 支付状态 0待审核 1待支付 2支付中 3支付成功 4支付失败 5已回款 6审核拒绝
      page: number; // 当前页
      pageSize: number; // 每页条数
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      const url = basePath + `/Energy_fee/payList`;

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      const { page, pageSize, ...args } = params
      configs.params = Object.assign({
        page_index: page ?? 1,
        page_size: pageSize ?? 20,
      }, args);

      configs.data = null;

      axios(configs, resolve, reject);
    });
  }

  // 费用支付-明细查询
  static payDetail(
    params: {
      pay_work_id: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      const url = basePath + `/Energy_fee/pay/detail`;

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { pay_work_id: params.pay_work_id }

      configs.data = null;

      axios(configs, resolve, reject);
    });
  }

  // 费用支付-日志查询
  static payLogs(
    params: {
      pay_work_id: string;
      page?: number;
      pageSize?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      const url = basePath + `/Energy_fee/pay/logs`;

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      const { page, pageSize, ...args } = params
      configs.params = Object.assign({
        pay_work_id: params.pay_work_id,
        page_index: page ?? 1,
        page_size: pageSize ?? 20,
      }, args);

      configs.data = null;

      axios(configs, resolve, reject);
    });
  }

  // 费用-导出
  static export(
    params: {
      code: number; // 0 支付，1 申请
      ids: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      const url = basePath + `/Energy_fee/export?code=${params.code}&ids=${params.ids}`;

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      configs.data = null;

      axios(configs, resolve, reject);
    });
  }
}

// 费用管理模块 end **************************************************************************************************

// 投资开发模块 start **************************************************************************************************
export class InvestService {
  // 工作台-统计查询
  static workbench(
    params: {
      id: string;
      root?: object;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      const url = basePath + `/Workbench`

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      configs.data = params['root'] ?? null;
      axios(configs, resolve, reject);
    });
  }

  // 工作台-签约情况
  static lineChart(
    params: {
      beginTime?: string; // 开始时间
      endTme?: string  // 截止时间
      dateType?: number | string //时间类型 1: 今日 2: 本周 3：本月 4：全年
      type?: number // 类型 1: 合作伙伴签约 2: 客户签约
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      const url = basePath + `/Workbench/lineChart`

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      configs.params = Object.assign({
        type: 1,
      }, params);

      configs.data = null;
      axios(configs, resolve, reject);
    });
  }
}

// 投资开发模块 end **************************************************************************************************

// 合同审批模块 start **************************************************************************************************
export class ContractService {
  // 获取列表
  static list(
    params: {
      stem?: number; // 合同来源 - 0 投资开发 1 工程管理
      contractType?: string // 合同类型
      contractNumber?: string; // 合同编号
      sort: string; // asc  desc
      sidx: string;
      page: number; // 当前页
      pageSize: number; // 每页条数
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      const url = basePath + `/Energy_contract`;

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      const { page, ...args } = params
      configs.params = Object.assign({
        sort: 'desc',
        sidx: '',
        currentPage: page ?? 1,
        pageSize: 20,
      }, args);

      configs.data = null;

      axios(configs, resolve, reject);
    });
  }

  // 删除
  static remove(
    params: {
      id: string;
      root?: object;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      const url = basePath + `/Energy_contract/${params.id}`

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      configs.data = params['root'] ?? null;
      axios(configs, resolve, reject);
    });
  }

  // 合同操作，（作废，提交，撤回）
  static stateUpdate(
    params: {
      id: string; // 合同ID
      type: number; // 操作类型 1: 提交 2: 作废 3：撤回
      root?: object;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      const url = basePath + `/Energy_contract/state/update/${params.id}/${params.type}`

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      configs.data = params['root'] ?? null;
      axios(configs, resolve, reject);
    });
  }

  // 合同类型列表
  static typeList(
    params: {
      contractIds: string | number
      root?: object;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      const url = basePath + `/Energy_contract/typeList`

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      configs.data = params['root'] ?? null;
      axios(configs, resolve, reject);
    });
  }

  // 获取详情
  static info(
    params: {
      id: string;
      root?: object;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      const url = basePath + `/Energy_contract/${params.id}`

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      configs.data = params['root'] ?? null;
      axios(configs, resolve, reject);
    });
  }

  // 催一下
  static urge(
    params: {
      code: string;
      root?: object;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      const url = basePath + `/Energy_contract/urge/${params.code}`

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      configs.data = params['root'] ?? null;
      axios(configs, resolve, reject);
    });
  }

  // 查看合同状态
  static state(
    params: {
      id: string;
      root?: object;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      const url = basePath + `/Energy_contract/state/${params.id}`

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      configs.data = params['root'] ?? null;
      axios(configs, resolve, reject);
    });
  }

  // 合同基础数据模型
  static basicData(
    params: {
      code?: number; // 0 投资开发模块 1 工程管理模块
      root?: object;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      const url = basePath + `/Energy_contract/basicData/two`

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      configs.data = { code: params.code ?? 0 }
      axios(configs, resolve, reject);
    });
  }

  // 新建/编辑 
  static update(
    params: {
      id?: string;
      root?: object;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/Energy_contract'
      if (params['id']) {
        url = basePath + '/Energy_contract/' + params['id']
      }
      const configs: IRequestConfig = getConfigs(params['id'] ? 'put' : 'post', 'application/json', url, options);

      configs.data = params['root'] ?? null;
      axios(configs, resolve, reject);
    });
  }
}

// 合同审批模块 end **************************************************************************************************


// 合作方模块 start **************************************************************************************************
export class ProjectPartnerService {
  // 获取列表
  static list(
    params: {
      type?: number;// 合作方类型 0:企业;1:个人
      name?: string;
      sort?: string; // asc  desc
      sidx?: string;
      page?: number; // 当前页
      pageSize?: number; // 每页条数
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      const url = basePath + `/energy_project_partner/all`;

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      const { page, ...args } = params
      configs.params = Object.assign({
        type: 0,
        currentPage: page ?? 1,
        pageSize: 20,
        sort: 'desc',
      }, args)

      configs.data = null;
      axios(configs, resolve, reject);
    });
  }

  // 删除
  static remove(
    params: {
      id: string;
      root?: object;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      const url = basePath + `/energy_project_partner/${params.id}`

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      configs.data = params['root'] ?? null;
      axios(configs, resolve, reject);
    });
  }

  // 新建/编辑 
  static update(
    params: {
      id?: string;
      root?: object;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/energy_project_partner'
      if (params['id']) {
        url = basePath + '/energy_project_partner/' + params['id']
      }
      const configs: IRequestConfig = getConfigs(params['id'] ? 'put' : 'post', 'application/json', url, options);

      configs.data = params['root'] ?? null;
      axios(configs, resolve, reject);
    });
  }
}

// 合作方模块 end **************************************************************************************************


// 计划执行模块 start **************************************************************************************************
export class PlanExecuteService {
  // 获取列表
  static list(
    params: {
      project_name?: string; // 项目名称
      sort: string; // asc  desc
      sidx: string;
      page: number; // 当前页
      pageSize: number; // 每页条数
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      const url = basePath + `/energy_plan_execute`;

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      const { page, ...args } = params
      configs.params = Object.assign({
        sort: 'desc',
        sidx: '',
        currentPage: page ?? 1,
        pageSize: 20,
      }, args);

      configs.data = null;

      axios(configs, resolve, reject);
    });
  }

  // 删除
  static remove(
    params: {
      id: string;
      root?: object;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      const url = basePath + `/energy_plan_execute/${params.id}`

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      configs.data = params['root'] ?? null;
      axios(configs, resolve, reject);
    });
  }

  // 获取详情
  static info(
    params: {
      id: string;
      root?: object;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      const url = basePath + `/energy_plan_execute/${params.id}`

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      configs.data = params['root'] ?? null;
      axios(configs, resolve, reject);
    });
  }

  // 获取草稿
  static draft(
    params: {
      id?: string;
      root?: object;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      const url = basePath + `/energy_plan_execute/draft`

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      configs.data = params['root'] ?? null;
      axios(configs, resolve, reject);
    });
  }


  // 新建/编辑 
  static update(
    params: {
      id?: string;
      root?: object;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/energy_plan_execute'
      let method = 'post'
      if (params['id']) {
        url += `/${params['id']}`
        method = 'put'
      }
      const configs: IRequestConfig = getConfigs(method, 'application/json', url, options);

      configs.data = params['root'] ?? null;
      axios(configs, resolve, reject);
    });
  }
}

// 计划执行模块 end **************************************************************************************************


// 投资计划模块 start **************************************************************************************************
export class InvestPlanService {
  // 获取列表
  static list(
    params: {
      project_name?: string; // 项目名称
      sort: string; // asc  desc
      sidx: string;
      page: number; // 当前页
      pageSize: number; // 每页条数
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      const url = basePath + `/Energy_invest_plan`;

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      const { page, ...args } = params
      configs.params = Object.assign({
        sort: 'desc',
        sidx: '',
        currentPage: page ?? 1,
        pageSize: 20,
      }, args);

      configs.data = null;

      axios(configs, resolve, reject);
    });
  }

  // 删除
  static remove(
    params: {
      id: string;
      root?: object;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      const url = basePath + `/Energy_invest_plan/${params.id}`

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      configs.data = params['root'] ?? null;
      axios(configs, resolve, reject);
    });
  }

  // 获取详情
  static info(
    params: {
      id: string;
      root?: object;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      const url = basePath + `/Energy_invest_plan/${params.id}`

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      configs.data = params['root'] ?? null;
      axios(configs, resolve, reject);
    });
  }

  // 获取草稿
  static draft(
    params: {
      id?: string;
      root?: object;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      const url = basePath + `/Energy_invest_plan/draft`

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      configs.data = params['root'] ?? null;
      axios(configs, resolve, reject);
    });
  }

  // 新建/编辑 
  static update(
    params: {
      id?: string;
      root?: object;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/Energy_invest_plan'
      let method = 'post'
      if (params['id']) {
        url += `/${params['id']}`
        method = 'put'
      }
      const configs: IRequestConfig = getConfigs(method, 'application/json', url, options);

      configs.data = params['root'] ?? null;
      axios(configs, resolve, reject);
    });
  }
}
// 投资计划模块 end **************************************************************************************************


// 项目造价模块 start **************************************************************************************************
export class CostService {
  // 获取列表
  static list(
    params: {
      project_id?: string; // 项目id
      project_name?: string; // 项目名称
      startyear?: string // 开始年份
      endyear?: string // 结束年份
      resourcedependency?: string; // 资源属地
      resourcemodule?: string // 板块
      resourcetype?: string; // 类型
      sort: string; // asc  desc
      sidx: string;
      page: number; // 当前页
      pageSize: number; // 每页条数
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      const url = basePath + `/Energy_cost`;

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      const { page, ...args } = params
      configs.params = Object.assign({
        sort: 'desc',
        sidx: '',
        currentPage: page ?? 1,
        pageSize: 20,
      }, args);

      configs.data = null;

      axios(configs, resolve, reject);
    });
  }

  // 删除
  static remove(
    params: {
      id: string;
      root?: object;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      const url = basePath + `/Energy_cost/${params.id}`

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      configs.data = params['root'] ?? null;
      axios(configs, resolve, reject);
    });
  }

  // 获取详情
  static info(
    params: {
      id: string;
      root?: object;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      const url = basePath + `/Energy_cost/${params.id}`

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      configs.data = params['root'] ?? null;
      axios(configs, resolve, reject);
    });
  }

  // 获取草稿
  static draft(
    params: {
      root?: object;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      const url = basePath + '/Energy_cost/draft'

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      configs.data = params['root'] ?? null;
      axios(configs, resolve, reject);
    });
  }

  // 新建/编辑 
  static update(
    params: {
      id?: string;
      root?: object;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/Energy_cost'
      let method = 'post'
      if (params['id']) {
        url += `/${params['id']}`
        method = 'put'
      }
      const configs: IRequestConfig = getConfigs(method, 'application/json', url, options);

      configs.data = params['root'] ?? null;
      axios(configs, resolve, reject);
    });
  }
}

// 项目造价模块 end **************************************************************************************************


// 建设模块 start **************************************************************************************************
export class ProjectProductionService {
  // 获取列表
  static list(
    params: {
      project_name?: string; // 项目名称
      important_level?: string; // 重要程度
      sort: string; // asc  desc
      sidx: string;
      page: number; // 当前页
      pageSize: number; // 每页条数
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      const url = basePath + `/Energy_project_production`;

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      const { page, ...args } = params
      configs.params = Object.assign({
        sort: 'desc',
        sidx: '',
        currentPage: page ?? 1,
        pageSize: 20,
      }, args);

      configs.data = null;

      axios(configs, resolve, reject);
    });
  }

  // 删除
  static remove(
    params: {
      id: string;
      root?: object;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      const url = basePath + `/Energy_project_production/${params.id}`

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      configs.data = params['root'] ?? null;
      axios(configs, resolve, reject);
    });
  }

  // 获取详情
  static info(
    params: {
      id: string;
      root?: object;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      const url = basePath + `/Energy_project_production/${params.id}`

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      configs.data = params['root'] ?? null;
      axios(configs, resolve, reject);
    });
  }

  // 获取草稿
  static draft(
    params: {
      id?: string;
      root?: object;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      const url = basePath + `/Energy_project_production/draft`

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      configs.data = params['root'] ?? null;
      axios(configs, resolve, reject);
    });
  }

  // 新建/编辑 
  static update(
    params: {
      id?: string;
      root?: object;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/Energy_project_production'
      let method = 'post'
      if (params['id']) {
        url += `/${params['id']}`
        method = 'put'
      }
      const configs: IRequestConfig = getConfigs(method, 'application/json', url, options);

      configs.data = params['root'] ?? null;
      axios(configs, resolve, reject);
    });
  }
}

// 建设模块 end **************************************************************************************************


// 投资决策模块 start **************************************************************************************************
export class InvestDecisionService {
  // 获取列表
  static list(
    params: {
      project_name?: string; // 项目名称
      decision_organization?: string; // 投资机构
      sort: string; // asc  desc
      sidx: string;
      page: number; // 当前页
      pageSize: number; // 每页条数
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      const url = basePath + `/Energy_invest_decision`;

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      const { page, ...args } = params
      configs.params = Object.assign({
        sort: 'desc',
        sidx: '',
        currentPage: page ?? 1,
        pageSize: 20,
      }, args);

      configs.data = null;

      axios(configs, resolve, reject);
    });
  }

  // 删除
  static remove(
    params: {
      id: string;
      root?: object;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      const url = basePath + `/Energy_invest_decision/${params.id}`

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      configs.data = params['root'] ?? null;
      axios(configs, resolve, reject);
    });
  }

  // 获取详情
  static info(
    params: {
      projectId: string;
      root?: object;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      const url = basePath + `/Energy_invest_decision/${params.projectId}`

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      configs.data = params['root'] ?? null;
      axios(configs, resolve, reject);
    });
  }

  // 获取草稿
  static draft(
    params: {
      id?: string;
      root?: object;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      const url = basePath + `/Energy_invest_decision/draft`

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      configs.data = params['root'] ?? null;
      axios(configs, resolve, reject);
    });
  }

  // 新建/编辑 
  static update(
    params: {
      id?: string;
      root?: object;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/Energy_invest_decision'
      let method = 'post'
      if (params['id']) {
        url += `/${params['id']}`
        method = 'put'
      }
      const configs: IRequestConfig = getConfigs(method, 'application/json', url, options);

      configs.data = params['root'] ?? null;
      axios(configs, resolve, reject);
    });
  }
}

// 投资决策模块 end **************************************************************************************************


// 核准备案模块 start **************************************************************************************************
export class ProjectRecordService {
  // 获取列表
  static list(
    params: {
      project_name?: string; // 项目名称
      startyear?: string;
      endyear?: string;
      important_level?: string;
      resourcedependency?: string; // 资源属地
      sort: string; // asc  desc
      sidx: string;
      page: number; // 当前页
      pageSize: number; // 每页条数
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      const url = basePath + `/Energy_project_record`;

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      const { page, ...args } = params
      configs.params = Object.assign({
        sort: 'desc',
        sidx: '',
        currentPage: page ?? 1,
        pageSize: 20,
      }, args);

      configs.data = null;

      axios(configs, resolve, reject);
    });
  }

  // 删除
  static remove(
    params: {
      id: string;
      root?: object;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      const url = basePath + `/Energy_project_record/${params.id}`

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      configs.data = params['root'] ?? null;
      axios(configs, resolve, reject);
    });
  }

  // 获取详情
  static info(
    params: {
      projectId: string;
      root?: object;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      const url = basePath + `/Energy_project_record/${params.projectId}`

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      configs.data = params['root'] ?? null;
      axios(configs, resolve, reject);
    });
  }

  // 获取草稿
  static draft(
    params: {
      id?: string;
      root?: object;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      const url = basePath + `/Energy_project_record/draft`

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      configs.data = params['root'] ?? null;
      axios(configs, resolve, reject);
    });
  }

  // 新建/编辑 
  static update(
    params: {
      id?: string;
      root?: object;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/Energy_project_record'
      if (params['id']) {
        url = basePath + '/Energy_project_record/' + params['id']
      }
      const configs: IRequestConfig = getConfigs(params['id'] ? 'put' : 'post', 'application/json', url, options);

      configs.data = params['root'] ?? null;
      axios(configs, resolve, reject);
    });
  }
}

// 核准备案模块 end **************************************************************************************************


// 资源开发模块 start **************************************************************************************************
export class ResourceService {
  // 获取列表
  static list(
    params: {
      project_name?: string; // 项目名称
      resourcetype?: string; // 项目类型
      startyear?: string;
      endyear?: string;
      // resourcemodule?: string; // 所属板块
      resourcedependency?: string; // 资源属地
      developer?: string; // 开发主体
      sort: string; // asc  desc
      sidx: string;
      page: number; // 当前页
      pageSize: number; // 每页条数
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      const url = basePath + `/Energy_resource_develop`;

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      const { page, ...args } = params
      configs.params = Object.assign({
        sort: 'desc',
        sidx: '',
        currentPage: page ?? 1,
        pageSize: 20,
      }, args);

      configs.data = null;
      axios(configs, resolve, reject);
    });
  }

  // 项目库项目列表
  static developList(
    params = {} as any, // eslint-disable-line
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      const url = basePath + `/Energy_resource_develop/depot/developList`;

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      configs.data = null;
      axios(configs, resolve, reject);
    });
  }

  // 项目库照片
  static depotPhoto(
    params: {
      id: string
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      const url = basePath + `/Energy_resource_develop/depot/photo/${params.id}`;

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      configs.data = null;
      axios(configs, resolve, reject);
    });
  }


  // 项目库列表
  static depotList(
    params: {
      project_name?: string; // 项目名称
      resourcetype?: string; // 项目类型
      startyear?: string;
      endyear?: string;
      resourcedependency?: string; // 资源属地
      developer?: string; // 开发主体
      sort: string; // asc  desc
      sidx: string;
      page: number; // 当前页
      pageSize: number; // 每页条数
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      const url = basePath + `/Energy_resource_develop/depotList`;

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      const { page, ...args } = params
      configs.params = Object.assign({
        sort: 'desc',
        sidx: '',
        currentPage: page ?? 1,
        pageSize: 20,
      }, args);

      configs.data = null;
      axios(configs, resolve, reject);
    });
  }


  // 删除
  static remove(
    params: {
      id: string;
      root?: object;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      const url = basePath + `/Energy_resource_develop/${params.id}`

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      configs.data = params['root'] ?? null;
      axios(configs, resolve, reject);
    });
  }

  // 根据组织找到所有项目
  static getUserParentOrganize(
    params: {
      root?: object;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      const url = basePath + `/Energy_resource_develop/getUserParentOrganize`

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      configs.data = params['root'] ?? null;
      axios(configs, resolve, reject);
    });
  }

  // 校验项目名称是否重复
  static createCheck(
    params: {
      projectName: string;
      root?: object;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      const url = basePath + `/Energy_resource_develop/module/create/check`

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { projectName: params.projectName }

      configs.data = params['root'] ?? null;
      axios(configs, resolve, reject);
    });
  }

  // 该资源已有集团投资主体，请联系集团
  static getSameProject(
    params: {
      id: string;
      resourcedependency: string;
      resourcetype: string;
      root?: object;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      const url = basePath + `/Energy_resource_develop/getSameProject/${params.resourcedependency}/${params.resourcetype}/${params.id}`

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      configs.data = params['root'] ?? null;
      axios(configs, resolve, reject);
    });
  }

  // 审核
  static verifyData(
    params: {
      id: string;
      state: number;
      root?: object;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      const url = basePath + `/Energy_resource_develop/verifyData/${params.id}/${params.state}`

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      configs.data = params['root'] ?? null;
      axios(configs, resolve, reject);
    });
  }


  // 申请终止
  static applyStop(
    params: {
      project_id: string;
      apply_memo: string;
      moduleId?: string;
      root?: object;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      const url = basePath + `/Energy_resource_develop/applyStop/${params.project_id}/${params.apply_memo}/${params.moduleId ?? 1}`

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      configs.data = params['root'] ?? null;
      axios(configs, resolve, reject);
    });
  }


  // 申请修改
  static applyUpdate(
    params: {
      project_id: string;
      apply_memo: string;
      moduleId?: string;
      root?: object;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      const url = basePath + `/Energy_resource_develop/applyUpdate/${params.project_id}/${params.apply_memo}/${params.moduleId ?? 1}`

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      configs.data = params['root'] ?? null;
      axios(configs, resolve, reject);
    });
  }

  // 获取本人创建的项目
  static loadSelfProject(
    params: {
      root?: object;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      const url = basePath + `/Energy_resource_develop/loadSelfProject`

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      configs.data = params['root'] ?? null;
      axios(configs, resolve, reject);
    });
  }

  // 获取资源开发+各子表的信息
  static getAllInfo(
    params: {
      projectId: string;
      root?: object;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      const url = basePath + `/Energy_resource_develop/getAllInfo/${params['projectId']}`

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      configs.data = params['root'] ?? null;
      axios(configs, resolve, reject);
    });
  }

  // 各模块提交接口
  static commit(
    params: {
      id: string;
      dataId: string;
      moduleId: string;
      root?: object;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      const url = basePath + `/Energy_resource_develop/submitData/${params.id}/${params.dataId}/${params.moduleId}`

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      configs.data = params['root'] ?? null;
      axios(configs, resolve, reject);
    });
  }

  // 导出Excel
  static createInformationTable(
    params: {
      id: string; // 项目id
    } = {} as any,
  ): void {
    const url = basePath + `/Energy_resource_develop/createInformationTable/${params.id}`
    window.location.href = FileService.path(url)
  }

  // 导出Excel
  static exportAllData(
    params: {
      project_name?: string; // 项目名称
      resourcetype?: string; // 项目类型
      // resourcemodule?: string; // 所属板块
      resourcedependency?: string; // 资源属地
      developer?: string; // 开发主体
      sort: string; // asc  desc
      sidx: string;
      page: number; // 当前页
      pageSize: number; // 每页条数
      dataType: string, // 下载类型 0当前页面数据,1勾选数据
      selectKey: string,
      ids?: string // 选中的列表id 
    } = {} as any,
  ): any {
    const { page, ...args } = params

    const paramsUrl = Object.entries(Object.assign({
      currentPage: page ?? 1,
      pageSize: 20,
      sort: 'desc',
      sidx: '',
    }, args)).reduce((str: string, cur: any[]) => {
      str += `&${cur[0]}=${cur[1]}`
      return str
    }, '')

    const url = basePath + '/Energy_resource_develop/Actions/ExportAllData'
    window.location.href = FileService.path(url + `?${paramsUrl.substring(1)}`)
  }

  // 新建/编辑 
  static update(
    params: {
      id?: string;
      root?: object;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/Energy_resource_develop'
      if (params['id']) {
        url = basePath + '/Energy_resource_develop/' + params['id']
      }
      const configs: IRequestConfig = getConfigs(params['id'] ? 'put' : 'post', 'application/json', url, options);

      configs.data = params['root'] ?? null;
      axios(configs, resolve, reject);
    });
  }

  // 获取草稿
  static draft(
    params: {
      id?: string;
      root?: object;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      const url = basePath + `/Energy_resource_develop/draft`

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      configs.data = params['root'] ?? null;
      axios(configs, resolve, reject);
    });
  }

  // 获取详情
  static info(
    params: {
      id: string;
      root?: object;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      const url = basePath + `/Energy_resource_develop/${params.id}`

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      configs.data = params['root'] ?? null;
      axios(configs, resolve, reject);
    });
  }
}

// 资源开发模块 end **************************************************************************************************


// 收资信息模块 start **************************************************************************************************
export class CollectionConService {
  // 收资信息回显
  static info(
    params: {
      projectId: string; // 项目ID
      root?: { // 项目建议书模型
        buildingAge?: string // 建筑物使用年限还剩余年限
        collectionTemplateName?: string // 收资表模板
        enterpriseNatureName?: string // 企业性质
        mainBusiness?: string // 主营业务
        projectCode?: string // 项目代码
        projectName?: string // 项目名称
        propertyName?: string // 产权证类型
        resourcetypename?: string // 项目类型
      }
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      const url = basePath + `/Energy_collectionCon/info/${params.projectId}`;

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      configs.data = params['root'] ?? null;
      axios(configs, resolve, reject);
    });
  }

  // 收资信息基础数据
  static basicData(
    params: {
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      const url = basePath + `/Energy_collectionCon/data`;

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = params

      configs.data = null;
      axios(configs, resolve, reject);
    });
  }

  // 信息
  static detail(
    params: {
      projectId: string; // 项目ID
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      const url = basePath + `/Energy_collectionCon/${params.projectId}`;

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      configs.data = null;
      axios(configs, resolve, reject);
    });
  }


  // 删除
  static remove(
    params: {
      id: string;
      root?: object;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      const url = basePath + `/Energy_collectionCon/${params.id}`

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      configs.data = params['root'] ?? null;
      axios(configs, resolve, reject);
    });
  }

  // 创建、更新
  static update(
    params: {
      id?: string;
      root?: object;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/Energy_collectionCon'
      let method = 'post'
      if (params['id']) {
        url = basePath + '/Energy_collectionCon/' + params['id']
        method = 'put'
      }
      const configs: IRequestConfig = getConfigs(method, 'application/json', url, options);

      configs.data = params['root'] ?? null;
      axios(configs, resolve, reject);
    });
  }

  // 上传收资表
  static upload(
    params: {
      projectId?: string;
      root?: object;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      const url = basePath + `/Energy_collectionCon/${params.projectId}/upload`
      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      configs.data = params['root'] ?? null;
      axios(configs, resolve, reject);
    });
  }

}

// 收资信息模块 end **************************************************************************************************

// 项目方案模块 start **************************************************************************************************
export class ProjectProposalService {
  // 列表
  static list(
    params: {
      projectId: string; // 项目ID
      root?: { // 项目建议书模型
        calculationState?: string;   // 计算状态
        createName?: string // 创建人
        createTime?: string | Date// 创建时间
        name?: string // 项目建议书名称
        projectCode?: string // 项目代码
        projectName?: string // 项目名称
        resourcetypename?: string // 项目类型
      }
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      const url = basePath + `/Energy_project_proposal/list/${params.projectId}`;

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      configs.data = params['root'] ?? null;
      axios(configs, resolve, reject);
    });
  }

  // 删除
  static remove(
    params: {
      id: string;
      root?: object;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      const url = basePath + `/Energy_project_proposal/${params.id}`

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      configs.data = params['root'] ?? null;
      axios(configs, resolve, reject);
    });
  }

  // 项目建议书上传
  static upload(
    params: {
      projectId?: string;
      root?: object;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      const url = basePath + `/Energy_project_proposal/upload/${params.projectId}`
      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      configs.data = params['root'] ?? null;
      axios(configs, resolve, reject);
    });
  }
}

// 项目方案模块 end **************************************************************************************************


// 项目文档模块 start **************************************************************************************************
export class ProjectDocumentService {
  // 列表
  static list(
    params: {
      page: number;
      pageSize: number;
      sidx: string;
      sort: string;
      documents?: number; // 文档数量
      investType?: string; // 投资模式
      keyword?: string;
      projectCode?: string; // 项目代码
      projectName?: string; // 项目名称
      projectId?: string; // 资源开发表主表ID
      projectType?: string; // 项目类型
      records?: number;
      resourcedependency?: string; // 资源属地
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      const url = basePath + `/Energy_project_document`;

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      const { page, ...args } = params
      configs.params = Object.assign({
        currentPage: page ?? 1,
        pageSize: 20,
        sort: 'desc',
        sidx: '',
      }, args);

      configs.data = null;
      axios(configs, resolve, reject);
    });
  }

  // 附件查看-项目下所有附件
  static info(
    params: {
      id: string;
      sort?: string; // asc  desc
      sidx?: string;
      page?: number; // 当前页
      pageSize?: number; // 每页条数
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      const url = basePath + `/Energy_project_document/${params.id}`;

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      const { page, ...args } = params
      configs.params = Object.assign({
        currentPage: page ?? 1,
        pageSize: 10,
        sort: 'desc',
        sidx: '',
      }, args)

      configs.data = null;
      axios(configs, resolve, reject);
    });
  }

  // 项目下所有附件-根据模块分组
  static detail(
    params: {
      id: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      const url = basePath + `/Energy_project_document/detail/${params.id}`;

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      configs.data = null;
      axios(configs, resolve, reject);
    });
  }

  // 批量下载
  static download(
    params: {
      id: string[];
    } = {} as any,
  ): Promise<any> {
    return new Promise((resolve) => {
      const url = basePath + `/Energy_project_document/downLoad?json=${params.id}`;

      resolve(url)
    })
  }

  // 查询下载权限
  static oauth(
    params: {
      projectId: string;
      code: number; // 1: 合同 2: 投资信息 3：项目方案
    } = {} as any,
    options: IRequestOptions = {},
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      const url = basePath + `/Energy_project_document/oauth?projectId=${params.projectId}&code=${params.code}`

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options)

      configs.data = null
      axios(configs, resolve, reject)
    })
  }
}

// 项目文档模块 end **************************************************************************************************


// 个人中心模块 start **************************************************************************************************
export class AgencyService {
  // 获取列表
  static list(
    params: {
      project_name?: string; // 项目名称
      type?: number; // 待办类型
      sort: string; // asc  desc
      sidx: string;
      page: number; // 当前页
      pageSize: number; // 每页条数
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      const url = basePath + `/Energy_agency`;

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      const { page, ...args } = params
      configs.params = Object.assign({
        currentPage: page ?? 1,
        pageSize: 20,
        sort: 'desc',
        sidx: '',
      }, args)

      configs.data = null;
      axios(configs, resolve, reject);
    });
  }

  // 获取详情
  static info(
    params: {
      id: string;
      root?: object;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      const url = basePath + `/Energy_agency/${params.id}`

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      configs.data = params['root'] ?? null;
      axios(configs, resolve, reject);
    });
  }

  // 删除
  static remove(
    params: {
      id: string;
      root?: object;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      const url = basePath + `/Energy_agency/${params.id}`

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      configs.data = params['root'] ?? null;
      axios(configs, resolve, reject);
    });
  }
}
// 个人中心模块 end **************************************************************************************************


// 政策法规模块 start **************************************************************************************************
export class PolicyService {
  // 获取列表
  static list(
    params: {
      title?: string; // 标题
      policy_type_id?: string; // 政策类型
      policy_dependency?: string; // 法规属地
      sort: string; // asc  desc
      sidx: string;
      page: number; // 当前页
      pageSize: number; // 每页条数
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      const url = basePath + `/Energy_policy_regulation`;

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      const { page, ...args } = params
      configs.params = Object.assign({
        currentPage: page ?? 1,
        pageSize: 20,
        sort: 'desc',
        sidx: '',
      }, args)

      configs.data = null;
      axios(configs, resolve, reject);
    });
  }

  // 获取详情
  static info(
    params: {
      id: string;
      root?: object;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      const url = basePath + `/Energy_policy_regulation/${params.id}`

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      configs.data = params['root'] ?? null;
      axios(configs, resolve, reject);
    });
  }

  // 发布、编辑
  static update(
    params: {
      id?: string;
      root?: object;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/Energy_policy_regulation'
      if (params['id']) {
        url = basePath + '/Energy_policy_regulation/' + params['id']
      }
      const configs: IRequestConfig = getConfigs(params['id'] ? 'put' : 'post', 'application/json', url, options);

      configs.data = params['root'] ?? null;
      axios(configs, resolve, reject);
    });
  }

  // 删除
  static remove(
    params: {
      id: string;
      root?: object;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      const url = basePath + `/Energy_policy_regulation/${params.id}`

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      configs.data = params['root'] ?? null;
      axios(configs, resolve, reject);
    });
  }
}
// 政策法规模块 end **************************************************************************************************