/**
 * project
 * unknown
 *
 * OpenAPI spec version: last
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import ajax, { AjaxPromise, ExtraFetchParams } from '@ajax';
import * as models from '../model/models';

/* tslint:disable:no-unused-variable member-ordering object-literal-shorthand */

/**
  * @description i3060EvaluationGetGridDataGet参数
  * @property `level` 级别 0.01 0.02 0.05 0.1 0.2 0.5 1 其中0.01 0.02 0.05 数据都很多 建议用坐标 其他可以直接中国的左下右上
  * @property `leftBottomLng` 左下经度
  * @property `leftBottomLat` 左下纬度
  * @property `rightTopLng` 右上纬度
  * @property `rightTopLat` 右上经度
  */
export interface Paramsi3060EvaluationGetGridDataGet {
  // queryParams
  /**
   * 级别 0.01 0.02 0.05 0.1 0.2 0.5 1 其中0.01 0.02 0.05 数据都很多 建议用坐标 其他可以直接中国的左下右上
   */
  level: string;
  /**
   * 左下经度
   */
  leftBottomLng: string;
  /**
   * 左下纬度
   */
  leftBottomLat: string;
  /**
   * 右上纬度
   */
  rightTopLng: string;
  /**
   * 右上经度
   */
  rightTopLat: string;
}
/**
  * @description i3060EvaluationGetRoofByGeoGet参数
  * @property `regionCode` 地区code
  * @property `leftBottomLng` 左下经度
  * @property `leftBottomLat` 左下纬度
  * @property `rightTopLng` 右上纬度
  * @property `rightTopLat` 右上经度
  */
export interface Paramsi3060EvaluationGetRoofByGeoGet {
  // queryParams
  /**
   * 地区code
   */
  regionCode: string;
  /**
   * 左下经度
   */
  leftBottomLng: string;
  /**
   * 左下纬度
   */
  leftBottomLat: string;
  /**
   * 右上纬度
   */
  rightTopLng: string;
  /**
   * 右上经度
   */
  rightTopLat: string;
}
/**
  * @description i3060EvaluationGetRoofGet参数
  * @property `[name]` 名字
  * @property `[address]` 地址
  * @property `regionCode` 地区code
  * @property `[areaMin]` 面积
  * @property `[areaMax]` 
  * @property `[areaSort]` 
  * @property `times` 时间 取选项的value 多个逗号隔开  必须选到子项选父项为子项全选
  * @property `timeSort` 
  * @property `[yearTotalIrradianceMin]` 
  * @property `[yearTotalIrradianceMax]` 
  * @property `[yearTotalIrradianceSort]` 
  */
export interface Paramsi3060EvaluationGetRoofGet {
  // queryParams
  /**
   * 名字
   */
  name?: string;
  /**
   * 地址
   */
  address?: string;
  /**
   * 地区code
   */
  regionCode: string;
  /**
   * 面积
   */
  areaMin?: string;
  /**
   * 
   */
  areaMax?: string;
  /**
   * 
   */
  areaSort?: string;
  /**
   * 时间 取选项的value 多个逗号隔开  必须选到子项选父项为子项全选
   */
  times: string;
  /**
   * 
   */
  timeSort: string;
  /**
   * 
   */
  yearTotalIrradianceMin?: string;
  /**
   * 
   */
  yearTotalIrradianceMax?: string;
  /**
   * 
   */
  yearTotalIrradianceSort?: string;
}
/**
  * @description i3060EvaluationGetRoofQueryParamGet参数
  * @property `regionCode` 地区code
  */
export interface Paramsi3060EvaluationGetRoofQueryParamGet {
  // queryParams
  /**
   * 地区code
   */
  regionCode: string;
}
/**
  * @description i3060EvaluationListGet参数
  * @property `label` 名字
  * @property `adcodes` 省份code 逗号隔开
  * @property `regionAreaMin` 区域面积 最小
  * @property `regionAreaMax` 区域面积 最大
  * @property `regionAreaSort` 区域面积 排序  0 不排序 1 asc 2 desc
  * @property `yearTotalIrradianceMin` 年辐照
  * @property `yearTotalIrradianceMax` 
  * @property `yearTotalIrradianceSort` 
  * @property `roofCountMin` 屋顶数量
  * @property `roofCountMax` 
  * @property `roofCountSort` 
  * @property `roofAreaMin` 屋顶面积
  * @property `roofAreaMax` 
  * @property `roofAreaSort` 
  * @property `avgRoofAreaMin` 屋顶面积平均值
  * @property `avgRoofAreaMax` 
  * @property `avgRoofAreaSort` 
  * @property `roofRatioMin` 覆盖率
  * @property `roofRatioMax` 
  * @property `roofRatioSort` 
  */
export interface Paramsi3060EvaluationListGet {
  // queryParams
  /**
   * 名字
   */
  label: string;
  /**
   * 省份code 逗号隔开
   */
  adcodes: string;
  /**
   * 区域面积 最小
   */
  regionAreaMin: string;
  /**
   * 区域面积 最大
   */
  regionAreaMax: string;
  /**
   * 区域面积 排序  0 不排序 1 asc 2 desc
   */
  regionAreaSort: string;
  /**
   * 年辐照
   */
  yearTotalIrradianceMin: string;
  /**
   * 
   */
  yearTotalIrradianceMax: string;
  /**
   * 
   */
  yearTotalIrradianceSort: string;
  /**
   * 屋顶数量
   */
  roofCountMin: string;
  /**
   * 
   */
  roofCountMax: string;
  /**
   * 
   */
  roofCountSort: string;
  /**
   * 屋顶面积
   */
  roofAreaMin: string;
  /**
   * 
   */
  roofAreaMax: string;
  /**
   * 
   */
  roofAreaSort: string;
  /**
   * 屋顶面积平均值
   */
  avgRoofAreaMin: string;
  /**
   * 
   */
  avgRoofAreaMax: string;
  /**
   * 
   */
  avgRoofAreaSort: string;
  /**
   * 覆盖率
   */
  roofRatioMin: string;
  /**
   * 
   */
  roofRatioMax: string;
  /**
   * 
   */
  roofRatioSort: string;
}
/**
  * @description i3060EvaluationRegionDataGet参数
  * @property `[level]` 地区等级，province,city,district 省市区 逗号隔开 可以传单个 也可以不传 默认所有
  */
export interface Paramsi3060EvaluationRegionDataGet {
  // queryParams
  /**
   * 地区等级，province,city,district 省市区 逗号隔开 可以传单个 也可以不传 默认所有
   */
  level?: string;
}
/**
  */
export type ParamsBodyi3060ResourceAddPost = Array<models.I3060ResourceAddBody>;

export class EvaluationApi {
  protected $basePath = '/api'.replace(/\/$/, '');

  public constructor(basePath?: string) {
    if (basePath !== undefined) {
      this.$basePath = basePath.replace(/\/$/, '');
    }
  }

  /**
   * 
   * @summary 根据左下右上坐标获取网格屋顶信息
   * @param params Paramsi3060EvaluationGetGridDataGet
   
   * @param opt ajax config
   * @returns models.I3060EvaluationGetGridData
   */
  public i3060EvaluationGetGridDataGet = (
    params: Paramsi3060EvaluationGetGridDataGet,
    opt?: ExtraFetchParams
  ) : AjaxPromise<models.I3060EvaluationGetGridData>  => {
    const url = this.$basePath + `/i3060/evaluation/getGridData`;
    const p: any = {};
    p.query = {};
    if ('level' in params) p.query.level = params.level;
    ajax.check(params.level, 'level');
    if ('leftBottomLng' in params) p.query.leftBottomLng = params.leftBottomLng;
    ajax.check(params.leftBottomLng, 'leftBottomLng');
    if ('leftBottomLat' in params) p.query.leftBottomLat = params.leftBottomLat;
    ajax.check(params.leftBottomLat, 'leftBottomLat');
    if ('rightTopLng' in params) p.query.rightTopLng = params.rightTopLng;
    ajax.check(params.rightTopLng, 'rightTopLng');
    if ('rightTopLat' in params) p.query.rightTopLat = params.rightTopLat;
    ajax.check(params.rightTopLat, 'rightTopLat');
    return ajax.ajax({
      ...opt,
      method: 'GET',
      url,
      ...p
    });
  }
  /**
   * 
   * @summary 根据左下右上坐标获取屋顶数据
   * @param params Paramsi3060EvaluationGetRoofByGeoGet
   
   * @param opt ajax config
   * @returns models.I3060EvaluationGetRoofByGeo
   */
  public i3060EvaluationGetRoofByGeoGet = (
    params: Paramsi3060EvaluationGetRoofByGeoGet,
    opt?: ExtraFetchParams
  ) : AjaxPromise<models.I3060EvaluationGetRoofByGeo>  => {
    const url = this.$basePath + `/i3060/evaluation/getRoofByGeo`;
    const p: any = {};
    p.query = {};
    if ('regionCode' in params) p.query.regionCode = params.regionCode;
    ajax.check(params.regionCode, 'regionCode');
    if ('leftBottomLng' in params) p.query.leftBottomLng = params.leftBottomLng;
    ajax.check(params.leftBottomLng, 'leftBottomLng');
    if ('leftBottomLat' in params) p.query.leftBottomLat = params.leftBottomLat;
    ajax.check(params.leftBottomLat, 'leftBottomLat');
    if ('rightTopLng' in params) p.query.rightTopLng = params.rightTopLng;
    ajax.check(params.rightTopLng, 'rightTopLng');
    if ('rightTopLat' in params) p.query.rightTopLat = params.rightTopLat;
    ajax.check(params.rightTopLat, 'rightTopLat');
    return ajax.ajax({
      ...opt,
      method: 'GET',
      url,
      ...p
    });
  }
  /**
   * 
   * @summary 屋顶扫描
   * @param params Paramsi3060EvaluationGetRoofGet
   
   * @param opt ajax config
   * @returns models.I3060EvaluationGetRoof
   */
  public i3060EvaluationGetRoofGet = (
    params: Paramsi3060EvaluationGetRoofGet,
    opt?: ExtraFetchParams
  ) : AjaxPromise<models.I3060EvaluationGetRoof>  => {
    const url = this.$basePath + `/i3060/evaluation/getRoof`;
    const p: any = {};
    p.query = {};
    if ('name' in params) p.query.name = params.name;
    if ('address' in params) p.query.address = params.address;
    if ('regionCode' in params) p.query.regionCode = params.regionCode;
    ajax.check(params.regionCode, 'regionCode');
    if ('areaMin' in params) p.query.areaMin = params.areaMin;
    if ('areaMax' in params) p.query.areaMax = params.areaMax;
    if ('areaSort' in params) p.query.areaSort = params.areaSort;
    if ('times' in params) p.query.times = params.times;
    ajax.check(params.times, 'times');
    if ('timeSort' in params) p.query.timeSort = params.timeSort;
    ajax.check(params.timeSort, 'timeSort');
    if ('yearTotalIrradianceMin' in params) p.query.yearTotalIrradianceMin = params.yearTotalIrradianceMin;
    if ('yearTotalIrradianceMax' in params) p.query.yearTotalIrradianceMax = params.yearTotalIrradianceMax;
    if ('yearTotalIrradianceSort' in params) p.query.yearTotalIrradianceSort = params.yearTotalIrradianceSort;
    return ajax.ajax({
      ...opt,
      method: 'GET',
      url,
      ...p
    });
  }
  /**
   * 
   * @summary 屋顶扫描时间条件筛选
   * @param params Paramsi3060EvaluationGetRoofQueryParamGet
   
   * @param opt ajax config
   * @returns models.I3060EvaluationGetRoofQueryParam
   */
  public i3060EvaluationGetRoofQueryParamGet = (
    params: Paramsi3060EvaluationGetRoofQueryParamGet,
    opt?: ExtraFetchParams
  ) : AjaxPromise<models.I3060EvaluationGetRoofQueryParam>  => {
    const url = this.$basePath + `/i3060/evaluation/getRoofQueryParam`;
    const p: any = {};
    p.query = {};
    if ('regionCode' in params) p.query.regionCode = params.regionCode;
    ajax.check(params.regionCode, 'regionCode');
    return ajax.ajax({
      ...opt,
      method: 'GET',
      url,
      ...p
    });
  }
  /**
   * 
   * @summary 获取全国市、区资源详情（带条件）
   * @param params Paramsi3060EvaluationListGet
   
   * @param opt ajax config
   * @returns models.I3060EvaluationList
   */
  public i3060EvaluationListGet = (
    params: Paramsi3060EvaluationListGet,
    opt?: ExtraFetchParams
  ) : AjaxPromise<models.I3060EvaluationList>  => {
    const url = this.$basePath + `/i3060/evaluation/list`;
    const p: any = {};
    p.query = {};
    if ('label' in params) p.query.label = params.label;
    ajax.check(params.label, 'label');
    if ('adcodes' in params) p.query.adcodes = params.adcodes;
    ajax.check(params.adcodes, 'adcodes');
    if ('regionAreaMin' in params) p.query.regionAreaMin = params.regionAreaMin;
    ajax.check(params.regionAreaMin, 'regionAreaMin');
    if ('regionAreaMax' in params) p.query.regionAreaMax = params.regionAreaMax;
    ajax.check(params.regionAreaMax, 'regionAreaMax');
    if ('regionAreaSort' in params) p.query.regionAreaSort = params.regionAreaSort;
    ajax.check(params.regionAreaSort, 'regionAreaSort');
    if ('yearTotalIrradianceMin' in params) p.query.yearTotalIrradianceMin = params.yearTotalIrradianceMin;
    ajax.check(params.yearTotalIrradianceMin, 'yearTotalIrradianceMin');
    if ('yearTotalIrradianceMax' in params) p.query.yearTotalIrradianceMax = params.yearTotalIrradianceMax;
    ajax.check(params.yearTotalIrradianceMax, 'yearTotalIrradianceMax');
    if ('yearTotalIrradianceSort' in params) p.query.yearTotalIrradianceSort = params.yearTotalIrradianceSort;
    ajax.check(params.yearTotalIrradianceSort, 'yearTotalIrradianceSort');
    if ('roofCountMin' in params) p.query.roofCountMin = params.roofCountMin;
    ajax.check(params.roofCountMin, 'roofCountMin');
    if ('roofCountMax' in params) p.query.roofCountMax = params.roofCountMax;
    ajax.check(params.roofCountMax, 'roofCountMax');
    if ('roofCountSort' in params) p.query.roofCountSort = params.roofCountSort;
    ajax.check(params.roofCountSort, 'roofCountSort');
    if ('roofAreaMin' in params) p.query.roofAreaMin = params.roofAreaMin;
    ajax.check(params.roofAreaMin, 'roofAreaMin');
    if ('roofAreaMax' in params) p.query.roofAreaMax = params.roofAreaMax;
    ajax.check(params.roofAreaMax, 'roofAreaMax');
    if ('roofAreaSort' in params) p.query.roofAreaSort = params.roofAreaSort;
    ajax.check(params.roofAreaSort, 'roofAreaSort');
    if ('avgRoofAreaMin' in params) p.query.avgRoofAreaMin = params.avgRoofAreaMin;
    ajax.check(params.avgRoofAreaMin, 'avgRoofAreaMin');
    if ('avgRoofAreaMax' in params) p.query.avgRoofAreaMax = params.avgRoofAreaMax;
    ajax.check(params.avgRoofAreaMax, 'avgRoofAreaMax');
    if ('avgRoofAreaSort' in params) p.query.avgRoofAreaSort = params.avgRoofAreaSort;
    ajax.check(params.avgRoofAreaSort, 'avgRoofAreaSort');
    if ('roofRatioMin' in params) p.query.roofRatioMin = params.roofRatioMin;
    ajax.check(params.roofRatioMin, 'roofRatioMin');
    if ('roofRatioMax' in params) p.query.roofRatioMax = params.roofRatioMax;
    ajax.check(params.roofRatioMax, 'roofRatioMax');
    if ('roofRatioSort' in params) p.query.roofRatioSort = params.roofRatioSort;
    ajax.check(params.roofRatioSort, 'roofRatioSort');
    return ajax.ajax({
      ...opt,
      method: 'GET',
      url,
      ...p
    });
  }
  /**
   * 
   * @summary 地区筛选栏接口
   
   
   * @param opt ajax config
   * @returns models.I3060EvaluationRegionCode
   */
  public i3060EvaluationRegionCodeGet = (
    
    opt?: ExtraFetchParams
  ) : AjaxPromise<models.I3060EvaluationRegionCode>  => {
    const url = this.$basePath + `/i3060/evaluation/regionCode`;
    const p: any = {};
    return ajax.ajax({
      ...opt,
      method: 'GET',
      url,
      ...p
    });
  }
  /**
   * 
   * @summary 获取全国市、区资源详情（不带条件）
   * @param params Paramsi3060EvaluationRegionDataGet
   
   * @param opt ajax config
   * @returns models.I3060EvaluationRegionData
   */
  public i3060EvaluationRegionDataGet = (
    params: Paramsi3060EvaluationRegionDataGet,
    opt?: ExtraFetchParams
  ) : AjaxPromise<models.I3060EvaluationRegionData>  => {
    const url = this.$basePath + `/i3060/evaluation/regionData`;
    const p: any = {};
    p.query = {};
    if ('level' in params) p.query.level = params.level;
    return ajax.ajax({
      ...opt,
      method: 'GET',
      url,
      ...p
    });
  }
  /**
   * 
   * @summary 资源添加
   
   * @param data: ParamsBodyi3060ResourceAddPost
   * @param opt ajax config
   * @returns models.I3060FinanceAwardSubsidyEdit
   */
  public i3060ResourceAddPost = (
    
    data: ParamsBodyi3060ResourceAddPost,opt?: ExtraFetchParams
  ) : AjaxPromise<models.I3060FinanceAwardSubsidyEdit>  => {
    const url = this.$basePath + `/i3060/resource/add`;
    const p: any = {};
    p.data = data;
    return ajax.ajax({
      ...opt,
      method: 'POST',
      url,
      ...p
    });
  }
}

export default new EvaluationApi();