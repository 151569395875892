import AMapLoader from '@amap/amap-jsapi-loader'

const MAX_SIZE = 32

let _AMap: any

export const getAMap = async (): Promise<any> => {
  return new Promise((resolve, reject) => {
    if (_AMap) {
      resolve(_AMap)
    } else {
      initAMap()
        .then((_AMap) => {
          resolve(_AMap)
        })
        .catch((e) => {
          reject(e)
        })
    }
  })
}

export const initAMap = async () => {
  return new Promise((resolve, reject) => {
    AMapLoader.load({
      key: '83aa76226369c2b9fcf94e6c1228db13', // 申请好的Web端开发者Key，首次调用 load 时必填
      version: '2.0',
      plugins: [
        'AMap.Geocoder',
        'AMap.HeatMap',
        'AMap.MarkerClusterer',
        'AMap.TileLayer',
        'AMap.DistrictLayer',
        'AMap.Geocoder',
        'AMap.DistrictSearch',
        'AMap.MapboxVectorTileLayer',
      ],
      AMapUI: {
        version: '1.1',
        plugins: ['overlay/SimpleMarker', 'geo/DistrictExplorer'],
      },
      Loca: {
        version: '2.0',
      },
    })
      .then((AMap) => {
        _AMap = AMap
        resolve(AMap)
      })
      .catch((e) => {
        reject(e)
      })
  })
}

export const renderMap = async (srcData = [], initZoom: number, callback: Function) => {
  const data: any[] = []
  srcData.forEach((o: { digital_location: string; name: string }) => {
    const arr = o.digital_location?.split(',')
    if (arr && arr.length === 2) {
      data.push({
        lng: parseFloat(arr[0]),
        lat: parseFloat(arr[1]),
        lnglat: arr,
        name: o.name,
      })
    }
  })

  if (callback) callback()

  const initMap = async (points: any) => {
    getAMap().then((AMap: any) => {
      const map = new AMap.Map('map', {
        resizeEnable: true,
        center: [116.418261, 39.921984],
        zoom: initZoom || 3,
        mapStyle: 'amap://styles/05e321edfe9e69b5902e4dc372c36fda',
      })

      const markers: any[] = []
      let cluster: any

      // let count = markers.length
      const _renderClusterMarker = (context: any) => {
        // var factor = Math.pow(context.count / count, 1 / 18)
        var div = document.createElement('div')
        div.style.backgroundImage = 'url("./assets/map-icon.svg")'
        div.style.backgroundSize = '100% 100%'
        div.style.backgroundRepeat = 'no-repeat'
        // var bgColor = 'hsla(' + Hue + ',100%,50%,0.7)';
        // var fontColor = 'hsla(' + Hue + ',100%,20%,1)';
        // var borderColor = 'hsla(' + Hue + ',100%,40%,1)';
        // var shadowColor = 'hsla(' + Hue + ',100%,50%,1)';
        // div.style.backgroundColor = bgColor;
        let size = (context.count / MAX_SIZE) * 64 + 32
        if (size > 108) size = 108
        // var size = Math.round(30 + Math.pow(context.count / count, 1 / 5) * 20);
        div.style.width = div.style.height = size + 'px'
        // div.style.border = 'solid 1px ' + borderColor;
        // div.style.borderRadius = size / 2 + 'px';
        // div.style.boxShadow = '0 0 1px ' + shadowColor;
        div.innerHTML = context.count
        div.style.lineHeight = size + 'px'
        // div.style.color = fontColor;
        div.style.fontSize = '14px'
        div.style.textAlign = 'center'
        context.marker.setOffset(new AMap.Pixel(-size / 2, -size / 2))
        context.marker.setContent(div)
      }

      const addCluster = () => {
        if (cluster) {
          cluster.setMap(null)
        }
        cluster = new AMap.MarkerClusterer(map, markers, {
          renderClusterMarker: _renderClusterMarker,
          gridSize: 64,
          maxZoom: 12,
        })
      }

      for (let i = 0; i < points.length; i += 1) {
        markers.push(
          new AMap.Marker({
            position: points[i].lnglat,
            icon: './assets/poi.svg',
            offset: new AMap.Pixel(-20, -20),
            label: {
              offset: new AMap.Pixel(0, 40),
              content: `<div className='info' style='padding: 3px 10px 3px 10px;'>${points[i].name}</div>`,
              direction: 'center',
            },
          })
        )
      }

      addCluster()
    })
  }

  initMap(data).then()
}
