import React from 'react'

// 设置表格搜索条件
export type ParamsType = {
  page?: number
  pageSize?: number
  [name: string]: any
}

export const useTableParams = () => {
  const ref = React.useRef<ParamsType>({ page: 1, pageSize: 20 })

  const get = (key?: string) => {
    if (key) {
      return ref.current[key]
    }
    return ref.current
  }

  const set = (args?: ParamsType) => {
    ref.current = { ...ref.current, ...(args ?? {}) }
  }
  return [{ get }, set]
}

export default useTableParams
