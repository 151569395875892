import React, { useEffect, useState } from 'react'
import { Button, message, Modal, Radio } from 'antd'
import InviteControllerApi from '../../../services/api/InviteControllerApi'
import TenantControllerApi from '../../../services/api/TenantControllerApi'
import type { InviteIntoOrg, InviteIntoProject } from '../typings'
import Global from '../../../global'
import IconInvite from '../../../assets/invite-title.png'
import AccountControllerApi from '../../../services/api/AccountControllerApi'
import ProjectAccountControllerApi from '../../../services/api/ProjectAccountControllerApi'
import RemoveModal from '../../user-center/rights-template/components/RemoveModal'
import styles from '../index.module.scss'

type Org = {
  id?: number // 组织id
  name?: string // 组织名称
  isAdministrators?: boolean // 是否组织管理员
}

const ModalTitle = React.memo((props: any) => (
  <div className="invite-header">
    <img src={IconInvite} alt="" />
    <span>{props.title}</span>
  </div>
))

// 邀请组件
const Invite: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(false)
  const [inviteVisible, setInviteVisible] = useState(false)
  const [inviteCode, setInviteCode] = useState<string>()
  const [inviteType, setInviteType] = useState<number>(1)
  const [inviteOrg, setInviteOrg] = useState<InviteIntoOrg>()
  const [inviteProject, setInviteProject] = useState<InviteIntoProject>()
  const [orgId, setOrgId] = useState<Org['id']>()
  const [orgList, setOrgList] = useState<Org[]>([])
  const [removeVisible, setRemoveVisible] = useState<boolean>(false)

  const result = new URLSearchParams(location.search)
  const invite = result.get('invite')

  const replaceTo = () => {
    const windowLocation = window.location
    const { origin, hash } = windowLocation
    const replace = `${origin}/${hash}`
    if (windowLocation.href === replace) {
      window.location.reload()
    } else {
      windowLocation.replace(`${origin}/${hash}`)
    }
  }

  const checkJoinProject = (data: any) => {
    if (loading || !data?.projectId) return
    setLoading(true)
    ProjectAccountControllerApi.i3060ProjectAccountCheckJoinGet({
      projectId: data.projectId,
    })
      .then((res) => {
        setLoading(false)
        const { code } = res || {}
        if (code === 200) {
          fetchOrgList()
          setInviteProject(data)
          setInviteVisible(true)
        } else if (code === 50003) {
          sessionStorage.removeItem('INVITE')
          JoinedModal(2)
        }
      })
      .catch(() => {
        sessionStorage.removeItem('INVITE')
        setLoading(false)
      })
  }

  const checkJoinOrg = (data: any) => {
    if (loading) return
    setLoading(true)
    const { id } = Global.user || {}
    AccountControllerApi.authAccountIsJoinTenantPost({
      userId: id,
      code: inviteCode,
    })
      .then((res) => {
        setLoading(false)
        const { code } = res || {}
        if (code === 200) {
          setInviteOrg(data)
          setInviteVisible(true)
        } else if (code === 50003) {
          sessionStorage.removeItem('INVITE')
          JoinedModal(1)
        }
      })
      .catch(() => {
        sessionStorage.removeItem('INVITE')
        setLoading(false)
      })
  }

  const checkJoin = (type: number, data: any) => {
    setInviteType(type)
    switch (type) {
      case 1:
        checkJoinOrg(data)
        break
      case 2:
        checkJoinProject(data)
        break
    }
  }

  const fetchInviteInfo = () => {
    if (loading || !inviteCode) return
    setLoading(true)
    InviteControllerApi.authInviteCodeToContentCodeGet({
      code: inviteCode,
    })
      .then((res) => {
        setLoading(false)
        try {
          const { code, data } = res || {}
          if (code === 200) {
            const { type, content } = data || {}
            if (content) {
              const data = JSON.parse(content)
              checkJoin(type, data)
            }
          } else if (code === 50004) {
            sessionStorage.removeItem('INVITE')
            setRemoveVisible(true)
          }
        } catch (err) {
          console.log('🚀 ~ file: login.tsx ~ line 63 ~ .then ~ err', err)
        }
      })
      .catch(() => {
        sessionStorage.removeItem('INVITE')
        setLoading(false)
      })
  }

  // 切换组织
  const tenantChange = ({ id, isAdministrators }: any) => {
    TenantControllerApi.authTenantChangeGet({ tenantId: id, isAdministrators })
      .then((res: any) => {
        message.success('切换成功，正在重新加载请稍等！')
        if (res?.msg == 'success') {
          replaceTo()
        }
      })
      .catch(() => {})
  }

  // 查询组织成员
  const fetchMember = () => {
    const { id } = Global.user || {}
    AccountControllerApi.authAccountDetailGet({
      userId: id,
      tenantId: `${inviteOrg.tenantId}`,
    }).then((res) => {
      const { code, data } = res || {}
      if (code === 200) {
        const { tenantAccountId } = (data || {}) as any
        if (tenantAccountId) {
          // 移除组织成员
          AccountControllerApi.authAccountDelTenantAccountPost([{ tenantAccountId }]).then((res) => {
            setLoading(false)
            const { code } = res || {}
            if (code === 200) {
              replaceTo()
            }
          })
        } else {
          replaceTo()
        }
      }
    })
  }

  // 接受组织邀请
  const acceptOrgInvite = () => {
    const { id, userName } = Global.user || {}
    if (loading || !inviteCode) return
    setLoading(true)
    AccountControllerApi.authAccountUserAcceptInvitePost({
      code: inviteCode,
      acceptUserId: id,
      userLoginName: userName,
    })
      .then((res) => {
        setLoading(false)
        const { code } = res || {}
        if (code === 200) {
          message.success('加入组织成功')
          setInviteVisible(false)
          sessionStorage.removeItem('INVITE')
          tenantChange({ id: inviteOrg.tenantId, isAdministrators: false })
        }
      })
      .catch(() => {
        sessionStorage.removeItem('INVITE')
        setLoading(false)
      })
  }

  // 接受项目邀请
  const acceptProjectInvite = () => {
    // 改为邀请人信息（2022-07-23）
    // const { id, userName } = Global.user || {}
    const { projectId, templateId } = inviteProject || {}
    if (loading) return
    if (!orgId) {
      message.warning('请选择组织')
      return
    }
    setLoading(true)
    ProjectAccountControllerApi.i3060ProjectAccountInvitationConfirmPost({
      userId: inviteProject?.userId,
      userName: inviteProject?.userName,
      projectId,
      templateId,
      tenantId: orgId,
    })
      .then((res) => {
        setLoading(false)
        const { code } = res || {}
        if (code === 200) {
          message.success('加入项目成功')
          setInviteVisible(false)
          sessionStorage.removeItem('INVITE')
          const org = orgList.find((item) => item.id === orgId)
          tenantChange({ id: orgId, isAdministrators: org?.isAdministrators || false })
        }
      })
      .catch(() => {
        sessionStorage.removeItem('INVITE')
        setLoading(false)
      })
  }

  const fetchOrgList = () => {
    TenantControllerApi.authTenantListGet({})
      .then((res) => {
        setOrgList(res?.data || [])
      })
      .catch(() => {})
  }

  const handleOrgChange = (value: number) => {
    setOrgId(value)
  }

  const handleOk = () => {
    switch (inviteType) {
      case 1:
        acceptOrgInvite()
        break
      case 2:
        acceptProjectInvite()
        break
    }
  }

  const handleCancel = () => {
    sessionStorage.removeItem('INVITE')
    setInviteVisible(false)
    switch (inviteType) {
      case 1:
        fetchMember()
        break
      case 2:
        replaceTo()
        break
    }
  }

  const handleRemoveOk = () => {
    setRemoveVisible(false)
    replaceTo()
  }

  const JoinedModal = (type: number) => {
    const content = `您已加入该${type === 1 ? '组织' : '项目'}`
    const modal = Modal.info({
      className: styles['invite-success-modal'],
      title: (
        <div className={styles['invite-success-modal-title']}>
          <img src={IconInvite} alt="" />
          <span>成功提示</span>
        </div>
      ),
      mask: false,
      closable: false,
      content,
      width: 360,
    })
    setTimeout(() => {
      modal.destroy()
      replaceTo()
    }, 3000)
  }

  useEffect(() => {
    if (invite) {
      sessionStorage.setItem('INVITE', invite)
    }
    setInviteCode(sessionStorage.getItem('INVITE'))
  }, [invite])

  useEffect(() => {
    if (inviteCode) {
      fetchInviteInfo()
    }
  }, [inviteCode])

  return (
    <>
      {inviteVisible && (
        <Modal
          className="invite-modal"
          visible={inviteVisible}
          centered={true}
          width={480}
          title={
            <ModalTitle
              title={
                inviteType === 2 ? `${inviteProject?.userName}邀请你加入项目${inviteProject?.projectName}` : '温馨提示'
              }
            />
          }
          onCancel={() => setInviteVisible(false)}
          footer={
            <>
              <Button onClick={handleCancel}>拒绝</Button>
              <Button type="primary" onClick={handleOk}>
                确定
              </Button>
            </>
          }
        >
          {inviteType === 1 && (
            <p className="invite-content">
              {inviteOrg?.userName}邀请你加入组织{inviteOrg?.tenantName}，请确认是否加入
            </p>
          )}
          {inviteType === 2 && (
            <>
              <p className="invite-content">请确认以下列哪个组织加入该项目</p>
              <div className="invite-checkbox-wrapper">
                <Radio.Group className="invite-checkbox" onChange={(e) => handleOrgChange(e.target.value)}>
                  {orgList?.map(({ id, name }) => (
                    <Radio key={id} value={id}>
                      {name}
                    </Radio>
                  ))}
                </Radio.Group>
              </div>
            </>
          )}
        </Modal>
      )}
      {removeVisible && (
        <RemoveModal visible={removeVisible} title="链接过期" onOk={handleRemoveOk}>
          <p>该邀请链接已过期，请联系管理员重新获取链接</p>
        </RemoveModal>
      )}
    </>
  )
}

export default Invite
