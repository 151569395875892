/** 不再兼容非标准的数据结构 */
import axios from 'axios'
import qs from 'qs';

export declare type AjaxPromise<R> = Promise<R>;
/** 非标准包裹 */
export declare type NonStandardAjaxPromise<R> = Promise<{
  code?: number;
  message?: string;
  result: R;
}>;
export interface ExtraFetchParams {
  /** extra data for extends */
  extra?: any;
  /** 扩展请求头 */
  headers?: any;
  /** cancel request */
  cancel?: Promise<string | undefined>;
}
export interface WrappedFetchParams extends ExtraFetchParams {
  /** http method */
  method: 'GET' | 'POST' | 'PUT' | 'DELETE' | 'OPTIONS' | 'PATCH' | 'HEAD';
  url: string;
  /** post json data */
  data?: any;
  /** post form data */
  form?: any;
  /** query data */
  query?: any;
  /** header */
  header?: any;
  /** path data */
  path?: any;
}
export class Ajax {
  /** ajax 方法 */
  ajax(
    { method, url, data, form, query, header, extra, cancel, headers }: WrappedFetchParams,
    path?: string,
    basePath?: string,
  ): Promise<any> {
    console.debug(path, basePath, cancel);
    let config = {
      ...extra,
      method: method.toLowerCase(),
      headers: { ...headers, ...header },
    };
    // json
    if (data) {
      config = {
        ...config,
        headers: {
          // 可覆盖
          'Content-Type': 'application/json',
          ...config.headers,
        },
        data,
      };
    }
    // form
    if (form) {
      config = {
        ...config,
        headers: {
          // 可覆盖
          'Content-Type': 'application/x-www-form-urlencoded',
          ...config.headers,
        },
        data:
          config.headers && config.headers['Content-Type'] === 'multipart/form-data'
            ? form
            : qs.stringify(form),
      };
    }
    return new Promise((resolve, reject) => {
      axios.request({
        ...config,
        url: url,
        params: query,
      }).then((resp) => {
        resolve(resp.data);
      }).catch(e => {
        reject(e)
      });
    })
  }
  /** 接口传参校验 */
  check<V>(value: V, name: string): void {
    const msg = `[ERROR PARAMS]: ${name} can't be null or undefined`;
    if (value === null || value === undefined) {
      // 非生产环境，直接抛出错误
      if (process.env.NODE_ENV === 'development') {
        // throw Error(msg);
        console.error(msg);
      }
    }
  }
}
const _default: Ajax = new Ajax();
export default _default;
