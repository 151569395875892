/**
 * sm2 密码前后端规定code
 */
export const SM2CODE =
  '04BE22EB7E824367E72875F2FB1D528662817DC101E8C39619E1F536CD1EB3BD4610A66D34782217BD5AB4D5766AF5619D06ED54053ADE0D161CA4E044EFE9066E'

/**
 * 邮箱验证正则
 */
export const EMAIL_EXP = /^[A-Za-z\d]+([-_.][A-Za-z\d]+)*@([A-Za-z\d]+[-.])+[A-Za-z\d]{2,5}$/

//手机号校验正则表达式（更换校验方式）
export const PHONE_EXP = /^((13[0-9])|(14[5,7])|(15[0-3,5-9])|(17[0-9])|(18[0-9])|(19[0-9])|166)\d{8}$/

//邮箱校验正则表达式
export const email_regx = /^[A-Za-z\d]+([-_.][A-Za-z\d]+)*@([A-Za-z\d]+[-.])+[a-z]{2,4}$/

// 数值正则
export const NUMBER_EXP = /^d+(.d)?$/

// 方案名称正则
export const NAME_EXP = /^[\u4E00-\u9FA5A-Za-z0-9_-]{1,16}$/

// 权限模板名称
export const NAME_REG = /^[\u4E00-\u9FA5A-Za-z0-9_-]{1,32}$/

// 方案名称正则
export const RESOURCE_NAME_EXP = /^[\u4E00-\u9FA5A-Za-z0-9_-]{1,50}$/

// 中文校验
export const ZH_EXP = /[\u4e00-\u9fa5]/g

/**
 * 表单布局1
 */
export const FORM_LAYOUT_TWO = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 2 },
    xl: { span: 2 }
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 20 },
    xl: { span: 20 }
  }
}

/**
 * 表单布局2
 */
export const FORM_LAYOUT_MANUAL = {
  labelCol: {
    xs: { span: 6 },
    sm: { span: 4 },
    md: { span: 4 },
    lg: { span: 2 },
    xl: { span: 2 },
    xxl: { span: 2 }
  },
  wrapperCol: {
    xs: { span: 14 },
    sm: { span: 12 },
    md: { span: 10 },
    lg: { span: 10 },
    xl: { span: 6 },
    xxl: { span: 6 }
  }
}

export const ELEC_CHARTS_COLORS = [
  '#1EC692',
  '#3274F6',
  '#F97942',
  '#B532F6',
  '#F6B932',
  '#54A378',
  '#F56C6C',
  '#3EB1B7',
  '#8468B2',
  '#B9A43F'
]

const hexReg = /^#([0-9a-fA-f]{3}|[0-9a-fA-f]{6})$/

export const colorRgb = function(hex, alpha = 1) {
  let sColor = hex.toLowerCase()
  if (sColor && hexReg.test(sColor)) {
    if (sColor.length === 4) {
      let sColorNew = '#'
      for (var i = 1; i < 4; i += 1) {
        sColorNew += sColor.slice(i, i + 1).concat(sColor.slice(i, i + 1))
      }
      sColor = sColorNew
    }
    //处理六位的颜色值
    const sColorChange = []
    for (let i = 1; i < 7; i += 2) {
      sColorChange.push(parseInt('0x' + sColor.slice(i, i + 2)))
    }
    return 'RGB(' + sColorChange.join(',') + ',' + alpha.toString() + ')'
  } else {
    return sColor
  }
}
