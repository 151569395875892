import {Button, Form, Input, message, Modal} from 'antd'
import {useState} from 'react'
import {sm2} from 'sm-crypto'

import Verify from './verify'
import {EMAIL_EXP, PHONE_EXP, SM2CODE} from '../../../utils/constant'
import {UserService} from '../../../apis/project-service-history'
import {token} from '../../../utils/utils'
import {Link, useNavigate} from 'react-router-dom'
import {Global, showRegister} from '../../../global'
import _ from 'lodash'
import {PasswordNotice} from './password-notice'

const formItemLayout = {
  labelCol: {span: 4},
  wrapperCol: {span: 20},
}

// 用户密码登录组件
function AccountLogin() {
  const [form] = Form.useForm()
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const [loginInfo, setLoginInfo] = useState({
    accountName: '',
    password: '',
  })
  const [verifyPass, setVerifyPass] = useState('')
  const [checkUsername, setCheckUsername] = useState<any>({
    validateStatus: ' ',
    help: undefined,
  })

  // 登录
  const handleLogin = _.throttle(
    (values: any) => {
      if (!verifyPass) return
      if (loading) return
      setLoading(true)
      UserService.login({
        root: {
          ...values,
          password: sm2.doEncrypt(values.password, SM2CODE, 0),
          captchaVerification: verifyPass,
        },
      })
        .then((res) => {
          const test = false
          if (test) {
            token.set(res?.data?.token)
            setEditPassword(true)
          } else if (res.code === 200) {
            token.set(res?.data?.token)
            message.success('登录成功')

            UserService.info()
              .then((res) => {
                Global.user = res?.data || {}
                if (!res?.data?.emailCheck) {
                  Global.emailTipVisible = true
                }
                UserService.roleInfo().then((resp) => {
                  Global.role = resp.data
                  navigate('/home')
                })
              })
              .catch(() => {
                Global.user = {}
              })
          } else if (res.code === 10005) {
            setEditPassword(true)
          } else {
            message.error(res.msg)
          }
        })
        .catch(() => {
          setLoading(false)
        })
        .finally(() => {
        })
    },
    2000,
    {
      leading: true,
      trailing: false,
    }
  )

  const checkUserName = _.throttle(
    () => {
      const name = form.getFieldValue('accountName')
      if (name?.trim()) {
        setCheckUsername({
          validateStatus: 'validating',
          help: '',
        })
        console.info(EMAIL_EXP.test(name), PHONE_EXP.test(name))
        if (EMAIL_EXP.test(name)) {
          return UserService.validEmail({
            email: name,
          })
            .then((res) => {
              if (res.data) {
                setCheckUsername({
                  validateStatus: ' ',
                  help: '',
                })
              } else {
                setCheckUsername({
                  validateStatus: 'error',
                  help: (
                    <>
                      <span>
                        账号不存在{showRegister && '，去'}
                        {showRegister && <Link to="/login/register">注册</Link>}
                      </span>
                    </>
                  ),
                })
                return Promise.reject(new Error('去注册,<a>test</a>'))
              }
            })
            .catch(() => {
            })
        } else if (PHONE_EXP.test(name)) {
          return UserService.validTelephone({
            mobile: name,
          })
            .then((res) => {
              if (res.data) {
                setCheckUsername({
                  validateStatus: ' ',
                  help: '',
                })
              } else {
                setCheckUsername({
                  validateStatus: 'error',
                  help: (
                    <>
                      <span>
                        账号不存在{showRegister && '，去'}
                        {showRegister && <Link to="/login/register">注册</Link>}
                      </span>
                    </>
                  ),
                })
                return Promise.reject(new Error('去注册,<a>test</a>'))
              }
            })
            .catch(() => {
            })
        } else {
          setCheckUsername({
            validateStatus: 'error',
            help: '用户名格式不正确',
          })
        }
      } else {
        setCheckUsername({
          validateStatus: 'error',
          help: '用户名必填',
        })
      }
    },
    2000,
    {
      leading: true,
      trailing: false,
    }
  )

  const [editPassword, setEditPassword] = useState(false)
  const [saveLoading, setSaveLoading] = useState(false)
  const [showNotice, setShowNotice] = useState(false)
  const [password, setPassword] = useState('')
  const [passwordForm] = Form.useForm()

  const [pwdValidate, setPwdValidate] = useState<any>({
    validateStatus: undefined,
    help: ' ',
  })

  // 用户信息
  const [user] = useState({
    userName: '',
    headUrl: '',
    email: '',
    emailCheck: false,
    companyName: '',
    telephone: '',
    field: '',
  })

  // 修改密码
  const doEditPassword = () => {
    passwordForm
      .validateFields()
      .then(() => {
        const values = passwordForm.getFieldsValue()
        if (!values.validNewPassword) {
          setPwdValidate({
            validateStatus: 'error',
            help: '请再次输入密码',
          })
          return
        }
        // if (values.oldPassword === values.newPassword) {
        //   message.error('新密码与旧密码不能相同')
        //   return
        // }
        setSaveLoading(true)
        UserService.updatePasswordByAccount({
          root: {
            accountNo: form.getFieldValue('accountName'),
            oldPassword: sm2.doEncrypt(values.oldPassword, SM2CODE, 0),
            newPassword: sm2.doEncrypt(values.newPassword, SM2CODE, 0),
            validNewPassword: sm2.doEncrypt(values.validNewPassword, SM2CODE, 0),
          },
        })
          .then((res) => {
            if (res.code === 200) {
              Global.user = Object.assign(user, {
                ...values,
              })
              token.clear()
              message.success('密码修改成功，请重新登录')
              setEditPassword(false)
              passwordForm.resetFields()
            }
          })
          .catch(() => {
            // message.error(e?.data?.msg || '修改失败')
          })
          .finally(() => {
            setSaveLoading(false)
            setLoading(false)
          })
      })
      .catch(() => {
        setSaveLoading(false)
        setLoading(false)
      })
  }
  return (
    <div>
      <div>
        <Form
          labelAlign="left"
          onFinish={(value) => {
            !loading && handleLogin(value)
          }}
          validateTrigger="onBlur"
          form={form}
          style={{
            width: '100%',
          }}
        >
          <Form.Item
            name="accountName"
            validateStatus={checkUsername.validateStatus}
            help={checkUsername.help}
            rules={[
              {
                validateTrigger: 'onBlur',
                validator: checkUserName,
              },
            ]}
          >
            <Input
              size="large"
              placeholder="请输入手机号/邮箱"
              onChange={(e) => {
                setVerifyPass('');
                form.setFieldsValue({captchaVerification: ''});
                setLoginInfo({
                  ...loginInfo,
                  accountName: e.target.value,
                })
              }}
            />
          </Form.Item>
          <Form.Item
            name="password"
            rules={[
              {
                required: true,
                message: '密码必填',
              },
              {
                min: 8,
                max: 16,
                message: '密码长度8~16位',
              },
              {
                pattern: /^.*(?=.*\d)(?=.*[A-Z])(?=.*[a-z]).*$/,
                message: '必须包含大写字母小写字母和数字',
              },
            ]}
          >
            <Input
              size="large"
              type="password"
              placeholder="请输入您的密码"
              onChange={(e) => {
                setVerifyPass('');
                form.setFieldsValue({captchaVerification: ''});
                setLoginInfo({
                  ...loginInfo,
                  password: e.target.value,
                })
              }}
            />
          </Form.Item>
          <Form.Item
            name="captchaVerification"
            rules={[
              {
                required: true,
                message: '请进行验证',
              },
            ]}
          >
            <Verify
              name="captchaVerification"
              disabled={!(loginInfo.accountName && loginInfo.password)}
              form={form}
              update={setVerifyPass}
              isVerifyPass={!!verifyPass}
            />
          </Form.Item>
          <Form.Item>
            <Button
              size="large"
              style={{
                width: '100%',
                height: 42,
                marginTop: '40px',
              }}
              type="primary"
              htmlType="submit"
              loading={loading}
            >
              登录
            </Button>
          </Form.Item>
        </Form>
      </div>

      {editPassword && (
        <Modal
          visible={editPassword}
          title="修改密码"
          maskClosable={false}
          keyboard={false}
          confirmLoading={saveLoading}
          destroyOnClose
          onOk={() => {
            doEditPassword()
          }}
          onCancel={() => {
            passwordForm.resetFields()
            setPassword('')
            setEditPassword(false)
            setLoading(false)
          }}
        >
          <div
            style={{
              fontSize: 14,
              color: 'gray',
              marginBottom: '1rem',
            }}
          >
            您的密码已失效，请立即修改
          </div>
          <Form form={passwordForm} {...formItemLayout}>
            <Form.Item
              name="oldPassword"
              label="验证密码"
              rules={[
                {
                  required: true,
                  message: '请输入新密码',
                },
              ]}
            >
              <Input size="large" type="password" placeholder="请输入当前登录密码"/>
            </Form.Item>
            <Form.Item
              name="newPassword"
              label="新密码"
              extra={showNotice && <PasswordNotice password={password || ''}/>}
              rules={[
                {
                  required: true,
                  message: '请输入新密码',
                },
                {
                  min: 8,
                  max: 16,
                  message: '密码长度8~16位',
                },
                {
                  pattern: /^.*(?=.*\d)(?=.*[A-Z])(?=.*[a-z]).*$/,
                  message: '必须包含大写字母小写字母和数字',
                },
                {
                  pattern: /((?=[\x21-\x7e]+)[^A-Za-z0-9])/,
                  message: '必须包含特殊字符',
                },
              ]}
            >
              <Input
                onFocus={() => {
                  setShowNotice(true)
                }}
                onBlur={() => {
                  setShowNotice(false)
                }}
                onChange={(dom) => {
                  setPassword(dom.target.value)
                }}
                size="large"
                type="password"
                placeholder="请输入新密码"
              />
            </Form.Item>
            <Form.Item
              name="validNewPassword"
              label="确认密码"
              validateStatus={pwdValidate.validateStatus}
              help={pwdValidate.help}
            >
              <Input
                onChange={(e: any) => {
                  const values = passwordForm.getFieldsValue(['newPassword'])
                  if (!e.currentTarget.value) {
                    setPwdValidate({
                      validateStatus: 'error',
                      help: '请再次输入密码',
                    })
                  } else if (e.currentTarget.value !== values.newPassword) {
                    setPwdValidate({
                      validateStatus: 'error',
                      help: '两次密码不一致',
                    })
                  } else {
                    setPwdValidate({
                      validateStatus: undefined,
                      help: '',
                    })
                  }
                }}
                size="large"
                type="password"
                placeholder="请在此输入密码"
              />
            </Form.Item>
          </Form>
        </Modal>
      )}
    </div>
  )
}

export default AccountLogin
