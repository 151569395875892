import React, { useState } from 'react'
import { Table, Tabs } from 'antd'
import moment from 'moment'
import { PowerStationDetail } from '../type'
import { INVERTER_COLUMNS_NOUNIT, METER_COLUMNS_NOUNIT } from '../constants'
import Global from "../../../../global";

import '../index.less'

const { TabPane } = Tabs

type IProps = {
  detail: PowerStationDetail
}

const PowerStationEquipment: React.FC<IProps> = React.memo((props) => {
  const [tabKey, setTabKey] = useState<string>('0')

  const { detail } = props
  const { dataUpdateTime, inverterList, meterList,inverterOnlineNum,inverterOfflineNum,meterOnlineNum,meterOfflineNum } = detail || {}

  const handleTabChange = (key: string) => {
    setTabKey(key)
  }

  const gotoDevice = () => {
    const url = '#/operation-management/equipment-station';
    location.assign(url + `/${detail.powerStationId}`)
    Global.changeMenu.forEach((Fn: any) => {
      Fn(url)
    })
  }

  return (
    <div className="power-station-equipment">
      <div className="power-station-equipment-header">
        <span className="power-station-equipment-title">设备列表</span>
        <span className="power-station-equipment-more" onClick={gotoDevice}>查看更多&nbsp;&gt;</span>
      </div>
      <div className="power-station-equipment-date">
        <span>更新时间：</span>
        <span className="power-station-equipment-date-value">{moment(dataUpdateTime).format('YYYY-MM-DD HH:mm:ss')}</span>
      </div>
      <Tabs activeKey={tabKey} onChange={handleTabChange}>
        <TabPane tab="逆变器" key="0">
          <div className="power-station-equipment-tips">
            <span>一共检索到逆变器在线</span>
            <span className="online">{inverterOnlineNum}</span>
            <span>，离线</span>
            <span className="offline">{inverterOfflineNum}</span>
            <span>台</span>
          </div>
          <Table
            className="station-channel-info-table"
            size="small"
            columns={INVERTER_COLUMNS_NOUNIT}
            dataSource={inverterList || []}
            pagination={false}
            scroll={{ y: 210 }}
          />
        </TabPane>
        <TabPane tab="电表" key="1">
          <div className="power-station-equipment-tips">
            <span>一共检索到电表在线</span>
            <span className="online">{meterOnlineNum}</span>
            <span>，离线</span>
            <span className="offline">{meterOfflineNum}</span>
            <span>台</span>
          </div>
          <Table
            className="station-channel-info-table"
            size="small"
            columns={METER_COLUMNS_NOUNIT}
            dataSource={meterList || []}
            pagination={false}
            scroll={{ y: 210 }}
          />
        </TabPane>
      </Tabs>
    </div>
  )
})

export default PowerStationEquipment
