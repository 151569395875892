import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Button, Space } from 'antd'
import { PlusOutlined } from '@ant-design/icons'

import '../../index.less'

export type IProps = {
  loading?: boolean
}

const ToolBar = React.memo((props: IProps) => {
  const navigate = useNavigate()

  const { loading } = props

  const handleCreate = () => {
    navigate('/operation-management/power-station/create')
  }

  return (
    <Space size={10}>
      {/* <Button loading={loading} size="large" icon={<PlusOutlined />}>
        下载
      </Button>
      <Button loading={loading} size="large" icon={<PlusOutlined />}>
        批量导入
      </Button> */}
      <Button loading={loading} type="primary" size="large" icon={<PlusOutlined />} onClick={handleCreate}>
        新建电站
      </Button>
    </Space>
  )
})

export default ToolBar
