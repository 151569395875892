import { PlusOutlined } from '@ant-design/icons'
import { Button, Card, Form, Image, Input, Menu, message, Modal, Popover, Space, Table, Tag, Tooltip } from 'antd'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { ProgramManageCreator } from './create'

import './index.less'
import { ProjectServiceHistory, ResourceService } from '../../../apis/project-service-history'
import { BreadcrumbObservable, SideMenuObservable } from '../../../application'
import Global, { CustomIcons } from '../../../global'
import { NAME_EXP } from '../../../utils/constant'
import { ProjectApi } from '../../../services'
import ProjectManageControllerApi from '../../../services/api/ProjectManageControllerApi'

let query = {
  name: '',
  operateName: '',
  tpnSort: 0,
  fsnSort: 0,
  createTimeSort: 0,
  updateTimeSort: 0,
  operateNameSort: 0,
  pageNum: 1,
  pageSize: 20,
}

enum SourceName {
  '内部' = 1,
  '外部' = 2,
}

export const ProgramManageList = () => {
  const nav = useNavigate()
  const [dataSource, setDataSource] = useState<ProgramModel[]>([])
  const [total, setTotal] = useState<number>(0)
  const [modal, contextHolder] = Modal.useModal()

  const [createForm] = Form.useForm()
  const [renameForm] = Form.useForm()
  const [deleteForm] = Form.useForm()

  const [projectCount, setProjectCount] = useState(0)

  const [nameFilter, setNameFilter] = useState('')
  const [creatorFilter, setCreatorFilter] = useState('')

  const [firstLoading, setFirstLoading] = useState(true)

  const [loading, setLoading] = useState(false)

  const ActionMenu = (props: { id: number; name: string; currOperationAuth: Array<string> }) => {
    return (
      <Menu mode="inline" selectable={false}>
        {props?.currOperationAuth?.includes('rename') ? (
          <Menu.Item
            style={{
              padding: 0,
            }}
            onClick={() => {
              renameForm.setFieldsValue({
                rename: props.name,
              })
              Modal.destroyAll()
              Modal.confirm({
                keyboard: false,
                title: '重命名',
                icon: <CustomIcons type="klf-alarm1" />,
                content: (
                  <Form form={renameForm} className="label-top">
                    <Form.Item
                      name="rename"
                      label="请输入项目名称"
                      rules={[
                        {
                          required: true,
                          message: '请输入项目名称',
                        },
                        {
                          pattern: NAME_EXP,
                          message: '1-16个字符,中文、英文、_、数字和-组成',
                        },
                      ]}
                    >
                      <Input placeholder="请输入"></Input>
                    </Form.Item>
                  </Form>
                ),
                width: 500,
                okType: 'primary',
                okText: '确定',
                cancelText: '取消',
                onOk: () => {
                  return new Promise((resolve, reject) => {
                    const { rename } = renameForm.getFieldsValue()
                    renameForm
                      .validateFields()
                      .then(() => {
                        ProjectServiceHistory.rename({
                          projectId: props.id.toString(),
                          root: {
                            name: rename,
                          },
                        })
                          .then((resp) => {
                            resolve(resp)
                            loadingData()
                          })
                          .catch((e) => reject(e))
                      })
                      .catch((e) => {
                        reject(e)
                      })
                  })
                },
              })
            }}
          >
            重命名
          </Menu.Item>
        ) : null}
        {props?.currOperationAuth?.includes('remove') ? (
          <Menu.Item
            style={{
              padding: 0,
            }}
            onClick={() => {
              deleteForm.resetFields()
              Modal.destroyAll()
              Modal.confirm({
                keyboard: false,
                title: '删除项目',
                icon: <CustomIcons type="klf-alarm1" />,
                content: (
                  <div>
                    {/* <p>确认删除项目？该项目下所有资源将无法恢复</p>
                    <p>
                      请输入此项目的名称 &quot;<strong>{props.name}</strong>&quot; 以确定删除
                    </p> */}
                    <p>确认删除项目？删除项目后，仅组织管理员可在后台管理的回收站中恢复项目</p>
                    <p>
                      请输入该项目名称“<strong>{props.name}</strong>”以确认删除
                    </p>
                    <Form form={deleteForm}>
                      <Form.Item
                        style={{
                          marginBottom: 0,
                        }}
                        name="projectName"
                        required
                        rules={[
                          {
                            required: true,
                            message: '请输入此项目的名称',
                          },
                        ]}
                      >
                        <Input placeholder="请输入"></Input>
                      </Form.Item>
                    </Form>
                  </div>
                ),
                width: 500,
                okType: 'primary',
                okText: '确定',
                okButtonProps: {
                  danger: true,
                },
                onOk: () => {
                  const { projectName } = deleteForm.getFieldsValue()
                  return new Promise((resolve, reject) => {
                    if (projectName !== props.name) {
                      message.error('请输入正确的项目名称').then()
                      reject()
                    } else {
                      deleteForm
                        .validateFields()
                        .then(() => {
                          ProjectManageControllerApi.i3060ProjectManageDeleteProjectIdPost(
                            {
                              type: '1',
                              projectId: props.id.toString(),
                            },
                            {
                              type: '1',
                              projectId: props.id.toString(),
                            }
                          )
                            .then((resp) => {
                              loadingData()
                              resolve(resp)
                            })
                            .catch((e) => reject(e))
                        })
                        .catch((e) => reject(e))
                    }
                  })
                },
                cancelText: '取消',
                cancelButtonProps: {
                  danger: true,
                },
              })
            }}
          >
            删除
          </Menu.Item>
        ) : null}
      </Menu>
    )
  }

  useEffect(() => {
    query = {
      name: '',
      operateName: '',
      tpnSort: 0,
      fsnSort: 0,
      createTimeSort: 0,
      updateTimeSort: 0,
      operateNameSort: 0,
      pageNum: 1,
      pageSize: 20,
    }

    loadingData()
    SideMenuObservable.next(false)
    BreadcrumbObservable.next([
      {
        name: '项目管理',
        url: '/program/manage',
      },
    ])

    if (location.hash.indexOf('add=1') > -1) {
      createProject(true)
    }
  }, [])

  const loadingData = () => {
    setLoading(true)

    const projectService = new ProjectApi()

    projectService
      .i3060ProjectCountGet()
      .then((resp: any) => {
        setProjectCount(resp)
      })
      .catch((e) => {
        console.log(e)
      })

    projectService
      .i3060ProjectListGet({
        page: query.pageNum.toString(),
        size: query.pageSize.toString(),
        name: query.name,
        operateName: query.operateName,
        tpnSort: query.tpnSort.toString(),
        fsnSort: query.fsnSort.toString(),
        createTimeSort: query.createTimeSort.toString(),
        updateTimeSort: query.updateTimeSort.toString(),
        operateNameSort: query.operateNameSort.toString(),
      })
      .then((resp: any) => {
        const data = resp.data
        query.pageNum = Math.floor(data.page)
        query.pageSize = Math.floor(data.size)
        setDataSource(data.data)
        setTotal(parseInt(data.total.toString()))
      })
      .catch((e) => {
        console.log(e)
      })
      .finally(() => {
        setLoading(false)
        setFirstLoading(false)
      })
  }

  function onChange(pagination: any, filters: any, sorter: any) {
    const { order, field } = sorter
    const { current, pageSize } = pagination
    query.pageSize = pageSize
    query.pageNum = current

    query.tpnSort = 0
    query.fsnSort = 0
    query.createTimeSort = 0
    query.updateTimeSort = 0
    query.operateNameSort = 0

    if (order) {
      let operation = 0
      switch (order) {
        case 'ascend':
          operation = 1
          break
        case 'descend':
          operation = 2
          break
      }
      switch (field) {
        case 'technicalProposalNum':
          query.tpnSort = operation
          break
        case 'financeSchemeNum':
          query.fsnSort = operation
          break
        case 'createTime':
          query.createTimeSort = operation
          break
        case 'updateTime':
          query.updateTimeSort = operation
          break
        case 'creator':
          query.operateNameSort = operation
          break
      }
    }
    loadingData()
  }

  const columns = [
    {
      title: '项目名称',
      dataIndex: 'name',
      key: 'name',
      width: 180,
      fixed: 'left' as any,
      onFilterDropdownVisibleChange: (visible: boolean) => {
        if (!visible) {
          setNameFilter(query.name)
        }
      },
      filterIcon: (
        <CustomIcons
          type="klf-search"
          style={{
            color: query.name ? '#1ec692' : '#bfbfbf',
          }}
        />
      ),
      filterDropdown: ({ confirm, visible }: any) => {
        if (!visible) {
          ;(window as any).closeDropdown = null
          return null
        }
        setTimeout(() => {
          ;(window as any).closeDropdown = confirm
        })
        return (
          <div
            style={{
              padding: '.5rem 1rem',
            }}
          >
            <Input
              id="nameFilter"
              value={nameFilter}
              placeholder="请输入项目名称"
              onChange={(dom) => {
                setNameFilter(dom.target.value)
              }}
              onBlur={(dom) => {
                setNameFilter(dom.target.value)
              }}
              onPressEnter={() => {
                query.name = nameFilter
                query.pageNum = 1
                loadingData()
                confirm?.()
              }}
            />
            <div
              style={{
                marginTop: '.5rem',
                textAlign: 'right',
              }}
            >
              <Button
                onClick={() => {
                  setNameFilter('')
                }}
              >
                重置
              </Button>
              <Button
                type="primary"
                style={{
                  marginLeft: '.5rem',
                }}
                onClick={() => {
                  query.name = nameFilter
                  loadingData()
                  confirm?.()
                }}
              >
                确定
              </Button>
            </div>
          </div>
        )
      },
      render: (res: string, row: any) => (
        <Space>
          {/* <Tooltip title={res} key={`name-${res}`}>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Link className="single-line" to={`/program/detail/${row.id}`}>
              {res}
            </Link>
          </div>
        </Tooltip> */}
          <Tooltip title={res} key={`name-${res}`}>
            <Link className="single-line" to={`/program/detail/${row.id}`}>
              {res}
            </Link>
          </Tooltip>
          {row?.source == 2 ? (
            <Tooltip title={row?.tenantName} key={`name-${row?.tenantName}`}>
              <Tag color="pink">{SourceName[row?.source]}</Tag>
            </Tooltip>
          ) : null}
        </Space>
      ),
    },
    {
      title: '技术方案数',
      dataIndex: 'technicalProposalNum',
      key: 'technicalProposalNum',
      render: (res: number, row: any) => (
        <Link to={`/program/detail/${row.id}`}>
          <Tag color="green" key={`techplans-${res}`}>
            {res}
          </Tag>
        </Link>
      ),
      sorter: true,
      width: 120,
      // sorter: (a: ProgramModel, b: ProgramModel) => a.techPlanCount - b.techPlanCount,
    },
    {
      title: '财务方案数',
      dataIndex: 'financeSchemeNum',
      key: 'financeSchemeNum',
      render: (res: number, row: any) => (
        <Link to={`/program/detail/${row.id}/fin`}>
          <Tag color="green" key={`finplans-${res}`}>
            {res}
          </Tag>
        </Link>
      ),
      sorter: true,
      width: 120,
      // sorter: (a: ProgramModel, b: ProgramModel) => a.finPlanCount - b.finPlanCount,
    },
    {
      title: '创建时间',
      dataIndex: 'createTime',
      key: 'createTime',
      width: 140,
      render: (res: Date) => <div key={`createtime-${res}`}>{moment(res).format('YYYY-MM-DD HH:mm')}</div>,
      sorter: true,
      // sorter: (a: ProgramModel, b: ProgramModel) => a.createTime.getTime?.() - b.createTime.getTime?.(),
    },
    {
      title: '更新时间',
      dataIndex: 'updateTime',
      key: 'updateTime',
      width: 140,
      render: (res: Date) => <div key={`updatetime-${res}`}>{moment(res).format('YYYY-MM-DD HH:mm')}</div>,
      sorter: true,
      // sorter: (a: ProgramModel, b: ProgramModel) => a.updateTime.getTime?.() - b.updateTime.getTime?.(),
    },
    {
      title: '创建人',
      dataIndex: 'createUserName',
      key: 'creator',
      sorter: true,
      width: 120,
      filterIcon: (
        <CustomIcons
          type="klf-search"
          style={{
            color: query.operateName ? '#1ec692' : '#bfbfbf',
          }}
        />
      ),
      onFilterDropdownVisibleChange: (visible: boolean) => {
        if (!visible) {
          setCreatorFilter(query.operateName)
        }
      },
      filterDropdown: ({ confirm, visible }: any) => {
        if (!visible) {
          ;(window as any).closeDropdown = null
          return null
        }
        setTimeout(() => {
          ;(window as any).closeDropdown = confirm
        })
        return (
          <div
            style={{
              padding: '.5rem 1rem',
            }}
          >
            <Input
              placeholder="请输入创建人"
              value={creatorFilter}
              onBlur={(dom) => {
                setCreatorFilter(dom.target.value)
              }}
              onChange={(dom) => {
                setCreatorFilter(dom.target.value)
              }}
              onPressEnter={() => {
                query.operateName = creatorFilter
                query.pageNum = 1
                loadingData()
                confirm?.()
              }}
            />
            <div
              style={{
                marginTop: '.5rem',
                textAlign: 'right',
              }}
            >
              <Button
                onClick={() => {
                  setCreatorFilter('')
                }}
              >
                重置
              </Button>
              <Button
                type="primary"
                style={{
                  marginLeft: '.5rem',
                }}
                onClick={() => {
                  query.operateName = creatorFilter
                  loadingData()
                  confirm?.()
                }}
              >
                确定
              </Button>
            </div>
          </div>
        )
      },
      render: (res: string) => <div key={`creator-${res}`}>{res}</div>,
    },
    {
      title: '操作',
      key: 'action',
      width: 60,
      fixed: 'right' as any,
      render: (text: any, record: any) => {
        const obj = false
        if (obj) {
          return (
            <div key={`action-${record.id}`}>
              {record.name} {text}
            </div>
          )
        }
        return record?.currOperationAuth?.includes('rename') || record?.currOperationAuth?.includes('remove') ? (
          <Popover
            placement="leftTop"
            overlayClassName="menu-popover-overlay"
            key={`action-${record.id}`}
            content={<ActionMenu id={record.id} name={record.name} currOperationAuth={record.currOperationAuth} />}
          >
            <Button
              type="link"
              className="ant-dropdown-link"
              onClick={(e) => e.preventDefault()}
              icon={<CustomIcons type="klf-more" />}
            />
          </Popover>
        ) : null
      },
    },
  ]

  const urlRes = Object.fromEntries(
    location.hash.split('?')[1]
      ? location.hash
          .split('?')[1]
          .split('&')
          .map((o) => o.split('=').map(decodeURIComponent))
      : []
  )

  const createProject = (checkResId = false) => {
    createForm.resetFields()
    if (checkResId && urlRes?.resourceId) {
      ResourceService.info({
        id: urlRes.resourceId,
      }).then((response) => {
        const resp = response.data
        const res = {
          name: resp.name,
          city: resp.adcode,
          address: resp.name,
          addressObject: JSON.stringify({
            name: resp.name,
            district: '',
            location: resp.lng + ',' + resp.lat,
            adcode: resp.adcode,
          }),
          lng: resp.lng,
          lat: resp.lat,
          resourceId: urlRes.resourceId,
        }

        const mdl = modal.info({
          title: <h2>新建项目</h2>,
          closable: true,
          content: (
            <ProgramManageCreator
              form={createForm}
              resourceId={urlRes?.resourceId}
              res={res}
              onClose={(reload: any) => {
                if (reload) {
                  nav(`/program/detail/${reload.data}`)
                } else {
                  mdl.destroy()
                }
              }}
            />
          ),
          width: 480,
          okCancel: true,
          keyboard: false,
          okButtonProps: {
            style: {
              display: 'none',
            },
          },
          cancelButtonProps: {
            style: {
              display: 'none',
            },
          },
        })
      })
    } else {
      const mdl = modal.info({
        title: <h2>新建项目</h2>,
        closable: true,
        content: (
          <ProgramManageCreator
            form={createForm}
            onClose={(reload: any) => {
              if (reload) {
                nav(`/program/detail/${reload.data}`)
              } else {
                mdl.destroy()
              }
            }}
          />
        ),
        width: 480,
        okCancel: true,
        keyboard: false,
        okButtonProps: {
          style: {
            display: 'none',
          },
        },
        cancelButtonProps: {
          style: {
            display: 'none',
          },
        },
      })
    }
  }

  return (
    <Card
      title={
        <div
          style={{
            fontWeight: 600,
            fontSize: 20,
          }}
        >
          项目列表
        </div>
      }
      className="no-border-bottom-card"
      extra={
        !loading &&
        (query.name || query.operateName || dataSource.length > 0) && (
          <div>
            <Button
              disabled={projectCount >= Global.role.projectNum}
              type="primary"
              className="custom-margin-left"
              onClick={() => {
                createProject()
              }}
            >
              <PlusOutlined />
              新建项目
            </Button>
          </div>
        )
      }
      bodyStyle={{
        height: 'calc(100vh - 190px)',
        paddingTop: 0,
      }}
    >
      <div
        className="manage-table"
        style={{
          height: '100%',
          position: 'relative',
        }}
      >
        {!query.name && !firstLoading && !loading && dataSource.length === 0 && !query.operateName && (
          <div
            style={{
              height: '100%',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <div
              style={{
                textAlign: 'center',
              }}
            >
              <div>
                <Image width={340} src={'./assets/program-empty.png'} alt="" preview={false} />
              </div>
              <Button
                type="primary"
                style={{
                  width: 200,
                  height: 48,
                  fontSize: 20,
                }}
                onClick={() => {
                  createProject()
                }}
              >
                新建项目
              </Button>
            </div>
          </div>
        )}
        {!firstLoading && (dataSource.length > 0 || query.name || query.operateName) && (
          <Table
            className={`full-screen-table right-actions ${total > query.pageSize ? 'jump-right' : ''}`}
            style={{ flexGrow: 1 }}
            scroll={{ x: '100%', y: 'calc(100vh - 360px)' }}
            dataSource={dataSource}
            columns={columns}
            onChange={onChange}
            loading={loading}
            pagination={{
              total,
              showSizeChanger: true,
              showQuickJumper: true,
              defaultPageSize: 20,
              current: query.pageNum,
              pageSize: query.pageSize,
              pageSizeOptions: ['10', '20', '50'],
              showTotal: (total) => `共 ${total} 条`,
            }}
          />
        )}
        <div>{contextHolder}</div>
      </div>
    </Card>
  )
}

export interface ProgramModel {
  id: number
  name: string
  techPlanCount: number
  finPlanCount: number
  createTime: Date
  updateTime: Date
  creator: string
}
