import React from 'react'
import { Spin, Card, Button, Modal, Form, message } from 'antd'
import { useParams, useNavigate } from 'react-router-dom'
import { ProjectServiceHistory } from '../../../../../apis/project-service-history'
import Global, { CustomIcons } from '../../../../../global'
import { LineChart } from '../components/LineCharts'
import { Electric } from '../components/Electric'
import { ELEC_CHARTS_COLORS } from '../../../../../utils/constant'
// import { ProjectApi } from '../../../../../services'
import { ElectricityObservable } from '../index'
import moment from 'moment'
import { formatConvertEleData } from '../creat-electricity'

const translateMap: { [key: string]: string } = {
  electricityPrice: '电价版本',
  userType: '用电类型',
  voltageLevel: '电压等级',
  isTime: '是否分时',
  chargeType: '收费方式',
  basicEnergyChargePattern: '基本电费收费方式',
}
export const ElectricityDetail = () => {
  const [loading, setLoading] = React.useState(false)
  // 电价名称
  const [customName, setCustomName] = React.useState<string>('')
  // 电价配置信息
  const [config, setConfig] = React.useState<{ [key: string]: any }>()
  //电价字段信息
  const [result, setResult] = React.useState<any>({
    electricityPrice: '',
    userType: '',
    voltageLevel: '',
    isTime: '',
    chargeType: '',
    basicEnergyChargePattern: '',
  })
  const params = useParams()
  // 图表颜色配置
  const colors = ELEC_CHARTS_COLORS

  // 折线图数据
  const [chartData, setChartData] = React.useState<any>()
  const [fsInfo, setFsInfo] = React.useState<any>()

  // const [customRefTechnicalList, setCustomRefTechnicalList] = React.useState<{ [key: string]: any }>([]);
  const navigate = useNavigate()

  const auth = Global.projectAuth || {}
  console.log("🚀 ~ file: index.tsx ~ line 49 ~ ElectricityDetail ~ auth", auth)
  const hasAuth = !!auth.electricity_price_scheme

  React.useEffect(() => {
    // 获取电价信息
    getResult(() => {
      // 获取字段配置信息
      getConfig()
    })
    // queryCustomRefTechnicalName()
  }, [params.customId])

  // 获取电价信息
  const getResult = (callback?: Function) => {
    setLoading(true)
    ProjectServiceHistory.queryCustom({
      projectId: params.id!,
      customId: params.customId!,
    })
      .then((resp) => {
        const resData = resp.data[0]
        setCustomName(resData.customName)
        setResult(resData.reqJson)

        const getFsInfo = (name?: number) => {
          const data = formatConvertEleData(resData.reqJson)
          const valArr = data.seasonReqList
          const res = valArr
            .filter((o, idx) => (name !== undefined ? idx === name : true))
            .map((o) => {
              return o.timeSharePrices.map((oo) => ({
                ...oo,
                name: o.seasonName,
              }))
            })
          return res
        }

        const val = getFsInfo()

        console.log(val, 'aaaaaa')

        const charts = { ...resData.charts }
        const time = { ...resData.time }

        Object.keys(charts).forEach((o) => {
          charts[o].push(charts[o][0])
        })
        Object.keys(time).forEach((o) => {
          time[o].push('24:00')
        })

        setChartData({
          updateTime: resData.updateTime,
          charts,
          time,
        })

        setFsInfo(val)
        callback?.()

        setLoading(false)
      })
      .catch(() => {
        setLoading(false)
      })
  }

  // 获取字段配置信息
  const getConfig = () => {
    ProjectServiceHistory.option({
      projectId: params.id!,
    })
      .then((resp) => {
        const cfg: any = {}
        Object.keys(resp.data).forEach((key) => {
          const val = resp.data[key]
          if (Array.isArray(val)) {
            const obj: any = {}
            val.forEach((o) => {
              obj[o] = o === true ? '是' : o === false ? '否' : o
            })
            cfg[key] = obj
          } else {
            cfg[key] = resp.data[key]
          }
        })

        const { electricityPrice, userType, voltageLevel, isTime, chargeType, basicEnergyChargePattern } = cfg

        setConfig({
          electricityPrice,
          userType,
          voltageLevel,
          isTime,
          chargeType,
          basicEnergyChargePattern,
        })
      })
      .catch(() => {
        setConfig({ ...config })
      })
      .finally(() => {})
  }

  // // 查询自定义电价关联的技术方案名称
  // const queryCustomRefTechnicalName = () => {

  //   const projectService = new ProjectApi();

  //   projectService.i3060BorderElectricityQueryCustomRefTechnicalNameCustomIdGet({
  //     customId: params.customId!
  //   }).then(({data =[]}) => {
  //     setCustomRefTechnicalList(data)
  //   })
  // }
  // 是否显示 电价
  const showBasicEnergyCharge = () => {
    if (result?.chargeType == '1') {
      return false
    }
    return true
  }

  // 获取设置的值
  const getStringsWith = (configKey: string, val: any) => {
    if (config) {
      return config[configKey][val]
    }
    return ''
  }

  const handleDeleteItems = () => {
    ProjectServiceHistory.deleteCustom({
      customId: params.customId!,
    }).then((res: any) => {
      if (res.code === 200) {
        message.success('删除成功')
        navigate(`/program/edge/${params.id!}/electricity/catalogue`)
        ElectricityObservable.next('load')
      }
    })
  }

  const deleteTips = (text) => {
    return (
      <div>
        <p>
          确定{text}
          {customName}?
        </p>
        <p>项目下与之相关联的技术方案结果全部失效（归档方案解除归档），财务方案被全部删除</p>
      </div>
    )
  }

  const handleConfirm = (text, callback?: Function) => {
    Modal.confirm({
      title: <span>{text}自定义电价</span>,
      icon: <CustomIcons type="klf-alarm1" />,
      content: deleteTips(text),
      okText: '确认',
      cancelText: '取消',
      onOk: () => callback?.(),
    })
  }

  const electricData =
    result?.seasonReqList?.map((v: any, index: number) => ({
      name: v?.seasonName,
      idx: index,
      values: v.timeFrames?.map((o) => {
        return [moment(`2000-${o.startMd}`, 'YYYY-MM-DD'), moment(`2000-${o.endMd}`, 'YYYY-MM-DD')]
      }),
    })) || []

  return (
    <Spin spinning={loading}>
      <Card
        className="no-padding-card electricity-info-card"
        title={customName}
        bordered={false}
        style={{
          marginTop: '14px',
        }}
        headStyle={{
          padding: 0,
          minHeight: '40px',
          lineHeight: '40px',
          fontSize: '18px',
          fontWeight: 'bold',
          color: '#262E3F',
        }}
        extra={
          <>
            <Button
              type="default"
              disabled={!hasAuth}
              danger
              style={{
                marginRight: '10px',
              }}
              onClick={() => {
                handleConfirm('删除', handleDeleteItems)
              }}
            >
              删除
            </Button>
            <Button
              type="primary"
              disabled={!hasAuth}
              ghost
              icon={<CustomIcons type={'klf-edit1'} />}
              onClick={() => {
                handleConfirm('编辑', () => {
                  navigate(`/program/edge/${params.id!}/electricity/custom/edit/${params.customId!}`)
                })
              }}
            >
              编辑
            </Button>
          </>
        }
      >
        <div
          style={{
            display: 'flex',
            marginTop: '20px',
            justifyContent: 'space-between',
            borderBottom: '1px dashed #EBEBEB',
          }}
        >
          {result.chargeType == '1' ? (
            <div
              style={{
                lineHeight: '40px',
                marginBottom: '10px',
              }}
            >
              <div
                style={{
                  fontSize: '16px',
                  color: '#262E3F',
                }}
              >
                容量电价：
                <span
                  style={{
                    fontSize: '14px',
                    color: '#767F95',
                  }}
                >
                  0元/kVA·月
                </span>
              </div>
            </div>
          ) : (
            <div
              style={{
                lineHeight: '40px',
                marginBottom: '10px',
              }}
            >
              {showBasicEnergyCharge() && (
                <div
                  style={{
                    fontSize: '16px',
                    color: '#262E3F',
                  }}
                >
                  {result?.basicEnergyChargePattern?.toString() === '2' ? '需量' : '容量'}电价：
                  <span
                    style={{
                      fontSize: '14px',
                      color: '#767F95',
                    }}
                  >
                    {result?.capacityCharge || result?.demandCharge || 0}元/
                    {result?.basicEnergyChargePattern?.toString() === '2' ? 'kW·月' : 'kVA·月'}
                  </span>
                </div>
              )}
            </div>
          )}
          <div
            style={{
              width: '442px',
            }}
          >
            {config &&
              Object.keys(result).map(
                (key) =>
                  translateMap[key] && (
                    <Form.Item
                      key={key}
                      label={`${translateMap[key]}`}
                      style={{
                        marginBottom: 0,
                      }}
                    >
                      <span style={{ lineHeight: '40px' }}>{getStringsWith(key, result[key])}</span>
                    </Form.Item>
                  )
              )}
          </div>
        </div>
      </Card>
      <div
        style={{
          flex: 1,
        }}
      >
        <div
          style={{
            fontSize: '16px',
            color: '#262E3F',
            height: '40px',
            lineHeight: '40px',
            marginBottom: '24px',
          }}
        >
          电量电价
        </div>
        <Electric colors={colors} data={electricData} />
        {chartData && <LineChart isEdit={true} colors={colors} chartInfo={chartData} fsInfo={fsInfo} />}
      </div>
    </Spin>
  )
}
