import React from 'react'
import { Avatar } from 'antd'

export interface IProfilePhoto {
  id?: number | null
  url?: string | null
  size?: number
  name?: string
  user?: object
  handleClick?: Function
}

// 头像
export default function ProfilePhoto({ url, size = 40, name = '', handleClick }: IProfilePhoto) {
  return (
    <div
      className="profile-photo"
      onClick={() => {
        handleClick && handleClick()
      }}
    >
      <Avatar size={size} src={url} style={url ? {} : { backgroundColor: '#42d4a1' }}>
        {name.substring(0, 2)}
      </Avatar>
    </div>
  )
}
