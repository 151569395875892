import React from 'react'
import { Link } from 'react-router-dom'
import { Button, Popover } from 'antd'

import { CustomIcons } from '../../global'
import './index.less'

export interface KLFBTNConfig {
  iconUrl?: string
  btnType?: string
  title: string 
  key: string
  finished: boolean
  disabled?: boolean
  maxWidth?: number | string
}

export const KLFButton = (props: {
  iconUrl?: string
  maxWidth?: number | string
  btnType: string
  title: string 
  k: string
  selected: boolean
  finished: boolean
  disabled: boolean
  clicked: () => void
}) => {
  return (
    <Link
      to={props.k || ''}
      style={{
        pointerEvents: !props.disabled ? 'all' : 'none',
        cursor: !props.disabled ? 'pointer' : 'not-allowed',
      }}
    >
      {props.title && props.title.length >= 8 ?
      <Popover content={props.title} trigger="hover">
          <Button 
              className={`${props.selected && 'active'} ant-btn-${props.btnType} klf-btn`}
              disabled={props.disabled}
              onClick={() => {
                  props.clicked()
              }}
              icon={props.iconUrl && <CustomIcons type={props.iconUrl}  style={{ fontSize: 12 }}/>}
              style={{
                maxWidth: props.maxWidth || '120px'
              }}
          >
              {props.title}
              {props.finished && <img className="finish-icon" src="assets/icon-finish.svg" alt="finished" />}
          </Button>
      </Popover>
      :
      <Button 
          className={`${props.selected && 'active'} ant-btn-${props.btnType} klf-btn`}
          disabled={props.disabled}
          onClick={() => {
              props.clicked()
          }}
          icon={props.iconUrl && <CustomIcons type={props.iconUrl}  style={{ fontSize: 12 }}/>}
          style={{
            maxWidth: props.maxWidth || '120px'
          }}
      >
          {props.title}
          {props.finished && <img className="finish-icon" src="assets/icon-finish.svg" alt="finished" />}
      </Button>
      }
    </Link>
  )
}

export const KLFButtons = (props: { config: KLFBTNConfig[]; activatedKey: string; callback: (key: string) => void }) => {
  const { config, activatedKey, callback } = props

  return (
    <div className="btn-content">
      {config.map((o) => {
        return (
          <KLFButton
            key={o.key}
            title={o.title}
            k={o.key}
            selected={activatedKey === o.key}
            finished={o.finished}
            disabled={o.disabled || false}
            clicked={() => {
              callback(o.key)
            }}
            iconUrl={o.iconUrl}
            btnType={o.btnType || "default"}
            maxWidth={o.maxWidth}
          />
        )
      })}
    </div>
  )
}
