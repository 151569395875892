import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { BreadcrumbObservable, SideMenuObservable } from '../../../application'

export default () => {
  const nav = useNavigate()
  const location = useLocation()
  const [url, setUrl] = React.useState<string>('')

  // 设置面包屑
  React.useEffect(() => {
    SideMenuObservable.next(false)
    BreadcrumbObservable.next([
      { name: '工程管理', url: `/invest/projectLibrary`, state: { engineering: true } },
      { name: '费用管理', url: '/engineering/cost' },
      {
        name: location.state?.type === 'pay' ? '新增支付' : '新增费用申请',
        url: `/engineering/cost/-1`,
        state: location.state,
      },
    ])
  }, [location.state])

  React.useEffect(() => {
    setUrl(location.state?.type === 'pay' ? 'http://新增支付' : 'http://费用申请')
  }, [location.state])

  React.useEffect(() => {
    ;(window as any).linkToFeeList = function () {
      nav(-1)
    }
  }, [])

  const onLoad = () => {}

  return (
    <iframe
      id="feeFrame"
      name="feeFrame"
      title="feeFrame"
      frameBorder="0"
      width="100%"
      height="100%"
      scrolling="no"
      src={url}
      style={{ overflow: 'visible', borderRadius: 2 }}
      onLoad={onLoad}
    />
  )
}
