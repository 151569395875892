import React, { useEffect, useState } from 'react'
import { GaodeMap, Scene } from '@antv/l7'
import { Statistic } from 'antd'
import moment from 'moment'
import { getAMap } from '../../../account-detail/components/map-script'
import IconPosition from '../../../../assets/icon-position.png'

import '../index.less'
import { PowerStationDetail } from '../type'

type IProps = {
  detail: PowerStationDetail
}

const PowerStationPosition: React.FC<IProps> = React.memo((props) => {
  const [map, setMap] = useState<any>()

  const { detail } = props
  const { name, address, lat, lng, gridConnectionDate, voltageLevel, installedCapacity } = detail || {}

  const createAMap = () => {
    getAMap().then((AMap) => {
      const lnglat = [116.418261, 39.921984]
      const zoom = 3
      const _map = new AMap.Map('program-create-map', {
        resizeEnable: true,
        center: lnglat,
        zoom,
        mapStyle: 'amap://styles/05e321edfe9e69b5902e4dc372c36fda',
      })

      new Scene({
        id: 'program-create-map',
        map: new GaodeMap({
          mapInstance: _map,
        }),
      })

      setMap(_map)
    })
  }

  useEffect(() => {
    if (map && detail) {
      getAMap().then((AMap: any) => {
        const lngLat = new AMap.LngLat(lng, lat)
        map.setZoom(16)
        map.setCenter(lngLat)
        map.clearMap()
        const icon = new AMap.Icon({
          size: new AMap.Size(24, 24),
          image: IconPosition,
          imageSize: new AMap.Size(24, 24)
        });
        const marker = new AMap.Marker({
          icon,
          position: lngLat,
          title: address,
        })
        map.add(marker)
      })
    } else {
      createAMap()
    }
  }, [map, detail])

  return (
    <div className="power-station-position">
      <div className="power-station-position-title">{name}</div>
      <div className="power-station-position-address">
        <span>{address}</span>
        <img src={IconPosition} alt="" />
        <span className="power-station-position-coordinate">
          {lng}°E,{lat}°N
        </span>
      </div>
      <div className="power-station-position-content">
        <div className="power-station-position-content-left">
          <h6 className="power-station-statistic-title">装机容量</h6>
          <div className="power-station-statistic-footer">
            <Statistic value={installedCapacity} />
            <span className="power-station-statistic-unit">kW</span>
          </div>
        </div>
        <div className="power-station-position-content-right">
          <div className="power-station-position-row">
            <span>电压等级：</span>
            <span className="power-station-position-row-value">{voltageLevel || 0}kV</span>
          </div>
          <div className="power-station-position-row">
            <span>并网日期：</span>
            <span className="power-station-position-row-value">
              {moment(gridConnectionDate).format('YYYY-MM-DD')}
            </span>
          </div>
        </div>
      </div>
      <div id="program-create-map" style={{ width: '100%', height: 236, position: 'relative' }}></div>
    </div>
  )
})

export default PowerStationPosition
