/**
 * project
 * unknown
 *
 * OpenAPI spec version: last
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import ajax, { AjaxPromise, ExtraFetchParams } from '@ajax';
import * as models from '../model/models';

/* tslint:disable:no-unused-variable member-ordering object-literal-shorthand */

/**
  * @description i3060EvaluationGetRoofInfoGet参数
  * @property `code` 区code
  * @property `id` 屋顶id
  */
export interface Paramsi3060EvaluationGetRoofInfoGet {
  // queryParams
  /**
   * 区code
   */
  code: string;
  /**
   * 屋顶id
   */
  id: string;
}
/**
  * @description i3060EvaluationGetRoofV2Get参数
  * @property `[name]` 名字
  * @property `regionCode` 地区code
  * @property `[areaMin]` 面积
  * @property `[areaMax]` 
  * @property `[areaSort]` 
  * @property `times` 时间 取选项的value 多个逗号隔开  必须选到子项选父项为子项全选
  * @property `page` 分页
  * @property `size` 分页
  */
export interface Paramsi3060EvaluationGetRoofV2Get {
  // queryParams
  /**
   * 名字
   */
  name?: string;
  /**
   * 地区code
   */
  regionCode: string;
  /**
   * 面积
   */
  areaMin?: string;
  /**
   * 
   */
  areaMax?: string;
  /**
   * 
   */
  areaSort?: string;
  /**
   * 时间 取选项的value 多个逗号隔开  必须选到子项选父项为子项全选
   */
  times: string;
  /**
   * 分页
   */
  page: string;
  /**
   * 分页
   */
  size: string;
}
/**
  * @description i3060RegionRoofGetRegionDataGet参数
  * @property `[level]` 地区等级，province,city,district 省市区 逗号隔开 可以传单个 也可以不传 默认所有
  */
export interface Paramsi3060RegionRoofGetRegionDataGet {
  // queryParams
  /**
   * 地区等级，province,city,district 省市区 逗号隔开 可以传单个 也可以不传 默认所有
   */
  level?: string;
}
/**
  */
export type ParamsBodyi3060RegionRoofGetRegionRoofSummaryPost = models.I3060RegionRoofGetRegionRoofSummaryBody;

export class EvaluationV2Api {
  protected $basePath = '/api'.replace(/\/$/, '');

  public constructor(basePath?: string) {
    if (basePath !== undefined) {
      this.$basePath = basePath.replace(/\/$/, '');
    }
  }

  /**
   * 
   * @summary 屋顶详情
   * @param params Paramsi3060EvaluationGetRoofInfoGet
   
   * @param opt ajax config
   * @returns models.I3060EvaluationGetRoofInfo
   */
  public i3060EvaluationGetRoofInfoGet = (
    params: Paramsi3060EvaluationGetRoofInfoGet,
    opt?: ExtraFetchParams
  ) : AjaxPromise<models.I3060EvaluationGetRoofInfo>  => {
    const url = this.$basePath + `/i3060/evaluation/getRoofInfo`;
    const p: any = {};
    p.query = {};
    if ('code' in params) p.query.code = params.code;
    ajax.check(params.code, 'code');
    if ('id' in params) p.query.id = params.id;
    ajax.check(params.id, 'id');
    return ajax.ajax({
      ...opt,
      method: 'GET',
      url,
      ...p
    });
  }
  /**
   * 
   * @summary 屋顶扫描
   * @param params Paramsi3060EvaluationGetRoofV2Get
   
   * @param opt ajax config
   * @returns models.I3060EvaluationGetRoofV2
   */
  public i3060EvaluationGetRoofV2Get = (
    params: Paramsi3060EvaluationGetRoofV2Get,
    opt?: ExtraFetchParams
  ) : AjaxPromise<models.I3060EvaluationGetRoofV2>  => {
    const url = this.$basePath + `/i3060/evaluation/getRoofV2`;
    const p: any = {};
    p.query = {};
    if ('name' in params) p.query.name = params.name;
    if ('regionCode' in params) p.query.regionCode = params.regionCode;
    ajax.check(params.regionCode, 'regionCode');
    if ('areaMin' in params) p.query.areaMin = params.areaMin;
    if ('areaMax' in params) p.query.areaMax = params.areaMax;
    if ('areaSort' in params) p.query.areaSort = params.areaSort;
    if ('times' in params) p.query.times = params.times;
    ajax.check(params.times, 'times');
    if ('page' in params) p.query.page = params.page;
    ajax.check(params.page, 'page');
    if ('size' in params) p.query.size = params.size;
    ajax.check(params.size, 'size');
    return ajax.ajax({
      ...opt,
      method: 'GET',
      url,
      ...p
    });
  }
  /**
   * 
   * @summary 获取全国市、区资源详情（不带条件）
   * @param params Paramsi3060RegionRoofGetRegionDataGet
   
   * @param opt ajax config
   * @returns models.I3060EvaluationRegionData
   */
  public i3060RegionRoofGetRegionDataGet = (
    params: Paramsi3060RegionRoofGetRegionDataGet,
    opt?: ExtraFetchParams
  ) : AjaxPromise<models.I3060EvaluationRegionData>  => {
    const url = this.$basePath + `/i3060/region_roof/getRegionData`;
    const p: any = {};
    p.query = {};
    if ('level' in params) p.query.level = params.level;
    return ajax.ajax({
      ...opt,
      method: 'GET',
      url,
      ...p
    });
  }
  /**
   * 
   * @summary 获取全国市、区资源详情
   
   * @param data: ParamsBodyi3060RegionRoofGetRegionRoofSummaryPost
   * @param opt ajax config
   * @returns models.I3060RegionRoofGetRegionRoofSummary
   */
  public i3060RegionRoofGetRegionRoofSummaryPost = (
    
    data: ParamsBodyi3060RegionRoofGetRegionRoofSummaryPost,opt?: ExtraFetchParams
  ) : AjaxPromise<models.I3060RegionRoofGetRegionRoofSummary>  => {
    const url = this.$basePath + `/i3060/region_roof/getRegionRoofSummary`;
    const p: any = {};
    p.data = data;
    return ajax.ajax({
      ...opt,
      method: 'POST',
      url,
      ...p
    });
  }
}

export default new EvaluationV2Api();