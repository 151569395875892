import React, { useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Button, Card, Space } from 'antd'
import { BreadcrumbObservable, SideMenuObservable } from '../../../application'
import BasicInfoForm from './components/basic-info-form'
import { StateContext, SubmitType, IAction, IState } from './components/StateContext'

export default () => {
  const nav = useNavigate()
  const location = useLocation()
  const formRef = React.useRef<any>() // 表单ref
  const [activeTabKey, setActiveTabKey] = useState('1') // 当前Tab
  const [state, dispatch] = React.useReducer(
    (state: IState, { type, payload }: IAction) => {
      switch (type) {
        case 'visibleDraft':
          return {
            ...state,
            visibleDraft: payload,
          }
        case 'loadingDraft':
          return {
            ...state,
            loadingDraft: payload,
          }
        case 'visibleSubmit':
          return {
            ...state,
            visibleSubmit: payload,
          }
        case 'loadingSubmit':
          return {
            ...state,
            loadingSubmit: payload,
          }
        case 'setCurrentProject':
          return {
            ...state,
            currentProject: payload,
          }
        case 'setProjectTypeOptions':
          return {
            ...state,
            projectTypeOptions: payload,
          }
        default:
          return state
      }
    },
    {
      loadingDraft: false, // 保存草稿按钮的状态
      loadingSubmit: false, // 确定按钮的状态
      visibleDraft: false, // 保存草稿按钮是否可见
      visibleSubmit: false, // 确定按钮是否可见
      currentProject: {
        order: [],
      }, // 选中项目信息
      projectTypeOptions: [], // 项目类型
    }
  )

  // 设置面包屑
  React.useEffect(() => {
    SideMenuObservable.next(false)
    BreadcrumbObservable.next([
      { name: '工程管理', url: '/invest/projectLibrary', state: location.state },
      { name: '项目库', url: '/invest/projectLibrary', state: location.state },
      { name: '新建项目', url: `/engineering/projectLibrary/true` },
    ])
  }, [])

  // 切换tab页
  const onTabChange = React.useCallback((key: string) => {
    setActiveTabKey(key as any)
  }, [])

  const tabList = React.useMemo(() => {
    return [{ key: '1', tab: '基本信息' }]
  }, [])

  const content = React.useMemo(() => {
    switch (activeTabKey) {
      case '1':
        return (
          <BasicInfoForm
            componentRef={formRef}
            onNext={() => {
              nav(-1)
            }}
          />
        )

      default:
        return null
    }
  }, [activeTabKey])

  const submit = (options: SubmitType): void => {
    formRef.current.submit?.({ ...options, showMessage: true })
  }

  return (
    <Card
      bodyStyle={{ height: 'calc(100vh - 204px)', overflow: 'auto', padding: 0 }}
      className="right-actions"
      style={{ height: '100%' }}
      tabBarExtraContent={
        <Space>
          {state.visibleDraft ? (
            <Button
              type="primary"
              loading={state.loadingDraft}
              onClick={() => {
                if (state.loadingDraft) return
                submit({
                  onVisibleChange: (visible: boolean) => {
                    dispatch({ type: 'loadingDraft', payload: visible })
                  },
                  isDraft: true,
                  values: {},
                })
              }}
            >
              保存草稿
            </Button>
          ) : null}
          {state.visibleSubmit ? (
            <Button
              type="primary"
              loading={state.loadingSubmit}
              onClick={() => {
                if (state.loadingSubmit) return
                submit({
                  onVisibleChange: (visible: boolean) => {
                    dispatch({ type: 'loadingSubmit', payload: visible })
                  },
                  isDraft: false,
                  values: {},
                })
              }}
            >
              确定
            </Button>
          ) : null}
          <Button onClick={() => nav(-1)}>取消</Button>
        </Space>
      }
      tabList={tabList}
      activeTabKey={activeTabKey}
      onTabChange={onTabChange}
    >
      <StateContext.Provider value={{ state, dispatch }}>{content}</StateContext.Provider>
    </Card>
  )
}
