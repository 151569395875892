import { PlusCircleFilled } from '@ant-design/icons'
import { Button, Dropdown, InputNumber, Menu, Select } from 'antd'
import _ from 'lodash'
import { useEffect, useState } from 'react'
import { CustomIcons } from '../../../../global'

const cellWidth = 160
export const VerticalTable = (props: any) => {
  const [forms, setForms] = useState<{ [key: string]: any }>({})

  useEffect(() => {
    setForms({})
  }, [])

  useEffect(() => {
    const buForms = { ...forms }
    Object.keys(buForms).forEach((key) => {
      if (Object.keys(props.data).indexOf(key) < 0) {
        delete buForms[key]
      }
    })
    Object.keys(props.data).forEach((key) => {
      if (!buForms[key]) {
        buForms[key] = props.data[key]
      }
    })
    setForms({ ...buForms })
    saveValue(buForms)
  }, [props.data])

  const saveValue = (data = forms) => {
    props.onChange?.(data)
  }

  return (
    <div
      style={{
        overflowX: 'auto',
      }}
    >
      {props.lockHeaders?.map((oo: any, idx: number) => (
        <div
          key={`lockHeader-${idx}`}
          style={{
            height: 56,
            backgroundColor: '#F7F6F8',
            width: Object.keys(props.data)?.length * cellWidth + 214 + (props.isEdit ? 50 : 0),
            minWidth: '100%',
            display: 'flex',
            flexDirection: 'row',
          }}
        >
          <div
            style={{
              height: 56,
              width: 214,
              overflow: 'hidden',
              color: '#262E3F',
              lineHeight: '56px',
              paddingLeft: 23,
              fontSize: 14,
            }}
          >
            {oo.value}
          </div>
          {Object.keys(props.data).map((key: string, idx) => {
            const o = props.data[key]
            const { isDefault } = o
            const data = _.get(o, oo.key)
            return (
              <div
                key={`name-${oo.key}-${data}-${o.id}`}
                style={{
                  width: cellWidth,
                  wordBreak: 'break-all',
                  color: '#262E3F',
                  lineHeight: '56px',
                  paddingLeft: 23,
                  fontSize: 14,
                  flexShrink: 0,
                  position: 'relative',
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  whiteSpace: 'nowrap',
                  flexWrap: 'nowrap',
                }}
              >
                <span
                  style={{
                    flexShrink: 1,
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                >
                  {data}
                </span>
                {oo.actions && (
                  <Dropdown
                    overlay={
                      <Menu>
                        {Object.values(oo.actions)
                          .filter((o: any) => o.isEditAction === props.isEdit)
                          .map(
                            (o: any) =>
                              !(o.noDefault && idx === 0) && (
                                <Menu.Item
                                  disabled={o.disableKey ? isDefault : false}
                                  key={o.label}
                                  onClick={() => {
                                    o.do?.(key)
                                  }}
                                >
                                  {o.label}
                                </Menu.Item>
                              )
                          )}
                      </Menu>
                    }
                  >
                    <Button type="link" icon={<CustomIcons type="klf-more" />} />
                  </Dropdown>
                )}
                {isDefault && (
                  <div className="absolute-top">
                    <CustomIcons className="absolute-top primary-mark" type="klf-bookmark_added_filled_regular" />
                  </div>
                )}
              </div>
            )
          })}
        </div>
      ))}
      <div
        style={{
          ...props.style,
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
          }}
        >
          <div>
            {props.keys.map((o: any, idx: number) => (
              <div
                style={{
                  height: 56,
                  width: 214,
                  overflow: 'hidden',
                  color: o.color || (o.key ? '#767F95' : '#262E3F'),
                  backgroundColor: '#F7F6F8',
                  lineHeight: '56px',
                  paddingLeft: 23,
                  fontSize: 14,
                }}
                key={`${o.key}-${idx}`}
              >
                {o.value}
                <span
                  style={{
                    marginLeft: 5,
                  }}
                >
                  {o.unit}
                </span>
              </div>
            ))}
          </div>
          {Object.keys(props.data)?.map((key: any, oidx: number) => {
            const o = props.data[key]
            return (
              <div
                style={{
                  width: cellWidth,
                  wordBreak: 'break-all',
                  backgroundColor: oidx % 2 ? 'transparent' : 'rgba(23, 158, 117, 0.03)',
                  flexShrink: 0,
                }}
                key={o.id}
              >
                {props.keys.map((oo: any, idx: number) => {
                  if (oo.key) {
                    const data = _.get(o, oo.key)
                    if (!props.isEdit) {
                      return (
                        <div
                          style={{
                            height: 56,
                            lineHeight: '56px',
                            paddingLeft: 23,
                            paddingRight: 23,
                            fontSize: 14,
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                          }}
                          key={`${o.id}-${idx}`}
                        >
                          {oo.type === 'dropdown' &&
                            props.extraData?.[oo.key]?.find((o: any) => o.value === data)?.label}
                          {oo.type === 'button' && (
                            <div
                              style={{
                                width: '100%',
                                height: 40,
                                display: 'flex',
                                alignItems: 'center',
                                flexDirection: 'row',
                              }}
                            >
                              <Button
                                className="ellipsis-button"
                                style={{
                                  width: '100%',
                                  overflow: 'hidden',
                                  textOverflow: 'ellipsis',
                                  padding: 0,
                                  color: oidx === 0 || oo.disabled ? 'rgba(0, 0, 0, 0.25)' : '#1ec692',
                                }}
                                size={'small'}
                                type={'link'}
                                onClick={() => {
                                  oo.callback?.(key)
                                }}
                              >
                                <span
                                  style={{
                                    textAlign: 'left',
                                    paddingLeft: 2,
                                    fontSize: 14,
                                    textOverflow: 'ellipsis',
                                  }}
                                  className={oidx === 0 || oo.disabled ? 'disabled' : ''}
                                >
                                  {data}
                                </span>
                              </Button>
                            </div>
                          )}

                          {oo.type !== 'button' && oo.type !== 'dropdown' && data}
                        </div>
                      )
                    }
                    return (
                      <div
                        style={{
                          height: 56,
                          lineHeight: '56px',
                          paddingLeft: 23,
                          paddingRight: 23,
                          fontSize: 14,
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                        }}
                        key={`${o.id}-${idx}`}
                      >
                        {oo.type === 'dropdown' && (
                          <Select
                            style={{
                              width: '100%',
                            }}
                            value={data}
                            disabled={oidx === 0 || oo.disabled}
                            onChange={(value) => {
                              oo.callback?.(
                                key,
                                props.extraData?.[oo.key].find((o: any) => o.value === value).data,
                                forms
                              )
                            }}
                          >
                            {props.extraData?.[oo.key]?.map((o: any, optIdx: number) => (
                              <Select.Option key={`${o.id}-${idx}-${optIdx}`} value={o.value}>
                                {o.label}
                              </Select.Option>
                            ))}
                          </Select>
                        )}
                        {oo.type === 'button' && (
                          <div
                            style={{
                              width: '100%',
                              height: 40,
                              borderRadius: 4,
                              border: '1px solid #BEC2CC',
                              borderColor: oidx === 0 || oo.disabled ? '#d9d9d9' : '#BEC2CC',
                              display: 'flex',
                              alignItems: 'center',
                              flexDirection: 'row',
                              backgroundColor: oidx === 0 || oo.disabled ? '#f5f5f5' : 'transparent',
                            }}
                          >
                            <Button
                              className="ellipsis-button"
                              style={{
                                width: '100%',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                color: oidx === 0 || oo.disabled ? 'rgba(0, 0, 0, 0.25)' : '#1ec692',
                              }}
                              size={'small'}
                              type={'link'}
                              onClick={() => {
                                oo.callback?.(key)
                              }}
                            >
                              <span
                                style={{
                                  textAlign: 'left',
                                  paddingLeft: 2,
                                  fontSize: 14,
                                  textOverflow: 'ellipsis',
                                }}
                                className={oidx === 0 || oo.disabled ? 'disabled' : ''}
                              >
                                {data}
                              </span>
                            </Button>
                          </div>
                        )}
                        {oo.type !== 'button' && oo.type !== 'dropdown' && (
                          <InputNumber
                            disabled={oidx === 0 || oo.disabled}
                            style={{
                              borderRadius: 4,
                              fontSize: 14,
                            }}
                            min={oo.min}
                            max={oo.max}
                            step={Math.pow(10, -(oo.precision || oo.precision === 0 ? oo.precision : 2))}
                            precision={oo.precision || oo.precision === 0 ? oo.precision : 2}
                            value={oo.link ? data : undefined}
                            defaultValue={oo.link ? undefined : data}
                            onChange={(val) => {
                              _.set(o, oo.key, val.toString())
                              saveValue()
                            }}
                            size={'large'}
                          />
                        )}
                      </div>
                    )
                  } else {
                    return (
                      <div
                        key="no-data"
                        style={{
                          height: 56,
                        }}
                      />
                    )
                  }
                })}
              </div>
            )
          })}
          {props.isEdit && (
            <div
              style={{
                flexGrow: 1,
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <div
                style={{
                  flexGrow: 1,
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    width: 56,
                    paddingLeft: 12,
                    paddingRight: 12,
                    height: '100%',
                  }}
                >
                  <div
                    style={{
                      height: 'calc(100vh - 650px)',
                      width: 1,
                      backgroundColor: '#EBEBEB',
                    }}
                  />
                  <div
                    style={{
                      marginTop: 16,
                      marginBottom: 16,
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      cursor: 'pointer',
                    }}
                    onClick={() => {
                      props.onAdd?.()
                    }}
                  >
                    <div>
                      <PlusCircleFilled
                        style={{
                          color: '#1ec692',
                        }}
                      />
                    </div>
                    <div>添加</div>
                  </div>
                  <div
                    style={{
                      flexGrow: 1,
                      width: 1,
                      backgroundColor: '#EBEBEB',
                    }}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
