import { Button, Card, Col, Form, Input, message, Modal, Row, Select, Upload } from 'antd'
import './work-order.less'
import { useNavigate } from 'react-router-dom'
import { WorkOrderService } from '../../apis/project-service'
import { backButtonSvg } from './work-order-home'
import { UploadOutlined } from '@ant-design/icons'
import React, { useState } from 'react'
import { CustomIcons, MAX_ATTACHMENT_COUNT } from '../../global'

export const WorkOrderCreate = () => {
  const navigate = useNavigate()

  const [form] = Form.useForm()

  const [attachmentList] = useState<any[]>([])

  const doCreate = () => {
    console.log(form.getFieldsValue())
    WorkOrderService.postAuthManageWorkOrderSubmit({
      root: {
        ...form.getFieldsValue(),
        fileUrls: attachmentList.filter(o => !!o.url).map(o => ({
          url: o.url,
          name: o.name,
          suffix: o.suffix
        }))
      }
    }).then(() => {
      navigate('../')
    })
  }
  const props = {
    action: '',
    maxCount: MAX_ATTACHMENT_COUNT,
    multiple: true,
    customRequest: (item: any) => {

      setTimeout(() => {
        const { file, onError, onSuccess } = item
        const size = file.size
        if (size > 10 * 1024 * 1024) {
          onError('File Too Large', '文件大小超过10M')
          return
        }
        const idx = attachmentList.length
        if (idx >= MAX_ATTACHMENT_COUNT) {
          message.error(`最多上传${MAX_ATTACHMENT_COUNT}个文件`).then()
          return
        }
        attachmentList[idx] = { file }
        WorkOrderService.postAuthManageWorkOrderUploadFile({
          file
        }).then((resp) => {
          file.url = resp.data.url
          attachmentList[idx] = { ...resp.data, file }
          onSuccess()
        }).catch(() => {
          attachmentList.splice(idx, 1)
          onError()
        })
      })
    },
    onChange({ file, fileList }) {
      if (file.status !== 'uploading') {
        if (!fileList.includes(file)) {
          const idx = attachmentList.findIndex(o => o.file.uid === file.uid)
          if (idx >= 0) {
            attachmentList.splice(idx, 1)
          }
        }
      }
    },
    defaultFileList: [],
    showUploadList: {
      showDownloadIcon: false,
      showRemoveIcon: true,
      removeIcon: <CustomIcons type='klf-delete' />
    }
  }
  return (
    <div>
      <Card>
        <Button type='text' className='back-button' onClick={() => {
          navigate('../')
        }}>{backButtonSvg}创建工单</Button>
        <Form
          form={form}
          className='work-create-form'
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 18 }}
        >
          <Form.Item rules={[
            {
              required: true,
              message: '请输入项目名称'
            },
            {
              pattern: /^[\u4E00-\u9FA5A-Za-z0-9 ]{1,64}$/,
              message: '1-64个字符,中文、英文和数字组成'
            }
          ]} label='主题描述' name='orderDesc'>
            <Input />
          </Form.Item>
          <Form.Item
            label='所属组织'
            name='companyName'
            rules={[
              {
                pattern: /^[\u4E00-\u9FA5A-Za-z0-9 ]{1,16}$/,
                message: '1-16字，由中文、英文和数字组成'
              }
            ]}>
            <Input />
          </Form.Item>
          <Form.Item rules={[
            {
              required: true,
              message: '请选择需求类型'
            }
          ]} label='需求类型' name='type'>
            <Select>
              <Select.Option value={1}>业务咨询</Select.Option>
              <Select.Option value={2}>使用帮助</Select.Option>
              <Select.Option value={3}>问题反馈</Select.Option>
              <Select.Option value={4}>其他</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item rules={[
            {
              required: true,
              message: '请输入需求内容'
            },
            {
              max: 800,
              message: '需求内容最长为800字'
            }
          ]} label='需求内容' name='orderContent'>
            <Input.TextArea maxLength={800} style={{
              minHeight: '8rem'
            }} />
          </Form.Item>
          <Form.Item label='附件'>
            <div>
              <Upload className='upload-wrap' {...props} onPreview={(file) => {
                if (file.status === 'success' || file.status === 'done') {
                  if (file.type.includes(`image`)) {
                    Modal.confirm({
                      icon: null,
                      content: <img style={{ maxWidth: 820 }} src={file.url} alt='' />,
                      okText: '关闭',
                      cancelText: '下载',
                      width: 900,
                      onCancel: () => {
                        window.open(file.url, '_blank')
                      }
                    })
                  } else {
                    window.open(file.url, '_blank')
                  }
                }
              }}>
                <Button type='link' icon={<UploadOutlined />}>上传附件</Button>
              </Upload>
            </div>
          </Form.Item>
          <Form.Item wrapperCol={{ span: 24 }}>
            <Row>
              <Col span={6} />
              <Col span={18}>
                <Button onClick={() => {
                  form.validateFields().then(() => {
                    doCreate()
                  })
                }} type='primary'>确定</Button>
                <Button onClick={() => {
                  navigate('../')
                }} style={{
                  marginLeft: '.5rem'
                }}>取消</Button>
              </Col>
            </Row>
          </Form.Item>
        </Form>
      </Card>
    </div>
  )
}
