import {Button, Form, Input, Pagination, Space, Spin, Table, Tree} from 'antd'
import {CustomIcons, doDownload} from '../../global'
import React, {useEffect, useState} from 'react'
import {EvaluationService} from '../../apis/project-service-history'
import {parseNumber} from './resource-map'
import styles from '../program/index.module.scss'
import {FormInstance} from 'antd/lib/form/hooks/useForm'
import {EvaluationV2Api} from '../../services'

export const query: any = {
  name: '',
  regionCode: (window as any).currentAdcode,
  areaMin: undefined,
  areaMax: undefined,
  yearTotalIrradianceMin: undefined,
  yearTotalIrradianceMax: undefined,
  times: undefined,
  timesSelected: [],

  areaSort: '0',
  timesSort: '0',
  yearTotalIrradianceSort: '0',
}

export const pageSize = 20;

interface TimeSrcData {
  name: string
  value?: string
  children?: TimeSrcData[]
}

interface TimeTreeData {
  title: string
  key: string
  children?: TimeTreeData[]
}

const exchangeData = (src: TimeSrcData, parentValue = 'ignore'): TimeTreeData => {
  if (src.value === '1') {
    src.value = undefined
  }
  if (src.value === '2') {
    src.value = undefined
  }
  const dst: TimeTreeData = {
    title: src.name,
    key: src.value ? parentValue.split('ignore') + '//' + src.value : parentValue + src.name,
  }
  if (src.children) {
    const children: any[] = []
    src.children.forEach((o) => {
      if (!children.find((oo) => !oo.children && oo.value === o.value)) {
        children.push(o)
      }
    })
    dst.children = children.map((o) => {
      return exchangeData(o, dst.key)
    })
  }
  return dst
}

const PickerDropdown = ({confirm, keyStr}: any) => {
  const [queryParams, setQueryParams] = useState<TimeTreeData[]>([])
  const [loading, setLoading] = useState(true)
  const [selected, setSelected] = useState<string[]>(query.timesSelected || [])

  console.log(query.timesSelected, selected)

  useEffect(() => {
    setLoading(true)
    EvaluationService.getRoofQueryParam({
      regionCode: (window as any).currentAdcode,
    })
      .then((resp) => {
        const rsp = resp.data.map((o: TimeSrcData) => exchangeData(o))
        // console.log(JSON.stringify(rsp))
        setQueryParams(rsp)
      })
      .catch(() => {
      })
      .finally(() => {
        setLoading(false)
      })
  }, [])

  return (
    <div className={styles.customFilter}>
      <Spin spinning={loading}>
        {!loading && (
          <Form
            onFinish={() => {
              query[`${keyStr}Selected`] = selected
              query[`${keyStr}`] = selected
                .filter((o) => !o.includes('ignore'))
                .map((o) => o.split('//')[1])
                .join(',')
              query[`${keyStr}Change`] = true
              confirm()
            }}
            {...{
              labelCol: {span: 6},
              wrapperCol: {span: 24},
            }}
            colon={false}
            className={'time-dropdown-picker'}
          >
            <Form.Item
              style={{
                alignItems: 'center',
              }}
            >
              <Tree
                checkable
                treeData={queryParams}
                onCheck={(val: any) => {
                  setSelected(val)
                }}
                checkedKeys={selected}
              />
            </Form.Item>
            <Form.Item label=" " className="filter-btns">
              <Space>
                <Button
                  onClick={() => {
                    setSelected([])
                  }}
                >
                  重置
                </Button>
                <Button type="primary" htmlType="submit">
                  确定
                </Button>
              </Space>
            </Form.Item>
          </Form>
        )}
      </Spin>
    </div>
  )
}

export const RoofSiftModal = (props: { onClose: (data?: any) => void }) => {
  // const [nameFilter, setNameFilter] = useState(query.name)
  const [dataSource, setDataSource] = useState([])

  const [areaForm] = Form.useForm()
  const [yearTotalIrradianceForm] = Form.useForm()
  const [timeForm] = Form.useForm()

  const dropdownArr: {
    [key: string]: {
      name: string
      form: FormInstance
      unit: string
    }
  } = {
    area: {
      name: '屋顶面积',
      form: areaForm,
      unit: '㎡',
    },
    yearTotalIrradiance: {
      name: '年总辐照量',
      form: yearTotalIrradianceForm,
      unit: 'kWh',
    },
    times: {
      name: '时间',
      form: timeForm,
      unit: '',
    },
  }

  const createRangeFilter = (confirm: any, key: string) => {
    return (
      <div className={styles.customFilter}>
        <Form
          onFinish={() => {
            query[`${key}Change`] = true
            confirm()
          }}
          {...{
            labelCol: {span: 6},
            wrapperCol: {span: 18},
          }}
          colon={false}
          form={dropdownArr[key].form}
        >
          <Form.Item
            label={dropdownArr[key].name}
            style={{
              alignItems: 'center',
            }}
          >
            <Input.Group compact>
              <Form.Item noStyle name={`${key}Min`}>
                <Input type="number" style={{width: 150}} placeholder="最小值"/>
              </Form.Item>
              <Input
                className="site-input-split"
                style={{
                  width: 30,
                  borderLeft: 0,
                  borderRight: 0,
                  pointerEvents: 'none',
                }}
                placeholder="-"
                disabled
              />
              <Form.Item noStyle name={`${key}Max`}>
                <Input type="number" style={{width: 180}} placeholder="最大值" suffix={dropdownArr[key].unit}/>
              </Form.Item>
            </Input.Group>
          </Form.Item>
          <Form.Item label=" " className="filter-btns">
            <Space>
              <Button
                onClick={() => {
                  dropdownArr[key].form.setFieldsValue({
                    [`${key}Min`]: undefined,
                    [`${key}Max`]: undefined,
                  })
                }}
              >
                重置
              </Button>
              <Button type="primary" htmlType="submit">
                确定
              </Button>
            </Space>
          </Form.Item>
        </Form>
      </div>
    )
  }

  const columns = [
    {
      title: '默认名称',
      dataIndex: 'name',
      key: 'name',
      width: 250,
      fixed: 'left' as any,
      // onFilterDropdownVisibleChange: (visible: boolean) => {
      //   if (!visible) {
      //     setNameFilter(query.name)
      //   }
      // },
      // filterIcon: (
      //   <CustomIcons
      //     type="klf-search"
      //     style={{
      //       color: query.name ? '#1ec692' : '#bfbfbf',
      //     }}
      //   />
      // ),
      // filterDropdown: ({confirm, visible}: any) => {
      //   if (!visible) {
      //     ;(window as any).closeDropdown = null
      //     return null
      //   }
      //   setTimeout(() => {
      //     ;(window as any).closeDropdown = confirm
      //   })
      //   return (
      //     <div
      //       style={{
      //         padding: '.5rem 1rem',
      //       }}
      //     >
      //       <Input
      //         id="nameFilter"
      //         value={nameFilter}
      //         placeholder="请输入默认名称"
      //         onChange={(dom) => {
      //           setNameFilter(dom.target.value)
      //         }}
      //         onBlur={(dom) => {
      //           setNameFilter(dom.target.value)
      //         }}
      //         onPressEnter={() => {
      //           query.name = nameFilter
      //           loadingData()
      //           confirm?.()
      //         }}
      //       />
      //       <div
      //         style={{
      //           marginTop: '.5rem',
      //           textAlign: 'right',
      //         }}
      //       >
      //         <Button
      //           onClick={() => {
      //             setNameFilter('')
      //           }}
      //         >
      //           重置
      //         </Button>
      //         <Button
      //           type="primary"
      //           style={{
      //             marginLeft: '.5rem',
      //           }}
      //           onClick={() => {
      //             query.name = nameFilter
      //             loadingData()
      //             confirm?.()
      //           }}
      //         >
      //           确定
      //         </Button>
      //       </div>
      //     </div>
      //   )
      // },
    },
    {
      title: '屋顶面积',
      dataIndex: 'area',
      key: 'area',
      render: (res: string) => `${parseNumber(res, {type: 'e', radix: 2})}㎡`,
      sorter: true,
      filterIcon: (
        <CustomIcons
          type="klf-filter"
          style={{
            color: query.areaMin || query.areaMax ? '#1ec692' : '#bfbfbf',
          }}
        />
      ),
      filterDropdown: ({confirm, visible}: any) => {
        if (!visible) {
          ;(window as any).closeDropdown = null
        } else {
          setTimeout(() => {
            ;(window as any).closeDropdown = confirm
          })
        }
        return createRangeFilter(confirm, 'area')
      },
      onFilterDropdownVisibleChange: (visible: boolean) => {
        const key = 'area'
        if (!visible) {
          if (query[`${key}Change`]) {
            query[`${key}Change`] = false
            query[`${key}Min`] = dropdownArr[key].form.getFieldsValue()[`${key}Min`]
            query[`${key}Max`] = dropdownArr[key].form.getFieldsValue()[`${key}Max`]
            loadingData()
          }
        } else {
          dropdownArr[key].form.setFieldsValue({
            [`${key}Max`]: query[`${key}Max`],
            [`${key}Min`]: query[`${key}Min`],
          })
        }
      },
    },
    {
      title: '年总辐照量',
      dataIndex: 'yearTotalIrradiance',
      key: 'yearTotalIrradiance',
      render: (res: string) => `${parseNumber(res, {type: 'custom', radix: 2})}kWh`,
      // sorter: true,
      // filterIcon: (
      //   <CustomIcons
      //     type="klf-filter"
      //     style={{
      //       color: query.yearTotalIrradianceMin || query.yearTotalIrradianceMa ? '#1ec692' : '#bfbfbf',
      //     }}
      //   />
      // ),
      // filterDropdown: ({confirm, visible}: any) => {
      //   if (!visible) {
      //     ;(window as any).closeDropdown = null
      //   } else {
      //     setTimeout(() => {
      //       ;(window as any).closeDropdown = confirm
      //     })
      //   }
      //   return createRangeFilter(confirm, 'yearTotalIrradiance')
      // },
      // onFilterDropdownVisibleChange: (visible: boolean) => {
      //   const key = 'yearTotalIrradiance'
      //   if (!visible) {
      //     if (query[`${key}Change`]) {
      //       query[`${key}Change`] = false
      //       query[`${key}Min`] = dropdownArr[key].form.getFieldsValue()[`${key}Min`]
      //       query[`${key}Max`] = dropdownArr[key].form.getFieldsValue()[`${key}Max`]
      //       loadingData()
      //     }
      //   } else {
      //     dropdownArr[key].form.setFieldsValue({
      //       [`${key}Max`]: query[`${key}Max`],
      //       [`${key}Min`]: query[`${key}Min`],
      //     })
      //   }
      // },
    },
    {
      title: '添加时间',
      dataIndex: 'addTime',
      key: 'addTime',
      // sorter: true,
      filterIcon: (
        <CustomIcons
          type="klf-filter"
          style={{
            color: query.times?.length > 0 ? '#1ec692' : '#bfbfbf',
          }}
        />
      ),
      filterDropdown: ({confirm, visible}: any) => {
        if (!visible) {
          ;(window as any).closeDropdown = null
        } else {
          setTimeout(() => {
            ;(window as any).closeDropdown = confirm
          })
        }
        return <PickerDropdown confirm={confirm} keyStr={'times'} cfg={dropdownArr['times']}/>
      },
      onFilterDropdownVisibleChange: (visible: boolean) => {
        const key = 'times'
        if (!visible) {
          if (query[`${key}Change`]) {
            query[`${key}Change`] = false
            loadingData()
          }
        }
      },
    },
  ]

  useEffect(() => {
    query.regionCode = (window as any).currentAdcode
    loadingData()
  }, [])

  const [pager, setPager] = useState<any>({
    current: 1,
    pageSize: 20,
    total: 0,
  })

  const [loading, setLoading] = useState(true)

  const loadingData = () => {
    setLoading(true)
    // console.log(JSON.stringify(query))
    new EvaluationV2Api().i3060EvaluationGetRoofV2Get({
      ...query,
      page: pager.current,
      size: pager.pageSize,
    }).then((rsp) => {
      const resp = rsp.data;
      setDataSource(resp.data)
      pager.total = resp.total
      setLoading(false)
    })
  }

  const changeTable = (pager: any, filter: any, sorter: any) => {
    const {order, field} = sorter
    let ope = '0'
    if (order === 'ascend') {
      ope = '1'
    }
    if (order === 'descend') {
      ope = '2'
    }

    query.areaSort = '0'
    query.timeSort = '0'
    query.yearTotalIrradianceSort = '0'
    if (field === 'addTime') {
      query[`timeSort`] = ope
    } else {
      query[`${field}Sort`] = ope
    }

    loadingData()
  }

  console.log(pager.total);

  const getCurrentPageData = () => {
    return dataSource;
    // return dataSource.slice((pager.current - 1) * pager.pageSize, pager.current * pager.pageSize)
  }

  return (
    <div>
      <Table
        loading={loading}
        dataSource={getCurrentPageData()}
        columns={columns}
        pagination={false}
        onChange={changeTable}
        scroll={{x: '1060', y: window.innerHeight - 400}}
      />
      <div className={styles.paginationWrap}>
        <Pagination
          className="left-pagination"
          size="small"
          total={pager.total}
          showQuickJumper={false}
          pageSizeOptions={['5', '10', '20', '50']}
          showTotal={(total) => {
            return `共 ${total} 条`
          }}
          showSizeChanger
          current={pager.current}
          pageSize={pager.pageSize}
          onChange={(page: number, pageSize?: number) => {
            pager.current = page
            pager.pageSize = pageSize
            setPager({...pager})
            setTimeout(() => {
              loadingData();
            })
          }}
          locale={{items_per_page: ''}}
        />
        <Pagination
          size="small"
          total={pager.total}
          current={pager.current}
          pageSize={pager.pageSize}
          showQuickJumper
          showSizeChanger={false}
          locale={{jump_to: '前往'}}
          onChange={(page: number, pageSize?: number) => {
            pager.current = page
            pager.pageSize = pageSize
            setPager({...pager})
            setTimeout(() => {
              loadingData();
            })
          }}
        />
      </div>

      <div className="ant-modal-confirm-btns">
        <Button
          onClick={() => {
            const url = '/api/i3060/evaluation/downloadRoof?';
            const qry = [];
            const baseQuery = {
              ...query,
              page: pager.current,
              size: pager.pageSize,
            };
            Object.keys(baseQuery).forEach((o) => {
              if (baseQuery[o]) {
                qry.push(`${o}=${encodeURIComponent(baseQuery[o])}`);
              }
            })
            doDownload(`${url}${qry.join('&')}`);
          }}
        >
          下载当前页面屋顶资源数据
        </Button>
        <Button
          onClick={() => {
            props.onClose?.()
          }}
        >
          取消
        </Button>
        <Button
          type="primary"
          onClick={() => {
            props.onClose(dataSource)
          }}
        >
          确定
        </Button>
      </div>
    </div>
  )
}
