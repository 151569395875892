import { Button, Col, Form, Input, Row, Table } from 'antd'
import React from 'react'
import { ProjectWorkbenchService } from '../../../../apis/project-service-history'
import ReactECharts from 'echarts-for-react'
import { ChartColorMaps, CustomIcons } from '../../../../global'
import * as echarts from 'echarts'
import { createRangeFilter } from '.'

let query: { [key: string]: any } = {
  inverterName: undefined,
  manufacturer: undefined,
  maxDcPowerMin: undefined,
  maxDcPowerMax: undefined,
  maxAcPowerMin: undefined,
  maxAcPowerMax: undefined,
  page: 1,
  size: 5,
  total: 0,
}

const layout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 18 },
}

export const PvInverterSelect = (props: any) => {
  const [current, setCurrent] = React.useState<any>()
  const [inverterList, setInverterList] = React.useState<any[]>()
  const [loading, setLoading] = React.useState(true)
  const [uploading, setUploading] = React.useState(false)
  const { viewMode } = props
  const [nameFilter, setNameFilter] = React.useState('')
  const [manufacturer, setManufacturer] = React.useState('')
  const [form] = Form.useForm()

  const init = () => {
    const { inverterModuleName } = props.current
    ProjectWorkbenchService.inverterList({
      inverterName: inverterModuleName,
      page: '1',
      size: '100',
    })
      .then((resp) => {
        setCurrent(resp.data.data[0])
      })
      .catch(() => {})
  }

  const loadData = () => {
    setLoading(true)
    const { inverterName, manufacturer, maxDcPowerMin, maxDcPowerMax, maxAcPowerMin, maxAcPowerMax, page, size } = query
    ProjectWorkbenchService.inverterList({
      inverterName,
      manufacturer,
      maxDcPowerMin,
      maxDcPowerMax,
      maxAcPowerMin,
      maxAcPowerMax,
      page: page.toString(),
      size: size.toString(),
    })
      .then((resp) => {
        query.total = resp.data.total
        query.page = resp.data.page
        setInverterList(resp.data.data.map((o: any) => ({ key: o.id, ...o })))
      })
      .catch(() => {})
      .finally(() => setLoading(false))
  }

  React.useEffect(() => {
    init()
    if (!viewMode) {
      loadData()
    }
    return () => {
      query = {
        inverterName: undefined,
        manufacturer: undefined,
        maxDcPowerMin: undefined,
        maxDcPowerMax: undefined,
        maxAcPowerMin: undefined,
        maxAcPowerMax: undefined,
        page: 1,
        size: 5,
        total: 0,
      }
    }
  }, [])

  const columns = [
    {
      title: '品牌',
      dataIndex: 'manufacturer',
      key: 'manufacturer',
      onFilterDropdownVisibleChange: (visible: boolean) => {
        if (!visible) {
          setManufacturer(query.manufacturer)
        }
      },
      filterIcon: (
        <CustomIcons
          type="klf-search"
          style={{
            color: query.manufacturer ? '#1ec692' : '#bfbfbf',
          }}
        />
      ),
      filterDropdown: ({ confirm, visible }: any) => {
        if (!visible) {
          ;(window as any).closeDropdown = null
        } else {
          setTimeout(() => {
            ;(window as any).closeDropdown = confirm
          })
        }
        return (
          <div
            style={{
              padding: '.5rem 1rem',
            }}
          >
            <Input
              placeholder="请输入品牌"
              value={manufacturer}
              onBlur={(dom) => {
                setManufacturer(dom.target.value)
              }}
              onChange={(dom) => {
                setManufacturer(dom.target.value)
              }}
              onPressEnter={() => {
                query.manufacturer = manufacturer
                loadData()
                confirm?.()
              }}
            />
            <div
              style={{
                marginTop: '.5rem',
                textAlign: 'right',
              }}
            >
              <Button
                onClick={() => {
                  setManufacturer('')
                }}
              >
                重置
              </Button>
              <Button
                type="primary"
                style={{
                  marginLeft: '.5rem',
                }}
                onClick={() => {
                  query.manufacturer = manufacturer
                  loadData()
                  confirm?.()
                }}
              >
                确定
              </Button>
            </div>
          </div>
        )
      },
    },
    {
      title: '型号',
      dataIndex: 'inverterName',
      key: 'inverterName',
      onFilterDropdownVisibleChange: (visible: boolean) => {
        if (!visible) {
          setNameFilter(query.inverterName)
        }
      },
      filterIcon: (
        <CustomIcons
          type="klf-search"
          style={{
            color: query.inverterName ? '#1ec692' : '#bfbfbf',
          }}
        />
      ),
      filterDropdown: ({ confirm, visible }: any) => {
        if (!visible) {
          ;(window as any).closeDropdown = null
        } else {
          setTimeout(() => {
            ;(window as any).closeDropdown = confirm
          })
        }
        return (
          <div
            style={{
              padding: '.5rem 1rem',
            }}
          >
            <Input
              placeholder="请输入型号"
              value={nameFilter}
              onBlur={(dom) => {
                setNameFilter(dom.target.value)
              }}
              onChange={(dom) => {
                setNameFilter(dom.target.value)
              }}
              onPressEnter={() => {
                query.inverterName = nameFilter
                loadData()
                confirm?.()
              }}
            />
            <div
              style={{
                marginTop: '.5rem',
                textAlign: 'right',
              }}
            >
              <Button
                onClick={() => {
                  setNameFilter('')
                }}
              >
                重置
              </Button>
              <Button
                type="primary"
                style={{
                  marginLeft: '.5rem',
                }}
                onClick={() => {
                  query.inverterName = nameFilter
                  loadData()
                  confirm?.()
                }}
              >
                确定
              </Button>
            </div>
          </div>
        )
      },
    },

    {
      title: '最大直流功率',
      dataIndex: 'maxDcPower',
      key: 'maxDcPower',
      render: (value: any) => {
        return `${value}Wdc`
      },
      filterDropdown: ({ confirm, visible }: any) => {
        if (!visible) {
          ;(window as any).closeDropdown = null
        } else {
          setTimeout(() => {
            ;(window as any).closeDropdown = confirm
          })
        }
        return createRangeFilter(confirm, 'maxDcPower', '最大直流功率', 'Wdc', form, query, layout)
      },
      filterIcon: (
        <CustomIcons
          type="klf-filter"
          style={{
            color: form.getFieldValue('maxDcPowerMin') || form.getFieldValue('maxDcPowerMax') ? '#1ec692' : '#bfbfbf',
          }}
        />
      ),
      onFilterDropdownVisibleChange: (visible: boolean) => {
        const key = 'maxDcPower'
        if (!visible) {
          if (query[`${key}Change`]) {
            query[`${key}Change`] = false
            query[`${key}Min`] = form.getFieldsValue()[`${key}Min`]
            query[`${key}Max`] = form.getFieldsValue()[`${key}Max`]
            loadData()
          } else {
            form.setFieldsValue({
              [`${key}Max`]: query[`${key}Max`],
              [`${key}Min`]: query[`${key}Min`],
            })
          }
        } else {
          form.setFieldsValue({
            [`${key}Max`]: query[`${key}Max`],
            [`${key}Min`]: query[`${key}Min`],
          })
        }
      },
    },
    {
      title: '最大交流功率',
      dataIndex: 'maxAcPower',
      key: 'maxAcPower',
      render: (value: any) => {
        return `${value}Wac`
      },
      filterDropdown: ({ confirm, visible }: any) => {
        if (!visible) {
          ;(window as any).closeDropdown = null
        } else {
          setTimeout(() => {
            ;(window as any).closeDropdown = confirm
          })
        }
        return createRangeFilter(confirm, 'maxAcPower', '最大交流功率', 'Wac', form, query, layout)
      },
      filterIcon: (
        <CustomIcons
          type="klf-filter"
          style={{
            color: form.getFieldValue('maxAcPowerMin') || form.getFieldValue('maxAcPowerMax') ? '#1ec692' : '#bfbfbf',
          }}
        />
      ),
      onFilterDropdownVisibleChange: (visible: boolean) => {
        const key = 'maxAcPower'
        if (!visible) {
          if (query[`${key}Change`]) {
            query[`${key}Change`] = false
            query[`${key}Min`] = form.getFieldsValue()[`${key}Min`]
            query[`${key}Max`] = form.getFieldsValue()[`${key}Max`]
            loadData()
          } else {
            form.setFieldsValue({
              [`${key}Max`]: query[`${key}Max`],
              [`${key}Min`]: query[`${key}Min`],
            })
          }
        } else {
          form.setFieldsValue({
            [`${key}Max`]: query[`${key}Max`],
            [`${key}Min`]: query[`${key}Min`],
          })
        }
      },
    },
  ]

  return (
    <div>
      {!viewMode && (
        <Table
          className="table-minimal"
          loading={loading}
          dataSource={inverterList}
          columns={columns}
          style={{
            marginBottom: '1rem',
          }}
          rowSelection={{
            type: 'radio',
            onChange: (val: any) => {
              setCurrent(inverterList?.find((o) => o.key === val[0]))
            },
          }}
          onChange={(pager: any) => {
            const { current, pageSize } = pager
            query.page = current
            query.size = pageSize
            loadData()
          }}
          pagination={{
            total: query.total,
            showSizeChanger: true,
            showQuickJumper: true,
            pageSize: query.size,
            pageSizeOptions: ['5', '10', '20', '50'],
            showTotal: (total) => `共 ${total} 条`,
          }}
        />
      )}
      {current && (
        <div>
          <strong>效率曲线</strong>
        </div>
      )}
      {current && <div className="dialog-tips">{current.inverterName}</div>}
      {current && (
        <Row
          gutter={[16, 8]}
          style={{
            marginBottom: '1rem',
          }}
        >
          <Col span={6}>
            <strong>最大直流输入功率：</strong>
            <span>{current.maxDcPower}Wdc</span>
          </Col>
          <Col span={6}>
            <strong>最大直流输入电压：</strong>
            <span>{current.maxDcVoltage}Vdc</span>
          </Col>
          <Col span={6}>
            <strong>额定交流电压：</strong>
            <span>{current.nomialAcVolt}Vac</span>
          </Col>
          <Col span={6}>
            <strong>最大MPPT直流电压：</strong>
            <span>{current.maxMpptDcVoltage}Vdc</span>
          </Col>
          <Col span={6}>
            <strong>最大交流输出功率：</strong>
            <span>{current.maxAcPower}Wac</span>
          </Col>
          <Col span={6}>
            <strong>最大直流输入电流：</strong>
            <span>{current.maxDcCurrent}Adc</span>
          </Col>
          <Col span={6}>
            <strong>额定直流电压：</strong>
            <span>{current.nomialDcVolt}Vdc</span>
          </Col>
          <Col span={6}>
            <strong>最小MPPT直流电压：</strong>
            <span>{current.minMpptDcVoltage}Vdc</span>
          </Col>
          <Col span={6}>
            <strong>运行时耗电量：</strong>
            <span>{current.operationConsumptionPower}Wdc</span>
          </Col>
          <Col span={6}>
            <strong>夜间耗电量：</strong>
            <span>{current.powerAtNight}Wdc</span>
          </Col>
        </Row>
      )}
      {current && (
        <div
          id="pv-inverter-current"
          style={{
            height: 250,
          }}
        >
          <ReactECharts
            style={{
              width: '100%',
              height: '100%',
            }}
            option={{
              animationDuration: 50,
              grid: { left: 20, top: 30, bottom: 30, right: 20, containLabel: true },
              tooltip: {
                trigger: 'axis',
                formatter: (args: any) => {
                  let res = `<strong>${args[0].axisValue}%</strong>`
                  if (args.length > 0) {
                    res += args
                      .map(
                        (o: any) =>
                          `<div style='width: 230px;'>${o.marker}${o.seriesName} <span style='float: right'>${o.value[1]}%</span></div>`
                      )
                      .join('')
                  }
                  return res
                },
              },
              legend: {
                show: true,
                top: 'bottom',
                icon: 'rect',
              },
              xAxis: {
                type: 'value',
                min: -5,
                max: 105,
                axisLine: {
                  onZero: false,
                },
                splitLine: {
                  show: false,
                },
                axisTick: {
                  show: false,
                },
                // data: [...(Array(101).keys() as any)].map((o) => `${o}%`),
                axisLabel: {
                  formatter: (val: number) => {
                    if (val < 0) return ''
                    if (val > 100) return '%'
                    return val
                  },
                },
              },
              yAxis: {
                name: '%',
                type: 'value',
                nameTextStyle: {
                  color: 'gray',
                  padding: [0, 125, 0, 0],
                },
                axisLine: {
                  show: false,
                },
                axisTick: {
                  show: false,
                },
                splitLine: {
                  lineStyle: {
                    type: [5, 5],
                    dashOffset: 5,
                  },
                },
              },
              series: [
                {
                  key: 'mpptMaxVoltageEfficiency',
                  name: 'MPPT最大电压效率',
                },
                {
                  key: 'mpptMinVoltageEfficiency',
                  name: 'MPPT最小电压效率',
                },
                // {
                //   key: 'ratedOutputPower',
                //   name: '额定输出功率',
                // },
                {
                  key: 'ratedVoltageEfficiency',
                  name: '额定电压效率',
                },
              ].map((o, idx) => {
                return {
                  name: o.name,
                  type: 'line',
                  showSymbol: false,
                  data: current.plot[o.key].map((o: string, idx: number) => [
                    idx,
                    parseFloat((parseFloat(o) * 100).toFixed(6)),
                  ]),
                  itemStyle: {
                    color: ChartColorMaps[idx].line,
                  },
                  areaStyle: {
                    color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                      {
                        offset: 0,
                        color: ChartColorMaps[idx].area[0],
                      },
                      {
                        offset: 1,
                        color: ChartColorMaps[idx].area[1],
                      },
                    ]),
                  },
                }
              }),
            }}
            // option={{
            //   grid: { top: '30px', bottom: '50px' },
            //   tooltip: {
            //     trigger: 'axis',
            //   },
            //   legend: {
            //     show: true,
            //     top: 'bottom',
            //     icon: 'rect',
            //   },
            //   xAxis: {
            //     type: 'category',
            //     name: '%',
            //     data: [...(Array(101).keys() as any)].map((o) => `${o}%`),
            //     axisLabel: {
            //       formatter: (val: string) => `${val.replace('%', '')}`,
            //     },
            //   },
            //   yAxis: {
            //     type: 'value',
            //     name: '%',
            //     nameTextStyle: {
            //       color: 'gray',
            //       padding: [0, 40, 0, 0],
            //     },
            //   },
            //   series: [
            //     {
            //       key: 'mpptMaxVoltageEfficiency',
            //       name: 'MPPT最大电压效率',
            //     },
            //     {
            //       key: 'mpptMinVoltageEfficiency',
            //       name: 'MPPT最小电压效率',
            //     },
            //     // {
            //     //   key: 'ratedOutputPower',
            //     //   name: '额定输出功率',
            //     // },
            //     {
            //       key: 'ratedVoltageEfficiency',
            //       name: '额定电压效率',
            //     },
            //   ].map((o) => {
            //     return {
            //       name: o.name,
            //       type: 'line',
            //       smooth: true,
            //       showSymbol: false,
            //       data: current.plot[o.key].map((o: string) => parseFloat((parseFloat(o) * 100).toFixed(8))),
            //     }
            //   }),
            // }}
          />
        </div>
      )}
      <div className="ant-modal-confirm-btns">
        {!viewMode && (
          <Button
            onClick={() => {
              props.onClose?.()
            }}
          >
            取 消
          </Button>
        )}
        <Button
          type="primary"
          loading={uploading}
          onClick={() => {
            if (viewMode) {
              props.onClose?.()
              return
            }
            setUploading(true)
            if (props.onChange) {
              props.onChange(current)
            } else {
              ProjectWorkbenchService.updateInverterModule({
                technologyId: props.technologyId,
                root: {
                  inverterModuleId: current.id,
                },
              }).then(() => {
                props.onClose?.(true)
              })
            }
          }}
        >
          确 定
        </Button>
      </div>
    </div>
  )
}
