import React from 'react'
import { debounce } from 'lodash'
import { Button, Menu, Modal, message, Popover } from 'antd'

import { CustomIcons } from '../../../global'
import { ParamsType } from './useTableParams'

// 表格操作列

export const remove = (getService: () => Promise<any>, success?: () => void) => {
  // Modal.destroyAll()
  Modal.confirm({
    keyboard: false,
    title: '删除提示',
    icon: <CustomIcons type="klf-alarm1" />,
    content: <p>此操作将永久删除该数据, 是否继续?</p>,
    width: 500,
    okType: 'primary',
    okText: '确定',
    okButtonProps: {
      danger: true,
    },
    onOk: () => {
      getService()
        ?.then((resp: any) => {
          if (resp?.code === 200) {
            message.success(resp?.msg, 1, success)
          } else if (resp?.msg) {
            message.warning(resp?.msg)
          }
        })
        .catch((err: any) => {
          console.warn('Table Delete Action:', err)
        })
    },
    cancelText: '取消',
    cancelButtonProps: {
      danger: true,
    },
  })
}

export interface ITableActionItem {
  rowData?: any
  type?: 'delete'
  key?: string
  label?: string,
  visible?: (rowData: any) => boolean
  onClick?: (rowData: any) => void
  getter?: (rowData: any) => Promise<any>
  success?: (tableParams?: ParamsType) => void
  children?: ITableActionItem[]
}

export interface ITableAction {
  rowData: any
  actions: ITableActionItem[]
}

export const TableAction: React.FC<ITableAction> = ({ rowData, actions }) => {
  const computed = React.useMemo(() => {
    let eventMap = {}
    const loop = (arr) => {
      return arr
        .map((item: ITableActionItem, index: number) => {
          if (item.visible?.(rowData) ?? true) {
            const _item: any = {
              key: item.key ?? index,
              label: item.label ?? (item.type === 'delete' ? '删除' : ''),
            }
            if (item.children?.length > 0) {
              _item.popupClassName = 'table-action-popover'
              _item.children = loop(item.children)
            } else {
              eventMap[item.key ?? index] =
                item.onClick ??
                (item.type === 'delete'
                  ? () => {
                      remove(
                        () => item.getter?.(rowData) as any,
                        () => item.success?.({ page: 1 })
                      )
                    }
                  : () => {
                      message.warn(`您还没有设置onClick事件哦~`)
                    })
            }
            return _item
          }
          return null
        })
        .filter((v: any) => !!v)
    }

    let items = loop(actions)

    return { items, eventMap }
  }, [rowData, actions])

  const handlerClick = React.useCallback(
    debounce(({ key }: any) => {
      computed.eventMap?.[key]?.(rowData)
    }, 500),
    [rowData, computed.eventMap]
  )

  return <Menu mode="vertical" selectable={false} activeKey="" items={computed.items} onClick={handlerClick} />
}

// 表格操作列Cloumn
export const getActionColumn = (actions: ITableActionItem[]) => {
  return {
    title: '操作',
    key: 'action',
    width: 60,
    fixed: 'right' as any,
    render: (text: any, record: any) => {
      const visible: boolean = actions.some((v: any) => v.visible?.(record) ?? true)
      if (visible) {
        return (
          <Popover
            placement="leftTop"
            overlayClassName="menu-popover-overlay table-action-popover"
            destroyTooltipOnHide
            content={<TableAction rowData={record} actions={actions} />}
          >
            <Button type="link" icon={<CustomIcons type="klf-more" />} />
          </Popover>
        )
      }
      return null
    },
  }
}
