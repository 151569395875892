// 轮询
export default class Poll {
  timerId: number = 1 // 模拟计时器id，唯一性
  timerObj: { [name: string]: boolean } = {} // 计时器存储器
  getData: () => Promise<any>
  counter: number = 0 // 请求次数

  constructor(getData) {
    this.getData = getData
  }

  start = () => {
    const id = this.timerId++
    this.timerObj[id] = true

    this.timerFn(id).then()
  }

  // 暂停
  stop = () => {
    this.timerObj = {}
  }

  timerFn = async (id) => {
    if (!this.timerObj[id]) return
    this.counter++
    await this.getData() // 模拟请求
    setTimeout(this.timerFn, 1000)
  }
}
