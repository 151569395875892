import { Button, Form, Input, message } from 'antd'
import { useState } from 'react'
import { Link } from 'react-router-dom'
import { EyeInvisibleOutlined } from '@ant-design/icons'
import Verify from './verify'
import { UserService } from '../../../apis/project-service-history'
import { token } from '../../../utils/utils'
import VerifyCode from './verify-code'
import { sm2 } from 'sm-crypto'
import { SM2CODE, EMAIL_EXP } from '../../../utils/constant'
import styles from '../index.module.scss'
import { showRegister } from '../../../global'
import { PasswordNotice } from './password-notice'

let fn: any = null
// 忘记密码邮箱找回
function ForgetPasswordByEmail() {
  const [form] = Form.useForm()
  const [loading, setLoading] = useState(false)
  // 图片滑块验证成功状态
  const [verifyPass, setVerifyPass] = useState('')
  const [visible, setVisible] = useState(false)
  const [showPwd, setShowPwd] = useState(false)
  // 验证手机状态
  const [checkEmail, setCheckEmail] = useState<any>({
    validateStatus: ' ',
    help: '',
  })

  const onModalCancel = () => {
    setVisible(false)
  }

  const onSendCode = async (callback: any) => {
    const mail = form.getFieldValue('mail')
    if (!mail) {
      setCheckEmail({
        validateStatus: 'error',
        help: '请输入邮箱',
      })
      return
    } else {
      setCheckEmail({
        validateStatus: '',
        help: ' ',
      })
    }
    await setVisible(true)
    fn = callback
  }

  // 验证邮箱是否已存在
  const validEmail = (e: any) => {
    const value = e.target.value
    var emailreg = EMAIL_EXP
    if (!value) {
      setCheckEmail({ validateStatus: 'error', help: '邮箱必填' })
      return
    } else if (!emailreg.test(value)) {
      setCheckEmail({ validateStatus: 'error', help: '邮箱格式错误' })
      return
    }

    UserService.checkEmail({ email: value })
      .then((res) => {
        if (!res.data) {
          setCheckEmail({
            validateStatus: '',
            help: null,
          })
        } else {
          setCheckEmail({
            validateStatus: 'error',
            help: (
              <div>
                邮箱不存在{showRegister && '，去'}
                {showRegister && <Link to="/login/register">注册</Link>}
              </div>
            ),
          })
        }
      })
      .catch(() => {})
  }

  const validateFields = (values: any) => {
    let errs = []
    if (!values.mail) {
      setCheckEmail({
        validateStatus: 'error',
        help: '请输入邮箱',
      })
      errs.push(values.mail)
    } else {
      setCheckEmail({
        validateStatus: ' ',
        help: ' ',
      })
    }

    return errs
  }

  // 请求验证码
  const getCode = (mail: string, captchaVerification: string) => {
    return UserService.sendMailValid({
      mail,
      captchaVerification,
    }).then((res) => {
      if (res.msg === 'success') {
        message.success('验证码已发送,请注意查收邮件')
      } else {
        message.error(res.msg)
        return Promise.reject()
      }
    })
  }

  // 重置
  const handleResetPassword = () => {
    const values = form.getFieldsValue(['mail', 'captchaVerification', 'code', 'password'])
    const validErrors = validateFields(values)
    form.validateFields().then(() => {
      if (validErrors.length > 0) {
        return
      }
      setLoading(true)
      UserService.updatePwdByMail({
        mail: values.mail,
        validCode: values.code,
        newPassword: sm2.doEncrypt(values.password, SM2CODE, 0),
        root: {
          ...values,
          captchaVerification: verifyPass,
        },
      })
        .then((res) => {
          if (res.code === 200) {
            token.set(res?.data?.token)
            message.success('重置密码成功')
            location.assign('#/login/index')
          } else {
            message.error(res.msg)
          }
        })
        .catch(() => {})
        .finally(() => {
          setLoading(false)
        })
    })
  }
  const [showNotice, setShowNotice] = useState(false)
  const [password, setPassword] = useState('')
  return (
    <div className={styles.forgetPage}>
      <h1 style={{ marginBottom: '3px', fontSize: 36 }}>重置密码</h1>
      <div className="desc-text" style={{ fontSize: 16, marginBottom: 30 }}>
        通过您的邮箱重置密码，或
        <Link to="/login/forget "> 通过手机号重置</Link>
      </div>
      <div>
        <Form
          form={form}
          labelAlign="left"
          style={{
            width: '100%',
          }}
        >
          <Form.Item
            name="mail"
            validateStatus={checkEmail.validateStatus}
            help={checkEmail.help ? checkEmail.help : undefined}
          >
            <Input
              size="large"
              onBlur={(e) => {
                if (!e.target.value) {
                  setCheckEmail({
                    validateStatus: 'error',
                    help: '邮箱必填',
                  })
                } else {
                  validEmail(e)
                }
              }}
              type="phone"
              placeholder="请输入邮箱"
            />
          </Form.Item>
          <Form.Item hidden name="captchaVerification">
            <Verify
              mode="modal"
              update={async (e: any) => {
                const phone = form.getFieldValue('mail')
                setVerifyPass(e)
                if (fn) {
                  fn(phone, e)
                  setCheckEmail({
                    validateStatus: '  ',
                    help: null,
                  })
                }
              }}
              onModalCancel={onModalCancel}
              modalVisible={visible}
              isVerifyPass={!!verifyPass}
            />
          </Form.Item>
          <Form.Item
            name="code"
            rules={[
              {
                required: true,
                message: '请输入验证码',
              },
              {
                min: 6,
                message: '验证码不正确',
              },
            ]}
          >
            <VerifyCode
              field="mail"
              disabled={checkEmail.validateStatus === 'error' || checkEmail.help === ''}
              customRequest={getCode}
              setVisible={setVisible}
              onSendCode={onSendCode}
              setValidate={setCheckEmail}
              sendFn={UserService.sendMailValid}
              form={form}
              type="login"
            />
          </Form.Item>
          <Form.Item
            name="password"
            rules={[
              {
                required: true,
                message: '密码必填',
              },
              {
                min: 8,
                max: 16,
                message: '密码长度8~16位',
              },
              {
                pattern: /^.*(?=.*\d)(?=.*[A-Z])(?=.*[a-z]).*$/,
                message: '必须包含大写字母小写字母和数字',
              },
              {
                pattern: /((?=[\x21-\x7e]+)[^A-Za-z0-9])/,
                message: '必须包含特殊字符',
              },
            ]}
          >
            <Input
              size="large"
              type={showPwd ? 'text' : 'password'}
              placeholder="请输入新密码"
              onFocus={() => {
                setShowNotice(true)
              }}
              onBlur={() => {
                setShowNotice(false)
              }}
              onChange={(dom) => {
                setPassword(dom.target.value)
                form.setFieldsValue({ password: dom.target.value })
              }}
              value={password}
              suffix={
                <EyeInvisibleOutlined
                  className="show-pwd-icon"
                  onMouseDown={() => {
                    setShowPwd(true)
                  }}
                  onMouseUp={() => {
                    setShowPwd(false)
                  }}
                />
              }
            />
            {showNotice && <PasswordNotice password={password || ''} />}
          </Form.Item>
          <Form.Item>
            <Button
              size="large"
              style={{
                width: '100%',
                height: 42,
                marginTop: '40px',
              }}
              type="primary"
              onClick={handleResetPassword}
              loading={loading}
            >
              重置密码
            </Button>
          </Form.Item>
          <Form.Item>
            <Button
              type="link"
              style={{
                width: '100%',
              }}
              onClick={() => {
                location.assign('#/login/index')
              }}
              loading={loading}
            >
              返回登录
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  )
}

export default ForgetPasswordByEmail
