import React, { useEffect, useImperativeHandle, useRef, useState } from 'react'
import { CardProps, Card, Table, TableColumnsType, TableProps, PaginationProps } from 'antd'

import { BreadcrumbObservable, SideMenuObservable } from '../../application'
import useTableParams, { ParamsType } from './components/useTableParams'
export { default as getColumnFilterProps } from '../wl-column-filter'
export { getActionColumn } from './components/table-action'
export type { ParamsType }

import './index.less'

// 组件实例方法
export interface TablePageInstance {
  reload: (params?: any) => void
  getSelectedRowKeys: () => any[]
  getParams: { get: (params?: ParamsType) => ParamsType }
}

// 组件属性
export interface ITablePage {
  formatJson?: (response: any) => {
    code?: number
    msg?: string
    data: {
      list: any[]
      pagination?: {
        currentPage: number
        total: number
        pageSize?: number
      }
    }
  } // 转换服务端获取的数据成为组件可用的数据
  columns: (tableParams?: ParamsType) => TableColumnsType // 返回表格列配置
  getter: (tableParams: ParamsType) => Promise<any> // 拉取远程数据
  breadcrumb?: { url: string; name: string }[] // 面包屑
  autoLoad?: boolean // 是否在didMount时自动请求数据
  checkable?: boolean // 是否包含全选功能,默认false
  children?: React.ReactElement // 页面中的其他内容
  extra?: CardProps['extra'] // 操作栏
  footer?: React.ReactElement // 页尾内容,默认为null
  pagination?: boolean | PaginationProps // 表格是否分页,默认true
  rowKey?: string | ((...args: any[]) => string | number) // 表格唯一键值
  title?: string | React.ReactElement // 页面标题
  scroll?: TableProps<any>['scroll']
  componentRef?: any
  isSorter?: boolean // 是否sorter 排序
  sorterChange?: Function// sorter 排序回调
}

// TODO 计算高度

// 组件
export const TablePage: React.ForwardRefRenderFunction<TablePageInstance, ITablePage> = ({
  formatJson,
  columns,
  getter,
  sorterChange,
  breadcrumb = [],
  autoLoad = true,
  checkable = false,
  children,
  extra,
  footer = null,
  pagination = true,
  rowKey = 'id',
  title,
  scroll,
  componentRef,
  isSorter = false,
}) => {
  const tableRef = useRef<any>()
  const footerRef = useRef<any>()
  const [dataSource, setDataSource] = useState<any[]>([]) // Table数据源
  const [total, setTotal] = useState<number>(0) // Table数据总条数
  const [selectedRowKeys, setSelectedRowKeys] = useState<Array<string | number>>([]) // Table 选中的数据id
  const [loading, setLoading] = useState(false) // Table loading 状态
  const [tableParams, setTableParams]: any = useTableParams() // Table搜索条件

  // 方法实例
  useImperativeHandle(componentRef, () => ({
    reload: loadingData,
    getParams: tableParams,
    getSelectedRowKeys: () => selectedRowKeys,
    
  }))

  // 设置默认请求页数
  useEffect(() => {
    if ((pagination as any)?.defaultPageSize) {
      setTableParams({ pageSize: (pagination as any).defaultPageSize })
    }
  }, [pagination])

  // React.useLayoutEffect(() => {
  //   console.log(tableRef.current?.getBoundingClientRect(), footerRef.current?.getBoundingClientRect())
  // }, [])

  // 加载数据
  useEffect(() => {
    autoLoad && loadingData()
  }, [autoLoad])

  // 设置面包屑
  useEffect(() => {
    SideMenuObservable.next(false)
    breadcrumb?.length && BreadcrumbObservable.next(breadcrumb)
  }, [breadcrumb])

  // 拉取数据
  const loadingData: TablePageInstance['reload'] = (params) => {
    // 翻页时清空选中
    checkable && setSelectedRowKeys([])

    setTableParams(params)
    setLoading(true)

    getter(tableParams.get())
      .then((resp: any) => {
        if (formatJson) {
          resp = formatJson(resp)
        }
        setDataSource(resp?.data?.list || [])
        setTotal(resp?.data?.pagination?.total ?? 0)
      })
      .catch(() => {})
      .finally(() => {
        setLoading(false)
      })
  }

  // 搜索表格
  const onChange: TableProps<any>['onChange'] = React.useCallback((pagination, pager: any, sorter: any) => {
    const { current, pageSize } = pagination
    // console.log('Table Component filters:', filters, ',order:')

    // 是否排序 默认不排序
    if (isSorter) {
      sorterChange(sorter)
    }else {
      loadingData({ pageSize, page: current })
    }
  }, [])

  const top = 210
  const tableContent = (
    <div ref={tableRef} className="wl-table">
      {children}
      <Table
        className="right-actions"
        columns={columns(tableParams.get()) as any}
        dataSource={dataSource}
        loading={loading}
        rowKey={rowKey}
        scroll={scroll ?? { x: '100%', y: `calc(100vh - ${top + 140}px)` }}
        style={{ flexGrow: 1 }}
        rowSelection={
          checkable
            ? {
                type: 'checkbox',
                selectedRowKeys,
                onChange: (selectedRowKeys: any[]) => {
                  setSelectedRowKeys(selectedRowKeys)
                },
              }
            : undefined
        }
        pagination={
          pagination
            ? {
                total,
                size: 'small',
                showSizeChanger: true,
                showQuickJumper: true,
                defaultPageSize: 20,
                current: tableParams?.get('page') ?? 1,
                pageSize: tableParams?.get('pageSize'),
                pageSizeOptions: ['10', '20', '50'],
                showTotal: (total) => `共 ${total} 条`,
                ...(typeof pagination === 'boolean' ? {} : pagination),
              }
            : false
        }
        onChange={onChange}
      />
      {footer && <div ref={footerRef}>{footer}</div>}
    </div>
  )
  if (!title && !extra) {
    return tableContent
  }
  return (
    <Card
      bodyStyle={{ height: `calc(100vh - ${top}px)` }}
      className="no-border-bottom-card wl-table-page right-actions"
      extra={extra}
      title={<div style={{ fontWeight: 600, fontSize: 20 }}>{title}</div>}
    >
      {tableContent}
    </Card>
  )
}

export default React.forwardRef<TablePageInstance, ITablePage>((props, ref) => {
  return <TablePage componentRef={ref} {...props} />
})
