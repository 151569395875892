import { VerticalTable } from './vertical-table'
import React, { useEffect, useState } from 'react'
import { TechnologyService } from '../../../../apis/project-service-history'
import { Button, Form, Input, message, Modal } from 'antd'
import { CustomIcons } from '../../../../global'
import { PvModelSelect } from '../../../workspace/technical/pv-config/pv-model-select'
import { PvInverterSelect } from '../../../workspace/technical/pv-config/pv-inverter-select'
import { PvSystemEfficiency } from '../../../workspace/technical/pv-config/pv-system-efficiency'
import { RESOURCE_NAME_EXP } from '../../../../utils/constant'
import { SubBreadcrumbObservable } from '../../layout'
import { ApplicationModal, SideMenuObservable } from '../../../../application'
import _ from 'lodash'
let cur = 1

let res: any = {}
let backup: any = {}

let _isEdit = false

export const PVParams = () => {
  const [renameForm] = Form.useForm()

  useEffect(() => {
    SubBreadcrumbObservable.next([
      {
        name: '设置',
        url: '',
      },
      {
        name: '参数库',
        url: '/user-center/user-params',
      },
      {
        name: '光伏参数',
        url: '/user-center/pv',
      },
    ])
    SideMenuObservable.next(true)
  }, [])

  const [lockKeys] = useState([
    {
      key: 'name',
      value: '光伏参数名称',
      color: '#262E3F',
      actions: {
        onSetDefault: {
          label: '设为默认',
          disableKey: 'isDefault',
          noDefault: false,
          isEditAction: false,
          do: (key: string) => {
            setData((data: any) => {
              const { id } = data[key]
              TechnologyService.default1({
                libraryId: id,
                root: {
                  setDefault: true,
                },
              })
                .then(() => {
                  Object.values(data).forEach((dt: any) => {
                    dt.isDefault = false
                  })
                  data[key].isDefault = true
                  setData({ ...data })
                })
                .catch(() => {})
              return data
            })
            console.log(key)
          },
        },
        onRename: {
          label: '重命名',
          noDefault: true,
          isEditAction: true,
          do: (key: string) => {
            setData((data: any) => {
              renameForm.setFieldsValue({
                rename: data[key].name,
              })
              Modal.destroyAll()
              Modal.confirm({
                keyboard: false,
                autoFocusButton: null,
                title: '重命名',
                icon: <CustomIcons type="klf-alarm1" />,
                content: (
                  <Form form={renameForm}>
                    <Form.Item
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                      }}
                      name="rename"
                      label="请输入光伏参数名称"
                      rules={[
                        {
                          required: true,
                          message: '请输入光伏参数名称',
                        },
                        {
                          pattern: RESOURCE_NAME_EXP,
                          message: '1-50个字符,中文、英文、_、数字和-组成',
                        },
                      ]}
                    >
                      <Input placeholder="请输入"></Input>
                    </Form.Item>
                  </Form>
                ),
                width: 500,
                okType: 'primary',
                okText: '确定',
                cancelText: '取消',
                onOk: () => {
                  return new Promise((resolve, reject) => {
                    const { rename } = renameForm.getFieldsValue()
                    renameForm
                      .validateFields()
                      .then(() => {
                        setData((data: any) => {
                          data[key].name = rename
                          return { ...data }
                        })
                        resolve({})
                      })
                      .catch((e) => {
                        reject(e)
                      })
                  })
                },
              })
              return data
            })
          },
        },
        onCopy: {
          label: '拷贝',
          noDefault: false,
          isEditAction: true,
          do: (key: string) => {
            setData((data: any) => {
              if (Object.keys(data).length > 6) {
                message.error('光伏参数最大设置6个')
                return data
              }
              data[Date.now()] = {
                ..._.cloneDeep(data[key]),
                isDefault: false,
                id: undefined,
                name: `${data[key].name}_拷贝`,
              }
              return { ...data }
            })
          },
        },
        onDelete: {
          label: '删除',
          noDefault: true,
          isEditAction: true,
          do: (key: string) => {
            console.log(key)
            setData((data: any) => {
              delete data[key]
              return { ...data }
            })
          },
        },
      },
    },
  ])
  const [isEdit, setIsEdit] = useState(false)

  useEffect(() => {
    _isEdit = isEdit
  }, [isEdit])

  const [keys] = useState([
    {
      key: 'rental',
      value: '是否有租金',
      type: 'dropdown',
      callback: (key: string, value: any) => {
        setData((data: any) => {
          data[key].rental = value;
          return { ...data }
        })
      },
    },
    { key: '', value: '关键设备' },
    {
      key: 'photovoltaicModuleInfo.name',
      value: '光伏模组型号',
      type: 'button',
      callback: (key: string) => {
        setData((data: any) => {
          const pvInfo = { ...data[key].photovoltaicModuleInfo }
          pvInfo.photovoltaicModuleName = pvInfo.name
          const modal = ApplicationModal.confirm({
            keyboard: false,
            title: '光伏模组型号',
            closable: true,
            content: (
              <PvModelSelect
                viewMode={!_isEdit || key === '0'}
                current={pvInfo}
                onChange={(_data: any) => {
                  const { id, pvModuleName } = _data
                  data[key].photovoltaicModuleInfo.photovoltaicModuleId = id
                  data[key].photovoltaicModuleInfo.name = pvModuleName
                  setData({ ...data })
                  modal.destroy()
                }}
                onClose={() => {
                  modal.destroy()
                }}
              />
            ),
            icon: null,
            width: 900,
            okButtonProps: {
              style: { display: 'none' },
            },
            cancelButtonProps: {
              style: { display: 'none' },
            },
          })
          return data
        })
      },
    },
    {
      key: 'inverterModuleInfo.name',
      value: '逆变器型号',
      type: 'button',
      callback: (key: string) => {
        setData((data: any) => {
          const inverterInfo = { ...data[key].inverterModuleInfo }
          inverterInfo.inverterModuleName = inverterInfo.name
          const modal = ApplicationModal.confirm({
            keyboard: false,
            title: '逆变器型号',
            closable: true,
            content: (
              <PvInverterSelect
                viewMode={!_isEdit || key === '0'}
                current={inverterInfo}
                onChange={(_data: any) => {
                  const { id, inverterName } = _data
                  data[key].inverterModuleInfo.inverterModuleId = id
                  data[key].inverterModuleInfo.name = inverterName
                  setData({ ...data })
                  modal.destroy()
                }}
                onClose={() => {
                  modal.destroy()
                }}
              />
            ),
            icon: null,
            width: 900,
            okButtonProps: {
              style: { display: 'none' },
            },
            cancelButtonProps: {
              style: { display: 'none' },
            },
          })
          return data
        })
      },
    },
    { key: '', value: '其他信息' },
    {
      key: 'systemEfficiencyInfo.systemEfficiency',
      value: '系统总效率',
      unit: '%',
      type: 'button',
      callback: (key: string) => {
        setData((data: any) => {
          console.log(data[key])
          const systemEfficiencyInfo = { ...data[key].systemEfficiencyInfo }
          const modal = Modal.confirm({
            keyboard: false,
            title: '系统总效率',
            closable: true,
            content: (
              <PvSystemEfficiency
                viewMode={!_isEdit || key === '0'}
                current={systemEfficiencyInfo}
                onChange={(_data: any) => {
                  data[key].systemEfficiencyInfo = _data
                  setData({ ...data })
                  modal.destroy()
                }}
                onClose={() => {
                  modal.destroy()
                }}
              />
            ),
            icon: null,
            width: 900,
            okButtonProps: {
              style: { display: 'none' },
            },
            cancelButtonProps: {
              style: { display: 'none' },
            },
          })
          return data
        })
      },
    },
    { key: 'systemLife', value: '系统寿命', unit: '年', disabled: true },
    { key: 'firstYearPowerAttenuation', value: '首年功率衰减率', unit: '%', min: 0, max: 100 },
    { key: 'annualPowerAttenuation', value: '逐年功率衰减率', unit: '%', min: 0, max: 100 },
    { key: '', value: '详细设计' },
    { key: 'roofModuleInfo.transverseSpacing', value: '模组横向间距', unit: 'm', min: 0, max: 100 },
    { key: 'roofModuleInfo.longitudinalSpacing', value: '模组纵向间距', unit: 'm', min: 0, max: 100 },
    // { key: 'roofModuleInfo.tiltAngle', value: '模组倾斜角度', unit: '°' },
    { key: 'roofModuleInfo.installationAzimuthAngle', value: '安装方位角度', unit: '°', min: 0, max: 360 },
    { key: 'roofModuleInfo.buildingHeight', value: '建筑高度', unit: 'm', disabled: true },
    { key: 'roofModuleInfo.supportHeight', value: '支架高度', unit: 'm', min: 0, max: 5 },
    { key: 'roofModuleInfo.inverterCapacityRate', value: '逆变器容配比', unit: '', min: 1, max: 1.5 },
    // { key: 'roofModuleInfo.selectedArea', value: '所选区域面积', unit: '㎡' },
    {
      key: 'roofModuleInfo.areaEffectiveCoefficient',
      value: '面积有效系数',
      unit: '%',
      min: 1,
      max: 100,
      precision: 0,
    },
  ])

  const [defaultData, setDefaultData] = useState({})
  const [data, setData] = useState<any>({})

  const loadData = () => {
    setData(undefined)
    TechnologyService.getList1()
      .then((resp) => {
        cur = 1
        resp.data
          .filter((o: any) => o.name.includes('光伏参数'))
          .forEach((o: any) => {
            const { name } = o
            const idx = name.replace('光伏参数', '')
            const val = parseInt(idx, 10)
            if (val && !isNaN(val)) {
              cur = Math.max(cur, val)
            }
          })
        cur++
        backup = {}
        res = {}
        setDefaultData(resp.data[0])
        setData({ ...resp.data })
        backup = resp.data
      })
      .catch(() => {})
  }

  useEffect(() => {
    loadData()
    return () => {
      cur = 1
      backup = {}
      res = {}
    }
  }, [])

  return (
    <div
      style={{
        padding: 24,
        paddingTop: 0,
      }}
    >
      <div
        style={{
          height: 76,
          fontSize: 20,
          fontWeight: 400,
          color: '#262E3F',
          lineHeight: '76px',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        光伏参数
        {!isEdit && (
          <Button
            type="primary"
            ghost
            onClick={() => {
              setIsEdit(true)
            }}
            icon={<CustomIcons type="klf-edit1" style={{ color: 'rgba(28, 184, 135, 1)' }} />}
          >
            编辑
          </Button>
        )}
      </div>
      <div>
        {data && (
          <VerticalTable
            style={{
              height: isEdit ? 'calc(100vh - 340px)' : 'calc(100vh - 290px)',
            }}
            lockHeaders={lockKeys}
            keys={keys}
            data={data}
            isEdit={isEdit}
            extraData={{
              'rental': [
                {
                  value: true,
                  label: '是',
                  data: true,
                },
                {
                  value: false,
                  label: '否',
                  data: false,
                }
              ],
            }}
            onAdd={() => {
              if (Object.keys(data).length > 6) {
                message.error('光伏参数最大设置6个')
                return
              }
              data[Date.now()] = {
                ..._.cloneDeep(defaultData),
                isDefault: false,
                id: undefined,
                name: `光伏参数${cur++}`,
              }
              setData({ ...data })
            }}
            onChange={(data: any) => {
              res = { ...data }
              console.log(res, backup)
            }}
          />
        )}
      </div>
      {isEdit && (
        <div
          style={{
            marginTop: 10,
          }}
        >
          <Button
            type="primary"
            onClick={() => {
              TechnologyService.createOrEditBatch1({
                root: [...Object.values(res)],
              })
                .then(() => {
                  loadData()
                  setIsEdit(false)
                  // location.assign(`#/user-center/user-params`)
                })
                .catch(() => {})
            }}
          >
            确定
          </Button>
          <Button
            style={{
              marginLeft: 10,
            }}
            onClick={() => {
              // location.assign(`#/user-center/user-params`)
              loadData()
              setIsEdit(false)
            }}
          >
            取消
          </Button>
        </div>
      )}
    </div>
  )
}
