
import { Badge } from 'antd'
import IconStatistic1 from '../../../assets/icon-statistic1.png'
import IconStatistic2 from '../../../assets/icon-statistic2.png'
import IconStatistic3 from '../../../assets/icon-statistic3.png'
import IconStatistic4 from '../../../assets/icon-statistic4.png'
import IconStatistic5 from '../../../assets/icon-statistic5.png'
import IconStatistic6 from '../../../assets/icon-statistic6.png'
import IconStatistic7 from '../../../assets/icon-statistic7.png'
import IconStatistic8 from '../../../assets/icon-statistic8.png'

export const STATISTIC_LIST = [
  {
    title: '当前发电功率',
    value: 0,
    icon: IconStatistic1,
    unit: 'kW',
    field: 'currPower',
  },
  {
    title: '当日发电量',
    value: 0,
    icon: IconStatistic2,
    unit: 'kWh',
    field: 'currGenerationElectricity',
  },
  {
    title: '累计发电量',
    value: 0,
    icon: IconStatistic3,
    unit: 'kWh',
    field: 'totalGenerationElectricity',
  },
  {
    title: '当前消纳率',
    value: 0,
    icon: IconStatistic4,
    unit: '%',
    field: 'absorptionRate',
  },
  {
    title: '平均消纳率',
    value: 0,
    icon: IconStatistic5,
    unit: '%',
    field: 'absorptionAvgRate',
  },
  {
    title: '节碳量',
    value: 0,
    icon: IconStatistic6,
    unit: 't',
    field: 'co',
  },
  {
    title: '等效种树',
    value: 0,
    icon: IconStatistic7,
    unit: '棵',
    field: 'tree',
  },
  {
    title: '等效车辆',
    value: 0,
    icon: IconStatistic8,
    unit: '辆',
    field: 'car',
  },
]

export const STATION_STATUS = {
  'ONLINE': {
    color: '#1EC692',
    title: '在线',
  },
  'OFFLINE': {
    color: '#98A0B3',
    title: '离线',
  },
}

export const INVERTER_COLUMNS = [
  {
    title: '逆变器名称',
    dataIndex: 'name',
    key: 'name',
    width: 150,
  },
  {
    title: '交流功率(kW)',
    dataIndex: 'acPower',
    key: 'acPower',
    width: 95,
  },
  {
    title: '额定功率(kW)',
    dataIndex: 'ratedPower',
    key: 'ratedPower',
    width: 95,
  },
  {
    title: '通讯状态',
    dataIndex: 'status',
    key: 'status',
    width: 70,
    render: (status) => {
      const { title, color } = STATION_STATUS[status] || {}
      return <Badge color={color} text={title} />
    },
  },
]

export const METER_COLUMNS = [
  {
    title: '电表名称',
    dataIndex: 'name',
    key: 'name',
    width: 150,
  },
  {
    title: '有功总电量(kWh)',
    dataIndex: 'electricityTotal',
    key: 'electricityTotal',
    width: 120,
  },
  {
    title: '总有功功率(kW)',
    dataIndex: 'powerTotal',
    key: 'powerTotal',
  },
  {
    title: '通讯状态',
    dataIndex: 'status',
    key: 'status',
    width: 70,
    render: (status) => {
      const { title, color } = STATION_STATUS[status] || {}
      return <Badge color={color} text={title} />
    },
  },
]

export const INVERTER_COLUMNS_NOUNIT = [
  {
    title: '逆变器名称',
    dataIndex: 'name',
    key: 'name',
    width: 150,
  },
  {
    title: '交流功率(kW)',
    dataIndex: 'acPower',
    key: 'acPower',
    width: 70,
  },
  {
    title: '额定功率(kW)',
    dataIndex: 'ratedPower',
    key: 'ratedPower',
    width: 66
  },
  {
    title: '状态',
    dataIndex: 'status',
    key: 'status',
    width: 70,
    render: (status) => {
      const { title, color } = STATION_STATUS[status] || {}
      return <Badge color={color} text={title} />
    },
  },
]

export const METER_COLUMNS_NOUNIT = [
  {
    title: '电表名称',
    dataIndex: 'name',
    key: 'name',
    width: 150,
  },
  {
    title: '有功总电量(kW)',
    dataIndex: 'electricityTotal',
    key: 'electricityTotal',
    width: 76,
  },
  {
    title: '总有功功率(kWh)',
    dataIndex: 'powerTotal',
    key: 'powerTotal',
    width: 76,
  },
  {
    title: '状态',
    dataIndex: 'status',
    key: 'status',
    width: 60,
    render: (status) => {
      const { title, color } = STATION_STATUS[status] || {}
      return <Badge color={color} text={title} />
    },
  },
]