import React from 'react'
import { Steps } from 'antd'
import IconStep from '../../../../../assets/icon-step.png'
import IconStepSuccess from '../../../../../assets/icon-step-success.png'

import '../../index.less'

export type IProps = {
  current?: number
  onChange?: (current: number) => void
}

const { Step } = Steps

const DefaultSteps = ['选择基础信息', '输入渠道信息', '完成创建']

const StepBar = React.memo((props: IProps) => {
  const { current = 0, onChange } = props

  const handleChange = (current: number) => {
    onChange?.(current)
  }

  return (
    <Steps className="power-station-steps" current={current} onChange={handleChange}>
      {DefaultSteps.map((item, index) => (
        <Step
          key={index}
          icon={<img width={24} src={current < index ? IconStep : IconStepSuccess} alt="" />}
          title={item}
        />
      ))}
    </Steps>
  )
})

export default StepBar
