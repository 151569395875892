// @ts-ignore
import mapboxgl, {GeoJSONSource} from '!mapbox-gl'
import MapboxDraw from "@mapbox/mapbox-gl-draw";
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css'
import 'mapbox-gl/dist/mapbox-gl.css'

import React, {useEffect, useState} from 'react'
import {Button, message, Select, Spin} from 'antd'
import {EvaluationService, ProjectServiceHistory} from '../../apis/project-service-history'
// import { VectorSource } from 'mapbox-gl'
import {CustomIcons, getDomData} from '../../global'
import {EvaluationV2Api} from '../../services'


let last = 1;

const provinceBbox = {
  '100000': [73.502355, 3.83703, 135.09567, 53.563624],
  '110000': [115.416855, 39.441461, 117.508134, 41.059233],
  '120000': [116.702128, 38.554815, 118.059217, 40.25179],
  '130000': [113.458721, 36.048394, 119.866915, 42.617629],
  '140000': [110.224329, 34.583264, 114.562939, 40.738366],
  '150000': [97.171967, 37.406246, 126.067939, 53.335367],
  '210000': [118.839424, 38.718924, 125.785621, 43.488571],
  '220000': [121.638323, 40.864614, 131.314285, 46.301935],
  '230000': [121.174279, 43.42301, 135.09567, 53.563624],
  '310000': [120.85262, 30.67779, 122.242919, 31.874625],
  '320000': [116.357106, 30.760149, 121.970943, 35.125012],
  '330000': [118.023019, 27.044745, 122.945311, 31.180867],
  '340000': [114.877479, 29.395624, 119.644266, 34.651695],
  '350000': [115.847634, 23.531158, 120.723299, 28.316203],
  '360000': [113.572778, 24.488947, 118.482141, 30.079839],
  '370000': [114.813671, 34.378534, 122.711101, 38.401667],
  '410000': [110.355026, 31.384369, 116.646412, 36.366508],
  '420000': [108.36247, 29.032624, 116.129963, 33.277542],
  '430000': [108.786641, 24.639321, 114.256035, 30.129091],
  '440000': [109.65079, 20.21418, 117.313712, 25.519616],
  '450000': [104.447754, 20.900972, 112.056399, 26.389041],
  '460000': [108.583473, 3.83703, 117.841863, 20.166159],
  '500000': [105.286411, 28.163658, 110.194854, 32.203442],
  '510000': [97.347424, 26.049272, 108.54241, 34.314704],
  '520000': [103.599576, 24.621194, 109.593349, 29.224317],
  '530000': [97.527699, 21.144353, 106.194221, 29.226134],
  '540000': [78.393301, 26.858273, 99.115193, 36.48355],
  '610000': [105.488224, 31.706994, 111.241569, 39.585793],
  '620000': [92.337825, 32.596184, 108.707503, 42.79343],
  '630000': [89.401766, 31.600933, 103.068637, 39.207695],
  '640000': [104.284273, 35.238766, 107.654309, 39.380676],
  '650000': [73.502355, 34.336312, 96.385334, 49.181411],
  '710000': [119.313606, 21.754124, 124.561574, 25.928982],
  '810000': [113.834898, 22.154044, 114.44239, 22.561917],
  '820000': [113.528181, 22.10915, 113.598862, 22.217043]
}


const marks: any = {}
for (let i = 1; i <= 12; i++) {
  marks[i] = `${i}月`
}


const changeLayerVisibility = (map: any, id: string, visibility: boolean) => {
  map.setLayoutProperty(id, 'visibility', visibility ? 'visible' : 'none')
}

import bbox from "@turf/bbox";
import area from "@turf/area";
import {debounce} from "lodash";
import {MountainCalculation} from "./mountain-calculation";
import lineSegment from "@turf/line-segment";
import distance from "@turf/distance";
import center from "@turf/center";
import {points} from "@turf/turf";
import {parseNumber} from "./resource-map";
import {ResourcesControllerService} from "../../apis/project-service";

;(window as any).doAdd = (id: number, name: string) => {
  EvaluationService.add({
    root: [
      {
        regionCode: (window as any).currentAdcode,
        name,
        id
      }
    ]
  }).then(() => {
    message.success('成功添加至资源管理列表').then()
    const doms = document.getElementsByClassName('add-source')
    if (doms) {
      for (let i = 0; i < doms.length; i++) {
        const dom: any = doms[i]
        dom.innerText = '已添加'
        dom.onclick = null
      }
    }
  })
}

export const ResourceMapboxMountain = (props: any) => {

  // const [loading, setLoading] = useState(false)
  const [, setRegionData] = useState([])
  const [map, setMap] = useState<mapboxgl.Map>()
  const [drawer, setDrawer] = useState<MapboxDraw>()
  const [selectedId, setSelectedId] = useState();
  const [loading, setLoading] = useState(false);
  const [showAction, setShowAction] = useState(false)
  const [layerType, setLayerType] = useState('wx')
  const [showCoverLayer] = useState(false)
  const [popup, setPopup] = useState<any>();

  const [areaEditable, setAreaEditable] = useState(true);

  useEffect(() => {
    if (!map) return
    if (!props.hideCluster) {
      if (props.pointerData && props.srcData) {
        ;(window as any).pointerData = props.pointerData
        ;(window as any).srcData = props.srcData
        const dt = props.pointerData.map(o => {
          const adcode = o.adcode
          const so = props.srcData.find(o => o.adcode == adcode)

          if (so) {
            return {
              ...o,
              lng: so.lng,
              lat: so.lat
            }
          } else {
            return undefined
          }
        }).filter(Boolean)

        // [73.502355, 3.83703, 135.09567, 53.563624]
        const features = dt.map((o: any) => {
          return {
            type: 'Feature',
            properties: {
              point_count: 1
            },
            geometry: {
              type: 'Point',
              coordinates: [o.lng, o.lat]
            }
          }
        })
        const data: any = {
          type: 'FeatureCollection',
          features
        };

        (map.getSource('earthquakes') as GeoJSONSource).setData(data)
        changeLayerVisibility(map, 'unclustered-point', true)
        changeLayerVisibility(map, 'cluster-count', true)
        changeLayerVisibility(map, 'clusters', true)
      }
    } else {
      changeLayerVisibility(map, 'unclustered-point', false)
      changeLayerVisibility(map, 'cluster-count', false)
      changeLayerVisibility(map, 'clusters', false)

    }
  }, [props.hideCluster, props.pointerData, props.srcData, map])

  useEffect(() => {
    if (!layerType || !map) {
      return
    }

    const roadLayers = map.getStyle().layers.filter(function (layer) {
      // filter out the road label layer
      return (layer.id.includes('road-') || layer.id.includes('tunnel-') || layer.id.includes('bridge-'))
    })

    switch (layerType) {
      case 'wx':
        changeLayerVisibility(map, 'stLayer', true)
        roadLayers.forEach(function (layer) {
          changeLayerVisibility(map, layer.id, false)
        })
        break
      case 'road':
        changeLayerVisibility(map, 'stLayer', true)
        roadLayers.forEach(function (layer) {
          changeLayerVisibility(map, layer.id, true)
        })
        break
      default:
        changeLayerVisibility(map, 'stLayer', false)
        roadLayers.forEach(function (layer) {
          changeLayerVisibility(map, layer.id, true)
        })
    }
  }, [layerType, map])

  const [features, setFeatures] = useState([]);

  const [adInfo, setAdInfo] = useState();

  useEffect(() => {
    last = 1;
    new EvaluationV2Api().i3060RegionRoofGetRegionDataGet({}).then((resp: any) => {
      setRegionData(resp.data)
    })
  }, [])

  useEffect(() => {
    if (!map) {
      return;
    }
    ;(window as any).curFeatures = features;
    popup?.remove();
    const fcs = {
      type: 'FeatureCollection',
      features: [],
    }
    features.forEach((o: any) => {
      const seg = lineSegment(o);
      seg.features?.forEach(oo => {
        const [p1, p2] = oo.geometry.coordinates;
        const dis = distance(p1, p2, {units: 'meters'});
        const ct = center(points(oo.geometry.coordinates))
        ct.properties.distance = `${parseNumber(dis, {
          type: 'e',
          radix: 2,
          beginValue: 100000,
        })} m`;
        fcs.features.push(ct);
      })

      const ct2 = center(o);
      const a = area(o);
      ct2.properties.distance = `${parseNumber(a, {
        type: 'e',
        radix: 2,
        beginValue: 100000,
      })} ㎡`;

      fcs.features.push(ct2);
    });
    (map.getSource('draw-feature-lines') as GeoJSONSource).setData(fcs);
  }, [features])

  const addSateliteLayer = (map: any) => {
    map.addSource('satelite', {
      type: 'raster',
      maxzoom: 20,
      tiles: ['https://map.i3060.com/static/v1.0.0/klfmbt/{z}/{x}/{y}.png']
    })

    map.addLayer(
      {
        id: 'stLayer',
        type: 'raster',
        maxzoom: 20,
        source: 'satelite',
        'source-layer': 'default',
        paint: {
          'raster-opacity': 1
        }
      },
      'building-underground'
    )
  }

  const addGroupLayer = (map) => {
    const data: any = {
      type: 'FeatureCollection',
      features: []
    }

    map.addSource('earthquakes', {
      type: 'geojson',
      data,
      cluster: true,
      clusterMaxZoom: 20,
      clusterRadius: 50
    })

    map.addLayer({
      id: 'clusters',
      type: 'circle',
      source: 'earthquakes',
      filter: ['has', 'point_count'],
      paint: {
        'circle-color': [
          'step',
          ['get', 'point_count'],
          '#51bbd6',
          2,
          '#51bbd6',
          10,
          '#f1f075',
          20,
          '#f28cb1'
        ],
        'circle-radius': [
          'step',
          ['get', 'point_count'],
          5,
          2,
          20,
          10,//
          30
        ]
      }
    })

    map.addLayer({
      id: 'cluster-count',
      type: 'symbol',
      source: 'earthquakes',
      filter: ['has', 'point_count'],
      layout: {
        'text-field': '{point_count_abbreviated}',
        'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
        'text-size': 12
      }
    })

    map.addLayer({
      id: 'unclustered-point',
      type: 'circle',
      source: 'earthquakes',
      filter: ['!', ['has', 'point_count']],
      paint: {
        'circle-color': '#11b4da',
        'circle-radius': 4,
        'circle-stroke-width': 1,
        'circle-stroke-color': '#fff'
      }
    })
  }

  const doScreenShoot = (callback: (data) => void) => {
      drawer.changeMode('simple_select')
      const geojson = drawer.getAll();
      popup?.remove();
      const bound = bbox(geojson)
      const spl = 3
      const dx = bound[2] - bound[0]
      const dy = bound[3] - bound[1]
      const dsx = dx / spl
      const dsy = dy / spl
      const lt: [number, number] = [bound[0] - dsx, bound[1] - dsy]
      const rb: [number, number] = [bound[2] + dsx, bound[3] + dsy]
      const min = map.project(lt)
      const max = map.project(rb)
      map.fitScreenCoordinates(min, max, map.getBearing(), {
        animate: false,
      });

      setTimeout(() => {
        const dom = document.getElementById('map');
        getDomData(dom, {
          width: dom.clientWidth,
          height: dom.clientHeight,
          x: 10,
          y: 10,
        }).then(r => {
          callback?.(r);
        })
      });
    }

  ;(window as any).doShootMap = doScreenShoot;


  const addDrawLineLayer = (map: mapboxgl.Map) => {
    map.addSource(
      'draw-feature-lines',
      {
        type: 'geojson',
        data: {
          type: 'FeatureCollection',
          features: [],
        }
      }
    );

    map.addLayer({
      "id": "draw-feature-lines-label",
      "type": "symbol",
      "source": "draw-feature-lines",
      "layout": {
        "text-field": "{distance}",
        "text-size": 20
      },
      "paint": {
        "text-color": "white",
      }
    });

  }

  const moveCenter = (map, adcode = 100000) => {
    const bound = provinceBbox[adcode]
    moveCenterBounds(map, bound)
  }

  const moveCenterBounds = (map, bound: number[]) => {
    const spl = 9
    const dx = bound[2] - bound[0]
    const dy = bound[3] - bound[1]
    const dsx = dx / spl
    const dsy = dy / spl
    const lt: [number, number] = [bound[0] - dsx, bound[1] - dsy]
    const rb: [number, number] = [bound[2] + dsx, bound[3] + dsy]
    const min = map.project(lt)
    const max = map.project(rb)
    map.fitScreenCoordinates(min, max, map.getBearing())
  }

  const createMapbox = () => {
    const map = new mapboxgl.Map({
      style: 'mapbox://styles/owops/cl2h1u3kx000u15o26t9bmtyh',
      center: [120.412002, 36.075867],
      zoom: 3.8,
      minZoom: 1,
      maxZoom: 20,
      container: 'map',
      preserveDrawingBuffer: true,
      projection: 'equirectangular' // starting projection
    } as any)

    const draw = new MapboxDraw({
      displayControlsDefault: false,
      controls: {},
    });

    map.addControl(draw, 'top-left');

    setDrawer(draw);

    moveCenter(map)

    map.on('zoomend', () => {
      console.log(map.getZoom())
    })

    map.on('load', () => {

      const updateArea = (args) => {
        console.log(args.features[0].id);

        popup.remove();
        const o = args.features[0];

        if (o) {
          const ct = center(o).geometry?.coordinates;
          if (ct) {
            const [x, y] = ct;
            ResourcesControllerService.getI3060ResourceGetAddressInfoByXy({
              x: x.toString(),
              y: y.toString(),
            }).then(resp => {
              setFeatures((features) => {
                const obj = features.find(ft => ft.id === o.id);
                if (obj?.properties) {
                  const id = o.id;
                  const addressDom: HTMLElement = document.getElementById(`${id}-address`);
                  if (addressDom) {
                    addressDom.innerText = resp.data.address;
                  }
                  const ghiDom: HTMLElement = document.getElementById(`${id}-ghi`);
                  if (ghiDom) {
                    ghiDom.innerText = resp.data.ghi;
                  }
                  obj.properties.ghi = resp.data.ghi;
                  obj.properties.address = resp.data.address;
                }
                ;(window as any).curFeatures = features;
                return features;
              });
              o.properties.ghi = resp.data.ghi;
              o.properties.address = resp.data.address;
            }).catch(() => {
            })
          }
        }

        const data = draw.getAll();
        setFeatures((features) => {
          data.features.forEach(oo => {
            const obj = features.find(ooo => ooo.id === oo.id);
            if(obj) {
              oo.properties.idx = obj.properties.idx;
            } else {
              oo.properties.idx = last++;
            }
          })
          if (data?.features?.length > 0) {
            const ct = center(data)?.geometry?.coordinates;
            if (ct) {
              const [x, y] = ct;
              ResourcesControllerService.getI3060ResourceGetAddressInfoByXy({
                x: x.toString(),
                y: y.toString(),
              }).then(resp => {
                setAdInfo(resp.data)
              }).catch(() => {
              })
            }
          }
          return data?.features;
        });
      }

      const popup = new mapboxgl.Popup({
        closeButton: false,
        closeOnClick: true,
        closeOnMove: true
      })

      setPopup(popup);

      const selectionChange = (args) => {
        if ((draw.getMode() === 'simple_select' || draw.getMode() === 'direct_select') && args.features?.[0]) {
          setTimeout(() => {
            setSelectedId(args.features[0].id)
            const bounds = bbox(args.features[0]);
            const featureArea = area(args.features[0]);
            const [x1, y1, x2, y2] = bounds;
            const center = [(x1 + x2) / 2, (y1 + y2) / 2];
            const properties = (window as any).curFeatures?.find(o => o.id === args.features[0].id)?.properties;
            popup
              .setLngLat(center)
              .setHTML(`<div style="font-weight: 500;">区域${properties?.idx || ''}</div><div><div style="margin: .5rem 0;">区域面积：<span class="number-primary">${parseNumber(featureArea, {
                type: 'e',
                radix: 2,
                beginValue: 100000,
              })}</span> ㎡</div><div style="margin: .5rem 0;">年总辐照：<span id="${args.features[0].id}-ghi"  class="number-primary">${parseNumber(properties?.ghi, {
                type: 'e',
                radix: 2,
                beginValue: 100000,
              })}</span> kWh/㎡</div></div><div>地址：<span id="${args.features[0].id}-address">${properties?.address || ''}</span></div>`)
              .setMaxWidth('470px')
              .addTo(map)
          }, 100);
        } else {
          setSelectedId(undefined)
        }
      }

      map.on('draw.selectionchange', selectionChange);
      map.on('draw.create', updateArea);
      map.on('draw.delete', updateArea);
      map.on('draw.update', updateArea);

      map.addSource('countries', {
        type: 'vector',
        url: 'mapbox://mapbox.country-boundaries-v1'
      })
      map.addLayer(
        {
          id: 'countries-join',
          type: 'fill',
          source: 'countries',
          'source-layer': 'country_boundaries',
          paint: {
            'fill-color': '#0000FF',
            'fill-opacity': 0
          },
          filter: ['match', ['get', 'worldview'], ['all', 'CN'], true, false]
        },
        'admin-1-boundary-bg'
      )

      addSateliteLayer(map)

      addGroupLayer(map)

      addDrawLineLayer(map)

      ;(window as any).mbxMap = map;
      setMap(map)

    })
  }
  useEffect(() => {
    setTimeout(() => {
      createMapbox()
    }, 0)
  }, [])

  const [addressList, setAddressList] = React.useState<any[]>([])

  const onSearch = (val: string) => {
    ProjectServiceHistory.inputTips({cityCode: '100000', address: val}).then((resp: any) => {
      setAddressList(resp.data)
    })
  }

  return (
    <div style={{
      position: 'absolute',
      left: 0,
      right: 0,
      top: 0,
      bottom: 0
    }}>
      <div
        id='map'
        style={{
          width: '100%',
          height: '100%'
        }}
      />

      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'flex-end',
          position: 'absolute',
          overflow: 'hidden',
          justifyContent: 'flex-end',
          right: 20,
          bottom: 20
        }}
      >
        {showAction && (
          <div
            style={{
              width: 114,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-end'
            }}
          >
            <div style={{
              display: 'flex',
              flexDirection: 'row'
            }}>

              {layerType !== 'custom' && (
                <div
                  style={{
                    backgroundColor: 'white',
                    borderRadius: 4,
                    height: 40,
                    marginBottom: 8,
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: 114,
                    pointerEvents: 'all',
                    cursor: 'pointer',
                    fontSize: 14,
                    color: layerType === 'road' ? 'rgba(28, 184, 135, 1)' : 'rgba(38, 46, 63, 1)'
                  }}
                  onClick={() => {
                    setLayerType((layerType) => {
                      if (layerType === 'wx') {
                        return 'road'
                      } else {
                        return 'wx'
                      }
                    })
                  }}
                >
                  <span
                    style={{
                      marginRight: 10,
                      fontSize: 16,
                      lineHeight: '16px'
                    }}
                  >
                    <CustomIcons type='klf-icon_yixuan-danxuan'/>
                  </span>
                  查看路网
                </div>
              )}
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                backgroundColor: 'white',
                borderRadius: 4,
                height: 40,
                width: showCoverLayer ? 88 : '100%'
              }}
            >
              <div
                style={{
                  flexGrow: 1,
                  textAlign: 'center',
                  pointerEvents: 'all',
                  cursor: 'pointer',
                  color: layerType !== 'custom' ? 'rgba(28, 184, 135, 1)' : 'rgba(38, 46, 63, 1)'
                }}
                onClick={() => {
                  setLayerType((layerType) => {
                    if (layerType === 'wx' || layerType === 'road') {
                      return 'custom'
                    } else {
                      return 'road'
                    }
                  })
                }}
              >
                  <span
                    style={{
                      marginRight: 10,
                      fontSize: 16
                    }}
                  >
                    <CustomIcons type={layerType !== 'custom' ? 'klf-a-juxing7' : 'klf-a-juxing6'}/>
                  </span>
                卫星
              </div>
            </div>
          </div>
        )}
        <div
          style={{
            marginLeft: 16,
            fontSize: 24
          }}
        >
          <div
            style={{
              width: 24,
              height: 24,
              lineHeight: '24px',
              textAlign: 'center',
              borderRadius: 2,
              pointerEvents: 'all',
              cursor: 'pointer',
              backgroundColor: 'white'
            }}
            onClick={() => {
              map?.zoomIn()
            }}
          >
            <CustomIcons type='klf-a-juxing2'/>
          </div>
          <div
            style={{
              width: 24,
              height: 24,
              lineHeight: '24px',
              textAlign: 'center',
              borderRadius: 2,
              pointerEvents: 'all',
              cursor: 'pointer',
              backgroundColor: 'white',
              marginTop: 1
            }}
            onClick={() => {
              map?.zoomOut()
            }}
          >
            <CustomIcons type='klf-a-juxing3'/>
          </div>
          <div
            style={{
              width: 24,
              height: 24,
              lineHeight: '24px',
              textAlign: 'center',
              borderRadius: 2,
              pointerEvents: 'all',
              cursor: 'pointer',
              backgroundColor: 'white',
              marginTop: 4
            }}
            onClick={() => {
              const bound = [73.502355, 3.83703, 135.09567, 53.563624]
              const spl = 9
              const dx = bound[2] - bound[0]
              const dy = bound[3] - bound[1]
              const dsx = dx / spl
              const dsy = dy / spl
              const lt: [number, number] = [bound[0] - dsx, bound[1] - dsy]
              const rb: [number, number] = [bound[2] + dsx, bound[3] + dsy]
              const min = map.project(lt)
              const max = map.project(rb)
              map.fitScreenCoordinates(min, max, map.getBearing())
            }}
          >
            <CustomIcons type='klf-a-juxing1'/>
          </div>
          <div
            style={{
              width: 24,
              height: 24,
              lineHeight: '24px',
              textAlign: 'center',
              borderRadius: 2,
              pointerEvents: 'all',
              cursor: 'pointer',
              backgroundColor: 'white',
              marginTop: 4
            }}
            onClick={() => {
              setShowAction(!showAction)
            }}
          >
            <CustomIcons type={showAction ? 'klf-a-juxing8' : 'klf-juxing'}/>
          </div>
        </div>
      </div>
      <div style={{
        position: "absolute",
        top: 20,
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        left: 20,
        height: 40,
        width: 200,
      }}>
        <Select
          className="gaode-input small-empty"
          size="middle"
          showSearch
          style={{
            width: '100%',
          }}
          onChange={(val: string) => {
            const obj = JSON.parse(val)
            if (obj.location) {
              const [lng, lat] = obj.location.split(',').map(parseFloat);
              map.setCenter([lng, lat]);
              map.setZoom(13);
            }
          }}
          onSearch={debounce(onSearch, 300)}
          filterOption={() => true}
          placeholder="请搜索地址"
          suffixIcon={<div
            style={{
              marginBottom: 7,
            }}
          >
            <CustomIcons
              type="klf-search"
            />
          </div>}
        >
          {addressList?.map((o: any, idx) => (
            <Select.Option key={`${o.address}--${idx}`} value={JSON.stringify(o)}>
              {o.name}
              <span className="hide-in-input" style={{fontSize: 10, color: 'lightgrey', marginLeft: '1rem'}}>
                      {o.district}
                    </span>
            </Select.Option>
          ))}
        </Select>
      </div>
      {
        areaEditable && <div style={{
          position: "absolute",
          bottom: 40,
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          left: 0,
          right: 0,
          height: 40,
        }}>
          <Button onClick={() => {
            drawer.changeMode('draw_polygon')
          }}><CustomIcons type="klf-gangbigongju"/></Button>

          <Button onClick={() => {
            drawer.changeMode('simple_select', {
              featureIds: [selectedId],
            });
            drawer.trash()
            popup.remove();
          }}><CustomIcons type="klf-delete"/></Button>

        </div>
      }
      {
        features &&
        <div
          style={{
            position: "absolute",
            top: 20,
            right: 20,
            width: 350,
            backgroundColor: "white",
            padding: 0,
            maxHeight: "calc(100% - 60px)",
            overflowY: "auto",
          }}
        >
          <MountainCalculation
            setLoading={setLoading}
            selectedId={selectedId}
            changeSelectedId={(id) => setSelectedId(id)}
            adInfo={adInfo} onCalc={props.onCalc} data={features} drawer={drawer}
            setAreaEditable={(fl) => {
              setAreaEditable(fl);
            }}
            onChange={(features) => {
              popup.remove();
              setFeatures(features);
            }}/>
        </div>
      }
      {
        loading && <div style={{
          position: 'fixed',
          left: 0,
          right: 0,
          top: 0,
          bottom: 0,
          backgroundColor: 'rgba(0,0,0,0.3)',
          pointerEvents: 'all',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}>
          <div><Spin spinning></Spin></div>
          <div style={{color: 'white'}}>报告生成中...</div>
        </div>
      }
    </div>
  )
}
