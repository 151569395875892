import * as echarts from 'echarts'

export default{  
    //模板发展目标
    plateDevelopmentGoals:{
      title:"模板发展目标",
      data:function(){
          let opt = {
            backgroundColor:'#fff',
            title: [
              {
                text:'单位MW',
                 textStyle: {'color':'#333333',fontSize: 12},
                top:'2%',
                // right: 'right',
                left:'left'
              },
            ],
            tooltip: {
              trigger: 'axis',
              axisPointer: { // 坐标轴指示器，坐标轴触发有效
                type: 'shadow' // 默认为直线，可选为：'line' | 'shadow'
              }
            },
            grid: {
              left: '2%',
              right: '4%',
              bottom: '4%',
              top:'20%',
              containLabel: true
            },
            legend: {
              data: ['屋面目标','屋面容量','屋面超额完成容量','地面目标','地面容量','地面超额完成容量'],
              top:'10%',
              textStyle: {
                color: "#000"
              },
              itemHeight: 10,
            },
            xAxis: {
              type: 'category',
              data: ['A板块','B板块','G板块','D板块','C板块'],
              axisLine: {
                lineStyle: {
                  color: '#4A4A4A'
                }
              },
              axisLabel: {
                textStyle: {
                  fontFamily: 'Microsoft YaHei'
                }
          
              },
            },
    
            yAxis: {
              type: 'value',
              // max:'1200',
              axisLine: {
                show: false,
                lineStyle: {
                  color: '#4A4A4A'
                }
              },
              splitLine: {
                show: true,
                lineStyle: {
                  color: '#F4F4F4'
                }
              },
              axisLabel: {
          }
            },
            "dataZoom": [{
              "show": false,
              "height": 12,
              "zoomOnMouseWheel":false,
              "xAxisIndex": [
                0
              ],
              bottom:'8%',
              "start": 0,
              "end": 100,
              handleIcon: 'path://M306.1,413c0,2.2-1.8,4-4,4h-59.8c-2.2,0-4-1.8-4-4V200.8c0-2.2,1.8-4,4-4h59.8c2.2,0,4,1.8,4,4V413z',
              handleSize: '110%',
              handleStyle:{
                color:"#d3dee5",
    
              },
              textStyle:{
                color:"#000"},
              borderColor:"#90979c"
            }, {
              "type": "inside",
              "show": false,
              "height": 15,
              "start": 1,
              "end": 35
            }],
            series: [{
              name: '屋面目标',
              type: 'bar',
              barWidth: '10%',
              itemStyle: {
                normal: {
                  color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                    offset: 0,
                    color: '#52B3F8'
                  }]),
                },
              },
              data: [8000, 7500, 6500, 5500, 4500]
            },
            {
              name: '屋面容量',
              type: 'bar',
              barWidth: '10%',
              itemStyle: {
                normal: {
                  color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                    offset: 0,
                    color: '#40CEA2'
                  }]),
                },
              },
              data: [8100, 7400, 6600, 5700, 4800]
            },
            {
              name: '屋面超额完成容量',
              type: 'bar',
              barWidth: '10%',
              itemStyle: {
                normal: {
                  color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                    offset: 0,
                    color: '#FBC25B'
                  }]),
                },
              },
              data: [6100, 8400, 7600, 4700, 5800]
            },
        {
          name: '地面目标',
          type: 'bar',
          barWidth: '10%',
          itemStyle: {
            normal: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                offset: 0,
                color: '#917DF2'
              }]),
            },
          },
          data: [6100, 8400, 7600, 4700, 5800]
        },
        {
          name: '地面容量',
          type: 'bar',
          barWidth: '10%',
          itemStyle: {
            normal: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                offset: 0,
                color: '#D33AFF'
              }]),
            },
          },
          data: [6100, 8400, 7600, 4700, 5800]
        },
        {
          name: '地面超额完成容量',
          type: 'bar',
          barWidth: '10%',
          itemStyle: {
            normal: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                offset: 0,
                color: '#F677A0'
              }]),
            },
          },
          data: [6100, 8400, 7600, 4700, 5800]
        },
        ]};    
          return opt;
      },
      insideAndOutsideProvince:function(){
        let opt = {
					// backgroundColor:'#fff',
					tooltip: {
						trigger: 'item',
						// formatter: "{a} <br/>{b} : {c} ({d}%)"
						formatter: "{b} : {c}%"
					},
					series: [{
						name: '',
						type: 'pie',
						radius: ['40%', '70%'],
						label: {
							normal: {
								textStyle: {
									color: '#000000'
								},
								formatter: '{name|{b}}\n{time|{c}%}',
								minMargin: 5,
								edgeDistance: 190,
								lineHeight: 15,

								rich: {
									time: {
										fontSize: 10,
										color: '#999999'
									},
								},
							},
						},
						data: [{
								value: 68,
								name: '省内',
								itemStyle: {
									color: "#0096FA"
								}
							},
							{
								value: 32,
								name: '省外',
								itemStyle: {
									color: "#FAD954"
								}
							}
						],
						emphasis: {
							itemStyle: {
								shadowBlur: 10,
								shadowOffsetX: 0,
								shadowColor: 'rgba(0, 0, 0, 0.5)'
							}
						},
					}],
				};

        return opt;
      },
    },
    //发展目标任务
    plate:{
        title:"发展目标任务",
        data:function(){
            let opt ={
                title: {
                    text: ''
                  },
                  tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                      type: 'cross',
                      label: {
                        backgroundColor: '#6a7985'
                      }
                    }
                  },
                  legend: {
                    data: ['A板块', 'B板块', 'C板块', 'D板块']
                  },
                  grid: {
                    left: '2%',
                    right: '3%',
                    bottom: '3%',
                    containLabel: true
                  },
                  xAxis: [
                    {
                      type: 'category',
                      boundaryGap: false,
                      data: ['2016-06', '2017-03', '2019-12', '2020-03', '2020-04', '2020-07', '2020-12'],
                      axisLine:{  //x轴线
                                                      show:false,
                                                      lineStyle: {
                                                          color: '#4A4A4A'
                                                      }
                                                  },
                    }
                  ],
                  yAxis: [
                    {
                      type: 'value',
                      axisLine:{  //x轴线
                                                      show:false,
                                                      lineStyle: {
                                                          color: '#4A4A4A'
                                                      }
                                                  },
                    }
                  ],
                  series: [
                    {
                      name: 'A板块',
                      type: 'line',
                      stack: 'Total',
                      itemStyle: { 
                        normal: { 
                          color: '#D1E9FF'  // 折线的颜色
                        } 
                      },
                      areaStyle: {
                      },
                      emphasis: {
                        focus: 'series'
                      },
                      data: [120, 132, 101, 134, 90, 230, 210]
                    },
                    {
                      name: 'B板块',
                      type: 'line',
                      stack: 'Total',
                      areaStyle: {},
                      itemStyle: { 
                              normal: { 
                                color: '#B4E3EC'  // 折线的颜色
                              } 
                            },
                      emphasis: {
                        focus: 'series'
                      },
                      data: [220, 182, 191, 234, 290, 330, 310]
                    },
                    {
                      name: 'C板块',
                      type: 'line',
                      stack: 'Total',
                      areaStyle: {},
                      itemStyle: { 
                              normal: { 
                                color: '#DE6CE0'  // 折线的颜色
                              } 
                            },
                      emphasis: {
                        focus: 'series'
                      },
                      data: [150, 232, 201, 154, 190, 330, 410]
                    },
                    {
                      name: 'D板块',
                      type: 'line',
                      stack: 'Total',
                      areaStyle: {},
                      itemStyle: { 
                              normal: { 
                                color: '#40CEA2'  // 折线的颜色
                              } 
                            },
                      emphasis: {
                        focus: 'series'
                      },
                      data: [320, 332, 301, 334, 390, 330, 320]
                    }
                  ]
              };
            return opt;
        }
    },
    //模板新增容量
    newPlateCapacity: {
        title:"发展目标任务",
        data:function(){
            let opt = {
                backgroundColor:'#fff',
                title: [
                  {
                    text:'模板新增容量',
                     textStyle: {'color':'#333333',fontSize: 15},
                    top:'3%',
                    left:'center'
                  },
                ],
                tooltip: {
                  trigger: 'axis',
                  axisPointer: { // 坐标轴指示器，坐标轴触发有效
                    type: 'shadow' // 默认为直线，可选为：'line' | 'shadow'
                  }
                },
                grid: {
                  left: '2%',
                  right: '4%',
                  bottom: '4%',
                  top:'20%',
                  containLabel: true
                },
                legend: {
                  data: ['屋面光伏容量','地面光伏容量'],
                  top:'10%',
                  textStyle: {
                    color: "#000"
                  },
                  itemHeight: 10,
                },
                xAxis: {
                  type: 'category',
                  data:['A板块','B板块','C板块','D板块','E板块','F板块'],
                  axisLine: {
                    lineStyle: {
                      color: 'black'
        
                    }
                  },
                  axisLabel: {
                    textStyle: {
                      fontFamily: 'Microsoft YaHei'
                    }
                  },
                },
        
                yAxis: {
                  type: 'value',
                  // max:'1200',
                  axisLine: {
                    show: false,
                    lineStyle: {
                      color: '#000'
                    }
                  },
                  splitLine: {
                    show: true,
                    lineStyle: {
                      color: '#cccccc'
                    }
                  },
                  axisLabel: {}
                },
                "dataZoom": [{
                  "show": false,
                  "height": 12,
                  "zoomOnMouseWheel":false,
                  "xAxisIndex": [
                    0
                  ],
                  bottom:'8%',
                  "start": 0,
                  "end": 100,
                  handleIcon: 'path://M306.1,413c0,2.2-1.8,4-4,4h-59.8c-2.2,0-4-1.8-4-4V200.8c0-2.2,1.8-4,4-4h59.8c2.2,0,4,1.8,4,4V413z',
                  handleSize: '110%',
                  handleStyle:{
                    color:"#d3dee5",
        
                  },
                  textStyle:{
                    color:"#000"},
                  borderColor:"#90979c"
                }, {
                  "type": "inside",
                  "show": false,
                  "height": 15,
                  "start": 1,
                  "end": 35
                }],
                series: [{
                  name: '屋面光伏容量',
                  type: 'bar',
                  barWidth: '25%',
                  itemStyle: {
                    normal: {
                      color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                        offset: 0,
                        color: '#3AA0FF'
                      }]),
                    },
                  },
                  data: [8000, 7500, 6500, 5500, 4500,8000, 7500, 6500, 5500, 4500,3000,2000]
                },
                {
                  name: '地面光伏容量',
                  type: 'bar',
                  barWidth: '25%',
                  itemStyle: {
                    normal: {
                      color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                        offset: 0,
                        color: '#BB57FF'
                      }]),
                      // barBorderRadius: 12,
                    },
                  },
                  data: [8100, 7400, 6600, 5700, 4800,3000,4000,5000,6000,6500,3500,4000]
                },
                ]
              };
            return opt;
        }
    },
    //省内外总容量统计
    totalCapacityStatistics:{
      title:"省内外总容量统计",
      roofData:function(){
          let opt = {
            // backgroundColor:'#fff',
              tooltip: {
                  trigger: 'item',
                  // formatter: "{a} <br/>{b} : {c} ({d}%)"
                  formatter: "{b} : {c}%"
                },
                series: [
                  {
                    name: '',
                    type: 'pie',
                    radius: ['40%', '70%'],
                    label: {
              normal:{
                textStyle:{
                  color:'#000000'
                },
                formatter: '{name|{b}}\n{time|{c}%}',
                minMargin: 5,
                edgeDistance: 190,
                lineHeight: 15,
                
                rich: {
                  time: {
                  fontSize: 10,
                  color: '#999999'
                  },
                },
              },
              
                    },
                    data: [
                      { value: 68, name: '省内',itemStyle: {color: "#4D6FED"} },
                      { value: 32, name: '省外',itemStyle: {color: "#DE6CE0"}  }
                    ],
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            },
                  }
                ],
          };
    
          return opt;
      },
      groundData:function(){
          let opt = {
            // backgroundColor:'#fff',
              tooltip: {
                  trigger: 'item',
                  // formatter: "{a} <br/>{b} : {c} ({d}%)"
                  formatter: "{b} : {c}%"
                },
                series: [
                  {
                    name: '',
                    type: 'pie',
                    radius: ['40%', '70%'],
                    label: {
              normal:{
                textStyle:{
                  color:'#000000'
                },
                formatter: '{name|{b}}\n{time|{c}%}',
                minMargin: 5,
                edgeDistance: 190,
                lineHeight: 15,
                
                rich: {
                  time: {
                  fontSize: 10,
                  color: '#999999'
                  },
                },
              },
              
                    },
                    data: [
                      { value: 68, name: '省内',itemStyle: {color: "#0096FA"} },
                      { value: 32, name: '省外',itemStyle: {color: "#1EC692"}  }
                    ],
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            },
                  }
                ],
          };
          return opt;
      }
    },
    //集团造价分析
    groupCostAnalysis:{
        title:"集团造价分析(月度)",
        data:function(){
            let opt = {
                backgroundColor:'#fff',
                title: [
                  {
                    text:'集团2021年月度决策光伏项目可研估算分',
                     textStyle: {'color':'#333333',fontSize: 15},
                    // left:'10%',//'5' | '5%'，title 组件离容器左侧的距离
                    top:'3%',
                    // right: 'right',
                    left:'center'
                    // padding: [20, 0, 0, '5%']  // 位置
                  },
                ],
                tooltip: {
                  trigger: 'axis',
                  axisPointer: { // 坐标轴指示器，坐标轴触发有效
                    type: 'shadow' // 默认为直线，可选为：'line' | 'shadow'
                  }
                },
                grid: {
                  left: '2%',
                  right: '4%',
                  bottom: '4%',
                  top:'20%',
                  containLabel: true
                },
                legend: {
                  data: ['光伏项目'],
                  // right: 30,
                  top:'10%',
                  textStyle: {
                    color: "#000"
                  },
                  // itemWidth: 12,
                  itemHeight: 10,
                  // itemGap: 35
                },
                xAxis: {
                  type: 'category',
                  data:['1月','2月','3月','4月','5月','6月','7月','8月','9月','10月','11月','12月'],
                  axisLine: {
                    lineStyle: {
                      color: 'black'
        
                    }
                  },
                  axisLabel: {
                    // interval: 0,
                    // rotate: 40,
                    textStyle: {
                      fontFamily: 'Microsoft YaHei'
                    }
                  },
                },
        
                yAxis: {
                  type: 'value',
                  // max:'1200',
                  axisLine: {
                    show: false,
                    lineStyle: {
                      color: '#000'
                    }
                  },
                  splitLine: {
                    show: true,
                    lineStyle: {
                      color: '#cccccc'
                    }
                  },
                  axisLabel: {}
                },
                "dataZoom": [{
                  "show": false,
                  "height": 12,
                  "zoomOnMouseWheel":false,
                  "xAxisIndex": [
                    0
                  ],
                  bottom:'8%',
                  "start": 0,
                  "end": 100,
                  handleIcon: 'path://M306.1,413c0,2.2-1.8,4-4,4h-59.8c-2.2,0-4-1.8-4-4V200.8c0-2.2,1.8-4,4-4h59.8c2.2,0,4,1.8,4,4V413z',
                  handleSize: '110%',
                  handleStyle:{
                    color:"#d3dee5",
        
                  },
                  textStyle:{
                    color:"#000"},
                  borderColor:"#90979c"
                }, {
                  "type": "inside",
                  "show": false,
                  "height": 15,
                  "start": 1,
                  "end": 35
                }],
                series: [{
                  name: '光伏项目',
                  type: 'bar',
                  barWidth: '25%',
                  itemStyle: {
                    normal: {
                      color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                        offset: 0,
                        color: '#1EC692'
                      }]),
                      // barBorderRadius: 12,
                    },
                  },
                  label: {
                      show: true, //开启显示
                      position: 'top', //在上方显示
                      textStyle: { //数值样式
                          color: '#000000',
                          fontSize: 12
                      }
                  },
                  data: [8000, 7500, 6500, 5500, 4500,8000, 7500, 6500, 5500, 4500,3000,2000]
                },
                
                ]
              };
            return opt;
        }
    },
    //年度决策项目资本金IRR分析
    annualDecision:{
    title:"月度决策项目资本金IRR分析",
    data:function(){
            let opt = {
                backgroundColor:'#fff',
                title: [
                {
                    text:'集团年度决策光伏项目可研估算分析示例(元)',
                    textStyle: {'color':'#333333',fontSize: 15},
                    // left:'10%',//'5' | '5%'，title 组件离容器左侧的距离
                    top:'3%',
                    // right: 'right',
                    left:'center'
                    // padding: [20, 0, 0, '5%']  // 位置
                },
                ],
                tooltip: {
                trigger: 'axis',
                axisPointer: { // 坐标轴指示器，坐标轴触发有效
                    type: 'shadow' // 默认为直线，可选为：'line' | 'shadow'
                }
                },
                grid: {
                left: '2%',
                right: '4%',
                bottom: '4%',
                top:'20%',
                containLabel: true
                },
                legend: {
                data: ['光伏项目'],
                // right: 30,
                top:'10%',
                textStyle: {
                    color: "#000"
                },
                // itemWidth: 12,
                itemHeight: 10,
                // itemGap: 35
                },
                xAxis: {
                type: 'category',
                data:['2016','2017','2018','2019','2020','2021','2022'],
                axisLine: {
                    lineStyle: {
                    color: 'black'
        
                    }
                },
                axisLabel: {
                    // interval: 0,
                    // rotate: 40,
                    textStyle: {
                    fontFamily: 'Microsoft YaHei'
                    }
                },
                },
        
                yAxis: {
                type: 'value',
                // max:'1200',
                axisLine: {
                    show: false,
                    lineStyle: {
                    color: '#000'
                    }
                },
                splitLine: {
                    show: true,
                    lineStyle: {
                    color: '#cccccc'
                    }
                },
                axisLabel: {}
                },
                "dataZoom": [{
                "show": false,
                "height": 12,
                "zoomOnMouseWheel":false,
                "xAxisIndex": [
                    0
                ],
                bottom:'8%',
                "start": 0,
                "end": 100,
                handleIcon: 'path://M306.1,413c0,2.2-1.8,4-4,4h-59.8c-2.2,0-4-1.8-4-4V200.8c0-2.2,1.8-4,4-4h59.8c2.2,0,4,1.8,4,4V413z',
                handleSize: '110%',
                handleStyle:{
                    color:"#d3dee5",
        
                },
                textStyle:{
                    color:"#000"},
                borderColor:"#90979c"
                }, {
                "type": "inside",
                "show": false,
                "height": 15,
                "start": 1,
                "end": 35
                }],
                series: [{
                name: '光伏项目',
                type: 'bar',
                barWidth: '25%',
                itemStyle: {
                    normal: {
                    color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                        offset: 0,
                        color: '#FF8B3F'
                    }]),
                    // barBorderRadius: 12,
                    },
                },
                label: {
                    show: true, //开启显示
                    position: 'top', //在上方显示
                    textStyle: { //数值样式
                        color: '#000000',
                        fontSize: 12
                    }
                },
                data: [8000, 7500, 6500, 5500, 4500,8000, 7500, 6500, 5500, 4500,3000,2000]
                },
                
                ]
            };
        
            return opt;
        }
    },
    //月度决策项目资本金IRR分析
    monthlyDecisionOfPrj:{
        title:"月度决策项目资本金IRR分析",
        data:function(){
            let opt  = {
                backgroundColor:'#fff',
                title: [
                    {
                      text:'单位(%)',
                       textStyle: {'color':'#333333',fontSize: 15},
                      // left:'10%',//'5' | '5%'，title 组件离容器左侧的距离
                      top:'3%',
                      // right: 'right',
                      left:'left'
                      // padding: [20, 0, 0, '5%']  // 位置
                    },
                  ],
                  xAxis: {
                    type: 'category',
                    data:['1','2','3','4','5','6','7'],
                    axisLine: {
                      lineStyle: {
                        color: 'black'
                    
                      }
                    },
                    axisLabel: {
                      // interval: 0,
                      // rotate: 40,
                      textStyle: {
                        fontFamily: 'Microsoft YaHei'
                      }
                    },
                  },
                  yAxis: {
                    type: 'value',
                    // max:'1200',
                    axisLine: {
                      show: false,
                      lineStyle: {
                        color: '#000'
                      }
                    },
                    splitLine: {
                      show: true,
                      lineStyle: {
                        color: '#cccccc'
                      }
                    },
                    axisLabel: {}
                  },
                  series: [
                    {
                         itemStyle: { 
                                      normal: { 
                                        color: '#4AD0A7'  // 折线的颜色
                                      } 
                                    },
                      data: [820, 932, 901, 934, 1290, 1330, 1320],
                      type: 'line',
                      smooth: true,
                      label: {
                              show: true, //开启显示
                              position: 'top', //在上方显示
                              textStyle: { //数值样式
                                  color: '#000000',
                                  fontSize: 12
                              }
                          },
                    }
                  ]
              };
            return opt;
        }
    },
    //年度决策项目资本金IRR分析
    annualDecisionOfPrj:{
        title:"年度决策项目资本金IRR分析",
        data:function(){
            let opt = {
                backgroundColor:'#fff',
                title: [
                    {
                      text:'单位(%)',
                       textStyle: {'color':'#333333',fontSize: 15},
                      // left:'10%',//'5' | '5%'，title 组件离容器左侧的距离
                      top:'3%',
                      // right: 'right',
                      left:'left'
                      // padding: [20, 0, 0, '5%']  // 位置
                    },
                  ],
                  xAxis: {
                    type: 'category',
                    data:['2016','2017','2018','2019','2020','2021','2022'],
                    axisLine: {
                      lineStyle: {
                        color: 'black'
                    
                      }
                    },
                    axisLabel: {
                      // interval: 0,
                      // rotate: 40,
                      textStyle: {
                        fontFamily: 'Microsoft YaHei'
                      }
                    },
                  },
                  yAxis: {
                    type: 'value',
                    // max:'1200',
                    axisLine: {
                      show: false,
                      lineStyle: {
                        color: '#000'
                      }
                    },
                    splitLine: {
                      show: true,
                      lineStyle: {
                        color: '#cccccc'
                      }
                    },
                    axisLabel: {}
                  },
                  series: [
                    {
                         itemStyle: { 
                                      normal: { 
                                        color: '#2495FF'  // 折线的颜色
                                      } 
                                    },
                      data: [820, 932, 901, 934, 1290, 1330, 1320],
                      type: 'line',
                      smooth: true,
                      label: {
                              show: true, //开启显示
                              position: 'top', //在上方显示
                              textStyle: { //数值样式
                                  color: '#000000',
                                  fontSize: 12
                              }
                          },
                    }
                  ]
              };        
            return opt;
        }
    },
    //投产容量月度统计
    monthlyProductionCapacity:{
        title:"投产容量月度统计",
        data:function(){
            let opt = {
                backgroundColor:'#fff',
                title: [
                  {
                    text:'投产容量月度统计(MW)',
                     textStyle: {'color':'#333333',fontSize: 15},
                    // left:'10%',//'5' | '5%'，title 组件离容器左侧的距离
                    top:'3%',
                    // right: 'right',
                    left:'center'
                    // padding: [20, 0, 0, '5%']  // 位置
                  },
                ],
                tooltip: {
                  trigger: 'axis',
                  axisPointer: { // 坐标轴指示器，坐标轴触发有效
                    type: 'shadow' // 默认为直线，可选为：'line' | 'shadow'
                  }
                },
                grid: {
                  left: '2%',
                  right: '4%',
                  bottom: '4%',
                  top:'20%',
                  containLabel: true
                },
                legend: {
                  data: ['屋面光伏','地面光伏'],
                  // right: 30,
                  top:'10%',
                  textStyle: {
                    color: "#000"
                  },
                  // itemWidth: 12,
                  itemHeight: 10,
                  // itemGap: 35
                },
                xAxis: {
                  type: 'category',
                  data:['1月','2月','3月','4月','5月','6月','7月','8月','9月','10月','11月','12月'],
                  axisLine: {
                    lineStyle: {
                      color: 'black'
        
                    }
                  },
                  axisLabel: {
                    // interval: 0,
                    // rotate: 40,
                    textStyle: {
                      fontFamily: 'Microsoft YaHei'
                    }
                  },
                },
        
                yAxis: {
                  type: 'value',
                  // max:'1200',
                  axisLine: {
                    show: false,
                    lineStyle: {
                      color: '#000'
                    }
                  },
                  splitLine: {
                    show: true,
                    lineStyle: {
                      color: '#cccccc'
                    }
                  },
                  axisLabel: {}
                },
                "dataZoom": [{
                  "show": false,
                  "height": 12,
                  "zoomOnMouseWheel":false,
                  "xAxisIndex": [
                    0
                  ],
                  bottom:'8%',
                  "start": 0,
                  "end": 100,
                  handleIcon: 'path://M306.1,413c0,2.2-1.8,4-4,4h-59.8c-2.2,0-4-1.8-4-4V200.8c0-2.2,1.8-4,4-4h59.8c2.2,0,4,1.8,4,4V413z',
                  handleSize: '110%',
                  handleStyle:{
                    color:"#d3dee5",
        
                  },
                  textStyle:{
                    color:"#000"},
                  borderColor:"#90979c"
                }, {
                  "type": "inside",
                  "show": false,
                  "height": 15,
                  "start": 1,
                  "end": 35
                }],
                series: [{
                  name: '屋面光伏',
                  type: 'bar',
                  barWidth: '25%',
                  itemStyle: {
                    normal: {
                      color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                        offset: 0,
                        color: '#54D3D3'
                      }]),
                      // barBorderRadius: 12,
                    },
                  },
                  label: {
                      show: true, //开启显示
                      position: 'top', //在上方显示
                      textStyle: { //数值样式
                          color: '#000000',
                          fontSize: 12
                      }
                  },
                  data: [8000, 7500, 6500, 5500, 4500,8000, 7500, 6500, 5500, 4500,3000,2000]
                },
                {
                  name: '地面光伏',
                  type: 'bar',
                  barWidth: '25%',
                  itemStyle: {
                    normal: {
                      color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                        offset: 0,
                        color: '#FBD955'
                      }]),
                      // barBorderRadius: 12,
                    },
                  },
                  label: {
                      show: true, //开启显示
                      position: 'top', //在上方显示
                      textStyle: { //数值样式
                          color: '#000000',
                          fontSize: 12
                      }
                  },
                  data: [5000, 3500, 5500, 7500, 2500,5000, 7500, 2500, 3500, 7500,3000,6000]
                },
                
                ]
              };
            return opt;
        }
    },
    //投产容量年度统计
    annualProductionCapacity:{
        title:"投产容量年度统计",
        data:function(){
            let opt = {
                backgroundColor:'#fff',
                title: [
                  {
                    text:'投产容量年度统计(MW)',
                     textStyle: {'color':'#333333',fontSize: 15},
                    // left:'10%',//'5' | '5%'，title 组件离容器左侧的距离
                    top:'3%',
                    // right: 'right',
                    left:'center'
                    // padding: [20, 0, 0, '5%']  // 位置
                  },
                ],
                tooltip: {
                  trigger: 'axis',
                  axisPointer: { // 坐标轴指示器，坐标轴触发有效
                    type: 'shadow' // 默认为直线，可选为：'line' | 'shadow'
                  }
                },
                grid: {
                  left: '2%',
                  right: '4%',
                  bottom: '4%',
                  top:'20%',
                  containLabel: true
                },
                legend: {
                  data: ['屋面光伏','地面光伏'],
                  // right: 30,
                  top:'10%',
                  textStyle: {
                    color: "#000"
                  },
                  // itemWidth: 12,
                  itemHeight: 10,
                  // itemGap: 35
                },
                xAxis: {
                  type: 'category',
                  data:['2016','2017','2018','2019','2020','2021','2022'],
                  axisLine: {
                    lineStyle: {
                      color: 'black'
        
                    }
                  },
                  axisLabel: {
                    // interval: 0,
                    // rotate: 40,
                    textStyle: {
                      fontFamily: 'Microsoft YaHei'
                    }
                  },
                },
        
                yAxis: {
                  type: 'value',
                  // max:'1200',
                  axisLine: {
                    show: false,
                    lineStyle: {
                      color: '#000'
                    }
                  },
                  splitLine: {
                    show: true,
                    lineStyle: {
                      color: '#cccccc'
                    }
                  },
                  axisLabel: {}
                },
                "dataZoom": [{
                  "show": false,
                  "height": 12,
                  "zoomOnMouseWheel":false,
                  "xAxisIndex": [
                    0
                  ],
                  bottom:'8%',
                  "start": 0,
                  "end": 100,
                  handleIcon: 'path://M306.1,413c0,2.2-1.8,4-4,4h-59.8c-2.2,0-4-1.8-4-4V200.8c0-2.2,1.8-4,4-4h59.8c2.2,0,4,1.8,4,4V413z',
                  handleSize: '110%',
                  handleStyle:{
                    color:"#d3dee5",
        
                  },
                  textStyle:{
                    color:"#000"},
                  borderColor:"#90979c"
                }, {
                  "type": "inside",
                  "show": false,
                  "height": 15,
                  "start": 1,
                  "end": 35
                }],
                series: [{
                  name: '屋面光伏',
                  type: 'bar',
                  barWidth: '25%',
                  itemStyle: {
                    normal: {
                      color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                        offset: 0,
                        color: '#F47A8F'
                      }]),
                      // barBorderRadius: 12,
                    },
                  },
                  label: {
                      show: true, //开启显示
                      position: 'top', //在上方显示
                      textStyle: { //数值样式
                          color: '#000000',
                          fontSize: 12
                      }
                  },
                  data: [8000, 7500, 6500, 5500, 4500,8000, 7500, 6500, 5500, 4500,3000,2000]
                },
                {
                  name: '地面光伏',
                  type: 'bar',
                  barWidth: '25%',
                  itemStyle: {
                    normal: {
                      color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                        offset: 0,
                        color: '#58AEFF'
                      }]),
                      // barBorderRadius: 12,
                    },
                  },
                  label: {
                      show: true, //开启显示
                      position: 'top', //在上方显示
                      textStyle: { //数值样式
                          color: '#000000',
                          fontSize: 12
                      }
                  },
                  data: [5000, 3500, 5500, 7500, 2500,5000, 7500, 2500, 3500, 7500,3000,6000]
                },
                
                ]
              };
            return opt;
        }
    },
}