import React, { useEffect, useState } from 'react'
import { Button, Checkbox, Input, message, Modal, Popconfirm, Select, Table, Tooltip } from 'antd'
import { SearchOutlined, UserOutlined } from '@ant-design/icons'
import { debounce } from 'lodash'
import './index.less'
import { CustomIcons } from '../../../../../global'
import HelperJurisdictionControllerApi from '../../../../../services/api/HelperJurisdictionControllerApi'

const { Option } = Select

type ModalProps = {
  visible: boolean
  onOk?: (data: any) => void
  onCancel?: () => void
  dataSource?: any
  dataId?: any
  projectId?: any
}

const AddTeamworkerModal = React.memo((props: ModalProps) => {
  const roleTypeList = [
    { id: 1, name: '仅查看' },
    { id: 2, name: '编辑' },
    { id: 3, name: '拷贝' },
  ]
  // 用户基础信息
  const { visible, onOk, onCancel, dataId, projectId } = props || { row: {} }
  const [keyword, setKeyword] = useState<string>('')

  const [loading, setLoading] = useState(false)
  const [memberList, setMemberList] = useState<any[]>([])
  const [checkedKeys, setCheckedKeys] = useState<React.Key[]>([])
  const [checkedRows, setCheckedRows] = useState<any[]>([])

  const MemberItem = React.memo((props: any) => {
    const { item } = props
    const { userId, userName, facilitatorsFlag } = item || {}

    return userId ? (
      <div className="add-members-item">
        <Checkbox value={userId} disabled={!facilitatorsFlag}>
          <UserOutlined className="add-members-item-avatar" />
          <Tooltip title={userName}>
            <span className="add-members-item-name">{userName}</span>
          </Tooltip>
        </Checkbox>
      </div>
    ) : null
  })

  const fetchMemberList = (roleType: any = 3) => {
    if (loading) return
    setLoading(true)
    const data = {
      projectId,
      dataId,
      dataType: 1,
      ...(keyword ? { keyword } : []),
    }
    HelperJurisdictionControllerApi.i3060HelperJurisdictionProjectMembersListPost(data)
      .then((res) => {
        setLoading(false)
        if (res.code === 200) {
          const list = res?.data || []
          setMemberList(list.map((res) => ({ ...res, roleType })))
        } else message.error(res.msg)
      })
      .catch(() => {
        setLoading(false)
      })
  }

  const handleNameChange = debounce(
    () => {
      fetchMemberList()
    },
    500,
    {
      leading: true,
      trailing: false,
    }
  )

  // const handleTemplateChange = (value: string) => {
  //   setInviteTemplate(value)
  // }

  const handleCheck = (keys: any[]) => {
    let rows = memberList.filter((item) => keys.includes(item.userId))
    setCheckedKeys(keys)
    setCheckedRows(rows)
  }

  const removeCheck = (keys: any[]) => {
    const rows = checkedRows.filter((item) => !keys.includes(item.userId))
    setCheckedKeys(checkedKeys?.filter((key) => !keys.includes(key)))
    setCheckedRows(rows)
  }

  const handleClearAll = () => {
    setCheckedKeys([])
    setCheckedRows([])
  }
  const columns = [
    {
      title: '成员',
      dataIndex: 'userName',
      key: 'userName',
      width: 150,
      ellipsis: {
        showTitle: false,
      },
      render: (text: string) => (
        <>
          <UserOutlined className="add-members-item-avatar" />
          <Tooltip title={text}>
            <span className="add-members-item-name">{text}</span>
          </Tooltip>
        </>
      ),
    },
    {
      title: '方案权限',
      dataIndex: 'roleType',
      key: 'roleType',
      width: 168,
      render: (text: string, row: any, i: any) => (
        <div className="add-members-item-action">
          <Select
            style={{ width: 90 }}
            showSearch
            bordered={false}
            value={row.roleType}
            placeholder="请选择"
            disabled
            onChange={(e) => {
              const item = { ...row },
                list = [...checkedRows]
              item.roleType = Number(e)
              list[i] = item
              setCheckedRows(list)
            }}
          >
            {roleTypeList?.map((res: any) => (
              <Option value={res.id} key={res.id}>
                {res.name}
              </Option>
            ))}
          </Select>
          <Button
            style={{ padding: 0, width: 16 }}
            onClick={() => removeCheck([row.userId])}
            size="small"
            type="text"
            icon={<CustomIcons type="klf-delete" />}
            // icon={<DeleteOutlined />}
          />
        </div>
      ),
    },
  ]
  const addJurisdiction = () => {
    if (loading) return
    else if (!checkedRows?.length) {
      return message.error('请选择协作者！')
    }
    setLoading(true)
    const data: any = checkedRows.map(({ userId, roleType }) => ({
      userId,
      projectId,
      dataId,
      dataType: '1',
      roleType,
    }))
    HelperJurisdictionControllerApi.i3060HelperJurisdictionBatchAddPost(data)
      .then((res) => {
        if (res.code === 200) {
          handleClearAll()
          onOk(checkedRows)
        } else message.error(res.msg)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  useEffect(() => {
    visible && fetchMemberList()
  }, [visible])

  return (
    <Modal
      maskClosable={false}
      className="add-members-modal"
      visible={visible}
      width={632}
      title="添加协作者"
      confirmLoading={loading}
      onCancel={() => {
        onCancel()
        handleClearAll()
      }}
      onOk={addJurisdiction}
    >
      <div className="add-members-content">
        <div className="add-members-list">
          <div className="add-members-list-header">
            <Input
              placeholder="搜索项目内成员名称"
              value={keyword}
              onChange={(e: any) => setKeyword(e?.target?.value)}
              suffix={<SearchOutlined onClick={handleNameChange} style={{ color: 'rgba(0,0,0,.45)' }} />}
            />
          </div>
          <div className="add-members-list-content">
            <Checkbox.Group onChange={handleCheck} style={{ width: '100%' }} value={checkedKeys}>
              {memberList.map((item) => (
                <MemberItem key={item.userId} item={item} />
              ))}
            </Checkbox.Group>
          </div>
        </div>
        <div className="add-members-selected">
          <div className="add-members-selected-header">
            <span>已选：{checkedKeys?.length}名用户</span>
            <Popconfirm title="确定清空所选成员?" onConfirm={handleClearAll}>
              <span className="add-members-selected-clear">清空</span>
            </Popconfirm>
          </div>
          <div className="add-members-selected-content">
            <Table columns={columns} dataSource={checkedRows} pagination={false} />
          </div>
        </div>
      </div>
    </Modal>
  )
})

export default AddTeamworkerModal
