import { useEffect, useState } from 'react'
import { Card, Typography, Tooltip, Button, Table } from 'antd'
import { BreadcrumbObservable, SideMenuObservable } from '../../../application'
import moment from 'moment'
import { useNavigate, useParams } from 'react-router-dom'
import { MaterialService } from '../../../apis/engineering-service'

import './index.less'

export default () => {
  const nav = useNavigate()
  const [dataSource, setDataSource] = useState<any>({})
  const [materialList, setMaterialList] = useState([]) // 弹窗数据
  const paramsData = useParams() // 链接参数
  useEffect(() => {
    SideMenuObservable.next(false)
    BreadcrumbObservable.next([
      {
        name: '工程管理',
        url: '/engineering',
      },
      {
        name: '物资管理',
        url: '/engineering/material',
      },
      {
        name: '项目物资详情',
        url: '/engineering/materialDetails',
      },
      
    ])
  }, [])

  // 获取数据
  useEffect(() => {
    // 获取详情数据
    MaterialService.getMaterialDetail(paramsData?.id || '')
      .then((res: any) => {
        console.log(res, '====res')
        setDataSource(res?.data || {})
        MaterialService.getMaterialsList(res?.data?.contractNo ||  '')
          .then((res: any) => {
            console.log(res, '采购合同查询物资明细')
            setMaterialList(res?.data || [])
          })
          .catch(() => {})
      })
      .catch(() => {})
      
  }, [])
  const columns = [
    {
      title: '项目名称', dataIndex: dataSource?.projectName || '-'
    },
    {
      title: '项目代码', dataIndex: dataSource?.projectCode || '-'
    },
    {
      title: '单据号', dataIndex: dataSource?.orderNo || '-'
    },
    {
      title: '产品编号', dataIndex: dataSource?.projectNo || '-'
    },
    {
      title: '合同名称', dataIndex: dataSource?.contractName || '-'
    },
    {
      title: '合同编号', dataIndex: dataSource?.contractNo || '-'
    },
    {
      title: '供应商', dataIndex: dataSource?.supplier || '-'
    },
    {
      title: '结算单位', dataIndex: dataSource?.clearingHouse || '-'
    },
    {
      title: '业务机构', dataIndex: dataSource?.programOffices || '-'
    },
    {
      title: '业务部门', dataIndex: dataSource?.programDepartment || '-'
    },
    {
      title: '制单人', dataIndex: dataSource?.operatorName || '-'
    },
    {
      title: '联系方式', dataIndex: dataSource?.contactWay || '-'
    },
  ]

  // 物资明细列表
  // 物资明细列表 弹窗列表
  const MaterialDetails = () => {
    const columns = [
      {
        title: '项次',
        dataIndex: 'itemNumber',
        width: 60,
        render: (text: string) =>
          text && (
            <Tooltip title={text}>
              <Typography.Text ellipsis>{text}</Typography.Text>
            </Tooltip>
          )
      },
      {
        title: '单据编号',
        dataIndex: 'orderNo',
        width: 100,
        render: (text: string) =>
          text && (
            <Tooltip title={text}>
              <Typography.Text ellipsis>{text}</Typography.Text>
            </Tooltip>
          ),
      },
      
      {
        title: '产品编号',
        dataIndex: 'productNo',
        width: 100,
        render: (text: string) =>
          text && (
            <Tooltip title={text}>
              <Typography.Text ellipsis>{text}</Typography.Text>
            </Tooltip>
          )
      },
      {
        title: '产品名称',
        dataIndex: 'productName',
        width: 100,
        render: (text: string) =>
          text && (
            <Tooltip title={text}>
              <Typography.Text ellipsis>{text}</Typography.Text>
            </Tooltip>
          ),
      },
      {
        title: '规格',
        dataIndex: 'specification',
        width: 100,
        render: (text: string) =>
          text && (
            <Tooltip title={text}>
              <Typography.Text ellipsis>{text}</Typography.Text>
            </Tooltip>
          ),
      },
      {
        title: '品牌',
        dataIndex: 'brand',
        width: 100,
        render: (text: string) =>
          text && (
            <Tooltip title={text}>
              <Typography.Text ellipsis>{text}</Typography.Text>
            </Tooltip>
          ),
      },
      {
        title: '型号',
        dataIndex: 'model',
        width: 120,
        render: (text: string) =>
          text && (
            <Tooltip title={text}>
              <Typography.Text ellipsis>{text}</Typography.Text>
            </Tooltip>
          ),
      },
      {
        title: '数量',
        dataIndex: 'number',
        width: 100,
        render: (text: number) =>
          text && (
            <Tooltip title={text}>
              <Typography.Text ellipsis>{text}</Typography.Text>
            </Tooltip>
          ),
      },
      {
        title: '产品单价',
        dataIndex: 'productUnitPrice',
        width: 100,
        render: (text: number) =>
          text && (
            <Tooltip title={text}>
              <Typography.Text ellipsis>{text}</Typography.Text>
            </Tooltip>
          ),
      },
      {
        title: '产品金额',
        dataIndex: 'productMoney',
        width: 100,
        render: (text: number) =>
          text && (
            <Tooltip title={text}>
              <Typography.Text ellipsis>{text}</Typography.Text>
            </Tooltip>
          ),
      },
      {
        title: '数量单位',
        dataIndex: 'numberUnit',
        width: 100,
        render: (text: string) =>
          text && (
            <Tooltip title={text}>
              <Typography.Text ellipsis>{text}</Typography.Text>
            </Tooltip>
          ),
      },
      {
        title: '重量单位',
        dataIndex: 'weightUnit',
        width: 100,
        render: (text: string) =>
          text && (
            <Tooltip title={text}>
              <Typography.Text ellipsis>{text}</Typography.Text>
            </Tooltip>
          ),
      },
      {
        title: '需求总量',
        dataIndex: 'demandTotal',
        width: 100,
        render: (text: number) =>
          text && (
            <Tooltip title={text}>
              <Typography.Text ellipsis>{text}</Typography.Text>
            </Tooltip>
          ),
      },
      {
        title: '已采购总量',
        dataIndex: 'purchaseTotal',
        width: 120,
        render: (text: number) =>
          text && (
            <Tooltip title={text}>
              <Typography.Text ellipsis>{text}</Typography.Text>
            </Tooltip>
          ),
      },
      {
        title: '日期',
        dataIndex: 'addTime',
        width: 180,
        render: (text: any) => {
          return text && moment(text).format('YYYY.MM.DD HH:mm:ss')
        }
      }
    ]
    return (
      <Table
        className="right-actions"
        columns={columns}
        dataSource={materialList}
        rowKey={(record) => record.orderNo}
        scroll={{ x: '100%', y: `400px` }}
        style={{ flexGrow: 1 }}
        pagination={false}
      />
    )
  }

  return (
    <Card
      className="material-details"
      extra={null}
      bodyStyle={{ padding: 0 }}
    >
      <Typography.Title level={4} style={{textAlign: 'center'}}>项目物资详情</Typography.Title>
      <div className="basicInfo">
        {
          columns.map((item , index) => (
            <div className="basic-item" key={index}>
              <div className="basic-title">{item.title}</div>
              <div className="basic-dataIndex">{item.dataIndex}</div>
            </div>
          ))
        }
      </div>
      <div className="basic-side">
        <Typography.Title level={5} style={{textAlign: 'left', marginBottom: 10}}>物资明细：</Typography.Title>
        <MaterialDetails />
      </div>
      <div className="basic-remarks">
        <Typography.Title level={5} style={{textAlign: 'left', marginBottom: 10}}>备注：</Typography.Title>
        <div className="remarks-text">{dataSource?.remark || '无'}</div>
      </div>
      <div className="basic-back">
        <Button onClick={() => {nav(-1)}}>返回</Button>
      </div>
    </Card>
  )
}
