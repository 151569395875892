/**
 * project
 * unknown
 *
 * OpenAPI spec version: last
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import ajax, { AjaxPromise, ExtraFetchParams } from '@ajax';
import * as models from '../model/models';

/* tslint:disable:no-unused-variable member-ordering object-literal-shorthand */


export class LargeScreenControllerApi {
  protected $basePath = '/api'.replace(/\/$/, '');

  public constructor(basePath?: string) {
    if (basePath !== undefined) {
      this.$basePath = basePath.replace(/\/$/, '');
    }
  }

  /**
   * 
   * @summary 电站排名
   
   
   * @param opt ajax config
   * @returns models.ResultListLargeScreenRankingRes
   */
  public i3060LargeScreenPowerStationRankingGet = (
    
    opt?: ExtraFetchParams
  ) : AjaxPromise<models.ResultListLargeScreenRankingRes>  => {
    const url = this.$basePath + `/i3060/largeScreen/powerStation/ranking`;
    const p: any = {};
    return ajax.ajax({
      ...opt,
      method: 'GET',
      url,
      ...p
    });
  }
}

export default new LargeScreenControllerApi();