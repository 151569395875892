import { Button, Col, Form, InputNumber, Row, Spin } from 'antd'
import React from 'react'
import { ProjectWorkbenchService } from '../../../../apis/project-service-history'

import '../index.less'

const keyMaps = {
  DC: [
    { name: '二极管损耗率', key: 'diodeLossRate' },
    { name: '铭牌损耗率', key: 'nameplateLossRate' },
    { name: '直流线损率', key: 'dcLineLossRate' },
    { name: '电流不协调损率', key: 'uncoordinatedLossRate' },
    { name: '直流功率优化器损耗率', key: 'dcPowerLossRate' },
    { name: '追踪误差损耗率', key: 'trackingErrorLossRate' },
    { name: '积雪损耗率', key: 'snowLossRate' },
    { name: '直流内部阴影损耗率', key: 'dcInternalShadowLossRate' },
  ],
  AC: [
    { name: '交流线损率', key: 'acLineLossRate' },
    { name: '变压器损耗率', key: 'transformerLossRate' },
  ],
  TT: [{ name: '校正损耗率', key: 'correctedLossRate' }],
}

const toFixedNumber = (num: number, digits = 4) => {
  return parseFloat(num.toFixed(digits))
}

export const PvSystemEfficiency = (props: {
  technologyId?: string
  viewMode?: boolean
  onClose: (reload?: boolean) => void
  onChange?: Function
  current?: any
}) => {
  const [current, setCurrent] = React.useState<any>()
  const [loading, setLoading] = React.useState(true)
  const [uploading, setUploading] = React.useState(false)
  const [form] = Form.useForm()

  const { viewMode } = props

  const calcDcLossRate = () => {
    let res = 1
    keyMaps.DC.forEach((o) => {
      const key = o.key
      const val = form.getFieldValue(key)
      res *= 1 - val / 100
    })
    return toFixedNumber((1 - res) * 100)
  }

  const calcAcLossRate = () => {
    let res = 1
    keyMaps.AC.forEach((o) => {
      const key = o.key
      const val = form.getFieldValue(key)
      res *= 1 - val / 100
    })

    return toFixedNumber((1 - res) * 100)
  }
  const calcSystemEfficiency = () => {
    let res = 1
    keyMaps.TT.forEach((o) => {
      const key = o.key
      const val = form.getFieldValue(key)
      res *= 1 - val / 100
    })

    return toFixedNumber((1 - res) * 100)
  }

  const calcTotalRate = () => {
    return toFixedNumber(
      ((100 - calcDcLossRate()) * (100 - calcAcLossRate()) * (100 - calcSystemEfficiency())) / 10000,
      2
    )
  }

  React.useEffect(() => {
    if (props.current) {
      setCurrent(props.current)
      const {
        diodeLossRate,
        nameplateLossRate,
        dcLineLossRate,
        uncoordinatedLossRate,
        dcPowerLossRate,
        trackingErrorLossRate,
        snowLossRate,
        dcInternalShadowLossRate,
        acLineLossRate,
        transformerLossRate,
        correctedLossRate,
      } = props.current

      form.setFieldsValue({
        diodeLossRate,
        nameplateLossRate,
        dcLineLossRate,
        uncoordinatedLossRate,
        dcPowerLossRate,
        trackingErrorLossRate,
        snowLossRate,
        dcInternalShadowLossRate,
        acLineLossRate,
        transformerLossRate,
        correctedLossRate,
      })
      setLoading(false)
    }
  }, [props.current])

  React.useEffect(() => {
    if (props.technologyId) {
      ProjectWorkbenchService.getPhotovoltaicSystemEfficiency({
        technologyId: props.technologyId,
      })
        .then((resp) => {
          setCurrent(resp.data)

          const {
            diodeLossRate,
            nameplateLossRate,
            dcLineLossRate,
            uncoordinatedLossRate,
            dcPowerLossRate,
            trackingErrorLossRate,
            snowLossRate,
            dcInternalShadowLossRate,
            acLineLossRate,
            transformerLossRate,
            correctedLossRate,
          } = resp.data

          form.setFieldsValue({
            diodeLossRate,
            nameplateLossRate,
            dcLineLossRate,
            uncoordinatedLossRate,
            dcPowerLossRate,
            trackingErrorLossRate,
            snowLossRate,
            dcInternalShadowLossRate,
            acLineLossRate,
            transformerLossRate,
            correctedLossRate,
          })
        })
        .finally(() => {
          setLoading(false)
        })
    }
  }, [])

  return (
    <Spin spinning={loading}>
      <div
        style={{
          minHeight: '3rem',
        }}
        className={viewMode ? 'only-view-mode' : ''}
      >
        {current && (
          <Form
            className="system-form"
            labelCol={{ span: 14 }}
            wrapperCol={{ span: 10 }}
            form={form}
            onFieldsChange={() => {
              setCurrent({ ...current })
            }}
          >
            <Row>
              <Col span={24}>
                <h3>
                  DC效率损耗率<span style={{ float: 'right' }}>{toFixedNumber(calcDcLossRate(), 2)}%</span>
                </h3>
              </Col>
              {keyMaps.DC.map((o) => (
                <Col span={12} key={o.key}>
                  <Form.Item label={o.name}>
                    {viewMode && <span>{form.getFieldValue(o.key)}%</span>}
                    {!viewMode && (
                      <Form.Item name={o.key}>
                        <InputNumber precision={2} min={0} max={100} addonAfter="%" controls={false} />
                      </Form.Item>
                    )}
                  </Form.Item>
                </Col>
              ))}
            </Row>
            <Row>
              <Col span={24}>
                <h3>
                  AC效率损耗率<span style={{ float: 'right' }}>{toFixedNumber(calcAcLossRate(), 2)}%</span>
                </h3>
              </Col>
              {keyMaps.AC.map((o) => (
                <Col span={12} key={o.key}>
                  <Form.Item label={o.name}>
                    {viewMode && <span>{form.getFieldValue(o.key)}%</span>}
                    {!viewMode && (
                      <Form.Item name={o.key}>
                        <InputNumber precision={2} min={0} max={100} addonAfter="%" controls={false} />
                      </Form.Item>
                    )}
                  </Form.Item>
                </Col>
              ))}
            </Row>
            <Row>
              <Col span={24}>
                <h3>
                  校正总效率<span style={{ float: 'right' }}>{toFixedNumber(calcSystemEfficiency(), 2)}%</span>
                </h3>
              </Col>
              {keyMaps.TT.map((o) => (
                <Col span={12} key={o.key}>
                  <Form.Item label={o.name}>
                    {viewMode && <span>{form.getFieldValue(o.key)}%</span>}
                    {!viewMode && (
                      <Form.Item name={o.key}>
                        <InputNumber precision={2} min={0} max={100} addonAfter="%" controls={false} />
                      </Form.Item>
                    )}
                  </Form.Item>
                </Col>
              ))}
            </Row>
          </Form>
        )}
        <div className="total-rate">
          <h3>
            光伏系统总效率<span style={{ float: 'right' }}>{calcTotalRate()}%</span>
          </h3>
        </div>
      </div>
      <div className="ant-modal-confirm-btns">
        {!viewMode && (
          <Button
            onClick={() => {
              props.onClose?.()
            }}
          >
            取 消
          </Button>
        )}
        <Button
          type="primary"
          loading={uploading}
          onClick={() => {
            if (viewMode) {
              props.onClose?.()
              return
            }
            setUploading(true)

            const {
              diodeLossRate,
              nameplateLossRate,
              dcLineLossRate,
              uncoordinatedLossRate,
              dcPowerLossRate,
              trackingErrorLossRate,
              snowLossRate,
              dcInternalShadowLossRate,
              acLineLossRate,
              transformerLossRate,
              correctedLossRate,
            } = form.getFieldsValue()

            if (props.onChange) {
              props.onChange({
                diodeLossRate,
                nameplateLossRate,
                dcLineLossRate,
                uncoordinatedLossRate,
                dcPowerLossRate,
                trackingErrorLossRate,
                snowLossRate,
                dcInternalShadowLossRate,
                acLineLossRate,
                transformerLossRate,
                correctedLossRate,
                systemEfficiency: calcTotalRate(),
              })
            } else {
              ProjectWorkbenchService.updatePhotovoltaicSystemEfficiency({
                technologyId: props.technologyId!,
                root: {
                  diodeLossRate,
                  nameplateLossRate,
                  dcLineLossRate,
                  uncoordinatedLossRate,
                  dcPowerLossRate,
                  trackingErrorLossRate,
                  snowLossRate,
                  dcInternalShadowLossRate,
                  acLineLossRate,
                  transformerLossRate,
                  correctedLossRate,
                },
              }).then(() => {
                props.onClose?.(true)
              })
            }
          }}
        >
          确 定
        </Button>
      </div>
    </Spin>
  )
}
