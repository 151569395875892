/**
 * project
 * unknown
 *
 * OpenAPI spec version: last
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import ajax, { AjaxPromise, ExtraFetchParams } from '@ajax';
import * as models from '../model/models';

/* tslint:disable:no-unused-variable member-ordering object-literal-shorthand */

/**
  * AccountAddReq :AccountAddReq
  */
export type ParamsBodyauthAccountAddPost = models.AccountAddReq;
/**
  * List<TenantAccountIdReq>
  */
export type ParamsBodyauthAccountDelTenantAccountPost = Array<models.AuthAccountDelTenantAccountBody>;
/**
  * @description authAccountDetailGet参数
  * @property `userId` (Long)
  * @property `tenantId` (Long)
  */
export interface ParamsauthAccountDetailGet {
  // queryParams
  /**
   * (Long)
   */
  userId: string;
  /**
   * (Long)
   */
  tenantId: string;
}
/**
  * AccountReq :AccountReq
  */
export type ParamsBodyauthAccountInfoPost = models.AccountReq;
/**
  */
export type ParamsBodyauthAccountIsJoinTenantPost = models.AuthAccountIsJoinTenantBody;
/**
  */
export type ParamsBodyauthAccountIsRemoveTenantPost = models.AuthAccountIsRemoveTenantBody;
/**
  * AcceptInviteReq :AcceptInviteReq
  */
export type ParamsBodyauthAccountUserAcceptInvitePost = models.AcceptInviteReq;

export class AccountControllerApi {
  protected $basePath = '/api'.replace(/\/$/, '');

  public constructor(basePath?: string) {
    if (basePath !== undefined) {
      this.$basePath = basePath.replace(/\/$/, '');
    }
  }

  /**
   * ```   /_**      * 新增用户      *_/     @PostMapping(\"/add\")     public Result<String> accountAdd(@RequestBody AccountAddReq accountAddReq) ```
   * @summary 生成用户链接和项目链接
   
   * @param data: ParamsBodyauthAccountAddPost// AccountAddReq :AccountAddReq
   * @param opt ajax config
   * @returns models.ResultString
   */
  public authAccountAddPost = (
    
    data: ParamsBodyauthAccountAddPost,opt?: ExtraFetchParams
  ) : AjaxPromise<models.ResultString>  => {
    const url = this.$basePath + `/auth/account/add`;
    const p: any = {};
    p.data = data;
    return ajax.ajax({
      ...opt,
      method: 'POST',
      url,
      ...p
    });
  }
  /**
   * 
   * @summary 移除组织用户
   
   * @param data: ParamsBodyauthAccountDelTenantAccountPost// List<TenantAccountIdReq>
   * @param opt ajax config
   * @returns models.ResultBoolean
   */
  public authAccountDelTenantAccountPost = (
    
    data: ParamsBodyauthAccountDelTenantAccountPost,opt?: ExtraFetchParams
  ) : AjaxPromise<models.ResultBoolean>  => {
    const url = this.$basePath + `/auth/account/delTenantAccount`;
    const p: any = {};
    p.data = data;
    return ajax.ajax({
      ...opt,
      method: 'POST',
      url,
      ...p
    });
  }
  /**
   * ```   /_**      * 查看用户详情      *_/     @GetMapping(\"/detail\")     public Result<AccountBaseInfoRes> accountAdd(@RequestParam(\"userId\") Long userId, @RequestParam(\"tenantId\") Long tenantId) ```
   * @summary 查看用户详情
   * @param params ParamsauthAccountDetailGet
   
   * @param opt ajax config
   * @returns models.ResultAccountBaseInfoRes
   */
  public authAccountDetailGet = (
    params: ParamsauthAccountDetailGet,
    opt?: ExtraFetchParams
  ) : AjaxPromise<models.ResultAccountBaseInfoRes>  => {
    const url = this.$basePath + `/auth/account/detail`;
    const p: any = {};
    p.query = {};
    if ('userId' in params) p.query.userId = params.userId;
    ajax.check(params.userId, 'userId');
    if ('tenantId' in params) p.query.tenantId = params.tenantId;
    ajax.check(params.tenantId, 'tenantId');
    return ajax.ajax({
      ...opt,
      method: 'GET',
      url,
      ...p
    });
  }
  /**
   * ```   /_**      * 组织下的用户      *_/     @PostMapping(\"/info\")     public Result<IPage<AccountRes>> tenantAccountInfo(@RequestBody AccountReq accountReq) ```
   * @summary 组织下的用户
   
   * @param data: ParamsBodyauthAccountInfoPost// AccountReq :AccountReq
   * @param opt ajax config
   * @returns models.ResultIPageAccountRes
   */
  public authAccountInfoPost = (
    
    data: ParamsBodyauthAccountInfoPost,opt?: ExtraFetchParams
  ) : AjaxPromise<models.ResultIPageAccountRes>  => {
    const url = this.$basePath + `/auth/account/info`;
    const p: any = {};
    p.data = data;
    return ajax.ajax({
      ...opt,
      method: 'POST',
      url,
      ...p
    });
  }
  /**
   * 
   * @summary 用户是否加入组织
   
   * @param data: ParamsBodyauthAccountIsJoinTenantPost
   * @param opt ajax config
   * @returns models.AuthAccountIsJoinTenant
   */
  public authAccountIsJoinTenantPost = (
    
    data: ParamsBodyauthAccountIsJoinTenantPost,opt?: ExtraFetchParams
  ) : AjaxPromise<models.AuthAccountIsJoinTenant>  => {
    const url = this.$basePath + `/auth/account/isJoinTenant`;
    const p: any = {};
    p.data = data;
    return ajax.ajax({
      ...opt,
      method: 'POST',
      url,
      ...p
    });
  }
  /**
   * 
   * @summary 登录或者注册用户是否被移除组织
   
   * @param data: ParamsBodyauthAccountIsRemoveTenantPost
   * @param opt ajax config
   * @returns models.AuthAccountIsRemoveTenant
   */
  public authAccountIsRemoveTenantPost = (
    
    data: ParamsBodyauthAccountIsRemoveTenantPost,opt?: ExtraFetchParams
  ) : AjaxPromise<models.AuthAccountIsRemoveTenant>  => {
    const url = this.$basePath + `/auth/account/isRemoveTenant`;
    const p: any = {};
    p.data = data;
    return ajax.ajax({
      ...opt,
      method: 'POST',
      url,
      ...p
    });
  }
  /**
   * ```   /_**      * 用户接受邀请      *_/     @PostMapping(\"userAcceptInvite\")     public Result<Boolean> userAcceptInvite(@RequestBody AcceptInviteReq acceptInviteReq) ```
   * @summary 用户接受邀请
   
   * @param data: ParamsBodyauthAccountUserAcceptInvitePost// AcceptInviteReq :AcceptInviteReq
   * @param opt ajax config
   * @returns models.ResultBoolean
   */
  public authAccountUserAcceptInvitePost = (
    
    data: ParamsBodyauthAccountUserAcceptInvitePost,opt?: ExtraFetchParams
  ) : AjaxPromise<models.ResultBoolean>  => {
    const url = this.$basePath + `/auth/account/userAcceptInvite`;
    const p: any = {};
    p.data = data;
    return ajax.ajax({
      ...opt,
      method: 'POST',
      url,
      ...p
    });
  }
}

export default new AccountControllerApi();