import { Row, Col, Tooltip } from 'antd'

import styles from '../../index.module.scss'

// 添加方案项目列表
const SelectProjectList = (props: any) => {
  const { onChange, dataSource, value } = props

  const cardDom = (item: any) => {
    const { id, name, isBorderSetting, provinceName, createUserName, hasAuth } = item || {}
    return (
      <div
        onClick={() => {
          isBorderSetting && hasAuth && onChange?.(id)
        }}
        className={value === id ? 'active project-item-card' : 'project-item-card'}
      >
        <div>
          <span className="title">
            {name?.length > 12 ? <Tooltip title={name}>{name.substring(0, 12)}...</Tooltip> : name}
          </span>
        </div>
        <div className="desc">
          <span>{provinceName} </span>|<span className="username"> {createUserName}</span>
        </div>
        {value === id && <img src="../assets/icon_choose.svg" alt="" />}
      </div>
    )
  }

  return (
    <Row className={styles.createSchemeProjectList} gutter={[20, 20]}>
      {dataSource.map((item: any) => {
        const data = { ...(item || {}) }
        const { id, isBorderSetting, currOperationAuth } = data
        const auths: string[] = currOperationAuth || []
        const hasAuth = auths.includes('technical_proposal')
        data.hasAuth = hasAuth
        const tips = !isBorderSetting ? '边界不全，无法创建' : !hasAuth ? '无权限' : ''
        return (
          <Col span={8} key={id}>
            {!tips ? cardDom(data) : <Tooltip title={tips}>{cardDom(data)}</Tooltip>}
          </Col>
        )
      })}
    </Row>
  )
}

export default SelectProjectList
