import styles from './index.module.scss'
import React, { useEffect, useState } from 'react'
import { Card, Tabs, Col, Row, Select } from 'antd'
import { BreadcrumbObservable, SideMenuObservable } from '../../application'
import SurveyCardList from './components/survey-card'
import EquipmentTypePie from './components/equipment-type-pie'
import MeasureSpot from './components/measure-spot'
import { EquipmentService } from '../../apis/equipment-service'
import { useParams } from 'react-router-dom'
import EquipmentStationList from './components/List'

const DefaultParams = {
  page: 0,
  size: 10,
}

const EquipmentStation = () => {
  const { powerStationId } = useParams()
  console.log(powerStationId, '===powerStationId')
  
  let pageInterval: any
  const [activeTabKey, setActiveTabKey] = useState<string>('equipmentStatus' || 'measureSpot') // 当前显示的Tab

  const [deviceTypes, setDeviceTypes] = useState<any>([]) // 设备类型下拉框数据
  const [powerStation, setPowerStation] = useState<any>([]) // 设备电站下拉框数据
  const [queryData, setQueryData] = useState<any>({}) // 查询条件

  const [loading, setLoading] = useState<boolean>(false)
  const [list, setList] = useState<any[]>([])
  const [total, setTotal] = useState<number>(0)
  const [pageNum, setPageNum] = useState<number>(1)
  const [pageSize, setPageSize] = useState<number>(10)

  const fetchList = (params: any) => {
    setLoading(true)
    EquipmentService.list(params)
      .then((res) => {
        setLoading(false)
        const { code, data } = res || {}
        if (code === 200) {
          setTotal(data?.total || 0)
          setList(data?.data || [])
        }
      })
      .catch(() => {
        setLoading(false)
      })
  }

  const handlePageChange = (page: number, size: number) => {
    setPageNum(page)
    setPageSize(size)
    fetchList({ page: page, size: size })
  }

  useEffect(() => {
    fetchList({ ...DefaultParams })
  }, [])

  // 设备数
  const [deviceData, setDeviceData] = useState<any>({
    deviceTotal: 0,
    deviceOnly: 0,
    onlyRate: 0,
    pieList: [] // 饼图 数据
  })

  const [entityId, setEntityId] = useState<string>('') // 存储设备ID
  
  
  useEffect(() => {
    // 设备情况及类型分布 数据
    EquipmentService.getDeviceTypeDistribution()
      .then((res) => {
        console.log(res, '===设备情况及类型分布')
        setDeviceData(res.data || {})
      })
      .catch(() => {})
    // 设备类型下拉框 数据
    EquipmentService.getDeviceTypeComboBox()
      .then((res) => {
        console.log(res, deviceTypes, '===设备类型下拉框')
        setDeviceTypes(res?.data || [])
      })
      .catch(() => {})
    // 设备电站下拉框 数据
    EquipmentService.getPowerStationComboBox()
    .then((res) => {
      console.log(res, '===设备类型下拉框')
      setPowerStation(res?.data || [])
    })
    .catch(() => {})
    if (powerStationId !== 'default') {
      setQueryData({ powerStationId: Number(powerStationId) })
      fetchList({
        powerStationId: Number(powerStationId) || ''
      })
    }

    pageInterval = setInterval(() => {
      // 设备情况及类型分布 数据
      EquipmentService.getDeviceTypeDistribution()
      .then((res) => {
        console.log(res, '===设备情况及类型分布')
        setDeviceData(res.data || {})
      })
      .catch(() => {})
      // 设备类型下拉框 数据
      EquipmentService.getDeviceTypeComboBox()
        .then((res) => {
          console.log(res, deviceTypes, '===设备类型下拉框')
          setDeviceTypes(res?.data || [])
        })
        .catch(() => {})
      // 设备电站下拉框 数据
      EquipmentService.getPowerStationComboBox()
      .then((res) => {
        console.log(res, '===设备类型下拉框')
        setPowerStation(res?.data || [])
      })
      .catch(() => {})
      // 更新列表
      if (activeTabKey === 'equipmentStatus') {
        fetchList({})
      }
    }, 300000)
    return () => {
      clearInterval(pageInterval)
      pageInterval = undefined
    }
  
  }, [])
  
  React.useEffect(() => {
    SideMenuObservable.next(false)
    BreadcrumbObservable.next([])
  }, [])

  return (
    <div className={`${styles.content} ro-content`}>
      <div className={`custom-scroll-bar equipmentContainer`}>
        <div className={styles.equipmentHead}>
          <SurveyCardList deviceData={deviceData} />
          <EquipmentTypePie pieList={deviceData?.pieList || []} />
        </div>
        <Card
          style={{ height: '100%' }}
          className="no-border-bottom-card"
          extra={loading}
          bodyStyle={{ minHeight: 'calc(100vh - 260px - 140px)', padding: 0 }}
        >
          <Tabs
            className="partner-tabs"
            activeKey={activeTabKey}
            tabBarStyle={activeTabKey !== 'equipmentStatus' ?  { padding: '0 24px', margin: 0 } : { padding: '0 24px' }}
            onChange={(key: string) => {
              setActiveTabKey(key)
              setEntityId('')
              setQueryData({
                powerStationId: '',
                deviceType: ''
              })
              fetchList({
                powerStationId: '',
                deviceType: ''
              })
            }}
          >
          <Tabs.TabPane tab="设备状况" key="equipmentStatus">
            <div className={styles.queryWarp}>
              <Row>
                <Col span={8}>
                  <div className={styles.queryItem}>
                    <span className={styles.queryItemTitle}>电站</span>
                    <Select placeholder={'请选择电站'}
                      allowClear
                      style={{ width: '100%' }}
                      value={queryData.powerStationId}
                      onChange={(value) => {
                        setQueryData({
                          ...queryData,
                          powerStationId: value
                        })
                        fetchList({
                          powerStationId: value || '',
                          deviceType: queryData?.deviceType || ''
                        })
                      }}
                    >
                      {powerStation?.map((item: any) => {
                        return (
                          <Select.Option key={item.powerStationId} value={item.powerStationId}>
                            {item.powerStationName}
                          </Select.Option>
                        )
                      })}
                    </Select>
                  </div>
                </Col>
                <Col span={8}>
                <div className={styles.queryItem}>
                    <span className={styles.queryItemTitle}>设备类型</span>
                    <Select placeholder={'请选择设备类型'}
                      allowClear
                      style={{ width: '100%' }}
                      value={queryData.deviceType}
                      onChange={(value) => {
                        setQueryData({
                          ...queryData,
                          deviceType: value
                        })
                        fetchList({
                          deviceType: value || '',
                          powerStationId: queryData?.powerStationId || ''
                        })
                      }}
                    >
                      {deviceTypes?.map((item: any) => {
                        return (
                          <Select.Option key={item.type} value={item.type}>
                            {item.name}
                          </Select.Option>
                        )
                      })}
                    </Select>
                  </div>
                </Col>
              </Row>
            </div>
            <EquipmentStationList
              list={list}
              total={total}
              pageNum={pageNum}
              pageSize={pageSize}
              changeList={(record: any) => {
                setEntityId(record?.entityId || '')
              setActiveTabKey('measureSpot')
              }}
              onChange={(pageNum, pageSize) => handlePageChange?.(pageNum, pageSize)}
            />
          </Tabs.TabPane>
          <Tabs.TabPane tab="测点数据" key="measureSpot">
            {
              activeTabKey === 'measureSpot' && (<MeasureSpot entityId={entityId} />)
            }
          </Tabs.TabPane>
          </Tabs>
        </Card>
        
      </div>
    </div>
  )
}

export default EquipmentStation
