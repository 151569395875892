import React, { useImperativeHandle } from 'react'
import moment from 'moment'
import { Form, Input, Row, Col, Spin, FormInstance } from 'antd'
import { dynamicText, ProjectProductionService } from '../../../../apis/wl-service'
import { importantLevelOptions, provinceProgressOptions, groupProgressOptions } from '../../common/constant'
import { CustomFormItem, CustomFormTitle, formItemLayout } from '../../../../components/wl-form-item'
import DynamicTable from '../common/dynamic-table'
import useProject from '../common/useProject'

export interface IProjectProductionFormProps {
  id?: string
  editable?: boolean
}

export const RefForm = ({ id, editable = false }: IProjectProductionFormProps, ref: any) => {
  // type=params作为参数发送到服务端
  // type=component转换数据用于组件渲染
  const formatter = (values: any, type: 'params' | 'component') => {
    // 转换时间值
    if (editable) {
      ;['product_date', 'production_real_time'].forEach((k: string) => {
        if (type === 'component') {
          values[k] = values[k] ? moment(values[k]) : null
        }
        if (type === 'params') {
          values[k] = values[k] ? moment(values[k]).format('YYYY-MM-DD') : ''
        }
      })

      // 转换表格数据
      ;['energy_project_production_scale'].forEach((k: string) => {
        if (type === 'component') {
          values[k] = values[k]?.map((v: any) => ({ ...v, planTime: v.planTime ? moment(v.planTime) : null })) ?? []
        }

        if (type === 'params') {
          values[k] =
            values[k]?.map((v: any) => ({
              ...v,
              planTime: v.planTime ? v.planTime.format('YYYY-MM-DD') : '',
            })) ?? []
        }
      })
    }

    // 转换文件值
    ;[
      'csbgpf_file',
      'lcbjdpf_file',
      'hpbgpf_file',
      'stbgpf_file',
      'ghxkz_file',
      'ydylqk_file',
      'sgxkz_file',
      'qt_file',
    ].forEach((k: string) => {
      if (type === 'component' && !Array.isArray(values[k])) {
        values[k] = values[k] ? JSON.parse(values[k]) : []
      }

      if (type === 'params') {
        values[k] = values[k]?.length ? JSON.stringify(values[k]) : ''
      }
    })

    // 设置select
    ;['importantlevel', 'province_progress_demand', 'group_progress_demand'].forEach((k: string) => {
      if (type === 'component' && typeof values[k] === 'number') {
        values[k] = values[k].toString()
      }
    })

    return values
  }

  const [{ imperativeHandle, form, formData, loading, projectId, options, getDetails, onValuesChange }] = useProject({
    submitOptions: {
      label: '新建建设',
      Api: (values: any) =>
        ProjectProductionService.update({
          id: values.id ?? '',
          root: values,
        }),
    },
    detailsApi: async (projectId) => {
      return await (projectId ? ProjectProductionService.info({ id: projectId }) : ProjectProductionService.draft())
    },
    formatter,
    key: 'production',
    shareable: !id,
    mergeResponse: (data) => {
      return {
        mark: data?.resourceList?.mark,
        project_id: data?.resourceList?.id,
        project_name: data?.resourceList?.projectName,
        project_code: data?.resourceList?.projectCode,
        project_type: data?.resourceList?.resourcetype,
        project_type_text: dynamicText(data?.resourceList?.resourcetype, options.projectTypeOptions),
        importantlevel: data?.recordList?.importantLevel,
      }
    },
  })

  useImperativeHandle(ref, imperativeHandle)

  // 根据projectId获取详情
  React.useEffect(() => {
    getDetails(id ?? projectId).then()
  }, [id, projectId])

  return (
    <Spin spinning={loading}>
      <Form
        name="projectProductionForm"
        form={form}
        labelWrap
        {...formItemLayout.span8}
        autoComplete="off"
        initialValues={formData}
        onValuesChange={(changedValues, allValues) => {
          onValuesChange(changedValues, allValues)
        }}
      >
        <Form.Item name="id" hidden>
          <Input />
        </Form.Item>

        <CustomFormTitle>基本信息</CustomFormTitle>
        <Row>
          <CustomFormItem
            label="项目名称"
            name="mark"
            type="project_selector"
            span={3}
            editable={editable}
            options={options.projectIdOptions}
            setOptions={options.setProjectIdOptions}
            setValues={form.setFieldsValue}
            value={formData.project_name}
            componentProps={{
              disabled: !!id,
            }}
            rules={[
              {
                required: true,
                message: '请选择项目名称',
              },
            ]}
          />

          <CustomFormItem
            label="项目代码"
            name="project_code"
            span={3}
            type="input"
            componentProps={{ disabled: true }}
            editable={editable}
            value={formData.project_code}
          />
          <CustomFormItem
            label="项目类型"
            name="project_type"
            span={3}
            type="select"
            options={options.projectTypeOptions}
            editable={editable}
            computed={false}
            value={formData.project_type_text ?? dynamicText(formData.project_type, options.projectTypeOptions)}
            componentProps={{
              disabled: true,
              onChange: (value: any, option: any) => {
                form.setFieldsValue({ project_type_text: option.children })
              },
            }}
          >
            <Form.Item name="project_type_text" hidden={true}>
              <Input />
            </Form.Item>
          </CustomFormItem>

          <CustomFormItem
            label="填报年月日"
            name="product_date"
            type="datepicker"
            span={3}
            editable={editable}
            value={formData.product_date}
            componentProps={{
              format: 'YYYY-MM-DD',
            }}
          />

          <CustomFormItem
            label="计划工期(月)"
            name="plan_work_month"
            span={3}
            type="number"
            editable={editable}
            value={formData.plan_work_month}
          />

          <CustomFormItem
            label="已投产容量(MW)"
            name="production_capacity"
            span={3}
            type="number"
            editable={editable}
            value={formData.production_capacity}
          />

          <CustomFormItem
            label="投产时间"
            name="production_real_time"
            span={3}
            type="datepicker"
            editable={editable}
            value={formData.production_real_time}
            componentProps={{
              format: 'YYYY-MM-DD',
            }}
          />

          <CustomFormItem
            label="重要程度"
            name="importantlevel"
            span={3}
            type="select"
            editable={editable}
            computed={true}
            options={importantLevelOptions}
            value={formData.importantlevel}
            componentProps={{
              disabled: true,
            }}
          />

          <Form.Item
            noStyle
            shouldUpdate={(prevValues, currentValues) => prevValues.importantLevel !== currentValues.importantLevel}
          >
            {({ getFieldValue }: FormInstance) => {
              const current = getFieldValue('importantLevel')
              if (['1', '2'].includes(current)) {
                return (
                  <CustomFormItem
                    label="省当年进度要求"
                    name="province_progress_demand"
                    span={3}
                    type="select"
                    editable={editable}
                    computed={true}
                    options={provinceProgressOptions}
                    value={formData.province_progress_demand}
                    componentProps={{}}
                  />
                )
              }
              if (['3', '4', '5'].includes(current)) {
                return (
                  <CustomFormItem
                    label="集团当年进度要求"
                    name="group_progress_demand"
                    span={3}
                    type="select"
                    editable={editable}
                    computed={true}
                    options={groupProgressOptions}
                    value={formData.group_progress_demand}
                    componentProps={{}}
                  />
                )
              }
              return null
            }}
          </Form.Item>
        </Row>

        <CustomFormTitle>项目工程进展情况</CustomFormTitle>
        <Row>
          <CustomFormItem
            label="工程总体进展"
            name="main_develop"
            span={3}
            type="textarea"
            editable={editable}
            value={formData.main_develop}
          />
          <CustomFormItem
            label="本月完成的主要工作"
            name="month_main_work"
            span={3}
            type="textarea"
            editable={editable}
            value={formData.month_main_work}
          />
          <CustomFormItem
            label="存在问题及解决措施"
            name="question_deal_info"
            span={3}
            type="textarea"
            editable={editable}
            value={formData.question_deal_info}
          />
          <Col xs={24}>
            <Form.Item noStyle name="energy_project_production_scale">
              <DynamicTable
                addText="新增机组信息"
                editable={editable}
                columns={[
                  {
                    title: '序号',
                    dataIndex: 'index',
                    width: 80,
                    render: (text: any, record: any, index: number) => '机型' + (index + 1),
                  },
                  {
                    title: '建设规模',
                    children: [
                      {
                        title: '台数',
                        dataIndex: 'buildT',
                        editable: true,
                        inputType: 'number',
                      },
                      {
                        title: '容量',
                        dataIndex: 'buildR',
                        editable: true,
                        inputType: 'number',
                      },
                    ],
                  },
                  {
                    title: '本年计划投产规模',
                    children: [
                      {
                        title: '台数',
                        dataIndex: 'planT',
                        editable: true,
                        inputType: 'number',
                      },
                      {
                        title: '容量',
                        dataIndex: 'planR',
                        editable: true,
                        inputType: 'number',
                      },
                      {
                        title: '投产时间',
                        dataIndex: 'planTime',
                        editable: true,
                        inputType: 'datepicker',
                        componentProps: {
                          format: 'YYYY-MM-DD',
                        },
                      },
                    ],
                  },
                  {
                    title: '本月施工规模',
                    children: [
                      {
                        title: '台数',
                        dataIndex: 'constructionT',
                        editable: true,
                        inputType: 'number',
                      },
                      {
                        title: '容量',
                        dataIndex: 'constructionR',
                        editable: true,
                        inputType: 'number',
                      },
                    ],
                  },
                ]}
              />
            </Form.Item>
          </Col>
        </Row>

        <CustomFormTitle>附件</CustomFormTitle>
        <Row>
          <CustomFormItem
            label="初设报告及批复意见"
            name="csbgpf_file"
            span={2}
            type="file"
            editable={editable}
            value={formData.csbgpf_file}
            // rules={[
            //   {
            //     validator: (rule: any, value: any) => {
            //       if (!value || value.length === 0) {
            //         return Promise.reject('需上传可研报告才可提交')
            //       }
            //       return Promise.resolve()
            //     },
            //   },
            // ]}
          />
          <CustomFormItem
            label="里程碑节点批复"
            name="lcbjdpf_file"
            span={2}
            type="file"
            editable={editable}
            value={formData.lcbjdpf_file}
          />

          <CustomFormItem
            label="环评报告及批复"
            name="hpbgpf_file"
            span={2}
            type="file"
            editable={editable}
            value={formData.hpbgpf_file}
          />
          <CustomFormItem
            label="水土保持报告与批复"
            name="stbgpf_file"
            span={2}
            type="file"
            editable={editable}
            value={formData.stbgpf_file}
          />

          <CustomFormItem
            label="规划许可证"
            name="ghxkz_file"
            span={2}
            type="file"
            editable={editable}
            value={formData.ghxkz_file}
          />
          <CustomFormItem
            label="用地用林用海指标落实情况"
            name="ydylqk_file"
            span={2}
            type="file"
            editable={editable}
            value={formData.ydylqk_file}
          />

          <CustomFormItem
            label="施工许可证"
            name="sgxkz_file"
            span={2}
            type="file"
            editable={editable}
            value={formData.sgxkz_file}
          />

          <CustomFormItem
            label="其他附件"
            name="qt_file"
            span={2}
            type="file"
            editable={editable}
            value={formData.qt_file}
          />
        </Row>
      </Form>
    </Spin>
  )
}

export default React.forwardRef(RefForm)
