import { Col, Form, Input, Row, Select, Slider, Spin } from 'antd'
import React from 'react'
import { ProjectWorkbenchService } from '../../../../apis/project-service-history'
import '../index.less'

export const BatteryAutoSelect = (props: { technologyId: string; onClose: (reload?: boolean) => void }) => {
  const [form] = Form.useForm()
  const [current, setCurrent] = React.useState<any>()
  const [loading, setLoading] = React.useState(true)

  React.useEffect(() => {
    ProjectWorkbenchService.getAutoCheckOpt({
      technologyId: props.technologyId,
    })
      .then((resp) => {
        setCurrent(resp.data)

        const { batteryLossRate, acLossRate, otherLossRate } = resp.data

        form.setFieldsValue({
          batteryLossRate,
          acLossRate,
          otherLossRate,
        })
      })
      .finally(() => {
        setLoading(false)
      })
  }, [])

  return (
    <Spin spinning={loading}>
      <div
        className="disabled-no-style"
        style={{
          minHeight: '3rem',
        }}
      >
        {current && (
          <Form
            className="system-form"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            form={form}
            onFieldsChange={() => {
              setCurrent({ ...current })
            }}
          >
            <div className="sub-title">算力资源</div>
            <div className="tips">虚机配置</div>
            <Row>
              <Col
                span={16}
                style={{
                  alignItems: 'center',
                }}
              >
                <Form.Item labelCol={{ span: 4 }} wrapperCol={{ span: 20 }} label="CPU">
                  <Select disabled value={0}>
                    <Select.Option value={0}>{current.cpu}</Select.Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="内存">
                  <Select disabled value={0}>
                    <Select.Option value={0}>{current.ram}</Select.Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col span={24}>
                <div className="tips">虚机数量</div>
              </Col>
              <Col span={24}>
                <Form.Item wrapperCol={{ span: 24 }}>
                  <Row>
                    <Col span={21}>
                      <Slider value={current.vmNum} min={0} max={6} disabled />
                    </Col>
                    <Col span={3}>
                      <Input disabled suffix="台" value={current.vmNum} />
                    </Col>
                  </Row>
                </Form.Item>
              </Col>
            </Row>

            <div className="sub-title">超参设置</div>
            <div className="tips">倍率搜索</div>
            <Row>
              <Col span={8}>
                <Form.Item label="起点">
                  <Input style={{ width: '100%' }} disabled suffix="" value={current.startPoint} />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="终点">
                  <Input style={{ width: '100%' }} disabled suffix="" value={current.endPoint} />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="步长">
                  <Input style={{ width: '100%' }} disabled suffix="" value={current.step} />
                </Form.Item>
              </Col>
              <Col span={24}>
                <div className="tips">倍率容错</div>
              </Col>
              <Col span={8}>
                <Form.Item wrapperCol={{ span: 24 }}>
                  <Input style={{ width: '100%' }} disabled suffix="%" value={current.multipleFaultTolerance} />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        )}
        {current && (
          <div className="total-rate">
            <h3>
              预估耗时：
              <span
                style={{
                  fontSize: 16,
                  marginRight: '3rem',
                }}
              >
                {current.timeConsuming || '--'}分钟
              </span>
              单次费用：
              <span
                style={{
                  fontSize: 16,
                }}
              >
                {current.cost || '--'}元
              </span>
            </h3>
          </div>
        )}
      </div>
    </Spin>
  )
}
