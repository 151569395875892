import React from 'react'
import { Button, Space } from 'antd'
import IconCorrect from '../../../../../assets/icon-correct.png'

import './index.less'

type IProps = {
  onBack?: () => void
  onView?: () => void
  onCreate?: () => void
}

const Complete: React.FC<IProps> = React.memo((props) => {
  const { onBack, onView, onCreate } = props
  return (
    <div className="station-complete">
      <div className="station-complete-icon">
        <img src={IconCorrect} alt="" />
      </div>
      <h3 className="station-complete-title">创建</h3>
      <p className="station-complete-tips">创建电站成功</p>
      <div>
        <Space size={10}>
          <Button size="large" style={{ width: 96 }} onClick={onBack}>
            返回
          </Button>
          <Button size="large" onClick={onView}>
            查看电站
          </Button>
          <Button type="primary" size="large" onClick={onCreate}>
            再次创建
          </Button>
        </Space>
      </div>
    </div>
  )
})

export default Complete
