import React, { useEffect, useRef, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { Button, Space, Tooltip, Typography } from 'antd'
import { ExportOutlined, PlusOutlined } from '@ant-design/icons'

import { CommonService, ResourceService } from '../../../apis/wl-service'
import TablePage, { getActionColumn, getColumnFilterProps, TablePageInstance } from '../../../components/wl-table-page'
import ExportModal from './components/export-modal'
import store from '../project-library-create/components/store'

export default () => {
  const nav = useNavigate()
  const location = useLocation()
  const tableRef = useRef<TablePageInstance>(null)
  const [dataSet, setDataSet] = useState<{ resourceModuleOptions?: []; resourceTypeOptions: [] }>({
    resourceTypeOptions: [],
    // resourceModuleOptions: [],
  }) // 选择项字段存储
  const [exportModal, setExportModal] = useState<{ visible: boolean; [name: string]: any }>({ visible: false }) // 导出弹窗数据
  const [breadcrumb, setBreadcrumb] = useState<any[]>([])

  // 设置面包屑
  useEffect(() => {
    setBreadcrumb([
      location.state?.engineering
        ? { name: '工程管理', url: `/invest/projectLibrary`, state: location.state }
        : { name: '投资开发', url: '/invest/workbench' },
      { name: '项目库', url: `/invest/projectLibrary`, state: location.state },
    ])
  }, [location.state])

  // 获取选择器字典
  useEffect(() => {
    CommonService.getDictionaryDataSelectorValid('15301742e5ec4e92843192cb76010e08')
      .then((resp: any) => {
        setDataSet((previous) => ({ ...previous, resourceTypeOptions: resp?.data?.list || [] }))
      })
      .catch(() => {})
    // CommonService.getDictionaryDataSelectorValid('0d73389eff704bef8668656ed024f3d0')
    //   .then((resp: any) => {
    //     setDataSet((previous) => ({ ...previous, resourceModuleOptions: resp?.data?.list || [] }))
    //   })
    //   .catch(() => {})
  }, [])

  // 表格列配置
  const columns = (tableParams: any) => [
    {
      title: '年份',
      dataIndex: 'resourceyear',
      width: 100,
      fixed: 'left' as any,
    },
    {
      title: '项目名称',
      dataIndex: 'projectName',
      width: 120,
      // ellipsis: true,
      fixed: 'left' as any,
      ...getColumnFilterProps({
        type: 'input',
        placeholder: '请输入项目名称',
        value: tableParams.project_name,
        onSearch: (value) => {
          tableRef.current?.reload?.({
            project_name: value,
            page: 1,
          })
        },
      }),
      render: (text: string, row: any) =>
        text && (
          <Tooltip title={text}>
            <Typography.Text ellipsis>
              <Link to={`/invest/projectDetail/${row.id}`} state={location.state}>
                {text}
              </Link>
            </Typography.Text>
          </Tooltip>
        ),
    },
    {
      title: '项目类型',
      dataIndex: 'resourcetypename',
      width: 100,
      ...getColumnFilterProps({
        type: 'select',
        placeholder: '请输入项目名称',
        value: tableParams.resourcetype,
        options: dataSet.resourceTypeOptions,
        onSearch: (value) => {
          tableRef.current?.reload?.({
            resourcetype: value,
            page: 1,
          })
        },
      }),
      // render: (text: any) => {
      //   return (dataSet.resourceTypeOptions.find((item: any) => item.id === text) as any)?.fullName
      // },
    },

    {
      title: '项目代码',
      dataIndex: 'projectCode',
      width: 100,
    },
    {
      title: '规模（MW）',
      dataIndex: 'projectModule',
      width: 120,
    },
    {
      title: '开发主体',
      dataIndex: 'developer',
      width: 120,
      render: (text: string) =>
        text && (
          <Tooltip title={text}>
            <Typography.Text ellipsis>{text}</Typography.Text>
          </Tooltip>
        ),
    },
    {
      title: '资源属地',
      dataIndex: 'resourcename',
      width: 100,
      ...getColumnFilterProps({
        placeholder: '请选择资源属地',
        type: 'address_selector',
        value: tableParams.resourcedependency?.length ? tableParams.resourcedependency.split(',') : [],
        onSearch: (value) => {
          tableRef.current?.reload?.({
            resourcedependency: Array.isArray(value) ? (value as any).join(',') : undefined,
            page: 1,
          })
        },
      }),
      render: (text: string) => (
        <Tooltip title={text}>
          <Typography.Text ellipsis>{text}</Typography.Text>
        </Tooltip>
      ),
    },
    {
      title: '状态',
      dataIndex: 'currentState',
      width: 100,
      render: (text: string) => {
        // ("项目状态 0未开始 1收资中 2已归档")
        switch (text?.toString()) {
          case '0':
            return <Typography.Text type="danger">未开始</Typography.Text>

          case '1':
            return <Typography.Text type="success">收资中</Typography.Text>

          case '2':
            return <Typography.Text>已归档</Typography.Text>

          default:
            return null
        }
      },
    },
    {
      title: '创建人',
      dataIndex: 'createName',
      width: 100,
    },
    getActionColumn([
      {
        key: 'setting',
        label: '项目设置',
        onClick: (row: any) => {
          nav(`/program/detail/${row.mark ?? ''}/settings/member`)
        },
      },
      ...(location.state?.engineering
        ? [
            { key: 4, label: '编辑建设' },
            {
              key: '5',
              label: '编辑造价',
              children: [
                { key: '1', label: '可研预算' },
                { key: '2', label: '初设概算' },
                { key: '3', label: '竣工结算' },
              ],
            }, // 编辑造价
            { key: 6, label: '编辑投资计划' },
            { key: 7, label: '编辑投资进度' },
          ].map((v: any) => {
            if (Array.isArray(v.children)) {
              return {
                key: `edit${v.key}`,
                label: v.label,
                children: v.children.map((c: any) => {
                  return {
                    key: `edit${v.key}.${c.key}`,
                    label: c.label,
                    onClick: (row: any) => {
                      nav(`/invest/project/true/${row.id}?moduleId=${v.key}&t=${c.key}`, { state: location.state })
                    },
                  }
                }),
              }
            }
            return {
              key: `edit${v.key}`,
              label: v.label,
              onClick: (row: any) => {
                nav(`/invest/project/true/${row.id}?moduleId=${v.key}`, { state: location.state })
              },
            }
          })
        : [
            { key: 1, label: '编辑资源开发' },
            { key: 2, label: '编辑投资决策' },
            { key: 3, label: '编辑核准备案' },
          ].map((v: any) => ({
            key: `edit${v.key}`,
            label: v.label,
            onClick: (row: any) => {
              nav(`/invest/projectLibrary/true/${row.id}?s=1&t=${v.key}`, { state: location.state })
            },
          }))),
      {
        key: 'delete',
        type: 'delete',
        getter: (row: any) => {
          return ResourceService.remove({ id: row.id })
        },
        success: (arg: any) => tableRef.current?.reload?.(arg),
      },
      {
        key: 'file',
        label: '附件',
        onClick: (row: any) => {
          nav(`/invest/document/${row.id}`, { state: location.state })
        },
      },
    ]),
  ]

  // 新建项目
  const onCreate = React.useCallback(() => {
    if (location.state?.engineering) {
      nav(`/engineering/projectLibrary/true`, { state: location.state })
    } else {
      store.clearAll()
      nav(`/invest/projectLibrary/true`)
    }
  }, [location.state, store])

  return (
    <TablePage
      ref={tableRef}
      title="项目库"
      extra={
        <Space>
          <Button type="primary" icon={<PlusOutlined />} onClick={onCreate}>
            新建项目
          </Button>
          <Button
            type="primary"
            icon={<ExportOutlined />}
            onClick={() => {
              setExportModal({ visible: true })
            }}
          >
            导出项目表格
          </Button>
        </Space>
      }
      breadcrumb={breadcrumb}
      checkable
      columns={columns}
      rowKey="id"
      getter={(arg: any) => ResourceService.depotList(arg)}
    >
      <ExportModal
        mode={location.state?.engineering ? 'engineering' : 'invest'}
        visible={exportModal.visible}
        visibleChange={(visible: boolean) => {
          setExportModal({ visible })
        }}
        getParams={() => {
          return {
            ...tableRef.current?.getParams.get(),
            ids: tableRef.current?.getSelectedRowKeys() ?? [],
          }
        }}
      />
    </TablePage>
  )
}
