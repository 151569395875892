import React, { useEffect, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import { Card, Tooltip, Typography, Button, Modal, message } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import { BreadcrumbObservable, SideMenuObservable } from '../../../application'
import { LogisticsService, CommonService } from '../../../apis/engineering-service'
import { TablePage, TablePageInstance, getActionColumn, getColumnFilterProps } from '../../../components/wl-table-page'
import './index.less'

export default () => {
  const nav = useNavigate()
  const logisticsRef = useRef<TablePageInstance>(null)

  const [options, setOptions] = React.useState<{ [name: string]: any }>({
    projectListOptions: [], // 项目列表
  })

  // 获取基础下拉数据
  useEffect(() => {
    // 获取项目名称 下拉数据
    CommonService.getProjectDataSelector()
      .then((res: any) => {
        let list = []
        if (res?.data) {
          res?.data.map((item) => {
            list.push({
              id: item.projectNo,
              label: item.projectName
            })
          })
        }
        setOptions((previous) => ({ ...previous, projectListOptions: list }))
      })
      .catch(() => {})
      
  }, [])

  useEffect(() => {
    SideMenuObservable.next(false)
    BreadcrumbObservable.next([
      {
        name: '工程管理',
        url: '/engineering',
      },
      {
        name: '物流管理',
        url: '/engineering/logistics',
      },
    ])
  }, [])

  const columns = (tableParams: any) => [
    {
      title: '运单编号',
      dataIndex: 'thirdPartyOrderNo',
      width: 100,
      render: (text: string, record: any) =>
        text && (
          <Tooltip title={text}>
            <Typography.Text ellipsis style={{color: '#1ec692', cursor: 'pointer'}} onClick={() => {nav(`/engineering/logisticsDetails/${record.manageId}`)}}>{text}</Typography.Text>
          </Tooltip>
        ),
    },
    {
      title: '项目名称',
      dataIndex: 'projectName',
      width: 100,
      ...getColumnFilterProps({
        type: 'select',
        placeholder: '请输入项目名称',
        value: tableParams.projectName,
        options: options.projectListOptions,
        onSearch: (value) => {
          logisticsRef.current?.reload?.({
            projectCode: value
          })
        },
      }),
      render: (text: string) =>
        text && (
          <Tooltip title={text}>
            <Typography.Text ellipsis>{text}</Typography.Text>
          </Tooltip>
        )
    },
    {
      title: '货物名称',
      dataIndex: 'name',
      width: 100,
      render: (text: string) =>
        text && (
          <Tooltip title={text}>
            <Typography.Text ellipsis>{text}</Typography.Text>
          </Tooltip>
        )
    },
    {
      title: '货物规格',
      dataIndex: 'specification',
      width: 100,
      render: (text: string) =>
        text && (
          <Tooltip title={text}>
            <Typography.Text ellipsis>{text}</Typography.Text>
          </Tooltip>
        ),
    },
    {
      title: '货物数量',
      dataIndex: 'number',
      width: 100,
      render: (text: string) =>
        text && (
          <Tooltip title={text}>
            <Typography.Text ellipsis>{text}</Typography.Text>
          </Tooltip>
        ),
    },
    {
      title: '货物重量',
      dataIndex: 'weight',
      width: 100,
      render: (text: string) =>
        text && (
          <Tooltip title={text}>
            <Typography.Text ellipsis>{text}</Typography.Text>
          </Tooltip>
        ),
    },
    {
      title: '包装费',
      dataIndex: 'packPrice',
      width: 120,
      render: (text: number) =>
        text && (
          <Tooltip title={text}>
            <Typography.Text ellipsis>{text}</Typography.Text>
          </Tooltip>
        ),
    },
    {
      title: '运输费',
      dataIndex: 'freightPrice',
      width: 120,
      render: (text: number) =>
        text && (
          <Tooltip title={text}>
            <Typography.Text ellipsis>{text}</Typography.Text>
          </Tooltip>
        ),
    },
    {
      title: '装卸费',
      dataIndex: 'loadUnloadPrice',
      width: 120,
      render: (text: number) =>
        text && (
          <Tooltip title={text}>
            <Typography.Text ellipsis>{text}</Typography.Text>
          </Tooltip>
        ),
    },
    {
      title: '仓储费',
      dataIndex: 'storagePrice',
      width: 120,
      render: (text: number) =>
        text && (
          <Tooltip title={text}>
            <Typography.Text ellipsis>{text}</Typography.Text>
          </Tooltip>
        ),
    },
    {
      title: '运单状态',
      dataIndex: 'status',
      width: 120,
      render: (status: number) => (
        <Typography.Text ellipsis>{status === 0 ? '未发货' : status === 1 ? '在途中' : status === 2 ? '已送达' : '-'}</Typography.Text>
      ),
    },
    {
      title: '创建时间',
      dataIndex: 'createTime',
      width: 180,
      render: (text: any) => {
        return text && (
          <Tooltip title={text}>
            <Typography.Text ellipsis>{text}</Typography.Text>
          </Tooltip>
        )
      }
    },
    {
      title: '发货时间',
      dataIndex: 'deliverGoodsTime',
      width: 180,
      render: (text: any) => {
        return text && (
          <Tooltip title={text}>
            <Typography.Text ellipsis>{text}</Typography.Text>
          </Tooltip>
        )
      },
    },
    {
      title: '支付状态',
      dataIndex: 'freightStatus',
      width: 100,
      render: (freightStatus: number) => {
        return (
          <Typography.Text ellipsis>{freightStatus === 1 ? '已支付' : '未支付'}</Typography.Text>
        )
      }
    },
    getActionColumn([
      {
        key: 'commit',
        label: '确认运费',
        visible: (row: any) => row.freightStatus === 0,
        onClick: (row: any) => {
          Modal.confirm({
            keyboard: false,
            title: '提示',
            type: 'warning',
            content: '您是否确认运费',
            okType: 'primary',
            okText: '确定',
            onOk: () => {
              LogisticsService.freightConfirm({ id: row.manageId })
                .then((resp: any) => {
                  if (resp?.code === 200) {
                    logisticsRef.current?.reload?.()
                  } else if (resp?.msg) {
                    message.warning(resp?.msg)
                  }
                })
                .catch(() => {})
            },
            cancelText: '取消',
          })
        }
      },
      {
        key: 'detail',
        label: '查看运单',
        onClick: (row: any) => {
          nav(`/engineering/logisticsDetails/${row.manageId}`)
        },
      },
      {
        key: 'edit',
        label: '编辑',
        visible: (row: any) => row.freightStatus !== 1,
        onClick: (row: any) => {
          console.log(row)
          nav(`/engineering/logisticsCreate/${row.manageId}`)
        },
      },
      {
        key: 'delete',
        type: 'delete',
        getter: (row: any) => {
          return LogisticsService.remove({ id: row.manageId })
        },
        success: (arg: any) => {
          logisticsRef.current?.reload?.({ ...arg })
        },
      },
    ]),
  ]

  return (
    <Card
      style={{ height: '100%' }}
      className="no-border-bottom-card"
      extra={null}
      bodyStyle={{ height: 'calc(100vh - 150px)', padding: 0 }}
    >
      <div className="screen-warp">
        <Button
          type="primary"
          icon={<PlusOutlined />}
          onClick={() => {
            console.log('新建运单')
            nav(`/engineering/logisticsCreate/${'create'}`)
          }}
        >
          新建运单
        </Button>
      </div>
      <TablePage
        componentRef={logisticsRef}
        columns={columns}
        rowKey="manageId"
        getter={(arg: any) => LogisticsService.list({ ...arg })}
      />
    </Card>
  )
}
