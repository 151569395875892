import { Spin } from 'antd'
import ReactECharts from 'echarts-for-react'

import styles from '../index.module.scss'

// 图标颜色
const defaultColors = [
  '#007BF6',
  '#FFB74C',
  '#10A350',
  '#00B1FA',
  '#FF7C72'
]


const setPieInterval = (data) => {
  
  let newRingData = []
  const total = data.length !== 0 ? data.map(v => (v.number)).reduce((prev, next) => prev + next) : 0
  if (data.length) {
    data.forEach(item => {
      if (data.length > 1) {
        newRingData.push({
          name: '间隔',
          value: total * 0.01,
          label: {
            show: false
          },
          labelLine: {
            show: false
          },
          itemStyle: {
            color: 'transparent'
          },
          tooltip: {
            show: false
          }
        })
      }
      newRingData.push({
        name: item.modelName,
        value: item.number,
        rate: item.rate,
      })
    })
  }
  return newRingData
}

const EquipmentTypePie = (props) => {
  const { pieList = [] } = props
  const total = pieList.length !== 0 ? pieList.map(v => (v.number)).reduce((prev, next) => prev + next) : 0
  return (
    <Spin spinning={false}>
      <div className={styles.equipmentTypeCard}>
        <p className={styles.equipmentTypeCardTitle}>设备类型分布</p>
        <ReactECharts
          style={{
            width: '100%',
            height: '260px',
          }}
          option={{
            color: defaultColors,
            title: {
              text: '设备数量',
              left: '14.8%',
              top: 'center',
              textStyle: {
                color: '#606266',
                fontSize: 12
              },
              subtext: `${total}台`,
              width: 100,
              textAlign: 'center',
              subtextStyle: {
                color: '#303133',
                fontSize: 14
              }
            },
            tooltip: {
              trigger: 'item',
              backgroundColor: 'rgba(3, 23, 44, 0.8)',
              formatter: (params) => TooltipFormatter(params)
            },
            legend: {
              orient: 'vertical',
              left: '30%',
              top: '20%',
              textStyle: {
                color: '#606266',
                fontSize: 14
              },
              icon: 'circle',
              itemWidth: 8,
              itemHeight: 8,
              itemGap: 20,
              data: pieList.map(item => item.modelName),
              formatter: name => LegendFormatter(name, pieList)
            },
            
            series: [
              // 外面的环
              {
                type: 'pie',
                silent: true, // 放在图形上无动效
                center: ['15%', '56%'],
                radius: ['50%', '64%'],
                startAngle: 45,
                label: {
                  show: false
                },
                labelLine: {
                  show: false
                },
                data: [{
                  value: 1,
                  itemStyle: {
                    color: '#F3F7FF'
                  }
                }]
              },
              {
                type: 'pie',
                center: ['15%', '56%'],
                radius: ['50%', '58%'],
                startAngle: 45,
                label: {
                    show: false
                },
                labelLine: {
                    show: false
                },
                data: setPieInterval(pieList),
              }
            ]
          }}
        />
      </div>
    </Spin>
  )
}

// 图表浮窗
const TooltipFormatter = (params: any) => {
  return (
    `<div>
      <span style="font-size: 12;color: #FFFFFF">${params?.name}</span>
      <span style="font-size: 12;color: #FFFFFF;margin-left: 12px;">${params?.value}台</span>
      <span style="font-size: 12;color: #FFFFFF;margin-left: 12px;">${params?.data?.rate || 0}%</span>
    </div>`
  )
}

// 图表图例
const LegendFormatter = (param: any, dataSource: any) => {
  const { modelName = '-', rate = 0 } = dataSource.find(i => i.modelName === param) || {}
  return `${modelName} ${rate}%`
}

export default EquipmentTypePie
