import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import React, { useMemo, useState } from 'react'
import { Breadcrumb, Button, Dropdown, Layout, Menu, Modal, Row, Space } from 'antd'
import Logo from '../../Layout/components/Logo'
import './work-order.less'
import ProfilePhoto from '../../Layout/components/profile-photo'
import { CaretDownOutlined, LoginOutlined } from '@ant-design/icons'
import { CustomIcons, Global } from '../../global'
import { token } from '../../utils/utils'

const { Header, Content } = Layout

export const backButtonSvg = (
  <svg width='50px' height='50px' viewBox='0 0 50 50' version='1.1' xmlns='http://www.w3.org/2000/svg'
       xmlnsXlink='http://www.w3.org/1999/xlink'>
    <defs>
      <circle id='path-1' cx='18' cy='18' r='18' />
      <filter x='-29.2%' y='-29.2%' width='158.3%' height='158.3%' filterUnits='objectBoundingBox' id='filter-2'>
        <feMorphology radius='1' operator='erode' in='SourceAlpha' result='shadowSpreadOuter1' />
        <feOffset dx='0' dy='0' in='shadowSpreadOuter1' result='shadowOffsetOuter1' />
        <feGaussianBlur stdDeviation='4.5' in='shadowOffsetOuter1' result='shadowBlurOuter1' />
        <feColorMatrix values='0 0 0 0 0.745098039   0 0 0 0 0.760784314   0 0 0 0 0.8  0 0 0 1 0' type='matrix'
                       in='shadowBlurOuter1' />
      </filter>
    </defs>
    <g id='页面-1' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
      <g id='创建工单' transform='translate(-40.000000, -137.000000)'>
        <rect fill='#F3F4F5' x='0' y='0' width='1440' height='900' />
        <rect id='矩形' fill='#FFFFFF' x='24' y='124' width='1392' height='726' rx='4' />
        <g id='编组-12' transform='translate(24.000000, 124.000000)'>
          <rect id='矩形' x='0' y='0' width='1176' height='76' />
          <g id='编组-8' transform='translate(23.000000, 20.000000)'>
            <g id='椭圆形'>
              <use fill='black' fillOpacity='1' filter='url(#filter-2)' xlinkHref='#path-1' />
              <use fill='#FFFFFF' fillRule='evenodd' xlinkHref='#path-1' />
            </g>
            <g id='编组' transform='translate(9.000000, 9.000000)' fill='#1CB887' fillRule='nonzero'>
              <path
                d='M16.5156233,7.3383872 L5.04881349,7.3383872 L9.85242135,2.53477934 C10.4338022,1.95339848 10.4338022,1.01329325 9.85242135,0.436035649 C9.27104048,-0.145345216 8.33093525,-0.145345216 7.75367765,0.436035649 L0.43487598,7.75071406 C0.125630839,8.0599592 -0.0186835606,8.46816278 0.00193278213,8.8722431 C-0.0186835606,9.27632342 0.125630839,9.68452701 0.43487598,9.99377215 L7.75367765,17.3166971 C8.04230645,17.6053259 8.42164716,17.7496403 8.80511113,17.7496403 C9.18857511,17.7496403 9.56379255,17.6053259 9.85654462,17.3166971 C10.4379255,16.7353162 10.4379255,15.795211 9.85654462,15.2179534 L4.94985504,10.3112638 L16.5156233,10.3112638 C17.3361538,10.3112638 18,9.64741759 18,8.82688715 C18,8.00223344 17.3361538,7.3383872 16.5156233,7.3383872 Z'
                id='路径' />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>)

const userLocation = location

export const WorkOrderHome = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const path = location.pathname.replace('/work-order/', '').replace('/', '')

  // 退出登录
  const confirmLoginOut = () => {
    Modal.confirm({
      keyboard: false,
      title: '退出登录',
      icon: <CustomIcons type='klf-alarm1' />,
      content: '确定退出?',
      okText: '确定',
      cancelText: '取消',
      onOk: () => {
        token.clear()
        Global.user = {}

        userLocation.assign('#/login/index')
      }
    })
  }

  const userDropMenu = (
    <Menu>
      <Menu.Item key='logout' onClick={confirmLoginOut} icon={<LoginOutlined />}>
        退出登录
      </Menu.Item>
    </Menu>
  )

  const [user, setUser] = useState(Global.user)

  useMemo(() => {
    setUser(Global.user)
  }, [Global.user])

  return <div className="work-order">
    <Layout>
      <Header style={{ position: 'fixed', zIndex: 1, width: '100%', justifyContent: 'space-between', alignItems: 'center', }}>
        <Row style={{
          alignItems: 'center',
        }}>
          <Logo
            text={
              <img src={`./logo-text.svg`} style={{ height: 45 }} alt='logo-text' />
            }
          />
          <div className='work-order-title'>我的工单</div>
        </Row>
        <Row style={{
          alignItems: 'center',
        }}>
          <Button type="text" onClick={() => {
            window.open('./', '_blank');
          }}>返回主页</Button>
          <Dropdown overlay={userDropMenu}>
            <Space size={5}>
              <ProfilePhoto user={user} url={user.headUrl} name={user.email} size={24} {...user} />
              {user?.userName || ''}
              <CaretDownOutlined />
            </Space>
          </Dropdown>
        </Row>
      </Header>
      <Content className='site-layout' style={{ padding: '0 50px', marginTop: 64 }}>
        {
          path !== 'list' && <Breadcrumb style={{ margin: '16px 0' }}>
            <Breadcrumb.Item><Button className='custom-button' type='text' onClick={() => {
              navigate('/work-order')
            }}>我的工单</Button></Breadcrumb.Item>
            {
              path.includes('detail') && <Breadcrumb.Item>工单详情</Breadcrumb.Item>
            }
            {
              path === 'create' && <Breadcrumb.Item>创建工单</Breadcrumb.Item>
            }
          </Breadcrumb>
        }
        <div className='site-layout-background' style={{
          padding: 0,
          marginTop: path === 'list' ? '1rem' : 0
        }}>
          <Outlet />
        </div>
      </Content>
    </Layout>
  </div>
}
