import React, { useEffect, useRef, useState } from 'react'
import { getAMap } from '../account-detail/components/map-script'
import { ResourceService } from '../../apis/project-service-history'
import { Spin } from 'antd'
import { parseNumber } from '../resource-reckon/resource-map'
// @ts-ignore
import mapboxgl  from '!mapbox-gl';

const mapbox = true;

export const ResourceContent = (props: any) => {
  const mapRef = useRef<any>(null)
  const [data, setData] = useState()
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    if (mapRef.current) {
      const id = props.data.id
      ResourceService.info({
        id,
      }).then((resp) => {
        setData(resp.data)
        setLoading(false)
        createMap(
          mapbox ? resp.data : resp.data.geometry.map((oo: any) => {
            const key = Object.keys(oo)[0]
            const val = oo[key]
            return [key, val].map(parseFloat)
          })
        )
      })
    }
  }, [mapRef])

  const createMap = (data: number[][] | any) => {
    console.log(data);
    if(mapbox) {
      const {lng, lat} = data;
      mapboxgl.accessToken = 'pk.eyJ1Ijoib3dvcHMiLCJhIjoiY2wxeHBieXhxMDUxYjNicWZua2R5a3RqayJ9.yjsA1-1sWYHfY7Ea5IeArg'
      const point = [parseFloat(lng), parseFloat(lat)];
      const map = new mapboxgl.Map({
        style: 'mapbox://styles/owops/cl2h1u3kx000u15o26t9bmtyh',
        center: point,
        zoom: 16,
        minZoom: 1,
        maxZoom: 20,
        container: 'source-map',
        // interactive: false,
        projection: 'equirectangular' // starting projection
      } as any)

      map.on('load', () => {

        map.addSource('satelite', {
          type: 'raster',
          maxzoom: 20,
          tiles: ['http://114.217.8.57:8888/static/v1.0.0/klfmbt/{z}/{x}/{y}.png']
        })

        map.addLayer(
          {
            id: 'stLayer',
            type: 'raster',
            maxzoom: 20,
            source: 'satelite',
            'source-layer': 'default',
            paint: {
              'raster-opacity': 1
            }
          },
          'hillshade'
        )

        map.addSource('roof', {
          type: 'vector',
          scheme: 'xyz',
          minzoom: 1,
          maxzoom: 13,
          tiles: [
            // https://dev-tile.terraqt.com/tile/xyz/{z}/{x}/{y}.pbf?path=f7a3c99d-4306-404a-ad33-5f1099992871/cor10403/20191201/CN-540000/vectortiles
            `https://dev-tile.terraqt.com/tile/xyz/{z}/{x}/{y}.pbf?path=f7a3c99d-4306-404a-ad33-5f1099992871/cor10403/20191201/CN-${data.provinceCode}/vectortiles`
          ]
        })

        map.addLayer({
          id: 'roofLayer',
          type: 'fill',
          source: 'roof',
          'source-layer': 'default',
          paint: {
            'fill-color': 'rgba(0, 255, 0, 0.6)',
            'fill-opacity': 0.6,
            'fill-outline-color': 'rgba(0, 255, 0, 1)'
          },
          filter: [
            'all', [
              'in', ['get', 'center_x'], data.lng
            ], [
              'in', ['get', 'center_y'], data.lat
            ]
          ]
        })

        const popup = new mapboxgl.Popup({
          offset: [0, -40],
          closeButton: false
        });
        new mapboxgl.Marker({offset: [0, -15]})
          .setLngLat(point)
          .addTo(map);
        const txt = data.name;

        popup.setLngLat(point)
          .setText(txt)
          .addTo(map);
      })

    } else {
      getAMap().then((AMap) => {
        const map = new AMap.Map('source-map', {
          resizeEnable: true,
          center: [116.418261, 39.921984],
          zoom: 4,
          mapStyle: 'amap://styles/05e321edfe9e69b5902e4dc372c36fda',
        })

        const _polygon = new AMap.Polygon({
          path: data,
          fillColor: '#0B7656',
          strokeOpacity: 1,
          fillOpacity: 0.5,
          strokeColor: '#05A573',
          bubble: true,
          strokeWeight: 2,
          zIndex: 9999,
          extData: {
            name: 'roof',
          },
        })

        _polygon.setPath(data)

        map.add(_polygon)
        map.setFitView(_polygon)
      })
    }
  }
  return (
    <div>
      <Spin spinning={loading}>
        <div
          ref={mapRef}
          id="source-map"
          style={{
            width: '100%',
            height: 400,
          }}
        />
        {data && (
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              flexWrap: 'wrap',
            }}
          >
            {[
              {
                key: 'alias',
                label: '资源名称',
                unit: '',
              },
              {
                key: 'type',
                label: '资源类型',
                formatter: () => '屋顶',
                unit: '',
              },
              {
                key: 'name',
                label: '默认名称',
                unit: '',
              },
              {
                key: 'area',
                label: '屋顶面积',
                formatter: (val: any) => {
                  return parseNumber(val, {
                    radix: 2,
                    type: 'e',
                  })
                },
                unit: '㎡',
              },
              {
                key: 'yearTotalIrradiance',
                label: '年总辐照量',
                unit: 'kWh',
              },
            ].map((o, idx) => (
              <div
                key={idx}
                style={{
                  width: '33%',
                  display: 'flex',
                  flexDirection: 'row',
                  flexWrap: 'nowrap',
                  marginTop: 18,
                }}
              >
                <span
                  style={{
                    fontSize: 14,
                    color: '#262E3F',
                    minWidth: 75,
                  }}
                >
                  {o.label}：
                </span>
                <span
                  style={{
                    fontSize: 14,
                    color: '#767F95',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                  }}
                >
                  {o.formatter ? o.formatter(data[o.key]) : data[o.key]} {o.unit}
                </span>
              </div>
            ))}
          </div>
        )}
      </Spin>
    </div>
  )
}
