import { Form } from 'antd'
import { set } from 'lodash'
import React from 'react'
import { CommonService, ResourceService } from '../../../../apis/wl-service'
import { StateContext, SubmitType } from '../../../engineering-manage/project-library-create/components/StateContext'

import { generatorSubmit, ISubmitOptions } from './generatorSubmit'

// 设置表格搜索条件
export type ParamsType = {
  shareable: boolean
  detailsApi: (projectId?: string) => Promise<any>
  formatter: (values: any, type: 'params' | 'component') => any
  key: 'production' | 'cost' | 'investPlan' | 'planExecute'
  submitOptions: ISubmitOptions
  mergeResponse: (data: any, detailsData?: any) => any
}

export const useProject = ({ shareable, detailsApi, formatter, submitOptions, key, mergeResponse }: ParamsType) => {
  const unmountRef = React.useRef<boolean>(false)
  const ref = React.useRef<any>({})
  const { state, dispatch } = React.useContext<any>(StateContext)
  const [loading, setLoading] = React.useState<boolean>(false) // 页面 loading 状态
  const [form] = Form.useForm()
  const [formData, setFormData] = React.useState<any>({})
  const [projectIdOptions, setProjectIdOptions] = React.useState<any[]>([]) // 项目ID

  // 设置组件挂载状态
  React.useEffect(() => {
    unmountRef.current = false
    return () => {
      unmountRef.current = true
    }
  }, [])

  // 获取基础数据
  React.useEffect(() => {
    // 新建时从缓存中读取数据
    if (!shareable || state?.projectTypeOptions?.length === 0) {
      CommonService.getDictionaryDataSelector('15301742e5ec4e92843192cb76010e08')
        .then((data: any) => {
          const projectTypeOptions = data?.data?.list ?? []
          if (shareable) {
            dispatch({ type: 'setProjectTypeOptions', payload: projectTypeOptions })
          } else {
            ref.current.projectTypeOptions = projectTypeOptions
          }
        })
        .catch((error: any) => {
          console.warn('项目类型获取失败：', error?.message)
        })
    }
  }, [shareable, state?.projectTypeOptions?.length])

  React.useEffect(() => {
    // 项目列表
    ResourceService.developList()
      .then((res: any) => {
        !unmountRef.current &&
          setProjectIdOptions(
            (res?.data ?? []).map((item: any) => {
              return {
                id: item.mark?.toString(),
                fullName: item.projectName,
                projectId: item.id ?? '',
                projectName: item.projectName ?? '',
              }
            })
          )
      })
      .catch((e) => {
        console.warn('获取项目列表失败：', e)
      })
  }, [])

  React.useEffect(() => {
    if (projectIdOptions?.length > 0) {
      addProjectCallback(getProject())
    }
  }, [projectIdOptions])

  const addProjectCallback = (defaultProject) => {
    if (defaultProject?.mark?.startsWith('_mark')) {
      const current = projectIdOptions.find((v: any) => v.projectName === defaultProject.project_name)
      if (current) {
        defaultProject = {
          mark: current.id ?? '',
          project_id: current.projectId ?? '',
          project_name: current.projectName ?? '',
          projectModel: {
            name: '',
            address: '',
            cityCode: '',
            latitude: '',
            longitude: '',
          },
        }
        const currentProject = { ...(state.currentProject ?? {}), [key]: defaultProject }
        currentProject.order = [...(currentProject.order?.filter((k: string) => k !== key) ?? []), key]
        dispatch({ type: 'setCurrentProject', payload: currentProject })
      }
    }
  }

  const getProject = (defaultProject?: any) => {
    if (!shareable) {
      return {}
    }
    if (state.currentProject[key]) {
      defaultProject = state.currentProject[key]
    } else if (state.currentProject?.order?.length > 0) {
      for (let i = state.currentProject.order.length - 1; i >= 0; i--) {
        defaultProject = state.currentProject[state.currentProject.order[i]]
        if (defaultProject) {
          const currentProject = { ...(state.currentProject ?? {}), [key]: defaultProject }
          currentProject.order = [...(currentProject.order?.filter((k: string) => k !== key) ?? []), key]
          dispatch({ type: 'setCurrentProject', payload: currentProject })
          break
        }
      }
    }

    addProjectCallback(defaultProject)

    return defaultProject
  }

  // 设置、存储表单数据
  const setValues = (values: any, defaultProject?: any) => {
    if (unmountRef.current) return

    try {
      values = { ...values, ...getProject(defaultProject) }
      form.resetFields()
      form.setFieldsValue(formatter(values, 'component'))
      !unmountRef.current && setFormData(values)
    } catch (e: any) {
      console.warn(e)
    }
  }

  // 根据projectId获取详情
  const getDetails = async (projectId?: string) => {
    if (unmountRef.current) return

    // 选中的项目只有mark，无id时不调用接口
    if (projectId === '') {
      setValues({})
      return
    }

    try {
      setLoading(true)

      let resp: any = await detailsApi(projectId)
      const requestId = resp?.data?.project_id ?? projectId
      // 获取绑定项目
      if (requestId) {
        let project = await ResourceService.getAllInfo({ projectId: requestId })
        project = project?.data

        set(resp, ['data'], {
          ...(resp?.data ?? {}),
          ...(mergeResponse(project ?? {}, resp?.data ?? {}) ?? {}),
          mark: project?.resourceList?.mark,
        })
      }

      setValues(resp?.data ?? { project_id: projectId })
    } catch (e) {
      console.warn(`获取${key}详情失败`, e)
    } finally {
      setLoading(false)
    }
  }

  return [
    {
      form,
      formData,
      getDetails,
      loading,
      options: {
        projectTypeOptions: shareable ? state.projectTypeOptions : ref.current.projectTypeOptions,
        projectIdOptions,
        setProjectIdOptions: (options: any[]) => {
          const [newProject = {}] = options

          const currentProject = {
            ...state.currentProject,
            [key]: {
              mark: newProject.id ?? '',
              project_id: '',
              project_name: newProject.name ?? '',
              projectModel: {
                name: newProject.name ?? '',
                address: newProject.address ?? '',
                cityCode: newProject.cityCode ?? '',
                latitude: newProject.latitude ?? '',
                longitude: newProject.longitude ?? '',
              },
            },
          }
          currentProject.order = [...(currentProject.order?.filter((k: string) => k !== key) ?? []), key]
          dispatch({ type: 'setCurrentProject', payload: currentProject })
          setValues({}, currentProject[key])

          !unmountRef.current && setProjectIdOptions(options)

          const formDispatch = (form as any).getInternalHooks('RC_FORM_INTERNAL_HOOKS').dispatch
          formDispatch({ type: 'updateValue', namePath: ['mark'], value: newProject.id ?? '' })
        },
      },
      projectId: getProject()?.project_id,
      onValuesChange: (changedValues: any, allValues: any) => {
        // 项目ID变化
        if ('mark' in changedValues && Object.keys(changedValues).length === 1) {
          const currentProject = {
            ...state.currentProject,
            [key]: {
              mark: allValues.mark ?? '',
              project_id: allValues.project_id ?? '',
              project_name: allValues.project_name ?? '',
              projectModel: {
                name: allValues.projectModel.name ?? '',
                address: allValues.projectModel.address ?? '',
                cityCode: allValues.projectModel.cityCode ?? '',
                latitude: allValues.projectModel.latitude ?? '',
                longitude: allValues.projectModel.longitude ?? '',
              },
            },
          }
          currentProject.order = [...(currentProject.order?.filter((k: string) => k !== key) ?? []), key]
          dispatch({ type: 'setCurrentProject', payload: currentProject })

          if (!allValues.project_id) {
            setValues({}, currentProject[key])
          }
        }
      },
      imperativeHandle: () => ({
        getName: () => key,
        submit: async (options: SubmitType) => {
          try {
            let values: any = await form.validateFields()
            values = formatter(values, 'params')
            generatorSubmit(submitOptions)?.({
              ...options,
              values,
              success: () => {
                if (!values.project_id) {
                  ResourceService.developList()
                    .then((res: any) => {
                      const newProject = res?.data?.find((v: any) => v.projectName === values.project_name)
                      const currentProject = {
                        ...state.currentProject,
                        [key]: {
                          ...state.currentProject?.[key],
                          mark: newProject?.mark?.toString() ?? '',
                          project_id: newProject?.id ?? '',
                          project_name: newProject?.projectName ?? '',
                        },
                      }
                      currentProject.order = [...(currentProject.order?.filter((k: string) => k !== key) ?? []), key]
                      dispatch({ type: 'setCurrentProject', payload: currentProject })

                      options?.success?.()
                    })
                    .catch((e) => {
                      console.warn('获取项目列表失败：', e)
                    })
                } else {
                  options?.success?.()
                }
              },
            })
          } catch (err) {
            console.warn('submit form Validate Failed:', err)
          }
        },
      }),
    },
  ]
}

export default useProject
