import React, { useEffect, useState, useImperativeHandle } from 'react'
import { Card, Typography, Space, Button, FormInstance, Form, Row, Col, Select, Input, InputNumber, DatePicker, Radio, message } from 'antd'
import { EnvironmentFilled, MinusCircleOutlined, PlusOutlined } from '@ant-design/icons'
import { useNavigate, useParams } from 'react-router-dom'
import { GaodeMap, Scene } from '@antv/l7'
import { BreadcrumbObservable, SideMenuObservable } from '../../../application'
import { AddressSelector } from '../../../components/address-selector'
import { getAMap } from '../../account-detail/components/map-script'
import { PHONE_EXP } from '../../../utils/constant'
import { ProjectService } from '../../../apis/project-service-stash'
import UploadFile from './components/upload'
import { LogisticsService, CommonService } from '../../../apis/engineering-service'
import _ from 'lodash'
import moment from 'moment'

import './index.less'

export interface ContractFormInstance extends FormInstance {
  reset: () => void // 重置表单数据
  submit: (cb?: () => void) => void // 提交表单
}

export interface ContractFormProps {
  formRef: any
  consigneeRes?: any
  consigneeId?: string
  manageId?: any
}

// @ts-ignore
import mapboxgl from '!mapbox-gl'

const mbxDeliver = true; // 发货人地图集成状态
const mbxConsignee = true; // 收货人地图集成状态
let lastMarker;
let lastMarkerConsignee;

const ContractForm: React.FC<ContractFormProps> = ({ formRef, consigneeRes, consigneeId, manageId }) => {
  const [form] = Form.useForm()
  const { TextArea } = Input;
  const [submiting, setSubmiting] = React.useState<boolean>(false) // 表单提交状态
  const [map, setMap] = React.useState<any>()
  const [consigneeMap, setConsigneeMap] = React.useState<any>()
  const [, setVer] = React.useState(0)

  const [isContract, setIsContract] = useState(true) // 是否选了 项目名称

  const [isShowUploadPdf, setIsShowUploadPdf] = useState(false) // 是否有上传随货清单

  const [dataSource, setDataSource] = useState<any>({}) // 编辑时 详情数据

  const [addressList, setAddressList] = React.useState<any[]>([])

  const [addressConsigneeList, setAddressConsigneeList] = React.useState<any[]>([])

  

  // 下拉数据
  const [options, setOptions] = React.useState<{ [name: string]: any }>({
    projectListOptions: [], // 项目名称 下拉数据
    contractOptions: [] //  合同名称、供应商、业务机构 下拉数据
  })

  // 验证手机状态
  const [checkPhone, setCheckPhone] = useState<any>({
    validateStatus: ' ',
    help: '',
  })

  // 发货人 地图集成
  const createDeliverAMap = () => {
    getAMap().then((AMap) => {
      const lnglat = [116.418261, 39.921984]
      const zoom = 3
      const _map = new AMap.Map('logistics-deliver-map', {
        resizeEnable: true,
        center: lnglat,
        zoom,
        mapStyle: 'amap://styles/05e321edfe9e69b5902e4dc372c36fda',
      })

      new Scene({
        id: 'logistics-deliver-map',
        map: new GaodeMap({
          mapInstance: _map,
        }),
      })

      setMap(_map)
    })
  }
  const createDeliverMapbox = () => {
    lastMarker = undefined;
    const map = new mapboxgl.Map({
      style: 'mapbox://styles/owops/cl2h1u3kx000u15o26t9bmtyh',
      center: [116.418261, 39.921984],
      zoom: 2,
      minZoom: 1,
      maxZoom: 20,
      container: 'logistics-deliver-map',
      interactive: false,
      projection: 'equirectangular' // starting projection
    } as any)

    setMap(map);
  }

  // 收货人 地图集成
  const createConsigneeAMap = () => {
    getAMap().then((AMap) => {
      const lnglat = [116.418261, 39.921984]
      const zoom = 3
      const _map = new AMap.Map('logistics-consignee-map', {
        resizeEnable: true,
        center: lnglat,
        zoom,
        mapStyle: 'amap://styles/05e321edfe9e69b5902e4dc372c36fda',
      })

      new Scene({
        id: 'logistics-consignee-map',
        map: new GaodeMap({
          mapInstance: _map,
        }),
      })

      setMap(_map)
    })
  }
  const createConsigneeMapbox = () => {
    lastMarkerConsignee = undefined;
    const map = new mapboxgl.Map({
      style: 'mapbox://styles/owops/cl2h1u3kx000u15o26t9bmtyh',
      center: [116.418261, 39.921984],
      zoom: 2,
      minZoom: 1,
      maxZoom: 20,
      container: 'logistics-consignee-map',
      interactive: false,
      projection: 'equirectangular' // starting projection
    } as any)

    setConsigneeMap(map);
  }

  /**
    * [isEmpty 判断对象对否为空]
    * @param {Object} target
    * @return {Boolean} true - 空对象, false - 非空对象
    */
   const isJSON_test = (str) => {
    if (typeof str === 'string') {
      try {
        var obj = JSON.parse(str)
        console.log('转换成功：' + obj)
        return true
      } catch (e) {
        console.log('error：' + str + '!!!' + e)
        return false
      }
    }
  }

  // 提交验证
  useImperativeHandle(formRef, () => ({
    ...form,
    reset: () => form.resetFields(),
    submit: async (cb?: () => void) => {
      if (submiting) return
      try {
        let params = await form.validateFields()
        params.predictArriveTime = params.predictArriveTime ? params.predictArriveTime.format('YYYY-MM-DD') : ''
        if (params.details) {
          params.details.map((item) => {
            item['projectCode'] = params.projectCode
          })
        }
        try {
          setSubmiting(true)
          const rootObj = {
            ...params
          }
          // 编辑时添加ID
          if (manageId && manageId !== 'create') {
            rootObj['manageId'] = manageId
            const { shipmentsLocationObj, consigneeLocationObj } = form.getFieldsValue()
            console.log(shipmentsLocationObj, consigneeLocationObj, dataSource, '===getFieldsValue')
            if (isJSON_test(shipmentsLocationObj)) {
              const shipmentsObj = JSON.parse(shipmentsLocationObj)
              rootObj['shipmentsLocationObj'] = shipmentsObj
              rootObj['shipmentsDetailAddress'] = shipmentsObj?.address
              rootObj['shipmentsAddress'] = shipmentsObj?.name
            } else {
              rootObj['shipmentsLocationObj'] = dataSource?.shipmentsLocationObj
              rootObj['shipmentsDetailAddress'] = dataSource?.shipmentsLocationObj?.address
              rootObj['shipmentsAddress'] = dataSource?.shipmentsLocationObj?.name
            }
            // 收获地址
            if (isJSON_test(consigneeLocationObj)) {
              const consigneeObj = JSON.parse(consigneeLocationObj)
              rootObj['consigneeLocationObj'] = consigneeObj
              rootObj['consigneeDetailAddress'] = consigneeObj?.address
              rootObj['consigneeAddress'] = consigneeObj?.name
            } else {
              rootObj['consigneeLocationObj'] = dataSource?.consigneeLocationObj
              rootObj['consigneeDetailAddress'] = dataSource?.consigneeLocationObj?.address
              rootObj['consigneeAddress'] = dataSource?.consigneeLocationObj?.name
            }
          } else {
            // 发货地址
            const { shipmentsLocationObj, consigneeLocationObj } = form.getFieldsValue()
            if (shipmentsLocationObj) {
              const shipmentsObj = JSON.parse(shipmentsLocationObj)
              rootObj['shipmentsLocationObj'] = shipmentsObj
              rootObj['shipmentsDetailAddress'] = shipmentsObj?.address || ''
              rootObj['shipmentsAddress'] = shipmentsObj?.name || ''
            }
            // 收获地址
            if (consigneeLocationObj) {
              const consigneeObj = JSON.parse(consigneeLocationObj)
              rootObj['consigneeLocationObj'] = consigneeObj
              rootObj['consigneeDetailAddress'] = consigneeObj?.address || ''
              rootObj['consigneeAddress'] = consigneeObj?.name || ''
            }
          }

          // 随货清单
          if (form.getFieldValue('isCargoList')) {
            const { cargoListUrlList } = form.getFieldsValue()
            rootObj['cargoListUrlList'] = cargoListUrlList?.map((v: any) => { return {name: v.name, url: v.url}})
          }

          const resp: any = await LogisticsService.create({ root: rootObj })
          if (resp?.code === 200) {
            message.success(manageId && manageId !== 'create' ? '编辑运单成功' : '新建运单成功')
            cb?.()
          } else if (resp?.msg) {
            message.error(resp.msg)
          }
        } catch (err: any) {
          console.warn(err)
        } finally {
          setSubmiting(false)
        }
      } catch (e) {
        console.warn(e)
      }
    },
  }))

  // 获取基础下拉数据
  useEffect(() => {
    // 获取项目名称 下拉数据
    CommonService.getProjectDataSelector()
      .then((res: any) => {
        setOptions((previous) => ({ ...previous, projectListOptions: res?.data || [] }))
      })
      .catch(() => {})
      
    // 编辑 获取详情数据 回显
    if (manageId && manageId !== 'create') {
      // 获取详情数据
      LogisticsService.getDetail(manageId)
        .then((res: any) => {
          if (res?.data) {
            setDataSource(_.cloneDeep(res?.data))
            // 地址回显
            ProjectService.inputTips({ cityCode: res?.data?.shipmentsLocationObj.adcode, address: res?.data?.shipmentsLocationObj.address }).then((resp: any) => {
              setAddressList(resp.data)
            })
            ProjectService.inputTips({ cityCode: res?.data?.consigneeLocationObj.adcode, address: res?.data?.consigneeLocationObj.address }).then((resp: any) => {
              setAddressConsigneeList(resp.data)
            })
            if (map && manageId) {
              if(!mbxDeliver) {
                getAMap().then((AMap: any) => {
                  const lngLat = new AMap.LngLat(res?.data?.shipmentsLocationObj.location)
                  map.setZoom(16)
                  map.setCenter(lngLat)
                  map.clearMap()
        
                  const marker = new AMap.Marker({
                    position: lngLat,
                    title: res?.data?.shipmentsLocationObj.address,
                  })
                  map.add(marker)
                })
              } else {
                map.setZoom(13)
                map.setCenter([res?.data?.shipmentsLocationObj.location + 0.003]);
                const popup = new mapboxgl.Popup({
                  offset: [0, -40],
                  closeButton: false
                });
                new mapboxgl.Marker({offset: [0, -15]})
                  .setLngLat([res?.data?.shipmentsLocationObj.location])
                  .addTo(map);
                const txt = res?.data?.address;
        
                popup.setLngLat([res?.data?.shipmentsLocationObj.location])
                  .setText(txt)
                  .addTo(map);
              }
            }
        
            if (consigneeMap && manageId) {
              if(!mbxConsignee) {
                getAMap().then((AMap: any) => {
                  const lngLat = new AMap.LngLat(res?.data?.consigneeLocationObj.location)
                  consigneeMap.setZoom(16)
                  consigneeMap.setCenter(lngLat)
                  consigneeMap.clearMap()
        
                  const marker = new AMap.Marker({
                    position: lngLat,
                    title: res?.data?.consigneeLocationObj.address,
                  })
                  consigneeMap.add(marker)
                })
              } else {
                consigneeMap.setZoom(13)
                consigneeMap.setCenter([res?.data?.consigneeLocationObj.location + 0.003]);
                const popup = new mapboxgl.Popup({
                  offset: [0, -40],
                  closeButton: false
                });
                new mapboxgl.Marker({offset: [0, -15]})
                  .setLngLat([res?.data?.consigneeLocationObj.location])
                  .addTo(consigneeMap);
                const txt = res?.data?.consigneeLocationObj.address;
        
                popup.setLngLat([res?.data?.consigneeLocationObj.location])
                  .setText(txt)
                  .addTo(consigneeMap);
              }
            }
            setTimeout(() => {
              form.setFieldsValue(formater(res?.data || {}))
            }, 300)
          }
        })
        .catch(() => {})
    }
  }, [])

  // 数据转换
  const formater = React.useCallback((values: any) => {
    setIsShowUploadPdf(values.isCargoList)
    values.predictArriveTime = values.realityArriveTime ? moment(values.realityArriveTime, 'YYYY-MM-DD') : null
    JSON.stringify(dataSource?.shipmentsLocationObj)
    JSON.stringify(dataSource?.consigneeLocationObj)
    values.shipmentsAddress = values.shipmentsLocationObj?.adcode || ''
    values.shipmentsLocationObj = values.shipmentsLocationObj?.name || ''
    values.consigneeAddress = values.consigneeLocationObj?.adcode || ''
    values.consigneeLocationObj = values.consigneeLocationObj?.name || ''
    values.cargoListUrlList = values?.cargoList || []
    return values
  }, [])

  React.useEffect(() => {
    if (map && consigneeId) {
      if(!mbxConsignee) {
        getAMap().then((AMap: any) => {
          const lngLat = new AMap.LngLat(consigneeRes.lng, consigneeRes.lat)
          map.setZoom(16)
          map.setCenter(lngLat)
          map.clearMap()

          const marker = new AMap.Marker({
            position: lngLat,
            title: consigneeRes.address,
          })
          map.add(marker)
        })
      } else {
        map.setZoom(13)
        map.setCenter([consigneeRes.lng, + consigneeRes.lat + 0.003]);
        const popup = new mapboxgl.Popup({
          offset: [0, -40],
          closeButton: false
        });
        new mapboxgl.Marker({offset: [0, -15]})
          .setLngLat([consigneeRes.lng, consigneeRes.lat])
          .addTo(map);
        const txt = consigneeRes.address;

        popup.setLngLat([consigneeRes.lng, consigneeRes.lat])
          .setText(txt)
          .addTo(map);
      }
    }
  }, [consigneeMap, consigneeId])

  React.useEffect(() => {
    if (consigneeRes) {
      form.setFieldsValue({ ...consigneeRes })
      setVer((ver) => ver + 1)
    }
  }, [consigneeRes])

  React.useEffect(() => {
    if(!mbxDeliver) {
      createDeliverAMap();
    } else {
      createDeliverMapbox();
    }
    if(!mbxConsignee) {
      createConsigneeAMap();
    } else {
      createConsigneeMapbox();
    }
  }, [])

  // 布局属性
  const layout = React.useMemo(
    () => ({
      col: { xs: 12, md: 8 },
      col12: { xs: 12, md: 12 },
      col24: { xs: 24 },
      span8: { labelCol: { xs: 24, md: 9 }, wrapperCol: { xs: 24, md: 14 } },
      span24: { labelCol: { xs: 24, md: 3 }, wrapperCol: { xs: 24, md: 21 } },
      span24NoLabel: { wrapperCol: { offset: 3, xs: 24, md: 21 } },
      select: {
        allowClear: true,
        dropdownMatchSelectWidth: true,
        placeholder: '请选择',
        showSearch: true,
        optionFilterProp: 'children',
        filterOption: (input: string, option: any) => {
          return (option!.children as unknown as string).toLowerCase().includes(input.toLowerCase())
        },
      },
    }),
    []
  )

  const onSearch = (val: string) => {
    ProjectService.inputTips({ cityCode: form.getFieldValue('shipmentsAddress') || '', address: val }).then((resp: any) => {
      setAddressList(resp.data)
    })
  }

  const onConsigneeSearch = (val: string) => {
    ProjectService.inputTips({ cityCode: form.getFieldValue('consigneeAddress') || '', address: val }).then((resp: any) => {
      setAddressConsigneeList(resp.data)
    })
  }

  return (
    <Form form={form} name="contract-form" className="wl-form-item" layout="horizontal" labelWrap  style={{marginTop: 30, paddingRight: 50}}>
      <Row gutter={24}>
        <Col {...layout.col}>
          <Form.Item
            name="projectName"
            label="项目名称"
            {...layout.span8}
            rules={[{ required: true, message: '请填写项目名称' }]}
          >
            <Select
              {...layout.select}
              onChange={(value: any) => {
                form.setFieldsValue({
                  projectCode: options?.projectListOptions[value].projectNo,
                  projectName: options?.projectListOptions[value].projectName
                })
                // 获取 合同名称、供应商、业务机构 下拉数据
                if (value) {
                  setIsContract(false)
                } else {
                  setIsContract(true)
                }
                CommonService.getContractData(options?.projectListOptions[value].projectNo || '')
                .then((res: any) => {
                  console.log(res, '获取 合同名称、供应商、业务机构 下拉数据')
                  
                  setOptions((previous) => ({ ...previous, contractOptions: res?.data || [] }))
                })
                .catch(() => {})
              }}
            >
              {options?.projectListOptions?.map((o: any, index: number) => {
                return <Select.Option key={index}>{o.projectName}</Select.Option>
              })}
            </Select>
          </Form.Item>
        </Col>
        <Col {...layout.col}>
          <Form.Item
            name="projectCode"
            label="项目代码"
            {...layout.span8}
          >
            <Input allowClear disabled placeholder="请输入" />
          </Form.Item>
        </Col>
        <Col {...layout.col}>
          <Form.Item
            name="projectAddress"
            label="项目地址"
            {...layout.span8}
          >
            <Input allowClear placeholder="请输入" suffix={<EnvironmentFilled style={{color: '#1ec692'}} />} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col {...layout.col}>
          <Form.Item
            name="name1"
            label="订单编号"
            {...layout.span8}
          >
            <Input allowClear disabled placeholder="请输入" />
          </Form.Item>
        </Col>
        <Col {...layout.col}>
          <Form.Item
            name="contractName"
            label="合同名称"
            {...layout.span8}
            rules={[{ required: true, message: '请填写合同名称' }]}
          >
            <Select
              {...layout.select}
              disabled={isContract}
              onChange={(value: any) => {
                console.log(value)
                form.setFieldsValue({
                  contractNo: options?.contractOptions[value].contractNo ||  '',
                  contractName: options?.contractOptions[value].contractName ||  '',
                })
              }}
            >
              {options?.contractOptions?.map((o: any, index: number) => {
                return <Select.Option key={index}>{o.contractName}</Select.Option>
              })}
            </Select>
          </Form.Item>
        </Col>
        <Col {...layout.col}>
          <Form.Item
            name="contractNo"
            label="合同编号"
            {...layout.span8}
            rules={[{ required: true, message: '请填写合同编号' }]}
          >
            <Input allowClear disabled placeholder="请输入" />
          </Form.Item>
        </Col>
      </Row>
      <div className="newly-title">
        <Typography.Title level={5}>投运人信息：</Typography.Title>
      </div>
      <Row gutter={24}>
        <Col {...layout.col}>
          <Form.Item
            name="shipmentsContact"
            label="联系人"
            {...layout.span8}
            rules={[{ required: true, message: '请填写联系人' }]}
          >
            <Input allowClear placeholder="请输入" />
          </Form.Item>
        </Col>
        <Col {...layout.col}>
          <Form.Item
            name="shipmentsTelephone"
            label="联人系电话"
            {...layout.span8}
            rules={[{ required: true, message: '请填写联人系电话' }]}
            validateStatus={checkPhone.validateStatus}
            help={checkPhone.help ? checkPhone.help : undefined}
          >
            <Input
              allowClear
              onBlur={(e) => {
                if (!e.target.value) {
                  setCheckPhone({
                    validateStatus: 'error',
                    help: '手机号必填',
                  })
                } else if (!PHONE_EXP.test(e.target.value)) {
                  setCheckPhone({
                    validateStatus: 'error',
                    help: '手机号格式不正确',
                  })
                } else {
                  setCheckPhone({
                    validateStatus: '  ',
                    help: null,
                  })
                }
              }}
              type="phone"
              placeholder="请输入联人系电话"
              suffix={<span>+86</span>}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col {...layout.col}>
          <Form.Item label="发货地址" name="shipmentsAddress" {...layout.span8}>
            <AddressSelector />
          </Form.Item>
        </Col>
        <Col {...layout.col}>
          {(
            <Form.Item name="shipmentsLocationObj" label="详细地址" {...layout.span8 } rules={[{ required: true, message: '请输入详细地址' }]}>
              <Select
                className="gaode-input small-empty"
                size="middle"
                showSearch
                onFocus={() => {console.log('onFocus')}}
                onChange={(val: string) => {
                  console.log(val, '===val')
                  const obj = JSON.parse(val)
                  const arr = obj.location.split(',').map(parseFloat)

                  // obj?.address && form.setFieldsValue({ shipmentsAddress: obj.address })
                  obj?.adcode && form.setFieldsValue({ shipmentsAddress: obj.adcode })

                  if(!mbxDeliver) {
                    getAMap().then((AMap) => {
                      const lngLat = new AMap.LngLat(arr[0], arr[1])
                      map.setZoom(16)
                      map.setCenter(lngLat)
                      map.clearMap()

                      const marker = new AMap.Marker({
                        position: lngLat,
                        title: obj.name,
                      })

                      map.add(marker)
                    })
                  } else {
                    const popup = new mapboxgl.Popup({
                      offset: [0, -40],
                      closeButton: false
                    });
                    if(lastMarker) {
                      lastMarker.remove();
                    }
                    lastMarker = new mapboxgl.Marker({offset: [0, -15]})
                      .setLngLat([arr[0], arr[1]])
                      .addTo(map);
                    const txt = obj.name;

                    popup.setLngLat([arr[0], arr[1]])
                      .setText(txt)
                      .addTo(map);

                    map.setZoom(15);
                    map.setCenter([arr[0], arr[1]+0.0006]);

                  }
                }}
                onSearch={_.debounce(onSearch, 300)}
                filterOption={() => true}
              >
                {addressList?.map((o: any, idx) => (
                  <Select.Option key={`${o.address}--${idx}`} value={JSON.stringify(o)}>
                    {o.name}
                    <span className="hide-in-input" style={{ fontSize: 10, color: 'lightgrey', marginLeft: '1rem' }}>
                      {o.district}
                    </span>
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          )}
        </Col>
      </Row>
      <div id="logistics-deliver-map" style={{ width: '100%', height: 188, position: 'relative' }}></div>
      <div className="newly-title">
        <Typography.Title level={5}>收货人信息：</Typography.Title>
      </div>
      <Row gutter={24}>
        <Col {...layout.col}>
          <Form.Item
            name="consigneeContact"
            label="联系人"
            {...layout.span8}
            rules={[{ required: true, message: '请填写联系人' }]}
          >
            <Input allowClear placeholder="请输入" />
          </Form.Item>
        </Col>
        <Col {...layout.col}>
          <Form.Item
            name="consigneeTelephone"
            label="联人系电话"
            {...layout.span8}
            rules={[{ required: true, message: '请填写联人系电话' }]}
            validateStatus={checkPhone.validateStatus}
            help={checkPhone.help ? checkPhone.help : undefined}
          >
            <Input
              allowClear
              onBlur={(e) => {
                if (!e.target.value) {
                  setCheckPhone({
                    validateStatus: 'error',
                    help: '手机号必填',
                  })
                } else if (!PHONE_EXP.test(e.target.value)) {
                  setCheckPhone({
                    validateStatus: 'error',
                    help: '手机号格式不正确',
                  })
                } else {
                  setCheckPhone({
                    validateStatus: '  ',
                    help: null,
                  })
                }
              }}
              type="phone"
              placeholder="请输入联人系电话"
              suffix={<span>+86</span>}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col {...layout.col}>
          <Form.Item label="收货地址" name="consigneeAddress" {...layout.span8}>
            <AddressSelector />
          </Form.Item>
        </Col>
        <Col {...layout.col}>
          {(
            <Form.Item  name="consigneeLocationObj" label="详细地址" {...layout.span8} rules={[{ required: true, message: '请输入详细地址' }]}>
              <Select
                className="gaode-input small-empty"
                size="middle"
                showSearch
                onFocus={() => {console.log('onFocus')}}
                onChange={(val: string) => {
                  const obj = JSON.parse(val)
                  const arr = obj.location.split(',').map(parseFloat)

                  obj?.adcode && form.setFieldsValue({ consigneeAddress: obj.adcode })

                  if(!mbxDeliver) {
                    getAMap().then((AMap) => {
                      const lngLat = new AMap.LngLat(arr[0], arr[1])
                      consigneeMap.setZoom(16)
                      consigneeMap.setCenter(lngLat)
                      consigneeMap.clearMap()

                      const marker = new AMap.Marker({
                        position: lngLat,
                        title: obj.name,
                      })

                      consigneeMap.add(marker)
                    })
                  } else {
                    const popup = new mapboxgl.Popup({
                      offset: [0, -40],
                      closeButton: false
                    });
                    if(lastMarkerConsignee) {
                      lastMarkerConsignee.remove();
                    }
                    lastMarkerConsignee = new mapboxgl.Marker({offset: [0, -15]})
                      .setLngLat([arr[0], arr[1]])
                      .addTo(consigneeMap);
                    const txt = obj.name;

                    popup.setLngLat([arr[0], arr[1]])
                      .setText(txt)
                      .addTo(consigneeMap);

                    consigneeMap.setZoom(15);
                    consigneeMap.setCenter([arr[0], arr[1]+0.0006]);

                  }
                }}
                onSearch={_.debounce(onConsigneeSearch, 300)}
                filterOption={() => true}
              >
                {addressConsigneeList?.map((o: any, idx) => (
                  <Select.Option key={`${o.address}--${idx}_consignee`} value={JSON.stringify(o)}>
                    {o.name}
                    <span className="hide-in-input" style={{ fontSize: 10, color: 'lightgrey', marginLeft: '1rem' }}>
                      {o.district}
                    </span>
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          )}
        </Col>
      </Row>
      <div id="logistics-consignee-map" style={{ width: '100%', height: 188, position: 'relative' }}></div>
      <div className="newly-title">
        <Typography.Title level={5}>货物信息：</Typography.Title>
      </div>
      <Form.List name="details">
        {(fields, { add, remove }) => (
          <>
            {fields.map((field, index) => (
              <div key={field.key}>
                
                <div className="goods-info-title">
                  <p>货物{index + 1}<MinusCircleOutlined style={{marginLeft: 20}} onClick={() => remove(field.name)} /></p>
                </div>
                <Row gutter={24}>
                  <Col {...layout.col}>
                    <Form.Item
                      {...field}
                      name={[field.name, 'name']}
                      label="货物名称"
                      {...layout.span8}
                      rules={[{ required: true, message: '请填写货物名称' }]}
                    >
                      <Input allowClear placeholder="请输入" />
                    </Form.Item>
                  </Col>
                  <Col {...layout.col}>
                    <Form.Item
                      {...field}
                      name={[field.name, 'specification']}
                      label="货物规格"
                      {...layout.span8}
                      rules={[{ required: true, message: '请填写货物名称' }]}
                    >
                      <Input allowClear placeholder="请输入" />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={24}>
                  <Col {...layout.col}>
                    <Form.Item
                      {...field}
                      name={[field.name, 'number']}
                      label="货物数量"
                      {...layout.span8}
                    >
                      <InputNumber style={{ width: '100%', borderRight: '1px solid #d9d9d9' }} min={1} onChange={(e) => {console.log(e)}} />
                    </Form.Item>
                  </Col>
                  <Col {...layout.col}>
                    <Form.Item
                      {...field}
                      name={[field.name, 'weight']}
                      label="货物重量"
                      {...layout.span8}
                    >
                      <InputNumber style={{ width: '100%', borderRight: '1px solid #d9d9d9' }} addonAfter="KG" min={1} onChange={(e) => {console.log(e)}} />
                    </Form.Item>
                  </Col>
                </Row>
              </div>
            ))}
            <Form.Item {...layout.span24}>
              <Button type="dashed" style={{width: 200, marginLeft: 30}} onClick={() => add()} block icon={<PlusOutlined />}>
              </Button>
            </Form.Item>
          </>
        )}
      </Form.List>
      <Row gutter={24}>
        <Col {...layout.col}>
          <Form.Item
            name="predictArriveTime"
            label="预计到达时间"
            {...layout.span8}
          >
            <DatePicker format="YYYY-MM-DD" style={{ width: '100%' }} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col {...layout.col}>
          <Form.Item
            name="isCargoList"
            label="随货清单"
            {...layout.span8}
          >
            <Radio.Group onChange={(e) => {
              console.log(e)
              setIsShowUploadPdf(e.target.value)
              form.setFieldsValue({
                isCargoList: e.target.value
              })
            }}>
              <Radio value={true}>有</Radio>
              <Radio value={false}>无</Radio>
            </Radio.Group>
          </Form.Item>
        </Col>
      </Row>
      {
        isShowUploadPdf && (
          <Row gutter={24}>
            <Col {...layout.col24}>
              <Form.Item label="随货清单" {...layout.span24} name="cargoListUrlList">
                <UploadFile
                  action={LogisticsService.uploadFile()}
                  maxCount={15}
                  maxSize={10}
                  disabled={!isShowUploadPdf}
                  onChange={(list) => {
                    let arr = []
                    list.forEach((item) => {
                      arr.push({
                        ...item,
                        url: item?.url || item.response?.data || ''
                      })
                    })
                    form.setFieldsValue({
                      cargoListUrlList: arr
                    })
                  }}
                  emptyText="暂无营业执照"
                  accept=".png,.jpg,.word,.excel,.docx,.xlsx,.pdf,.xls,.doc"
                  extra="支持扩展名：.png、.jpg、.word、.excel、.pdf、.docx、.xlsx、.xls、.doc"
                  extraWrap={false}
                />
              </Form.Item>
            </Col>
          </Row>
        )
      }
      
      <Row gutter={24} style={{marginTop: 40}}>
        <Col {...layout.col24}>
          <Form.Item
            name="remark"
            label="备注"
            {...layout.span24}
          >
              <TextArea showCount maxLength={200} style={{ height: 150 }} onChange={
                (e: React.ChangeEvent<HTMLTextAreaElement>) => {
                  console.log('Change:', e.target.value);
                }
              } />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  )
}

export default () => {
  const nav = useNavigate()
  const formRef = React.useRef<ContractFormInstance>()
  const paramsData = useParams() // 链接参数

  useEffect(() => {
    SideMenuObservable.next(false)
    BreadcrumbObservable.next([
      {
        name: '工程管理',
        url: '/engineering',
      },
      {
        name: '物流管理',
        url: '/engineering/logistics',
      },
      {
        name: paramsData?.manageId && paramsData?.manageId !== 'create' ? '编辑运单' : '新建运单',
        url: '/engineering/logisticsCreate',
      },
    ])
  }, [])

  const goBack = React.useCallback(() => {
    formRef.current?.reset()
    nav(-1)
  }, [])

  const cancel = React.useCallback(() => {
    formRef.current?.reset()
    nav(-1)
  }, [])

  return (
    <Card
    className="right-actions logistics-create"
    title={<Typography.Title level={5}>新建运单</Typography.Title>}
    extra={
      <Space size={12}>
        <Button onClick={goBack}>返回</Button>
        <Button onClick={cancel}>取消</Button>
        <Button
          type="primary"
          onClick={() => {
            formRef.current?.submit(() => nav(-1))
          }}
        >
          提交
        </Button>
      </Space>
      }
      bodyStyle={{ height: `calc(100vh - 210px)`, padding: '0 0 30px 0' }}
    >
      
      <div style={{ padding: '16px 40px', height: '100%', overflow: 'auto' }}>
        <ContractForm formRef={formRef} manageId={paramsData.manageId}  />
      </div>
    </Card>
  )
}
