import React from 'react'
import { cloneDeep } from 'lodash'
import {
  Checkbox,
  Col,
  Descriptions,
  Form,
  FormInstance,
  Input,
  InputNumber,
  Radio,
  Row,
  Select,
  Space,
  Spin,
  Typography,
} from 'antd'
import UploadFile from '../../../../components/wl-upload'
import { CollectionConService } from '../../../../apis/wl-service'
import store, { SubmitType } from './store'

export interface OnlineFormInstance extends FormInstance {
  getName: () => string
  reset: () => void // 重置表单数据
  submit: (cb?: () => void) => void // 提交表单
}

export interface OnlineFormProps {
  componentRef?: any
  editable?: boolean
  dataSource?: any
}

export const OnlineForm: React.FC<OnlineFormProps> = ({ dataSource, editable = true, componentRef }) => {
  const unmountRef = React.useRef<boolean>(false)
  const [form] = Form.useForm()
  const storeKey = React.useMemo(() => store.keyMap.collectionCon, [store]) // 用于本地数据存储的键值
  const [formData, setFormData] = React.useState<any>({}) // 存储表单数据
  const [loading, setLoading] = React.useState<boolean>(false) // 数据加载状态
  const [options, setOptions] = React.useState<any>({
    developList: [], // 1）项目（屋顶企业）名称列表（此处的项目全部已新建过资源）
    enterpriseNature: [], // 企业性质
    property: [], // 产权
    collectionTemplate: [], // 收资表模板数据
    stationInstallation: [
      { fullName: '混凝土屋顶', id: '1' },
      { fullName: '瓦屋顶', id: '2' },
    ],
    plantNature: [
      { fullName: '工业厂房', id: '1' },
      { fullName: '办公楼', id: '2' },
      { fullName: '住宅楼', id: '3' },
      { fullName: '车棚/大棚', id: '5' },
    ],
    isFocus: [
      { fullName: '是', id: '0' },
      { fullName: '否', id: '1' },
    ],
    fireRating: [
      { fullName: '甲类', id: '1' },
      { fullName: '乙类', id: '2' },
      { fullName: '丙类', id: '3' },
      { fullName: '丁类', id: '4' },
      { fullName: '戍类', id: '5' },
    ],
    electricityMetering: [
      { fullName: '35kV', id: '1' },
      { fullName: '10kV', id: '2' },
      { fullName: '0.4kV', id: '3' },
      { fullName: '0.22kV', id: '4' },
    ],
    isPowerRoom: [
      { fullName: '有', id: '0' },
      { fullName: '无', id: '1' },
    ],
  }) // 基础选择数据

  React.useImperativeHandle(componentRef, () => ({
    ...form,
    getName: () => storeKey,
    reset: () => form.resetFields(),
    submit: async (options: SubmitType) => {
      try {
        let values: any = await form.validateFields()
        values = formatter(values, 'params')
        store.submit[storeKey]({
          ...options,
          values,
        })
      } catch (e) {
        console.warn('validateFields:', e)
      }
    },
  }))

  // 设置组件挂载状态
  React.useEffect(() => {
    unmountRef.current = false
    return () => {
      unmountRef.current = true
    }
  }, [])

  // 获取基础数据
  React.useEffect(() => {
    if (unmountRef.current) return

    // 编辑时从缓存中读取数据
    const prevOptions = editable && store.getOptions()

    // 收资信息基础数据
    prevOptions?.developList
      ? setOptions((previous: any) => ({
          ...previous,
          developList: prevOptions.developList,
          enterpriseNature: prevOptions.enterpriseNature,
          property: prevOptions.property,
          collectionTemplate: prevOptions.collectionTemplate,
        }))
      : CollectionConService.basicData()
          .then((res: any) => {
            const current = res?.data ?? {}
            current.developList = current.developList?.map(({ id, name }: any) => ({ id, name, fullName: name }))
            if (!unmountRef.current) {
              setOptions((previous: any) => ({ ...previous, ...current }))
            }
            editable && store.setOptions(current, true)
          })
          .catch((e) => {
            console.warn('获取收资信息基础数据失败：', e)
          })
  }, [editable, store.getOptions, store.setOptions])

  // 根据projectId获取详情
  React.useEffect(() => {
    // 设置、存储表单数据
    const setValues = (values: any) => {
      try {
        if (editable) {
          // 设置form的项目选中项
          values.projectId = store.projectId ?? ''
          store.setItem(storeKey, values)
        }

        // form.resetFields()
        form.setFieldsValue(formatter(values, 'component'))
        !unmountRef.current && setFormData(values)
      } catch (e: any) {
        console.warn(e)
      }
    }

    // 详情传递数据源，直接使用
    if (!editable && dataSource) {
      setValues(dataSource)
      return
    }

    const prevDetailData = editable && store.getItem(storeKey)

    if (prevDetailData) {
      setValues(prevDetailData)
      return
    }

    if (!store.projectId) {
      return
    }

    setLoading(true)
    CollectionConService.detail({ projectId: store.projectId })
      .then((resp: any) => {
        setValues(resp?.data ?? {})
      })
      .catch((e: any) => {
        console.warn(`获取在线收资详情失败`, e)
      })
      .finally(() => {
        setLoading(false)
      })
  }, [store.projectId, storeKey])

  // 数据转换
  const formatter = (values: any, type: 'params' | 'component') => {
    values = cloneDeep(values)

    // 文件
    ;['businessLicense', 'propertyFile', 'drawingFile', 'photo', 'proof'].forEach((k: string) => {
      if (type === 'params') {
        values[k] = values[k] ? JSON.stringify(values[k]) : ''
      }
      if (type === 'component') {
        values[k] = values[k] ? JSON.parse(values[k]) : []
      }
    })

    // select tags, checkbox group
    ;['transformerCapacity', 'isOcclude'].forEach((k: string) => {
      if (type === 'params') {
        values[k] = Array.isArray(values[k]) ? values[k].join(',') : values[k]
      }
      if (type === 'component') {
        values[k] = Array.isArray(values[k]) ? values[k] : values[k]?.split(',') ?? []
      }
    })

    if (type === 'component') {
      ;[
        'property',
        'stationInstallation',
        'plantNature',
        'isFocus',
        'fireRating',
        'electricityMetering',
        'isPowerRoom',
      ].forEach((k: string) => {
        if (typeof values[k] === 'number') {
          values[k] = values[k]?.toString()
        }
      })
    }
    return values
  }

  // 布局设置
  const layout = React.useMemo(() => {
    return {
      formItemProps: {
        labelCol: { xs: 24, md: 4 },
        wrapperCol: { xs: 24, md: 20 },
      },
      formItem2Props: {
        labelCol: { xs: 24, md: 8 },
        wrapperCol: { xs: 24, md: 16 },
      },
      formItemOffsetProps: {
        labelCol: { xs: 24, md: 4 },
        wrapperCol: { offset: 4, xs: 24, md: 20 },
      },
      inputNumberProps: {
        min: 0,
        placeholder: '请输入',
        style: { width: '100%' },
      },
      selectProps: {
        dropdownMatchSelectWidth: false,
        allowClear: true,
        placeholder: '请选择',
        showSearch: true,
        optionFilterProp: 'children',
        filterOption: (input: string, option: any) => {
          return (option!.children as unknown as string).toLowerCase().includes(input.toLowerCase())
        },
      },
    }
  }, [])

  const descriptions = React.useMemo(() => {
    return [
      {
        title: '1.基本信息',
        dataSource: [
          {
            label: '1）项目（屋顶企业）名称',
            value: (
              <>
                <Form.Item name="id" hidden>
                  <Input />
                </Form.Item>
                {editable ? (
                  <Form.Item name="projectId" {...layout.formItemOffsetProps}>
                    <Select
                      {...layout.selectProps}
                      disabled={!!store.projectId && store.getModuleName() !== storeKey}
                      onChange={(value: string) => {
                        // 项目id变化
                        store.setProjectId(value ?? '', storeKey)
                      }}
                    >
                      {(options?.developList ?? []).map(({ id, name }: any) => {
                        return <Select.Option key={id}>{name}</Select.Option>
                      })}
                    </Select>
                  </Form.Item>
                ) : (
                  formData.projectName ?? options?.developList?.find(({ id }: any) => id === formData?.projectId)?.name
                )}
              </>
            ),
          },
          {
            label: '2）企业性质',
            value: editable ? (
              <>
                <Form.Item label="企业性质" name="enterpriseNature" {...layout.formItemProps}>
                  <Select {...layout.selectProps}>
                    {options?.enterpriseNature?.map(({ id, name }: any) => {
                      return <Select.Option key={id}>{name}</Select.Option>
                    })}
                  </Select>
                </Form.Item>
                <Form.Item label="营业执照" name="businessLicense" {...layout.formItemProps}>
                  <UploadFile
                    maxCount={15}
                    maxSize={10}
                    disabled={!editable}
                    emptyText="暂无营业执照"
                    accept=".png,.jpg,.jpeg,.pdf"
                    extra="支持扩展名：.png、.jpg、.pdf"
                    extraWrap={false}
                  />
                </Form.Item>
              </>
            ) : (
              <Space direction="horizontal">
                <Typography.Text underline>
                  {formData.enterpriseNatureName ??
                    options?.enterpriseNature?.find((v: any) => v.id === formData.enterpriseNature)?.name}
                </Typography.Text>
                <Typography.Text>（{options?.enterpriseNature?.map((v: any) => v.name).join(',')}等）</Typography.Text>
              </Space>
            ),
          },
          {
            label: '3）主营业务',
            value: editable ? (
              <Form.Item name="mainBusiness" {...layout.formItemOffsetProps}>
                <Input allowClear placeholder="请输入" />
              </Form.Item>
            ) : (
              formData.mainBusiness
            ),
          },
          {
            label: '4）建设地址',
            value: editable ? (
              <Form.Item name="constructionAddress" {...layout.formItemOffsetProps}>
                <Input.TextArea
                  allowClear
                  placeholder="请输入"
                  style={{ marginBottom: 16 }}
                  autoSize={{ minRows: 3 }}
                  maxLength={200}
                  showCount
                />
              </Form.Item>
            ) : (
              formData.constructionAddress
            ),
          },
          {
            label: <Typography.Text type="danger">5）产权证</Typography.Text>,
            value: editable ? (
              <>
                <Form.Item label="产权证" name="property" {...layout.formItemProps}>
                  <Select {...layout.selectProps}>
                    {options?.property?.map((item: any) => {
                      return (
                        <Select.Option key={item.id} value={item.id}>
                          {item.name}
                        </Select.Option>
                      )
                    })}
                  </Select>
                </Form.Item>

                <Form.Item label="产权证上传" name="propertyFile" {...layout.formItemProps}>
                  <UploadFile
                    maxCount={15}
                    maxSize={10}
                    disabled={!editable}
                    emptyText="暂无营业执照"
                    accept=".png,.jpg,.jpeg,.pdf"
                    extra="支持扩展名：.png、.jpg、.pdf"
                    extraWrap={false}
                  />
                </Form.Item>
              </>
            ) : (
              <Radio.Group value={formData.property}>
                {options?.property?.map((item: any) => {
                  return (
                    <Radio key={item.id} value={item.id}>
                      <Typography.Text type="danger">{item.name}</Typography.Text>
                    </Radio>
                  )
                })}
              </Radio.Group>
            ),
          },
          {
            label: '6）建筑物使用年限还剩余',
            value: editable ? (
              <Form.Item name="buildingAge" {...layout.formItemOffsetProps}>
                <InputNumber {...layout.inputNumberProps} addonAfter="年" />
              </Form.Item>
            ) : (
              <Space direction="horizontal">
                <Typography.Text underline>{formData.buildingAge}</Typography.Text>年
              </Space>
            ),
          },
        ],
      },
      {
        title: '2.场地条件',
        dataSource: [
          {
            label: '1）电站安装类型',
            value: editable ? (
              <>
                <Form.Item name="stationInstallation" {...layout.formItemProps}>
                  <Radio.Group>
                    {options.stationInstallation.map(({ id, fullName }: any) => (
                      <Radio key={id} value={id}>
                        {fullName}
                      </Radio>
                    ))}
                  </Radio.Group>
                </Form.Item>
                <Row>
                  <Col>
                    <Form.Item name="isOcclude">
                      {/* 是否有遮挡 0：是 1：否 */}
                      <Checkbox.Group>
                        <Checkbox value="0">东、西、南、北四面是否有遮挡</Checkbox>
                      </Checkbox.Group>
                    </Form.Item>
                  </Col>
                  <Col flex="auto">
                    <Form.Item
                      noStyle
                      shouldUpdate={(prevValues, currentValues) => prevValues.isOcclude != currentValues.isOcclude}
                    >
                      {({ getFieldValue }: FormInstance) => {
                        return getFieldValue('isOcclude')?.[0] === '0' ? (
                          <Form.Item name="mark" noStyle>
                            <Input allowClear placeholder="请输入备注说明" />
                          </Form.Item>
                        ) : null
                      }}
                    </Form.Item>
                  </Col>
                </Row>
              </>
            ) : (
              <Space direction="vertical">
                <Radio.Group value={formData.stationInstallation}>
                  {options.stationInstallation.map(({ id, fullName }: any) => (
                    <Radio key={id} value={id}>
                      {fullName}
                    </Radio>
                  ))}
                </Radio.Group>
                <Space direction="horizontal" size={12}>
                  {/* 是否有遮挡 0：是 1：否 */}
                  <Checkbox checked={formData.isOcclude == 0}>东、西、南、北四面是否有遮挡</Checkbox>
                  {formData.isOcclude == 0 ? (
                    <Typography.Text>
                      （注明：<Typography.Text>{formData.mark}</Typography.Text>）
                    </Typography.Text>
                  ) : null}
                </Space>
              </Space>
            ),
          },
          {
            label: '2）屋面情况',
            value: editable ? (
              <Row>
                <Col span={24}>
                  <Form.Item label="厂房性质" name="plantNature" {...layout.formItemProps}>
                    <Radio.Group>
                      {options.plantNature.map(({ id, fullName }: any) => (
                        <Radio key={id} value={id}>
                          {fullName}
                        </Radio>
                      ))}
                    </Radio.Group>
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label="屋顶面积" name="roofArea" {...layout.formItem2Props}>
                    <InputNumber {...layout.inputNumberProps} />
                  </Form.Item>
                </Col>

                <Col span={12}>
                  <Form.Item label="实际可利用面积（预估）" name="actualArea" {...layout.formItem2Props}>
                    <InputNumber {...layout.inputNumberProps} />
                  </Form.Item>
                </Col>

                <Col span={12}>
                  <Form.Item label="屋顶个数" name="roofNum" {...layout.formItem2Props}>
                    <InputNumber {...layout.inputNumberProps} />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label="屋顶是否集中" name="isFocus" {...layout.formItem2Props}>
                    <Radio.Group>
                      {options.isFocus.map(({ id, fullName }: any) => (
                        <Radio key={id} value={id}>
                          {fullName}
                        </Radio>
                      ))}
                    </Radio.Group>
                  </Form.Item>
                </Col>
              </Row>
            ) : (
              <Space direction="vertical">
                <Space direction="horizontal">
                  <Typography.Text>厂房类型：</Typography.Text>
                  <Radio.Group value={formData.plantNature}>
                    {options.plantNature.map(({ id, fullName }: any) => (
                      <Radio key={id} value={id}>
                        {fullName}
                      </Radio>
                    ))}
                  </Radio.Group>
                </Space>
                <Space direction="horizontal" size={[100, 12]} wrap>
                  <Space direction="horizontal">
                    <Typography.Text>屋顶面积：</Typography.Text>
                    <Typography.Text underline>{formData.roofArea}</Typography.Text>
                  </Space>
                  <Space direction="horizontal">
                    <Typography.Text>实际可利用面积（预估）：</Typography.Text>
                    <Typography.Text underline>{formData.actualArea}</Typography.Text>
                  </Space>
                  <Space direction="horizontal">
                    <Typography.Text>屋顶个数：</Typography.Text>
                    <Typography.Text underline>{formData.roofNum}</Typography.Text>
                  </Space>
                  <Space direction="horizontal">
                    <Typography.Text>屋顶是否集中：</Typography.Text>
                    <Radio.Group value={formData.isFocus}>
                      {options.isFocus.map(({ id, fullName }: any) => (
                        <Radio key={id} value={id}>
                          {fullName}
                        </Radio>
                      ))}
                    </Radio.Group>
                  </Space>
                </Space>
              </Space>
            ),
          },
          {
            label: '3）图纸（CAD电子版）',
            value: editable ? (
              <Form.Item name="drawingFile" wrapperCol={{ span: 24 }}>
                <UploadFile
                  maxCount={15}
                  maxSize={10}
                  disabled={!editable}
                  emptyText="暂无图纸"
                  accept=".dwg"
                  extra="支持扩展名：.dwg"
                  extraWrap
                  description="厂区总平面布置图，整套建筑施工图、整套结构施工图、电气接线图、建筑物屋面总平面图"
                />
              </Form.Item>
            ) : (
              <Typography.Text type="danger">
                厂区总平面布置图，整套建筑施工图、整套结构施工图、电气接线图、建筑物屋面总平面图
              </Typography.Text>
            ),
          },
          {
            label: '4）照片',
            value: editable ? (
              <Form.Item name="photo" wrapperCol={{ span: 24 }}>
                <UploadFile
                  maxCount={15}
                  maxSize={10}
                  disabled={!editable}
                  emptyText="暂无照片"
                  accept=".jpg,.jpeg,.png"
                  extra="支持扩展名：.jpg、.png"
                  extraWrap
                  description="建筑屋面全景照片，内部钢结构照片（包含檩条、钢梁、立柱、拉杆）建筑物四面照片或者视频，高低压电房照片，彩钢瓦照片"
                />
              </Form.Item>
            ) : (
              <Typography.Text>
                建筑屋面全景照片，内部钢结构照片（包含檩条、钢梁、立柱、拉杆）建筑物四面照片或者视频，高低压电房照片，彩钢瓦照片
              </Typography.Text>
            ),
          },
          {
            label: '5）厂房消防等级',
            value: editable ? (
              <Form.Item name="fireRating" wrapperCol={{ span: 24 }}>
                <Radio.Group>
                  {options.fireRating.map(({ id, fullName }: any) => (
                    <Radio key={id} value={id}>
                      {fullName}
                    </Radio>
                  ))}
                </Radio.Group>
              </Form.Item>
            ) : (
              <Radio.Group value={formData.fireRating}>
                {options.fireRating.map(({ id, fullName }: any) => (
                  <Radio key={id} value={id}>
                    {fullName}
                  </Radio>
                ))}
              </Radio.Group>
            ),
          },
        ],
      },
      {
        title: '3.电力情况',
        dataSource: [
          {
            label: '1）可并网点电压等级',
            value: editable ? (
              <Row>
                <Col span={12}>
                  <Form.Item label="变压器台数" name="transformerNum" {...layout.formItem2Props}>
                    <InputNumber {...layout.inputNumberProps} />
                  </Form.Item>
                </Col>

                <Col span={12}>
                  <Form.Item
                    label="变压器容量分别为（单位：kV）"
                    name="transformerCapacity"
                    {...layout.formItem2Props}
                    rules={[
                      {
                        validator: (rule: any, value: any) => {
                          if (value?.length) {
                            const cloneValue = value.concat()
                            const last = cloneValue.pop()
                            if (!/^(\d+)$/.test(last.trim())) {
                              setTimeout(() => {
                                form.setFieldsValue({ [rule?.field]: [...cloneValue] })
                              }, 50)
                              return Promise.reject('请输入数字，并回车确认~')
                            }
                          }
                          return Promise.resolve()
                        },
                      },
                    ]}
                  >
                    <Select mode="tags" tokenSeparators={[',']} placeholder="输入数字后请回车确认"></Select>
                  </Form.Item>
                </Col>

                <Col span={24}>
                  <Form.Item label="厂区电费计量方式" name="electricityMetering" {...layout.formItemProps}>
                    <Radio.Group>
                      {options.electricityMetering.map(({ id, fullName }: any) => (
                        <Radio key={id} value={id}>
                          <Typography.Text type={editable ? undefined : 'danger'}>{fullName}</Typography.Text>
                        </Radio>
                      ))}
                    </Radio.Group>
                  </Form.Item>
                </Col>
              </Row>
            ) : (
              <Space direction="vertical">
                <Space direction="horizontal" size={40}>
                  <Space direction="horizontal">
                    <Typography.Text type="danger">变压器台数：</Typography.Text>
                    <Typography.Text underline type="danger">
                      {formData.transformerNum}
                    </Typography.Text>
                  </Space>
                  <Space direction="horizontal">
                    <Typography.Text type="danger">变压器容量分别为（单位：kV）：</Typography.Text>
                    <Typography.Text underline type="danger">
                      {formData.transformerCapacity}
                    </Typography.Text>
                  </Space>
                </Space>

                <Space direction="horizontal">
                  <Typography.Text type="danger">厂区电费计量方式:</Typography.Text>
                  <Radio.Group value={formData.electricityMetering}>
                    {options.electricityMetering.map(({ id, fullName }: any) => (
                      <Radio key={id} value={id}>
                        <Typography.Text type={editable ? undefined : 'danger'}>{fullName}</Typography.Text>
                      </Radio>
                    ))}
                  </Radio.Group>
                </Space>
              </Space>
            ),
          },
          {
            label: '2）用电负荷情况',
            value: editable ? (
              <Row>
                <Col span={12}>
                  <Form.Item label="厂区是否有配电房" name="isPowerRoom" {...layout.formItem2Props}>
                    <Radio.Group>
                      {options.isPowerRoom.map(({ id, fullName }: any) => (
                        <Radio key={id} value={id}>
                          {fullName}
                        </Radio>
                      ))}
                    </Radio.Group>
                  </Form.Item>
                </Col>

                <Col span={12}>
                  <Form.Item label="全年电力消纳电量（万度）" name="powerConsumptionYear" {...layout.formItem2Props}>
                    <InputNumber {...layout.inputNumberProps} />
                  </Form.Item>
                </Col>

                <Col span={12}>
                  <Form.Item label="月度电力消纳电量（万度）" name="powerConsumptionMonth" {...layout.formItem2Props}>
                    <InputNumber {...layout.inputNumberProps} />
                  </Form.Item>
                </Col>

                <Col span={12}>
                  <Form.Item
                    label="白天晚上用电比例（例如：3/2）"
                    name="scale"
                    {...layout.formItem2Props}
                    rules={[
                      {
                        validator: (rule: any, value: any) => {
                          if (value && !/^(\d+)\/(\d+)$/.test(value.trim())) {
                            return Promise.reject(`请输入格式为3/2的数值~`)
                          }
                          return Promise.resolve()
                        },
                      },
                    ]}
                  >
                    <Input allowClear placeholder="请输入" />
                  </Form.Item>
                </Col>
              </Row>
            ) : (
              <Row>
                <Col span={24} style={{ marginBottom: 12 }}>
                  <Space direction="horizontal">
                    <Typography.Text type="danger">厂区是否有配电房</Typography.Text>
                    <Radio.Group value={formData.isPowerRoom}>
                      {options.isPowerRoom.map(({ id, fullName }: any) => (
                        <Radio key={id} value={id}>
                          <Typography.Text type="danger"> {fullName}</Typography.Text>
                        </Radio>
                      ))}
                    </Radio.Group>
                  </Space>
                </Col>

                <Col span={12} style={{ marginBottom: 12 }}>
                  <Space direction="horizontal">
                    <Typography.Text strong>全年电力消纳电量（万度）</Typography.Text>
                    <Typography.Text underline strong>
                      {formData.powerConsumptionYear}（万度）
                    </Typography.Text>
                  </Space>
                </Col>

                <Col span={12}>
                  <Space direction="horizontal">
                    <Typography.Text strong>月度电力消纳电量（万度）</Typography.Text>
                    <Typography.Text underline strong>
                      {formData.powerConsumptionMonth}（万度）
                    </Typography.Text>
                  </Space>
                </Col>

                <Col span={12}>
                  <Space direction="horizontal">
                    <Typography.Text strong>白天晚上用电比例（例如：3/2）</Typography.Text>
                    <Typography.Text underline strong>
                      {formData.scale}
                    </Typography.Text>
                  </Space>
                </Col>
              </Row>
            ),
          },
          {
            label: <Typography.Text type="danger">3）电费结算证明</Typography.Text>,
            value: editable ? (
              <Form.Item name="proof" wrapperCol={{ span: 24 }}>
                <UploadFile
                  maxCount={15}
                  maxSize={10}
                  disabled={!editable}
                  emptyText="暂无电费结算证明"
                  accept=".jpg,.jpeg,.pdf,.png,.docx,.doc"
                  extra="支持扩展名：.docx .pdf .jpg .png"
                  extraWrap
                  description="即提供近期连续12个月缴费单"
                />
              </Form.Item>
            ) : (
              <Typography.Text type="danger">(即提供近期连续12个月缴费单)</Typography.Text>
            ),
          },
        ],
      },
    ]
  }, [editable, formData, options])

  return (
    <Spin spinning={loading}>
      <Form
        className="wl-form-item"
        form={form}
        name="onlineForm"
        layout="horizontal"
        colon
        labelWrap
        labelAlign="right"
        onValuesChange={(changedValues, allValues) => {
          store.onValuesChange(storeKey, { ...store.getItem(storeKey), ...formatter(allValues, 'params') })
        }}
      >
        <Row>
          <Col span={12}>
            {editable ? (
              <Form.Item name="collectionTemplate" label="选择收资表模板" {...layout.formItem2Props}>
                <Select {...layout.selectProps}>
                  {options?.collectionTemplate?.map(({ id, name }: any) => (
                    <Select.Option key={id}>{name}</Select.Option>
                  ))}
                </Select>
              </Form.Item>
            ) : null}
          </Col>
        </Row>
        <Space direction="vertical" size={24}>
          {descriptions.map((desc: any, i: number) => {
            return (
              <Descriptions
                key={i}
                title={desc.title}
                column={1}
                size="small"
                bordered
                labelStyle={{ width: 200 }}
                style={{ width: '100%' }}
              >
                {desc.dataSource?.map((item: any, idx: number) => {
                  return (
                    <Descriptions.Item key={idx} label={item.label}>
                      <div style={{ marginBottom: editable ? -24 : 0 }}>{item.value}</div>
                    </Descriptions.Item>
                  )
                })}
              </Descriptions>
            )
          })}
        </Space>
      </Form>
    </Spin>
  )
}
