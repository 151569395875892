import React, { ReactNode } from 'react'
import { Link } from 'react-router-dom'
import './index.less'
import { Image } from 'antd'

export interface KLFTabConfig {
  iconUrl?: string
  title: string | ReactNode
  key: string
  finished: boolean
  disabled?: boolean
}

export const KLFTab = (props: {
  iconUrl?: string
  title: string | ReactNode
  k: string
  selected: boolean
  finished: boolean
  disabled: boolean
  clicked: () => void
}) => {
  return (
    <Link
      to={props.k || ''}
      style={{
        pointerEvents: !props.disabled ? 'all' : 'none',
        cursor: !props.disabled ? 'pointer' : 'not-allowed',
      }}
    >
      <div
        className={`tab ${(props.selected && 'tab-selected') || ''} ${(props.finished && 'tab-finished') || ''} ${
          props.disabled ? 'tab-disabled' : ''
        }`}
        onClick={() => {
          props.clicked()
        }}
        style={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Image
          style={{
            display: !props.selected ? 'inline-block' : 'none',
          }}
          preview={false}
          src={`assets/${props.iconUrl}-custom.svg`}
        />
        <Image
          style={{
            display: props.selected ? 'inline-block' : 'none',
          }}
          preview={false}
          src={`assets/${props.iconUrl}-selected.svg`}
        />
        <span
          style={{
            marginLeft: '0.5rem',
          }}
        >
          {props.title}
        </span>
        {props.finished && <img className="finish-icon" src="assets/icon-finish.svg" alt="finished" />}
      </div>
    </Link>
  )
}

export const KLFTabs = (props: { config: KLFTabConfig[]; activatedKey: string; callback: (key: string) => void }) => {
  const { config, activatedKey, callback } = props

  return (
    <div className="tab-content">
      {config.map((o) => {
        return (
          <KLFTab
            iconUrl={o.iconUrl}
            key={o.key}
            title={o.title}
            k={o.key}
            selected={activatedKey === o.key.split("/")[0]}
            finished={o.finished}
            disabled={o.disabled || false}
            clicked={() => {
              callback(o.key)
            }}
          />
        )
      })}
    </div>
  )
}
