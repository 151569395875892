import React, { useEffect, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { Card, Space, Descriptions, Typography, Button, List, Skeleton, message, Tag } from 'antd'
import { FileExcelOutlined, FilePdfOutlined } from '@ant-design/icons'

import { ContractService, dynamicText, FileService } from '../../../apis/wl-service'
import { BreadcrumbObservable, SideMenuObservable } from '../../../application'
import { contractCodeOptions } from '../constants'

// 合同详情页面
export default () => {
  const params = useParams()
  const location = useLocation()
  const [loading, setLoading] = useState<boolean>(false) // 数据加载状态
  const [data, setData] = useState<any>({}) // 项目信息数据
  const [mode, setMode] = useState<'detail' | 'signed'>('detail') // 页面内容标识

  // 设置面包屑
  useEffect(() => {
    SideMenuObservable.next(false)
    BreadcrumbObservable.next([
      location.state?.engineering
        ? { name: '工程管理', url: `/invest/projectLibrary`, state: location.state }
        : { name: '投资开发', url: '/invest/workbench' },
      { name: '合同审批', url: `/invest/contractApproval`, state: location.state },
      { name: '合同详情', url: `/invest/contractDetail/${params.id}`, state: location.state },
    ])

    return () => {}
  }, [params.id, location.state])

  // 获取合同详情
  useEffect(() => {
    if (!params.id) return

    setLoading(true)
    ContractService.info({ id: params.id ?? '' })
      .then((resp: any) => {
        const data = resp?.data ?? {}
        data.contractFile = data.contractFile ? JSON.parse(data.contractFile) : []
        data.stock = data.stock ? JSON.parse(data.stock) : []
        setData(data)
      })
      .catch((e) => {
        console.warn(e)
      })
      .finally(() => {
        setLoading(false)
      })
  }, [])

  const content = React.useMemo(() => {
    switch (mode) {
      case 'detail':
        return (
          <DetailContent
            dataSource={data}
            jumpTo={() => {
              setMode('signed')
            }}
          />
        )

      case 'signed':
        return <SignedContent id={params.id} />

      default:
        return null
    }
  }, [data, mode, params.id])

  const title = React.useMemo(() => {
    if (mode === 'signed') {
      return data?.contractNumber
    }
    return data?.contractName
  }, [mode, data])

  return (
    <Card
      className="right-actions no-border-bottom-card"
      loading={loading}
      title={<Typography.Title level={4}>{title}</Typography.Title>}
      bodyStyle={{ height: `calc(100vh - 210px)`, padding: 0 }}
      extra={
        mode === 'detail' ? null : (
          <Space direction="horizontal">
            <Button type="primary" ghost onClick={() => viewPdf(data?.contractFile?.[0]?.fileId ?? '')}>
              查看合同文件
            </Button>
            <Button onClick={() => setMode('detail')}>返回</Button>
          </Space>
        )
      }
    >
      <div style={{ padding: '0 16px 16px', height: '100%', overflow: 'auto' }}>{content}</div>
    </Card>
  )
}

export const viewPdf = async (fileId: string) => {
  const resp: any = await FileService.preview({ path: fileId ?? '' })
  // 在新页面中打开链接
  if (resp?.code === 200 && resp?.msg) {
    const a = document.createElement('a')
    a.target = '_blank'
    a.href = resp?.msg
    a.click()
  }
}

// 签署详情内容
export const SignedContent = ({ id }: any) => {
  const [dataSource, setDataSource] = React.useState<any>({})

  React.useEffect(() => {
    ContractService.state({ id }).then((resp: any) => {
      setDataSource(resp?.data ?? {})
    })
  }, [id])

  const descriptions = React.useMemo(() => {
    return [
      { name: '业务分类', value: dataSource?.industry },
      { name: '签署状态', value: dynamicText(dataSource?.code, contractCodeOptions) },
      { name: '签署截止日期', value: dataSource?.contractDateEnd },
      { name: '文件编号', value: dataSource?.contractNumber },
      {
        name: '文件',
        value: (dataSource?.contractFile ? JSON.parse(dataSource.contractFile) : []).map((item: any) => {
          return (
            <div key={item.fileId} style={{ cursor: 'pointer' }} onClick={() => FileService.download(item.fileId)}>
              <FilePdfOutlined style={{ marginRight: 8, color: '#f8ad90' }} />
              {item.name}
            </div>
          )
        }),
      },
    ]
  }, [dataSource])

  return (
    <Space direction="vertical" size={0}>
      <Descriptions title={null} column={1} layout="horizontal">
        {descriptions.map((desc: any, index: number) => {
          return (
            <Descriptions.Item key={index} label={desc.name} span={desc.span}>
              {desc.value ?? ''}
            </Descriptions.Item>
          )
        })}
      </Descriptions>
      <Typography.Title level={5}>签署方</Typography.Title>
      {/* <Typography.Paragraph>
        由 上海尊方数据科技有限公司 于 2022-05-07 17:57:42 发起
      </Typography.Paragraph> */}
      <List
        style={{ width: '100%' }}
        itemLayout="horizontal"
        dataSource={dataSource?.signState ?? []}
        renderItem={(item: any, index: number) => (
          <List.Item
            actions={[]}
            style={{ padding: 12, marginBottom: 12, border: '1px solid #f0f0f0', borderRadius: 4 }}
          >
            <Skeleton avatar title={false} loading={false} active>
              <List.Item.Meta
                avatar={null}
                title={
                  <Space direction="vertical">
                    <Tag>接收方{index + 1}</Tag>
                    <Space direction="horizontal" style={{ textIndent: '2em' }}>
                      <Typography.Text strong>{item.partyName}</Typography.Text>
                      <Button
                        type="link"
                        onClick={async () => {
                          try {
                            const resp: any = await ContractService.urge({ code: item.partyCode })
                            if (resp?.code === 200) {
                              message.success(resp?.msg ?? '操作成功~')
                            } else if (resp?.msg) {
                              message.warn(resp.msg)
                            }
                          } catch (e) {
                            console.warn('催一下:', e)
                          }
                        }}
                      >
                        催一下
                      </Button>
                    </Space>
                  </Space>
                }
                description={
                  <Typography.Text style={{ textIndent: '2em', display: 'block' }}>
                    于 {item.receiveDate} 接收
                  </Typography.Text>
                }
              />
              <Typography.Text strong type="success">
                {dynamicText(item.signCode, contractCodeOptions)}
              </Typography.Text>
            </Skeleton>
          </List.Item>
        )}
      />
    </Space>
  )
}

// 合同详情内容
export const DetailContent = ({ dataSource }: any) => {
  const descriptions = React.useMemo(() => {
    return [
      { name: '合同编号', value: dataSource?.contractNumber },
      { name: '合同名称', value: dataSource?.contractName },
      { name: '合同日期', value: dataSource?.contractDate },
      { name: '项目名称', value: dataSource?.projectName },
      { name: '项目类型', value: dataSource?.projectType },
      { name: '所属行业', value: dataSource?.industry },
      { name: '合同类型', value: dataSource?.contractTypeName },
      { name: '合同模板', value: dataSource?.contractTemplateName },
      { name: '合同子模板', value: dataSource?.contractTemplateSonName },
      { name: '甲方（用能单位）', value: dataSource?.partyAName },
      { name: '合同账期', value: dataSource?.contractAccountPeriod ? `${dataSource?.contractAccountPeriod}天` : '' },
      { name: '合同金额', value: dataSource?.contractMoney ? `${dataSource?.contractMoney}元` : '' },
      { name: '乙方（节能服务公司）', value: dataSource?.partyBName, span: 3 },
    ]
  }, [dataSource])

  return (
    <Space direction="vertical" size={24}>
      <Descriptions title={null} column={3} layout="horizontal">
        {descriptions.map((desc: any, index: number) => {
          return (
            <Descriptions.Item key={index} label={desc.name} span={desc.span}>
              {desc.value ?? ''}
            </Descriptions.Item>
          )
        })}

        {/* 未签约：合同详情没有签核详情，其他状态都有签核详情。 */}
        {['1', '2', '10'].includes(dataSource.code?.toString()) && (
          <Descriptions.Item
            span={3}
            label={<Typography.Text style={{ lineHeight: '32px' }}>签核详情</Typography.Text>}
          >
            <Button
              type="link"
              onClick={() => {
                if (!dataSource?.fileUrl) return
                const a = document.createElement('a')
                a.href = dataSource?.fileUrl
                a.target = '_blank'
                a.click()
              }}
            >
              查看签核详情
            </Button>
          </Descriptions.Item>
        )}
      </Descriptions>
      <Descriptions title="合同档案" column={1}>
        {dataSource?.contractFile?.map((item: any) => {
          return (
            <Descriptions.Item key={item.fileId} style={{ cursor: 'pointer' }}>
              <div onClick={() => viewPdf(item.fileId)}>
                <FilePdfOutlined style={{ marginRight: 8, color: '#f8ad90' }} />
                {item.name}
              </div>
            </Descriptions.Item>
          )
        })}
      </Descriptions>
      {dataSource?.stock?.length > 0 ? (
        <Descriptions title="采购合同" column={1}>
          {dataSource.stock?.map((item: any) => {
            return (
              <Descriptions.Item key={item.fileId} style={{ cursor: 'pointer' }}>
                <div onClick={() => FileService.download(item.fileId)}>
                  <FileExcelOutlined style={{ marginRight: 8, color: '#f8ad90' }} />
                  {item.name}
                </div>
              </Descriptions.Item>
            )
          })}
        </Descriptions>
      ) : null}
    </Space>
  )
}
