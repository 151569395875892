import { useEffect, useState } from 'react'
import styles from './index.module.scss'
import { useLocation } from 'react-router-dom'
import { Form, Select } from 'antd'
import { SubBreadcrumbObservable } from './layout'

// 用户中心 修改用户信息用
const UserCenter = () => {
  const [form] = Form.useForm()
  const location = useLocation()

  // 用户信息
  const [user, setUser] = useState({
    lang: 'cn',
    zone: 'beijing',
  })

  useEffect(() => {
    SubBreadcrumbObservable.next([
      {
        name: '设置',
        url: '',
      },
      {
        name: '语言与时间',
        url: '/user-center/time-zone',
      },
    ])
    // 获取通过路径传递过来的user信息
    if (location.state) {
      setUser(location.state as any)
      form.setFieldsValue({
        ...(location.state as any),
      })
    }
  }, [])

  return (
    <div className={`${styles.userCenter} ${styles.timeZone}`}>
      <h2 style={{ fontWeight: 700 }}>语言与时间</h2>
      <div className="user-center-wrap">
        <Form form={form}>
          <Form.Item name="lang" label="显示语言" colon={false}>
            <Select size="large" defaultValue={user.lang} disabled style={{ width: 342 }}>
              <Select.Option value="cn">简体中文</Select.Option>
              <Select.Option value="en">英文</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item name="timezone" label="选择时区" colon={false}>
            <Select size="large" defaultValue={user.zone} disabled style={{ width: 342 }}>
              <Select.Option value="beijing">(UTC+08:00) 北京时区</Select.Option>
            </Select>
          </Form.Item>
        </Form>
      </div>
    </div>
  )
}

export default UserCenter
