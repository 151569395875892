import {
  Table,
  Space,
  Menu,
  Badge,
  Button,
  Input,
  Dropdown,
  Form,
  Modal,
  Tooltip,
  Card,
  Pagination,
  Checkbox,
} from 'antd'
import React, { useEffect, useState, useRef } from 'react'
import {
  EllipsisOutlined,
  StarOutlined,
  StarFilled,
  FundProjectionScreenOutlined,
  SearchOutlined,
  FilterFilled,
  ReloadOutlined,
  ExclamationCircleFilled,
} from '@ant-design/icons'
import AddModal from './components/addModal'
import { CustomIcons } from '../../../global'

import '../index.less'
import { FinanceService } from '../../../apis/project-service-history'
import moment from 'moment'
import { Link } from 'react-router-dom'
import styles from '../index.module.scss'
import { NAME_EXP } from '../../../utils/constant'
import { BreadcrumbObservable } from '../../../application'

type BadgeTypes = 'default' | 'processing' | 'success' | 'error' | 'warning' | undefined

let currentModal: any

const SearchFilterText = (props: any) => {
  const { placeholder, field, fieldValue, onConfirm, visible } = props
  const [value, setvalue] = useState(fieldValue)

  useEffect(() => {
    if (visible) {
      setvalue(fieldValue)
    }
  }, [visible])

  return (
    <div style={{ padding: '24px 24px 16px 24px', width: '328px' }} className="list-filter-popover">
      <Input
        placeholder={placeholder}
        value={value}
        onChange={(e) => {
          setvalue(e.currentTarget.value)
        }}
        suffix={<SearchOutlined />}
        size="large"
      />
      <Space style={{ width: '100%', justifyContent: 'end', marginTop: '32px' }}>
        <Button
          className="actived"
          onClick={() => {
            setvalue('')
            const dom = document.querySelector('.ant-input.ant-input-lg') as HTMLElement
            dom?.focus()
          }}
        >
          重置
        </Button>
        <Button
          type="primary"
          onClick={() => {
            onConfirm({ [field]: value })
          }}
        >
          确定
        </Button>
      </Space>
    </div>
  )
}

const SearchFilterCheckbox = (props: any) => {
  const { dataSource, field, fieldValue, onConfirm, visible } = props
  const [value, setvalue] = useState(fieldValue?.split(',') || [])

  useEffect(() => {
    if (visible) {
      setvalue(fieldValue ? fieldValue.split(',') : [])
    }
  }, [visible])

  return (
    <div className={`${styles.filterDownCheckboxWrap} list-filter-popover`}>
      {dataSource?.map((option: any) => {
        return (
          <Checkbox
            key={option.value}
            value={option.value}
            checked={value.indexOf(option.value) > -1}
            onClick={() => {
              const val = option.value
              const list = value.slice()
              if (value.indexOf(val) > -1) {
                list.splice(value.indexOf(val), 1)
              } else {
                list.push(val)
              }
              setvalue(list)
            }}
          >
            {option.text}
          </Checkbox>
        )
      })}
      <Space style={{ width: '100%', justifyContent: 'end', marginTop: '12px' }}>
        <Button
          className="actived"
          onClick={() => {
            setvalue([])
          }}
        >
          重置
        </Button>
        <Button
          type="primary"
          onClick={() => {
            onConfirm({ [field]: value.join(',') })
          }}
        >
          确定
        </Button>
      </Space>
    </div>
  )
}

// 财务方案列表
const FinancialList = () => {
  const [modal, contextHolder] = Modal.useModal()
  const [renameForm] = Form.useForm()
  const params = useRef<any>({
    createTimeSort: '0',
    updateTimeSort: '0',
    marked: undefined,
    locked: undefined,
    installedCapacitySort: '0',
    dayAvgPowerGenerationSort: '0',
    useHourYearSort: '0',
    selfAbsorptionRateSort: '0',
    batteryCapacitySort: '0',
    pcsPowerSort: '0',
    dayAvgDischargeSort: '0',
    dayAvgChargeDischargeSort: '0',
    name: '',
    creatorUserName: '',
    projectName: '',
    calcStatus: '',
  })
  const [loading, setLoading] = useState(false)
  const [addVisible, setAddVisible] = useState(false)
  const [dataSource, setDataSource] = useState<any>()
  const [filterDownVisible, setFilterDownVisible] = useState<any>({
    nameVisible: false,
    projectVisible: false,
    createVisible: false,
    computedVisible: false,
  })
  let listRef = useRef<any[]>([])
  let pageRef = useRef<any>({
    page: 1,
    total: 0,
    pageSize: 10,
  })

  useEffect(() => {
    BreadcrumbObservable.next([])
  }, [])

  // 新建方案
  const addFinancial = () => {
    setAddVisible(true)
  }

  // 重新计算
  const reComputed = (record: any) => {
    Modal.confirm({
      title: (
        <>
          <strong style={{ fontSize: 16 }}>重新计算</strong>
        </>
      ),
      keyboard: false,
      autoFocusButton: null,
      icon: <ExclamationCircleFilled style={{ color: '#1ec692' }} />,
      content: `确认重新计算【${record.name}】？原计算结果将覆盖`,
      cancelButtonProps: {
        className: 'actived',
      },
      onOk: async () => {
        await FinanceService.calculate({
          projectId: record?.projectId,
          root: {
            financeId: record.id,
          },
        })
          .then(() => {
            queryList()
          })
          .catch(() => {})
          .finally(() => {})
      },
    })
  }

  const listStatusColumn = (text: string, row: any) => {
    let status: {
      status: BadgeTypes
      text: string
    } = {
      status: 'default',
      text: '未开始',
    }
    switch (row.calcStatus) {
      case 1:
        status = {
          status: 'processing',
          text: '进行中',
        }
        break
      case 2:
        status = {
          status: 'success',
          text: '已完成',
        }
        break
      case 3:
        status = {
          status: 'error',
          text: '计算失败',
        }
        break
      case 4:
        status = {
          status: 'error',
          text: '结果失效',
        }
        break
    }
    return (
      <>
        <Badge status={status.status} />
        {status.text}
      </>
    )
  }

  useEffect(() => {
    const filterDownCloseFn = () => {
      setFilterDownVisible({
        nameVisible: false,
        projectVisible: false,
        createVisible: false,
        computedVisible: false,
      })
    }
    const filterEnterDown = (event: any) => {
      console.info(event)
      if (event.key === 'Enter') {
        const doms = document.getElementsByClassName('list-filter-popover') as any
        if (doms && doms.length > 0) {
          console.info(doms)
          Array.from(doms).forEach((dom: any) => {
            const clientRect = dom.getBoundingClientRect()
            if (clientRect.width) {
              const btn = dom.querySelector('.list-filter-popover button.ant-btn-primary') as HTMLElement
              btn?.click()
            }
          })
        }
      }
      if (event.key === 'Escape') {
        setFilterDownVisible({
          nameVisible: false,
          projectVisible: false,
          createVisible: false,
          computedVisible: false,
        })
      }
    }

    window.addEventListener('click', filterDownCloseFn)
    document.addEventListener('keydown', filterEnterDown)
    return () => {
      window.removeEventListener('click', filterDownCloseFn)
      document.removeEventListener('keydown', filterEnterDown)
    }
  }, [])

  // 分页
  const changePagination = (page: number, pageSize?: number) => {
    pageRef.current = {
      ...pageRef.current,
      page,
      pageSize: pageSize || pageRef.current.pageSize,
    }
    queryList()
  }

  // 排序
  const changeTable = (pager: any, filter: any, sorter: any) => {
    const sortData = {
      createTimeSort: sorter.order
        ? sorter.field === 'createTime'
          ? sorter.order === 'ascend'
            ? '1'
            : '2'
          : '0'
        : '0',
      updateTimeSort: sorter.order
        ? sorter.field === 'updateTime'
          ? sorter.order === 'ascend'
            ? '1'
            : '2'
          : '0'
        : '0',
    }
    queryList({ ...sortData })
  }

  const queryList = (condition = {}, reload = true) => {
    params.current = { ...params.current, ...condition }
    setLoading(true)
    return FinanceService.all({
      ...params.current,
      ...condition,
      page: pageRef.current?.page,
      size: pageRef.current?.pageSize,
    })
      .then((res) => {
        // 避免闭包造成列表渲染出问题
        const resData = res?.data?.data
        const setData = (dataSource: any) => (reload ? resData : [...dataSource, ...resData])
        setDataSource((dataSource: any) => setData(dataSource))
        pageRef.current = {
          page: pageRef.current.page,
          pageSize: res?.data?.size,
          total: res?.data?.total,
        }
      })
      .catch(() => {})
      .finally(() => {
        setLoading(false)
      })
  }

  const init = () => {
    queryList()
  }

  useEffect(() => {
    init()
  }, [])

  useEffect(() => {
    listRef.current = dataSource
  })

  // 重命名与删除
  const ActionMenu = (props: { id: number; name: string }) => {
    return (
      <Menu className="custom-row-menu">
        <Menu.Item
          onClick={() => {
            renameForm.setFieldsValue({
              rename: props.name,
            })
            currentModal?.destroy()
            currentModal = modal.confirm({
              keyboard: false,
              autoFocusButton: null,
              title: '重命名',
              icon: <CustomIcons type="klf-alarm1" />,
              content: (
                <Form form={renameForm}>
                  <Form.Item
                    name="rename"
                    label="请输入方案名称"
                    rules={[
                      {
                        required: true,
                        message: '请输入方案名称',
                      },
                      {
                        pattern: NAME_EXP,
                        message: '1-16个字符,中文、英文、_、数字和-组成',
                      },
                    ]}
                  >
                    <Input placeholder="请输入"></Input>
                  </Form.Item>
                </Form>
              ),
              width: 500,
              okType: 'primary',
              okText: '确定',
              cancelText: '取消',
              onOk: () => {
                return new Promise((resolve, reject) => {
                  const { rename } = renameForm.getFieldsValue()
                  renameForm
                    .validateFields()
                    .then(() => {
                      FinanceService.rename1({
                        financeId: props.id.toString(),
                        root: {
                          name: rename,
                        },
                      })
                        .then((resp) => {
                          resolve(resp)
                          queryList()
                        })
                        .catch((e) => reject(e))
                    })
                    .catch((e) => {
                      reject(e)
                    })
                })
              },
            })
          }}
        >
          重命名
        </Menu.Item>
      </Menu>
    )
  }

  const columns = [
    {
      title: '财务方案名称',
      dataIndex: 'name',
      key: 'name',
      width: 328,
      filterIcon: (
        <SearchOutlined
          style={{
            color: params.current.name ? '#42d4a1' : '',
          }}
        />
      ),
      onFilterDropdownVisibleChange: () => {
        setFilterDownVisible({
          nameVisible: true,
          projectVisible: false,
          createVisible: false,
          computedVisible: false,
        })
      },
      filterDropdown: () => {
        return (
          <SearchFilterText
            placeholder="请输入财务方案名称"
            field="name"
            fieldValue={params.current.name}
            visible={filterDownVisible.nameVisible}
            onConfirm={(condition: any) => {
              pageRef.current.page = 1
              setFilterDownVisible({
                ...filterDownVisible,
                nameVisible: false,
              })
              queryList(condition)
            }}
            onCancel={() => {
              setFilterDownVisible({
                ...filterDownVisible,
                nameVisible: false,
              })
            }}
          />
        )
      },
      filterDropdownVisible: filterDownVisible.nameVisible,
      render: (text: string, row: any) => {
        return (
          <Tooltip title={text}>
            {row.calcStatus == 1 ? text : <Link to={`/workspace/${row.projectId}/fin?comp=true`}>{text}</Link>}
          </Tooltip>
        )
      },
    },
    {
      title: '所属项目',
      dataIndex: 'projectName',
      key: 'projectName',
      width: 162,
      filterIcon: (
        <SearchOutlined
          style={{
            color: params.current.projectName ? '#42d4a1' : '',
          }}
        />
      ),
      onFilterDropdownVisibleChange: () => {
        setFilterDownVisible({
          nameVisible: false,
          projectVisible: true,
          createVisible: false,
          computedVisible: false,
        })
      },
      filterDropdown: () => {
        return (
          <SearchFilterText
            placeholder="请输入项目名称"
            field="projectName"
            fieldValue={params.current.projectName}
            visible={filterDownVisible.projectVisible}
            onConfirm={(condition: any) => {
              pageRef.current.page = 1
              setFilterDownVisible({
                ...filterDownVisible,
                projectVisible: false,
              })
              queryList(condition)
            }}
            onCancel={() => {
              setFilterDownVisible({
                ...filterDownVisible,
                projectVisible: false,
              })
            }}
          />
        )
      },
      filterDropdownVisible: filterDownVisible.projectVisible,
      render: (text: string) => {
        return <>{text.length > 10 ? `${text.substring(0, 9)}...` : text}</>
      },
    },
    {
      title: '计算状态',
      dataIndex: 'calcStatus',
      key: 'calcStatus',
      width: 120,
      render: listStatusColumn,
      filterDropdown: () => {
        return (
          <SearchFilterCheckbox
            dataSource={[
              { text: '未开始', value: '0' },
              { text: '进行中', value: '1' },
              { text: '已完成', value: '2' },
              { text: '计算失败', value: '3' },
              { text: '结果失效', value: '4' },
            ]}
            visible={filterDownVisible.computedVisible}
            field="calcStatus"
            fieldValue={params.current.calcStatus}
            onConfirm={(condition: any) => {
              pageRef.current.page = 1
              setFilterDownVisible({
                ...filterDownVisible,
                computedVisible: false,
              })
              queryList(condition)
            }}
            onCancel={() => {
              setFilterDownVisible({
                ...filterDownVisible,
                computedVisible: false,
              })
            }}
          />
        )
      },
      filterIcon: (
        <FilterFilled
          style={{
            color: params.current.calcStatus ? '#42d4a1' : '',
          }}
        />
      ),
      filterDropdownVisible: filterDownVisible.computedVisible,
      onFilterDropdownVisibleChange: () => {
        setFilterDownVisible({
          nameVisible: false,
          projectVisible: false,
          createVisible: false,
          computedVisible: true,
        })
      },
    },
    {
      title: '创建时间',
      dataIndex: 'createTime',
      key: 'createTime',
      sorter: true,
      width: 162,
      render: (text: any) => {
        return text && moment(text).format('YYYY.MM.DD HH:mm')
      },
    },
    {
      title: '更新时间',
      dataIndex: 'updateTime',
      key: 'updateTime',
      width: 162,
      sorter: true,
      render: (text: any) => {
        return text && moment(text).format('YYYY.MM.DD HH:mm')
      },
    },
    {
      title: '创建人',
      dataIndex: 'creatorName',
      key: 'creatorName',
      width: 144,
      filterIcon: (
        <SearchOutlined
          style={{
            color: params.current.creatorUserName ? '#42d4a1' : '',
          }}
        />
      ),
      onFilterDropdownVisibleChange: () => {
        setFilterDownVisible({
          nameVisible: false,
          projectVisible: false,
          createVisible: true,
          computedVisible: false,
        })
      },
      render: (res: string) => <div className="table-creator">{res}</div>,
      filterDropdown: () => {
        return (
          <SearchFilterText
            placeholder="请输入创建人名称"
            field="creatorUserName"
            fieldValue={params.current.creatorUserName}
            visible={filterDownVisible.createVisible}
            onConfirm={(condition: any) => {
              pageRef.current.page = 1
              setFilterDownVisible({
                ...filterDownVisible,
                createVisible: false,
              })
              queryList(condition)
            }}
            onCancel={() => {
              setFilterDownVisible({
                ...filterDownVisible,
                createVisible: false,
              })
            }}
          />
        )
      },
      filterDropdownVisible: filterDownVisible.createVisible,
    },
    {
      title: <strong>操作</strong>,
      key: 'action',
      width: 116,
      render: (text: any, record: any) => {
        const obj = false
        if (obj) {
          return (
            <div key={`action-${record.id}`}>
              {record.name} {text}
            </div>
          )
        }
        return (
          <Space className="row-actions">
            <span>
              <Dropdown
                key={`action-${record.id}`}
                overlay={<ActionMenu id={record.id} name={record.name} />}
                destroyPopupOnHide={true}
              >
                <Button type="link" className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
                  <EllipsisOutlined style={{ textShadow: '0px 0px 5px black' }} />
                </Button>
              </Dropdown>
            </span>
            <span>{record.marked && <CustomIcons type="klf-mark" style={{ fontSize: 16, color: '#98a0b3' }} />}</span>
            <span>
              {record.locked && <img src="./assets/file.svg" alt="" style={{ width: 16, height: 16 }} />}
              {(record.calcStatus == 3 || record.calcStatus == 4) && (
                <div
                  className="icon-btn"
                  onClick={() => {
                    reComputed(record)
                  }}
                >
                  <Tooltip title="重新计算">
                    <ReloadOutlined style={{ color: '#42d4a1' }} />
                  </Tooltip>
                </div>
              )}
            </span>
          </Space>
        )
      },
    },
  ]

  return (
    <Card
      title={
        <div
          style={{
            fontWeight: 600,
            fontSize: 20,
          }}
        >
          财务方案
        </div>
      }
      className={`${styles.listPage} no-border-bottom-card list-card-wrap`}
      extra={
        !loading && (
          <Space className="btns">
            <Button
              className={params.current.locked ? 'actived' : ''}
              style={{ display: 'flex', alignItems: 'center' }}
              icon={
                params.current.locked ? (
                  <img src="./assets/file-active.svg" alt="" style={{ marginRight: '8px', width: 14, height: 14 }} />
                ) : (
                  <CustomIcons type="klf-file-line" />
                )
              }
              onClick={() => {
                pageRef.current.page = 1
                queryList({ locked: params.current.locked ? undefined : 1 })
              }}
            >
              仅看归档
            </Button>
            <Button
              className={params.current.marked ? 'actived' : ''}
              icon={params.current.marked ? <StarFilled /> : <StarOutlined />}
              onClick={() => {
                pageRef.current.page = 1
                queryList({ marked: params.current.marked ? undefined : 1 })
              }}
            >
              仅看星标
            </Button>
            <Button type="primary" icon={<FundProjectionScreenOutlined />} onClick={addFinancial}>
              进工作台
            </Button>
          </Space>
        )
      }
      bodyStyle={{
        height: 'calc(100vh - 210px)',
        paddingTop: 0,
      }}
    >
      <div
        className="manage-table"
        style={{
          height: '100%',
          position: 'relative',
        }}
      >
        <Table
          rowKey="id"
          className="full-screen-table right-actions"
          style={{ flexGrow: 1 }}
          columns={columns}
          dataSource={dataSource}
          onChange={changeTable}
          scroll={{ x: 'auto', y: 'calc(100vh - 265px)' }}
          loading={loading}
          pagination={false}
          // pagination={{
          //   total: pageRef.current.total,
          //   showSizeChanger: true,
          //   showQuickJumper: true,
          //   defaultPageSize: 20,
          //   current: pageRef.current.page,
          //   pageSize: pageRef.current.pageSize,
          //   pageSizeOptions: ['10', '20', '50'],
          //   showTotal: (total) => `共 ${total} 条`,
          // }}
        ></Table>
        <div className={styles.paginationWrap}>
          <Pagination
            className="left-pagination"
            size="small"
            total={pageRef.current.total}
            showTotal={(total) => {
              return `共 ${total} 条`
            }}
            showSizeChanger
            current={pageRef.current.page}
            pageSize={pageRef.current.pageSize}
            defaultPageSize={20}
            pageSizeOptions={['10', '20', '50']}
            onChange={changePagination}
            locale={{ items_per_page: '' }}
          />
          <Pagination
            size="small"
            total={pageRef.current.total}
            current={pageRef.current.page}
            defaultPageSize={20}
            pageSize={pageRef.current.pageSize}
            pageSizeOptions={['10', '20', '50']}
            showQuickJumper
            showSizeChanger={false}
            locale={{ jump_to: '前往' }}
            onChange={changePagination}
          />
        </div>

        {addVisible && (
          <AddModal
            visible={addVisible}
            onCancel={() => {
              setAddVisible(false)
            }}
          />
        )}
        <div>{contextHolder}</div>
      </div>
    </Card>
  )
}

export default FinancialList
