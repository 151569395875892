import React from 'react'
import { Table } from 'antd'
import { padStart } from 'lodash'

export default ({ dataSource }) => {
  const columns = React.useMemo(() => {
    return [
      {
        dataIndex: 'index',
        title: '项次',
        align: 'center',
        width: 120,
        render: (...args) => padStart(args[2] + 1, 2, '0'),
      },
      { dataIndex: 'goodNumber', align: 'center', width: 200, title: '产品编号' },
      { dataIndex: 'goodName', align: 'center', width: 200, title: '产品名称' },
      { dataIndex: 'goodNum', align: 'center', width: 200, title: '产品数量' },
      { dataIndex: 'goodAvgPrice', align: 'center', width: 200, title: '产品单价' },
      { dataIndex: 'goodPrice', align: 'center', width: 200, title: '产品金额' },
    ]
  }, [])

  return (
    <Table
      rowKey={(...args: any[]) => args[1]}
      bordered
      className="bordered-table"
      columns={columns as any[]}
      dataSource={dataSource || []}
      locale={{ emptyText: '暂无数据' }}
      size="small"
    />
  )
}
