import React, { useEffect, useState } from 'react'
import {
  Button,
  Card,
  Checkbox,
  Col,
  Dropdown,
  Input,
  InputNumber,
  Menu,
  message,
  Modal,
  Radio,
  Row,
  Select,
  Slider,
  Spin,
} from 'antd'
import '../index.less'
// import { SettingOutlined } from '@ant-design/icons'
import { ProjectWorkbenchService, TechnologyService } from '../../../../apis/project-service-history'
import { useParams } from 'react-router-dom'
import { BatterySystemEfficiency } from './battery-system-efficiency'
import { BatteryAutoSelect } from './battery-auto-select'
import { CustomIcons } from '../../../../global'
import { CaretDownOutlined } from '@ant-design/icons'

const showXJ = false

export const BatteryConfig = (props: any) => {
  const { info, viewMode } = props
  const params = useParams()

  const [revenueSharing, setRevenueSharing] = React.useState<any>()

  const [batteryParams, setBatteryParams] = useState([])
  useEffect(() => {
    TechnologyService.getList()
      .then((resp) => {
        setBatteryParams(resp.data)
      })
      .catch(() => {})
  }, [])

  React.useEffect(() => {
    if (info) {
      setRevenueSharing(info.revenueSharing)
    }
  }, [info])

  const [loading, setLoading] = useState(true)
  useEffect(() => {
    setLoading(props.loading)
  }, [props.loading])

  if (!info) return null

  return (
    <Spin spinning={loading}>
      <Col
        style={{
          width: 300,
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        {!viewMode && (
          <Card
            style={{
              textAlign: 'left',
            }}
            title={
              <div>
                储能设置
                <div
                  style={{
                    position: 'absolute',
                    left: 0,
                    width: 3,
                    height: 22,
                    background: '#1EC692',
                    top: '50%',
                    transform: 'translateY(-50%)',
                  }}
                />
              </div>
            }
            extra={
              <Dropdown
                overlay={
                  <Menu>
                    {batteryParams.map((o: any) => {
                      return (
                        <Menu.Item
                          key={o.id}
                          onClick={() => {
                            ProjectWorkbenchService.checkParamLibrary({
                              technicalProposalId: params.id!,
                              root: {
                                paramLibraryId: o.id,
                                type: '0',
                              },
                            })
                              .then(() => {
                                props.onTriggerReload?.(true)
                              })
                              .catch(() => {})
                          }}
                        >
                          {o.name}
                        </Menu.Item>
                      )
                    })}
                  </Menu>
                }
              >
                <Button
                  icon={
                    <div>
                      <CustomIcons
                        type="klf-stack"
                        style={{
                          fontSize: 16,
                        }}
                      />
                      <CaretDownOutlined
                        style={{
                          fontSize: 10,
                        }}
                      />
                    </div>
                  }
                  type="link"
                  ghost
                />
              </Dropdown>
            }
            bodyStyle={{
              display: 'none',
            }}
          />
        )}
        <Card
          title="运行策略"
          style={{
            flexGrow: 1,
            overflowY: 'auto',
            textAlign: 'left',
          }}
        >
          <div
            style={{
              fontWeight: 600,
            }}
          >
            分时套利
          </div>
          <div
            style={{
              fontSize: 12,
              color: '#767F95',
              margin: '.3rem 0',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            分时套利收益分成{viewMode && <span>运营方{revenueSharing}%</span>}
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'flex-end',
              textAlign: 'center',
            }}
          >
            {!loading && !viewMode && (
              <div
                style={{
                  width: 40,
                  textAlign: 'left',
                }}
              >
                <div
                  style={{
                    fontSize: 12,
                    color: '#262E3F',
                  }}
                >
                  运营方
                </div>
                <div
                  style={{
                    fontSize: 12,
                    color: '#1ec692',
                    marginTop: 3,
                  }}
                >
                  {revenueSharing}%
                </div>
              </div>
            )}
            {!loading && !viewMode && (revenueSharing || revenueSharing === 0) && (
              <Slider
                className="percent-slider"
                style={{
                  flexGrow: 1,
                  margin: '0px 0.2rem 3px 0.2rem',
                }}
                defaultValue={revenueSharing}
                tipFormatter={(val) => {
                  if (!val && val !== 0) {
                    return undefined
                  }
                  return val + ':' + (100 - val)
                }}
                min={0}
                max={100}
                step={1}
                onAfterChange={(val) => {
                  setRevenueSharing(val)
                  ProjectWorkbenchService.updateRevenueSharing({
                    technologyId: params.id!,
                    root: {
                      revenueSharing: val,
                    },
                  }).then(() => {
                    props.onTriggerReload?.()
                  })
                }}
              />
            )}
            {!loading && !viewMode && (
              <div
                style={{
                  width: 35,
                  textAlign: 'right',
                }}
              >
                <div
                  style={{
                    fontSize: 12,
                    color: '#262E3F',
                  }}
                >
                  用户
                </div>
                <div
                  style={{
                    fontSize: 12,
                    color: 'rgba(50, 116, 246, 1)',
                    marginTop: 3,
                  }}
                >
                  {100 - revenueSharing}%
                </div>
              </div>
            )}
          </div>

          {showXJ && (
            <Checkbox
              disabled
              className="full-filled"
              style={{
                width: '100%',
                textAlign: 'left',
                marginTop: '1rem',
                marginLeft: 0,
              }}
            >
              <div
                style={{
                  fontWeight: 600,
                }}
              >
                削减需量
              </div>
              <div
                style={{
                  fontSize: 12,
                  color: 'gray',
                  margin: '.3rem 0',
                }}
              >
                需量电价下降收益分成
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'flex-end',
                  textAlign: 'center',
                }}
              >
                <Input suffix="%" value={0} disabled size="middle"></Input>
              </div>
            </Checkbox>
          )}
        </Card>
        <Card
          title="关键设备"
          style={{
            flexGrow: 1,
            overflowY: 'auto',
            textAlign: 'left',
            marginTop: 0,
          }}
        >
          <Row
            style={{
              marginBottom: '10px',
              alignItems: 'center',
            }}
          >
            <Col span={10}>电池类型</Col>
            <Col span={14}>
              {viewMode && (
                <span className="align-right-full">
                  {['', '磷酸铁锂电池', '铅碳电池', '三元锂电池'][info.batteryType]}
                </span>
              )}
              {!loading && !viewMode && (
                <Select
                  size="middle"
                  style={{
                    width: '100%',
                  }}
                  value={info.batteryType}
                  onChange={(val) => {
                    info.batteryType = val
                    ProjectWorkbenchService.updateBattery({
                      technologyId: params.id!,
                      root: {
                        batteryType: val,
                      },
                    }).then(() => {
                      props.onTriggerReload?.()
                    })
                  }}
                >
                  <Select.Option value={1}>磷酸铁锂电池</Select.Option>
                  <Select.Option value={2}>铅碳电池</Select.Option>
                  <Select.Option value={3}>三元锂电池</Select.Option>
                </Select>
              )}
            </Col>
          </Row>
          <Row
            style={{
              marginBottom: '10px',
              alignItems: 'center',
            }}
          >
            <Col span={10}>寿命</Col>
            <Col span={14} className="content-value">
              {info.batteryLife}次
            </Col>
          </Row>
          <Row
            style={{
              marginBottom: '10px',
              alignItems: 'center',
            }}
          >
            <Col span={10}>最小SOC</Col>
            <Col span={14} className="content-value">
              {info.minSoc}%
            </Col>
          </Row>
          <Row
            style={{
              marginBottom: '10px',
              alignItems: 'center',
            }}
          >
            <Col span={10}>最大SOC</Col>
            <Col span={14} className="content-value">
              {info.maxSoc}%
            </Col>
          </Row>
          <Row
            style={{
              alignItems: 'center',
            }}
          >
            <Col span={10}>年衰减率</Col>
            <Col span={14} className="content-value">
              {info.annualDecayRate}%
            </Col>
          </Row>
        </Card>
        <Card
          title="其他信息"
          style={{
            flexGrow: 1,
            overflowY: 'auto',
            textAlign: 'left',
            marginTop: 0,
          }}
        >
          <Row
            style={{
              marginBottom: '10px',
              alignItems: 'center',
            }}
          >
            <Col span={12}>系统总效率</Col>
            <Col span={12}>
              <Button
                type="link"
                style={{
                  width: '100%',
                  textAlign: 'right',
                  padding: 0,
                }}
                onClick={() => {
                  const modal = Modal.confirm({
                    keyboard: false,
                    title: '系统总效率',
                    closable: true,
                    content: (
                      <BatterySystemEfficiency
                        viewMode={viewMode}
                        technologyId={params.id!}
                        onClose={(reload: boolean = false) => {
                          modal.destroy()
                          if (reload) {
                            props.onTriggerReload?.()
                          }
                        }}
                      />
                    ),
                    icon: null,
                    width: 480,
                    okButtonProps: {
                      style: { display: 'none' },
                    },
                    cancelButtonProps: {
                      style: { display: 'none' },
                    },
                  })
                }}
              >
                {`${parseFloat(info.energyStorageEfficiency).toFixed(2)}% >`}
              </Button>
            </Col>
          </Row>
          <Row
            style={{
              alignItems: 'center',
            }}
          >
            <Col span={10}>BOP寿命</Col>
            <Col span={14}>
              {viewMode && <span className="align-right-full">{info.bopLife}年</span>}
              {!loading && !viewMode && (
                <Input size="middle" suffix={'年'} style={{ width: '100%' }} value={info.bopLife} disabled />
              )}
            </Col>
          </Row>
        </Card>
        <Card
          title="容量设置"
          style={{
            flexGrow: 1,
            overflowY: 'auto',
            textAlign: 'left',
            marginTop: 0,
          }}
        >
          <Radio.Group
            defaultValue={0}
            style={{
              width: '100%',
            }}
          >
            <Radio className="full-filled" value={0} disabled={viewMode}>
              <div
                style={{
                  marginBottom: 10,
                }}
              >
                手动预设
              </div>
              <Row
                style={{
                  marginBottom: 10,
                  alignItems: 'center',
                }}
              >
                <Col span={10}>储能容量</Col>
                <Col span={14}>
                  {viewMode && <span className="align-right-full">{info.energyStorageCapacity}kWh</span>}

                  {!loading && !viewMode && (
                    <InputNumber
                      id="energyStorageCapacity"
                      style={{
                        width: '100%',
                      }}
                      size="middle"
                      addonAfter="kWh"
                      controls={false}
                      precision={0}
                      formatter={(val) => {
                        if (val === '') {
                          return '0'
                        }
                        const formattedVal = parseFloat(parseFloat(val).toFixed(0))
                        if (formattedVal < 0) {
                          return '0'
                        }
                        return formattedVal.toString()
                      }}
                      defaultValue={info.energyStorageCapacity}
                      onBlur={(dom) => {
                        const energyStorageCapacity = dom.target.value
                        if (!energyStorageCapacity || parseFloat(energyStorageCapacity) === 0) {
                          message.error('储能容量必须大于0')
                          setTimeout(() => {
                            ;(document.getElementById('energyStorageCapacity') as HTMLInputElement).value =
                              info.energyStorageCapacity
                          }, 100)
                          return
                        }
                        ProjectWorkbenchService.updateEnergyStorageCapacity({
                          technologyId: params.id!,
                          root: {
                            energyStorageCapacity,
                          },
                        })
                          .then(() => {
                            props.onTriggerReload?.()
                          })
                          .catch(() => {
                            ;(document.getElementById('energyStorageCapacity') as HTMLInputElement).value =
                              energyStorageCapacity
                          })
                      }}
                    />
                  )}
                </Col>
              </Row>
              <Row
                style={{
                  marginBottom: 10,
                  alignItems: 'center',
                }}
              >
                <Col span={10}>储能倍率</Col>
                <Col span={14}>
                  {viewMode && <span className="align-right-full">{info.energyStorageRatio}&nbsp;&nbsp;1/h</span>}

                  {!loading && !viewMode && (
                    <InputNumber
                      id="energyStorageRatio"
                      style={{
                        width: '100%',
                      }}
                      step={0.01}
                      size="middle"
                      addonAfter="1/h"
                      defaultValue={info.energyStorageRatio}
                      formatter={(val) => {
                        if (val === '') {
                          return '0'
                        }
                        if (val === '0.') {
                          return '0.'
                        }
                        const formattedVal = parseFloat(parseFloat(val).toFixed(2))
                        if (formattedVal > 1) {
                          return '1'
                        }
                        if (formattedVal < 0) {
                          return '0'
                        }
                        return formattedVal.toString()
                      }}
                      controls={false}
                      onBlur={(dom) => {
                        const energyStorageRatio = dom.target.value
                        if (!energyStorageRatio || parseFloat(energyStorageRatio) === 0) {
                          message.error('储能倍率必须大于0')
                          setTimeout(() => {
                            ;(document.getElementById('energyStorageRatio') as HTMLInputElement).value =
                              info.energyStorageRatio
                          }, 100)
                          return
                        }
                        ProjectWorkbenchService.updateEnergyStorageCapacity({
                          technologyId: params.id!,
                          root: {
                            energyStorageRatio,
                          },
                        })
                          .then(() => {
                            props.onTriggerReload?.()
                          })
                          .catch(() => {
                            // console.log(energyStorageRatio, 2)
                            ;(document.getElementById('energyStorageRatio') as HTMLInputElement).value =
                              energyStorageRatio
                          })
                      }}
                    />
                  )}
                </Col>
              </Row>
            </Radio>
            {!loading && !viewMode && (
              <Radio className="full-filled" value={1} disabled>
                <Row
                  style={{
                    marginTop: '10px',
                    alignItems: 'center',
                  }}
                >
                  <Col span={10}>自动优选</Col>
                  <Col
                    span={14}
                    style={{
                      textAlign: 'right',
                    }}
                  >
                    <Button
                      type="link"
                      style={{
                        width: '100%',
                        textAlign: 'right',
                      }}
                      onClick={() => {
                        const modal = Modal.confirm({
                          keyboard: false,
                          title: '储能自动优选配置',
                          content: (
                            <BatteryAutoSelect
                              technologyId={params.id!}
                              onClose={(reload: boolean = false) => {
                                modal.destroy()
                                if (reload) {
                                  props.onTriggerReload?.()
                                }
                              }}
                            />
                          ),
                          closable: true,
                          icon: null,
                          width: 640,
                          okButtonProps: {
                            style: { display: 'none' },
                          },
                          cancelButtonProps: {
                            style: { display: 'none' },
                          },
                        })
                      }}
                    >
                      {`了解详情 >`}
                    </Button>
                  </Col>
                </Row>
              </Radio>
            )}
          </Radio.Group>
        </Card>
      </Col>
    </Spin>
  )
}
