import { useState, useEffect, useCallback } from 'react'
import { Modal, Form, Radio, message } from 'antd'
import { ProjectPartnerService } from '../../../../apis/wl-service'
import CompanyForm from './company-form'
import PersonalForm from './personal-form'

interface IDlgProps {
  dataSource?: {
    id: string
    [name: string]: any
  }
  visible: boolean
  title: string
  onOk: (type: string) => void
  onCancel: () => void
}

// 添加，编辑合作方
const PartnerDlg = ({ dataSource, visible, title, onOk, onCancel }: IDlgProps) => {
  const [form] = Form.useForm()
  const [loading, setLoading] = useState<boolean>(false)

  useEffect(() => {
    form.resetFields()
    form.setFieldsValue(dataSource)
  }, [dataSource])

  // 确认
  const handleOk = () => {
    form.validateFields().then((values: any) => {
      setLoading(true)
      values.id = dataSource?.id ?? ''
      ProjectPartnerService.update({ id: dataSource?.id ?? '', root: values })
        .then((resp: any) => {
          if (resp?.code === 200) {
            message.success(resp?.msg ?? '操作成功')
            form.resetFields()
            onOk(values.type)
          } else if (resp?.msg) {
            message.error(resp?.msg)
          }
        })
        .catch((err: any) => {
          console.warn(err)
        })
        .finally(() => {
          setLoading(false)
        })
    })
  }

  const changeType = useCallback(
    (e: any) => {
      form.resetFields()
      form.setFieldsValue({ type: e.target.value })
    },
    [form]
  )

  return (
    <Modal
      visible={visible}
      title={title}
      maskClosable={false}
      keyboard={false}
      width={640}
      okButtonProps={{
        loading,
        disabled: loading,
      }}
      destroyOnClose
      onCancel={onCancel}
      onOk={handleOk}
    >
      <Form form={form} labelCol={{ span: 6 }} wrapperCol={{ span: 18 }}>
        <Form.Item name="type" wrapperCol={{ offset: 6, span: 18 }}>
          <Radio.Group disabled={!!dataSource?.id} onChange={changeType}>
            <Radio value="0">企业</Radio>
            <Radio value="1">个人</Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item noStyle shouldUpdate={(prevValues, currentValues) => prevValues.type !== currentValues.type}>
          {({ getFieldValue }) => {
            return getFieldValue('type') === '0' ? <CompanyForm /> : <PersonalForm />
          }}
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default PartnerDlg
