/**
 * 合同状态
 * 可提交签约状态：0
 * 可删除状态：0，3，4，5，8，11
 * 可撤回状态：1，6，7
 * 可作废状态：1，6，7
 * 可查看签核详情状态：1，2，10
 * 可下载合同状态：2，10
 * */
export const contractCodeOptions = [
  { id: '0', fullName: '草稿', color: '#708cff' },
  { id: '1', fullName: '签署中', color: '#9cd494' },
  { id: '2', fullName: '已完成', color: undefined },
  { id: '10', fullName: '已完成', color: undefined },
  { id: '3', fullName: '已退回', color: '#ff3300' },
  { id: '4', fullName: '已撤回', color: '#f76763' },
  { id: '5', fullName: '已过期', color: '#771ed6' },
  { id: '6', fullName: '拟定中', color: '#cbcc00' },
  { id: '7', fullName: '作废确定中', color: '#608000' },
  { id: '8', fullName: '已作废', color: '#f69853' },
  { id: '11', fullName: '已作废', color: '#f69853' },
  { id: '9', fullName: '已删除', color: '#cc3300' },
]
