import React, { forwardRef, useEffect, useImperativeHandle } from 'react'
import { DatePicker, Form, Input, Select, Table, Tabs } from 'antd'
import { TabPaneProps } from '../../type'
import { INVERTER_COLUMNS, METER_COLUMNS } from '../../constants'

import './index.less'

const { TabPane } = Tabs

const layout = {
  colon: false,
  labelCol: { span: 6 },
  wrapperCol: { span: 18 },
}

const DefaultParmas = {
  thingxPowerStationId: null, // 物联电站id
  installedCapacity: null, // 装机容量
  gridConnectionDate: null, // 并网日期
  voltageLevel: null, // 电压等级
}

const ChannelInfo: React.FC<TabPaneProps> = React.memo(
  forwardRef((props, ref) => {
    const [form] = Form.useForm()

    useImperativeHandle(ref, () => ({
      submit: () => {
        handleSubmit()
      },
      reset: () => {
        form.setFieldsValue({ ...DefaultParmas })
      },
    }))

    const { initialValues, nodeList, stationDevice, onFetch, onSubmit, onFailed } = props
    const { inverterList, meterList } = stationDevice || {}

    const handleNodeChange = (value: any) => {
      onFetch?.(value)
    }

    const handleSubmit = () => {
      form && form.submit()
    }

    // 表单提交
    const onFinish = (values: any) => {
      onSubmit?.(values)
    }

    // 验证失败
    const onFinishFailed = (values: any) => {
      onFailed?.(values)
    }

    useEffect(() => {
      form.setFieldsValue(initialValues)
    }, [initialValues])

    return (
      <div className="station-channel-info-create">
        <Form
          form={form}
          {...layout}
          initialValues={{ ...DefaultParmas }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Form.Item
            label="电站物联节点"
            name="thingxPowerStationId"
            rules={[{ required: true, message: '请选择电站物联节点' }]}
          >
            <Select size="large" showSearch onChange={handleNodeChange}>
              {nodeList?.map(({ thingxPowerStationId, name, disable }) => (
                <Select.Option key={thingxPowerStationId} value={thingxPowerStationId} disabled={disable}>
                  {name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item label="装机容量" name="installedCapacity" rules={[{ required: true, message: '请输入装机容量' }, {pattern: /^-?[1-9]\d*$/, message:'装机容量必须是整数'}]}>
            <Input size="large" suffix="kW" />
          </Form.Item>

          <Form.Item label="电压等级" name="voltageLevel" rules={[{ required: true, message: '请输入电压等级' }, {pattern: /^[+]{0,1}(\d+)$|^[+]{0,1}(\d+\.\d+)$/, message: '电压等级必须是正数'}]}>
            <Input size="large" suffix="kV" />
          </Form.Item>

          <Form.Item label="并网日期" name="gridConnectionDate" rules={[{ required: true, message: '请选择并网日期' }]}>
            <DatePicker size="large" style={{ width: '100%' }} />
          </Form.Item>
        </Form>
        <Tabs defaultActiveKey="0">
          <TabPane tab="逆变器清单" key="0">
            <Table
              className="station-channel-info-create-table"
              size="small"
              columns={INVERTER_COLUMNS}
              dataSource={inverterList || []}
              pagination={false}
              scroll={{ y: 210 }}
            />
          </TabPane>
          <TabPane tab="电表清单" key="1">
            <Table
              className="station-channel-info-create-table"
              size="small"
              columns={METER_COLUMNS}
              dataSource={meterList || []}
              pagination={false}
              scroll={{ y: 210 }}
            />
          </TabPane>
        </Tabs>
      </div>
    )
  })
)

export default ChannelInfo
