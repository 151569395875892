import React, { useEffect, useState } from 'react'
import { Button, Checkbox, Input,  Modal, Popconfirm, Select, Table, Tooltip } from 'antd'
import { SearchOutlined, UserOutlined } from '@ant-design/icons'
import { debounce } from 'lodash'
import './index.less'
import { CustomIcons } from '../../../../../global'
import {  PermissionTemplateControllerService } from '../../../../../apis/project-service-history'
import { useParams } from 'react-router'
import ProjectAccountControllerApi from '../../../../../services/api/ProjectAccountControllerApi'

const { Option } = Select

type ModalProps = {
  visible: boolean
  onOk?: (data: any) => void
  onCancel?: () => void
  dataSource?: any
}

type SearchParams = {
  projectId: string // 组织id
  keyword?: string // 用户名称
}

const AddMembersModal = React.memo((props: ModalProps) => {
  // 用户基础信息
  const [keyword, setKeyword] = useState<string>('')
  const { id }: any = useParams()
  // const { info }: any = useContext(CurrAuthContext)
  // const [user, setUser] = useState(Global.user)
  // Global.updateUser.push((e: any) => {
  //   setUser(e)
  // })

  const [loading, setLoading] = useState(false)
  const [params, setParms] = useState<SearchParams>({
    projectId: id,
  })
  const [memberList, setMemberList] = useState<any[]>([])
  const [templateList, setTemplateList] = useState<any[]>([])
  const [checkedKeys, setCheckedKeys] = useState<React.Key[]>([])
  const [checkedRows, setCheckedRows] = useState<any[]>([])
  const [ckKes, setCkKes] = useState<any[]>([])
  // const [inviteTemplate, setInviteTemplate] = useState<any>('')
  // const [defaultTemplateId, setDefaultTemplateId] = useState<any>('')

  const { visible, onOk, onCancel, dataSource } = props

  const MemberItem = React.memo((props: any) => {
    const { item } = props
    const { userId, userName } = item || {}

    return userId ? (
      <div className="add-members-item">
        <Checkbox value={userId} disabled={ckKes?.includes(userId)}>
          <UserOutlined className="add-members-item-avatar" />
          <Tooltip title={userName}>
            <span className="add-members-item-name">{userName}</span>
          </Tooltip>
        </Checkbox>
      </div>
    ) : null
  })

  const fetchMemberList = (permissionId?: any) => {
    if (loading) return
    setLoading(true)
    const data = {
      ...params,
      ...{
        keyword,
      },
    }
    setParms(data)
    ProjectAccountControllerApi.i3060ProjectAccountSelectionProjectIdGet({
      ...data,
    })
      .then((res) => {
        setLoading(false)
        const list = res?.data || []

        setMemberList(list.map((res) => ({ ...res, permissionId })))
      })
      .catch(() => {
        setLoading(false)
      })
  }

  const handleNameChange = debounce(
    () => {
      fetchMemberList()
    },
    500,
    {
      leading: true,
      trailing: false,
    }
  )

  // const handleTemplateChange = (value: string) => {
  //   setInviteTemplate(value)
  // }

  const handleCheck = (keys: any[]) => {
    let rows = memberList.filter((item) => keys.includes(item.userId))
    setCheckedKeys(keys)
    setCheckedRows(rows)
  }

  const removeCheck = (keys: any[]) => {
    const rows = checkedRows.filter((item) => !keys.includes(item.userId))
    setCheckedKeys(checkedKeys?.filter((key) => !keys.includes(key)))
    setCheckedRows(rows)
  }

  const handleClearAll = () => {
    setCheckedKeys([])
    setCheckedRows([])
  }

  const fetchPermissionTemplateList = () => {
    PermissionTemplateControllerService.list1().then((res: any) => {
      const list = res?.data || []
      setTemplateList(list)
      const defaultItem = list.find((item) => item?.defaultChecked)
      // if (defaultItem) {
      //   setInviteTemplate(Number(defaultItem.templateId))
      //   // setDefaultTemplateId(Number(defaultItem.templateId))
      // }
      return fetchMemberList(defaultItem?.templateId)
    })
  }
  const columns = [
    {
      title: '成员',
      dataIndex: 'userName',
      key: 'userName',
      width: 150,
      ellipsis: {
        showTitle: false,
      },
      render: (text: string) => (
        <>
          <UserOutlined className="add-members-item-avatar" />
          <Tooltip title={text}>
            <span className="add-members-item-name">{text}</span>
          </Tooltip>
        </>
      ),
    },
    {
      title: '功能权限模板',
      dataIndex: 'permissionId',
      key: 'permissionId',
      width: 168,
      render: (text: string, row: any, i: any) => (
        <div className="add-members-item-action">
          <Select
            style={{ width: 90 }}
            showSearch
            bordered={false}
            value={row.permissionId}
            placeholder="请选择"
            onChange={(e) => {
              const item = { ...row },
                list = [...checkedRows]
              item.permissionId = Number(e)
              list[i] = item
              setCheckedRows(list)
            }}
          >
            {templateList?.map((res: any) => (
              <Option value={res.templateId} key={res.templateId}>
                {res.templateName}
              </Option>
            ))}
          </Select>
          <Button
            style={{ padding: 0, width: 16 }}
            onClick={() => removeCheck([row.userId])}
            size="small"
            type="text"
            icon={<CustomIcons type="klf-delete" />}
            // icon={<DeleteOutlined />}
          />
        </div>
      ),
    },
  ]

  useEffect(() => {
    fetchPermissionTemplateList()
  }, [])

  useEffect(() => {
    setCkKes(dataSource?.map((res) => res.userId) || [])
  }, [dataSource])
  return (
    <Modal
      maskClosable={false}
      className="add-members-modal"
      visible={visible}
      width={632}
      title="添加成员"
      confirmLoading={loading}
      onCancel={onCancel}
      onOk={() => onOk(checkedRows)}
    >
      <div className="add-members-content">
        <div className="add-members-list">
          <div className="add-members-list-header">
            <Input
              placeholder="搜索组织内成员名称"
              value={keyword}
              onChange={(e: any) => setKeyword(e?.target?.value)}
              suffix={<SearchOutlined onClick={handleNameChange} style={{ color: 'rgba(0,0,0,.45)' }} />}
            />
          </div>
          <div className="add-members-list-content">
            <Checkbox.Group onChange={handleCheck} style={{ width: '100%' }} value={checkedKeys}>
              {memberList.map((item) => (
                <MemberItem key={item.userId} item={item} />
              ))}
            </Checkbox.Group>
          </div>
        </div>
        <div className="add-members-selected">
          <div className="add-members-selected-header">
            <span>已选：{checkedKeys?.length}名用户</span>
            <Popconfirm title="确定清空所选成员?" onConfirm={handleClearAll}>
              <span className="add-members-selected-clear">清空</span>
            </Popconfirm>
          </div>
          <div className="add-members-selected-content">
            <Table columns={columns} dataSource={checkedRows} pagination={false} />
          </div>
        </div>
      </div>
      {/* <div className="add-members-link">
        <h3 className="add-members-link-title">链接分享</h3>
        <div className="add-members-link-create">
          <Select
            showSearch
            size="large"
            bordered={false}
            value={inviteTemplate?.toString()}
            placeholder="请选择权限模板"
            onChange={handleTemplateChange}
          >
            {templateList?.map(({ templateId, templateName }) => (
              <Option key={templateId} value={`${templateId}`}>
                <div className="add-members-link-option">
                  <span className="add-members-link-option-prefix">加入项目后，权限为 </span>
                  <span>{templateName}</span>
                </div>
              </Option>
            ))}
          </Select>
          <div
            className="add-members-link-create-btn"
            onClick={() => {
              if (!inviteTemplate) {
                message.warning('请选择权限模板')
                return
              }
              // const content = JSON.stringify(
              //   checkedRows.map((res) => ({ ...res, templateIds: inviteTemplate, projectId: id }))
              // )
              const content = {
                projectId: id,
                userList: checkedRows,
                projectName: info?.name,
                userId: user?.id,
                userName: user?.userName,
                templateId: inviteTemplate,
                tenantId: user.tenantId,
              }
              AccountControllerService.add({
                root: {
                  type: 2,
                  content: JSON.stringify(content),
                },
              })
                .then((res: any) => {
                  try {
                    const url = location.href.split('#')[0] + `?invite=${res.data}`
                    copy(url, () => {
                      message.success('复制成功！')
                    })
                  } catch (error) {
                    console.log(error)
                  }
                })
                .catch((err) => {
                  console.log(err)
                })
            }}
          >
            复制链接
          </div>
        </div>
      </div> */}
    </Modal>
  )
})

export default AddMembersModal
